/*
 * # Title: Static Component
 * - Date: 2023-12-21
 * - LastEditors: liujin liujin@elinklaw.com
 * - LastEditTime: 2024-03-01 16:20:46
 */
import { App } from 'antd'
import type { MessageInstance } from 'antd/es/message/interface'
import type { ModalStaticFunctions } from 'antd/es/modal/confirm'
import type { NotificationInstance } from 'antd/es/notification/interface'
// import { BitzToast as message } from 'bitz-react-admin-ui'

let message: MessageInstance
let notification: NotificationInstance
let modal: Omit<ModalStaticFunctions, 'warn'>

export default () => {
  const staticFunction = App.useApp()
  message = staticFunction.message
  modal = staticFunction.modal
  notification = staticFunction.notification
  return null
}

export { message, notification, modal }
