import { number } from 'mathjs';
import numeral from 'numeral';

export function toStringfiy(value: any) {
  try {
    return JSON.stringify(value, (key, value) => {
      if (typeof value === 'object' && value?.$$typeof) {
        // todo 获取JSX文本信息
        return {
          type: value.type,
          props: value.props
        };
      }
      return value;
    });
  } catch (e) {
    console.log(e);
    return '...';
  }
}

export function toPercent(value: number) {
  return numeral(value).format('0.00%');
}
