/**
 * 优化 fetch 读取
 * fetch support timeout
 */

import { isEmpty } from 'lodash-es';

export interface RefetchOptions extends Omit<RequestInit, 'body'> {
  headers?: Record<string, string>;
  body?: Row;
  responseType?: 'json' | 'text' | 'blob' | 'arrayBuffer' | 'formData';
}
export async function refetch(resource: string | URL | globalThis.Request, options: RefetchOptions = {}, timeout: number = 90 * 1000) {
  const { headers = {}, body, responseType = 'json', ...rest } = options;

  if (responseType === 'json') {
    headers['Content-Type'] = 'application/json';
  }

  const controller = new AbortController();
  const promise = fetch(resource, {
    headers,
    body: isEmpty(body) ? void 0 : JSON.stringify(body),
    ...rest,
    signal: controller.signal
  });

  let tid;
  // Return a race between our timeout and the request
  const response = timeout
    ? await promise
    : await Promise.race<Response>([
        promise,
        new Promise((_, reject) => {
          tid = setTimeout(() => {
            controller.abort();
            reject(new Error('Request timed out'));
          }, timeout);
        })
      ]);

  tid && clearTimeout(tid);

  if (responseType === 'json') {
    return await response.json();
  }

  // @todo 其它分会类型
  return response;
}
