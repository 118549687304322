import { isFalsy, isPowerFalsy, toFunc } from '@/v2/utils';
import { isEqual, isNil } from 'lodash-es';

export function run(func: () => void);
export function run(condition: any, truely: any);
export function run(condition: any, truely: any, falsy: any);
export function run(...args) {
  const [condition, truely, falsy] = args;
  if (args.length === 1) {
    return toFunc(truely)();
  }
  const con$value = toFunc(condition)();
  // todo isFalsy
  const con = !isFalsy(con$value);
  return con ? toFunc(truely)(condition) : toFunc(falsy)(condition);
}

/**
 * run power 与 run 的区别
 * - run 以 isFalsy 为判断条件, power 以 isPowerFalsy 为判断条件
 * - run 可以接受单个参数，其参数为函数, 此情况为闭包的简写；power 不支持
 * - run 的第一个参数为函数时，会运行其函数得到其的值，作为判断条件；power 则第一个函数丕支持函数
 */
export function runp(condition: any, truely: any, falsy?: any) {
  return !isPowerFalsy(condition, true) ? toFunc(truely)(condition) : toFunc(falsy)(condition);
}
run.and = (condition: any[], truely: any, falsy?: any) => {
  const hasFasly = condition.some((item) => isPowerFalsy(item));
  return !hasFasly ? toFunc(truely)(condition) : toFunc(falsy)(condition);
};

run.or = (condition: any[], truely: any, falsy?: any) => {
  const hasTruely = condition.some((item) => !isPowerFalsy(item));
  return hasTruely ? toFunc(truely)(condition) : toFunc(falsy)(condition);
};

run.eq = (conditon: [any, any], truely: any, falsy?: any) => {
  const [first, second] = conditon;
  const con = isEqual(first, second);
  return con ? toFunc(truely)(first, second) : toFunc(falsy)(first, second);
};

/**
 * if 系列方法
 */
export function ifexist(condition: any, truely: any, falsy?: any) {
  return !isNil(condition) ? toFunc(truely)(condition) : toFunc(falsy)(condition);
}
export function ifnil(condition: any, truely: any, falsy?: any) {
  return isNil(condition) ? toFunc(truely)(condition) : toFunc(falsy)(condition);
}
