import { upArray } from '@/utils';
import { get, isNil } from 'lodash-es';

export function toEntities(rows: Row | Rows, key: string) {
  const rows$ = upArray(rows);
  const entries = rows$.reduce((acc, row) => {
    const identiy = get(row, key);
    !isNil(identiy) && acc.push([identiy, row]);
    return acc;
  }, []);

  return entries.filter(Boolean);
}

/**
 * 将rows转为map,
 * 必须保证key是唯一的
 * 否则将丢失信息
 */
export function toMap(rows: Row | Rows, key: string) {
  const entries = toEntities(rows, key);
  return new Map(entries);
}
