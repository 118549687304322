/**
 * 用于数据处理
 */

import { mapping, upArray } from '@/utils';
import { camelCase, get, isEmpty, isNil, isPlainObject, map, omitBy, upperFirst } from 'lodash-es';

/**
 * 将key按规则解构
 * 如 lastCreateUserId 将解构成如下结构
 * [lastCreateUserId, LastCreateUserId, lastCreateUser, LastCreateUser, CreateUserId, createUserId, CreateUser, createUser, UserId, userId, User, user]
 */
export function deconstructingKey(key: string) {
  // 正则表达式用于匹配单词边界
  const regex = /([A-Z]?[a-z]+|[A-Z]+)/g;

  // 分离单词
  const words = key.match(regex) || [];
  const len = words.length;
  const words$ = words.map((key, inx) => {
    const keys = words.slice(inx, len);
    const str = keys.join('');
    return [upperFirst(str), camelCase(str)];
  });
  return words$.flat(Infinity) as string[];
}

/**
 * 判断源数据的类型，决定结果返回数据类型，并返回数据
 */
export function reorigin(rows: RRows, result: Rows) {
  const isArray = Array.isArray(rows);
  const results = upArray(result);
  return isArray ? results : result[0];
}

/**
 * 清理数据中 STATUS 字段
 * 清理字段中含有`::`的字段
 */
export function clearExtraData(rows: RRows) {
  if (isNil(rows)) return rows;
  if (Array.isArray(rows)) return map(rows, clearExtraData);
  if (isPlainObject(rows)) {
    const row = omitBy(rows, (value, key) => key.includes('::') || key === 'STATUS');
    return row;
  }
  return rows;
}

/**
 * 获取映射字段key配置规则
 */
export function mappingExtraKey(key: string) {
  return `${key}::entity`;
}

/**
 * 解构返回数据
 */
export function mappingExtraData(rows: RRows, mappingInfo: Row) {
  if (isNil(rows)) return rows;
  if (Array.isArray(rows)) {
    const data$ = mapping(rows, { key: 'key' });
    return map(data$, (item: Row) => mappingExtraData(item, mappingInfo));
  }
  const temp = {};
  Object.entries(rows).forEach(([key, value]) => {
    if (/Id$/.test(key) || /Ids$/.test(key)) {
      // @todo 处理数据和','的数据
      const value$ = typeof value === 'string' && /[,]/.test(value) ? value.split(',') : value;

      const dest = deconstructingKey(key);
      for (const key of dest) {
        const val$ = get(mappingInfo, `${key}.${value}`);
        if (!isEmpty(val$)) {
          const key$ = mappingExtraKey(key);
          temp[key$] = val$;
          break;
        }
      }
    }
  });
  return { ...rows, ...temp };
}

/**
 * 处理Response mapping 字段映射
 */
export function handleExtraData(response: Row) {
  if (!(isPlainObject(response) && !isEmpty(response?.mapping) && !isEmpty(response?.data))) return response;
  const { data, mapping: responseMapping, ...rest } = response;
  const data$ = mappingExtraData(data, responseMapping);
  return { ...rest, mapping: responseMapping, data: data$ };
}

export function extraMapping(key: string, data: Row = {}) {
  const key$ = mappingExtraKey(key);
  return get(data, key$);
}
