type MaxLength = { up: number; down: number } | number;

export function extractHighlightedText(inputString: string, maxLength: MaxLength): string {
  // 如果 maxLength 是数字，则转换为 { up: maxLength, down: maxLength }
  if (typeof maxLength === 'number') {
    maxLength = { up: maxLength, down: maxLength };
  }

  // 使��正则表达式查找所有高亮文本
  const highlightRegex = /<em class=(['"])highlight\1>(.*?)<\/em>/g;
  const matches = [...inputString.matchAll(highlightRegex)];

  if (matches.length === 0) {
    // 如果没有高亮文本，返回原字符串
    return inputString;
  }

  const firstMatch = matches[0];
  const startIndex = firstMatch.index;
  const endIndex = startIndex + firstMatch[0].length;

  // 向上截取
  let upStart = startIndex;
  while (upStart > 0) {
    if (/[,.，。]/.test(inputString[upStart - 1])) {
      break; // 遇到标点符号停止
    }
    upStart--;
    if (startIndex - upStart >= maxLength.up) break; // 限制长度
  }

  // 向下截取
  let downEnd = endIndex;
  let textLength = 0; // 用于计算实际文本长度
  while (downEnd < inputString.length) {
    // 只计算文本长度，忽略 HTML 标签
    if (inputString[downEnd] !== '<' && inputString[downEnd] !== '>') {
      textLength++;
    }
    if (textLength >= maxLength.down) break; // 限制长度
    downEnd++;
  }

  // 检查是否在截取范围内遇到高亮标签
  for (const match of matches) {
    const matchStart = match.index;
    const matchEnd = matchStart + match[0].length;

    // 如果高亮标签在截取范围内，调整截取范围
    if (matchStart < downEnd && matchEnd > upStart) {
      upStart = Math.min(upStart, matchStart);
      downEnd = Math.max(downEnd, matchEnd);
    }
  }

  // 检查最后一个字符是否为英文字符，如果是，则继续向下截取到下一个空格
  if (downEnd < inputString.length && /[a-zA-Z]$/.test(inputString.slice(upStart, downEnd))) {
    while (downEnd < inputString.length && inputString[downEnd] !== ' ') {
      downEnd++;
    }
  }

  // 提取最终字符串
  const result = inputString.slice(upStart, downEnd);
  return result; // 返回包含高亮文本的结果
}
