import { isPlainObject } from 'lodash-es';
import { omiting } from './mapping';

export function remove(obj: Row, key: string);
export function remove(obj: Row, key: string);
export function remove(arr: any[], index: number);
export function remove(arr: any[], obj: Row);
// todo
export function remove(arr: any[], predicate: (item: Row) => boolean);
export function remove(...args) {
  const [obj, keyOrIndex] = args;
  if (Array.isArray(obj)) {
    if (typeof keyOrIndex === 'number') {
      return obj.toSpliced(keyOrIndex, 1);
    }

    if (isPlainObject(keyOrIndex)) {
      return obj.filter((item) => {
        return !Object.entries(item).some(([key, value]) => keyOrIndex[key] === value);
      });
    }
  }
  return omiting(obj, keyOrIndex);
}
