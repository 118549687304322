/**
 * HOST接口
 * https://api.vnext.ailinkedlaw.com/index.html?urls.primaryName=Host
 */
import { Api } from './MyApi';
// import Request from './request'
import request from '@/api/request';

const api = new Api();
api.instance = request;
// AxiosInstance

export default api.api;
