export * from './up-array';
export * from './sleep';
export * from './to-is';
export * from './if';
export * from './mapping';
export * from './format2';
export * from '../v2/utils/to-try';
export * from './run';
export * from './to';
export * from './number';
export * from './math';
export * from './remove';

export function findRouteByName(routes, name) {
  for (const route of routes) {
    if (route.name === name) {
      return route;
    }
    if (route.children) {
      // @ts-ignore
      const found = findRouteByName(route.children, name);
      if (found) {
        return found;
      }
    }
  }
  return null; // 如果没有找到匹配的路由，返回null
}

// @ts-ignore
export function buildPath(routes, name, path = '') {
  for (const route of routes) {
    const currentPath = path + '/' + route.path;
    if (route.name === name) {
      return currentPath;
    }
    if (route.children) {
      // @ts-ignore
      const foundPath = buildPath(route.children, name, currentPath);
      if (foundPath) return foundPath;
    }
  }
  return null; // 如果没有找到匹配的路由，返回null
}

export function toChineseNumeral(num: string | number) {
  const numerals = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'];
  const units = ['', '拾', '佰', '仟'];
  const sections = ['', '万', '亿', '兆'];

  // eslint-disable-next-line prefer-const
  let [integer, decimal] = num.toString().split('.');
  let result = '';
  let sectionIndex = 0;

  while (integer.length > 0) {
    const part = integer.substr(-4);
    integer = integer.slice(0, -4);
    let partResult = '';
    let zeroFlag = false;

    for (let i = 0; i < part.length; i++) {
      const digit = parseInt(part[i]);
      if (digit !== 0) {
        if (zeroFlag) {
          partResult += numerals[0];
          zeroFlag = false;
        }
        partResult += numerals[digit] + units[part.length - 1 - i];
      } else {
        zeroFlag = true;
      }
    }

    if (partResult !== '') {
      result = partResult + sections[sectionIndex] + result;
    }
    sectionIndex++;
  }

  if (decimal) {
    result += '點';
    for (let i = 0; i < decimal.length; i++) {
      result += numerals[parseInt(decimal[i])];
    }
  }

  return result.replace(/零+$/, '');
}
