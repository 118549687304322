/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ARContactListItem {
  contactNo?: string | null
  /** 不公示 */
  emailAddress?: string | null
  /** 苏州市工业园区东长路88号C1幢5层503室 */
  address?: string | null
}

/** 访问控制 */
export interface AccessConfig {
  /** 允许IP地址区间访问 */
  isIpAreaAccess?: boolean
  /** IP地址区间 */
  ipAreaIntervals?: string[] | null
  /** 允许IP地址/网段访问 */
  isIpAddressNetworkSegment?: boolean
  /** IP地址/网段 */
  ipAddressNetworkSegments?: string[] | null
}

export interface ActualControl {
  personList?: PersonListItem[] | null
  stockPercent?: string | null
}

export interface ActualControlResultInfo {
  keyNo?: string | null
  companyName?: string | null
  updateTime?: string | null
  controllerData?: ControllerData
  actualControl?: ActualControl
  controllerDataList?: ControllerDataListItem[] | null
}

/** 通用返回信息类 */
export interface ActualControlResultInfoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  response?: ActualControlResultInfo
}

export interface ActualControllerListItem {
  keyNo?: string | null
  name?: string | null
  finalBenefitPercent?: string | null
  controlPercent?: string | null
  isActual?: string | null
}

export interface AdminLicenseListItem {
  /** 京网文xxxxxxxxxxx号 */
  licensDocNo?: string | null
  /** 网络文化经营许可证 */
  licensDocName?: string | null
  validityFrom?: string | null
  validityTo?: string | null
  /** 北京市文化和旅游局 */
  licensOffice?: string | null
  /** xxxx有限责任公司申请设立经营性互联网文化单位审批(延续) */
  licensContent?: string | null
  /** 信用中国 */
  source?: string | null
}

export interface AggregateRouteConfig {
  routeKey?: string | null
  parameter?: string | null
  jsonPath?: string | null
}

export interface ApproveSiteListItem {
  /** xxxx有限责任公司 */
  name?: string | null
  webAddress?: string | null
  domainName?: string | null
  /** 京ICP备xxxxxx号-7 */
  lesenceNo?: string | null
  auditDate?: string | null
}

export interface Area {
  /** 江苏省 */
  province?: string | null
  /** 苏州市 */
  city?: string | null
  /** 苏州工业园区 */
  county?: string | null
}

export interface AssignShow {
  permissionids?: string[] | null
  assignbtns?: string[] | null
}

/** 通用返回信息类 */
export interface AssignShowMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  response?: AssignShow
}

export interface AssignView {
  pids?: string[] | null
  rid?: string | null
}

export interface BankInfo {
  /** xxxx有限公司xxxxx支行 */
  bank?: string | null
  bankAccount?: string | null
  /** xxxx有限责任公司 */
  name?: string | null
  creditCode?: string | null
  /** 北京市海淀区西xxxxxxxxxxxxxxxx号 */
  address?: string | null
  tel?: string | null
}

/** Where 条件查询 */
export interface BaseWhereInput {
  /**
   * 筛选类型
   * @format int32
   */
  type?: 0 | 1 | 2 | null
  /** 传参名 */
  inputFieldName?: string | null
  /** 字段集 实际数据库查询字段 */
  fieldNames?: string[] | null
  /**
   * 默认等于   筛选条件
   * @format int32
   */
  conditionalType?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15
  /** 值 ， True False    数组则为 0,1,2 */
  value?: string | null
}

export interface BasicDetailsByNameResult {
  keyNo?: string | null
  /** xxxxxxx公司 */
  name?: string | null
  no?: string | null
  /** xxxxxxxxxxxx管理局 */
  belongOrg?: string | null
  operId?: string | null
  operName?: string | null
  startDate?: string | null
  endDate?: string | null
  /** 注销 */
  status?: string | null
  province?: string | null
  updatedDate?: string | null
  creditCode?: string | null
  registCapi?: string | null
  /** 全民所有制 */
  econKind?: string | null
  address?: string | null
  scope?: string | null
  termStart?: string | null
  termEnd?: string | null
  checkDate?: string | null
  orgNo?: string | null
  isOnStock?: string | null
  stockNumber?: string | null
  stockType?: string | null
  originalName?: OriginalName[] | null
  imageUrl?: string | null
  entType?: string | null
  recCap?: string | null
  revokeInfo?: RevokeInfo
  area?: Area
  areaCode?: string | null
}

/** 通用返回信息类 */
export interface BasicDetailsByNameResultMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  response?: BasicDetailsByNameResult
}

export interface Beneficiary {
  keyNo?: string | null
  name?: string | null
  finalBenefitPercent?: string | null
  /** 穿透识别出直接或间接拥有超过25%公司股权或者表决权的自然人 */
  reason?: string | null
}

export interface BindEmailInput {
  email?: string | null
  code?: string | null
}

export interface BindPhoneInput {
  /** 手机号 */
  phone?: string | null
  /** 验证码 */
  code?: string | null
}

/** 通用返回信息类 */
export interface BooleanMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 返回数据集合 */
  response?: boolean
}

export interface BranchListItem {
  keyNo?: string | null
  /** xxxx有限责任公司xxxx分公司 */
  name?: string | null
  operName?: string | null
  startDate?: string | null
  /** 存续 */
  status?: string | null
  area?: Area
}

export interface BranchsDto {
  /** 名称 */
  companyId?: string | null
  /** 注册号 */
  regNo?: string | null
  /** 名称 */
  name?: string | null
  /** 登记机关 */
  belongOrg?: string | null
  /** 统一社会信用代码( 保留字段) */
  creditCode?: string | null
  /** 法定代表人姓名（保留字段） */
  operName?: string | null
  enterpriseId?: string | null
}

/** 利冲规避偏好设置 */
export interface CSRAvoidPreferenceDto {
  /**
   * Desc:雪花Id
   * Default:
   * Nullable:False
   */
  id?: string | null
  /**
   * Desc:规避偏好关键字
   * Default:
   * Nullable:True
   */
  avoidKeyword?: string | null
  /**
   * Desc:规避利冲方类型
   * Default:
   * Nullable:True
   */
  patryType?: string | null
  /**
   * Desc:关键字规避方法（模糊/精确）
   * Default:
   * Nullable:True
   */
  avoidMethod?: string | null
  /**
   * Desc:规避开始时间
   * Default:
   * Nullable:False
   * @format date-time
   */
  startDate?: string
  /**
   * Desc:规避结束时间（空则长期生效）
   * Default:
   * Nullable:False
   * @format date-time
   */
  endTime?: string
  /**
   * Desc:附件信息
   * Default:
   * Nullable:True
   */
  attachments?: string | null
  /**
   * Desc:说明描述
   * Default:
   * Nullable:True
   */
  narrative?: string | null
  /**
   * Desc:租户ID
   * Default:0
   * Nullable:False
   */
  tenantId?: string | null
  /**
   * Desc:部门ID  DepartmentId
   * Default:0
   * Nullable:False
   */
  organizationUnitId?: string | null
  /**
   * Desc:分所Id
   * Default:0
   * Nullable:False
   */
  officeId?: string | null
  /**
   * Desc:备注
   * Default:
   * Nullable:True
   */
  remark?: string | null
  /**
   * Desc:状态 	                中立字段，某些表可使用某些表不使用
   * Default:1
   * Nullable:False
   */
  isEnabled?: boolean
  /**
   * Desc:中立字段，某些表可使用某些表不使用   	                逻辑上的删除，非物理删除  	                例如：单据删除并非直接删除
   * Default:0
   * Nullable:False
   */
  isDeleted?: boolean
  /**
   * Desc:中立字段 	                是否内置数据
   * Default:
   * Nullable:False
   */
  isInternal?: boolean
  /**
   * Desc:创建ID
   * Default:
   * Nullable:True
   */
  createId?: string | null
  /**
   * Desc:创建者
   * Default:
   * Nullable:True
   */
  createBy?: string | null
  /**
   * Desc:创建时间
   * Default:
   * Nullable:False
   * @format date-time
   */
  createTime?: string
  /**
   * Desc:修改ID
   * Default:
   * Nullable:True
   */
  modifyId?: string | null
  /**
   * Desc:更新者
   * Default:
   * Nullable:True
   */
  modifyBy?: string | null
  /**
   * Desc:修改日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  modifyTime?: string | null
  /**
   * Desc:数据版本
   * Default:0
   * Nullable:False
   */
  version?: string | null
  /**
   * Desc:删除ID
   * Default:
   * Nullable:True
   */
  deleteId?: string | null
  /**
   * Desc:删除者
   * Default:
   * Nullable:True
   */
  deleteBy?: string | null
  /**
   * Desc:删除日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  deleteTime?: string | null
}

/** 通用返回信息类 */
export interface CSRAvoidPreferenceDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 返回数据集合 */
  response?: CSRAvoidPreferenceDto[] | null
}

/** 通用返回信息类 */
export interface CSRAvoidPreferenceDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 利冲规避偏好设置 */
  response?: CSRAvoidPreferenceDto
}

/** 通用分页信息类 */
export interface CSRAvoidPreferenceDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number
  /** 返回数据 */
  data?: CSRAvoidPreferenceDto[] | null
}

/** 通用返回信息类 */
export interface CSRAvoidPreferenceDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 通用分页信息类 */
  response?: CSRAvoidPreferenceDtoPageModel
}

/** 利冲规避偏好结果表 */
export interface CSRAvoidPreferenceResultDto {
  /**
   * Desc:雪花Id
   * Default:
   * Nullable:False
   */
  id?: string | null
  /**
   * Desc:利冲规避编号
   * Default:
   * Nullable:False
   */
  csrAvoidID?: string | null
  /**
   * Desc:利冲规避关键词
   * Default:
   * Nullable:True
   */
  avoidKeyword?: string | null
  /**
   * Desc:关键词隶属规避方角色
   * Default:
   * Nullable:True
   */
  partyType?: string | null
  /**
   * Desc:规避冲突案件的角色
   * Default:
   * Nullable:True
   */
  caseParty?: string | null
  /**
   * Desc:规避案件冲突方名称
   * Default:
   * Nullable:True
   */
  casePartyName?: string | null
  /**
   * Desc:规避利冲关联业务类型（利冲/案件/入库/投标等）
   * Default:
   * Nullable:True
   */
  csrRelationType?: string | null
  /**
   * Desc:规避业务编号（默认案件）
   * Default:
   * Nullable:False
   */
  relationID?: string | null
  /**
   * Desc:冲突产生时关联业务状态
   * Default:
   * Nullable:True
   */
  relationStatus?: string | null
  /**
   * Desc:匹配客户名称
   * Default:
   * Nullable:True
   */
  clientName?: string | null
  /**
   * Desc:匹配客户外文名称
   * Default:
   * Nullable:True
   */
  clientForeignName?: string | null
  /**
   * Desc:匹配客户实控人名称
   * Default:
   * Nullable:True
   */
  clientShareholderActualCtrlName?: string | null
  /**
   * Desc:匹配客户实控人外文名称
   * Default:
   * Nullable:True
   */
  clientShareholderActualCtrlForeignName?: string | null
  /**
   * Desc:匹配对方名称
   * Default:
   * Nullable:True
   */
  oppositeName?: string | null
  /**
   * Desc:匹配对方外文名称
   * Default:
   * Nullable:True
   */
  oppositeForeignName?: string | null
  /**
   * Desc:匹配对方实控人名称
   * Default:
   * Nullable:True
   */
  oppositeShareholderActualCtrlName?: string | null
  /**
   * Desc:匹配对方实控人外文名称
   * Default:
   * Nullable:True
   */
  oppositeShareholderActualCtrlForeignName?: string | null
  /**
   * Desc:潜在第三方名称
   * Default:
   * Nullable:True
   */
  potential3rdPartyName?: string | null
  /**
   * Desc:潜在第三方外文名称
   * Default:
   * Nullable:True
   */
  potential3rdPartyForeignName?: string | null
  /**
   * Desc:关联业务类型
   * Default:
   * Nullable:True
   */
  relationCategory?: string | null
  /**
   * Desc:关联业务名称
   * Default:
   * Nullable:True
   */
  relationName?: string | null
  /**
   * Desc:关联业务申请时间
   * Default:
   * Nullable:False
   * @format date-time
   */
  relationRequestTime?: string
  /**
   * Desc:关联业务主管律师
   * Default:
   * Nullable:True
   */
  relationInChargeLawyerID?: string | null
  /**
   * Desc:关联业务申请合伙人
   * Default:
   * Nullable:False
   */
  relationRequestUserID?: string | null
  /**
   * Desc:关联业务协办律师
   * Default:
   * Nullable:True
   */
  relationCoWorkingLawyerID?: string | null
  /**
   * Desc:关联业务账单律师
   * Default:
   * Nullable:True
   */
  relationBillingLawyerID?: string | null
  /**
   * Desc:规避匹配方式（包含/忽略）
   * Default:
   * Nullable:True
   */
  matchingMethod?: string | null
  /**
   * Desc:租户ID
   * Default:0
   * Nullable:False
   */
  tenantId?: string | null
  /**
   * Desc:部门ID  DepartmentId
   * Default:0
   * Nullable:False
   */
  organizationUnitId?: string | null
  /**
   * Desc:分所Id
   * Default:0
   * Nullable:False
   */
  officeId?: string | null
  /**
   * Desc:备注
   * Default:
   * Nullable:True
   */
  remark?: string | null
  /**
   * Desc:状态 	                中立字段，某些表可使用某些表不使用
   * Default:1
   * Nullable:False
   */
  isEnabled?: boolean
  /**
   * Desc:中立字段，某些表可使用某些表不使用   	                逻辑上的删除，非物理删除  	                例如：单据删除并非直接删除
   * Default:0
   * Nullable:False
   */
  isDeleted?: boolean
  /**
   * Desc:中立字段 	                是否内置数据
   * Default:
   * Nullable:False
   */
  isInternal?: boolean
  /**
   * Desc:创建ID
   * Default:
   * Nullable:True
   */
  createId?: string | null
  /**
   * Desc:创建者
   * Default:
   * Nullable:True
   */
  createBy?: string | null
  /**
   * Desc:创建时间
   * Default:
   * Nullable:False
   * @format date-time
   */
  createTime?: string
  /**
   * Desc:修改ID
   * Default:
   * Nullable:True
   */
  modifyId?: string | null
  /**
   * Desc:更新者
   * Default:
   * Nullable:True
   */
  modifyBy?: string | null
  /**
   * Desc:修改日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  modifyTime?: string | null
  /**
   * Desc:数据版本
   * Default:0
   * Nullable:False
   */
  version?: string | null
  /**
   * Desc:删除ID
   * Default:
   * Nullable:True
   */
  deleteId?: string | null
  /**
   * Desc:删除者
   * Default:
   * Nullable:True
   */
  deleteBy?: string | null
  /**
   * Desc:删除日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  deleteTime?: string | null
}

/** 通用返回信息类 */
export interface CSRAvoidPreferenceResultDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 返回数据集合 */
  response?: CSRAvoidPreferenceResultDto[] | null
}

/** 通用返回信息类 */
export interface CSRAvoidPreferenceResultDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 利冲规避偏好结果表 */
  response?: CSRAvoidPreferenceResultDto
}

/** 通用分页信息类 */
export interface CSRAvoidPreferenceResultDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number
  /** 返回数据 */
  data?: CSRAvoidPreferenceResultDto[] | null
}

/** 通用返回信息类 */
export interface CSRAvoidPreferenceResultDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 通用分页信息类 */
  response?: CSRAvoidPreferenceResultDtoPageModel
}

/** 利冲豁免确认表 */
export interface CSRExemptionDto {
  /**
   * Desc:雪花Id
   * Default:
   * Nullable:False
   */
  id?: string | null
  /**
   * Desc:利冲编号
   * Default:
   * Nullable:False
   */
  csrid?: string | null
  /**
   * Desc:利冲关键词
   * Default:
   * Nullable:True
   */
  csrKeyword?: string | null
  /**
   * Desc:与我冲突案件的角色（冲突方）
   * Default:
   * Nullable:True
   */
  csrParty?: string | null
  /**
   * Desc:我的案件冲突角色
   * Default:
   * Nullable:True
   */
  caseParty?: string | null
  /**
   * Desc:我的案件冲突方名称
   * Default:
   * Nullable:True
   */
  casePartyName?: string | null
  /**
   * Desc:主管律师
   * Default:
   * Nullable:False
   */
  inChargeLawyer?: string | null
  /**
   * Desc:利冲关联业务类型（利冲/案件/入库/投标等）
   * Default:
   * Nullable:True
   */
  csrRelationType?: string | null
  /**
   * Desc:关联业务编号（默认案件）
   * Default:
   * Nullable:False
   */
  relationID?: string | null
  /**
   * Desc:冲突产生时关联业务状态
   * Default:
   * Nullable:True
   */
  relationStatus?: string | null
  /**
   * Desc:与我冲突业务编号合计
   * Default:
   * Nullable:True
   */
  csrRelationIDs?: string | null
  /**
   * Desc:利冲确认发送人编号
   * Default:
   * Nullable:False
   */
  csrSendUserID?: string | null
  /**
   * Desc:利冲确认发送时间
   * Default:
   * Nullable:False
   * @format date-time
   */
  csrSendTime?: string
  /**
   * Desc:利冲豁免确认时间
   * Default:
   * Nullable:False
   * @format date-time
   */
  confirmTime?: string
  /**
   * Desc:豁免文件
   * Default:
   * Nullable:True
   */
  attachments?: string | null
  /**
   * Desc:说明描述
   * Default:
   * Nullable:True
   */
  narrative?: string | null
  /**
   * Desc:租户ID
   * Default:0
   * Nullable:False
   */
  tenantId?: string | null
  /**
   * Desc:部门ID  DepartmentId
   * Default:0
   * Nullable:False
   */
  organizationUnitId?: string | null
  /**
   * Desc:分所Id
   * Default:0
   * Nullable:False
   */
  officeId?: string | null
  /**
   * Desc:备注
   * Default:
   * Nullable:True
   */
  remark?: string | null
  /**
   * Desc:状态 	                中立字段，某些表可使用某些表不使用
   * Default:1
   * Nullable:False
   */
  isEnabled?: boolean
  /**
   * Desc:中立字段，某些表可使用某些表不使用   	                逻辑上的删除，非物理删除  	                例如：单据删除并非直接删除
   * Default:0
   * Nullable:False
   */
  isDeleted?: boolean
  /**
   * Desc:中立字段 	                是否内置数据
   * Default:
   * Nullable:False
   */
  isInternal?: boolean
  /**
   * Desc:创建ID
   * Default:
   * Nullable:True
   */
  createId?: string | null
  /**
   * Desc:创建者
   * Default:
   * Nullable:True
   */
  createBy?: string | null
  /**
   * Desc:创建时间
   * Default:
   * Nullable:False
   * @format date-time
   */
  createTime?: string
  /**
   * Desc:修改ID
   * Default:
   * Nullable:True
   */
  modifyId?: string | null
  /**
   * Desc:更新者
   * Default:
   * Nullable:True
   */
  modifyBy?: string | null
  /**
   * Desc:修改日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  modifyTime?: string | null
  /**
   * Desc:数据版本
   * Default:0
   * Nullable:False
   */
  version?: string | null
  /**
   * Desc:删除ID
   * Default:
   * Nullable:True
   */
  deleteId?: string | null
  /**
   * Desc:删除者
   * Default:
   * Nullable:True
   */
  deleteBy?: string | null
  /**
   * Desc:删除日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  deleteTime?: string | null
}

/** 通用返回信息类 */
export interface CSRExemptionDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 返回数据集合 */
  response?: CSRExemptionDto[] | null
}

/** 通用返回信息类 */
export interface CSRExemptionDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 利冲豁免确认表 */
  response?: CSRExemptionDto
}

/** 通用分页信息类 */
export interface CSRExemptionDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number
  /** 返回数据 */
  data?: CSRExemptionDto[] | null
}

/** 通用返回信息类 */
export interface CSRExemptionDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 通用分页信息类 */
  response?: CSRExemptionDtoPageModel
}

/** 利冲预检结果表 */
export interface CSRPreProcessResultDto {
  /**
   * Desc:雪花Id
   * Default:
   * Nullable:False
   */
  id?: string | null
  /**
   * Desc:利冲预检编号
   * Default:
   * Nullable:False
   */
  preCSRID?: string | null
  /**
   * Desc:利冲关键词
   * Default:
   * Nullable:True
   */
  csrKeyword?: string | null
  /**
   * Desc:与我冲突案件的角色
   * Default:
   * Nullable:True
   */
  csrParty?: string | null
  /**
   * Desc:我被冲突案件的角色
   * Default:
   * Nullable:True
   */
  caseParty?: string | null
  /**
   * Desc:我的案件冲突方名称
   * Default:
   * Nullable:True
   */
  casePartyName?: string | null
  /**
   * Desc:利冲关联业务类型（利冲/案件/入库/投标等）
   * Default:
   * Nullable:True
   */
  csrRelationType?: string | null
  /**
   * Desc:关联业务编号（默认案件）
   * Default:
   * Nullable:False
   */
  relationID?: string | null
  /**
   * Desc:冲突产生时关联业务状态
   * Default:
   * Nullable:True
   */
  relationStatus?: string | null
  /**
   * Desc:匹配利冲规则
   * Default:
   * Nullable:True
   */
  matchingRule?: string | null
  /**
   * Desc:匹配客户名称
   * Default:
   * Nullable:True
   */
  clientName?: string | null
  /**
   * Desc:匹配客户外文名称
   * Default:
   * Nullable:True
   */
  clientForeignName?: string | null
  /**
   * Desc:匹配客户实控人名称
   * Default:
   * Nullable:True
   */
  clientShareholderActualCtrlName?: string | null
  /**
   * Desc:匹配客户实控人外文名称
   * Default:
   * Nullable:True
   */
  clientShareholderActualCtrlForeignName?: string | null
  /**
   * Desc:匹配对方名称
   * Default:
   * Nullable:True
   */
  oppositeName?: string | null
  /**
   * Desc:匹配对方外文名称
   * Default:
   * Nullable:True
   */
  oppositeForeignName?: string | null
  /**
   * Desc:匹配对方实控人名称
   * Default:
   * Nullable:True
   */
  oppositeShareholderActualCtrlName?: string | null
  /**
   * Desc:匹配对方实控人外文名称
   * Default:
   * Nullable:True
   */
  oppositeShareholderActualCtrlForeignName?: string | null
  /**
   * Desc:潜在第三方名称
   * Default:
   * Nullable:True
   */
  potential3rdPartyName?: string | null
  /**
   * Desc:潜在第三方外文名称
   * Default:
   * Nullable:True
   */
  potential3rdPartyForeignName?: string | null
  /**
   * Desc:关联业务类型
   * Default:
   * Nullable:True
   */
  relationCategory?: string | null
  /**
   * Desc:关联业务名称
   * Default:
   * Nullable:True
   */
  relationName?: string | null
  /**
   * Desc:关联业务申请时间
   * Default:
   * Nullable:False
   * @format date-time
   */
  relationRequestTime?: string | null
  /**
   * Desc:关联业务主管律师
   * Default:
   * Nullable:False
   */
  relationInChargeLawyerID?: string | null
  /**
   * Desc:关联业务申请合伙人
   * Default:
   * Nullable:False
   */
  relationRequestUserID?: string | null
  /** 关联业务案源人 */
  relationCaseUser?: string | null
  /**
   * Desc:租户ID
   * Default:0
   * Nullable:False
   */
  tenantId?: string | null
  /**
   * Desc:部门ID  DepartmentId
   * Default:0
   * Nullable:False
   */
  organizationUnitId?: string | null
  /**
   * Desc:分所Id
   * Default:0
   * Nullable:False
   */
  officeId?: string | null
  /**
   * Desc:备注
   * Default:
   * Nullable:True
   */
  remark?: string | null
  /**
   * Desc:状态 	                中立字段，某些表可使用某些表不使用
   * Default:1
   * Nullable:False
   */
  isEnabled?: boolean
  /**
   * Desc:中立字段，某些表可使用某些表不使用   	                逻辑上的删除，非物理删除  	                例如：单据删除并非直接删除
   * Default:0
   * Nullable:False
   */
  isDeleted?: boolean
  /**
   * Desc:中立字段 	                是否内置数据
   * Default:
   * Nullable:False
   */
  isInternal?: boolean
  /**
   * Desc:创建ID
   * Default:
   * Nullable:True
   */
  createId?: string | null
  /**
   * Desc:创建者
   * Default:
   * Nullable:True
   */
  createBy?: string | null
  /**
   * Desc:创建时间
   * Default:
   * Nullable:False
   * @format date-time
   */
  createTime?: string
  /**
   * Desc:修改ID
   * Default:
   * Nullable:True
   */
  modifyId?: string | null
  /**
   * Desc:更新者
   * Default:
   * Nullable:True
   */
  modifyBy?: string | null
  /**
   * Desc:修改日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  modifyTime?: string | null
  /**
   * Desc:数据版本
   * Default:0
   * Nullable:False
   */
  version?: string | null
  /**
   * Desc:删除ID
   * Default:
   * Nullable:True
   */
  deleteId?: string | null
  /**
   * Desc:删除者
   * Default:
   * Nullable:True
   */
  deleteBy?: string | null
  /**
   * Desc:删除日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  deleteTime?: string | null
}

/** 通用分页信息类 */
export interface CSRPreProcessResultDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number
  /** 返回数据 */
  data?: CSRPreProcessResultDto[] | null
}

/** 通用返回信息类 */
export interface CSRPreProcessResultDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 通用分页信息类 */
  response?: CSRPreProcessResultDtoPageModel
}

/** 利冲审查表 */
export interface CSRProcessDto {
  /**
   * Desc:利冲编号
   * Default:
   * Nullable:False
   */
  csrid?: string | null
  /**
   * Desc:检索模式（精确，模糊）
   * Default:
   * Nullable:True
   */
  searchMode?: string | null
  /**
   * Desc:我方名称
   * Default:
   * Nullable:True
   */
  ourPatryName?: string | null
  /**
   * Desc:我方外文名称
   * Default:
   * Nullable:True
   */
  ourPatryForeignName?: string | null
  /**
   * Desc:对方名称
   * Default:
   * Nullable:True
   */
  oppositeName?: string | null
  /**
   * Desc:对方外文名称
   * Default:
   * Nullable:True
   */
  oppositeForeignName?: string | null
  /**
   * Desc:潜在三方名称
   * Default:
   * Nullable:True
   */
  potential3rdPartyName?: string | null
  /**
   * Desc:潜在三方外文名称
   * Default:
   * Nullable:True
   */
  potential3rdPartyForeignName?: string | null
  /**
   * Desc:是否分词检索
   * Default:
   * Nullable:True
   */
  isSplitWordSearch?: string | null
  /**
   * Desc:是否外文分词检索
   * Default:
   * Nullable:True
   */
  isForeignSplitWordSearch?: string | null
  /**
   * Desc:匹配结果数量
   * Default:
   * Nullable:False
   * @format double
   */
  matchingCount?: number
  /**
   * Desc:需要豁免案件编号
   * Default:
   * Nullable:True
   */
  needExemptionCaseID?: string | null
  /**
   * Desc:利冲审查人编号
   * Default:
   * Nullable:False
   */
  processUserID?: string | null
  /**
   * Desc:利冲审查时间
   * Default:
   * Nullable:False
   * @format date-time
   */
  processTime?: string
  /**
   * Desc:说明描述
   * Default:
   * Nullable:True
   */
  narrative?: string | null
  /**
   * Desc:租户ID
   * Default:0
   * Nullable:False
   */
  tenantId?: string | null
  /**
   * Desc:部门ID  DepartmentId
   * Default:0
   * Nullable:False
   */
  organizationUnitId?: string | null
  /**
   * Desc:分所Id
   * Default:0
   * Nullable:False
   */
  officeId?: string | null
  /**
   * Desc:备注
   * Default:
   * Nullable:True
   */
  remark?: string | null
  /**
   * Desc:状态 	                中立字段，某些表可使用某些表不使用
   * Default:1
   * Nullable:False
   */
  isEnabled?: boolean
  /**
   * Desc:中立字段，某些表可使用某些表不使用   	                逻辑上的删除，非物理删除  	                例如：单据删除并非直接删除
   * Default:0
   * Nullable:False
   */
  isDeleted?: boolean
  /**
   * Desc:中立字段 	                是否内置数据
   * Default:
   * Nullable:False
   */
  isInternal?: boolean
  /**
   * Desc:创建ID
   * Default:
   * Nullable:True
   */
  createId?: string | null
  /**
   * Desc:创建者
   * Default:
   * Nullable:True
   */
  createBy?: string | null
  /**
   * Desc:修改ID
   * Default:
   * Nullable:True
   */
  modifyId?: string | null
  /**
   * Desc:更新者
   * Default:
   * Nullable:True
   */
  modifyBy?: string | null
  /**
   * Desc:修改日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  modifyTime?: string | null
  /**
   * Desc:数据版本
   * Default:0
   * Nullable:False
   */
  version?: string | null
  /**
   * Desc:删除ID
   * Default:
   * Nullable:True
   */
  deleteId?: string | null
  /**
   * Desc:删除者
   * Default:
   * Nullable:True
   */
  deleteBy?: string | null
  /**
   * Desc:删除日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  deleteTime?: string | null
  /** Id */
  id?: string | null
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string
}

/** 通用返回信息类 */
export interface CSRProcessDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 返回数据集合 */
  response?: CSRProcessDto[] | null
}

/** 通用返回信息类 */
export interface CSRProcessDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 利冲审查表 */
  response?: CSRProcessDto
}

/** 通用分页信息类 */
export interface CSRProcessDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number
  /** 返回数据 */
  data?: CSRProcessDto[] | null
}

/** 通用返回信息类 */
export interface CSRProcessDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 通用分页信息类 */
  response?: CSRProcessDtoPageModel
}

/** 利冲审查结果表 */
export interface CSRProcessResultDto {
  /**
   * Desc:雪花Id
   * Default:
   * Nullable:False
   */
  id?: string | null
  /**
   * Desc:利冲申请编号
   * Default:
   * Nullable:False
   */
  csrid?: string | null
  /**
   * Desc:利冲审查编号
   * Default:
   * Nullable:False
   */
  csrProcessID?: string | null
  /**
   * Desc:是否需要豁免
   * Default:
   * Nullable:True
   */
  isNeedExemption?: string | null
  /**
   * Desc:利冲关键词
   * Default:
   * Nullable:True
   */
  csrKeyword?: string | null
  /**
   * Desc:与我冲突案件的角色
   * Default:
   * Nullable:True
   */
  csrParty?: string | null
  /**
   * Desc:我被冲突案件的角色
   * Default:
   * Nullable:True
   */
  caseParty?: string | null
  /**
   * Desc:我的案件冲突方名称
   * Default:
   * Nullable:True
   */
  casePartyName?: string | null
  /**
   * Desc:利冲关联业务类型（利冲/案件/入库/投标等）
   * Default:
   * Nullable:True
   */
  csrRelationType?: string | null
  /**
   * Desc:关联业务编号（默认案件）
   * Default:
   * Nullable:False
   */
  relationID?: string | null
  /**
   * Desc:冲突产生时关联业务状态
   * Default:
   * Nullable:True
   */
  relationStatus?: string | null
  /**
   * Desc:利冲豁免结果
   * Default:
   * Nullable:True
   */
  exemptionResult?: string | null
  /**
   * Desc:匹配利冲规则
   * Default:
   * Nullable:True
   */
  matchingRule?: string | null
  /**
   * Desc:匹配客户名称
   * Default:
   * Nullable:True
   */
  clientName?: string | null
  /**
   * Desc:匹配客户外文名称
   * Default:
   * Nullable:True
   */
  clientForeignName?: string | null
  /**
   * Desc:匹配客户实控人名称
   * Default:
   * Nullable:True
   */
  clientShareholderActualCtrlName?: string | null
  /**
   * Desc:匹配客户实控人外文名称
   * Default:
   * Nullable:True
   */
  clientShareholderActualCtrlForeignName?: string | null
  /**
   * Desc:匹配对方名称
   * Default:
   * Nullable:True
   */
  oppositeName?: string | null
  /**
   * Desc:匹配对方外文名称
   * Default:
   * Nullable:True
   */
  oppositeForeignName?: string | null
  /**
   * Desc:匹配对方实控人名称
   * Default:
   * Nullable:True
   */
  oppositeShareholderActualCtrlName?: string | null
  /**
   * Desc:匹配对方实控人外文名称
   * Default:
   * Nullable:True
   */
  oppositeShareholderActualCtrlForeignName?: string | null
  /**
   * Desc:潜在第三方名称
   * Default:
   * Nullable:True
   */
  potential3rdPartyName?: string | null
  /**
   * Desc:潜在第三方外文名称
   * Default:
   * Nullable:True
   */
  potential3rdPartyForeignName?: string | null
  /**
   * Desc:关联业务类型
   * Default:
   * Nullable:True
   */
  relationCategory?: string | null
  /**
   * Desc:关联业务名称
   * Default:
   * Nullable:True
   */
  relationName?: string | null
  /**
   * Desc:关联业务申请时间
   * Default:
   * Nullable:False
   * @format date-time
   */
  relationRequestTime?: string
  /**
   * Desc:关联业务主管律师
   * Default:
   * Nullable:False
   */
  relationInChargeLawyerID?: string | null
  /**
   * Desc:关联业务申请合伙人
   * Default:
   * Nullable:False
   */
  relationRequestUserID?: string | null
  /**
   * Desc:租户ID
   * Default:0
   * Nullable:False
   */
  tenantId?: string | null
  /**
   * Desc:部门ID  DepartmentId
   * Default:0
   * Nullable:False
   */
  organizationUnitId?: string | null
  /**
   * Desc:分所Id
   * Default:0
   * Nullable:False
   */
  officeId?: string | null
  /**
   * Desc:备注
   * Default:
   * Nullable:True
   */
  remark?: string | null
  /**
   * Desc:状态 	                中立字段，某些表可使用某些表不使用
   * Default:1
   * Nullable:False
   */
  isEnabled?: boolean
  /**
   * Desc:中立字段，某些表可使用某些表不使用   	                逻辑上的删除，非物理删除  	                例如：单据删除并非直接删除
   * Default:0
   * Nullable:False
   */
  isDeleted?: boolean
  /**
   * Desc:中立字段 	                是否内置数据
   * Default:
   * Nullable:False
   */
  isInternal?: boolean
  /**
   * Desc:创建ID
   * Default:
   * Nullable:True
   */
  createId?: string | null
  /**
   * Desc:创建者
   * Default:
   * Nullable:True
   */
  createBy?: string | null
  /**
   * Desc:创建时间
   * Default:
   * Nullable:False
   * @format date-time
   */
  createTime?: string
  /**
   * Desc:修改ID
   * Default:
   * Nullable:True
   */
  modifyId?: string | null
  /**
   * Desc:更新者
   * Default:
   * Nullable:True
   */
  modifyBy?: string | null
  /**
   * Desc:修改日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  modifyTime?: string | null
  /**
   * Desc:数据版本
   * Default:0
   * Nullable:False
   */
  version?: string | null
  /**
   * Desc:删除ID
   * Default:
   * Nullable:True
   */
  deleteId?: string | null
  /**
   * Desc:删除者
   * Default:
   * Nullable:True
   */
  deleteBy?: string | null
  /**
   * Desc:删除日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  deleteTime?: string | null
}

/** 通用返回信息类 */
export interface CSRProcessResultDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 返回数据集合 */
  response?: CSRProcessResultDto[] | null
}

/** 通用返回信息类 */
export interface CSRProcessResultDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 利冲审查结果表 */
  response?: CSRProcessResultDto
}

/** 通用分页信息类 */
export interface CSRProcessResultDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number
  /** 返回数据 */
  data?: CSRProcessResultDto[] | null
}

/** 通用返回信息类 */
export interface CSRProcessResultDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 通用分页信息类 */
  response?: CSRProcessResultDtoPageModel
}

/** 利益冲突信息表 */
export interface CSRRequestDto {
  /**
   * 雪花Id
   * @format int64
   */
  id?: number
  /** 利冲案件名称 */
  name?: string | null
  /** 利冲案件类型 */
  category?: string | null
  /**
   * 关联案件编号
   * @format int64
   */
  relationCaseID?: number
  /** 客户行业编号 */
  clientIndustryID?: string | null
  /** 执业领域 */
  practiceArea?: string | null
  /** 是否涉密 */
  isClassified?: string | null
  /** 是否紧急 */
  isUrgent?: string | null
  /** 收费方式（固定，小时，风险，免费代理） */
  chargingMethods?: string | null
  /** 收费方式文本 */
  chargingMethodText?: string | null
  /**
   * 合同金额（费用总额）
   * @format double
   */
  contractAmount?: number
  /** 委托事项 */
  entrustedMatters?: string | null
  /** 客户名称 */
  clientName?: string | null
  /** 客户外文名称 */
  clientForeignName?: string | null
  /** 客户股东实控人名称 */
  clientShareholderActualCtrlName?: string | null
  /** 客户股东实控人外文名称 */
  clientShareholderActualCtrlForeignName?: string | null
  /** 客户参与实体名称 */
  clientParticipatingEntityName?: string | null
  /** 客户参与实体外文名称 */
  clientParticipatingEntityForeignName?: string | null
  /** 对方当事人 */
  oppositeName?: string | null
  /** 对方当事人外文名称 */
  oppositeForeignName?: string | null
  /** 对方股东实控人名称 */
  oppositeShareholderActualCtrlName?: string | null
  /** 对方股东实控人外文名称 */
  oppositeShareholderActualCtrlForeignName?: string | null
  /** 对方参与实体名称 */
  oppositeParticipatingEntityName?: string | null
  /** 对方参与实体外文名称 */
  oppositeParticipatingEntityForeignName?: string | null
  /** 潜在第三方名称 */
  potential3rdPartyName?: string | null
  /** 潜在第三方外文名称 */
  potential3rdPartyForeignName?: string | null
  /** 潜在第三方股东实控人 */
  potential3rdPartyShareholderActualCtrlName?: string | null
  /** 潜在第三方股东实控人外文名称 */
  potential3rdPartyShareholderActualCtrlForeignName?: string | null
  /** 主管律师（主办律师） */
  inChargeLawyer?: string | null
  /** 协办律师（合作律师） */
  coWorkingLawyer?: string | null
  /** 案源律师 */
  caseSourceLawyer?: string | null
  /** 账单律师 */
  billingLawyer?: string | null
  /** 秘书助理（预留）;选择人则限定选择人和值班秘书可见，不选就全部助理和秘书可见 */
  assistantSecretary?: string | null
  /** 说明描述 */
  narrative?: string | null
  /**
   * 利冲关联案件编号
   * Nullable:False
   * @format int64
   */
  caseID?: number
  /** 利冲关联案件名称 */
  caseName?: string | null
  /** 利冲关联案件类型 */
  caseCategory?: string | null
  /** 利冲关联案件状态 */
  caseStatus?: string | null
  /** 豁免文件 */
  exemptionAttachments?: string | null
  /** 附件 */
  attachments?: string | null
  /** 是否标的 */
  isTarget?: string | null
  /**
   * 标的金额
   * @format double
   */
  targetAmount?: number
  /** 利冲适用规则 */
  csrRule?: string | null
  /** 利冲时效是否过期 */
  isExpired?: string | null
  /**
   * 利冲审查进度
   * @format double
   */
  rateOfProgress?: number
  /**
   * 利冲时效剩余天数
   * @format double
   */
  remainingDays?: number
  /**
   * 租户ID
   * @format int64
   */
  tenantId?: number
  /**
   * 部门ID
   * @format int64
   */
  organizationUnitId?: number
  /**
   * 分所Id
   * @format int64
   */
  officeId?: number
  /** 备注 */
  remark?: string | null
  /** 状态 */
  isEnabled?: boolean
  /** 中立字段，某些表可使用某些表不使用 */
  isDeleted?: boolean
  /** 中立字段 */
  isInternal?: boolean
  /**
   * 创建ID
   * @format int64
   */
  createId?: number | null
  /** 创建者 */
  createBy?: string | null
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string
  /**
   * 修改ID
   * @format int64
   */
  modifyId?: number | null
  /** 更新者 */
  modifyBy?: string | null
  /**
   * 修改日期
   * @format date-time
   */
  modifyTime?: string | null
  /**
   * 数据版本
   * @format int64
   */
  version?: number
  /**
   * 删除ID
   * @format int64
   */
  deleteId?: number | null
  /** 删除者 */
  deleteBy?: string | null
  /**
   * 删除日期
   * @format date-time
   */
  deleteTime?: string | null
  /** 案件关联方信息表 */
  caseRelatedPartyDtos?: CaseRelatedPartyDto[] | null
  /** 案件参与律师表 */
  caseLawyerDtos?: CaseLawyerDto[] | null
}

/** 通用返回信息类 */
export interface CSRRequestDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 返回数据集合 */
  response?: CSRRequestDto[] | null
}

/** 通用返回信息类 */
export interface CSRRequestDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 利益冲突信息表 */
  response?: CSRRequestDto
}

/** 通用分页信息类 */
export interface CSRRequestDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number
  /** 返回数据 */
  data?: CSRRequestDto[] | null
}

/** 通用返回信息类 */
export interface CSRRequestDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 通用分页信息类 */
  response?: CSRRequestDtoPageModel
}

/** 案件受理机构信息表 */
export interface CaseAcceptAgencyDto {
  /**
   * Desc:雪花Id
   * Default:
   * Nullable:False
   */
  id?: string | null
  /**
   * Desc:案件或利冲编号
   * Default:0
   * Nullable:True
   */
  caseId?: string | null
  /**
   * Desc:机构名称
   * Default:
   * Nullable:True
   */
  name?: string | null
  /**
   * Desc:机构类别（法院/检察院/监狱等）
   * Default:
   * Nullable:True
   */
  agencyType?: string | null
  /**
   * Desc:开始时间
   * Default:
   * Nullable:False
   * @format date-time
   */
  startTime?: string
  /**
   * Desc:结束时间
   * Default:
   * Nullable:False
   * @format date-time
   */
  endTime?: string
  /**
   * Desc:事由分类
   * Default:
   * Nullable:True
   */
  category?: string | null
  /**
   * Desc:案件阶段
   * Default:
   * Nullable:True
   */
  stage?: string | null
  /**
   * Desc:联系人
   * Default:
   * Nullable:True
   */
  linker?: string | null
  /**
   * Desc:联系电话
   * Default:
   * Nullable:True
   */
  phoneNumber?: string | null
  /**
   * Desc:关联法院编号
   * Default:0
   * Nullable:True
   */
  courtId?: string | null
  /**
   * Desc:租户ID
   * Default:0
   * Nullable:False
   */
  tenantId?: string | null
  /**
   * Desc:部门ID  DepartmentId
   * Default:0
   * Nullable:False
   */
  organizationUnitId?: string | null
  /**
   * Desc:分所Id
   * Default:0
   * Nullable:False
   */
  officeId?: string | null
  /**
   * Desc:备注
   * Default:
   * Nullable:True
   */
  remark?: string | null
  /**
   * Desc:状态 	            中立字段，某些表可使用某些表不使用
   * Default:1
   * Nullable:False
   */
  isEnabled?: boolean
  /**
   * Desc:中立字段，某些表可使用某些表不使用   	            逻辑上的删除，非物理删除  	            例如：单据删除并非直接删除
   * Default:0
   * Nullable:False
   */
  isDeleted?: boolean
  /**
   * Desc:中立字段 	            是否内置数据
   * Default:
   * Nullable:False
   */
  isInternal?: boolean
  /**
   * Desc:创建ID
   * Default:
   * Nullable:True
   */
  createId?: string | null
  /**
   * Desc:创建者
   * Default:
   * Nullable:True
   */
  createBy?: string | null
  /**
   * Desc:创建时间
   * Default:
   * Nullable:False
   * @format date-time
   */
  createTime?: string
  /**
   * Desc:修改ID
   * Default:
   * Nullable:True
   */
  modifyId?: string | null
  /**
   * Desc:更新者
   * Default:
   * Nullable:True
   */
  modifyBy?: string | null
  /**
   * Desc:修改日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  modifyTime?: string | null
  /**
   * Desc:数据版本
   * Default:0
   * Nullable:False
   */
  version?: string | null
  /**
   * Desc:删除ID
   * Default:
   * Nullable:True
   */
  deleteId?: string | null
  /**
   * Desc:删除者
   * Default:
   * Nullable:True
   */
  deleteBy?: string | null
  /**
   * Desc:删除日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  deleteTime?: string | null
}

/** 通用返回信息类 */
export interface CaseAcceptAgencyDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 返回数据集合 */
  response?: CaseAcceptAgencyDto[] | null
}

/** 通用返回信息类 */
export interface CaseAcceptAgencyDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 案件受理机构信息表 */
  response?: CaseAcceptAgencyDto
}

/** 通用分页信息类 */
export interface CaseAcceptAgencyDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number
  /** 返回数据 */
  data?: CaseAcceptAgencyDto[] | null
}

/** 通用返回信息类 */
export interface CaseAcceptAgencyDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 通用分页信息类 */
  response?: CaseAcceptAgencyDtoPageModel
}

/** 案件结案表（撤案/销案） */
export interface CaseClosedDto {
  /**
   * Desc:雪花Id
   * Default:
   * Nullable:False
   */
  id?: string | null
  /**
   * Desc:案件编号
   * Default:0
   * Nullable:True
   */
  caseId?: string | null
  /**
   * Desc:裁判类型（判决/裁定/调解/其他）
   * Default:
   * Nullable:True
   */
  refereeType?: string | null
  /**
   * Desc:受理机构（法院名称）
   * Default:
   * Nullable:True
   */
  court?: string | null
  /**
   * Desc:裁判文书日期
   * Default:
   * Nullable:False
   * @format date-time
   */
  judgmentDate?: string
  /**
   * Desc:裁判文书号
   * Default:
   * Nullable:True
   */
  judgmentNo?: string | null
  /**
   * Desc:送达日期
   * Default:
   * Nullable:False
   * @format date-time
   */
  deliveryDate?: string
  /**
   * Desc:是否机密案卷
   * Default:
   * Nullable:False
   */
  confidentialVolume?: boolean
  /**
   * Desc:结案时收费额是否与委托协议一致
   * Default:
   * Nullable:False
   */
  isConsistentCharge?: boolean
  /**
   * Desc:起诉日期
   * Default:
   * Nullable:False
   * @format date-time
   */
  sueDate?: string
  /**
   * Desc:阅卷日期
   * Default:
   * Nullable:False
   * @format date-time
   */
  examDate?: string
  /**
   * Desc:初次会见/谈话日期
   * Default:
   * Nullable:False
   * @format date-time
   */
  meetDate?: string
  /**
   * Desc:首次开庭日期
   * Default:
   * Nullable:False
   * @format date-time
   */
  courtDate?: string
  /**
   * Desc:简要案情和工作概况
   * Default:
   * Nullable:True
   */
  result?: string | null
  /**
   * Desc:处理结果
   * Default:
   * Nullable:True
   */
  pocessResult?: string | null
  /**
   * Desc:律师办案体会
   * Default:
   * Nullable:True
   */
  experience?: string | null
  /**
   * Desc:代理事项
   * Default:
   * Nullable:True
   */
  discussion?: string | null
  /**
   * Desc:结案必要文件夹
   * Default:
   * Nullable:True
   */
  folders?: string | null
  /**
   * Desc:归档人编号
   * Default:0
   * Nullable:True
   */
  archiveUserId?: string | null
  /**
   * Desc:归档日期
   * Default:
   * Nullable:False
   * @format date-time
   */
  archiveDate?: string
  /**
   * Desc:归档编号
   * Default:
   * Nullable:True
   */
  archiveId?: string | null
  /**
   * Desc:归档状态
   * Default:
   * Nullable:True
   */
  archiveStatus?: string | null
  /**
   * Desc:服务结果（中止/终止/存续）
   * Default:
   * Nullable:True
   */
  serviceResult?: string | null
  /**
   * Desc:电子档案编号
   * Default:
   * Nullable:True
   */
  electronicFileNo?: string | null
  /**
   * Desc:是否扫描件
   * Default:
   * Nullable:False
   */
  isScanFile?: boolean
  /**
   * Desc:是否逾期结案
   * Default:
   * Nullable:False
   */
  isOverdueFiling?: boolean
  /**
   * Desc:逾期结案原因
   * Default:
   * Nullable:True
   */
  overdueFilingReason?: string | null
  /**
   * Desc:逾期天数
   * Default:
   * Nullable:False
   * @format double
   */
  overdueDays?: number
  /**
   * Desc:归档地
   * Default:
   * Nullable:True
   */
  archivePlace?: string | null
  /**
   * Desc:租户ID
   * Default:0
   * Nullable:False
   */
  tenantId?: string | null
  /**
   * Desc:部门ID  DepartmentId
   * Default:0
   * Nullable:False
   */
  organizationUnitId?: string | null
  /**
   * Desc:分所Id
   * Default:0
   * Nullable:False
   */
  officeId?: string | null
  /**
   * Desc:备注
   * Default:
   * Nullable:True
   */
  remark?: string | null
  /**
   * Desc:状态 	            中立字段，某些表可使用某些表不使用
   * Default:1
   * Nullable:False
   */
  isEnabled?: boolean
  /**
   * Desc:中立字段，某些表可使用某些表不使用   	            逻辑上的删除，非物理删除  	            例如：单据删除并非直接删除
   * Default:0
   * Nullable:False
   */
  isDeleted?: boolean
  /**
   * Desc:中立字段 	            是否内置数据
   * Default:
   * Nullable:False
   */
  isInternal?: boolean
  /**
   * Desc:创建ID
   * Default:
   * Nullable:True
   */
  createId?: string | null
  /**
   * Desc:创建者
   * Default:
   * Nullable:True
   */
  createBy?: string | null
  /**
   * Desc:创建时间
   * Default:
   * Nullable:False
   * @format date-time
   */
  createTime?: string
  /**
   * Desc:修改ID
   * Default:
   * Nullable:True
   */
  modifyId?: string | null
  /**
   * Desc:更新者
   * Default:
   * Nullable:True
   */
  modifyBy?: string | null
  /**
   * Desc:修改日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  modifyTime?: string | null
  /**
   * Desc:数据版本
   * Default:0
   * Nullable:False
   */
  version?: string | null
  /**
   * Desc:删除ID
   * Default:
   * Nullable:True
   */
  deleteId?: string | null
  /**
   * Desc:删除者
   * Default:
   * Nullable:True
   */
  deleteBy?: string | null
  /**
   * Desc:删除日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  deleteTime?: string | null
}

/** 通用返回信息类 */
export interface CaseClosedDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 返回数据集合 */
  response?: CaseClosedDto[] | null
}

/** 通用返回信息类 */
export interface CaseClosedDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 案件结案表（撤案/销案） */
  response?: CaseClosedDto
}

/** 通用分页信息类 */
export interface CaseClosedDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number
  /** 返回数据 */
  data?: CaseClosedDto[] | null
}

/** 通用返回信息类 */
export interface CaseClosedDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 通用分页信息类 */
  response?: CaseClosedDtoPageModel
}

/** 案件收款信息表 */
export interface CaseCollectionDto {
  /**
   * Desc:雪花Id
   * Default:
   * Nullable:False
   */
  id?: string | null
  /**
   * Desc:案件编号
   * Default:0
   * Nullable:True
   */
  caseId?: string | null
  /**
   * Desc:收费方式
   * Default:
   * Nullable:True
   */
  chargingMethod?: string | null
  /**
   * Desc:收费金额
   * Default:
   * Nullable:False
   * @format double
   */
  amount?: number
  /**
   * Desc:基础风险金额
   * Default:
   * Nullable:False
   * @format double
   */
  riskBasicAmount?: number
  /**
   * Desc:说明描述/风险达成条件
   * Default:
   * Nullable:True
   */
  narrative?: string | null
  /**
   * Desc:是否可收款
   * Default:
   * Nullable:True
   */
  isAcceptable?: string | null
  /**
   * Desc:是否使用账单
   * Default:
   * Nullable:True
   */
  isUseBilling?: string | null
  /**
   * Desc:收费日期
   * Default:
   * Nullable:False
   * @format date-time
   */
  chargeDate?: string
  /**
   * Desc:是否已到账
   * Default:
   * Nullable:True
   */
  isArrived?: string | null
  /**
   * Desc:到账日期
   * Default:
   * Nullable:False
   * @format date-time
   */
  arrivedDate?: string
  /**
   * Desc:到账金额
   * Default:
   * Nullable:False
   * @format double
   */
  arrivedAmount?: number
  /**
   * Desc:币种
   * Default:
   * Nullable:True
   */
  currency?: string | null
  /**
   * Desc:汇率
   * Default:
   * Nullable:False
   * @format double
   */
  exchangeRate?: number
  /**
   * Desc:是否账单
   * Default:
   * Nullable:True
   */
  isBilling?: string | null
  /**
   * Desc:关联账单编号
   * Default:0
   * Nullable:True
   */
  billingID?: string | null
  /**
   * Desc:付款比例
   * Default:
   * Nullable:False
   * @format double
   */
  chargeRatio?: number
  /**
   * Desc:开始时间
   * Default:
   * Nullable:False
   * @format date-time
   */
  startDate?: string
  /**
   * Desc:结束时间
   * Default:
   * Nullable:False
   * @format date-time
   */
  endDate?: string
  /**
   * Desc:是否提醒
   * Default:
   * Nullable:True
   */
  isRemind?: string | null
  /**
   * Desc:提醒时间
   * Default:
   * Nullable:False
   * @format date-time
   */
  remindTime?: string
  /**
   * Desc:风险收费比例
   * Default:
   * Nullable:False
   * @format double
   */
  riskRatio?: number
  /**
   * Desc:关联案件编号
   * Default:0
   * Nullable:True
   */
  relationCaseID?: string | null
  /**
   * Desc:外币收费金额
   * Default:
   * Nullable:False
   * @format double
   */
  foreignCurrencyAmount?: number
  /**
   * Desc:租户ID
   * Default:0
   * Nullable:False
   */
  tenantId?: string | null
  /**
   * Desc:部门ID  DepartmentId
   * Default:0
   * Nullable:False
   */
  organizationUnitId?: string | null
  /**
   * Desc:分所Id
   * Default:0
   * Nullable:False
   */
  officeId?: string | null
  /**
   * Desc:备注
   * Default:
   * Nullable:True
   */
  remark?: string | null
  /**
   * Desc:状态 	            中立字段，某些表可使用某些表不使用
   * Default:1
   * Nullable:False
   */
  isEnabled?: boolean
  /**
   * Desc:中立字段，某些表可使用某些表不使用   	            逻辑上的删除，非物理删除  	            例如：单据删除并非直接删除
   * Default:0
   * Nullable:False
   */
  isDeleted?: boolean
  /**
   * Desc:中立字段 	            是否内置数据
   * Default:
   * Nullable:False
   */
  isInternal?: boolean
  /**
   * Desc:创建ID
   * Default:
   * Nullable:True
   */
  createId?: string | null
  /**
   * Desc:创建者
   * Default:
   * Nullable:True
   */
  createBy?: string | null
  /**
   * Desc:创建时间
   * Default:
   * Nullable:False
   * @format date-time
   */
  createTime?: string
  /**
   * Desc:修改ID
   * Default:
   * Nullable:True
   */
  modifyId?: string | null
  /**
   * Desc:更新者
   * Default:
   * Nullable:True
   */
  modifyBy?: string | null
  /**
   * Desc:修改日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  modifyTime?: string | null
  /**
   * Desc:数据版本
   * Default:0
   * Nullable:False
   */
  version?: string | null
  /**
   * Desc:删除ID
   * Default:
   * Nullable:True
   */
  deleteId?: string | null
  /**
   * Desc:删除者
   * Default:
   * Nullable:True
   */
  deleteBy?: string | null
  /**
   * Desc:删除日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  deleteTime?: string | null
}

/** 通用返回信息类 */
export interface CaseCollectionDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 返回数据集合 */
  response?: CaseCollectionDto[] | null
}

/** 通用返回信息类 */
export interface CaseCollectionDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 案件收款信息表 */
  response?: CaseCollectionDto
}

/** 通用分页信息类 */
export interface CaseCollectionDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number
  /** 返回数据 */
  data?: CaseCollectionDto[] | null
}

/** 通用返回信息类 */
export interface CaseCollectionDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 通用分页信息类 */
  response?: CaseCollectionDtoPageModel
}

/** 案件分配表 */
export interface CaseDistributionDto {
  /**
   * Desc:雪花Id
   * Default:
   * Nullable:False
   */
  id?: string | null
  /**
   * Desc:案件编号
   * Default:0
   * Nullable:True
   */
  caseId?: string | null
  /**
   * Desc:用户编号
   * Default:0
   * Nullable:True
   */
  userId?: string | null
  /**
   * Desc:律师角色
   * Default:
   * Nullable:True
   */
  lawyerRole?: string | null
  /**
   * Desc:分配金额
   * Default:
   * Nullable:False
   * @format double
   */
  allocationAmount?: number
  /**
   * Desc:分配比例
   * Default:
   * Nullable:False
   * @format double
   */
  allocationRate?: number
  /**
   * Desc:尾差金额
   * Default:
   * Nullable:False
   * @format double
   */
  tailAmount?: number
  /**
   * Desc:是否案源费
   * Default:
   * Nullable:False
   */
  isSourceFee?: boolean
  /**
   * Desc:是否默认策略
   * Default:
   * Nullable:False
   */
  isDefault?: boolean
  /**
   * Desc:租户ID
   * Default:0
   * Nullable:False
   */
  tenantId?: string | null
  /**
   * Desc:部门ID  DepartmentId
   * Default:0
   * Nullable:False
   */
  organizationUnitId?: string | null
  /**
   * Desc:分所Id
   * Default:0
   * Nullable:False
   */
  officeId?: string | null
  /**
   * Desc:备注
   * Default:
   * Nullable:True
   */
  remark?: string | null
  /**
   * Desc:状态 	            中立字段，某些表可使用某些表不使用
   * Default:1
   * Nullable:False
   */
  isEnabled?: boolean
  /**
   * Desc:中立字段，某些表可使用某些表不使用   	            逻辑上的删除，非物理删除  	            例如：单据删除并非直接删除
   * Default:0
   * Nullable:False
   */
  isDeleted?: boolean
  /**
   * Desc:中立字段 	            是否内置数据
   * Default:
   * Nullable:False
   */
  isInternal?: boolean
  /**
   * Desc:创建ID
   * Default:
   * Nullable:True
   */
  createId?: string | null
  /**
   * Desc:创建者
   * Default:
   * Nullable:True
   */
  createBy?: string | null
  /**
   * Desc:创建时间
   * Default:
   * Nullable:False
   * @format date-time
   */
  createTime?: string
  /**
   * Desc:修改ID
   * Default:
   * Nullable:True
   */
  modifyId?: string | null
  /**
   * Desc:更新者
   * Default:
   * Nullable:True
   */
  modifyBy?: string | null
  /**
   * Desc:修改日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  modifyTime?: string | null
  /**
   * Desc:数据版本
   * Default:0
   * Nullable:False
   */
  version?: string | null
  /**
   * Desc:删除ID
   * Default:
   * Nullable:True
   */
  deleteId?: string | null
  /**
   * Desc:删除者
   * Default:
   * Nullable:True
   */
  deleteBy?: string | null
  /**
   * Desc:删除日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  deleteTime?: string | null
}

/** 通用返回信息类 */
export interface CaseDistributionDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 返回数据集合 */
  response?: CaseDistributionDto[] | null
}

/** 通用返回信息类 */
export interface CaseDistributionDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 案件分配表 */
  response?: CaseDistributionDto
}

/** 通用分页信息类 */
export interface CaseDistributionDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number
  /** 返回数据 */
  data?: CaseDistributionDto[] | null
}

/** 通用返回信息类 */
export interface CaseDistributionDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 通用分页信息类 */
  response?: CaseDistributionDtoPageModel
}

/** 案件参与律师表 */
export interface CaseLawyerDto {
  /**
   * Desc:雪花Id
   * Default:
   * Nullable:False
   */
  id?: string | null
  /**
   * Desc:案件编号
   * Default:0
   * Nullable:True
   */
  caseId?: string | null
  /**
   * Desc:用户编号
   * Default:0
   * Nullable:True
   */
  userId?: string | null
  /**
   * Desc:律师参案角色
   * Default:
   * Nullable:True
   */
  lawyerRole?: string | null
  /**
   * Desc:律师费率
   * Default:
   * Nullable:False
   * @format double
   */
  chargeRatio?: number
  /**
   * Desc:服务开始时间
   * Default:
   * Nullable:False
   * @format date-time
   */
  startDate?: string
  /**
   * Desc:服务结束时间
   * Default:
   * Nullable:False
   * @format date-time
   */
  endDate?: string
  /**
   * Desc:是否非当前服务律师
   * Default:
   * Nullable:False
   */
  isInActive?: boolean
  /**
   * Desc:排序编号
   * Default:
   * Nullable:False
   * @format double
   */
  sort?: number
  /**
   * Desc:租户ID
   * Default:0
   * Nullable:False
   */
  tenantId?: string | null
  /**
   * Desc:部门ID  DepartmentId
   * Default:0
   * Nullable:False
   */
  organizationUnitId?: string | null
  /**
   * Desc:分所Id
   * Default:0
   * Nullable:False
   */
  officeId?: string | null
  /**
   * Desc:备注
   * Default:
   * Nullable:True
   */
  remark?: string | null
  /**
   * Desc:状态 	            中立字段，某些表可使用某些表不使用
   * Default:1
   * Nullable:False
   */
  isEnabled?: boolean
  /**
   * Desc:中立字段，某些表可使用某些表不使用   	            逻辑上的删除，非物理删除  	            例如：单据删除并非直接删除
   * Default:0
   * Nullable:False
   */
  isDeleted?: boolean
  /**
   * Desc:中立字段 	            是否内置数据
   * Default:
   * Nullable:False
   */
  isInternal?: boolean
  /**
   * Desc:创建ID
   * Default:
   * Nullable:True
   */
  createId?: string | null
  /**
   * Desc:创建者
   * Default:
   * Nullable:True
   */
  createBy?: string | null
  /**
   * Desc:创建时间
   * Default:
   * Nullable:False
   * @format date-time
   */
  createTime?: string
  /**
   * Desc:修改ID
   * Default:
   * Nullable:True
   */
  modifyId?: string | null
  /**
   * Desc:更新者
   * Default:
   * Nullable:True
   */
  modifyBy?: string | null
  /**
   * Desc:修改日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  modifyTime?: string | null
  /**
   * Desc:数据版本
   * Default:0
   * Nullable:False
   * @format int64
   */
  version?: number
  /**
   * Desc:删除ID
   * Default:
   * Nullable:True
   */
  deleteId?: string | null
  /**
   * Desc:删除者
   * Default:
   * Nullable:True
   */
  deleteBy?: string | null
  /**
   * Desc:删除日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  deleteTime?: string | null
  /**
   * Desc:邮件地址
   * Default:
   * Nullable:True
   */
  emailAddress?: string | null
}

/** 通用返回信息类 */
export interface CaseLawyerDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 返回数据集合 */
  response?: CaseLawyerDto[] | null
}

/** 通用返回信息类 */
export interface CaseLawyerDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 案件参与律师表 */
  response?: CaseLawyerDto
}

/** 通用分页信息类 */
export interface CaseLawyerDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number
  /** 返回数据 */
  data?: CaseLawyerDto[] | null
}

/** 通用返回信息类 */
export interface CaseLawyerDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 通用分页信息类 */
  response?: CaseLawyerDtoPageModel
}

/** 案件信息表 */
export interface CaseMatterDto {
  /**
   * Desc:雪花Id
   * Default:
   * Nullable:False
   */
  id?: string | null
  /**
   * Desc:利冲预检编号
   * Default:0
   * Nullable:True
   */
  preCSRID?: string | null
  /**
   * Desc:利冲申请编号
   * Default:0
   * Nullable:True
   */
  csrid?: string | null
  /**
   * Desc:案件类型
   * Default:
   * Nullable:True
   */
  category?: string | null
  /**
   * Desc:案由分类
   * Default:
   * Nullable:True
   */
  reason?: string | null
  /**
   * Desc:补充案由
   * Default:
   * Nullable:True
   */
  reasonSupplement?: string | null
  /**
   * Desc:关联案件编号
   * Default:0
   * Nullable:True
   */
  relationCaseID?: string | null
  /**
   * Desc:案件名称
   * Default:
   * Nullable:True
   */
  name?: string | null
  /**
   * Desc:案件名称注释备注
   * Default:
   * Nullable:True
   */
  nameNote?: string | null
  /**
   * Desc:客户行业编号
   * Default:
   * Nullable:True
   */
  clientIndustryID?: string | null
  /**
   * Desc:执业领域
   * Default:
   * Nullable:True
   */
  practiceArea?: string | null
  /**
   * Desc:是否涉密
   * Default:
   * Nullable:True
   */
  isClassified?: string | null
  /**
   * Desc:是否紧急
   * Default:
   * Nullable:True
   */
  isUrgent?: string | null
  /**
   * Desc:收费方式（固定，小时，风险，免费代理）
   * Default:
   * Nullable:True
   */
  chargingMethods?: string | null
  /**
   * Desc:收费方式文本
   * Default:
   * Nullable:True
   */
  chargingMethodText?: string | null
  /**
   * Desc:合同金额（费用总额）
   * Default:
   * Nullable:False
   * @format double
   */
  contractAmount?: number
  /**
   * Desc:委托事项
   * Default:
   * Nullable:True
   */
  entrustedMatters?: string | null
  /**
   * Desc:书写语言
   * Default:
   * Nullable:True
   */
  writtenLanguage?: string | null
  /**
   * Desc:收案日期
   * Default:
   * Nullable:False
   * @format date-time
   */
  acceptDate?: string
  /**
   * Desc:案件来源
   * Default:
   * Nullable:True
   */
  caseSource?: string | null
  /**
   * Desc:委托阶段
   * Default:
   * Nullable:True
   */
  stages?: string | null
  /**
   * Desc:当前阶段
   * Default:
   * Nullable:True
   */
  currentStage?: string | null
  /**
   * Desc:受理机构
   * Default:
   * Nullable:True
   */
  acceptAgency?: string | null
  /**
   * Desc:受理机构案号
   * Default:
   * Nullable:True
   */
  acceptCaseNo?: string | null
  /**
   * Desc:受理机构级别（法院级别）
   * Default:
   * Nullable:True
   */
  acceptAgencyLevel?: string | null
  /**
   * Desc:特殊案件类型
   * Default:
   * Nullable:True
   */
  specialType?: string | null
  /**
   * Desc:是否跨所合作案件
   * Default:
   * Nullable:True
   */
  isInterbankCooperation?: string | null
  /**
   * Desc:合作分所
   * Default:0
   * Nullable:True
   */
  cooperationOffice?: string | null
  /**
   * Desc:合作律师
   * Default:
   * Nullable:True
   */
  cooperativeLawyerIDs?: string | null
  /**
   * Desc:案情简介
   * Default:
   * Nullable:True
   */
  narrative?: string | null
  /**
   * Desc:代理权限
   * Default:
   * Nullable:True
   */
  agencyAuthority?: string | null
  /**
   * Desc:诉讼地位（代理何方）
   * Default:
   * Nullable:True
   */
  litigationStatus?: string | null
  /**
   * Desc:是否标的
   * Default:
   * Nullable:True
   */
  isTarget?: string | null
  /**
   * Desc:标的额
   * Default:
   * Nullable:False
   * @format double
   */
  targetAmount?: number
  /**
   * Desc:货币单位
   * Default:
   * Nullable:True
   */
  currency?: string | null
  /**
   * Desc:顾问合同起始时间
   * Default:
   * Nullable:False
   * @format date-time
   */
  startDate?: string
  /**
   * Desc:顾问合同结束时间（空则长期）
   * Default:
   * Nullable:False
   * @format date-time
   */
  endDate?: string
  /**
   * Desc:律师费用
   * Default:
   * Nullable:False
   * @format double
   */
  lawyerFee?: number
  /**
   * Desc:办案费
   * Default:
   * Nullable:False
   * @format double
   */
  caseHandlingFee?: number
  /**
   * Desc:代收代付费
   * Default:
   * Nullable:False
   * @format double
   */
  pocoFee?: number
  /**
   * Desc:案件项下办案费支出方
   * Default:
   * Nullable:True
   */
  feePaidParty?: string | null
  /**
   * Desc:办案费限额
   * Default:
   * Nullable:False
   * @format double
   */
  caseHandlingFeeLimit?: number
  /**
   * Desc:约定汇率模式
   * Default:
   * Nullable:True
   */
  agreedExchangeRateMode?: string | null
  /**
   * Desc:汇率
   * Default:
   * Nullable:False
   * @format double
   */
  exchangeRate?: number
  /**
   * Desc:免费代理小时数
   * Default:
   * Nullable:False
   * @format double
   */
  freeHourLimit?: number
  /**
   * Desc:主管律师（主办律师）
   * Default:
   * Nullable:True
   */
  inChargeLawyer?: string | null
  /**
   * Desc:协办律师（合作律师）
   * Default:
   * Nullable:True
   */
  coWorkingLawyer?: string | null
  /**
   * Desc:案源律师
   * Default:
   * Nullable:True
   */
  caseSourceLawyer?: string | null
  /**
   * Desc:账单律师
   * Default:
   * Nullable:True
   */
  billingLawyer?: string | null
  /**
   * Desc:秘书助理（预留）;选择人则限定选择人和值班秘书可见，不选就全部助理和秘书可见
   * Default:
   * Nullable:True
   */
  assistantSecretary?: string | null
  /**
   * Desc:租户ID
   * Default:0
   * Nullable:False
   */
  tenantId?: string | null
  /**
   * Desc:部门ID  DepartmentId
   * Default:0
   * Nullable:False
   */
  organizationUnitId?: string | null
  /**
   * Desc:分所Id
   * Default:0
   * Nullable:False
   */
  officeId?: string | null
  /**
   * Desc:备注
   * Default:
   * Nullable:True
   */
  remark?: string | null
  /**
   * Desc:状态 	                中立字段，某些表可使用某些表不使用
   * Default:1
   * Nullable:False
   */
  isEnabled?: boolean
  /**
   * Desc:中立字段，某些表可使用某些表不使用   	                逻辑上的删除，非物理删除  	                例如：单据删除并非直接删除
   * Default:0
   * Nullable:False
   */
  isDeleted?: boolean
  /**
   * Desc:中立字段 	                是否内置数据
   * Default:
   * Nullable:False
   */
  isInternal?: boolean
  /**
   * Desc:创建ID
   * Default:
   * Nullable:True
   */
  createId?: string | null
  /**
   * Desc:创建者
   * Default:
   * Nullable:True
   */
  createBy?: string | null
  /**
   * Desc:创建时间
   * Default:
   * Nullable:False
   * @format date-time
   */
  createTime?: string
  /**
   * Desc:修改ID
   * Default:
   * Nullable:True
   */
  modifyId?: string | null
  /**
   * Desc:更新者
   * Default:
   * Nullable:True
   */
  modifyBy?: string | null
  /**
   * Desc:修改日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  modifyTime?: string | null
  /**
   * Desc:数据版本
   * Default:0
   * Nullable:False
   */
  version?: string | null
  /**
   * Desc:删除ID
   * Default:
   * Nullable:True
   */
  deleteId?: string | null
  /**
   * Desc:删除者
   * Default:
   * Nullable:True
   */
  deleteBy?: string | null
  /**
   * Desc:删除日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  deleteTime?: string | null
}

/** 通用返回信息类 */
export interface CaseMatterDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 返回数据集合 */
  response?: CaseMatterDto[] | null
}

/** 通用返回信息类 */
export interface CaseMatterDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 案件信息表 */
  response?: CaseMatterDto
}

/** 通用分页信息类 */
export interface CaseMatterDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number
  /** 返回数据 */
  data?: CaseMatterDto[] | null
}

/** 通用返回信息类 */
export interface CaseMatterDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 通用分页信息类 */
  response?: CaseMatterDtoPageModel
}

/** 案件关联方信息表 */
export interface CaseRelatedPartyDto {
  /**
   * Desc:雪花Id
   * Default:
   * Nullable:False
   */
  id?: string | null
  /**
   * Desc:案件或利冲编号
   * Default:0
   * Nullable:True
   */
  caseId?: string | null
  /**
   * Desc:客户编号
   * Default:0
   * Nullable:True
   */
  clientId?: string | null
  /**
   * Desc:关联方类型
   * Default:
   * Nullable:True
   */
  category?: string | null
  /**
   * Desc:文书关联编号
   * Default:0
   * Nullable:True
   */
  caseFileRelationId?: string | null
  /**
   * Desc:关联方名称
   * Default:
   * Nullable:True
   */
  name?: string | null
  /**
   * Desc:外文名称
   * Default:
   * Nullable:True
   */
  foreignName?: string | null
  /**
   * Desc:曾用名
   * Default:
   * Nullable:True
   */
  formerName?: string | null
  /**
   * Desc:联系人
   * Default:
   * Nullable:True
   */
  linker?: string | null
  /**
   * Desc:证件类型
   * Default:
   * Nullable:True
   */
  creditType?: string | null
  /**
   * Desc:证件号码
   * Default:
   * Nullable:True
   */
  creditCode?: string | null
  /**
   * Desc:邮件地址
   * Default:
   * Nullable:True
   */
  emailAddress?: string | null
  /**
   * Desc:联系电话
   * Default:
   * Nullable:True
   */
  phoneNumber?: string | null
  /**
   * Desc:联系地址
   * Default:
   * Nullable:True
   */
  address?: string | null
  /**
   * Desc:关联方角色
   * Default:
   * Nullable:True
   */
  partyRole?: string | null
  /**
   * Desc:利冲关键词
   * Default:
   * Nullable:True
   */
  conflictKeyword?: string | null
  /**
   * Desc:是否双方当事人
   * Default:
   * Nullable:False
   */
  isParties?: boolean
  /**
   * Desc:说明描述
   * Default:
   * Nullable:True
   */
  narrative?: string | null
  /**
   * Desc:租户ID
   * Default:0
   * Nullable:False
   */
  tenantId?: string | null
  /**
   * Desc:部门ID  DepartmentId
   * Default:0
   * Nullable:False
   */
  organizationUnitId?: string | null
  /**
   * Desc:分所Id
   * Default:0
   * Nullable:False
   */
  officeId?: string | null
  /**
   * Desc:备注
   * Default:
   * Nullable:True
   */
  remark?: string | null
  /**
   * Desc:状态 	            中立字段，某些表可使用某些表不使用
   * Default:1
   * Nullable:False
   */
  isEnabled?: boolean
  /**
   * Desc:中立字段，某些表可使用某些表不使用   	            逻辑上的删除，非物理删除  	            例如：单据删除并非直接删除
   * Default:0
   * Nullable:False
   */
  isDeleted?: boolean
  /**
   * Desc:中立字段 	            是否内置数据
   * Default:
   * Nullable:False
   */
  isInternal?: boolean
  /**
   * Desc:创建ID
   * Default:
   * Nullable:True
   * @format int64
   */
  createId?: number | null
  /**
   * Desc:创建者
   * Default:
   * Nullable:True
   */
  createBy?: string | null
  /**
   * Desc:创建时间
   * Default:
   * Nullable:False
   * @format date-time
   */
  createTime?: string
  /**
   * Desc:修改ID
   * Default:
   * Nullable:True
   */
  modifyId?: string | null
  /**
   * Desc:更新者
   * Default:
   * Nullable:True
   */
  modifyBy?: string | null
  /**
   * Desc:修改日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  modifyTime?: string | null
  /**
   * Desc:数据版本
   * Default:0
   * Nullable:False
   * @format int64
   */
  version?: number
  /**
   * Desc:删除ID
   * Default:
   * Nullable:True
   */
  deleteId?: string | null
  /**
   * Desc:删除者
   * Default:
   * Nullable:True
   */
  deleteBy?: string | null
  /**
   * Desc:删除日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  deleteTime?: string | null
}

/** 通用返回信息类 */
export interface CaseRelatedPartyDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 返回数据集合 */
  response?: CaseRelatedPartyDto[] | null
}

/** 通用返回信息类 */
export interface CaseRelatedPartyDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 案件关联方信息表 */
  response?: CaseRelatedPartyDto
}

/** 通用分页信息类 */
export interface CaseRelatedPartyDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number
  /** 返回数据 */
  data?: CaseRelatedPartyDto[] | null
}

/** 通用返回信息类 */
export interface CaseRelatedPartyDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 通用分页信息类 */
  response?: CaseRelatedPartyDtoPageModel
}

export interface ChangeListItem {
  /** 经营范围 */
  projectName?: string | null
  changeDate?: string | null
  beforeList?: string[] | null
  afterList?: string[] | null
}

export interface ChangeRecordsItem {
  /** 注册资本变更（注册资金、资金数额等变更） */
  projectName?: string | null
  beforeContent?: string | null
  afterContent?: string | null
  changeDate?: string | null
}

/** 客户所属人表 */
export interface ClientBelongTimekeeperDto {
  /**
   * Desc:雪花Id
   * Default:
   * Nullable:False
   */
  id?: string | null
  /**
   * Desc:客户编号
   * Default:
   * Nullable:False
   */
  clientID?: string | null
  /**
   * Desc:计时人员编号
   * Default:
   * Nullable:False
   */
  timekeeperID?: string | null
  /**
   * Desc:是否其助理或秘书可见
   * Default:
   * Nullable:True
   */
  isAssistantVisible?: string | null
  /**
   * Desc:是否团队律师可见
   * Default:
   * Nullable:True
   */
  isTeamLawyerVisible?: string | null
  /**
   * Desc:所属时效开始时间
   * Default:
   * Nullable:False
   * @format date-time
   */
  startDate?: string
  /**
   * Desc:所属时效结束时间（空则长期有效）
   * Default:
   * Nullable:False
   * @format date-time
   */
  endDate?: string
  /**
   * Desc:是否是初始所属人
   * Default:
   * Nullable:True
   */
  isFirstOwner?: string | null
  /**
   * Desc:是否过期失效
   * Default:
   * Nullable:True
   */
  isExpred?: string | null
  /**
   * Desc:租户ID
   * Default:0
   * Nullable:False
   */
  tenantId?: string | null
  /**
   * Desc:部门ID  DepartmentId
   * Default:0
   * Nullable:False
   */
  organizationUnitId?: string | null
  /**
   * Desc:分所Id
   * Default:0
   * Nullable:False
   */
  officeId?: string | null
  /**
   * Desc:备注
   * Default:
   * Nullable:True
   */
  remark?: string | null
  /**
   * Desc:状态 	                中立字段，某些表可使用某些表不使用
   * Default:1
   * Nullable:False
   */
  isEnabled?: boolean
  /**
   * Desc:中立字段，某些表可使用某些表不使用   	                逻辑上的删除，非物理删除  	                例如：单据删除并非直接删除
   * Default:0
   * Nullable:False
   */
  isDeleted?: boolean
  /**
   * Desc:中立字段 	                是否内置数据
   * Default:
   * Nullable:False
   */
  isInternal?: boolean
  /**
   * Desc:创建ID
   * Default:
   * Nullable:True
   */
  createId?: string | null
  /**
   * Desc:创建者
   * Default:
   * Nullable:True
   */
  createBy?: string | null
  /**
   * Desc:创建时间
   * Default:
   * Nullable:False
   * @format date-time
   */
  createTime?: string
  /**
   * Desc:修改ID
   * Default:
   * Nullable:True
   */
  modifyId?: string | null
  /**
   * Desc:更新者
   * Default:
   * Nullable:True
   */
  modifyBy?: string | null
  /**
   * Desc:修改日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  modifyTime?: string | null
  /**
   * Desc:数据版本
   * Default:0
   * Nullable:False
   */
  version?: string | null
  /**
   * Desc:删除ID
   * Default:
   * Nullable:True
   */
  deleteId?: string | null
  /**
   * Desc:删除者
   * Default:
   * Nullable:True
   */
  deleteBy?: string | null
  /**
   * Desc:删除日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  deleteTime?: string | null
}

/** 通用返回信息类 */
export interface ClientBelongTimekeeperDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 返回数据集合 */
  response?: ClientBelongTimekeeperDto[] | null
}

/** 通用返回信息类 */
export interface ClientBelongTimekeeperDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 客户所属人表 */
  response?: ClientBelongTimekeeperDto
}

/** 通用分页信息类 */
export interface ClientBelongTimekeeperDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number
  /** 返回数据 */
  data?: ClientBelongTimekeeperDto[] | null
}

/** 通用返回信息类 */
export interface ClientBelongTimekeeperDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 通用分页信息类 */
  response?: ClientBelongTimekeeperDtoPageModel
}

/** 客户信息表 */
export interface ClientDto {
  /** 雪花Id */
  id?: string | null
  /** 客户名称 */
  name?: string | null
  /** 外文名称 */
  foreignName?: string | null
  /** 曾用名 */
  formerName?: string | null
  /** 别名 */
  altName?: string | null
  /** 客户类型 */
  clientType?: string | null
  /** 证件类型 */
  creditType?: string | null
  /** 证件号码 */
  creditCode?: string | null
  /** 来源类型（立案/入库/投标） */
  originType?: string | null
  /** 来源编号 */
  originID?: string | null
  /** 通讯地址 */
  address?: string | null
  /** 税号 */
  taxNumber?: string | null
  /** 邮箱地址 */
  emailAddress?: string | null
  /** 手机号码 */
  phoneNumber?: string | null
  /** 固定电话 */
  landline?: string | null
  /** 描述说明 */
  narrative?: string | null
  /** 客户级别（星标客户/重要客户等） */
  clientLevel?: string | null
  /** 租户ID */
  tenantId?: string | null
  /** 部门ID  DepartmentId */
  organizationUnitId?: string | null
  /** 分所Id */
  officeId?: string | null
  /** 备注 */
  remark?: string | null
  /** 股东/实控人信息 */
  entityInfoDtos?: EntityInfoDto[] | null
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string
}

/** 通用返回信息类 */
export interface ClientDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 返回数据集合 */
  response?: ClientDto[] | null
}

/** 通用返回信息类 */
export interface ClientDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 客户信息表 */
  response?: ClientDto
}

/** 通用分页信息类 */
export interface ClientDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number
  /** 返回数据 */
  data?: ClientDto[] | null
}

/** 通用返回信息类 */
export interface ClientDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 通用分页信息类 */
  response?: ClientDtoPageModel
}

export interface ClientInfoDto {
  /** 客户类型 */
  clientType?: string | null
  /** 来源类型（立案/入库/投标） */
  originType?: string | null
  /** 来源编号 */
  originID?: string | null
  /** 客户级别（星标客户/重要客户等） */
  clientLevel?: string | null
}

export interface ComboboxItemDto {
  /** Value of the item. */
  value?: string | null
  /** Display text of the item. */
  displayText?: string | null
  isSelected?: boolean
}

export interface ConInfo {
  votePercent?: string | null
  pathInfoList?: PathInfoListItem[] | null
}

export interface ContactInfo {
  webSiteList?: string[] | null
  email?: string | null
  moreEmailList?: MoreEmailListItem[] | null
  tel?: string | null
  moreTelList?: MoreTelListItem[] | null
}

export interface ControllerData {
  keyNo?: string | null
  name?: string | null
  percent?: string | null
  percentTotal?: string | null
  level?: string | null
  pathCount?: string | null
  paths?: PathsItemItem[][] | null
  controlPercent?: string | null
}

export interface ControllerDataListItem {
  keyNo?: string | null
  name?: string | null
  isActual?: string | null
  /** 企业控制权最大；最终受益股份最高（最终受益股份14.428%） */
  judgeReason?: string | null
  conInfo?: ConInfo
  stockInfo?: string | null
}

/** 利冲规避偏好设置 */
export interface CreateCSRAvoidPreferenceInput {
  /**
   * Desc:雪花Id
   * Default:
   * Nullable:False
   */
  id?: string | null
  /**
   * Desc:规避偏好关键字
   * Default:
   * Nullable:True
   */
  avoidKeyword?: string | null
  /**
   * Desc:规避利冲方类型
   * Default:
   * Nullable:True
   */
  patryType?: string | null
  /**
   * Desc:关键字规避方法（模糊/精确）
   * Default:
   * Nullable:True
   */
  avoidMethod?: string | null
  /**
   * Desc:规避开始时间
   * Default:
   * Nullable:False
   * @format date-time
   */
  startDate?: string
  /**
   * Desc:规避结束时间（空则长期生效）
   * Default:
   * Nullable:False
   * @format date-time
   */
  endTime?: string
  /**
   * Desc:附件信息
   * Default:
   * Nullable:True
   */
  attachments?: string | null
  /**
   * Desc:说明描述
   * Default:
   * Nullable:True
   */
  narrative?: string | null
  /**
   * Desc:租户ID
   * Default:0
   * Nullable:False
   */
  tenantId?: string | null
  /**
   * Desc:部门ID  DepartmentId
   * Default:0
   * Nullable:False
   */
  organizationUnitId?: string | null
  /**
   * Desc:分所Id
   * Default:0
   * Nullable:False
   */
  officeId?: string | null
  /**
   * Desc:备注
   * Default:
   * Nullable:True
   */
  remark?: string | null
  /**
   * Desc:状态 	                中立字段，某些表可使用某些表不使用
   * Default:1
   * Nullable:False
   */
  isEnabled?: boolean
  /**
   * Desc:中立字段，某些表可使用某些表不使用   	                逻辑上的删除，非物理删除  	                例如：单据删除并非直接删除
   * Default:0
   * Nullable:False
   */
  isDeleted?: boolean
  /**
   * Desc:中立字段 	                是否内置数据
   * Default:
   * Nullable:False
   */
  isInternal?: boolean
  /**
   * Desc:创建ID
   * Default:
   * Nullable:True
   */
  createId?: string | null
  /**
   * Desc:创建者
   * Default:
   * Nullable:True
   */
  createBy?: string | null
  /**
   * Desc:创建时间
   * Default:
   * Nullable:False
   * @format date-time
   */
  createTime?: string
  /**
   * Desc:修改ID
   * Default:
   * Nullable:True
   */
  modifyId?: string | null
  /**
   * Desc:更新者
   * Default:
   * Nullable:True
   */
  modifyBy?: string | null
  /**
   * Desc:修改日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  modifyTime?: string | null
  /**
   * Desc:数据版本
   * Default:0
   * Nullable:False
   */
  version?: string | null
  /**
   * Desc:删除ID
   * Default:
   * Nullable:True
   */
  deleteId?: string | null
  /**
   * Desc:删除者
   * Default:
   * Nullable:True
   */
  deleteBy?: string | null
  /**
   * Desc:删除日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  deleteTime?: string | null
}

/** 利冲规避偏好结果表 */
export interface CreateCSRAvoidPreferenceResultInput {
  /**
   * Desc:雪花Id
   * Default:
   * Nullable:False
   */
  id?: string | null
  /**
   * Desc:利冲规避编号
   * Default:
   * Nullable:False
   */
  csrAvoidID?: string | null
  /**
   * Desc:利冲规避关键词
   * Default:
   * Nullable:True
   */
  avoidKeyword?: string | null
  /**
   * Desc:关键词隶属规避方角色
   * Default:
   * Nullable:True
   */
  partyType?: string | null
  /**
   * Desc:规避冲突案件的角色
   * Default:
   * Nullable:True
   */
  caseParty?: string | null
  /**
   * Desc:规避案件冲突方名称
   * Default:
   * Nullable:True
   */
  casePartyName?: string | null
  /**
   * Desc:规避利冲关联业务类型（利冲/案件/入库/投标等）
   * Default:
   * Nullable:True
   */
  csrRelationType?: string | null
  /**
   * Desc:规避业务编号（默认案件）
   * Default:
   * Nullable:False
   */
  relationID?: string | null
  /**
   * Desc:冲突产生时关联业务状态
   * Default:
   * Nullable:True
   */
  relationStatus?: string | null
  /**
   * Desc:匹配客户名称
   * Default:
   * Nullable:True
   */
  clientName?: string | null
  /**
   * Desc:匹配客户外文名称
   * Default:
   * Nullable:True
   */
  clientForeignName?: string | null
  /**
   * Desc:匹配客户实控人名称
   * Default:
   * Nullable:True
   */
  clientShareholderActualCtrlName?: string | null
  /**
   * Desc:匹配客户实控人外文名称
   * Default:
   * Nullable:True
   */
  clientShareholderActualCtrlForeignName?: string | null
  /**
   * Desc:匹配对方名称
   * Default:
   * Nullable:True
   */
  oppositeName?: string | null
  /**
   * Desc:匹配对方外文名称
   * Default:
   * Nullable:True
   */
  oppositeForeignName?: string | null
  /**
   * Desc:匹配对方实控人名称
   * Default:
   * Nullable:True
   */
  oppositeShareholderActualCtrlName?: string | null
  /**
   * Desc:匹配对方实控人外文名称
   * Default:
   * Nullable:True
   */
  oppositeShareholderActualCtrlForeignName?: string | null
  /**
   * Desc:潜在第三方名称
   * Default:
   * Nullable:True
   */
  potential3rdPartyName?: string | null
  /**
   * Desc:潜在第三方外文名称
   * Default:
   * Nullable:True
   */
  potential3rdPartyForeignName?: string | null
  /**
   * Desc:关联业务类型
   * Default:
   * Nullable:True
   */
  relationCategory?: string | null
  /**
   * Desc:关联业务名称
   * Default:
   * Nullable:True
   */
  relationName?: string | null
  /**
   * Desc:关联业务申请时间
   * Default:
   * Nullable:False
   * @format date-time
   */
  relationRequestTime?: string
  /**
   * Desc:关联业务主管律师
   * Default:
   * Nullable:True
   */
  relationInChargeLawyerID?: string | null
  /**
   * Desc:关联业务申请合伙人
   * Default:
   * Nullable:False
   */
  relationRequestUserID?: string | null
  /**
   * Desc:关联业务协办律师
   * Default:
   * Nullable:True
   */
  relationCoWorkingLawyerID?: string | null
  /**
   * Desc:关联业务账单律师
   * Default:
   * Nullable:True
   */
  relationBillingLawyerID?: string | null
  /**
   * Desc:规避匹配方式（包含/忽略）
   * Default:
   * Nullable:True
   */
  matchingMethod?: string | null
  /**
   * Desc:租户ID
   * Default:0
   * Nullable:False
   */
  tenantId?: string | null
  /**
   * Desc:部门ID  DepartmentId
   * Default:0
   * Nullable:False
   */
  organizationUnitId?: string | null
  /**
   * Desc:分所Id
   * Default:0
   * Nullable:False
   */
  officeId?: string | null
  /**
   * Desc:备注
   * Default:
   * Nullable:True
   */
  remark?: string | null
  /**
   * Desc:状态 	                中立字段，某些表可使用某些表不使用
   * Default:1
   * Nullable:False
   */
  isEnabled?: boolean
  /**
   * Desc:中立字段，某些表可使用某些表不使用   	                逻辑上的删除，非物理删除  	                例如：单据删除并非直接删除
   * Default:0
   * Nullable:False
   */
  isDeleted?: boolean
  /**
   * Desc:中立字段 	                是否内置数据
   * Default:
   * Nullable:False
   */
  isInternal?: boolean
  /**
   * Desc:创建ID
   * Default:
   * Nullable:True
   */
  createId?: string | null
  /**
   * Desc:创建者
   * Default:
   * Nullable:True
   */
  createBy?: string | null
  /**
   * Desc:创建时间
   * Default:
   * Nullable:False
   * @format date-time
   */
  createTime?: string
  /**
   * Desc:修改ID
   * Default:
   * Nullable:True
   */
  modifyId?: string | null
  /**
   * Desc:更新者
   * Default:
   * Nullable:True
   */
  modifyBy?: string | null
  /**
   * Desc:修改日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  modifyTime?: string | null
  /**
   * Desc:数据版本
   * Default:0
   * Nullable:False
   */
  version?: string | null
  /**
   * Desc:删除ID
   * Default:
   * Nullable:True
   */
  deleteId?: string | null
  /**
   * Desc:删除者
   * Default:
   * Nullable:True
   */
  deleteBy?: string | null
  /**
   * Desc:删除日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  deleteTime?: string | null
}

/** 利冲豁免确认表 */
export interface CreateCSRExemptionInput {
  /** 雪花Id */
  id?: string | null
  /**
   * 利冲编号
   * Default:
   * Nullable:False
   */
  csrid?: string | null
  /** 利冲关键词 */
  csrKeyword?: string | null
  /** 与我冲突案件的角色（冲突方） */
  csrParty?: string | null
  /** 我的案件冲突角色 */
  caseParty?: string | null
  /** 我的案件冲突方名称 */
  casePartyName?: string | null
  /** 主管律师 */
  inChargeLawyer?: string | null
  /** 利冲关联业务类型（利冲/案件/入库/投标等） */
  csrRelationType?: string | null
  /** 关联业务编号（默认案件） */
  relationID?: string | null
  /** 冲突产生时关联业务状态 */
  relationStatus?: string | null
  /** 与我冲突业务编号合计 */
  csrRelationIDs?: string | null
  /** 利冲确认发送人编号 */
  csrSendUserID?: string | null
  /**
   * 利冲确认发送时间
   * @format date-time
   */
  csrSendTime?: string
  /**
   * 利冲豁免确认时间
   * @format date-time
   */
  confirmTime?: string
  /** 豁免文件 */
  attachments?: string | null
  /** 说明描述 */
  narrative?: string | null
  /** 租户ID */
  tenantId?: string | null
  /** 部门ID  DepartmentId */
  organizationUnitId?: string | null
  /** 分所Id */
  officeId?: string | null
  /** 备注 */
  remark?: string | null
  /** 状态 中立字段，某些表可使用某些表不使用 */
  isEnabled?: boolean
  /** 中立字段， 逻辑上的删除，非物理删除  例如：单据删除并非直接删除 */
  isDeleted?: boolean
  /** 中立字段  是否内置数据 */
  isInternal?: boolean
  /** 创建ID */
  createId?: string | null
  /** 创建者 */
  createBy?: string | null
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string
  /** 修改ID */
  modifyId?: string | null
  /** 更新者 */
  modifyBy?: string | null
  /**
   * 修改日期
   * @format date-time
   */
  modifyTime?: string | null
  /** 数据版本 */
  version?: string | null
  /** 删除ID */
  deleteId?: string | null
  /** 删除者 */
  deleteBy?: string | null
  /**
   * 删除日期
   * @format date-time
   */
  deleteTime?: string | null
}

/** 利冲审查结果表 */
export interface CreateCSRProcessResultInput {
  /**
   * Desc:雪花Id
   * Default:
   * Nullable:False
   */
  id?: string | null
  /**
   * Desc:利冲申请编号
   * Default:
   * Nullable:False
   */
  csrid?: string | null
  /**
   * Desc:利冲审查编号
   * Default:
   * Nullable:False
   */
  csrProcessID?: string | null
  /**
   * Desc:是否需要豁免
   * Default:
   * Nullable:True
   */
  isNeedExemption?: string | null
  /**
   * Desc:利冲关键词
   * Default:
   * Nullable:True
   */
  csrKeyword?: string | null
  /**
   * Desc:与我冲突案件的角色
   * Default:
   * Nullable:True
   */
  csrParty?: string | null
  /**
   * Desc:我被冲突案件的角色
   * Default:
   * Nullable:True
   */
  caseParty?: string | null
  /**
   * Desc:我的案件冲突方名称
   * Default:
   * Nullable:True
   */
  casePartyName?: string | null
  /**
   * Desc:利冲关联业务类型（利冲/案件/入库/投标等）
   * Default:
   * Nullable:True
   */
  csrRelationType?: string | null
  /**
   * Desc:关联业务编号（默认案件）
   * Default:
   * Nullable:False
   */
  relationID?: string | null
  /**
   * Desc:冲突产生时关联业务状态
   * Default:
   * Nullable:True
   */
  relationStatus?: string | null
  /**
   * Desc:利冲豁免结果
   * Default:
   * Nullable:True
   */
  exemptionResult?: string | null
  /**
   * Desc:匹配利冲规则
   * Default:
   * Nullable:True
   */
  matchingRule?: string | null
  /**
   * Desc:匹配客户名称
   * Default:
   * Nullable:True
   */
  clientName?: string | null
  /**
   * Desc:匹配客户外文名称
   * Default:
   * Nullable:True
   */
  clientForeignName?: string | null
  /**
   * Desc:匹配客户实控人名称
   * Default:
   * Nullable:True
   */
  clientShareholderActualCtrlName?: string | null
  /**
   * Desc:匹配客户实控人外文名称
   * Default:
   * Nullable:True
   */
  clientShareholderActualCtrlForeignName?: string | null
  /**
   * Desc:匹配对方名称
   * Default:
   * Nullable:True
   */
  oppositeName?: string | null
  /**
   * Desc:匹配对方外文名称
   * Default:
   * Nullable:True
   */
  oppositeForeignName?: string | null
  /**
   * Desc:匹配对方实控人名称
   * Default:
   * Nullable:True
   */
  oppositeShareholderActualCtrlName?: string | null
  /**
   * Desc:匹配对方实控人外文名称
   * Default:
   * Nullable:True
   */
  oppositeShareholderActualCtrlForeignName?: string | null
  /**
   * Desc:潜在第三方名称
   * Default:
   * Nullable:True
   */
  potential3rdPartyName?: string | null
  /**
   * Desc:潜在第三方外文名称
   * Default:
   * Nullable:True
   */
  potential3rdPartyForeignName?: string | null
  /**
   * Desc:关联业务类型
   * Default:
   * Nullable:True
   */
  relationCategory?: string | null
  /**
   * Desc:关联业务名称
   * Default:
   * Nullable:True
   */
  relationName?: string | null
  /**
   * Desc:关联业务申请时间
   * Default:
   * Nullable:False
   * @format date-time
   */
  relationRequestTime?: string
  /**
   * Desc:关联业务主管律师
   * Default:
   * Nullable:False
   */
  relationInChargeLawyerID?: string | null
  /**
   * Desc:关联业务申请合伙人
   * Default:
   * Nullable:False
   */
  relationRequestUserID?: string | null
  /**
   * Desc:租户ID
   * Default:0
   * Nullable:False
   */
  tenantId?: string | null
  /**
   * Desc:部门ID  DepartmentId
   * Default:0
   * Nullable:False
   */
  organizationUnitId?: string | null
  /**
   * Desc:分所Id
   * Default:0
   * Nullable:False
   */
  officeId?: string | null
  /**
   * Desc:备注
   * Default:
   * Nullable:True
   */
  remark?: string | null
  /**
   * Desc:状态 	                中立字段，某些表可使用某些表不使用
   * Default:1
   * Nullable:False
   */
  isEnabled?: boolean
  /**
   * Desc:中立字段，某些表可使用某些表不使用   	                逻辑上的删除，非物理删除  	                例如：单据删除并非直接删除
   * Default:0
   * Nullable:False
   */
  isDeleted?: boolean
  /**
   * Desc:中立字段 	                是否内置数据
   * Default:
   * Nullable:False
   */
  isInternal?: boolean
  /**
   * Desc:创建ID
   * Default:
   * Nullable:True
   */
  createId?: string | null
  /**
   * Desc:创建者
   * Default:
   * Nullable:True
   */
  createBy?: string | null
  /**
   * Desc:创建时间
   * Default:
   * Nullable:False
   * @format date-time
   */
  createTime?: string
  /**
   * Desc:修改ID
   * Default:
   * Nullable:True
   */
  modifyId?: string | null
  /**
   * Desc:更新者
   * Default:
   * Nullable:True
   */
  modifyBy?: string | null
  /**
   * Desc:修改日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  modifyTime?: string | null
  /**
   * Desc:数据版本
   * Default:0
   * Nullable:False
   */
  version?: string | null
  /**
   * Desc:删除ID
   * Default:
   * Nullable:True
   */
  deleteId?: string | null
  /**
   * Desc:删除者
   * Default:
   * Nullable:True
   */
  deleteBy?: string | null
  /**
   * Desc:删除日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  deleteTime?: string | null
}

/** 利益冲突信息表 */
export interface CreateCSRRequestInput {
  /** 利冲案件名称 */
  name?: string | null
  /** 利冲案件类型 */
  category?: string | null
  /**
   * 关联案件编号
   * @format int64
   */
  relationCaseID?: number
  /** 客户行业编号 */
  clientIndustryID?: string | null
  /** 执业领域 */
  practiceArea?: string | null
  /** 是否涉密 */
  isClassified?: string | null
  /** 是否紧急 */
  isUrgent?: string | null
  /** 收费方式（固定，小时，风险，免费代理） */
  chargingMethods?: string | null
  /** 收费方式文本 */
  chargingMethodText?: string | null
  /**
   * 合同金额（费用总额）
   * @format double
   */
  contractAmount?: number
  /** 委托事项 */
  entrustedMatters?: string | null
  /** 客户名称 */
  clientName?: string | null
  /** 客户外文名称 */
  clientForeignName?: string | null
  /** 客户股东实控人名称 */
  clientShareholderActualCtrlName?: string | null
  /** 客户股东实控人外文名称 */
  clientShareholderActualCtrlForeignName?: string | null
  /** 客户参与实体名称 */
  clientParticipatingEntityName?: string | null
  /** 客户参与实体外文名称 */
  clientParticipatingEntityForeignName?: string | null
  /** 对方当事人 */
  oppositeName?: string | null
  /** 对方当事人外文名称 */
  oppositeForeignName?: string | null
  /** 对方股东实控人名称 */
  oppositeShareholderActualCtrlName?: string | null
  /** 对方股东实控人外文名称 */
  oppositeShareholderActualCtrlForeignName?: string | null
  /** 对方参与实体名称 */
  oppositeParticipatingEntityName?: string | null
  /** 对方参与实体外文名称 */
  oppositeParticipatingEntityForeignName?: string | null
  /** 潜在第三方名称 */
  potential3rdPartyName?: string | null
  /** 潜在第三方外文名称 */
  potential3rdPartyForeignName?: string | null
  /** 潜在第三方股东实控人 */
  potential3rdPartyShareholderActualCtrlName?: string | null
  /** 潜在第三方股东实控人外文名称 */
  potential3rdPartyShareholderActualCtrlForeignName?: string | null
  /** 主管律师（主办律师） */
  inChargeLawyer?: string | null
  /** 协办律师（合作律师） */
  coWorkingLawyer?: string | null
  /** 案源律师 */
  caseSourceLawyer?: string | null
  /** 账单律师 */
  billingLawyer?: string | null
  /** 秘书助理（预留）;选择人则限定选择人和值班秘书可见，不选就全部助理和秘书可见 */
  assistantSecretary?: string | null
  /** 说明描述 */
  narrative?: string | null
  /** 利冲关联案件编号 */
  caseID?: string | null
  /** 利冲关联案件名称 */
  caseName?: string | null
  /** 利冲关联案件类型 */
  caseCategory?: string | null
  /** 利冲关联案件状态 */
  caseStatus?: string | null
  /** 豁免文件 */
  exemptionAttachments?: string | null
  /** 附件 */
  attachments?: string | null
  /** 是否标的 */
  isTarget?: string | null
  /**
   * 标的金额
   * @format double
   */
  targetAmount?: number
  /** 利冲适用规则 */
  csrRule?: string | null
  /** 利冲时效是否过期 */
  isExpired?: string | null
  /**
   * 利冲审查进度
   * @format double
   */
  rateOfProgress?: number
  /**
   * 利冲时效剩余天数
   * @format double
   */
  remainingDays?: number
  /**
   * 租户ID
   * @format int64
   */
  tenantId?: number
  /**
   * 部门ID  DepartmentId
   * @format int64
   */
  organizationUnitId?: number
  /**
   * 分所Id
   * @format int64
   */
  officeId?: number
  /** 备注 */
  remark?: string | null
  /** 案件关联方信息表 */
  createCaseRelatedPartyInputs?: CreateCaseRelatedPartyInput[] | null
  /** 案件参与律师表 */
  createCaseLawyerInputs?: CreateCaseLawyerInput[] | null
  isEnabled?: boolean
}

/** 案件受理机构信息表 */
export interface CreateCaseAcceptAgencyInput {
  /**
   * Desc:雪花Id
   * Default:
   * Nullable:False
   */
  id?: string | null
  /**
   * Desc:案件或利冲编号
   * Default:0
   * Nullable:True
   */
  caseId?: string | null
  /**
   * Desc:机构名称
   * Default:
   * Nullable:True
   */
  name?: string | null
  /**
   * Desc:机构类别（法院/检察院/监狱等）
   * Default:
   * Nullable:True
   */
  agencyType?: string | null
  /**
   * Desc:开始时间
   * Default:
   * Nullable:False
   * @format date-time
   */
  startTime?: string
  /**
   * Desc:结束时间
   * Default:
   * Nullable:False
   * @format date-time
   */
  endTime?: string
  /**
   * Desc:事由分类
   * Default:
   * Nullable:True
   */
  category?: string | null
  /**
   * Desc:案件阶段
   * Default:
   * Nullable:True
   */
  stage?: string | null
  /**
   * Desc:联系人
   * Default:
   * Nullable:True
   */
  linker?: string | null
  /**
   * Desc:联系电话
   * Default:
   * Nullable:True
   */
  phoneNumber?: string | null
  /**
   * Desc:关联法院编号
   * Default:0
   * Nullable:True
   */
  courtId?: string | null
  /**
   * Desc:租户ID
   * Default:0
   * Nullable:False
   */
  tenantId?: string | null
  /**
   * Desc:部门ID  DepartmentId
   * Default:0
   * Nullable:False
   */
  organizationUnitId?: string | null
  /**
   * Desc:分所Id
   * Default:0
   * Nullable:False
   */
  officeId?: string | null
  /**
   * Desc:备注
   * Default:
   * Nullable:True
   */
  remark?: string | null
  /**
   * Desc:状态 	            中立字段，某些表可使用某些表不使用
   * Default:1
   * Nullable:False
   */
  isEnabled?: boolean
  /**
   * Desc:中立字段，某些表可使用某些表不使用   	            逻辑上的删除，非物理删除  	            例如：单据删除并非直接删除
   * Default:0
   * Nullable:False
   */
  isDeleted?: boolean
  /**
   * Desc:中立字段 	            是否内置数据
   * Default:
   * Nullable:False
   */
  isInternal?: boolean
  /**
   * Desc:创建ID
   * Default:
   * Nullable:True
   */
  createId?: string | null
  /**
   * Desc:创建者
   * Default:
   * Nullable:True
   */
  createBy?: string | null
  /**
   * Desc:创建时间
   * Default:
   * Nullable:False
   * @format date-time
   */
  createTime?: string
  /**
   * Desc:修改ID
   * Default:
   * Nullable:True
   */
  modifyId?: string | null
  /**
   * Desc:更新者
   * Default:
   * Nullable:True
   */
  modifyBy?: string | null
  /**
   * Desc:修改日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  modifyTime?: string | null
  /**
   * Desc:数据版本
   * Default:0
   * Nullable:False
   */
  version?: string | null
  /**
   * Desc:删除ID
   * Default:
   * Nullable:True
   */
  deleteId?: string | null
  /**
   * Desc:删除者
   * Default:
   * Nullable:True
   */
  deleteBy?: string | null
  /**
   * Desc:删除日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  deleteTime?: string | null
}

/** 案件结案表（撤案/销案） */
export interface CreateCaseClosedInput {
  /**
   * Desc:雪花Id
   * Default:
   * Nullable:False
   */
  id?: string | null
  /**
   * Desc:案件编号
   * Default:0
   * Nullable:True
   */
  caseId?: string | null
  /**
   * Desc:裁判类型（判决/裁定/调解/其他）
   * Default:
   * Nullable:True
   */
  refereeType?: string | null
  /**
   * Desc:受理机构（法院名称）
   * Default:
   * Nullable:True
   */
  court?: string | null
  /**
   * Desc:裁判文书日期
   * Default:
   * Nullable:False
   * @format date-time
   */
  judgmentDate?: string
  /**
   * Desc:裁判文书号
   * Default:
   * Nullable:True
   */
  judgmentNo?: string | null
  /**
   * Desc:送达日期
   * Default:
   * Nullable:False
   * @format date-time
   */
  deliveryDate?: string
  /**
   * Desc:是否机密案卷
   * Default:
   * Nullable:False
   */
  confidentialVolume?: boolean
  /**
   * Desc:结案时收费额是否与委托协议一致
   * Default:
   * Nullable:False
   */
  isConsistentCharge?: boolean
  /**
   * Desc:起诉日期
   * Default:
   * Nullable:False
   * @format date-time
   */
  sueDate?: string
  /**
   * Desc:阅卷日期
   * Default:
   * Nullable:False
   * @format date-time
   */
  examDate?: string
  /**
   * Desc:初次会见/谈话日期
   * Default:
   * Nullable:False
   * @format date-time
   */
  meetDate?: string
  /**
   * Desc:首次开庭日期
   * Default:
   * Nullable:False
   * @format date-time
   */
  courtDate?: string
  /**
   * Desc:简要案情和工作概况
   * Default:
   * Nullable:True
   */
  result?: string | null
  /**
   * Desc:处理结果
   * Default:
   * Nullable:True
   */
  pocessResult?: string | null
  /**
   * Desc:律师办案体会
   * Default:
   * Nullable:True
   */
  experience?: string | null
  /**
   * Desc:代理事项
   * Default:
   * Nullable:True
   */
  discussion?: string | null
  /**
   * Desc:结案必要文件夹
   * Default:
   * Nullable:True
   */
  folders?: string | null
  /**
   * Desc:归档人编号
   * Default:0
   * Nullable:True
   */
  archiveUserId?: string | null
  /**
   * Desc:归档日期
   * Default:
   * Nullable:False
   * @format date-time
   */
  archiveDate?: string
  /**
   * Desc:归档编号
   * Default:
   * Nullable:True
   */
  archiveId?: string | null
  /**
   * Desc:归档状态
   * Default:
   * Nullable:True
   */
  archiveStatus?: string | null
  /**
   * Desc:服务结果（中止/终止/存续）
   * Default:
   * Nullable:True
   */
  serviceResult?: string | null
  /**
   * Desc:电子档案编号
   * Default:
   * Nullable:True
   */
  electronicFileNo?: string | null
  /**
   * Desc:是否扫描件
   * Default:
   * Nullable:False
   */
  isScanFile?: boolean
  /**
   * Desc:是否逾期结案
   * Default:
   * Nullable:False
   */
  isOverdueFiling?: boolean
  /**
   * Desc:逾期结案原因
   * Default:
   * Nullable:True
   */
  overdueFilingReason?: string | null
  /**
   * Desc:逾期天数
   * Default:
   * Nullable:False
   * @format double
   */
  overdueDays?: number
  /**
   * Desc:归档地
   * Default:
   * Nullable:True
   */
  archivePlace?: string | null
  /**
   * Desc:租户ID
   * Default:0
   * Nullable:False
   */
  tenantId?: string | null
  /**
   * Desc:部门ID  DepartmentId
   * Default:0
   * Nullable:False
   */
  organizationUnitId?: string | null
  /**
   * Desc:分所Id
   * Default:0
   * Nullable:False
   */
  officeId?: string | null
  /**
   * Desc:备注
   * Default:
   * Nullable:True
   */
  remark?: string | null
  /**
   * Desc:状态 	            中立字段，某些表可使用某些表不使用
   * Default:1
   * Nullable:False
   */
  isEnabled?: boolean
  /**
   * Desc:中立字段，某些表可使用某些表不使用   	            逻辑上的删除，非物理删除  	            例如：单据删除并非直接删除
   * Default:0
   * Nullable:False
   */
  isDeleted?: boolean
  /**
   * Desc:中立字段 	            是否内置数据
   * Default:
   * Nullable:False
   */
  isInternal?: boolean
  /**
   * Desc:创建ID
   * Default:
   * Nullable:True
   */
  createId?: string | null
  /**
   * Desc:创建者
   * Default:
   * Nullable:True
   */
  createBy?: string | null
  /**
   * Desc:创建时间
   * Default:
   * Nullable:False
   * @format date-time
   */
  createTime?: string
  /**
   * Desc:修改ID
   * Default:
   * Nullable:True
   */
  modifyId?: string | null
  /**
   * Desc:更新者
   * Default:
   * Nullable:True
   */
  modifyBy?: string | null
  /**
   * Desc:修改日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  modifyTime?: string | null
  /**
   * Desc:数据版本
   * Default:0
   * Nullable:False
   */
  version?: string | null
  /**
   * Desc:删除ID
   * Default:
   * Nullable:True
   */
  deleteId?: string | null
  /**
   * Desc:删除者
   * Default:
   * Nullable:True
   */
  deleteBy?: string | null
  /**
   * Desc:删除日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  deleteTime?: string | null
}

/** 案件收款信息表 */
export interface CreateCaseCollectionInput {
  /**
   * Desc:雪花Id
   * Default:
   * Nullable:False
   */
  id?: string | null
  /**
   * Desc:案件编号
   * Default:0
   * Nullable:True
   */
  caseId?: string | null
  /**
   * Desc:收费方式
   * Default:
   * Nullable:True
   */
  chargingMethod?: string | null
  /**
   * Desc:收费金额
   * Default:
   * Nullable:False
   * @format double
   */
  amount?: number
  /**
   * Desc:基础风险金额
   * Default:
   * Nullable:False
   * @format double
   */
  riskBasicAmount?: number
  /**
   * Desc:说明描述/风险达成条件
   * Default:
   * Nullable:True
   */
  narrative?: string | null
  /**
   * Desc:是否可收款
   * Default:
   * Nullable:True
   */
  isAcceptable?: string | null
  /**
   * Desc:是否使用账单
   * Default:
   * Nullable:True
   */
  isUseBilling?: string | null
  /**
   * Desc:收费日期
   * Default:
   * Nullable:False
   * @format date-time
   */
  chargeDate?: string
  /**
   * Desc:是否已到账
   * Default:
   * Nullable:True
   */
  isArrived?: string | null
  /**
   * Desc:到账日期
   * Default:
   * Nullable:False
   * @format date-time
   */
  arrivedDate?: string
  /**
   * Desc:到账金额
   * Default:
   * Nullable:False
   * @format double
   */
  arrivedAmount?: number
  /**
   * Desc:币种
   * Default:
   * Nullable:True
   */
  currency?: string | null
  /**
   * Desc:汇率
   * Default:
   * Nullable:False
   * @format double
   */
  exchangeRate?: number
  /**
   * Desc:是否账单
   * Default:
   * Nullable:True
   */
  isBilling?: string | null
  /**
   * Desc:关联账单编号
   * Default:0
   * Nullable:True
   */
  billingID?: string | null
  /**
   * Desc:付款比例
   * Default:
   * Nullable:False
   * @format double
   */
  chargeRatio?: number
  /**
   * Desc:开始时间
   * Default:
   * Nullable:False
   * @format date-time
   */
  startDate?: string
  /**
   * Desc:结束时间
   * Default:
   * Nullable:False
   * @format date-time
   */
  endDate?: string
  /**
   * Desc:是否提醒
   * Default:
   * Nullable:True
   */
  isRemind?: string | null
  /**
   * Desc:提醒时间
   * Default:
   * Nullable:False
   * @format date-time
   */
  remindTime?: string
  /**
   * Desc:风险收费比例
   * Default:
   * Nullable:False
   * @format double
   */
  riskRatio?: number
  /**
   * Desc:关联案件编号
   * Default:0
   * Nullable:True
   */
  relationCaseID?: string | null
  /**
   * Desc:外币收费金额
   * Default:
   * Nullable:False
   * @format double
   */
  foreignCurrencyAmount?: number
  isEnabled?: boolean
}

/** 案件分配表 */
export interface CreateCaseDistributionInput {
  /**
   * Desc:雪花Id
   * Default:
   * Nullable:False
   */
  id?: string | null
  /**
   * Desc:案件编号
   * Default:0
   * Nullable:True
   */
  caseId?: string | null
  /**
   * Desc:用户编号
   * Default:0
   * Nullable:True
   */
  userId?: string | null
  /**
   * Desc:律师角色
   * Default:
   * Nullable:True
   */
  lawyerRole?: string | null
  /**
   * Desc:分配金额
   * Default:
   * Nullable:False
   * @format double
   */
  allocationAmount?: number
  /**
   * Desc:分配比例
   * Default:
   * Nullable:False
   * @format double
   */
  allocationRate?: number
  /**
   * Desc:尾差金额
   * Default:
   * Nullable:False
   * @format double
   */
  tailAmount?: number
  /**
   * Desc:是否案源费
   * Default:
   * Nullable:False
   */
  isSourceFee?: boolean
  /**
   * Desc:是否默认策略
   * Default:
   * Nullable:False
   */
  isDefault?: boolean
  isEnabled?: boolean
}

/** 案件参与律师表 */
export interface CreateCaseLawyerInput {
  /**
   * Desc:案件编号
   * Default:0
   * Nullable:True
   * @format int64
   */
  caseId?: number | null
  /**
   * Desc:用户编号
   * Default:0
   * Nullable:True
   * @format int64
   */
  userId?: number | null
  /**
   * Desc:律师参案角色
   * Default:
   * Nullable:True
   */
  lawyerRole?: string | null
  /**
   * Desc:律师费率
   * Default:
   * Nullable:False
   * @format double
   */
  chargeRatio?: number
  /**
   * Desc:服务开始时间
   * Default:
   * Nullable:False
   * @format date-time
   */
  startDate?: string
  /**
   * Desc:服务结束时间
   * Default:
   * Nullable:False
   * @format date-time
   */
  endDate?: string
  /**
   * Desc:是否非当前服务律师
   * Default:
   * Nullable:False
   */
  isInActive?: boolean
  /**
   * Desc:排序编号
   * Default:
   * Nullable:False
   * @format double
   */
  sort?: number
  /**
   * Desc:备注
   * Default:
   * Nullable:True
   */
  remark?: string | null
  /**
   * Desc:邮件地址
   * Default:
   * Nullable:True
   */
  emailAddress?: string | null
  isEnabled?: boolean
}

/** 案件信息表 */
export interface CreateCaseMatterInput {
  /**
   * Desc:雪花Id
   * Default:
   * Nullable:False
   */
  id?: string | null
  /**
   * Desc:利冲预检编号
   * Default:0
   * Nullable:True
   */
  preCSRID?: string | null
  /**
   * Desc:利冲申请编号
   * Default:0
   * Nullable:True
   */
  csrid?: string | null
  /**
   * Desc:案件类型
   * Default:
   * Nullable:True
   */
  category?: string | null
  /**
   * Desc:案由分类
   * Default:
   * Nullable:True
   */
  reason?: string | null
  /**
   * Desc:补充案由
   * Default:
   * Nullable:True
   */
  reasonSupplement?: string | null
  /**
   * Desc:关联案件编号
   * Default:0
   * Nullable:True
   */
  relationCaseID?: string | null
  /**
   * Desc:案件名称
   * Default:
   * Nullable:True
   */
  name?: string | null
  /**
   * Desc:案件名称注释备注
   * Default:
   * Nullable:True
   */
  nameNote?: string | null
  /**
   * Desc:客户行业编号
   * Default:
   * Nullable:True
   */
  clientIndustryID?: string | null
  /**
   * Desc:执业领域
   * Default:
   * Nullable:True
   */
  practiceArea?: string | null
  /**
   * Desc:是否涉密
   * Default:
   * Nullable:True
   */
  isClassified?: string | null
  /**
   * Desc:是否紧急
   * Default:
   * Nullable:True
   */
  isUrgent?: string | null
  /**
   * Desc:收费方式（固定，小时，风险，免费代理）
   * Default:
   * Nullable:True
   */
  chargingMethods?: string | null
  /**
   * Desc:收费方式文本
   * Default:
   * Nullable:True
   */
  chargingMethodText?: string | null
  /**
   * Desc:合同金额（费用总额）
   * Default:
   * Nullable:False
   * @format double
   */
  contractAmount?: number
  /**
   * Desc:委托事项
   * Default:
   * Nullable:True
   */
  entrustedMatters?: string | null
  /**
   * Desc:书写语言
   * Default:
   * Nullable:True
   */
  writtenLanguage?: string | null
  /**
   * Desc:收案日期
   * Default:
   * Nullable:False
   * @format date-time
   */
  acceptDate?: string
  /**
   * Desc:案件来源
   * Default:
   * Nullable:True
   */
  caseSource?: string | null
  /**
   * Desc:委托阶段
   * Default:
   * Nullable:True
   */
  stages?: string | null
  /**
   * Desc:当前阶段
   * Default:
   * Nullable:True
   */
  currentStage?: string | null
  /**
   * Desc:受理机构
   * Default:
   * Nullable:True
   */
  acceptAgency?: string | null
  /**
   * Desc:受理机构案号
   * Default:
   * Nullable:True
   */
  acceptCaseNo?: string | null
  /**
   * Desc:受理机构级别（法院级别）
   * Default:
   * Nullable:True
   */
  acceptAgencyLevel?: string | null
  /**
   * Desc:特殊案件类型
   * Default:
   * Nullable:True
   */
  specialType?: string | null
  /**
   * Desc:是否跨所合作案件
   * Default:
   * Nullable:True
   */
  isInterbankCooperation?: string | null
  /**
   * Desc:合作分所
   * Default:0
   * Nullable:True
   */
  cooperationOffice?: string | null
  /**
   * Desc:合作律师
   * Default:
   * Nullable:True
   */
  cooperativeLawyerIDs?: string | null
  /**
   * Desc:案情简介
   * Default:
   * Nullable:True
   */
  narrative?: string | null
  /**
   * Desc:代理权限
   * Default:
   * Nullable:True
   */
  agencyAuthority?: string | null
  /**
   * Desc:诉讼地位（代理何方）
   * Default:
   * Nullable:True
   */
  litigationStatus?: string | null
  /**
   * Desc:是否标的
   * Default:
   * Nullable:True
   */
  isTarget?: string | null
  /**
   * Desc:标的额
   * Default:
   * Nullable:False
   * @format double
   */
  targetAmount?: number
  /**
   * Desc:货币单位
   * Default:
   * Nullable:True
   */
  currency?: string | null
  /**
   * Desc:顾问合同起始时间
   * Default:
   * Nullable:False
   * @format date-time
   */
  startDate?: string
  /**
   * Desc:顾问合同结束时间（空则长期）
   * Default:
   * Nullable:False
   * @format date-time
   */
  endDate?: string
  /**
   * Desc:律师费用
   * Default:
   * Nullable:False
   * @format double
   */
  lawyerFee?: number
  /**
   * Desc:办案费
   * Default:
   * Nullable:False
   * @format double
   */
  caseHandlingFee?: number
  /**
   * Desc:代收代付费
   * Default:
   * Nullable:False
   * @format double
   */
  pocoFee?: number
  /**
   * Desc:案件项下办案费支出方
   * Default:
   * Nullable:True
   */
  feePaidParty?: string | null
  /**
   * Desc:办案费限额
   * Default:
   * Nullable:False
   * @format double
   */
  caseHandlingFeeLimit?: number
  /**
   * Desc:约定汇率模式
   * Default:
   * Nullable:True
   */
  agreedExchangeRateMode?: string | null
  /**
   * Desc:汇率
   * Default:
   * Nullable:False
   * @format double
   */
  exchangeRate?: number
  /**
   * Desc:免费代理小时数
   * Default:
   * Nullable:False
   * @format double
   */
  freeHourLimit?: number
  /**
   * Desc:主管律师（主办律师）
   * Default:
   * Nullable:True
   */
  inChargeLawyer?: string | null
  /**
   * Desc:协办律师（合作律师）
   * Default:
   * Nullable:True
   */
  coWorkingLawyer?: string | null
  /**
   * Desc:案源律师
   * Default:
   * Nullable:True
   */
  caseSourceLawyer?: string | null
  /**
   * Desc:账单律师
   * Default:
   * Nullable:True
   */
  billingLawyer?: string | null
  /**
   * Desc:秘书助理（预留）;选择人则限定选择人和值班秘书可见，不选就全部助理和秘书可见
   * Default:
   * Nullable:True
   */
  assistantSecretary?: string | null
  /** 案件人员信息 */
  caseLawyers?: CreateCaseLawyerInput[] | null
  /** 案件收款信息 */
  caseCollections?: CreateCaseCollectionInput[] | null
  /** 案件分配信息 */
  caseDistributions?: CreateCaseDistributionInput[] | null
  isEnabled?: boolean
}

/** 案件关联方信息表 */
export interface CreateCaseRelatedPartyInput {
  /**
   * Desc:案件或利冲编号
   * Default:0
   * Nullable:True
   */
  caseId?: string | null
  /**
   * Desc:客户编号
   * Default:0
   * Nullable:True
   */
  clientId?: string | null
  /**
   * Desc:关联方类型
   * Default:
   * Nullable:True
   */
  category?: string | null
  /**
   * Desc:文书关联编号
   * Default:0
   * Nullable:True
   */
  caseFileRelationId?: string | null
  /**
   * Desc:关联方名称
   * Default:
   * Nullable:True
   */
  name?: string | null
  /**
   * Desc:外文名称
   * Default:
   * Nullable:True
   */
  foreignName?: string | null
  /**
   * Desc:曾用名
   * Default:
   * Nullable:True
   */
  formerName?: string | null
  /**
   * Desc:联系人
   * Default:
   * Nullable:True
   */
  linker?: string | null
  /**
   * Desc:证件类型
   * Default:
   * Nullable:True
   */
  creditType?: string | null
  /**
   * Desc:证件号码
   * Default:
   * Nullable:True
   */
  creditCode?: string | null
  /**
   * Desc:邮件地址
   * Default:
   * Nullable:True
   */
  emailAddress?: string | null
  /**
   * Desc:联系电话
   * Default:
   * Nullable:True
   */
  phoneNumber?: string | null
  /**
   * Desc:联系地址
   * Default:
   * Nullable:True
   */
  address?: string | null
  /**
   * Desc:关联方角色
   * Default:
   * Nullable:True
   */
  partyRole?: string | null
  /**
   * Desc:利冲关键词
   * Default:
   * Nullable:True
   */
  conflictKeyword?: string | null
  /**
   * Desc:是否双方当事人
   * Default:
   * Nullable:False
   */
  isParties?: boolean
  /**
   * Desc:说明描述
   * Default:
   * Nullable:True
   */
  narrative?: string | null
  /**
   * Desc:租户ID
   * Default:0
   * Nullable:False
   */
  tenantId?: string | null
  /**
   * Desc:部门ID  DepartmentId
   * Default:0
   * Nullable:False
   */
  organizationUnitId?: string | null
  /**
   * Desc:分所Id
   * Default:0
   * Nullable:False
   */
  officeId?: string | null
  /**
   * Desc:备注
   * Default:
   * Nullable:True
   */
  remark?: string | null
  isEnabled?: boolean
}

/** 客户所属人表 */
export interface CreateClientBelongTimekeeperInput {
  /**
   * Desc:雪花Id
   * Default:
   * Nullable:False
   */
  id?: string | null
  /**
   * Desc:客户编号
   * Default:
   * Nullable:False
   */
  clientID?: string | null
  /**
   * Desc:计时人员编号
   * Default:
   * Nullable:False
   */
  timekeeperID?: string | null
  /**
   * Desc:是否其助理或秘书可见
   * Default:
   * Nullable:True
   */
  isAssistantVisible?: string | null
  /**
   * Desc:是否团队律师可见
   * Default:
   * Nullable:True
   */
  isTeamLawyerVisible?: string | null
  /**
   * Desc:所属时效开始时间
   * Default:
   * Nullable:False
   * @format date-time
   */
  startDate?: string
  /**
   * Desc:所属时效结束时间（空则长期有效）
   * Default:
   * Nullable:False
   * @format date-time
   */
  endDate?: string
  /**
   * Desc:是否是初始所属人
   * Default:
   * Nullable:True
   */
  isFirstOwner?: string | null
  /**
   * Desc:是否过期失效
   * Default:
   * Nullable:True
   */
  isExpred?: string | null
  /**
   * Desc:租户ID
   * Default:0
   * Nullable:False
   */
  tenantId?: string | null
  /**
   * Desc:部门ID  DepartmentId
   * Default:0
   * Nullable:False
   */
  organizationUnitId?: string | null
  /**
   * Desc:分所Id
   * Default:0
   * Nullable:False
   */
  officeId?: string | null
  /**
   * Desc:备注
   * Default:
   * Nullable:True
   */
  remark?: string | null
  /**
   * Desc:状态 	                中立字段，某些表可使用某些表不使用
   * Default:1
   * Nullable:False
   */
  isEnabled?: boolean
  /**
   * Desc:中立字段，某些表可使用某些表不使用   	                逻辑上的删除，非物理删除  	                例如：单据删除并非直接删除
   * Default:0
   * Nullable:False
   */
  isDeleted?: boolean
  /**
   * Desc:中立字段 	                是否内置数据
   * Default:
   * Nullable:False
   */
  isInternal?: boolean
  /**
   * Desc:创建ID
   * Default:
   * Nullable:True
   */
  createId?: string | null
  /**
   * Desc:创建者
   * Default:
   * Nullable:True
   */
  createBy?: string | null
  /**
   * Desc:创建时间
   * Default:
   * Nullable:False
   * @format date-time
   */
  createTime?: string
  /**
   * Desc:修改ID
   * Default:
   * Nullable:True
   */
  modifyId?: string | null
  /**
   * Desc:更新者
   * Default:
   * Nullable:True
   */
  modifyBy?: string | null
  /**
   * Desc:修改日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  modifyTime?: string | null
  /**
   * Desc:数据版本
   * Default:0
   * Nullable:False
   */
  version?: string | null
  /**
   * Desc:删除ID
   * Default:
   * Nullable:True
   */
  deleteId?: string | null
  /**
   * Desc:删除者
   * Default:
   * Nullable:True
   */
  deleteBy?: string | null
  /**
   * Desc:删除日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  deleteTime?: string | null
}

export interface CreateClientInfoInput {
  /** 是否手工录入（手工录入的传true） */
  isOrManualInput?: boolean
  /** Id */
  id?: string | null
  /** 股东/实控人/当前客户信息都传入到实体表 */
  entityInfoInputs?: CreateEntityInfoInput[] | null
  isEnabled?: boolean
}

export interface CreateDingtalkDepartmentInput {
  /**
   * Desc:雪花Id
   * Default:
   * Nullable:False
   */
  id?: string | null
  /**
   * Desc:应用编号
   * Default:
   * Nullable:True
   */
  appId?: string | null
  /**
   * Desc:部门ID
   * Default:
   * Nullable:True
   */
  deptId?: string | null
  /**
   * Desc:部门名称
   * Default:
   * Nullable:True
   */
  name?: string | null
  /**
   * Desc:父部门Id
   * Default:
   * Nullable:True
   */
  parentId?: string | null
  /**
   * Desc:是否同步创建一个关联此部门的企业群
   * Default:
   * Nullable:True
   */
  createDeptGroup?: boolean | null
  /**
   * Desc:部门群已经创建后，有新人加入部门是否会自动加入该群
   * Default:
   * Nullable:True
   */
  autoAddUser?: boolean | null
  /**
   * Desc:部门标识字段
   * Default:
   * Nullable:True
   */
  sourceIdentifier?: string | null
  /**
   * Desc:是否默认同意加入该部门的申请
   * Default:
   * Nullable:True
   */
  autoApproveApply?: boolean | null
  /**
   * Desc:部门是否来自关联组织
   * Default:
   * Nullable:True
   */
  fromUnionOrg?: boolean | null
  /**
   * Desc:教育部门标签
   * Default:
   * Nullable:True
   */
  tags?: string | null
  /**
   * Desc:在父部门中的次序值
   * Default:
   * Nullable:True
   */
  order?: string | null
  /**
   * Desc:部门群ID
   * Default:
   * Nullable:True
   */
  deptGroupChatId?: string | null
  /**
   * Desc:部门群是否包含子部门
   * Default:
   * Nullable:True
   */
  groupContainSubDept?: boolean | null
  /**
   * Desc:租户ID
   * Default:0
   * Nullable:False
   */
  tenantId?: string | null
  /**
   * Desc:部门ID  DepartmentId
   * Default:0
   * Nullable:False
   */
  organizationUnitId?: string | null
  /**
   * Desc:分所Id
   * Default:0
   * Nullable:False
   */
  officeId?: string | null
  /**
   * Desc:备注
   * Default:
   * Nullable:True
   */
  remark?: string | null
  /**
   * Desc:状态 	            中立字段，某些表可使用某些表不使用
   * Default:1
   * Nullable:False
   */
  isEnabled?: boolean
  /**
   * Desc:中立字段，某些表可使用某些表不使用   	            逻辑上的删除，非物理删除  	            例如：单据删除并非直接删除
   * Default:0
   * Nullable:False
   */
  isDeleted?: boolean
  /**
   * Desc:中立字段 	            是否内置数据
   * Default:
   * Nullable:False
   */
  isInternal?: boolean
  /**
   * Desc:创建ID
   * Default:
   * Nullable:True
   */
  createId?: string | null
  /**
   * Desc:创建者
   * Default:
   * Nullable:True
   */
  createBy?: string | null
  /**
   * Desc:创建时间
   * Default:
   * Nullable:False
   * @format date-time
   */
  createTime?: string
  /**
   * Desc:修改ID
   * Default:
   * Nullable:True
   */
  modifyId?: string | null
  /**
   * Desc:更新者
   * Default:
   * Nullable:True
   */
  modifyBy?: string | null
  /**
   * Desc:修改日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  modifyTime?: string | null
  /**
   * Desc:数据版本
   * Default:0
   * Nullable:False
   */
  version?: string | null
  /**
   * Desc:删除ID
   * Default:
   * Nullable:True
   */
  deleteId?: string | null
  /**
   * Desc:删除者
   * Default:
   * Nullable:True
   */
  deleteBy?: string | null
  /**
   * Desc:删除日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  deleteTime?: string | null
}

export interface CreateDingtalkUserInput {
  /**
   * Desc:雪花Id
   * Default:
   * Nullable:False
   */
  id?: string | null
  /**
   * Desc:应用编号
   * Default:
   * Nullable:False
   */
  appId?: string | null
  /**
   * Desc:BitzOrcas UserId
   * Default:
   * Nullable:False
   */
  relationUserId?: string | null
  /**
   * Desc:用户名
   * Default:
   * Nullable:True
   */
  name?: string | null
  /**
   * Desc:用户英文名称
   * Default:
   * Nullable:True
   */
  enName?: string | null
  /**
   * Desc:昵称
   * Default:
   * Nullable:True
   */
  nickName?: string | null
  /**
   * Desc:区域代码  +86
   * Default:
   * Nullable:True
   */
  stateCode?: string | null
  /**
   * Desc:邮箱地址
   * Default:
   * Nullable:True
   */
  email?: string | null
  /**
   * Desc:机构邮箱地址
   * Default:
   * Nullable:True
   */
  orgEmail?: string | null
  /**
   * Desc:手机号
   * Default:
   * Nullable:True
   */
  mobile?: string | null
  /**
   * Desc:是否显示手机号
   * Default:
   * Nullable:True
   */
  mobileVisible?: boolean | null
  /**
   * Desc:分机号
   * Default:
   * Nullable:True
   */
  telephone?: string | null
  /**
   * Desc:钉钉用户的open_id
   * Default:
   * Nullable:True
   */
  openId?: string | null
  /**
   * Desc:钉钉用户的union_id
   * Default:
   * Nullable:True
   */
  unionId?: string | null
  /**
   * Desc:钉钉用户的user_id
   * Default:
   * Nullable:True
   */
  userId?: string | null
  /**
   * Desc:性别
   * Default:
   * Nullable:True
   * @format int32
   */
  gender?: number | null
  /**
   * Desc:用户头像信息
   * Default:
   * Nullable:True
   */
  avatar?: string | null
  /**
   * Desc:员工工号
   * Default:
   * Nullable:True
   */
  jobNumber?: string | null
  /**
   * Desc:员工的直属主管
   * Default:
   * Nullable:True
   */
  managerUserId?: string | null
  /**
   * Desc:所属部门的Id列表
   * Default:
   * Nullable:True
   */
  departmentIds?: string | null
  /**
   * Desc:员工在对应的部门中的排序 json：dept_id,order
   * Default:
   * Nullable:True
   */
  deptOrderList?: string | null
  /**
   * Desc:员工所在部门信息及是否是领导 json:dept_id,leader
   * Default:
   * Nullable:True
   */
  leaderInDept?: string | null
  /**
   * Desc:工作地点
   * Default:
   * Nullable:True
   */
  workPlace?: string | null
  /**
   * Desc:入职时间
   * Default:
   * Nullable:True
   */
  hiredDate?: string | null
  /**
   * Desc:职务
   * Default:
   * Nullable:True
   */
  title?: string | null
  /**
   * Desc:是否为企业账号
   * Default:
   * Nullable:True
   */
  exclusiveAccount?: boolean | null
  /**
   * Desc:扩展属性，最大长度2000个字符。
   * Default:
   * Nullable:True
   */
  extension?: string | null
  /**
   * Desc:是否激活了钉钉
   * Default:
   * Nullable:True
   */
  active?: boolean | null
  /**
   * Desc:是否完成了实名认证
   * Default:
   * Nullable:True
   */
  realAuthed?: boolean | null
  /**
   * Desc:是否为企业高管
   * Default:
   * Nullable:True
   */
  senior?: boolean | null
  /**
   * Desc:是否为企业的管理员
   * Default:
   * Nullable:True
   */
  admin?: boolean | null
  /**
   * Desc:是否为企业的老板
   * Default:
   * Nullable:True
   */
  boss?: boolean | null
  /**
   * Desc:租户ID
   * Default:0
   * Nullable:False
   */
  tenantId?: string | null
  /**
   * Desc:部门ID  DepartmentId
   * Default:0
   * Nullable:False
   */
  organizationUnitId?: string | null
  /**
   * Desc:分所Id
   * Default:0
   * Nullable:False
   */
  officeId?: string | null
  /**
   * Desc:备注
   * Default:
   * Nullable:True
   */
  remark?: string | null
  /**
   * Desc:状态 	            中立字段，某些表可使用某些表不使用
   * Default:1
   * Nullable:False
   */
  isEnabled?: boolean
  /**
   * Desc:中立字段，某些表可使用某些表不使用   	            逻辑上的删除，非物理删除  	            例如：单据删除并非直接删除
   * Default:0
   * Nullable:False
   */
  isDeleted?: boolean
  /**
   * Desc:中立字段 	            是否内置数据
   * Default:
   * Nullable:False
   */
  isInternal?: boolean
  /**
   * Desc:创建ID
   * Default:
   * Nullable:True
   */
  createId?: string | null
  /**
   * Desc:创建者
   * Default:
   * Nullable:True
   */
  createBy?: string | null
  /**
   * Desc:创建时间
   * Default:
   * Nullable:False
   * @format date-time
   */
  createTime?: string
  /**
   * Desc:修改ID
   * Default:
   * Nullable:True
   */
  modifyId?: string | null
  /**
   * Desc:更新者
   * Default:
   * Nullable:True
   */
  modifyBy?: string | null
  /**
   * Desc:修改日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  modifyTime?: string | null
  /**
   * Desc:数据版本
   * Default:0
   * Nullable:False
   */
  version?: string | null
  /**
   * Desc:删除ID
   * Default:
   * Nullable:True
   */
  deleteId?: string | null
  /**
   * Desc:删除者
   * Default:
   * Nullable:True
   */
  deleteBy?: string | null
  /**
   * Desc:删除日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  deleteTime?: string | null
}

export interface CreateDingtalkUserRelationInput {
  /**
   * Desc:雪花Id
   * Default:
   * Nullable:False
   */
  id?: string | null
  /**
   * Desc:律智荟 UserId
   * Default:
   * Nullable:True
   */
  relationUserId?: string | null
  /**
   * Desc:邮箱地址
   * Default:
   * Nullable:True
   */
  email?: string | null
  /**
   * Desc:手机号
   * Default:
   * Nullable:True
   */
  mobile?: string | null
  /**
   * Desc:钉钉用户的open_id
   * Default:
   * Nullable:True
   */
  openId?: string | null
  /**
   * Desc:钉钉用户的union_id
   * Default:
   * Nullable:True
   */
  unionId?: string | null
  /**
   * Desc:钉钉用户的user_id
   * Default:
   * Nullable:True
   */
  userId?: string | null
  /**
   * Desc:钉钉appid
   * Default:
   * Nullable:True
   */
  appId?: string | null
  /**
   * Desc:钉钉用户编号获取状态（N：未同步到，A：同步到其中任一Id）
   * Default:
   * Nullable:True
   */
  syncStatus?: string | null
  /**
   * Desc:租户ID
   * Default:0
   * Nullable:False
   */
  tenantId?: string | null
  /**
   * Desc:部门ID  DepartmentId
   * Default:0
   * Nullable:False
   */
  organizationUnitId?: string | null
  /**
   * Desc:分所Id
   * Default:0
   * Nullable:False
   */
  officeId?: string | null
  /**
   * Desc:备注
   * Default:
   * Nullable:True
   */
  remark?: string | null
  /**
   * Desc:状态 	            中立字段，某些表可使用某些表不使用
   * Default:1
   * Nullable:False
   */
  isEnabled?: boolean
  /**
   * Desc:中立字段，某些表可使用某些表不使用   	            逻辑上的删除，非物理删除  	            例如：单据删除并非直接删除
   * Default:0
   * Nullable:False
   */
  isDeleted?: boolean
  /**
   * Desc:中立字段 	            是否内置数据
   * Default:
   * Nullable:False
   */
  isInternal?: boolean
  /**
   * Desc:创建ID
   * Default:
   * Nullable:True
   */
  createId?: string | null
  /**
   * Desc:创建者
   * Default:
   * Nullable:True
   */
  createBy?: string | null
  /**
   * Desc:创建时间
   * Default:
   * Nullable:False
   * @format date-time
   */
  createTime?: string
  /**
   * Desc:修改ID
   * Default:
   * Nullable:True
   */
  modifyId?: string | null
  /**
   * Desc:更新者
   * Default:
   * Nullable:True
   */
  modifyBy?: string | null
  /**
   * Desc:修改日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  modifyTime?: string | null
  /**
   * Desc:数据版本
   * Default:0
   * Nullable:False
   */
  version?: string | null
  /**
   * Desc:删除ID
   * Default:
   * Nullable:True
   */
  deleteId?: string | null
  /**
   * Desc:删除者
   * Default:
   * Nullable:True
   */
  deleteBy?: string | null
  /**
   * Desc:删除日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  deleteTime?: string | null
}

export interface CreateDisplayDesensitizationInput {
  /** 分组名称 */
  groupName?: string | null
  /** 模块名称 */
  controllerName?: string | null
  /** 方法名称 */
  actionName?: string | null
  /** Dto名称 */
  dtoName?: string | null
  /**
   * 配置层级
   * @format int32
   */
  settingLevel?: 0 | 1 | 2 | 3
  /** 整体掩盖字符 */
  mainCharacter?: string | null
  /** 组织机构Id */
  orgIdList?: string[] | null
  /** 备注 */
  remark?: string | null
  /** 是否覆盖下级机构规则 */
  isCover?: boolean
  /** 显示脱敏规则 */
  displayDesensitizationRules?: DisplayDesensitizationRuleDto[] | null
  isEnabled?: boolean
}

/** 通用返回信息类 */
export interface CreateDisplayDesensitizationInputMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  response?: CreateDisplayDesensitizationInput
}

/** 创建企业信息 */
export interface CreateEnterpriseInfoInput {
  /** 公司名称 */
  enterpriseName?: string | null
  /** 执行许可号 */
  enterpriseCode?: string | null
  /** 法人姓名 */
  corporation?: string | null
  /** 统一社会信用代码 */
  creditCode?: string | null
  /** 邮箱地址 */
  email?: string | null
  /** 注册地址 */
  enterpriseAddress?: string | null
  isEnabled?: boolean
}

/** 实体联系人表 */
export interface CreateEntityContactInput {
  /**
   * Desc:雪花Id
   * Default:
   * Nullable:False
   */
  id?: string | null
  /**
   * Desc:联系人名称
   * Default:
   * Nullable:True
   */
  name?: string | null
  /**
   * Desc:别名
   * Default:
   * Nullable:True
   */
  altName?: string | null
  /**
   * Desc:外文名称
   * Default:
   * Nullable:True
   */
  foreignName?: string | null
  /**
   * Desc:速搜码，简码
   * Default:
   * Nullable:True
   */
  shortCode?: string | null
  /**
   * Desc:身份类别（客户联系人/法院法官/对方联系人/检察官/监狱长等）
   * Default:
   * Nullable:True
   */
  identityType?: string | null
  /**
   * Desc:记录来源
   * Default:
   * Nullable:True
   */
  loadSource?: string | null
  /**
   * Desc:性别
   * Default:
   * Nullable:True
   */
  gender?: string | null
  /**
   * Desc:民族
   * Default:
   * Nullable:True
   */
  nation?: string | null
  /**
   * Desc:生日
   * Default:
   * Nullable:False
   * @format date-time
   */
  birthday?: string
  /**
   * Desc:职位
   * Default:
   * Nullable:True
   */
  duty?: string | null
  /**
   * Desc:头像
   * Default:
   * Nullable:True
   */
  avatar?: string | null
  /**
   * Desc:图像/图片
   * Default:
   * Nullable:True
   */
  picture?: string | null
  /**
   * Desc:邮编
   * Default:
   * Nullable:True
   */
  zipCode?: string | null
  /**
   * Desc:通讯地址
   * Default:
   * Nullable:True
   */
  address?: string | null
  /**
   * Desc:所属地区
   * Default:
   * Nullable:True
   */
  region?: string | null
  /**
   * Desc:画像
   * Default:
   * Nullable:True
   */
  portraitNarrative?: string | null
  /**
   * Desc:邮箱地址
   * Default:
   * Nullable:True
   */
  emailAddress?: string | null
  /**
   * Desc:个人邮箱地址
   * Default:
   * Nullable:True
   */
  perEmailAddress?: string | null
  /**
   * Desc:联系固话
   * Default:
   * Nullable:True
   */
  landline?: string | null
  /**
   * Desc:移动电话
   * Default:
   * Nullable:True
   */
  phoneNumber?: string | null
  /**
   * Desc:个人移动电话
   * Default:
   * Nullable:True
   */
  perPhoneNumber?: string | null
  /**
   * Desc:传真
   * Default:
   * Nullable:True
   */
  fax?: string | null
  /**
   * Desc:网站主页
   * Default:
   * Nullable:True
   */
  webPortal?: string | null
  /**
   * Desc:证件类型
   * Default:
   * Nullable:True
   */
  creditType?: string | null
  /**
   * Desc:证件号码
   * Default:
   * Nullable:True
   */
  creditCode?: string | null
  /**
   * Desc:来源
   * Default:
   * Nullable:True
   */
  originSource?: string | null
  /**
   * Desc:即时通讯类型（微信/Line/Whatapp等）
   * Default:
   * Nullable:True
   */
  imType?: string | null
  /**
   * Desc:即时通讯号码
   * Default:
   * Nullable:True
   */
  imNumber?: string | null
  /**
   * Desc:即时通讯描述
   * Default:
   * Nullable:True
   */
  imNarrative?: string | null
  /**
   * Desc:即时通讯类型2
   * Default:
   * Nullable:True
   */
  imType2?: string | null
  /**
   * Desc:即时通讯号码2
   * Default:
   * Nullable:True
   */
  imNumber2?: string | null
  /**
   * Desc:即时通讯描述2
   * Default:
   * Nullable:True
   */
  imNarrative2?: string | null
  /**
   * Desc:社交媒体类型
   * Default:
   * Nullable:True
   */
  socialMediaType?: string | null
  /**
   * Desc:社交媒体编号
   * Default:
   * Nullable:True
   */
  socialMediaID?: string | null
  /**
   * Desc:社交媒体描述
   * Default:
   * Nullable:True
   */
  socialMediaNarrative?: string | null
  /**
   * Desc:描述说明
   * Default:
   * Nullable:True
   */
  narrative?: string | null
  /**
   * Desc:喜好/爱好
   * Default:
   * Nullable:True
   */
  beFondOf?: string | null
  /**
   * Desc:租户ID
   * Default:0
   * Nullable:False
   */
  tenantId?: string | null
  /**
   * Desc:部门ID  DepartmentId
   * Default:0
   * Nullable:False
   */
  organizationUnitId?: string | null
  /**
   * Desc:分所Id
   * Default:0
   * Nullable:False
   */
  officeId?: string | null
  /**
   * Desc:备注
   * Default:
   * Nullable:True
   */
  remark?: string | null
  isEnabled?: boolean
}

/** 实体联系人与客户案件关系表 */
export interface CreateEntityContactRelationshipInput {
  /**
   * Desc:联系人编号
   * Default:
   * Nullable:False
   */
  contactID?: string | null
  /**
   * Desc:客户编号
   * Default:
   * Nullable:False
   */
  clientID?: string | null
  /**
   * Desc:案件编号
   * Default:
   * Nullable:False
   */
  caseID?: string | null
  /**
   * Desc:说明描述
   * Default:
   * Nullable:True
   */
  narrative?: string | null
  /**
   * Desc:租户ID
   * Default:0
   * Nullable:False
   */
  tenantId?: string | null
  /**
   * Desc:部门ID  DepartmentId
   * Default:0
   * Nullable:False
   */
  organizationUnitId?: string | null
  /**
   * Desc:分所Id
   * Default:0
   * Nullable:False
   */
  officeId?: string | null
  /**
   * Desc:备注
   * Default:
   * Nullable:True
   */
  remark?: string | null
  /** 实体联系人表 */
  entityContact?: CreateEntityContactInput
  isEnabled?: boolean
}

/** 实体曾用名记录表 */
export interface CreateEntityFormerNameInput {
  /**
   * Desc:雪花Id
   * Default:
   * Nullable:False
   */
  id?: string | null
  /**
   * Desc:实体编号
   * Default:
   * Nullable:False
   */
  entityID?: string | null
  /**
   * Desc:曾用名
   * Default:
   * Nullable:True
   */
  name?: string | null
  /**
   * Desc:外文曾用名称
   * Default:
   * Nullable:True
   */
  foreignName?: string | null
  /**
   * Desc:名称使用开始时间
   * Default:
   * Nullable:False
   * @format date-time
   */
  beginDate?: string
  /**
   * Desc:名称使用结束时间
   * Default:
   * Nullable:False
   * @format date-time
   */
  endDate?: string
  /**
   * Desc:记录来源
   * Default:
   * Nullable:True
   */
  loadSource?: string | null
  /**
   * Desc:说明描述
   * Default:
   * Nullable:True
   */
  narrative?: string | null
  /**
   * Desc:实体类别（客户/联系人/股东/实控人/母公司等）
   * Default:
   * Nullable:True
   */
  entityType?: string | null
  /**
   * Desc:租户ID
   * Default:0
   * Nullable:False
   */
  tenantId?: string | null
  /**
   * Desc:部门ID  DepartmentId
   * Default:0
   * Nullable:False
   */
  organizationUnitId?: string | null
  /**
   * Desc:分所Id
   * Default:0
   * Nullable:False
   */
  officeId?: string | null
  /**
   * Desc:备注
   * Default:
   * Nullable:True
   */
  remark?: string | null
  isEnabled?: boolean
}

/** 实体主体信息表 */
export interface CreateEntityInfoInput {
  id?: string | null
  /** 实体或自然人名称 */
  name?: string | null
  /** 外文名称 */
  foreignName?: string | null
  /** 曾用户 */
  formerName?: string | null
  /** 别名 */
  altName?: string | null
  /** 速搜码，简码 */
  shortCode?: string | null
  /** 是否自然人 */
  isNaturalPerson?: boolean
  /** 是否涉外实体或个人 */
  isForeign?: boolean
  /** 客户类型（自然人/政府机构/实体） */
  category?: string | null
  /**
   * 实体类别（客户/客户实控人/股东等）
   * CP:客户,CPOC:客户股东或实控人,EP:参与实体,OP:对方当事人,OPOC:对方股东或实控人,OPE:对方关联方,TP:第三方,TPOC:第三方股东或实控人
   */
  entityType?: string | null
  /** 实体子类 */
  econKind?: string | null
  /** 注册号 */
  registerationNumber?: string | null
  /** 注册名称 */
  registerationName?: string | null
  /** 注册地址 */
  registerationAddress?: string | null
  /** 所属行业编号 */
  industryID?: string | null
  /** 所属行业名称 */
  industryText?: string | null
  /** 证件类型 */
  creditType?: string | null
  /** 证件号码（身份证/信用代码/护照） */
  creditCode?: string | null
  /** 地址 */
  address?: string | null
  /** 性别（自然人独占） */
  gender?: string | null
  /** 民族（自然人独占） */
  nation?: string | null
  /** 邮箱地址 */
  emailAddress?: string | null
  /** 固定电话 */
  landline?: string | null
  /** 移动电话 */
  phoneNumber?: string | null
  /** 传真 */
  fax?: string | null
  /** 邮政编码 */
  zipCode?: string | null
  /** 网站地址 */
  webPortal?: string | null
  /** 出生日期（自然人独占） */
  birthday?: string | null
  /** 法定代表人 */
  legalPerson?: string | null
  /** 法定代表人编号 */
  legalPersonID?: string | null
  /** 经营场所 */
  businessAddress?: string | null
  /** 税号 */
  taxNumber?: string | null
  /** 组织机构编号 */
  orgNumber?: string | null
  /** 经营范围 */
  businessScope?: string | null
  /** 注册资本 */
  registeredCapital?: string | null
  /** 实缴资本 */
  paidInCapital?: string | null
  /** 成立日期 */
  establishmentDate?: string | null
  /** 营业期限 */
  businessTerm?: string | null
  /** 营业执照发证日期 */
  licenseIssueDate?: string | null
  /** 发证机关（登记机关） */
  issuingAuthority?: string | null
  /** 经营状态（登记状态） */
  operationalStatus?: string | null
  /** 纳税人资质 */
  credential?: string | null
  /** 纳税人资质ID */
  credentialID?: string | null
  /** 核准日期 */
  issueDate?: string | null
  /** 所属国家 */
  country?: string | null
  /** 所属省或州郡 */
  province?: string | null
  /** 所属地区 */
  region?: string | null
  /** 进出口企业代码 */
  importExportCode?: string | null
  /** 人员规模 */
  staffSize?: string | null
  /** 参保人数 */
  numberOfInsured?: string | null
  /** 客户来源 */
  customerSource?: string | null
  /** 源实体编号 */
  originEntityID?: string | null
  /** 源实体名称 */
  originEntityName?: string | null
  /** 业务来源（立案/投标/入库等） */
  businessSource?: string | null
  /** 是否上市企业 */
  isListedEnterprise?: string | null
  /** 上市地区 */
  listedRegion?: string | null
  /** 上市机构 */
  listedOrg?: string | null
  /** 上市类别（新三板，创业板，A股等） */
  listedType?: string | null
  /** 上市股票代码 */
  listedStockNumber?: string | null
  /** 是否名录企业 */
  isDirectoryEnterprise?: string | null
  /** 是否500强企业 */
  isTop500?: string | null
  /** 是否小微企业 */
  isMicroEnterprise?: string | null
  /** 标签 */
  tags?: string | null
  /** 是否披露 */
  isDisclose?: string | null
  /** 客户级别 */
  clientLevel?: string | null
  /** 记录来源 */
  loadSource?: string | null
  /** 记录来源编号 */
  loadSourceID?: string | null
  /** logo链接 */
  logoUrl?: string | null
  /** Logo图片 */
  logoPicture?: string | null
  /** 头像 */
  avatar?: string | null
  /** 客户画像描述 */
  portraitNarrative?: string | null
  /** 是否吊销 */
  isRevoke?: boolean
  /** 吊销日期 */
  revokeDate?: string | null
  /** 吊销原因 */
  revokeRemark?: string | null
  /** 租户ID */
  tenantId?: string | null
  /** 部门ID  DepartmentId */
  organizationUnitId?: string | null
  /** 分所Id */
  officeId?: string | null
  /** 备注 */
  remark?: string | null
  /** 联系人 */
  contactName?: string | null
  isEnabled?: boolean
}

export interface CreateFormFieldAttributesInput {
  /** 显示名 */
  displayName?: string | null
  /** 是否显示 */
  isDisplay?: boolean
  /**
   * 字段表单类型
   * @format int32
   */
  formInputType?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20
  /** 默认数据集 */
  defaultValue?: Record<string, any>
  /** 前缀附件Id */
  startIconId?: string | null
  /** 前缀文本 */
  startText?: string | null
  /** 后缀附件Id */
  endIconId?: string | null
  /** 后缀文本 */
  endText?: string | null
  /**
   * 字间距
   * @format double
   */
  spacing?: number
  /**
   * 行间距
   * @format double
   */
  lineSpacing?: number
  /**
   * 对齐方式
   * @format int32
   */
  alignment?: 0 | 1 | 2 | 3
  /**
   * 标签宽度
   * @format int32
   */
  labelWidth?: 0 | 1 | 2
  /**
   * 字段大小
   * @format int32
   */
  fieldSize?: 0 | 1 | 2
  /** 提示语 */
  reminder?: string | null
  /** 提示文字 */
  placeholder?: string | null
  /** 帮助说明 */
  helpExplanation?: string | null
  /**
   * 字段宽度
   * @format int32
   */
  fieldWidth?: 0 | 1 | 2 | 3 | 4 | 5
  /** 千分位分隔符 */
  isThousandthSeparator?: boolean
  /** 百分位 */
  isPercentile?: boolean
  /** 正常颜色 */
  normalError?: string | null
  /** 错误颜色 */
  errorColor?: string | null
  /** 保留小数 */
  isReservedDecimal?: boolean
  /** 负值标红 */
  isNegativeRed?: boolean
  /** 数值调节按钮 */
  isNumericalAdjustment?: boolean
  /** 科学计数法 */
  isScientificNotation?: boolean
  /**
   * 长度
   * @format int32
   */
  length?: number
  /**
   * 小数位置
   * @format int32
   */
  decimalLocation?: number
  /**
   * 零值显示
   * @format int32
   */
  valueNullType?: 0 | 1 | 2
  /** 是否必填 */
  isRequired?: boolean
  /** 是否只读 */
  isRead?: boolean
  /** 是否清除 */
  isClean?: boolean
  /** 显示分钟 */
  isShowMinute?: boolean
  /** 显示分钟 */
  isSecond?: boolean
  /** Json配置集 */
  json?: Record<string, any>
  /** 是否是模板列 */
  isImportTemplateField?: boolean
  isEnabled?: boolean
}

export interface CreateFormValidateBatchInput {
  /** 是否覆盖子集配置  默认false */
  isCover?: boolean
  /**
   * 配置层级
   * @format int32
   */
  settingLevel?: 0 | 1 | 2 | 3
  /** Dto名称 */
  dtoName?: string | null
  /** 租户Id 应用不传 */
  tenantId?: string | null
  /** 分所Id 租户不传 */
  officeId?: string | null
  /** 部门Id */
  organizationUnitId?: string | null
  /** 配置规则 */
  formValidateList?: CreateFromValidateInput[] | null
  isEnabled?: boolean
}

export interface CreateFromValidateInput {
  /** 字段 */
  field?: string | null
  /** 字段类型 */
  fieldType?: string | null
  /** 验证规则参数 */
  formValidateRuleList?: CreateFromValidateRuleInput[] | null
  formFieldAttributes?: CreateFormFieldAttributesInput
}

export interface CreateFromValidateRuleInput {
  /**
   * 验证规则
   * @format int32
   */
  ruleType?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20
  /**
   * 弹窗规则
   * @format int32
   */
  alertRule?: 0 | 1 | 2
  /** 错误信息 */
  errorMessage?: string | null
  /** 值 */
  value?: string | null
  /** 是否是Dto字段 */
  isDtoField?: boolean
  /**
   * 最小长度
   * @format int32
   */
  minLength?: number
  /**
   * 最大长度
   * @format int32
   */
  maxLength?: number
  /**
   * 开始值
   * @format int32
   */
  start?: number
  /**
   * 结束值
   * @format int32
   */
  end?: number
  /**
   * 验证货币相关
   * @format int32
   */
  number?: number
  /**
   * 验证货币相关
   * @format int32
   */
  point?: number
  /** 验证货币相关 */
  ignoreTrailingZeros?: boolean
  isEnabled?: boolean
}

export interface CreateGroupClientRelshipInput {
  /** 集团编号 */
  groupID?: string | null
  /** 客户编号 */
  clientID?: string | null
  /** 说明描述 */
  narrative?: string | null
  isEnabled?: boolean
}

export interface CreateGroupInfoInput {
  /** 客户名称 */
  name?: string | null
  /** 外文名称 */
  foreignName?: string | null
  /** 曾用名 */
  formerName?: string | null
  /** 别名 */
  altName?: string | null
  /** 客户类型 */
  clientType?: string | null
  /** 证件类型 */
  creditType?: string | null
  /** 证件号码 */
  creditCode?: string | null
  /** 来源类型（立案/入库/投标） */
  originType?: string | null
  /** 来源编号 */
  originID?: string | null
  /** 通讯地址 */
  address?: string | null
  /** 税号 */
  taxNumber?: string | null
  /** 邮箱地址 */
  emailAddress?: string | null
  /** 手机号码 */
  phoneNumber?: string | null
  /** 固定电话 */
  landline?: string | null
  /** 描述说明 */
  narrative?: string | null
  /** 客户级别（星标客户/重要客户等） */
  clientLevel?: string | null
  /** 客户性质 */
  customerNature?: string | null
  /** 股东/实控人存入EntityInfo */
  entityInfosInput?: CreateEntityInfoInput[] | null
  isEnabled?: boolean
}

export interface CreateLanguageInput {
  /** 图标 */
  icon?: string | null
  /** 名称 */
  name?: string | null
  /** 代码 */
  code?: string | null
  /** 显示 */
  displayName?: string | null
  /** 租户 */
  tenantId?: string | null
  /** 组织机构 */
  organizationUnitId?: string | null
  /** 分所 */
  officeId?: string | null
  isEnabled?: boolean
}

export interface CreateOrganizationUnitLeadersInput {
  userId?: string | null
  isHost?: boolean
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null
}

/** 创建组织机构 */
export interface CreateOrganizationUnitsInput {
  /** 机构键值 */
  code?: string | null
  /** 显示名称 */
  displayName?: string | null
  /** 别名 */
  alterName?: string | null
  /** 地区代码 */
  regionCode?: string | null
  /** 负责人 */
  organizationUnitLeaders?: CreateOrganizationUnitLeadersInput[] | null
  /** 父级Id */
  parentId?: string | null
  /** 机构描述 */
  remark?: string | null
  /** 工作地点 */
  workAddress?: string | null
  /** 区别标识 */
  discriminator?: string | null
  /**
   * 默认排序
   * @format int32
   */
  sort?: number
  /** 是否启用 */
  isActive?: boolean
  /** 是否虚拟机构 */
  isVirtual?: boolean
  /** 是否总所 */
  isHeadOffice?: boolean
  /**
   * 阈值标识
   * @format int32
   */
  thresholdFlag?: 0 | 1 | 2 | 3
  /** 地理位置 */
  location?: string | null
  /** 创建企业信息 */
  enterpriseInfo?: CreateEnterpriseInfoInput
  isEnabled?: boolean
}

export interface CreateRoleInput {
  /** 角色名 */
  name?: string | null
  /** 显示名称 */
  alterName?: string | null
  /** 描述 */
  description?: string | null
  /**
   * 排序
   * @format int32
   */
  sort?: number
  /**
   * 权限类型
   * @format int32
   */
  roleType?: 0 | 1 | 2 | 3 | 4
  /** 分所Id */
  officeId?: string | null
  /** 复制的角色Id */
  copyRoleId?: string | null
  /** 用户组Id */
  roleGroupId?: string | null
  /** 是否独占 */
  isMonopoly?: boolean | null
  /** 是否启用 */
  isActive?: boolean
  /** 是否默认角色 */
  isDefault?: boolean
  /** 是否虚拟角色 */
  isVirtual?: boolean
  isEnabled?: boolean
}

export interface CreateSequenceNumberRulesInput {
  /** 编码名称 */
  name?: string | null
  /** 所属表单 */
  tableName?: string | null
  /** 编码字段 */
  field?: string | null
  /** 段间分隔符 */
  separator?: string | null
  /**
   * 应用规则
   * @format int32
   */
  applyRulesType?: 0 | 1
  /** 编码依据变化时重新编码 */
  isChange?: boolean
  /** 允许断号补偿 */
  isCompensate?: boolean
  /**
   * 补位衬垫位置
   * @format int32
   */
  paddingSideType?: 0 | 1
  /**
   * 起始值时间
   * @format date-time
   */
  startTime?: string
  /** 流水号单据编码配置 */
  sequenceNumberSettings?: SequenceNumberSettingsDto[] | null
  isEnabled?: boolean
}

/** 通用返回信息类 */
export interface CreateSequenceNumberRulesInputMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  response?: CreateSequenceNumberRulesInput
}

export interface CreateSettingInput {
  /** 值名称 */
  name?: string | null
  /**
   * 配置
   * @format int32
   */
  settingScopes?: 1 | 2 | 4
  /** 值 , json */
  value?: string | null
  /** 用户Id */
  userId?: string | null
  /** 租户Id */
  tenantId?: string | null
  /** 是否覆盖子集配置  默认false */
  isCover?: boolean
  isEnabled?: boolean
}

export interface CreateSupplierInput {
  /** 供应商类型 */
  supplierTypes?: (0 | 1 | 2 | 3)[] | null
  /** 名称 */
  name?: string | null
  /** 联系人 */
  contact?: string | null
  /** 联系电话 */
  telephone?: string | null
  /** 联系地址 */
  address?: string | null
  /** 邮箱 */
  email?: string | null
  isEnabled?: boolean
}

export interface CreateSupplierPluginInput {
  pluginName?: string | null
  pluginType?: string | null
  supplierId?: string | null
  ico?: string | null
  status?: boolean
  remark?: string | null
  fieldsJson?: string | null
  isEnabled?: boolean
}

export interface CreateSysEmailTemplatesInput {
  /**
   * Desc:模版名称 名字唯一
   * Default:
   * Nullable:False
   */
  templateName?: string | null
  /**
   * Desc:配置层级
   * Default:
   * Nullable:False
   * @format int32
   */
  settingLevel?: 0 | 1 | 2 | 3
  /**
   * Desc:模版正文
   * Default:
   * Nullable:False
   */
  templateContent?: string | null
  /**
   * Desc:模版描述
   * Default:
   * Nullable:True
   */
  templateSubject?: string | null
  /**
   * Desc:授权失效时间 分钟
   * Default:
   * Nullable:False
   * @format int32
   */
  authFailureTime?: number
  isEnabled?: boolean
}

/** 雇员表彰和奖励 */
export interface CreateSysEmployeeAwardsInput {
  /**
   * Desc:入职日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  entryDate?: string | null
  /**
   * Desc:类别
   * Default:
   * Nullable:True
   */
  type?: string | null
  /**
   * Desc:最后工作日
   * Default:
   * Nullable:True
   * @format date-time
   */
  lastWorkDate?: string | null
  /**
   * Desc:描述
   * Default:
   * Nullable:True
   */
  discription?: string | null
  /**
   * Desc:奖项
   * Default:
   * Nullable:True
   */
  awardName?: string | null
  /**
   * Desc:工号
   * Default:
   * Nullable:True
   */
  jobNumber?: string | null
  /**
   * Desc:奖励级别
   * Default:
   * Nullable:True
   * @format int32
   */
  level?: number | null
  /**
   * Desc:获奖日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  date?: string | null
  /**
   * Desc:雇员Id
   * Default:
   * Nullable:False
   */
  userId?: string | null
  isEnabled?: boolean
}

/** 雇员证书执照 */
export interface CreateSysEmployeeCertificatesInput {
  /**
   * Desc:证书名称
   * Default:
   * Nullable:True
   */
  name?: string | null
  /**
   * Desc:证书描述
   * Default:
   * Nullable:True
   */
  discription?: string | null
  /**
   * Desc:获得时间
   * Default:
   * Nullable:True
   * @format date-time
   */
  gettime?: string | null
  /**
   * Desc:学习证书Id
   * Default:
   * Nullable:True
   */
  learningCertificateId?: string | null
  /**
   * Desc:有效期至
   * Default:
   * Nullable:True
   * @format date-time
   */
  validuntil?: string | null
  /**
   * Desc:入职日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  entryDate?: string | null
  /**
   * Desc:证书编号
   * Default:
   * Nullable:True
   */
  certificateNo?: string | null
  /**
   * Desc:发证机构
   * Default:
   * Nullable:True
   */
  issauthority?: string | null
  /**
   * Desc:工号
   * Default:
   * Nullable:True
   */
  jobNumber?: string | null
  /**
   * Desc:审批状态
   * Default:
   * Nullable:True
   */
  approveStatus?: string | null
  /**
   * Desc:最后工作日
   * Default:
   * Nullable:True
   * @format date-time
   */
  lastWorkDate?: string | null
  /**
   * Desc:生效日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  effectiveDate?: string | null
  /**
   * Desc:证书照类型
   * Default:
   * Nullable:True
   */
  certificateType?: string | null
  /**
   * Desc:雇员Id
   * Default:
   * Nullable:False
   */
  userId?: string | null
  isEnabled?: boolean
}

/** 雇员教育经历 */
export interface CreateSysEmployeeEducationsInput {
  /**
   * Desc:学历
   * Default:
   * Nullable:True
   */
  educationLevel?: string | null
  /**
   * Desc:工号
   * Default:
   * Nullable:True
   */
  jobNumber?: string | null
  /**
   * Desc:培养方式
   * Default:
   * Nullable:True
   */
  trainingMode?: string | null
  /**
   * Desc:工作情况
   * Default:
   * Nullable:True
   */
  performance?: string | null
  /**
   * Desc:备注
   * Default:
   * Nullable:True
   */
  comments?: string | null
  /**
   * Desc:学制
   * Default:
   * Nullable:True
   */
  schoolSystme?: string | null
  /**
   * Desc:专业类别
   * Default:
   * Nullable:True
   */
  specializedfield?: string | null
  /**
   * Desc:专业授予机构
   * Default:
   * Nullable:True
   */
  grantingInstitution?: string | null
  /**
   * Desc:专业
   * Default:
   * Nullable:True
   */
  major?: string | null
  /**
   * Desc:毕业学校
   * Default:
   * Nullable:True
   */
  schoolCode?: string | null
  /**
   * Desc:班级排名
   * Default:
   * Nullable:True
   */
  classRanking?: string | null
  /**
   * Desc:学校名繁体
   * Default:
   * Nullable:True
   */
  school_zh_TW?: string | null
  /**
   * Desc:学位授予国家
   * Default:
   * Nullable:True
   */
  degreeCountry?: string | null
  /**
   * Desc:专业描述
   * Default:
   * Nullable:True
   */
  mainCourse?: string | null
  /**
   * Desc:专业描述
   * Default:
   * Nullable:True
   */
  majorDescription?: string | null
  /**
   * Desc:审批状态
   * Default:
   * Nullable:True
   */
  approveStatus?: string | null
  /**
   * Desc:学位
   * Default:
   * Nullable:True
   */
  degree?: string | null
  /**
   * Desc:所在城市
   * Default:
   * Nullable:True
   */
  location?: string | null
  /**
   * Desc:毕业学校名称
   * Default:
   * Nullable:True
   */
  school?: string | null
  /**
   * Desc:见证人
   * Default:
   * Nullable:True
   */
  prover?: string | null
  /**
   * Desc:是否主专业
   * Default:
   * Nullable:True
   */
  isFirstMajor?: boolean | null
  /**
   * Desc:是否最高学历
   * Default:
   * Nullable:True
   */
  isHighestEducation?: boolean | null
  /**
   * Desc:是否最高学位
   * Default:
   * Nullable:True
   */
  isHighestDegree?: boolean | null
  /**
   * Desc:是否第一学历
   * Default:
   * Nullable:True
   */
  isFirstEducationLevel?: boolean | null
  /**
   * Desc:学习形式
   * Default:
   * Nullable:True
   * @format int32
   */
  studyMode?: number | null
  /**
   * Desc:毕业类型
   * Default:
   * Nullable:True
   * @format int32
   */
  graduationType?: number | null
  /**
   * Desc:入职日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  entryDate?: string | null
  /**
   * Desc:结束日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  enddatE?: string | null
  /**
   * Desc:最后工作日
   * Default:
   * Nullable:True
   * @format date-time
   */
  lastWorkDate?: string | null
  /**
   * Desc:毕业学校名称_英文
   * Default:
   * Nullable:True
   */
  school_en_US?: string | null
  /**
   * Desc:雇员Id
   * Default:
   * Nullable:False
   */
  userId?: string | null
  isEnabled?: boolean
}

/** 材料管理 */
export interface CreateSysEmployeeEntryMaterialRecInput {
  /**
   * Desc:材料名称
   * Default:
   * Nullable:True
   */
  materialName?: string | null
  /**
   * Desc:所属单元
   * Default:
   * Nullable:True
   */
  onwerMou?: string | null
  /**
   * Desc:催办时间
   * Default:
   * Nullable:True
   * @format date-time
   */
  promotTime?: string | null
  /**
   * Desc:是否法定
   * Default:
   * Nullable:True
   */
  isLegal?: boolean | null
  /**
   * Desc:是否收到
   * Default:
   * Nullable:True
   */
  hasReceived?: boolean | null
  /**
   * Desc:退回备注
   * Default:
   * Nullable:True
   */
  giveBackRemark?: string | null
  /**
   * Desc:材料名称(文本)
   * Default:
   * Nullable:True
   */
  name?: string | null
  /**
   * Desc:提交日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  submitDate?: string | null
  /**
   * Desc:组织排序号
   * Default:
   * Nullable:True
   */
  organizationOrderNum?: string | null
  /**
   * Desc:工号
   * Default:
   * Nullable:True
   */
  jobNumber?: string | null
  /**
   * Desc:材料说明
   * Default:
   * Nullable:True
   */
  remark?: string | null
  /**
   * Desc:材料状态
   * Default:
   * Nullable:True
   */
  materialState?: string | null
  /**
   * Desc:创建时间
   * Default:
   * Nullable:True
   * @format date-time
   */
  createdTime?: string | null
  /**
   * Desc:准备阶段
   * Default:
   * Nullable:True
   */
  preparationPhase?: string | null
  /**
   * Desc:接收日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  receiveDate?: string | null
  /**
   * Desc:退回日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  giveBackDate?: string | null
  /**
   * Desc:未提交备注
   * Default:
   * Nullable:True
   */
  notSubmittedRemarks?: string | null
  /**
   * Desc:附件
   * Default:
   * Nullable:True
   */
  materialAttachment?: string | null
  /**
   * Desc:入职日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  entryDate?: string | null
  /**
   * Desc:处理备注
   * Default:
   * Nullable:True
   */
  handleRemark?: string | null
  /**
   * Desc:是否提交
   * Default:
   * Nullable:True
   */
  hasSubmitted?: boolean | null
  /**
   * Desc:预计提交日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  prepareSubmitDate?: string | null
  /**
   * Desc:是否必备
   * Default:
   * Nullable:True
   */
  isEssential?: boolean | null
  /**
   * Desc:所属单元层级
   * Default:
   * Nullable:True
   * @format int32
   */
  onwerMou_TreeLevel?: number | null
  /**
   * Desc:催办人
   * Default:
   * Nullable:True
   * @format int32
   */
  promoter?: number | null
  /**
   * Desc:所属单元路径
   * Default:
   * Nullable:True
   */
  onwerMou_TreePath?: string | null
  /**
   * Desc:材料图片缩略图
   * Default:
   * Nullable:True
   */
  materialImageThumbnail?: string | null
  /**
   * Desc:最后工作日
   * Default:
   * Nullable:True
   * @format date-time
   */
  lastWorkDate?: string | null
  /**
   * Desc:雇员Id
   * Default:
   * Nullable:False
   */
  userId?: string | null
  isEnabled?: boolean
}

/** 雇员家庭信息 */
export interface CreateSysEmployeeFamilyInput {
  /**
   * Desc:性别
   * Default:
   * Nullable:True
   * @format int32
   */
  gender?: number | null
  /**
   * Desc:组织排序号
   * Default:
   * Nullable:True
   */
  organizationOrderNum?: string | null
  /**
   * Desc:与本人关系
   * Default:
   * Nullable:True
   */
  relationType?: string | null
  /**
   * Desc:职级排序号
   * Default:
   * Nullable:True
   */
  jobLevelOrderNum?: string | null
  /**
   * Desc:业务修改人
   * Default:
   * Nullable:True
   * @format int32
   */
  businessModifiedBy?: number | null
  /**
   * Desc:出生日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  birthday?: string | null
  /**
   * Desc:最后工作日
   * Default:
   * Nullable:True
   * @format date-time
   */
  lastWorkDate?: string | null
  /**
   * Desc:审批状态
   * Default:
   * Nullable:True
   */
  approveStatus?: string | null
  /**
   * Desc:邮件
   * Default:
   * Nullable:True
   */
  email?: string | null
  /**
   * Desc:工号
   * Default:
   * Nullable:True
   */
  jobNumber?: string | null
  /**
   * Desc:职务
   * Default:
   * Nullable:True
   */
  jobPost?: string | null
  /**
   * Desc:入职日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  entryDate?: string | null
  /**
   * Desc:政治面貌
   * Default:
   * Nullable:True
   */
  politicalStatus?: string | null
  /**
   * Desc:手机号码
   * Default:
   * Nullable:True
   */
  mobile?: string | null
  /**
   * Desc:与本人关系名称
   * Default:
   * Nullable:True
   */
  relation?: string | null
  /**
   * Desc:联系电话
   * Default:
   * Nullable:True
   */
  telephone?: string | null
  /**
   * Desc:成员姓名
   * Default:
   * Nullable:True
   */
  name?: string | null
  /**
   * Desc:工作单位
   * Default:
   * Nullable:True
   */
  company?: string | null
  /**
   * Desc:雇员Id
   * Default:
   * Nullable:False
   */
  userId?: string | null
  isEnabled?: boolean
}

/** 雇员信息表 */
export interface CreateSysEmployeeInfosInput {
  /**
   * Desc:显示名称
   * Default:
   * Nullable:True
   */
  displayName?: string | null
  /**
   * Desc:入职时间
   * Default:
   * Nullable:True
   * @format date-time
   */
  entryTime?: string | null
  /**
   * Desc:别名
   * Default:
   * Nullable:True
   */
  alterName?: string | null
  /**
   * Desc:手机号
   * Default:
   * Nullable:True
   */
  phoneNumber?: string | null
  /**
   * Desc:描述
   * Default:
   * Nullable:True
   */
  description?: string | null
  /**
   * Desc:
   * Default:
   * Nullable:True
   */
  address?: string | null
  /**
   * Desc:邮箱
   * Default:
   * Nullable:True
   */
  emailAddress?: string | null
  /**
   * Desc:
   * Default:
   * Nullable:True
   * @format int32
   */
  sex?: number | null
  /**
   * Desc:生日
   * Default:
   * Nullable:True
   * @format date-time
   */
  birthday?: string | null
  /**
   * Desc:
   * Default:
   * Nullable:True
   * @format int32
   */
  age?: number | null
  /**
   * Desc:教育背景
   * Default:
   * Nullable:True
   */
  educationBackground?: string | null
  /**
   * Desc:工号
   * Default:
   * Nullable:True
   */
  jobNumber?: string | null
  /**
   * Desc:Id卡类型
   * Default:
   * Nullable:True
   */
  idCardType?: string | null
  /**
   * Desc:唯一标识号码
   * Default:
   * Nullable:True
   */
  idCardNumber?: string | null
  /**
   * Desc:毕业时间
   * Default:
   * Nullable:True
   * @format date-time
   */
  graduateDate?: string | null
  /**
   * Desc:婚姻状态
   * Default:
   * Nullable:True
   */
  maritalStatus?: string | null
  /**
   * Desc:用户类型
   * Default:
   * Nullable:True
   */
  userType?: string | null
  /**
   * Desc:
   * Default:
   * Nullable:True
   * @format int32
   */
  origin?: number | null
  /**
   * Desc:外部标识
   * Default:
   * Nullable:True
   */
  originalId?: string | null
  /**
   * Desc:户籍所在地
   * Default:
   * Nullable:True
   */
  householdRegister?: string | null
  /**
   * Desc:民族
   * Default:
   * Nullable:True
   */
  nation?: string | null
  /**
   * Desc:政治面貌
   * Default:
   * Nullable:True
   */
  politicalOutlook?: string | null
  /**
   * Desc:籍贯
   * Default:
   * Nullable:True
   */
  nativePlace?: string | null
  /**
   * Desc:兴趣爱好
   * Default:
   * Nullable:True
   */
  hobbies?: string | null
  /**
   * Desc:特长
   * Default:
   * Nullable:True
   */
  speciality?: string | null
  /**
   * Desc:参加工作时间
   * Default:
   * Nullable:True
   * @format date-time
   */
  beganWorkDate?: string | null
  /**
   * Desc:毕业学校
   * Default:
   * Nullable:True
   */
  graduateFrom?: string | null
  /**
   * Desc:专业
   * Default:
   * Nullable:True
   */
  major?: string | null
  /**
   * Desc:工作地址
   * Default:
   * Nullable:True
   */
  officeLocation?: string | null
  /**
   * Desc:工作电话
   * Default:
   * Nullable:True
   */
  workPhone?: string | null
  /**
   * Desc:传真
   * Default:
   * Nullable:True
   */
  fax?: string | null
  /**
   * Desc:座机
   * Default:
   * Nullable:True
   */
  landline?: string | null
  /**
   * Desc:头像地址
   * Default:
   * Nullable:True
   */
  pictureUrl?: string | null
  /**
   * Desc:关于我
   * Default:
   * Nullable:True
   */
  aboutMe?: string | null
  /**
   * Desc:最高学历
   * Default:
   * Nullable:True
   */
  highestEducation?: string | null
  /**
   * Desc:邮政编码
   * Default:
   * Nullable:True
   */
  postalCode?: string | null
  /**
   * Desc:对外费率
   * Default:
   * Nullable:True
   * @format double
   */
  externalRate?: number | null
  /**
   * Desc:内部费率
   * Default:
   * Nullable:True
   * @format double
   */
  internalRate?: number | null
  /**
   * Desc:币种
   * Default:
   * Nullable:True
   */
  currency?: string | null
  /**
   * Desc:擅长语言
   * Default:
   * Nullable:True
   */
  goodLanguage?: string | null
  /**
   * Desc:擅长专业
   * Default:
   * Nullable:True
   */
  goodProfessional?: string | null
  /**
   * Desc:原执业律师事务所
   * Default:
   * Nullable:True
   */
  formerLawyer?: string | null
  /**
   * Desc:执业证号
   * Default:
   * Nullable:True
   */
  formerLawyerNumber?: string | null
  /**
   * Desc:紧急联系人
   * Default:
   * Nullable:True
   */
  emergencyContact?: string | null
  /**
   * Desc:与其关系
   * Default:
   * Nullable:True
   */
  relationship?: string | null
  /**
   * Desc:紧急联系人电话
   * Default:
   * Nullable:True
   */
  emergencyContactPhone?: string | null
  /**
   * Desc:紧急联系人地址
   * Default:
   * Nullable:True
   */
  emergencyContactAddress?: string | null
  /**
   * Desc:律师关系存放地
   * Default:
   * Nullable:True
   */
  localRelationshipStorage?: string | null
  /**
   * Desc:律师关系存放日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  localRelationshipTime?: string | null
  /**
   * Desc:人事档案存放地
   * Default:
   * Nullable:True
   */
  personnelArchivesStorageAgency?: string | null
  /**
   * Desc:存档号
   * Default:
   * Nullable:True
   */
  archiveNumber?: string | null
  /**
   * Desc:存档日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  archiveTime?: string | null
  /**
   * Desc:雇员Id
   * Default:
   * Nullable:False
   */
  userId?: string | null
  isEnabled?: boolean
}

/** 雇员工作经历 */
export interface CreateSysEmployeeJobHistoryInput {
  /**
   * Desc:审批状态
   * Default:
   * Nullable:True
   */
  approveStatus?: string | null
  /**
   * Desc:部门_繁体
   * Default:
   * Nullable:True
   */
  department_zh_TW?: string | null
  /**
   * Desc:单位名称
   * Default:
   * Nullable:True
   */
  company?: string | null
  /**
   * Desc:工作业绩
   * Default:
   * Nullable:True
   */
  achievement?: string | null
  /**
   * Desc:月薪（税前）
   * Default:
   * Nullable:True
   * @format double
   */
  salary?: number | null
  /**
   * Desc:离职日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  dimissionDate?: string | null
  /**
   * Desc:职务_英文
   * Default:
   * Nullable:True
   */
  firstJob_en_US?: string | null
  /**
   * Desc:工作类型
   * Default:
   * Nullable:True
   */
  jobType?: string | null
  /**
   * Desc:企业规模
   * Default:
   * Nullable:True
   */
  companySize?: string | null
  /**
   * Desc:工号
   * Default:
   * Nullable:True
   */
  jobNumber?: string | null
  /**
   * Desc:与本人关系
   * Default:
   * Nullable:True
   */
  proverRelation?: string | null
  /**
   * Desc:部门_英文
   * Default:
   * Nullable:True
   */
  department_en_US?: string | null
  /**
   * Desc:证明人联系方式
   * Default:
   * Nullable:True
   */
  proverContactInfo?: string | null
  /**
   * Desc:下属人数
   * Default:
   * Nullable:True
   * @format int32
   */
  underlingNum?: number | null
  /**
   * Desc:部门
   * Default:
   * Nullable:True
   */
  department?: string | null
  /**
   * Desc:证明人
   * Default:
   * Nullable:True
   */
  proverName?: string | null
  /**
   * Desc:行业类别
   * Default:
   * Nullable:True
   */
  hangye?: string | null
  /**
   * Desc:单位介绍
   * Default:
   * Nullable:True
   */
  introduction?: string | null
  /**
   * Desc:职务_繁体
   * Default:
   * Nullable:True
   */
  firstJob_zh_TW?: string | null
  /**
   * Desc:工作性质
   * Default:
   * Nullable:True
   */
  workKind?: string | null
  /**
   * Desc:单位类型
   * Default:
   * Nullable:True
   */
  companyType?: string | null
  /**
   * Desc:开始日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  startDate?: string | null
  /**
   * Desc:职务
   * Default:
   * Nullable:True
   */
  firstJob?: string | null
  /**
   * Desc:单位名称_繁体
   * Default:
   * Nullable:True
   */
  company_zh_TW?: string | null
  /**
   * Desc:职级名称
   * Default:
   * Nullable:True
   */
  jobLevel?: string | null
  /**
   * Desc:职级名称_繁体
   * Default:
   * Nullable:True
   */
  jobLevel_zh_TW?: string | null
  /**
   * Desc:是否本单位工作经历
   * Default:
   * Nullable:True
   */
  whetherTheWorkExperienceOfTheUnit?: boolean | null
  /**
   * Desc:职级名称_英文
   * Default:
   * Nullable:True
   */
  jobLevel_en_US?: string | null
  /**
   * Desc:职位_繁体
   * Default:
   * Nullable:True
   */
  lastDog_zh_TW?: string | null
  /**
   * Desc:离职原因
   * Default:
   * Nullable:True
   */
  leaveReason?: string | null
  /**
   * Desc:入职日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  beginDate?: string | null
  /**
   * Desc:入职日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  entryDate?: string | null
  /**
   * Desc:结束日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  stopDate?: string | null
  /**
   * Desc:任职类型
   * Default:
   * Nullable:True
   */
  serviceType?: string | null
  /**
   * Desc:职位
   * Default:
   * Nullable:True
   */
  lastJog?: string | null
  /**
   * Desc:部门全称
   * Default:
   * Nullable:True
   */
  poiOldAdminNameTreePath?: string | null
  /**
   * Desc:职位英文
   * Default:
   * Nullable:True
   */
  lastJog_en_US?: string | null
  /**
   * Desc:工作地址
   * Default:
   * Nullable:True
   */
  address?: string | null
  /**
   * Desc:最后工作日
   * Default:
   * Nullable:True
   * @format date-time
   */
  lastWorkDate?: string | null
  /**
   * Desc:证明人职务
   * Default:
   * Nullable:True
   */
  proverJobPost?: string | null
  /**
   * Desc:工作职责
   * Default:
   * Nullable:True
   */
  responsibility?: string | null
  /**
   * Desc:雇员Id
   * Default:
   * Nullable:False
   */
  userId?: string | null
  isEnabled?: boolean
}

/** 语言能力 */
export interface CreateSysEmployeeLanguageAbilitiesInput {
  /**
   * Desc:语言
   * Default:
   * Nullable:True
   */
  language?: string | null
  /**
   * Desc:技能描述
   * Default:
   * Nullable:True
   */
  languageskill?: string | null
  /**
   * Desc:审批状态
   * Default:
   * Nullable:True
   */
  approveStatus?: string | null
  /**
   * Desc:掌握程度
   * Default:
   * Nullable:True
   */
  level?: string | null
  /**
   * Desc:书写能力
   * Default:
   * Nullable:True
   */
  writingAbility?: string | null
  /**
   * Desc:工号
   * Default:
   * Nullable:True
   */
  jobNumber?: string | null
  /**
   * Desc:最后工作日
   * Default:
   * Nullable:True
   * @format date-time
   */
  lastWorkDate?: string | null
  /**
   * Desc:是否母语
   * Default:
   * Nullable:True
   */
  isNative?: boolean | null
  /**
   * Desc:入职日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  entryDate?: string | null
  /**
   * Desc:阅读能力
   * Default:
   * Nullable:True
   */
  readingAbility?: string | null
  /**
   * Desc:口语能力
   * Default:
   * Nullable:True
   */
  speakingAbility?: string | null
  /**
   * Desc:雇员Id
   * Default:
   * Nullable:False
   */
  userId?: string | null
  isEnabled?: boolean
}

/** 雇员专业技术职务 */
export interface CreateSysEmployeeProfessionalTechnicalPostInfoInput {
  /**
   * Desc:工号
   * Default:
   * Nullable:True
   */
  jobNumber?: string | null
  /**
   * Desc:组织_层级
   * Default:
   * Nullable:True
   * @format int32
   */
  stdOrganization_TreeLevel?: number | null
  /**
   * Desc:资格评审单位
   * Default:
   * Nullable:True
   */
  qualificationEvaluationUnit?: string | null
  /**
   * Desc:备注
   * Default:
   * Nullable:True
   */
  remarks?: string | null
  /**
   * Desc:共享人
   * Default:
   * Nullable:True
   */
  permissionBy?: string | null
  /**
   * Desc:是否最高级别
   * Default:
   * Nullable:True
   */
  isTopLevel?: boolean | null
  /**
   * Desc:组织
   * Default:
   * Nullable:True
   * @format int32
   */
  stdOrganization?: number | null
  /**
   * Desc:聘任起始时间
   * Default:
   * Nullable:True
   * @format date-time
   */
  appointStartDate?: string | null
  /**
   * Desc:评定日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  assessmentDate?: string | null
  /**
   * Desc:所属单位层级
   * Default:
   * Nullable:True
   * @format int32
   */
  onwerMou_TreeLevel?: number | null
  /**
   * Desc:熟悉专业专长
   * Default:
   * Nullable:True
   */
  professionalSpeciality?: string | null
  /**
   * Desc:审批状态
   * Default:
   * Nullable:True
   */
  approveStatus?: string | null
  /**
   * Desc:所属单位
   * Default:
   * Nullable:True
   */
  onwerMou?: string | null
  /**
   * Desc:最后工作日
   * Default:
   * Nullable:True
   * @format date-time
   */
  lastWorkDate?: string | null
  /**
   * Desc:入职日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  entryDate?: string | null
  /**
   * Desc:共享部门
   * Default:
   * Nullable:True
   */
  permissionOrganization?: string | null
  /**
   * Desc:获取资历途径
   * Default:
   * Nullable:True
   */
  accessWayID?: string | null
  /**
   * Desc:共享部门，包含下级
   * Default:
   * Nullable:True
   */
  permissionOrganization_SelfAndChildren?: string | null
  /**
   * Desc:职任专业技术级别
   * Default:
   * Nullable:True
   */
  appointTechnicalLevelID?: string | null
  /**
   * Desc:职任单位
   * Default:
   * Nullable:True
   */
  appointCompany?: string | null
  /**
   * Desc:共享部门，仅自己
   * Default:
   * Nullable:True
   */
  permissionOrganization_SelfOnly?: string | null
  /**
   * Desc:专业技术职务任职资格名称
   * Default:
   * Nullable:True
   */
  technicalPostQualificationsID?: string | null
  /**
   * Desc:所属单元路径
   * Default:
   * Nullable:True
   */
  onwerMou_TreePath?: string | null
  /**
   * Desc:组织路径
   * Default:
   * Nullable:True
   */
  stdOrganization_TreePath?: string | null
  /**
   * Desc:职任专业技术职务名称
   * Default:
   * Nullable:True
   */
  appointTechnicalPostQualificationsID?: string | null
  /**
   * Desc:专业技术级别
   * Default:
   * Nullable:True
   */
  technicalLevelID?: string | null
  /**
   * Desc:职任结束时间
   * Default:
   * Nullable:True
   * @format date-time
   */
  appointEndDate?: string | null
  /**
   * Desc:雇员Id
   * Default:
   * Nullable:False
   */
  userId?: string | null
  isEnabled?: boolean
}

/** 雇员项目经历 */
export interface CreateSysEmployeeProjectExperiencesInput {
  /**
   * Desc:职位
   * Default:
   * Nullable:True
   */
  jobPosition?: string | null
  /**
   * Desc:最后工作日
   * Default:
   * Nullable:True
   * @format date-time
   */
  lastWorkDate?: string | null
  /**
   * Desc:项目人数
   * Default:
   * Nullable:True
   * @format int32
   */
  peopleNumber?: number | null
  /**
   * Desc:硬件环境
   * Default:
   * Nullable:True
   */
  hardware?: string | null
  /**
   * Desc:职位拼音排序
   * Default:
   * Nullable:True
   */
  jobPosition_Pinyin?: string | null
  /**
   * Desc:开发工具
   * Default:
   * Nullable:True
   */
  devTool?: string | null
  /**
   * Desc:软件环境
   * Default:
   * Nullable:True
   */
  software?: string | null
  /**
   * Desc:开始日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  startDate?: string | null
  /**
   * Desc:项目名称
   * Default:
   * Nullable:True
   */
  name?: string | null
  /**
   * Desc:描述
   * Default:
   * Nullable:True
   */
  description?: string | null
  /**
   * Desc:项目成果
   * Default:
   * Nullable:True
   */
  achievement?: string | null
  /**
   * Desc:结束日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  endDate?: string | null
  /**
   * Desc:项目职责
   * Default:
   * Nullable:True
   */
  responsibility?: string | null
  /**
   * Desc:入职日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  entryDate?: string | null
  /**
   * Desc:审批状态
   * Default:
   * Nullable:True
   */
  approveStatus?: string | null
  /**
   * Desc:工号
   * Default:
   * Nullable:True
   */
  jobNumber?: string | null
  /**
   * Desc:雇员Id
   * Default:
   * Nullable:False
   */
  userId?: string | null
  isEnabled?: boolean
}

/** 雇员专业技能 */
export interface CreateSysEmployeeSkillInput {
  /**
   * Desc:审批状态
   * Default:
   * Nullable:True
   */
  approveStatus?: string | null
  /**
   * Desc:掌握程度
   * Default:
   * Nullable:True
   */
  level?: string | null
  /**
   * Desc:使用时间（月）
   * Default:
   * Nullable:True
   * @format int32
   */
  useLength?: number | null
  /**
   * Desc:入职日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  entryDate?: string | null
  /**
   * Desc:最后工作日
   * Default:
   * Nullable:True
   * @format date-time
   */
  lastWorkDate?: string | null
  /**
   * Desc:种类
   * Default:
   * Nullable:True
   */
  category?: string | null
  /**
   * Desc:工号
   * Default:
   * Nullable:True
   */
  jobNumber?: string | null
  /**
   * Desc:名称
   * Default:
   * Nullable:True
   */
  name?: string | null
  /**
   * Desc:雇员Id
   * Default:
   * Nullable:False
   */
  userId?: string | null
  isEnabled?: boolean
}

/** 培训经历 */
export interface CreateSysEmployeeTrainingsInput {
  /**
   * Desc:培训描述
   * Default:
   * Nullable:True
   */
  discription?: string | null
  /**
   * Desc:是否获得勋章
   * Default:
   * Nullable:True
   */
  isHaveMedal?: boolean | null
  /**
   * Desc:开始日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  startDate?: string | null
  /**
   * Desc:适用场景
   * Default:
   * Nullable:True
   */
  applyScene?: string | null
  /**
   * Desc:培训机构
   * Default:
   * Nullable:True
   */
  trainingAgency?: string | null
  /**
   * Desc:培训成绩
   * Default:
   * Nullable:True
   */
  result?: string | null
  /**
   * Desc:培训表现
   * Default:
   * Nullable:True
   */
  evaluation?: string | null
  /**
   * Desc:获得学分
   * Default:
   * Nullable:True
   * @format double
   */
  getCredit?: number | null
  /**
   * Desc:结束日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  stopDate?: string | null
  /**
   * Desc:名称
   * Default:
   * Nullable:True
   */
  course?: string | null
  /**
   * Desc:最后工作日
   * Default:
   * Nullable:True
   * @format date-time
   */
  lastWorkDate?: string | null
  /**
   * Desc:入职日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  entryDate?: string | null
  /**
   * Desc:培训类别
   * Default:
   * Nullable:True
   */
  trainingCategory?: string | null
  /**
   * Desc:培训类别名称
   * Default:
   * Nullable:True
   */
  trainType?: string | null
  /**
   * Desc:课时总计
   * Default:
   * Nullable:True
   */
  timeToTile?: string | null
  /**
   * Desc:及格状态
   * Default:
   * Nullable:True
   */
  passStatus?: string | null
  /**
   * Desc:工号
   * Default:
   * Nullable:True
   */
  jobNumber?: string | null
  /**
   * Desc:审批状态
   * Default:
   * Nullable:True
   */
  approveStatus?: string | null
  /**
   * Desc:辅导导师
   * Default:
   * Nullable:True
   * @format int32
   */
  mentorUserId?: number | null
  /**
   * Desc:获得证书
   * Default:
   * Nullable:True
   */
  certificate?: string | null
  /**
   * Desc:完成状态
   * Default:
   * Nullable:True
   */
  completionStatus?: string | null
  /**
   * Desc:培训活动编号
   * Default:
   * Nullable:True
   */
  trainingActivityNum?: string | null
  /**
   * Desc:雇员Id
   * Default:
   * Nullable:False
   */
  userId?: string | null
  isEnabled?: boolean
}

/** 雇员执业（职业）资格信息 */
export interface CreateSysEmployeeVocationalQualificationInfoInput {
  /**
   * Desc:资格名称
   * Default:
   * Nullable:True
   */
  qualificationNameID?: string | null
  /**
   * Desc:资格级别
   * Default:
   * Nullable:True
   */
  qualificationLevelID?: string | null
  /**
   * Desc:组织路径
   * Default:
   * Nullable:True
   */
  stdOrganization_TreePath?: string | null
  /**
   * Desc:获取资格途径
   * Default:
   * Nullable:True
   */
  accessWayID?: string | null
  /**
   * Desc:证书附件
   * Default:
   * Nullable:True
   */
  accessory?: string | null
  /**
   * Desc:组织层级
   * Default:
   * Nullable:True
   * @format int32
   */
  stdOrganization_TreeLevel?: number | null
  /**
   * Desc:共享部门_包含下级
   * Default:
   * Nullable:True
   */
  permissionOrganization_SelfAndChildren?: string | null
  /**
   * Desc:工号
   * Default:
   * Nullable:True
   */
  jobNumber?: string | null
  /**
   * Desc:资格类型
   * Default:
   * Nullable:True
   */
  qualificationTypeID?: string | null
  /**
   * Desc:共享部门
   * Default:
   * Nullable:True
   */
  permissionOrganization?: string | null
  /**
   * Desc:取得日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  dateOfAcquisition?: string | null
  /**
   * Desc:共享人
   * Default:
   * Nullable:True
   */
  permissionBy?: string | null
  /**
   * Desc:证书编号
   * Default:
   * Nullable:True
   */
  certificateNumber?: string | null
  /**
   * Desc:所属单元_层级
   * Default:
   * Nullable:True
   * @format int32
   */
  onverMou_TreeLevel?: number | null
  /**
   * Desc:有效期至
   * Default:
   * Nullable:True
   * @format date-time
   */
  validUntil?: string | null
  /**
   * Desc:入职日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  entryDate?: string | null
  /**
   * Desc:期限类型
   * Default:
   * Nullable:True
   */
  termTypeId?: string | null
  /**
   * Desc:是否最高等级
   * Default:
   * Nullable:True
   */
  supremeGrade?: boolean | null
  /**
   * Desc:申请类型
   * Default:
   * Nullable:True
   */
  applicationTypeId?: string | null
  /**
   * Desc:最后工作日
   * Default:
   * Nullable:True
   * @format date-time
   */
  lastWorkDate?: string | null
  /**
   * Desc:所属单位
   * Default:
   * Nullable:True
   */
  ownerMou?: string | null
  /**
   * Desc:所属单位路径
   * Default:
   * Nullable:True
   */
  ownerMou_TreePath?: string | null
  /**
   * Desc:专业
   * Default:
   * Nullable:True
   */
  professional?: string | null
  /**
   * Desc:组织
   * Default:
   * Nullable:True
   */
  stdOrganization?: string | null
  /**
   * Desc:证据来源
   * Default:
   * Nullable:True
   */
  sourceLicensiesID?: string | null
  /**
   * Desc:共享部门_仅自己
   * Default:
   * Nullable:True
   */
  permissionOrganization_SelfOnly?: string | null
  /**
   * Desc:审批状态
   * Default:
   * Nullable:True
   */
  approveStatus?: string | null
  /**
   * Desc:发布机构
   * Default:
   * Nullable:True
   */
  issueUnit?: string | null
  /**
   * Desc:证书编号
   * Default:
   * Nullable:True
   */
  certificateNumberUpper?: string | null
  /**
   * Desc:雇员Id
   * Default:
   * Nullable:False
   */
  userId?: string | null
  isEnabled?: boolean
}

/** Excel导出记录 */
export interface CreateSysExcelExportCenterInput {
  /** 导出文件地址 */
  path?: string | null
  /**
   * 文件大小
   * @format int32
   */
  size?: number
  /**
   * 请求耗时 单位秒
   * @format int32
   */
  requestDuration?: number
  /** 模块 */
  module?: string | null
  /** 参数 */
  searchParameter?: string | null
  isEnabled?: boolean
}

/** 第三方通知记录表   //备注使用Remark */
export interface CreateSysExternalNotificationHistoriesInput {
  /**
   * Desc:正文
   * Default:
   * Nullable:False
   */
  notificationContent?: string | null
  /**
   * Desc:通知类型
   * Default:
   * Nullable:False
   * @format int32
   */
  notificationType?: 0 | 1 | 2 | 3
  /**
   * Desc:关联Id
   * Default:
   * Nullable:True
   */
  associatedId?: string | null
  /**
   * Desc:参数
   * Default:
   * Nullable:True
   */
  parameterJson?: string | null
  /**
   * Desc:返回参数
   * Default:
   * Nullable:True
   */
  responseJson?: string | null
  isEnabled?: boolean
}

/** 字典分组 */
export interface CreateSysGeneralCodeGroupsInput {
  /**
   * Desc:名称
   * Default:
   * Nullable:False
   */
  name?: string | null
  /**
   * Desc:代码
   * Default:
   * Nullable:False
   */
  code?: string | null
  /**
   * Desc:父级代码
   * Default:
   * Nullable:True
   */
  parentCode?: string | null
  /**
   * Desc:模块
   * Default:
   * Nullable:False
   * @format int32
   */
  funcGroup?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7
  isEnabled?: boolean
}

/** 角色分组 */
export interface CreateSysRoleGroupsInput {
  /**
   * Desc:分组名称
   * Default:
   * Nullable:False
   */
  name?: string | null
  /**
   * Desc:上级Id
   * Default:
   * Nullable:True
   */
  parentId?: string | null
  /**
   * 权限类型
   * @format int32
   */
  roleType?: 0 | 1 | 2 | 3 | 4
  /**
   * Desc:排序
   * Default:0
   * Nullable:True
   * @format int32
   */
  sort?: number | null
  isEnabled?: boolean
}

export interface CreateUserInfoInput {
  /** 登录账号 */
  userName?: string | null
  password?: string | null
  /** 显示名称 */
  displayName?: string | null
  /** 工号 */
  jobNumber?: string | null
  /** 别名 */
  alterName?: string | null
  /** 手机号 */
  phoneNumber?: string | null
  /** 邮箱 */
  emailAddress?: string | null
  /** 姓名 */
  name?: string | null
  /** 组织机构 */
  organizationUnitId?: string | null
  /**
   * 性别
   * @format int32
   */
  sex?: number
  /**
   * 年龄
   * @format int32
   */
  age?: number | null
  /**
   * 生日
   * @format date-time
   */
  birth?: string | null
  address?: string | null
  /**
   * 用户类型
   * @format int32
   */
  userType?: 0 | 1 | null
  /** 默认角色 */
  defaultRoleId?: string | null
  /** 是否在本系统创建 */
  isOwned?: boolean | null
  /** 描述 */
  description?: string | null
  /**
   * 默认排序
   * @format int32
   */
  sort?: number | null
  /** 是否默认角色 */
  isDefault?: boolean | null
  /** 是否启用 */
  isActive?: boolean | null
  /** 必智运维账号 */
  isBitzOwned?: boolean | null
  /** 数据权限 */
  userOrganizationUnitInputs?: CreateUserOrganizationUnitInput[] | null
  /** 角色配置 */
  roleIds?: string[] | null
  /** 岗位 */
  positionIds?: string[] | null
  /** 用户组 */
  userGroupIds?: string[] | null
  /**
   * 入职时间
   * @format date-time
   */
  entryTime?: string
  /** 是否发送邮件 */
  isSendEmail?: boolean
  /** 是否在下次登录时重置密码 */
  isResetPassword?: boolean
  isEnabled?: boolean
}

export interface CreateUserOrganizationUnitInput {
  roleId?: string | null
  userId?: string | null
  isActive?: boolean
  /** @format int32 */
  thresholdFlag?: 0 | 1 | 2 | 3 | 4 | 5
}

export interface CreditCodeNewResult {
  /** 企业名称 */
  name?: string | null
  /** 统一社会信用代码（纳税人识别号） */
  creditCode?: string | null
  /** 企业类型 */
  econKind?: string | null
  /** 企业状态 */
  status?: string | null
  /** 地址 */
  address?: string | null
  /** 联系电话 */
  tel?: string | null
  /** 开户行 */
  bank?: string | null
  /** 开户行账号 */
  bankAccount?: string | null
}

/** 通用返回信息类 */
export interface CreditCodeNewResultMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  response?: CreditCodeNewResult
}

export interface CustomerDueDeliData {
  /** 主键 */
  keyNo?: string | null
  /** 企业名称 */
  name?: string | null
  /** 统一社会信用代码 */
  creditCode?: string | null
  /** 法定代表人 */
  operName?: string | null
  /** 登记状态 */
  status?: string | null
  /** 成立日期，精确到天，如“2022-01-01” */
  startDate?: string | null
  /** 注册资本 */
  registCapi?: string | null
  /** 实缴资本 */
  realCapi?: string | null
  /** 组织机构代码 */
  orgNo?: string | null
  /** 注册号或企业编号 */
  no?: string | null
  /** 纳税人识别号 */
  taxNo?: string | null
  /** 企业类型 */
  econKind?: string | null
  /** 营业期限始，精确到天，如“2022-01-01” */
  termStart?: string | null
  /** 营业期限至，精确到天，如“2022-01-01” */
  termEnd?: string | null
  /** 纳税人资质 */
  taxpayerType?: string | null
  /** 人员规模 */
  personScope?: string | null
  /** 参保人数 */
  insuredCount?: string | null
  /** 核准日期，精确到天，如“2022-01-01” */
  checkDate?: string | null
  /** 所属地区代码 */
  areaCode?: string | null
  area?: Area
  /** 登记机关 */
  belongOrg?: string | null
  /** 进出口企业代码 */
  imExCode?: string | null
  industry?: IndustryInfo
  /** 英文名 */
  englishName?: string | null
  /** 注册地址 */
  address?: string | null
  /** 通信地址 */
  annualAddress?: string | null
  /** 经营范围 */
  scope?: string | null
  /** 企业性质，0-大陆企业，1-社会组织 ，3-中国香港公司，4-事业单位，5-中国台湾公司，7-医院 */
  entType?: string | null
  /** 企业Logo地址 */
  imageUrl?: string | null
  revokeInfo?: RevokeInfo
  /** 曾用名 */
  originalName?: OriginalName[] | null
  stockInfo?: StockInfo
  contactInfo?: ContactInfo
  longLat?: LongLat
  bankInfo?: BankInfo
  /** 是否是小微企业，1-是，0-不是 */
  isSmall?: string | null
  /** 企业规模（L：大型、M：中型、S：小型、XS：微型） */
  scale?: string | null
  qccIndustry?: QccIndustry
  /** 英文名来源 1：官方标识 ，0：非官方标识，-1：未标识 */
  isOfficialEnglish?: string | null
  /** 工商登记股东信息（返回前100条） */
  partnerList?: PartnerListItem[] | null
  /** 最新公示股东信息（返回前100条） */
  pubPartnerList?: PubPartnerListItem[] | null
  /** 工商登记主要人员（返回前100条） */
  employeeList?: EmployeeListItem[] | null
  /** 最新公示主要人员（返回前100条） */
  pubEmployeeList?: PubEmployeeListItem[] | null
  /** 分支机构（返回前100条） */
  branchList?: BranchListItem[] | null
  /** 变更信息（返回前100条） */
  changeList?: ChangeListItem[] | null
  /** 企业标签\企业画像 */
  tagList?: TagListItem[] | null
  parent?: Parent
  /** 受益所有人 */
  beneficiaryList?: Beneficiary[] | null
  /** 实际控制人 */
  actualControllerList?: ActualControllerListItem[] | null
  /** 新兴行业分类 */
  emergingIndustyList?: EmergingIndustyListItem[] | null
  groupInfo?: GroupInfo
  /** 对外投资（返回前100条） */
  investmentList?: InvestmentListItem[] | null
  /** 产品列表（返回前100条） */
  productList?: ProductListItem[] | null
  /** 行政许可（返回前100条） */
  adminLicenseList?: AdminLicenseListItem[] | null
  /** 备案网站（返回前100条） */
  approveSiteList?: ApproveSiteListItem[] | null
  /** 抽查检查（返回前100条） */
  spotCheckList?: SpotCheckListItem[] | null
  /** 纳税信用等级（返回前100条） */
  taxCreditList?: TaxCreditListItem[] | null
  clientInfo?: ClientInfoDto
}

/** 通用返回信息类 */
export interface CustomerDueDeliDataMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  response?: CustomerDueDeliData
}

export interface DingtalkDepartmentDto {
  /**
   * Desc:雪花Id
   * Default:
   * Nullable:False
   */
  id?: string | null
  /**
   * Desc:应用编号
   * Default:
   * Nullable:True
   */
  appId?: string | null
  /**
   * Desc:部门ID
   * Default:
   * Nullable:True
   */
  deptId?: string | null
  /**
   * Desc:部门名称
   * Default:
   * Nullable:True
   */
  name?: string | null
  /**
   * Desc:父部门Id
   * Default:
   * Nullable:True
   */
  parentId?: string | null
  /**
   * Desc:是否同步创建一个关联此部门的企业群
   * Default:
   * Nullable:True
   */
  createDeptGroup?: boolean | null
  /**
   * Desc:部门群已经创建后，有新人加入部门是否会自动加入该群
   * Default:
   * Nullable:True
   */
  autoAddUser?: boolean | null
  /**
   * Desc:部门标识字段
   * Default:
   * Nullable:True
   */
  sourceIdentifier?: string | null
  /**
   * Desc:是否默认同意加入该部门的申请
   * Default:
   * Nullable:True
   */
  autoApproveApply?: boolean | null
  /**
   * Desc:部门是否来自关联组织
   * Default:
   * Nullable:True
   */
  fromUnionOrg?: boolean | null
  /**
   * Desc:教育部门标签
   * Default:
   * Nullable:True
   */
  tags?: string | null
  /**
   * Desc:在父部门中的次序值
   * Default:
   * Nullable:True
   */
  order?: string | null
  /**
   * Desc:部门群ID
   * Default:
   * Nullable:True
   */
  deptGroupChatId?: string | null
  /**
   * Desc:部门群是否包含子部门
   * Default:
   * Nullable:True
   */
  groupContainSubDept?: boolean | null
  /**
   * Desc:租户ID
   * Default:0
   * Nullable:False
   */
  tenantId?: string | null
  /**
   * Desc:部门ID  DepartmentId
   * Default:0
   * Nullable:False
   */
  organizationUnitId?: string | null
  /**
   * Desc:分所Id
   * Default:0
   * Nullable:False
   */
  officeId?: string | null
  /**
   * Desc:备注
   * Default:
   * Nullable:True
   */
  remark?: string | null
  /**
   * Desc:状态 	            中立字段，某些表可使用某些表不使用
   * Default:1
   * Nullable:False
   */
  isEnabled?: boolean
  /**
   * Desc:中立字段，某些表可使用某些表不使用   	            逻辑上的删除，非物理删除  	            例如：单据删除并非直接删除
   * Default:0
   * Nullable:False
   */
  isDeleted?: boolean
  /**
   * Desc:中立字段 	            是否内置数据
   * Default:
   * Nullable:False
   */
  isInternal?: boolean
  /**
   * Desc:创建ID
   * Default:
   * Nullable:True
   */
  createId?: string | null
  /**
   * Desc:创建者
   * Default:
   * Nullable:True
   */
  createBy?: string | null
  /**
   * Desc:创建时间
   * Default:
   * Nullable:False
   * @format date-time
   */
  createTime?: string
  /**
   * Desc:修改ID
   * Default:
   * Nullable:True
   */
  modifyId?: string | null
  /**
   * Desc:更新者
   * Default:
   * Nullable:True
   */
  modifyBy?: string | null
  /**
   * Desc:修改日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  modifyTime?: string | null
  /**
   * Desc:数据版本
   * Default:0
   * Nullable:False
   */
  version?: string | null
  /**
   * Desc:删除ID
   * Default:
   * Nullable:True
   */
  deleteId?: string | null
  /**
   * Desc:删除者
   * Default:
   * Nullable:True
   */
  deleteBy?: string | null
  /**
   * Desc:删除日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  deleteTime?: string | null
}

/** 通用返回信息类 */
export interface DingtalkDepartmentDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 返回数据集合 */
  response?: DingtalkDepartmentDto[] | null
}

/** 通用返回信息类 */
export interface DingtalkDepartmentDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  response?: DingtalkDepartmentDto
}

/** 通用分页信息类 */
export interface DingtalkDepartmentDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number
  /** 返回数据 */
  data?: DingtalkDepartmentDto[] | null
}

/** 通用返回信息类 */
export interface DingtalkDepartmentDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 通用分页信息类 */
  response?: DingtalkDepartmentDtoPageModel
}

export interface DingtalkUserDto {
  /**
   * Desc:雪花Id
   * Default:
   * Nullable:False
   */
  id?: string | null
  /**
   * Desc:应用编号
   * Default:
   * Nullable:False
   */
  appId?: string | null
  /**
   * Desc:BitzOrcas UserId
   * Default:
   * Nullable:False
   */
  relationUserId?: string | null
  /**
   * Desc:用户名
   * Default:
   * Nullable:True
   */
  name?: string | null
  /**
   * Desc:用户英文名称
   * Default:
   * Nullable:True
   */
  enName?: string | null
  /**
   * Desc:昵称
   * Default:
   * Nullable:True
   */
  nickName?: string | null
  /**
   * Desc:区域代码  +86
   * Default:
   * Nullable:True
   */
  stateCode?: string | null
  /**
   * Desc:邮箱地址
   * Default:
   * Nullable:True
   */
  email?: string | null
  /**
   * Desc:机构邮箱地址
   * Default:
   * Nullable:True
   */
  orgEmail?: string | null
  /**
   * Desc:手机号
   * Default:
   * Nullable:True
   */
  mobile?: string | null
  /**
   * Desc:是否显示手机号
   * Default:
   * Nullable:True
   */
  mobileVisible?: boolean | null
  /**
   * Desc:分机号
   * Default:
   * Nullable:True
   */
  telephone?: string | null
  /**
   * Desc:钉钉用户的open_id
   * Default:
   * Nullable:True
   */
  openId?: string | null
  /**
   * Desc:钉钉用户的union_id
   * Default:
   * Nullable:True
   */
  unionId?: string | null
  /**
   * Desc:钉钉用户的user_id
   * Default:
   * Nullable:True
   */
  userId?: string | null
  /**
   * Desc:性别
   * Default:
   * Nullable:True
   * @format int32
   */
  gender?: number | null
  /**
   * Desc:用户头像信息
   * Default:
   * Nullable:True
   */
  avatar?: string | null
  /**
   * Desc:员工工号
   * Default:
   * Nullable:True
   */
  jobNumber?: string | null
  /**
   * Desc:员工的直属主管
   * Default:
   * Nullable:True
   */
  managerUserId?: string | null
  /**
   * Desc:所属部门的Id列表
   * Default:
   * Nullable:True
   */
  departmentIds?: string | null
  /**
   * Desc:员工在对应的部门中的排序 json：dept_id,order
   * Default:
   * Nullable:True
   */
  deptOrderList?: string | null
  /**
   * Desc:员工所在部门信息及是否是领导 json:dept_id,leader
   * Default:
   * Nullable:True
   */
  leaderInDept?: string | null
  /**
   * Desc:工作地点
   * Default:
   * Nullable:True
   */
  workPlace?: string | null
  /**
   * Desc:入职时间
   * Default:
   * Nullable:True
   */
  hiredDate?: string | null
  /**
   * Desc:职务
   * Default:
   * Nullable:True
   */
  title?: string | null
  /**
   * Desc:是否为企业账号
   * Default:
   * Nullable:True
   */
  exclusiveAccount?: boolean | null
  /**
   * Desc:扩展属性，最大长度2000个字符。
   * Default:
   * Nullable:True
   */
  extension?: string | null
  /**
   * Desc:是否激活了钉钉
   * Default:
   * Nullable:True
   */
  active?: boolean | null
  /**
   * Desc:是否完成了实名认证
   * Default:
   * Nullable:True
   */
  realAuthed?: boolean | null
  /**
   * Desc:是否为企业高管
   * Default:
   * Nullable:True
   */
  senior?: boolean | null
  /**
   * Desc:是否为企业的管理员
   * Default:
   * Nullable:True
   */
  admin?: boolean | null
  /**
   * Desc:是否为企业的老板
   * Default:
   * Nullable:True
   */
  boss?: boolean | null
  /**
   * Desc:租户ID
   * Default:0
   * Nullable:False
   */
  tenantId?: string | null
  /**
   * Desc:部门ID  DepartmentId
   * Default:0
   * Nullable:False
   */
  organizationUnitId?: string | null
  /**
   * Desc:分所Id
   * Default:0
   * Nullable:False
   */
  officeId?: string | null
  /**
   * Desc:备注
   * Default:
   * Nullable:True
   */
  remark?: string | null
  /**
   * Desc:状态 	            中立字段，某些表可使用某些表不使用
   * Default:1
   * Nullable:False
   */
  isEnabled?: boolean
  /**
   * Desc:中立字段，某些表可使用某些表不使用   	            逻辑上的删除，非物理删除  	            例如：单据删除并非直接删除
   * Default:0
   * Nullable:False
   */
  isDeleted?: boolean
  /**
   * Desc:中立字段 	            是否内置数据
   * Default:
   * Nullable:False
   */
  isInternal?: boolean
  /**
   * Desc:创建ID
   * Default:
   * Nullable:True
   */
  createId?: string | null
  /**
   * Desc:创建者
   * Default:
   * Nullable:True
   */
  createBy?: string | null
  /**
   * Desc:创建时间
   * Default:
   * Nullable:False
   * @format date-time
   */
  createTime?: string
  /**
   * Desc:修改ID
   * Default:
   * Nullable:True
   */
  modifyId?: string | null
  /**
   * Desc:更新者
   * Default:
   * Nullable:True
   */
  modifyBy?: string | null
  /**
   * Desc:修改日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  modifyTime?: string | null
  /**
   * Desc:数据版本
   * Default:0
   * Nullable:False
   */
  version?: string | null
  /**
   * Desc:删除ID
   * Default:
   * Nullable:True
   */
  deleteId?: string | null
  /**
   * Desc:删除者
   * Default:
   * Nullable:True
   */
  deleteBy?: string | null
  /**
   * Desc:删除日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  deleteTime?: string | null
}

/** 通用返回信息类 */
export interface DingtalkUserDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 返回数据集合 */
  response?: DingtalkUserDto[] | null
}

/** 通用返回信息类 */
export interface DingtalkUserDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  response?: DingtalkUserDto
}

/** 通用分页信息类 */
export interface DingtalkUserDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number
  /** 返回数据 */
  data?: DingtalkUserDto[] | null
}

/** 通用返回信息类 */
export interface DingtalkUserDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 通用分页信息类 */
  response?: DingtalkUserDtoPageModel
}

export interface DingtalkUserRelationDto {
  /**
   * Desc:雪花Id
   * Default:
   * Nullable:False
   */
  id?: string | null
  /**
   * Desc:律智荟 UserId
   * Default:
   * Nullable:True
   */
  relationUserId?: string | null
  /**
   * Desc:邮箱地址
   * Default:
   * Nullable:True
   */
  email?: string | null
  /**
   * Desc:手机号
   * Default:
   * Nullable:True
   */
  mobile?: string | null
  /**
   * Desc:钉钉用户的open_id
   * Default:
   * Nullable:True
   */
  openId?: string | null
  /**
   * Desc:钉钉用户的union_id
   * Default:
   * Nullable:True
   */
  unionId?: string | null
  /**
   * Desc:钉钉用户的user_id
   * Default:
   * Nullable:True
   */
  userId?: string | null
  /**
   * Desc:钉钉appid
   * Default:
   * Nullable:True
   */
  appId?: string | null
  /**
   * Desc:钉钉用户编号获取状态（N：未同步到，A：同步到其中任一Id）
   * Default:
   * Nullable:True
   */
  syncStatus?: string | null
  /**
   * Desc:租户ID
   * Default:0
   * Nullable:False
   */
  tenantId?: string | null
  /**
   * Desc:部门ID  DepartmentId
   * Default:0
   * Nullable:False
   */
  organizationUnitId?: string | null
  /**
   * Desc:分所Id
   * Default:0
   * Nullable:False
   */
  officeId?: string | null
  /**
   * Desc:备注
   * Default:
   * Nullable:True
   */
  remark?: string | null
  /**
   * Desc:状态 	            中立字段，某些表可使用某些表不使用
   * Default:1
   * Nullable:False
   */
  isEnabled?: boolean
  /**
   * Desc:中立字段，某些表可使用某些表不使用   	            逻辑上的删除，非物理删除  	            例如：单据删除并非直接删除
   * Default:0
   * Nullable:False
   */
  isDeleted?: boolean
  /**
   * Desc:中立字段 	            是否内置数据
   * Default:
   * Nullable:False
   */
  isInternal?: boolean
  /**
   * Desc:创建ID
   * Default:
   * Nullable:True
   */
  createId?: string | null
  /**
   * Desc:创建者
   * Default:
   * Nullable:True
   */
  createBy?: string | null
  /**
   * Desc:创建时间
   * Default:
   * Nullable:False
   * @format date-time
   */
  createTime?: string
  /**
   * Desc:修改ID
   * Default:
   * Nullable:True
   */
  modifyId?: string | null
  /**
   * Desc:更新者
   * Default:
   * Nullable:True
   */
  modifyBy?: string | null
  /**
   * Desc:修改日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  modifyTime?: string | null
  /**
   * Desc:数据版本
   * Default:0
   * Nullable:False
   */
  version?: string | null
  /**
   * Desc:删除ID
   * Default:
   * Nullable:True
   */
  deleteId?: string | null
  /**
   * Desc:删除者
   * Default:
   * Nullable:True
   */
  deleteBy?: string | null
  /**
   * Desc:删除日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  deleteTime?: string | null
}

/** 通用返回信息类 */
export interface DingtalkUserRelationDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 返回数据集合 */
  response?: DingtalkUserRelationDto[] | null
}

/** 通用返回信息类 */
export interface DingtalkUserRelationDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  response?: DingtalkUserRelationDto
}

/** 通用分页信息类 */
export interface DingtalkUserRelationDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number
  /** 返回数据 */
  data?: DingtalkUserRelationDto[] | null
}

/** 通用返回信息类 */
export interface DingtalkUserRelationDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 通用分页信息类 */
  response?: DingtalkUserRelationDtoPageModel
}

export interface DisplayDesensitizationDefaultRuleDto {
  id?: string | null
  /**
   * 脱敏类型
   * @format int32
   */
  displayDesensitizationType?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10
  /** 显示名称 */
  displayName?: string | null
  /**
   * 开始保留位
   * @format int32
   */
  startIndex?: number
  /**
   * 结束保留位
   * @format int32
   */
  endIndex?: number
  /** 正则表达式 */
  regExp?: string | null
  /** 备注 */
  remark?: string | null
}

/** 通用返回信息类 */
export interface DisplayDesensitizationDefaultRuleDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 返回数据集合 */
  response?: DisplayDesensitizationDefaultRuleDto[] | null
}

export interface DisplayDesensitizationModule {
  /** 模块名称 */
  controllerName?: string | null
  /** 分组名称 */
  groupName?: string | null
  /** 模块描述 */
  controllerDescription?: string | null
  /** 模块下脱敏配置列表 */
  displayDesensitizationSettingList?: DisplayDesensitizationSettingDto[] | null
}

/** 通用返回信息类 */
export interface DisplayDesensitizationModuleListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 返回数据集合 */
  response?: DisplayDesensitizationModule[] | null
}

export interface DisplayDesensitizationOutput {
  /** 创建ID */
  createId?: string | null
  /** 创建者 */
  createBy?: string | null
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string
  /** 显示脱敏规则 */
  displayDesensitizationRules?: DisplayDesensitizationRuleDto[] | null
  /** 分组名称 */
  groupName?: string | null
  /** 模块名称 */
  controllerName?: string | null
  /** 方法名称 */
  actionName?: string | null
  /** Dto名称 */
  dtoName?: string | null
  /** 组织机构Id */
  orgId?: string | null
  /**
   * 配置层级
   * @format int32
   */
  settingLevel?: 0 | 1 | 2 | 3
  /** 整体掩盖字符 */
  mainCharacter?: string | null
  /** 备注 */
  remark?: string | null
}

/** 通用返回信息类 */
export interface DisplayDesensitizationOutputMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  response?: DisplayDesensitizationOutput
}

/** 通用分页信息类 */
export interface DisplayDesensitizationOutputPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number
  /** 返回数据 */
  data?: DisplayDesensitizationOutput[] | null
}

/** 通用返回信息类 */
export interface DisplayDesensitizationOutputPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 通用分页信息类 */
  response?: DisplayDesensitizationOutputPageModel
}

export interface DisplayDesensitizationRuleDto {
  /** 字段名称 */
  field?: string | null
  /** 字段类型 */
  fieldType?: string | null
  /** 字段描述 */
  fieldDescription?: string | null
  /**
   * 验证规则
   * @format int32
   */
  ruleType?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10
  /**
   * 开始保留位
   * @format int32
   */
  startIndex?: number
  /**
   * 结束保留位
   * @format int32
   */
  endIndex?: number
  /** 正则表达式 */
  regExp?: string | null
  /** 脱敏替换字符 */
  character?: string | null
  /** 是否脱敏 */
  isDesensitization?: boolean
  /** 是否自动脱敏 */
  isAutoDesensitization?: boolean
}

export interface DisplayDesensitizationSettingDto {
  /** Dto名称 */
  dtoName?: string | null
  /** 请求地址 */
  url?: string | null
  /** 描述 */
  actionDescription?: string | null
  /** 方法名称 */
  actionName?: string | null
  /** 控制器名称 */
  controllerName?: string | null
  /** 模块描述 */
  controllerDescription?: string | null
  /** 分组名称 */
  groupName?: string | null
  /**
   * 配置层级
   * @format int32
   */
  settingLevel?: 0 | 1 | 2 | 3
  /** 整体掩盖字符 */
  mainCharacter?: string | null
  /** 显示脱敏 */
  displayDesensitizationRules?: DisplayDesensitizationRuleDto[] | null
}

export interface ECIBranchInfo {
  keyNo?: string | null
  name?: string | null
  /** 企业状态 */
  status?: string | null
  /** 法定代表人信息 */
  operInfo?: OperInfo
}

/** 通用返回信息类 */
export interface ECIBranchInfoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 返回数据集合 */
  response?: ECIBranchInfo[] | null
}

export interface ECIEmployeeInfo {
  keyNo?: string | null
  name?: string | null
  /** 中国香港 */
  job?: string | null
}

/** 通用返回信息类 */
export interface ECIEmployeeInfoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 返回数据集合 */
  response?: ECIEmployeeInfo[] | null
}

export interface ECIInfoVerifyData {
  partners?: PartnersItem[] | null
  employees?: EmployeeListItem[] | null
  branches?: BranchsDto[] | null
  changeRecords?: ChangeRecordsItem[] | null
  contactInfo?: ECIVerifyContactInfo
  industry?: IndustryInfo
  area?: Area
  areaCode?: string | null
  emergingIndustyList?: EmergingIndustyListItem[] | null
  revokeInfo?: RevokeInfo
  insuredCount?: string | null
  englishName?: string | null
  /** 300-399人 */
  personScope?: string | null
  ixCode?: string | null
  tagList?: TagListItem[] | null
  arContactList?: ARContactListItem[] | null
  econKindCodeList?: string[] | null
  keyNo?: string | null
  name?: string | null
  no?: string | null
  /** 苏州工业园区市场监督管理局 */
  belongOrg?: string | null
  operId?: string | null
  operName?: string | null
  startDate?: string | null
  endDate?: string | null
  /** 存续（在营、开业、在册） */
  status?: string | null
  province?: string | null
  updatedDate?: string | null
  creditCode?: string | null
  /** 5000万元人民币 */
  registCapi?: string | null
  /** 有限责任公司 */
  econKind?: string | null
  /** 苏州工业园区东长路88号C1幢5层503室 */
  address?: string | null
  scope?: string | null
  termStart?: string | null
  termEnd?: string | null
  checkDate?: string | null
  orgNo?: string | null
  isOnStock?: string | null
  stockNumber?: string | null
  stockType?: string | null
  originalName?: OriginalName[] | null
  imageUrl?: string | null
  entType?: string | null
  /** 5000万元人民币 */
  recCap?: string | null
}

/** 通用返回信息类 */
export interface ECIInfoVerifyDataMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  response?: ECIInfoVerifyData
}

export interface ECIPartnerResult {
  keyNo?: string | null
  stockName?: string | null
  /** 企业法人 */
  stockType?: string | null
  stockPercent?: string | null
  shouldCapi?: string | null
  shoudDate?: string | null
  stakeDate?: string | null
  creditCode?: string | null
  /** 中国香港 */
  area?: string | null
}

/** 通用返回信息类 */
export interface ECIPartnerResultListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 返回数据集合 */
  response?: ECIPartnerResult[] | null
}

export interface ECISeniorPersonIndustryDto {
  /** 租赁和商务服务业 */
  industry?: string | null
  industryCode?: string | null
  /** 商务服务业 */
  subIndustry?: string | null
  subIndustryCode?: string | null
}

export interface ECISeniorPersonResultItem {
  area?: Area
  date?: string | null
  econKind?: string | null
  imageUrl?: string | null
  industry?: ECISeniorPersonIndustryDto
  keyNo?: string | null
  name?: string | null
  operName?: string | null
  operPersonId?: string | null
  operType?: string | null
  /** 100000万元人民币 */
  regCap?: string | null
  relationList?: RelationListItem[] | null
  sxCount?: string | null
  /** 存续 */
  status?: string | null
  zxCount?: string | null
  creditCode?: string | null
}

/** 通用返回信息类 */
export interface ECISeniorPersonResultItemListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 返回数据集合 */
  response?: ECISeniorPersonResultItem[] | null
}

export interface ECIVerifyContactInfo {
  webSite?: WebSiteItem[] | null
  email?: string | null
  phoneNumber?: string | null
}

export interface EmergingIndustyListItem {
  primaryCode?: string | null
  /** 机器人 */
  primaryDes?: string | null
  secondaryList?: SecondaryListItem[] | null
}

export interface EmployeeListItem {
  keyNo?: string | null
  name?: string | null
  /** 董事长,经理,法定代表人 */
  job?: string | null
}

export interface EnterpriseInfoDto {
  /** 公司名称 */
  enterpriseName?: string | null
  /** 执行许可号 */
  enterpriseCode?: string | null
  /** 法人姓名 */
  corporation?: string | null
  /** 统一社会信用代码 */
  creditCode?: string | null
  /** 邮箱地址 */
  email?: string | null
  /** 注册地址 */
  enterpriseAddress?: string | null
  /** Id */
  id?: string | null
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string
}

/** 实体联系人表 */
export interface EntityContactDto {
  /**
   * Desc:雪花Id
   * Default:
   * Nullable:False
   */
  id?: string | null
  /**
   * Desc:联系人名称
   * Default:
   * Nullable:True
   */
  name?: string | null
  /**
   * Desc:别名
   * Default:
   * Nullable:True
   */
  altName?: string | null
  /**
   * Desc:外文名称
   * Default:
   * Nullable:True
   */
  foreignName?: string | null
  /**
   * Desc:速搜码，简码
   * Default:
   * Nullable:True
   */
  shortCode?: string | null
  /**
   * Desc:身份类别（客户联系人/法院法官/对方联系人/检察官/监狱长等）
   * Default:
   * Nullable:True
   */
  identityType?: string | null
  /**
   * Desc:记录来源
   * Default:
   * Nullable:True
   */
  loadSource?: string | null
  /**
   * Desc:性别
   * Default:
   * Nullable:True
   */
  gender?: string | null
  /**
   * Desc:民族
   * Default:
   * Nullable:True
   */
  nation?: string | null
  /**
   * Desc:生日
   * Default:
   * Nullable:False
   * @format date-time
   */
  birthday?: string
  /**
   * Desc:职位
   * Default:
   * Nullable:True
   */
  duty?: string | null
  /**
   * Desc:头像
   * Default:
   * Nullable:True
   */
  avatar?: string | null
  /**
   * Desc:图像/图片
   * Default:
   * Nullable:True
   */
  picture?: string | null
  /**
   * Desc:邮编
   * Default:
   * Nullable:True
   */
  zipCode?: string | null
  /**
   * Desc:通讯地址
   * Default:
   * Nullable:True
   */
  address?: string | null
  /**
   * Desc:所属地区
   * Default:
   * Nullable:True
   */
  region?: string | null
  /**
   * Desc:画像
   * Default:
   * Nullable:True
   */
  portraitNarrative?: string | null
  /**
   * Desc:邮箱地址
   * Default:
   * Nullable:True
   */
  emailAddress?: string | null
  /**
   * Desc:个人邮箱地址
   * Default:
   * Nullable:True
   */
  perEmailAddress?: string | null
  /**
   * Desc:联系固话
   * Default:
   * Nullable:True
   */
  landline?: string | null
  /**
   * Desc:移动电话
   * Default:
   * Nullable:True
   */
  phoneNumber?: string | null
  /**
   * Desc:个人移动电话
   * Default:
   * Nullable:True
   */
  perPhoneNumber?: string | null
  /**
   * Desc:传真
   * Default:
   * Nullable:True
   */
  fax?: string | null
  /**
   * Desc:网站主页
   * Default:
   * Nullable:True
   */
  webPortal?: string | null
  /**
   * Desc:证件类型
   * Default:
   * Nullable:True
   */
  creditType?: string | null
  /**
   * Desc:证件号码
   * Default:
   * Nullable:True
   */
  creditCode?: string | null
  /**
   * Desc:来源
   * Default:
   * Nullable:True
   */
  originSource?: string | null
  /**
   * Desc:即时通讯类型（微信/Line/Whatapp等）
   * Default:
   * Nullable:True
   */
  imType?: string | null
  /**
   * Desc:即时通讯号码
   * Default:
   * Nullable:True
   */
  imNumber?: string | null
  /**
   * Desc:即时通讯描述
   * Default:
   * Nullable:True
   */
  imNarrative?: string | null
  /**
   * Desc:即时通讯类型2
   * Default:
   * Nullable:True
   */
  imType2?: string | null
  /**
   * Desc:即时通讯号码2
   * Default:
   * Nullable:True
   */
  imNumber2?: string | null
  /**
   * Desc:即时通讯描述2
   * Default:
   * Nullable:True
   */
  imNarrative2?: string | null
  /**
   * Desc:社交媒体类型
   * Default:
   * Nullable:True
   */
  socialMediaType?: string | null
  /**
   * Desc:社交媒体编号
   * Default:
   * Nullable:True
   */
  socialMediaID?: string | null
  /**
   * Desc:社交媒体描述
   * Default:
   * Nullable:True
   */
  socialMediaNarrative?: string | null
  /**
   * Desc:描述说明
   * Default:
   * Nullable:True
   */
  narrative?: string | null
  /**
   * Desc:喜好/爱好
   * Default:
   * Nullable:True
   */
  beFondOf?: string | null
  /**
   * Desc:租户ID
   * Default:0
   * Nullable:False
   */
  tenantId?: string | null
  /**
   * Desc:部门ID  DepartmentId
   * Default:0
   * Nullable:False
   */
  organizationUnitId?: string | null
  /**
   * Desc:分所Id
   * Default:0
   * Nullable:False
   */
  officeId?: string | null
  /**
   * Desc:备注
   * Default:
   * Nullable:True
   */
  remark?: string | null
  /**
   * Desc:状态 	                中立字段，某些表可使用某些表不使用
   * Default:1
   * Nullable:False
   */
  isEnabled?: boolean
  /**
   * Desc:中立字段，某些表可使用某些表不使用   	                逻辑上的删除，非物理删除  	                例如：单据删除并非直接删除
   * Default:0
   * Nullable:False
   */
  isDeleted?: boolean
  /**
   * Desc:中立字段 	                是否内置数据
   * Default:
   * Nullable:False
   */
  isInternal?: boolean
  /**
   * Desc:创建ID
   * Default:
   * Nullable:True
   */
  createId?: string | null
  /**
   * Desc:创建者
   * Default:
   * Nullable:True
   */
  createBy?: string | null
  /**
   * Desc:创建时间
   * Default:
   * Nullable:False
   * @format date-time
   */
  createTime?: string
  /**
   * Desc:修改ID
   * Default:
   * Nullable:True
   */
  modifyId?: string | null
  /**
   * Desc:更新者
   * Default:
   * Nullable:True
   */
  modifyBy?: string | null
  /**
   * Desc:修改日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  modifyTime?: string | null
  /**
   * Desc:数据版本
   * Default:0
   * Nullable:False
   */
  version?: string | null
  /**
   * Desc:删除ID
   * Default:
   * Nullable:True
   */
  deleteId?: string | null
  /**
   * Desc:删除者
   * Default:
   * Nullable:True
   */
  deleteBy?: string | null
  /**
   * Desc:删除日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  deleteTime?: string | null
}

/** 通用返回信息类 */
export interface EntityContactDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 返回数据集合 */
  response?: EntityContactDto[] | null
}

/** 通用返回信息类 */
export interface EntityContactDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 实体联系人表 */
  response?: EntityContactDto
}

/** 通用分页信息类 */
export interface EntityContactDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number
  /** 返回数据 */
  data?: EntityContactDto[] | null
}

/** 通用返回信息类 */
export interface EntityContactDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 通用分页信息类 */
  response?: EntityContactDtoPageModel
}

/** 实体联系人与客户案件关系表 */
export interface EntityContactRelationshipDto {
  /**
   * Desc:雪花Id
   * Default:
   * Nullable:False
   */
  id?: string | null
  /**
   * Desc:联系人编号
   * Default:
   * Nullable:False
   */
  contactID?: string | null
  /**
   * Desc:客户编号
   * Default:
   * Nullable:False
   */
  clientID?: string | null
  /**
   * Desc:案件编号
   * Default:
   * Nullable:False
   */
  caseID?: string | null
  /**
   * Desc:说明描述
   * Default:
   * Nullable:True
   */
  narrative?: string | null
  /**
   * Desc:租户ID
   * Default:0
   * Nullable:False
   */
  tenantId?: string | null
  /**
   * Desc:部门ID  DepartmentId
   * Default:0
   * Nullable:False
   */
  organizationUnitId?: string | null
  /**
   * Desc:分所Id
   * Default:0
   * Nullable:False
   */
  officeId?: string | null
  /**
   * Desc:备注
   * Default:
   * Nullable:True
   */
  remark?: string | null
  /**
   * Desc:状态 	                中立字段，某些表可使用某些表不使用
   * Default:1
   * Nullable:False
   */
  isEnabled?: boolean
  /**
   * Desc:中立字段，某些表可使用某些表不使用   	                逻辑上的删除，非物理删除  	                例如：单据删除并非直接删除
   * Default:0
   * Nullable:False
   */
  isDeleted?: boolean
  /**
   * Desc:中立字段 	                是否内置数据
   * Default:
   * Nullable:False
   */
  isInternal?: boolean
  /**
   * Desc:创建ID
   * Default:
   * Nullable:True
   */
  createId?: string | null
  /**
   * Desc:创建者
   * Default:
   * Nullable:True
   */
  createBy?: string | null
  /**
   * Desc:创建时间
   * Default:
   * Nullable:False
   * @format date-time
   */
  createTime?: string
  /**
   * Desc:修改ID
   * Default:
   * Nullable:True
   */
  modifyId?: string | null
  /**
   * Desc:更新者
   * Default:
   * Nullable:True
   */
  modifyBy?: string | null
  /**
   * Desc:修改日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  modifyTime?: string | null
  /**
   * Desc:数据版本
   * Default:0
   * Nullable:False
   */
  version?: string | null
  /**
   * Desc:删除ID
   * Default:
   * Nullable:True
   */
  deleteId?: string | null
  /**
   * Desc:删除者
   * Default:
   * Nullable:True
   */
  deleteBy?: string | null
  /**
   * Desc:删除日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  deleteTime?: string | null
}

/** 通用返回信息类 */
export interface EntityContactRelationshipDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 返回数据集合 */
  response?: EntityContactRelationshipDto[] | null
}

/** 通用返回信息类 */
export interface EntityContactRelationshipDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 实体联系人与客户案件关系表 */
  response?: EntityContactRelationshipDto
}

/** 通用分页信息类 */
export interface EntityContactRelationshipDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number
  /** 返回数据 */
  data?: EntityContactRelationshipDto[] | null
}

/** 通用返回信息类 */
export interface EntityContactRelationshipDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 通用分页信息类 */
  response?: EntityContactRelationshipDtoPageModel
}

/** 实体曾用名记录表 */
export interface EntityFormerNameDto {
  /**
   * Desc:雪花Id
   * Default:
   * Nullable:False
   */
  id?: string | null
  /**
   * Desc:实体编号
   * Default:
   * Nullable:False
   */
  entityID?: string | null
  /**
   * Desc:曾用名
   * Default:
   * Nullable:True
   */
  name?: string | null
  /**
   * Desc:外文曾用名称
   * Default:
   * Nullable:True
   */
  foreignName?: string | null
  /**
   * Desc:名称使用开始时间
   * Default:
   * Nullable:False
   * @format date-time
   */
  beginDate?: string
  /**
   * Desc:名称使用结束时间
   * Default:
   * Nullable:False
   * @format date-time
   */
  endDate?: string
  /**
   * Desc:记录来源
   * Default:
   * Nullable:True
   */
  loadSource?: string | null
  /**
   * Desc:说明描述
   * Default:
   * Nullable:True
   */
  narrative?: string | null
  /**
   * Desc:实体类别（客户/联系人/股东/实控人/母公司等）
   * Default:
   * Nullable:True
   */
  entityType?: string | null
  /**
   * Desc:租户ID
   * Default:0
   * Nullable:False
   */
  tenantId?: string | null
  /**
   * Desc:部门ID  DepartmentId
   * Default:0
   * Nullable:False
   */
  organizationUnitId?: string | null
  /**
   * Desc:分所Id
   * Default:0
   * Nullable:False
   */
  officeId?: string | null
  /**
   * Desc:备注
   * Default:
   * Nullable:True
   */
  remark?: string | null
  /**
   * Desc:状态 	                中立字段，某些表可使用某些表不使用
   * Default:1
   * Nullable:False
   */
  isEnabled?: boolean
  /**
   * Desc:中立字段，某些表可使用某些表不使用   	                逻辑上的删除，非物理删除  	                例如：单据删除并非直接删除
   * Default:0
   * Nullable:False
   */
  isDeleted?: boolean
  /**
   * Desc:中立字段 	                是否内置数据
   * Default:
   * Nullable:False
   */
  isInternal?: boolean
  /**
   * Desc:创建ID
   * Default:
   * Nullable:True
   */
  createId?: string | null
  /**
   * Desc:创建者
   * Default:
   * Nullable:True
   */
  createBy?: string | null
  /**
   * Desc:创建时间
   * Default:
   * Nullable:False
   * @format date-time
   */
  createTime?: string
  /**
   * Desc:修改ID
   * Default:
   * Nullable:True
   */
  modifyId?: string | null
  /**
   * Desc:更新者
   * Default:
   * Nullable:True
   */
  modifyBy?: string | null
  /**
   * Desc:修改日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  modifyTime?: string | null
  /**
   * Desc:数据版本
   * Default:0
   * Nullable:False
   */
  version?: string | null
  /**
   * Desc:删除ID
   * Default:
   * Nullable:True
   */
  deleteId?: string | null
  /**
   * Desc:删除者
   * Default:
   * Nullable:True
   */
  deleteBy?: string | null
  /**
   * Desc:删除日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  deleteTime?: string | null
}

/** 通用返回信息类 */
export interface EntityFormerNameDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 返回数据集合 */
  response?: EntityFormerNameDto[] | null
}

/** 通用返回信息类 */
export interface EntityFormerNameDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 实体曾用名记录表 */
  response?: EntityFormerNameDto
}

/** 通用分页信息类 */
export interface EntityFormerNameDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number
  /** 返回数据 */
  data?: EntityFormerNameDto[] | null
}

/** 通用返回信息类 */
export interface EntityFormerNameDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 通用分页信息类 */
  response?: EntityFormerNameDtoPageModel
}

/** 实体主体信息表 */
export interface EntityInfoDto {
  /**
   * Desc:雪花Id
   * Default:
   * Nullable:False
   */
  id?: string | null
  /**
   * Desc:实体或自然人名称
   * Default:
   * Nullable:True
   */
  name?: string | null
  /**
   * Desc:外文名称
   * Default:
   * Nullable:True
   */
  foreignName?: string | null
  /**
   * Desc:曾用户
   * Default:
   * Nullable:True
   */
  formerName?: string | null
  /**
   * Desc:别名
   * Default:
   * Nullable:True
   */
  altName?: string | null
  /**
   * Desc:速搜码，简码
   * Default:
   * Nullable:True
   */
  shortCode?: string | null
  /**
   * Desc:是否自然人
   * Default:
   * Nullable:False
   */
  isNaturalPerson?: boolean
  /**
   * Desc:是否涉外实体或个人
   * Default:
   * Nullable:False
   */
  isForeign?: boolean
  /**
   * Desc:客户类型（自然人/政府机构/实体）
   * Default:
   * Nullable:True
   */
  category?: string | null
  /**
   * Desc:实体类别（客户/客户实控人/股东等）
   * Default:
   * Nullable:True
   */
  entityType?: string | null
  /**
   * Desc:实体子类
   * Default:
   * Nullable:True
   */
  econKind?: string | null
  /**
   * Desc:注册号
   * Default:
   * Nullable:True
   */
  registerationNumber?: string | null
  /**
   * Desc:注册名称
   * Default:
   * Nullable:True
   */
  registerationName?: string | null
  /**
   * Desc:注册地址
   * Default:
   * Nullable:True
   */
  registerationAddress?: string | null
  /**
   * Desc:所属行业编号
   * Default:
   * Nullable:True
   */
  industryID?: string | null
  /**
   * Desc:所属行业名称
   * Default:
   * Nullable:True
   */
  industryText?: string | null
  /**
   * Desc:证件类型
   * Default:
   * Nullable:True
   */
  creditType?: string | null
  /**
   * Desc:证件号码（身份证/信用代码/护照）
   * Default:
   * Nullable:True
   */
  creditCode?: string | null
  /**
   * Desc:地址
   * Default:
   * Nullable:True
   */
  address?: string | null
  /**
   * Desc:性别（自然人独占）
   * Default:
   * Nullable:True
   */
  gender?: string | null
  /**
   * Desc:民族（自然人独占）
   * Default:
   * Nullable:True
   */
  nation?: string | null
  /**
   * Desc:邮箱地址
   * Default:
   * Nullable:True
   */
  emailAddress?: string | null
  /**
   * Desc:固定电话
   * Default:
   * Nullable:True
   */
  landline?: string | null
  /**
   * Desc:移动电话
   * Default:
   * Nullable:True
   */
  phoneNumber?: string | null
  /**
   * Desc:传真
   * Default:
   * Nullable:True
   */
  fax?: string | null
  /**
   * Desc:邮政编码
   * Default:
   * Nullable:True
   */
  zipCode?: string | null
  /**
   * Desc:网站地址
   * Default:
   * Nullable:True
   */
  webPortal?: string | null
  /**
   * Desc:出生日期（自然人独占）
   * Default:
   * Nullable:False
   * @format date-time
   */
  birthday?: string
  /**
   * Desc:法定代表人
   * Default:
   * Nullable:True
   */
  legalPerson?: string | null
  /**
   * Desc:法定代表人编号
   * Default:
   * Nullable:True
   */
  legalPersonID?: string | null
  /**
   * Desc:经营场所
   * Default:
   * Nullable:True
   */
  businessAddress?: string | null
  /**
   * Desc:税号
   * Default:
   * Nullable:True
   */
  taxNumber?: string | null
  /**
   * Desc:组织机构编号
   * Default:
   * Nullable:True
   */
  orgNumber?: string | null
  /**
   * Desc:经营范围
   * Default:
   * Nullable:True
   */
  businessScope?: string | null
  /**
   * Desc:注册资本
   * Default:
   * Nullable:True
   */
  registeredCapital?: string | null
  /**
   * Desc:实缴资本
   * Default:
   * Nullable:True
   */
  paidInCapital?: string | null
  /**
   * Desc:成立日期
   * Default:
   * Nullable:False
   * @format date-time
   */
  establishmentDate?: string
  /**
   * Desc:营业期限
   * Default:
   * Nullable:True
   */
  businessTerm?: string | null
  /**
   * Desc:营业执照发证日期
   * Default:
   * Nullable:False
   * @format date-time
   */
  licenseIssueDate?: string
  /**
   * Desc:发证机关（登记机关）
   * Default:
   * Nullable:True
   */
  issuingAuthority?: string | null
  /**
   * Desc:经营状态（登记状态）
   * Default:
   * Nullable:True
   */
  operationalStatus?: string | null
  /**
   * Desc:纳税人资质
   * Default:
   * Nullable:True
   */
  credential?: string | null
  /**
   * Desc:纳税人资质ID
   * Default:
   * Nullable:True
   */
  credentialID?: string | null
  /**
   * Desc:核准日期
   * Default:
   * Nullable:False
   * @format date-time
   */
  issueDate?: string
  /**
   * Desc:所属国家
   * Default:
   * Nullable:True
   */
  country?: string | null
  /**
   * Desc:所属省或州郡
   * Default:
   * Nullable:True
   */
  province?: string | null
  /**
   * Desc:所属地区
   * Default:
   * Nullable:True
   */
  region?: string | null
  /**
   * Desc:进出口企业代码
   * Default:
   * Nullable:True
   */
  importExportCode?: string | null
  /**
   * Desc:人员规模
   * Default:
   * Nullable:True
   */
  staffSize?: string | null
  /**
   * Desc:参保人数
   * Default:
   * Nullable:True
   */
  numberOfInsured?: string | null
  /**
   * Desc:客户来源
   * Default:
   * Nullable:True
   */
  customerSource?: string | null
  /**
   * Desc:源实体编号
   * Default:
   * Nullable:True
   */
  originEntityID?: string | null
  /**
   * Desc:源实体名称
   * Default:
   * Nullable:True
   */
  originEntityName?: string | null
  /**
   * Desc:业务来源（立案/投标/入库等）
   * Default:
   * Nullable:True
   */
  businessSource?: string | null
  /**
   * Desc:是否上市企业
   * Default:
   * Nullable:True
   */
  isListedEnterprise?: string | null
  /**
   * Desc:上市地区
   * Default:
   * Nullable:True
   */
  listedRegion?: string | null
  /**
   * Desc:上市机构
   * Default:
   * Nullable:True
   */
  listedOrg?: string | null
  /**
   * Desc:上市类别（新三板，创业板，A股等）
   * Default:
   * Nullable:True
   */
  listedType?: string | null
  /**
   * Desc:上市股票代码
   * Default:
   * Nullable:True
   */
  listedStockNumber?: string | null
  /**
   * Desc:是否名录企业
   * Default:
   * Nullable:True
   */
  isDirectoryEnterprise?: string | null
  /**
   * Desc:是否500强企业
   * Default:
   * Nullable:True
   */
  isTop500?: string | null
  /**
   * Desc:是否小微企业
   * Default:
   * Nullable:True
   */
  isMicroEnterprise?: string | null
  /**
   * Desc:标签
   * Default:
   * Nullable:True
   */
  tags?: string | null
  /**
   * Desc:是否披露
   * Default:
   * Nullable:True
   */
  isDisclose?: string | null
  /**
   * Desc:客户级别
   * Default:
   * Nullable:True
   */
  clientLevel?: string | null
  /**
   * Desc:记录来源
   * Default:
   * Nullable:True
   */
  loadSource?: string | null
  /**
   * Desc:记录来源编号
   * Default:
   * Nullable:True
   */
  loadSourceID?: string | null
  /**
   * Desc:logo链接
   * Default:
   * Nullable:True
   */
  logoUrl?: string | null
  /**
   * Desc:Logo图片
   * Default:
   * Nullable:True
   */
  logoPicture?: string | null
  /**
   * Desc:头像
   * Default:
   * Nullable:True
   */
  avatar?: string | null
  /**
   * Desc:客户画像描述
   * Default:
   * Nullable:True
   */
  portraitNarrative?: string | null
  /**
   * Desc:是否吊销
   * Default:
   * Nullable:False
   */
  isRevoke?: boolean
  /**
   * Desc:吊销日期
   * Default:
   * Nullable:False
   * @format date-time
   */
  revokeDate?: string
  /**
   * Desc:吊销原因
   * Default:
   * Nullable:True
   */
  revokeRemark?: string | null
  /**
   * Desc:租户ID
   * Default:0
   * Nullable:False
   */
  tenantId?: string | null
  /**
   * Desc:部门ID  DepartmentId
   * Default:0
   * Nullable:False
   */
  organizationUnitId?: string | null
  /**
   * Desc:分所Id
   * Default:0
   * Nullable:False
   */
  officeId?: string | null
  /**
   * Desc:备注
   * Default:
   * Nullable:True
   */
  remark?: string | null
  /**
   * Desc:状态 	                中立字段，某些表可使用某些表不使用
   * Default:1
   * Nullable:False
   */
  isEnabled?: boolean
  /**
   * Desc:中立字段，某些表可使用某些表不使用   	                逻辑上的删除，非物理删除  	                例如：单据删除并非直接删除
   * Default:0
   * Nullable:False
   */
  isDeleted?: boolean
  /**
   * Desc:中立字段 	                是否内置数据
   * Default:
   * Nullable:False
   */
  isInternal?: boolean
  /**
   * Desc:创建ID
   * Default:
   * Nullable:True
   */
  createId?: string | null
  /**
   * Desc:创建者
   * Default:
   * Nullable:True
   */
  createBy?: string | null
  /**
   * Desc:创建时间
   * Default:
   * Nullable:False
   * @format date-time
   */
  createTime?: string
  /**
   * Desc:修改ID
   * Default:
   * Nullable:True
   */
  modifyId?: string | null
  /**
   * Desc:更新者
   * Default:
   * Nullable:True
   */
  modifyBy?: string | null
  /**
   * Desc:修改日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  modifyTime?: string | null
  /**
   * Desc:数据版本
   * Default:0
   * Nullable:False
   */
  version?: string | null
  /**
   * Desc:删除ID
   * Default:
   * Nullable:True
   */
  deleteId?: string | null
  /**
   * Desc:删除者
   * Default:
   * Nullable:True
   */
  deleteBy?: string | null
  /**
   * Desc:删除日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  deleteTime?: string | null
  /** 联系人 */
  contactName?: string | null
}

export interface ExcelFileDto {
  /** 文件Id */
  fileId?: string | null
  /** 文件名称 */
  fileName?: string | null
  /**
   * 文件大小
   * @format int32
   */
  fileSize?: number
  /** 文件路径 */
  filePath?: string | null
}

/** 通用返回信息类 */
export interface ExcelFileDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  response?: ExcelFileDto
}

/** 字段来源 */
export interface FieldSourceDto {
  /** 所属表单 */
  tableName?: string | null
  /** 编码字段 */
  field?: string | null
  /** 关联字段 */
  associatedField?: string | null
}

/** 字段值 */
export interface FieldValueDto {
  /** 所属表单 */
  tableName?: string | null
  /** 编码字段 */
  field?: string | null
  /** 字段值 */
  value?: string | null
}

export interface FileAggregateRoute {
  routeKeys?: string[] | null
  routeKeysConfig?: AggregateRouteConfig[] | null
  upstreamPathTemplate?: string | null
  upstreamHost?: string | null
  routeIsCaseSensitive?: boolean
  aggregator?: string | null
  upstreamHttpMethod?: string[] | null
  /** @format int32 */
  priority?: number
}

export interface FileAuthenticationOptions {
  authenticationProviderKey?: string | null
  allowedScopes?: string[] | null
}

export interface FileCacheOptions {
  /** @format int32 */
  ttlSeconds?: number
  region?: string | null
}

export interface FileConfiguration {
  routes?: FileRoute[] | null
  dynamicRoutes?: FileDynamicRoute[] | null
  aggregates?: FileAggregateRoute[] | null
  globalConfiguration?: FileGlobalConfiguration
}

export interface FileDynamicRoute {
  serviceName?: string | null
  rateLimitRule?: FileRateLimitRule
  downstreamHttpVersion?: string | null
}

export interface FileGlobalConfiguration {
  requestIdKey?: string | null
  serviceDiscoveryProvider?: FileServiceDiscoveryProvider
  rateLimitOptions?: FileRateLimitOptions
  qoSOptions?: FileQoSOptions
  baseUrl?: string | null
  loadBalancerOptions?: FileLoadBalancerOptions
  downstreamScheme?: string | null
  httpHandlerOptions?: FileHttpHandlerOptions
  downstreamHttpVersion?: string | null
}

export interface FileHostAndPort {
  host?: string | null
  /** @format int32 */
  port?: number
}

export interface FileHttpHandlerOptions {
  allowAutoRedirect?: boolean
  useCookieContainer?: boolean
  useTracing?: boolean
  useProxy?: boolean
  /** @format int32 */
  maxConnectionsPerServer?: number
}

export interface FileLoadBalancerOptions {
  type?: string | null
  key?: string | null
  /** @format int32 */
  expiry?: number
}

export interface FileQoSOptions {
  /** @format int32 */
  exceptionsAllowedBeforeBreaking?: number
  /** @format int32 */
  durationOfBreak?: number
  /** @format int32 */
  timeoutValue?: number
}

export interface FileRateLimitOptions {
  clientIdHeader?: string | null
  quotaExceededMessage?: string | null
  rateLimitCounterPrefix?: string | null
  disableRateLimitHeaders?: boolean
  /** @format int32 */
  httpStatusCode?: number
}

export interface FileRateLimitRule {
  clientWhitelist?: string[] | null
  enableRateLimiting?: boolean
  period?: string | null
  /** @format double */
  periodTimespan?: number
  /** @format int64 */
  limit?: number
}

export interface FileRoute {
  downstreamPathTemplate?: string | null
  upstreamPathTemplate?: string | null
  upstreamHttpMethod?: string[] | null
  downstreamHttpMethod?: string | null
  addHeadersToRequest?: Record<string, string | null>
  upstreamHeaderTransform?: Record<string, string | null>
  downstreamHeaderTransform?: Record<string, string | null>
  addClaimsToRequest?: Record<string, string | null>
  routeClaimsRequirement?: Record<string, string | null>
  addQueriesToRequest?: Record<string, string | null>
  changeDownstreamPathTemplate?: Record<string, string | null>
  requestIdKey?: string | null
  fileCacheOptions?: FileCacheOptions
  routeIsCaseSensitive?: boolean
  serviceName?: string | null
  serviceNamespace?: string | null
  downstreamScheme?: string | null
  qoSOptions?: FileQoSOptions
  loadBalancerOptions?: FileLoadBalancerOptions
  rateLimitOptions?: FileRateLimitRule
  authenticationOptions?: FileAuthenticationOptions
  httpHandlerOptions?: FileHttpHandlerOptions
  downstreamHostAndPorts?: FileHostAndPort[] | null
  upstreamHost?: string | null
  key?: string | null
  delegatingHandlers?: string[] | null
  /** @format int32 */
  priority?: number
  /** @format int32 */
  timeout?: number
  dangerousAcceptAnyServerCertificateValidator?: boolean
  securityOptions?: FileSecurityOptions
  downstreamHttpVersion?: string | null
}

export interface FileSecurityOptions {
  ipAllowedList?: string[] | null
  ipBlockedList?: string[] | null
}

export interface FileServiceDiscoveryProvider {
  scheme?: string | null
  host?: string | null
  /** @format int32 */
  port?: number
  type?: string | null
  token?: string | null
  configurationKey?: string | null
  /** @format int32 */
  pollingInterval?: number
  namespace?: string | null
}

export interface FilterConfig {
  /** 显示更多筛选 */
  isShowMoreFilter?: boolean
}

export interface FormConfig {
  /** 文本框标签对齐方式 */
  textTagAlignmentWay?: string | null
  /** 必填﹡号显示位置 */
  requiredDisplay?: string | null
  /** 组件尺寸 */
  componentSize?: string | null
  /** 组件宽度 */
  componentWidth?: string | null
  /** 仅显示必填项 */
  isRequiredShow?: boolean
}

export interface FormFieldAttributesDto {
  /** 显示名 */
  displayName?: string | null
  /** 是否显示 */
  isDisplay?: boolean
  /**
   * 字段表单类型
   * @format int32
   */
  formInputType?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20
  /** 默认数据集 */
  defaultValue?: Record<string, any>
  /** 前缀附件Id */
  startIconId?: string | null
  /** 前缀文本 */
  startText?: string | null
  /** 后缀附件Id */
  endIconId?: string | null
  /** 后缀文本 */
  endText?: string | null
  /**
   * 字间距
   * @format double
   */
  spacing?: number
  /**
   * 行间距
   * @format double
   */
  lineSpacing?: number
  /**
   * 对齐方式
   * @format int32
   */
  alignment?: 0 | 1 | 2 | 3
  /**
   * 标签宽度
   * @format int32
   */
  labelWidth?: 0 | 1 | 2
  /**
   * 字段大小
   * @format int32
   */
  fieldSize?: 0 | 1 | 2
  /** 提示语 */
  reminder?: string | null
  /** 提示文字 */
  placeholder?: string | null
  /** 帮助说明 */
  helpExplanation?: string | null
  /**
   * 字段宽度
   * @format int32
   */
  fieldWidth?: 0 | 1 | 2 | 3 | 4 | 5
  /** 千分位分隔符 */
  isThousandthSeparator?: boolean
  /** 百分位 */
  isPercentile?: boolean
  /** 正常颜色 */
  normalError?: string | null
  /** 错误颜色 */
  errorColor?: string | null
  /** 保留小数 */
  isReservedDecimal?: boolean
  /** 负值标红 */
  isNegativeRed?: boolean
  /** 数值调节按钮 */
  isNumericalAdjustment?: boolean
  /** 科学计数法 */
  isScientificNotation?: boolean
  /**
   * 长度
   * @format int32
   */
  length?: number
  /**
   * 小数位置
   * @format int32
   */
  decimalLocation?: number
  /**
   * 零值显示
   * @format int32
   */
  valueNullType?: 0 | 1 | 2
  /** 是否必填 */
  isRequired?: boolean
  /** 是否只读 */
  isRead?: boolean
  /** 是否清除 */
  isClean?: boolean
  /** 显示分钟 */
  isShowMinute?: boolean
  /** 显示分钟 */
  isSecond?: boolean
  /** Json配置集 */
  json?: Record<string, any>
  /** 是否是模板列 */
  isImportTemplateField?: boolean
  /** Id */
  id?: string | null
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string
}

export interface FormValidateDto {
  /** 字段 */
  field?: string | null
  /** 值类型 */
  fieldType?: string | null
  /** 验证规则 */
  formValidateRuleList?: FormValidateRuleDto[] | null
  formFieldAttributes?: FormFieldAttributesDto
}

/** 通用返回信息类 */
export interface FormValidateDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  response?: FormValidateDto
}

export interface FormValidateRuleDto {
  /**
   * 验证规则
   * @format int32
   */
  ruleType?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20
  /**
   * 弹窗规则
   * @format int32
   */
  alertRule?: 0 | 1 | 2
  /** 错误信息 */
  errorMessage?: string | null
  /** 值 */
  value?: string | null
  /** 是否是Dto字段 */
  isDtoField?: boolean
  /**
   * 最小长度
   * @format int32
   */
  minLength?: number
  /**
   * 最大长度
   * @format int32
   */
  maxLength?: number
  /**
   * 开始值
   * @format int32
   */
  start?: number
  /**
   * 结束值
   * @format int32
   */
  end?: number
  /**
   * 验证货币相关
   * @format int32
   */
  number?: number
  /**
   * 验证货币相关
   * @format int32
   */
  point?: number
  /** 验证货币相关 */
  ignoreTrailingZeros?: boolean
  /** 多语言Key */
  languages?: Record<string, string | null>
}

export interface FormValidateSettingDto {
  /** Dto名称 */
  dtoName?: string | null
  /** 请求方法 */
  methodName?: string | null
  /** 请求地址 */
  url?: string | null
  /** 功能 FromValidate */
  feature?: string | null
  /** 模块  Sys.UserInfo SysUserInfo */
  module?: string | null
  /** 描述 */
  description?: string | null
  /** 字段验证 */
  formValidateList?: FormValidateDto[] | null
}

/** 通用返回信息类 */
export interface FormValidateSettingDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 返回数据集合 */
  response?: FormValidateSettingDto[] | null
}

/** 通用返回信息类 */
export interface FormValidateSettingDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  response?: FormValidateSettingDto
}

export interface FuzzySearchDataItemDto {
  keyNo?: string | null
  name?: string | null
  creditCode?: string | null
  startDate?: string | null
  operName?: string | null
  /** 存续 */
  status?: string | null
  no?: string | null
  /** xxxxxxxxxxxxxx室 */
  address?: string | null
}

/** 通用返回信息类 */
export interface FuzzySearchDataItemDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 返回数据集合 */
  response?: FuzzySearchDataItemDto[] | null
}

/** 企查查高级搜索入参 */
export interface FuzzySearchInput {
  searchKey?: string | null
  provinceCode?: string | null
  cityCode?: string | null
}

/** 检索条件 */
export interface GetCSRSearchPreProcessInput {
  /**
   * 搜索模式
   * @format int32
   */
  searchMode?: 0 | 1 | 2 | 3
  /** 是否高级搜索 */
  isAdvanceSearch?: boolean
  /** 是否分词检索 */
  isSplitWordSearch?: boolean | null
  keywords?: string | null
  /** 客户方 */
  clientName?: string | null
  /** 对方 */
  oppositeName?: string | null
  /** 第三方 */
  potential3rdPartyName?: string | null
  /**
   * 页码
   * @format int32
   */
  page?: number
  /**
   * 数量
   * @format int32
   */
  size?: number
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null
}

export interface GetOrganizationUnitsInput {
  /** 机构键值 */
  code?: string | null
  /** 显示名称 */
  displayName?: string | null
  /** 父级Id */
  parentId?: string | null
  /** 别名 */
  alterName?: string | null
  /** 区别标识 */
  discriminator?: string | null
  /** 是否启用 */
  isActive?: boolean | null
  /** 是否虚拟机构 */
  isVirtual?: boolean | null
  /** 是否总所 */
  isHeadOffice?: boolean | null
  /**
   * 阈值标识
   * @format int32
   */
  thresholdFlag?: 0 | 1 | 2 | 3 | null
  /**
   * 页码
   * @format int32
   */
  page?: number
  /**
   * 数量
   * @format int32
   */
  size?: number
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null
}

export interface GetRoleInput {
  /** 关键词 */
  key?: string | null
  /** 用户组Id */
  roleGroupId?: string | null
  /**
   * 权限类型
   * @format int32
   */
  roleType?: 0 | 1 | 2 | 3 | 4 | null
  /** 是否启用 */
  isActive?: boolean | null
  /** 是否默认角色 */
  isDefault?: boolean | null
  /** 虚拟角色 */
  isVirtual?: boolean | null
  /**
   * 页码
   * @format int32
   */
  page?: number
  /**
   * 数量
   * @format int32
   */
  size?: number
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null
}

export interface GetSequenceNumberRulesHistoryInput {
  /** 隶属表单 */
  tableName?: string | null
  /** 编码字段 */
  field?: string | null
  /**
   * 页码
   * @format int32
   */
  page?: number
  /**
   * 数量
   * @format int32
   */
  size?: number
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null
}

export interface GetSequenceNumberRulesInput {
  /** 关键字 */
  filter?: string | null
  /** 隶属表单 */
  entityName?: string | null
  /** 编码字段 */
  fieldName?: string | null
  /**
   * 页码
   * @format int32
   */
  page?: number
  /**
   * 数量
   * @format int32
   */
  size?: number
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null
}

export interface GetSettingInput {
  /** 配置名 */
  name?: string | null
  /**
   * 配置级别
   * @format int32
   */
  settingScopes?: 1 | 2 | 4 | null
  /** 租户Id */
  tenantId?: string | null
  /** 用户Id */
  userId?: string | null
  /**
   * 页码
   * @format int32
   */
  page?: number
  /**
   * 数量
   * @format int32
   */
  size?: number
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null
}

export interface GetSupplierInput {
  /** 名称 */
  name?: string | null
  /** 联系人 */
  contact?: string | null
  /** 联系电话 */
  telephone?: string | null
  /** 联系地址 */
  address?: string | null
  /** 邮箱 */
  email?: string | null
  /**
   * 页码
   * @format int32
   */
  page?: number
  /**
   * 数量
   * @format int32
   */
  size?: number
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null
}

export interface GetSupplierPluginInput {
  /** 插件名称 */
  pluginName?: string | null
  /** 插件类型 */
  pluginType?: string | null
  supplierId?: string | null
  /** 状态 */
  status?: boolean | null
  /**
   * 页码
   * @format int32
   */
  page?: number
  /**
   * 数量
   * @format int32
   */
  size?: number
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null
}

/** 角色分组 */
export interface GetSysRoleGroupsInput {
  /**
   * Desc:分组名称
   * Default:
   * Nullable:False
   */
  name?: string | null
  /** 角色名称 */
  roleName?: string | null
  /**
   * Desc:排序
   * Default:0
   * Nullable:True
   * @format int32
   */
  sort?: number | null
  /**
   * 权限类型
   * @format int32
   */
  roleType?: 0 | 1 | 2 | 3 | 4 | null
  /**
   * 页码
   * @format int32
   */
  page?: number
  /**
   * 数量
   * @format int32
   */
  size?: number
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null
}

export interface GetUserInfoInput {
  /** 用户名/名字/邮箱地址  where (a.id =1 or b.id) and (c.id=2 or d.id=2) */
  filter?: string | null
  /** 姓名 */
  name?: string | null
  /** 工号 */
  jobNumber?: string | null
  /** 手机号 */
  phoneNumber?: string | null
  /** 邮箱 */
  emailAddress?: string | null
  /** 部门 */
  organizationUnitId?: string[] | null
  /** 角色 */
  roleIds?: string[] | null
  /** 状态 */
  isActive?: boolean | null
  /** 时间范围均使用 */
  createTime?: RangeDateTimeInput
  /** 时间范围均使用 */
  lastLoginTime?: RangeDateTimeInput
  /**
   * 页码
   * @format int32
   */
  page?: number
  /**
   * 数量
   * @format int32
   */
  size?: number
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null
}

export interface GroupInfo {
  groupId?: string | null
  /** xx集团 */
  name?: string | null
  logo?: string | null
}

export interface GroupInfoDto {
  /** 客户名称 */
  name?: string | null
  /** 外文名称 */
  foreignName?: string | null
  /** 曾用名 */
  formerName?: string | null
  /** 别名 */
  altName?: string | null
  /** 客户类型 */
  clientType?: string | null
  /** 证件类型 */
  creditType?: string | null
  /** 证件号码 */
  creditCode?: string | null
  /** 来源类型（立案/入库/投标） */
  originType?: string | null
  /** 来源编号 */
  originID?: string | null
  /** 通讯地址 */
  address?: string | null
  /** 税号 */
  taxNumber?: string | null
  /** 邮箱地址 */
  emailAddress?: string | null
  /** 手机号码 */
  phoneNumber?: string | null
  /** 固定电话 */
  landline?: string | null
  /** 描述说明 */
  narrative?: string | null
  /** 客户级别（星标客户/重要客户等） */
  clientLevel?: string | null
  /** 客户性质 */
  customerNature?: string | null
  /** 股东/实控人存入EntityInfo */
  entityInfosInput?: CreateEntityInfoInput[] | null
  /** Id */
  id?: string | null
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string
}

/** 通用返回信息类 */
export interface GroupInfoDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 返回数据集合 */
  response?: GroupInfoDto[] | null
}

/** 通用分页信息类 */
export interface GroupInfoDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number
  /** 返回数据 */
  data?: GroupInfoDto[] | null
}

/** 通用返回信息类 */
export interface GroupInfoDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 通用分页信息类 */
  response?: GroupInfoDtoPageModel
}

export interface HostUserInfoDto {
  /** 登录账号 */
  userName?: string | null
  /** 显示名称 */
  displayName?: string | null
  /** 工号 */
  jobNumber?: string | null
  /** 别名 */
  alterName?: string | null
  /** 手机号 */
  phoneNumber?: string | null
  /** 邮箱 */
  emailAddress?: string | null
  /** 姓名 */
  name?: string | null
  /** 时区 */
  timeZone?: string | null
  /**
   * 入职时间
   * @format date-time
   */
  entryTime?: string
  /** 租户 */
  tenantId?: string | null
  /** 组织机构Id */
  organizationUnitId?: string | null
  /** 组织机构父级Id */
  organizationUnitParentId?: string | null
  /** 分所Id */
  officeId?: string | null
  /** Id */
  id?: string | null
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string
}

/** 通用返回信息类 */
export interface HostUserInfoDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  response?: HostUserInfoDto
}

export interface IndustryInfo {
  industryCode?: string | null
  /** 科学研究和技术服务业 */
  industry?: string | null
  subIndustryCode?: string | null
  /** 研究和试验发展 */
  subIndustry?: string | null
  middleCategoryCode?: string | null
  middleCategory?: string | null
  smallCategoryCode?: string | null
  smallCategory?: string | null
}

/** 通用返回信息类 */
export interface Int64MessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /**
   * 返回数据集合
   * @format int64
   */
  response?: number
}

export interface InvestmentListItem {
  keyNo?: string | null
  /** xxxxxxxx有限公司 */
  name?: string | null
  startDate?: string | null
  /** 存续 */
  status?: string | null
  fundedRatio?: string | null
  /** 247655.7552万元人民币 */
  shouldCapi?: string | null
  industry?: IndustryInfo
  area?: Area
}

export interface ItemDto {
  /** Key值 */
  key?: string | null
  /** 预留 */
  value?: string | null
  /** 描述 */
  description?: string | null
}

/** 通用返回信息类 */
export interface ItemDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 返回数据集合 */
  response?: ItemDto[] | null
}

export interface LanguageDto {
  /** 图标 */
  icon?: string | null
  /** 语言名称 */
  name?: string | null
  /** 语言代码 */
  code?: string | null
  /** 显示名称 */
  displayName?: string | null
  /** 是否默认语言 */
  isDefault?: boolean
  /** 是否启用该语言 */
  isEnabled?: boolean
  /** Id */
  id?: string | null
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string
}

/** 通用返回信息类 */
export interface LanguageDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 返回数据集合 */
  response?: LanguageDto[] | null
}

export interface LdapConfig {
  isEnabled?: boolean
  ldapIp?: string | null
  /** @format int32 */
  port?: number
  isLdap?: boolean
  userDn?: string | null
  baseDn?: string | null
  password?: string | null
  roleTable?: string | null
  organizationTable?: string | null
  isCronSyncLdapToCurrentSystem?: boolean
  isLdapAuth?: boolean
  userObjectClass?: string | null
  userLdapLoginIdentify?: string | null
  organizationUnitObjectClass?: string | null
  roleObjectClass?: string | null
  roleFilterTable?: string | null
  ldapFieldMatchRules?: LdapFieldMatchRule[] | null
  userFilterRule?: string | null
  notSyncUserEmails?: string[] | null
}

/** 通用返回信息类 */
export interface LdapConfigMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  response?: LdapConfig
}

export interface LdapFieldMatchRule {
  fieldName?: string | null
  fieldDescription?: string | null
  /** @format int32 */
  ldapOuType?: 0 | 1 | 2
  ldapFieldName?: string | null
  fieldTips?: string | null
  isIdentify?: boolean
}

export interface LoginValidateConfig {
  /** 会话超时策略 */
  isSessionTimeout?: boolean
  /**
   * 用户在（N）分钟内没有操作，退出当前账号到锁屏界面
   * @format int32
   */
  sessionTimeoutTime?: number | null
  /** 敏感会话超时策略 */
  sensitiveSessionTimeout?: boolean
  /**
   * 用户在（N）分钟内没有操作，退出当前账号
   * @format int32
   */
  sensitiveSessionTimeoutTime?: number | null
  /** 账号锁定策略 */
  isAccountLock?: boolean
  /**
   * 账号锁定时间
   * @format int32
   */
  accountLockTime?: number | null
  /**
   * 触发验证码
   * @format int32
   */
  validateCodeNumber?: number | null
  /**
   * 触发锁定
   * @format int32
   */
  lockNumber?: number | null
  /**
   * 账号锁定时长重置
   * @format int32
   */
  accountLockResetTime?: number | null
  /** 账号停用策略 */
  isAccountStop?: boolean
  /** 最近登录提示 */
  isLastLoginHint?: boolean
  /** 登录验证提示 */
  isLoginValidateHint?: boolean
  /** 登录验证提示文本内容 */
  loginValidateHint?: string | null
}

export interface LongLat {
  longitude?: string | null
  latitude?: string | null
}

/** 合并组织机构 */
export interface MergeOrganizationInput {
  /** 原组织机构 */
  organizationUnitId?: string | null
  /** 合并组织机构 */
  mergeOrganizationUnitId?: string | null
}

export interface MessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功 */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 返回数据集合 */
  response?: any
}

export interface MoreEmailListItem {
  email?: string | null
  /** 2021年报 */
  source?: string | null
}

export interface MoreTelListItem {
  tel?: string | null
  /** 2021年报 */
  source?: string | null
}

export interface NameSearchDataItemDto {
  /** 企业名称 */
  name?: string | null
  /** 匹配原因 */
  hitReason?: string | null
  /** 数据来源 */
  dataType?: string | null
}

/** 通用返回信息类 */
export interface NameSearchDataItemDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 返回数据集合 */
  response?: NameSearchDataItemDto[] | null
}

export interface NavigationBar {
  id?: string | null
  pid?: string | null
  /** @format int32 */
  order?: number
  name?: string | null
  isHide?: boolean
  isButton?: boolean
  path?: string | null
  func?: string | null
  iconCls?: string | null
  meta?: NavigationBarMeta
  children?: NavigationBar[] | null
}

/** 通用返回信息类 */
export interface NavigationBarMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  response?: NavigationBar
}

export interface NavigationBarMeta {
  title?: string | null
  requireAuth?: boolean
  noTabPage?: boolean
  keepAlive?: boolean
}

export interface NavigationBarMetaPro {
  title?: string | null
  icon?: string | null
  show?: boolean
}

export interface NavigationBarPro {
  id?: string | null
  parentId?: string | null
  /** @format int32 */
  order?: number
  name?: string | null
  isHide?: boolean
  isButton?: boolean
  path?: string | null
  component?: string | null
  func?: string | null
  iconCls?: string | null
  meta?: NavigationBarMetaPro
}

/** 通用返回信息类 */
export interface NavigationBarProListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 返回数据集合 */
  response?: NavigationBarPro[] | null
}

/** 法定代表人信息 */
export interface OperInfo {
  /** 法定代表人主键 */
  keyNo?: string | null
  /** 法定代表人姓名 */
  name?: string | null
}

export interface OrderByModel {
  fieldName?: any
  /** @format int32 */
  orderByType?: 0 | 1
}

export interface OrganizationLeaderDto {
  /** 用户名 */
  userName?: string | null
  /** 用户Id */
  userId?: string | null
  /** 是否是主负责人 */
  isHost?: boolean
}

export interface OrganizationUnitsDto {
  id?: string | null
  /** 机构键值 */
  code?: string | null
  /** 显示名称 */
  displayName?: string | null
  /** 别名 */
  alterName?: string | null
  /** 地区代码 */
  regionCode?: string | null
  /** 父级Id */
  parentId?: string | null
  /**
   * 默认排序
   * @format int32
   */
  sort?: number
  /**
   * 阈值标识
   * @format int32
   */
  thresholdFlag?: 0 | 1 | 2 | 3
  /** 地理位置 */
  location?: string | null
  /** 工作地点 */
  workAddress?: string | null
  /** 机构描述 */
  remark?: string | null
  enterpriseInfo?: EnterpriseInfoDto
  /** 负责人 */
  organizationLeaders?: OrganizationLeaderDto[] | null
}

/** 通用返回信息类 */
export interface OrganizationUnitsDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  response?: OrganizationUnitsDto
}

/** 通用分页信息类 */
export interface OrganizationUnitsDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number
  /** 返回数据 */
  data?: OrganizationUnitsDto[] | null
}

/** 通用返回信息类 */
export interface OrganizationUnitsDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 通用分页信息类 */
  response?: OrganizationUnitsDtoPageModel
}

/** 负责人 */
export interface OrganizationUnitsTreeDto {
  /** 子节点 */
  children?: OrganizationUnitsTreeDto[] | null
  /** 是否拥有权限 */
  isCheck?: boolean
  id?: string | null
  /** 机构键值 */
  code?: string | null
  /** 显示名称 */
  displayName?: string | null
  /** 别名 */
  alterName?: string | null
  /** 地区代码 */
  regionCode?: string | null
  /** 父级Id */
  parentId?: string | null
  /**
   * 默认排序
   * @format int32
   */
  sort?: number
  /**
   * 阈值标识
   * @format int32
   */
  thresholdFlag?: 0 | 1 | 2 | 3
  /** 地理位置 */
  location?: string | null
  /** 工作地点 */
  workAddress?: string | null
  /** 机构描述 */
  remark?: string | null
  enterpriseInfo?: EnterpriseInfoDto
  /** 负责人 */
  organizationLeaders?: OrganizationLeaderDto[] | null
}

/** 通用返回信息类 */
export interface OrganizationUnitsTreeDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 负责人 */
  response?: OrganizationUnitsTreeDto
}

export interface OriginalName {
  /** 曾用名 */
  name?: string | null
  /** 变更日期 */
  changeDate?: string | null
}

export interface Parent {
  keyNo?: string | null
  /** xxxx有限责任公司 */
  name?: string | null
  operName?: string | null
  startDate?: string | null
  /** 存续 */
  status?: string | null
  /** 185000万元人民币 */
  registCapi?: string | null
}

export interface PartnerListItem {
  keyNo?: string | null
  stockName?: string | null
  /** 自然人股东 */
  stockType?: string | null
  stockPercent?: string | null
  shouldCapi?: string | null
  shoudDate?: string | null
  stakeDate?: string | null
  creditCode?: string | null
  area?: string | null
}

export interface PartnersItem {
  keyNo?: string | null
  stockName?: string | null
  /** 自然人股东 */
  stockType?: string | null
  stockPercent?: string | null
  shouldCapi?: string | null
  shoudDate?: string | null
  investType?: string | null
  investName?: string | null
  realCapi?: string | null
  capiDate?: string | null
  tagsList?: string[] | null
  finalBenefitPercent?: string | null
  relatedProduct?: string | null
  relatedOrg?: string | null
  area?: string | null
}

export interface PasswordConfig {
  /** 密码设置策略 */
  isSetting?: boolean
  /**
   * 最小密码长度
   * @format int32
   */
  minPasswordLength?: number | null
  /** 在密码中使用 */
  passwordType?: string[] | null
  /** 允许字符连续出现 */
  isContinuousCharacterNumber?: boolean | null
  /**
   * 连续字符
   * @format int32
   */
  continuousCharacterNumber?: number | null
  /** 不允许与历史密码相同 */
  isHistoryPasswordsSame?: boolean | null
  /**
   * 历史密码不重复范围
   * @format int32
   */
  historyPasswordNumber?: number | null
  /** 密码有效期 */
  isPasswordExpiration?: boolean | null
  /**
   * 密码有效期天数
   * @format int32
   */
  passwordExpirationTime?: number | null
}

export interface PathInfoListItem {
  percentTotal?: string | null
  pathList?: PathListItem[] | null
}

export interface PathListItem {
  name?: string | null
  level?: string | null
  percent?: string | null
}

export interface PathsItemItem {
  keyNo?: string | null
  name?: string | null
  percent?: string | null
  percentTotal?: string | null
  level?: string | null
}

export interface PermissionTree {
  value?: string | null
  pid?: string | null
  label?: string | null
  /** @format int32 */
  order?: number
  isBtn?: boolean
  disabled?: boolean
  isCheck?: boolean
  children?: PermissionTree[] | null
  btnList?: PermissionTree[] | null
}

/** 通用返回信息类 */
export interface PermissionTreeMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  response?: PermissionTree
}

export interface PersonListItem {
  /** @format int32 */
  org?: number
  name?: string | null
  keyNo?: string | null
}

export interface PluginItem {
  value?: string | null
  title?: string | null
}

/** 通用返回信息类 */
export interface PluginItemListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 返回数据集合 */
  response?: PluginItem[] | null
}

export interface ProductListItem {
  /** xxxx有限责任公司 */
  name?: string | null
  startDate?: string | null
  roundDesc?: string | null
  /** 北京 */
  location?: string | null
  /** xxxx是xxx公司旗下基于Android系统深度优化、定制、开发的第三方手机操作系统，也是xxxx的第一个产品。从2010年8月16日首个内测版发布至今，xxxx目前已经拥有国内外2.8亿用户，覆盖55种语言，支持142个国家与地区。 */
  description?: string | null
}

export interface PubEmployeeListItem {
  /** 刘xx */
  name?: string | null
  /** 监事会主席 */
  job?: string | null
}

export interface PubPartnerListItem {
  /** 贾xx */
  stockName?: string | null
  stockPercent?: string | null
  holdType?: string | null
  amount?: string | null
  creditCode?: string | null
  area?: string | null
}

export interface QccIndustry {
  /** 消费 */
  aName?: string | null
  /** 文娱传媒 */
  bName?: string | null
  /** 出版 */
  cName?: string | null
  /** 报纸杂志 */
  dName?: string | null
}

/** 时间范围均使用 */
export interface RangeDateTimeInput {
  /**
   * 开始时间
   * @format date-time
   */
  startDate?: string | null
  /**
   * 结束时间
   * @format date-time
   */
  endDate?: string | null
}

export interface RelationListItem {
  type?: string | null
  /** 股东 */
  typeDesc?: string | null
  value?: string | null
}

export interface RevokeInfo {
  cancelDate?: string | null
  /** 其他原因 */
  cancelReason?: string | null
  revokeDate?: string | null
  revokeReason?: string | null
}

export interface RoleDto {
  /** 角色名 */
  name?: string | null
  /** 显示名称 */
  alterName?: string | null
  /** 描述 */
  description?: string | null
  /** 分所Id */
  officeId?: string | null
  /** 是否独占 */
  isMonopoly?: boolean | null
  /**
   * 权限类型
   * @format int32
   */
  roleType?: 0 | 1 | 2 | 3 | 4
  /** 用户组Id */
  roleGroupId?: string | null
  /** 是否启用 */
  isActive?: boolean
  /** 是否默认角色 */
  isDefault?: boolean
  /** 是否虚拟角色 */
  isVirtual?: boolean
  /** Id */
  id?: string | null
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string
}

/** 通用返回信息类 */
export interface RoleDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 返回数据集合 */
  response?: RoleDto[] | null
}

/** 通用返回信息类 */
export interface RoleDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  response?: RoleDto
}

/** 通用分页信息类 */
export interface RoleDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number
  /** 返回数据 */
  data?: RoleDto[] | null
}

/** 通用返回信息类 */
export interface RoleDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 通用分页信息类 */
  response?: RoleDtoPageModel
}

export interface SecondaryListItem {
  secondaryCode?: string | null
  /** 商用机器人 */
  secondaryDes?: string | null
  tertiaryList?: TertiaryListItem[] | null
}

export interface SensitiveConfig {
  /** 多因子验证 */
  isMultiFactor?: boolean
  /** 虚拟MFA */
  isMFA?: boolean
  /** 短信 */
  issms?: boolean
  /** 邮箱 */
  isEmail?: boolean
  /** 操作 */
  isOperator?: boolean
}

export interface SequenceNumberRulesDto {
  /** 编码名称 */
  name?: string | null
  /** 所属表单 */
  tableName?: string | null
  /** 编码字段 */
  field?: string | null
  /** 段间分隔符 */
  separator?: string | null
  /**
   * 应用规则
   * @format int32
   */
  applyRulesType?: 0 | 1
  /** 编码依据变化时重新编码 */
  isChange?: boolean
  /** 允许断号补偿 */
  isCompensate?: boolean
  /**
   * 补位衬垫位置
   * @format int32
   */
  paddingSideType?: 0 | 1
  /**
   * 起始值时间
   * @format date-time
   */
  startTime?: string
  /** 流水号单据编码配置 */
  sequenceNumberSettings?: SequenceNumberSettingsDto[] | null
  /** Id */
  id?: string | null
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string
}

/** 通用返回信息类 */
export interface SequenceNumberRulesDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  response?: SequenceNumberRulesDto
}

/** 通用分页信息类 */
export interface SequenceNumberRulesDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number
  /** 返回数据 */
  data?: SequenceNumberRulesDto[] | null
}

/** 通用返回信息类 */
export interface SequenceNumberRulesDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 通用分页信息类 */
  response?: SequenceNumberRulesDtoPageModel
}

export interface SequenceNumberRulesOutput {
  sequenceNumberRules?: SequenceNumberRulesDto
  /** 实体名称 */
  entityName?: string | null
  /** 实体描述 */
  entityDescription?: string | null
  /** 字段名称 */
  fieldName?: string | null
  /** 字段描述 */
  description?: string | null
  /** 数据源键 */
  dataSourceKey?: string | null
  /**
   * 数据类型
   * @format int32
   */
  fieldDataType?: 0 | 1 | 2 | 3 | 4 | 5
}

/** 通用分页信息类 */
export interface SequenceNumberRulesOutputPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number
  /** 返回数据 */
  data?: SequenceNumberRulesOutput[] | null
}

/** 通用返回信息类 */
export interface SequenceNumberRulesOutputPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 通用分页信息类 */
  response?: SequenceNumberRulesOutputPageModel
}

export interface SequenceNumberSettingsDto {
  /**
   * 分段类型
   * @format int32
   */
  sectionType?: 0 | 1 | 2 | 3 | 4
  /** 常量内容 */
  value?: string | null
  /**
   * 日期格式
   * @format int32
   */
  dateFormat?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11
  /**
   * 补位衬垫位置
   * @format int32
   */
  paddingSide?: 0 | 1
  /**
   * 文本包裹符
   * @format int32
   */
  textWrapperStyle?: 0 | 40 | 60 | 123 | 12298 | 12300 | 12302 | 12304 | 65288
  /**
   * 流水号长度
   * @format int32
   */
  length?: number
  /**
   * 起始值
   * @format int32
   */
  startValue?: number
  /** 单位 */
  unit?: string | null
  /** 是否自动补位 */
  isAutomaticFilling?: boolean
  /** 是否开启子号设置 */
  isSubNumber?: boolean
  /**
   * 子号长度
   * @format int32
   */
  subNumberLength?: number
  /**
   * 子号补位衬垫位置
   * @format int32
   */
  subNumberPaddingSide?: 0 | 1
  /**
   * 子号起始值
   * @format int32
   */
  subNumberStartValue?: number
  /** 子号单位 */
  subNumberUnit?: string | null
  /** 字段来源 */
  fieldSource?: string | null
  /** 字段来源 */
  fieldSourceList?: FieldSourceDto[] | null
  /** 代值设置 */
  substitutionValue?: string | null
  /** 代值设置 */
  substitutionList?: SubstitutionDto[] | null
  /** 是否流水号依据 */
  isBasedOn?: boolean
  /** 是否业务字段依据 */
  isFieldBasedOn?: boolean
  /** 是否起始值依据 */
  isStartBasedOn?: boolean
  /** 是否启用 */
  isEnabled?: boolean
}

export interface SettingDto {
  /** 配置名 */
  name?: string | null
  /** 值 */
  value?: string | null
  /**
   * 配置级别
   * @format int32
   */
  settingScopes?: 1 | 2 | 4
  /** 租户Id */
  tenantId?: string | null
  /** 租户名 */
  tenantName?: string | null
  /** 用户Id */
  userId?: string | null
  /** 用户名 */
  userName?: string | null
  /** Id */
  id?: string | null
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string
}

/** 通用分页信息类 */
export interface SettingDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number
  /** 返回数据 */
  data?: SettingDto[] | null
}

/** 通用返回信息类 */
export interface SettingDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 通用分页信息类 */
  response?: SettingDtoPageModel
}

export interface SettingLanguageDto {
  /** 语言 */
  languageNames?: ComboboxItemDto[] | null
  /** 图标 */
  flags?: ComboboxItemDto[] | null
}

/** 通用返回信息类 */
export interface SettingLanguageDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  response?: SettingLanguageDto
}

export interface SpotCheckListItem {
  /** 淮滨县市场监督管理局 */
  executiveOrg?: string | null
  /** 检查 */
  type?: string | null
  date?: string | null
  /** 符合 */
  consequence?: string | null
}

export interface StockInfo {
  stockNumber?: string | null
  /** 新三板 */
  stockType?: string | null
}

/** 通用返回信息类 */
export interface StringListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 返回数据集合 */
  response?: string[] | null
}

/** 通用返回信息类 */
export interface StringMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 返回数据集合 */
  response?: string | null
}

/** 代值 */
export interface SubstitutionDto {
  /** 字段值 */
  fieldValues?: FieldValueDto[] | null
  /** 代值 */
  substitutionValue?: string | null
  /**
   * 起始值
   * @format int32
   */
  startValue?: number
}

export interface SupplierDto {
  /** 供应商类型 */
  supplierTypes?: (0 | 1 | 2 | 3)[] | null
  /** 名称 */
  name?: string | null
  /** 联系人 */
  contact?: string | null
  /** 联系电话 */
  telephone?: string | null
  /** 联系地址 */
  address?: string | null
  /** 邮箱 */
  email?: string | null
  /** Id */
  id?: string | null
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string
}

/** 通用分页信息类 */
export interface SupplierDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number
  /** 返回数据 */
  data?: SupplierDto[] | null
}

/** 通用返回信息类 */
export interface SupplierDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 通用分页信息类 */
  response?: SupplierDtoPageModel
}

export interface SupplierPluginDto {
  /** 插件名称 */
  pluginName?: string | null
  /** 插件类型 */
  pluginType?: string | null
  /** 供应商id */
  supplierId?: string | null
  /** 供应商名称 */
  supplierName?: string | null
  /** 供应商插件类别名称 */
  pluginTypeNames?: string | null
  ico?: string | null
  /** 状态 */
  status?: boolean
  /** 配置字段 */
  fieldsJson?: string | null
  remark?: string | null
  /** @format date-time */
  modifyTime?: string | null
  /** Id */
  id?: string | null
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string
}

/** 通用分页信息类 */
export interface SupplierPluginDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number
  /** 返回数据 */
  data?: SupplierPluginDto[] | null
}

/** 通用返回信息类 */
export interface SupplierPluginDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 通用分页信息类 */
  response?: SupplierPluginDtoPageModel
}

export interface SysEmailTemplatesDto {
  /**
   * Desc:模版名称 名字唯一
   * Default:
   * Nullable:False
   */
  templateName?: string | null
  /**
   * Desc:配置层级
   * Default:
   * Nullable:False
   * @format int32
   */
  settingLevel?: 0 | 1 | 2 | 3
  /**
   * Desc:模版正文
   * Default:
   * Nullable:False
   */
  templateContent?: string | null
  /**
   * Desc:模版描述
   * Default:
   * Nullable:True
   */
  templateSubject?: string | null
  /**
   * Desc:授权失效时间 分钟
   * Default:
   * Nullable:False
   * @format int32
   */
  authFailureTime?: number
  /** Id */
  id?: string | null
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string
}

/** 通用返回信息类 */
export interface SysEmailTemplatesDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 返回数据集合 */
  response?: SysEmailTemplatesDto[] | null
}

/** 通用返回信息类 */
export interface SysEmailTemplatesDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  response?: SysEmailTemplatesDto
}

/** 通用分页信息类 */
export interface SysEmailTemplatesDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number
  /** 返回数据 */
  data?: SysEmailTemplatesDto[] | null
}

/** 通用返回信息类 */
export interface SysEmailTemplatesDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 通用分页信息类 */
  response?: SysEmailTemplatesDtoPageModel
}

/** 雇员表彰和奖励 */
export interface SysEmployeeAwardsDto {
  /**
   * Desc:入职日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  entryDate?: string | null
  /**
   * Desc:类别
   * Default:
   * Nullable:True
   */
  type?: string | null
  /**
   * Desc:最后工作日
   * Default:
   * Nullable:True
   * @format date-time
   */
  lastWorkDate?: string | null
  /**
   * Desc:描述
   * Default:
   * Nullable:True
   */
  discription?: string | null
  /**
   * Desc:奖项
   * Default:
   * Nullable:True
   */
  awardName?: string | null
  /**
   * Desc:工号
   * Default:
   * Nullable:True
   */
  jobNumber?: string | null
  /**
   * Desc:奖励级别
   * Default:
   * Nullable:True
   * @format int32
   */
  level?: number | null
  /**
   * Desc:获奖日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  date?: string | null
  /**
   * Desc:雇员Id
   * Default:
   * Nullable:False
   */
  userId?: string | null
  /** Id */
  id?: string | null
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string
}

/** 通用返回信息类 */
export interface SysEmployeeAwardsDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 返回数据集合 */
  response?: SysEmployeeAwardsDto[] | null
}

/** 通用返回信息类 */
export interface SysEmployeeAwardsDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 雇员表彰和奖励 */
  response?: SysEmployeeAwardsDto
}

/** 通用分页信息类 */
export interface SysEmployeeAwardsDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number
  /** 返回数据 */
  data?: SysEmployeeAwardsDto[] | null
}

/** 通用返回信息类 */
export interface SysEmployeeAwardsDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 通用分页信息类 */
  response?: SysEmployeeAwardsDtoPageModel
}

/** 雇员证书执照 */
export interface SysEmployeeCertificatesDto {
  /**
   * Desc:证书名称
   * Default:
   * Nullable:True
   */
  name?: string | null
  /**
   * Desc:证书描述
   * Default:
   * Nullable:True
   */
  discription?: string | null
  /**
   * Desc:获得时间
   * Default:
   * Nullable:True
   * @format date-time
   */
  gettime?: string | null
  /**
   * Desc:学习证书Id
   * Default:
   * Nullable:True
   */
  learningCertificateId?: string | null
  /**
   * Desc:有效期至
   * Default:
   * Nullable:True
   * @format date-time
   */
  validuntil?: string | null
  /**
   * Desc:入职日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  entryDate?: string | null
  /**
   * Desc:证书编号
   * Default:
   * Nullable:True
   */
  certificateNo?: string | null
  /**
   * Desc:发证机构
   * Default:
   * Nullable:True
   */
  issauthority?: string | null
  /**
   * Desc:工号
   * Default:
   * Nullable:True
   */
  jobNumber?: string | null
  /**
   * Desc:审批状态
   * Default:
   * Nullable:True
   */
  approveStatus?: string | null
  /**
   * Desc:最后工作日
   * Default:
   * Nullable:True
   * @format date-time
   */
  lastWorkDate?: string | null
  /**
   * Desc:生效日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  effectiveDate?: string | null
  /**
   * Desc:证书照类型
   * Default:
   * Nullable:True
   */
  certificateType?: string | null
  /**
   * Desc:雇员Id
   * Default:
   * Nullable:False
   */
  userId?: string | null
  /** Id */
  id?: string | null
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string
}

/** 通用返回信息类 */
export interface SysEmployeeCertificatesDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 返回数据集合 */
  response?: SysEmployeeCertificatesDto[] | null
}

/** 通用返回信息类 */
export interface SysEmployeeCertificatesDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 雇员证书执照 */
  response?: SysEmployeeCertificatesDto
}

/** 通用分页信息类 */
export interface SysEmployeeCertificatesDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number
  /** 返回数据 */
  data?: SysEmployeeCertificatesDto[] | null
}

/** 通用返回信息类 */
export interface SysEmployeeCertificatesDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 通用分页信息类 */
  response?: SysEmployeeCertificatesDtoPageModel
}

/** 雇员教育经历 */
export interface SysEmployeeEducationsDto {
  /**
   * Desc:学历
   * Default:
   * Nullable:True
   */
  educationLevel?: string | null
  /**
   * Desc:工号
   * Default:
   * Nullable:True
   */
  jobNumber?: string | null
  /**
   * Desc:培养方式
   * Default:
   * Nullable:True
   */
  trainingMode?: string | null
  /**
   * Desc:工作情况
   * Default:
   * Nullable:True
   */
  performance?: string | null
  /**
   * Desc:备注
   * Default:
   * Nullable:True
   */
  comments?: string | null
  /**
   * Desc:学制
   * Default:
   * Nullable:True
   */
  schoolSystme?: string | null
  /**
   * Desc:专业类别
   * Default:
   * Nullable:True
   */
  specializedfield?: string | null
  /**
   * Desc:专业授予机构
   * Default:
   * Nullable:True
   */
  grantingInstitution?: string | null
  /**
   * Desc:专业
   * Default:
   * Nullable:True
   */
  major?: string | null
  /**
   * Desc:毕业学校
   * Default:
   * Nullable:True
   */
  schoolCode?: string | null
  /**
   * Desc:班级排名
   * Default:
   * Nullable:True
   */
  classRanking?: string | null
  /**
   * Desc:学校名繁体
   * Default:
   * Nullable:True
   */
  school_zh_TW?: string | null
  /**
   * Desc:学位授予国家
   * Default:
   * Nullable:True
   */
  degreeCountry?: string | null
  /**
   * Desc:专业描述
   * Default:
   * Nullable:True
   */
  mainCourse?: string | null
  /**
   * Desc:专业描述
   * Default:
   * Nullable:True
   */
  majorDescription?: string | null
  /**
   * Desc:审批状态
   * Default:
   * Nullable:True
   */
  approveStatus?: string | null
  /**
   * Desc:学位
   * Default:
   * Nullable:True
   */
  degree?: string | null
  /**
   * Desc:所在城市
   * Default:
   * Nullable:True
   */
  location?: string | null
  /**
   * Desc:毕业学校名称
   * Default:
   * Nullable:True
   */
  school?: string | null
  /**
   * Desc:见证人
   * Default:
   * Nullable:True
   */
  prover?: string | null
  /**
   * Desc:是否主专业
   * Default:
   * Nullable:True
   */
  isFirstMajor?: boolean | null
  /**
   * Desc:是否最高学历
   * Default:
   * Nullable:True
   */
  isHighestEducation?: boolean | null
  /**
   * Desc:是否最高学位
   * Default:
   * Nullable:True
   */
  isHighestDegree?: boolean | null
  /**
   * Desc:是否第一学历
   * Default:
   * Nullable:True
   */
  isFirstEducationLevel?: boolean | null
  /**
   * Desc:学习形式
   * Default:
   * Nullable:True
   * @format int32
   */
  studyMode?: number | null
  /**
   * Desc:毕业类型
   * Default:
   * Nullable:True
   * @format int32
   */
  graduationType?: number | null
  /**
   * Desc:入职日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  entryDate?: string | null
  /**
   * Desc:结束日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  enddatE?: string | null
  /**
   * Desc:最后工作日
   * Default:
   * Nullable:True
   * @format date-time
   */
  lastWorkDate?: string | null
  /**
   * Desc:毕业学校名称_英文
   * Default:
   * Nullable:True
   */
  school_en_US?: string | null
  /**
   * Desc:雇员Id
   * Default:
   * Nullable:False
   */
  userId?: string | null
  /** Id */
  id?: string | null
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string
}

/** 通用返回信息类 */
export interface SysEmployeeEducationsDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 返回数据集合 */
  response?: SysEmployeeEducationsDto[] | null
}

/** 通用返回信息类 */
export interface SysEmployeeEducationsDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 雇员教育经历 */
  response?: SysEmployeeEducationsDto
}

/** 通用分页信息类 */
export interface SysEmployeeEducationsDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number
  /** 返回数据 */
  data?: SysEmployeeEducationsDto[] | null
}

/** 通用返回信息类 */
export interface SysEmployeeEducationsDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 通用分页信息类 */
  response?: SysEmployeeEducationsDtoPageModel
}

/** 材料管理 */
export interface SysEmployeeEntryMaterialRecDto {
  /**
   * Desc:材料名称
   * Default:
   * Nullable:True
   */
  materialName?: string | null
  /**
   * Desc:所属单元
   * Default:
   * Nullable:True
   */
  onwerMou?: string | null
  /**
   * Desc:催办时间
   * Default:
   * Nullable:True
   * @format date-time
   */
  promotTime?: string | null
  /**
   * Desc:是否法定
   * Default:
   * Nullable:True
   */
  isLegal?: boolean | null
  /**
   * Desc:是否收到
   * Default:
   * Nullable:True
   */
  hasReceived?: boolean | null
  /**
   * Desc:退回备注
   * Default:
   * Nullable:True
   */
  giveBackRemark?: string | null
  /**
   * Desc:材料名称(文本)
   * Default:
   * Nullable:True
   */
  name?: string | null
  /**
   * Desc:提交日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  submitDate?: string | null
  /**
   * Desc:组织排序号
   * Default:
   * Nullable:True
   */
  organizationOrderNum?: string | null
  /**
   * Desc:工号
   * Default:
   * Nullable:True
   */
  jobNumber?: string | null
  /**
   * Desc:材料说明
   * Default:
   * Nullable:True
   */
  remark?: string | null
  /**
   * Desc:材料状态
   * Default:
   * Nullable:True
   */
  materialState?: string | null
  /**
   * Desc:创建时间
   * Default:
   * Nullable:True
   * @format date-time
   */
  createdTime?: string | null
  /**
   * Desc:准备阶段
   * Default:
   * Nullable:True
   */
  preparationPhase?: string | null
  /**
   * Desc:接收日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  receiveDate?: string | null
  /**
   * Desc:退回日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  giveBackDate?: string | null
  /**
   * Desc:未提交备注
   * Default:
   * Nullable:True
   */
  notSubmittedRemarks?: string | null
  /**
   * Desc:附件
   * Default:
   * Nullable:True
   */
  materialAttachment?: string | null
  /**
   * Desc:入职日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  entryDate?: string | null
  /**
   * Desc:处理备注
   * Default:
   * Nullable:True
   */
  handleRemark?: string | null
  /**
   * Desc:是否提交
   * Default:
   * Nullable:True
   */
  hasSubmitted?: boolean | null
  /**
   * Desc:预计提交日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  prepareSubmitDate?: string | null
  /**
   * Desc:是否必备
   * Default:
   * Nullable:True
   */
  isEssential?: boolean | null
  /**
   * Desc:所属单元层级
   * Default:
   * Nullable:True
   * @format int32
   */
  onwerMou_TreeLevel?: number | null
  /**
   * Desc:催办人
   * Default:
   * Nullable:True
   * @format int32
   */
  promoter?: number | null
  /**
   * Desc:所属单元路径
   * Default:
   * Nullable:True
   */
  onwerMou_TreePath?: string | null
  /**
   * Desc:材料图片缩略图
   * Default:
   * Nullable:True
   */
  materialImageThumbnail?: string | null
  /**
   * Desc:最后工作日
   * Default:
   * Nullable:True
   * @format date-time
   */
  lastWorkDate?: string | null
  /**
   * Desc:雇员Id
   * Default:
   * Nullable:False
   */
  userId?: string | null
  /** Id */
  id?: string | null
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string
}

/** 通用返回信息类 */
export interface SysEmployeeEntryMaterialRecDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 返回数据集合 */
  response?: SysEmployeeEntryMaterialRecDto[] | null
}

/** 通用返回信息类 */
export interface SysEmployeeEntryMaterialRecDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 材料管理 */
  response?: SysEmployeeEntryMaterialRecDto
}

/** 通用分页信息类 */
export interface SysEmployeeEntryMaterialRecDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number
  /** 返回数据 */
  data?: SysEmployeeEntryMaterialRecDto[] | null
}

/** 通用返回信息类 */
export interface SysEmployeeEntryMaterialRecDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 通用分页信息类 */
  response?: SysEmployeeEntryMaterialRecDtoPageModel
}

/** 雇员家庭信息 */
export interface SysEmployeeFamilyDto {
  /**
   * Desc:性别
   * Default:
   * Nullable:True
   * @format int32
   */
  gender?: number | null
  /**
   * Desc:组织排序号
   * Default:
   * Nullable:True
   */
  organizationOrderNum?: string | null
  /**
   * Desc:与本人关系
   * Default:
   * Nullable:True
   */
  relationType?: string | null
  /**
   * Desc:职级排序号
   * Default:
   * Nullable:True
   */
  jobLevelOrderNum?: string | null
  /**
   * Desc:业务修改人
   * Default:
   * Nullable:True
   * @format int32
   */
  businessModifiedBy?: number | null
  /**
   * Desc:出生日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  birthday?: string | null
  /**
   * Desc:最后工作日
   * Default:
   * Nullable:True
   * @format date-time
   */
  lastWorkDate?: string | null
  /**
   * Desc:审批状态
   * Default:
   * Nullable:True
   */
  approveStatus?: string | null
  /**
   * Desc:邮件
   * Default:
   * Nullable:True
   */
  email?: string | null
  /**
   * Desc:工号
   * Default:
   * Nullable:True
   */
  jobNumber?: string | null
  /**
   * Desc:职务
   * Default:
   * Nullable:True
   */
  jobPost?: string | null
  /**
   * Desc:入职日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  entryDate?: string | null
  /**
   * Desc:政治面貌
   * Default:
   * Nullable:True
   */
  politicalStatus?: string | null
  /**
   * Desc:手机号码
   * Default:
   * Nullable:True
   */
  mobile?: string | null
  /**
   * Desc:与本人关系名称
   * Default:
   * Nullable:True
   */
  relation?: string | null
  /**
   * Desc:联系电话
   * Default:
   * Nullable:True
   */
  telephone?: string | null
  /**
   * Desc:成员姓名
   * Default:
   * Nullable:True
   */
  name?: string | null
  /**
   * Desc:工作单位
   * Default:
   * Nullable:True
   */
  company?: string | null
  /**
   * Desc:雇员Id
   * Default:
   * Nullable:False
   */
  userId?: string | null
  /** Id */
  id?: string | null
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string
}

/** 通用返回信息类 */
export interface SysEmployeeFamilyDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 返回数据集合 */
  response?: SysEmployeeFamilyDto[] | null
}

/** 通用返回信息类 */
export interface SysEmployeeFamilyDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 雇员家庭信息 */
  response?: SysEmployeeFamilyDto
}

/** 通用分页信息类 */
export interface SysEmployeeFamilyDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number
  /** 返回数据 */
  data?: SysEmployeeFamilyDto[] | null
}

/** 通用返回信息类 */
export interface SysEmployeeFamilyDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 通用分页信息类 */
  response?: SysEmployeeFamilyDtoPageModel
}

/** 雇员信息表 */
export interface SysEmployeeInfosDto {
  /**
   * Desc:显示名称
   * Default:
   * Nullable:True
   */
  displayName?: string | null
  /**
   * Desc:入职时间
   * Default:
   * Nullable:True
   * @format date-time
   */
  entryTime?: string | null
  /**
   * Desc:别名
   * Default:
   * Nullable:True
   */
  alterName?: string | null
  /**
   * Desc:手机号
   * Default:
   * Nullable:True
   */
  phoneNumber?: string | null
  /**
   * Desc:描述
   * Default:
   * Nullable:True
   */
  description?: string | null
  /**
   * Desc:
   * Default:
   * Nullable:True
   */
  address?: string | null
  /**
   * Desc:邮箱
   * Default:
   * Nullable:True
   */
  emailAddress?: string | null
  /**
   * Desc:
   * Default:
   * Nullable:True
   * @format int32
   */
  sex?: number | null
  /**
   * Desc:生日
   * Default:
   * Nullable:True
   * @format date-time
   */
  birthday?: string | null
  /**
   * Desc:
   * Default:
   * Nullable:True
   * @format int32
   */
  age?: number | null
  /**
   * Desc:教育背景
   * Default:
   * Nullable:True
   */
  educationBackground?: string | null
  /**
   * Desc:工号
   * Default:
   * Nullable:True
   */
  jobNumber?: string | null
  /**
   * Desc:Id卡类型
   * Default:
   * Nullable:True
   */
  idCardType?: string | null
  /**
   * Desc:唯一标识号码
   * Default:
   * Nullable:True
   */
  idCardNumber?: string | null
  /**
   * Desc:毕业时间
   * Default:
   * Nullable:True
   * @format date-time
   */
  graduateDate?: string | null
  /**
   * Desc:婚姻状态
   * Default:
   * Nullable:True
   */
  maritalStatus?: string | null
  /**
   * Desc:用户类型
   * Default:
   * Nullable:True
   */
  userType?: string | null
  /**
   * Desc:
   * Default:
   * Nullable:True
   * @format int32
   */
  origin?: number | null
  /**
   * Desc:外部标识
   * Default:
   * Nullable:True
   */
  originalId?: string | null
  /**
   * Desc:户籍所在地
   * Default:
   * Nullable:True
   */
  householdRegister?: string | null
  /**
   * Desc:民族
   * Default:
   * Nullable:True
   */
  nation?: string | null
  /**
   * Desc:政治面貌
   * Default:
   * Nullable:True
   */
  politicalOutlook?: string | null
  /**
   * Desc:籍贯
   * Default:
   * Nullable:True
   */
  nativePlace?: string | null
  /**
   * Desc:兴趣爱好
   * Default:
   * Nullable:True
   */
  hobbies?: string | null
  /**
   * Desc:特长
   * Default:
   * Nullable:True
   */
  speciality?: string | null
  /**
   * Desc:参加工作时间
   * Default:
   * Nullable:True
   * @format date-time
   */
  beganWorkDate?: string | null
  /**
   * Desc:毕业学校
   * Default:
   * Nullable:True
   */
  graduateFrom?: string | null
  /**
   * Desc:专业
   * Default:
   * Nullable:True
   */
  major?: string | null
  /**
   * Desc:工作地址
   * Default:
   * Nullable:True
   */
  officeLocation?: string | null
  /**
   * Desc:工作电话
   * Default:
   * Nullable:True
   */
  workPhone?: string | null
  /**
   * Desc:传真
   * Default:
   * Nullable:True
   */
  fax?: string | null
  /**
   * Desc:座机
   * Default:
   * Nullable:True
   */
  landline?: string | null
  /**
   * Desc:头像地址
   * Default:
   * Nullable:True
   */
  pictureUrl?: string | null
  /**
   * Desc:关于我
   * Default:
   * Nullable:True
   */
  aboutMe?: string | null
  /**
   * Desc:最高学历
   * Default:
   * Nullable:True
   */
  highestEducation?: string | null
  /**
   * Desc:邮政编码
   * Default:
   * Nullable:True
   */
  postalCode?: string | null
  /**
   * Desc:对外费率
   * Default:
   * Nullable:True
   * @format double
   */
  externalRate?: number | null
  /**
   * Desc:内部费率
   * Default:
   * Nullable:True
   * @format double
   */
  internalRate?: number | null
  /**
   * Desc:币种
   * Default:
   * Nullable:True
   */
  currency?: string | null
  /**
   * Desc:擅长语言
   * Default:
   * Nullable:True
   */
  goodLanguage?: string | null
  /**
   * Desc:擅长专业
   * Default:
   * Nullable:True
   */
  goodProfessional?: string | null
  /**
   * Desc:原执业律师事务所
   * Default:
   * Nullable:True
   */
  formerLawyer?: string | null
  /**
   * Desc:执业证号
   * Default:
   * Nullable:True
   */
  formerLawyerNumber?: string | null
  /**
   * Desc:紧急联系人
   * Default:
   * Nullable:True
   */
  emergencyContact?: string | null
  /**
   * Desc:与其关系
   * Default:
   * Nullable:True
   */
  relationship?: string | null
  /**
   * Desc:紧急联系人电话
   * Default:
   * Nullable:True
   */
  emergencyContactPhone?: string | null
  /**
   * Desc:紧急联系人地址
   * Default:
   * Nullable:True
   */
  emergencyContactAddress?: string | null
  /**
   * Desc:律师关系存放地
   * Default:
   * Nullable:True
   */
  localRelationshipStorage?: string | null
  /**
   * Desc:律师关系存放日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  localRelationshipTime?: string | null
  /**
   * Desc:人事档案存放地
   * Default:
   * Nullable:True
   */
  personnelArchivesStorageAgency?: string | null
  /**
   * Desc:存档号
   * Default:
   * Nullable:True
   */
  archiveNumber?: string | null
  /**
   * Desc:存档日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  archiveTime?: string | null
  /**
   * Desc:雇员Id
   * Default:
   * Nullable:False
   */
  userId?: string | null
  /** Id */
  id?: string | null
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string
}

/** 通用返回信息类 */
export interface SysEmployeeInfosDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 返回数据集合 */
  response?: SysEmployeeInfosDto[] | null
}

/** 通用返回信息类 */
export interface SysEmployeeInfosDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 雇员信息表 */
  response?: SysEmployeeInfosDto
}

/** 通用分页信息类 */
export interface SysEmployeeInfosDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number
  /** 返回数据 */
  data?: SysEmployeeInfosDto[] | null
}

/** 通用返回信息类 */
export interface SysEmployeeInfosDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 通用分页信息类 */
  response?: SysEmployeeInfosDtoPageModel
}

/** 雇员工作经历 */
export interface SysEmployeeJobHistoryDto {
  /**
   * Desc:审批状态
   * Default:
   * Nullable:True
   */
  approveStatus?: string | null
  /**
   * Desc:部门_繁体
   * Default:
   * Nullable:True
   */
  department_zh_TW?: string | null
  /**
   * Desc:单位名称
   * Default:
   * Nullable:True
   */
  company?: string | null
  /**
   * Desc:工作业绩
   * Default:
   * Nullable:True
   */
  achievement?: string | null
  /**
   * Desc:月薪（税前）
   * Default:
   * Nullable:True
   * @format double
   */
  salary?: number | null
  /**
   * Desc:离职日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  dimissionDate?: string | null
  /**
   * Desc:职务_英文
   * Default:
   * Nullable:True
   */
  firstJob_en_US?: string | null
  /**
   * Desc:工作类型
   * Default:
   * Nullable:True
   */
  jobType?: string | null
  /**
   * Desc:企业规模
   * Default:
   * Nullable:True
   */
  companySize?: string | null
  /**
   * Desc:工号
   * Default:
   * Nullable:True
   */
  jobNumber?: string | null
  /**
   * Desc:与本人关系
   * Default:
   * Nullable:True
   */
  proverRelation?: string | null
  /**
   * Desc:部门_英文
   * Default:
   * Nullable:True
   */
  department_en_US?: string | null
  /**
   * Desc:证明人联系方式
   * Default:
   * Nullable:True
   */
  proverContactInfo?: string | null
  /**
   * Desc:下属人数
   * Default:
   * Nullable:True
   * @format int32
   */
  underlingNum?: number | null
  /**
   * Desc:部门
   * Default:
   * Nullable:True
   */
  department?: string | null
  /**
   * Desc:证明人
   * Default:
   * Nullable:True
   */
  proverName?: string | null
  /**
   * Desc:行业类别
   * Default:
   * Nullable:True
   */
  hangye?: string | null
  /**
   * Desc:单位介绍
   * Default:
   * Nullable:True
   */
  introduction?: string | null
  /**
   * Desc:职务_繁体
   * Default:
   * Nullable:True
   */
  firstJob_zh_TW?: string | null
  /**
   * Desc:工作性质
   * Default:
   * Nullable:True
   */
  workKind?: string | null
  /**
   * Desc:单位类型
   * Default:
   * Nullable:True
   */
  companyType?: string | null
  /**
   * Desc:开始日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  startDate?: string | null
  /**
   * Desc:职务
   * Default:
   * Nullable:True
   */
  firstJob?: string | null
  /**
   * Desc:单位名称_繁体
   * Default:
   * Nullable:True
   */
  company_zh_TW?: string | null
  /**
   * Desc:职级名称
   * Default:
   * Nullable:True
   */
  jobLevel?: string | null
  /**
   * Desc:职级名称_繁体
   * Default:
   * Nullable:True
   */
  jobLevel_zh_TW?: string | null
  /**
   * Desc:是否本单位工作经历
   * Default:
   * Nullable:True
   */
  whetherTheWorkExperienceOfTheUnit?: boolean | null
  /**
   * Desc:职级名称_英文
   * Default:
   * Nullable:True
   */
  jobLevel_en_US?: string | null
  /**
   * Desc:职位_繁体
   * Default:
   * Nullable:True
   */
  lastDog_zh_TW?: string | null
  /**
   * Desc:离职原因
   * Default:
   * Nullable:True
   */
  leaveReason?: string | null
  /**
   * Desc:入职日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  beginDate?: string | null
  /**
   * Desc:入职日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  entryDate?: string | null
  /**
   * Desc:结束日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  stopDate?: string | null
  /**
   * Desc:任职类型
   * Default:
   * Nullable:True
   */
  serviceType?: string | null
  /**
   * Desc:职位
   * Default:
   * Nullable:True
   */
  lastJog?: string | null
  /**
   * Desc:部门全称
   * Default:
   * Nullable:True
   */
  poiOldAdminNameTreePath?: string | null
  /**
   * Desc:职位英文
   * Default:
   * Nullable:True
   */
  lastJog_en_US?: string | null
  /**
   * Desc:工作地址
   * Default:
   * Nullable:True
   */
  address?: string | null
  /**
   * Desc:最后工作日
   * Default:
   * Nullable:True
   * @format date-time
   */
  lastWorkDate?: string | null
  /**
   * Desc:证明人职务
   * Default:
   * Nullable:True
   */
  proverJobPost?: string | null
  /**
   * Desc:工作职责
   * Default:
   * Nullable:True
   */
  responsibility?: string | null
  /**
   * Desc:雇员Id
   * Default:
   * Nullable:False
   */
  userId?: string | null
  /** Id */
  id?: string | null
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string
}

/** 通用返回信息类 */
export interface SysEmployeeJobHistoryDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 返回数据集合 */
  response?: SysEmployeeJobHistoryDto[] | null
}

/** 通用返回信息类 */
export interface SysEmployeeJobHistoryDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 雇员工作经历 */
  response?: SysEmployeeJobHistoryDto
}

/** 通用分页信息类 */
export interface SysEmployeeJobHistoryDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number
  /** 返回数据 */
  data?: SysEmployeeJobHistoryDto[] | null
}

/** 通用返回信息类 */
export interface SysEmployeeJobHistoryDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 通用分页信息类 */
  response?: SysEmployeeJobHistoryDtoPageModel
}

export interface SysEmployeeLanguageAbilitiesDto {
  /**
   * Desc:语言
   * Default:
   * Nullable:True
   */
  language?: string | null
  /**
   * Desc:技能描述
   * Default:
   * Nullable:True
   */
  languageskill?: string | null
  /**
   * Desc:审批状态
   * Default:
   * Nullable:True
   */
  approveStatus?: string | null
  /**
   * Desc:掌握程度
   * Default:
   * Nullable:True
   */
  level?: string | null
  /**
   * Desc:书写能力
   * Default:
   * Nullable:True
   */
  writingAbility?: string | null
  /**
   * Desc:工号
   * Default:
   * Nullable:True
   */
  jobNumber?: string | null
  /**
   * Desc:最后工作日
   * Default:
   * Nullable:True
   * @format date-time
   */
  lastWorkDate?: string | null
  /**
   * Desc:是否母语
   * Default:
   * Nullable:True
   */
  isNative?: boolean | null
  /**
   * Desc:入职日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  entryDate?: string | null
  /**
   * Desc:阅读能力
   * Default:
   * Nullable:True
   */
  readingAbility?: string | null
  /**
   * Desc:口语能力
   * Default:
   * Nullable:True
   */
  speakingAbility?: string | null
  /**
   * Desc:雇员Id
   * Default:
   * Nullable:False
   */
  userId?: string | null
  /** Id */
  id?: string | null
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string
}

/** 通用返回信息类 */
export interface SysEmployeeLanguageAbilitiesDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 返回数据集合 */
  response?: SysEmployeeLanguageAbilitiesDto[] | null
}

/** 通用返回信息类 */
export interface SysEmployeeLanguageAbilitiesDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  response?: SysEmployeeLanguageAbilitiesDto
}

/** 通用分页信息类 */
export interface SysEmployeeLanguageAbilitiesDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number
  /** 返回数据 */
  data?: SysEmployeeLanguageAbilitiesDto[] | null
}

/** 通用返回信息类 */
export interface SysEmployeeLanguageAbilitiesDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 通用分页信息类 */
  response?: SysEmployeeLanguageAbilitiesDtoPageModel
}

/** 雇员专业技术职务 */
export interface SysEmployeeProfessionalTechnicalPostInfoDto {
  /**
   * Desc:工号
   * Default:
   * Nullable:True
   */
  jobNumber?: string | null
  /**
   * Desc:组织_层级
   * Default:
   * Nullable:True
   * @format int32
   */
  stdOrganization_TreeLevel?: number | null
  /**
   * Desc:资格评审单位
   * Default:
   * Nullable:True
   */
  qualificationEvaluationUnit?: string | null
  /**
   * Desc:备注
   * Default:
   * Nullable:True
   */
  remarks?: string | null
  /**
   * Desc:共享人
   * Default:
   * Nullable:True
   */
  permissionBy?: string | null
  /**
   * Desc:是否最高级别
   * Default:
   * Nullable:True
   */
  isTopLevel?: boolean | null
  /**
   * Desc:组织
   * Default:
   * Nullable:True
   * @format int32
   */
  stdOrganization?: number | null
  /**
   * Desc:聘任起始时间
   * Default:
   * Nullable:True
   * @format date-time
   */
  appointStartDate?: string | null
  /**
   * Desc:评定日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  assessmentDate?: string | null
  /**
   * Desc:所属单位层级
   * Default:
   * Nullable:True
   * @format int32
   */
  onwerMou_TreeLevel?: number | null
  /**
   * Desc:熟悉专业专长
   * Default:
   * Nullable:True
   */
  professionalSpeciality?: string | null
  /**
   * Desc:审批状态
   * Default:
   * Nullable:True
   */
  approveStatus?: string | null
  /**
   * Desc:所属单位
   * Default:
   * Nullable:True
   */
  onwerMou?: string | null
  /**
   * Desc:最后工作日
   * Default:
   * Nullable:True
   * @format date-time
   */
  lastWorkDate?: string | null
  /**
   * Desc:入职日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  entryDate?: string | null
  /**
   * Desc:共享部门
   * Default:
   * Nullable:True
   */
  permissionOrganization?: string | null
  /**
   * Desc:获取资历途径
   * Default:
   * Nullable:True
   */
  accessWayID?: string | null
  /**
   * Desc:共享部门，包含下级
   * Default:
   * Nullable:True
   */
  permissionOrganization_SelfAndChildren?: string | null
  /**
   * Desc:职任专业技术级别
   * Default:
   * Nullable:True
   */
  appointTechnicalLevelID?: string | null
  /**
   * Desc:职任单位
   * Default:
   * Nullable:True
   */
  appointCompany?: string | null
  /**
   * Desc:共享部门，仅自己
   * Default:
   * Nullable:True
   */
  permissionOrganization_SelfOnly?: string | null
  /**
   * Desc:专业技术职务任职资格名称
   * Default:
   * Nullable:True
   */
  technicalPostQualificationsID?: string | null
  /**
   * Desc:所属单元路径
   * Default:
   * Nullable:True
   */
  onwerMou_TreePath?: string | null
  /**
   * Desc:组织路径
   * Default:
   * Nullable:True
   */
  stdOrganization_TreePath?: string | null
  /**
   * Desc:职任专业技术职务名称
   * Default:
   * Nullable:True
   */
  appointTechnicalPostQualificationsID?: string | null
  /**
   * Desc:专业技术级别
   * Default:
   * Nullable:True
   */
  technicalLevelID?: string | null
  /**
   * Desc:职任结束时间
   * Default:
   * Nullable:True
   * @format date-time
   */
  appointEndDate?: string | null
  /**
   * Desc:雇员Id
   * Default:
   * Nullable:False
   */
  userId?: string | null
  /** Id */
  id?: string | null
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string
}

/** 通用返回信息类 */
export interface SysEmployeeProfessionalTechnicalPostInfoDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 返回数据集合 */
  response?: SysEmployeeProfessionalTechnicalPostInfoDto[] | null
}

/** 通用返回信息类 */
export interface SysEmployeeProfessionalTechnicalPostInfoDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 雇员专业技术职务 */
  response?: SysEmployeeProfessionalTechnicalPostInfoDto
}

/** 通用分页信息类 */
export interface SysEmployeeProfessionalTechnicalPostInfoDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number
  /** 返回数据 */
  data?: SysEmployeeProfessionalTechnicalPostInfoDto[] | null
}

/** 通用返回信息类 */
export interface SysEmployeeProfessionalTechnicalPostInfoDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 通用分页信息类 */
  response?: SysEmployeeProfessionalTechnicalPostInfoDtoPageModel
}

/** 雇员项目经历 */
export interface SysEmployeeProjectExperiencesDto {
  /**
   * Desc:职位
   * Default:
   * Nullable:True
   */
  jobPosition?: string | null
  /**
   * Desc:最后工作日
   * Default:
   * Nullable:True
   * @format date-time
   */
  lastWorkDate?: string | null
  /**
   * Desc:项目人数
   * Default:
   * Nullable:True
   * @format int32
   */
  peopleNumber?: number | null
  /**
   * Desc:硬件环境
   * Default:
   * Nullable:True
   */
  hardware?: string | null
  /**
   * Desc:职位拼音排序
   * Default:
   * Nullable:True
   */
  jobPosition_Pinyin?: string | null
  /**
   * Desc:开发工具
   * Default:
   * Nullable:True
   */
  devTool?: string | null
  /**
   * Desc:软件环境
   * Default:
   * Nullable:True
   */
  software?: string | null
  /**
   * Desc:开始日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  startDate?: string | null
  /**
   * Desc:项目名称
   * Default:
   * Nullable:True
   */
  name?: string | null
  /**
   * Desc:描述
   * Default:
   * Nullable:True
   */
  description?: string | null
  /**
   * Desc:项目成果
   * Default:
   * Nullable:True
   */
  achievement?: string | null
  /**
   * Desc:结束日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  endDate?: string | null
  /**
   * Desc:项目职责
   * Default:
   * Nullable:True
   */
  responsibility?: string | null
  /**
   * Desc:入职日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  entryDate?: string | null
  /**
   * Desc:审批状态
   * Default:
   * Nullable:True
   */
  approveStatus?: string | null
  /**
   * Desc:工号
   * Default:
   * Nullable:True
   */
  jobNumber?: string | null
  /**
   * Desc:雇员Id
   * Default:
   * Nullable:False
   */
  userId?: string | null
  /** Id */
  id?: string | null
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string
}

/** 通用返回信息类 */
export interface SysEmployeeProjectExperiencesDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 返回数据集合 */
  response?: SysEmployeeProjectExperiencesDto[] | null
}

/** 通用返回信息类 */
export interface SysEmployeeProjectExperiencesDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 雇员项目经历 */
  response?: SysEmployeeProjectExperiencesDto
}

/** 通用分页信息类 */
export interface SysEmployeeProjectExperiencesDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number
  /** 返回数据 */
  data?: SysEmployeeProjectExperiencesDto[] | null
}

/** 通用返回信息类 */
export interface SysEmployeeProjectExperiencesDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 通用分页信息类 */
  response?: SysEmployeeProjectExperiencesDtoPageModel
}

/** 雇员专业技能 */
export interface SysEmployeeSkillDto {
  /**
   * Desc:审批状态
   * Default:
   * Nullable:True
   */
  approveStatus?: string | null
  /**
   * Desc:掌握程度
   * Default:
   * Nullable:True
   */
  level?: string | null
  /**
   * Desc:使用时间（月）
   * Default:
   * Nullable:True
   * @format int32
   */
  useLength?: number | null
  /**
   * Desc:入职日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  entryDate?: string | null
  /**
   * Desc:最后工作日
   * Default:
   * Nullable:True
   * @format date-time
   */
  lastWorkDate?: string | null
  /**
   * Desc:种类
   * Default:
   * Nullable:True
   */
  category?: string | null
  /**
   * Desc:工号
   * Default:
   * Nullable:True
   */
  jobNumber?: string | null
  /**
   * Desc:名称
   * Default:
   * Nullable:True
   */
  name?: string | null
  /**
   * Desc:雇员Id
   * Default:
   * Nullable:False
   */
  userId?: string | null
  /** Id */
  id?: string | null
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string
}

/** 通用返回信息类 */
export interface SysEmployeeSkillDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 返回数据集合 */
  response?: SysEmployeeSkillDto[] | null
}

/** 通用返回信息类 */
export interface SysEmployeeSkillDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 雇员专业技能 */
  response?: SysEmployeeSkillDto
}

/** 通用分页信息类 */
export interface SysEmployeeSkillDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number
  /** 返回数据 */
  data?: SysEmployeeSkillDto[] | null
}

/** 通用返回信息类 */
export interface SysEmployeeSkillDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 通用分页信息类 */
  response?: SysEmployeeSkillDtoPageModel
}

/** 培训经历 */
export interface SysEmployeeTrainingsDto {
  /**
   * Desc:培训描述
   * Default:
   * Nullable:True
   */
  discription?: string | null
  /**
   * Desc:是否获得勋章
   * Default:
   * Nullable:True
   */
  isHaveMedal?: boolean | null
  /**
   * Desc:开始日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  startDate?: string | null
  /**
   * Desc:适用场景
   * Default:
   * Nullable:True
   */
  applyScene?: string | null
  /**
   * Desc:培训机构
   * Default:
   * Nullable:True
   */
  trainingAgency?: string | null
  /**
   * Desc:培训成绩
   * Default:
   * Nullable:True
   */
  result?: string | null
  /**
   * Desc:培训表现
   * Default:
   * Nullable:True
   */
  evaluation?: string | null
  /**
   * Desc:获得学分
   * Default:
   * Nullable:True
   * @format double
   */
  getCredit?: number | null
  /**
   * Desc:结束日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  stopDate?: string | null
  /**
   * Desc:名称
   * Default:
   * Nullable:True
   */
  course?: string | null
  /**
   * Desc:最后工作日
   * Default:
   * Nullable:True
   * @format date-time
   */
  lastWorkDate?: string | null
  /**
   * Desc:入职日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  entryDate?: string | null
  /**
   * Desc:培训类别
   * Default:
   * Nullable:True
   */
  trainingCategory?: string | null
  /**
   * Desc:培训类别名称
   * Default:
   * Nullable:True
   */
  trainType?: string | null
  /**
   * Desc:课时总计
   * Default:
   * Nullable:True
   */
  timeToTile?: string | null
  /**
   * Desc:及格状态
   * Default:
   * Nullable:True
   */
  passStatus?: string | null
  /**
   * Desc:工号
   * Default:
   * Nullable:True
   */
  jobNumber?: string | null
  /**
   * Desc:审批状态
   * Default:
   * Nullable:True
   */
  approveStatus?: string | null
  /**
   * Desc:辅导导师
   * Default:
   * Nullable:True
   * @format int32
   */
  mentorUserId?: number | null
  /**
   * Desc:获得证书
   * Default:
   * Nullable:True
   */
  certificate?: string | null
  /**
   * Desc:完成状态
   * Default:
   * Nullable:True
   */
  completionStatus?: string | null
  /**
   * Desc:培训活动编号
   * Default:
   * Nullable:True
   */
  trainingActivityNum?: string | null
  /**
   * Desc:雇员Id
   * Default:
   * Nullable:False
   */
  userId?: string | null
  /** Id */
  id?: string | null
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string
}

/** 通用返回信息类 */
export interface SysEmployeeTrainingsDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 返回数据集合 */
  response?: SysEmployeeTrainingsDto[] | null
}

/** 通用返回信息类 */
export interface SysEmployeeTrainingsDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 培训经历 */
  response?: SysEmployeeTrainingsDto
}

/** 通用分页信息类 */
export interface SysEmployeeTrainingsDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number
  /** 返回数据 */
  data?: SysEmployeeTrainingsDto[] | null
}

/** 通用返回信息类 */
export interface SysEmployeeTrainingsDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 通用分页信息类 */
  response?: SysEmployeeTrainingsDtoPageModel
}

/** 雇员执业（职业）资格信息 */
export interface SysEmployeeVocationalQualificationInfoDto {
  /**
   * Desc:资格名称
   * Default:
   * Nullable:True
   */
  qualificationNameID?: string | null
  /**
   * Desc:资格级别
   * Default:
   * Nullable:True
   */
  qualificationLevelID?: string | null
  /**
   * Desc:组织路径
   * Default:
   * Nullable:True
   */
  stdOrganization_TreePath?: string | null
  /**
   * Desc:获取资格途径
   * Default:
   * Nullable:True
   */
  accessWayID?: string | null
  /**
   * Desc:证书附件
   * Default:
   * Nullable:True
   */
  accessory?: string | null
  /**
   * Desc:组织层级
   * Default:
   * Nullable:True
   * @format int32
   */
  stdOrganization_TreeLevel?: number | null
  /**
   * Desc:共享部门_包含下级
   * Default:
   * Nullable:True
   */
  permissionOrganization_SelfAndChildren?: string | null
  /**
   * Desc:工号
   * Default:
   * Nullable:True
   */
  jobNumber?: string | null
  /**
   * Desc:资格类型
   * Default:
   * Nullable:True
   */
  qualificationTypeID?: string | null
  /**
   * Desc:共享部门
   * Default:
   * Nullable:True
   */
  permissionOrganization?: string | null
  /**
   * Desc:取得日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  dateOfAcquisition?: string | null
  /**
   * Desc:共享人
   * Default:
   * Nullable:True
   */
  permissionBy?: string | null
  /**
   * Desc:证书编号
   * Default:
   * Nullable:True
   */
  certificateNumber?: string | null
  /**
   * Desc:所属单元_层级
   * Default:
   * Nullable:True
   * @format int32
   */
  onverMou_TreeLevel?: number | null
  /**
   * Desc:有效期至
   * Default:
   * Nullable:True
   * @format date-time
   */
  validUntil?: string | null
  /**
   * Desc:入职日期
   * Default:
   * Nullable:True
   * @format date-time
   */
  entryDate?: string | null
  /**
   * Desc:期限类型
   * Default:
   * Nullable:True
   */
  termTypeId?: string | null
  /**
   * Desc:是否最高等级
   * Default:
   * Nullable:True
   */
  supremeGrade?: boolean | null
  /**
   * Desc:申请类型
   * Default:
   * Nullable:True
   */
  applicationTypeId?: string | null
  /**
   * Desc:最后工作日
   * Default:
   * Nullable:True
   * @format date-time
   */
  lastWorkDate?: string | null
  /**
   * Desc:所属单位
   * Default:
   * Nullable:True
   */
  ownerMou?: string | null
  /**
   * Desc:所属单位路径
   * Default:
   * Nullable:True
   */
  ownerMou_TreePath?: string | null
  /**
   * Desc:专业
   * Default:
   * Nullable:True
   */
  professional?: string | null
  /**
   * Desc:组织
   * Default:
   * Nullable:True
   */
  stdOrganization?: string | null
  /**
   * Desc:证据来源
   * Default:
   * Nullable:True
   */
  sourceLicensiesID?: string | null
  /**
   * Desc:共享部门_仅自己
   * Default:
   * Nullable:True
   */
  permissionOrganization_SelfOnly?: string | null
  /**
   * Desc:审批状态
   * Default:
   * Nullable:True
   */
  approveStatus?: string | null
  /**
   * Desc:发布机构
   * Default:
   * Nullable:True
   */
  issueUnit?: string | null
  /**
   * Desc:证书编号
   * Default:
   * Nullable:True
   */
  certificateNumberUpper?: string | null
  /**
   * Desc:雇员Id
   * Default:
   * Nullable:False
   */
  userId?: string | null
  /** Id */
  id?: string | null
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string
}

/** 通用返回信息类 */
export interface SysEmployeeVocationalQualificationInfoDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 返回数据集合 */
  response?: SysEmployeeVocationalQualificationInfoDto[] | null
}

/** 通用返回信息类 */
export interface SysEmployeeVocationalQualificationInfoDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 雇员执业（职业）资格信息 */
  response?: SysEmployeeVocationalQualificationInfoDto
}

/** 通用分页信息类 */
export interface SysEmployeeVocationalQualificationInfoDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number
  /** 返回数据 */
  data?: SysEmployeeVocationalQualificationInfoDto[] | null
}

/** 通用返回信息类 */
export interface SysEmployeeVocationalQualificationInfoDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 通用分页信息类 */
  response?: SysEmployeeVocationalQualificationInfoDtoPageModel
}

/** Excel导出记录 */
export interface SysExcelExportCenterDto {
  /** 导出文件地址 */
  path?: string | null
  /**
   * 文件大小
   * @format int32
   */
  size?: number
  /**
   * 请求耗时 单位秒
   * @format int32
   */
  requestDuration?: number
  /** 模块 */
  module?: string | null
  /** 参数 */
  searchParameter?: string | null
  /** Id */
  id?: string | null
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string
}

/** 通用返回信息类 */
export interface SysExcelExportCenterDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 返回数据集合 */
  response?: SysExcelExportCenterDto[] | null
}

/** 通用返回信息类 */
export interface SysExcelExportCenterDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** Excel导出记录 */
  response?: SysExcelExportCenterDto
}

/** 通用分页信息类 */
export interface SysExcelExportCenterDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number
  /** 返回数据 */
  data?: SysExcelExportCenterDto[] | null
}

/** 通用返回信息类 */
export interface SysExcelExportCenterDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 通用分页信息类 */
  response?: SysExcelExportCenterDtoPageModel
}

/** 第三方通知记录表   //备注使用Remark */
export interface SysExternalNotificationHistoriesDto {
  /**
   * Desc:正文
   * Default:
   * Nullable:False
   */
  notificationContent?: string | null
  /**
   * Desc:通知类型
   * Default:
   * Nullable:False
   * @format int32
   */
  notificationType?: 0 | 1 | 2 | 3
  /**
   * Desc:关联Id
   * Default:
   * Nullable:True
   */
  associatedId?: string | null
  /**
   * Desc:参数
   * Default:
   * Nullable:True
   */
  parameterJson?: string | null
  /**
   * Desc:返回参数
   * Default:
   * Nullable:True
   */
  responseJson?: string | null
  /** Id */
  id?: string | null
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string
}

/** 通用返回信息类 */
export interface SysExternalNotificationHistoriesDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 返回数据集合 */
  response?: SysExternalNotificationHistoriesDto[] | null
}

/** 通用返回信息类 */
export interface SysExternalNotificationHistoriesDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 第三方通知记录表   //备注使用Remark */
  response?: SysExternalNotificationHistoriesDto
}

/** 通用分页信息类 */
export interface SysExternalNotificationHistoriesDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number
  /** 返回数据 */
  data?: SysExternalNotificationHistoriesDto[] | null
}

/** 通用返回信息类 */
export interface SysExternalNotificationHistoriesDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 通用分页信息类 */
  response?: SysExternalNotificationHistoriesDtoPageModel
}

/** 字典分组 */
export interface SysGeneralCodeGroupsDto {
  /**
   * Desc:名称
   * Default:
   * Nullable:False
   */
  name?: string | null
  /**
   * Desc:代码
   * Default:
   * Nullable:False
   */
  code?: string | null
  /**
   * Desc:父级代码
   * Default:
   * Nullable:True
   */
  parentCode?: string | null
  /**
   * Desc:模块
   * Default:
   * Nullable:False
   * @format int32
   */
  funcGroup?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7
  /** Id */
  id?: string | null
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string
}

/** 通用返回信息类 */
export interface SysGeneralCodeGroupsDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 返回数据集合 */
  response?: SysGeneralCodeGroupsDto[] | null
}

/** 通用返回信息类 */
export interface SysGeneralCodeGroupsDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 字典分组 */
  response?: SysGeneralCodeGroupsDto
}

/** 通用分页信息类 */
export interface SysGeneralCodeGroupsDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number
  /** 返回数据 */
  data?: SysGeneralCodeGroupsDto[] | null
}

/** 通用返回信息类 */
export interface SysGeneralCodeGroupsDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 通用分页信息类 */
  response?: SysGeneralCodeGroupsDtoPageModel
}

/** 接口API地址信息表 */
export interface SysModules {
  /** 获取或设置是否禁用，逻辑上的删除，非物理删除 */
  isDeleted?: boolean | null
  /** 名称 */
  name?: string | null
  /** 菜单链接地址 */
  linkUrl?: string | null
  /** 区域名称 */
  area?: string | null
  /** 控制器名称 */
  controller?: string | null
  /** Action名称 */
  action?: string | null
  /** 图标 */
  icon?: string | null
  /** 菜单编号 */
  code?: string | null
  /**
   * 排序
   * @format int32
   */
  orderSort?: number
  /** /描述 */
  description?: string | null
  /** 是否是右侧菜单 */
  isMenu?: boolean
  /** 是否激活 */
  enabled?: boolean
  /** 创建ID */
  createId?: string | null
  /** 创建者 */
  createBy?: string | null
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string
  /** 修改ID */
  modifyId?: string | null
  /** 修改者 */
  modifyBy?: string | null
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string | null
  /**
   * 平台
   * @format int32
   */
  scope?: 0 | 1
  /** 父ID */
  parentId?: string | null
  /**
   * ID
   * 泛型主键Tkey
   */
  id?: string | null
}

/** 路由菜单表 */
export interface SysPermission {
  /** 菜单执行Action名 */
  code?: string | null
  /** 菜单显示名（如用户页、编辑(按钮)、删除(按钮)） */
  name?: string | null
  /** 是否是按钮 */
  isButton?: boolean
  /** 是否是隐藏菜单 */
  isHide?: boolean | null
  /** 是否keepAlive */
  iskeepAlive?: boolean | null
  /** 按钮事件 */
  func?: string | null
  /**
   * 排序
   * @format int32
   */
  orderSort?: number
  /** 菜单图标 */
  icon?: string | null
  /** 菜单描述 */
  description?: string | null
  /** 激活状态 */
  enabled?: boolean
  /** 创建ID */
  createId?: string | null
  /** 创建者 */
  createBy?: string | null
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string | null
  /** 修改ID */
  modifyId?: string | null
  /** 修改者 */
  modifyBy?: string | null
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string | null
  /** 获取或设置是否禁用，逻辑上的删除，非物理删除 */
  isDeleted?: boolean | null
  pnameArr?: string[] | null
  pCodeArr?: string[] | null
  mName?: string | null
  hasChildren?: boolean
  children?: SysPermission[] | null
  /** 接口API地址信息表 */
  sysModule?: SysModules
  /**
   * 平台
   * @format int32
   */
  scope?: 0 | 1
  /** 显示名称多语言Key */
  displayName?: string | null
  /** 上一级菜单（0表示上一级无菜单） */
  pid?: string | null
  /** 接口api */
  mid?: string | null
  pidArr?: string[] | null
  /**
   * ID
   * 泛型主键Tkey
   */
  id?: string | null
}

/** 通用返回信息类 */
export interface SysPermissionListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 返回数据集合 */
  response?: SysPermission[] | null
}

/** 通用分页信息类 */
export interface SysPermissionPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number
  /** 返回数据 */
  data?: SysPermission[] | null
}

/** 通用返回信息类 */
export interface SysPermissionPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 通用分页信息类 */
  response?: SysPermissionPageModel
}

/** 角色分组 */
export interface SysRoleGroupsDto {
  /**
   * Desc:分组名称
   * Default:
   * Nullable:False
   */
  name?: string | null
  /**
   * Desc:排序
   * Default:0
   * Nullable:True
   * @format int32
   */
  sort?: number | null
  /**
   * 权限类型
   * @format int32
   */
  roleType?: 0 | 1 | 2 | 3 | 4
  /** 子级 */
  sysRolesList?: RoleDto[] | null
  /** Id */
  id?: string | null
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string
}

/** 通用返回信息类 */
export interface SysRoleGroupsDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 返回数据集合 */
  response?: SysRoleGroupsDto[] | null
}

/** 通用返回信息类 */
export interface SysRoleGroupsDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 角色分组 */
  response?: SysRoleGroupsDto
}

/** 通用分页信息类 */
export interface SysRoleGroupsDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number
  /** 返回数据 */
  data?: SysRoleGroupsDto[] | null
}

/** 通用返回信息类 */
export interface SysRoleGroupsDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 通用分页信息类 */
  response?: SysRoleGroupsDtoPageModel
}

export interface SystemSecuritySettingDto {
  sensitiveConfig?: SensitiveConfig
  loginValidateConfig?: LoginValidateConfig
  passwordConfig?: PasswordConfig
  /** 访问控制 */
  accessConfig?: AccessConfig
}

/** 通用返回信息类 */
export interface SystemSecuritySettingDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  response?: SystemSecuritySettingDto
}

export interface SystemThemeSettingDto {
  themeConfig?: ThemeConfig
  tableConfig?: TableConfig
  formConfig?: FormConfig
  filterConfig?: FilterConfig
}

/** 通用返回信息类 */
export interface SystemThemeSettingDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  response?: SystemThemeSettingDto
}

export interface TableConfig {
  /**
   * 默认展示数据条数
   * @format int32
   */
  dataSize?: number
  /** 操作栏显示位置 */
  operatePosition?: string | null
  /** 开启全局提醒 */
  isTrueRemind?: boolean
  /** 默认排序方式 */
  defaultSortWay?: string | null
  /** 操作跳转方式 */
  operatorHrefWay?: string | null
}

export interface TagListItem {
  type?: string | null
  /** 曾用名 */
  name?: string | null
}

export interface TaxCreditListItem {
  taxNo?: string | null
  year?: string | null
  level?: string | null
  /** 国家税务总局 */
  org?: string | null
}

export interface TertiaryListItem {
  tertiaryCode?: string | null
  /** 清洁机器人 */
  tertiaryDes?: string | null
}

export interface ThemeConfig {
  /** 主题样式 */
  theme?: string | null
  /** 主题颜色 */
  colorPrimary?: string | null
  /** 多开标签页 */
  isMorePages?: boolean
  /** 侧边栏背景色 */
  sideBgColor?: string | null
  /** 菜单栏背景色 */
  menuBgColor?: string | null
  /** 导航栏样式 */
  navigationBarStyle?: string | null
}

export interface UpdateUserEmailInput {
  /** 手机号 */
  phone?: string | null
  /** 验证码 */
  verifyCode?: string | null
  /** 邮箱 */
  email?: string | null
  /** 邮箱验证码 */
  emailVerifyCode?: string | null
}

export interface UpdateUserPassWordInput {
  /** 当前密码 */
  currentPassWord?: string | null
  /** 新密码 */
  newPassWord?: string | null
  /** 确认新密码 */
  confirmNewPassWord?: string | null
  isEnabled?: boolean
}

export interface UpdateUserPhoneInput {
  /** 旧手机号 */
  oldPhone?: string | null
  /** 旧手机号验证码 */
  verifyCode?: string | null
  /** 新手机号 */
  newPhone?: string | null
  /** 新手机号验证码 */
  newVerifyCode?: string | null
}

export interface UpdateUserPhoneToPasswordInput {
  /** 手机号 */
  phone?: string | null
  /** 验证码 */
  code?: string | null
  /** 密码 */
  password?: string | null
  /** 确认密码 */
  confirmPassword?: string | null
}

export interface UserInfoDto {
  /** 登录账号 */
  userName?: string | null
  /** 显示名称 */
  displayName?: string | null
  /** 工号 */
  jobNumber?: string | null
  /** 别名 */
  alterName?: string | null
  /** 手机号 */
  phoneNumber?: string | null
  /** 邮箱 */
  emailAddress?: string | null
  /** 姓名 */
  name?: string | null
  /** 邮箱是否已验证 */
  isEmailConfirmed?: boolean
  /** 账号锁定是否开启 */
  isLockoutEnabled?: boolean
  /** 是否手机号已验证 */
  isPhoneNumberConfirmed?: boolean
  /** 是否启用多因子验证 */
  isTwoFactorEnabled?: boolean
  /** 部门 */
  organizationUnitName?: string | null
  /** 分所 */
  officeName?: string | null
  /** 权限组 ， */
  roleNames?: string | null
  roleIds?: string[] | null
  /**
   * 用户组
   * 岗位
   */
  positionNames?: string | null
  /** 岗位 */
  positionIds?: string[] | null
  /** 用户组 */
  userGroupNames?: string | null
  userGroupIds?: string[] | null
  /**
   * 岗位
   * 用户组
   */
  positionIdNames?: string | null
  /** 是否启用 */
  isActive?: boolean
  /**
   * 入职时间
   * @format date-time
   */
  entryTime?: string
  /**
   * 最后登录时间
   * @format date-time
   */
  lastLoginTime?: string | null
  /** 组织机构Id */
  organizationUnitId?: string | null
  /**
   * 用户类型
   * @format int32
   */
  userType?: 0 | 1
  /** Id */
  id?: string | null
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string
}

/** 通用返回信息类 */
export interface UserInfoDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  response?: UserInfoDto
}

/** 通用分页信息类 */
export interface UserInfoDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number
  /** 返回数据 */
  data?: UserInfoDto[] | null
}

/** 通用返回信息类 */
export interface UserInfoDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 通用分页信息类 */
  response?: UserInfoDtoPageModel
}

export interface UserLoginAttemptsDto {
  /** 用户名 */
  userName?: string | null
  /** 手机号 */
  phoneNumber?: string | null
  /**
   * 操作类型
   * @format int32
   */
  loginOperation?: 0 | 1 | 2 | 3 | 4
  /** 浏览器代理信息 */
  browserAgent?: string | null
  /** 客户端Ip地址 */
  clientIpAddress?: string | null
  /** 客户端Ip所在地区 */
  clientIpRegion?: string | null
  /** 客户端Ip所在地区坐标 */
  clientIpLocation?: string | null
  /** 客户端名称 */
  clientName?: string | null
  /** 客户端设备信息 */
  clientDeviceInfo?: string | null
  /** 是否在异地登录 */
  isLoginFromAnotherLocation?: boolean
  /** 是否常用设备登录 */
  isLoginFromInCommonUseClient?: boolean
  /** 是否App登录 */
  isAppLogin?: boolean
  /** Id */
  id?: string | null
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string
}

/** 通用分页信息类 */
export interface UserLoginAttemptsDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number
  /** 返回数据 */
  data?: UserLoginAttemptsDto[] | null
}

/** 通用返回信息类 */
export interface UserLoginAttemptsDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 通用分页信息类 */
  response?: UserLoginAttemptsDtoPageModel
}

export interface UserOrganizationUnitDto {
  /** @format int32 */
  thresholdFlag?: 0 | 1 | 2 | 3 | 4 | 5
  userId?: string | null
  roleId?: string | null
  isActive?: boolean
  roleName?: string | null
  userName?: string | null
  /** Id */
  id?: string | null
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string
}

/** 通用返回信息类 */
export interface UserOrganizationUnitDtoIListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number
  /** 操作是否成功s */
  success?: boolean
  /** 返回信息 */
  msg?: string | null
  /** 开发者信息 */
  msgDev?: string | null
  /** 返回数据集合 */
  response?: UserOrganizationUnitDto[] | null
}

export interface UserWorkTransferInput {
  /** 交接人用户Id */
  userId?: string[] | null
  /** 接收人用户Id */
  transferUserId?: string | null
}

export interface WebSiteItem {
  name?: string | null
  url?: string | null
}

import type { AxiosInstance, AxiosRequestConfig, HeadersDefaults, ResponseType } from 'axios'
import axios from 'axios'

export type QueryParamsType = Record<string | number, any>

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean
  /** request path */
  path: string
  /** content type of request body */
  type?: ContentType
  /** query params */
  query?: QueryParamsType
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType
  /** request body */
  body?: unknown
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (securityData: SecurityDataType | null) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void
  secure?: boolean
  format?: ResponseType
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance
  private securityData: SecurityDataType | null = null
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
  private secure?: boolean
  private format?: ResponseType

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || '' })
    this.secure = secure
    this.format = format
    this.securityWorker = securityWorker
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data
  }

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method)

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    }
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem)
    } else {
      return `${formItem}`
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key]
      const propertyContent: any[] = property instanceof Array ? property : [property]

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem))
      }

      return formData
    }, new FormData())
  }

  public request = async <T = any, _E = any>({ secure, path, type, query, format, body, ...params }: FullRequestParams): Promise<T> => {
    const secureParams = ((typeof secure === 'boolean' ? secure : this.secure) && this.securityWorker && (await this.securityWorker(this.securityData))) || {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const responseFormat = format || this.format || undefined

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>)
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body)
    }

    return this.instance
      .request({
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
        },
        params: query,
        responseType: responseFormat,
        data: body,
        url: path,
      })
      .then((response) => response.data)
  }
}

/**
 * @title Web接口
 * @version 1.0.0
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * No description
     *
     * @tags CaseAcceptAgency
     * @name WebBusinessCaseCaseacceptagencyGetpagePageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/business/case/caseacceptagency/getpage/page
     * @secure
     */
    webBusinessCaseCaseacceptagencyGetpagePageCreate: (
      query?: {
        /**
         * Desc:雪花Id
         * Default:
         * Nullable:False
         */
        id?: string
        /**
         * Desc:案件或利冲编号
         * Default:0
         * Nullable:True
         */
        caseId?: string
        /**
         * Desc:机构名称
         * Default:
         * Nullable:True
         */
        name?: string
        /**
         * Desc:机构类别（法院/检察院/监狱等）
         * Default:
         * Nullable:True
         */
        agencyType?: string
        /**
         * Desc:开始时间
         * Default:
         * Nullable:False
         * @format date-time
         */
        startTime?: string
        /**
         * Desc:结束时间
         * Default:
         * Nullable:False
         * @format date-time
         */
        endTime?: string
        /**
         * Desc:事由分类
         * Default:
         * Nullable:True
         */
        category?: string
        /**
         * Desc:案件阶段
         * Default:
         * Nullable:True
         */
        stage?: string
        /**
         * Desc:联系人
         * Default:
         * Nullable:True
         */
        linker?: string
        /**
         * Desc:联系电话
         * Default:
         * Nullable:True
         */
        phoneNumber?: string
        /**
         * Desc:关联法院编号
         * Default:0
         * Nullable:True
         */
        courtId?: string
        /**
         * Desc:租户ID
         * Default:0
         * Nullable:False
         */
        tenantId?: string
        /**
         * Desc:部门ID  DepartmentId
         * Default:0
         * Nullable:False
         */
        organizationUnitId?: string
        /**
         * Desc:分所Id
         * Default:0
         * Nullable:False
         */
        officeId?: string
        /**
         * Desc:备注
         * Default:
         * Nullable:True
         */
        remark?: string
        /**
         * Desc:状态 	            中立字段，某些表可使用某些表不使用
         * Default:1
         * Nullable:False
         */
        isEnabled?: boolean
        /**
         * Desc:中立字段，某些表可使用某些表不使用   	            逻辑上的删除，非物理删除  	            例如：单据删除并非直接删除
         * Default:0
         * Nullable:False
         */
        isDeleted?: boolean
        /**
         * Desc:中立字段 	            是否内置数据
         * Default:
         * Nullable:False
         */
        isInternal?: boolean
        /**
         * Desc:创建ID
         * Default:
         * Nullable:True
         */
        createId?: string
        /**
         * Desc:创建者
         * Default:
         * Nullable:True
         */
        createBy?: string
        /**
         * Desc:创建时间
         * Default:
         * Nullable:False
         * @format date-time
         */
        createTime?: string
        /**
         * Desc:修改ID
         * Default:
         * Nullable:True
         */
        modifyId?: string
        /**
         * Desc:更新者
         * Default:
         * Nullable:True
         */
        modifyBy?: string
        /**
         * Desc:修改日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        modifyTime?: string
        /**
         * Desc:数据版本
         * Default:0
         * Nullable:False
         */
        version?: string
        /**
         * Desc:删除ID
         * Default:
         * Nullable:True
         */
        deleteId?: string
        /**
         * Desc:删除者
         * Default:
         * Nullable:True
         */
        deleteBy?: string
        /**
         * Desc:删除日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        deleteTime?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<CaseAcceptAgencyDtoPageModelMessageModel, void>({
        path: `/api/web/business/case/caseacceptagency/getpage/page`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseAcceptAgency
     * @name WebBusinessCaseCaseacceptagencyListListList
     * @summary 列表获取所有 (Auth policies: Web)
     * @request GET:/api/web/business/case/caseacceptagency/list/list
     * @secure
     */
    webBusinessCaseCaseacceptagencyListListList: (
      query?: {
        /**
         * Desc:雪花Id
         * Default:
         * Nullable:False
         */
        id?: string
        /**
         * Desc:案件或利冲编号
         * Default:0
         * Nullable:True
         */
        caseId?: string
        /**
         * Desc:机构名称
         * Default:
         * Nullable:True
         */
        name?: string
        /**
         * Desc:机构类别（法院/检察院/监狱等）
         * Default:
         * Nullable:True
         */
        agencyType?: string
        /**
         * Desc:开始时间
         * Default:
         * Nullable:False
         * @format date-time
         */
        startTime?: string
        /**
         * Desc:结束时间
         * Default:
         * Nullable:False
         * @format date-time
         */
        endTime?: string
        /**
         * Desc:事由分类
         * Default:
         * Nullable:True
         */
        category?: string
        /**
         * Desc:案件阶段
         * Default:
         * Nullable:True
         */
        stage?: string
        /**
         * Desc:联系人
         * Default:
         * Nullable:True
         */
        linker?: string
        /**
         * Desc:联系电话
         * Default:
         * Nullable:True
         */
        phoneNumber?: string
        /**
         * Desc:关联法院编号
         * Default:0
         * Nullable:True
         */
        courtId?: string
        /**
         * Desc:租户ID
         * Default:0
         * Nullable:False
         */
        tenantId?: string
        /**
         * Desc:部门ID  DepartmentId
         * Default:0
         * Nullable:False
         */
        organizationUnitId?: string
        /**
         * Desc:分所Id
         * Default:0
         * Nullable:False
         */
        officeId?: string
        /**
         * Desc:备注
         * Default:
         * Nullable:True
         */
        remark?: string
        /**
         * Desc:状态 	            中立字段，某些表可使用某些表不使用
         * Default:1
         * Nullable:False
         */
        isEnabled?: boolean
        /**
         * Desc:中立字段，某些表可使用某些表不使用   	            逻辑上的删除，非物理删除  	            例如：单据删除并非直接删除
         * Default:0
         * Nullable:False
         */
        isDeleted?: boolean
        /**
         * Desc:中立字段 	            是否内置数据
         * Default:
         * Nullable:False
         */
        isInternal?: boolean
        /**
         * Desc:创建ID
         * Default:
         * Nullable:True
         */
        createId?: string
        /**
         * Desc:创建者
         * Default:
         * Nullable:True
         */
        createBy?: string
        /**
         * Desc:创建时间
         * Default:
         * Nullable:False
         * @format date-time
         */
        createTime?: string
        /**
         * Desc:修改ID
         * Default:
         * Nullable:True
         */
        modifyId?: string
        /**
         * Desc:更新者
         * Default:
         * Nullable:True
         */
        modifyBy?: string
        /**
         * Desc:修改日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        modifyTime?: string
        /**
         * Desc:数据版本
         * Default:0
         * Nullable:False
         */
        version?: string
        /**
         * Desc:删除ID
         * Default:
         * Nullable:True
         */
        deleteId?: string
        /**
         * Desc:删除者
         * Default:
         * Nullable:True
         */
        deleteBy?: string
        /**
         * Desc:删除日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        deleteTime?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<CaseAcceptAgencyDtoListMessageModel, void>({
        path: `/api/web/business/case/caseacceptagency/list/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseAcceptAgency
     * @name WebBusinessCaseCaseacceptagencyGetDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/business/case/caseacceptagency/get/{id}
     * @secure
     */
    webBusinessCaseCaseacceptagencyGetDetail: (id: string, params: RequestParams = {}) =>
      this.request<CaseAcceptAgencyDtoMessageModel, void>({
        path: `/api/web/business/case/caseacceptagency/get/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseAcceptAgency
     * @name WebBusinessCaseCaseacceptagencyPostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/business/case/caseacceptagency/post
     * @secure
     */
    webBusinessCaseCaseacceptagencyPostCreate: (data: CreateCaseAcceptAgencyInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/case/caseacceptagency/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseAcceptAgency
     * @name WebBusinessCaseCaseacceptagencyPutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/business/case/caseacceptagency/put/{id}
     * @secure
     */
    webBusinessCaseCaseacceptagencyPutUpdate: (id: string, data: CreateCaseAcceptAgencyInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/case/caseacceptagency/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseAcceptAgency
     * @name WebBusinessCaseCaseacceptagencyDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/business/case/caseacceptagency/delete/{id}
     * @secure
     */
    webBusinessCaseCaseacceptagencyDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/case/caseacceptagency/delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseClosed
     * @name WebBusinessCaseclosedCaseclosedGetpagePageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/business/caseclosed/caseclosed/getpage/page
     * @secure
     */
    webBusinessCaseclosedCaseclosedGetpagePageCreate: (
      query?: {
        /**
         * Desc:雪花Id
         * Default:
         * Nullable:False
         */
        id?: string
        /**
         * Desc:案件编号
         * Default:0
         * Nullable:True
         */
        caseId?: string
        /**
         * Desc:裁判类型（判决/裁定/调解/其他）
         * Default:
         * Nullable:True
         */
        refereeType?: string
        /**
         * Desc:受理机构（法院名称）
         * Default:
         * Nullable:True
         */
        court?: string
        /**
         * Desc:裁判文书日期
         * Default:
         * Nullable:False
         * @format date-time
         */
        judgmentDate?: string
        /**
         * Desc:裁判文书号
         * Default:
         * Nullable:True
         */
        judgmentNo?: string
        /**
         * Desc:送达日期
         * Default:
         * Nullable:False
         * @format date-time
         */
        deliveryDate?: string
        /**
         * Desc:是否机密案卷
         * Default:
         * Nullable:False
         */
        confidentialVolume?: boolean
        /**
         * Desc:结案时收费额是否与委托协议一致
         * Default:
         * Nullable:False
         */
        isConsistentCharge?: boolean
        /**
         * Desc:起诉日期
         * Default:
         * Nullable:False
         * @format date-time
         */
        sueDate?: string
        /**
         * Desc:阅卷日期
         * Default:
         * Nullable:False
         * @format date-time
         */
        examDate?: string
        /**
         * Desc:初次会见/谈话日期
         * Default:
         * Nullable:False
         * @format date-time
         */
        meetDate?: string
        /**
         * Desc:首次开庭日期
         * Default:
         * Nullable:False
         * @format date-time
         */
        courtDate?: string
        /**
         * Desc:简要案情和工作概况
         * Default:
         * Nullable:True
         */
        result?: string
        /**
         * Desc:处理结果
         * Default:
         * Nullable:True
         */
        pocessResult?: string
        /**
         * Desc:律师办案体会
         * Default:
         * Nullable:True
         */
        experience?: string
        /**
         * Desc:代理事项
         * Default:
         * Nullable:True
         */
        discussion?: string
        /**
         * Desc:结案必要文件夹
         * Default:
         * Nullable:True
         */
        folders?: string
        /**
         * Desc:归档人编号
         * Default:0
         * Nullable:True
         */
        archiveUserId?: string
        /**
         * Desc:归档日期
         * Default:
         * Nullable:False
         * @format date-time
         */
        archiveDate?: string
        /**
         * Desc:归档编号
         * Default:
         * Nullable:True
         */
        archiveId?: string
        /**
         * Desc:归档状态
         * Default:
         * Nullable:True
         */
        archiveStatus?: string
        /**
         * Desc:服务结果（中止/终止/存续）
         * Default:
         * Nullable:True
         */
        serviceResult?: string
        /**
         * Desc:电子档案编号
         * Default:
         * Nullable:True
         */
        electronicFileNo?: string
        /**
         * Desc:是否扫描件
         * Default:
         * Nullable:False
         */
        isScanFile?: boolean
        /**
         * Desc:是否逾期结案
         * Default:
         * Nullable:False
         */
        isOverdueFiling?: boolean
        /**
         * Desc:逾期结案原因
         * Default:
         * Nullable:True
         */
        overdueFilingReason?: string
        /**
         * Desc:逾期天数
         * Default:
         * Nullable:False
         * @format double
         */
        overdueDays?: number
        /**
         * Desc:归档地
         * Default:
         * Nullable:True
         */
        archivePlace?: string
        /**
         * Desc:租户ID
         * Default:0
         * Nullable:False
         */
        tenantId?: string
        /**
         * Desc:部门ID  DepartmentId
         * Default:0
         * Nullable:False
         */
        organizationUnitId?: string
        /**
         * Desc:分所Id
         * Default:0
         * Nullable:False
         */
        officeId?: string
        /**
         * Desc:备注
         * Default:
         * Nullable:True
         */
        remark?: string
        /**
         * Desc:状态 	            中立字段，某些表可使用某些表不使用
         * Default:1
         * Nullable:False
         */
        isEnabled?: boolean
        /**
         * Desc:中立字段，某些表可使用某些表不使用   	            逻辑上的删除，非物理删除  	            例如：单据删除并非直接删除
         * Default:0
         * Nullable:False
         */
        isDeleted?: boolean
        /**
         * Desc:中立字段 	            是否内置数据
         * Default:
         * Nullable:False
         */
        isInternal?: boolean
        /**
         * Desc:创建ID
         * Default:
         * Nullable:True
         */
        createId?: string
        /**
         * Desc:创建者
         * Default:
         * Nullable:True
         */
        createBy?: string
        /**
         * Desc:创建时间
         * Default:
         * Nullable:False
         * @format date-time
         */
        createTime?: string
        /**
         * Desc:修改ID
         * Default:
         * Nullable:True
         */
        modifyId?: string
        /**
         * Desc:更新者
         * Default:
         * Nullable:True
         */
        modifyBy?: string
        /**
         * Desc:修改日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        modifyTime?: string
        /**
         * Desc:数据版本
         * Default:0
         * Nullable:False
         */
        version?: string
        /**
         * Desc:删除ID
         * Default:
         * Nullable:True
         */
        deleteId?: string
        /**
         * Desc:删除者
         * Default:
         * Nullable:True
         */
        deleteBy?: string
        /**
         * Desc:删除日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        deleteTime?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<CaseClosedDtoPageModelMessageModel, void>({
        path: `/api/web/business/caseclosed/caseclosed/getpage/page`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseClosed
     * @name WebBusinessCaseclosedCaseclosedListListList
     * @summary 列表获取所有 (Auth policies: Web)
     * @request GET:/api/web/business/caseclosed/caseclosed/list/list
     * @secure
     */
    webBusinessCaseclosedCaseclosedListListList: (
      query?: {
        /**
         * Desc:雪花Id
         * Default:
         * Nullable:False
         */
        id?: string
        /**
         * Desc:案件编号
         * Default:0
         * Nullable:True
         */
        caseId?: string
        /**
         * Desc:裁判类型（判决/裁定/调解/其他）
         * Default:
         * Nullable:True
         */
        refereeType?: string
        /**
         * Desc:受理机构（法院名称）
         * Default:
         * Nullable:True
         */
        court?: string
        /**
         * Desc:裁判文书日期
         * Default:
         * Nullable:False
         * @format date-time
         */
        judgmentDate?: string
        /**
         * Desc:裁判文书号
         * Default:
         * Nullable:True
         */
        judgmentNo?: string
        /**
         * Desc:送达日期
         * Default:
         * Nullable:False
         * @format date-time
         */
        deliveryDate?: string
        /**
         * Desc:是否机密案卷
         * Default:
         * Nullable:False
         */
        confidentialVolume?: boolean
        /**
         * Desc:结案时收费额是否与委托协议一致
         * Default:
         * Nullable:False
         */
        isConsistentCharge?: boolean
        /**
         * Desc:起诉日期
         * Default:
         * Nullable:False
         * @format date-time
         */
        sueDate?: string
        /**
         * Desc:阅卷日期
         * Default:
         * Nullable:False
         * @format date-time
         */
        examDate?: string
        /**
         * Desc:初次会见/谈话日期
         * Default:
         * Nullable:False
         * @format date-time
         */
        meetDate?: string
        /**
         * Desc:首次开庭日期
         * Default:
         * Nullable:False
         * @format date-time
         */
        courtDate?: string
        /**
         * Desc:简要案情和工作概况
         * Default:
         * Nullable:True
         */
        result?: string
        /**
         * Desc:处理结果
         * Default:
         * Nullable:True
         */
        pocessResult?: string
        /**
         * Desc:律师办案体会
         * Default:
         * Nullable:True
         */
        experience?: string
        /**
         * Desc:代理事项
         * Default:
         * Nullable:True
         */
        discussion?: string
        /**
         * Desc:结案必要文件夹
         * Default:
         * Nullable:True
         */
        folders?: string
        /**
         * Desc:归档人编号
         * Default:0
         * Nullable:True
         */
        archiveUserId?: string
        /**
         * Desc:归档日期
         * Default:
         * Nullable:False
         * @format date-time
         */
        archiveDate?: string
        /**
         * Desc:归档编号
         * Default:
         * Nullable:True
         */
        archiveId?: string
        /**
         * Desc:归档状态
         * Default:
         * Nullable:True
         */
        archiveStatus?: string
        /**
         * Desc:服务结果（中止/终止/存续）
         * Default:
         * Nullable:True
         */
        serviceResult?: string
        /**
         * Desc:电子档案编号
         * Default:
         * Nullable:True
         */
        electronicFileNo?: string
        /**
         * Desc:是否扫描件
         * Default:
         * Nullable:False
         */
        isScanFile?: boolean
        /**
         * Desc:是否逾期结案
         * Default:
         * Nullable:False
         */
        isOverdueFiling?: boolean
        /**
         * Desc:逾期结案原因
         * Default:
         * Nullable:True
         */
        overdueFilingReason?: string
        /**
         * Desc:逾期天数
         * Default:
         * Nullable:False
         * @format double
         */
        overdueDays?: number
        /**
         * Desc:归档地
         * Default:
         * Nullable:True
         */
        archivePlace?: string
        /**
         * Desc:租户ID
         * Default:0
         * Nullable:False
         */
        tenantId?: string
        /**
         * Desc:部门ID  DepartmentId
         * Default:0
         * Nullable:False
         */
        organizationUnitId?: string
        /**
         * Desc:分所Id
         * Default:0
         * Nullable:False
         */
        officeId?: string
        /**
         * Desc:备注
         * Default:
         * Nullable:True
         */
        remark?: string
        /**
         * Desc:状态 	            中立字段，某些表可使用某些表不使用
         * Default:1
         * Nullable:False
         */
        isEnabled?: boolean
        /**
         * Desc:中立字段，某些表可使用某些表不使用   	            逻辑上的删除，非物理删除  	            例如：单据删除并非直接删除
         * Default:0
         * Nullable:False
         */
        isDeleted?: boolean
        /**
         * Desc:中立字段 	            是否内置数据
         * Default:
         * Nullable:False
         */
        isInternal?: boolean
        /**
         * Desc:创建ID
         * Default:
         * Nullable:True
         */
        createId?: string
        /**
         * Desc:创建者
         * Default:
         * Nullable:True
         */
        createBy?: string
        /**
         * Desc:创建时间
         * Default:
         * Nullable:False
         * @format date-time
         */
        createTime?: string
        /**
         * Desc:修改ID
         * Default:
         * Nullable:True
         */
        modifyId?: string
        /**
         * Desc:更新者
         * Default:
         * Nullable:True
         */
        modifyBy?: string
        /**
         * Desc:修改日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        modifyTime?: string
        /**
         * Desc:数据版本
         * Default:0
         * Nullable:False
         */
        version?: string
        /**
         * Desc:删除ID
         * Default:
         * Nullable:True
         */
        deleteId?: string
        /**
         * Desc:删除者
         * Default:
         * Nullable:True
         */
        deleteBy?: string
        /**
         * Desc:删除日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        deleteTime?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<CaseClosedDtoListMessageModel, void>({
        path: `/api/web/business/caseclosed/caseclosed/list/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseClosed
     * @name WebBusinessCaseclosedCaseclosedGetDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/business/caseclosed/caseclosed/get/{id}
     * @secure
     */
    webBusinessCaseclosedCaseclosedGetDetail: (id: string, params: RequestParams = {}) =>
      this.request<CaseClosedDtoMessageModel, void>({
        path: `/api/web/business/caseclosed/caseclosed/get/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseClosed
     * @name WebBusinessCaseclosedCaseclosedPostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/business/caseclosed/caseclosed/post
     * @secure
     */
    webBusinessCaseclosedCaseclosedPostCreate: (data: CreateCaseClosedInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/caseclosed/caseclosed/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseClosed
     * @name WebBusinessCaseclosedCaseclosedPutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/business/caseclosed/caseclosed/put/{id}
     * @secure
     */
    webBusinessCaseclosedCaseclosedPutUpdate: (id: string, data: CreateCaseClosedInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/caseclosed/caseclosed/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseClosed
     * @name WebBusinessCaseclosedCaseclosedDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/business/caseclosed/caseclosed/delete/{id}
     * @secure
     */
    webBusinessCaseclosedCaseclosedDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/caseclosed/caseclosed/delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseCollection
     * @name WebBusinessCaseCasecollectionGetpagePageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/business/case/casecollection/getpage/page
     * @secure
     */
    webBusinessCaseCasecollectionGetpagePageCreate: (
      query?: {
        /**
         * Desc:雪花Id
         * Default:
         * Nullable:False
         */
        id?: string
        /**
         * Desc:案件编号
         * Default:0
         * Nullable:True
         */
        caseId?: string
        /**
         * Desc:收费方式
         * Default:
         * Nullable:True
         */
        chargingMethod?: string
        /**
         * Desc:收费金额
         * Default:
         * Nullable:False
         * @format double
         */
        amount?: number
        /**
         * Desc:基础风险金额
         * Default:
         * Nullable:False
         * @format double
         */
        riskBasicAmount?: number
        /**
         * Desc:说明描述/风险达成条件
         * Default:
         * Nullable:True
         */
        narrative?: string
        /**
         * Desc:是否可收款
         * Default:
         * Nullable:True
         */
        isAcceptable?: string
        /**
         * Desc:是否使用账单
         * Default:
         * Nullable:True
         */
        isUseBilling?: string
        /**
         * Desc:收费日期
         * Default:
         * Nullable:False
         * @format date-time
         */
        chargeDate?: string
        /**
         * Desc:是否已到账
         * Default:
         * Nullable:True
         */
        isArrived?: string
        /**
         * Desc:到账日期
         * Default:
         * Nullable:False
         * @format date-time
         */
        arrivedDate?: string
        /**
         * Desc:到账金额
         * Default:
         * Nullable:False
         * @format double
         */
        arrivedAmount?: number
        /**
         * Desc:币种
         * Default:
         * Nullable:True
         */
        currency?: string
        /**
         * Desc:汇率
         * Default:
         * Nullable:False
         * @format double
         */
        exchangeRate?: number
        /**
         * Desc:是否账单
         * Default:
         * Nullable:True
         */
        isBilling?: string
        /**
         * Desc:关联账单编号
         * Default:0
         * Nullable:True
         */
        billingID?: string
        /**
         * Desc:付款比例
         * Default:
         * Nullable:False
         * @format double
         */
        chargeRatio?: number
        /**
         * Desc:开始时间
         * Default:
         * Nullable:False
         * @format date-time
         */
        startDate?: string
        /**
         * Desc:结束时间
         * Default:
         * Nullable:False
         * @format date-time
         */
        endDate?: string
        /**
         * Desc:是否提醒
         * Default:
         * Nullable:True
         */
        isRemind?: string
        /**
         * Desc:提醒时间
         * Default:
         * Nullable:False
         * @format date-time
         */
        remindTime?: string
        /**
         * Desc:风险收费比例
         * Default:
         * Nullable:False
         * @format double
         */
        riskRatio?: number
        /**
         * Desc:关联案件编号
         * Default:0
         * Nullable:True
         */
        relationCaseID?: string
        /**
         * Desc:外币收费金额
         * Default:
         * Nullable:False
         * @format double
         */
        foreignCurrencyAmount?: number
        /**
         * Desc:租户ID
         * Default:0
         * Nullable:False
         */
        tenantId?: string
        /**
         * Desc:部门ID  DepartmentId
         * Default:0
         * Nullable:False
         */
        organizationUnitId?: string
        /**
         * Desc:分所Id
         * Default:0
         * Nullable:False
         */
        officeId?: string
        /**
         * Desc:备注
         * Default:
         * Nullable:True
         */
        remark?: string
        /**
         * Desc:状态 	            中立字段，某些表可使用某些表不使用
         * Default:1
         * Nullable:False
         */
        isEnabled?: boolean
        /**
         * Desc:中立字段，某些表可使用某些表不使用   	            逻辑上的删除，非物理删除  	            例如：单据删除并非直接删除
         * Default:0
         * Nullable:False
         */
        isDeleted?: boolean
        /**
         * Desc:中立字段 	            是否内置数据
         * Default:
         * Nullable:False
         */
        isInternal?: boolean
        /**
         * Desc:创建ID
         * Default:
         * Nullable:True
         */
        createId?: string
        /**
         * Desc:创建者
         * Default:
         * Nullable:True
         */
        createBy?: string
        /**
         * Desc:创建时间
         * Default:
         * Nullable:False
         * @format date-time
         */
        createTime?: string
        /**
         * Desc:修改ID
         * Default:
         * Nullable:True
         */
        modifyId?: string
        /**
         * Desc:更新者
         * Default:
         * Nullable:True
         */
        modifyBy?: string
        /**
         * Desc:修改日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        modifyTime?: string
        /**
         * Desc:数据版本
         * Default:0
         * Nullable:False
         */
        version?: string
        /**
         * Desc:删除ID
         * Default:
         * Nullable:True
         */
        deleteId?: string
        /**
         * Desc:删除者
         * Default:
         * Nullable:True
         */
        deleteBy?: string
        /**
         * Desc:删除日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        deleteTime?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<CaseCollectionDtoPageModelMessageModel, void>({
        path: `/api/web/business/case/casecollection/getpage/page`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseCollection
     * @name WebBusinessCaseCasecollectionListListList
     * @summary 列表获取所有 (Auth policies: Web)
     * @request GET:/api/web/business/case/casecollection/list/list
     * @secure
     */
    webBusinessCaseCasecollectionListListList: (
      query?: {
        /**
         * Desc:雪花Id
         * Default:
         * Nullable:False
         */
        id?: string
        /**
         * Desc:案件编号
         * Default:0
         * Nullable:True
         */
        caseId?: string
        /**
         * Desc:收费方式
         * Default:
         * Nullable:True
         */
        chargingMethod?: string
        /**
         * Desc:收费金额
         * Default:
         * Nullable:False
         * @format double
         */
        amount?: number
        /**
         * Desc:基础风险金额
         * Default:
         * Nullable:False
         * @format double
         */
        riskBasicAmount?: number
        /**
         * Desc:说明描述/风险达成条件
         * Default:
         * Nullable:True
         */
        narrative?: string
        /**
         * Desc:是否可收款
         * Default:
         * Nullable:True
         */
        isAcceptable?: string
        /**
         * Desc:是否使用账单
         * Default:
         * Nullable:True
         */
        isUseBilling?: string
        /**
         * Desc:收费日期
         * Default:
         * Nullable:False
         * @format date-time
         */
        chargeDate?: string
        /**
         * Desc:是否已到账
         * Default:
         * Nullable:True
         */
        isArrived?: string
        /**
         * Desc:到账日期
         * Default:
         * Nullable:False
         * @format date-time
         */
        arrivedDate?: string
        /**
         * Desc:到账金额
         * Default:
         * Nullable:False
         * @format double
         */
        arrivedAmount?: number
        /**
         * Desc:币种
         * Default:
         * Nullable:True
         */
        currency?: string
        /**
         * Desc:汇率
         * Default:
         * Nullable:False
         * @format double
         */
        exchangeRate?: number
        /**
         * Desc:是否账单
         * Default:
         * Nullable:True
         */
        isBilling?: string
        /**
         * Desc:关联账单编号
         * Default:0
         * Nullable:True
         */
        billingID?: string
        /**
         * Desc:付款比例
         * Default:
         * Nullable:False
         * @format double
         */
        chargeRatio?: number
        /**
         * Desc:开始时间
         * Default:
         * Nullable:False
         * @format date-time
         */
        startDate?: string
        /**
         * Desc:结束时间
         * Default:
         * Nullable:False
         * @format date-time
         */
        endDate?: string
        /**
         * Desc:是否提醒
         * Default:
         * Nullable:True
         */
        isRemind?: string
        /**
         * Desc:提醒时间
         * Default:
         * Nullable:False
         * @format date-time
         */
        remindTime?: string
        /**
         * Desc:风险收费比例
         * Default:
         * Nullable:False
         * @format double
         */
        riskRatio?: number
        /**
         * Desc:关联案件编号
         * Default:0
         * Nullable:True
         */
        relationCaseID?: string
        /**
         * Desc:外币收费金额
         * Default:
         * Nullable:False
         * @format double
         */
        foreignCurrencyAmount?: number
        /**
         * Desc:租户ID
         * Default:0
         * Nullable:False
         */
        tenantId?: string
        /**
         * Desc:部门ID  DepartmentId
         * Default:0
         * Nullable:False
         */
        organizationUnitId?: string
        /**
         * Desc:分所Id
         * Default:0
         * Nullable:False
         */
        officeId?: string
        /**
         * Desc:备注
         * Default:
         * Nullable:True
         */
        remark?: string
        /**
         * Desc:状态 	            中立字段，某些表可使用某些表不使用
         * Default:1
         * Nullable:False
         */
        isEnabled?: boolean
        /**
         * Desc:中立字段，某些表可使用某些表不使用   	            逻辑上的删除，非物理删除  	            例如：单据删除并非直接删除
         * Default:0
         * Nullable:False
         */
        isDeleted?: boolean
        /**
         * Desc:中立字段 	            是否内置数据
         * Default:
         * Nullable:False
         */
        isInternal?: boolean
        /**
         * Desc:创建ID
         * Default:
         * Nullable:True
         */
        createId?: string
        /**
         * Desc:创建者
         * Default:
         * Nullable:True
         */
        createBy?: string
        /**
         * Desc:创建时间
         * Default:
         * Nullable:False
         * @format date-time
         */
        createTime?: string
        /**
         * Desc:修改ID
         * Default:
         * Nullable:True
         */
        modifyId?: string
        /**
         * Desc:更新者
         * Default:
         * Nullable:True
         */
        modifyBy?: string
        /**
         * Desc:修改日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        modifyTime?: string
        /**
         * Desc:数据版本
         * Default:0
         * Nullable:False
         */
        version?: string
        /**
         * Desc:删除ID
         * Default:
         * Nullable:True
         */
        deleteId?: string
        /**
         * Desc:删除者
         * Default:
         * Nullable:True
         */
        deleteBy?: string
        /**
         * Desc:删除日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        deleteTime?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<CaseCollectionDtoListMessageModel, void>({
        path: `/api/web/business/case/casecollection/list/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseCollection
     * @name WebBusinessCaseCasecollectionGetDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/business/case/casecollection/get/{id}
     * @secure
     */
    webBusinessCaseCasecollectionGetDetail: (id: string, params: RequestParams = {}) =>
      this.request<CaseCollectionDtoMessageModel, void>({
        path: `/api/web/business/case/casecollection/get/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseCollection
     * @name WebBusinessCaseCasecollectionPostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/business/case/casecollection/post
     * @secure
     */
    webBusinessCaseCasecollectionPostCreate: (data: CreateCaseCollectionInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/case/casecollection/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseCollection
     * @name WebBusinessCaseCasecollectionPutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/business/case/casecollection/put/{id}
     * @secure
     */
    webBusinessCaseCasecollectionPutUpdate: (id: string, data: CreateCaseCollectionInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/case/casecollection/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseCollection
     * @name WebBusinessCaseCasecollectionDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/business/case/casecollection/delete/{id}
     * @secure
     */
    webBusinessCaseCasecollectionDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/case/casecollection/delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseDistribution
     * @name WebBusinessCaseCasedistributionGetpagePageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/business/case/casedistribution/getpage/page
     * @secure
     */
    webBusinessCaseCasedistributionGetpagePageCreate: (
      query?: {
        /**
         * Desc:雪花Id
         * Default:
         * Nullable:False
         */
        id?: string
        /**
         * Desc:案件编号
         * Default:0
         * Nullable:True
         */
        caseId?: string
        /**
         * Desc:用户编号
         * Default:0
         * Nullable:True
         */
        userId?: string
        /**
         * Desc:律师角色
         * Default:
         * Nullable:True
         */
        lawyerRole?: string
        /**
         * Desc:分配金额
         * Default:
         * Nullable:False
         * @format double
         */
        allocationAmount?: number
        /**
         * Desc:分配比例
         * Default:
         * Nullable:False
         * @format double
         */
        allocationRate?: number
        /**
         * Desc:尾差金额
         * Default:
         * Nullable:False
         * @format double
         */
        tailAmount?: number
        /**
         * Desc:是否案源费
         * Default:
         * Nullable:False
         */
        isSourceFee?: boolean
        /**
         * Desc:是否默认策略
         * Default:
         * Nullable:False
         */
        isDefault?: boolean
        /**
         * Desc:租户ID
         * Default:0
         * Nullable:False
         */
        tenantId?: string
        /**
         * Desc:部门ID  DepartmentId
         * Default:0
         * Nullable:False
         */
        organizationUnitId?: string
        /**
         * Desc:分所Id
         * Default:0
         * Nullable:False
         */
        officeId?: string
        /**
         * Desc:备注
         * Default:
         * Nullable:True
         */
        remark?: string
        /**
         * Desc:状态 	            中立字段，某些表可使用某些表不使用
         * Default:1
         * Nullable:False
         */
        isEnabled?: boolean
        /**
         * Desc:中立字段，某些表可使用某些表不使用   	            逻辑上的删除，非物理删除  	            例如：单据删除并非直接删除
         * Default:0
         * Nullable:False
         */
        isDeleted?: boolean
        /**
         * Desc:中立字段 	            是否内置数据
         * Default:
         * Nullable:False
         */
        isInternal?: boolean
        /**
         * Desc:创建ID
         * Default:
         * Nullable:True
         */
        createId?: string
        /**
         * Desc:创建者
         * Default:
         * Nullable:True
         */
        createBy?: string
        /**
         * Desc:创建时间
         * Default:
         * Nullable:False
         * @format date-time
         */
        createTime?: string
        /**
         * Desc:修改ID
         * Default:
         * Nullable:True
         */
        modifyId?: string
        /**
         * Desc:更新者
         * Default:
         * Nullable:True
         */
        modifyBy?: string
        /**
         * Desc:修改日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        modifyTime?: string
        /**
         * Desc:数据版本
         * Default:0
         * Nullable:False
         */
        version?: string
        /**
         * Desc:删除ID
         * Default:
         * Nullable:True
         */
        deleteId?: string
        /**
         * Desc:删除者
         * Default:
         * Nullable:True
         */
        deleteBy?: string
        /**
         * Desc:删除日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        deleteTime?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<CaseDistributionDtoPageModelMessageModel, void>({
        path: `/api/web/business/case/casedistribution/getpage/page`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseDistribution
     * @name WebBusinessCaseCasedistributionListListList
     * @summary 列表获取所有 (Auth policies: Web)
     * @request GET:/api/web/business/case/casedistribution/list/list
     * @secure
     */
    webBusinessCaseCasedistributionListListList: (
      query?: {
        /**
         * Desc:雪花Id
         * Default:
         * Nullable:False
         */
        id?: string
        /**
         * Desc:案件编号
         * Default:0
         * Nullable:True
         */
        caseId?: string
        /**
         * Desc:用户编号
         * Default:0
         * Nullable:True
         */
        userId?: string
        /**
         * Desc:律师角色
         * Default:
         * Nullable:True
         */
        lawyerRole?: string
        /**
         * Desc:分配金额
         * Default:
         * Nullable:False
         * @format double
         */
        allocationAmount?: number
        /**
         * Desc:分配比例
         * Default:
         * Nullable:False
         * @format double
         */
        allocationRate?: number
        /**
         * Desc:尾差金额
         * Default:
         * Nullable:False
         * @format double
         */
        tailAmount?: number
        /**
         * Desc:是否案源费
         * Default:
         * Nullable:False
         */
        isSourceFee?: boolean
        /**
         * Desc:是否默认策略
         * Default:
         * Nullable:False
         */
        isDefault?: boolean
        /**
         * Desc:租户ID
         * Default:0
         * Nullable:False
         */
        tenantId?: string
        /**
         * Desc:部门ID  DepartmentId
         * Default:0
         * Nullable:False
         */
        organizationUnitId?: string
        /**
         * Desc:分所Id
         * Default:0
         * Nullable:False
         */
        officeId?: string
        /**
         * Desc:备注
         * Default:
         * Nullable:True
         */
        remark?: string
        /**
         * Desc:状态 	            中立字段，某些表可使用某些表不使用
         * Default:1
         * Nullable:False
         */
        isEnabled?: boolean
        /**
         * Desc:中立字段，某些表可使用某些表不使用   	            逻辑上的删除，非物理删除  	            例如：单据删除并非直接删除
         * Default:0
         * Nullable:False
         */
        isDeleted?: boolean
        /**
         * Desc:中立字段 	            是否内置数据
         * Default:
         * Nullable:False
         */
        isInternal?: boolean
        /**
         * Desc:创建ID
         * Default:
         * Nullable:True
         */
        createId?: string
        /**
         * Desc:创建者
         * Default:
         * Nullable:True
         */
        createBy?: string
        /**
         * Desc:创建时间
         * Default:
         * Nullable:False
         * @format date-time
         */
        createTime?: string
        /**
         * Desc:修改ID
         * Default:
         * Nullable:True
         */
        modifyId?: string
        /**
         * Desc:更新者
         * Default:
         * Nullable:True
         */
        modifyBy?: string
        /**
         * Desc:修改日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        modifyTime?: string
        /**
         * Desc:数据版本
         * Default:0
         * Nullable:False
         */
        version?: string
        /**
         * Desc:删除ID
         * Default:
         * Nullable:True
         */
        deleteId?: string
        /**
         * Desc:删除者
         * Default:
         * Nullable:True
         */
        deleteBy?: string
        /**
         * Desc:删除日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        deleteTime?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<CaseDistributionDtoListMessageModel, void>({
        path: `/api/web/business/case/casedistribution/list/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseDistribution
     * @name WebBusinessCaseCasedistributionGetDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/business/case/casedistribution/get/{id}
     * @secure
     */
    webBusinessCaseCasedistributionGetDetail: (id: string, params: RequestParams = {}) =>
      this.request<CaseDistributionDtoMessageModel, void>({
        path: `/api/web/business/case/casedistribution/get/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseDistribution
     * @name WebBusinessCaseCasedistributionPostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/business/case/casedistribution/post
     * @secure
     */
    webBusinessCaseCasedistributionPostCreate: (data: CreateCaseDistributionInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/case/casedistribution/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseDistribution
     * @name WebBusinessCaseCasedistributionPutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/business/case/casedistribution/put/{id}
     * @secure
     */
    webBusinessCaseCasedistributionPutUpdate: (id: string, data: CreateCaseDistributionInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/case/casedistribution/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseDistribution
     * @name WebBusinessCaseCasedistributionDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/business/case/casedistribution/delete/{id}
     * @secure
     */
    webBusinessCaseCasedistributionDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/case/casedistribution/delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseLawyer
     * @name WebBusinessCaseCaselawyerGetpagePageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/business/case/caselawyer/getpage/page
     * @secure
     */
    webBusinessCaseCaselawyerGetpagePageCreate: (
      query?: {
        /**
         * Desc:案件编号
         * Default:0
         * Nullable:True
         */
        caseId?: string
        /**
         * Desc:用户编号
         * Default:0
         * Nullable:True
         */
        userId?: string
        /**
         * Desc:律师参案角色
         * Default:
         * Nullable:True
         */
        lawyerRole?: string
        /**
         * Desc:律师费率
         * Default:
         * Nullable:False
         * @format double
         */
        chargeRatio?: number
        /**
         * Desc:服务开始时间
         * Default:
         * Nullable:False
         * @format date-time
         */
        startDate?: string
        /**
         * Desc:服务结束时间
         * Default:
         * Nullable:False
         * @format date-time
         */
        endDate?: string
        /**
         * Desc:是否非当前服务律师
         * Default:
         * Nullable:False
         */
        isInActive?: boolean
        /**
         * Desc:备注
         * Default:
         * Nullable:True
         */
        remark?: string
        /**
         * Desc:状态 	            中立字段，某些表可使用某些表不使用
         * Default:1
         * Nullable:False
         */
        createId?: string
        /**
         * Desc:创建者
         * Default:
         * Nullable:True
         */
        createBy?: string
        /**
         * Desc:创建时间
         * Default:
         * Nullable:False
         * @format date-time
         */
        createTime?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<CaseLawyerDtoPageModelMessageModel, void>({
        path: `/api/web/business/case/caselawyer/getpage/page`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseLawyer
     * @name WebBusinessCaseCaselawyerListListList
     * @summary 列表获取所有 (Auth policies: Web)
     * @request GET:/api/web/business/case/caselawyer/list/list
     * @secure
     */
    webBusinessCaseCaselawyerListListList: (
      query?: {
        /**
         * Desc:案件编号
         * Default:0
         * Nullable:True
         */
        caseId?: string
        /**
         * Desc:用户编号
         * Default:0
         * Nullable:True
         */
        userId?: string
        /**
         * Desc:律师参案角色
         * Default:
         * Nullable:True
         */
        lawyerRole?: string
        /**
         * Desc:律师费率
         * Default:
         * Nullable:False
         * @format double
         */
        chargeRatio?: number
        /**
         * Desc:服务开始时间
         * Default:
         * Nullable:False
         * @format date-time
         */
        startDate?: string
        /**
         * Desc:服务结束时间
         * Default:
         * Nullable:False
         * @format date-time
         */
        endDate?: string
        /**
         * Desc:是否非当前服务律师
         * Default:
         * Nullable:False
         */
        isInActive?: boolean
        /**
         * Desc:备注
         * Default:
         * Nullable:True
         */
        remark?: string
        /**
         * Desc:状态 	            中立字段，某些表可使用某些表不使用
         * Default:1
         * Nullable:False
         */
        createId?: string
        /**
         * Desc:创建者
         * Default:
         * Nullable:True
         */
        createBy?: string
        /**
         * Desc:创建时间
         * Default:
         * Nullable:False
         * @format date-time
         */
        createTime?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<CaseLawyerDtoListMessageModel, void>({
        path: `/api/web/business/case/caselawyer/list/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseLawyer
     * @name WebBusinessCaseCaselawyerGetDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/business/case/caselawyer/get/{id}
     * @secure
     */
    webBusinessCaseCaselawyerGetDetail: (id: string, params: RequestParams = {}) =>
      this.request<CaseLawyerDtoMessageModel, void>({
        path: `/api/web/business/case/caselawyer/get/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseLawyer
     * @name WebBusinessCaseCaselawyerPostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/business/case/caselawyer/post
     * @secure
     */
    webBusinessCaseCaselawyerPostCreate: (data: CreateCaseLawyerInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/case/caselawyer/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseLawyer
     * @name WebBusinessCaseCaselawyerPutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/business/case/caselawyer/put/{id}
     * @secure
     */
    webBusinessCaseCaselawyerPutUpdate: (id: string, data: CreateCaseLawyerInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/case/caselawyer/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseLawyer
     * @name WebBusinessCaseCaselawyerDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/business/case/caselawyer/delete/{id}
     * @secure
     */
    webBusinessCaseCaselawyerDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/case/caselawyer/delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseMatter
     * @name WebBusinessCaseCasematterGetpagePageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/business/case/casematter/getpage/page
     * @secure
     */
    webBusinessCaseCasematterGetpagePageCreate: (
      query?: {
        /**
         * Desc:利冲预检编号
         * Default:0
         * Nullable:True
         */
        preCSRID?: string
        /**
         * Desc:利冲申请编号
         * Default:0
         * Nullable:True
         */
        cSRID?: string
        /**
         * Desc:案件类型
         * Default:
         * Nullable:True
         */
        category?: string
        /**
         * Desc:案由分类
         * Default:
         * Nullable:True
         */
        reason?: string
        /**
         * Desc:补充案由
         * Default:
         * Nullable:True
         */
        reasonSupplement?: string
        /**
         * Desc:关联案件编号
         * Default:0
         * Nullable:True
         */
        relationCaseID?: string
        /**
         * Desc:案件名称
         * Default:
         * Nullable:True
         */
        name?: string
        /**
         * Desc:案件名称注释备注
         * Default:
         * Nullable:True
         */
        nameNote?: string
        /**
         * Desc:客户行业编号
         * Default:
         * Nullable:True
         */
        clientIndustryID?: string
        /**
         * Desc:执业领域
         * Default:
         * Nullable:True
         */
        practiceArea?: string
        /**
         * Desc:是否涉密
         * Default:
         * Nullable:True
         */
        isClassified?: string
        /**
         * Desc:是否紧急
         * Default:
         * Nullable:True
         */
        isUrgent?: string
        /**
         * Desc:收费方式（固定，小时，风险，免费代理）
         * Default:
         * Nullable:True
         */
        chargingMethods?: string
        /**
         * Desc:收费方式文本
         * Default:
         * Nullable:True
         */
        chargingMethodText?: string
        /**
         * Desc:合同金额（费用总额）
         * Default:
         * Nullable:False
         * @format double
         */
        contractAmount?: number
        /**
         * Desc:委托事项
         * Default:
         * Nullable:True
         */
        entrustedMatters?: string
        /**
         * Desc:书写语言
         * Default:
         * Nullable:True
         */
        writtenLanguage?: string
        /**
         * Desc:收案日期
         * Default:
         * Nullable:False
         * @format date-time
         */
        acceptDate?: string
        /**
         * Desc:案件来源
         * Default:
         * Nullable:True
         */
        caseSource?: string
        /**
         * Desc:委托阶段
         * Default:
         * Nullable:True
         */
        stages?: string
        /**
         * Desc:当前阶段
         * Default:
         * Nullable:True
         */
        currentStage?: string
        /**
         * Desc:受理机构
         * Default:
         * Nullable:True
         */
        acceptAgency?: string
        /**
         * Desc:受理机构案号
         * Default:
         * Nullable:True
         */
        acceptCaseNo?: string
        /**
         * Desc:受理机构级别（法院级别）
         * Default:
         * Nullable:True
         */
        acceptAgencyLevel?: string
        /**
         * Desc:特殊案件类型
         * Default:
         * Nullable:True
         */
        specialType?: string
        /**
         * Desc:是否跨所合作案件
         * Default:
         * Nullable:True
         */
        isInterbankCooperation?: string
        /**
         * Desc:合作分所
         * Default:0
         * Nullable:True
         * @format int64
         */
        cooperationOffice?: number
        /**
         * Desc:合作律师
         * Default:
         * Nullable:True
         */
        cooperativeLawyerIDs?: string
        /**
         * Desc:案情简介
         * Default:
         * Nullable:True
         */
        narrative?: string
        /**
         * Desc:代理权限
         * Default:
         * Nullable:True
         */
        agencyAuthority?: string
        /**
         * Desc:诉讼地位（代理何方）
         * Default:
         * Nullable:True
         */
        litigationStatus?: string
        /**
         * Desc:是否标的
         * Default:
         * Nullable:True
         */
        isTarget?: string
        /**
         * Desc:标的额
         * Default:
         * Nullable:False
         * @format double
         */
        targetAmount?: number
        /**
         * Desc:货币单位
         * Default:
         * Nullable:True
         */
        currency?: string
        /**
         * Desc:顾问合同起始时间
         * Default:
         * Nullable:False
         * @format date-time
         */
        startDate?: string
        /**
         * Desc:顾问合同结束时间（空则长期）
         * Default:
         * Nullable:False
         * @format date-time
         */
        endDate?: string
        /**
         * Desc:律师费用
         * Default:
         * Nullable:False
         * @format double
         */
        lawyerFee?: number
        /**
         * Desc:办案费
         * Default:
         * Nullable:False
         * @format double
         */
        caseHandlingFee?: number
        /**
         * Desc:代收代付费
         * Default:
         * Nullable:False
         * @format double
         */
        pocoFee?: number
        /**
         * Desc:案件项下办案费支出方
         * Default:
         * Nullable:True
         */
        feePaidParty?: string
        /**
         * Desc:办案费限额
         * Default:
         * Nullable:False
         * @format double
         */
        caseHandlingFeeLimit?: number
        /**
         * Desc:约定汇率模式
         * Default:
         * Nullable:True
         */
        agreedExchangeRateMode?: string
        /**
         * Desc:汇率
         * Default:
         * Nullable:False
         * @format double
         */
        exchangeRate?: number
        /**
         * Desc:免费代理小时数
         * Default:
         * Nullable:False
         * @format double
         */
        freeHourLimit?: number
        /**
         * Desc:主管律师（主办律师）
         * Default:
         * Nullable:True
         */
        inChargeLawyer?: string
        /**
         * Desc:协办律师（合作律师）
         * Default:
         * Nullable:True
         */
        coWorkingLawyer?: string
        /**
         * Desc:案源律师
         * Default:
         * Nullable:True
         */
        caseSourceLawyer?: string
        /**
         * Desc:账单律师
         * Default:
         * Nullable:True
         */
        billingLawyer?: string
        /**
         * Desc:秘书助理（预留）;选择人则限定选择人和值班秘书可见，不选就全部助理和秘书可见
         * Default:
         * Nullable:True
         */
        assistantSecretary?: string
        /**
         * Desc:备注
         * Default:
         * Nullable:True
         */
        remark?: string
        /**
         * Desc:中立字段，某些表可使用某些表不使用   	                逻辑上的删除，非物理删除  	                例如：单据删除并非直接删除
         * Default:0
         * Nullable:False
         */
        isDeleted?: boolean
        /**
         * Desc:创建ID
         * Default:
         * Nullable:True
         */
        createId?: string
        /**
         * Desc:创建者
         * Default:
         * Nullable:True
         */
        createBy?: string
        /**
         * Desc:创建时间
         * Default:
         * Nullable:False
         * @format date-time
         */
        createTime?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<CaseMatterDtoPageModelMessageModel, void>({
        path: `/api/web/business/case/casematter/getpage/page`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseMatter
     * @name WebBusinessCaseCasematterListListList
     * @summary 列表获取所有 (Auth policies: Web)
     * @request GET:/api/web/business/case/casematter/list/list
     * @secure
     */
    webBusinessCaseCasematterListListList: (
      query?: {
        /**
         * Desc:利冲预检编号
         * Default:0
         * Nullable:True
         */
        preCSRID?: string
        /**
         * Desc:利冲申请编号
         * Default:0
         * Nullable:True
         */
        cSRID?: string
        /**
         * Desc:案件类型
         * Default:
         * Nullable:True
         */
        category?: string
        /**
         * Desc:案由分类
         * Default:
         * Nullable:True
         */
        reason?: string
        /**
         * Desc:补充案由
         * Default:
         * Nullable:True
         */
        reasonSupplement?: string
        /**
         * Desc:关联案件编号
         * Default:0
         * Nullable:True
         */
        relationCaseID?: string
        /**
         * Desc:案件名称
         * Default:
         * Nullable:True
         */
        name?: string
        /**
         * Desc:案件名称注释备注
         * Default:
         * Nullable:True
         */
        nameNote?: string
        /**
         * Desc:客户行业编号
         * Default:
         * Nullable:True
         */
        clientIndustryID?: string
        /**
         * Desc:执业领域
         * Default:
         * Nullable:True
         */
        practiceArea?: string
        /**
         * Desc:是否涉密
         * Default:
         * Nullable:True
         */
        isClassified?: string
        /**
         * Desc:是否紧急
         * Default:
         * Nullable:True
         */
        isUrgent?: string
        /**
         * Desc:收费方式（固定，小时，风险，免费代理）
         * Default:
         * Nullable:True
         */
        chargingMethods?: string
        /**
         * Desc:收费方式文本
         * Default:
         * Nullable:True
         */
        chargingMethodText?: string
        /**
         * Desc:合同金额（费用总额）
         * Default:
         * Nullable:False
         * @format double
         */
        contractAmount?: number
        /**
         * Desc:委托事项
         * Default:
         * Nullable:True
         */
        entrustedMatters?: string
        /**
         * Desc:书写语言
         * Default:
         * Nullable:True
         */
        writtenLanguage?: string
        /**
         * Desc:收案日期
         * Default:
         * Nullable:False
         * @format date-time
         */
        acceptDate?: string
        /**
         * Desc:案件来源
         * Default:
         * Nullable:True
         */
        caseSource?: string
        /**
         * Desc:委托阶段
         * Default:
         * Nullable:True
         */
        stages?: string
        /**
         * Desc:当前阶段
         * Default:
         * Nullable:True
         */
        currentStage?: string
        /**
         * Desc:受理机构
         * Default:
         * Nullable:True
         */
        acceptAgency?: string
        /**
         * Desc:受理机构案号
         * Default:
         * Nullable:True
         */
        acceptCaseNo?: string
        /**
         * Desc:受理机构级别（法院级别）
         * Default:
         * Nullable:True
         */
        acceptAgencyLevel?: string
        /**
         * Desc:特殊案件类型
         * Default:
         * Nullable:True
         */
        specialType?: string
        /**
         * Desc:是否跨所合作案件
         * Default:
         * Nullable:True
         */
        isInterbankCooperation?: string
        /**
         * Desc:合作分所
         * Default:0
         * Nullable:True
         * @format int64
         */
        cooperationOffice?: number
        /**
         * Desc:合作律师
         * Default:
         * Nullable:True
         */
        cooperativeLawyerIDs?: string
        /**
         * Desc:案情简介
         * Default:
         * Nullable:True
         */
        narrative?: string
        /**
         * Desc:代理权限
         * Default:
         * Nullable:True
         */
        agencyAuthority?: string
        /**
         * Desc:诉讼地位（代理何方）
         * Default:
         * Nullable:True
         */
        litigationStatus?: string
        /**
         * Desc:是否标的
         * Default:
         * Nullable:True
         */
        isTarget?: string
        /**
         * Desc:标的额
         * Default:
         * Nullable:False
         * @format double
         */
        targetAmount?: number
        /**
         * Desc:货币单位
         * Default:
         * Nullable:True
         */
        currency?: string
        /**
         * Desc:顾问合同起始时间
         * Default:
         * Nullable:False
         * @format date-time
         */
        startDate?: string
        /**
         * Desc:顾问合同结束时间（空则长期）
         * Default:
         * Nullable:False
         * @format date-time
         */
        endDate?: string
        /**
         * Desc:律师费用
         * Default:
         * Nullable:False
         * @format double
         */
        lawyerFee?: number
        /**
         * Desc:办案费
         * Default:
         * Nullable:False
         * @format double
         */
        caseHandlingFee?: number
        /**
         * Desc:代收代付费
         * Default:
         * Nullable:False
         * @format double
         */
        pocoFee?: number
        /**
         * Desc:案件项下办案费支出方
         * Default:
         * Nullable:True
         */
        feePaidParty?: string
        /**
         * Desc:办案费限额
         * Default:
         * Nullable:False
         * @format double
         */
        caseHandlingFeeLimit?: number
        /**
         * Desc:约定汇率模式
         * Default:
         * Nullable:True
         */
        agreedExchangeRateMode?: string
        /**
         * Desc:汇率
         * Default:
         * Nullable:False
         * @format double
         */
        exchangeRate?: number
        /**
         * Desc:免费代理小时数
         * Default:
         * Nullable:False
         * @format double
         */
        freeHourLimit?: number
        /**
         * Desc:主管律师（主办律师）
         * Default:
         * Nullable:True
         */
        inChargeLawyer?: string
        /**
         * Desc:协办律师（合作律师）
         * Default:
         * Nullable:True
         */
        coWorkingLawyer?: string
        /**
         * Desc:案源律师
         * Default:
         * Nullable:True
         */
        caseSourceLawyer?: string
        /**
         * Desc:账单律师
         * Default:
         * Nullable:True
         */
        billingLawyer?: string
        /**
         * Desc:秘书助理（预留）;选择人则限定选择人和值班秘书可见，不选就全部助理和秘书可见
         * Default:
         * Nullable:True
         */
        assistantSecretary?: string
        /**
         * Desc:备注
         * Default:
         * Nullable:True
         */
        remark?: string
        /**
         * Desc:中立字段，某些表可使用某些表不使用   	                逻辑上的删除，非物理删除  	                例如：单据删除并非直接删除
         * Default:0
         * Nullable:False
         */
        isDeleted?: boolean
        /**
         * Desc:创建ID
         * Default:
         * Nullable:True
         */
        createId?: string
        /**
         * Desc:创建者
         * Default:
         * Nullable:True
         */
        createBy?: string
        /**
         * Desc:创建时间
         * Default:
         * Nullable:False
         * @format date-time
         */
        createTime?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<CaseMatterDtoListMessageModel, void>({
        path: `/api/web/business/case/casematter/list/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseMatter
     * @name WebBusinessCaseCasematterGetDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/business/case/casematter/get/{id}
     * @secure
     */
    webBusinessCaseCasematterGetDetail: (id: string, params: RequestParams = {}) =>
      this.request<CaseMatterDtoMessageModel, void>({
        path: `/api/web/business/case/casematter/get/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseMatter
     * @name WebBusinessCaseCasematterPostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/business/case/casematter/post
     * @secure
     */
    webBusinessCaseCasematterPostCreate: (data: CreateCaseMatterInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/case/casematter/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseMatter
     * @name WebBusinessCaseCasematterPutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/business/case/casematter/put/{id}
     * @secure
     */
    webBusinessCaseCasematterPutUpdate: (id: string, data: CreateCaseMatterInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/case/casematter/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseMatter
     * @name WebBusinessCaseCasematterDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/business/case/casematter/delete/{id}
     * @secure
     */
    webBusinessCaseCasematterDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/case/casematter/delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseRelatedParty
     * @name WebBusinessCaseCaserelatedpartyGetpagePageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/business/case/caserelatedparty/getpage/page
     * @secure
     */
    webBusinessCaseCaserelatedpartyGetpagePageCreate: (
      query?: {
        /**
         * Desc:案件或利冲编号
         * Default:0
         * Nullable:True
         */
        caseId?: string
        /**
         * Desc:客户编号
         * Default:0
         * Nullable:True
         */
        clientId?: string
        /**
         * Desc:关联方类型
         * Default:
         * Nullable:True
         */
        category?: string
        /**
         * Desc:文书关联编号
         * Default:0
         * Nullable:True
         */
        caseFileRelationId?: string
        /**
         * Desc:关联方名称
         * Default:
         * Nullable:True
         */
        name?: string
        /**
         * Desc:外文名称
         * Default:
         * Nullable:True
         */
        foreignName?: string
        /**
         * Desc:曾用名
         * Default:
         * Nullable:True
         */
        formerName?: string
        /**
         * Desc:联系人
         * Default:
         * Nullable:True
         */
        linker?: string
        /**
         * Desc:证件类型
         * Default:
         * Nullable:True
         */
        creditType?: string
        /**
         * Desc:证件号码
         * Default:
         * Nullable:True
         */
        creditCode?: string
        /**
         * Desc:邮件地址
         * Default:
         * Nullable:True
         */
        emailAddress?: string
        /**
         * Desc:联系电话
         * Default:
         * Nullable:True
         */
        phoneNumber?: string
        /**
         * Desc:联系地址
         * Default:
         * Nullable:True
         */
        address?: string
        /**
         * Desc:关联方角色
         * Default:
         * Nullable:True
         */
        partyRole?: string
        /**
         * Desc:利冲关键词
         * Default:
         * Nullable:True
         */
        conflictKeyword?: string
        /**
         * Desc:是否双方当事人
         * Default:
         * Nullable:False
         */
        isParties?: boolean
        /**
         * Desc:说明描述
         * Default:
         * Nullable:True
         */
        narrative?: string
        /**
         * Desc:备注
         * Default:
         * Nullable:True
         */
        remark?: string
        /**
         * Desc:创建ID
         * Default:
         * Nullable:True
         * @format int64
         */
        createId?: number
        /**
         * Desc:创建者
         * Default:
         * Nullable:True
         */
        createBy?: string
        /**
         * Desc:创建时间
         * Default:
         * Nullable:False
         * @format date-time
         */
        createTime?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<CaseRelatedPartyDtoPageModelMessageModel, void>({
        path: `/api/web/business/case/caserelatedparty/getpage/page`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseRelatedParty
     * @name WebBusinessCaseCaserelatedpartyListListList
     * @summary 列表获取所有 (Auth policies: Web)
     * @request GET:/api/web/business/case/caserelatedparty/list/list
     * @secure
     */
    webBusinessCaseCaserelatedpartyListListList: (
      query?: {
        /**
         * Desc:案件或利冲编号
         * Default:0
         * Nullable:True
         */
        caseId?: string
        /**
         * Desc:客户编号
         * Default:0
         * Nullable:True
         */
        clientId?: string
        /**
         * Desc:关联方类型
         * Default:
         * Nullable:True
         */
        category?: string
        /**
         * Desc:文书关联编号
         * Default:0
         * Nullable:True
         */
        caseFileRelationId?: string
        /**
         * Desc:关联方名称
         * Default:
         * Nullable:True
         */
        name?: string
        /**
         * Desc:外文名称
         * Default:
         * Nullable:True
         */
        foreignName?: string
        /**
         * Desc:曾用名
         * Default:
         * Nullable:True
         */
        formerName?: string
        /**
         * Desc:联系人
         * Default:
         * Nullable:True
         */
        linker?: string
        /**
         * Desc:证件类型
         * Default:
         * Nullable:True
         */
        creditType?: string
        /**
         * Desc:证件号码
         * Default:
         * Nullable:True
         */
        creditCode?: string
        /**
         * Desc:邮件地址
         * Default:
         * Nullable:True
         */
        emailAddress?: string
        /**
         * Desc:联系电话
         * Default:
         * Nullable:True
         */
        phoneNumber?: string
        /**
         * Desc:联系地址
         * Default:
         * Nullable:True
         */
        address?: string
        /**
         * Desc:关联方角色
         * Default:
         * Nullable:True
         */
        partyRole?: string
        /**
         * Desc:利冲关键词
         * Default:
         * Nullable:True
         */
        conflictKeyword?: string
        /**
         * Desc:是否双方当事人
         * Default:
         * Nullable:False
         */
        isParties?: boolean
        /**
         * Desc:说明描述
         * Default:
         * Nullable:True
         */
        narrative?: string
        /**
         * Desc:备注
         * Default:
         * Nullable:True
         */
        remark?: string
        /**
         * Desc:创建ID
         * Default:
         * Nullable:True
         * @format int64
         */
        createId?: number
        /**
         * Desc:创建者
         * Default:
         * Nullable:True
         */
        createBy?: string
        /**
         * Desc:创建时间
         * Default:
         * Nullable:False
         * @format date-time
         */
        createTime?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<CaseRelatedPartyDtoListMessageModel, void>({
        path: `/api/web/business/case/caserelatedparty/list/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseRelatedParty
     * @name WebBusinessCaseCaserelatedpartyGetDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/business/case/caserelatedparty/get/{id}
     * @secure
     */
    webBusinessCaseCaserelatedpartyGetDetail: (id: string, params: RequestParams = {}) =>
      this.request<CaseRelatedPartyDtoMessageModel, void>({
        path: `/api/web/business/case/caserelatedparty/get/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseRelatedParty
     * @name WebBusinessCaseCaserelatedpartyPostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/business/case/caserelatedparty/post
     * @secure
     */
    webBusinessCaseCaserelatedpartyPostCreate: (data: CreateCaseRelatedPartyInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/case/caserelatedparty/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseRelatedParty
     * @name WebBusinessCaseCaserelatedpartyPutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/business/case/caserelatedparty/put/{id}
     * @secure
     */
    webBusinessCaseCaserelatedpartyPutUpdate: (id: string, data: CreateCaseRelatedPartyInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/case/caserelatedparty/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseRelatedParty
     * @name WebBusinessCaseCaserelatedpartyDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/business/case/caserelatedparty/delete/{id}
     * @secure
     */
    webBusinessCaseCaserelatedpartyDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/case/caserelatedparty/delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Client
     * @name WebCustomerClientGetclientpageCreate
     * @summary 获取客户分页 (Auth policies: Web)
     * @request POST:/api/web/customer/client/getclientpage
     * @secure
     */
    webCustomerClientGetclientpageCreate: (
      query?: {
        /** 客户名称 */
        name?: string
        /** 客户类型 */
        clientType?: string
        /** 证件类型 */
        creditType?: string
        /** 证件号码 */
        creditCode?: string
        /** 来源类型 */
        originType?: string
        /** 来源编号 */
        originID?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<ClientDtoPageModelMessageModel, void>({
        path: `/api/web/customer/client/getclientpage`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Client
     * @name WebCustomerClientGetList
     * @summary 获取客户详情 (Auth policies: Web)
     * @request GET:/api/web/customer/client/get
     * @secure
     */
    webCustomerClientGetList: (
      query?: {
        id?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<ClientDtoMessageModel, void>({
        path: `/api/web/customer/client/get`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Client
     * @name WebCustomerClientGetclientinfoCreate
     * @summary 添加或修改客户 (Auth policies: Web)
     * @request POST:/api/web/customer/client/getclientinfo
     * @secure
     */
    webCustomerClientGetclientinfoCreate: (data: CreateClientInfoInput, params: RequestParams = {}) =>
      this.request<ClientDtoMessageModel, void>({
        path: `/api/web/customer/client/getclientinfo`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Client
     * @name WebCustomerClientDeleteDelete
     * @summary 删除客户 (Auth policies: Web)
     * @request DELETE:/api/web/customer/client/delete
     * @secure
     */
    webCustomerClientDeleteDelete: (
      query?: {
        id?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<BooleanMessageModel, void>({
        path: `/api/web/customer/client/delete`,
        method: 'DELETE',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Client
     * @name WebCustomerClientCustomexceltemplateinitExcelTemplateList
     * @summary 下载导入模板 (Auth policies: Web)
     * @request GET:/api/web/customer/client/customexceltemplateinit/excel/template
     * @secure
     */
    webCustomerClientCustomexceltemplateinitExcelTemplateList: (
      query?: {
        fileName?: string
        requestUrl?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<ExcelFileDtoMessageModel, void>({
        path: `/api/web/customer/client/customexceltemplateinit/excel/template`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Client
     * @name WebCustomerClientCustomexceltemplateimportExcelImportCreate
     * @summary Excel导入数据 (Auth policies: Web)
     * @request POST:/api/web/customer/client/customexceltemplateimport/excel/import
     * @secure
     */
    webCustomerClientCustomexceltemplateimportExcelImportCreate: (
      data: {
        /** @format binary */
        file?: File
      },
      params: RequestParams = {},
    ) =>
      this.request<ClientDtoListMessageModel, void>({
        path: `/api/web/customer/client/customexceltemplateimport/excel/import`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Client
     * @name WebCustomerClientCustomexcelexportExcelCreate
     * @summary 导出数据 (Auth policies: Web)
     * @request POST:/api/web/customer/client/customexcelexport/excel
     * @secure
     */
    webCustomerClientCustomexcelexportExcelCreate: (params: RequestParams = {}) =>
      this.request<ExcelFileDtoMessageModel, void>({
        path: `/api/web/customer/client/customexcelexport/excel`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ClientBelongTimekeeper
     * @name WebCustomerClientbelongtimekeeperGetpagePageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/customer/clientbelongtimekeeper/getpage/page
     * @secure
     */
    webCustomerClientbelongtimekeeperGetpagePageCreate: (
      query?: {
        /**
         * Desc:雪花Id
         * Default:
         * Nullable:False
         */
        id?: string
        /**
         * Desc:客户编号
         * Default:
         * Nullable:False
         */
        clientID?: string
        /**
         * Desc:计时人员编号
         * Default:
         * Nullable:False
         */
        timekeeperID?: string
        /**
         * Desc:是否其助理或秘书可见
         * Default:
         * Nullable:True
         */
        isAssistantVisible?: string
        /**
         * Desc:是否团队律师可见
         * Default:
         * Nullable:True
         */
        isTeamLawyerVisible?: string
        /**
         * Desc:所属时效开始时间
         * Default:
         * Nullable:False
         * @format date-time
         */
        startDate?: string
        /**
         * Desc:所属时效结束时间（空则长期有效）
         * Default:
         * Nullable:False
         * @format date-time
         */
        endDate?: string
        /**
         * Desc:是否是初始所属人
         * Default:
         * Nullable:True
         */
        isFirstOwner?: string
        /**
         * Desc:是否过期失效
         * Default:
         * Nullable:True
         */
        isExpred?: string
        /**
         * Desc:租户ID
         * Default:0
         * Nullable:False
         */
        tenantId?: string
        /**
         * Desc:部门ID  DepartmentId
         * Default:0
         * Nullable:False
         */
        organizationUnitId?: string
        /**
         * Desc:分所Id
         * Default:0
         * Nullable:False
         */
        officeId?: string
        /**
         * Desc:备注
         * Default:
         * Nullable:True
         */
        remark?: string
        /**
         * Desc:状态 	                中立字段，某些表可使用某些表不使用
         * Default:1
         * Nullable:False
         */
        isEnabled?: boolean
        /**
         * Desc:中立字段，某些表可使用某些表不使用   	                逻辑上的删除，非物理删除  	                例如：单据删除并非直接删除
         * Default:0
         * Nullable:False
         */
        isDeleted?: boolean
        /**
         * Desc:中立字段 	                是否内置数据
         * Default:
         * Nullable:False
         */
        isInternal?: boolean
        /**
         * Desc:创建ID
         * Default:
         * Nullable:True
         */
        createId?: string
        /**
         * Desc:创建者
         * Default:
         * Nullable:True
         */
        createBy?: string
        /**
         * Desc:创建时间
         * Default:
         * Nullable:False
         * @format date-time
         */
        createTime?: string
        /**
         * Desc:修改ID
         * Default:
         * Nullable:True
         */
        modifyId?: string
        /**
         * Desc:更新者
         * Default:
         * Nullable:True
         */
        modifyBy?: string
        /**
         * Desc:修改日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        modifyTime?: string
        /**
         * Desc:数据版本
         * Default:0
         * Nullable:False
         */
        version?: string
        /**
         * Desc:删除ID
         * Default:
         * Nullable:True
         */
        deleteId?: string
        /**
         * Desc:删除者
         * Default:
         * Nullable:True
         */
        deleteBy?: string
        /**
         * Desc:删除日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        deleteTime?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<ClientBelongTimekeeperDtoPageModelMessageModel, void>({
        path: `/api/web/customer/clientbelongtimekeeper/getpage/page`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ClientBelongTimekeeper
     * @name WebCustomerClientbelongtimekeeperListListList
     * @summary 列表获取所有 (Auth policies: Web)
     * @request GET:/api/web/customer/clientbelongtimekeeper/list/list
     * @secure
     */
    webCustomerClientbelongtimekeeperListListList: (
      query?: {
        /**
         * Desc:雪花Id
         * Default:
         * Nullable:False
         */
        id?: string
        /**
         * Desc:客户编号
         * Default:
         * Nullable:False
         */
        clientID?: string
        /**
         * Desc:计时人员编号
         * Default:
         * Nullable:False
         */
        timekeeperID?: string
        /**
         * Desc:是否其助理或秘书可见
         * Default:
         * Nullable:True
         */
        isAssistantVisible?: string
        /**
         * Desc:是否团队律师可见
         * Default:
         * Nullable:True
         */
        isTeamLawyerVisible?: string
        /**
         * Desc:所属时效开始时间
         * Default:
         * Nullable:False
         * @format date-time
         */
        startDate?: string
        /**
         * Desc:所属时效结束时间（空则长期有效）
         * Default:
         * Nullable:False
         * @format date-time
         */
        endDate?: string
        /**
         * Desc:是否是初始所属人
         * Default:
         * Nullable:True
         */
        isFirstOwner?: string
        /**
         * Desc:是否过期失效
         * Default:
         * Nullable:True
         */
        isExpred?: string
        /**
         * Desc:租户ID
         * Default:0
         * Nullable:False
         */
        tenantId?: string
        /**
         * Desc:部门ID  DepartmentId
         * Default:0
         * Nullable:False
         */
        organizationUnitId?: string
        /**
         * Desc:分所Id
         * Default:0
         * Nullable:False
         */
        officeId?: string
        /**
         * Desc:备注
         * Default:
         * Nullable:True
         */
        remark?: string
        /**
         * Desc:状态 	                中立字段，某些表可使用某些表不使用
         * Default:1
         * Nullable:False
         */
        isEnabled?: boolean
        /**
         * Desc:中立字段，某些表可使用某些表不使用   	                逻辑上的删除，非物理删除  	                例如：单据删除并非直接删除
         * Default:0
         * Nullable:False
         */
        isDeleted?: boolean
        /**
         * Desc:中立字段 	                是否内置数据
         * Default:
         * Nullable:False
         */
        isInternal?: boolean
        /**
         * Desc:创建ID
         * Default:
         * Nullable:True
         */
        createId?: string
        /**
         * Desc:创建者
         * Default:
         * Nullable:True
         */
        createBy?: string
        /**
         * Desc:创建时间
         * Default:
         * Nullable:False
         * @format date-time
         */
        createTime?: string
        /**
         * Desc:修改ID
         * Default:
         * Nullable:True
         */
        modifyId?: string
        /**
         * Desc:更新者
         * Default:
         * Nullable:True
         */
        modifyBy?: string
        /**
         * Desc:修改日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        modifyTime?: string
        /**
         * Desc:数据版本
         * Default:0
         * Nullable:False
         */
        version?: string
        /**
         * Desc:删除ID
         * Default:
         * Nullable:True
         */
        deleteId?: string
        /**
         * Desc:删除者
         * Default:
         * Nullable:True
         */
        deleteBy?: string
        /**
         * Desc:删除日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        deleteTime?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<ClientBelongTimekeeperDtoListMessageModel, void>({
        path: `/api/web/customer/clientbelongtimekeeper/list/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ClientBelongTimekeeper
     * @name WebCustomerClientbelongtimekeeperGetDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/customer/clientbelongtimekeeper/get/{id}
     * @secure
     */
    webCustomerClientbelongtimekeeperGetDetail: (id: string, params: RequestParams = {}) =>
      this.request<ClientBelongTimekeeperDtoMessageModel, void>({
        path: `/api/web/customer/clientbelongtimekeeper/get/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ClientBelongTimekeeper
     * @name WebCustomerClientbelongtimekeeperPostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/customer/clientbelongtimekeeper/post
     * @secure
     */
    webCustomerClientbelongtimekeeperPostCreate: (data: CreateClientBelongTimekeeperInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/customer/clientbelongtimekeeper/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ClientBelongTimekeeper
     * @name WebCustomerClientbelongtimekeeperPutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/customer/clientbelongtimekeeper/put/{id}
     * @secure
     */
    webCustomerClientbelongtimekeeperPutUpdate: (id: string, data: CreateClientBelongTimekeeperInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/customer/clientbelongtimekeeper/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ClientBelongTimekeeper
     * @name WebCustomerClientbelongtimekeeperDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/customer/clientbelongtimekeeper/delete/{id}
     * @secure
     */
    webCustomerClientbelongtimekeeperDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/customer/clientbelongtimekeeper/delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CSRAvoidPreference
     * @name WebBusinessCsfCsravoidpreferenceGetpagePageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/business/csf/csravoidpreference/getpage/page
     * @secure
     */
    webBusinessCsfCsravoidpreferenceGetpagePageCreate: (
      query?: {
        /**
         * Desc:雪花Id
         * Default:
         * Nullable:False
         */
        id?: string
        /**
         * Desc:规避偏好关键字
         * Default:
         * Nullable:True
         */
        avoidKeyword?: string
        /**
         * Desc:规避利冲方类型
         * Default:
         * Nullable:True
         */
        patryType?: string
        /**
         * Desc:关键字规避方法（模糊/精确）
         * Default:
         * Nullable:True
         */
        avoidMethod?: string
        /**
         * Desc:规避开始时间
         * Default:
         * Nullable:False
         * @format date-time
         */
        startDate?: string
        /**
         * Desc:规避结束时间（空则长期生效）
         * Default:
         * Nullable:False
         * @format date-time
         */
        endTime?: string
        /**
         * Desc:附件信息
         * Default:
         * Nullable:True
         */
        attachments?: string
        /**
         * Desc:说明描述
         * Default:
         * Nullable:True
         */
        narrative?: string
        /**
         * Desc:租户ID
         * Default:0
         * Nullable:False
         */
        tenantId?: string
        /**
         * Desc:部门ID  DepartmentId
         * Default:0
         * Nullable:False
         */
        organizationUnitId?: string
        /**
         * Desc:分所Id
         * Default:0
         * Nullable:False
         */
        officeId?: string
        /**
         * Desc:备注
         * Default:
         * Nullable:True
         */
        remark?: string
        /**
         * Desc:状态 	                中立字段，某些表可使用某些表不使用
         * Default:1
         * Nullable:False
         */
        isEnabled?: boolean
        /**
         * Desc:中立字段，某些表可使用某些表不使用   	                逻辑上的删除，非物理删除  	                例如：单据删除并非直接删除
         * Default:0
         * Nullable:False
         */
        isDeleted?: boolean
        /**
         * Desc:中立字段 	                是否内置数据
         * Default:
         * Nullable:False
         */
        isInternal?: boolean
        /**
         * Desc:创建ID
         * Default:
         * Nullable:True
         */
        createId?: string
        /**
         * Desc:创建者
         * Default:
         * Nullable:True
         */
        createBy?: string
        /**
         * Desc:创建时间
         * Default:
         * Nullable:False
         * @format date-time
         */
        createTime?: string
        /**
         * Desc:修改ID
         * Default:
         * Nullable:True
         */
        modifyId?: string
        /**
         * Desc:更新者
         * Default:
         * Nullable:True
         */
        modifyBy?: string
        /**
         * Desc:修改日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        modifyTime?: string
        /**
         * Desc:数据版本
         * Default:0
         * Nullable:False
         */
        version?: string
        /**
         * Desc:删除ID
         * Default:
         * Nullable:True
         */
        deleteId?: string
        /**
         * Desc:删除者
         * Default:
         * Nullable:True
         */
        deleteBy?: string
        /**
         * Desc:删除日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        deleteTime?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<CSRAvoidPreferenceDtoPageModelMessageModel, void>({
        path: `/api/web/business/csf/csravoidpreference/getpage/page`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CSRAvoidPreference
     * @name WebBusinessCsfCsravoidpreferenceListListList
     * @summary 列表获取所有 (Auth policies: Web)
     * @request GET:/api/web/business/csf/csravoidpreference/list/list
     * @secure
     */
    webBusinessCsfCsravoidpreferenceListListList: (
      query?: {
        /**
         * Desc:雪花Id
         * Default:
         * Nullable:False
         */
        id?: string
        /**
         * Desc:规避偏好关键字
         * Default:
         * Nullable:True
         */
        avoidKeyword?: string
        /**
         * Desc:规避利冲方类型
         * Default:
         * Nullable:True
         */
        patryType?: string
        /**
         * Desc:关键字规避方法（模糊/精确）
         * Default:
         * Nullable:True
         */
        avoidMethod?: string
        /**
         * Desc:规避开始时间
         * Default:
         * Nullable:False
         * @format date-time
         */
        startDate?: string
        /**
         * Desc:规避结束时间（空则长期生效）
         * Default:
         * Nullable:False
         * @format date-time
         */
        endTime?: string
        /**
         * Desc:附件信息
         * Default:
         * Nullable:True
         */
        attachments?: string
        /**
         * Desc:说明描述
         * Default:
         * Nullable:True
         */
        narrative?: string
        /**
         * Desc:租户ID
         * Default:0
         * Nullable:False
         */
        tenantId?: string
        /**
         * Desc:部门ID  DepartmentId
         * Default:0
         * Nullable:False
         */
        organizationUnitId?: string
        /**
         * Desc:分所Id
         * Default:0
         * Nullable:False
         */
        officeId?: string
        /**
         * Desc:备注
         * Default:
         * Nullable:True
         */
        remark?: string
        /**
         * Desc:状态 	                中立字段，某些表可使用某些表不使用
         * Default:1
         * Nullable:False
         */
        isEnabled?: boolean
        /**
         * Desc:中立字段，某些表可使用某些表不使用   	                逻辑上的删除，非物理删除  	                例如：单据删除并非直接删除
         * Default:0
         * Nullable:False
         */
        isDeleted?: boolean
        /**
         * Desc:中立字段 	                是否内置数据
         * Default:
         * Nullable:False
         */
        isInternal?: boolean
        /**
         * Desc:创建ID
         * Default:
         * Nullable:True
         */
        createId?: string
        /**
         * Desc:创建者
         * Default:
         * Nullable:True
         */
        createBy?: string
        /**
         * Desc:创建时间
         * Default:
         * Nullable:False
         * @format date-time
         */
        createTime?: string
        /**
         * Desc:修改ID
         * Default:
         * Nullable:True
         */
        modifyId?: string
        /**
         * Desc:更新者
         * Default:
         * Nullable:True
         */
        modifyBy?: string
        /**
         * Desc:修改日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        modifyTime?: string
        /**
         * Desc:数据版本
         * Default:0
         * Nullable:False
         */
        version?: string
        /**
         * Desc:删除ID
         * Default:
         * Nullable:True
         */
        deleteId?: string
        /**
         * Desc:删除者
         * Default:
         * Nullable:True
         */
        deleteBy?: string
        /**
         * Desc:删除日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        deleteTime?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<CSRAvoidPreferenceDtoListMessageModel, void>({
        path: `/api/web/business/csf/csravoidpreference/list/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CSRAvoidPreference
     * @name WebBusinessCsfCsravoidpreferenceGetDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/business/csf/csravoidpreference/get/{id}
     * @secure
     */
    webBusinessCsfCsravoidpreferenceGetDetail: (id: string, params: RequestParams = {}) =>
      this.request<CSRAvoidPreferenceDtoMessageModel, void>({
        path: `/api/web/business/csf/csravoidpreference/get/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CSRAvoidPreference
     * @name WebBusinessCsfCsravoidpreferencePostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/business/csf/csravoidpreference/post
     * @secure
     */
    webBusinessCsfCsravoidpreferencePostCreate: (data: CreateCSRAvoidPreferenceInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/csf/csravoidpreference/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CSRAvoidPreference
     * @name WebBusinessCsfCsravoidpreferencePutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/business/csf/csravoidpreference/put/{id}
     * @secure
     */
    webBusinessCsfCsravoidpreferencePutUpdate: (id: string, data: CreateCSRAvoidPreferenceInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/csf/csravoidpreference/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CSRAvoidPreference
     * @name WebBusinessCsfCsravoidpreferenceDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/business/csf/csravoidpreference/delete/{id}
     * @secure
     */
    webBusinessCsfCsravoidpreferenceDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/csf/csravoidpreference/delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CSRAvoidPreferenceResult
     * @name WebBusinessCsfCsravoidpreferenceresultGetpagePageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/business/csf/csravoidpreferenceresult/getpage/page
     * @secure
     */
    webBusinessCsfCsravoidpreferenceresultGetpagePageCreate: (
      query?: {
        /**
         * Desc:雪花Id
         * Default:
         * Nullable:False
         */
        id?: string
        /**
         * Desc:利冲规避编号
         * Default:
         * Nullable:False
         */
        cSRAvoidID?: string
        /**
         * Desc:利冲规避关键词
         * Default:
         * Nullable:True
         */
        avoidKeyword?: string
        /**
         * Desc:关键词隶属规避方角色
         * Default:
         * Nullable:True
         */
        partyType?: string
        /**
         * Desc:规避冲突案件的角色
         * Default:
         * Nullable:True
         */
        caseParty?: string
        /**
         * Desc:规避案件冲突方名称
         * Default:
         * Nullable:True
         */
        casePartyName?: string
        /**
         * Desc:规避利冲关联业务类型（利冲/案件/入库/投标等）
         * Default:
         * Nullable:True
         */
        cSRRelationType?: string
        /**
         * Desc:规避业务编号（默认案件）
         * Default:
         * Nullable:False
         */
        relationID?: string
        /**
         * Desc:冲突产生时关联业务状态
         * Default:
         * Nullable:True
         */
        relationStatus?: string
        /**
         * Desc:匹配客户名称
         * Default:
         * Nullable:True
         */
        clientName?: string
        /**
         * Desc:匹配客户外文名称
         * Default:
         * Nullable:True
         */
        clientForeignName?: string
        /**
         * Desc:匹配客户实控人名称
         * Default:
         * Nullable:True
         */
        clientShareholderActualCtrlName?: string
        /**
         * Desc:匹配客户实控人外文名称
         * Default:
         * Nullable:True
         */
        clientShareholderActualCtrlForeignName?: string
        /**
         * Desc:匹配对方名称
         * Default:
         * Nullable:True
         */
        oppositeName?: string
        /**
         * Desc:匹配对方外文名称
         * Default:
         * Nullable:True
         */
        oppositeForeignName?: string
        /**
         * Desc:匹配对方实控人名称
         * Default:
         * Nullable:True
         */
        oppositeShareholderActualCtrlName?: string
        /**
         * Desc:匹配对方实控人外文名称
         * Default:
         * Nullable:True
         */
        oppositeShareholderActualCtrlForeignName?: string
        /**
         * Desc:潜在第三方名称
         * Default:
         * Nullable:True
         */
        potential3rdPartyName?: string
        /**
         * Desc:潜在第三方外文名称
         * Default:
         * Nullable:True
         */
        potential3rdPartyForeignName?: string
        /**
         * Desc:关联业务类型
         * Default:
         * Nullable:True
         */
        relationCategory?: string
        /**
         * Desc:关联业务名称
         * Default:
         * Nullable:True
         */
        relationName?: string
        /**
         * Desc:关联业务申请时间
         * Default:
         * Nullable:False
         * @format date-time
         */
        relationRequestTime?: string
        /**
         * Desc:关联业务主管律师
         * Default:
         * Nullable:True
         */
        relationInChargeLawyerID?: string
        /**
         * Desc:关联业务申请合伙人
         * Default:
         * Nullable:False
         */
        relationRequestUserID?: string
        /**
         * Desc:关联业务协办律师
         * Default:
         * Nullable:True
         */
        relationCoWorkingLawyerID?: string
        /**
         * Desc:关联业务账单律师
         * Default:
         * Nullable:True
         */
        relationBillingLawyerID?: string
        /**
         * Desc:规避匹配方式（包含/忽略）
         * Default:
         * Nullable:True
         */
        matchingMethod?: string
        /**
         * Desc:租户ID
         * Default:0
         * Nullable:False
         */
        tenantId?: string
        /**
         * Desc:部门ID  DepartmentId
         * Default:0
         * Nullable:False
         */
        organizationUnitId?: string
        /**
         * Desc:分所Id
         * Default:0
         * Nullable:False
         */
        officeId?: string
        /**
         * Desc:备注
         * Default:
         * Nullable:True
         */
        remark?: string
        /**
         * Desc:状态 	                中立字段，某些表可使用某些表不使用
         * Default:1
         * Nullable:False
         */
        isEnabled?: boolean
        /**
         * Desc:中立字段，某些表可使用某些表不使用   	                逻辑上的删除，非物理删除  	                例如：单据删除并非直接删除
         * Default:0
         * Nullable:False
         */
        isDeleted?: boolean
        /**
         * Desc:中立字段 	                是否内置数据
         * Default:
         * Nullable:False
         */
        isInternal?: boolean
        /**
         * Desc:创建ID
         * Default:
         * Nullable:True
         */
        createId?: string
        /**
         * Desc:创建者
         * Default:
         * Nullable:True
         */
        createBy?: string
        /**
         * Desc:创建时间
         * Default:
         * Nullable:False
         * @format date-time
         */
        createTime?: string
        /**
         * Desc:修改ID
         * Default:
         * Nullable:True
         */
        modifyId?: string
        /**
         * Desc:更新者
         * Default:
         * Nullable:True
         */
        modifyBy?: string
        /**
         * Desc:修改日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        modifyTime?: string
        /**
         * Desc:数据版本
         * Default:0
         * Nullable:False
         */
        version?: string
        /**
         * Desc:删除ID
         * Default:
         * Nullable:True
         */
        deleteId?: string
        /**
         * Desc:删除者
         * Default:
         * Nullable:True
         */
        deleteBy?: string
        /**
         * Desc:删除日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        deleteTime?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<CSRAvoidPreferenceResultDtoPageModelMessageModel, void>({
        path: `/api/web/business/csf/csravoidpreferenceresult/getpage/page`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CSRAvoidPreferenceResult
     * @name WebBusinessCsfCsravoidpreferenceresultListListList
     * @summary 列表获取所有 (Auth policies: Web)
     * @request GET:/api/web/business/csf/csravoidpreferenceresult/list/list
     * @secure
     */
    webBusinessCsfCsravoidpreferenceresultListListList: (
      query?: {
        /**
         * Desc:雪花Id
         * Default:
         * Nullable:False
         */
        id?: string
        /**
         * Desc:利冲规避编号
         * Default:
         * Nullable:False
         */
        cSRAvoidID?: string
        /**
         * Desc:利冲规避关键词
         * Default:
         * Nullable:True
         */
        avoidKeyword?: string
        /**
         * Desc:关键词隶属规避方角色
         * Default:
         * Nullable:True
         */
        partyType?: string
        /**
         * Desc:规避冲突案件的角色
         * Default:
         * Nullable:True
         */
        caseParty?: string
        /**
         * Desc:规避案件冲突方名称
         * Default:
         * Nullable:True
         */
        casePartyName?: string
        /**
         * Desc:规避利冲关联业务类型（利冲/案件/入库/投标等）
         * Default:
         * Nullable:True
         */
        cSRRelationType?: string
        /**
         * Desc:规避业务编号（默认案件）
         * Default:
         * Nullable:False
         */
        relationID?: string
        /**
         * Desc:冲突产生时关联业务状态
         * Default:
         * Nullable:True
         */
        relationStatus?: string
        /**
         * Desc:匹配客户名称
         * Default:
         * Nullable:True
         */
        clientName?: string
        /**
         * Desc:匹配客户外文名称
         * Default:
         * Nullable:True
         */
        clientForeignName?: string
        /**
         * Desc:匹配客户实控人名称
         * Default:
         * Nullable:True
         */
        clientShareholderActualCtrlName?: string
        /**
         * Desc:匹配客户实控人外文名称
         * Default:
         * Nullable:True
         */
        clientShareholderActualCtrlForeignName?: string
        /**
         * Desc:匹配对方名称
         * Default:
         * Nullable:True
         */
        oppositeName?: string
        /**
         * Desc:匹配对方外文名称
         * Default:
         * Nullable:True
         */
        oppositeForeignName?: string
        /**
         * Desc:匹配对方实控人名称
         * Default:
         * Nullable:True
         */
        oppositeShareholderActualCtrlName?: string
        /**
         * Desc:匹配对方实控人外文名称
         * Default:
         * Nullable:True
         */
        oppositeShareholderActualCtrlForeignName?: string
        /**
         * Desc:潜在第三方名称
         * Default:
         * Nullable:True
         */
        potential3rdPartyName?: string
        /**
         * Desc:潜在第三方外文名称
         * Default:
         * Nullable:True
         */
        potential3rdPartyForeignName?: string
        /**
         * Desc:关联业务类型
         * Default:
         * Nullable:True
         */
        relationCategory?: string
        /**
         * Desc:关联业务名称
         * Default:
         * Nullable:True
         */
        relationName?: string
        /**
         * Desc:关联业务申请时间
         * Default:
         * Nullable:False
         * @format date-time
         */
        relationRequestTime?: string
        /**
         * Desc:关联业务主管律师
         * Default:
         * Nullable:True
         */
        relationInChargeLawyerID?: string
        /**
         * Desc:关联业务申请合伙人
         * Default:
         * Nullable:False
         */
        relationRequestUserID?: string
        /**
         * Desc:关联业务协办律师
         * Default:
         * Nullable:True
         */
        relationCoWorkingLawyerID?: string
        /**
         * Desc:关联业务账单律师
         * Default:
         * Nullable:True
         */
        relationBillingLawyerID?: string
        /**
         * Desc:规避匹配方式（包含/忽略）
         * Default:
         * Nullable:True
         */
        matchingMethod?: string
        /**
         * Desc:租户ID
         * Default:0
         * Nullable:False
         */
        tenantId?: string
        /**
         * Desc:部门ID  DepartmentId
         * Default:0
         * Nullable:False
         */
        organizationUnitId?: string
        /**
         * Desc:分所Id
         * Default:0
         * Nullable:False
         */
        officeId?: string
        /**
         * Desc:备注
         * Default:
         * Nullable:True
         */
        remark?: string
        /**
         * Desc:状态 	                中立字段，某些表可使用某些表不使用
         * Default:1
         * Nullable:False
         */
        isEnabled?: boolean
        /**
         * Desc:中立字段，某些表可使用某些表不使用   	                逻辑上的删除，非物理删除  	                例如：单据删除并非直接删除
         * Default:0
         * Nullable:False
         */
        isDeleted?: boolean
        /**
         * Desc:中立字段 	                是否内置数据
         * Default:
         * Nullable:False
         */
        isInternal?: boolean
        /**
         * Desc:创建ID
         * Default:
         * Nullable:True
         */
        createId?: string
        /**
         * Desc:创建者
         * Default:
         * Nullable:True
         */
        createBy?: string
        /**
         * Desc:创建时间
         * Default:
         * Nullable:False
         * @format date-time
         */
        createTime?: string
        /**
         * Desc:修改ID
         * Default:
         * Nullable:True
         */
        modifyId?: string
        /**
         * Desc:更新者
         * Default:
         * Nullable:True
         */
        modifyBy?: string
        /**
         * Desc:修改日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        modifyTime?: string
        /**
         * Desc:数据版本
         * Default:0
         * Nullable:False
         */
        version?: string
        /**
         * Desc:删除ID
         * Default:
         * Nullable:True
         */
        deleteId?: string
        /**
         * Desc:删除者
         * Default:
         * Nullable:True
         */
        deleteBy?: string
        /**
         * Desc:删除日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        deleteTime?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<CSRAvoidPreferenceResultDtoListMessageModel, void>({
        path: `/api/web/business/csf/csravoidpreferenceresult/list/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CSRAvoidPreferenceResult
     * @name WebBusinessCsfCsravoidpreferenceresultGetDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/business/csf/csravoidpreferenceresult/get/{id}
     * @secure
     */
    webBusinessCsfCsravoidpreferenceresultGetDetail: (id: string, params: RequestParams = {}) =>
      this.request<CSRAvoidPreferenceResultDtoMessageModel, void>({
        path: `/api/web/business/csf/csravoidpreferenceresult/get/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CSRAvoidPreferenceResult
     * @name WebBusinessCsfCsravoidpreferenceresultPostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/business/csf/csravoidpreferenceresult/post
     * @secure
     */
    webBusinessCsfCsravoidpreferenceresultPostCreate: (data: CreateCSRAvoidPreferenceResultInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/csf/csravoidpreferenceresult/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CSRAvoidPreferenceResult
     * @name WebBusinessCsfCsravoidpreferenceresultPutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/business/csf/csravoidpreferenceresult/put/{id}
     * @secure
     */
    webBusinessCsfCsravoidpreferenceresultPutUpdate: (id: string, data: CreateCSRAvoidPreferenceResultInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/csf/csravoidpreferenceresult/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CSRAvoidPreferenceResult
     * @name WebBusinessCsfCsravoidpreferenceresultDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/business/csf/csravoidpreferenceresult/delete/{id}
     * @secure
     */
    webBusinessCsfCsravoidpreferenceresultDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/csf/csravoidpreferenceresult/delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CSRExemption
     * @name WebBusinessCsfCsrexemptionGetpagePageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/business/csf/csrexemption/getpage/page
     * @secure
     */
    webBusinessCsfCsrexemptionGetpagePageCreate: (
      query?: {
        /**
         * Desc:雪花Id
         * Default:
         * Nullable:False
         */
        id?: string
        /**
         * Desc:利冲编号
         * Default:
         * Nullable:False
         */
        cSRID?: string
        /**
         * Desc:利冲关键词
         * Default:
         * Nullable:True
         */
        cSRKeyword?: string
        /**
         * Desc:与我冲突案件的角色（冲突方）
         * Default:
         * Nullable:True
         */
        cSRParty?: string
        /**
         * Desc:我的案件冲突角色
         * Default:
         * Nullable:True
         */
        caseParty?: string
        /**
         * Desc:我的案件冲突方名称
         * Default:
         * Nullable:True
         */
        casePartyName?: string
        /**
         * Desc:主管律师
         * Default:
         * Nullable:False
         */
        inChargeLawyer?: string
        /**
         * Desc:利冲关联业务类型（利冲/案件/入库/投标等）
         * Default:
         * Nullable:True
         */
        cSRRelationType?: string
        /**
         * Desc:关联业务编号（默认案件）
         * Default:
         * Nullable:False
         */
        relationID?: string
        /**
         * Desc:冲突产生时关联业务状态
         * Default:
         * Nullable:True
         */
        relationStatus?: string
        /**
         * Desc:与我冲突业务编号合计
         * Default:
         * Nullable:True
         */
        cSRRelationIDs?: string
        /**
         * Desc:利冲确认发送人编号
         * Default:
         * Nullable:False
         */
        cSRSendUserID?: string
        /**
         * Desc:利冲确认发送时间
         * Default:
         * Nullable:False
         * @format date-time
         */
        cSRSendTime?: string
        /**
         * Desc:利冲豁免确认时间
         * Default:
         * Nullable:False
         * @format date-time
         */
        confirmTime?: string
        /**
         * Desc:豁免文件
         * Default:
         * Nullable:True
         */
        attachments?: string
        /**
         * Desc:说明描述
         * Default:
         * Nullable:True
         */
        narrative?: string
        /**
         * Desc:租户ID
         * Default:0
         * Nullable:False
         */
        tenantId?: string
        /**
         * Desc:部门ID  DepartmentId
         * Default:0
         * Nullable:False
         */
        organizationUnitId?: string
        /**
         * Desc:分所Id
         * Default:0
         * Nullable:False
         */
        officeId?: string
        /**
         * Desc:备注
         * Default:
         * Nullable:True
         */
        remark?: string
        /**
         * Desc:状态 	                中立字段，某些表可使用某些表不使用
         * Default:1
         * Nullable:False
         */
        isEnabled?: boolean
        /**
         * Desc:中立字段，某些表可使用某些表不使用   	                逻辑上的删除，非物理删除  	                例如：单据删除并非直接删除
         * Default:0
         * Nullable:False
         */
        isDeleted?: boolean
        /**
         * Desc:中立字段 	                是否内置数据
         * Default:
         * Nullable:False
         */
        isInternal?: boolean
        /**
         * Desc:创建ID
         * Default:
         * Nullable:True
         */
        createId?: string
        /**
         * Desc:创建者
         * Default:
         * Nullable:True
         */
        createBy?: string
        /**
         * Desc:创建时间
         * Default:
         * Nullable:False
         * @format date-time
         */
        createTime?: string
        /**
         * Desc:修改ID
         * Default:
         * Nullable:True
         */
        modifyId?: string
        /**
         * Desc:更新者
         * Default:
         * Nullable:True
         */
        modifyBy?: string
        /**
         * Desc:修改日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        modifyTime?: string
        /**
         * Desc:数据版本
         * Default:0
         * Nullable:False
         */
        version?: string
        /**
         * Desc:删除ID
         * Default:
         * Nullable:True
         */
        deleteId?: string
        /**
         * Desc:删除者
         * Default:
         * Nullable:True
         */
        deleteBy?: string
        /**
         * Desc:删除日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        deleteTime?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<CSRExemptionDtoPageModelMessageModel, void>({
        path: `/api/web/business/csf/csrexemption/getpage/page`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CSRExemption
     * @name WebBusinessCsfCsrexemptionListListList
     * @summary 列表获取所有 (Auth policies: Web)
     * @request GET:/api/web/business/csf/csrexemption/list/list
     * @secure
     */
    webBusinessCsfCsrexemptionListListList: (
      query?: {
        /**
         * Desc:雪花Id
         * Default:
         * Nullable:False
         */
        id?: string
        /**
         * Desc:利冲编号
         * Default:
         * Nullable:False
         */
        cSRID?: string
        /**
         * Desc:利冲关键词
         * Default:
         * Nullable:True
         */
        cSRKeyword?: string
        /**
         * Desc:与我冲突案件的角色（冲突方）
         * Default:
         * Nullable:True
         */
        cSRParty?: string
        /**
         * Desc:我的案件冲突角色
         * Default:
         * Nullable:True
         */
        caseParty?: string
        /**
         * Desc:我的案件冲突方名称
         * Default:
         * Nullable:True
         */
        casePartyName?: string
        /**
         * Desc:主管律师
         * Default:
         * Nullable:False
         */
        inChargeLawyer?: string
        /**
         * Desc:利冲关联业务类型（利冲/案件/入库/投标等）
         * Default:
         * Nullable:True
         */
        cSRRelationType?: string
        /**
         * Desc:关联业务编号（默认案件）
         * Default:
         * Nullable:False
         */
        relationID?: string
        /**
         * Desc:冲突产生时关联业务状态
         * Default:
         * Nullable:True
         */
        relationStatus?: string
        /**
         * Desc:与我冲突业务编号合计
         * Default:
         * Nullable:True
         */
        cSRRelationIDs?: string
        /**
         * Desc:利冲确认发送人编号
         * Default:
         * Nullable:False
         */
        cSRSendUserID?: string
        /**
         * Desc:利冲确认发送时间
         * Default:
         * Nullable:False
         * @format date-time
         */
        cSRSendTime?: string
        /**
         * Desc:利冲豁免确认时间
         * Default:
         * Nullable:False
         * @format date-time
         */
        confirmTime?: string
        /**
         * Desc:豁免文件
         * Default:
         * Nullable:True
         */
        attachments?: string
        /**
         * Desc:说明描述
         * Default:
         * Nullable:True
         */
        narrative?: string
        /**
         * Desc:租户ID
         * Default:0
         * Nullable:False
         */
        tenantId?: string
        /**
         * Desc:部门ID  DepartmentId
         * Default:0
         * Nullable:False
         */
        organizationUnitId?: string
        /**
         * Desc:分所Id
         * Default:0
         * Nullable:False
         */
        officeId?: string
        /**
         * Desc:备注
         * Default:
         * Nullable:True
         */
        remark?: string
        /**
         * Desc:状态 	                中立字段，某些表可使用某些表不使用
         * Default:1
         * Nullable:False
         */
        isEnabled?: boolean
        /**
         * Desc:中立字段，某些表可使用某些表不使用   	                逻辑上的删除，非物理删除  	                例如：单据删除并非直接删除
         * Default:0
         * Nullable:False
         */
        isDeleted?: boolean
        /**
         * Desc:中立字段 	                是否内置数据
         * Default:
         * Nullable:False
         */
        isInternal?: boolean
        /**
         * Desc:创建ID
         * Default:
         * Nullable:True
         */
        createId?: string
        /**
         * Desc:创建者
         * Default:
         * Nullable:True
         */
        createBy?: string
        /**
         * Desc:创建时间
         * Default:
         * Nullable:False
         * @format date-time
         */
        createTime?: string
        /**
         * Desc:修改ID
         * Default:
         * Nullable:True
         */
        modifyId?: string
        /**
         * Desc:更新者
         * Default:
         * Nullable:True
         */
        modifyBy?: string
        /**
         * Desc:修改日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        modifyTime?: string
        /**
         * Desc:数据版本
         * Default:0
         * Nullable:False
         */
        version?: string
        /**
         * Desc:删除ID
         * Default:
         * Nullable:True
         */
        deleteId?: string
        /**
         * Desc:删除者
         * Default:
         * Nullable:True
         */
        deleteBy?: string
        /**
         * Desc:删除日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        deleteTime?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<CSRExemptionDtoListMessageModel, void>({
        path: `/api/web/business/csf/csrexemption/list/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CSRExemption
     * @name WebBusinessCsfCsrexemptionGetDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/business/csf/csrexemption/get/{id}
     * @secure
     */
    webBusinessCsfCsrexemptionGetDetail: (id: string, params: RequestParams = {}) =>
      this.request<CSRExemptionDtoMessageModel, void>({
        path: `/api/web/business/csf/csrexemption/get/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CSRExemption
     * @name WebBusinessCsfCsrexemptionPostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/business/csf/csrexemption/post
     * @secure
     */
    webBusinessCsfCsrexemptionPostCreate: (data: CreateCSRExemptionInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/csf/csrexemption/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CSRExemption
     * @name WebBusinessCsfCsrexemptionPutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/business/csf/csrexemption/put/{id}
     * @secure
     */
    webBusinessCsfCsrexemptionPutUpdate: (id: string, data: CreateCSRExemptionInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/csf/csrexemption/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CSRExemption
     * @name WebBusinessCsfCsrexemptionDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/business/csf/csrexemption/delete/{id}
     * @secure
     */
    webBusinessCsfCsrexemptionDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/csf/csrexemption/delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CSRPreProcess
     * @name WebBusinessCsfCsrpreprocessListcsrprocessListcsrprocessList
     * @summary 利冲预检-检索条件 (Auth policies: Web)
     * @request GET:/api/web/business/csf/csrpreprocess/listcsrprocess/listcsrprocess
     * @secure
     */
    webBusinessCsfCsrpreprocessListcsrprocessListcsrprocessList: (data: GetCSRSearchPreProcessInput, params: RequestParams = {}) =>
      this.request<CSRPreProcessResultDtoPageModelMessageModel, void>({
        path: `/api/web/business/csf/csrpreprocess/listcsrprocess/listcsrprocess`,
        method: 'GET',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CSRPreProcess
     * @name WebBusinessCsfCsrpreprocessGetsegmentList
     * @summary 利冲预检-获取分词 (Auth policies: Web)
     * @request GET:/api/web/business/csf/csrpreprocess/getsegment
     * @secure
     */
    webBusinessCsfCsrpreprocessGetsegmentList: (
      query?: {
        word?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<StringListMessageModel, void>({
        path: `/api/web/business/csf/csrpreprocess/getsegment`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CSRPreProcessResult
     * @name WebBusinessCsfCsrpreprocessresultGetpagePageCreate
     * @summary 检索结果列表 (Auth policies: Web)
     * @request POST:/api/web/business/csf/csrpreprocessresult/getpage/page
     * @secure
     */
    webBusinessCsfCsrpreprocessresultGetpagePageCreate: (
      query?: {
        /** 案件状态 */
        relationStatus?: string
        /**
         * 立案时间
         * @format date-time
         */
        relationRequestTime?: string
        /** 主办合伙人 */
        relationRequestUserID?: string
        /** 案源人 */
        relationCaseUser?: string
        /** 账单管理人 */
        relationInChargeLawyerID?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<CSRPreProcessResultDtoPageModelMessageModel, void>({
        path: `/api/web/business/csf/csrpreprocessresult/getpage/page`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CSRProcess
     * @name WebBusinessCsfCsrprocessGetpagePageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/business/csf/csrprocess/getpage/page
     * @secure
     */
    webBusinessCsfCsrprocessGetpagePageCreate: (
      query?: {
        /** @format int64 */
        cSRID?: number
        /**
         * Desc:检索模式（精确，模糊）
         * Default:
         * Nullable:True
         */
        searchMode?: string
        /**
         * Desc:我方名称
         * Default:
         * Nullable:True
         */
        ourPatryName?: string
        /**
         * Desc:我方外文名称
         * Default:
         * Nullable:True
         */
        ourPatryForeignName?: string
        /**
         * Desc:对方名称
         * Default:
         * Nullable:True
         */
        oppositeName?: string
        /**
         * Desc:对方外文名称
         * Default:
         * Nullable:True
         */
        oppositeForeignName?: string
        /**
         * Desc:潜在三方名称
         * Default:
         * Nullable:True
         */
        potential3rdPartyName?: string
        /**
         * Desc:潜在三方外文名称
         * Default:
         * Nullable:True
         */
        potential3rdPartyForeignName?: string
        /**
         * Desc:是否分词检索
         * Default:
         * Nullable:True
         */
        isSplitWordSearch?: string
        /**
         * Desc:是否外文分词检索
         * Default:
         * Nullable:True
         */
        isForeignSplitWordSearch?: string
        /**
         * Desc:匹配结果数量
         * Default:
         * Nullable:False
         * @format double
         */
        matchingCount?: number
        /**
         * Desc:需要豁免案件编号
         * Default:
         * Nullable:True
         */
        needExemptionCaseID?: string
        /**
         * Desc:利冲审查人编号
         * Default:
         * Nullable:False
         * @format int64
         */
        processUserID?: number
        /**
         * Desc:利冲审查时间
         * Default:
         * Nullable:False
         * @format date-time
         */
        processTime?: string
        /**
         * Desc:说明描述
         * Default:
         * Nullable:True
         */
        narrative?: string
        /**
         * Desc:备注
         * Default:
         * Nullable:True
         */
        remark?: string
        /**
         * Desc:创建ID
         * Default:
         * Nullable:True
         * @format int64
         */
        createId?: number
        /**
         * Desc:创建者
         * Default:
         * Nullable:True
         */
        createBy?: string
        /**
         * Desc:创建时间
         * Default:
         * Nullable:False
         * @format date-time
         */
        createTime?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<CSRProcessDtoPageModelMessageModel, void>({
        path: `/api/web/business/csf/csrprocess/getpage/page`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CSRProcess
     * @name WebBusinessCsfCsrprocessListListList
     * @summary 列表获取所有 (Auth policies: Web)
     * @request GET:/api/web/business/csf/csrprocess/list/list
     * @secure
     */
    webBusinessCsfCsrprocessListListList: (
      query?: {
        /** @format int64 */
        cSRID?: number
        /**
         * Desc:检索模式（精确，模糊）
         * Default:
         * Nullable:True
         */
        searchMode?: string
        /**
         * Desc:我方名称
         * Default:
         * Nullable:True
         */
        ourPatryName?: string
        /**
         * Desc:我方外文名称
         * Default:
         * Nullable:True
         */
        ourPatryForeignName?: string
        /**
         * Desc:对方名称
         * Default:
         * Nullable:True
         */
        oppositeName?: string
        /**
         * Desc:对方外文名称
         * Default:
         * Nullable:True
         */
        oppositeForeignName?: string
        /**
         * Desc:潜在三方名称
         * Default:
         * Nullable:True
         */
        potential3rdPartyName?: string
        /**
         * Desc:潜在三方外文名称
         * Default:
         * Nullable:True
         */
        potential3rdPartyForeignName?: string
        /**
         * Desc:是否分词检索
         * Default:
         * Nullable:True
         */
        isSplitWordSearch?: string
        /**
         * Desc:是否外文分词检索
         * Default:
         * Nullable:True
         */
        isForeignSplitWordSearch?: string
        /**
         * Desc:匹配结果数量
         * Default:
         * Nullable:False
         * @format double
         */
        matchingCount?: number
        /**
         * Desc:需要豁免案件编号
         * Default:
         * Nullable:True
         */
        needExemptionCaseID?: string
        /**
         * Desc:利冲审查人编号
         * Default:
         * Nullable:False
         * @format int64
         */
        processUserID?: number
        /**
         * Desc:利冲审查时间
         * Default:
         * Nullable:False
         * @format date-time
         */
        processTime?: string
        /**
         * Desc:说明描述
         * Default:
         * Nullable:True
         */
        narrative?: string
        /**
         * Desc:备注
         * Default:
         * Nullable:True
         */
        remark?: string
        /**
         * Desc:创建ID
         * Default:
         * Nullable:True
         * @format int64
         */
        createId?: number
        /**
         * Desc:创建者
         * Default:
         * Nullable:True
         */
        createBy?: string
        /**
         * Desc:创建时间
         * Default:
         * Nullable:False
         * @format date-time
         */
        createTime?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<CSRProcessDtoListMessageModel, void>({
        path: `/api/web/business/csf/csrprocess/list/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CSRProcess
     * @name WebBusinessCsfCsrprocessGetDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/business/csf/csrprocess/get/{id}
     * @secure
     */
    webBusinessCsfCsrprocessGetDetail: (id: string, params: RequestParams = {}) =>
      this.request<CSRProcessDtoMessageModel, void>({
        path: `/api/web/business/csf/csrprocess/get/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CSRProcessResult
     * @name WebBusinessCsfCsrprocessresultGetpagePageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/business/csf/csrprocessresult/getpage/page
     * @secure
     */
    webBusinessCsfCsrprocessresultGetpagePageCreate: (
      query?: {
        /**
         * Desc:雪花Id
         * Default:
         * Nullable:False
         */
        id?: string
        /**
         * Desc:利冲申请编号
         * Default:
         * Nullable:False
         */
        cSRID?: string
        /**
         * Desc:利冲审查编号
         * Default:
         * Nullable:False
         */
        cSRProcessID?: string
        /**
         * Desc:是否需要豁免
         * Default:
         * Nullable:True
         */
        isNeedExemption?: string
        /**
         * Desc:利冲关键词
         * Default:
         * Nullable:True
         */
        cSRKeyword?: string
        /**
         * Desc:与我冲突案件的角色
         * Default:
         * Nullable:True
         */
        cSRParty?: string
        /**
         * Desc:我被冲突案件的角色
         * Default:
         * Nullable:True
         */
        caseParty?: string
        /**
         * Desc:我的案件冲突方名称
         * Default:
         * Nullable:True
         */
        casePartyName?: string
        /**
         * Desc:利冲关联业务类型（利冲/案件/入库/投标等）
         * Default:
         * Nullable:True
         */
        cSRRelationType?: string
        /**
         * Desc:关联业务编号（默认案件）
         * Default:
         * Nullable:False
         */
        relationID?: string
        /**
         * Desc:冲突产生时关联业务状态
         * Default:
         * Nullable:True
         */
        relationStatus?: string
        /**
         * Desc:利冲豁免结果
         * Default:
         * Nullable:True
         */
        exemptionResult?: string
        /**
         * Desc:匹配利冲规则
         * Default:
         * Nullable:True
         */
        matchingRule?: string
        /**
         * Desc:匹配客户名称
         * Default:
         * Nullable:True
         */
        clientName?: string
        /**
         * Desc:匹配客户外文名称
         * Default:
         * Nullable:True
         */
        clientForeignName?: string
        /**
         * Desc:匹配客户实控人名称
         * Default:
         * Nullable:True
         */
        clientShareholderActualCtrlName?: string
        /**
         * Desc:匹配客户实控人外文名称
         * Default:
         * Nullable:True
         */
        clientShareholderActualCtrlForeignName?: string
        /**
         * Desc:匹配对方名称
         * Default:
         * Nullable:True
         */
        oppositeName?: string
        /**
         * Desc:匹配对方外文名称
         * Default:
         * Nullable:True
         */
        oppositeForeignName?: string
        /**
         * Desc:匹配对方实控人名称
         * Default:
         * Nullable:True
         */
        oppositeShareholderActualCtrlName?: string
        /**
         * Desc:匹配对方实控人外文名称
         * Default:
         * Nullable:True
         */
        oppositeShareholderActualCtrlForeignName?: string
        /**
         * Desc:潜在第三方名称
         * Default:
         * Nullable:True
         */
        potential3rdPartyName?: string
        /**
         * Desc:潜在第三方外文名称
         * Default:
         * Nullable:True
         */
        potential3rdPartyForeignName?: string
        /**
         * Desc:关联业务类型
         * Default:
         * Nullable:True
         */
        relationCategory?: string
        /**
         * Desc:关联业务名称
         * Default:
         * Nullable:True
         */
        relationName?: string
        /**
         * Desc:关联业务申请时间
         * Default:
         * Nullable:False
         * @format date-time
         */
        relationRequestTime?: string
        /**
         * Desc:关联业务主管律师
         * Default:
         * Nullable:False
         */
        relationInChargeLawyerID?: string
        /**
         * Desc:关联业务申请合伙人
         * Default:
         * Nullable:False
         */
        relationRequestUserID?: string
        /**
         * Desc:租户ID
         * Default:0
         * Nullable:False
         */
        tenantId?: string
        /**
         * Desc:部门ID  DepartmentId
         * Default:0
         * Nullable:False
         */
        organizationUnitId?: string
        /**
         * Desc:分所Id
         * Default:0
         * Nullable:False
         */
        officeId?: string
        /**
         * Desc:备注
         * Default:
         * Nullable:True
         */
        remark?: string
        /**
         * Desc:状态 	                中立字段，某些表可使用某些表不使用
         * Default:1
         * Nullable:False
         */
        isEnabled?: boolean
        /**
         * Desc:中立字段，某些表可使用某些表不使用   	                逻辑上的删除，非物理删除  	                例如：单据删除并非直接删除
         * Default:0
         * Nullable:False
         */
        isDeleted?: boolean
        /**
         * Desc:中立字段 	                是否内置数据
         * Default:
         * Nullable:False
         */
        isInternal?: boolean
        /**
         * Desc:创建ID
         * Default:
         * Nullable:True
         */
        createId?: string
        /**
         * Desc:创建者
         * Default:
         * Nullable:True
         */
        createBy?: string
        /**
         * Desc:创建时间
         * Default:
         * Nullable:False
         * @format date-time
         */
        createTime?: string
        /**
         * Desc:修改ID
         * Default:
         * Nullable:True
         */
        modifyId?: string
        /**
         * Desc:更新者
         * Default:
         * Nullable:True
         */
        modifyBy?: string
        /**
         * Desc:修改日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        modifyTime?: string
        /**
         * Desc:数据版本
         * Default:0
         * Nullable:False
         */
        version?: string
        /**
         * Desc:删除ID
         * Default:
         * Nullable:True
         */
        deleteId?: string
        /**
         * Desc:删除者
         * Default:
         * Nullable:True
         */
        deleteBy?: string
        /**
         * Desc:删除日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        deleteTime?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<CSRProcessResultDtoPageModelMessageModel, void>({
        path: `/api/web/business/csf/csrprocessresult/getpage/page`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CSRProcessResult
     * @name WebBusinessCsfCsrprocessresultListListList
     * @summary 列表获取所有 (Auth policies: Web)
     * @request GET:/api/web/business/csf/csrprocessresult/list/list
     * @secure
     */
    webBusinessCsfCsrprocessresultListListList: (
      query?: {
        /**
         * Desc:雪花Id
         * Default:
         * Nullable:False
         */
        id?: string
        /**
         * Desc:利冲申请编号
         * Default:
         * Nullable:False
         */
        cSRID?: string
        /**
         * Desc:利冲审查编号
         * Default:
         * Nullable:False
         */
        cSRProcessID?: string
        /**
         * Desc:是否需要豁免
         * Default:
         * Nullable:True
         */
        isNeedExemption?: string
        /**
         * Desc:利冲关键词
         * Default:
         * Nullable:True
         */
        cSRKeyword?: string
        /**
         * Desc:与我冲突案件的角色
         * Default:
         * Nullable:True
         */
        cSRParty?: string
        /**
         * Desc:我被冲突案件的角色
         * Default:
         * Nullable:True
         */
        caseParty?: string
        /**
         * Desc:我的案件冲突方名称
         * Default:
         * Nullable:True
         */
        casePartyName?: string
        /**
         * Desc:利冲关联业务类型（利冲/案件/入库/投标等）
         * Default:
         * Nullable:True
         */
        cSRRelationType?: string
        /**
         * Desc:关联业务编号（默认案件）
         * Default:
         * Nullable:False
         */
        relationID?: string
        /**
         * Desc:冲突产生时关联业务状态
         * Default:
         * Nullable:True
         */
        relationStatus?: string
        /**
         * Desc:利冲豁免结果
         * Default:
         * Nullable:True
         */
        exemptionResult?: string
        /**
         * Desc:匹配利冲规则
         * Default:
         * Nullable:True
         */
        matchingRule?: string
        /**
         * Desc:匹配客户名称
         * Default:
         * Nullable:True
         */
        clientName?: string
        /**
         * Desc:匹配客户外文名称
         * Default:
         * Nullable:True
         */
        clientForeignName?: string
        /**
         * Desc:匹配客户实控人名称
         * Default:
         * Nullable:True
         */
        clientShareholderActualCtrlName?: string
        /**
         * Desc:匹配客户实控人外文名称
         * Default:
         * Nullable:True
         */
        clientShareholderActualCtrlForeignName?: string
        /**
         * Desc:匹配对方名称
         * Default:
         * Nullable:True
         */
        oppositeName?: string
        /**
         * Desc:匹配对方外文名称
         * Default:
         * Nullable:True
         */
        oppositeForeignName?: string
        /**
         * Desc:匹配对方实控人名称
         * Default:
         * Nullable:True
         */
        oppositeShareholderActualCtrlName?: string
        /**
         * Desc:匹配对方实控人外文名称
         * Default:
         * Nullable:True
         */
        oppositeShareholderActualCtrlForeignName?: string
        /**
         * Desc:潜在第三方名称
         * Default:
         * Nullable:True
         */
        potential3rdPartyName?: string
        /**
         * Desc:潜在第三方外文名称
         * Default:
         * Nullable:True
         */
        potential3rdPartyForeignName?: string
        /**
         * Desc:关联业务类型
         * Default:
         * Nullable:True
         */
        relationCategory?: string
        /**
         * Desc:关联业务名称
         * Default:
         * Nullable:True
         */
        relationName?: string
        /**
         * Desc:关联业务申请时间
         * Default:
         * Nullable:False
         * @format date-time
         */
        relationRequestTime?: string
        /**
         * Desc:关联业务主管律师
         * Default:
         * Nullable:False
         */
        relationInChargeLawyerID?: string
        /**
         * Desc:关联业务申请合伙人
         * Default:
         * Nullable:False
         */
        relationRequestUserID?: string
        /**
         * Desc:租户ID
         * Default:0
         * Nullable:False
         */
        tenantId?: string
        /**
         * Desc:部门ID  DepartmentId
         * Default:0
         * Nullable:False
         */
        organizationUnitId?: string
        /**
         * Desc:分所Id
         * Default:0
         * Nullable:False
         */
        officeId?: string
        /**
         * Desc:备注
         * Default:
         * Nullable:True
         */
        remark?: string
        /**
         * Desc:状态 	                中立字段，某些表可使用某些表不使用
         * Default:1
         * Nullable:False
         */
        isEnabled?: boolean
        /**
         * Desc:中立字段，某些表可使用某些表不使用   	                逻辑上的删除，非物理删除  	                例如：单据删除并非直接删除
         * Default:0
         * Nullable:False
         */
        isDeleted?: boolean
        /**
         * Desc:中立字段 	                是否内置数据
         * Default:
         * Nullable:False
         */
        isInternal?: boolean
        /**
         * Desc:创建ID
         * Default:
         * Nullable:True
         */
        createId?: string
        /**
         * Desc:创建者
         * Default:
         * Nullable:True
         */
        createBy?: string
        /**
         * Desc:创建时间
         * Default:
         * Nullable:False
         * @format date-time
         */
        createTime?: string
        /**
         * Desc:修改ID
         * Default:
         * Nullable:True
         */
        modifyId?: string
        /**
         * Desc:更新者
         * Default:
         * Nullable:True
         */
        modifyBy?: string
        /**
         * Desc:修改日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        modifyTime?: string
        /**
         * Desc:数据版本
         * Default:0
         * Nullable:False
         */
        version?: string
        /**
         * Desc:删除ID
         * Default:
         * Nullable:True
         */
        deleteId?: string
        /**
         * Desc:删除者
         * Default:
         * Nullable:True
         */
        deleteBy?: string
        /**
         * Desc:删除日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        deleteTime?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<CSRProcessResultDtoListMessageModel, void>({
        path: `/api/web/business/csf/csrprocessresult/list/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CSRProcessResult
     * @name WebBusinessCsfCsrprocessresultGetDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/business/csf/csrprocessresult/get/{id}
     * @secure
     */
    webBusinessCsfCsrprocessresultGetDetail: (id: string, params: RequestParams = {}) =>
      this.request<CSRProcessResultDtoMessageModel, void>({
        path: `/api/web/business/csf/csrprocessresult/get/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CSRProcessResult
     * @name WebBusinessCsfCsrprocessresultPostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/business/csf/csrprocessresult/post
     * @secure
     */
    webBusinessCsfCsrprocessresultPostCreate: (data: CreateCSRProcessResultInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/csf/csrprocessresult/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CSRProcessResult
     * @name WebBusinessCsfCsrprocessresultPutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/business/csf/csrprocessresult/put/{id}
     * @secure
     */
    webBusinessCsfCsrprocessresultPutUpdate: (id: string, data: CreateCSRProcessResultInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/csf/csrprocessresult/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CSRProcessResult
     * @name WebBusinessCsfCsrprocessresultDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/business/csf/csrprocessresult/delete/{id}
     * @secure
     */
    webBusinessCsfCsrprocessresultDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/csf/csrprocessresult/delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CSRRequest
     * @name WebBusinessCsfCsrrequestGetcsrrrequestpageCreate
     * @summary 利冲列表 (Auth policies: Web)
     * @request POST:/api/web/business/csf/csrrequest/getcsrrrequestpage
     * @secure
     */
    webBusinessCsfCsrrequestGetcsrrrequestpageCreate: (
      query?: {
        /** 雪花Id */
        id?: string
        /** 利冲案件名称 */
        name?: string
        /** 利冲案件类型 */
        category?: string
        /** 案由分类 */
        reason?: string
        /** 关联案件编号 */
        relationCaseID?: string
        /** 客户行业编号 */
        clientIndustryID?: string
        /** 执业领域 */
        practiceArea?: string
        /** 是否涉密 */
        isClassified?: string
        /** 是否紧急 */
        isUrgent?: string
        /** 收费方式（固定，小时，风险，免费代理） */
        chargingMethods?: string
        /** 收费方式文本 */
        chargingMethodText?: string
        /**
         * 合同金额（费用总额）
         * @format double
         */
        contractAmount?: number
        /** 委托事项 */
        entrustedMatters?: string
        /** 客户名称 */
        clientName?: string
        /** 客户外文名称 */
        clientForeignName?: string
        /** 客户股东实控人名称 */
        clientShareholderActualCtrlName?: string
        /** 客户股东实控人外文名称 */
        clientShareholderActualCtrlForeignName?: string
        /** 客户参与实体名称 */
        clientParticipatingEntityName?: string
        /** 客户参与实体外文名称 */
        clientParticipatingEntityForeignName?: string
        /** 对方当事人 */
        oppositeName?: string
        /** 对方当事人外文名称 */
        oppositeForeignName?: string
        /** 对方股东实控人名称 */
        oppositeShareholderActualCtrlName?: string
        /** 对方股东实控人外文名称 */
        oppositeShareholderActualCtrlForeignName?: string
        /** 对方参与实体名称 */
        oppositeParticipatingEntityName?: string
        /** 对方参与实体外文名称 */
        oppositeParticipatingEntityForeignName?: string
        /** 潜在第三方名称 */
        potential3rdPartyName?: string
        /** 潜在第三方外文名称 */
        potential3rdPartyForeignName?: string
        /** 潜在第三方股东实控人 */
        potential3rdPartyShareholderActualCtrlName?: string
        /** 潜在第三方股东实控人外文名称 */
        potential3rdPartyShareholderActualCtrlForeignName?: string
        /** 主管律师（主办律师） */
        inChargeLawyer?: string
        /** 协办律师（合作律师） */
        coWorkingLawyer?: string
        /** 案源律师 */
        caseSourceLawyer?: string
        /** 账单律师 */
        billingLawyer?: string
        /** 秘书助理（预留）;选择人则限定选择人和值班秘书可见，不选就全部助理和秘书可见 */
        assistantSecretary?: string
        /** 说明描述 */
        narrative?: string
        /** 利冲关联案件编号 */
        caseID?: string
        /** 利冲关联案件名称 */
        caseName?: string
        /** 利冲关联案件类型 */
        caseCategory?: string
        /** 利冲关联案件状态 */
        caseStatus?: string
        /** 豁免文件 */
        exemptionAttachments?: string
        /** 附件 */
        attachments?: string
        /** 是否标的 */
        isTarget?: string
        /**
         * 标的金额
         * @format double
         */
        targetAmount?: number
        /** 利冲适用规则 */
        cSRRule?: string
        /** 利冲时效是否过期 */
        isExpired?: string
        /**
         * 利冲审查进度
         * @format double
         */
        rateOfProgress?: number
        /**
         * 利冲时效剩余天数
         * @format double
         */
        remainingDays?: number
        /** 租户ID */
        tenantId?: string
        /** 部门ID  DepartmentId */
        organizationUnitId?: string
        /** 分所Id */
        officeId?: string
        /** 备注 */
        remark?: string
        /** 状态 	                中立字段，某些表可使用某些表不使用 */
        isEnabled?: boolean
        /** 中立字段，某些表可使用某些表不使用   	                逻辑上的删除，非物理删除  	                例如：单据删除并非直接删除 */
        isDeleted?: boolean
        /** 中立字段 	                是否内置数据 */
        isInternal?: boolean
        /** 创建ID */
        createId?: string
        /** 创建者 */
        createBy?: string
        /**
         * 创建时间
         * @format date-time
         */
        createTime?: string
        /** 修改ID */
        modifyId?: string
        /** 更新者 */
        modifyBy?: string
        /**
         * 修改日期
         * @format date-time
         */
        modifyTime?: string
        /** 数据版本 */
        version?: string
        /** 删除ID */
        deleteId?: string
        /** 删除者 */
        deleteBy?: string
        /**
         * 删除日期
         * @format date-time
         */
        deleteTime?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<CSRRequestDtoPageModelMessageModel, void>({
        path: `/api/web/business/csf/csrrequest/getcsrrrequestpage`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CSRRequest
     * @name WebBusinessCsfCsrrequestGetcsrrrequestandexemptionpageCreate
     * @summary 待我确认的利冲 (Auth policies: Web)
     * @request POST:/api/web/business/csf/csrrequest/getcsrrrequestandexemptionpage
     * @secure
     */
    webBusinessCsfCsrrequestGetcsrrrequestandexemptionpageCreate: (
      query?: {
        /** 雪花Id */
        id?: string
        /** 利冲案件名称 */
        name?: string
        /** 利冲案件类型 */
        category?: string
        /** 案由分类 */
        reason?: string
        /** 关联案件编号 */
        relationCaseID?: string
        /** 客户行业编号 */
        clientIndustryID?: string
        /** 执业领域 */
        practiceArea?: string
        /** 是否涉密 */
        isClassified?: string
        /** 是否紧急 */
        isUrgent?: string
        /** 收费方式（固定，小时，风险，免费代理） */
        chargingMethods?: string
        /** 收费方式文本 */
        chargingMethodText?: string
        /**
         * 合同金额（费用总额）
         * @format double
         */
        contractAmount?: number
        /** 委托事项 */
        entrustedMatters?: string
        /** 客户名称 */
        clientName?: string
        /** 客户外文名称 */
        clientForeignName?: string
        /** 客户股东实控人名称 */
        clientShareholderActualCtrlName?: string
        /** 客户股东实控人外文名称 */
        clientShareholderActualCtrlForeignName?: string
        /** 客户参与实体名称 */
        clientParticipatingEntityName?: string
        /** 客户参与实体外文名称 */
        clientParticipatingEntityForeignName?: string
        /** 对方当事人 */
        oppositeName?: string
        /** 对方当事人外文名称 */
        oppositeForeignName?: string
        /** 对方股东实控人名称 */
        oppositeShareholderActualCtrlName?: string
        /** 对方股东实控人外文名称 */
        oppositeShareholderActualCtrlForeignName?: string
        /** 对方参与实体名称 */
        oppositeParticipatingEntityName?: string
        /** 对方参与实体外文名称 */
        oppositeParticipatingEntityForeignName?: string
        /** 潜在第三方名称 */
        potential3rdPartyName?: string
        /** 潜在第三方外文名称 */
        potential3rdPartyForeignName?: string
        /** 潜在第三方股东实控人 */
        potential3rdPartyShareholderActualCtrlName?: string
        /** 潜在第三方股东实控人外文名称 */
        potential3rdPartyShareholderActualCtrlForeignName?: string
        /** 主管律师（主办律师） */
        inChargeLawyer?: string
        /** 协办律师（合作律师） */
        coWorkingLawyer?: string
        /** 案源律师 */
        caseSourceLawyer?: string
        /** 账单律师 */
        billingLawyer?: string
        /** 秘书助理（预留）;选择人则限定选择人和值班秘书可见，不选就全部助理和秘书可见 */
        assistantSecretary?: string
        /** 说明描述 */
        narrative?: string
        /** 利冲关联案件编号 */
        caseID?: string
        /** 利冲关联案件名称 */
        caseName?: string
        /** 利冲关联案件类型 */
        caseCategory?: string
        /** 利冲关联案件状态 */
        caseStatus?: string
        /** 豁免文件 */
        exemptionAttachments?: string
        /** 附件 */
        attachments?: string
        /** 是否标的 */
        isTarget?: string
        /**
         * 标的金额
         * @format double
         */
        targetAmount?: number
        /** 利冲适用规则 */
        cSRRule?: string
        /** 利冲时效是否过期 */
        isExpired?: string
        /**
         * 利冲审查进度
         * @format double
         */
        rateOfProgress?: number
        /**
         * 利冲时效剩余天数
         * @format double
         */
        remainingDays?: number
        /** 租户ID */
        tenantId?: string
        /** 部门ID  DepartmentId */
        organizationUnitId?: string
        /** 分所Id */
        officeId?: string
        /** 备注 */
        remark?: string
        /** 状态 	                中立字段，某些表可使用某些表不使用 */
        isEnabled?: boolean
        /** 中立字段，某些表可使用某些表不使用   	                逻辑上的删除，非物理删除  	                例如：单据删除并非直接删除 */
        isDeleted?: boolean
        /** 中立字段 	                是否内置数据 */
        isInternal?: boolean
        /** 创建ID */
        createId?: string
        /** 创建者 */
        createBy?: string
        /**
         * 创建时间
         * @format date-time
         */
        createTime?: string
        /** 修改ID */
        modifyId?: string
        /** 更新者 */
        modifyBy?: string
        /**
         * 修改日期
         * @format date-time
         */
        modifyTime?: string
        /** 数据版本 */
        version?: string
        /** 删除ID */
        deleteId?: string
        /** 删除者 */
        deleteBy?: string
        /**
         * 删除日期
         * @format date-time
         */
        deleteTime?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<CSRRequestDtoPageModelMessageModel, void>({
        path: `/api/web/business/csf/csrrequest/getcsrrrequestandexemptionpage`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CSRRequest
     * @name WebBusinessCsfCsrrequestListListList
     * @summary 列表获取所有 (Auth policies: Web)
     * @request GET:/api/web/business/csf/csrrequest/list/list
     * @secure
     */
    webBusinessCsfCsrrequestListListList: (
      query?: {
        /** 雪花Id */
        id?: string
        /** 利冲案件名称 */
        name?: string
        /** 利冲案件类型 */
        category?: string
        /** 案由分类 */
        reason?: string
        /** 关联案件编号 */
        relationCaseID?: string
        /** 客户行业编号 */
        clientIndustryID?: string
        /** 执业领域 */
        practiceArea?: string
        /** 是否涉密 */
        isClassified?: string
        /** 是否紧急 */
        isUrgent?: string
        /** 收费方式（固定，小时，风险，免费代理） */
        chargingMethods?: string
        /** 收费方式文本 */
        chargingMethodText?: string
        /**
         * 合同金额（费用总额）
         * @format double
         */
        contractAmount?: number
        /** 委托事项 */
        entrustedMatters?: string
        /** 客户名称 */
        clientName?: string
        /** 客户外文名称 */
        clientForeignName?: string
        /** 客户股东实控人名称 */
        clientShareholderActualCtrlName?: string
        /** 客户股东实控人外文名称 */
        clientShareholderActualCtrlForeignName?: string
        /** 客户参与实体名称 */
        clientParticipatingEntityName?: string
        /** 客户参与实体外文名称 */
        clientParticipatingEntityForeignName?: string
        /** 对方当事人 */
        oppositeName?: string
        /** 对方当事人外文名称 */
        oppositeForeignName?: string
        /** 对方股东实控人名称 */
        oppositeShareholderActualCtrlName?: string
        /** 对方股东实控人外文名称 */
        oppositeShareholderActualCtrlForeignName?: string
        /** 对方参与实体名称 */
        oppositeParticipatingEntityName?: string
        /** 对方参与实体外文名称 */
        oppositeParticipatingEntityForeignName?: string
        /** 潜在第三方名称 */
        potential3rdPartyName?: string
        /** 潜在第三方外文名称 */
        potential3rdPartyForeignName?: string
        /** 潜在第三方股东实控人 */
        potential3rdPartyShareholderActualCtrlName?: string
        /** 潜在第三方股东实控人外文名称 */
        potential3rdPartyShareholderActualCtrlForeignName?: string
        /** 主管律师（主办律师） */
        inChargeLawyer?: string
        /** 协办律师（合作律师） */
        coWorkingLawyer?: string
        /** 案源律师 */
        caseSourceLawyer?: string
        /** 账单律师 */
        billingLawyer?: string
        /** 秘书助理（预留）;选择人则限定选择人和值班秘书可见，不选就全部助理和秘书可见 */
        assistantSecretary?: string
        /** 说明描述 */
        narrative?: string
        /** 利冲关联案件编号 */
        caseID?: string
        /** 利冲关联案件名称 */
        caseName?: string
        /** 利冲关联案件类型 */
        caseCategory?: string
        /** 利冲关联案件状态 */
        caseStatus?: string
        /** 豁免文件 */
        exemptionAttachments?: string
        /** 附件 */
        attachments?: string
        /** 是否标的 */
        isTarget?: string
        /**
         * 标的金额
         * @format double
         */
        targetAmount?: number
        /** 利冲适用规则 */
        cSRRule?: string
        /** 利冲时效是否过期 */
        isExpired?: string
        /**
         * 利冲审查进度
         * @format double
         */
        rateOfProgress?: number
        /**
         * 利冲时效剩余天数
         * @format double
         */
        remainingDays?: number
        /** 租户ID */
        tenantId?: string
        /** 部门ID  DepartmentId */
        organizationUnitId?: string
        /** 分所Id */
        officeId?: string
        /** 备注 */
        remark?: string
        /** 状态 	                中立字段，某些表可使用某些表不使用 */
        isEnabled?: boolean
        /** 中立字段，某些表可使用某些表不使用   	                逻辑上的删除，非物理删除  	                例如：单据删除并非直接删除 */
        isDeleted?: boolean
        /** 中立字段 	                是否内置数据 */
        isInternal?: boolean
        /** 创建ID */
        createId?: string
        /** 创建者 */
        createBy?: string
        /**
         * 创建时间
         * @format date-time
         */
        createTime?: string
        /** 修改ID */
        modifyId?: string
        /** 更新者 */
        modifyBy?: string
        /**
         * 修改日期
         * @format date-time
         */
        modifyTime?: string
        /** 数据版本 */
        version?: string
        /** 删除ID */
        deleteId?: string
        /** 删除者 */
        deleteBy?: string
        /**
         * 删除日期
         * @format date-time
         */
        deleteTime?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<CSRRequestDtoListMessageModel, void>({
        path: `/api/web/business/csf/csrrequest/list/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CSRRequest
     * @name WebBusinessCsfCsrrequestGetDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/business/csf/csrrequest/get/{id}
     * @secure
     */
    webBusinessCsfCsrrequestGetDetail: (id: number, params: RequestParams = {}) =>
      this.request<CSRRequestDtoMessageModel, void>({
        path: `/api/web/business/csf/csrrequest/get/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CSRRequest
     * @name WebBusinessCsfCsrrequestCreatecsrrequestasynCreate
     * @summary 利冲申请添加或修改 (Auth policies: Web)
     * @request POST:/api/web/business/csf/csrrequest/createcsrrequestasyn
     * @secure
     */
    webBusinessCsfCsrrequestCreatecsrrequestasynCreate: (data: CreateCSRRequestInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/csf/csrrequest/createcsrrequestasyn`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CSRRequest
     * @name WebBusinessCsfCsrrequestPutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/business/csf/csrrequest/put/{id}
     * @secure
     */
    webBusinessCsfCsrrequestPutUpdate: (id: number, data: CreateCSRRequestInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/csf/csrrequest/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CSRRequest
     * @name WebBusinessCsfCsrrequestDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/business/csf/csrrequest/delete/{id}
     * @secure
     */
    webBusinessCsfCsrrequestDeleteDelete: (id: number, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/csf/csrrequest/delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomExcel
     * @name WebSysCustomexcelCreatetemplateruleCreate
     * @summary 新增显示创建客户模板规则 (Auth policies: Web)
     * @request POST:/api/web/sys/customexcel/createtemplaterule
     * @secure
     */
    webSysCustomexcelCreatetemplateruleCreate: (data: CreateEntityInfoInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/customexcel/createtemplaterule`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomExcel
     * @name WebSysCustomexcelCustomexceltemplateinitExcelTemplateList
     * @summary Excel导出 (Auth policies: Web)
     * @request GET:/api/web/sys/customexcel/customexceltemplateinit/excel/template
     * @secure
     */
    webSysCustomexcelCustomexceltemplateinitExcelTemplateList: (
      query?: {
        fileName?: string
        requestUrl?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<ExcelFileDtoMessageModel, void>({
        path: `/api/web/sys/customexcel/customexceltemplateinit/excel/template`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DingtalkDepartment
     * @name WebTeamworkDingtalkdepartmentGetpagePageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/teamwork/dingtalkdepartment/getpage/page
     * @secure
     */
    webTeamworkDingtalkdepartmentGetpagePageCreate: (
      query?: {
        /**
         * Desc:雪花Id
         * Default:
         * Nullable:False
         */
        id?: string
        /**
         * Desc:应用编号
         * Default:
         * Nullable:True
         */
        appId?: string
        /**
         * Desc:部门ID
         * Default:
         * Nullable:True
         */
        deptId?: string
        /**
         * Desc:部门名称
         * Default:
         * Nullable:True
         */
        name?: string
        /**
         * Desc:父部门Id
         * Default:
         * Nullable:True
         */
        parentId?: string
        /**
         * Desc:是否同步创建一个关联此部门的企业群
         * Default:
         * Nullable:True
         */
        createDeptGroup?: boolean
        /**
         * Desc:部门群已经创建后，有新人加入部门是否会自动加入该群
         * Default:
         * Nullable:True
         */
        autoAddUser?: boolean
        /**
         * Desc:部门标识字段
         * Default:
         * Nullable:True
         */
        sourceIdentifier?: string
        /**
         * Desc:是否默认同意加入该部门的申请
         * Default:
         * Nullable:True
         */
        autoApproveApply?: boolean
        /**
         * Desc:部门是否来自关联组织
         * Default:
         * Nullable:True
         */
        fromUnionOrg?: boolean
        /**
         * Desc:教育部门标签
         * Default:
         * Nullable:True
         */
        tags?: string
        /**
         * Desc:在父部门中的次序值
         * Default:
         * Nullable:True
         */
        order?: string
        /**
         * Desc:部门群ID
         * Default:
         * Nullable:True
         */
        deptGroupChatId?: string
        /**
         * Desc:部门群是否包含子部门
         * Default:
         * Nullable:True
         */
        groupContainSubDept?: boolean
        /**
         * Desc:租户ID
         * Default:0
         * Nullable:False
         */
        tenantId?: string
        /**
         * Desc:部门ID  DepartmentId
         * Default:0
         * Nullable:False
         */
        organizationUnitId?: string
        /**
         * Desc:分所Id
         * Default:0
         * Nullable:False
         */
        officeId?: string
        /**
         * Desc:备注
         * Default:
         * Nullable:True
         */
        remark?: string
        /**
         * Desc:状态 	            中立字段，某些表可使用某些表不使用
         * Default:1
         * Nullable:False
         */
        isEnabled?: boolean
        /**
         * Desc:中立字段，某些表可使用某些表不使用   	            逻辑上的删除，非物理删除  	            例如：单据删除并非直接删除
         * Default:0
         * Nullable:False
         */
        isDeleted?: boolean
        /**
         * Desc:中立字段 	            是否内置数据
         * Default:
         * Nullable:False
         */
        isInternal?: boolean
        /**
         * Desc:创建ID
         * Default:
         * Nullable:True
         */
        createId?: string
        /**
         * Desc:创建者
         * Default:
         * Nullable:True
         */
        createBy?: string
        /**
         * Desc:创建时间
         * Default:
         * Nullable:False
         * @format date-time
         */
        createTime?: string
        /**
         * Desc:修改ID
         * Default:
         * Nullable:True
         */
        modifyId?: string
        /**
         * Desc:更新者
         * Default:
         * Nullable:True
         */
        modifyBy?: string
        /**
         * Desc:修改日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        modifyTime?: string
        /**
         * Desc:数据版本
         * Default:0
         * Nullable:False
         */
        version?: string
        /**
         * Desc:删除ID
         * Default:
         * Nullable:True
         */
        deleteId?: string
        /**
         * Desc:删除者
         * Default:
         * Nullable:True
         */
        deleteBy?: string
        /**
         * Desc:删除日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        deleteTime?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<DingtalkDepartmentDtoPageModelMessageModel, void>({
        path: `/api/web/teamwork/dingtalkdepartment/getpage/page`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DingtalkDepartment
     * @name WebTeamworkDingtalkdepartmentListListList
     * @summary 列表获取所有 (Auth policies: Web)
     * @request GET:/api/web/teamwork/dingtalkdepartment/list/list
     * @secure
     */
    webTeamworkDingtalkdepartmentListListList: (
      query?: {
        /**
         * Desc:雪花Id
         * Default:
         * Nullable:False
         */
        id?: string
        /**
         * Desc:应用编号
         * Default:
         * Nullable:True
         */
        appId?: string
        /**
         * Desc:部门ID
         * Default:
         * Nullable:True
         */
        deptId?: string
        /**
         * Desc:部门名称
         * Default:
         * Nullable:True
         */
        name?: string
        /**
         * Desc:父部门Id
         * Default:
         * Nullable:True
         */
        parentId?: string
        /**
         * Desc:是否同步创建一个关联此部门的企业群
         * Default:
         * Nullable:True
         */
        createDeptGroup?: boolean
        /**
         * Desc:部门群已经创建后，有新人加入部门是否会自动加入该群
         * Default:
         * Nullable:True
         */
        autoAddUser?: boolean
        /**
         * Desc:部门标识字段
         * Default:
         * Nullable:True
         */
        sourceIdentifier?: string
        /**
         * Desc:是否默认同意加入该部门的申请
         * Default:
         * Nullable:True
         */
        autoApproveApply?: boolean
        /**
         * Desc:部门是否来自关联组织
         * Default:
         * Nullable:True
         */
        fromUnionOrg?: boolean
        /**
         * Desc:教育部门标签
         * Default:
         * Nullable:True
         */
        tags?: string
        /**
         * Desc:在父部门中的次序值
         * Default:
         * Nullable:True
         */
        order?: string
        /**
         * Desc:部门群ID
         * Default:
         * Nullable:True
         */
        deptGroupChatId?: string
        /**
         * Desc:部门群是否包含子部门
         * Default:
         * Nullable:True
         */
        groupContainSubDept?: boolean
        /**
         * Desc:租户ID
         * Default:0
         * Nullable:False
         */
        tenantId?: string
        /**
         * Desc:部门ID  DepartmentId
         * Default:0
         * Nullable:False
         */
        organizationUnitId?: string
        /**
         * Desc:分所Id
         * Default:0
         * Nullable:False
         */
        officeId?: string
        /**
         * Desc:备注
         * Default:
         * Nullable:True
         */
        remark?: string
        /**
         * Desc:状态 	            中立字段，某些表可使用某些表不使用
         * Default:1
         * Nullable:False
         */
        isEnabled?: boolean
        /**
         * Desc:中立字段，某些表可使用某些表不使用   	            逻辑上的删除，非物理删除  	            例如：单据删除并非直接删除
         * Default:0
         * Nullable:False
         */
        isDeleted?: boolean
        /**
         * Desc:中立字段 	            是否内置数据
         * Default:
         * Nullable:False
         */
        isInternal?: boolean
        /**
         * Desc:创建ID
         * Default:
         * Nullable:True
         */
        createId?: string
        /**
         * Desc:创建者
         * Default:
         * Nullable:True
         */
        createBy?: string
        /**
         * Desc:创建时间
         * Default:
         * Nullable:False
         * @format date-time
         */
        createTime?: string
        /**
         * Desc:修改ID
         * Default:
         * Nullable:True
         */
        modifyId?: string
        /**
         * Desc:更新者
         * Default:
         * Nullable:True
         */
        modifyBy?: string
        /**
         * Desc:修改日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        modifyTime?: string
        /**
         * Desc:数据版本
         * Default:0
         * Nullable:False
         */
        version?: string
        /**
         * Desc:删除ID
         * Default:
         * Nullable:True
         */
        deleteId?: string
        /**
         * Desc:删除者
         * Default:
         * Nullable:True
         */
        deleteBy?: string
        /**
         * Desc:删除日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        deleteTime?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<DingtalkDepartmentDtoListMessageModel, void>({
        path: `/api/web/teamwork/dingtalkdepartment/list/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DingtalkDepartment
     * @name WebTeamworkDingtalkdepartmentGetDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/teamwork/dingtalkdepartment/get/{id}
     * @secure
     */
    webTeamworkDingtalkdepartmentGetDetail: (id: string, params: RequestParams = {}) =>
      this.request<DingtalkDepartmentDtoMessageModel, void>({
        path: `/api/web/teamwork/dingtalkdepartment/get/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DingtalkDepartment
     * @name WebTeamworkDingtalkdepartmentPostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/teamwork/dingtalkdepartment/post
     * @secure
     */
    webTeamworkDingtalkdepartmentPostCreate: (data: CreateDingtalkDepartmentInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/teamwork/dingtalkdepartment/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DingtalkDepartment
     * @name WebTeamworkDingtalkdepartmentPutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/teamwork/dingtalkdepartment/put/{id}
     * @secure
     */
    webTeamworkDingtalkdepartmentPutUpdate: (id: string, data: CreateDingtalkDepartmentInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/teamwork/dingtalkdepartment/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DingtalkDepartment
     * @name WebTeamworkDingtalkdepartmentDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/teamwork/dingtalkdepartment/delete/{id}
     * @secure
     */
    webTeamworkDingtalkdepartmentDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/teamwork/dingtalkdepartment/delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DingtalkUser
     * @name WebTeamworkDingtalkuserGetpagePageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/teamwork/dingtalkuser/getpage/page
     * @secure
     */
    webTeamworkDingtalkuserGetpagePageCreate: (
      query?: {
        /**
         * Desc:雪花Id
         * Default:
         * Nullable:False
         */
        id?: string
        /**
         * Desc:应用编号
         * Default:
         * Nullable:False
         */
        appId?: string
        /**
         * Desc:BitzOrcas UserId
         * Default:
         * Nullable:False
         */
        relationUserId?: string
        /**
         * Desc:用户名
         * Default:
         * Nullable:True
         */
        name?: string
        /**
         * Desc:用户英文名称
         * Default:
         * Nullable:True
         */
        enName?: string
        /**
         * Desc:昵称
         * Default:
         * Nullable:True
         */
        nickName?: string
        /**
         * Desc:区域代码  +86
         * Default:
         * Nullable:True
         */
        stateCode?: string
        /**
         * Desc:邮箱地址
         * Default:
         * Nullable:True
         */
        email?: string
        /**
         * Desc:机构邮箱地址
         * Default:
         * Nullable:True
         */
        orgEmail?: string
        /**
         * Desc:手机号
         * Default:
         * Nullable:True
         */
        mobile?: string
        /**
         * Desc:是否显示手机号
         * Default:
         * Nullable:True
         */
        mobileVisible?: boolean
        /**
         * Desc:分机号
         * Default:
         * Nullable:True
         */
        telephone?: string
        /**
         * Desc:钉钉用户的open_id
         * Default:
         * Nullable:True
         */
        openId?: string
        /**
         * Desc:钉钉用户的union_id
         * Default:
         * Nullable:True
         */
        unionId?: string
        /**
         * Desc:钉钉用户的user_id
         * Default:
         * Nullable:True
         */
        userId?: string
        /**
         * Desc:性别
         * Default:
         * Nullable:True
         * @format int32
         */
        gender?: number
        /**
         * Desc:用户头像信息
         * Default:
         * Nullable:True
         */
        avatar?: string
        /**
         * Desc:员工工号
         * Default:
         * Nullable:True
         */
        jobNumber?: string
        /**
         * Desc:员工的直属主管
         * Default:
         * Nullable:True
         */
        managerUserId?: string
        /**
         * Desc:所属部门的Id列表
         * Default:
         * Nullable:True
         */
        departmentIds?: string
        /**
         * Desc:员工在对应的部门中的排序 json：dept_id,order
         * Default:
         * Nullable:True
         */
        deptOrderList?: string
        /**
         * Desc:员工所在部门信息及是否是领导 json:dept_id,leader
         * Default:
         * Nullable:True
         */
        leaderInDept?: string
        /**
         * Desc:工作地点
         * Default:
         * Nullable:True
         */
        workPlace?: string
        /**
         * Desc:入职时间
         * Default:
         * Nullable:True
         */
        hiredDate?: string
        /**
         * Desc:职务
         * Default:
         * Nullable:True
         */
        title?: string
        /**
         * Desc:是否为企业账号
         * Default:
         * Nullable:True
         */
        exclusiveAccount?: boolean
        /**
         * Desc:扩展属性，最大长度2000个字符。
         * Default:
         * Nullable:True
         */
        extension?: string
        /**
         * Desc:是否激活了钉钉
         * Default:
         * Nullable:True
         */
        active?: boolean
        /**
         * Desc:是否完成了实名认证
         * Default:
         * Nullable:True
         */
        realAuthed?: boolean
        /**
         * Desc:是否为企业高管
         * Default:
         * Nullable:True
         */
        senior?: boolean
        /**
         * Desc:是否为企业的管理员
         * Default:
         * Nullable:True
         */
        admin?: boolean
        /**
         * Desc:是否为企业的老板
         * Default:
         * Nullable:True
         */
        boss?: boolean
        /**
         * Desc:租户ID
         * Default:0
         * Nullable:False
         */
        tenantId?: string
        /**
         * Desc:部门ID  DepartmentId
         * Default:0
         * Nullable:False
         */
        organizationUnitId?: string
        /**
         * Desc:分所Id
         * Default:0
         * Nullable:False
         */
        officeId?: string
        /**
         * Desc:备注
         * Default:
         * Nullable:True
         */
        remark?: string
        /**
         * Desc:状态 	            中立字段，某些表可使用某些表不使用
         * Default:1
         * Nullable:False
         */
        isEnabled?: boolean
        /**
         * Desc:中立字段，某些表可使用某些表不使用   	            逻辑上的删除，非物理删除  	            例如：单据删除并非直接删除
         * Default:0
         * Nullable:False
         */
        isDeleted?: boolean
        /**
         * Desc:中立字段 	            是否内置数据
         * Default:
         * Nullable:False
         */
        isInternal?: boolean
        /**
         * Desc:创建ID
         * Default:
         * Nullable:True
         */
        createId?: string
        /**
         * Desc:创建者
         * Default:
         * Nullable:True
         */
        createBy?: string
        /**
         * Desc:创建时间
         * Default:
         * Nullable:False
         * @format date-time
         */
        createTime?: string
        /**
         * Desc:修改ID
         * Default:
         * Nullable:True
         */
        modifyId?: string
        /**
         * Desc:更新者
         * Default:
         * Nullable:True
         */
        modifyBy?: string
        /**
         * Desc:修改日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        modifyTime?: string
        /**
         * Desc:数据版本
         * Default:0
         * Nullable:False
         */
        version?: string
        /**
         * Desc:删除ID
         * Default:
         * Nullable:True
         */
        deleteId?: string
        /**
         * Desc:删除者
         * Default:
         * Nullable:True
         */
        deleteBy?: string
        /**
         * Desc:删除日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        deleteTime?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<DingtalkUserDtoPageModelMessageModel, void>({
        path: `/api/web/teamwork/dingtalkuser/getpage/page`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DingtalkUser
     * @name WebTeamworkDingtalkuserListListList
     * @summary 列表获取所有 (Auth policies: Web)
     * @request GET:/api/web/teamwork/dingtalkuser/list/list
     * @secure
     */
    webTeamworkDingtalkuserListListList: (
      query?: {
        /**
         * Desc:雪花Id
         * Default:
         * Nullable:False
         */
        id?: string
        /**
         * Desc:应用编号
         * Default:
         * Nullable:False
         */
        appId?: string
        /**
         * Desc:BitzOrcas UserId
         * Default:
         * Nullable:False
         */
        relationUserId?: string
        /**
         * Desc:用户名
         * Default:
         * Nullable:True
         */
        name?: string
        /**
         * Desc:用户英文名称
         * Default:
         * Nullable:True
         */
        enName?: string
        /**
         * Desc:昵称
         * Default:
         * Nullable:True
         */
        nickName?: string
        /**
         * Desc:区域代码  +86
         * Default:
         * Nullable:True
         */
        stateCode?: string
        /**
         * Desc:邮箱地址
         * Default:
         * Nullable:True
         */
        email?: string
        /**
         * Desc:机构邮箱地址
         * Default:
         * Nullable:True
         */
        orgEmail?: string
        /**
         * Desc:手机号
         * Default:
         * Nullable:True
         */
        mobile?: string
        /**
         * Desc:是否显示手机号
         * Default:
         * Nullable:True
         */
        mobileVisible?: boolean
        /**
         * Desc:分机号
         * Default:
         * Nullable:True
         */
        telephone?: string
        /**
         * Desc:钉钉用户的open_id
         * Default:
         * Nullable:True
         */
        openId?: string
        /**
         * Desc:钉钉用户的union_id
         * Default:
         * Nullable:True
         */
        unionId?: string
        /**
         * Desc:钉钉用户的user_id
         * Default:
         * Nullable:True
         */
        userId?: string
        /**
         * Desc:性别
         * Default:
         * Nullable:True
         * @format int32
         */
        gender?: number
        /**
         * Desc:用户头像信息
         * Default:
         * Nullable:True
         */
        avatar?: string
        /**
         * Desc:员工工号
         * Default:
         * Nullable:True
         */
        jobNumber?: string
        /**
         * Desc:员工的直属主管
         * Default:
         * Nullable:True
         */
        managerUserId?: string
        /**
         * Desc:所属部门的Id列表
         * Default:
         * Nullable:True
         */
        departmentIds?: string
        /**
         * Desc:员工在对应的部门中的排序 json：dept_id,order
         * Default:
         * Nullable:True
         */
        deptOrderList?: string
        /**
         * Desc:员工所在部门信息及是否是领导 json:dept_id,leader
         * Default:
         * Nullable:True
         */
        leaderInDept?: string
        /**
         * Desc:工作地点
         * Default:
         * Nullable:True
         */
        workPlace?: string
        /**
         * Desc:入职时间
         * Default:
         * Nullable:True
         */
        hiredDate?: string
        /**
         * Desc:职务
         * Default:
         * Nullable:True
         */
        title?: string
        /**
         * Desc:是否为企业账号
         * Default:
         * Nullable:True
         */
        exclusiveAccount?: boolean
        /**
         * Desc:扩展属性，最大长度2000个字符。
         * Default:
         * Nullable:True
         */
        extension?: string
        /**
         * Desc:是否激活了钉钉
         * Default:
         * Nullable:True
         */
        active?: boolean
        /**
         * Desc:是否完成了实名认证
         * Default:
         * Nullable:True
         */
        realAuthed?: boolean
        /**
         * Desc:是否为企业高管
         * Default:
         * Nullable:True
         */
        senior?: boolean
        /**
         * Desc:是否为企业的管理员
         * Default:
         * Nullable:True
         */
        admin?: boolean
        /**
         * Desc:是否为企业的老板
         * Default:
         * Nullable:True
         */
        boss?: boolean
        /**
         * Desc:租户ID
         * Default:0
         * Nullable:False
         */
        tenantId?: string
        /**
         * Desc:部门ID  DepartmentId
         * Default:0
         * Nullable:False
         */
        organizationUnitId?: string
        /**
         * Desc:分所Id
         * Default:0
         * Nullable:False
         */
        officeId?: string
        /**
         * Desc:备注
         * Default:
         * Nullable:True
         */
        remark?: string
        /**
         * Desc:状态 	            中立字段，某些表可使用某些表不使用
         * Default:1
         * Nullable:False
         */
        isEnabled?: boolean
        /**
         * Desc:中立字段，某些表可使用某些表不使用   	            逻辑上的删除，非物理删除  	            例如：单据删除并非直接删除
         * Default:0
         * Nullable:False
         */
        isDeleted?: boolean
        /**
         * Desc:中立字段 	            是否内置数据
         * Default:
         * Nullable:False
         */
        isInternal?: boolean
        /**
         * Desc:创建ID
         * Default:
         * Nullable:True
         */
        createId?: string
        /**
         * Desc:创建者
         * Default:
         * Nullable:True
         */
        createBy?: string
        /**
         * Desc:创建时间
         * Default:
         * Nullable:False
         * @format date-time
         */
        createTime?: string
        /**
         * Desc:修改ID
         * Default:
         * Nullable:True
         */
        modifyId?: string
        /**
         * Desc:更新者
         * Default:
         * Nullable:True
         */
        modifyBy?: string
        /**
         * Desc:修改日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        modifyTime?: string
        /**
         * Desc:数据版本
         * Default:0
         * Nullable:False
         */
        version?: string
        /**
         * Desc:删除ID
         * Default:
         * Nullable:True
         */
        deleteId?: string
        /**
         * Desc:删除者
         * Default:
         * Nullable:True
         */
        deleteBy?: string
        /**
         * Desc:删除日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        deleteTime?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<DingtalkUserDtoListMessageModel, void>({
        path: `/api/web/teamwork/dingtalkuser/list/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DingtalkUser
     * @name WebTeamworkDingtalkuserGetDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/teamwork/dingtalkuser/get/{id}
     * @secure
     */
    webTeamworkDingtalkuserGetDetail: (id: string, params: RequestParams = {}) =>
      this.request<DingtalkUserDtoMessageModel, void>({
        path: `/api/web/teamwork/dingtalkuser/get/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DingtalkUser
     * @name WebTeamworkDingtalkuserPostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/teamwork/dingtalkuser/post
     * @secure
     */
    webTeamworkDingtalkuserPostCreate: (data: CreateDingtalkUserInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/teamwork/dingtalkuser/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DingtalkUser
     * @name WebTeamworkDingtalkuserPutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/teamwork/dingtalkuser/put/{id}
     * @secure
     */
    webTeamworkDingtalkuserPutUpdate: (id: string, data: CreateDingtalkUserInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/teamwork/dingtalkuser/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DingtalkUser
     * @name WebTeamworkDingtalkuserDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/teamwork/dingtalkuser/delete/{id}
     * @secure
     */
    webTeamworkDingtalkuserDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/teamwork/dingtalkuser/delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DingtalkUser
     * @name WebTeamworkDingtalkuserDingtalkloginList
     * @summary 钉钉登录
     * @request GET:/api/web/teamwork/dingtalkuser/dingtalklogin
     */
    webTeamworkDingtalkuserDingtalkloginList: (
      query?: {
        /** 应用ID */
        appId?: string
        /** 租户标识 */
        tenancyName?: string
        /** Code */
        code?: string
        /** 跳转链接 */
        redirectUrl?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/web/teamwork/dingtalkuser/dingtalklogin`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DingtalkUserRelation
     * @name WebTeamworkDingtalkuserrelationGetpagePageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/teamwork/dingtalkuserrelation/getpage/page
     * @secure
     */
    webTeamworkDingtalkuserrelationGetpagePageCreate: (
      query?: {
        /**
         * Desc:雪花Id
         * Default:
         * Nullable:False
         */
        id?: string
        /**
         * Desc:律智荟 UserId
         * Default:
         * Nullable:True
         */
        relationUserId?: string
        /**
         * Desc:邮箱地址
         * Default:
         * Nullable:True
         */
        email?: string
        /**
         * Desc:手机号
         * Default:
         * Nullable:True
         */
        mobile?: string
        /**
         * Desc:钉钉用户的open_id
         * Default:
         * Nullable:True
         */
        openId?: string
        /**
         * Desc:钉钉用户的union_id
         * Default:
         * Nullable:True
         */
        unionId?: string
        /**
         * Desc:钉钉用户的user_id
         * Default:
         * Nullable:True
         */
        userId?: string
        /**
         * Desc:钉钉appid
         * Default:
         * Nullable:True
         */
        appId?: string
        /**
         * Desc:钉钉用户编号获取状态（N：未同步到，A：同步到其中任一Id）
         * Default:
         * Nullable:True
         */
        syncStatus?: string
        /**
         * Desc:租户ID
         * Default:0
         * Nullable:False
         */
        tenantId?: string
        /**
         * Desc:部门ID  DepartmentId
         * Default:0
         * Nullable:False
         */
        organizationUnitId?: string
        /**
         * Desc:分所Id
         * Default:0
         * Nullable:False
         */
        officeId?: string
        /**
         * Desc:备注
         * Default:
         * Nullable:True
         */
        remark?: string
        /**
         * Desc:状态 	            中立字段，某些表可使用某些表不使用
         * Default:1
         * Nullable:False
         */
        isEnabled?: boolean
        /**
         * Desc:中立字段，某些表可使用某些表不使用   	            逻辑上的删除，非物理删除  	            例如：单据删除并非直接删除
         * Default:0
         * Nullable:False
         */
        isDeleted?: boolean
        /**
         * Desc:中立字段 	            是否内置数据
         * Default:
         * Nullable:False
         */
        isInternal?: boolean
        /**
         * Desc:创建ID
         * Default:
         * Nullable:True
         */
        createId?: string
        /**
         * Desc:创建者
         * Default:
         * Nullable:True
         */
        createBy?: string
        /**
         * Desc:创建时间
         * Default:
         * Nullable:False
         * @format date-time
         */
        createTime?: string
        /**
         * Desc:修改ID
         * Default:
         * Nullable:True
         */
        modifyId?: string
        /**
         * Desc:更新者
         * Default:
         * Nullable:True
         */
        modifyBy?: string
        /**
         * Desc:修改日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        modifyTime?: string
        /**
         * Desc:数据版本
         * Default:0
         * Nullable:False
         */
        version?: string
        /**
         * Desc:删除ID
         * Default:
         * Nullable:True
         */
        deleteId?: string
        /**
         * Desc:删除者
         * Default:
         * Nullable:True
         */
        deleteBy?: string
        /**
         * Desc:删除日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        deleteTime?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<DingtalkUserRelationDtoPageModelMessageModel, void>({
        path: `/api/web/teamwork/dingtalkuserrelation/getpage/page`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DingtalkUserRelation
     * @name WebTeamworkDingtalkuserrelationListListList
     * @summary 列表获取所有 (Auth policies: Web)
     * @request GET:/api/web/teamwork/dingtalkuserrelation/list/list
     * @secure
     */
    webTeamworkDingtalkuserrelationListListList: (
      query?: {
        /**
         * Desc:雪花Id
         * Default:
         * Nullable:False
         */
        id?: string
        /**
         * Desc:律智荟 UserId
         * Default:
         * Nullable:True
         */
        relationUserId?: string
        /**
         * Desc:邮箱地址
         * Default:
         * Nullable:True
         */
        email?: string
        /**
         * Desc:手机号
         * Default:
         * Nullable:True
         */
        mobile?: string
        /**
         * Desc:钉钉用户的open_id
         * Default:
         * Nullable:True
         */
        openId?: string
        /**
         * Desc:钉钉用户的union_id
         * Default:
         * Nullable:True
         */
        unionId?: string
        /**
         * Desc:钉钉用户的user_id
         * Default:
         * Nullable:True
         */
        userId?: string
        /**
         * Desc:钉钉appid
         * Default:
         * Nullable:True
         */
        appId?: string
        /**
         * Desc:钉钉用户编号获取状态（N：未同步到，A：同步到其中任一Id）
         * Default:
         * Nullable:True
         */
        syncStatus?: string
        /**
         * Desc:租户ID
         * Default:0
         * Nullable:False
         */
        tenantId?: string
        /**
         * Desc:部门ID  DepartmentId
         * Default:0
         * Nullable:False
         */
        organizationUnitId?: string
        /**
         * Desc:分所Id
         * Default:0
         * Nullable:False
         */
        officeId?: string
        /**
         * Desc:备注
         * Default:
         * Nullable:True
         */
        remark?: string
        /**
         * Desc:状态 	            中立字段，某些表可使用某些表不使用
         * Default:1
         * Nullable:False
         */
        isEnabled?: boolean
        /**
         * Desc:中立字段，某些表可使用某些表不使用   	            逻辑上的删除，非物理删除  	            例如：单据删除并非直接删除
         * Default:0
         * Nullable:False
         */
        isDeleted?: boolean
        /**
         * Desc:中立字段 	            是否内置数据
         * Default:
         * Nullable:False
         */
        isInternal?: boolean
        /**
         * Desc:创建ID
         * Default:
         * Nullable:True
         */
        createId?: string
        /**
         * Desc:创建者
         * Default:
         * Nullable:True
         */
        createBy?: string
        /**
         * Desc:创建时间
         * Default:
         * Nullable:False
         * @format date-time
         */
        createTime?: string
        /**
         * Desc:修改ID
         * Default:
         * Nullable:True
         */
        modifyId?: string
        /**
         * Desc:更新者
         * Default:
         * Nullable:True
         */
        modifyBy?: string
        /**
         * Desc:修改日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        modifyTime?: string
        /**
         * Desc:数据版本
         * Default:0
         * Nullable:False
         */
        version?: string
        /**
         * Desc:删除ID
         * Default:
         * Nullable:True
         */
        deleteId?: string
        /**
         * Desc:删除者
         * Default:
         * Nullable:True
         */
        deleteBy?: string
        /**
         * Desc:删除日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        deleteTime?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<DingtalkUserRelationDtoListMessageModel, void>({
        path: `/api/web/teamwork/dingtalkuserrelation/list/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DingtalkUserRelation
     * @name WebTeamworkDingtalkuserrelationGetDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/teamwork/dingtalkuserrelation/get/{id}
     * @secure
     */
    webTeamworkDingtalkuserrelationGetDetail: (id: string, params: RequestParams = {}) =>
      this.request<DingtalkUserRelationDtoMessageModel, void>({
        path: `/api/web/teamwork/dingtalkuserrelation/get/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DingtalkUserRelation
     * @name WebTeamworkDingtalkuserrelationPostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/teamwork/dingtalkuserrelation/post
     * @secure
     */
    webTeamworkDingtalkuserrelationPostCreate: (data: CreateDingtalkUserRelationInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/teamwork/dingtalkuserrelation/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DingtalkUserRelation
     * @name WebTeamworkDingtalkuserrelationPutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/teamwork/dingtalkuserrelation/put/{id}
     * @secure
     */
    webTeamworkDingtalkuserrelationPutUpdate: (id: string, data: CreateDingtalkUserRelationInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/teamwork/dingtalkuserrelation/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DingtalkUserRelation
     * @name WebTeamworkDingtalkuserrelationDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/teamwork/dingtalkuserrelation/delete/{id}
     * @secure
     */
    webTeamworkDingtalkuserrelationDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/teamwork/dingtalkuserrelation/delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DisplayDesensitization
     * @name WebSysDisplaydesensitizationListList
     * @summary 获取所有Dto规则 (Auth policies: Web)
     * @request GET:/api/web/sys/displaydesensitization/list
     * @secure
     */
    webSysDisplaydesensitizationListList: (params: RequestParams = {}) =>
      this.request<DisplayDesensitizationModuleListMessageModel, void>({
        path: `/api/web/sys/displaydesensitization/list`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DisplayDesensitization
     * @name WebSysDisplaydesensitizationGetdisplaydesensitizationpagelistList
     * @summary 获取显示规则分页 (Auth policies: Web)
     * @request GET:/api/web/sys/displaydesensitization/getdisplaydesensitizationpagelist
     * @secure
     */
    webSysDisplaydesensitizationGetdisplaydesensitizationpagelistList: (
      query?: {
        /** 分组名称 */
        groupName?: string
        /** 模块名称 */
        controllerName?: string
        /** 方法名称 */
        actionName?: string
        /** Dto名称 */
        dtoName?: string
        /**
         * 配置层级
         * @format int32
         */
        settingLevel?: 0 | 1 | 2 | 3
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<DisplayDesensitizationOutputPageModelMessageModel, void>({
        path: `/api/web/sys/displaydesensitization/getdisplaydesensitizationpagelist`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DisplayDesensitization
     * @name WebSysDisplaydesensitizationGetdisplaydesensitizationDetail
     * @summary 根据Id获取显示脱敏信息 (Auth policies: Web)
     * @request GET:/api/web/sys/displaydesensitization/getdisplaydesensitization/{id}
     * @secure
     */
    webSysDisplaydesensitizationGetdisplaydesensitizationDetail: (id: string, params: RequestParams = {}) =>
      this.request<DisplayDesensitizationOutputMessageModel, void>({
        path: `/api/web/sys/displaydesensitization/getdisplaydesensitization/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DisplayDesensitization
     * @name WebSysDisplaydesensitizationGetdisplaydesensitizationforeditDetail
     * @summary 根据Id获取显示脱敏编辑信息 (Auth policies: Web)
     * @request GET:/api/web/sys/displaydesensitization/getdisplaydesensitizationforedit/{id}
     * @secure
     */
    webSysDisplaydesensitizationGetdisplaydesensitizationforeditDetail: (id: string, params: RequestParams = {}) =>
      this.request<CreateDisplayDesensitizationInputMessageModel, void>({
        path: `/api/web/sys/displaydesensitization/getdisplaydesensitizationforedit/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DisplayDesensitization
     * @name WebSysDisplaydesensitizationCreateCreate
     * @summary 新增显示脱敏规则 (Auth policies: Web)
     * @request POST:/api/web/sys/displaydesensitization/create
     * @secure
     */
    webSysDisplaydesensitizationCreateCreate: (data: CreateDisplayDesensitizationInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/displaydesensitization/create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DisplayDesensitization
     * @name WebSysDisplaydesensitizationUpdateUpdate
     * @summary 修改显示脱敏规则 (Auth policies: Web)
     * @request PUT:/api/web/sys/displaydesensitization/update
     * @secure
     */
    webSysDisplaydesensitizationUpdateUpdate: (
      data: CreateDisplayDesensitizationInput,
      query?: {
        id?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/displaydesensitization/update`,
        method: 'PUT',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DisplayDesensitization
     * @name WebSysDisplaydesensitizationDeleteDelete
     * @summary 删除显示脱敏规则 (Auth policies: Web)
     * @request DELETE:/api/web/sys/displaydesensitization/delete/{id}
     * @secure
     */
    webSysDisplaydesensitizationDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/displaydesensitization/delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DisplayDesensitization
     * @name WebSysDisplaydesensitizationGetdefaultrulelistList
     * @summary 获取默认规则设置 (Auth policies: Web)
     * @request GET:/api/web/sys/displaydesensitization/getdefaultrulelist
     * @secure
     */
    webSysDisplaydesensitizationGetdefaultrulelistList: (params: RequestParams = {}) =>
      this.request<DisplayDesensitizationDefaultRuleDtoListMessageModel, void>({
        path: `/api/web/sys/displaydesensitization/getdefaultrulelist`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags EntityContact
     * @name WebCustomerEntitycontactGetpagePageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/customer/entitycontact/getpage/page
     * @secure
     */
    webCustomerEntitycontactGetpagePageCreate: (
      query?: {
        /**
         * Desc:雪花Id
         * Default:
         * Nullable:False
         */
        id?: string
        /**
         * Desc:联系人名称
         * Default:
         * Nullable:True
         */
        name?: string
        /**
         * Desc:别名
         * Default:
         * Nullable:True
         */
        altName?: string
        /**
         * Desc:外文名称
         * Default:
         * Nullable:True
         */
        foreignName?: string
        /**
         * Desc:速搜码，简码
         * Default:
         * Nullable:True
         */
        shortCode?: string
        /**
         * Desc:身份类别（客户联系人/法院法官/对方联系人/检察官/监狱长等）
         * Default:
         * Nullable:True
         */
        identityType?: string
        /**
         * Desc:记录来源
         * Default:
         * Nullable:True
         */
        loadSource?: string
        /**
         * Desc:性别
         * Default:
         * Nullable:True
         */
        gender?: string
        /**
         * Desc:民族
         * Default:
         * Nullable:True
         */
        nation?: string
        /**
         * Desc:生日
         * Default:
         * Nullable:False
         * @format date-time
         */
        birthday?: string
        /**
         * Desc:职位
         * Default:
         * Nullable:True
         */
        duty?: string
        /**
         * Desc:头像
         * Default:
         * Nullable:True
         */
        avatar?: string
        /**
         * Desc:图像/图片
         * Default:
         * Nullable:True
         */
        picture?: string
        /**
         * Desc:邮编
         * Default:
         * Nullable:True
         */
        zipCode?: string
        /**
         * Desc:通讯地址
         * Default:
         * Nullable:True
         */
        address?: string
        /**
         * Desc:所属地区
         * Default:
         * Nullable:True
         */
        region?: string
        /**
         * Desc:画像
         * Default:
         * Nullable:True
         */
        portraitNarrative?: string
        /**
         * Desc:邮箱地址
         * Default:
         * Nullable:True
         */
        emailAddress?: string
        /**
         * Desc:个人邮箱地址
         * Default:
         * Nullable:True
         */
        perEmailAddress?: string
        /**
         * Desc:联系固话
         * Default:
         * Nullable:True
         */
        landline?: string
        /**
         * Desc:移动电话
         * Default:
         * Nullable:True
         */
        phoneNumber?: string
        /**
         * Desc:个人移动电话
         * Default:
         * Nullable:True
         */
        perPhoneNumber?: string
        /**
         * Desc:传真
         * Default:
         * Nullable:True
         */
        fax?: string
        /**
         * Desc:网站主页
         * Default:
         * Nullable:True
         */
        webPortal?: string
        /**
         * Desc:证件类型
         * Default:
         * Nullable:True
         */
        creditType?: string
        /**
         * Desc:证件号码
         * Default:
         * Nullable:True
         */
        creditCode?: string
        /**
         * Desc:来源
         * Default:
         * Nullable:True
         */
        originSource?: string
        /**
         * Desc:即时通讯类型（微信/Line/Whatapp等）
         * Default:
         * Nullable:True
         */
        iMType?: string
        /**
         * Desc:即时通讯号码
         * Default:
         * Nullable:True
         */
        iMNumber?: string
        /**
         * Desc:即时通讯描述
         * Default:
         * Nullable:True
         */
        iMNarrative?: string
        /**
         * Desc:即时通讯类型2
         * Default:
         * Nullable:True
         */
        iMType2?: string
        /**
         * Desc:即时通讯号码2
         * Default:
         * Nullable:True
         */
        iMNumber2?: string
        /**
         * Desc:即时通讯描述2
         * Default:
         * Nullable:True
         */
        iMNarrative2?: string
        /**
         * Desc:社交媒体类型
         * Default:
         * Nullable:True
         */
        socialMediaType?: string
        /**
         * Desc:社交媒体编号
         * Default:
         * Nullable:True
         */
        socialMediaID?: string
        /**
         * Desc:社交媒体描述
         * Default:
         * Nullable:True
         */
        socialMediaNarrative?: string
        /**
         * Desc:描述说明
         * Default:
         * Nullable:True
         */
        narrative?: string
        /**
         * Desc:喜好/爱好
         * Default:
         * Nullable:True
         */
        beFondOf?: string
        /**
         * Desc:租户ID
         * Default:0
         * Nullable:False
         */
        tenantId?: string
        /**
         * Desc:部门ID  DepartmentId
         * Default:0
         * Nullable:False
         */
        organizationUnitId?: string
        /**
         * Desc:分所Id
         * Default:0
         * Nullable:False
         */
        officeId?: string
        /**
         * Desc:备注
         * Default:
         * Nullable:True
         */
        remark?: string
        /**
         * Desc:状态 	                中立字段，某些表可使用某些表不使用
         * Default:1
         * Nullable:False
         */
        isEnabled?: boolean
        /**
         * Desc:中立字段，某些表可使用某些表不使用   	                逻辑上的删除，非物理删除  	                例如：单据删除并非直接删除
         * Default:0
         * Nullable:False
         */
        isDeleted?: boolean
        /**
         * Desc:中立字段 	                是否内置数据
         * Default:
         * Nullable:False
         */
        isInternal?: boolean
        /**
         * Desc:创建ID
         * Default:
         * Nullable:True
         */
        createId?: string
        /**
         * Desc:创建者
         * Default:
         * Nullable:True
         */
        createBy?: string
        /**
         * Desc:创建时间
         * Default:
         * Nullable:False
         * @format date-time
         */
        createTime?: string
        /**
         * Desc:修改ID
         * Default:
         * Nullable:True
         */
        modifyId?: string
        /**
         * Desc:更新者
         * Default:
         * Nullable:True
         */
        modifyBy?: string
        /**
         * Desc:修改日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        modifyTime?: string
        /**
         * Desc:数据版本
         * Default:0
         * Nullable:False
         */
        version?: string
        /**
         * Desc:删除ID
         * Default:
         * Nullable:True
         */
        deleteId?: string
        /**
         * Desc:删除者
         * Default:
         * Nullable:True
         */
        deleteBy?: string
        /**
         * Desc:删除日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        deleteTime?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<EntityContactDtoPageModelMessageModel, void>({
        path: `/api/web/customer/entitycontact/getpage/page`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags EntityContact
     * @name WebCustomerEntitycontactListListList
     * @summary 列表获取所有 (Auth policies: Web)
     * @request GET:/api/web/customer/entitycontact/list/list
     * @secure
     */
    webCustomerEntitycontactListListList: (
      query?: {
        /**
         * Desc:雪花Id
         * Default:
         * Nullable:False
         */
        id?: string
        /**
         * Desc:联系人名称
         * Default:
         * Nullable:True
         */
        name?: string
        /**
         * Desc:别名
         * Default:
         * Nullable:True
         */
        altName?: string
        /**
         * Desc:外文名称
         * Default:
         * Nullable:True
         */
        foreignName?: string
        /**
         * Desc:速搜码，简码
         * Default:
         * Nullable:True
         */
        shortCode?: string
        /**
         * Desc:身份类别（客户联系人/法院法官/对方联系人/检察官/监狱长等）
         * Default:
         * Nullable:True
         */
        identityType?: string
        /**
         * Desc:记录来源
         * Default:
         * Nullable:True
         */
        loadSource?: string
        /**
         * Desc:性别
         * Default:
         * Nullable:True
         */
        gender?: string
        /**
         * Desc:民族
         * Default:
         * Nullable:True
         */
        nation?: string
        /**
         * Desc:生日
         * Default:
         * Nullable:False
         * @format date-time
         */
        birthday?: string
        /**
         * Desc:职位
         * Default:
         * Nullable:True
         */
        duty?: string
        /**
         * Desc:头像
         * Default:
         * Nullable:True
         */
        avatar?: string
        /**
         * Desc:图像/图片
         * Default:
         * Nullable:True
         */
        picture?: string
        /**
         * Desc:邮编
         * Default:
         * Nullable:True
         */
        zipCode?: string
        /**
         * Desc:通讯地址
         * Default:
         * Nullable:True
         */
        address?: string
        /**
         * Desc:所属地区
         * Default:
         * Nullable:True
         */
        region?: string
        /**
         * Desc:画像
         * Default:
         * Nullable:True
         */
        portraitNarrative?: string
        /**
         * Desc:邮箱地址
         * Default:
         * Nullable:True
         */
        emailAddress?: string
        /**
         * Desc:个人邮箱地址
         * Default:
         * Nullable:True
         */
        perEmailAddress?: string
        /**
         * Desc:联系固话
         * Default:
         * Nullable:True
         */
        landline?: string
        /**
         * Desc:移动电话
         * Default:
         * Nullable:True
         */
        phoneNumber?: string
        /**
         * Desc:个人移动电话
         * Default:
         * Nullable:True
         */
        perPhoneNumber?: string
        /**
         * Desc:传真
         * Default:
         * Nullable:True
         */
        fax?: string
        /**
         * Desc:网站主页
         * Default:
         * Nullable:True
         */
        webPortal?: string
        /**
         * Desc:证件类型
         * Default:
         * Nullable:True
         */
        creditType?: string
        /**
         * Desc:证件号码
         * Default:
         * Nullable:True
         */
        creditCode?: string
        /**
         * Desc:来源
         * Default:
         * Nullable:True
         */
        originSource?: string
        /**
         * Desc:即时通讯类型（微信/Line/Whatapp等）
         * Default:
         * Nullable:True
         */
        iMType?: string
        /**
         * Desc:即时通讯号码
         * Default:
         * Nullable:True
         */
        iMNumber?: string
        /**
         * Desc:即时通讯描述
         * Default:
         * Nullable:True
         */
        iMNarrative?: string
        /**
         * Desc:即时通讯类型2
         * Default:
         * Nullable:True
         */
        iMType2?: string
        /**
         * Desc:即时通讯号码2
         * Default:
         * Nullable:True
         */
        iMNumber2?: string
        /**
         * Desc:即时通讯描述2
         * Default:
         * Nullable:True
         */
        iMNarrative2?: string
        /**
         * Desc:社交媒体类型
         * Default:
         * Nullable:True
         */
        socialMediaType?: string
        /**
         * Desc:社交媒体编号
         * Default:
         * Nullable:True
         */
        socialMediaID?: string
        /**
         * Desc:社交媒体描述
         * Default:
         * Nullable:True
         */
        socialMediaNarrative?: string
        /**
         * Desc:描述说明
         * Default:
         * Nullable:True
         */
        narrative?: string
        /**
         * Desc:喜好/爱好
         * Default:
         * Nullable:True
         */
        beFondOf?: string
        /**
         * Desc:租户ID
         * Default:0
         * Nullable:False
         */
        tenantId?: string
        /**
         * Desc:部门ID  DepartmentId
         * Default:0
         * Nullable:False
         */
        organizationUnitId?: string
        /**
         * Desc:分所Id
         * Default:0
         * Nullable:False
         */
        officeId?: string
        /**
         * Desc:备注
         * Default:
         * Nullable:True
         */
        remark?: string
        /**
         * Desc:状态 	                中立字段，某些表可使用某些表不使用
         * Default:1
         * Nullable:False
         */
        isEnabled?: boolean
        /**
         * Desc:中立字段，某些表可使用某些表不使用   	                逻辑上的删除，非物理删除  	                例如：单据删除并非直接删除
         * Default:0
         * Nullable:False
         */
        isDeleted?: boolean
        /**
         * Desc:中立字段 	                是否内置数据
         * Default:
         * Nullable:False
         */
        isInternal?: boolean
        /**
         * Desc:创建ID
         * Default:
         * Nullable:True
         */
        createId?: string
        /**
         * Desc:创建者
         * Default:
         * Nullable:True
         */
        createBy?: string
        /**
         * Desc:创建时间
         * Default:
         * Nullable:False
         * @format date-time
         */
        createTime?: string
        /**
         * Desc:修改ID
         * Default:
         * Nullable:True
         */
        modifyId?: string
        /**
         * Desc:更新者
         * Default:
         * Nullable:True
         */
        modifyBy?: string
        /**
         * Desc:修改日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        modifyTime?: string
        /**
         * Desc:数据版本
         * Default:0
         * Nullable:False
         */
        version?: string
        /**
         * Desc:删除ID
         * Default:
         * Nullable:True
         */
        deleteId?: string
        /**
         * Desc:删除者
         * Default:
         * Nullable:True
         */
        deleteBy?: string
        /**
         * Desc:删除日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        deleteTime?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<EntityContactDtoListMessageModel, void>({
        path: `/api/web/customer/entitycontact/list/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags EntityContact
     * @name WebCustomerEntitycontactGetDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/customer/entitycontact/get/{id}
     * @secure
     */
    webCustomerEntitycontactGetDetail: (id: string, params: RequestParams = {}) =>
      this.request<EntityContactDtoMessageModel, void>({
        path: `/api/web/customer/entitycontact/get/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags EntityContact
     * @name WebCustomerEntitycontactPostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/customer/entitycontact/post
     * @secure
     */
    webCustomerEntitycontactPostCreate: (data: CreateEntityContactInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/customer/entitycontact/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags EntityContact
     * @name WebCustomerEntitycontactPutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/customer/entitycontact/put/{id}
     * @secure
     */
    webCustomerEntitycontactPutUpdate: (id: string, data: CreateEntityContactInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/customer/entitycontact/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags EntityContact
     * @name WebCustomerEntitycontactDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/customer/entitycontact/delete/{id}
     * @secure
     */
    webCustomerEntitycontactDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/customer/entitycontact/delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags EntityContactRelationship
     * @name WebCustomerEntitycontactrelationshipGetpagePageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/customer/entitycontactrelationship/getpage/page
     * @secure
     */
    webCustomerEntitycontactrelationshipGetpagePageCreate: (
      query?: {
        /**
         * Desc:雪花Id
         * Default:
         * Nullable:False
         */
        id?: string
        /**
         * Desc:联系人编号
         * Default:
         * Nullable:False
         */
        contactID?: string
        /**
         * Desc:客户编号
         * Default:
         * Nullable:False
         */
        clientID?: string
        /**
         * Desc:案件编号
         * Default:
         * Nullable:False
         */
        caseID?: string
        /**
         * Desc:说明描述
         * Default:
         * Nullable:True
         */
        narrative?: string
        /**
         * Desc:租户ID
         * Default:0
         * Nullable:False
         */
        tenantId?: string
        /**
         * Desc:部门ID  DepartmentId
         * Default:0
         * Nullable:False
         */
        organizationUnitId?: string
        /**
         * Desc:分所Id
         * Default:0
         * Nullable:False
         */
        officeId?: string
        /**
         * Desc:备注
         * Default:
         * Nullable:True
         */
        remark?: string
        /**
         * Desc:状态 	                中立字段，某些表可使用某些表不使用
         * Default:1
         * Nullable:False
         */
        isEnabled?: boolean
        /**
         * Desc:中立字段，某些表可使用某些表不使用   	                逻辑上的删除，非物理删除  	                例如：单据删除并非直接删除
         * Default:0
         * Nullable:False
         */
        isDeleted?: boolean
        /**
         * Desc:中立字段 	                是否内置数据
         * Default:
         * Nullable:False
         */
        isInternal?: boolean
        /**
         * Desc:创建ID
         * Default:
         * Nullable:True
         */
        createId?: string
        /**
         * Desc:创建者
         * Default:
         * Nullable:True
         */
        createBy?: string
        /**
         * Desc:创建时间
         * Default:
         * Nullable:False
         * @format date-time
         */
        createTime?: string
        /**
         * Desc:修改ID
         * Default:
         * Nullable:True
         */
        modifyId?: string
        /**
         * Desc:更新者
         * Default:
         * Nullable:True
         */
        modifyBy?: string
        /**
         * Desc:修改日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        modifyTime?: string
        /**
         * Desc:数据版本
         * Default:0
         * Nullable:False
         */
        version?: string
        /**
         * Desc:删除ID
         * Default:
         * Nullable:True
         */
        deleteId?: string
        /**
         * Desc:删除者
         * Default:
         * Nullable:True
         */
        deleteBy?: string
        /**
         * Desc:删除日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        deleteTime?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<EntityContactRelationshipDtoPageModelMessageModel, void>({
        path: `/api/web/customer/entitycontactrelationship/getpage/page`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags EntityContactRelationship
     * @name WebCustomerEntitycontactrelationshipListListList
     * @summary 列表获取所有 (Auth policies: Web)
     * @request GET:/api/web/customer/entitycontactrelationship/list/list
     * @secure
     */
    webCustomerEntitycontactrelationshipListListList: (
      query?: {
        /**
         * Desc:雪花Id
         * Default:
         * Nullable:False
         */
        id?: string
        /**
         * Desc:联系人编号
         * Default:
         * Nullable:False
         */
        contactID?: string
        /**
         * Desc:客户编号
         * Default:
         * Nullable:False
         */
        clientID?: string
        /**
         * Desc:案件编号
         * Default:
         * Nullable:False
         */
        caseID?: string
        /**
         * Desc:说明描述
         * Default:
         * Nullable:True
         */
        narrative?: string
        /**
         * Desc:租户ID
         * Default:0
         * Nullable:False
         */
        tenantId?: string
        /**
         * Desc:部门ID  DepartmentId
         * Default:0
         * Nullable:False
         */
        organizationUnitId?: string
        /**
         * Desc:分所Id
         * Default:0
         * Nullable:False
         */
        officeId?: string
        /**
         * Desc:备注
         * Default:
         * Nullable:True
         */
        remark?: string
        /**
         * Desc:状态 	                中立字段，某些表可使用某些表不使用
         * Default:1
         * Nullable:False
         */
        isEnabled?: boolean
        /**
         * Desc:中立字段，某些表可使用某些表不使用   	                逻辑上的删除，非物理删除  	                例如：单据删除并非直接删除
         * Default:0
         * Nullable:False
         */
        isDeleted?: boolean
        /**
         * Desc:中立字段 	                是否内置数据
         * Default:
         * Nullable:False
         */
        isInternal?: boolean
        /**
         * Desc:创建ID
         * Default:
         * Nullable:True
         */
        createId?: string
        /**
         * Desc:创建者
         * Default:
         * Nullable:True
         */
        createBy?: string
        /**
         * Desc:创建时间
         * Default:
         * Nullable:False
         * @format date-time
         */
        createTime?: string
        /**
         * Desc:修改ID
         * Default:
         * Nullable:True
         */
        modifyId?: string
        /**
         * Desc:更新者
         * Default:
         * Nullable:True
         */
        modifyBy?: string
        /**
         * Desc:修改日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        modifyTime?: string
        /**
         * Desc:数据版本
         * Default:0
         * Nullable:False
         */
        version?: string
        /**
         * Desc:删除ID
         * Default:
         * Nullable:True
         */
        deleteId?: string
        /**
         * Desc:删除者
         * Default:
         * Nullable:True
         */
        deleteBy?: string
        /**
         * Desc:删除日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        deleteTime?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<EntityContactRelationshipDtoListMessageModel, void>({
        path: `/api/web/customer/entitycontactrelationship/list/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags EntityContactRelationship
     * @name WebCustomerEntitycontactrelationshipGetDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/customer/entitycontactrelationship/get/{id}
     * @secure
     */
    webCustomerEntitycontactrelationshipGetDetail: (id: string, params: RequestParams = {}) =>
      this.request<EntityContactRelationshipDtoMessageModel, void>({
        path: `/api/web/customer/entitycontactrelationship/get/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags EntityContactRelationship
     * @name WebCustomerEntitycontactrelationshipPostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/customer/entitycontactrelationship/post
     * @secure
     */
    webCustomerEntitycontactrelationshipPostCreate: (data: CreateEntityContactRelationshipInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/customer/entitycontactrelationship/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags EntityContactRelationship
     * @name WebCustomerEntitycontactrelationshipPutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/customer/entitycontactrelationship/put/{id}
     * @secure
     */
    webCustomerEntitycontactrelationshipPutUpdate: (id: string, data: CreateEntityContactRelationshipInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/customer/entitycontactrelationship/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags EntityContactRelationship
     * @name WebCustomerEntitycontactrelationshipDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/customer/entitycontactrelationship/delete/{id}
     * @secure
     */
    webCustomerEntitycontactrelationshipDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/customer/entitycontactrelationship/delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags EntityFormerName
     * @name WebCustomerEntityformernameGetpagePageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/customer/entityformername/getpage/page
     * @secure
     */
    webCustomerEntityformernameGetpagePageCreate: (
      query?: {
        /**
         * Desc:雪花Id
         * Default:
         * Nullable:False
         */
        id?: string
        /**
         * Desc:实体编号
         * Default:
         * Nullable:False
         */
        entityID?: string
        /**
         * Desc:曾用名
         * Default:
         * Nullable:True
         */
        name?: string
        /**
         * Desc:外文曾用名称
         * Default:
         * Nullable:True
         */
        foreignName?: string
        /**
         * Desc:名称使用开始时间
         * Default:
         * Nullable:False
         * @format date-time
         */
        beginDate?: string
        /**
         * Desc:名称使用结束时间
         * Default:
         * Nullable:False
         * @format date-time
         */
        endDate?: string
        /**
         * Desc:记录来源
         * Default:
         * Nullable:True
         */
        loadSource?: string
        /**
         * Desc:说明描述
         * Default:
         * Nullable:True
         */
        narrative?: string
        /**
         * Desc:实体类别（客户/联系人/股东/实控人/母公司等）
         * Default:
         * Nullable:True
         */
        entityType?: string
        /**
         * Desc:租户ID
         * Default:0
         * Nullable:False
         */
        tenantId?: string
        /**
         * Desc:部门ID  DepartmentId
         * Default:0
         * Nullable:False
         */
        organizationUnitId?: string
        /**
         * Desc:分所Id
         * Default:0
         * Nullable:False
         */
        officeId?: string
        /**
         * Desc:备注
         * Default:
         * Nullable:True
         */
        remark?: string
        /**
         * Desc:状态 	                中立字段，某些表可使用某些表不使用
         * Default:1
         * Nullable:False
         */
        isEnabled?: boolean
        /**
         * Desc:中立字段，某些表可使用某些表不使用   	                逻辑上的删除，非物理删除  	                例如：单据删除并非直接删除
         * Default:0
         * Nullable:False
         */
        isDeleted?: boolean
        /**
         * Desc:中立字段 	                是否内置数据
         * Default:
         * Nullable:False
         */
        isInternal?: boolean
        /**
         * Desc:创建ID
         * Default:
         * Nullable:True
         */
        createId?: string
        /**
         * Desc:创建者
         * Default:
         * Nullable:True
         */
        createBy?: string
        /**
         * Desc:创建时间
         * Default:
         * Nullable:False
         * @format date-time
         */
        createTime?: string
        /**
         * Desc:修改ID
         * Default:
         * Nullable:True
         */
        modifyId?: string
        /**
         * Desc:更新者
         * Default:
         * Nullable:True
         */
        modifyBy?: string
        /**
         * Desc:修改日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        modifyTime?: string
        /**
         * Desc:数据版本
         * Default:0
         * Nullable:False
         */
        version?: string
        /**
         * Desc:删除ID
         * Default:
         * Nullable:True
         */
        deleteId?: string
        /**
         * Desc:删除者
         * Default:
         * Nullable:True
         */
        deleteBy?: string
        /**
         * Desc:删除日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        deleteTime?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<EntityFormerNameDtoPageModelMessageModel, void>({
        path: `/api/web/customer/entityformername/getpage/page`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags EntityFormerName
     * @name WebCustomerEntityformernameListListList
     * @summary 列表获取所有 (Auth policies: Web)
     * @request GET:/api/web/customer/entityformername/list/list
     * @secure
     */
    webCustomerEntityformernameListListList: (
      query?: {
        /**
         * Desc:雪花Id
         * Default:
         * Nullable:False
         */
        id?: string
        /**
         * Desc:实体编号
         * Default:
         * Nullable:False
         */
        entityID?: string
        /**
         * Desc:曾用名
         * Default:
         * Nullable:True
         */
        name?: string
        /**
         * Desc:外文曾用名称
         * Default:
         * Nullable:True
         */
        foreignName?: string
        /**
         * Desc:名称使用开始时间
         * Default:
         * Nullable:False
         * @format date-time
         */
        beginDate?: string
        /**
         * Desc:名称使用结束时间
         * Default:
         * Nullable:False
         * @format date-time
         */
        endDate?: string
        /**
         * Desc:记录来源
         * Default:
         * Nullable:True
         */
        loadSource?: string
        /**
         * Desc:说明描述
         * Default:
         * Nullable:True
         */
        narrative?: string
        /**
         * Desc:实体类别（客户/联系人/股东/实控人/母公司等）
         * Default:
         * Nullable:True
         */
        entityType?: string
        /**
         * Desc:租户ID
         * Default:0
         * Nullable:False
         */
        tenantId?: string
        /**
         * Desc:部门ID  DepartmentId
         * Default:0
         * Nullable:False
         */
        organizationUnitId?: string
        /**
         * Desc:分所Id
         * Default:0
         * Nullable:False
         */
        officeId?: string
        /**
         * Desc:备注
         * Default:
         * Nullable:True
         */
        remark?: string
        /**
         * Desc:状态 	                中立字段，某些表可使用某些表不使用
         * Default:1
         * Nullable:False
         */
        isEnabled?: boolean
        /**
         * Desc:中立字段，某些表可使用某些表不使用   	                逻辑上的删除，非物理删除  	                例如：单据删除并非直接删除
         * Default:0
         * Nullable:False
         */
        isDeleted?: boolean
        /**
         * Desc:中立字段 	                是否内置数据
         * Default:
         * Nullable:False
         */
        isInternal?: boolean
        /**
         * Desc:创建ID
         * Default:
         * Nullable:True
         */
        createId?: string
        /**
         * Desc:创建者
         * Default:
         * Nullable:True
         */
        createBy?: string
        /**
         * Desc:创建时间
         * Default:
         * Nullable:False
         * @format date-time
         */
        createTime?: string
        /**
         * Desc:修改ID
         * Default:
         * Nullable:True
         */
        modifyId?: string
        /**
         * Desc:更新者
         * Default:
         * Nullable:True
         */
        modifyBy?: string
        /**
         * Desc:修改日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        modifyTime?: string
        /**
         * Desc:数据版本
         * Default:0
         * Nullable:False
         */
        version?: string
        /**
         * Desc:删除ID
         * Default:
         * Nullable:True
         */
        deleteId?: string
        /**
         * Desc:删除者
         * Default:
         * Nullable:True
         */
        deleteBy?: string
        /**
         * Desc:删除日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        deleteTime?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<EntityFormerNameDtoListMessageModel, void>({
        path: `/api/web/customer/entityformername/list/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags EntityFormerName
     * @name WebCustomerEntityformernameGetDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/customer/entityformername/get/{id}
     * @secure
     */
    webCustomerEntityformernameGetDetail: (id: string, params: RequestParams = {}) =>
      this.request<EntityFormerNameDtoMessageModel, void>({
        path: `/api/web/customer/entityformername/get/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags EntityFormerName
     * @name WebCustomerEntityformernamePostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/customer/entityformername/post
     * @secure
     */
    webCustomerEntityformernamePostCreate: (data: CreateEntityFormerNameInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/customer/entityformername/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags EntityFormerName
     * @name WebCustomerEntityformernamePutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/customer/entityformername/put/{id}
     * @secure
     */
    webCustomerEntityformernamePutUpdate: (id: string, data: CreateEntityFormerNameInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/customer/entityformername/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags EntityFormerName
     * @name WebCustomerEntityformernameDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/customer/entityformername/delete/{id}
     * @secure
     */
    webCustomerEntityformernameDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/customer/entityformername/delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags FormValidate
     * @name WebSysFormvalidateList
     * @summary 获取所有Dto规则， 待处理 (Auth policies: Web)
     * @request GET:/api/web/sys/formvalidate
     * @secure
     */
    webSysFormvalidateList: (params: RequestParams = {}) =>
      this.request<FormValidateSettingDtoListMessageModel, void>({
        path: `/api/web/sys/formvalidate`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags FormValidate
     * @name WebSysFormvalidateCreate
     * @summary 添加验证规则 , 并更新缓存 (Auth policies: Web)
     * @request POST:/api/web/sys/formvalidate
     * @secure
     */
    webSysFormvalidateCreate: (data: CreateFormValidateBatchInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/formvalidate`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags FormValidate
     * @name WebSysFormvalidateDetail
     * @summary 获取单个Dto规则 (Auth policies: Web)
     * @request GET:/api/web/sys/formvalidate/{id}
     * @secure
     */
    webSysFormvalidateDetail: (id: string, params: RequestParams = {}) =>
      this.request<FormValidateDtoMessageModel, void>({
        path: `/api/web/sys/formvalidate/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags FormValidate
     * @name WebSysFormvalidateDtoList
     * @summary 通过Url获取Dto验证规则 (Auth policies: Web)
     * @request GET:/api/web/sys/formvalidate/dto
     * @secure
     */
    webSysFormvalidateDtoList: (
      query?: {
        url?: string
        requestMethod?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<FormValidateSettingDtoMessageModel, void>({
        path: `/api/web/sys/formvalidate/dto`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags GroupInfo
     * @name WebCustomerGroupinfoGetpageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/customer/groupinfo/getpage
     * @secure
     */
    webCustomerGroupinfoGetpageCreate: (
      query?: {
        /** 名字/证件编码  where (a.id =1 or b.id) and (c.id=2 or d.id=2) */
        filter?: string
        /** 客户名称 */
        name?: string
        /** 客户类型 */
        clientType?: string
        /** 证件类型 */
        creditType?: string
        /** 证件号码 */
        creditCode?: string
        /** 来源类型 */
        originType?: string
        /** 来源编号 */
        originID?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<GroupInfoDtoPageModelMessageModel, void>({
        path: `/api/web/customer/groupinfo/getpage`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags GroupInfo
     * @name WebCustomerGroupinfoGetgrouplistCreate
     * @summary 集团模糊匹配 (Auth policies: Web)
     * @request POST:/api/web/customer/groupinfo/getgrouplist
     * @secure
     */
    webCustomerGroupinfoGetgrouplistCreate: (
      query?: {
        /** 名字/证件编码  where (a.id =1 or b.id) and (c.id=2 or d.id=2) */
        filter?: string
        /** 客户名称 */
        name?: string
        /** 客户类型 */
        clientType?: string
        /** 证件类型 */
        creditType?: string
        /** 证件号码 */
        creditCode?: string
        /** 来源类型 */
        originType?: string
        /** 来源编号 */
        originID?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<GroupInfoDtoListMessageModel, void>({
        path: `/api/web/customer/groupinfo/getgrouplist`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags GroupInfo
     * @name WebCustomerGroupinfoCreategroupinfoCreate
     * @summary 添加集团信息 (Auth policies: Web)
     * @request POST:/api/web/customer/groupinfo/creategroupinfo
     * @secure
     */
    webCustomerGroupinfoCreategroupinfoCreate: (data: CreateGroupInfoInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/customer/groupinfo/creategroupinfo`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags GroupInfo
     * @name WebCustomerGroupinfoUpdategroupinfoCreate
     * @summary 修改集团信息 (Auth policies: Web)
     * @request POST:/api/web/customer/groupinfo/updategroupinfo
     * @secure
     */
    webCustomerGroupinfoUpdategroupinfoCreate: (
      data: CreateGroupInfoInput,
      query?: {
        id?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<MessageModel, void>({
        path: `/api/web/customer/groupinfo/updategroupinfo`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags GroupInfo
     * @name WebCustomerGroupinfoDeleteDelete
     * @summary 删除集团信息 (Auth policies: Web)
     * @request DELETE:/api/web/customer/groupinfo/delete/{id}
     * @secure
     */
    webCustomerGroupinfoDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<BooleanMessageModel, void>({
        path: `/api/web/customer/groupinfo/delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags GroupInfo
     * @name WebCustomerGroupinfoUpdategroupidbyclientidCreate
     * @summary 关联集团与客户信息 (Auth policies: Web)
     * @request POST:/api/web/customer/groupinfo/updategroupidbyclientid
     * @secure
     */
    webCustomerGroupinfoUpdategroupidbyclientidCreate: (data: CreateGroupClientRelshipInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/customer/groupinfo/updategroupidbyclientid`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Language
     * @name WebSysLanguageList
     * @summary 获取语言列表 (Auth policies: Web)
     * @request GET:/api/web/sys/language
     * @secure
     */
    webSysLanguageList: (params: RequestParams = {}) =>
      this.request<LanguageDtoListMessageModel, void>({
        path: `/api/web/sys/language`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Language
     * @name WebSysLanguageCreate
     * @summary 添加语言 (Auth policies: Web)
     * @request POST:/api/web/sys/language
     * @secure
     */
    webSysLanguageCreate: (data: CreateLanguageInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/sys/language`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Language
     * @name WebSysLanguageSettingsList
     * @summary 获得语言配置 (Auth policies: Web)
     * @request GET:/api/web/sys/language/settings
     * @secure
     */
    webSysLanguageSettingsList: (params: RequestParams = {}) =>
      this.request<SettingLanguageDtoMessageModel, void>({
        path: `/api/web/sys/language/settings`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Language
     * @name WebSysLanguageUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/sys/language/{id}
     * @secure
     */
    webSysLanguageUpdate: (id: string, data: CreateLanguageInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/language/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Language
     * @name WebSysLanguageDelete
     * @summary 删除语言 (Auth policies: Web)
     * @request DELETE:/api/web/sys/language/{id}
     * @secure
     */
    webSysLanguageDelete: (id: string, params: RequestParams = {}) =>
      this.request<BooleanMessageModel, void>({
        path: `/api/web/sys/language/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Language
     * @name WebSysLanguageDefaultUpdate
     * @summary 设置默认语言 (Auth policies: Web)
     * @request PUT:/api/web/sys/language/{id}/default
     * @secure
     */
    webSysLanguageDefaultUpdate: (id: string, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/sys/language/${id}/default`,
        method: 'PUT',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Language
     * @name WebSysLanguageEnabledUpdate
     * @summary 启用 (Auth policies: Web)
     * @request PUT:/api/web/sys/language/{id}/enabled
     * @secure
     */
    webSysLanguageEnabledUpdate: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/language/${id}/enabled`,
        method: 'PUT',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Language
     * @name WebSysLanguageDisabledUpdate
     * @summary 禁用 (Auth policies: Web)
     * @request PUT:/api/web/sys/language/{id}/disabled
     * @secure
     */
    webSysLanguageDisabledUpdate: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/language/${id}/disabled`,
        method: 'PUT',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrganizationUnit
     * @name WebSysOrganizationunitPageCreate
     * @summary 获取部门列表 (Auth policies: Web)
     * @request POST:/api/web/sys/organizationunit/page
     * @secure
     */
    webSysOrganizationunitPageCreate: (data: GetOrganizationUnitsInput, params: RequestParams = {}) =>
      this.request<OrganizationUnitsDtoPageModelMessageModel, void>({
        path: `/api/web/sys/organizationunit/page`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrganizationUnit
     * @name WebSysOrganizationunitDetail
     * @summary 获取部门详情 (Auth policies: Web)
     * @request GET:/api/web/sys/organizationunit/{id}
     * @secure
     */
    webSysOrganizationunitDetail: (id: string, params: RequestParams = {}) =>
      this.request<OrganizationUnitsDtoMessageModel, void>({
        path: `/api/web/sys/organizationunit/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrganizationUnit
     * @name WebSysOrganizationunitUpdate
     * @summary 修改组织机构 (Auth policies: Web)
     * @request PUT:/api/web/sys/organizationunit/{id}
     * @secure
     */
    webSysOrganizationunitUpdate: (id: string, data: CreateOrganizationUnitsInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/sys/organizationunit/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrganizationUnit
     * @name WebSysOrganizationunitDelete
     * @summary 删除数据 (Auth policies: Web)
     * @request DELETE:/api/web/sys/organizationunit/{id}
     * @secure
     */
    webSysOrganizationunitDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/organizationunit/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrganizationUnit
     * @name WebSysOrganizationunitTreeList
     * @summary 获取组织机构结构树 (Auth policies: Web)
     * @request GET:/api/web/sys/organizationunit/tree
     * @secure
     */
    webSysOrganizationunitTreeList: (
      query?: {
        /** @default "0" */
        pid?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<OrganizationUnitsTreeDtoMessageModel, void>({
        path: `/api/web/sys/organizationunit/tree`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrganizationUnit
     * @name WebSysOrganizationunitUserCreate
     * @summary 移动部门 (Auth policies: Web)
     * @request POST:/api/web/sys/organizationunit/{orgid}/user
     * @secure
     */
    webSysOrganizationunitUserCreate: (orgId: string, orgid: string, data: string[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/organizationunit/${orgid}/user`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrganizationUnit
     * @name WebSysOrganizationunitBatchUpdate
     * @summary 批量更新状态 (Auth policies: Web)
     * @request PUT:/api/web/sys/organizationunit/batch/{status}
     * @secure
     */
    webSysOrganizationunitBatchUpdate: (status: string, data: string[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/organizationunit/batch/${status}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrganizationUnit
     * @name WebSysOrganizationunitMergeUpdate
     * @summary 合并组织机构 (Auth policies: Web)
     * @request PUT:/api/web/sys/organizationunit/merge
     * @secure
     */
    webSysOrganizationunitMergeUpdate: (data: MergeOrganizationInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/organizationunit/merge`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrganizationUnit
     * @name WebSysOrganizationunitCreate
     * @summary 创建部门机构 (Auth policies: Web)
     * @request POST:/api/web/sys/organizationunit
     * @secure
     */
    webSysOrganizationunitCreate: (data: CreateOrganizationUnitsInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/sys/organizationunit`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Permission
     * @name WebSysPermissionGetList
     * @summary 获取菜单 (Auth policies: Web)
     * @request GET:/api/web/sys/permission/get
     * @secure
     */
    webSysPermissionGetList: (
      query?: {
        /**
         * @format int32
         * @default 1
         */
        page?: number
        /** @default "" */
        key?: string
        /**
         * @format int32
         * @default 50
         */
        pageSize?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<SysPermissionPageModelMessageModel, void>({
        path: `/api/web/sys/permission/get`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Permission
     * @name WebSysPermissionGettreetableList
     * @summary 查询树形 Table
     * @request GET:/api/web/sys/permission/gettreetable
     */
    webSysPermissionGettreetableList: (
      query?: {
        /**
         * 父节点
         * @default "0"
         */
        f?: string
        /**
         * 关键字
         * @default ""
         */
        key?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<SysPermissionListMessageModel, any>({
        path: `/api/web/sys/permission/gettreetable`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Permission
     * @name WebSysPermissionPostCreate
     * @summary 添加一个菜单 (Auth policies: Web)
     * @request POST:/api/web/sys/permission/post
     * @secure
     */
    webSysPermissionPostCreate: (data: SysPermission, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/sys/permission/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Permission
     * @name WebSysPermissionAssignCreate
     * @summary 保存菜单权限分配 (Auth policies: Web)
     * @request POST:/api/web/sys/permission/assign
     * @secure
     */
    webSysPermissionAssignCreate: (data: AssignView, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/sys/permission/assign`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Permission
     * @name WebSysPermissionGetpermissiontreeList
     * @summary 获取菜单树 (Auth policies: Web)
     * @request GET:/api/web/sys/permission/getpermissiontree
     * @secure
     */
    webSysPermissionGetpermissiontreeList: (
      query?: {
        /** @default "0" */
        pid?: string
        /** @default false */
        needbtn?: boolean
      },
      params: RequestParams = {},
    ) =>
      this.request<PermissionTreeMessageModel, void>({
        path: `/api/web/sys/permission/getpermissiontree`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Permission
     * @name WebSysPermissionGetnavigationbarList
     * @summary 获取路由树 (Auth policies: Web)
     * @request GET:/api/web/sys/permission/getnavigationbar
     * @secure
     */
    webSysPermissionGetnavigationbarList: (
      query?: {
        uid?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<NavigationBarMessageModel, void>({
        path: `/api/web/sys/permission/getnavigationbar`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Permission
     * @name WebSysPermissionGetnavigationbarproList
     * @summary 获取路由树 (Auth policies: Web)
     * @request GET:/api/web/sys/permission/getnavigationbarpro
     * @secure
     */
    webSysPermissionGetnavigationbarproList: (
      query?: {
        uid?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<NavigationBarProListMessageModel, void>({
        path: `/api/web/sys/permission/getnavigationbarpro`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Permission
     * @name WebSysPermissionGetpermissionidbyroleidList
     * @summary 通过角色获取菜单
     * @request GET:/api/web/sys/permission/getpermissionidbyroleid
     */
    webSysPermissionGetpermissionidbyroleidList: (
      query?: {
        /** @default "0" */
        rid?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<AssignShowMessageModel, any>({
        path: `/api/web/sys/permission/getpermissionidbyroleid`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Permission
     * @name WebSysPermissionPutUpdate
     * @summary 更新菜单 (Auth policies: Web)
     * @request PUT:/api/web/sys/permission/put
     * @secure
     */
    webSysPermissionPutUpdate: (data: SysPermission, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/sys/permission/put`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Permission
     * @name WebSysPermissionDeleteDelete
     * @summary 删除菜单 (Auth policies: Web)
     * @request DELETE:/api/web/sys/permission/delete
     * @secure
     */
    webSysPermissionDeleteDelete: (
      query?: {
        /** @format int32 */
        id?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/sys/permission/delete`,
        method: 'DELETE',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Permission
     * @name WebSysPermissionBatchpostCreate
     * @summary 导入多条菜单信息 (Auth policies: Web)
     * @request POST:/api/web/sys/permission/batchpost
     * @secure
     */
    webSysPermissionBatchpostCreate: (data: SysPermission[], params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/sys/permission/batchpost`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Permission
     * @name WebSysPermissionMigratepermissionList
     * @summary 系统接口菜单同步接口 (Auth policies: Web)
     * @request GET:/api/web/sys/permission/migratepermission
     * @secure
     */
    webSysPermissionMigratepermissionList: (
      query?: {
        /** @default "" */
        action?: string
        /**
         * 接口module的控制器名称
         * @default ""
         */
        controllerName?: string
        /**
         * 菜单permission的父id
         * @default "0"
         */
        pid?: string
        /**
         * 是否执行迁移到数据
         * @default false
         */
        isAction?: boolean
      },
      params: RequestParams = {},
    ) =>
      this.request<SysPermissionListMessageModel, void>({
        path: `/api/web/sys/permission/migratepermission`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags QccEnterprise
     * @name WebEnterpriseQccenterpriseGetnamesearchgetlistList
     * @summary 企业模糊搜索 (Auth policies: Web)
     * @request GET:/api/web/enterprise/qccenterprise/getnamesearchgetlist
     * @secure
     */
    webEnterpriseQccenterpriseGetnamesearchgetlistList: (
      query?: {
        /** 企业名称（模糊匹配） */
        searchName?: string
        /** @default false */
        isOrQccApi?: boolean
      },
      params: RequestParams = {},
    ) =>
      this.request<NameSearchDataItemDtoListMessageModel, void>({
        path: `/api/web/enterprise/qccenterprise/getnamesearchgetlist`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags QccEnterprise
     * @name WebEnterpriseQccenterpriseGetfuzzysearchlistList
     * @summary 企业高级搜素 (Auth policies: Web)
     * @request GET:/api/web/enterprise/qccenterprise/getfuzzysearchlist
     * @secure
     */
    webEnterpriseQccenterpriseGetfuzzysearchlistList: (
      query?: {
        fuzzySearchInputs?: FuzzySearchInput[]
        /** @default false */
        isOrQccApi?: boolean
      },
      params: RequestParams = {},
    ) =>
      this.request<FuzzySearchDataItemDtoListMessageModel, void>({
        path: `/api/web/enterprise/qccenterprise/getfuzzysearchlist`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags QccEnterprise
     * @name WebEnterpriseQccenterpriseGetbasicdetailsbynameList
     * @summary 企查查企业工商照面 (Auth policies: Web)
     * @request GET:/api/web/enterprise/qccenterprise/getbasicdetailsbyname
     * @secure
     */
    webEnterpriseQccenterpriseGetbasicdetailsbynameList: (
      query?: {
        keyword?: string
        /**
         * 是否调用企查查
         * @default false
         */
        isOrQccApi?: boolean
      },
      params: RequestParams = {},
    ) =>
      this.request<BasicDetailsByNameResultMessageModel, void>({
        path: `/api/web/enterprise/qccenterprise/getbasicdetailsbyname`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags QccEnterprise
     * @name WebEnterpriseQccenterpriseGetcreditcodenewList
     * @summary 企业税务登记号查询 (Auth policies: Web)
     * @request GET:/api/web/enterprise/qccenterprise/getcreditcodenew
     * @secure
     */
    webEnterpriseQccenterpriseGetcreditcodenewList: (
      query?: {
        keyword?: string
        /**
         * 是否调用企查查
         * @default false
         */
        isOrQccApi?: boolean
      },
      params: RequestParams = {},
    ) =>
      this.request<CreditCodeNewResultMessageModel, void>({
        path: `/api/web/enterprise/qccenterprise/getcreditcodenew`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags QccEnterprise
     * @name WebEnterpriseQccenterpriseGetactualcontrolList
     * @summary 企查查实际控制人信息 (Auth policies: Web)
     * @request GET:/api/web/enterprise/qccenterprise/getactualcontrol
     * @secure
     */
    webEnterpriseQccenterpriseGetactualcontrolList: (
      query?: {
        keyWord?: string
        /**
         * 是否调用企查查
         * @default false
         */
        isOrQccApi?: boolean
      },
      params: RequestParams = {},
    ) =>
      this.request<ActualControlResultInfoMessageModel, void>({
        path: `/api/web/enterprise/qccenterprise/getactualcontrol`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags QccEnterprise
     * @name WebEnterpriseQccenterpriseGeteciseniorpersonList
     * @summary 企业人员董监高信息 (Auth policies: Web)
     * @request GET:/api/web/enterprise/qccenterprise/geteciseniorperson
     * @secure
     */
    webEnterpriseQccenterpriseGeteciseniorpersonList: (
      query?: {
        searchKey?: string
        personName?: string
        type?: string
        /**
         * 是否调用企查查
         * @default false
         */
        isOrQccApi?: boolean
      },
      params: RequestParams = {},
    ) =>
      this.request<ECISeniorPersonResultItemListMessageModel, void>({
        path: `/api/web/enterprise/qccenterprise/geteciseniorperson`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags QccEnterprise
     * @name WebEnterpriseQccenterpriseGetecipartnerList
     * @summary 股东信息（工商登记） (Auth policies: Web)
     * @request GET:/api/web/enterprise/qccenterprise/getecipartner
     * @secure
     */
    webEnterpriseQccenterpriseGetecipartnerList: (
      query?: {
        searchKey?: string
        /**
         * 是否调用企查查
         * @default false
         */
        isOrQccApi?: boolean
      },
      params: RequestParams = {},
    ) =>
      this.request<ECIPartnerResultListMessageModel, void>({
        path: `/api/web/enterprise/qccenterprise/getecipartner`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags QccEnterprise
     * @name WebEnterpriseQccenterpriseGeteciemployeeList
     * @summary 主要人员 (Auth policies: Web)
     * @request GET:/api/web/enterprise/qccenterprise/geteciemployee
     * @secure
     */
    webEnterpriseQccenterpriseGeteciemployeeList: (
      query?: {
        searchKey?: string
        /**
         * 是否调用企查查
         * @default false
         */
        isOrQccApi?: boolean
      },
      params: RequestParams = {},
    ) =>
      this.request<ECIEmployeeInfoListMessageModel, void>({
        path: `/api/web/enterprise/qccenterprise/geteciemployee`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags QccEnterprise
     * @name WebEnterpriseQccenterpriseGetecibranchList
     * @summary 分支机构 (Auth policies: Web)
     * @request GET:/api/web/enterprise/qccenterprise/getecibranch
     * @secure
     */
    webEnterpriseQccenterpriseGetecibranchList: (
      query?: {
        searchKey?: string
        /** @default false */
        isOrQccApi?: boolean
      },
      params: RequestParams = {},
    ) =>
      this.request<ECIBranchInfoListMessageModel, void>({
        path: `/api/web/enterprise/qccenterprise/getecibranch`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags QccEnterprise
     * @name WebEnterpriseQccenterpriseGetecustomerduediligenceList
     * @summary 客户身份识别 (Auth policies: Web)
     * @request GET:/api/web/enterprise/qccenterprise/getecustomerduediligence
     * @secure
     */
    webEnterpriseQccenterpriseGetecustomerduediligenceList: (
      query?: {
        /** 企业名称、统一社会信用代码、注册号 */
        searchKey?: string
        /** @default false */
        isOrQccApi?: boolean
      },
      params: RequestParams = {},
    ) =>
      this.request<CustomerDueDeliDataMessageModel, void>({
        path: `/api/web/enterprise/qccenterprise/getecustomerduediligence`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags QccEnterprise
     * @name WebEnterpriseQccenterpriseGeteciinfoverifyList
     * @summary 工商信息详情 (Auth policies: Web)
     * @request GET:/api/web/enterprise/qccenterprise/geteciinfoverify
     * @secure
     */
    webEnterpriseQccenterpriseGeteciinfoverifyList: (
      query?: {
        searchKey?: string
        /** @default false */
        isOrQccApi?: boolean
      },
      params: RequestParams = {},
    ) =>
      this.request<ECIInfoVerifyDataMessageModel, void>({
        path: `/api/web/enterprise/qccenterprise/geteciinfoverify`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags QccEnterprise
     * @name WebEnterpriseQccenterpriseGetecustomerduediligenceunionList
     * @summary 客户身份识别聚合 (Auth policies: Web)
     * @request GET:/api/web/enterprise/qccenterprise/getecustomerduediligenceunion
     * @secure
     */
    webEnterpriseQccenterpriseGetecustomerduediligenceunionList: (
      query?: {
        /** 企业名称、统一社会信用代码、注册号 */
        searchKey?: string
        /** @default false */
        isOrQccApi?: boolean
      },
      params: RequestParams = {},
    ) =>
      this.request<CustomerDueDeliDataMessageModel, void>({
        path: `/api/web/enterprise/qccenterprise/getecustomerduediligenceunion`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags QccEnterprise
     * @name WebEnterpriseQccenterpriseGeteciinfoverifyunionList
     * @summary 工商信息详情聚合 (Auth policies: Web)
     * @request GET:/api/web/enterprise/qccenterprise/geteciinfoverifyunion
     * @secure
     */
    webEnterpriseQccenterpriseGeteciinfoverifyunionList: (
      query?: {
        searchKey?: string
        /** @default false */
        isOrQccApi?: boolean
      },
      params: RequestParams = {},
    ) =>
      this.request<ECIInfoVerifyDataMessageModel, void>({
        path: `/api/web/enterprise/qccenterprise/geteciinfoverifyunion`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
 * No description
 *
 * @tags Role
 * @name WebSysRolePageCreate
 * @summary z
            获取角色 (Auth policies: Web)
 * @request POST:/api/web/sys/role/page
 * @secure
 */
    webSysRolePageCreate: (data: GetRoleInput, params: RequestParams = {}) =>
      this.request<RoleDtoPageModelMessageModel, void>({
        path: `/api/web/sys/role/page`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role
     * @name WebSysRoleAllList
     * @summary 获取列表数据 (Auth policies: Web)
     * @request GET:/api/web/sys/role/all
     * @secure
     */
    webSysRoleAllList: (
      query?: {
        /** 关键词 */
        key?: string
        /** 用户组Id */
        roleGroupId?: string
        /**
         * 权限类型
         * @format int32
         */
        roleType?: 0 | 1 | 2 | 3 | 4
        /** 是否启用 */
        isActive?: boolean
        /** 是否默认角色 */
        isDefault?: boolean
        /** 虚拟角色 */
        isVirtual?: boolean
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<RoleDtoListMessageModel, void>({
        path: `/api/web/sys/role/all`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role
     * @name WebSysRoleDetail
     * @summary  (Auth policies: Web)
     * @request GET:/api/web/sys/role/{id}
     * @secure
     */
    webSysRoleDetail: (id: string, params: RequestParams = {}) =>
      this.request<RoleDtoMessageModel, void>({
        path: `/api/web/sys/role/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role
     * @name WebSysRoleUpdate
     * @summary 更新角色 (Auth policies: Web)
     * @request PUT:/api/web/sys/role/{id}
     * @secure
     */
    webSysRoleUpdate: (id: string, data: CreateRoleInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/sys/role/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role
     * @name WebSysRoleDelete
     * @summary 删除角色 (Auth policies: Web)
     * @request DELETE:/api/web/sys/role/{id}
     * @secure
     */
    webSysRoleDelete: (id: string, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/sys/role/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role
     * @name WebSysRoleOrganizationunitDetail
     * @summary 获取角色数据权限 (Auth policies: Web)
     * @request GET:/api/web/sys/role/{id}/organizationunit
     * @secure
     */
    webSysRoleOrganizationunitDetail: (id: string, params: RequestParams = {}) =>
      this.request<OrganizationUnitsTreeDtoMessageModel, void>({
        path: `/api/web/sys/role/${id}/organizationunit`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role
     * @name WebSysRoleOrganizationunitCreate
     * @summary 设置角色数据权限 (Auth policies: Web)
     * @request POST:/api/web/sys/role/{id}/organizationunit
     * @secure
     */
    webSysRoleOrganizationunitCreate: (id: string, data: string[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/role/${id}/organizationunit`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role
     * @name WebSysRolePermissionDetail
     * @summary 获取角色权限 (Auth policies: Web)
     * @request GET:/api/web/sys/role/{id}/permission
     * @secure
     */
    webSysRolePermissionDetail: (id: string, params: RequestParams = {}) =>
      this.request<PermissionTreeMessageModel, void>({
        path: `/api/web/sys/role/${id}/permission`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role
     * @name WebSysRolePermissionCreate
     * @summary 添加角色权限 (Auth policies: Web)
     * @request POST:/api/web/sys/role/{id}/permission
     * @secure
     */
    webSysRolePermissionCreate: (id: string, data: string[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/role/${id}/permission`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role
     * @name WebSysRoleCreate
     * @summary 添加角色 (Auth policies: Web)
     * @request POST:/api/web/sys/role
     * @secure
     */
    webSysRoleCreate: (data: CreateRoleInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/sys/role`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role
     * @name WebSysRoleUnbindUserCreate
     * @summary 删除绑定用户 (Auth policies: Web)
     * @request POST:/api/web/sys/role/unbind/{id}/user
     * @secure
     */
    webSysRoleUnbindUserCreate: (id: string, data: string[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/role/unbind/${id}/user`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role
     * @name WebSysRoleBindGroupCreate
     * @summary 添加用户到用户组 (Auth policies: Web)
     * @request POST:/api/web/sys/role/bind/{id}/group
     * @secure
     */
    webSysRoleBindGroupCreate: (id: string, data: string[], params: RequestParams = {}) =>
      this.request<BooleanMessageModel, void>({
        path: `/api/web/sys/role/bind/${id}/group`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role
     * @name WebSysRoleBindUserCreate
     * @summary 为一个角色添加多个用户 (Auth policies: Web)
     * @request POST:/api/web/sys/role/bind/{id}/user
     * @secure
     */
    webSysRoleBindUserCreate: (id: string, data: string[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/role/bind/${id}/user`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SequenceNumberRules
     * @name WebSysSequencenumberrulesPageCreate
     * @summary 获取序列号规则分页 (Auth)
     * @request POST:/api/web/sys/sequencenumberrules/page
     * @secure
     */
    webSysSequencenumberrulesPageCreate: (data: GetSequenceNumberRulesInput, params: RequestParams = {}) =>
      this.request<SequenceNumberRulesOutputPageModelMessageModel, void>({
        path: `/api/web/sys/sequencenumberrules/page`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SequenceNumberRules
     * @name WebSysSequencenumberrulesHistoryPageCreate
     * @summary 获取历史序列号规则分页 (Auth)
     * @request POST:/api/web/sys/sequencenumberrules/history/page
     * @secure
     */
    webSysSequencenumberrulesHistoryPageCreate: (data: GetSequenceNumberRulesHistoryInput, params: RequestParams = {}) =>
      this.request<SequenceNumberRulesDtoPageModelMessageModel, void>({
        path: `/api/web/sys/sequencenumberrules/history/page`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SequenceNumberRules
     * @name WebSysSequencenumberrulesGetEditList
     * @summary 获取序列号规则编辑对象 (Auth)
     * @request GET:/api/web/sys/sequencenumberrules/get/edit
     * @secure
     */
    webSysSequencenumberrulesGetEditList: (
      query?: {
        id?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<CreateSequenceNumberRulesInputMessageModel, void>({
        path: `/api/web/sys/sequencenumberrules/get/edit`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SequenceNumberRules
     * @name WebSysSequencenumberrulesGetList
     * @summary 获取序列号规则详情 (Auth)
     * @request GET:/api/web/sys/sequencenumberrules/get
     * @secure
     */
    webSysSequencenumberrulesGetList: (
      query?: {
        id?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<SequenceNumberRulesDtoMessageModel, void>({
        path: `/api/web/sys/sequencenumberrules/get`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SequenceNumberRules
     * @name WebSysSequencenumberrulesPostCreate
     * @summary 新增序列号规则 (Auth)
     * @request POST:/api/web/sys/sequencenumberrules/post
     * @secure
     */
    webSysSequencenumberrulesPostCreate: (data: CreateSequenceNumberRulesInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/sequencenumberrules/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SequenceNumberRules
     * @name WebSysSequencenumberrulesPutUpdate
     * @summary 修改序列号规则 (Auth)
     * @request PUT:/api/web/sys/sequencenumberrules/put
     * @secure
     */
    webSysSequencenumberrulesPutUpdate: (
      data: CreateSequenceNumberRulesInput,
      query?: {
        id?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/sequencenumberrules/put`,
        method: 'PUT',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SequenceNumberRules
     * @name WebSysSequencenumberrulesDeleteDelete
     * @summary 删除序列号规则 (Auth)
     * @request DELETE:/api/web/sys/sequencenumberrules/delete
     * @secure
     */
    webSysSequencenumberrulesDeleteDelete: (
      query?: {
        id?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<BooleanMessageModel, void>({
        path: `/api/web/sys/sequencenumberrules/delete`,
        method: 'DELETE',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Setting
     * @name WebSysSettingPageCreate
     * @summary 获取所有配置 (Auth policies: Web)
     * @request POST:/api/web/sys/setting/page
     * @secure
     */
    webSysSettingPageCreate: (data: GetSettingInput, params: RequestParams = {}) =>
      this.request<SettingDtoPageModelMessageModel, void>({
        path: `/api/web/sys/setting/page`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Setting
     * @name WebSysSettingKeyList
     * @summary 获取配置key (Auth policies: Web)
     * @request GET:/api/web/sys/setting/key
     * @secure
     */
    webSysSettingKeyList: (params: RequestParams = {}) =>
      this.request<ItemDtoListMessageModel, void>({
        path: `/api/web/sys/setting/key`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Setting
     * @name WebSysSettingCreate
     * @summary 创建配置 (Auth policies: Web)
     * @request POST:/api/web/sys/setting
     * @secure
     */
    webSysSettingCreate: (data: CreateSettingInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/setting`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Setting
     * @name WebSysSettingUpdate
     * @summary 修改配置 (Auth policies: Web)
     * @request PUT:/api/web/sys/setting/{id}
     * @secure
     */
    webSysSettingUpdate: (id: string, data: CreateSettingInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/setting/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Setting
     * @name WebSysSettingDelete
     * @summary 删除配置 (Auth policies: Web)
     * @request DELETE:/api/web/sys/setting/{id}
     * @secure
     */
    webSysSettingDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/setting/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Supplier
     * @name HostSysSupplierPageCreate
     * @summary 获取 (Auth policies: Host)
     * @request POST:/api/host/sys/supplier/page
     * @secure
     */
    hostSysSupplierPageCreate: (data: GetSupplierInput, params: RequestParams = {}) =>
      this.request<SupplierDtoPageModelMessageModel, void>({
        path: `/api/host/sys/supplier/page`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Supplier
     * @name HostSysSupplierCreate
     * @summary 创建 (Auth policies: Host)
     * @request POST:/api/host/sys/supplier
     * @secure
     */
    hostSysSupplierCreate: (data: CreateSupplierInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/host/sys/supplier`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Supplier
     * @name HostSysSupplierUpdate
     * @summary 更新 (Auth policies: Host)
     * @request PUT:/api/host/sys/supplier/{id}
     * @secure
     */
    hostSysSupplierUpdate: (id: string, data: CreateSupplierInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/host/sys/supplier/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Supplier
     * @name HostSysSupplierDelete
     * @summary 删除供应商 (Auth policies: Host)
     * @request DELETE:/api/host/sys/supplier/{id}
     * @secure
     */
    hostSysSupplierDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/host/sys/supplier/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SupplierPlugin
     * @name HostSysSupplierpluginPageCreate
     * @summary 获取 (Auth policies: Host)
     * @request POST:/api/host/sys/supplierplugin/page
     * @secure
     */
    hostSysSupplierpluginPageCreate: (data: GetSupplierPluginInput, params: RequestParams = {}) =>
      this.request<SupplierPluginDtoPageModelMessageModel, void>({
        path: `/api/host/sys/supplierplugin/page`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SupplierPlugin
     * @name HostSysSupplierpluginPlugintypesList
     * @summary 获取插件类型 (Auth policies: Host)
     * @request GET:/api/host/sys/supplierplugin/plugintypes
     * @secure
     */
    hostSysSupplierpluginPlugintypesList: (params: RequestParams = {}) =>
      this.request<PluginItemListMessageModel, void>({
        path: `/api/host/sys/supplierplugin/plugintypes`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SupplierPlugin
     * @name HostSysSupplierpluginPluginconfigList
     * @summary 获取插件类型字段 (Auth policies: Host)
     * @request GET:/api/host/sys/supplierplugin/pluginconfig
     * @secure
     */
    hostSysSupplierpluginPluginconfigList: (
      query?: {
        pluginName?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<string[], void>({
        path: `/api/host/sys/supplierplugin/pluginconfig`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SupplierPlugin
     * @name HostSysSupplierpluginCreate
     * @summary 创建 (Auth policies: Host)
     * @request POST:/api/host/sys/supplierplugin
     * @secure
     */
    hostSysSupplierpluginCreate: (data: CreateSupplierPluginInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/host/sys/supplierplugin`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SupplierPlugin
     * @name HostSysSupplierpluginUpdate
     * @summary 更新 (Auth policies: Host)
     * @request PUT:/api/host/sys/supplierplugin/{id}
     * @secure
     */
    hostSysSupplierpluginUpdate: (id: string, data: CreateSupplierPluginInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/host/sys/supplierplugin/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SupplierPlugin
     * @name HostSysSupplierpluginDelete
     * @summary 删除供应商插件 (Auth policies: Host)
     * @request DELETE:/api/host/sys/supplierplugin/{id}
     * @secure
     */
    hostSysSupplierpluginDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/host/sys/supplierplugin/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmailTemplates
     * @name WebSysSysemailtemplatesPageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/sys/sysemailtemplates/page
     * @secure
     */
    webSysSysemailtemplatesPageCreate: (
      query?: {
        /**
         * Desc:模版名称 名字唯一
         * Default:
         * Nullable:False
         */
        templateName?: string
        /**
         * Desc:配置层级
         * Default:
         * Nullable:False
         * @format int32
         */
        settingLevel?: 0 | 1 | 2 | 3
        /**
         * Desc:模版正文
         * Default:
         * Nullable:False
         */
        templateContent?: string
        /**
         * Desc:模版描述
         * Default:
         * Nullable:True
         */
        templateSubject?: string
        /**
         * Desc:授权失效时间 分钟
         * Default:
         * Nullable:False
         * @format int32
         */
        authFailureTime?: number
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<SysEmailTemplatesDtoPageModelMessageModel, void>({
        path: `/api/web/sys/sysemailtemplates/page`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmailTemplates
     * @name WebSysSysemailtemplatesListList
     * @summary 列表获取所有 (Auth policies: Web)
     * @request GET:/api/web/sys/sysemailtemplates/list
     * @secure
     */
    webSysSysemailtemplatesListList: (
      query?: {
        /**
         * Desc:模版名称 名字唯一
         * Default:
         * Nullable:False
         */
        templateName?: string
        /**
         * Desc:配置层级
         * Default:
         * Nullable:False
         * @format int32
         */
        settingLevel?: 0 | 1 | 2 | 3
        /**
         * Desc:模版正文
         * Default:
         * Nullable:False
         */
        templateContent?: string
        /**
         * Desc:模版描述
         * Default:
         * Nullable:True
         */
        templateSubject?: string
        /**
         * Desc:授权失效时间 分钟
         * Default:
         * Nullable:False
         * @format int32
         */
        authFailureTime?: number
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<SysEmailTemplatesDtoListMessageModel, void>({
        path: `/api/web/sys/sysemailtemplates/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmailTemplates
     * @name WebSysSysemailtemplatesDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/sys/sysemailtemplates/{id}
     * @secure
     */
    webSysSysemailtemplatesDetail: (id: number, params: RequestParams = {}) =>
      this.request<SysEmailTemplatesDtoMessageModel, void>({
        path: `/api/web/sys/sysemailtemplates/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmailTemplates
     * @name WebSysSysemailtemplatesUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/sys/sysemailtemplates/{id}
     * @secure
     */
    webSysSysemailtemplatesUpdate: (id: number, data: CreateSysEmailTemplatesInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/sysemailtemplates/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmailTemplates
     * @name WebSysSysemailtemplatesDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/sys/sysemailtemplates/{id}
     * @secure
     */
    webSysSysemailtemplatesDelete: (id: number, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/sysemailtemplates/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmailTemplates
     * @name WebSysSysemailtemplatesCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/sys/sysemailtemplates
     * @secure
     */
    webSysSysemailtemplatesCreate: (data: CreateSysEmailTemplatesInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/sysemailtemplates`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeAwards
     * @name WebSysEmployeeSysemployeeawardsPageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/sys/employee/sysemployeeawards/page
     * @secure
     */
    webSysEmployeeSysemployeeawardsPageCreate: (
      query?: {
        /**
         * Desc:入职日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        entryDate?: string
        /**
         * Desc:类别
         * Default:
         * Nullable:True
         */
        type?: string
        /**
         * Desc:最后工作日
         * Default:
         * Nullable:True
         * @format date-time
         */
        lastWorkDate?: string
        /**
         * Desc:描述
         * Default:
         * Nullable:True
         */
        discription?: string
        /**
         * Desc:奖项
         * Default:
         * Nullable:True
         */
        awardName?: string
        /**
         * Desc:工号
         * Default:
         * Nullable:True
         */
        jobNumber?: string
        /**
         * Desc:奖励级别
         * Default:
         * Nullable:True
         * @format int32
         */
        level?: number
        /**
         * Desc:获奖日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        date?: string
        /**
         * Desc:雇员Id
         * Default:
         * Nullable:False
         */
        userId?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<SysEmployeeAwardsDtoPageModelMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeawards/page`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeAwards
     * @name WebSysEmployeeSysemployeeawardsListList
     * @summary 列表获取所有 (Auth policies: Web)
     * @request GET:/api/web/sys/employee/sysemployeeawards/list
     * @secure
     */
    webSysEmployeeSysemployeeawardsListList: (
      query?: {
        /**
         * Desc:入职日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        entryDate?: string
        /**
         * Desc:类别
         * Default:
         * Nullable:True
         */
        type?: string
        /**
         * Desc:最后工作日
         * Default:
         * Nullable:True
         * @format date-time
         */
        lastWorkDate?: string
        /**
         * Desc:描述
         * Default:
         * Nullable:True
         */
        discription?: string
        /**
         * Desc:奖项
         * Default:
         * Nullable:True
         */
        awardName?: string
        /**
         * Desc:工号
         * Default:
         * Nullable:True
         */
        jobNumber?: string
        /**
         * Desc:奖励级别
         * Default:
         * Nullable:True
         * @format int32
         */
        level?: number
        /**
         * Desc:获奖日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        date?: string
        /**
         * Desc:雇员Id
         * Default:
         * Nullable:False
         */
        userId?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<SysEmployeeAwardsDtoListMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeawards/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeAwards
     * @name WebSysEmployeeSysemployeeawardsDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/sys/employee/sysemployeeawards/{id}
     * @secure
     */
    webSysEmployeeSysemployeeawardsDetail: (id: string, params: RequestParams = {}) =>
      this.request<SysEmployeeAwardsDtoMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeawards/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeAwards
     * @name WebSysEmployeeSysemployeeawardsUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/sys/employee/sysemployeeawards/{id}
     * @secure
     */
    webSysEmployeeSysemployeeawardsUpdate: (id: string, data: CreateSysEmployeeAwardsInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeawards/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeAwards
     * @name WebSysEmployeeSysemployeeawardsDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/sys/employee/sysemployeeawards/{id}
     * @secure
     */
    webSysEmployeeSysemployeeawardsDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeawards/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeAwards
     * @name WebSysEmployeeSysemployeeawardsCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/sys/employee/sysemployeeawards
     * @secure
     */
    webSysEmployeeSysemployeeawardsCreate: (data: CreateSysEmployeeAwardsInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeawards`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeCertificates
     * @name WebSysEmployeeSysemployeecertificatesPageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/sys/employee/sysemployeecertificates/page
     * @secure
     */
    webSysEmployeeSysemployeecertificatesPageCreate: (
      query?: {
        /**
         * Desc:证书名称
         * Default:
         * Nullable:True
         */
        name?: string
        /**
         * Desc:证书描述
         * Default:
         * Nullable:True
         */
        discription?: string
        /**
         * Desc:获得时间
         * Default:
         * Nullable:True
         * @format date-time
         */
        gettime?: string
        /**
         * Desc:学习证书Id
         * Default:
         * Nullable:True
         */
        learningCertificateId?: string
        /**
         * Desc:有效期至
         * Default:
         * Nullable:True
         * @format date-time
         */
        validuntil?: string
        /**
         * Desc:入职日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        entryDate?: string
        /**
         * Desc:证书编号
         * Default:
         * Nullable:True
         */
        certificateNo?: string
        /**
         * Desc:发证机构
         * Default:
         * Nullable:True
         */
        issauthority?: string
        /**
         * Desc:工号
         * Default:
         * Nullable:True
         */
        jobNumber?: string
        /**
         * Desc:审批状态
         * Default:
         * Nullable:True
         */
        approveStatus?: string
        /**
         * Desc:最后工作日
         * Default:
         * Nullable:True
         * @format date-time
         */
        lastWorkDate?: string
        /**
         * Desc:生效日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        effectiveDate?: string
        /**
         * Desc:证书照类型
         * Default:
         * Nullable:True
         */
        certificateType?: string
        /**
         * Desc:雇员Id
         * Default:
         * Nullable:False
         */
        userId?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<SysEmployeeCertificatesDtoPageModelMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeecertificates/page`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeCertificates
     * @name WebSysEmployeeSysemployeecertificatesListList
     * @summary 列表获取所有 (Auth policies: Web)
     * @request GET:/api/web/sys/employee/sysemployeecertificates/list
     * @secure
     */
    webSysEmployeeSysemployeecertificatesListList: (
      query?: {
        /**
         * Desc:证书名称
         * Default:
         * Nullable:True
         */
        name?: string
        /**
         * Desc:证书描述
         * Default:
         * Nullable:True
         */
        discription?: string
        /**
         * Desc:获得时间
         * Default:
         * Nullable:True
         * @format date-time
         */
        gettime?: string
        /**
         * Desc:学习证书Id
         * Default:
         * Nullable:True
         */
        learningCertificateId?: string
        /**
         * Desc:有效期至
         * Default:
         * Nullable:True
         * @format date-time
         */
        validuntil?: string
        /**
         * Desc:入职日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        entryDate?: string
        /**
         * Desc:证书编号
         * Default:
         * Nullable:True
         */
        certificateNo?: string
        /**
         * Desc:发证机构
         * Default:
         * Nullable:True
         */
        issauthority?: string
        /**
         * Desc:工号
         * Default:
         * Nullable:True
         */
        jobNumber?: string
        /**
         * Desc:审批状态
         * Default:
         * Nullable:True
         */
        approveStatus?: string
        /**
         * Desc:最后工作日
         * Default:
         * Nullable:True
         * @format date-time
         */
        lastWorkDate?: string
        /**
         * Desc:生效日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        effectiveDate?: string
        /**
         * Desc:证书照类型
         * Default:
         * Nullable:True
         */
        certificateType?: string
        /**
         * Desc:雇员Id
         * Default:
         * Nullable:False
         */
        userId?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<SysEmployeeCertificatesDtoListMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeecertificates/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeCertificates
     * @name WebSysEmployeeSysemployeecertificatesDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/sys/employee/sysemployeecertificates/{id}
     * @secure
     */
    webSysEmployeeSysemployeecertificatesDetail: (id: string, params: RequestParams = {}) =>
      this.request<SysEmployeeCertificatesDtoMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeecertificates/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeCertificates
     * @name WebSysEmployeeSysemployeecertificatesUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/sys/employee/sysemployeecertificates/{id}
     * @secure
     */
    webSysEmployeeSysemployeecertificatesUpdate: (id: string, data: CreateSysEmployeeCertificatesInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeecertificates/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeCertificates
     * @name WebSysEmployeeSysemployeecertificatesDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/sys/employee/sysemployeecertificates/{id}
     * @secure
     */
    webSysEmployeeSysemployeecertificatesDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeecertificates/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeCertificates
     * @name WebSysEmployeeSysemployeecertificatesCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/sys/employee/sysemployeecertificates
     * @secure
     */
    webSysEmployeeSysemployeecertificatesCreate: (data: CreateSysEmployeeCertificatesInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeecertificates`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeEducations
     * @name WebSysEmployeeSysemployeeeducationsPageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/sys/employee/sysemployeeeducations/page
     * @secure
     */
    webSysEmployeeSysemployeeeducationsPageCreate: (
      query?: {
        /**
         * Desc:学历
         * Default:
         * Nullable:True
         */
        educationLevel?: string
        /**
         * Desc:工号
         * Default:
         * Nullable:True
         */
        jobNumber?: string
        /**
         * Desc:培养方式
         * Default:
         * Nullable:True
         */
        trainingMode?: string
        /**
         * Desc:工作情况
         * Default:
         * Nullable:True
         */
        performance?: string
        /**
         * Desc:备注
         * Default:
         * Nullable:True
         */
        comments?: string
        /**
         * Desc:学制
         * Default:
         * Nullable:True
         */
        schoolSystme?: string
        /**
         * Desc:专业类别
         * Default:
         * Nullable:True
         */
        specializedfield?: string
        /**
         * Desc:专业授予机构
         * Default:
         * Nullable:True
         */
        grantingInstitution?: string
        /**
         * Desc:专业
         * Default:
         * Nullable:True
         */
        major?: string
        /**
         * Desc:毕业学校
         * Default:
         * Nullable:True
         */
        schoolCode?: string
        /**
         * Desc:班级排名
         * Default:
         * Nullable:True
         */
        classRanking?: string
        /**
         * Desc:学校名繁体
         * Default:
         * Nullable:True
         */
        school_zh_TW?: string
        /**
         * Desc:学位授予国家
         * Default:
         * Nullable:True
         */
        degreeCountry?: string
        /**
         * Desc:专业描述
         * Default:
         * Nullable:True
         */
        mainCourse?: string
        /**
         * Desc:专业描述
         * Default:
         * Nullable:True
         */
        majorDescription?: string
        /**
         * Desc:审批状态
         * Default:
         * Nullable:True
         */
        approveStatus?: string
        /**
         * Desc:学位
         * Default:
         * Nullable:True
         */
        degree?: string
        /**
         * Desc:所在城市
         * Default:
         * Nullable:True
         */
        location?: string
        /**
         * Desc:毕业学校名称
         * Default:
         * Nullable:True
         */
        school?: string
        /**
         * Desc:见证人
         * Default:
         * Nullable:True
         */
        prover?: string
        /**
         * Desc:是否主专业
         * Default:
         * Nullable:True
         */
        isFirstMajor?: boolean
        /**
         * Desc:是否最高学历
         * Default:
         * Nullable:True
         */
        isHighestEducation?: boolean
        /**
         * Desc:是否最高学位
         * Default:
         * Nullable:True
         */
        isHighestDegree?: boolean
        /**
         * Desc:是否第一学历
         * Default:
         * Nullable:True
         */
        isFirstEducationLevel?: boolean
        /**
         * Desc:学习形式
         * Default:
         * Nullable:True
         * @format int32
         */
        studyMode?: number
        /**
         * Desc:毕业类型
         * Default:
         * Nullable:True
         * @format int32
         */
        graduationType?: number
        /**
         * Desc:入职日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        entryDate?: string
        /**
         * Desc:结束日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        enddatE?: string
        /**
         * Desc:最后工作日
         * Default:
         * Nullable:True
         * @format date-time
         */
        lastWorkDate?: string
        /**
         * Desc:毕业学校名称_英文
         * Default:
         * Nullable:True
         */
        school_en_US?: string
        /**
         * Desc:雇员Id
         * Default:
         * Nullable:False
         */
        userId?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<SysEmployeeEducationsDtoPageModelMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeeducations/page`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeEducations
     * @name WebSysEmployeeSysemployeeeducationsListList
     * @summary 列表获取所有 (Auth policies: Web)
     * @request GET:/api/web/sys/employee/sysemployeeeducations/list
     * @secure
     */
    webSysEmployeeSysemployeeeducationsListList: (
      query?: {
        /**
         * Desc:学历
         * Default:
         * Nullable:True
         */
        educationLevel?: string
        /**
         * Desc:工号
         * Default:
         * Nullable:True
         */
        jobNumber?: string
        /**
         * Desc:培养方式
         * Default:
         * Nullable:True
         */
        trainingMode?: string
        /**
         * Desc:工作情况
         * Default:
         * Nullable:True
         */
        performance?: string
        /**
         * Desc:备注
         * Default:
         * Nullable:True
         */
        comments?: string
        /**
         * Desc:学制
         * Default:
         * Nullable:True
         */
        schoolSystme?: string
        /**
         * Desc:专业类别
         * Default:
         * Nullable:True
         */
        specializedfield?: string
        /**
         * Desc:专业授予机构
         * Default:
         * Nullable:True
         */
        grantingInstitution?: string
        /**
         * Desc:专业
         * Default:
         * Nullable:True
         */
        major?: string
        /**
         * Desc:毕业学校
         * Default:
         * Nullable:True
         */
        schoolCode?: string
        /**
         * Desc:班级排名
         * Default:
         * Nullable:True
         */
        classRanking?: string
        /**
         * Desc:学校名繁体
         * Default:
         * Nullable:True
         */
        school_zh_TW?: string
        /**
         * Desc:学位授予国家
         * Default:
         * Nullable:True
         */
        degreeCountry?: string
        /**
         * Desc:专业描述
         * Default:
         * Nullable:True
         */
        mainCourse?: string
        /**
         * Desc:专业描述
         * Default:
         * Nullable:True
         */
        majorDescription?: string
        /**
         * Desc:审批状态
         * Default:
         * Nullable:True
         */
        approveStatus?: string
        /**
         * Desc:学位
         * Default:
         * Nullable:True
         */
        degree?: string
        /**
         * Desc:所在城市
         * Default:
         * Nullable:True
         */
        location?: string
        /**
         * Desc:毕业学校名称
         * Default:
         * Nullable:True
         */
        school?: string
        /**
         * Desc:见证人
         * Default:
         * Nullable:True
         */
        prover?: string
        /**
         * Desc:是否主专业
         * Default:
         * Nullable:True
         */
        isFirstMajor?: boolean
        /**
         * Desc:是否最高学历
         * Default:
         * Nullable:True
         */
        isHighestEducation?: boolean
        /**
         * Desc:是否最高学位
         * Default:
         * Nullable:True
         */
        isHighestDegree?: boolean
        /**
         * Desc:是否第一学历
         * Default:
         * Nullable:True
         */
        isFirstEducationLevel?: boolean
        /**
         * Desc:学习形式
         * Default:
         * Nullable:True
         * @format int32
         */
        studyMode?: number
        /**
         * Desc:毕业类型
         * Default:
         * Nullable:True
         * @format int32
         */
        graduationType?: number
        /**
         * Desc:入职日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        entryDate?: string
        /**
         * Desc:结束日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        enddatE?: string
        /**
         * Desc:最后工作日
         * Default:
         * Nullable:True
         * @format date-time
         */
        lastWorkDate?: string
        /**
         * Desc:毕业学校名称_英文
         * Default:
         * Nullable:True
         */
        school_en_US?: string
        /**
         * Desc:雇员Id
         * Default:
         * Nullable:False
         */
        userId?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<SysEmployeeEducationsDtoListMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeeducations/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeEducations
     * @name WebSysEmployeeSysemployeeeducationsDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/sys/employee/sysemployeeeducations/{id}
     * @secure
     */
    webSysEmployeeSysemployeeeducationsDetail: (id: string, params: RequestParams = {}) =>
      this.request<SysEmployeeEducationsDtoMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeeducations/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeEducations
     * @name WebSysEmployeeSysemployeeeducationsUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/sys/employee/sysemployeeeducations/{id}
     * @secure
     */
    webSysEmployeeSysemployeeeducationsUpdate: (id: string, data: CreateSysEmployeeEducationsInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeeducations/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeEducations
     * @name WebSysEmployeeSysemployeeeducationsDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/sys/employee/sysemployeeeducations/{id}
     * @secure
     */
    webSysEmployeeSysemployeeeducationsDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeeducations/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeEducations
     * @name WebSysEmployeeSysemployeeeducationsCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/sys/employee/sysemployeeeducations
     * @secure
     */
    webSysEmployeeSysemployeeeducationsCreate: (data: CreateSysEmployeeEducationsInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeeducations`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeEntryMaterialRec
     * @name WebSysEmployeeSysemployeeentrymaterialrecPageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/sys/employee/sysemployeeentrymaterialrec/page
     * @secure
     */
    webSysEmployeeSysemployeeentrymaterialrecPageCreate: (
      query?: {
        /**
         * Desc:材料名称
         * Default:
         * Nullable:True
         */
        materialName?: string
        /**
         * Desc:所属单元
         * Default:
         * Nullable:True
         */
        onwerMou?: string
        /**
         * Desc:催办时间
         * Default:
         * Nullable:True
         * @format date-time
         */
        promotTime?: string
        /**
         * Desc:是否法定
         * Default:
         * Nullable:True
         */
        isLegal?: boolean
        /**
         * Desc:是否收到
         * Default:
         * Nullable:True
         */
        hasReceived?: boolean
        /**
         * Desc:退回备注
         * Default:
         * Nullable:True
         */
        giveBackRemark?: string
        /**
         * Desc:材料名称(文本)
         * Default:
         * Nullable:True
         */
        name?: string
        /**
         * Desc:提交日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        submitDate?: string
        /**
         * Desc:组织排序号
         * Default:
         * Nullable:True
         */
        organizationOrderNum?: string
        /**
         * Desc:工号
         * Default:
         * Nullable:True
         */
        jobNumber?: string
        /**
         * Desc:材料说明
         * Default:
         * Nullable:True
         */
        remark?: string
        /**
         * Desc:材料状态
         * Default:
         * Nullable:True
         */
        materialState?: string
        /**
         * Desc:创建时间
         * Default:
         * Nullable:True
         * @format date-time
         */
        createdTime?: string
        /**
         * Desc:准备阶段
         * Default:
         * Nullable:True
         */
        preparationPhase?: string
        /**
         * Desc:接收日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        receiveDate?: string
        /**
         * Desc:退回日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        giveBackDate?: string
        /**
         * Desc:未提交备注
         * Default:
         * Nullable:True
         */
        notSubmittedRemarks?: string
        /**
         * Desc:附件
         * Default:
         * Nullable:True
         */
        materialAttachment?: string
        /**
         * Desc:入职日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        entryDate?: string
        /**
         * Desc:处理备注
         * Default:
         * Nullable:True
         */
        handleRemark?: string
        /**
         * Desc:是否提交
         * Default:
         * Nullable:True
         */
        hasSubmitted?: boolean
        /**
         * Desc:预计提交日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        prepareSubmitDate?: string
        /**
         * Desc:是否必备
         * Default:
         * Nullable:True
         */
        isEssential?: boolean
        /**
         * Desc:所属单元层级
         * Default:
         * Nullable:True
         * @format int32
         */
        onwerMou_TreeLevel?: number
        /**
         * Desc:催办人
         * Default:
         * Nullable:True
         * @format int32
         */
        promoter?: number
        /**
         * Desc:所属单元路径
         * Default:
         * Nullable:True
         */
        onwerMou_TreePath?: string
        /**
         * Desc:材料图片缩略图
         * Default:
         * Nullable:True
         */
        materialImageThumbnail?: string
        /**
         * Desc:最后工作日
         * Default:
         * Nullable:True
         * @format date-time
         */
        lastWorkDate?: string
        /**
         * Desc:雇员Id
         * Default:
         * Nullable:False
         */
        userId?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<SysEmployeeEntryMaterialRecDtoPageModelMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeentrymaterialrec/page`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeEntryMaterialRec
     * @name WebSysEmployeeSysemployeeentrymaterialrecListList
     * @summary 列表获取所有 (Auth policies: Web)
     * @request GET:/api/web/sys/employee/sysemployeeentrymaterialrec/list
     * @secure
     */
    webSysEmployeeSysemployeeentrymaterialrecListList: (
      query?: {
        /**
         * Desc:材料名称
         * Default:
         * Nullable:True
         */
        materialName?: string
        /**
         * Desc:所属单元
         * Default:
         * Nullable:True
         */
        onwerMou?: string
        /**
         * Desc:催办时间
         * Default:
         * Nullable:True
         * @format date-time
         */
        promotTime?: string
        /**
         * Desc:是否法定
         * Default:
         * Nullable:True
         */
        isLegal?: boolean
        /**
         * Desc:是否收到
         * Default:
         * Nullable:True
         */
        hasReceived?: boolean
        /**
         * Desc:退回备注
         * Default:
         * Nullable:True
         */
        giveBackRemark?: string
        /**
         * Desc:材料名称(文本)
         * Default:
         * Nullable:True
         */
        name?: string
        /**
         * Desc:提交日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        submitDate?: string
        /**
         * Desc:组织排序号
         * Default:
         * Nullable:True
         */
        organizationOrderNum?: string
        /**
         * Desc:工号
         * Default:
         * Nullable:True
         */
        jobNumber?: string
        /**
         * Desc:材料说明
         * Default:
         * Nullable:True
         */
        remark?: string
        /**
         * Desc:材料状态
         * Default:
         * Nullable:True
         */
        materialState?: string
        /**
         * Desc:创建时间
         * Default:
         * Nullable:True
         * @format date-time
         */
        createdTime?: string
        /**
         * Desc:准备阶段
         * Default:
         * Nullable:True
         */
        preparationPhase?: string
        /**
         * Desc:接收日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        receiveDate?: string
        /**
         * Desc:退回日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        giveBackDate?: string
        /**
         * Desc:未提交备注
         * Default:
         * Nullable:True
         */
        notSubmittedRemarks?: string
        /**
         * Desc:附件
         * Default:
         * Nullable:True
         */
        materialAttachment?: string
        /**
         * Desc:入职日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        entryDate?: string
        /**
         * Desc:处理备注
         * Default:
         * Nullable:True
         */
        handleRemark?: string
        /**
         * Desc:是否提交
         * Default:
         * Nullable:True
         */
        hasSubmitted?: boolean
        /**
         * Desc:预计提交日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        prepareSubmitDate?: string
        /**
         * Desc:是否必备
         * Default:
         * Nullable:True
         */
        isEssential?: boolean
        /**
         * Desc:所属单元层级
         * Default:
         * Nullable:True
         * @format int32
         */
        onwerMou_TreeLevel?: number
        /**
         * Desc:催办人
         * Default:
         * Nullable:True
         * @format int32
         */
        promoter?: number
        /**
         * Desc:所属单元路径
         * Default:
         * Nullable:True
         */
        onwerMou_TreePath?: string
        /**
         * Desc:材料图片缩略图
         * Default:
         * Nullable:True
         */
        materialImageThumbnail?: string
        /**
         * Desc:最后工作日
         * Default:
         * Nullable:True
         * @format date-time
         */
        lastWorkDate?: string
        /**
         * Desc:雇员Id
         * Default:
         * Nullable:False
         */
        userId?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<SysEmployeeEntryMaterialRecDtoListMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeentrymaterialrec/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeEntryMaterialRec
     * @name WebSysEmployeeSysemployeeentrymaterialrecDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/sys/employee/sysemployeeentrymaterialrec/{id}
     * @secure
     */
    webSysEmployeeSysemployeeentrymaterialrecDetail: (id: string, params: RequestParams = {}) =>
      this.request<SysEmployeeEntryMaterialRecDtoMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeentrymaterialrec/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeEntryMaterialRec
     * @name WebSysEmployeeSysemployeeentrymaterialrecUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/sys/employee/sysemployeeentrymaterialrec/{id}
     * @secure
     */
    webSysEmployeeSysemployeeentrymaterialrecUpdate: (id: string, data: CreateSysEmployeeEntryMaterialRecInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeentrymaterialrec/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeEntryMaterialRec
     * @name WebSysEmployeeSysemployeeentrymaterialrecDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/sys/employee/sysemployeeentrymaterialrec/{id}
     * @secure
     */
    webSysEmployeeSysemployeeentrymaterialrecDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeentrymaterialrec/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeEntryMaterialRec
     * @name WebSysEmployeeSysemployeeentrymaterialrecCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/sys/employee/sysemployeeentrymaterialrec
     * @secure
     */
    webSysEmployeeSysemployeeentrymaterialrecCreate: (data: CreateSysEmployeeEntryMaterialRecInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeentrymaterialrec`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeFamily
     * @name WebSysEmployeeSysemployeefamilyPageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/sys/employee/sysemployeefamily/page
     * @secure
     */
    webSysEmployeeSysemployeefamilyPageCreate: (
      query?: {
        /**
         * Desc:性别
         * Default:
         * Nullable:True
         * @format int32
         */
        gender?: number
        /**
         * Desc:组织排序号
         * Default:
         * Nullable:True
         */
        organizationOrderNum?: string
        /**
         * Desc:与本人关系
         * Default:
         * Nullable:True
         */
        relationType?: string
        /**
         * Desc:职级排序号
         * Default:
         * Nullable:True
         */
        jobLevelOrderNum?: string
        /**
         * Desc:业务修改人
         * Default:
         * Nullable:True
         * @format int32
         */
        businessModifiedBy?: number
        /**
         * Desc:出生日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        birthday?: string
        /**
         * Desc:最后工作日
         * Default:
         * Nullable:True
         * @format date-time
         */
        lastWorkDate?: string
        /**
         * Desc:审批状态
         * Default:
         * Nullable:True
         */
        approveStatus?: string
        /**
         * Desc:邮件
         * Default:
         * Nullable:True
         */
        email?: string
        /**
         * Desc:工号
         * Default:
         * Nullable:True
         */
        jobNumber?: string
        /**
         * Desc:职务
         * Default:
         * Nullable:True
         */
        jobPost?: string
        /**
         * Desc:入职日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        entryDate?: string
        /**
         * Desc:政治面貌
         * Default:
         * Nullable:True
         */
        politicalStatus?: string
        /**
         * Desc:手机号码
         * Default:
         * Nullable:True
         */
        mobile?: string
        /**
         * Desc:与本人关系名称
         * Default:
         * Nullable:True
         */
        relation?: string
        /**
         * Desc:联系电话
         * Default:
         * Nullable:True
         */
        telephone?: string
        /**
         * Desc:成员姓名
         * Default:
         * Nullable:True
         */
        name?: string
        /**
         * Desc:工作单位
         * Default:
         * Nullable:True
         */
        company?: string
        /**
         * Desc:雇员Id
         * Default:
         * Nullable:False
         */
        userId?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<SysEmployeeFamilyDtoPageModelMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeefamily/page`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeFamily
     * @name WebSysEmployeeSysemployeefamilyListList
     * @summary 列表获取所有 (Auth policies: Web)
     * @request GET:/api/web/sys/employee/sysemployeefamily/list
     * @secure
     */
    webSysEmployeeSysemployeefamilyListList: (
      query?: {
        /**
         * Desc:性别
         * Default:
         * Nullable:True
         * @format int32
         */
        gender?: number
        /**
         * Desc:组织排序号
         * Default:
         * Nullable:True
         */
        organizationOrderNum?: string
        /**
         * Desc:与本人关系
         * Default:
         * Nullable:True
         */
        relationType?: string
        /**
         * Desc:职级排序号
         * Default:
         * Nullable:True
         */
        jobLevelOrderNum?: string
        /**
         * Desc:业务修改人
         * Default:
         * Nullable:True
         * @format int32
         */
        businessModifiedBy?: number
        /**
         * Desc:出生日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        birthday?: string
        /**
         * Desc:最后工作日
         * Default:
         * Nullable:True
         * @format date-time
         */
        lastWorkDate?: string
        /**
         * Desc:审批状态
         * Default:
         * Nullable:True
         */
        approveStatus?: string
        /**
         * Desc:邮件
         * Default:
         * Nullable:True
         */
        email?: string
        /**
         * Desc:工号
         * Default:
         * Nullable:True
         */
        jobNumber?: string
        /**
         * Desc:职务
         * Default:
         * Nullable:True
         */
        jobPost?: string
        /**
         * Desc:入职日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        entryDate?: string
        /**
         * Desc:政治面貌
         * Default:
         * Nullable:True
         */
        politicalStatus?: string
        /**
         * Desc:手机号码
         * Default:
         * Nullable:True
         */
        mobile?: string
        /**
         * Desc:与本人关系名称
         * Default:
         * Nullable:True
         */
        relation?: string
        /**
         * Desc:联系电话
         * Default:
         * Nullable:True
         */
        telephone?: string
        /**
         * Desc:成员姓名
         * Default:
         * Nullable:True
         */
        name?: string
        /**
         * Desc:工作单位
         * Default:
         * Nullable:True
         */
        company?: string
        /**
         * Desc:雇员Id
         * Default:
         * Nullable:False
         */
        userId?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<SysEmployeeFamilyDtoListMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeefamily/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeFamily
     * @name WebSysEmployeeSysemployeefamilyDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/sys/employee/sysemployeefamily/{id}
     * @secure
     */
    webSysEmployeeSysemployeefamilyDetail: (id: string, params: RequestParams = {}) =>
      this.request<SysEmployeeFamilyDtoMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeefamily/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeFamily
     * @name WebSysEmployeeSysemployeefamilyUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/sys/employee/sysemployeefamily/{id}
     * @secure
     */
    webSysEmployeeSysemployeefamilyUpdate: (id: string, data: CreateSysEmployeeFamilyInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeefamily/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeFamily
     * @name WebSysEmployeeSysemployeefamilyDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/sys/employee/sysemployeefamily/{id}
     * @secure
     */
    webSysEmployeeSysemployeefamilyDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeefamily/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeFamily
     * @name WebSysEmployeeSysemployeefamilyCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/sys/employee/sysemployeefamily
     * @secure
     */
    webSysEmployeeSysemployeefamilyCreate: (data: CreateSysEmployeeFamilyInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeefamily`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeInfos
     * @name WebSysEmployeeSysemployeeinfosPageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/sys/employee/sysemployeeinfos/page
     * @secure
     */
    webSysEmployeeSysemployeeinfosPageCreate: (
      query?: {
        /**
         * Desc:显示名称
         * Default:
         * Nullable:True
         */
        displayName?: string
        /**
         * Desc:入职时间
         * Default:
         * Nullable:True
         * @format date-time
         */
        entryTime?: string
        /**
         * Desc:别名
         * Default:
         * Nullable:True
         */
        alterName?: string
        /**
         * Desc:手机号
         * Default:
         * Nullable:True
         */
        phoneNumber?: string
        /**
         * Desc:描述
         * Default:
         * Nullable:True
         */
        description?: string
        /**
         * Desc:
         * Default:
         * Nullable:True
         */
        address?: string
        /**
         * Desc:邮箱
         * Default:
         * Nullable:True
         */
        emailAddress?: string
        /**
         * Desc:
         * Default:
         * Nullable:True
         * @format int32
         */
        sex?: number
        /**
         * Desc:生日
         * Default:
         * Nullable:True
         * @format date-time
         */
        birthday?: string
        /**
         * Desc:
         * Default:
         * Nullable:True
         * @format int32
         */
        age?: number
        /**
         * Desc:教育背景
         * Default:
         * Nullable:True
         */
        educationBackground?: string
        /**
         * Desc:工号
         * Default:
         * Nullable:True
         */
        jobNumber?: string
        /**
         * Desc:Id卡类型
         * Default:
         * Nullable:True
         */
        idCardType?: string
        /**
         * Desc:唯一标识号码
         * Default:
         * Nullable:True
         */
        idCardNumber?: string
        /**
         * Desc:毕业时间
         * Default:
         * Nullable:True
         * @format date-time
         */
        graduateDate?: string
        /**
         * Desc:婚姻状态
         * Default:
         * Nullable:True
         */
        maritalStatus?: string
        /**
         * Desc:用户类型
         * Default:
         * Nullable:True
         */
        userType?: string
        /**
         * Desc:
         * Default:
         * Nullable:True
         * @format int32
         */
        origin?: number
        /**
         * Desc:外部标识
         * Default:
         * Nullable:True
         */
        originalId?: string
        /**
         * Desc:户籍所在地
         * Default:
         * Nullable:True
         */
        householdRegister?: string
        /**
         * Desc:民族
         * Default:
         * Nullable:True
         */
        nation?: string
        /**
         * Desc:政治面貌
         * Default:
         * Nullable:True
         */
        politicalOutlook?: string
        /**
         * Desc:籍贯
         * Default:
         * Nullable:True
         */
        nativePlace?: string
        /**
         * Desc:兴趣爱好
         * Default:
         * Nullable:True
         */
        hobbies?: string
        /**
         * Desc:特长
         * Default:
         * Nullable:True
         */
        speciality?: string
        /**
         * Desc:参加工作时间
         * Default:
         * Nullable:True
         * @format date-time
         */
        beganWorkDate?: string
        /**
         * Desc:毕业学校
         * Default:
         * Nullable:True
         */
        graduateFrom?: string
        /**
         * Desc:专业
         * Default:
         * Nullable:True
         */
        major?: string
        /**
         * Desc:工作地址
         * Default:
         * Nullable:True
         */
        officeLocation?: string
        /**
         * Desc:工作电话
         * Default:
         * Nullable:True
         */
        workPhone?: string
        /**
         * Desc:传真
         * Default:
         * Nullable:True
         */
        fax?: string
        /**
         * Desc:座机
         * Default:
         * Nullable:True
         */
        landline?: string
        /**
         * Desc:头像地址
         * Default:
         * Nullable:True
         */
        pictureUrl?: string
        /**
         * Desc:关于我
         * Default:
         * Nullable:True
         */
        aboutMe?: string
        /**
         * Desc:最高学历
         * Default:
         * Nullable:True
         */
        highestEducation?: string
        /**
         * Desc:邮政编码
         * Default:
         * Nullable:True
         */
        postalCode?: string
        /**
         * Desc:对外费率
         * Default:
         * Nullable:True
         * @format double
         */
        externalRate?: number
        /**
         * Desc:内部费率
         * Default:
         * Nullable:True
         * @format double
         */
        internalRate?: number
        /**
         * Desc:币种
         * Default:
         * Nullable:True
         */
        currency?: string
        /**
         * Desc:擅长语言
         * Default:
         * Nullable:True
         */
        goodLanguage?: string
        /**
         * Desc:擅长专业
         * Default:
         * Nullable:True
         */
        goodProfessional?: string
        /**
         * Desc:原执业律师事务所
         * Default:
         * Nullable:True
         */
        formerLawyer?: string
        /**
         * Desc:执业证号
         * Default:
         * Nullable:True
         */
        formerLawyerNumber?: string
        /**
         * Desc:紧急联系人
         * Default:
         * Nullable:True
         */
        emergencyContact?: string
        /**
         * Desc:与其关系
         * Default:
         * Nullable:True
         */
        relationship?: string
        /**
         * Desc:紧急联系人电话
         * Default:
         * Nullable:True
         */
        emergencyContactPhone?: string
        /**
         * Desc:紧急联系人地址
         * Default:
         * Nullable:True
         */
        emergencyContactAddress?: string
        /**
         * Desc:律师关系存放地
         * Default:
         * Nullable:True
         */
        localRelationshipStorage?: string
        /**
         * Desc:律师关系存放日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        localRelationshipTime?: string
        /**
         * Desc:人事档案存放地
         * Default:
         * Nullable:True
         */
        personnelArchivesStorageAgency?: string
        /**
         * Desc:存档号
         * Default:
         * Nullable:True
         */
        archiveNumber?: string
        /**
         * Desc:存档日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        archiveTime?: string
        /**
         * Desc:雇员Id
         * Default:
         * Nullable:False
         */
        userId?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<SysEmployeeInfosDtoPageModelMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeinfos/page`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeInfos
     * @name WebSysEmployeeSysemployeeinfosListList
     * @summary 列表获取所有 (Auth policies: Web)
     * @request GET:/api/web/sys/employee/sysemployeeinfos/list
     * @secure
     */
    webSysEmployeeSysemployeeinfosListList: (
      query?: {
        /**
         * Desc:显示名称
         * Default:
         * Nullable:True
         */
        displayName?: string
        /**
         * Desc:入职时间
         * Default:
         * Nullable:True
         * @format date-time
         */
        entryTime?: string
        /**
         * Desc:别名
         * Default:
         * Nullable:True
         */
        alterName?: string
        /**
         * Desc:手机号
         * Default:
         * Nullable:True
         */
        phoneNumber?: string
        /**
         * Desc:描述
         * Default:
         * Nullable:True
         */
        description?: string
        /**
         * Desc:
         * Default:
         * Nullable:True
         */
        address?: string
        /**
         * Desc:邮箱
         * Default:
         * Nullable:True
         */
        emailAddress?: string
        /**
         * Desc:
         * Default:
         * Nullable:True
         * @format int32
         */
        sex?: number
        /**
         * Desc:生日
         * Default:
         * Nullable:True
         * @format date-time
         */
        birthday?: string
        /**
         * Desc:
         * Default:
         * Nullable:True
         * @format int32
         */
        age?: number
        /**
         * Desc:教育背景
         * Default:
         * Nullable:True
         */
        educationBackground?: string
        /**
         * Desc:工号
         * Default:
         * Nullable:True
         */
        jobNumber?: string
        /**
         * Desc:Id卡类型
         * Default:
         * Nullable:True
         */
        idCardType?: string
        /**
         * Desc:唯一标识号码
         * Default:
         * Nullable:True
         */
        idCardNumber?: string
        /**
         * Desc:毕业时间
         * Default:
         * Nullable:True
         * @format date-time
         */
        graduateDate?: string
        /**
         * Desc:婚姻状态
         * Default:
         * Nullable:True
         */
        maritalStatus?: string
        /**
         * Desc:用户类型
         * Default:
         * Nullable:True
         */
        userType?: string
        /**
         * Desc:
         * Default:
         * Nullable:True
         * @format int32
         */
        origin?: number
        /**
         * Desc:外部标识
         * Default:
         * Nullable:True
         */
        originalId?: string
        /**
         * Desc:户籍所在地
         * Default:
         * Nullable:True
         */
        householdRegister?: string
        /**
         * Desc:民族
         * Default:
         * Nullable:True
         */
        nation?: string
        /**
         * Desc:政治面貌
         * Default:
         * Nullable:True
         */
        politicalOutlook?: string
        /**
         * Desc:籍贯
         * Default:
         * Nullable:True
         */
        nativePlace?: string
        /**
         * Desc:兴趣爱好
         * Default:
         * Nullable:True
         */
        hobbies?: string
        /**
         * Desc:特长
         * Default:
         * Nullable:True
         */
        speciality?: string
        /**
         * Desc:参加工作时间
         * Default:
         * Nullable:True
         * @format date-time
         */
        beganWorkDate?: string
        /**
         * Desc:毕业学校
         * Default:
         * Nullable:True
         */
        graduateFrom?: string
        /**
         * Desc:专业
         * Default:
         * Nullable:True
         */
        major?: string
        /**
         * Desc:工作地址
         * Default:
         * Nullable:True
         */
        officeLocation?: string
        /**
         * Desc:工作电话
         * Default:
         * Nullable:True
         */
        workPhone?: string
        /**
         * Desc:传真
         * Default:
         * Nullable:True
         */
        fax?: string
        /**
         * Desc:座机
         * Default:
         * Nullable:True
         */
        landline?: string
        /**
         * Desc:头像地址
         * Default:
         * Nullable:True
         */
        pictureUrl?: string
        /**
         * Desc:关于我
         * Default:
         * Nullable:True
         */
        aboutMe?: string
        /**
         * Desc:最高学历
         * Default:
         * Nullable:True
         */
        highestEducation?: string
        /**
         * Desc:邮政编码
         * Default:
         * Nullable:True
         */
        postalCode?: string
        /**
         * Desc:对外费率
         * Default:
         * Nullable:True
         * @format double
         */
        externalRate?: number
        /**
         * Desc:内部费率
         * Default:
         * Nullable:True
         * @format double
         */
        internalRate?: number
        /**
         * Desc:币种
         * Default:
         * Nullable:True
         */
        currency?: string
        /**
         * Desc:擅长语言
         * Default:
         * Nullable:True
         */
        goodLanguage?: string
        /**
         * Desc:擅长专业
         * Default:
         * Nullable:True
         */
        goodProfessional?: string
        /**
         * Desc:原执业律师事务所
         * Default:
         * Nullable:True
         */
        formerLawyer?: string
        /**
         * Desc:执业证号
         * Default:
         * Nullable:True
         */
        formerLawyerNumber?: string
        /**
         * Desc:紧急联系人
         * Default:
         * Nullable:True
         */
        emergencyContact?: string
        /**
         * Desc:与其关系
         * Default:
         * Nullable:True
         */
        relationship?: string
        /**
         * Desc:紧急联系人电话
         * Default:
         * Nullable:True
         */
        emergencyContactPhone?: string
        /**
         * Desc:紧急联系人地址
         * Default:
         * Nullable:True
         */
        emergencyContactAddress?: string
        /**
         * Desc:律师关系存放地
         * Default:
         * Nullable:True
         */
        localRelationshipStorage?: string
        /**
         * Desc:律师关系存放日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        localRelationshipTime?: string
        /**
         * Desc:人事档案存放地
         * Default:
         * Nullable:True
         */
        personnelArchivesStorageAgency?: string
        /**
         * Desc:存档号
         * Default:
         * Nullable:True
         */
        archiveNumber?: string
        /**
         * Desc:存档日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        archiveTime?: string
        /**
         * Desc:雇员Id
         * Default:
         * Nullable:False
         */
        userId?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<SysEmployeeInfosDtoListMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeinfos/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeInfos
     * @name WebSysEmployeeSysemployeeinfosDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/sys/employee/sysemployeeinfos/{id}
     * @secure
     */
    webSysEmployeeSysemployeeinfosDetail: (id: string, params: RequestParams = {}) =>
      this.request<SysEmployeeInfosDtoMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeinfos/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeInfos
     * @name WebSysEmployeeSysemployeeinfosUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/sys/employee/sysemployeeinfos/{id}
     * @secure
     */
    webSysEmployeeSysemployeeinfosUpdate: (id: string, data: CreateSysEmployeeInfosInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeinfos/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeInfos
     * @name WebSysEmployeeSysemployeeinfosDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/sys/employee/sysemployeeinfos/{id}
     * @secure
     */
    webSysEmployeeSysemployeeinfosDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeinfos/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeInfos
     * @name WebSysEmployeeSysemployeeinfosCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/sys/employee/sysemployeeinfos
     * @secure
     */
    webSysEmployeeSysemployeeinfosCreate: (data: CreateSysEmployeeInfosInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeinfos`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeJobHistory
     * @name WebSysEmployeeSysemployeejobhistoryPageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/sys/employee/sysemployeejobhistory/page
     * @secure
     */
    webSysEmployeeSysemployeejobhistoryPageCreate: (
      query?: {
        /**
         * Desc:审批状态
         * Default:
         * Nullable:True
         */
        approveStatus?: string
        /**
         * Desc:部门_繁体
         * Default:
         * Nullable:True
         */
        department_zh_TW?: string
        /**
         * Desc:单位名称
         * Default:
         * Nullable:True
         */
        company?: string
        /**
         * Desc:工作业绩
         * Default:
         * Nullable:True
         */
        achievement?: string
        /**
         * Desc:月薪（税前）
         * Default:
         * Nullable:True
         * @format double
         */
        salary?: number
        /**
         * Desc:离职日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        dimissionDate?: string
        /**
         * Desc:职务_英文
         * Default:
         * Nullable:True
         */
        firstJob_en_US?: string
        /**
         * Desc:工作类型
         * Default:
         * Nullable:True
         */
        jobType?: string
        /**
         * Desc:企业规模
         * Default:
         * Nullable:True
         */
        companySize?: string
        /**
         * Desc:工号
         * Default:
         * Nullable:True
         */
        jobNumber?: string
        /**
         * Desc:与本人关系
         * Default:
         * Nullable:True
         */
        proverRelation?: string
        /**
         * Desc:部门_英文
         * Default:
         * Nullable:True
         */
        department_en_US?: string
        /**
         * Desc:证明人联系方式
         * Default:
         * Nullable:True
         */
        proverContactInfo?: string
        /**
         * Desc:下属人数
         * Default:
         * Nullable:True
         * @format int32
         */
        underlingNum?: number
        /**
         * Desc:部门
         * Default:
         * Nullable:True
         */
        department?: string
        /**
         * Desc:证明人
         * Default:
         * Nullable:True
         */
        proverName?: string
        /**
         * Desc:行业类别
         * Default:
         * Nullable:True
         */
        hangye?: string
        /**
         * Desc:单位介绍
         * Default:
         * Nullable:True
         */
        introduction?: string
        /**
         * Desc:职务_繁体
         * Default:
         * Nullable:True
         */
        firstJob_zh_TW?: string
        /**
         * Desc:工作性质
         * Default:
         * Nullable:True
         */
        workKind?: string
        /**
         * Desc:单位类型
         * Default:
         * Nullable:True
         */
        companyType?: string
        /**
         * Desc:开始日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        startDate?: string
        /**
         * Desc:职务
         * Default:
         * Nullable:True
         */
        firstJob?: string
        /**
         * Desc:单位名称_繁体
         * Default:
         * Nullable:True
         */
        company_zh_TW?: string
        /**
         * Desc:职级名称
         * Default:
         * Nullable:True
         */
        jobLevel?: string
        /**
         * Desc:职级名称_繁体
         * Default:
         * Nullable:True
         */
        jobLevel_zh_TW?: string
        /**
         * Desc:是否本单位工作经历
         * Default:
         * Nullable:True
         */
        whetherTheWorkExperienceOfTheUnit?: boolean
        /**
         * Desc:职级名称_英文
         * Default:
         * Nullable:True
         */
        jobLevel_en_US?: string
        /**
         * Desc:职位_繁体
         * Default:
         * Nullable:True
         */
        lastDog_zh_TW?: string
        /**
         * Desc:离职原因
         * Default:
         * Nullable:True
         */
        leaveReason?: string
        /**
         * Desc:入职日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        beginDate?: string
        /**
         * Desc:入职日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        entryDate?: string
        /**
         * Desc:结束日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        stopDate?: string
        /**
         * Desc:任职类型
         * Default:
         * Nullable:True
         */
        serviceType?: string
        /**
         * Desc:职位
         * Default:
         * Nullable:True
         */
        lastJog?: string
        /**
         * Desc:部门全称
         * Default:
         * Nullable:True
         */
        pOIOldAdminNameTreePath?: string
        /**
         * Desc:职位英文
         * Default:
         * Nullable:True
         */
        lastJog_en_US?: string
        /**
         * Desc:工作地址
         * Default:
         * Nullable:True
         */
        address?: string
        /**
         * Desc:最后工作日
         * Default:
         * Nullable:True
         * @format date-time
         */
        lastWorkDate?: string
        /**
         * Desc:证明人职务
         * Default:
         * Nullable:True
         */
        proverJobPost?: string
        /**
         * Desc:工作职责
         * Default:
         * Nullable:True
         */
        responsibility?: string
        /**
         * Desc:雇员Id
         * Default:
         * Nullable:False
         */
        userId?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<SysEmployeeJobHistoryDtoPageModelMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeejobhistory/page`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeJobHistory
     * @name WebSysEmployeeSysemployeejobhistoryListList
     * @summary 列表获取所有 (Auth policies: Web)
     * @request GET:/api/web/sys/employee/sysemployeejobhistory/list
     * @secure
     */
    webSysEmployeeSysemployeejobhistoryListList: (
      query?: {
        /**
         * Desc:审批状态
         * Default:
         * Nullable:True
         */
        approveStatus?: string
        /**
         * Desc:部门_繁体
         * Default:
         * Nullable:True
         */
        department_zh_TW?: string
        /**
         * Desc:单位名称
         * Default:
         * Nullable:True
         */
        company?: string
        /**
         * Desc:工作业绩
         * Default:
         * Nullable:True
         */
        achievement?: string
        /**
         * Desc:月薪（税前）
         * Default:
         * Nullable:True
         * @format double
         */
        salary?: number
        /**
         * Desc:离职日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        dimissionDate?: string
        /**
         * Desc:职务_英文
         * Default:
         * Nullable:True
         */
        firstJob_en_US?: string
        /**
         * Desc:工作类型
         * Default:
         * Nullable:True
         */
        jobType?: string
        /**
         * Desc:企业规模
         * Default:
         * Nullable:True
         */
        companySize?: string
        /**
         * Desc:工号
         * Default:
         * Nullable:True
         */
        jobNumber?: string
        /**
         * Desc:与本人关系
         * Default:
         * Nullable:True
         */
        proverRelation?: string
        /**
         * Desc:部门_英文
         * Default:
         * Nullable:True
         */
        department_en_US?: string
        /**
         * Desc:证明人联系方式
         * Default:
         * Nullable:True
         */
        proverContactInfo?: string
        /**
         * Desc:下属人数
         * Default:
         * Nullable:True
         * @format int32
         */
        underlingNum?: number
        /**
         * Desc:部门
         * Default:
         * Nullable:True
         */
        department?: string
        /**
         * Desc:证明人
         * Default:
         * Nullable:True
         */
        proverName?: string
        /**
         * Desc:行业类别
         * Default:
         * Nullable:True
         */
        hangye?: string
        /**
         * Desc:单位介绍
         * Default:
         * Nullable:True
         */
        introduction?: string
        /**
         * Desc:职务_繁体
         * Default:
         * Nullable:True
         */
        firstJob_zh_TW?: string
        /**
         * Desc:工作性质
         * Default:
         * Nullable:True
         */
        workKind?: string
        /**
         * Desc:单位类型
         * Default:
         * Nullable:True
         */
        companyType?: string
        /**
         * Desc:开始日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        startDate?: string
        /**
         * Desc:职务
         * Default:
         * Nullable:True
         */
        firstJob?: string
        /**
         * Desc:单位名称_繁体
         * Default:
         * Nullable:True
         */
        company_zh_TW?: string
        /**
         * Desc:职级名称
         * Default:
         * Nullable:True
         */
        jobLevel?: string
        /**
         * Desc:职级名称_繁体
         * Default:
         * Nullable:True
         */
        jobLevel_zh_TW?: string
        /**
         * Desc:是否本单位工作经历
         * Default:
         * Nullable:True
         */
        whetherTheWorkExperienceOfTheUnit?: boolean
        /**
         * Desc:职级名称_英文
         * Default:
         * Nullable:True
         */
        jobLevel_en_US?: string
        /**
         * Desc:职位_繁体
         * Default:
         * Nullable:True
         */
        lastDog_zh_TW?: string
        /**
         * Desc:离职原因
         * Default:
         * Nullable:True
         */
        leaveReason?: string
        /**
         * Desc:入职日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        beginDate?: string
        /**
         * Desc:入职日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        entryDate?: string
        /**
         * Desc:结束日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        stopDate?: string
        /**
         * Desc:任职类型
         * Default:
         * Nullable:True
         */
        serviceType?: string
        /**
         * Desc:职位
         * Default:
         * Nullable:True
         */
        lastJog?: string
        /**
         * Desc:部门全称
         * Default:
         * Nullable:True
         */
        pOIOldAdminNameTreePath?: string
        /**
         * Desc:职位英文
         * Default:
         * Nullable:True
         */
        lastJog_en_US?: string
        /**
         * Desc:工作地址
         * Default:
         * Nullable:True
         */
        address?: string
        /**
         * Desc:最后工作日
         * Default:
         * Nullable:True
         * @format date-time
         */
        lastWorkDate?: string
        /**
         * Desc:证明人职务
         * Default:
         * Nullable:True
         */
        proverJobPost?: string
        /**
         * Desc:工作职责
         * Default:
         * Nullable:True
         */
        responsibility?: string
        /**
         * Desc:雇员Id
         * Default:
         * Nullable:False
         */
        userId?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<SysEmployeeJobHistoryDtoListMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeejobhistory/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeJobHistory
     * @name WebSysEmployeeSysemployeejobhistoryDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/sys/employee/sysemployeejobhistory/{id}
     * @secure
     */
    webSysEmployeeSysemployeejobhistoryDetail: (id: string, params: RequestParams = {}) =>
      this.request<SysEmployeeJobHistoryDtoMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeejobhistory/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeJobHistory
     * @name WebSysEmployeeSysemployeejobhistoryUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/sys/employee/sysemployeejobhistory/{id}
     * @secure
     */
    webSysEmployeeSysemployeejobhistoryUpdate: (id: string, data: CreateSysEmployeeJobHistoryInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeejobhistory/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeJobHistory
     * @name WebSysEmployeeSysemployeejobhistoryDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/sys/employee/sysemployeejobhistory/{id}
     * @secure
     */
    webSysEmployeeSysemployeejobhistoryDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeejobhistory/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeJobHistory
     * @name WebSysEmployeeSysemployeejobhistoryCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/sys/employee/sysemployeejobhistory
     * @secure
     */
    webSysEmployeeSysemployeejobhistoryCreate: (data: CreateSysEmployeeJobHistoryInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeejobhistory`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeLanguageAbilities
     * @name WebSysEmployeeSysemployeelanguageabilitiesPageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/sys/employee/sysemployeelanguageabilities/page
     * @secure
     */
    webSysEmployeeSysemployeelanguageabilitiesPageCreate: (
      query?: {
        /**
         * Desc:语言
         * Default:
         * Nullable:True
         */
        language?: string
        /**
         * Desc:技能描述
         * Default:
         * Nullable:True
         */
        languageskill?: string
        /**
         * Desc:审批状态
         * Default:
         * Nullable:True
         */
        approveStatus?: string
        /**
         * Desc:掌握程度
         * Default:
         * Nullable:True
         */
        level?: string
        /**
         * Desc:书写能力
         * Default:
         * Nullable:True
         */
        writingAbility?: string
        /**
         * Desc:工号
         * Default:
         * Nullable:True
         */
        jobNumber?: string
        /**
         * Desc:最后工作日
         * Default:
         * Nullable:True
         * @format date-time
         */
        lastWorkDate?: string
        /**
         * Desc:是否母语
         * Default:
         * Nullable:True
         */
        isNative?: boolean
        /**
         * Desc:入职日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        entryDate?: string
        /**
         * Desc:阅读能力
         * Default:
         * Nullable:True
         */
        readingAbility?: string
        /**
         * Desc:口语能力
         * Default:
         * Nullable:True
         */
        speakingAbility?: string
        /**
         * Desc:雇员Id
         * Default:
         * Nullable:False
         */
        userId?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<SysEmployeeLanguageAbilitiesDtoPageModelMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeelanguageabilities/page`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeLanguageAbilities
     * @name WebSysEmployeeSysemployeelanguageabilitiesListList
     * @summary 列表获取所有 (Auth policies: Web)
     * @request GET:/api/web/sys/employee/sysemployeelanguageabilities/list
     * @secure
     */
    webSysEmployeeSysemployeelanguageabilitiesListList: (
      query?: {
        /**
         * Desc:语言
         * Default:
         * Nullable:True
         */
        language?: string
        /**
         * Desc:技能描述
         * Default:
         * Nullable:True
         */
        languageskill?: string
        /**
         * Desc:审批状态
         * Default:
         * Nullable:True
         */
        approveStatus?: string
        /**
         * Desc:掌握程度
         * Default:
         * Nullable:True
         */
        level?: string
        /**
         * Desc:书写能力
         * Default:
         * Nullable:True
         */
        writingAbility?: string
        /**
         * Desc:工号
         * Default:
         * Nullable:True
         */
        jobNumber?: string
        /**
         * Desc:最后工作日
         * Default:
         * Nullable:True
         * @format date-time
         */
        lastWorkDate?: string
        /**
         * Desc:是否母语
         * Default:
         * Nullable:True
         */
        isNative?: boolean
        /**
         * Desc:入职日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        entryDate?: string
        /**
         * Desc:阅读能力
         * Default:
         * Nullable:True
         */
        readingAbility?: string
        /**
         * Desc:口语能力
         * Default:
         * Nullable:True
         */
        speakingAbility?: string
        /**
         * Desc:雇员Id
         * Default:
         * Nullable:False
         */
        userId?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<SysEmployeeLanguageAbilitiesDtoListMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeelanguageabilities/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeLanguageAbilities
     * @name WebSysEmployeeSysemployeelanguageabilitiesDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/sys/employee/sysemployeelanguageabilities/{id}
     * @secure
     */
    webSysEmployeeSysemployeelanguageabilitiesDetail: (id: string, params: RequestParams = {}) =>
      this.request<SysEmployeeLanguageAbilitiesDtoMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeelanguageabilities/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeLanguageAbilities
     * @name WebSysEmployeeSysemployeelanguageabilitiesUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/sys/employee/sysemployeelanguageabilities/{id}
     * @secure
     */
    webSysEmployeeSysemployeelanguageabilitiesUpdate: (id: string, data: CreateSysEmployeeLanguageAbilitiesInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeelanguageabilities/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeLanguageAbilities
     * @name WebSysEmployeeSysemployeelanguageabilitiesDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/sys/employee/sysemployeelanguageabilities/{id}
     * @secure
     */
    webSysEmployeeSysemployeelanguageabilitiesDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeelanguageabilities/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeLanguageAbilities
     * @name WebSysEmployeeSysemployeelanguageabilitiesCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/sys/employee/sysemployeelanguageabilities
     * @secure
     */
    webSysEmployeeSysemployeelanguageabilitiesCreate: (data: CreateSysEmployeeLanguageAbilitiesInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeelanguageabilities`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeProfessionalTechnicalPostInfo
     * @name WebSysEmployeeSysemployeeprofessionaltechnicalpostinfoPageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/sys/employee/sysemployeeprofessionaltechnicalpostinfo/page
     * @secure
     */
    webSysEmployeeSysemployeeprofessionaltechnicalpostinfoPageCreate: (
      query?: {
        /**
         * Desc:工号
         * Default:
         * Nullable:True
         */
        jobNumber?: string
        /**
         * Desc:组织_层级
         * Default:
         * Nullable:True
         * @format int32
         */
        stdOrganization_TreeLevel?: number
        /**
         * Desc:资格评审单位
         * Default:
         * Nullable:True
         */
        qualificationEvaluationUnit?: string
        /**
         * Desc:备注
         * Default:
         * Nullable:True
         */
        remarks?: string
        /**
         * Desc:共享人
         * Default:
         * Nullable:True
         */
        permissionBy?: string
        /**
         * Desc:是否最高级别
         * Default:
         * Nullable:True
         */
        isTopLevel?: boolean
        /**
         * Desc:组织
         * Default:
         * Nullable:True
         * @format int32
         */
        stdOrganization?: number
        /**
         * Desc:聘任起始时间
         * Default:
         * Nullable:True
         * @format date-time
         */
        appointStartDate?: string
        /**
         * Desc:评定日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        assessmentDate?: string
        /**
         * Desc:所属单位层级
         * Default:
         * Nullable:True
         * @format int32
         */
        onwerMou_TreeLevel?: number
        /**
         * Desc:熟悉专业专长
         * Default:
         * Nullable:True
         */
        professionalSpeciality?: string
        /**
         * Desc:审批状态
         * Default:
         * Nullable:True
         */
        approveStatus?: string
        /**
         * Desc:所属单位
         * Default:
         * Nullable:True
         */
        onwerMou?: string
        /**
         * Desc:最后工作日
         * Default:
         * Nullable:True
         * @format date-time
         */
        lastWorkDate?: string
        /**
         * Desc:入职日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        entryDate?: string
        /**
         * Desc:共享部门
         * Default:
         * Nullable:True
         */
        permissionOrganization?: string
        /**
         * Desc:获取资历途径
         * Default:
         * Nullable:True
         */
        accessWayID?: string
        /**
         * Desc:共享部门，包含下级
         * Default:
         * Nullable:True
         */
        permissionOrganization_SelfAndChildren?: string
        /**
         * Desc:职任专业技术级别
         * Default:
         * Nullable:True
         */
        appointTechnicalLevelID?: string
        /**
         * Desc:职任单位
         * Default:
         * Nullable:True
         */
        appointCompany?: string
        /**
         * Desc:共享部门，仅自己
         * Default:
         * Nullable:True
         */
        permissionOrganization_SelfOnly?: string
        /**
         * Desc:专业技术职务任职资格名称
         * Default:
         * Nullable:True
         */
        technicalPostQualificationsID?: string
        /**
         * Desc:所属单元路径
         * Default:
         * Nullable:True
         */
        onwerMou_TreePath?: string
        /**
         * Desc:组织路径
         * Default:
         * Nullable:True
         */
        stdOrganization_TreePath?: string
        /**
         * Desc:职任专业技术职务名称
         * Default:
         * Nullable:True
         */
        appointTechnicalPostQualificationsID?: string
        /**
         * Desc:专业技术级别
         * Default:
         * Nullable:True
         */
        technicalLevelID?: string
        /**
         * Desc:职任结束时间
         * Default:
         * Nullable:True
         * @format date-time
         */
        appointEndDate?: string
        /**
         * Desc:雇员Id
         * Default:
         * Nullable:False
         */
        userId?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<SysEmployeeProfessionalTechnicalPostInfoDtoPageModelMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeprofessionaltechnicalpostinfo/page`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeProfessionalTechnicalPostInfo
     * @name WebSysEmployeeSysemployeeprofessionaltechnicalpostinfoListList
     * @summary 列表获取所有 (Auth policies: Web)
     * @request GET:/api/web/sys/employee/sysemployeeprofessionaltechnicalpostinfo/list
     * @secure
     */
    webSysEmployeeSysemployeeprofessionaltechnicalpostinfoListList: (
      query?: {
        /**
         * Desc:工号
         * Default:
         * Nullable:True
         */
        jobNumber?: string
        /**
         * Desc:组织_层级
         * Default:
         * Nullable:True
         * @format int32
         */
        stdOrganization_TreeLevel?: number
        /**
         * Desc:资格评审单位
         * Default:
         * Nullable:True
         */
        qualificationEvaluationUnit?: string
        /**
         * Desc:备注
         * Default:
         * Nullable:True
         */
        remarks?: string
        /**
         * Desc:共享人
         * Default:
         * Nullable:True
         */
        permissionBy?: string
        /**
         * Desc:是否最高级别
         * Default:
         * Nullable:True
         */
        isTopLevel?: boolean
        /**
         * Desc:组织
         * Default:
         * Nullable:True
         * @format int32
         */
        stdOrganization?: number
        /**
         * Desc:聘任起始时间
         * Default:
         * Nullable:True
         * @format date-time
         */
        appointStartDate?: string
        /**
         * Desc:评定日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        assessmentDate?: string
        /**
         * Desc:所属单位层级
         * Default:
         * Nullable:True
         * @format int32
         */
        onwerMou_TreeLevel?: number
        /**
         * Desc:熟悉专业专长
         * Default:
         * Nullable:True
         */
        professionalSpeciality?: string
        /**
         * Desc:审批状态
         * Default:
         * Nullable:True
         */
        approveStatus?: string
        /**
         * Desc:所属单位
         * Default:
         * Nullable:True
         */
        onwerMou?: string
        /**
         * Desc:最后工作日
         * Default:
         * Nullable:True
         * @format date-time
         */
        lastWorkDate?: string
        /**
         * Desc:入职日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        entryDate?: string
        /**
         * Desc:共享部门
         * Default:
         * Nullable:True
         */
        permissionOrganization?: string
        /**
         * Desc:获取资历途径
         * Default:
         * Nullable:True
         */
        accessWayID?: string
        /**
         * Desc:共享部门，包含下级
         * Default:
         * Nullable:True
         */
        permissionOrganization_SelfAndChildren?: string
        /**
         * Desc:职任专业技术级别
         * Default:
         * Nullable:True
         */
        appointTechnicalLevelID?: string
        /**
         * Desc:职任单位
         * Default:
         * Nullable:True
         */
        appointCompany?: string
        /**
         * Desc:共享部门，仅自己
         * Default:
         * Nullable:True
         */
        permissionOrganization_SelfOnly?: string
        /**
         * Desc:专业技术职务任职资格名称
         * Default:
         * Nullable:True
         */
        technicalPostQualificationsID?: string
        /**
         * Desc:所属单元路径
         * Default:
         * Nullable:True
         */
        onwerMou_TreePath?: string
        /**
         * Desc:组织路径
         * Default:
         * Nullable:True
         */
        stdOrganization_TreePath?: string
        /**
         * Desc:职任专业技术职务名称
         * Default:
         * Nullable:True
         */
        appointTechnicalPostQualificationsID?: string
        /**
         * Desc:专业技术级别
         * Default:
         * Nullable:True
         */
        technicalLevelID?: string
        /**
         * Desc:职任结束时间
         * Default:
         * Nullable:True
         * @format date-time
         */
        appointEndDate?: string
        /**
         * Desc:雇员Id
         * Default:
         * Nullable:False
         */
        userId?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<SysEmployeeProfessionalTechnicalPostInfoDtoListMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeprofessionaltechnicalpostinfo/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeProfessionalTechnicalPostInfo
     * @name WebSysEmployeeSysemployeeprofessionaltechnicalpostinfoDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/sys/employee/sysemployeeprofessionaltechnicalpostinfo/{id}
     * @secure
     */
    webSysEmployeeSysemployeeprofessionaltechnicalpostinfoDetail: (id: string, params: RequestParams = {}) =>
      this.request<SysEmployeeProfessionalTechnicalPostInfoDtoMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeprofessionaltechnicalpostinfo/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeProfessionalTechnicalPostInfo
     * @name WebSysEmployeeSysemployeeprofessionaltechnicalpostinfoUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/sys/employee/sysemployeeprofessionaltechnicalpostinfo/{id}
     * @secure
     */
    webSysEmployeeSysemployeeprofessionaltechnicalpostinfoUpdate: (
      id: string,
      data: CreateSysEmployeeProfessionalTechnicalPostInfoInput,
      params: RequestParams = {},
    ) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeprofessionaltechnicalpostinfo/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeProfessionalTechnicalPostInfo
     * @name WebSysEmployeeSysemployeeprofessionaltechnicalpostinfoDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/sys/employee/sysemployeeprofessionaltechnicalpostinfo/{id}
     * @secure
     */
    webSysEmployeeSysemployeeprofessionaltechnicalpostinfoDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeprofessionaltechnicalpostinfo/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeProfessionalTechnicalPostInfo
     * @name WebSysEmployeeSysemployeeprofessionaltechnicalpostinfoCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/sys/employee/sysemployeeprofessionaltechnicalpostinfo
     * @secure
     */
    webSysEmployeeSysemployeeprofessionaltechnicalpostinfoCreate: (data: CreateSysEmployeeProfessionalTechnicalPostInfoInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeprofessionaltechnicalpostinfo`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeProjectExperiences
     * @name WebSysEmployeeSysemployeeprojectexperiencesPageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/sys/employee/sysemployeeprojectexperiences/page
     * @secure
     */
    webSysEmployeeSysemployeeprojectexperiencesPageCreate: (
      query?: {
        /**
         * Desc:职位
         * Default:
         * Nullable:True
         */
        jobPosition?: string
        /**
         * Desc:最后工作日
         * Default:
         * Nullable:True
         * @format date-time
         */
        lastWorkDate?: string
        /**
         * Desc:项目人数
         * Default:
         * Nullable:True
         * @format int32
         */
        peopleNumber?: number
        /**
         * Desc:硬件环境
         * Default:
         * Nullable:True
         */
        hardware?: string
        /**
         * Desc:职位拼音排序
         * Default:
         * Nullable:True
         */
        jobPosition_Pinyin?: string
        /**
         * Desc:开发工具
         * Default:
         * Nullable:True
         */
        devTool?: string
        /**
         * Desc:软件环境
         * Default:
         * Nullable:True
         */
        software?: string
        /**
         * Desc:开始日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        startDate?: string
        /**
         * Desc:项目名称
         * Default:
         * Nullable:True
         */
        name?: string
        /**
         * Desc:描述
         * Default:
         * Nullable:True
         */
        description?: string
        /**
         * Desc:项目成果
         * Default:
         * Nullable:True
         */
        achievement?: string
        /**
         * Desc:结束日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        endDate?: string
        /**
         * Desc:项目职责
         * Default:
         * Nullable:True
         */
        responsibility?: string
        /**
         * Desc:入职日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        entryDate?: string
        /**
         * Desc:审批状态
         * Default:
         * Nullable:True
         */
        approveStatus?: string
        /**
         * Desc:工号
         * Default:
         * Nullable:True
         */
        jobNumber?: string
        /**
         * Desc:雇员Id
         * Default:
         * Nullable:False
         */
        userId?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<SysEmployeeProjectExperiencesDtoPageModelMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeprojectexperiences/page`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeProjectExperiences
     * @name WebSysEmployeeSysemployeeprojectexperiencesListList
     * @summary 列表获取所有 (Auth policies: Web)
     * @request GET:/api/web/sys/employee/sysemployeeprojectexperiences/list
     * @secure
     */
    webSysEmployeeSysemployeeprojectexperiencesListList: (
      query?: {
        /**
         * Desc:职位
         * Default:
         * Nullable:True
         */
        jobPosition?: string
        /**
         * Desc:最后工作日
         * Default:
         * Nullable:True
         * @format date-time
         */
        lastWorkDate?: string
        /**
         * Desc:项目人数
         * Default:
         * Nullable:True
         * @format int32
         */
        peopleNumber?: number
        /**
         * Desc:硬件环境
         * Default:
         * Nullable:True
         */
        hardware?: string
        /**
         * Desc:职位拼音排序
         * Default:
         * Nullable:True
         */
        jobPosition_Pinyin?: string
        /**
         * Desc:开发工具
         * Default:
         * Nullable:True
         */
        devTool?: string
        /**
         * Desc:软件环境
         * Default:
         * Nullable:True
         */
        software?: string
        /**
         * Desc:开始日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        startDate?: string
        /**
         * Desc:项目名称
         * Default:
         * Nullable:True
         */
        name?: string
        /**
         * Desc:描述
         * Default:
         * Nullable:True
         */
        description?: string
        /**
         * Desc:项目成果
         * Default:
         * Nullable:True
         */
        achievement?: string
        /**
         * Desc:结束日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        endDate?: string
        /**
         * Desc:项目职责
         * Default:
         * Nullable:True
         */
        responsibility?: string
        /**
         * Desc:入职日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        entryDate?: string
        /**
         * Desc:审批状态
         * Default:
         * Nullable:True
         */
        approveStatus?: string
        /**
         * Desc:工号
         * Default:
         * Nullable:True
         */
        jobNumber?: string
        /**
         * Desc:雇员Id
         * Default:
         * Nullable:False
         */
        userId?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<SysEmployeeProjectExperiencesDtoListMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeprojectexperiences/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeProjectExperiences
     * @name WebSysEmployeeSysemployeeprojectexperiencesDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/sys/employee/sysemployeeprojectexperiences/{id}
     * @secure
     */
    webSysEmployeeSysemployeeprojectexperiencesDetail: (id: string, params: RequestParams = {}) =>
      this.request<SysEmployeeProjectExperiencesDtoMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeprojectexperiences/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeProjectExperiences
     * @name WebSysEmployeeSysemployeeprojectexperiencesUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/sys/employee/sysemployeeprojectexperiences/{id}
     * @secure
     */
    webSysEmployeeSysemployeeprojectexperiencesUpdate: (id: string, data: CreateSysEmployeeProjectExperiencesInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeprojectexperiences/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeProjectExperiences
     * @name WebSysEmployeeSysemployeeprojectexperiencesDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/sys/employee/sysemployeeprojectexperiences/{id}
     * @secure
     */
    webSysEmployeeSysemployeeprojectexperiencesDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeprojectexperiences/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeProjectExperiences
     * @name WebSysEmployeeSysemployeeprojectexperiencesCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/sys/employee/sysemployeeprojectexperiences
     * @secure
     */
    webSysEmployeeSysemployeeprojectexperiencesCreate: (data: CreateSysEmployeeProjectExperiencesInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeprojectexperiences`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeSkill
     * @name WebSysEmployeeSysemployeeskillPageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/sys/employee/sysemployeeskill/page
     * @secure
     */
    webSysEmployeeSysemployeeskillPageCreate: (
      query?: {
        /**
         * Desc:审批状态
         * Default:
         * Nullable:True
         */
        approveStatus?: string
        /**
         * Desc:掌握程度
         * Default:
         * Nullable:True
         */
        level?: string
        /**
         * Desc:使用时间（月）
         * Default:
         * Nullable:True
         * @format int32
         */
        useLength?: number
        /**
         * Desc:入职日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        entryDate?: string
        /**
         * Desc:最后工作日
         * Default:
         * Nullable:True
         * @format date-time
         */
        lastWorkDate?: string
        /**
         * Desc:种类
         * Default:
         * Nullable:True
         */
        category?: string
        /**
         * Desc:工号
         * Default:
         * Nullable:True
         */
        jobNumber?: string
        /**
         * Desc:名称
         * Default:
         * Nullable:True
         */
        name?: string
        /**
         * Desc:雇员Id
         * Default:
         * Nullable:False
         */
        userId?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<SysEmployeeSkillDtoPageModelMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeskill/page`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeSkill
     * @name WebSysEmployeeSysemployeeskillListList
     * @summary 列表获取所有 (Auth policies: Web)
     * @request GET:/api/web/sys/employee/sysemployeeskill/list
     * @secure
     */
    webSysEmployeeSysemployeeskillListList: (
      query?: {
        /**
         * Desc:审批状态
         * Default:
         * Nullable:True
         */
        approveStatus?: string
        /**
         * Desc:掌握程度
         * Default:
         * Nullable:True
         */
        level?: string
        /**
         * Desc:使用时间（月）
         * Default:
         * Nullable:True
         * @format int32
         */
        useLength?: number
        /**
         * Desc:入职日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        entryDate?: string
        /**
         * Desc:最后工作日
         * Default:
         * Nullable:True
         * @format date-time
         */
        lastWorkDate?: string
        /**
         * Desc:种类
         * Default:
         * Nullable:True
         */
        category?: string
        /**
         * Desc:工号
         * Default:
         * Nullable:True
         */
        jobNumber?: string
        /**
         * Desc:名称
         * Default:
         * Nullable:True
         */
        name?: string
        /**
         * Desc:雇员Id
         * Default:
         * Nullable:False
         */
        userId?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<SysEmployeeSkillDtoListMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeskill/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeSkill
     * @name WebSysEmployeeSysemployeeskillDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/sys/employee/sysemployeeskill/{id}
     * @secure
     */
    webSysEmployeeSysemployeeskillDetail: (id: string, params: RequestParams = {}) =>
      this.request<SysEmployeeSkillDtoMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeskill/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeSkill
     * @name WebSysEmployeeSysemployeeskillUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/sys/employee/sysemployeeskill/{id}
     * @secure
     */
    webSysEmployeeSysemployeeskillUpdate: (id: string, data: CreateSysEmployeeSkillInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeskill/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeSkill
     * @name WebSysEmployeeSysemployeeskillDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/sys/employee/sysemployeeskill/{id}
     * @secure
     */
    webSysEmployeeSysemployeeskillDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeskill/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeSkill
     * @name WebSysEmployeeSysemployeeskillCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/sys/employee/sysemployeeskill
     * @secure
     */
    webSysEmployeeSysemployeeskillCreate: (data: CreateSysEmployeeSkillInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeskill`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeTrainings
     * @name WebSysEmployeeSysemployeetrainingsPageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/sys/employee/sysemployeetrainings/page
     * @secure
     */
    webSysEmployeeSysemployeetrainingsPageCreate: (
      query?: {
        /**
         * Desc:培训描述
         * Default:
         * Nullable:True
         */
        discription?: string
        /**
         * Desc:是否获得勋章
         * Default:
         * Nullable:True
         */
        isHaveMedal?: boolean
        /**
         * Desc:开始日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        startDate?: string
        /**
         * Desc:适用场景
         * Default:
         * Nullable:True
         */
        applyScene?: string
        /**
         * Desc:培训机构
         * Default:
         * Nullable:True
         */
        trainingAgency?: string
        /**
         * Desc:培训成绩
         * Default:
         * Nullable:True
         */
        result?: string
        /**
         * Desc:培训表现
         * Default:
         * Nullable:True
         */
        evaluation?: string
        /**
         * Desc:获得学分
         * Default:
         * Nullable:True
         * @format double
         */
        getCredit?: number
        /**
         * Desc:结束日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        stopDate?: string
        /**
         * Desc:名称
         * Default:
         * Nullable:True
         */
        course?: string
        /**
         * Desc:最后工作日
         * Default:
         * Nullable:True
         * @format date-time
         */
        lastWorkDate?: string
        /**
         * Desc:入职日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        entryDate?: string
        /**
         * Desc:培训类别
         * Default:
         * Nullable:True
         */
        trainingCategory?: string
        /**
         * Desc:培训类别名称
         * Default:
         * Nullable:True
         */
        trainType?: string
        /**
         * Desc:课时总计
         * Default:
         * Nullable:True
         */
        timeToTile?: string
        /**
         * Desc:及格状态
         * Default:
         * Nullable:True
         */
        passStatus?: string
        /**
         * Desc:工号
         * Default:
         * Nullable:True
         */
        jobNumber?: string
        /**
         * Desc:审批状态
         * Default:
         * Nullable:True
         */
        approveStatus?: string
        /**
         * Desc:辅导导师
         * Default:
         * Nullable:True
         * @format int32
         */
        mentorUserId?: number
        /**
         * Desc:获得证书
         * Default:
         * Nullable:True
         */
        certificate?: string
        /**
         * Desc:完成状态
         * Default:
         * Nullable:True
         */
        completionStatus?: string
        /**
         * Desc:培训活动编号
         * Default:
         * Nullable:True
         */
        trainingActivityNum?: string
        /**
         * Desc:雇员Id
         * Default:
         * Nullable:False
         */
        userId?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<SysEmployeeTrainingsDtoPageModelMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeetrainings/page`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeTrainings
     * @name WebSysEmployeeSysemployeetrainingsListList
     * @summary 列表获取所有 (Auth policies: Web)
     * @request GET:/api/web/sys/employee/sysemployeetrainings/list
     * @secure
     */
    webSysEmployeeSysemployeetrainingsListList: (
      query?: {
        /**
         * Desc:培训描述
         * Default:
         * Nullable:True
         */
        discription?: string
        /**
         * Desc:是否获得勋章
         * Default:
         * Nullable:True
         */
        isHaveMedal?: boolean
        /**
         * Desc:开始日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        startDate?: string
        /**
         * Desc:适用场景
         * Default:
         * Nullable:True
         */
        applyScene?: string
        /**
         * Desc:培训机构
         * Default:
         * Nullable:True
         */
        trainingAgency?: string
        /**
         * Desc:培训成绩
         * Default:
         * Nullable:True
         */
        result?: string
        /**
         * Desc:培训表现
         * Default:
         * Nullable:True
         */
        evaluation?: string
        /**
         * Desc:获得学分
         * Default:
         * Nullable:True
         * @format double
         */
        getCredit?: number
        /**
         * Desc:结束日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        stopDate?: string
        /**
         * Desc:名称
         * Default:
         * Nullable:True
         */
        course?: string
        /**
         * Desc:最后工作日
         * Default:
         * Nullable:True
         * @format date-time
         */
        lastWorkDate?: string
        /**
         * Desc:入职日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        entryDate?: string
        /**
         * Desc:培训类别
         * Default:
         * Nullable:True
         */
        trainingCategory?: string
        /**
         * Desc:培训类别名称
         * Default:
         * Nullable:True
         */
        trainType?: string
        /**
         * Desc:课时总计
         * Default:
         * Nullable:True
         */
        timeToTile?: string
        /**
         * Desc:及格状态
         * Default:
         * Nullable:True
         */
        passStatus?: string
        /**
         * Desc:工号
         * Default:
         * Nullable:True
         */
        jobNumber?: string
        /**
         * Desc:审批状态
         * Default:
         * Nullable:True
         */
        approveStatus?: string
        /**
         * Desc:辅导导师
         * Default:
         * Nullable:True
         * @format int32
         */
        mentorUserId?: number
        /**
         * Desc:获得证书
         * Default:
         * Nullable:True
         */
        certificate?: string
        /**
         * Desc:完成状态
         * Default:
         * Nullable:True
         */
        completionStatus?: string
        /**
         * Desc:培训活动编号
         * Default:
         * Nullable:True
         */
        trainingActivityNum?: string
        /**
         * Desc:雇员Id
         * Default:
         * Nullable:False
         */
        userId?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<SysEmployeeTrainingsDtoListMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeetrainings/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeTrainings
     * @name WebSysEmployeeSysemployeetrainingsDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/sys/employee/sysemployeetrainings/{id}
     * @secure
     */
    webSysEmployeeSysemployeetrainingsDetail: (id: string, params: RequestParams = {}) =>
      this.request<SysEmployeeTrainingsDtoMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeetrainings/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeTrainings
     * @name WebSysEmployeeSysemployeetrainingsUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/sys/employee/sysemployeetrainings/{id}
     * @secure
     */
    webSysEmployeeSysemployeetrainingsUpdate: (id: string, data: CreateSysEmployeeTrainingsInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeetrainings/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeTrainings
     * @name WebSysEmployeeSysemployeetrainingsDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/sys/employee/sysemployeetrainings/{id}
     * @secure
     */
    webSysEmployeeSysemployeetrainingsDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeetrainings/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeTrainings
     * @name WebSysEmployeeSysemployeetrainingsCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/sys/employee/sysemployeetrainings
     * @secure
     */
    webSysEmployeeSysemployeetrainingsCreate: (data: CreateSysEmployeeTrainingsInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeetrainings`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeVocationalQualificationInfo
     * @name WebSysEmployeeSysemployeevocationalqualificationinfoPageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/sys/employee/sysemployeevocationalqualificationinfo/page
     * @secure
     */
    webSysEmployeeSysemployeevocationalqualificationinfoPageCreate: (
      query?: {
        /**
         * Desc:资格名称
         * Default:
         * Nullable:True
         */
        qualificationNameID?: string
        /**
         * Desc:资格级别
         * Default:
         * Nullable:True
         */
        qualificationLevelID?: string
        /**
         * Desc:组织路径
         * Default:
         * Nullable:True
         */
        stdOrganization_TreePath?: string
        /**
         * Desc:获取资格途径
         * Default:
         * Nullable:True
         */
        accessWayID?: string
        /**
         * Desc:证书附件
         * Default:
         * Nullable:True
         */
        accessory?: string
        /**
         * Desc:组织层级
         * Default:
         * Nullable:True
         * @format int32
         */
        stdOrganization_TreeLevel?: number
        /**
         * Desc:共享部门_包含下级
         * Default:
         * Nullable:True
         */
        permissionOrganization_SelfAndChildren?: string
        /**
         * Desc:工号
         * Default:
         * Nullable:True
         */
        jobNumber?: string
        /**
         * Desc:资格类型
         * Default:
         * Nullable:True
         */
        qualificationTypeID?: string
        /**
         * Desc:共享部门
         * Default:
         * Nullable:True
         */
        permissionOrganization?: string
        /**
         * Desc:取得日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        dateOfAcquisition?: string
        /**
         * Desc:共享人
         * Default:
         * Nullable:True
         */
        permissionBy?: string
        /**
         * Desc:证书编号
         * Default:
         * Nullable:True
         */
        certificateNumber?: string
        /**
         * Desc:所属单元_层级
         * Default:
         * Nullable:True
         * @format int32
         */
        onverMou_TreeLevel?: number
        /**
         * Desc:有效期至
         * Default:
         * Nullable:True
         * @format date-time
         */
        validUntil?: string
        /**
         * Desc:入职日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        entryDate?: string
        /**
         * Desc:期限类型
         * Default:
         * Nullable:True
         */
        termTypeId?: string
        /**
         * Desc:是否最高等级
         * Default:
         * Nullable:True
         */
        supremeGrade?: boolean
        /**
         * Desc:申请类型
         * Default:
         * Nullable:True
         */
        applicationTypeId?: string
        /**
         * Desc:最后工作日
         * Default:
         * Nullable:True
         * @format date-time
         */
        lastWorkDate?: string
        /**
         * Desc:所属单位
         * Default:
         * Nullable:True
         */
        ownerMou?: string
        /**
         * Desc:所属单位路径
         * Default:
         * Nullable:True
         */
        ownerMou_TreePath?: string
        /**
         * Desc:专业
         * Default:
         * Nullable:True
         */
        professional?: string
        /**
         * Desc:组织
         * Default:
         * Nullable:True
         */
        stdOrganization?: string
        /**
         * Desc:证据来源
         * Default:
         * Nullable:True
         */
        sourceLicensiesID?: string
        /**
         * Desc:共享部门_仅自己
         * Default:
         * Nullable:True
         */
        permissionOrganization_SelfOnly?: string
        /**
         * Desc:审批状态
         * Default:
         * Nullable:True
         */
        approveStatus?: string
        /**
         * Desc:发布机构
         * Default:
         * Nullable:True
         */
        issueUnit?: string
        /**
         * Desc:证书编号
         * Default:
         * Nullable:True
         */
        certificateNumberUpper?: string
        /**
         * Desc:雇员Id
         * Default:
         * Nullable:False
         */
        userId?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<SysEmployeeVocationalQualificationInfoDtoPageModelMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeevocationalqualificationinfo/page`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeVocationalQualificationInfo
     * @name WebSysEmployeeSysemployeevocationalqualificationinfoListList
     * @summary 列表获取所有 (Auth policies: Web)
     * @request GET:/api/web/sys/employee/sysemployeevocationalqualificationinfo/list
     * @secure
     */
    webSysEmployeeSysemployeevocationalqualificationinfoListList: (
      query?: {
        /**
         * Desc:资格名称
         * Default:
         * Nullable:True
         */
        qualificationNameID?: string
        /**
         * Desc:资格级别
         * Default:
         * Nullable:True
         */
        qualificationLevelID?: string
        /**
         * Desc:组织路径
         * Default:
         * Nullable:True
         */
        stdOrganization_TreePath?: string
        /**
         * Desc:获取资格途径
         * Default:
         * Nullable:True
         */
        accessWayID?: string
        /**
         * Desc:证书附件
         * Default:
         * Nullable:True
         */
        accessory?: string
        /**
         * Desc:组织层级
         * Default:
         * Nullable:True
         * @format int32
         */
        stdOrganization_TreeLevel?: number
        /**
         * Desc:共享部门_包含下级
         * Default:
         * Nullable:True
         */
        permissionOrganization_SelfAndChildren?: string
        /**
         * Desc:工号
         * Default:
         * Nullable:True
         */
        jobNumber?: string
        /**
         * Desc:资格类型
         * Default:
         * Nullable:True
         */
        qualificationTypeID?: string
        /**
         * Desc:共享部门
         * Default:
         * Nullable:True
         */
        permissionOrganization?: string
        /**
         * Desc:取得日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        dateOfAcquisition?: string
        /**
         * Desc:共享人
         * Default:
         * Nullable:True
         */
        permissionBy?: string
        /**
         * Desc:证书编号
         * Default:
         * Nullable:True
         */
        certificateNumber?: string
        /**
         * Desc:所属单元_层级
         * Default:
         * Nullable:True
         * @format int32
         */
        onverMou_TreeLevel?: number
        /**
         * Desc:有效期至
         * Default:
         * Nullable:True
         * @format date-time
         */
        validUntil?: string
        /**
         * Desc:入职日期
         * Default:
         * Nullable:True
         * @format date-time
         */
        entryDate?: string
        /**
         * Desc:期限类型
         * Default:
         * Nullable:True
         */
        termTypeId?: string
        /**
         * Desc:是否最高等级
         * Default:
         * Nullable:True
         */
        supremeGrade?: boolean
        /**
         * Desc:申请类型
         * Default:
         * Nullable:True
         */
        applicationTypeId?: string
        /**
         * Desc:最后工作日
         * Default:
         * Nullable:True
         * @format date-time
         */
        lastWorkDate?: string
        /**
         * Desc:所属单位
         * Default:
         * Nullable:True
         */
        ownerMou?: string
        /**
         * Desc:所属单位路径
         * Default:
         * Nullable:True
         */
        ownerMou_TreePath?: string
        /**
         * Desc:专业
         * Default:
         * Nullable:True
         */
        professional?: string
        /**
         * Desc:组织
         * Default:
         * Nullable:True
         */
        stdOrganization?: string
        /**
         * Desc:证据来源
         * Default:
         * Nullable:True
         */
        sourceLicensiesID?: string
        /**
         * Desc:共享部门_仅自己
         * Default:
         * Nullable:True
         */
        permissionOrganization_SelfOnly?: string
        /**
         * Desc:审批状态
         * Default:
         * Nullable:True
         */
        approveStatus?: string
        /**
         * Desc:发布机构
         * Default:
         * Nullable:True
         */
        issueUnit?: string
        /**
         * Desc:证书编号
         * Default:
         * Nullable:True
         */
        certificateNumberUpper?: string
        /**
         * Desc:雇员Id
         * Default:
         * Nullable:False
         */
        userId?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<SysEmployeeVocationalQualificationInfoDtoListMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeevocationalqualificationinfo/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeVocationalQualificationInfo
     * @name WebSysEmployeeSysemployeevocationalqualificationinfoDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/sys/employee/sysemployeevocationalqualificationinfo/{id}
     * @secure
     */
    webSysEmployeeSysemployeevocationalqualificationinfoDetail: (id: string, params: RequestParams = {}) =>
      this.request<SysEmployeeVocationalQualificationInfoDtoMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeevocationalqualificationinfo/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeVocationalQualificationInfo
     * @name WebSysEmployeeSysemployeevocationalqualificationinfoUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/sys/employee/sysemployeevocationalqualificationinfo/{id}
     * @secure
     */
    webSysEmployeeSysemployeevocationalqualificationinfoUpdate: (
      id: string,
      data: CreateSysEmployeeVocationalQualificationInfoInput,
      params: RequestParams = {},
    ) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeevocationalqualificationinfo/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeVocationalQualificationInfo
     * @name WebSysEmployeeSysemployeevocationalqualificationinfoDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/sys/employee/sysemployeevocationalqualificationinfo/{id}
     * @secure
     */
    webSysEmployeeSysemployeevocationalqualificationinfoDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeevocationalqualificationinfo/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysEmployeeVocationalQualificationInfo
     * @name WebSysEmployeeSysemployeevocationalqualificationinfoCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/sys/employee/sysemployeevocationalqualificationinfo
     * @secure
     */
    webSysEmployeeSysemployeevocationalqualificationinfoCreate: (data: CreateSysEmployeeVocationalQualificationInfoInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeevocationalqualificationinfo`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysExcelExportCenter
     * @name WebSysSysexcelexportcenterPageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/sys/sysexcelexportcenter/page
     * @secure
     */
    webSysSysexcelexportcenterPageCreate: (
      query?: {
        /** 导出文件地址 */
        path?: string
        /**
         * 文件大小
         * @format int32
         */
        size?: number
        /**
         * 请求耗时 单位秒
         * @format int32
         */
        requestDuration?: number
        /** 模块 */
        module?: string
        /** 参数 */
        searchParameter?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<SysExcelExportCenterDtoPageModelMessageModel, void>({
        path: `/api/web/sys/sysexcelexportcenter/page`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysExcelExportCenter
     * @name WebSysSysexcelexportcenterListList
     * @summary 列表获取所有 (Auth policies: Web)
     * @request GET:/api/web/sys/sysexcelexportcenter/list
     * @secure
     */
    webSysSysexcelexportcenterListList: (
      query?: {
        /** 导出文件地址 */
        path?: string
        /**
         * 文件大小
         * @format int32
         */
        size?: number
        /**
         * 请求耗时 单位秒
         * @format int32
         */
        requestDuration?: number
        /** 模块 */
        module?: string
        /** 参数 */
        searchParameter?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<SysExcelExportCenterDtoListMessageModel, void>({
        path: `/api/web/sys/sysexcelexportcenter/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysExcelExportCenter
     * @name WebSysSysexcelexportcenterDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/sys/sysexcelexportcenter/{id}
     * @secure
     */
    webSysSysexcelexportcenterDetail: (id: string, params: RequestParams = {}) =>
      this.request<SysExcelExportCenterDtoMessageModel, void>({
        path: `/api/web/sys/sysexcelexportcenter/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysExcelExportCenter
     * @name WebSysSysexcelexportcenterUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/sys/sysexcelexportcenter/{id}
     * @secure
     */
    webSysSysexcelexportcenterUpdate: (id: string, data: CreateSysExcelExportCenterInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/sysexcelexportcenter/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysExcelExportCenter
     * @name WebSysSysexcelexportcenterDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/sys/sysexcelexportcenter/{id}
     * @secure
     */
    webSysSysexcelexportcenterDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/sysexcelexportcenter/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysExcelExportCenter
     * @name WebSysSysexcelexportcenterCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/sys/sysexcelexportcenter
     * @secure
     */
    webSysSysexcelexportcenterCreate: (data: CreateSysExcelExportCenterInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/sysexcelexportcenter`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysExternalNotificationHistories
     * @name WebSysSysexternalnotificationhistoriesPageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/sys/sysexternalnotificationhistories/page
     * @secure
     */
    webSysSysexternalnotificationhistoriesPageCreate: (
      query?: {
        /**
         * Desc:正文
         * Default:
         * Nullable:False
         */
        notificationContent?: string
        /**
         * Desc:通知类型
         * Default:
         * Nullable:False
         * @format int32
         */
        notificationType?: 0 | 1 | 2 | 3
        /**
         * Desc:关联Id
         * Default:
         * Nullable:True
         */
        associatedId?: string
        /**
         * Desc:参数
         * Default:
         * Nullable:True
         */
        parameterJson?: string
        /**
         * Desc:返回参数
         * Default:
         * Nullable:True
         */
        responseJson?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<SysExternalNotificationHistoriesDtoPageModelMessageModel, void>({
        path: `/api/web/sys/sysexternalnotificationhistories/page`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysExternalNotificationHistories
     * @name WebSysSysexternalnotificationhistoriesListList
     * @summary 列表获取所有 (Auth policies: Web)
     * @request GET:/api/web/sys/sysexternalnotificationhistories/list
     * @secure
     */
    webSysSysexternalnotificationhistoriesListList: (
      query?: {
        /**
         * Desc:正文
         * Default:
         * Nullable:False
         */
        notificationContent?: string
        /**
         * Desc:通知类型
         * Default:
         * Nullable:False
         * @format int32
         */
        notificationType?: 0 | 1 | 2 | 3
        /**
         * Desc:关联Id
         * Default:
         * Nullable:True
         */
        associatedId?: string
        /**
         * Desc:参数
         * Default:
         * Nullable:True
         */
        parameterJson?: string
        /**
         * Desc:返回参数
         * Default:
         * Nullable:True
         */
        responseJson?: string
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<SysExternalNotificationHistoriesDtoListMessageModel, void>({
        path: `/api/web/sys/sysexternalnotificationhistories/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysExternalNotificationHistories
     * @name WebSysSysexternalnotificationhistoriesDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/sys/sysexternalnotificationhistories/{id}
     * @secure
     */
    webSysSysexternalnotificationhistoriesDetail: (id: number, params: RequestParams = {}) =>
      this.request<SysExternalNotificationHistoriesDtoMessageModel, void>({
        path: `/api/web/sys/sysexternalnotificationhistories/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysExternalNotificationHistories
     * @name WebSysSysexternalnotificationhistoriesUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/sys/sysexternalnotificationhistories/{id}
     * @secure
     */
    webSysSysexternalnotificationhistoriesUpdate: (id: number, data: CreateSysExternalNotificationHistoriesInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/sysexternalnotificationhistories/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysExternalNotificationHistories
     * @name WebSysSysexternalnotificationhistoriesDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/sys/sysexternalnotificationhistories/{id}
     * @secure
     */
    webSysSysexternalnotificationhistoriesDelete: (id: number, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/sysexternalnotificationhistories/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysExternalNotificationHistories
     * @name WebSysSysexternalnotificationhistoriesCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/sys/sysexternalnotificationhistories
     * @secure
     */
    webSysSysexternalnotificationhistoriesCreate: (data: CreateSysExternalNotificationHistoriesInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/sysexternalnotificationhistories`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysGeneralCodeGroups
     * @name WebSysSysgeneralcodegroupsPageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/sys/sysgeneralcodegroups/page
     * @secure
     */
    webSysSysgeneralcodegroupsPageCreate: (
      query?: {
        /**
         * Desc:名称
         * Default:
         * Nullable:False
         */
        name?: string
        /**
         * Desc:代码
         * Default:
         * Nullable:False
         */
        code?: string
        /**
         * Desc:父级代码
         * Default:
         * Nullable:True
         */
        parentCode?: string
        /**
         * Desc:模块
         * Default:
         * Nullable:False
         * @format int32
         */
        funcGroup?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<SysGeneralCodeGroupsDtoPageModelMessageModel, void>({
        path: `/api/web/sys/sysgeneralcodegroups/page`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysGeneralCodeGroups
     * @name WebSysSysgeneralcodegroupsListList
     * @summary 列表获取所有 (Auth policies: Web)
     * @request GET:/api/web/sys/sysgeneralcodegroups/list
     * @secure
     */
    webSysSysgeneralcodegroupsListList: (
      query?: {
        /**
         * Desc:名称
         * Default:
         * Nullable:False
         */
        name?: string
        /**
         * Desc:代码
         * Default:
         * Nullable:False
         */
        code?: string
        /**
         * Desc:父级代码
         * Default:
         * Nullable:True
         */
        parentCode?: string
        /**
         * Desc:模块
         * Default:
         * Nullable:False
         * @format int32
         */
        funcGroup?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<SysGeneralCodeGroupsDtoListMessageModel, void>({
        path: `/api/web/sys/sysgeneralcodegroups/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysGeneralCodeGroups
     * @name WebSysSysgeneralcodegroupsDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/sys/sysgeneralcodegroups/{id}
     * @secure
     */
    webSysSysgeneralcodegroupsDetail: (id: number, params: RequestParams = {}) =>
      this.request<SysGeneralCodeGroupsDtoMessageModel, void>({
        path: `/api/web/sys/sysgeneralcodegroups/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysGeneralCodeGroups
     * @name WebSysSysgeneralcodegroupsUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/sys/sysgeneralcodegroups/{id}
     * @secure
     */
    webSysSysgeneralcodegroupsUpdate: (id: number, data: CreateSysGeneralCodeGroupsInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/sysgeneralcodegroups/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysGeneralCodeGroups
     * @name WebSysSysgeneralcodegroupsDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/sys/sysgeneralcodegroups/{id}
     * @secure
     */
    webSysSysgeneralcodegroupsDelete: (id: number, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/sysgeneralcodegroups/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysGeneralCodeGroups
     * @name WebSysSysgeneralcodegroupsCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/sys/sysgeneralcodegroups
     * @secure
     */
    webSysSysgeneralcodegroupsCreate: (data: CreateSysGeneralCodeGroupsInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/sysgeneralcodegroups`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysRoleGroups
     * @name WebSysSysrolegroupsPageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/sys/sysrolegroups/page
     * @secure
     */
    webSysSysrolegroupsPageCreate: (data: GetSysRoleGroupsInput, params: RequestParams = {}) =>
      this.request<SysRoleGroupsDtoPageModelMessageModel, void>({
        path: `/api/web/sys/sysrolegroups/page`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysRoleGroups
     * @name WebSysSysrolegroupsListList
     * @summary 列表获取所有 (Auth policies: Web)
     * @request GET:/api/web/sys/sysrolegroups/list
     * @secure
     */
    webSysSysrolegroupsListList: (
      query?: {
        /**
         * Desc:分组名称
         * Default:
         * Nullable:False
         */
        name?: string
        /** 角色名称 */
        roleName?: string
        /**
         * Desc:排序
         * Default:0
         * Nullable:True
         * @format int32
         */
        sort?: number
        /**
         * 权限类型
         * @format int32
         */
        roleType?: 0 | 1 | 2 | 3 | 4
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<SysRoleGroupsDtoListMessageModel, void>({
        path: `/api/web/sys/sysrolegroups/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysRoleGroups
     * @name WebSysSysrolegroupsDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/sys/sysrolegroups/{id}
     * @secure
     */
    webSysSysrolegroupsDetail: (id: string, params: RequestParams = {}) =>
      this.request<SysRoleGroupsDtoMessageModel, void>({
        path: `/api/web/sys/sysrolegroups/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysRoleGroups
     * @name WebSysSysrolegroupsUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/sys/sysrolegroups/{id}
     * @secure
     */
    webSysSysrolegroupsUpdate: (id: string, data: CreateSysRoleGroupsInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/sysrolegroups/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysRoleGroups
     * @name WebSysSysrolegroupsDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/sys/sysrolegroups/{id}
     * @secure
     */
    webSysSysrolegroupsDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/sysrolegroups/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SysRoleGroups
     * @name WebSysSysrolegroupsCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/sys/sysrolegroups
     * @secure
     */
    webSysSysrolegroupsCreate: (data: CreateSysRoleGroupsInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/sysrolegroups`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SystemConfig
     * @name WebSysSystemconfigGeneralList
     * @summary 获取常规配置 (Auth policies: Web)
     * @request GET:/api/web/sys/systemconfig/general
     * @secure
     */
    webSysSystemconfigGeneralList: (params: RequestParams = {}) =>
      this.request<SystemThemeSettingDtoMessageModel, void>({
        path: `/api/web/sys/systemconfig/general`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SystemConfig
     * @name WebSysSystemconfigGeneralCreate
     * @summary 修改常规配置 (Auth policies: Web)
     * @request POST:/api/web/sys/systemconfig/general
     * @secure
     */
    webSysSystemconfigGeneralCreate: (data: SystemThemeSettingDto, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/systemconfig/general`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SystemConfig
     * @name WebSysSystemconfigGeneralRemindUpdate
     * @summary 修改全局弹窗 (Auth policies: Web)
     * @request PUT:/api/web/sys/systemconfig/general/remind
     * @secure
     */
    webSysSystemconfigGeneralRemindUpdate: (params: RequestParams = {}) =>
      this.request<SystemThemeSettingDtoMessageModel, void>({
        path: `/api/web/sys/systemconfig/general/remind`,
        method: 'PUT',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SystemConfig
     * @name WebSysSystemconfigSecurityList
     * @summary 获取安全配置 (Auth policies: Web)
     * @request GET:/api/web/sys/systemconfig/security
     * @secure
     */
    webSysSystemconfigSecurityList: (params: RequestParams = {}) =>
      this.request<SystemSecuritySettingDtoMessageModel, void>({
        path: `/api/web/sys/systemconfig/security`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SystemConfig
     * @name WebSysSystemconfigSecurityCreate
     * @summary 修改安全配置 (Auth policies: Web)
     * @request POST:/api/web/sys/systemconfig/security
     * @secure
     */
    webSysSystemconfigSecurityCreate: (data: SystemSecuritySettingDto, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/systemconfig/security`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SystemConfig
     * @name WebSysSystemconfigLdapList
     * @summary 获取Ldap配置 (Auth policies: Web)
     * @request GET:/api/web/sys/systemconfig/ldap
     * @secure
     */
    webSysSystemconfigLdapList: (
      query?: {
        tenantId?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<LdapConfigMessageModel, void>({
        path: `/api/web/sys/systemconfig/ldap`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SystemConfig
     * @name WebSysSystemconfigLdapCreate
     * @summary 修改LDAP配置 (Auth policies: Web)
     * @request POST:/api/web/sys/systemconfig/ldap
     * @secure
     */
    webSysSystemconfigLdapCreate: (
      data: LdapConfig,
      query?: {
        tenantId?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/systemconfig/ldap`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserInfo
     * @name WebSysUserinfoPageCreate
     * @summary 获取全部用户 (Auth policies: Web)
     * @request POST:/api/web/sys/userinfo/page
     * @secure
     */
    webSysUserinfoPageCreate: (data: GetUserInfoInput, params: RequestParams = {}) =>
      this.request<UserInfoDtoPageModelMessageModel, void>({
        path: `/api/web/sys/userinfo/page`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserInfo
     * @name WebSysUserinfoOrganizationunitDetail
     * @summary 获取用户数据权限 (Auth policies: Web)
     * @request GET:/api/web/sys/userinfo/{id}/organizationunit
     * @secure
     */
    webSysUserinfoOrganizationunitDetail: (id: string, params: RequestParams = {}) =>
      this.request<OrganizationUnitsTreeDtoMessageModel, void>({
        path: `/api/web/sys/userinfo/${id}/organizationunit`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserInfo
     * @name WebSysUserinfoOrganizationunitCreate
     * @summary 设置用户数据权限 (Auth policies: Web)
     * @request POST:/api/web/sys/userinfo/{id}/organizationunit
     * @secure
     */
    webSysUserinfoOrganizationunitCreate: (id: string, data: string[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/userinfo/${id}/organizationunit`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserInfo
     * @name WebSysUserinfoPermissionDetail
     * @summary 获取用户权限 (Auth policies: Web)
     * @request GET:/api/web/sys/userinfo/{id}/permission
     * @secure
     */
    webSysUserinfoPermissionDetail: (id: string, params: RequestParams = {}) =>
      this.request<PermissionTreeMessageModel, void>({
        path: `/api/web/sys/userinfo/${id}/permission`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserInfo
     * @name WebSysUserinfoPermissionCreate
     * @summary 添加角色权限 (Auth policies: Web)
     * @request POST:/api/web/sys/userinfo/{id}/permission
     * @secure
     */
    webSysUserinfoPermissionCreate: (id: string, data: string[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/userinfo/${id}/permission`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserInfo
     * @name WebSysUserinfoDetail
     * @summary 获取用户 (Auth policies: Web)
     * @request GET:/api/web/sys/userinfo/{id}
     * @secure
     */
    webSysUserinfoDetail: (id: string, params: RequestParams = {}) =>
      this.request<UserInfoDtoMessageModel, void>({
        path: `/api/web/sys/userinfo/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserInfo
     * @name WebSysUserinfoUpdate
     * @summary 更新用户与角色 (Auth policies: Web)
     * @request PUT:/api/web/sys/userinfo/{id}
     * @secure
     */
    webSysUserinfoUpdate: (id: string, data: CreateUserInfoInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/sys/userinfo/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserInfo
     * @name WebSysUserinfoDelete
     * @summary 删除用户 (Auth policies: Web)
     * @request DELETE:/api/web/sys/userinfo/{id}
     * @secure
     */
    webSysUserinfoDelete: (id: number, params: RequestParams = {}) =>
      this.request<Int64MessageModel, void>({
        path: `/api/web/sys/userinfo/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserInfo
     * @name WebSysUserinfoCreate
     * @summary 添加一个用户 (Auth policies: Web)
     * @request POST:/api/web/sys/userinfo
     * @secure
     */
    webSysUserinfoCreate: (data: CreateUserInfoInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/sys/userinfo`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserInfo
     * @name WebSysUserinfoDelete2
     * @summary 批量删除 (Auth policies: Web)
     * @request DELETE:/api/web/sys/userinfo
     * @originalName webSysUserinfoDelete
     * @duplicate
     * @secure
     */
    webSysUserinfoDelete2: (data: string[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/userinfo`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserInfo
     * @name WebSysUserinfoBindEmailUpdate
     * @summary 绑定邮箱 (Auth policies: Web)
     * @request PUT:/api/web/sys/userinfo/bind/email
     * @secure
     */
    webSysUserinfoBindEmailUpdate: (data: BindEmailInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/userinfo/bind/email`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserInfo
     * @name WebSysUserinfoEmailUpdate
     * @summary 修改邮箱 (Auth policies: Web)
     * @request PUT:/api/web/sys/userinfo/email
     * @secure
     */
    webSysUserinfoEmailUpdate: (data: UpdateUserEmailInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/userinfo/email`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserInfo
     * @name WebSysUserinfoBindPhoneUpdate
     * @summary  (Auth policies: Web)
     * @request PUT:/api/web/sys/userinfo/bind/phone
     * @secure
     */
    webSysUserinfoBindPhoneUpdate: (data: BindPhoneInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/userinfo/bind/phone`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserInfo
     * @name WebSysUserinfoPhoneUpdate
     * @summary 修改手机号   888888 万能验证码 (Auth policies: Web)
     * @request PUT:/api/web/sys/userinfo/phone
     * @secure
     */
    webSysUserinfoPhoneUpdate: (data: UpdateUserPhoneInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/userinfo/phone`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserInfo
     * @name WebSysUserinfoBindRoleCreate
     * @summary 绑定角色或职位 (Auth policies: Web)
     * @request POST:/api/web/sys/userinfo/bind/{id}/role
     * @secure
     */
    webSysUserinfoBindRoleCreate: (id: number, data: string[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/userinfo/bind/${id}/role`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
 * No description
 *
 * @tags UserInfo
 * @name WebSysUserinfoWorkTransferUpdate
 * @summary 工作交接
TODO 待完善，实际业务场景 (Auth policies: Web)
 * @request PUT:/api/web/sys/userinfo/work/transfer
 * @secure
 */
    webSysUserinfoWorkTransferUpdate: (data: UserWorkTransferInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/userinfo/work/transfer`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserInfo
     * @name WebSysUserinfoBatchUpdate
     * @summary 批量更新状态 (Auth policies: Web)
     * @request PUT:/api/web/sys/userinfo/batch/{status}
     * @secure
     */
    webSysUserinfoBatchUpdate: (status: string, data: string[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/userinfo/batch/${status}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserInfo
     * @name WebSysUserinfoCurrentList
     * @summary 获取当前登录用户信息 (Auth policies: Web)
     * @request GET:/api/web/sys/userinfo/current
     * @secure
     */
    webSysUserinfoCurrentList: (params: RequestParams = {}) =>
      this.request<HostUserInfoDtoMessageModel, void>({
        path: `/api/web/sys/userinfo/current`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserInfo
     * @name WebSysUserinfoLoginLogList
     * @summary 获取用户登录日志 (Auth policies: Web)
     * @request GET:/api/web/sys/userinfo/login/log
     * @secure
     */
    webSysUserinfoLoginLogList: (
      query?: {
        /** 客户端Ip所在地区 */
        clientIpRegion?: string
        /** 用户名 */
        userName?: string
        /** 手机号 */
        phoneNumber?: string
        /** 客户端名称 */
        clientName?: string
        /**
         * 开始时间
         * @format date-time
         */
        startTime?: string
        /**
         * 结束时间
         * @format date-time
         */
        endTime?: string
        /** 客户端设备信息 */
        clientDeviceInfo?: string
        /** 是否在异地登录 */
        isLoginFromAnotherLocation?: boolean
        /** 是否常用设备登录 */
        isLoginFromInCommonUseClient?: boolean
        /**
         * 页码
         * @format int32
         */
        page?: number
        /**
         * 数量
         * @format int32
         */
        size?: number
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[]
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[]
      },
      params: RequestParams = {},
    ) =>
      this.request<UserLoginAttemptsDtoPageModelMessageModel, void>({
        path: `/api/web/sys/userinfo/login/log`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserInfo
     * @name WebSysUserinfoPhonePasswordUpdate
     * @summary 通过手机号修改密码 (Auth policies: Web)
     * @request PUT:/api/web/sys/userinfo/phone/password
     * @secure
     */
    webSysUserinfoPhonePasswordUpdate: (data: UpdateUserPhoneToPasswordInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/userinfo/phone/password`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserInfo
     * @name WebSysUserinfoPasswordUpdate
     * @summary 修改密码 (Auth policies: Web)
     * @request PUT:/api/web/sys/userinfo/password
     * @secure
     */
    webSysUserinfoPasswordUpdate: (data: UpdateUserPassWordInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/userinfo/password`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserOrganizationUnit
     * @name WebSysUserorganizationunitCreate
     * @summary 添加角色或用户数据权限 (Auth policies: Web)
     * @request POST:/api/web/sys/userorganizationunit
     * @secure
     */
    webSysUserorganizationunitCreate: (data: CreateUserOrganizationUnitInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/userorganizationunit`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserOrganizationUnit
     * @name WebSysUserorganizationunitList
     * @summary 获取用户配置的数据权限 (Auth policies: Web)
     * @request GET:/api/web/sys/userorganizationunit
     * @secure
     */
    webSysUserorganizationunitList: (
      query?: {
        userId?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<UserOrganizationUnitDtoIListMessageModel, void>({
        path: `/api/web/sys/userorganizationunit`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserOrganizationUnit
     * @name WebSysUserorganizationunitUserDetail
     * @summary 获取用户配置的数据权限 (Auth policies: Web)
     * @request GET:/api/web/sys/userorganizationunit/{id}/user
     * @secure
     */
    webSysUserorganizationunitUserDetail: (
      id: string,
      query?: {
        userId?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<UserOrganizationUnitDtoIListMessageModel, void>({
        path: `/api/web/sys/userorganizationunit/${id}/user`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserRole
     * @name WebSysUserroleUserDetail
     * @summary 获取用户所有角色 (Auth policies: Web)
     * @request GET:/api/web/sys/userrole/user/{id}
     * @secure
     */
    webSysUserroleUserDetail: (
      id: string,
      query?: {
        userId?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<RoleDtoListMessageModel, void>({
        path: `/api/web/sys/userrole/user/${id}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  configuration = {
    /**
     * No description
     *
     * @tags FileConfiguration
     * @name ConfigurationList
     * @summary  (Auth)
     * @request GET:/configuration
     * @secure
     */
    configurationList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/configuration`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags FileConfiguration
     * @name ConfigurationCreate
     * @summary  (Auth)
     * @request POST:/configuration
     * @secure
     */
    configurationCreate: (data: FileConfiguration, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/configuration`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  }
  outputcache = {
    /**
     * No description
     *
     * @tags OutputCache
     * @name OutputcacheDelete
     * @summary  (Auth)
     * @request DELETE:/outputcache/{region}
     * @secure
     */
    outputcacheDelete: (region: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/outputcache/${region}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),
  }
}
