import currencyInfo from '../json/currencyInfo.json';

/**
 * 通过货币的简写代码获取国旗及货币符号信息
 * @param currency
 * @returns
 */

export const getCurrencyInfo = (currency) => {
  if (!currency) return [];
  const list = currency.split(',');
  return list.map((e) => currencyInfo.find((item) => item.code === e) || { code: e });
};
