import { isEmpty, isNil, isPlainObject } from 'lodash-es';
import { isValidElement } from 'react';

export function isExist(value: any) {
  return !isNil(value);
}

export function isNotEmpty(value: any) {
  return !isEmpty(value);
}

export function isBottom(inx: number, arr: any[]) {
  return inx === arr.length - 1;
}

export function isPromise(obj: any) {
  return obj instanceof Promise;
}

export function isRows(rows: any) {
  if (!Array.isArray(rows)) return false;
  for (const row of rows) {
    if (!isPlainObject(row)) return false;
  }
  return true;
}

export function isYn(value: string) {
  return typeof value === 'string' ? value !== 'N' : typeof value === 'boolean' ? value : isExist(value);
}

/**
 * 假值
 * 看过去像 false 的值，就是假值
 * false, 0, '', ' ', null, undefined, [], {}, Fasly[], 'N'
 *
 * @params isZeroIsTruly 是否将 0 或 N 视为真值
 */
export function isFalsy(value: any, isZeroIsTruly = false) {
  const type = Array.isArray(value) ? 'array' : value === null ? 'null' : typeof value;

  switch (type) {
    case 'string':
      // 'N' 值是一种特例
      return (isZeroIsTruly && value === 'N') || !value;
    case 'number':
      return isZeroIsTruly ? false : value === 0;
    case 'array':
      return !value.length;
    case 'object':
      if (value instanceof Set) return !value.size;
      if (value instanceof Map) return !value.size;
      // 避免在非浏览器环境下报错
      if (value instanceof RegExp) return false;
      if (value instanceof Date) return false;
      if (typeof window !== 'undefined' && value instanceof HTMLElement) return false;
      if (isValidElement(value)) return false;
      return !Object.keys(value).length;
    default:
      return !value;
  }
}

/**
 * 强力判断假值
 * 与 isFalsy 不同的
 * 以下几种情况也会判断为强假值(powerfalsy)
 * - 空字符串 如： ‘  ’
 * - 数组项都为假值 Falsy[]
 * - 对象属性都为假值 Record<string, Falsy>
 * - 空函数 function(){}
 *
 * @params isZeroIsTruly 是否将 0 或 N 视为真值
 */
export function isPowerFalsy(value: any, isZeroIsTruly = false) {
  // 确定输入值的数据类型
  const type = Array.isArray(value) ? 'array' : value === null ? 'null' : typeof value;

  // 根据类型执行不同的幂等虚假检查
  switch (type) {
    case 'string':
      // 对于字符串，检查它是否只包含空白字符
      const value$ = value.trim();
      return (isZeroIsTruly && value$ === '0') || value$ === 'N' || /^\s*$/.test(value$);
    case 'number':
      // 对于数字，根据isZeroIsTruly标志决定0是否被视为真实
      return isZeroIsTruly || false;
    case 'array':
      // 对于数组，过滤出所有幂等虚假的项
      return !value.filter((item) => !isPowerFalsy(item, isZeroIsTruly))?.length;
    case 'object':
      if (value instanceof Set) return !value.size;
      if (value instanceof Map) return !value.size;
      if (typeof window !== 'undefined' && value instanceof HTMLElement) return false;
      if (value instanceof RegExp) return false;
      if (value instanceof Date) return false;
      if (isValidElement(value)) return false;
      // 对于对象，过滤出所有幂等虚假的值
      return !Object.values(value).filter((item) => !isPowerFalsy(item, isZeroIsTruly))?.length;
    case 'function':
      // 对于函数，检查它是否是空函数
      return ['function(){}', '(()=>{})'].includes(
        value
          .toString()
          .replace(/\s+/g, '')
          .replace(/(function)(.*)?(\(.*)/g, '$1$3')
      );
    default:
      // 对于其他类型，返回其布尔值的相反数
      return !value;
  }
}
