/**
 * 数字相关方法
 */

/**
 * 获取数字的步长
 */
export const getNumberStep = (num: number | string) => {
  const str = num.toString();
  const index = str.indexOf('.');
  const precision = str.length - index - 1;
  if (index === -1) {
    // return { step: 1, precision: 0 };
    return 1;
  }

  const step = 1 / 10 ** precision;
  //   return { step, precision };
  return step;
};
