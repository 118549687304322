import { run } from '@/utils';

/**
 * error cache 存储在 session storage ，
 * 生命周期短
 */
export function setErrorCache(payload: Row, symbol?: string) {
  run(payload?.id, (businessId) => {
    sessionStorage.setItem(businessId, JSON.stringify(payload));
    markErrorCache(symbol);
  });
}

export function getErrorCache(businessId: string) {
  const values = sessionStorage.getItem(businessId);
  try {
    return JSON.parse(values!);
  } catch (e) {
    return void 0;
  }
}

export function removeErrorCache(businessId: string) {
  businessId && sessionStorage.removeItem(businessId);
}

export function markErrorCache(symbol = 'error-cahche') {
  sessionStorage.setItem(symbol, 'Y');
}

export function hasHistoryCache(symbol = 'error-cahche') {
  return !!sessionStorage.getItem(symbol);
}

/**
 * 错误cache生命周期极短
 * 主要用于界面提醒用户数据操作产生错误
 * -
 * 删除数据的同时，删除标识
 */
export function autoDestoryErrorCache(businessId: string, delay = 10 * 1000) {
  businessId &&
    setTimeout(() => {
      removeErrorCache(businessId);
      sessionStorage.removeItem('error-cahche');
    }, delay);
}
