import { Formatter } from '@/constants';
import numeral from 'numeral';
import currency from "currency.js";

// export const numberFormat = (number, formatter = '0,0.00[0000]') => {
export const numberFormat = (number, formatter = Formatter.amount) => {
  return numeral(number).format(formatter);
};

export const RmbFormat = (value: currency.Any) => currency(value, { symbol: "", precision: 2 }).toString();
