/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** 访问控制 */
export interface AccessConfig {
  /** 允许IP地址区间访问 */
  isIpAreaAccess?: boolean;
  /** IP地址区间 */
  ipAreaIntervals?: string[] | null;
  /** 允许IP地址/网段访问 */
  isIpAddressNetworkSegment?: boolean;
  /** IP地址/网段 */
  ipAddressNetworkSegments?: string[] | null;
}

export interface AccountDto {
  /**
   * 用户最大数量
   * @format int32
   */
  userCount?: number;
}

/** 实际控制人 */
export interface ActualControllerListItem {
  /** 主键 */
  keyNo?: string | null;
  /** 实际控制人名称 */
  name?: string | null;
  /** 最终受益股份 */
  finalBenefitPercent?: string | null;
  /** 表决权 */
  controlPercent?: string | null;
  /** 是否实际控制人（0：疑似实际控制人，1：实际控制人） */
  isActual?: string | null;
}

/** 行政许可（返回前100条） */
export interface AdminLicenseListItem {
  /** 决定文书/许可编号 */
  licensDocNo?: string | null;
  /** 决定文书/许可证名称 */
  licensDocName?: string | null;
  /** 有效期自 */
  validityFrom?: string | null;
  /** 有效期至 */
  validityTo?: string | null;
  /** 许可机关 */
  licensOffice?: string | null;
  /** 许可内容 */
  licensContent?: string | null;
  /** 来源 */
  source?: string | null;
}

export interface AggregateRouteConfig {
  routeKey?: string | null;
  parameter?: string | null;
  jsonPath?: string | null;
}

export interface ApprovalInput {
  /** 业务Id */
  id?: string | null;
  /** 审批意见 */
  approvalOpinion?: string | null;
  /** 附件 */
  attachments?: string | null;
  /** 传参 */
  paramsString?: string | null;
}

/** 备案网站（返回前100条） */
export interface ApproveSiteListItem {
  /** 网站名称 */
  name?: string | null;
  /** 网址 */
  webAddress?: string | null;
  /** 域名 */
  domainName?: string | null;
  /** 网站备案/许可证号 */
  lesenceNo?: string | null;
  /** 审核日期，精确到天，如“2022-01-01” */
  auditDate?: string | null;
}

export interface Approver {
  /** 加签用户 */
  userId?: string | null;
  /**
   * 加签用户审批排序
   * @format int32
   */
  sort?: number;
  /** 加签用户名 */
  userName?: string | null;
  /**
   * 加签用户类型
   * @format int32
   */
  userType?: 0 | 1 | 2 | 3 | null;
}

/** 行政区域 */
export interface Area {
  /** 省份 */
  province?: string | null;
  /** 城市 */
  city?: string | null;
  /** 区域 */
  county?: string | null;
}

export interface AssignShow {
  permissionids?: string[] | null;
  assignbtns?: string[] | null;
}

/** 通用返回信息类 */
export interface AssignShowMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  response?: AssignShow;
}

export interface AssignView {
  pids?: string[] | null;
  rid?: string | null;
}

export interface AttachmentDownloadDto {
  /** 下载地址 */
  downloadUrl?: string | null;
  /** FileName */
  name?: string | null;
  /**
   * 文件大小
   * @format int32
   */
  size?: number;
  /** 文件扩展名 */
  extension?: string | null;
  /** 文件哈希值 */
  hash?: string | null;
  /**
   * 文件状态
   * @format int32
   */
  status?: 0 | 1;
  statusText?: string | null;
  /**
   * 文件排序
   * @format int32
   */
  sort?: number;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface AttachmentDownloadDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: AttachmentDownloadDto[] | null;
}

/** 通用返回信息类 */
export interface AttachmentDownloadDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  response?: AttachmentDownloadDto;
}

/** 开票信息 */
export interface BankInfo {
  /** 开户行 */
  bank?: string | null;
  /** 开户行账号 */
  bankAccount?: string | null;
  /** 企业名称 */
  name?: string | null;
  /** 企业税号 */
  creditCode?: string | null;
  /** 企业地址 */
  address?: string | null;
  /** 电话号码 */
  tel?: string | null;
}

/** Where 条件查询 */
export interface BaseWhereInput {
  /**
   * 筛选类型
   * @format int32
   */
  type?: 0 | 1 | 2 | null;
  /** 传参名 */
  inputFieldName?: string | null;
  /** 字段集 实际数据库查询字段 */
  fieldNames?: string[] | null;
  /**
   * 默认等于   筛选条件
   * @format int32
   */
  conditionalType?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16;
  /** 值 ， True False    数组则为 0,1,2   string  string[] */
  value?: string | null;
  /** 字段集 多关键字模糊查询 */
  andOrCondition?: string[] | null;
}

export interface BatchUpdateAdjustmentInput {
  /** 账单编号 */
  billingId?: string | null;
  /** 调整类型 */
  adjustmentType?: string | null;
  /**
   * 调整金额
   * @format double
   */
  amount?: number;
  /** 固定专业服务费ID集合 */
  fixedServiceFeeIds?: string[] | null;
  /** 计时专业服务费ID集合 */
  timesheetServiceFeeIds?: string[] | null;
  /** 支出费用ID集合 */
  expenseFeeIds?: string[] | null;
}

/** 受益所有人 */
export interface Beneficiary {
  /** 主键 */
  keyNo?: string | null;
  /** 受益所有人名称 */
  name?: string | null;
  /** 最终受益股份 */
  finalBenefitPercent?: string | null;
  /** 判定理由 */
  reason?: string | null;
}

export interface BillingCase {
  /** 案件Id */
  id?: string | null;
  /** 案件编号 */
  serialId?: string | null;
  /** 案件名称 */
  name?: string | null;
  /** 客户Id */
  clientId?: string | null;
  /** 客户名称 */
  clientName?: string | null;
  /**
   * 总金额
   * @format double
   */
  totalAmount?: number;
  /**
   * 外币金额
   * @format double
   */
  settlementAmount?: number;
  /**
   * 已收金额
   * @format double
   */
  amountReceived?: number;
  /**
   * 应收金额
   * @format double
   */
  accountsReceivable?: number;
  /** 币种 */
  currency?: string | null;
  /**
   * 汇率
   * @format double
   */
  exchangeRate?: number;
  /** 账单Id */
  billingId?: string | null;
  /**
   * 代收代付费
   * @format double
   */
  poboSubtotalAmount?: number;
  /**
   * 应收代收代付费
   * @format double
   */
  poboSubReceivedAmount?: number;
  /** 到账状态 */
  paymentStatus?: string | null;
  /** 案件分配信息 */
  caseDistributions?: CaseDistributionDto[] | null;
}

/** 账单案件 */
export interface BillingCaseInfo {
  /** 账单Id */
  id?: string | null;
  /** 客户Id */
  clientId?: string | null;
  /** 客户名称 */
  clientName?: string | null;
  /** 账单编号 */
  serialId?: string | null;
  /**
   * 总金额
   * @format double
   */
  totalAmount?: number;
  /**
   * 外币金额
   * @format double
   */
  settlementAmount?: number;
  /** 币种 */
  currency?: string | null;
  /**
   * 应收金额
   * @format double
   */
  accountsReceivable?: number;
  /**
   * 已收金额
   * @format double
   */
  amountReceived?: number;
  /**
   * 汇率
   * @format double
   */
  exchangeRate?: number;
  /**
   * 代收代付费
   * @format double
   */
  poboSubtotalAmount?: number;
  /**
   * 应收代收代付费
   * @format double
   */
  poboSubReceivedAmount?: number;
  /** 账单案件信息 */
  billingCases?: BillingCase[] | null;
  /** 到账状态 */
  paymentStatus?: string | null;
}

/** 通用返回信息类 */
export interface BillingCaseInfoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: BillingCaseInfo[] | null;
}

export interface BindEmailInput {
  email?: string | null;
  code?: string | null;
}

export interface BindPhoneInput {
  /** 手机号 */
  phone?: string | null;
  /** 验证码 */
  code?: string | null;
}

/** 通用返回信息类 */
export interface BooleanMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: boolean;
}

/** 分支机构（返回前100条） */
export interface BranchListItem {
  /** 主键 */
  keyNo?: string | null;
  /** 名称 */
  name?: string | null;
  /** 负责人 */
  operName?: string | null;
  /** 成立日期，精确到天，如“2022-01-01” */
  startDate?: string | null;
  /** 登记状态 */
  status?: string | null;
  /** 行政区域 */
  area?: Area;
}

export interface CSRExemptionInchargeLawyerOutput {
  inChargeLawyer?: string | null;
  /** 主管律师 */
  inChargeLawyerName?: string | null;
  /** 头像 */
  inchargeLawyerPhoto?: string | null;
  /** 部门 */
  inchargeLawyerOrganizationUnitId?: string | null;
  /** 部门 */
  inchargeLawyerOrganizationUnitName?: string | null;
}

/** 通用返回信息类 */
export interface CSRExemptionInchargeLawyerOutputListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: CSRExemptionInchargeLawyerOutput[] | null;
}

export interface CSRExemptionOutput {
  /** 主管律师 */
  inChargeLawyerName?: string | null;
  /** 头像 */
  inchargeLawyerPhoto?: string | null;
  /** 部门 */
  inchargeLawyerOrganizationUnitId?: string | null;
  /** 部门 */
  inchargeLawyerOrganizationUnitName?: string | null;
  /** 利冲编号 */
  csrId?: string | null;
  /** 利冲关键词 */
  csrKeyword?: string | null;
  /** 与我冲突案件的角色（冲突方） */
  csrParty?: string | null;
  /** 我的案件冲突角色 */
  caseParty?: string | null;
  /** 我的案件冲突方名称 */
  casePartyName?: string | null;
  /** 主管律师 */
  inChargeLawyer?: string | null;
  /** 利冲关联业务类型（利冲/案件/入库/投标等） */
  csrRelationType?: string | null;
  /** 关联业务编号（默认案件） */
  relationId?: string | null;
  /** 冲突产生时关联业务状态 */
  relationStatus?: string | null;
  /** 与我冲突业务编号合计 */
  csrRelationIds?: string | null;
  /** 利冲确认发送人编号 */
  csrSendUserId?: string | null;
  /**
   * 利冲确认发送时间
   * @format date-time
   */
  csrSendTime?: string;
  /** 是否确认 */
  isConfirm?: boolean;
  /** 是否利冲 */
  isConflict?: boolean;
  /**
   * 利冲豁免确认时间
   * @format date-time
   */
  confirmTime?: string | null;
  /** 豁免文件 */
  attachments?: string | null;
  /** 说明描述 */
  narrative?: string | null;
  /**
   * 提醒方式
   * @format int32
   */
  reminderMethod?: 0 | 1;
  /** 提醒方式文本 */
  reminderMethodText?: string | null;
  /** 操作人 */
  operator?: string | null;
  /** 操作人姓名 */
  operatorName?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 利冲偏好 */
export interface CSRProcessPerferenceDto {
  /** 是否启用法律意义上检索 */
  isEnabledLegalSenseSearch?: boolean | null;
  /** 是否高级搜索 */
  isAdvanceSearch?: boolean | null;
  /** 关键字 */
  keywords?: string | null;
  keywordList?: string[] | null;
  /**
   * 检索模式（精确，模糊）
   * @format int32
   */
  searchMode?: 0 | 1 | 2 | 3;
  /** 我方名称 */
  ourPatryName?: string | null;
  /** 我方外文名称 */
  ourPatryForeignName?: string | null;
  /** 对方名称 */
  oppositeName?: string | null;
  /** 对方外文名称 */
  oppositeForeignName?: string | null;
  /** 潜在三方名称 */
  potential3rdPartyName?: string | null;
  /** 潜在三方外文名称 */
  potential3rdPartyForeignName?: string | null;
  /** 是否分词检索 */
  isSplitWordSearch?: boolean | null;
  /** 是否外文分词检索 */
  isForeignSplitWordSearch?: string | null;
  /**
   * 匹配结果数量
   * @format float
   */
  matchingCount?: number;
  /** 需要豁免案件编号 */
  needExemptionCaseId?: string | null;
  /** 利冲预检人编号 */
  requestUserId?: string | null;
  /** 说明描述 */
  narrative?: string | null;
  /** 关联利冲编号 */
  csrId?: string | null;
  /** 关联业务编号(立案/投标/入库) */
  relationId?: string | null;
  /** 标签名 */
  tagName?: string | null;
  /** 应用于 */
  applyCategory?: string | null;
  /** 弃用 */
  relationIdList?: string[] | null;
  /**
   * 选中数量
   * @format int32
   */
  checkCount?: number;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface CSRProcessPerferenceDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: CSRProcessPerferenceDto[] | null;
}

/** 通用返回信息类 */
export interface CSRProcessPerferenceDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 利冲偏好 */
  response?: CSRProcessPerferenceDto;
}

/** 通用分页信息类 */
export interface CSRProcessPerferenceDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: CSRProcessPerferenceDto[] | null;
}

/** 通用返回信息类 */
export interface CSRProcessPerferenceDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: CSRProcessPerferenceDtoPageModel;
}

/** 利冲审查偏好结果 */
export interface CSRProcessResultPerferenceDto {
  /** Id */
  id?: string | null;
  /** 利冲预检编号 */
  preCsrId?: string | null;
  /** 利冲关键词 */
  csrKeyword?: string | null;
  /** 与我冲突案件的角色 */
  csrParty?: string | null;
  /** 我被冲突案件的角色 */
  caseParty?: string | null;
  /** 我的案件冲突方名称 */
  casePartyName?: string | null;
  /** 匹配客户名称 */
  clientName?: string | null;
  /** 匹配客户外文名称 */
  clientForeignName?: string | null;
  /** 匹配客户实控人名称 */
  clientShareholderActualCtrlName?: string | null;
  /** 匹配客户实控人外文名称 */
  clientShareholderActualCtrlForeignName?: string | null;
  /** 客户参与实体名称 */
  clientParticipatingEntityName?: string | null;
  /** 客户参与实体外文名称 */
  clientParticipatingEntityForeignName?: string | null;
  /** 匹配对方名称 */
  oppositeName?: string | null;
  /** 匹配对方外文名称 */
  oppositeForeignName?: string | null;
  /** 匹配对方实控人名称 */
  oppositeShareholderActualCtrlName?: string | null;
  /** 匹配对方实控人外文名称 */
  oppositeShareholderActualCtrlForeignName?: string | null;
  /** 潜在第三方名称 */
  potential3rdPartyName?: string | null;
  /** 潜在第三方外文名称 */
  potential3rdPartyForeignName?: string | null;
  /** 利冲关联业务类型 */
  csrRelationType?: string | null;
  /** 利冲关联业务类型文本 */
  csrRelationTypeText?: string | null;
  /** 关联业务编号 */
  relationId?: string | null;
  /** 利冲流水号 */
  csrSerialId?: string | null;
  /** 案件流水号 */
  caseSerialId?: string | null;
  /** 案件/利冲号 */
  serialId?: string | null;
  /** 冲突产生时关联业务状态 */
  relationStatus?: string | null;
  /** 匹配利冲规则 */
  matchingRule?: string | null;
  /** 关联业务类型 */
  relationCategory?: string | null;
  /** 关联业务类型文本 */
  relationCategoryText?: string | null;
  /** 关联业务名称 */
  relationName?: string | null;
  /**
   * 关联业务申请时间
   * @format date-time
   */
  relationRequestTime?: string | null;
  /** 关联业务主办合伙人 */
  relationInChargeLawyerId?: string | null;
  /** 关联业务主办合伙人名称 */
  relationInChargeLawyerName?: string | null;
  /** 关联业务申请合伙人 */
  relationRequestUserId?: string | null;
  /** 关联业务申请合伙人名称 */
  relationRequestUserName?: string | null;
  /** 关系列表 */
  relationList?: CaseRelatedResultDto[] | null;
  /** 关联业务案源人 */
  relationCaseUser?: string | null;
  /** 关联业务案源人名称 */
  relationCaseUserName?: string | null;
  /** 账单管理人 */
  relationBillingLawyerNameId?: string | null;
  /** 账单管理人名称 */
  relationBillingLawyerName?: string | null;
  /**
   * 结案时间
   * @format date-time
   */
  relationEndTime?: string | null;
  /** 关联关系json */
  caseRelatedResultJson?: string | null;
  /**
   * 提交时间
   * @format date-time
   */
  submittedOn?: string | null;
  /** 标签名称 */
  tagName?: string | null;
  isChecked?: boolean;
  /** 委托事项 */
  entrustedMatters?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface CSRProcessResultPerferenceDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: CSRProcessResultPerferenceDto[] | null;
}

/** 通用返回信息类 */
export interface CSRProcessResultPerferenceDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 利冲审查偏好结果 */
  response?: CSRProcessResultPerferenceDto;
}

/** 通用分页信息类 */
export interface CSRProcessResultPerferenceDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: CSRProcessResultPerferenceDto[] | null;
}

/** 通用返回信息类 */
export interface CSRProcessResultPerferenceDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: CSRProcessResultPerferenceDtoPageModel;
}

export interface CancelCaseLawyerListInput {
  /** 案件Id */
  caseId?: string | null;
  /** 参案律师角色 */
  lawyerRole?: string | null;
  /** 参案律师Id */
  userIds?: string[] | null;
}

/** 案件受理机构信息表 */
export interface CaseAcceptAgencyDto {
  /** 案件或利冲编号 */
  caseId?: string | null;
  /** 机构名称 */
  name?: string | null;
  /** 机构类别（法院/检察院/监狱等） */
  agencyType?: string | null;
  /**
   * 开始时间
   * @format date-time
   */
  startTime?: string;
  /**
   * 结束时间
   * @format date-time
   */
  endTime?: string;
  /** 事由分类 */
  category?: string | null;
  /** 案件阶段 */
  stage?: string | null;
  /** 联系人 */
  linker?: string | null;
  /** 联系电话 */
  phoneNumber?: string | null;
  /** 关联法院编号 */
  courtId?: string | null;
  /** 租户ID */
  tenantId?: string | null;
  /** 部门ID  DepartmentId */
  organizationUnitId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 备注 */
  remark?: string | null;
  /** 状态 */
  isEnabled?: boolean;
  /** 中立字段 */
  isInternal?: boolean;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface CaseAcceptAgencyDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: CaseAcceptAgencyDto[] | null;
}

/** 通用返回信息类 */
export interface CaseAcceptAgencyDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 案件受理机构信息表 */
  response?: CaseAcceptAgencyDto;
}

/** 通用分页信息类 */
export interface CaseAcceptAgencyDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: CaseAcceptAgencyDto[] | null;
}

/** 通用返回信息类 */
export interface CaseAcceptAgencyDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: CaseAcceptAgencyDtoPageModel;
}

/** 案件审核 */
export interface CaseApprovalInput {
  serialId?: string | null;
  /** 业务Id */
  id?: string | null;
  /** 审批意见 */
  approvalOpinion?: string | null;
  /** 附件 */
  attachments?: string | null;
  /** 传参 */
  paramsString?: string | null;
}

export interface CaseCheckOutput {
  /** 利冲申请编号 */
  csrId?: string | null;
  /** 案件类型 */
  category?: string | null;
  /** 案件类型 */
  categoryText?: string | null;
  /** 案件名称 */
  name?: string | null;
  /** 案件编号 */
  serialId?: string | null;
  /** 书写语言 */
  writtenLanguage?: string | null;
  /** 书写语言 */
  writtenLanguageText?: string | null;
  /** 客户编号 */
  clientId?: string | null;
  /** 客户名称 */
  clientName?: string | null;
  /** 客户外文名称 */
  clientForeignName?: string | null;
  /** 客户编号 */
  clientSerialId?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用分页信息类 */
export interface CaseCheckOutputPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: CaseCheckOutput[] | null;
}

/** 通用返回信息类 */
export interface CaseCheckOutputPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: CaseCheckOutputPageModel;
}

/** 案件结案表（撤案/销案） */
export interface CaseClosedDto {
  id?: string | null;
  /** 案件编号 */
  caseId?: string | null;
  /** 裁判类型（判决/裁定/调解/其他） */
  refereeType?: string | null;
  /** 受理机构（法院名称） */
  court?: string | null;
  /**
   * 裁判文书日期
   * @format date-time
   */
  judgmentDate?: string;
  /** 裁判文书号 */
  judgmentNo?: string | null;
  /**
   * 送达日期
   * @format date-time
   */
  deliveryDate?: string;
  /** 是否机密案卷 */
  confidentialVolume?: boolean;
  /** 结案时收费额是否与委托协议一致 */
  isConsistentCharge?: boolean;
  /**
   * 起诉日期
   * @format date-time
   */
  sueDate?: string;
  /**
   * 阅卷日期
   * @format date-time
   */
  examDate?: string;
  /**
   * 初次会见/谈话日期
   * @format date-time
   */
  meetDate?: string;
  /**
   * 首次开庭日期
   * @format date-time
   */
  courtDate?: string;
  /** 简要案情和工作概况 */
  result?: string | null;
  /** 处理结果 */
  pocessResult?: string | null;
  /** 律师办案体会 */
  experience?: string | null;
  /** 代理事项 */
  discussion?: string | null;
  /** 结案必要文件夹 */
  folders?: string | null;
  /** 归档人编号 */
  archiveUserId?: string | null;
  /**
   * 归档日期
   * @format date-time
   */
  archiveDate?: string;
  /** 归档编号 */
  archiveId?: string | null;
  /** 归档状态 */
  archiveStatus?: string | null;
  /** 服务结果（中止/终止/存续） */
  serviceResult?: string | null;
  /** 电子档案编号 */
  electronicFileNo?: string | null;
  /** 是否扫描件 */
  isScanFile?: boolean;
  /** 是否逾期结案 */
  isOverdueFiling?: boolean;
  /** 逾期结案原因 */
  overdueFilingReason?: string | null;
  /**
   * 逾期天数
   * @format double
   */
  overdueDays?: number;
  /** 归档地 */
  archivePlace?: string | null;
  /** 部门ID  DepartmentId */
  organizationUnitId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface CaseClosedDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: CaseClosedDto[] | null;
}

/** 通用返回信息类 */
export interface CaseClosedDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 案件结案表（撤案/销案） */
  response?: CaseClosedDto;
}

/** 通用分页信息类 */
export interface CaseClosedDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: CaseClosedDto[] | null;
}

/** 通用返回信息类 */
export interface CaseClosedDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: CaseClosedDtoPageModel;
}

/** 案件收款信息表 */
export interface CaseCollectionDto {
  /** 案件编号 */
  caseId?: string | null;
  /** 收费方式 */
  chargingMethod?: string | null;
  /** 收费方式文本 */
  chargingMethodText?: string | null;
  /**
   * 收费金额
   * @format double
   */
  amount?: number;
  /**
   * 基础风险金额
   * @format double
   */
  riskBasicAmount?: number;
  /** 说明描述/风险达成条件 */
  narrative?: string | null;
  /** 是否可收款 */
  isAcceptable?: string | null;
  /** 是否使用账单 */
  isUseBilling?: string | null;
  /**
   * 收费日期
   * @format date-time
   */
  chargeDate?: string;
  /** 是否已到账 */
  isArrived?: string | null;
  /**
   * 到账日期
   * @format date-time
   */
  arrivedDate?: string;
  /**
   * 到账金额
   * @format double
   */
  arrivedAmount?: number;
  /** 币种 */
  currency?: string | null;
  /** 币种文本 */
  currencyText?: string | null;
  /**
   * 汇率
   * @format double
   */
  exchangeRate?: number;
  /** 是否账单 */
  isBilling?: string | null;
  /** 关联账单编号 */
  billingId?: string | null;
  /**
   * 付款比例
   * @format double
   */
  chargeRatio?: number;
  /**
   * 开始时间
   * @format date-time
   */
  startDate?: string;
  /**
   * 结束时间
   * @format date-time
   */
  endDate?: string;
  /** 是否提醒 */
  isRemind?: string | null;
  /**
   * 提醒时间
   * @format date-time
   */
  remindTime?: string;
  /**
   * 风险收费比例
   * @format double
   */
  riskRatio?: number;
  /** 关联案件编号 */
  relationCaseId?: string | null;
  /**
   * 外币收费金额
   * @format double
   */
  foreignCurrencyAmount?: number;
  /**
   * 排序
   * @format int32
   */
  sort?: number;
  /**
   * 标的额
   * @format double
   */
  targetAmount?: number;
  /**
   * 标的比例
   * @format double
   */
  targetRatio?: number;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface CaseCollectionDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: CaseCollectionDto[] | null;
}

/** 通用返回信息类 */
export interface CaseCollectionDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 案件收款信息表 */
  response?: CaseCollectionDto;
}

/** 通用分页信息类 */
export interface CaseCollectionDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: CaseCollectionDto[] | null;
}

/** 通用返回信息类 */
export interface CaseCollectionDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: CaseCollectionDtoPageModel;
}

/** 案件分配表 */
export interface CaseDistributionDto {
  /** 案件编号 */
  caseId?: string | null;
  /** 用户编号 */
  userId?: string | null;
  /** 用户名称 */
  lawyerName?: string | null;
  /** 用户头像 */
  pictureUrl?: string | null;
  /** 律师角色 */
  lawyerRole?: string | null;
  /** 律师角色 */
  lawyerRoleText?: string | null;
  /**
   * 分配金额
   * @format double
   */
  allocationAmount?: number;
  /**
   * 分配比例
   * @format double
   */
  allocationRate?: number;
  /**
   * 尾差金额
   * @format double
   */
  tailAmount?: number;
  /** 是否案源费 */
  isSourceFee?: boolean;
  /** 是否默认策略 */
  isDefault?: boolean;
  /**
   * 排序
   * @format int32
   */
  sort?: number;
  /** 办公室 */
  officeId?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface CaseDistributionDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: CaseDistributionDto[] | null;
}

/** 通用返回信息类 */
export interface CaseDistributionDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 案件分配表 */
  response?: CaseDistributionDto;
}

/** 通用分页信息类 */
export interface CaseDistributionDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: CaseDistributionDto[] | null;
}

/** 通用返回信息类 */
export interface CaseDistributionDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: CaseDistributionDtoPageModel;
}

export interface CaseLawyerAssignmentDto {
  /** 利冲/案件编号 */
  caseId?: string | null;
  /** 用户编号 */
  userId?: string | null;
  /** 用户名称 */
  displayName?: string | null;
  /** 用户头像 */
  pictureUrl?: string | null;
  /** 用户邮箱 */
  emailAddress?: string | null;
  /** 律师参案角色 */
  lawyerRole?: string | null;
  /** 律师参案角色文本 */
  lawyerRoleText?: string | null;
  /**
   * 律师费率
   * @format double
   */
  chargeRatio?: number;
  /**
   * 服务开始时间
   * @format date-time
   */
  startDate?: string | null;
  /**
   * 服务结束时间
   * @format date-time
   */
  endDate?: string | null;
  /** 是否非当前服务律师 */
  isInActive?: boolean;
  /**
   * 排序
   * @format int32
   */
  sort?: number;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 案件参与律师表 */
export interface CaseLawyerDto {
  /** 利冲/案件编号 */
  caseId?: string | null;
  /** 用户编号 */
  userId?: string | null;
  /** 用户名称 */
  displayName?: string | null;
  /** 用户头像 */
  pictureUrl?: string | null;
  /** 用户邮箱 */
  emailAddress?: string | null;
  /** 律师参案角色 */
  lawyerRole?: string | null;
  /** 律师参案角色文本 */
  lawyerRoleText?: string | null;
  /**
   * 律师费率
   * @format double
   */
  chargeRatio?: number;
  /** 币种 */
  currency?: string | null;
  /** 币种 */
  currencyText?: string | null;
  /**
   * 律师费率 外币
   * @format double
   */
  usdChargeRatio?: number;
  /**
   * 分配比例
   * @format double
   */
  assignRatio?: number;
  /** 是否启用（参与分配） */
  isEnableAssign?: boolean;
  /**
   * 服务开始时间
   * @format date-time
   */
  startDate?: string | null;
  /**
   * 服务结束时间
   * @format date-time
   */
  endDate?: string | null;
  /** 是否非当前服务律师 */
  isInActive?: boolean;
  /**
   * 排序
   * @format int32
   */
  sort?: number;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface CaseLawyerDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: CaseLawyerDto[] | null;
}

/** 通用返回信息类 */
export interface CaseLawyerDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 案件参与律师表 */
  response?: CaseLawyerDto;
}

/** 通用分页信息类 */
export interface CaseLawyerDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: CaseLawyerDto[] | null;
}

/** 通用返回信息类 */
export interface CaseLawyerDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: CaseLawyerDtoPageModel;
}

/** 案件信息表 */
export interface CaseMatterDto {
  /** 利冲预检编号 */
  preCsrId?: string | null;
  /** 利冲申请编号 */
  csrId?: string | null;
  /** 案件类型 */
  category?: string | null;
  /** 案由分类 */
  reason?: string | null;
  /** 补充案由 */
  reasonSupplement?: string | null;
  /** 关联案件编号 */
  relationCaseId?: string | null;
  /** 案件名称 */
  name?: string | null;
  /** 案件名称注释备注 */
  nameNote?: string | null;
  /** 客户行业 */
  clientIndustryId?: string | null;
  /** 执业领域 */
  practiceArea?: string | null;
  /** 是否涉密 */
  isClassified?: string | null;
  /** 是否紧急 */
  isUrgent?: string | null;
  /** 收费方式（固定，小时，风险，免费代理） */
  chargingMethods?: string | null;
  /**
   * 合同金额（费用总额）
   * @format double
   */
  contractAmount?: number;
  /** 委托事项 */
  entrustedMatters?: string | null;
  /** 书写语言 */
  writtenLanguage?: string | null;
  /** 案件性质 */
  caseNature?: string | null;
  /**
   * 收案日期
   * @format date-time
   */
  acceptDate?: string | null;
  /** 案件来源 */
  caseSource?: string | null;
  /** 委托阶段 */
  stages?: string | null;
  /** 当前阶段 */
  currentStage?: string | null;
  /** 受理机构 */
  acceptAgency?: string | null;
  /** 受理机构案号 */
  acceptCaseNo?: string | null;
  /** 受理机构级别（法院级别） */
  acceptAgencyLevel?: string | null;
  /** 特殊案件类型 */
  specialType?: string | null;
  /** 是否跨所合作案件 */
  isInterbankCooperation?: string | null;
  /** 合作分所 */
  cooperationOffice?: string | null;
  /** 合作律师 */
  cooperativeLawyerIds?: string | null;
  /** 案情简介 */
  narrative?: string | null;
  /** 代理权限 */
  agencyAuthority?: string | null;
  /** 诉讼地位（代理何方） */
  litigationStatus?: string | null;
  /** 是否标的 */
  isTarget?: string | null;
  /**
   * 标的额
   * @format double
   */
  targetAmount?: number;
  /** 货币单位 */
  currency?: string | null;
  /**
   * 顾问合同起始时间
   * @format date-time
   */
  startDate?: string | null;
  /**
   * 顾问合同结束时间（空则长期）
   * @format date-time
   */
  endDate?: string | null;
  /**
   * 律师费用
   * @format double
   */
  lawyerFee?: number;
  /**
   * 办案费
   * @format double
   */
  caseHandlingFee?: number;
  /**
   * 代收代付费
   * @format double
   */
  pocoFee?: number;
  /** 案件项下办案费支出方 */
  feePaidParty?: string | null;
  /**
   * 办案费限额
   * @format double
   */
  caseHandlingFeeLimit?: number;
  /** 约定汇率模式 */
  agreedExchangeRateMode?: string | null;
  /**
   * 汇率
   * @format double
   */
  exchangeRate?: number;
  /**
   * 免费代理小时数
   * @format float
   */
  freeHourLimit?: number;
  /** 免费代理原因 */
  freeAttorneyReason?: string | null;
  /** 是否计费 */
  isBillingFee?: boolean;
  /** 是否折扣 */
  isDiscount?: boolean;
  /**
   * 折扣金额
   * @format double
   */
  discountAmount?: number;
  /** 计时收费费率 */
  hourFeeRateType?: string | null;
  /** 风险收费类别 */
  riskFeeType?: string | null;
  /** 主管律师（主办律师） */
  inChargeLawyer?: string | null;
  /** 协办律师（合作律师） */
  coWorkingLawyer?: string | null;
  /** 案源律师 */
  caseSourceLawyer?: string | null;
  /** 账单律师 */
  billingLawyer?: string | null;
  /**
   * 秘书助理
   *
   * （预留）;选择人则限定选择人和值班秘书可见，不选就全部助理和秘书可见
   */
  assistantSecretary?: string | null;
  /** 申请合伙人 */
  applicant?: string | null;
  /**
   * 结案时间
   * @format date-time
   */
  closedTime?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /**
   * 预估回收率
   * @format double
   */
  estimatedRecoveryRate?: number;
  /** 是否克隆 */
  isClone?: boolean;
  /** 原案件编号 */
  fromCaseId?: string | null;
  /** 原利冲编号 */
  fromCsrId?: string | null;
  /** 是否所务案件 */
  isInternal?: boolean;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface CaseMatterDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: CaseMatterDto[] | null;
}

/** 通用返回信息类 */
export interface CaseMatterDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 案件信息表 */
  response?: CaseMatterDto;
}

/** 通用分页信息类 */
export interface CaseMatterDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: CaseMatterDto[] | null;
}

/** 通用返回信息类 */
export interface CaseMatterDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: CaseMatterDtoPageModel;
}

export interface CaseMatterOutput {
  /** 案件类型文本 */
  categoryText?: string | null;
  /** 案由分类文本 */
  reasonText?: string | null;
  /** 客户行业文本 */
  clientIndustryText?: string | null;
  /** 执业领域文本 */
  practiceAreaText?: string | null;
  /** 案件性质 */
  caseNatureText?: string | null;
  /** 是否涉密文本 */
  isClassifiedText?: string | null;
  /** 收费方式文本 */
  chargingMethodText?: string | null;
  /** 是否紧急文本 */
  isUrgentText?: string | null;
  /** 书写语言文本 */
  writtenLanguageText?: string | null;
  /** 案件来源文本 */
  caseSourceText?: string | null;
  /** 委托阶段文本 */
  stageText?: string | null;
  /** 当前阶段文本 */
  currentStageText?: string | null;
  /** 受理机构级别（法院级别） */
  acceptAgencyLevelText?: string | null;
  /** 特殊案件类型文本 */
  specialTypeText?: string | null;
  /** 代理权限文本 */
  agencyAuthorityText?: string | null;
  /** 是否标的文本 */
  isTargetText?: string | null;
  /** 货币单位文本 */
  currencyText?: string | null;
  /** 案件项下办案费支出方 */
  feePaidPartyText?: string | null;
  /** 是否跨所合作案件文本 */
  isInterbankCooperationText?: string | null;
  /** 合作分所名称 */
  cooperationOfficeName?: string | null;
  /** 利冲申请编号 */
  csrSerialId?: string | null;
  /** 关联案件名称 */
  relationCaseName?: string | null;
  /** 关联案件编号 */
  relationCaseSerialId?: string | null;
  /**
   * 申请时间
   * @format date-time
   */
  submittedOn?: string | null;
  /** 案件律师信息 */
  caseLawyers?: CaseLawyerDto[] | null;
  /** 案件费用信息 */
  caseCollections?: CaseCollectionDto[] | null;
  /** 案件分配信息 */
  caseDistributions?: CaseDistributionDto[] | null;
  /** 案件客户关联信息 */
  caseRelatedPartys?: CaseRelatedPartyDto[] | null;
  /** 客户名称 */
  clientName?: string | null;
  /** 客户英文名称 */
  clientForeignName?: string | null;
  /** 案件编号 */
  serialId?: string | null;
  /** 计时收费费率文本 */
  hourFeeRateTypeText?: string | null;
  /** 风险收费类别文本 */
  riskFeeTypeText?: string | null;
  /** 主管律师名称 */
  inChargeLawyerName?: string | null;
  /** 协办律师名称 */
  coWorkingLawyerName?: string | null;
  /** 案源律师名称 */
  caseSourceLawyerName?: string | null;
  /** 账单律师名称 */
  billingLawyerName?: string | null;
  /** 申请合伙人名称 */
  applicantName?: string | null;
  /** 是否已出账单 */
  issuedBilling?: string | null;
  /** 是否已出账单 */
  issuedBillingText?: string | null;
  agencyAgreements?: CreateSysDocumentCenterListInput;
  otherDocuments?: CreateSysDocumentCenterListInput;
  exemptionDocuments?: CreateSysDocumentCenterListInput;
  communicationDocuments?: CreateSysDocumentCenterListInput;
  /** 利冲预检编号 */
  preCsrId?: string | null;
  /** 利冲申请编号 */
  csrId?: string | null;
  /** 案件类型 */
  category?: string | null;
  /** 案由分类 */
  reason?: string | null;
  /** 补充案由 */
  reasonSupplement?: string | null;
  /** 关联案件编号 */
  relationCaseId?: string | null;
  /** 案件名称 */
  name?: string | null;
  /** 案件名称注释备注 */
  nameNote?: string | null;
  /** 客户行业 */
  clientIndustryId?: string | null;
  /** 执业领域 */
  practiceArea?: string | null;
  /** 是否涉密 */
  isClassified?: string | null;
  /** 是否紧急 */
  isUrgent?: string | null;
  /** 收费方式（固定，小时，风险，免费代理） */
  chargingMethods?: string | null;
  /**
   * 合同金额（费用总额）
   * @format double
   */
  contractAmount?: number;
  /** 委托事项 */
  entrustedMatters?: string | null;
  /** 书写语言 */
  writtenLanguage?: string | null;
  /** 案件性质 */
  caseNature?: string | null;
  /**
   * 收案日期
   * @format date-time
   */
  acceptDate?: string | null;
  /** 案件来源 */
  caseSource?: string | null;
  /** 委托阶段 */
  stages?: string | null;
  /** 当前阶段 */
  currentStage?: string | null;
  /** 受理机构 */
  acceptAgency?: string | null;
  /** 受理机构案号 */
  acceptCaseNo?: string | null;
  /** 受理机构级别（法院级别） */
  acceptAgencyLevel?: string | null;
  /** 特殊案件类型 */
  specialType?: string | null;
  /** 是否跨所合作案件 */
  isInterbankCooperation?: string | null;
  /** 合作分所 */
  cooperationOffice?: string | null;
  /** 合作律师 */
  cooperativeLawyerIds?: string | null;
  /** 案情简介 */
  narrative?: string | null;
  /** 代理权限 */
  agencyAuthority?: string | null;
  /** 诉讼地位（代理何方） */
  litigationStatus?: string | null;
  /** 是否标的 */
  isTarget?: string | null;
  /**
   * 标的额
   * @format double
   */
  targetAmount?: number;
  /** 货币单位 */
  currency?: string | null;
  /**
   * 顾问合同起始时间
   * @format date-time
   */
  startDate?: string | null;
  /**
   * 顾问合同结束时间（空则长期）
   * @format date-time
   */
  endDate?: string | null;
  /**
   * 律师费用
   * @format double
   */
  lawyerFee?: number;
  /**
   * 办案费
   * @format double
   */
  caseHandlingFee?: number;
  /**
   * 代收代付费
   * @format double
   */
  pocoFee?: number;
  /** 案件项下办案费支出方 */
  feePaidParty?: string | null;
  /**
   * 办案费限额
   * @format double
   */
  caseHandlingFeeLimit?: number;
  /** 约定汇率模式 */
  agreedExchangeRateMode?: string | null;
  /**
   * 汇率
   * @format double
   */
  exchangeRate?: number;
  /**
   * 免费代理小时数
   * @format float
   */
  freeHourLimit?: number;
  /** 免费代理原因 */
  freeAttorneyReason?: string | null;
  /** 是否计费 */
  isBillingFee?: boolean;
  /** 是否折扣 */
  isDiscount?: boolean;
  /**
   * 折扣金额
   * @format double
   */
  discountAmount?: number;
  /** 计时收费费率 */
  hourFeeRateType?: string | null;
  /** 风险收费类别 */
  riskFeeType?: string | null;
  /** 主管律师（主办律师） */
  inChargeLawyer?: string | null;
  /** 协办律师（合作律师） */
  coWorkingLawyer?: string | null;
  /** 案源律师 */
  caseSourceLawyer?: string | null;
  /** 账单律师 */
  billingLawyer?: string | null;
  /**
   * 秘书助理
   *
   * （预留）;选择人则限定选择人和值班秘书可见，不选就全部助理和秘书可见
   */
  assistantSecretary?: string | null;
  /** 申请合伙人 */
  applicant?: string | null;
  /**
   * 结案时间
   * @format date-time
   */
  closedTime?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /**
   * 预估回收率
   * @format double
   */
  estimatedRecoveryRate?: number;
  /** 是否克隆 */
  isClone?: boolean;
  /** 原案件编号 */
  fromCaseId?: string | null;
  /** 原利冲编号 */
  fromCsrId?: string | null;
  /** 是否所务案件 */
  isInternal?: boolean;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface CaseMatterOutputMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  response?: CaseMatterOutput;
}

export interface CasePartyResultSearchInput {
  /** 类型 */
  category?: string | null;
  /** 名称 */
  name?: string | null;
  /** 外文名称 */
  foreignName?: string | null;
  /** 关键字 */
  keyWords?: string[] | null;
  /** 外国人关键字 */
  foreignKeyWords?: string[] | null;
}

export interface CaseRelPartyValidatorDto {
  /** 关联方名称 */
  name?: string | null;
  /** 关联方名称必填验证错误信息，为空无错误 */
  nameMsg?: string | null;
  /** 外文名称 */
  foreignName?: string | null;
  /** 外文名称必填验证错误信息，为空无错误 */
  foreignNameMsg?: string | null;
  /** 关联方类型 */
  category?: string | null;
  /** 关联方类型必填验证错误信息，为空无错误 */
  categoryMsg?: string | null;
  /** 客户性质 */
  customerNature?: string | null;
  /** 客户性质必填验证错误信息，为空无错误 */
  customerNatureMsg?: string | null;
  /** 证件类型 */
  creditType?: string | null;
  /** 证件类型必填验证错误信息，为空无错误 */
  creditTypeMsg?: string | null;
  /** 证件号码 */
  creditCode?: string | null;
  /** 证件号码必填验证错误信息，为空无错误 */
  creditCodeMsg?: string | null;
}

/** 通用返回信息类 */
export interface CaseRelPartyValidatorDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: CaseRelPartyValidatorDto[] | null;
}

/** 案件关联方信息表 */
export interface CaseRelatedPartyDto {
  /** 利冲/案件编号 */
  caseId?: string | null;
  /** 客户编号 */
  clientId?: string | null;
  /** 关联方类型 */
  category?: string | null;
  /** 关联方类型文本 */
  categoryText?: string | null;
  /** 文书关联编号 */
  caseFileRelationId?: string | null;
  /** 关联方名称 */
  name?: string | null;
  /** 外文名称 */
  foreignName?: string | null;
  /** 曾用名 */
  formerName?: string | null;
  /** 联系人 */
  linker?: string | null;
  /** 证件类型 */
  creditType?: string | null;
  /** 证件类型类型 */
  creditTypeText?: string | null;
  /** 证件号码 */
  creditCode?: string | null;
  /** 邮件地址 */
  emailAddress?: string | null;
  /** 联系电话 */
  phoneNumber?: string | null;
  /** 联系地址 */
  address?: string | null;
  /** 关联方角色 */
  partyRole?: string | null;
  /** 关联方角色文本 */
  partyRoleText?: string | null;
  /** 利冲关键词 */
  conflictKeyword?: string | null;
  /** 是否双方当事人 */
  isParties?: boolean;
  /** 说明描述 */
  narrative?: string | null;
  /** 部门ID  DepartmentId */
  organizationUnitId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 备注 */
  remark?: string | null;
  /** 父级ID */
  parentId?: string | null;
  /** 是否草稿Draft */
  isEnabled?: boolean;
  /** 客户性质 */
  customerNature?: string | null;
  /** 客户性质文本 */
  customerNatureText?: string | null;
  /** 实体主体信息表 */
  entityInfo?: EntityInfoDto;
  /** 客户信息表 */
  clientInfo?: ClientDto;
  /** 所属集团Id */
  groupId?: string | null;
  /** 所属集团名称 */
  groupName?: string | null;
  /** 所属集团名称简称 */
  groupAltName?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface CaseRelatedPartyDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: CaseRelatedPartyDto[] | null;
}

/** 通用返回信息类 */
export interface CaseRelatedPartyDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 案件关联方信息表 */
  response?: CaseRelatedPartyDto;
}

/** 关系结果 */
export interface CaseRelatedResultDto {
  /** 案件或利冲编号 */
  caseId?: string | null;
  /** 客户编号 */
  clientId?: string | null;
  /** 关联方类型 */
  category?: string | null;
  /** 关联方名称 */
  name?: string | null;
  /** 外文名称 */
  foreignName?: string | null;
  /** 曾用名 */
  formerName?: string | null;
  /** 联系人 */
  linker?: string | null;
  /** 证件类型 */
  creditType?: string | null;
  /** 证件号码 */
  creditCode?: string | null;
  /** 关联方角色 */
  partyRole?: string | null;
  /** 利冲关键词 */
  conflictKeyword?: string | null;
  /** 是否双方当事人 */
  isParties?: boolean;
  parentId?: string | null;
  /** @format date-time */
  submittedOn?: string | null;
  /** @format date-time */
  createTime?: string;
  /** Id */
  id?: string | null;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

export interface CaseRelationExcelDto {
  parentName?: string | null;
  /** 关联方名称 */
  name?: string | null;
  /** 外文名称 */
  foreignName?: string | null;
  /** 关联方类型(CP:客户,EP:参与实体,OP:对方当事人,OPE:对方关联方,TP:第三方) */
  category?: string | null;
  /** 证件类型 */
  creditTypeText?: string | null;
  /** 证件类型 */
  creditType?: string | null;
  /** 证件号码 */
  creditCode?: string | null;
}

/** 通用返回信息类 */
export interface CaseRelationExcelDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: CaseRelationExcelDto[] | null;
}

export interface CerRequestPartnerConfirmationApplicationInput {
  /** 审查结果集Id */
  csrProcessId?: string | null;
  /** 是否提醒 */
  isRemind?: boolean;
  /** 需要豁免的案件 */
  csrRelationList?: CsrRelation[] | null;
  /** 业务Id */
  id?: string | null;
  /** 审批意见 */
  approvalOpinion?: string | null;
  /** 附件 */
  attachments?: string | null;
  /** 传参 */
  paramsString?: string | null;
}

export interface ChangeDefaultTenantBankInfoInput {
  /** 收款银行账号主键 */
  id?: string | null;
  /** 是否默认收款账号 */
  isDefaultCollectionAccount?: string | null;
  /** 是否默认付款账号 */
  isDefaultPaymentAccount?: string | null;
}

/** 变更信息（返回前100条） */
export interface ChangeListItem {
  /** 变更事项 */
  projectName?: string | null;
  /** 变更日期，精确到天，如“2022-01-01” */
  changeDate?: string | null;
  /** 变更前内容列表 */
  beforeList?: string[] | null;
  /** 变更后内容列表 */
  afterList?: string[] | null;
}

export interface ChangeTenantBankInfoOfficeInput {
  /** 收款银行账号主键 */
  id?: string | null;
  /** 变更后的办公室编号 */
  officeId?: string | null;
}

/** 客户所属人表 */
export interface ClientBelongTimekeeperDto {
  id?: string | null;
  /** 客户编号 */
  clientId?: string | null;
  /** 计时人员编号 */
  timekeeperId?: string | null;
  /** 是否其助理或秘书可见 */
  isAssistantVisible?: string | null;
  /** 是否团队律师可见 */
  isTeamLawyerVisible?: string | null;
  /**
   * 所属时效开始时间
   * @format date-time
   */
  startDate?: string;
  /**
   * 所属时效结束时间（空则长期有效）
   * @format date-time
   */
  endDate?: string;
  /** 是否是初始所属人 */
  isFirstOwner?: string | null;
  /** 是否过期失效 */
  isExpred?: string | null;
  /** 租户ID */
  tenantId?: string | null;
  /** 部门ID  DepartmentId */
  organizationUnitId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 备注 */
  remark?: string | null;
  /** 状态 	                中立字段，某些表可使用某些表不使用 */
  isEnabled?: boolean;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface ClientBelongTimekeeperDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: ClientBelongTimekeeperDto[] | null;
}

/** 通用返回信息类 */
export interface ClientBelongTimekeeperDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 客户所属人表 */
  response?: ClientBelongTimekeeperDto;
}

/** 通用分页信息类 */
export interface ClientBelongTimekeeperDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: ClientBelongTimekeeperDto[] | null;
}

/** 通用返回信息类 */
export interface ClientBelongTimekeeperDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: ClientBelongTimekeeperDtoPageModel;
}

/** 客户信息表 */
export interface ClientDto {
  id?: string | null;
  /** 客户编号 */
  serialId?: string | null;
  /** 客户名称 */
  name?: string | null;
  /** 外文名称 */
  foreignName?: string | null;
  /** 曾用名 */
  formerName?: string | null;
  /** 别名 */
  altName?: string | null;
  /** 客户行业 */
  clientIndustryId?: string | null;
  /** 客户类型 */
  clientType?: string | null;
  /** 证件类型 */
  creditType?: string | null;
  /** 证件号码 */
  creditCode?: string | null;
  /** 来源类型（立案/入库/投标） */
  originType?: string | null;
  /** 来源编号 */
  originId?: string | null;
  /** 通讯地址 */
  address?: string | null;
  /** 税号 */
  taxNumber?: string | null;
  /** 邮箱地址 */
  emailAddress?: string | null;
  /** 手机号码 */
  phoneNumber?: string | null;
  /** 固定电话 */
  landline?: string | null;
  /** 描述说明 */
  narrative?: string | null;
  /** 客户级别 */
  clientLevel?: string | null;
  /** 客户性质 */
  customerNature?: string | null;
  /** 租户ID */
  tenantId?: string | null;
  /** 部门ID */
  organizationUnitId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 备注 */
  remark?: string | null;
  clientGroupInfo?: GroupInfoDto;
  /** 是否所务客户 */
  isInternal?: boolean;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface ClientDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: ClientDto[] | null;
}

/** 通用返回信息类 */
export interface ClientDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 客户信息表 */
  response?: ClientDto;
}

export interface ClientOutput {
  /** 是否保护 */
  isProtect?: boolean;
  /** 客户类型 */
  clientTypeText?: string | null;
  /** 客户行业 */
  clientIndustryText?: string | null;
  /** 证件类型 */
  creditTypeText?: string | null;
  /** 客户性质 */
  customerNatureText?: string | null;
  /** 创建人 */
  createUserName?: string | null;
  id?: string | null;
  /** 客户编号 */
  serialId?: string | null;
  /** 客户名称 */
  name?: string | null;
  /** 外文名称 */
  foreignName?: string | null;
  /** 曾用名 */
  formerName?: string | null;
  /** 别名 */
  altName?: string | null;
  /** 客户行业 */
  clientIndustryId?: string | null;
  /** 客户类型 */
  clientType?: string | null;
  /** 证件类型 */
  creditType?: string | null;
  /** 证件号码 */
  creditCode?: string | null;
  /** 来源类型（立案/入库/投标） */
  originType?: string | null;
  /** 来源编号 */
  originId?: string | null;
  /** 通讯地址 */
  address?: string | null;
  /** 税号 */
  taxNumber?: string | null;
  /** 邮箱地址 */
  emailAddress?: string | null;
  /** 手机号码 */
  phoneNumber?: string | null;
  /** 固定电话 */
  landline?: string | null;
  /** 描述说明 */
  narrative?: string | null;
  /** 客户级别 */
  clientLevel?: string | null;
  /** 客户性质 */
  customerNature?: string | null;
  /** 租户ID */
  tenantId?: string | null;
  /** 部门ID */
  organizationUnitId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 备注 */
  remark?: string | null;
  clientGroupInfo?: GroupInfoDto;
  /** 是否所务客户 */
  isInternal?: boolean;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface ClientOutputMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  response?: ClientOutput;
}

/** 通用分页信息类 */
export interface ClientOutputPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: ClientOutput[] | null;
}

/** 通用返回信息类 */
export interface ClientOutputPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: ClientOutputPageModel;
}

export interface ComboboxGroupDto {
  items?: ComboboxGroupDto[] | null;
  /** Value of the item. */
  value?: string | null;
  /** Display text of the item. */
  displayText?: string | null;
  /** 是否启用 */
  isActive?: boolean;
  /** 是否选中 */
  isSelected?: boolean;
  /** 父级Id */
  parentCode?: string | null;
  /** 描述 */
  description?: string | null;
  /** 用户组 */
  group?: string | null;
  /**
   * 作用域
   * @format int32
   */
  scope?: number;
}

/** 通用返回信息类 */
export interface ComboboxGroupDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: ComboboxGroupDto[] | null;
}

export interface ComboboxItemDto {
  /** Value of the item. */
  value?: string | null;
  /** Display text of the item. */
  displayText?: string | null;
  /** 是否启用 */
  isActive?: boolean;
  /** 是否选中 */
  isSelected?: boolean;
  /** 父级Id */
  parentCode?: string | null;
  /** 描述 */
  description?: string | null;
  /** 用户组 */
  group?: string | null;
  /**
   * 作用域
   * @format int32
   */
  scope?: number;
}

/** 通用返回信息类 */
export interface ComboboxItemDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: ComboboxItemDto[] | null;
}

/** 联系信息 */
export interface ContactInfo {
  webSiteList?: string[] | null;
  email?: string | null;
  moreEmailList?: MoreEmailListItem[] | null;
  tel?: string | null;
  moreTelList?: MoreTelListItem[] | null;
}

/** 利冲偏好 */
export interface CreateCSRProcessPerferenceInput {
  /** 是否启用法律意义上检索 */
  isEnabledLegalSenseSearch?: boolean | null;
  /** 是否高级搜索 */
  isAdvanceSearch?: boolean | null;
  /** 关键字 */
  keywords?: string | null;
  /**
   * 检索模式（精确，模糊）
   * @format int32
   */
  searchMode?: 0 | 1 | 2 | 3;
  /** 我方名称 */
  ourPatryName?: string | null;
  /** 我方外文名称 */
  ourPatryForeignName?: string | null;
  /** 对方名称 */
  oppositeName?: string | null;
  /** 对方外文名称 */
  oppositeForeignName?: string | null;
  /** 潜在三方名称 */
  potential3rdPartyName?: string | null;
  /** 潜在三方外文名称 */
  potential3rdPartyForeignName?: string | null;
  /** 是否分词检索 */
  isSplitWordSearch?: boolean | null;
  /** 是否外文分词检索 */
  isForeignSplitWordSearch?: string | null;
  /**
   * 匹配结果数量
   * @format float
   */
  matchingCount?: number;
  /** 需要豁免案件编号 */
  needExemptionCaseId?: string | null;
  /** 利冲预检人编号 */
  requestUserId?: string | null;
  /** 说明描述 */
  narrative?: string | null;
  /** 关联利冲编号 */
  csrId?: string | null;
  /** 关联业务编号(立案/投标/入库) */
  relationId?: string | null;
  /** 标签名 */
  tagName?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 利冲审查结果表 */
export interface CreateCSRProcessResultPerferenceInput {
  /** 利冲申请编号 */
  csrId?: string | null;
  /** 利冲审查编号 */
  csrProcessId?: string | null;
  /** 是否需要豁免 */
  isNeedExemption?: string | null;
  /** 是否选中 */
  isChecked?: string | null;
  /** 利冲关键词 */
  csrKeyword?: string | null;
  /** 与我冲突案件的角色 */
  csrParty?: string | null;
  /** 我被冲突案件的角色 */
  caseParty?: string | null;
  /** 我的案件冲突方名称 */
  casePartyName?: string | null;
  /** 冲突等级 */
  csrLevel?: string | null;
  /** 利冲关联业务类型 */
  csrRelationType?: string | null;
  /** 关联业务编号（默认案件） */
  relationId?: string | null;
  /** 冲突产生时关联业务状态 */
  relationStatus?: string | null;
  /** 利冲豁免结果 */
  exemptionResult?: string | null;
  /** 匹配利冲规则 */
  matchingRule?: string | null;
  /** 关联方信息 */
  caseRelatedResultJson?: string | null;
  /** 匹配客户名称 */
  clientName?: string | null;
  /** 匹配客户外文名称 */
  clientForeignName?: string | null;
  /** 匹配客户实控人名称 */
  clientShareholderActualCtrlName?: string | null;
  /** 匹配客户实控人外文名称 */
  clientShareholderActualCtrlForeignName?: string | null;
  /** 客户参与实体名称 */
  clientParticipatingEntityName?: string | null;
  /** 客户参与实体外文名称 */
  clientParticipatingEntityForeignName?: string | null;
  /** 匹配对方名称 */
  oppositeName?: string | null;
  /** 匹配对方外文名称 */
  oppositeForeignName?: string | null;
  /** 匹配对方实控人名称 */
  oppositeShareholderActualCtrlName?: string | null;
  /** 匹配对方实控人外文名称 */
  oppositeShareholderActualCtrlForeignName?: string | null;
  /** 对方关联方名称 */
  oppositeRelatedPartiesName?: string | null;
  /** 对方关联方外文名称 */
  oppositeRelatedPartiesForeignName?: string | null;
  /** 潜在第三方名称 */
  potential3rdPartyName?: string | null;
  /** 潜在第三方外文名称 */
  potential3rdPartyForeignName?: string | null;
  /** 潜在第三方实控人名称 */
  potential3rdPartyShareholderActualCtrlName?: string | null;
  /** 潜在第三方实控人外文名称 */
  potential3rdPartyShareholderActualCtrlForeignName?: string | null;
  /** 关联业务类型 */
  relationCategory?: string | null;
  /** 关联业务名称 */
  relationName?: string | null;
  /**
   * 关联业务申请时间
   * @format date-time
   */
  relationRequestTime?: string | null;
  /** 关联业务主管律师 */
  relationInChargeLawyerId?: string | null;
  /** 关联业务申请合伙人 */
  relationRequestUserId?: string | null;
  /** 案源人 */
  relationCaseUser?: string | null;
  /** 关联实体 */
  relationEntity?: string | null;
  /**
   * 结案时间
   * @format date-time
   */
  relationEndTime?: string | null;
  /** 确认律师Id */
  confirmLawyerId?: string | null;
  /** 账单管理人 */
  relationBillingLawyerNameId?: string | null;
  /** 关联客户Id */
  clientId?: string | null;
  /** 标签 */
  tagName?: string | null;
  /** 流水编号 */
  serialId?: string | null;
  /** 原系统主键编号 */
  originalId?: string | null;
  /** 流程数据大状态 */
  status?: string | null;
  /**
   * 提交时间
   * @format date-time
   */
  submittedOn?: string | null;
  /** 工作流状态 */
  flowState?: string | null;
  /** 申请人 */
  applicant?: string | null;
  /**
   * 工作流结束时间
   * @format date-time
   */
  flowEndDateTime?: string | null;
  /** 是否超期 */
  isOverdue?: boolean | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 利冲偏好标签 */
export interface CreateCSRProcessTagsPerferenceInput {
  tagName?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 案件受理机构信息表 */
export interface CreateCaseAcceptAgencyInput {
  /** 案件或利冲编号 */
  caseId?: string | null;
  /** 机构名称 */
  name?: string | null;
  /** 机构类别（法院/检察院/监狱等） */
  agencyType?: string | null;
  /**
   * 开始时间
   * @format date-time
   */
  startTime?: string;
  /**
   * 结束时间
   * @format date-time
   */
  endTime?: string;
  /** 事由分类 */
  category?: string | null;
  /** 案件阶段 */
  stage?: string | null;
  /** 联系人 */
  linker?: string | null;
  /** 联系电话 */
  phoneNumber?: string | null;
  /** 关联法院编号 */
  courtId?: string | null;
  /** 部门 */
  organizationUnitId?: string | null;
  /** 分所 */
  officeId?: string | null;
  /** 备注 */
  remark?: string | null;
  /** 状态 	            中立字段，某些表可使用某些表不使用 */
  isEnabled?: boolean;
  /** 中立字段 	            是否内置数据 */
  isInternal?: boolean;
}

/** 案件结案表（撤案/销案） */
export interface CreateCaseClosedInput {
  id?: string | null;
  /** 案件编号 */
  caseId?: string | null;
  /** 裁判类型（判决/裁定/调解/其他） */
  refereeType?: string | null;
  /** 受理机构（法院名称） */
  court?: string | null;
  /**
   * 裁判文书日期
   * @format date-time
   */
  judgmentDate?: string;
  /** 裁判文书号 */
  judgmentNo?: string | null;
  /**
   * 送达日期
   * @format date-time
   */
  deliveryDate?: string;
  /** 是否机密案卷 */
  confidentialVolume?: boolean;
  /** 结案时收费额是否与委托协议一致 */
  isConsistentCharge?: boolean;
  /**
   * 起诉日期
   * @format date-time
   */
  sueDate?: string;
  /**
   * 阅卷日期
   * @format date-time
   */
  examDate?: string;
  /**
   * 初次会见/谈话日期
   * @format date-time
   */
  meetDate?: string;
  /**
   * 首次开庭日期
   * @format date-time
   */
  courtDate?: string;
  /** 简要案情和工作概况 */
  result?: string | null;
  /** 处理结果 */
  pocessResult?: string | null;
  /** 律师办案体会 */
  experience?: string | null;
  /** 代理事项 */
  discussion?: string | null;
  /** 结案必要文件夹 */
  folders?: string | null;
  /** 归档人编号 */
  archiveUserId?: string | null;
  /**
   * 归档日期
   * @format date-time
   */
  archiveDate?: string;
  /** 归档编号 */
  archiveId?: string | null;
  /** 归档状态 */
  archiveStatus?: string | null;
  /** 服务结果（中止/终止/存续） */
  serviceResult?: string | null;
  /** 电子档案编号 */
  electronicFileNo?: string | null;
  /** 是否扫描件 */
  isScanFile?: boolean;
  /** 是否逾期结案 */
  isOverdueFiling?: boolean;
  /** 逾期结案原因 */
  overdueFilingReason?: string | null;
  /**
   * 逾期天数
   * @format double
   */
  overdueDays?: number;
  /** 归档地 */
  archivePlace?: string | null;
  /** 部门ID  DepartmentId */
  organizationUnitId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 案件收款信息表 */
export interface CreateCaseCollectionInput {
  /** 案件编号 */
  caseId?: string | null;
  /** 收费方式 */
  chargingMethod?: string | null;
  /**
   * 标的金额
   * @format double
   */
  targetAmount?: number;
  /**
   * 收费金额
   * @format double
   */
  amount?: number;
  /**
   * 基础风险金额
   * @format double
   */
  riskBasicAmount?: number;
  /** 说明描述/风险达成条件 */
  narrative?: string | null;
  /** 是否可收款 */
  isAcceptable?: boolean;
  /** 是否使用账单 */
  isUseBilling?: boolean;
  /**
   * 收费/付款日期
   * @format date-time
   */
  chargeDate?: string;
  /** 是否已到账 */
  isArrived?: string | null;
  /**
   * 到账日期
   * @format date-time
   */
  arrivedDate?: string;
  /**
   * 到账金额
   * @format double
   */
  arrivedAmount?: number;
  /** 币种 */
  currency?: string | null;
  /**
   * 汇率
   * @format double
   */
  exchangeRate?: number;
  /** 是否账单 */
  isBilling?: string | null;
  /** 关联账单编号 */
  billingId?: string | null;
  /**
   * 付款/标的比例
   * @format double
   */
  chargeRatio?: number;
  /**
   * 开始时间
   * @format date-time
   */
  startDate?: string;
  /**
   * 结束时间
   * @format date-time
   */
  endDate?: string;
  /** 是否提醒 */
  isRemind?: string | null;
  /**
   * 提醒时间
   * @format date-time
   */
  remindTime?: string;
  /**
   * 风险收费比例
   * @format double
   */
  riskRatio?: number;
  /** 关联案件编号 */
  relationCaseId?: string | null;
  /**
   * 外币收费金额
   * @format double
   */
  foreignCurrencyAmount?: number;
  /**
   * 排序
   * @format int32
   */
  sort?: number;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 案件分配表 */
export interface CreateCaseDistributionInput {
  id?: string | null;
  /** 案件编号 */
  caseId?: string | null;
  /** 用户编号 */
  userId?: string | null;
  /** 律师角色 */
  lawyerRole?: string | null;
  /**
   * 分配金额
   * @format double
   */
  allocationAmount?: number;
  /**
   * 分配比例
   * @format double
   */
  allocationRate?: number;
  /**
   * 尾差金额
   * @format double
   */
  tailAmount?: number;
  /** 是否案源费 */
  isSourceFee?: boolean;
  /** 是否默认策略 */
  isDefault?: boolean;
  /**
   * 排序
   * @format int32
   */
  sort?: number;
  /** 人员名称 */
  displayName?: string | null;
  /** 办公室 */
  officeId?: string | null;
  /** 办公室名称 */
  officeName?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 案件参与律师表 */
export interface CreateCaseLawyerInput {
  /** Id */
  id?: string | null;
  /** 案件编号 */
  caseId?: string | null;
  /** 用户编号 */
  userId?: string | null;
  /** 律师参案角色 */
  lawyerRole?: string | null;
  /**
   * 律师费率
   * @format double
   */
  chargeRatio?: number;
  /**
   * 律师费率 外币
   * @format double
   */
  usdChargeRatio?: number;
  /**
   * 分配比例
   * @format double
   */
  assignRatio?: number;
  /** 是否启用（参与分配） */
  isEnableAssign?: boolean;
  /**
   * 服务开始时间
   * @format date-time
   */
  startDate?: string | null;
  /**
   * 服务结束时间
   * @format date-time
   */
  endDate?: string | null;
  /** 是否非当前服务律师 */
  isInActive?: boolean;
  /**
   * 排序
   * @format int32
   */
  sort?: number;
  /** 备注 */
  remark?: string | null;
  /** 邮件地址 */
  emailAddress?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 案件管理 */
export interface CreateCaseManageInput {
  /** 唯一Id */
  id?: string | null;
  /** 利冲预检编号 */
  preCsrId?: string | null;
  /** 利冲申请编号 */
  csrId?: string | null;
  /** 案件类型 */
  category?: string | null;
  /** 案由分类 */
  reason?: string | null;
  /** 补充案由 */
  reasonSupplement?: string | null;
  /** 关联案件编号 */
  relationCaseId?: string | null;
  /** 关联案件名称 */
  relationCaseName?: string | null;
  /** 案件名称 */
  name?: string | null;
  /** 案件名称注释备注 */
  nameNote?: string | null;
  /** 客户行业 */
  clientIndustryId?: string | null;
  /** 执业领域 */
  practiceArea?: string | null;
  /** 案件性质 */
  caseNature?: string | null;
  /** 是否涉密 */
  isClassified?: string | null;
  /** 是否紧急 */
  isUrgent?: string | null;
  /** 收费方式（固定，小时，风险，免费代理） */
  chargingMethodsList?: string[] | null;
  /**
   * 合同金额（费用总额）
   * @format double
   */
  contractAmount?: number;
  /** 委托事项 */
  entrustedMatters?: string | null;
  /** 书写语言 */
  writtenLanguage?: string | null;
  /**
   * 收案日期
   * @format date-time
   */
  acceptDate?: string | null;
  /** 案件来源 */
  caseSource?: string | null;
  /** 委托阶段 */
  stagesList?: string[] | null;
  /** 当前阶段 */
  currentStage?: string | null;
  /** 受理机构 */
  acceptAgency?: string | null;
  /** 受理机构案号 */
  acceptCaseNo?: string | null;
  /** 受理机构级别（法院级别） */
  acceptAgencyLevel?: string | null;
  /** 特殊案件类型 */
  specialTypeList?: string[] | null;
  /** 是否跨所合作案件 */
  isInterbankCooperation?: string | null;
  /** 合作分所 */
  cooperationOffice?: string | null;
  /** 合作律师 */
  cooperativeLawyerIds?: string | null;
  /** 案情简介 */
  narrative?: string | null;
  /** 代理权限 */
  agencyAuthorityList?: string[] | null;
  /** 诉讼地位（代理何方） */
  litigationStatus?: string | null;
  /** 是否标的 */
  isTarget?: string | null;
  /**
   * 标的额
   * @format double
   */
  targetAmount?: number;
  /** 货币单位 */
  currency?: string | null;
  /**
   * 顾问合同起始时间
   * @format date-time
   */
  startDate?: string | null;
  /**
   * 顾问合同结束时间（空则长期）
   * @format date-time
   */
  endDate?: string | null;
  /**
   * 律师费用
   * @format double
   */
  lawyerFee?: number;
  /**
   * 办案费
   * @format double
   */
  caseHandlingFee?: number;
  /**
   * 代收代付费
   * @format double
   */
  pocoFee?: number;
  /** 案件项下办案费支出方 */
  feePaidParty?: string | null;
  /**
   * 办案费限额
   * @format double
   */
  caseHandlingFeeLimit?: number;
  /** 约定汇率模式 */
  agreedExchangeRateMode?: string | null;
  /**
   * 汇率
   * @format double
   */
  exchangeRate?: number;
  /**
   * 免费代理小时数
   * @format double
   */
  freeHourLimit?: number;
  /** 主管律师（主办律师） */
  inChargeLawyer?: string | null;
  /** 协办律师（合作律师） */
  coWorkingLawyer?: string | null;
  /** 案源律师 */
  caseSourceLawyer?: string | null;
  /** 账单律师 */
  billingLawyer?: string | null;
  /** 秘书助理（预留）;选择人则限定选择人和值班秘书可见，不选就全部助理和秘书可见 */
  assistantSecretary?: string | null;
  /** 申请合伙人 */
  applicant?: string | null;
  /** 案件人员信息 */
  caseLawyers?: CaseLawyerDto[] | null;
  /** 案件人员费用分配信息 */
  caseLawyerAssignments?: CaseLawyerAssignmentDto[] | null;
  /** 案件收款信息（案件费用信息） */
  caseCollections?: CreateCaseCollectionInput[] | null;
  /** 案件分配信息 */
  caseDistributions?: CreateCaseDistributionInput[] | null;
  /** 案件委托方、对方当事人信息 */
  caseRelatedPartys?: CreateCaseRelatedPartyInput[] | null;
  /** 办公室 */
  officeId?: string | null;
  /** 利冲编号 */
  csrSerialId?: string | null;
  /**
   * 预估回收率
   * @format double
   */
  estimatedRecoveryRate?: number;
  /** 免费代理原因 */
  freeAttorneyReason?: string | null;
  /** 是否计费 */
  isBillingFee?: boolean;
  /** 是否折扣 */
  isDiscount?: boolean;
  /**
   * 折扣百分比
   * @format double
   */
  discountAmount?: number;
  /** 计时收费费率(字典) */
  hourFeeRateType?: string | null;
  /** 风险收费类别（字典） */
  riskFeeType?: string | null;
  agencyAgreements?: CreateSysDocumentCenterListInput;
  otherDocuments?: CreateSysDocumentCenterListInput;
  exemptionDocuments?: CreateSysDocumentCenterListInput;
  communicationDocuments?: CreateSysDocumentCenterListInput;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 案件信息表 */
export interface CreateCaseMatterInput {
  /** 唯一Id */
  id?: string | null;
  /** 利冲预检编号 */
  preCsrId?: string | null;
  /** 利冲申请编号 */
  csrId?: string | null;
  /** 案件类型 */
  category?: string | null;
  /** 案由分类 */
  reason?: string | null;
  /** 补充案由 */
  reasonSupplement?: string | null;
  /** 关联案件编号 */
  relationCaseId?: string | null;
  /** 关联案件名称 */
  relationCaseName?: string | null;
  /** 案件名称 */
  name?: string | null;
  /** 案件名称注释备注 */
  nameNote?: string | null;
  /** 客户行业 */
  clientIndustryId?: string | null;
  /** 执业领域 */
  practiceArea?: string | null;
  /** 案件性质 */
  caseNature?: string | null;
  /** 是否涉密 */
  isClassified?: string | null;
  /** 是否紧急 */
  isUrgent?: string | null;
  /** 收费方式（固定，小时，风险，免费代理） */
  chargingMethodsList?: string[] | null;
  /**
   * 合同金额（费用总额）
   * @format double
   */
  contractAmount?: number;
  /** 委托事项 */
  entrustedMatters?: string | null;
  /** 书写语言 */
  writtenLanguage?: string | null;
  /**
   * 收案日期
   * @format date-time
   */
  acceptDate?: string | null;
  /** 案件来源 */
  caseSource?: string | null;
  /** 委托阶段 */
  stagesList?: string[] | null;
  /** 当前阶段 */
  currentStage?: string | null;
  /** 受理机构 */
  acceptAgency?: string | null;
  /** 受理机构案号 */
  acceptCaseNo?: string | null;
  /** 受理机构级别（法院级别） */
  acceptAgencyLevel?: string | null;
  /** 特殊案件类型 */
  specialTypeList?: string[] | null;
  /** 是否跨所合作案件 */
  isInterbankCooperation?: string | null;
  /** 合作分所 */
  cooperationOffice?: string | null;
  /** 合作律师 */
  cooperativeLawyerIds?: string | null;
  /** 案情简介 */
  narrative?: string | null;
  /** 代理权限 */
  agencyAuthorityList?: string[] | null;
  /** 诉讼地位（代理何方） */
  litigationStatus?: string | null;
  /** 是否标的 */
  isTarget?: string | null;
  /**
   * 标的额
   * @format double
   */
  targetAmount?: number;
  /** 货币单位 */
  currency?: string | null;
  /**
   * 顾问合同起始时间
   * @format date-time
   */
  startDate?: string | null;
  /**
   * 顾问合同结束时间（空则长期）
   * @format date-time
   */
  endDate?: string | null;
  /**
   * 律师费用
   * @format double
   */
  lawyerFee?: number;
  /**
   * 办案费
   * @format double
   */
  caseHandlingFee?: number;
  /**
   * 代收代付费
   * @format double
   */
  pocoFee?: number;
  /** 案件项下办案费支出方 */
  feePaidParty?: string | null;
  /**
   * 办案费限额
   * @format double
   */
  caseHandlingFeeLimit?: number;
  /** 约定汇率模式 */
  agreedExchangeRateMode?: string | null;
  /**
   * 汇率
   * @format double
   */
  exchangeRate?: number;
  /**
   * 免费代理小时数
   * @format double
   */
  freeHourLimit?: number;
  /** 主管律师（主办律师） */
  inChargeLawyer?: string | null;
  /** 协办律师（合作律师） */
  coWorkingLawyer?: string | null;
  /** 案源律师 */
  caseSourceLawyer?: string | null;
  /** 账单律师 */
  billingLawyer?: string | null;
  /** 秘书助理（预留）;选择人则限定选择人和值班秘书可见，不选就全部助理和秘书可见 */
  assistantSecretary?: string | null;
  /** 申请合伙人 */
  applicant?: string | null;
  /** 案件人员信息 */
  caseLawyers?: CaseLawyerDto[] | null;
  /** 案件人员费用分配信息 */
  caseLawyerAssignments?: CaseLawyerAssignmentDto[] | null;
  /** 案件收款信息 */
  caseCollections?: CreateCaseCollectionInput[] | null;
  /** 案件分配信息 */
  caseDistributions?: CreateCaseDistributionInput[] | null;
  /** 案件委托方、对方当事人信息 */
  caseRelatedPartys?: CaseRelatedPartyDto[] | null;
  /** 办公室 */
  officeId?: string | null;
  /** 利冲编号 */
  csrSerialId?: string | null;
  /**
   * 预估回收率
   * @format double
   */
  estimatedRecoveryRate?: number;
  /** 免费代理原因 */
  freeAttorneyReason?: string | null;
  /** 是否计费 */
  isBillingFee?: boolean;
  /** 是否折扣 */
  isDiscount?: boolean;
  /**
   * 折扣金额
   * @format double
   */
  discountAmount?: number;
  /** 计时收费费率(字典) */
  hourFeeRateType?: string | null;
  /** 风险收费类别（字典） */
  riskFeeType?: string | null;
  /** 是否克隆 */
  isClone?: boolean;
  /** 原案件编号 */
  fromCaseId?: string | null;
  /** 原利冲编号 */
  fromCsrId?: string | null;
  status?: string | null;
  flowState?: string | null;
  /** 客户Id */
  clientId?: string | null;
  /** 客户名称 */
  clientName?: string | null;
  /** 客户外文名称 */
  clientForeignName?: string | null;
  /** 是否所务案件 */
  isInternal?: boolean;
  /** 案件编号 */
  serialId?: string | null;
  agencyAgreements?: CreateSysDocumentCenterListInput;
  otherDocuments?: CreateSysDocumentCenterListInput;
  exemptionDocuments?: CreateSysDocumentCenterListInput;
  communicationDocuments?: CreateSysDocumentCenterListInput;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 通用返回信息类 */
export interface CreateCaseMatterInputMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 案件信息表 */
  response?: CreateCaseMatterInput;
}

/** 案件关联方信息表 */
export interface CreateCaseRelatedPartyInput {
  id?: string | null;
  /** 案件或利冲编号 */
  caseId?: string | null;
  /** 客户编号(客户方才有，其余案件相关方没有) */
  clientId?: string | null;
  /** 关联方类型以及父类类型  CP:客户,CPOC:客户股东或实控人,EP:参与实体,OP:对方当事人,OPOC:对方股东或实控人,OPE:对方关联方,TP:第三方,TPOC:第三方股东或实控人 */
  category?: string | null;
  /** 文书关联编号 */
  caseFileRelationId?: string | null;
  /** 关联方名称 */
  name?: string | null;
  /** 外文名称 */
  foreignName?: string | null;
  /** 曾用名 */
  formerName?: string | null;
  /** 联系人 */
  linker?: string | null;
  /** 证件类型 */
  creditType?: string | null;
  /** 证件号码 */
  creditCode?: string | null;
  /** 邮件地址 */
  emailAddress?: string | null;
  /** 联系电话 */
  phoneNumber?: string | null;
  /** 联系地址 */
  address?: string | null;
  /** 关联方角色（子集类型） */
  partyRole?: string | null;
  /** 利冲关键词 */
  conflictKeyword?: string | null;
  /** 是否双方当事人 */
  isParties?: boolean;
  /** 说明描述 */
  narrative?: string | null;
  /** 租户ID */
  tenantId?: string | null;
  /** 部门ID  DepartmentId */
  organizationUnitId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 备注 */
  remark?: string | null;
  /** 父级ID */
  parentId?: string | null;
  /** 客户性质 */
  customerNature?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 客户所属人表 */
export interface CreateClientBelongTimekeeperInput {
  id?: string | null;
  /** 客户编号 */
  clientId?: string | null;
  /** 计时人员编号 */
  timekeeperId?: string | null;
  /** 是否其助理或秘书可见 */
  isAssistantVisible?: string | null;
  /** 是否团队律师可见 */
  isTeamLawyerVisible?: string | null;
  /**
   * 所属时效开始时间
   * @format date-time
   */
  startDate?: string;
  /**
   * 所属时效结束时间（空则长期有效）
   * @format date-time
   */
  endDate?: string;
  /** 是否是初始所属人 */
  isFirstOwner?: string | null;
  /** 是否过期失效 */
  isExpred?: string | null;
  /** 租户ID */
  tenantId?: string | null;
  /** 部门ID  DepartmentId */
  organizationUnitId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 备注 */
  remark?: string | null;
  /** 状态 	                中立字段，某些表可使用某些表不使用 */
  isEnabled?: boolean;
}

/** 客户信息表 */
export interface CreateClientInput {
  /** Id */
  id?: string | null;
  /** 客户名称 */
  name?: string | null;
  /** 外文名称 */
  foreignName?: string | null;
  /** 客户类型 */
  clientType?: string | null;
  /** 客户性质 */
  customerNature?: string | null;
  /** 曾用名 */
  formerName?: string | null;
  /** 别名 */
  altName?: string | null;
  /** 证件类型 */
  creditType?: string | null;
  /** 证件号码 */
  creditCode?: string | null;
  /** 客户地址 */
  address?: string | null;
  /** 描述说明 */
  narrative?: string | null;
  /** 备注 */
  remark?: string | null;
  /** 所属行业编号 */
  clientIndustryId?: string | null;
  /** 所属集团ID */
  belongGroupId?: string | null;
  /** 所属集团名称 */
  belongGroupName?: string | null;
  /** 来源类型（立案/入库/投标） */
  originType?: string | null;
  /** 来源编号 */
  originId?: string | null;
  /** 税号 */
  taxNumber?: string | null;
  /** 邮箱地址 */
  emailAddress?: string | null;
  /** 手机号码 */
  phoneNumber?: string | null;
  /** 固定电话 */
  landline?: string | null;
  /** 客户级别（星标客户/重要客户等） */
  clientLevel?: string | null;
  /** 母公司名称 */
  parentCompanyName?: string | null;
  /** 母公司外文名称 */
  parentCompanyForeignName?: string | null;
  /** 母公司证件类型 */
  parentCompanyCreditType?: string | null;
  /** 母公司证件号码 */
  parentCompanyCreditCode?: string | null;
  /** 流水编号 */
  serialId?: string | null;
  /** 原系统主键编号 */
  originalId?: string | null;
  /** 流程数据大状态 */
  status?: string | null;
  /**
   * 提交时间
   * @format date-time
   */
  submittedOn?: string | null;
  /** 工作流状态 */
  flowState?: string | null;
  /** 申请人 */
  applicant?: string | null;
  /**
   * 工作流结束时间
   * @format date-time
   */
  flowEndDateTime?: string | null;
  /** 是否超期 */
  isOverdue?: boolean | null;
  /** 股东/实控人都传入到实体表 */
  entityInfos?: CreateEntityInfoInput[] | null;
  /** 发票抬头 */
  entityInvoiceTitleInputs?: CreateEntityInvoiceTitleInput[] | null;
  /** 是否所务客户 */
  isInternal?: boolean;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 克隆案件 */
export interface CreateCloneCaseInput {
  isRelationMatter?: boolean;
  /** 案件或利冲号 */
  id?: string | null;
}

/** 利冲规避偏好设置 */
export interface CreateCsrAvoidPreferenceInput {
  /** Id */
  id?: string | null;
  /** 规避偏好关键字 */
  avoidKeyword?: string | null;
  /** 规避利冲方类型 */
  patryType?: string | null;
  /** 关键字规避方法（模糊/精确） */
  avoidMethod?: string | null;
  /**
   * 规避开始时间
   * @format date-time
   */
  startDate?: string;
  /**
   * 规避结束时间
   * @format date-time
   */
  endTime?: string;
  /** 附件信息 */
  attachments?: string | null;
  /** 说明描述 */
  narrative?: string | null;
  /** 租户ID */
  tenantId?: string | null;
  /** 部门编号 */
  organizationUnitId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 备注 */
  remark?: string | null;
  /** 创建ID */
  createId?: string | null;
  /** 创建者 */
  createBy?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 修改ID */
  modifyId?: string | null;
  /** 更新者 */
  modifyBy?: string | null;
  /**
   * 修改日期
   * @format date-time
   */
  modifyTime?: string | null;
  /** 数据版本 */
  version?: string | null;
  /** 删除ID */
  deleteId?: string | null;
  /** 删除者 */
  deleteBy?: string | null;
  /**
   * 删除日期
   * @format date-time
   */
  deleteTime?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 利冲规避偏好结果表 */
export interface CreateCsrAvoidPreferenceResultInput {
  /** Id */
  id?: string | null;
  /** 利冲规避编号 */
  csrAvoidId?: string | null;
  /** 利冲规避关键词 */
  avoidKeyword?: string | null;
  /** 关键词隶属规避方角色 */
  partyType?: string | null;
  /** 规避冲突案件的角色 */
  caseParty?: string | null;
  /** 规避案件冲突方名称 */
  casePartyName?: string | null;
  /** 规避利冲关联业务类型（利冲/案件/入库/投标等） */
  csrRelationType?: string | null;
  /** 规避业务编号（默认案件） */
  relationId?: string | null;
  /** 冲突产生时关联业务状态 */
  relationStatus?: string | null;
  /** 匹配客户名称 */
  clientName?: string | null;
  /** 匹配客户外文名称 */
  clientForeignName?: string | null;
  /** 匹配客户实控人名称 */
  clientShareholderActualCtrlName?: string | null;
  /** 匹配客户实控人外文名称 */
  clientShareholderActualCtrlForeignName?: string | null;
  /** 匹配对方名称 */
  oppositeName?: string | null;
  /** 匹配对方外文名称 */
  oppositeForeignName?: string | null;
  /** 匹配对方实控人名称 */
  oppositeShareholderActualCtrlName?: string | null;
  /** 匹配对方实控人外文名称 */
  oppositeShareholderActualCtrlForeignName?: string | null;
  /** 潜在第三方名称 */
  potential3rdPartyName?: string | null;
  /** 潜在第三方外文名称 */
  potential3rdPartyForeignName?: string | null;
  /** 关联业务类型 */
  relationCategory?: string | null;
  /** 关联业务名称 */
  relationName?: string | null;
  /**
   * 关联业务申请时间
   * @format date-time
   */
  relationRequestTime?: string;
  /** 关联业务主管律师 */
  relationInChargeLawyerId?: string | null;
  /** 关联业务申请合伙人 */
  relationRequestUserId?: string | null;
  /** 关联业务协办律师 */
  relationCoWorkingLawyerId?: string | null;
  /** 关联业务账单律师 */
  relationBillingLawyerId?: string | null;
  /** 规避匹配方式（包含/忽略） */
  matchingMethod?: string | null;
  /** 租户ID */
  tenantId?: string | null;
  /** 部门ID  DepartmentId */
  organizationUnitId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 备注 */
  remark?: string | null;
  /** 状态 */
  isEnabled?: boolean;
  /** 创建ID */
  createId?: string | null;
  /** 创建者 */
  createBy?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
}

/** 利冲豁免确认表 */
export interface CreateCsrExemptionInput {
  id?: string | null;
  /** 利冲编号 */
  csrId?: string | null;
  /** 利冲关键词 */
  csrKeyword?: string | null;
  /** 与我冲突案件的角色（冲突方） */
  csrParty?: string | null;
  /** 我的案件冲突角色 */
  caseParty?: string | null;
  /** 我的案件冲突方名称 */
  casePartyName?: string | null;
  /** 主管律师 */
  inChargeLawyer?: string | null;
  /** 利冲关联业务类型（利冲/案件/入库/投标等） */
  csrRelationType?: string | null;
  /** 关联业务编号（默认案件） */
  relationId?: string | null;
  /** 冲突产生时关联业务状态 */
  relationStatus?: string | null;
  /** 与我冲突业务编号合计 */
  csrRelationIds?: string | null;
  /** 利冲确认发送人编号 */
  csrSendUserId?: string | null;
  /**
   * 利冲确认发送时间
   * @format date-time
   */
  csrSendTime?: string;
  /**
   * 利冲豁免确认时间
   * @format date-time
   */
  confirmTime?: string;
  /** 豁免文件 */
  attachments?: string | null;
  /** 说明描述 */
  narrative?: string | null;
  /** 租户ID */
  tenantId?: string | null;
  /** 部门ID  DepartmentId */
  organizationUnitId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 备注 */
  remark?: string | null;
  /** 状态 中立字段，某些表可使用某些表不使用 */
  isEnabled?: boolean;
}

/** 利冲审查结果表 */
export interface CreateCsrProcessResultInput {
  id?: string | null;
  /** 利冲申请编号 */
  csrId?: string | null;
  /** 利冲审查编号 */
  csrProcessId?: string | null;
  /** 是否需要豁免 */
  isNeedExemption?: string | null;
  /** 利冲关键词 */
  csrKeyword?: string | null;
  /** 与我冲突案件的角色 */
  csrParty?: string | null;
  /** 我被冲突案件的角色 */
  caseParty?: string | null;
  /** 我的案件冲突方名称 */
  casePartyName?: string | null;
  /** 利冲关联业务类型（利冲/案件/入库/投标等） */
  csrRelationType?: string | null;
  /** 关联业务编号（默认案件） */
  relationId?: string | null;
  /** 冲突产生时关联业务状态 */
  relationStatus?: string | null;
  /** 利冲豁免结果 */
  exemptionResult?: string | null;
  /** 匹配利冲规则 */
  matchingRule?: string | null;
  /** 匹配客户名称 */
  clientName?: string | null;
  /** 匹配客户外文名称 */
  clientForeignName?: string | null;
  /** 匹配客户实控人名称 */
  clientShareholderActualCtrlName?: string | null;
  /** 匹配客户实控人外文名称 */
  clientShareholderActualCtrlForeignName?: string | null;
  /** 匹配对方名称 */
  oppositeName?: string | null;
  /** 匹配对方外文名称 */
  oppositeForeignName?: string | null;
  /** 匹配对方实控人名称 */
  oppositeShareholderActualCtrlName?: string | null;
  /** 匹配对方实控人外文名称 */
  oppositeShareholderActualCtrlForeignName?: string | null;
  /** 潜在第三方名称 */
  potential3rdPartyName?: string | null;
  /** 潜在第三方外文名称 */
  potential3rdPartyForeignName?: string | null;
  /** 关联业务类型 */
  relationCategory?: string | null;
  /** 关联业务名称 */
  relationName?: string | null;
  /**
   * 关联业务申请时间
   * @format date-time
   */
  relationRequestTime?: string;
  /** 关联业务主管律师 */
  relationInChargeLawyerId?: string | null;
  /** 关联业务申请合伙人 */
  relationRequestUserId?: string | null;
  /** 租户ID */
  tenantId?: string | null;
  /** 部门ID  DepartmentId */
  organizationUnitId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 备注 */
  remark?: string | null;
  /** 状态 	                中立字段，某些表可使用某些表不使用 */
  isEnabled?: boolean;
}

export interface CreateCsrProtectEntityInfoInput {
  /** Id */
  id?: string | null;
  /** 客户名称 */
  name?: string | null;
  /** 外文名称 */
  foreignName?: string | null;
  /** 客户类型 */
  clientType?: string | null;
  /** 客户性质 */
  customerNature?: string | null;
  /** 曾用名 */
  formerName?: string | null;
  /** 别名 */
  altName?: string | null;
  /** 证件类型 */
  creditType?: string | null;
  /** 证件号码 */
  creditCode?: string | null;
  /** 客户地址 */
  address?: string | null;
  /** 描述说明 */
  narrative?: string | null;
  /** 备注 */
  remark?: string | null;
  /** 所属行业编号 */
  clientIndustryId?: string | null;
  /** 税号 */
  taxNumber?: string | null;
  /** 邮箱地址 */
  emailAddress?: string | null;
  /** 手机号码 */
  phoneNumber?: string | null;
  /** 固定电话 */
  landline?: string | null;
  /** 母公司名称 */
  parentCompanyName?: string | null;
  /** 母公司外文名称 */
  parentCompanyForeignName?: string | null;
  /** 母公司证件类型 */
  parentCompanyCreditType?: string | null;
  /** 母公司证件号码 */
  parentCompanyCreditCode?: string | null;
  /** 所属客户 */
  clientId?: string | null;
  /** 客户名称 */
  clientName?: string | null;
  /** 客户序列号 */
  clientSerialId?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 通用返回信息类 */
export interface CreateCsrProtectEntityInfoInputMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  response?: CreateCsrProtectEntityInfoInput;
}

/** 利益冲突信息表 */
export interface CreateCsrRequestInput {
  /** ID（编辑时需传ID） */
  id?: string | null;
  /** 流水编号 */
  serialId?: string | null;
  /** 利冲案件名称 */
  name?: string | null;
  /** 利冲案件类型 */
  category?: string | null;
  /** 关联案件编号 */
  relationCaseId?: string | null;
  /** 关联案件名称 */
  relationCaseName?: string | null;
  /** 客户行业 */
  clientIndustryId?: string | null;
  /** 执业领域 */
  practiceArea?: string | null;
  /** 是否涉密 */
  isClassified?: string | null;
  /** 是否紧急 */
  isUrgent?: string | null;
  /** 收费方式（固定，小时，风险，免费代理） */
  chargingMethods?: string[] | null;
  /** 收费方式文本 */
  chargingMethodText?: string | null;
  /**
   * 合同金额（费用总额）
   * @format double
   */
  contractAmount?: number;
  /** 委托事项 */
  entrustedMatters?: string | null;
  /** 说明描述 */
  narrative?: string | null;
  /** 秘书助理（预留）;选择人则限定选择人和值班秘书可见，不选就全部助理和秘书可见 */
  assistantSecretary?: string | null;
  /** 利冲关联案件编号 */
  caseId?: string | null;
  /** 利冲关联案件名称 */
  caseName?: string | null;
  /** 利冲关联案件类型 */
  caseCategory?: string | null;
  /** 利冲关联案件状态 */
  caseStatus?: string | null;
  /** 豁免文件 */
  exemptionAttachments?: string | null;
  /** 附件 */
  attachments?: string | null;
  /** 是否标的 */
  isTarget?: string | null;
  /**
   * 标的金额
   * @format double
   */
  targetAmount?: number;
  /** 利冲适用规则 */
  csrRule?: string | null;
  /** 利冲时效是否过期 */
  isExpired?: string | null;
  /**
   * 利冲审查进度
   * @format double
   */
  rateOfProgress?: number;
  /**
   * 利冲时效剩余天数
   * @format double
   */
  remainingDays?: number;
  /** 办公室 */
  officeId?: string | null;
  /** 备注 */
  remark?: string | null;
  /** 案件关联方信息 */
  caseRelatedPartys?: CreateCaseRelatedPartyInput[] | null;
  /** 案件参与律师 */
  caseLawyers?: CreateCaseLawyerInput[] | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

export interface CreateDingtalkDepartmentInput {
  id?: string | null;
  /** 应用编号 */
  appId?: string | null;
  /** 部门ID */
  deptId?: string | null;
  /** 部门名称 */
  name?: string | null;
  /** 父部门Id */
  parentId?: string | null;
  /** 是否同步创建一个关联此部门的企业群 */
  createDeptGroup?: boolean | null;
  /** 部门群已经创建后，有新人加入部门是否会自动加入该群 */
  autoAddUser?: boolean | null;
  /** 部门标识字段 */
  sourceIdentifier?: string | null;
  /** 是否默认同意加入该部门的申请 */
  autoApproveApply?: boolean | null;
  /** 部门是否来自关联组织 */
  fromUnionOrg?: boolean | null;
  /** 教育部门标签 */
  tags?: string | null;
  /** 在父部门中的次序值 */
  order?: string | null;
  /** 部门群ID */
  deptGroupChatId?: string | null;
  /** 部门群是否包含子部门 */
  groupContainSubDept?: boolean | null;
  /** 租户ID */
  tenantId?: string | null;
  /** 部门ID  DepartmentId */
  organizationUnitId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 备注 */
  remark?: string | null;
  /** 状态 	            中立字段，某些表可使用某些表不使用 */
  isEnabled?: boolean;
}

export interface CreateDingtalkUserInput {
  id?: string | null;
  /** 应用编号 */
  appId?: string | null;
  /** BitzOrcas UserId */
  relationUserId?: string | null;
  /** 用户名 */
  name?: string | null;
  /** 用户英文名称 */
  enName?: string | null;
  /** 昵称 */
  nickName?: string | null;
  /** 区域代码  +86 */
  stateCode?: string | null;
  /** 邮箱地址 */
  email?: string | null;
  /** 机构邮箱地址 */
  orgEmail?: string | null;
  /** 手机号 */
  mobile?: string | null;
  /** 是否显示手机号 */
  mobileVisible?: boolean | null;
  /** 分机号 */
  telephone?: string | null;
  /** 钉钉用户的open_id */
  openId?: string | null;
  /** 钉钉用户的union_id */
  unionId?: string | null;
  /** 钉钉用户的user_id */
  userId?: string | null;
  /**
   * 性别
   * @format int32
   */
  gender?: number | null;
  /** 用户头像信息 */
  avatar?: string | null;
  /** 员工工号 */
  jobNumber?: string | null;
  /** 员工的直属主管 */
  managerUserId?: string | null;
  /** 所属部门的Id列表 */
  departmentIds?: string | null;
  /** 员工在对应的部门中的排序 json：dept_id,order */
  deptOrderList?: string | null;
  /** 员工所在部门信息及是否是领导 json:dept_id,leader */
  leaderInDept?: string | null;
  /** 工作地点 */
  workPlace?: string | null;
  /** 入职时间 */
  hiredDate?: string | null;
  /** 职务 */
  title?: string | null;
  /** 是否为企业账号 */
  exclusiveAccount?: boolean | null;
  /** 扩展属性，最大长度2000个字符。 */
  extension?: string | null;
  /** 是否激活了钉钉 */
  active?: boolean | null;
  /** 是否完成了实名认证 */
  realAuthed?: boolean | null;
  /** 是否为企业高管 */
  senior?: boolean | null;
  /** 是否为企业的管理员 */
  admin?: boolean | null;
  /** 是否为企业的老板 */
  boss?: boolean | null;
  /** 部门ID  DepartmentId */
  organizationUnitId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 备注 */
  remark?: string | null;
  /** 状态 	            中立字段，某些表可使用某些表不使用 */
  isEnabled?: boolean;
}

export interface CreateDingtalkUserRelationInput {
  id?: string | null;
  /** 律智荟 UserId */
  relationUserId?: string | null;
  /** 邮箱地址 */
  email?: string | null;
  /** 手机号 */
  mobile?: string | null;
  /** 钉钉用户的open_id */
  openId?: string | null;
  /** 钉钉用户的union_id */
  unionId?: string | null;
  /** 钉钉用户的user_id */
  userId?: string | null;
  /** 钉钉appid */
  appId?: string | null;
  /** 钉钉用户编号获取状态（N：未同步到，A：同步到其中任一Id） */
  syncStatus?: string | null;
  /** 部门ID  DepartmentId */
  organizationUnitId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 备注 */
  remark?: string | null;
  /** 状态 	            中立字段，某些表可使用某些表不使用 */
  isEnabled?: boolean;
}

export interface CreateDisplayDesensitizationInput {
  /** 分组名称 */
  groupName?: string | null;
  /** 模块名称 */
  controllerName?: string | null;
  /** 方法名称 */
  actionName?: string | null;
  /** Dto名称 */
  dtoName?: string | null;
  /**
   * 配置层级
   * @format int32
   */
  settingLevel?: 0 | 1 | 2 | 3;
  /** 整体掩盖字符 */
  mainCharacter?: string | null;
  /** 组织机构Id */
  orgIdList?: string[] | null;
  /** 备注 */
  remark?: string | null;
  /** 是否覆盖下级机构规则 */
  isCover?: boolean;
  /** 显示脱敏规则 */
  displayDesensitizationRules?: DisplayDesensitizationRuleDto[] | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 通用返回信息类 */
export interface CreateDisplayDesensitizationInputMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  response?: CreateDisplayDesensitizationInput;
}

export interface CreateDraftCaseMatterInput {
  /** 唯一Id */
  id?: string | null;
  /** 利冲预检编号 */
  preCsrId?: string | null;
  /** 利冲申请编号 */
  csrId?: string | null;
  /** 案件类型 */
  category?: string | null;
  /** 案由分类 */
  reason?: string | null;
  /** 补充案由 */
  reasonSupplement?: string | null;
  /** 关联案件编号 */
  relationCaseId?: string | null;
  /** 关联案件名称 */
  relationCaseName?: string | null;
  /** 案件名称 */
  name?: string | null;
  /** 案件名称注释备注 */
  nameNote?: string | null;
  /** 客户行业 */
  clientIndustryId?: string | null;
  /** 执业领域 */
  practiceArea?: string | null;
  /** 案件性质 */
  caseNature?: string | null;
  /** 是否涉密 */
  isClassified?: string | null;
  /** 是否紧急 */
  isUrgent?: string | null;
  /** 收费方式（固定，小时，风险，免费代理） */
  chargingMethodsList?: string[] | null;
  /**
   * 合同金额（费用总额）
   * @format double
   */
  contractAmount?: number;
  /** 委托事项 */
  entrustedMatters?: string | null;
  /** 书写语言 */
  writtenLanguage?: string | null;
  /**
   * 收案日期
   * @format date-time
   */
  acceptDate?: string | null;
  /** 案件来源 */
  caseSource?: string | null;
  /** 委托阶段 */
  stagesList?: string[] | null;
  /** 当前阶段 */
  currentStage?: string | null;
  /** 受理机构 */
  acceptAgency?: string | null;
  /** 受理机构案号 */
  acceptCaseNo?: string | null;
  /** 受理机构级别（法院级别） */
  acceptAgencyLevel?: string | null;
  /** 特殊案件类型 */
  specialTypeList?: string[] | null;
  /** 是否跨所合作案件 */
  isInterbankCooperation?: string | null;
  /** 合作分所 */
  cooperationOffice?: string | null;
  /** 合作律师 */
  cooperativeLawyerIds?: string | null;
  /** 案情简介 */
  narrative?: string | null;
  /** 代理权限 */
  agencyAuthorityList?: string[] | null;
  /** 诉讼地位（代理何方） */
  litigationStatus?: string | null;
  /** 是否标的 */
  isTarget?: string | null;
  /**
   * 标的额
   * @format double
   */
  targetAmount?: number;
  /** 货币单位 */
  currency?: string | null;
  /**
   * 顾问合同起始时间
   * @format date-time
   */
  startDate?: string | null;
  /**
   * 顾问合同结束时间（空则长期）
   * @format date-time
   */
  endDate?: string | null;
  /**
   * 律师费用
   * @format double
   */
  lawyerFee?: number;
  /**
   * 办案费
   * @format double
   */
  caseHandlingFee?: number;
  /**
   * 代收代付费
   * @format double
   */
  pocoFee?: number;
  /** 案件项下办案费支出方 */
  feePaidParty?: string | null;
  /**
   * 办案费限额
   * @format double
   */
  caseHandlingFeeLimit?: number;
  /** 约定汇率模式 */
  agreedExchangeRateMode?: string | null;
  /**
   * 汇率
   * @format double
   */
  exchangeRate?: number;
  /**
   * 免费代理小时数
   * @format double
   */
  freeHourLimit?: number;
  /** 主管律师（主办律师） */
  inChargeLawyer?: string | null;
  /** 协办律师（合作律师） */
  coWorkingLawyer?: string | null;
  /** 案源律师 */
  caseSourceLawyer?: string | null;
  /** 账单律师 */
  billingLawyer?: string | null;
  /** 秘书助理（预留）;选择人则限定选择人和值班秘书可见，不选就全部助理和秘书可见 */
  assistantSecretary?: string | null;
  /** 申请合伙人 */
  applicant?: string | null;
  /** 案件人员信息 */
  caseLawyers?: CaseLawyerDto[] | null;
  /** 案件人员费用分配信息 */
  caseLawyerAssignments?: CaseLawyerAssignmentDto[] | null;
  /** 案件收款信息 */
  caseCollections?: CreateCaseCollectionInput[] | null;
  /** 案件分配信息 */
  caseDistributions?: CreateCaseDistributionInput[] | null;
  /** 案件委托方、对方当事人信息 */
  caseRelatedPartys?: CaseRelatedPartyDto[] | null;
  /** 办公室 */
  officeId?: string | null;
  /** 利冲编号 */
  csrSerialId?: string | null;
  /**
   * 预估回收率
   * @format double
   */
  estimatedRecoveryRate?: number;
  /** 免费代理原因 */
  freeAttorneyReason?: string | null;
  /** 是否计费 */
  isBillingFee?: boolean;
  /** 是否折扣 */
  isDiscount?: boolean;
  /**
   * 折扣金额
   * @format double
   */
  discountAmount?: number;
  /** 计时收费费率(字典) */
  hourFeeRateType?: string | null;
  /** 风险收费类别（字典） */
  riskFeeType?: string | null;
  /** 是否克隆 */
  isClone?: boolean;
  /** 原案件编号 */
  fromCaseId?: string | null;
  /** 原利冲编号 */
  fromCsrId?: string | null;
  /** 客户Id */
  clientId?: string | null;
  /** 客户名称 */
  clientName?: string | null;
  /** 客户外文名称 */
  clientForeignName?: string | null;
  /** 是否所务案件 */
  isInternal?: boolean;
  agencyAgreements?: CreateSysDocumentCenterListInput;
  otherDocuments?: CreateSysDocumentCenterListInput;
  exemptionDocuments?: CreateSysDocumentCenterListInput;
  communicationDocuments?: CreateSysDocumentCenterListInput;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

export interface CreateDraftCsrRequestInput {
  /** ID（编辑时需传ID） */
  id?: string | null;
  /** 流水编号 */
  serialId?: string | null;
  /** 利冲案件名称 */
  name?: string | null;
  /** 利冲案件类型 */
  category?: string | null;
  /** 关联案件编号 */
  relationCaseId?: string | null;
  /** 关联案件名称 */
  relationCaseName?: string | null;
  /** 客户行业 */
  clientIndustryId?: string | null;
  /** 执业领域 */
  practiceArea?: string | null;
  /** 是否涉密 */
  isClassified?: string | null;
  /** 是否紧急 */
  isUrgent?: string | null;
  /** 收费方式（固定，小时，风险，免费代理） */
  chargingMethods?: string[] | null;
  /** 收费方式文本 */
  chargingMethodText?: string | null;
  /**
   * 合同金额（费用总额）
   * @format double
   */
  contractAmount?: number;
  /** 委托事项 */
  entrustedMatters?: string | null;
  /** 说明描述 */
  narrative?: string | null;
  /** 秘书助理（预留）;选择人则限定选择人和值班秘书可见，不选就全部助理和秘书可见 */
  assistantSecretary?: string | null;
  /** 利冲关联案件编号 */
  caseId?: string | null;
  /** 利冲关联案件名称 */
  caseName?: string | null;
  /** 利冲关联案件类型 */
  caseCategory?: string | null;
  /** 利冲关联案件状态 */
  caseStatus?: string | null;
  /** 豁免文件 */
  exemptionAttachments?: string | null;
  /** 附件 */
  attachments?: string | null;
  /** 是否标的 */
  isTarget?: string | null;
  /**
   * 标的金额
   * @format double
   */
  targetAmount?: number;
  /** 利冲适用规则 */
  csrRule?: string | null;
  /** 利冲时效是否过期 */
  isExpired?: string | null;
  /**
   * 利冲审查进度
   * @format double
   */
  rateOfProgress?: number;
  /**
   * 利冲时效剩余天数
   * @format double
   */
  remainingDays?: number;
  /** 办公室 */
  officeId?: string | null;
  /** 备注 */
  remark?: string | null;
  /** 案件关联方信息 */
  caseRelatedPartys?: CreateCaseRelatedPartyInput[] | null;
  /** 案件参与律师 */
  caseLawyers?: CreateCaseLawyerInput[] | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 账单信息表 */
export interface CreateDraftFinancialBillingInput {
  /** 案件编号 */
  caseId?: string | null;
  /** 客户编号 */
  clientId?: string | null;
  /** 账单案件别名 */
  caseMatterName?: string | null;
  /**
   * 账单日期
   * @format date-time
   */
  billingDate?: string | null;
  /**
   * 到账日期
   * @format date-time
   */
  arrivedDate?: string | null;
  /**
   * 账单开始日期
   * @format date-time
   */
  startDate?: string | null;
  /**
   * 账单结束日期
   * @format date-time
   */
  endDate?: string | null;
  /** 付款方编号 */
  paymentId?: string | null;
  /** 付款方名称 */
  paymentName?: string | null;
  /** 付款周期 */
  paymentTerms?: string | null;
  /** 付款货币类型 */
  paymentCurrency?: string | null;
  /**
   * 应收账款
   * @format double
   */
  accountsReceivable?: number;
  /** 账单合伙人 */
  billingPartnerId?: string | null;
  /** 账单申请律师 */
  billingLawyerId?: string | null;
  /** AR负责人 */
  arResponsibleLawyerId?: string | null;
  /** 币种 */
  currency?: string | null;
  /**
   * 汇率
   * @format double
   */
  exchangeRate?: number;
  /** 支付状态 */
  paymentStatus?: string | null;
  /** 账单联系人 */
  contactPerson?: string | null;
  /** 邮箱地址 */
  emailAddress?: string | null;
  /** 账单所属办公室 */
  billingOffice?: string | null;
  /** 账单关联银行账号 */
  billingBankId?: string | null;
  /** 账单类型 */
  category?: string | null;
  /** 是否联合账单 */
  isBatchBilling?: string | null;
  /** 联合账单关联案号 */
  batchCaseIds?: string | null;
  /** 关联内部账单编号 */
  internalBillingIds?: string | null;
  /**
   * 总固定服务费
   * @format double
   */
  totalFixedServiceFee?: number;
  /**
   * 固定服务税费
   * @format double
   */
  fixedServiceTax?: number;
  /**
   * 账单总金额
   * @format double
   */
  totalAmount?: number;
  /**
   * 总工时服务费
   * @format double
   */
  totalTimesheetServiceFee?: number;
  /**
   * 工时税费
   * @format double
   */
  timesheetTax?: number;
  /**
   * 总支出费用金额
   * @format double
   */
  totalDisbursement?: number;
  /**
   * 支出税费
   * @format double
   */
  disbursementTax?: number;
  /**
   * 调整金额
   * @format double
   */
  totalAdjustment?: number;
  /**
   * 工时调整总额
   * @format double
   */
  timesheetAdjustment?: number;
  /**
   * 固定服务调整总额
   * @format double
   */
  fixedServiceAdjustment?: number;
  /**
   * 支出调整总额
   * @format double
   */
  disbursementAdjustment?: number;
  /**
   * 上限金额
   * @format double
   */
  upperLimitAmount?: number;
  /**
   * 上限金额税费
   * @format double
   */
  upperLimitAmountTax?: number;
  /**
   * POBO子项总计金额
   * @format double
   */
  poboSubtotalAmount?: number;
  /**
   * 外币结算金额
   * @format double
   */
  settlementAmount?: number;
  /** 是否跨境账单 */
  isCrossBorderBilling?: string | null;
  /** 是否内部账单 */
  isInternalBilling?: string | null;
  /** 是否坏账冲抵账单 */
  isBadDebts?: string | null;
  /** 账单生成附件 */
  billingAttachmentId?: string | null;
  /** 账单模板 */
  billingTemplateId?: string | null;
  /** 账单描述 */
  narrative?: string | null;
  /** 内部描述 */
  internalDescription?: string | null;
  /** 是否子账单 */
  isSubBilling?: string | null;
  /** 原系统主键编号 */
  originalId?: string | null;
  /** 流程数据大状态 */
  status?: string | null;
  /**
   * 提交时间
   * @format date-time
   */
  submittedOn?: string | null;
  /** 工作流状态 */
  flowState?: string | null;
  /** 申请人 */
  applicant?: string | null;
  /**
   * 工作流结束时间
   * @format date-time
   */
  flowEndDateTime?: string | null;
  /** 是否超期 */
  isOverdue?: boolean | null;
  /** 备注 */
  remark?: string | null;
  /** 办公室 */
  officeId?: string | null;
  /** 父级账单编号 */
  parentBillingId?: string | null;
  /**
   * 账单工时区间低回收率说明
   * @maxLength 500
   */
  lowRecoveryRateRemark?: string | null;
  /** 是否e-billing账单 */
  isEBilling?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 发票申请表 */
export interface CreateDraftFinancialInvoiceApplyInput {
  /** 发票申请ID（编辑时需传ID） */
  id?: string | null;
  /** 发票类型 */
  invoiceType?: string | null;
  /** 客户Id */
  clientId?: string | null;
  /** 办公室 */
  officeId?: string | null;
  /**
   * 税率
   * @format double
   */
  taxRate?: number;
  /**
   * 开票日期
   * @format date-time
   */
  invoiceDate?: string | null;
  /** 发票内容 */
  invoiceContent?: string | null;
  /** 邮箱地址 */
  emailAddress?: string | null;
  /** 备注 */
  remark?: string | null;
  /** 发票列表 */
  invoiceList?: GetFinancialInvoiceOutput[] | null;
  /** 是否发票抬头与委托方不一致 */
  isInvoiceHeaderNotMatchPrincipal?: string | null;
  /** 发票抬头Id */
  invoiceTitleId?: string | null;
  /** 发票抬头 */
  invoiceHeader?: string | null;
  /** 开票方类型 */
  invoiceIssuerType?: string | null;
  /** 纳税人识别号 */
  taxNumber?: string | null;
  /** 开户行 */
  bankName?: string | null;
  /** 银行账号 */
  bankAccount?: string | null;
  /** 联系电话 */
  landline?: string | null;
  /** 地址 */
  address?: string | null;
  /** 发票抬头说明附件 */
  invoiceHeaderAttachmentId?: string | null;
  /** 协议或补充协议附件 */
  agreementAttachmentId?: string | null;
  /** 其他附件 */
  otherAttachmentId?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 创建工时记录草稿入参 */
export interface CreateDraftTimesheetInput {
  /** 工时编号 */
  id?: string | null;
  /**
   * 工作日期
   * @format date-time
   */
  workDate?: string;
  /**
   * 开始时间
   * @format time
   */
  startTime?: string | null;
  /**
   * 结束时间
   * @format time
   */
  endTime?: string | null;
  /**
   * 工作小时数
   * @format double
   */
  hoursWorked?: number;
  /**
   * 内部费率
   * @format double
   */
  internalRate?: number;
  /** 客户编号 */
  clientId?: string | null;
  /** 案件编号 */
  caseId?: string | null;
  /** 书写语言 */
  writtenLanguage?: string | null;
  /** 是否收藏 */
  isFavorited?: boolean | null;
  /**
   * 累计计时时间
   * @format double
   */
  accumulatedTime?: number;
  /** 是否跨天拆分 */
  isSplitAcrossDays?: boolean | null;
  /** 计时人员 */
  employeeId?: string | null;
  /** 计时状态 */
  timerState?: string | null;
  /** 工时描述  长度2000 */
  narrative?: string | null;
  /** 工时类别 */
  category?: string | null;
  /** 暂代昵称 */
  nickName?: string | null;
  /** 关联任务 */
  task?: string | null;
  /** 活动代码 */
  activityCode?: string | null;
  /** 货币类型 */
  currency?: string | null;
  /** 是否计时器计时 */
  isTimerTracked?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 创建企业信息 */
export interface CreateEnterpriseInfoInput {
  /** 公司名称 */
  enterpriseName?: string | null;
  /** 执行许可号 */
  enterpriseCode?: string | null;
  /** 法人姓名 */
  corporation?: string | null;
  /** 统一社会信用代码 */
  creditCode?: string | null;
  /** 邮箱地址 */
  email?: string | null;
  /** 注册地址 */
  enterpriseAddress?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 实体联系人表 */
export interface CreateEntityContactInput {
  id?: string | null;
  /** 联系人名称 */
  name?: string | null;
  /** 别名 */
  altName?: string | null;
  /** 外文名称 */
  foreignName?: string | null;
  /** 速搜码，简码 */
  shortCode?: string | null;
  /** 身份类别（客户联系人/法院法官/对方联系人/检察官/监狱长等） */
  identityType?: string | null;
  /** 记录来源 */
  loadSource?: string | null;
  /** 性别 */
  gender?: string | null;
  /** 民族 */
  nation?: string | null;
  /**
   * 生日
   * @format date-time
   */
  birthday?: string;
  /** 职位 */
  duty?: string | null;
  /** 头像 */
  avatar?: string | null;
  /** 图像/图片 */
  picture?: string | null;
  /** 邮编 */
  zipCode?: string | null;
  /** 通讯地址 */
  address?: string | null;
  /** 所属地区 */
  region?: string | null;
  /** 画像 */
  portraitNarrative?: string | null;
  /** 邮箱地址 */
  emailAddress?: string | null;
  /** 个人邮箱地址 */
  perEmailAddress?: string | null;
  /** 联系固话 */
  landline?: string | null;
  /** 移动电话 */
  phoneNumber?: string | null;
  /** 个人移动电话 */
  perPhoneNumber?: string | null;
  /** 传真 */
  fax?: string | null;
  /** 网站主页 */
  webPortal?: string | null;
  /** 证件类型 */
  creditType?: string | null;
  /** 证件号码 */
  creditCode?: string | null;
  /** 来源 */
  originSource?: string | null;
  /** 即时通讯类型（微信/Line/Whatapp等） */
  imType?: string | null;
  /** 即时通讯号码 */
  imNumber?: string | null;
  /** 即时通讯描述 */
  imNarrative?: string | null;
  /** 即时通讯类型2 */
  imType2?: string | null;
  /** 即时通讯号码2 */
  imNumber2?: string | null;
  /** 即时通讯描述2 */
  imNarrative2?: string | null;
  /** 社交媒体类型 */
  socialMediaType?: string | null;
  /** 社交媒体编号 */
  socialMediaId?: string | null;
  /** 社交媒体描述 */
  socialMediaNarrative?: string | null;
  /** 描述说明 */
  narrative?: string | null;
  /** 喜好/爱好 */
  beFondOf?: string | null;
  /** 备注 */
  remark?: string | null;
  /** 客户编号 */
  clientId?: string | null;
  /** 案件编号 */
  caseId?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 通用返回信息类 */
export interface CreateEntityContactInputMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 实体联系人表 */
  response?: CreateEntityContactInput;
}

/** 实体联系人与客户案件关系表 */
export interface CreateEntityContactRelationshipInput {
  /** 联系人编号 */
  contactId?: string | null;
  /** 客户编号 */
  clientId?: string | null;
  /** 案件编号 */
  caseId?: string | null;
  /** 说明描述 */
  narrative?: string | null;
  /** 租户ID */
  tenantId?: string | null;
  /** 部门ID  DepartmentId */
  organizationUnitId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 备注 */
  remark?: string | null;
  /** 实体联系人表 */
  entityContact?: CreateEntityContactInput;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 实体曾用名记录表 */
export interface CreateEntityFormerNameInput {
  id?: string | null;
  /** 实体编号 */
  entityId?: string | null;
  /** 曾用名 */
  name?: string | null;
  /** 外文曾用名称 */
  foreignName?: string | null;
  /**
   * 名称使用开始时间
   * @format date-time
   */
  beginDate?: string;
  /**
   * 名称使用结束时间
   * @format date-time
   */
  endDate?: string;
  /** 记录来源 */
  loadSource?: string | null;
  /** 说明描述 */
  narrative?: string | null;
  /** 实体类别（客户/联系人/股东/实控人/母公司等） */
  entityType?: string | null;
  /** 租户ID */
  tenantId?: string | null;
  /** 部门ID  DepartmentId */
  organizationUnitId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 实体主体信息表 */
export interface CreateEntityInfoInput {
  /** Id */
  id?: string | null;
  /** 外键id */
  foreignId?: string | null;
  /** 实体或自然人名称 */
  name?: string | null;
  /** 外文名称 */
  foreignName?: string | null;
  /** 曾用户 */
  formerName?: string | null;
  /** 别名 */
  altName?: string | null;
  /** 速搜码，简码 */
  shortCode?: string | null;
  /** 是否自然人 */
  isNaturalPerson?: boolean;
  /** 是否涉外实体或个人 */
  isForeign?: boolean;
  /** 客户类型（自然人/政府机构/实体） */
  category?: string | null;
  /** 实体类别（客户/客户实控人/股东等） */
  entityType?: string | null;
  /** 实体子类 */
  econKind?: string | null;
  /** 注册号 */
  registerationNumber?: string | null;
  /** 注册名称 */
  registerationName?: string | null;
  /** 注册地址 */
  registerationAddress?: string | null;
  /** 所属行业编号 */
  industryId?: string | null;
  /** 所属行业名称 */
  industryText?: string | null;
  /** 证件类型（身份证/信用代码/护照） */
  creditType?: string | null;
  /** 证件号码 */
  creditCode?: string | null;
  /** 地址 */
  address?: string | null;
  /** 性别（自然人独占） */
  gender?: string | null;
  /** 民族（自然人独占） */
  nation?: string | null;
  /** 邮箱地址 */
  emailAddress?: string | null;
  /** 固定电话 */
  landline?: string | null;
  /** 移动电话 */
  phoneNumber?: string | null;
  /** 传真 */
  fax?: string | null;
  /** 邮政编码 */
  zipCode?: string | null;
  /** 网站地址 */
  webPortal?: string | null;
  /** 出生日期（自然人独占） */
  birthday?: string | null;
  /** 法定代表人 */
  legalPerson?: string | null;
  /** 法定代表人编号 */
  legalPersonId?: string | null;
  /** 经营场所 */
  businessAddress?: string | null;
  /** 税号 */
  taxNumber?: string | null;
  /** 组织机构编号 */
  orgNumber?: string | null;
  /** 经营范围 */
  businessScope?: string | null;
  /** 注册资本 */
  registeredCapital?: string | null;
  /** 实缴资本 */
  paidInCapital?: string | null;
  /** 成立日期 */
  establishmentDate?: string | null;
  /** 营业期限 */
  businessTerm?: string | null;
  /** 营业执照发证日期 */
  licenseIssueDate?: string | null;
  /** 发证机关（登记机关） */
  issuingAuthority?: string | null;
  /** 经营状态（登记状态） */
  operationalStatus?: string | null;
  /** 纳税人资质 */
  credential?: string | null;
  /** 纳税人资质ID */
  credentialId?: string | null;
  /** 核准日期 */
  issueDate?: string | null;
  /** 所属国家 */
  country?: string | null;
  /** 所属省或州郡 */
  province?: string | null;
  /** 所属地区 */
  region?: string | null;
  /** 进出口企业代码 */
  importExportCode?: string | null;
  /** 人员规模 */
  staffSize?: string | null;
  /** 参保人数 */
  numberOfInsured?: string | null;
  /** 客户来源 */
  customerSource?: string | null;
  /** 源实体编号 */
  originEntityId?: string | null;
  /** 源实体名称（如果是手工创建，请传手工创建，如果是第三方（下拉框选择），请传第三方） */
  originEntityName?: string | null;
  /** 业务来源（立案/投标/入库等） */
  businessSource?: string | null;
  /** 是否上市企业 */
  isListedEnterprise?: string | null;
  /** 上市地区 */
  listedRegion?: string | null;
  /** 上市机构 */
  listedOrg?: string | null;
  /** 上市类别（新三板，创业板，A股等） */
  listedType?: string | null;
  /** 上市股票代码 */
  listedStockNumber?: string | null;
  /** 是否名录企业 */
  isDirectoryEnterprise?: string | null;
  /** 是否500强企业 */
  isTop500?: string | null;
  /** 是否小微企业 */
  isMicroEnterprise?: string | null;
  /** 标签 */
  tags?: string | null;
  /** 是否披露 */
  isDisclose?: string | null;
  /** 客户级别 */
  clientLevel?: string | null;
  /** 记录来源 */
  loadSource?: string | null;
  /** 记录来源编号 */
  loadSourceId?: string | null;
  /** logo链接 */
  logoUrl?: string | null;
  /** Logo图片 */
  logoPicture?: string | null;
  /** 头像 */
  avatar?: string | null;
  /** 客户画像描述 */
  portraitNarrative?: string | null;
  /** 是否吊销 */
  isRevoke?: boolean;
  /**
   * 吊销日期
   * @format date-time
   */
  revokeDate?: string;
  /** 吊销原因 */
  revokeRemark?: string | null;
  /** 租户ID */
  tenantId?: string | null;
  /** 部门ID  DepartmentId */
  organizationUnitId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 备注 */
  remark?: string | null;
  /** 联系人 */
  contactName?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 发票抬头表 */
export interface CreateEntityInvoiceTitleInput {
  /** Id */
  id?: string | null;
  /** 外键id */
  foreignId?: string | null;
  /** 开户行 */
  bank?: string | null;
  /** 账号 */
  bankAccount?: string | null;
  /** 发票抬头 */
  invoiceTitle?: string | null;
  /** 纳税人识别号 */
  creditCode?: string | null;
  /** 地址 */
  address?: string | null;
  /** 电话 */
  tel?: string | null;
  /** 流水编号 */
  serialId?: string | null;
  /** 原系统主键编号 */
  originalId?: string | null;
  /** 流程数据大状态 */
  status?: string | null;
  /**
   * 提交时间
   * @format date-time
   */
  submittedOn?: string | null;
  /** 工作流状态 */
  flowState?: string | null;
  /** 申请人 */
  applicant?: string | null;
  /**
   * 工作流结束时间
   * @format date-time
   */
  flowEndDateTime?: string | null;
  /** 是否超期 */
  isOverdue?: boolean | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 分配信息表 */
export interface CreateFinancialAllocationInput {
  /** 收款编号 */
  receiptId?: string | null;
  /** 案件Id */
  caseId?: string | null;
  /** 分配方式 */
  allocationType?: string | null;
  /**
   * 分配日期
   * @format date-time
   */
  allocationDate?: string | null;
  /** 是否红冲分配 */
  isRedPunch?: string | null;
  /** 分配模式 */
  allocationMode?: string | null;
  /** 分配性质 */
  allocationNature?: string | null;
  /**
   * 分配金额
   * @format double
   */
  amount?: number;
  /**
   * 分配比例
   * @format double
   */
  allocationRate?: number;
  /**
   * 案源费比例
   * @format double
   */
  caseSourceRate?: number;
  /**
   * 案源费分配金额
   * @format double
   */
  caseSourceAmount?: number;
  /**
   * 尾差
   * @format double
   */
  roundingDifference?: number;
  /** 分配策略 */
  allocationStrattegy?: string | null;
  /** 分配人 */
  employeeId?: string | null;
  /** 是否保密 */
  ifConfidential?: string | null;
  /**
   * 律师费金额
   * @format double
   */
  lawyerFee?: number;
  /**
   * 官费金额
   * @format double
   */
  officialFees?: number;
  /**
   * 支出费用
   * @format double
   */
  expenseFee?: number;
  /**
   * 会计账期
   * @format date-time
   */
  accountingPeriod?: string | null;
  currency?: string | null;
  /** 流水编号 */
  serialId?: string | null;
  /** 原系统主键编号 */
  originalId?: string | null;
  /** 流程数据大状态 */
  status?: string | null;
  /**
   * 提交时间
   * @format date-time
   */
  submittedOn?: string | null;
  /** 工作流状态 */
  flowState?: string | null;
  /** 申请人 */
  applicant?: string | null;
  /**
   * 工作流结束时间
   * @format date-time
   */
  flowEndDateTime?: string | null;
  /** 是否超期 */
  isOverdue?: boolean | null;
  /** 备注 */
  remark?: string | null;
  /** 分配明细 */
  items?: CreateFinancialAllocationItemInput[] | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 历史分配明细信息表 */
export interface CreateFinancialAllocationItemHistoryInput {
  /** 分配编号 */
  allocationId?: string | null;
  /** 收款编号 */
  receiptId?: string | null;
  /** 被分配人 */
  employeeId?: string | null;
  /** 案件Id */
  caseId?: string | null;
  /**
   * 分配日期
   * @format date-time
   */
  allocationDate?: string | null;
  /**
   * 分配金额
   * @format double
   */
  amount?: number;
  /**
   * 分配比例
   * @format double
   */
  allocationRate?: number;
  /**
   * 尾差
   * @format double
   */
  roundingDifference?: number;
  /**
   * 薪酬金额
   * @format double
   */
  remunerationAmount?: number;
  /**
   * 薪酬比例
   * @format double
   */
  remunerationRate?: number;
  /**
   * 案源费比例
   * @format double
   */
  caseSourceRate?: number;
  /**
   * 案源费分配金额
   * @format double
   */
  caseSourceAmount?: number;
  /** 是否是案源费 */
  isSourceFee?: string | null;
  /** 参案角色 */
  lawyerRole?: string | null;
  /**
   * 分配基数
   * @format double
   */
  allocationBase?: number;
  /** 备注 */
  remark?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 通用返回信息类 */
export interface CreateFinancialAllocationItemHistoryInputMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 历史分配明细信息表 */
  response?: CreateFinancialAllocationItemHistoryInput;
}

/** 分配明细信息表 */
export interface CreateFinancialAllocationItemInput {
  /** 明细Id */
  id?: string | null;
  /** 分配编号 */
  allocationId?: string | null;
  /** 收款编号 */
  receiptId?: string | null;
  /** 被分配人 */
  employeeId?: string | null;
  /** 被分配人 */
  employeeIdText?: string | null;
  /**
   * 分配日期
   * @format date-time
   */
  allocationDate?: string | null;
  /**
   * 分配金额
   * @format double
   */
  amount?: number;
  /**
   * 分配比例
   * @format double
   */
  allocationRate?: number;
  /**
   * 尾差
   * @format double
   */
  roundingDifference?: number;
  /**
   * 薪酬金额
   * @format double
   */
  remunerationAmount?: number;
  /**
   * 薪酬比例
   * @format double
   */
  remunerationRate?: number;
  /**
   * 案源费比例
   * @format double
   */
  caseSourceRate?: number;
  /**
   * 案源费分配金额
   * @format double
   */
  caseSourceAmount?: number;
  /** 是否是案源费 */
  isSourceFee?: string | null;
  /** 是否是案源费 */
  isSourceFeeText?: string | null;
  /** 参案角色 */
  lawyerRole?: string | null;
  /** 参案角色 */
  lawyerRoleText?: string | null;
  /**
   * 分配基数
   * @format double
   */
  allocationBase?: number;
  /** 备注 */
  remark?: string | null;
  /** 币种 */
  currency?: string | null;
  /** 参案角色 */
  currencyText?: string | null;
  /** 案件Id */
  caseId?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 通用返回信息类 */
export interface CreateFinancialAllocationItemInputMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 分配明细信息表 */
  response?: CreateFinancialAllocationItemInput;
}

/** 分配比例案件设置表 */
export interface CreateFinancialAllocationRateCaseSettingInput {
  id?: string | null;
  /** 案件Id */
  caseId?: string | null;
  /** 相关分配策略Id */
  rateSettingId?: string | null;
  /** 相关分配策略Id */
  rateSettingName?: string | null;
  /** 备注 */
  remark?: string | null;
  /** 分配明细 */
  items?: CreateFinancialAllocationRateCaseSettingItemInput[] | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

export interface CreateFinancialAllocationRateCaseSettingItemInput {
  /** 分配比例设置编号 */
  allocationRateSettingId?: string | null;
  /** 案件编号 */
  caseId?: string | null;
  /** 人员编号 */
  employeeId?: string | null;
  /** 参案角色 */
  lawyerRole?: string | null;
  /**
   * 分配比例
   * @format double
   */
  allocationRate?: number;
  /** 是否案源费 */
  isCaseSourceFee?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 分配比例设置表 */
export interface CreateFinancialAllocationRateSettingInput {
  /** ID */
  id?: string | null;
  /** 策略名称 */
  strattegyName?: string | null;
  /** 策略编号 */
  strattegyId?: string | null;
  /** 是否默认 */
  isDefault?: boolean;
  /** 备注 */
  remark?: string | null;
  /** 案件Id */
  caseId?: string | null;
  /** 分配比例明细 */
  items?: CreateFinancialAllocationItemInput[] | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 分配比例设置明细历史表 */
export interface CreateFinancialAllocationRateSettingItemHistoryInput {
  /** 分配比例设置编号 */
  allocationRateSettingId?: string | null;
  /** 案件编号 */
  caseId?: string | null;
  /** 人员编号 */
  employeeId?: string | null;
  /** 参案角色 */
  lawyerRole?: string | null;
  /**
   * 分配比例
   * @format double
   */
  allocationRate?: number;
  /** 是否案源费 */
  isCaseSourceFee?: string | null;
  /** 分配比例历史版本 */
  historyVersion?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 分配比例设置明细表 */
export interface CreateFinancialAllocationRateSettingItemInput {
  /** 分配比例设置编号 */
  allocationRateSettingId?: string | null;
  /** 案件编号 */
  caseId?: string | null;
  /** 人员编号 */
  employeeId?: string | null;
  /** 参案角色 */
  lawyerRole?: string | null;
  /**
   * 分配比例
   * @format double
   */
  allocationRate?: number;
  /** 是否案源费 */
  isCaseSourceFee?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 账单调整明细表 */
export interface CreateFinancialBillingAdjustmentInput {
  /** 账单编号 */
  billingId?: string | null;
  /** 调整项目 */
  category?: string | null;
  /** 应用范围 */
  scope?: string | null;
  /** 调整类型 */
  adjustmentType?: string | null;
  /**
   * 基数
   * @format double
   */
  base?: number;
  /**
   * 调整比例
   * @format double
   */
  adjustmentRate?: number;
  /**
   * 调整额
   * @format double
   */
  adjustment?: number;
  /**
   * 调整后金额
   * @format double
   */
  amount?: number;
  /**
   * 小计金额
   * @format double
   */
  subtotal?: number;
  /** 描述 */
  narrative?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 表格数据，支持分页 */
export interface CreateFinancialBillingAdjustmentInputForEditModel {
  /** 账单调整明细表 */
  data?: CreateFinancialBillingAdjustmentInput;
  /** 实体映射 */
  mapping?: Record<string, any>;
}

/** 通用返回信息类 */
export interface CreateFinancialBillingAdjustmentInputForEditModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 表格数据，支持分页 */
  response?: CreateFinancialBillingAdjustmentInputForEditModel;
}

/** 账单生成文件表 */
export interface CreateFinancialBillingAttachmentInput {
  /** 账单编号 */
  billingId?: string | null;
  /** 账单文件名称 */
  name?: string | null;
  /** 账单文件类型 */
  fileExtension?: string | null;
  /** 账单模版编号 */
  templateId?: string | null;
  /** 账单模版名称 */
  templateName?: string | null;
  attachmentId?: string | null;
  pdfAttachmentId?: string | null;
  isFinal?: boolean;
  displayAdjustmentDiscountPercentage?: boolean;
  includingTimesheetDetailedInfo?: boolean;
  displayTimesheetDetailedHorizontally?: boolean;
  displayOriginalFee?: boolean;
  includingExpenseDetailedInfo?: boolean;
  displayCoverLetter?: boolean;
  displayBankAccountInfo?: boolean;
  /** 备注 */
  remark?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 账单评论信息 */
export interface CreateFinancialBillingCommentsInput {
  /** 账单编号 */
  billingId?: string | null;
  /** 账单子项编号 */
  itemId?: string | null;
  /** 账单子项类型 */
  itemScope?: string | null;
  /** 评论人编号 */
  userId?: string | null;
  /** 评论可见范围 */
  visibleScope?: string | null;
  /** 评论内容 */
  commentText?: string | null;
  /** 是否已解决 */
  isResolved?: string | null;
  /** 解决人编号 */
  resolvedUserId?: string | null;
  /**
   * 解决时间
   * @format date-time
   */
  resolvedTime?: string | null;
  /** 父评论编号 */
  parentCommitId?: string | null;
  /** 评论引用高亮文本 */
  highlightedText?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 账单关联费用支出明细表 */
export interface CreateFinancialBillingDisbursementInput {
  /** 账单编号 */
  billingId?: string | null;
  /**
   * 费用日期
   * @format date-time
   */
  expenseDate?: string | null;
  /** 费用名称 */
  name?: string | null;
  /** 费用代码 */
  expenseCode?: string | null;
  /** 费用类别 */
  category?: string | null;
  /** 费用子类别 */
  subCategory?: string | null;
  /** 币种 */
  currency?: string | null;
  /**
   * 币种汇率
   * @format double
   */
  exchangeRate?: number;
  /** 报销类型 */
  expenseType?: string | null;
  /** 是否代收代付 */
  isPobo?: string | null;
  /**
   * 费用金额
   * @format double
   */
  amount?: number;
  /**
   * 计数数量
   * @format double
   */
  count?: number;
  /** 调整类型 */
  adjustmentType?: string | null;
  /**
   * 调整比例
   * @format double
   */
  adjustmentRate?: number;
  /**
   * 调整金额
   * @format double
   */
  adjustment?: number;
  /**
   * 税率
   * @format double
   */
  taxRate?: number;
  /**
   * 税额
   * @format double
   */
  taxAmount?: number;
  /**
   * 外币服务费金额
   * @format double
   */
  foreignAmount?: number;
  /**
   * 外币小计金额
   * @format double
   */
  foreignSubtotal?: number;
  /**
   * 小计金额
   * @format double
   */
  subtotal?: number;
  /** 是否计费 */
  isBillable?: string | null;
  /** 报销单编号 */
  expenserportId?: string | null;
  /** 是否已开票 */
  isInvoiced?: string | null;
  /** 商户名称 */
  merchant?: string | null;
  /** 案件编号 */
  caseId?: string | null;
  /** 费用报销人 */
  employeeId?: string | null;
  /** 费用报销人显示名称 */
  employeeDisplayName?: string | null;
  /** 发票/收据类型 */
  receiptType?: string | null;
  /** 描述 */
  narrative?: string | null;
  /** 是否内部账单 */
  isInternalBilling?: string | null;
  /** 内部账单编号 */
  internalBillingId?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 表格数据，支持分页 */
export interface CreateFinancialBillingDisbursementInputForEditModel {
  /** 账单关联费用支出明细表 */
  data?: CreateFinancialBillingDisbursementInput;
  /** 实体映射 */
  mapping?: Record<string, any>;
}

/** 通用返回信息类 */
export interface CreateFinancialBillingDisbursementInputForEditModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 表格数据，支持分页 */
  response?: CreateFinancialBillingDisbursementInputForEditModel;
}

/** 账单汇率表 */
export interface CreateFinancialBillingExchangeInput {
  /** 账单编号 */
  billingId?: string | null;
  /** 账单币种 */
  baseCurrency?: string | null;
  /** 目标币种 */
  targetCurrency?: string | null;
  /**
   * 汇率
   * @format double
   */
  exchangeRate?: number | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 账单关联固定服务费明细表 */
export interface CreateFinancialBillingFixedServiceFeeInput {
  /** 账单编号 */
  billingId?: string | null;
  /** 案件编号 */
  caseId?: string | null;
  /**
   * 服务日期
   * @format date-time
   */
  serviceDate?: string | null;
  /** 任务 */
  task?: string | null;
  /** 活动代码 */
  activityCode?: string | null;
  /** 服务类型 */
  category?: string | null;
  /**
   * 服务费金额
   * @format double
   */
  amount?: number;
  /** 调整类型 */
  adjustmentType?: string | null;
  /**
   * 调整比例
   * @format double
   */
  adjustmentRate?: number;
  /**
   * 调整额
   * @format double
   */
  adjustment?: number;
  /**
   * 税率
   * @format double
   */
  taxRate?: number;
  /**
   * 税额
   * @format double
   */
  taxAmount?: number;
  /** 币种 */
  currency?: string | null;
  /**
   * 币种汇率
   * @format double
   */
  exchangeRate?: number;
  /**
   * 外币服务费金额
   * @format double
   */
  foreignAmount?: number;
  /**
   * 外币小计金额
   * @format double
   */
  foreignSubtotal?: number;
  /**
   * 小计金额
   * @format double
   */
  subtotal?: number;
  /** 是否计费 */
  isBillable?: string | null;
  /** 描述 */
  narrative?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 表格数据，支持分页 */
export interface CreateFinancialBillingFixedServiceFeeInputForEditModel {
  /** 账单关联固定服务费明细表 */
  data?: CreateFinancialBillingFixedServiceFeeInput;
  /** 实体映射 */
  mapping?: Record<string, any>;
}

/** 通用返回信息类 */
export interface CreateFinancialBillingFixedServiceFeeInputForEditModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 表格数据，支持分页 */
  response?: CreateFinancialBillingFixedServiceFeeInputForEditModel;
}

/** 账单外币支付信息 */
export interface CreateFinancialBillingForeignPaymentInput {
  /** 账单编号 */
  billingId?: string | null;
  /** 账单币种 */
  billingCurrency?: string | null;
  /**
   * 账单金额
   * @format double
   */
  billingAmount?: number | null;
  /** 支付币种 */
  targetCurrency?: string | null;
  /**
   * 汇率
   * @format double
   */
  exchangeRate?: number | null;
  /**
   * 结算金额
   * @format double
   */
  targetAmount?: number | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 账单信息表 */
export interface CreateFinancialBillingInput {
  /** 案件编号 */
  caseId?: string | null;
  /** 客户编号 */
  clientId?: string | null;
  /** 账单案件别名 */
  caseMatterName?: string | null;
  /**
   * 账单日期
   * @format date-time
   */
  billingDate?: string | null;
  /**
   * 到账日期
   * @format date-time
   */
  arrivedDate?: string | null;
  /**
   * 账单开始日期
   * @format date-time
   */
  startDate?: string | null;
  /**
   * 账单结束日期
   * @format date-time
   */
  endDate?: string | null;
  /** 付款方编号 */
  paymentId?: string | null;
  /** 付款方名称 */
  paymentName?: string | null;
  /** 付款周期 */
  paymentTerms?: string | null;
  /** 付款货币类型 */
  paymentCurrency?: string | null;
  /**
   * 应收账款
   * @format double
   */
  accountsReceivable?: number;
  /** 账单合伙人 */
  billingPartnerId?: string | null;
  /** 账单申请律师 */
  billingLawyerId?: string | null;
  /** AR负责人 */
  arResponsibleLawyerId?: string | null;
  /** 币种 */
  currency?: string | null;
  /**
   * 汇率
   * @format double
   */
  exchangeRate?: number;
  /** 支付状态 */
  paymentStatus?: string | null;
  /** 账单联系人 */
  contactPerson?: string | null;
  /** 邮箱地址 */
  emailAddress?: string | null;
  /** 账单所属办公室 */
  billingOffice?: string | null;
  /** 账单关联银行账号 */
  billingBankId?: string | null;
  /** 账单类型 */
  category?: string | null;
  /** 是否联合账单 */
  isBatchBilling?: string | null;
  /** 联合账单关联案号 */
  batchCaseIds?: string | null;
  /** 关联内部账单编号 */
  internalBillingIds?: string | null;
  /**
   * 总固定服务费
   * @format double
   */
  totalFixedServiceFee?: number;
  /**
   * 固定服务税费
   * @format double
   */
  fixedServiceTax?: number;
  /**
   * 账单总金额
   * @format double
   */
  totalAmount?: number;
  /**
   * 总工时服务费
   * @format double
   */
  totalTimesheetServiceFee?: number;
  /**
   * 工时税费
   * @format double
   */
  timesheetTax?: number;
  /**
   * 总支出费用金额
   * @format double
   */
  totalDisbursement?: number;
  /**
   * 支出税费
   * @format double
   */
  disbursementTax?: number;
  /**
   * 调整金额
   * @format double
   */
  totalAdjustment?: number;
  /**
   * 工时调整总额
   * @format double
   */
  timesheetAdjustment?: number;
  /**
   * 固定服务调整总额
   * @format double
   */
  fixedServiceAdjustment?: number;
  /**
   * 支出调整总额
   * @format double
   */
  disbursementAdjustment?: number;
  /**
   * 上限金额
   * @format double
   */
  upperLimitAmount?: number;
  /**
   * 上限金额税费
   * @format double
   */
  upperLimitAmountTax?: number;
  /**
   * POBO子项总计金额
   * @format double
   */
  poboSubtotalAmount?: number;
  /**
   * 外币结算金额
   * @format double
   */
  settlementAmount?: number;
  /** 是否跨境账单 */
  isCrossBorderBilling?: string | null;
  /** 是否内部账单 */
  isInternalBilling?: string | null;
  /** 是否坏账冲抵账单 */
  isBadDebts?: string | null;
  /** 账单生成附件 */
  billingAttachmentId?: string | null;
  /** 账单模板 */
  billingTemplateId?: string | null;
  /** 账单描述 */
  narrative?: string | null;
  /** 内部描述 */
  internalDescription?: string | null;
  /** 是否子账单 */
  isSubBilling?: string | null;
  /** 原系统主键编号 */
  originalId?: string | null;
  /** 流程数据大状态 */
  status?: string | null;
  /**
   * 提交时间
   * @format date-time
   */
  submittedOn?: string | null;
  /** 工作流状态 */
  flowState?: string | null;
  /** 申请人 */
  applicant?: string | null;
  /**
   * 工作流结束时间
   * @format date-time
   */
  flowEndDateTime?: string | null;
  /** 是否超期 */
  isOverdue?: boolean | null;
  /** 备注 */
  remark?: string | null;
  /** 办公室 */
  officeId?: string | null;
  /** 父级账单编号 */
  parentBillingId?: string | null;
  /**
   * 账单工时区间低回收率说明
   * @maxLength 500
   */
  lowRecoveryRateRemark?: string | null;
  /** 是否e-billing账单 */
  isEBilling?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 账单模板表 */
export interface CreateFinancialBillingTemplateInput {
  /** 模板名称 */
  name?: string | null;
  /** 文件路径 */
  filePath?: string | null;
  /** 文件类型 */
  fileExtension?: string | null;
  /**
   * 文件大小
   * @format float
   */
  fileSize?: number;
  /** 适用语言 */
  language?: string | null;
  /** 模板适用范围 */
  scope?: string | null;
  /** 适用案件类型 */
  caseCategory?: string | null;
  /** 模板类型 */
  category?: string | null;
  /** 是否包含封面页 */
  isIncludedCoverLetter?: string | null;
  /** 是否是上传模板 */
  isUpload?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 通用返回信息类 */
export interface CreateFinancialBillingTemplateInputMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 账单模板表 */
  response?: CreateFinancialBillingTemplateInput;
}

/** 账单工时费明细表 */
export interface CreateFinancialBillingTimesheetServiceFeeInput {
  /** 账单编号 */
  billingId?: string | null;
  /** 案件编号 */
  caseId?: string | null;
  /** 工时编号 */
  timesheetId?: string | null;
  /**
   * 工时日期
   * @format date-time
   */
  workDate?: string | null;
  /** 工时填报人 */
  employeeId?: string | null;
  /** 费用报销人显示名称 */
  employeeDisplayName?: string | null;
  /**
   * 小时费率
   * @format double
   */
  hourlyRate?: number;
  /** 任务 */
  task?: string | null;
  /** 活动代码 */
  activityCode?: string | null;
  /** 币种 */
  currency?: string | null;
  /**
   * 币种汇率
   * @format double
   */
  exchangeRate?: number;
  /**
   * 自报小时
   * @format double
   */
  hoursWorked?: number;
  /**
   * 计费小时
   * @format double
   */
  billableHours?: number;
  /** 工时类型 */
  category?: string | null;
  /** 调整类型 */
  adjustmentType?: string | null;
  /**
   * 调整比例
   * @format double
   */
  adjustmentRate?: number;
  /**
   * 调整额
   * @format double
   */
  adjustment?: number;
  /**
   * 税率
   * @format double
   */
  taxRate?: number;
  /**
   * 税额
   * @format double
   */
  taxAmount?: number;
  /**
   * 金额
   * @format double
   */
  amount?: number;
  /**
   * 外币服务费金额
   * @format double
   */
  foreignAmount?: number;
  /**
   * 外币小计金额
   * @format double
   */
  foreignSubtotal?: number;
  /**
   * 小计金额
   * @format double
   */
  subtotal?: number;
  /** 是否计费 */
  isBillable?: string | null;
  /** 是否销账 */
  isWriteOff?: string | null;
  /** 销账类型 */
  writeOffType?: string | null;
  /** 销账理由 */
  writeOffRemark?: string | null;
  /** 工时描述 */
  narrative?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 表格数据，支持分页 */
export interface CreateFinancialBillingTimesheetServiceFeeInputForEditModel {
  /** 账单工时费明细表 */
  data?: CreateFinancialBillingTimesheetServiceFeeInput;
  /** 实体映射 */
  mapping?: Record<string, any>;
}

/** 通用返回信息类 */
export interface CreateFinancialBillingTimesheetServiceFeeInputForEditModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 表格数据，支持分页 */
  response?: CreateFinancialBillingTimesheetServiceFeeInputForEditModel;
}

/** 账单限额设置 */
export interface CreateFinancialBillingUpperLimitInput {
  /** 账单编号 */
  billingId?: string | null;
  /** 应用范围 */
  scope?: string | null;
  /** 是否包含税费 */
  isIncludeTaxFee?: string | null;
  /** 是否设为固定收费 */
  isSetAsFixedFee?: string | null;
  /**
   * 上限金额
   * @format double
   */
  amount?: number;
  /**
   * 税率
   * @format double
   */
  taxRate?: number;
  /**
   * 小计金额
   * @format double
   */
  subtotal?: number;
  /** 备注 */
  remark?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 表格数据，支持分页 */
export interface CreateFinancialBillingUpperLimitInputForEditModel {
  /** 账单限额设置 */
  data?: CreateFinancialBillingUpperLimitInput;
  /** 实体映射 */
  mapping?: Record<string, any>;
}

/** 通用返回信息类 */
export interface CreateFinancialBillingUpperLimitInputForEditModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 表格数据，支持分页 */
  response?: CreateFinancialBillingUpperLimitInputForEditModel;
}

/** 财务办公室主管会计表 */
export interface CreateFinancialChiefAccountantInput {
  /** 会计主管 */
  userId?: string | null;
  /** 隶属办公室 */
  officeId?: string | null;
  /** 是否主管 */
  isChief?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 费用申请表 */
export interface CreateFinancialExpenseApplyInput {
  /**
   * 申请时间
   * @format date-time
   */
  applyDate?: string | null;
  /**
   * 消费时间
   * @format date-time
   */
  feeDate?: string | null;
  /** 账单编号 */
  billingId?: string | null;
  /** 费用名称 */
  name?: string | null;
  /** 费用代码 */
  expenseCode?: string | null;
  /** 费用类别 */
  category?: string | null;
  /** 费用子类别 */
  subCategory?: string | null;
  /** 币种 */
  currency?: string | null;
  /**
   * 币种汇率
   * @format double
   */
  exchangeRate?: number | null;
  /** 报销类型 */
  expenseType?: string | null;
  /** 是否代收代付 */
  isPobo?: string | null;
  /**
   * 费用金额
   * @format double
   */
  amount?: number | null;
  /**
   * 计数数量
   * @format double
   */
  count?: number | null;
  /**
   * 支付时间
   * @format date-time
   */
  payDate?: string | null;
  /**
   * 税率
   * @format double
   */
  taxRate?: number | null;
  /**
   * 税额
   * @format double
   */
  taxAmount?: number | null;
  /**
   * 小计金额
   * @format double
   */
  subtotal?: number | null;
  /** 是否计费 */
  isBillable?: string | null;
  /** 报销单编号 */
  expenserportId?: string | null;
  /** 是否已开票 */
  isInvoiced?: string | null;
  /** 商户名称 */
  merchant?: string | null;
  /** 案件编号 */
  caseId?: string | null;
  /** 费用报销人 */
  employeeId?: string | null;
  /** 费用描述 */
  narrative?: string | null;
  /** 发票/收据类型 */
  receiptType?: string | null;
  /** 流水编号 */
  serialId?: string | null;
  /** 原系统主键编号 */
  originalId?: string | null;
  /** 流程数据大状态 */
  status?: string | null;
  /**
   * 提交时间
   * @format date-time
   */
  submittedOn?: string | null;
  /** 工作流状态 */
  flowState?: string | null;
  /** 申请人 */
  applicant?: string | null;
  /**
   * 工作流结束时间
   * @format date-time
   */
  flowEndDateTime?: string | null;
  /** 是否超期 */
  isOverdue?: boolean | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 费用明细表 */
export interface CreateFinancialExpenseItemInput {
  /** 费用申请编号 */
  applyId?: string | null;
  /**
   * 费用日期
   * @format date-time
   */
  expenseDate?: string;
  /** 费用类型 */
  category?: string | null;
  /** 费用类型子类 */
  subCategory?: string | null;
  /** 账单编号 */
  billingId?: string | null;
  /** 是否入账 */
  isBillable?: string | null;
  /**
   * 金额
   * @format double
   */
  amount?: number | null;
  /** 币种 */
  currency?: string | null;
  /** 描述 */
  narrative?: string | null;
  /** 类型 */
  expenseType?: string | null;
  /** 商户名称 */
  merchant?: string | null;
  /** 案件编号 */
  caseId?: string | null;
  /** 客户编号 */
  clientId?: string | null;
  /** 收费代码 */
  chargeCode?: string | null;
  /** 费用详细描述 */
  expenseNarrative?: string | null;
  /** 报销单ID */
  expenseReportId?: string | null;
  /** 是否已开票 */
  isInvoiced?: string | null;
  /** 是否为POBO */
  isPobo?: string | null;
  /**
   * 支付时间
   * @format date-time
   */
  payDate?: string | null;
  /**
   * 税率
   * @format double
   */
  taxRate?: number | null;
  /**
   * 税额
   * @format double
   */
  taxAmount?: number | null;
  /**
   * 小计金额
   * @format double
   */
  subtotal?: number | null;
  /** 发票编号 */
  invoiceId?: string | null;
  /** 发票类型 */
  invoiceType?: string | null;
  /** 结算币种 */
  settlementCurrency?: string | null;
  /**
   * 结算汇率
   * @format double
   */
  settlementRate?: number | null;
  /**
   * 结算金额
   * @format double
   */
  settlementAmount?: number | null;
  /** 是否销账 */
  isWriteOff?: string | null;
  /** 销账类型 */
  writeOffType?: string | null;
  /** 销账理由 */
  writeOffRemark?: string | null;
  /**
   * 数量
   * @format double
   */
  count?: number | null;
  /** 参与人员 */
  attendees?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 发票分配信息表 */
export interface CreateFinancialInvoiceAllocationInput {
  /** 发票编号 */
  invoiceId?: string | null;
  /** 账单编号 */
  billingId?: string | null;
  /** 发票申请编号 */
  invoiceApplyId?: string | null;
  /** 案件编号 */
  caseId?: string | null;
  /**
   * 分配比例
   * @format double
   */
  allocationRate?: number;
  /**
   * 分配金额
   * @format double
   */
  allocationAmount?: number;
  /**
   * 尾差
   * @format double
   */
  roundingDifference?: number;
  /** 分配策略 */
  allocationStrattegy?: string | null;
  /** 分配人 */
  employeeId?: string | null;
  /** 是否保密 */
  ifConfidential?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 通用返回信息类 */
export interface CreateFinancialInvoiceAllocationInputMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 发票分配信息表 */
  response?: CreateFinancialInvoiceAllocationInput;
}

/** 发票申请表 */
export interface CreateFinancialInvoiceApplyInput {
  /** 发票申请ID（编辑时需传ID） */
  id?: string | null;
  /** 发票类型 */
  invoiceType?: string | null;
  /** 客户Id */
  clientId?: string | null;
  /** 办公室 */
  officeId?: string | null;
  /**
   * 税率
   * @format double
   */
  taxRate?: number;
  /**
   * 开票日期
   * @format date-time
   */
  invoiceDate?: string | null;
  /** 发票内容 */
  invoiceContent?: string | null;
  /** 邮箱地址 */
  emailAddress?: string | null;
  /** 备注 */
  remark?: string | null;
  /** 发票列表 */
  invoiceList?: GetFinancialInvoiceOutput[] | null;
  /** 是否发票抬头与委托方不一致 */
  isInvoiceHeaderNotMatchPrincipal?: string | null;
  /** 发票抬头Id */
  invoiceTitleId?: string | null;
  /** 发票抬头 */
  invoiceHeader?: string | null;
  /** 开票方类型 */
  invoiceIssuerType?: string | null;
  /** 纳税人识别号 */
  taxNumber?: string | null;
  /** 开户行 */
  bankName?: string | null;
  /** 银行账号 */
  bankAccount?: string | null;
  /** 联系电话 */
  landline?: string | null;
  /** 地址 */
  address?: string | null;
  /** 发票抬头说明附件 */
  invoiceHeaderAttachmentId?: string | null;
  /** 协议或补充协议附件 */
  agreementAttachmentId?: string | null;
  /** 其他附件 */
  otherAttachmentId?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 发票作废表 */
export interface CreateFinancialInvoiceInvalidateInput {
  /** 案件编号 */
  caseId?: string | null;
  /** 合伙人 */
  employeeId?: string | null;
  /** 客户编号 */
  clientId?: string | null;
  /** 账单编号 */
  billingId?: string | null;
  /** 发票申请编号 */
  invoiceApplyId?: string | null;
  /** 是否预开票 */
  isPreInvoice?: string | null;
  /** 发票编号 */
  invoiceNo?: string | null;
  /** 合同编号 */
  contractId?: string | null;
  /** 发票类型 */
  invoiceType?: string | null;
  /** 发票抬头 */
  invoiceHeader?: string | null;
  /** 是否发票抬头与委托方不一致 */
  isInvoiceHeaderNotMatchPrincipal?: string | null;
  /** 发票抬头说明附件 */
  invoiceHeaderAttachmentId?: string | null;
  /** 开票方类型 */
  invoiceIssuerType?: string | null;
  /** 纳税人识别号 */
  taxNumber?: string | null;
  /** 开户行 */
  bankName?: string | null;
  /** 银行账号 */
  bankAccount?: string | null;
  /** 联系电话 */
  landline?: string | null;
  /** 邮箱地址 */
  emailAddress?: string | null;
  /** 手机号码 */
  phoneNumber?: string | null;
  /** 地址 */
  address?: string | null;
  /** 企业注册地址 */
  registeredAddress?: string | null;
  /**
   * 开票日期
   * @format date-time
   */
  invoiceDate?: string | null;
  /** 币种 */
  currency?: string | null;
  /**
   * 币种汇率
   * @format double
   */
  exchangeRate?: number;
  /** 发票内容 */
  invoiceContent?: string | null;
  /**
   * 外币结算金额
   * @format double
   */
  settlementAmount?: number;
  /** 发票电子凭据 */
  invoicePicture?: string | null;
  /** 是否电子发票 */
  isElectronicInvoice?: string | null;
  /** 是否代收代付 */
  isPoco?: string | null;
  /** 代收代付名称 */
  pocoName?: string | null;
  /** 是否官费 */
  isOfficialFee?: string | null;
  /**
   * 税率
   * @format double
   */
  taxRate?: number;
  /**
   * 税额
   * @format double
   */
  taxAmount?: number;
  /**
   * 不含税金额
   * @format double
   */
  preTaxAmount?: number;
  /**
   * 开票金额
   * @format double
   */
  invoiceAmount?: number;
  /** 是否红字发票 */
  isRedPunch?: string | null;
  /** 红字发票编号 */
  redPunchInvoiceId?: string | null;
  /** 收款编号 */
  receiptId?: string | null;
  /**
   * 收款金额
   * @format double
   */
  receiptAmount?: number;
  /** 描述 */
  narrative?: string | null;
  /** 发票附件 */
  attachmentId?: string | null;
  /** 到账状态 */
  arrivedAmountState?: string | null;
  /** 作废原因 */
  reason?: string | null;
  /** 作废退回原因 */
  rejectReason?: string | null;
  /** 流水编号 */
  serialId?: string | null;
  /** 原系统主键编号 */
  originalId?: string | null;
  /** 流程数据大状态 */
  status?: string | null;
  /**
   * 提交时间
   * @format date-time
   */
  submittedOn?: string | null;
  /** 工作流状态 */
  flowState?: string | null;
  /** 申请人 */
  applicant?: string | null;
  /**
   * 工作流结束时间
   * @format date-time
   */
  flowEndDateTime?: string | null;
  /** 是否超期 */
  isOverdue?: boolean | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 付款方表 */
export interface CreateFinancialPayerInfoInput {
  /** 付款方名称 */
  name?: string | null;
  /** 外文名称 */
  foreignName?: string | null;
  /** 曾用名 */
  formerName?: string | null;
  /** 别名 */
  altName?: string | null;
  /** 证件类型 */
  creditType?: string | null;
  /** 证件号码 */
  creditCode?: string | null;
  /** 税号 */
  taxNumber?: string | null;
  /** 邮箱地址 */
  emailAddress?: string | null;
  /** 联系电话 */
  landline?: string | null;
  /** 所属行业 */
  industry?: string | null;
  /** 区域城市 */
  area?: string | null;
  /** 注册名称 */
  regName?: string | null;
  /** 注册号 */
  regNo?: string | null;
  /** 银行名称 */
  bankName?: string | null;
  /** 银行账号 */
  bankAccount?: string | null;
  /** 开户行 */
  openingBranchName?: string | null;
  /** 开户行地址 */
  openingBranchAddress?: string | null;
  /** 银行联系电话 */
  bankLandline?: string | null;
  /** 所属客户编号 */
  clientId?: string | null;
  /** 客户性质 */
  customerNature?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 通用返回信息类 */
export interface CreateFinancialPayerInfoInputMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 付款方表 */
  response?: CreateFinancialPayerInfoInput;
}

/** 公共收款表 */
export interface CreateFinancialPublicReceiptInput {
  /** Id */
  id?: string | null;
  /** 案件编号 */
  caseId?: string | null;
  /** 客户编号 */
  clientId?: string | null;
  /** 收款方式 */
  payMode?: string | null;
  /** 付款方Id */
  paymentId?: string | null;
  /** 付款方名称 */
  payerName?: string | null;
  /** 是否境外汇款 */
  isOverseasPay?: string | null;
  /** 汇款国家 */
  remittanceCountry?: string | null;
  /** 收款币种 */
  currency?: string | null;
  /** 支票编号 */
  checkNo?: string | null;
  /**
   * 收款总额
   * @format double
   */
  payAmount?: number;
  /**
   * 收款日期
   * @format date-time
   */
  receiptDate?: string | null;
  /** 结算币种 */
  settlementCurrency?: string | null;
  /**
   * 结算汇率
   * @format double
   */
  settlementRate?: number;
  /**
   * 结算金额
   * @format double
   */
  settlementAmount?: number;
  /**
   * 已认领收款金额
   * @format double
   */
  receiptAmount?: number;
  /** 是否全额认领 */
  isFullyClaimed?: string | null;
  /**
   * 认领尾差金额
   * @format double
   */
  roundingDifference?: number;
  /** 银行流水号 */
  bankSerialId?: string | null;
  /** 认领人 */
  claimEmployeeId?: string | null;
  /** 认领人名称 */
  claimEmployeeName?: string | null;
  /** 附件 */
  attachmentId?: string | null;
  /** 付款说明 */
  paymentExplain?: string | null;
  /** 认领收款编号 */
  receiptId?: string | null;
  /** 账单编号 */
  billingId?: string | null;
  /** 发票编号 */
  invoiceId?: string | null;
  /** 备注 */
  remark?: string | null;
  /** 办公室 */
  officeId?: string | null;
  /** 收款信息 */
  financialReceipts?: CreateFinancialReceiptInput[] | null;
  /** 收款账号Id */
  bankId?: string | null;
  /** 收款账号 */
  bankAccount?: string | null;
  /** 描述说明 */
  narrative?: string | null;
  /** 错误信息 */
  errorInfo?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 通用分页信息类 */
export interface CreateFinancialPublicReceiptInputPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: CreateFinancialPublicReceiptInput[] | null;
}

/** 通用返回信息类 */
export interface CreateFinancialPublicReceiptInputPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: CreateFinancialPublicReceiptInputPageModel;
}

/** 收款信息表 */
export interface CreateFinancialReceiptInput {
  /** 收款Id */
  id?: string | null;
  /** 案件编号 */
  caseId?: string | null;
  /** 客户编号 */
  clientId?: string | null;
  /** 公共收款编号 */
  publicReceiptId?: string | null;
  /** 收款方式 */
  payMode?: string | null;
  /** 付款方名称 */
  payerName?: string | null;
  /** 是否境外汇款 */
  isOverseasPay?: string | null;
  /** 汇款国家 */
  remittanceCountry?: string | null;
  /** 收款币种 */
  currency?: string | null;
  /** 支票编号 */
  checkNo?: string | null;
  /**
   * 收款总额
   * @format double
   */
  payAmount?: number;
  /**
   * 收款日期
   * @format date-time
   */
  receiptDate?: string | null;
  /** 结算币种 */
  settlementCurrency?: string | null;
  /**
   * 结算汇率
   * @format double
   */
  settlementRate?: number;
  /**
   * 结算金额
   * @format double
   */
  settlementAmount?: number;
  /** 银行流水号 */
  bankSerialId?: string | null;
  /** 认领人 */
  claimEmployeeId?: string | null;
  /** 附件 */
  attachmentId?: string | null;
  /** 付款说明 */
  paymentExplain?: string | null;
  /** 认领收款编号 */
  receiptId?: string | null;
  /** 账单编号 */
  billingId?: string | null;
  /** 发票编号 */
  invoiceId?: string | null;
  /** 是否分配 */
  isAllocated?: string | null;
  /**
   * 官费金额
   * @format double
   */
  officialFees?: number;
  /**
   * 律师费金额
   * @format double
   */
  lawyerFee?: number;
  /**
   * 支出费用
   * @format double
   */
  expenseFee?: number;
  /**
   * 尾差金额
   * @format double
   */
  roundingDifference?: number;
  /** 到账原因 */
  receiptReason?: string | null;
  /**
   * 认领金额
   * @format double
   */
  claimAmount?: number;
  /** 是否全额到账 */
  isFullyOffset?: string | null;
  /** 是否同步账单汇率 */
  isSyncBillingExchangeRate?: string | null;
  /**
   * 代收代付费
   * @format double
   */
  poboSubtotalAmount?: number;
  /**
   * 税率
   * @format double
   */
  taxRate?: number;
  /**
   * 税费
   * @format double
   */
  taxAmount?: number;
  /** 备注 */
  remark?: string | null;
  /**
   * 分配金额
   * @format double
   */
  allocatedAmount?: number;
  /** 案件分配信息 */
  caseDistributions?: CaseDistributionDto[] | null;
  /** 账单结算币种 */
  billingCurrency?: string | null;
  /**
   * 账单结算汇率
   * @format double
   */
  billingRate?: number;
  /**
   * 账单结算金额
   * @format double
   */
  billingAmount?: number;
  /** 错误信息 */
  errorInfo?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 税率 */
export interface CreateFinancialTaxRateInput {
  /**
   * 配置层级
   * @format int32
   */
  settingLevel?: 0 | 1 | 2 | 3;
  /** 税类型 */
  taxType?: string | null;
  /** 国家/地区 */
  region?: string | null;
  /** 币种 */
  currency?: string | null;
  /**
   * 税率
   * @format double
   */
  taxRate?: number;
  /** 是否账单 */
  isBilling?: boolean;
  /** 账单是否可编辑 */
  isBillingChange?: boolean;
  /** 备注 */
  remark?: string | null;
  /** 分所 */
  officeId?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

export interface CreateFlowActivityNodeInput {
  /** 节点id */
  nodeId?: string | null;
  /** 节点名称 */
  nodeName?: string | null;
  /** 流程编号 */
  flowDefineId?: string | null;
  /** 流程节点数据 */
  nodeData?: string | null;
  /** 当前工作流状态 */
  flowStateId?: string | null;
  /** 工作流退回节点状态 */
  flowReturnStateId?: string | null;
  /** 工作流关闭节点状态 */
  flowCloseStateId?: string | null;
  /** 流程步骤 */
  nodeStep?: string | null;
  /** 是否允许申请人审批 */
  isApplicantAudit?: boolean;
  /** 是否逐人审批 只有在会签节点时有效 */
  examinationApproval?: boolean;
  /** 节点类型 */
  nodeType?: string | null;
  /** 是否是客户执行节点 */
  isGuestNode?: boolean | null;
  /** 节点时限 */
  isNodeTimelimit?: boolean | null;
  /** 节点时限规则 */
  nodeTimelimitRule?: string | null;
  /**
   * 节点时限规则
   * @format int32
   */
  nodeTimelimitType?: 0 | 1 | 2 | null;
  /**
   * 紧急级别
   * @format int32
   */
  urgentLevel?: number | null;
  /** 是否会签节点 */
  isCounterSigned?: boolean | null;
  /**
   * 会签通过率
   * @format int32
   */
  counterSignedPassRate?: number | null;
  /**
   * 会签选择类型
   * @format int32
   */
  counterSignedType?: 2 | 3 | null;
  /** 是否允许审批驳回 */
  isRollback?: boolean | null;
  /**
   * 退回规则;默认退回一步，前置节点都可退回，指定节点可退回
   * @format int32
   */
  rollbackRule?: 1 | 2 | 3 | -1 | null;
  /** 驳回节点id */
  rollbackToNodeId?: string | null;
  /** 经办人按流经节点设置 复制上个节点参数设置;经办人按流经节点设置 复制上个节点参数设置 */
  actorBypPrevNodeId?: string | null;
  /** 是否开启跳转到节点;是否开启跳转到节点 */
  isSendToNode?: boolean | null;
  /** 跳转到指定节点;跳转到指定节点 */
  sendToNode?: string | null;
  /** 是否允许撤回;是否允许撤回 */
  isWithdraw?: boolean | null;
  /** 是否允许转交;是否允许转交 */
  isTransmitted?: boolean | null;
  /** 是否允许抄送;是否允许抄送 */
  isCC?: boolean | null;
  /** 通用参数 */
  paylodParams?: string | null;
  /** 审核默认意见 */
  approvalOpinion?: string | null;
  /** 审批意见开关 */
  isApprovalOpinion?: boolean | null;
  /** 是否提醒发送用户 */
  isRemindUser?: boolean | null;
  /** 提醒角色id */
  remindByPositionIds?: string | null;
  /** 提醒类型 */
  remindType?: string | null;
  /** 是否紧急 */
  isUrgent?: boolean | null;
  /** 是否结束流程 */
  isEndFlow?: boolean | null;
  /** 是否暂存 */
  isStaging?: boolean | null;
  /** 是否发送 */
  isSend?: boolean | null;
  /** 是否加签 */
  isCountersignUser?: boolean | null;
  /** 是否节点控制 */
  isNodeControl?: boolean | null;
  /** 允许退回直达审批 */
  isRollbackToApproval?: boolean | null;
  /** 是否允许可编辑 */
  isAllowEdit?: boolean | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 流程条件 */
export interface CreateFlowConditionInput {
  /** 流程控制主键id */
  flowControlId?: string | null;
  /**
   * 条件类型;0方向条件、1流程完成条件、2节点完成条件
   * @format int32
   */
  condType?: 0 | 1 | 2;
  /**
   * 条件数据源;0表单数据 2、sql计算
   * @format int32
   */
  dataFrom?: 0 | 1 | 3 | 4;
  /** 条件数据源文本 */
  dataFromText?: string | null;
  /** 属性ID;属性主键 如果存在 */
  attributeId?: string | null;
  /** 属性字段;如表单属性字段 Title、DeptName等 */
  fieldName?: string | null;
  /** 属性名称;属性中文名称 */
  fieldDescription?: string | null;
  /**
   * 运算符号;如> >!= 包含等等
   * @format int32
   */
  conditionalType?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | null;
  conditionalTypeAlias?: string | null;
  /** 要运算的值;具体值 如Emp = zhangsan ，123 */
  fieldValue?: string | null;
  modelValue?: string | null;
  /** 通用参数 */
  payloadParams?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 创建流程控制 */
export interface CreateFlowControlInput {
  /** 流程编号 */
  flowDefineId?: string | null;
  /** 节点id */
  nodeId?: string | null;
  /** 节点名称 */
  fromNodeId?: string | null;
  /** 目标节点id */
  toNodeId?: string | null;
  /**
   * 控制类型0 连接线 1 泳道图
   * @format int32
   */
  laneType?: 0 | 1 | 2;
  /** 节点关系/泳道图 数据 */
  laneData?: string | null;
  /** @format int32 */
  condControlType?: 0 | 1 | null;
  /** @format int32 */
  condFlowingType?: 0 | 1 | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

export interface CreateFlowDefineInput {
  /** 名称;名称 */
  name?: string | null;
  /** 业务分类;业务类型分类 */
  buinessType?: string | null;
  /**
   * 版本号;流程版本
   * @format int32
   */
  version?: number;
  /** 模型名称;模型名称 */
  modelName?: string | null;
  /** 模型值 */
  modelValue?: string | null;
  /** 是否允许在运行时编辑 */
  isAllowEdit?: boolean | null;
  /** 允许流程发起人催办 */
  isAllowRemind?: boolean | null;
  /** 提醒类型字符串分割 */
  remindType?: string | null;
  /** 是否开启自动提交 */
  isAutoCommit?: boolean | null;
  /**
   * 自动提交选项
   * @format int32
   */
  autoCommitType?: 2 | 3 | 4 | null;
  /** 是否允许撤回 */
  isAllowWithdraw?: boolean | null;
  /**
   * 撤回类型
   * @format int32
   */
  withdrawType?: 2 | 3 | 4 | null;
  /** 作用域 */
  isScope?: boolean | null;
  additionalInformation?: FlowAdditionalInformationDto;
  /** 备注 */
  remark?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

export interface CreateFlowInstanceNodeUsersInput {
  /** 实例id */
  flowInstanceId?: string | null;
  /** 节点主键id */
  flowNodeId?: string | null;
  /** 用户 */
  approvers?: Approver[] | null;
}

/** 外汇汇率表 */
export interface CreateForeignExchangeRatesInput {
  /** 本位币 */
  base?: string | null;
  /** 目标币种 */
  target?: string | null;
  /**
   * 汇率
   * @format double
   */
  rate?: number;
  /**
   * 反算汇率
   * @format double
   */
  inverseRate?: number;
  /**
   * 汇率小数位精度
   * @format int32
   */
  scale?: number;
  /**
   * 尾差阈值
   * @format double
   */
  tailDifferenceThreshold?: number;
  /** 汇率列表 */
  rates?: string | null;
  /** 汇率列表 */
  ratesList?: Record<string, number>;
  /**
   * 更新时间
   * @format date-time
   */
  updateTime?: string | null;
  /** 请求地址 */
  url?: string | null;
  /** 是否默认 */
  isDefault?: boolean;
  /**
   * 生效日期
   * @format date-time
   */
  effectiveDate?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 通用返回信息类 */
export interface CreateForeignExchangeRatesInputMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 外汇汇率表 */
  response?: CreateForeignExchangeRatesInput;
}

export interface CreateFormFieldAttributesInput {
  /** 显示名 */
  displayName?: string | null;
  /** 是否显示 */
  isDisplay?: boolean;
  /**
   * 字段表单类型
   * @format int32
   */
  formInputType?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20;
  /** 默认数据集 */
  defaultValue?: Record<string, any>;
  /** 前缀附件Id */
  startIconId?: string | null;
  /** 前缀文本 */
  startText?: string | null;
  /** 后缀附件Id */
  endIconId?: string | null;
  /** 后缀文本 */
  endText?: string | null;
  /**
   * 字间距
   * @format double
   */
  spacing?: number;
  /** 是否草稿必填 */
  isDraftRequired?: boolean;
  /**
   * 行间距
   * @format double
   */
  lineSpacing?: number;
  /**
   * 对齐方式
   * @format int32
   */
  alignment?: 0 | 1 | 2 | 3;
  /**
   * 标签宽度
   * @format int32
   */
  labelWidth?: 0 | 1 | 2;
  /**
   * 字段大小
   * @format int32
   */
  fieldSize?: 0 | 1 | 2;
  /** 提示语 */
  reminder?: string | null;
  /** 提示文字 */
  placeholder?: string | null;
  /** 帮助说明 */
  helpExplanation?: string | null;
  /**
   * 字段宽度
   * @format int32
   */
  fieldWidth?: 0 | 1 | 2 | 3 | 4 | 5;
  /** 千分位分隔符 */
  isThousandthSeparator?: boolean;
  /** 百分位 */
  isPercentile?: boolean;
  /** 正常颜色 */
  normalError?: string | null;
  /** 错误颜色 */
  errorColor?: string | null;
  /** 保留小数 */
  isReservedDecimal?: boolean;
  /** 负值标红 */
  isNegativeRed?: boolean;
  /** 数值调节按钮 */
  isNumericalAdjustment?: boolean;
  /** 科学计数法 */
  isScientificNotation?: boolean;
  /**
   * 长度
   * @format int32
   */
  length?: number;
  /**
   * 小数位置
   * @format int32
   */
  decimalLocation?: number;
  /**
   * 零值显示
   * @format int32
   */
  valueNullType?: 0 | 1 | 2;
  /** 是否必填 */
  isRequired?: boolean;
  /** 是否只读 */
  isRead?: boolean;
  /** 是否清除 */
  isClean?: boolean;
  /** 显示分钟 */
  isShowMinute?: boolean;
  /** 显示分钟 */
  isSecond?: boolean;
  /** Json配置集 */
  json?: Record<string, any>;
  /** 是否是模板列 */
  isImportTemplateField?: boolean;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

export interface CreateFormValidateBatchInput {
  /** 是否覆盖子集配置  默认false */
  isCover?: boolean;
  /**
   * 配置层级
   * @format int32
   */
  settingLevel?: 0 | 1 | 2 | 3;
  /** Dto名称 */
  dtoName?: string | null;
  /** 租户Id 应用不传 */
  tenantId?: string | null;
  /** 分所Id 租户不传 */
  officeId?: string | null;
  /** 部门Id */
  organizationUnitId?: string | null;
  /** 配置规则 */
  formValidateList?: CreateFromValidateInput[] | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

export interface CreateFromValidateInput {
  /** 字段 */
  field?: string | null;
  /** 字段类型 */
  fieldType?: string | null;
  /** 验证规则参数 */
  formValidateRuleList?: CreateFromValidateRuleInput[] | null;
  formFieldAttributes?: CreateFormFieldAttributesInput;
}

export interface CreateFromValidateRuleInput {
  /**
   * 验证规则
   * @format int32
   */
  ruleType?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20;
  /**
   * 弹窗规则
   * @format int32
   */
  alertRule?: 0 | 1 | 2;
  /** 错误信息 */
  errorMessage?: string | null;
  /** 值 */
  value?: string | null;
  /** 是否是Dto字段 */
  isDtoField?: boolean;
  /**
   * 最小长度
   * @format int32
   */
  minLength?: number;
  /**
   * 最大长度
   * @format int32
   */
  maxLength?: number;
  /**
   * 开始值
   * @format int32
   */
  start?: number;
  /**
   * 结束值
   * @format int32
   */
  end?: number;
  /**
   * 验证货币相关
   * @format int32
   */
  number?: number;
  /**
   * 验证货币相关
   * @format int32
   */
  point?: number;
  /** 验证货币相关 */
  ignoreTrailingZeros?: boolean;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

export interface CreateGroupClientRelshipInput {
  /** 集团编号 */
  groupId?: string | null;
  /** 客户编号 */
  clientId?: string | null;
  /** 是否删除客户所属集团 */
  isDeleteGroup?: boolean;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

export interface CreateGroupInfoInput {
  /** Id */
  id?: string | null;
  /** 客户名称 */
  name?: string | null;
  /** 外文名称 */
  foreignName?: string | null;
  /** 曾用名 */
  formerName?: string | null;
  /** 别名 */
  altName?: string | null;
  /** 客户类型 */
  clientType?: string | null;
  /** 证件类型 */
  creditType?: string | null;
  /** 证件号码 */
  creditCode?: string | null;
  /** 来源类型（立案/入库/投标） */
  originType?: string | null;
  /** 来源编号 */
  originId?: string | null;
  /** 通讯地址 */
  address?: string | null;
  /** 税号 */
  taxNumber?: string | null;
  /** 邮箱地址 */
  emailAddress?: string | null;
  /** 手机号码 */
  phoneNumber?: string | null;
  /** 固定电话 */
  landline?: string | null;
  /** 描述说明 */
  narrative?: string | null;
  /** 客户级别（星标客户/重要客户等） */
  clientLevel?: string | null;
  /** 客户性质 */
  customerNature?: string | null;
  /** 股东/实控人存入EntityInfo */
  entityInfosInput?: CreateEntityInfoInput[] | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

export interface CreateLanguageInput {
  /** 图标 */
  icon?: string | null;
  /** 名称 */
  name?: string | null;
  /** 代码 */
  code?: string | null;
  /** 显示 */
  displayName?: string | null;
  /** 租户 */
  tenantId?: string | null;
  /** 组织机构 */
  organizationUnitId?: string | null;
  /** 分所 */
  officeId?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 通用返回信息类 */
export interface CreateLanguageInputMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  response?: CreateLanguageInput;
}

export interface CreateLanguageKeyInput {
  /** Key */
  key?: string | null;
  /** 模块 */
  module?: string | null;
  /**
   * 平台
   * @format int32
   */
  source?: 0 | 1 | 2;
  /** 多语言 */
  languageTexts?: CreateOrUpdateLanguageTextInput[] | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

export interface CreateOrUpdateLanguageTextInput {
  /** 语言代码 */
  code?: string | null;
  /** 显示 */
  displayName?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

export interface CreateOrganizationUnitLeadersInput {
  userId?: string | null;
  isHost?: boolean;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 创建组织机构 */
export interface CreateOrganizationUnitsInput {
  /** 机构键值 */
  code?: string | null;
  /** 显示名称 */
  displayName?: string | null;
  /** 别名 */
  alterName?: string | null;
  /** 地区代码 */
  regionCode?: string | null;
  /** 负责人 */
  organizationUnitLeaders?: CreateOrganizationUnitLeadersInput[] | null;
  /** 父级Id */
  parentId?: string | null;
  /** 机构描述 */
  remark?: string | null;
  /** 工作地点 */
  workAddress?: string | null;
  /** 区别标识 */
  discriminator?: string | null;
  /**
   * 默认排序
   * @format int32
   */
  sort?: number;
  /** 是否启用 */
  isActive?: boolean;
  /** 是否虚拟机构 */
  isVirtual?: boolean;
  /** 是否总所 */
  isHeadOffice?: boolean;
  /**
   * 阈值标识
   * @format int32
   */
  thresholdFlag?: 0 | 1 | 2 | 3;
  /** 地理位置 */
  location?: string | null;
  /** 创建企业信息 */
  enterpriseInfo?: CreateEnterpriseInfoInput;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

export interface CreatePayerInfoInput {
  /** Id */
  id?: string | null;
  /** 客户名称 */
  name?: string | null;
  /** 外文名称 */
  foreignName?: string | null;
  /** 曾用名 */
  formerName?: string | null;
  /** 别名 */
  altName?: string | null;
  /** 账户 */
  account?: string | null;
  /** 开户行 */
  openingBank?: string | null;
  /** 客户类型 */
  clientType?: string | null;
  /** 客户行业 */
  clientIndustryId?: string | null;
  /** 证件类型 */
  creditType?: string | null;
  /** 证件号码 */
  creditCode?: string | null;
  /** 通讯地址 */
  address?: string | null;
  /** 税号 */
  taxNumber?: string | null;
  /** 邮箱地址 */
  emailAddress?: string | null;
  /** 手机号码 */
  phoneNumber?: string | null;
  /** 固定电话 */
  landline?: string | null;
  /** 描述说明 */
  narrative?: string | null;
  /** 母公司名称 */
  parentCompanyName?: string | null;
  /** 母公司外文名称 */
  parentCompanyForeignName?: string | null;
  /** 母公司证件类型 */
  parentCompanyCreditType?: string | null;
  /** 母公司证件号码 */
  parentCompanyCreditCode?: string | null;
  /** 客户性质 */
  customerNature?: string | null;
  /** 所属客户 */
  clientId?: string | null;
  /** 客户流水号 */
  clientSerialId?: string | null;
  /** 客户名称 */
  clientName?: string | null;
  /** 发票抬头 */
  entityInvoiceTitles?: CreateEntityInvoiceTitleInput[] | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 表格数据，支持分页 */
export interface CreatePayerInfoInputForEditModel {
  data?: CreatePayerInfoInput;
  /** 实体映射 */
  mapping?: Record<string, any>;
}

/** 通用返回信息类 */
export interface CreatePayerInfoInputForEditModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 表格数据，支持分页 */
  response?: CreatePayerInfoInputForEditModel;
}

export interface CreateRoleInput {
  /** 角色名 */
  name?: string | null;
  /** 显示名称 */
  alterName?: string | null;
  /** 描述 */
  description?: string | null;
  /**
   * 排序
   * @format int32
   */
  sort?: number;
  /**
   * 权限类型
   * @format int32
   */
  roleType?: 0 | 1 | 2 | 3;
  /** 分所Id */
  officeId?: string | null;
  /** 复制的角色Id */
  copyRoleId?: string | null;
  /** 用户组Id */
  roleGroupId?: string | null;
  /** 是否独占 */
  isMonopoly?: boolean | null;
  /** 是否启用 */
  isActive?: boolean;
  /** 是否默认角色 */
  isDefault?: boolean;
  /** 是否虚拟角色 */
  isVirtual?: boolean;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

export interface CreateSequenceNumberRulesInput {
  /** 编码名称 */
  name?: string | null;
  /** 所属表单 */
  tableName?: string | null;
  /** 编码字段 */
  field?: string | null;
  /** 段间分隔符 */
  separator?: string | null;
  /**
   * 应用规则
   * @format int32
   */
  applyRulesType?: 0 | 1;
  /** 编码依据变化时重新编码 */
  isChange?: boolean;
  /** 允许断号补偿 */
  isCompensate?: boolean;
  /**
   * 补位衬垫位置
   * @format int32
   */
  paddingSideType?: 0 | 1;
  /**
   * 起始值时间
   * @format date-time
   */
  startTime?: string;
  /** 数据集合 */
  dataset?: string | null;
  /** 流水号单据编码配置 */
  sequenceNumberSettings?: SequenceNumberSettingsDto[] | null;
  /** 是否激活 */
  isActive?: boolean;
  /** 流水号预览 */
  sequenceNumberPreview?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 通用返回信息类 */
export interface CreateSequenceNumberRulesInputMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  response?: CreateSequenceNumberRulesInput;
}

export interface CreateSettingInput {
  /** 值名称 */
  name?: string | null;
  /**
   * 配置
   * @format int32
   */
  settingScopes?: 1 | 2 | 4;
  /** 值 , json */
  value?: string | null;
  /** 用户Id */
  userId?: string | null;
  /** 租户Id */
  tenantId?: string | null;
  /** 是否覆盖子集配置  默认false */
  isCover?: boolean;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

export interface CreateSupplierInput {
  /** 供应商类型 */
  supplierTypes?: (0 | 1 | 2 | 3)[] | null;
  /** 名称 */
  name?: string | null;
  /** 联系人 */
  contact?: string | null;
  /** 联系电话 */
  telephone?: string | null;
  /** 联系地址 */
  address?: string | null;
  /** 邮箱 */
  email?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

export interface CreateSupplierPluginInput {
  pluginName?: string | null;
  pluginType?: string | null;
  supplierId?: string | null;
  ico?: string | null;
  status?: boolean;
  remark?: string | null;
  fieldsJson?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 国家地区表 */
export interface CreateSysCountryInput {
  /** 中文名称 */
  cnName?: string | null;
  /** 名称 */
  name?: string | null;
  alpha2?: string | null;
  /** 数据字典Code */
  alpha3?: string | null;
  /** 地区编号 */
  countryCode?: string | null;
  iso_3166_2?: string | null;
  region?: string | null;
  subRegion?: string | null;
  intermediateRegion?: string | null;
  regionCode?: string | null;
  subRegionCode?: string | null;
  intermediateRegionCode?: string | null;
  /** 国旗 */
  nationalFlag?: string | null;
  /** Emoji */
  emoji?: string | null;
  /** 币种 */
  currency?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 文档中心 */
export interface CreateSysDocumentCenterInput {
  /** 名称 */
  name?: string | null;
  /** 全名 */
  fullName?: string | null;
  /** 父级Id */
  parentId?: string | null;
  /** 递归路径Id */
  fullPathId?: string | null;
  /** 递归路径Name */
  fullPathName?: string | null;
  /** 级别 */
  level?: string | null;
  /** 业务Id */
  businessId?: string | null;
  /** 业务名称 */
  businessName?: string | null;
  /** 拓展名 */
  extension?: string | null;
  /** 附件Id */
  attachmentId?: string | null;
  /** 哈希值 */
  hashValue?: string | null;
  /**
   * 大小
   * @format int64
   */
  size?: number;
  /** 是否读取 */
  isWrite?: string | null;
  /** 是否写入 */
  isDownload?: string | null;
  /** 是否删除 */
  isDelete?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

export interface CreateSysDocumentCenterListInput {
  /** 上传路径 */
  uploadPath?: string | null;
  /** 文档中心路径 */
  documentPath?: string | null;
  /** 文件列表 */
  fileItems?: GetSysDocumentCenterOutput[] | null;
}

export interface CreateSysEmailTemplatesInput {
  /** 模版名称 名字唯一 */
  templateName?: string | null;
  /**
   * 配置层级
   * @format int32
   */
  settingLevel?: 0 | 1 | 2 | 3;
  /** 模版正文 */
  templateContent?: string | null;
  /** 模版描述 */
  templateSubject?: string | null;
  /**
   * 授权失效时间 分钟
   * @format int32
   */
  authFailureTime?: number;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 雇员表彰和奖励 */
export interface CreateSysEmployeeAwardsInput {
  /**
   * 入职日期
   * @format date-time
   */
  entryDate?: string | null;
  /** 类别 */
  type?: string | null;
  /**
   * 最后工作日
   * @format date-time
   */
  lastWorkDate?: string | null;
  /** 描述 */
  discription?: string | null;
  /** 奖项 */
  awardName?: string | null;
  /** 工号 */
  jobNumber?: string | null;
  /**
   * 奖励级别
   * @format int32
   */
  level?: number | null;
  /**
   * 获奖日期
   * @format date-time
   */
  date?: string | null;
  /** 雇员Id */
  userId?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 雇员证书执照 */
export interface CreateSysEmployeeCertificatesInput {
  /** 证书名称 */
  name?: string | null;
  /** 证书描述 */
  discription?: string | null;
  /**
   * 获得时间
   * @format date-time
   */
  gettime?: string | null;
  /** 学习证书Id */
  learningCertificateId?: string | null;
  /**
   * 有效期至
   * @format date-time
   */
  validuntil?: string | null;
  /**
   * 入职日期
   * @format date-time
   */
  entryDate?: string | null;
  /** 证书编号 */
  certificateNo?: string | null;
  /** 发证机构 */
  issauthority?: string | null;
  /** 工号 */
  jobNumber?: string | null;
  /** 审批状态 */
  approveStatus?: string | null;
  /**
   * 最后工作日
   * @format date-time
   */
  lastWorkDate?: string | null;
  /**
   * 生效日期
   * @format date-time
   */
  effectiveDate?: string | null;
  /** 证书照类型 */
  certificateType?: string | null;
  /** 雇员Id */
  userId?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 雇员教育经历 */
export interface CreateSysEmployeeEducationsInput {
  /** 学历 */
  educationLevel?: string | null;
  /** 工号 */
  jobNumber?: string | null;
  /** 培养方式 */
  trainingMode?: string | null;
  /** 工作情况 */
  performance?: string | null;
  /** 备注 */
  comments?: string | null;
  /** 学制 */
  schoolSystme?: string | null;
  /** 专业类别 */
  specializedfield?: string | null;
  /** 专业授予机构 */
  grantingInstitution?: string | null;
  /** 专业 */
  major?: string | null;
  /** 毕业学校 */
  schoolCode?: string | null;
  /** 班级排名 */
  classRanking?: string | null;
  /** 学校名繁体 */
  school_zh_TW?: string | null;
  /** 学位授予国家 */
  degreeCountry?: string | null;
  /** 专业描述 */
  mainCourse?: string | null;
  /** 专业描述 */
  majorDescription?: string | null;
  /** 审批状态 */
  approveStatus?: string | null;
  /** 学位 */
  degree?: string | null;
  /** 所在城市 */
  location?: string | null;
  /** 毕业学校名称 */
  school?: string | null;
  /** 见证人 */
  prover?: string | null;
  /** 是否主专业 */
  isFirstMajor?: boolean | null;
  /** 是否最高学历 */
  isHighestEducation?: boolean | null;
  /** 是否最高学位 */
  isHighestDegree?: boolean | null;
  /** 是否第一学历 */
  isFirstEducationLevel?: boolean | null;
  /**
   * 学习形式
   * @format int32
   */
  studyMode?: number | null;
  /**
   * 毕业类型
   * @format int32
   */
  graduationType?: number | null;
  /**
   * 入职日期
   * @format date-time
   */
  entryDate?: string | null;
  /**
   * 结束日期
   * @format date-time
   */
  enddatE?: string | null;
  /**
   * 最后工作日
   * @format date-time
   */
  lastWorkDate?: string | null;
  /** 毕业学校名称_英文 */
  school_en_US?: string | null;
  /** 雇员Id */
  userId?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 材料管理 */
export interface CreateSysEmployeeEntryMaterialRecInput {
  /** 材料名称 */
  materialName?: string | null;
  /** 所属单元 */
  onwerMou?: string | null;
  /**
   * 催办时间
   * @format date-time
   */
  promotTime?: string | null;
  /** 是否法定 */
  isLegal?: boolean | null;
  /** 是否收到 */
  hasReceived?: boolean | null;
  /** 退回备注 */
  giveBackRemark?: string | null;
  /** 材料名称(文本) */
  name?: string | null;
  /**
   * 提交日期
   * @format date-time
   */
  submitDate?: string | null;
  /** 组织排序号 */
  organizationOrderNum?: string | null;
  /** 工号 */
  jobNumber?: string | null;
  /** 材料说明 */
  remark?: string | null;
  /** 材料状态 */
  materialState?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createdTime?: string | null;
  /** 准备阶段 */
  preparationPhase?: string | null;
  /**
   * 接收日期
   * @format date-time
   */
  receiveDate?: string | null;
  /**
   * 退回日期
   * @format date-time
   */
  giveBackDate?: string | null;
  /** 未提交备注 */
  notSubmittedRemarks?: string | null;
  /** 附件 */
  materialAttachment?: string | null;
  /**
   * 入职日期
   * @format date-time
   */
  entryDate?: string | null;
  /** 处理备注 */
  handleRemark?: string | null;
  /** 是否提交 */
  hasSubmitted?: boolean | null;
  /**
   * 预计提交日期
   * @format date-time
   */
  prepareSubmitDate?: string | null;
  /** 是否必备 */
  isEssential?: boolean | null;
  /**
   * 所属单元层级
   * @format int32
   */
  onwerMou_TreeLevel?: number | null;
  /**
   * 催办人
   * @format int32
   */
  promoter?: number | null;
  /** 所属单元路径 */
  onwerMou_TreePath?: string | null;
  /** 材料图片缩略图 */
  materialImageThumbnail?: string | null;
  /**
   * 最后工作日
   * @format date-time
   */
  lastWorkDate?: string | null;
  /** 雇员Id */
  userId?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 雇员家庭信息 */
export interface CreateSysEmployeeFamilyInput {
  /**
   * 性别
   * @format int32
   */
  gender?: number | null;
  /** 组织排序号 */
  organizationOrderNum?: string | null;
  /** 与本人关系 */
  relationType?: string | null;
  /** 职级排序号 */
  jobLevelOrderNum?: string | null;
  /**
   * 业务修改人
   * @format int32
   */
  businessModifiedBy?: number | null;
  /**
   * 出生日期
   * @format date-time
   */
  birthday?: string | null;
  /**
   * 最后工作日
   * @format date-time
   */
  lastWorkDate?: string | null;
  /** 审批状态 */
  approveStatus?: string | null;
  /** 邮件 */
  email?: string | null;
  /** 工号 */
  jobNumber?: string | null;
  /** 职务 */
  jobPost?: string | null;
  /**
   * 入职日期
   * @format date-time
   */
  entryDate?: string | null;
  /** 政治面貌 */
  politicalStatus?: string | null;
  /** 手机号码 */
  mobile?: string | null;
  /** 与本人关系名称 */
  relation?: string | null;
  /** 联系电话 */
  telephone?: string | null;
  /** 成员姓名 */
  name?: string | null;
  /** 工作单位 */
  company?: string | null;
  /** 雇员Id */
  userId?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 雇员信息表 */
export interface CreateSysEmployeeInfosInput {
  /** 显示名称 */
  displayName?: string | null;
  /**
   * 入职时间
   * @format date-time
   */
  entryTime?: string | null;
  /** 别名 */
  alterName?: string | null;
  /** 手机号 */
  phoneNumber?: string | null;
  /** 描述 */
  description?: string | null;
  address?: string | null;
  /** 邮箱 */
  emailAddress?: string | null;
  /** @format int32 */
  sex?: number | null;
  /**
   * 生日
   * @format date-time
   */
  birthday?: string | null;
  /** @format int32 */
  age?: number | null;
  /** 教育背景 */
  educationBackground?: string | null;
  /** 工号 */
  jobNumber?: string | null;
  /** Id卡类型 */
  idCardType?: string | null;
  /** 唯一标识号码 */
  idCardNumber?: string | null;
  /**
   * 毕业时间
   * @format date-time
   */
  graduateDate?: string | null;
  /** 婚姻状态 */
  maritalStatus?: string | null;
  /** 用户类型 */
  userType?: string | null;
  /** @format int32 */
  origin?: number | null;
  /** 外部标识 */
  originalId?: string | null;
  /** 户籍所在地 */
  householdRegister?: string | null;
  /** 民族 */
  nation?: string | null;
  /** 政治面貌 */
  politicalOutlook?: string | null;
  /** 籍贯 */
  nativePlace?: string | null;
  /** 兴趣爱好 */
  hobbies?: string | null;
  /** 特长 */
  speciality?: string | null;
  /**
   * 参加工作时间
   * @format date-time
   */
  beganWorkDate?: string | null;
  /** 毕业学校 */
  graduateFrom?: string | null;
  /** 专业 */
  major?: string | null;
  /** 工作地址 */
  officeLocation?: string | null;
  /** 工作电话 */
  workPhone?: string | null;
  /** 传真 */
  fax?: string | null;
  /** 座机 */
  landline?: string | null;
  /** 头像地址 */
  pictureUrl?: string | null;
  /** 关于我 */
  aboutMe?: string | null;
  /** 最高学历 */
  highestEducation?: string | null;
  /** 邮政编码 */
  postalCode?: string | null;
  /**
   * 对外费率
   * @format double
   */
  externalRate?: number | null;
  /**
   * 内部费率
   * @format double
   */
  internalRate?: number | null;
  /** 币种 */
  currency?: string | null;
  /** 擅长语言 */
  goodLanguage?: string | null;
  /** 擅长专业 */
  goodProfessional?: string | null;
  /** 原执业律师事务所 */
  formerLawyer?: string | null;
  /** 执业证号 */
  formerLawyerNumber?: string | null;
  /** 紧急联系人 */
  emergencyContact?: string | null;
  /** 与其关系 */
  relationship?: string | null;
  /** 紧急联系人电话 */
  emergencyContactPhone?: string | null;
  /** 紧急联系人地址 */
  emergencyContactAddress?: string | null;
  /** 律师关系存放地 */
  localRelationshipStorage?: string | null;
  /**
   * 律师关系存放日期
   * @format date-time
   */
  localRelationshipTime?: string | null;
  /** 人事档案存放地 */
  personnelArchivesStorageAgency?: string | null;
  /** 存档号 */
  archiveNumber?: string | null;
  /**
   * 存档日期
   * @format date-time
   */
  archiveTime?: string | null;
  /** 雇员Id */
  userId?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 雇员工作经历 */
export interface CreateSysEmployeeJobHistoryInput {
  /** 审批状态 */
  approveStatus?: string | null;
  /** 部门_繁体 */
  department_zh_TW?: string | null;
  /** 单位名称 */
  company?: string | null;
  /** 工作业绩 */
  achievement?: string | null;
  /**
   * 月薪（税前）
   * @format double
   */
  salary?: number | null;
  /**
   * 离职日期
   * @format date-time
   */
  dimissionDate?: string | null;
  /** 职务_英文 */
  firstJob_en_US?: string | null;
  /** 工作类型 */
  jobType?: string | null;
  /** 企业规模 */
  companySize?: string | null;
  /** 工号 */
  jobNumber?: string | null;
  /** 与本人关系 */
  proverRelation?: string | null;
  /** 部门_英文 */
  department_en_US?: string | null;
  /** 证明人联系方式 */
  proverContactInfo?: string | null;
  /**
   * 下属人数
   * @format int32
   */
  underlingNum?: number | null;
  /** 部门 */
  department?: string | null;
  /** 证明人 */
  proverName?: string | null;
  /** 行业类别 */
  hangye?: string | null;
  /** 单位介绍 */
  introduction?: string | null;
  /** 职务_繁体 */
  firstJob_zh_TW?: string | null;
  /** 工作性质 */
  workKind?: string | null;
  /** 单位类型 */
  companyType?: string | null;
  /**
   * 开始日期
   * @format date-time
   */
  startDate?: string | null;
  /** 职务 */
  firstJob?: string | null;
  /** 单位名称_繁体 */
  company_zh_TW?: string | null;
  /** 职级名称 */
  jobLevel?: string | null;
  /** 职级名称_繁体 */
  jobLevel_zh_TW?: string | null;
  /** 是否本单位工作经历 */
  whetherTheWorkExperienceOfTheUnit?: boolean | null;
  /** 职级名称_英文 */
  jobLevel_en_US?: string | null;
  /** 职位_繁体 */
  lastDog_zh_TW?: string | null;
  /** 离职原因 */
  leaveReason?: string | null;
  /**
   * 入职日期
   * @format date-time
   */
  beginDate?: string | null;
  /**
   * 入职日期
   * @format date-time
   */
  entryDate?: string | null;
  /**
   * 结束日期
   * @format date-time
   */
  stopDate?: string | null;
  /** 任职类型 */
  serviceType?: string | null;
  /** 职位 */
  lastJog?: string | null;
  /** 部门全称 */
  poiOldAdminNameTreePath?: string | null;
  /** 职位英文 */
  lastJog_en_US?: string | null;
  /** 工作地址 */
  address?: string | null;
  /**
   * 最后工作日
   * @format date-time
   */
  lastWorkDate?: string | null;
  /** 证明人职务 */
  proverJobPost?: string | null;
  /** 工作职责 */
  responsibility?: string | null;
  /** 雇员Id */
  userId?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 语言能力 */
export interface CreateSysEmployeeLanguageAbilitiesInput {
  /** 语言 */
  language?: string | null;
  /** 技能描述 */
  languageskill?: string | null;
  /** 审批状态 */
  approveStatus?: string | null;
  /** 掌握程度 */
  level?: string | null;
  /** 书写能力 */
  writingAbility?: string | null;
  /** 工号 */
  jobNumber?: string | null;
  /**
   * 最后工作日
   * @format date-time
   */
  lastWorkDate?: string | null;
  /** 是否母语 */
  isNative?: boolean | null;
  /**
   * 入职日期
   * @format date-time
   */
  entryDate?: string | null;
  /** 阅读能力 */
  readingAbility?: string | null;
  /** 口语能力 */
  speakingAbility?: string | null;
  /** 雇员Id */
  userId?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 雇员专业技术职务 */
export interface CreateSysEmployeeProfessionalTechnicalPostInfoInput {
  /** 工号 */
  jobNumber?: string | null;
  /**
   * 组织_层级
   * @format int32
   */
  stdOrganization_TreeLevel?: number | null;
  /** 资格评审单位 */
  qualificationEvaluationUnit?: string | null;
  /** 备注 */
  remarks?: string | null;
  /** 共享人 */
  permissionBy?: string | null;
  /** 是否最高级别 */
  isTopLevel?: boolean | null;
  /**
   * 组织
   * @format int32
   */
  stdOrganization?: number | null;
  /**
   * 聘任起始时间
   * @format date-time
   */
  appointStartDate?: string | null;
  /**
   * 评定日期
   * @format date-time
   */
  assessmentDate?: string | null;
  /**
   * 所属单位层级
   * @format int32
   */
  onwerMou_TreeLevel?: number | null;
  /** 熟悉专业专长 */
  professionalSpeciality?: string | null;
  /** 审批状态 */
  approveStatus?: string | null;
  /** 所属单位 */
  onwerMou?: string | null;
  /**
   * 最后工作日
   * @format date-time
   */
  lastWorkDate?: string | null;
  /**
   * 入职日期
   * @format date-time
   */
  entryDate?: string | null;
  /** 共享部门 */
  permissionOrganization?: string | null;
  /** 获取资历途径 */
  accessWayID?: string | null;
  /** 共享部门，包含下级 */
  permissionOrganization_SelfAndChildren?: string | null;
  /** 职任专业技术级别 */
  appointTechnicalLevelID?: string | null;
  /** 职任单位 */
  appointCompany?: string | null;
  /** 共享部门，仅自己 */
  permissionOrganization_SelfOnly?: string | null;
  /** 专业技术职务任职资格名称 */
  technicalPostQualificationsID?: string | null;
  /** 所属单元路径 */
  onwerMou_TreePath?: string | null;
  /** 组织路径 */
  stdOrganization_TreePath?: string | null;
  /** 职任专业技术职务名称 */
  appointTechnicalPostQualificationsID?: string | null;
  /** 专业技术级别 */
  technicalLevelID?: string | null;
  /**
   * 职任结束时间
   * @format date-time
   */
  appointEndDate?: string | null;
  /** 雇员Id */
  userId?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 雇员项目经历 */
export interface CreateSysEmployeeProjectExperiencesInput {
  /** 职位 */
  jobPosition?: string | null;
  /**
   * 最后工作日
   * @format date-time
   */
  lastWorkDate?: string | null;
  /**
   * 项目人数
   * @format int32
   */
  peopleNumber?: number | null;
  /** 硬件环境 */
  hardware?: string | null;
  /** 职位拼音排序 */
  jobPosition_Pinyin?: string | null;
  /** 开发工具 */
  devTool?: string | null;
  /** 软件环境 */
  software?: string | null;
  /**
   * 开始日期
   * @format date-time
   */
  startDate?: string | null;
  /** 项目名称 */
  name?: string | null;
  /** 描述 */
  description?: string | null;
  /** 项目成果 */
  achievement?: string | null;
  /**
   * 结束日期
   * @format date-time
   */
  endDate?: string | null;
  /** 项目职责 */
  responsibility?: string | null;
  /**
   * 入职日期
   * @format date-time
   */
  entryDate?: string | null;
  /** 审批状态 */
  approveStatus?: string | null;
  /** 工号 */
  jobNumber?: string | null;
  /** 雇员Id */
  userId?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 雇员专业技能 */
export interface CreateSysEmployeeSkillInput {
  /** 审批状态 */
  approveStatus?: string | null;
  /** 掌握程度 */
  level?: string | null;
  /**
   * 使用时间（月）
   * @format int32
   */
  useLength?: number | null;
  /**
   * 入职日期
   * @format date-time
   */
  entryDate?: string | null;
  /**
   * 最后工作日
   * @format date-time
   */
  lastWorkDate?: string | null;
  /** 种类 */
  category?: string | null;
  /** 工号 */
  jobNumber?: string | null;
  /** 名称 */
  name?: string | null;
  /** 雇员Id */
  userId?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 培训经历 */
export interface CreateSysEmployeeTrainingsInput {
  /** 培训描述 */
  discription?: string | null;
  /** 是否获得勋章 */
  isHaveMedal?: boolean | null;
  /**
   * 开始日期
   * @format date-time
   */
  startDate?: string | null;
  /** 适用场景 */
  applyScene?: string | null;
  /** 培训机构 */
  trainingAgency?: string | null;
  /** 培训成绩 */
  result?: string | null;
  /** 培训表现 */
  evaluation?: string | null;
  /**
   * 获得学分
   * @format double
   */
  getCredit?: number | null;
  /**
   * 结束日期
   * @format date-time
   */
  stopDate?: string | null;
  /** 名称 */
  course?: string | null;
  /**
   * 最后工作日
   * @format date-time
   */
  lastWorkDate?: string | null;
  /**
   * 入职日期
   * @format date-time
   */
  entryDate?: string | null;
  /** 培训类别 */
  trainingCategory?: string | null;
  /** 培训类别名称 */
  trainType?: string | null;
  /** 课时总计 */
  timeToTile?: string | null;
  /** 及格状态 */
  passStatus?: string | null;
  /** 工号 */
  jobNumber?: string | null;
  /** 审批状态 */
  approveStatus?: string | null;
  /**
   * 辅导导师
   * @format int32
   */
  mentorUserId?: number | null;
  /** 获得证书 */
  certificate?: string | null;
  /** 完成状态 */
  completionStatus?: string | null;
  /** 培训活动编号 */
  trainingActivityNum?: string | null;
  /** 雇员Id */
  userId?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 雇员执业（职业）资格信息 */
export interface CreateSysEmployeeVocationalQualificationInfoInput {
  /** 资格名称 */
  qualificationNameID?: string | null;
  /** 资格级别 */
  qualificationLevelID?: string | null;
  /** 组织路径 */
  stdOrganization_TreePath?: string | null;
  /** 获取资格途径 */
  accessWayID?: string | null;
  /** 证书附件 */
  accessory?: string | null;
  /**
   * 组织层级
   * @format int32
   */
  stdOrganization_TreeLevel?: number | null;
  /** 共享部门_包含下级 */
  permissionOrganization_SelfAndChildren?: string | null;
  /** 工号 */
  jobNumber?: string | null;
  /** 资格类型 */
  qualificationTypeID?: string | null;
  /** 共享部门 */
  permissionOrganization?: string | null;
  /**
   * 取得日期
   * @format date-time
   */
  dateOfAcquisition?: string | null;
  /** 共享人 */
  permissionBy?: string | null;
  /** 证书编号 */
  certificateNumber?: string | null;
  /**
   * 所属单元_层级
   * @format int32
   */
  onverMou_TreeLevel?: number | null;
  /**
   * 有效期至
   * @format date-time
   */
  validUntil?: string | null;
  /**
   * 入职日期
   * @format date-time
   */
  entryDate?: string | null;
  /** 期限类型 */
  termTypeId?: string | null;
  /** 是否最高等级 */
  supremeGrade?: boolean | null;
  /** 申请类型 */
  applicationTypeId?: string | null;
  /**
   * 最后工作日
   * @format date-time
   */
  lastWorkDate?: string | null;
  /** 所属单位 */
  ownerMou?: string | null;
  /** 所属单位路径 */
  ownerMou_TreePath?: string | null;
  /** 专业 */
  professional?: string | null;
  /** 组织 */
  stdOrganization?: string | null;
  /** 证据来源 */
  sourceLicensiesID?: string | null;
  /** 共享部门_仅自己 */
  permissionOrganization_SelfOnly?: string | null;
  /** 审批状态 */
  approveStatus?: string | null;
  /** 发布机构 */
  issueUnit?: string | null;
  /** 证书编号 */
  certificateNumberUpper?: string | null;
  /** 雇员Id */
  userId?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 第三方通知记录表   //备注使用Remark */
export interface CreateSysExternalNotificationHistoriesInput {
  /** 正文 */
  notificationContent?: string | null;
  /**
   * 通知类型
   * @format int32
   */
  notificationType?: 0 | 1 | 2 | 3 | 4;
  /** 关联Id */
  associatedId?: string | null;
  /** 参数 */
  parameterJson?: string | null;
  /** 返回参数 */
  responseJson?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 字典分组 */
export interface CreateSysGeneralCodeGroupsInput {
  /** 名称 */
  name?: string | null;
  /** 代码 */
  code?: string | null;
  /** 父级代码 */
  parentCode?: string | null;
  /**
   * 模块
   * @format int32
   */
  funcGroup?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 角色分组 */
export interface CreateSysRoleGroupsInput {
  /** 分组名称 */
  name?: string | null;
  /** 上级Id */
  parentId?: string | null;
  /**
   * 权限类型
   * @format int32
   */
  roleType?: 0 | 1 | 2 | 3;
  /**
   * 排序
   * @format int32
   */
  sort?: number | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 租户分所银行账号信息 */
export interface CreateTenantBankInfoInput {
  /** 账户昵称 */
  name?: string | null;
  /** 企业名称 */
  regName?: string | null;
  /** 信用代码 */
  creditCode?: string | null;
  /** 执业许可证号 */
  licenseNumber?: string | null;
  /** 税号 */
  taxVatNo?: string | null;
  /** 发票代码 */
  invoiceCode?: string | null;
  /** 企业注册地址 */
  address?: string | null;
  /** 联系电话 */
  landline?: string | null;
  /** 传真 */
  fax?: string | null;
  /** 邮政编码 */
  postCode?: string | null;
  /** 银行名称 */
  bankName?: string | null;
  /** 银行账号 */
  bankAccount?: string | null;
  /** 银行代码 */
  bankCode?: string | null;
  /** 开户支行 */
  openingBranchName?: string | null;
  /** 开户支行代码 */
  openingBranchCode?: string | null;
  /** 开户支行地址 */
  openingBranchAddress?: string | null;
  /** 开户支行电话 */
  openingBranchLandline?: string | null;
  /** 是否默认收款账号 */
  isDefaultCollectionAccount?: string | null;
  /** 是否默认付款账号 */
  isDefaultPaymentAccount?: string | null;
  /** 货币单位 */
  currency?: string | null;
  /** 银行国际结算代码 */
  swiftCode?: string | null;
  /** 支付结算代码 */
  cnapsCode?: string | null;
  /** 银联支付号码 */
  unionPayNo?: string | null;
  /** 同城交换号码 */
  localExchangeNumber?: string | null;
  /** 分所办公室ID */
  officeId?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

export interface CreateTimerInput {
  /** @format date-time */
  workDate?: string | null;
}

/** 创建或修改工时记录入参 */
export interface CreateTimesheetInput {
  /**
   * 工作日期
   * @format date-time
   */
  workDate?: string;
  /**
   * 开始时间
   * @format time
   */
  startTime?: string | null;
  /**
   * 结束时间
   * @format time
   */
  endTime?: string | null;
  /**
   * 工作小时数
   * @format double
   */
  hoursWorked?: number;
  /**
   * 内部费率
   * @format double
   */
  internalRate?: number;
  /** 客户编号 */
  clientId?: string | null;
  /** 案件编号 */
  caseId?: string | null;
  /** 书写语言 */
  writtenLanguage?: string | null;
  /** 是否收藏 */
  isFavorited?: boolean | null;
  /**
   * 累计计时时间
   * @format double
   */
  accumulatedTime?: number;
  /** 是否跨天拆分 */
  isSplitAcrossDays?: boolean | null;
  /** 计时人员 */
  employeeId?: string | null;
  /** 计时状态 */
  timerState?: string | null;
  /** 工时描述 */
  narrative?: string | null;
  /** 工时类别 */
  category?: string | null;
  /** 暂代昵称 */
  nickName?: string | null;
  /** 关联任务 */
  task?: string | null;
  /** 活动代码 */
  activityCode?: string | null;
  /** 货币类型 */
  currency?: string | null;
  /** 是否计时器计时 */
  isTimerTracked?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 工时暂代名称表 */
export interface CreateTimesheetNickNameInput {
  /** 暂代名称 */
  nickName?: string | null;
  /** 人员编号 */
  employeeId?: string | null;
  /** 客户编号 */
  clientId?: string | null;
  /** 案件编号 */
  caseId?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

export interface CreateUpsertFlowConditionInput {
  /**
   * 流转条件的数据
   * @format int32
   */
  condFlowingType?: 0 | 1;
  /**
   * 流程控制类型选项
   * @format int32
   */
  condControlType?: 0 | 1;
  flowConditionUpsert?: FlowConditionUpsertDto[] | null;
}

export interface CreateUserInfoInput {
  /** 登录账号 */
  userName?: string | null;
  password?: string | null;
  /** 显示名称 */
  displayName?: string | null;
  /** 工号 */
  jobNumber?: string | null;
  /** 别名 */
  alterName?: string | null;
  /** 手机号 */
  phoneNumber?: string | null;
  /** 邮箱 */
  emailAddress?: string | null;
  /** 姓名 */
  name?: string | null;
  /** 组织机构 */
  organizationUnitId?: string | null;
  /**
   * 性别
   * @format int32
   */
  sex?: number | null;
  /**
   * 年龄
   * @format int32
   */
  age?: number | null;
  /**
   * 生日
   * @format date-time
   */
  birth?: string | null;
  address?: string | null;
  /**
   * 用户类型
   * @format int32
   */
  userType?: 0 | 1 | 2 | null;
  /** 默认角色 */
  defaultRoleId?: string | null;
  /** 是否在本系统创建 */
  isOwned?: boolean | null;
  /** 描述 */
  description?: string | null;
  /**
   * 默认排序
   * @format int32
   */
  sort?: number | null;
  /** 是否默认角色 */
  isDefault?: boolean | null;
  /** 是否启用 */
  isActive?: boolean | null;
  /** 必智运维账号 */
  isBitzOwned?: boolean | null;
  /** 数据权限 */
  userOrganizationUnitInputs?: CreateUserOrganizationUnitInput[] | null;
  /** 角色配置 */
  roleIds?: string[] | null;
  /** 岗位 */
  positionIds?: string[] | null;
  /** 用户组 */
  userGroupIds?: string[] | null;
  /**
   * 入职时间
   * @format date-time
   */
  entryTime?: string;
  /** 是否发送邮件 */
  isSendEmail?: boolean;
  /** 是否在下次登录时重置密码 */
  isResetPassword?: boolean;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 用户手册内容 */
export interface CreateUserManualContentInput {
  /** 模块ID */
  userManualModuleId?: string | null;
  /** 内容 */
  content?: string | null;
  /** 简介 */
  brief?: string | null;
  /** 关键词 */
  keyWords?: string | null;
  /**
   * 访问量
   * @format int32
   */
  pageview?: number;
  /** 备注 */
  remark?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 用户手册问题 */
export interface CreateUserManualFAQInput {
  /** id */
  userManualContentId?: string | null;
  /** 模块ID */
  userManualModuleId?: string | null;
  /** 主题 */
  question?: string | null;
  /** 答案 */
  answer?: string | null;
  /**
   * 排序
   * @format int32
   */
  sort?: number;
  /** 备注 */
  remark?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 用户手册模块 */
export interface CreateUserManualModuleInput {
  /** 名称 */
  name?: string | null;
  /** 父级ID */
  parentId?: string | null;
  /** 主题 */
  topic?: string | null;
  /** 视频 */
  video?: string | null;
  /** 封面 */
  picture?: string | null;
  /** 视频时长 */
  videoDuration?: string | null;
  /** 业务描述 */
  description?: string | null;
  /** 汇总描述 */
  summary?: string | null;
  /** 菜单链接 */
  website?: string | null;
  /**
   * 排序
   * @format int32
   */
  sort?: number;
  /** 是否是菜单 */
  isMenu?: string | null;
  /** 语言 */
  language?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

export interface CreateUserOrganizationUnitInput {
  roleId?: string | null;
  userId?: string | null;
  isActive?: boolean;
  /** @format int32 */
  thresholdFlag?: 0 | 1 | 2 | 3 | 4 | 5;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

export interface CreditCodeInput {
  creditCode?: string | null;
}

/** 利冲规避偏好设置 */
export interface CsrAvoidPreferenceDto {
  id?: string | null;
  /** 规避偏好关键字 */
  avoidKeyword?: string | null;
  /** 规避利冲方类型 */
  patryType?: string | null;
  /** 关键字规避方法（模糊/精确） */
  avoidMethod?: string | null;
  /**
   * 规避开始时间
   * @format date-time
   */
  startDate?: string;
  /**
   * 规避结束时间（空则长期生效）
   * @format date-time
   */
  endTime?: string;
  /** 附件信息 */
  attachments?: string | null;
  /** 说明描述 */
  narrative?: string | null;
  /** 租户ID */
  tenantId?: string | null;
  /** 部门ID  DepartmentId */
  organizationUnitId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 备注 */
  remark?: string | null;
  /** 状态 	                中立字段，某些表可使用某些表不使用 */
  isEnabled?: boolean;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface CsrAvoidPreferenceDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: CsrAvoidPreferenceDto[] | null;
}

/** 通用返回信息类 */
export interface CsrAvoidPreferenceDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 利冲规避偏好设置 */
  response?: CsrAvoidPreferenceDto;
}

/** 通用分页信息类 */
export interface CsrAvoidPreferenceDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: CsrAvoidPreferenceDto[] | null;
}

/** 通用返回信息类 */
export interface CsrAvoidPreferenceDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: CsrAvoidPreferenceDtoPageModel;
}

/** 利冲规避偏好结果表 */
export interface CsrAvoidPreferenceResultDto {
  id?: string | null;
  /** 利冲规避编号 */
  csrAvoidId?: string | null;
  /** 利冲规避关键词 */
  avoidKeyword?: string | null;
  /** 关键词隶属规避方角色 */
  partyType?: string | null;
  /** 规避冲突案件的角色 */
  caseParty?: string | null;
  /** 规避案件冲突方名称 */
  casePartyName?: string | null;
  /** 规避利冲关联业务类型（利冲/案件/入库/投标等） */
  csrRelationType?: string | null;
  /** 规避业务编号（默认案件） */
  relationId?: string | null;
  /** 冲突产生时关联业务状态 */
  relationStatus?: string | null;
  /** 匹配客户名称 */
  clientName?: string | null;
  /** 匹配客户外文名称 */
  clientForeignName?: string | null;
  /** 匹配客户实控人名称 */
  clientShareholderActualCtrlName?: string | null;
  /** 匹配客户实控人外文名称 */
  clientShareholderActualCtrlForeignName?: string | null;
  /** 匹配对方名称 */
  oppositeName?: string | null;
  /** 匹配对方外文名称 */
  oppositeForeignName?: string | null;
  /** 匹配对方实控人名称 */
  oppositeShareholderActualCtrlName?: string | null;
  /** 匹配对方实控人外文名称 */
  oppositeShareholderActualCtrlForeignName?: string | null;
  /** 潜在第三方名称 */
  potential3rdPartyName?: string | null;
  /** 潜在第三方外文名称 */
  potential3rdPartyForeignName?: string | null;
  /** 关联业务类型 */
  relationCategory?: string | null;
  /** 关联业务名称 */
  relationName?: string | null;
  /**
   * 关联业务申请时间
   * @format date-time
   */
  relationRequestTime?: string;
  /** 关联业务主管律师 */
  relationInChargeLawyerId?: string | null;
  /** 关联业务申请合伙人 */
  relationRequestUserId?: string | null;
  /** 关联业务协办律师 */
  relationCoWorkingLawyerId?: string | null;
  /** 关联业务账单律师 */
  relationBillingLawyerId?: string | null;
  /** 规避匹配方式（包含/忽略） */
  matchingMethod?: string | null;
  /** 租户ID */
  tenantId?: string | null;
  /** 部门ID  DepartmentId */
  organizationUnitId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 备注 */
  remark?: string | null;
  /** 状态 	                中立字段，某些表可使用某些表不使用 */
  isEnabled?: boolean;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface CsrAvoidPreferenceResultDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: CsrAvoidPreferenceResultDto[] | null;
}

/** 通用返回信息类 */
export interface CsrAvoidPreferenceResultDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 利冲规避偏好结果表 */
  response?: CsrAvoidPreferenceResultDto;
}

/** 通用分页信息类 */
export interface CsrAvoidPreferenceResultDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: CsrAvoidPreferenceResultDto[] | null;
}

/** 通用返回信息类 */
export interface CsrAvoidPreferenceResultDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: CsrAvoidPreferenceResultDtoPageModel;
}

/** 利冲豁免确认表 */
export interface CsrExemptionDto {
  /** 利冲编号 */
  csrId?: string | null;
  /** 利冲关键词 */
  csrKeyword?: string | null;
  /** 与我冲突案件的角色（冲突方） */
  csrParty?: string | null;
  /** 我的案件冲突角色 */
  caseParty?: string | null;
  /** 我的案件冲突方名称 */
  casePartyName?: string | null;
  /** 主管律师 */
  inChargeLawyer?: string | null;
  /** 利冲关联业务类型（利冲/案件/入库/投标等） */
  csrRelationType?: string | null;
  /** 关联业务编号（默认案件） */
  relationId?: string | null;
  /** 冲突产生时关联业务状态 */
  relationStatus?: string | null;
  /** 与我冲突业务编号合计 */
  csrRelationIds?: string | null;
  /** 利冲确认发送人编号 */
  csrSendUserId?: string | null;
  /**
   * 利冲确认发送时间
   * @format date-time
   */
  csrSendTime?: string;
  /** 是否确认 */
  isConfirm?: boolean;
  /** 是否利冲 */
  isConflict?: boolean;
  /**
   * 利冲豁免确认时间
   * @format date-time
   */
  confirmTime?: string | null;
  /** 豁免文件 */
  attachments?: string | null;
  /** 说明描述 */
  narrative?: string | null;
  /**
   * 提醒方式
   * @format int32
   */
  reminderMethod?: 0 | 1;
  /** 提醒方式文本 */
  reminderMethodText?: string | null;
  /** 操作人 */
  operator?: string | null;
  /** 操作人姓名 */
  operatorName?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface CsrExemptionDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: CsrExemptionDto[] | null;
}

/** 通用返回信息类 */
export interface CsrExemptionDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 利冲豁免确认表 */
  response?: CsrExemptionDto;
}

/** 通用分页信息类 */
export interface CsrExemptionDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: CsrExemptionDto[] | null;
}

/** 通用返回信息类 */
export interface CsrExemptionDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: CsrExemptionDtoPageModel;
}

/** 利冲审查分配表 */
export interface CsrProcessAssignDto {
  /** 分配尾号 */
  assignTailNumberList?: string[] | null;
  /** 移交人id */
  assignUserId?: string | null;
  /** 分配人名称 */
  assignUserName?: string | null;
  avatar?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface CsrProcessAssignDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: CsrProcessAssignDto[] | null;
}

/** 分配用户 */
export interface CsrProcessAssignUserDto {
  userId?: string | null;
  userName?: string | null;
  avatar?: string | null;
}

/** 通用返回信息类 */
export interface CsrProcessAssignUserDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: CsrProcessAssignUserDto[] | null;
}

/** 利冲审查表 */
export interface CsrProcessDto {
  /** 利冲编号 */
  csrId?: string | null;
  /**
   * 检索模式（精确，模糊）
   * @format int32
   */
  searchMode?: 0 | 1 | 2 | 3;
  /** 我方名称 */
  ourPatryName?: string | null;
  /** 我方外文名称 */
  ourPatryForeignName?: string | null;
  /** 我方证件号码 */
  ourPartyCreditCode?: string | null;
  /** 对方名称 */
  oppositeName?: string | null;
  /** 对方外文名称 */
  oppositeForeignName?: string | null;
  /** 对方证件号码 */
  oppositeCreditCode?: string | null;
  /** 潜在三方名称 */
  potential3rdPartyName?: string | null;
  /** 潜在三方外文名称 */
  potential3rdPartyForeignName?: string | null;
  /** 潜在三方证件号码 */
  potential3rdPartyCreditCode?: string | null;
  /** 是否分词检索 */
  isSplitWordSearch?: string | null;
  /** 是否外文分词检索 */
  isForeignSplitWordSearch?: string | null;
  /**
   * 匹配结果数量
   * @format double
   */
  matchingCount?: number;
  /** 需要豁免案件编号 */
  needExemptionCaseId?: string | null;
  /** 利冲审查人编号 */
  processUserId?: string | null;
  /**
   * 利冲审查时间
   * @format date-time
   */
  processTime?: string;
  /** 说明描述 */
  narrative?: string | null;
  /** 租户ID */
  tenantId?: string | null;
  /** 部门ID  DepartmentId */
  organizationUnitId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 备注 */
  remark?: string | null;
  /** 状态 */
  isEnabled?: boolean;
  /** 利冲检索条件Json */
  caseRelatedPartyListJson?: string | null;
  /** 利冲二次检索条件Json */
  caseRelatedPartySearchJson?: string | null;
  /** 利冲审查结果 */
  csrProcessResults?: CsrSearchProcessResultDto[] | null;
  /** 检索模式 */
  searchModeText?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface CsrProcessDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: CsrProcessDto[] | null;
}

/** 通用返回信息类 */
export interface CsrProcessDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 利冲审查表 */
  response?: CsrProcessDto;
}

export interface CsrProcessResultConfirmInfoOutput {
  csrExemptionList?: CSRExemptionOutput[] | null;
  /** @format double */
  completePercent?: number;
  /** 利冲申请编号 */
  csrId?: string | null;
  /**
   * 索引
   * @format int32
   */
  index?: number;
  /** 利冲审查编号 */
  csrProcessId?: string | null;
  /** 是否确认 */
  isChecked?: string | null;
  /** 是否需要豁免 */
  isNeedExemption?: string | null;
  /** 利冲关键词 */
  csrKeyword?: string | null;
  /** 与我冲突案件的角色 */
  csrParty?: string | null;
  /** 我被冲突案件的角色 */
  caseParty?: string | null;
  /** 我的案件冲突方名称 */
  casePartyName?: string | null;
  /** 冲突等级 */
  csrLevel?: string | null;
  /** 利冲关联业务类型 */
  csrRelationType?: string | null;
  /** 利冲关联业务类型文本 */
  csrRelationTypeText?: string | null;
  /** 关联业务编号 */
  relationId?: string | null;
  /** 利冲申请编号 */
  csrSerialId?: string | null;
  /** 案件编号 */
  caseSerialId?: string | null;
  /** 冲突产生时关联业务状态 */
  relationStatus?: string | null;
  /** 利冲豁免结果 */
  exemptionResult?: string | null;
  /** 匹配利冲规则 */
  matchingRule?: string | null;
  /** 委托事项 */
  entrustedMatters?: string | null;
  /** 匹配客户名称 */
  clientName?: string | null;
  /** 匹配客户外文名称 */
  clientForeignName?: string | null;
  /** 匹配客户实控人名称 */
  clientShareholderActualCtrlName?: string | null;
  /** 匹配客户实控人外文名称 */
  clientShareholderActualCtrlForeignName?: string | null;
  /** 客户参与实体名称 */
  clientParticipatingEntityName?: string | null;
  /** 客户参与实体外文名称 */
  clientParticipatingEntityForeignName?: string | null;
  /** 匹配对方名称 */
  oppositeName?: string | null;
  /** 匹配对方外文名称 */
  oppositeForeignName?: string | null;
  /** 匹配对方实控人名称 */
  oppositeShareholderActualCtrlName?: string | null;
  /** 匹配对方实控人外文名称 */
  oppositeShareholderActualCtrlForeignName?: string | null;
  /** 潜在第三方名称 */
  potential3rdPartyName?: string | null;
  /** 潜在第三方外文名称 */
  potential3rdPartyForeignName?: string | null;
  /** 案件类型 */
  relationCategory?: string | null;
  /** 案件类型类型文本 */
  relationCategoryText?: string | null;
  /** 案件名称 */
  relationName?: string | null;
  /**
   * 申请时间
   * @format date-time
   */
  relationRequestTime?: string | null;
  /** 主办合伙人 */
  relationInChargeLawyerId?: string | null;
  /** 主办合伙人名称 */
  relationInChargeLawyerName?: string | null;
  /** 申请合伙人 */
  relationRequestUserId?: string | null;
  /** 申请合伙人名称 */
  relationRequestUserName?: string | null;
  /** 关系列表 */
  relationList?: CaseRelatedResultDto[] | null;
  /** 案源人 */
  relationCaseUser?: string | null;
  /** 案源人名称 */
  relationCaseUserName?: string | null;
  /**
   * 结案时间
   * @format date-time
   */
  relationEndTime?: string | null;
  /** 关联关系json */
  caseRelatedResultJson?: string | null;
  /** @format date-time */
  submittedOn?: string | null;
  /** 确认律师Id */
  confirmLawyerId?: string | null;
  /** 确认律师名称 */
  confirmLawyerName?: string | null;
  /** 账单管理人 */
  relationBillingLawyerNameId?: string | null;
  /** 账单管理人名称 */
  relationBillingLawyerName?: string | null;
  /** 客户Id */
  clientId?: string | null;
  /** 案件/利冲号 */
  serialId?: string | null;
  /** 对方关联方名称 */
  oppositeRelatedPartiesName?: string | null;
  /** 对方关联方外文名称 */
  oppositeRelatedPartiesForeignName?: string | null;
  /** 潜在第三方实控人名称 */
  potential3rdPartyShareholderActualCtrlName?: string | null;
  /** 潜在第三方实控人外文名称 */
  potential3rdPartyShareholderActualCtrlForeignName?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface CsrProcessResultConfirmInfoOutputListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: CsrProcessResultConfirmInfoOutput[] | null;
}

/** 利冲审查结果表 */
export interface CsrProcessResultDto {
  /** 利冲编号 */
  csrId?: string | null;
  /** 利冲审查编号 */
  csrProcessId?: string | null;
  /** 是否需要豁免 */
  isNeedExemption?: string | null;
  /** 利冲关键词 */
  csrKeyword?: string | null;
  /** 与我冲突案件的角色 */
  csrParty?: string | null;
  /** 我被冲突案件的角色 */
  caseParty?: string | null;
  /** 我的案件冲突方名称 */
  casePartyName?: string | null;
  /** 冲突等级 */
  csrLevel?: string | null;
  /** 利冲关联业务类型 */
  csrRelationType?: string | null;
  /** 关联业务编号 */
  relationId?: string | null;
  /** 冲突产生时关联业务状态 */
  relationStatus?: string | null;
  /** 利冲豁免结果 */
  exemptionResult?: string | null;
  /** 匹配利冲规则 */
  matchingRule?: string | null;
  /** 关系列表 */
  relatedList?: CaseRelatedResultDto[] | null;
  /** 关联业务类型 */
  relationCategory?: string | null;
  /** 关联业务名称 */
  relationName?: string | null;
  /**
   * 关联业务申请时间
   * @format date-time
   */
  relationRequestTime?: string | null;
  /** 关联业务主管律师 */
  relationInChargeLawyerId?: string | null;
  /** 关联业务申请合伙人 */
  relationRequestUserId?: string | null;
  /** 案源律师 */
  relationCaseUser?: string | null;
  /** 确认律师Id */
  confirmLawyerId?: string | null;
  /** 账单管理人 */
  relationBillingLawyerNameId?: string | null;
  /** 账单管理人名称 */
  relationBillingLawyerName?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface CsrProcessResultDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: CsrProcessResultDto[] | null;
}

/** 通用返回信息类 */
export interface CsrProcessResultDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 利冲审查结果表 */
  response?: CsrProcessResultDto;
}

export interface CsrProcessResultNoConfirmOutput {
  /** 豁免确认人 */
  inChargeLawyer?: string | null;
  /** 豁免确认人名称 */
  inChargeLawyerName?: string | null;
  /** 豁免确认人名称 */
  inChargeLawyerPicture?: string | null;
  /** 利冲申请编号 */
  csrId?: string | null;
  /**
   * 索引
   * @format int32
   */
  index?: number;
  /** 利冲审查编号 */
  csrProcessId?: string | null;
  /** 是否确认 */
  isChecked?: string | null;
  /** 是否需要豁免 */
  isNeedExemption?: string | null;
  /** 利冲关键词 */
  csrKeyword?: string | null;
  /** 与我冲突案件的角色 */
  csrParty?: string | null;
  /** 我被冲突案件的角色 */
  caseParty?: string | null;
  /** 我的案件冲突方名称 */
  casePartyName?: string | null;
  /** 冲突等级 */
  csrLevel?: string | null;
  /** 利冲关联业务类型 */
  csrRelationType?: string | null;
  /** 利冲关联业务类型文本 */
  csrRelationTypeText?: string | null;
  /** 关联业务编号 */
  relationId?: string | null;
  /** 利冲申请编号 */
  csrSerialId?: string | null;
  /** 案件编号 */
  caseSerialId?: string | null;
  /** 冲突产生时关联业务状态 */
  relationStatus?: string | null;
  /** 利冲豁免结果 */
  exemptionResult?: string | null;
  /** 匹配利冲规则 */
  matchingRule?: string | null;
  /** 委托事项 */
  entrustedMatters?: string | null;
  /** 匹配客户名称 */
  clientName?: string | null;
  /** 匹配客户外文名称 */
  clientForeignName?: string | null;
  /** 匹配客户实控人名称 */
  clientShareholderActualCtrlName?: string | null;
  /** 匹配客户实控人外文名称 */
  clientShareholderActualCtrlForeignName?: string | null;
  /** 客户参与实体名称 */
  clientParticipatingEntityName?: string | null;
  /** 客户参与实体外文名称 */
  clientParticipatingEntityForeignName?: string | null;
  /** 匹配对方名称 */
  oppositeName?: string | null;
  /** 匹配对方外文名称 */
  oppositeForeignName?: string | null;
  /** 匹配对方实控人名称 */
  oppositeShareholderActualCtrlName?: string | null;
  /** 匹配对方实控人外文名称 */
  oppositeShareholderActualCtrlForeignName?: string | null;
  /** 潜在第三方名称 */
  potential3rdPartyName?: string | null;
  /** 潜在第三方外文名称 */
  potential3rdPartyForeignName?: string | null;
  /** 案件类型 */
  relationCategory?: string | null;
  /** 案件类型类型文本 */
  relationCategoryText?: string | null;
  /** 案件名称 */
  relationName?: string | null;
  /**
   * 申请时间
   * @format date-time
   */
  relationRequestTime?: string | null;
  /** 主办合伙人 */
  relationInChargeLawyerId?: string | null;
  /** 主办合伙人名称 */
  relationInChargeLawyerName?: string | null;
  /** 申请合伙人 */
  relationRequestUserId?: string | null;
  /** 申请合伙人名称 */
  relationRequestUserName?: string | null;
  /** 关系列表 */
  relationList?: CaseRelatedResultDto[] | null;
  /** 案源人 */
  relationCaseUser?: string | null;
  /** 案源人名称 */
  relationCaseUserName?: string | null;
  /**
   * 结案时间
   * @format date-time
   */
  relationEndTime?: string | null;
  /** 关联关系json */
  caseRelatedResultJson?: string | null;
  /** @format date-time */
  submittedOn?: string | null;
  /** 确认律师Id */
  confirmLawyerId?: string | null;
  /** 确认律师名称 */
  confirmLawyerName?: string | null;
  /** 账单管理人 */
  relationBillingLawyerNameId?: string | null;
  /** 账单管理人名称 */
  relationBillingLawyerName?: string | null;
  /** 客户Id */
  clientId?: string | null;
  /** 案件/利冲号 */
  serialId?: string | null;
  /** 对方关联方名称 */
  oppositeRelatedPartiesName?: string | null;
  /** 对方关联方外文名称 */
  oppositeRelatedPartiesForeignName?: string | null;
  /** 潜在第三方实控人名称 */
  potential3rdPartyShareholderActualCtrlName?: string | null;
  /** 潜在第三方实控人外文名称 */
  potential3rdPartyShareholderActualCtrlForeignName?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface CsrProcessResultNoConfirmOutputListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: CsrProcessResultNoConfirmOutput[] | null;
}

export interface CsrProtectEntityInfoOutput {
  /** 客户类型 */
  clientTypeText?: string | null;
  /** 客户行业 */
  clientIndustryText?: string | null;
  /** 证件类型 */
  creditTypeText?: string | null;
  /** 客户性质 */
  customerNatureText?: string | null;
  /** 客户信息表 */
  clientInfo?: ClientDto;
  /** 客户名称 */
  name?: string | null;
  /** 外文名称 */
  foreignName?: string | null;
  /** 曾用名 */
  formerName?: string | null;
  /** 别名 */
  altName?: string | null;
  /** 客户类型 */
  clientType?: string | null;
  /** 证件类型 */
  creditType?: string | null;
  /** 证件号码 */
  creditCode?: string | null;
  /** 通讯地址 */
  address?: string | null;
  /** 税号 */
  taxNumber?: string | null;
  /** 邮箱地址 */
  emailAddress?: string | null;
  /** 手机号码 */
  phoneNumber?: string | null;
  /** 固定电话 */
  landline?: string | null;
  /** 描述说明 */
  narrative?: string | null;
  /** 客户级别 */
  clientLevel?: string | null;
  /** 客户性质 */
  customerNature?: string | null;
  /** 备注 */
  remark?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface CsrProtectEntityInfoOutputMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  response?: CsrProtectEntityInfoOutput;
}

/** 通用分页信息类 */
export interface CsrProtectEntityInfoOutputPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: CsrProtectEntityInfoOutput[] | null;
}

/** 通用返回信息类 */
export interface CsrProtectEntityInfoOutputPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: CsrProtectEntityInfoOutputPageModel;
}

/** 需要豁免的案件 */
export interface CsrRelation {
  /** 选择的需要豁免的案件的Id */
  relationId?: string | null;
  /** 是否需要豁免 */
  isNeedExemption?: boolean;
  /** 豁免主办合伙人Id */
  inChargeLawyerIds?: string[] | null;
}

export interface CsrRequestBatchEmailRemindInput {
  /** 提醒类型 多选案件:批量合并提醒 单人合并提醒:合并案件提醒 */
  remindType?: string | null;
  /** 审查结果集Id */
  csrProcessIdList?: string[] | null;
  /** 利冲Id */
  csrRequestIdList?: string[] | null;
  /** 批量提醒人 */
  remindList?: CsrRequestRemindInput[] | null;
  /** 发送邮箱 */
  emails?: string[] | null;
}

/** 利冲审查列表 */
export interface CsrRequestCheckOutput {
  /** 利冲号 */
  serialId?: string | null;
  /** 利冲适用规则 */
  csrRule?: string | null;
  /** 利冲适用规则 */
  csrRuleText?: string | null;
  /** 利冲名称 */
  name?: string | null;
  /** 客户名称 */
  clientName?: string | null;
  /** 客户外文名称 */
  clientForeignName?: string | null;
  /** 客户股东实控人名称 */
  clientShareholderActualCtrlName?: string | null;
  /** 客户股东实控人外文名称 */
  clientShareholderActualCtrlForeignName?: string | null;
  /** 对方当事人 */
  oppositeName?: string | null;
  /** 对方当事人外文名称 */
  oppositeForeignName?: string | null;
  /** 对方股东实控人名称 */
  oppositeShareholderActualCtrlName?: string | null;
  /** 对方股东实控人外文名称 */
  oppositeShareholderActualCtrlForeignName?: string | null;
  /** 客户参与实体名称 */
  clientParticipatingEntityName?: string | null;
  /** 客户参与实体外文名称 */
  clientParticipatingEntityForeignName?: string | null;
  /** 对方参与实体名称 */
  oppositeParticipatingEntityName?: string | null;
  /** 对方参与实体外文名称 */
  oppositeParticipatingEntityForeignName?: string | null;
  /** 潜在第三方名称 */
  potential3rdPartyName?: string | null;
  /** 潜在第三方外文名称 */
  potential3rdPartyForeignName?: string | null;
  /** 潜在第三方股东实控人 */
  potential3rdPartyShareholderActualCtrlName?: string | null;
  /** 潜在第三方股东实控人外文名称 */
  potential3rdPartyShareholderActualCtrlForeignName?: string | null;
  /** 利冲案件类型 */
  category?: string | null;
  /** 利冲案件类型文本 */
  categoryText?: string | null;
  /** 是否紧急 */
  isUrgent?: string | null;
  /** 是否紧急文本 */
  isUrgentText?: string | null;
  /** 利冲转立案Id */
  caseId?: string | null;
  /** 利冲转立案编号 */
  caseSerialId?: string | null;
  /** 关联案件名称 */
  caseName?: string | null;
  /**
   * 申请时间
   * @format date-time
   */
  submittedOn?: string | null;
  /** 申请合伙人 */
  applicant?: string | null;
  /** 申请合伙人 */
  applicantName?: string | null;
  /** 关联案件Id */
  relationCaseId?: string | null;
  /** 关联案件名称 */
  relationCaseName?: string | null;
  /** 关联案件编号 */
  relationCaseSerialId?: string | null;
  flowOperation?: Record<string, any>;
  /** 分所 */
  officeName?: string | null;
  /** 业务状态   列表不处理，只处理导出 */
  flowState?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用分页信息类 */
export interface CsrRequestCheckOutputPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: CsrRequestCheckOutput[] | null;
}

/** 通用返回信息类 */
export interface CsrRequestCheckOutputPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: CsrRequestCheckOutputPageModel;
}

export interface CsrRequestConfirmInput {
  /** 附件 */
  attachments?: string | null;
  /** 主办合伙人 */
  inChargeLawyer?: string | null;
  /** 是否冲突 */
  isConflict?: boolean;
  /** 业务Id */
  id?: string | null;
  /** 审批意见 */
  approvalOpinion?: string | null;
  /** 传参 */
  paramsString?: string | null;
}

export interface CsrRequestConfirmOutput {
  /** 利冲号 */
  serialId?: string | null;
  /** 利冲适用规则 */
  csrRule?: string | null;
  /** 利冲适用规则 */
  csrRuleText?: string | null;
  /** 利冲名称 */
  name?: string | null;
  /** 客户名称 */
  clientName?: string | null;
  /** 客户外文名称 */
  clientForeignName?: string | null;
  /** 参与实体 */
  clientParticipatingEntityForeignName?: string | null;
  /** 参与实体 */
  clientParticipatingEntityName?: string | null;
  /** 客户股东实控人名称 */
  clientShareholderActualCtrlName?: string | null;
  /** 客户股东实控人外文名称 */
  clientShareholderActualCtrlForeignName?: string | null;
  /** 对方当事人 */
  oppositeName?: string | null;
  /** 对方当事人外文名称 */
  oppositeForeignName?: string | null;
  /** 对方股东实控人名称 */
  oppositeShareholderActualCtrlName?: string | null;
  /** 对方股东实控人外文名称 */
  oppositeShareholderActualCtrlForeignName?: string | null;
  /** 对方参与实体名称 */
  oppositeParticipatingEntityName?: string | null;
  /** 对方参与实体外文名称 */
  oppositeParticipatingEntityForeignName?: string | null;
  /** 潜在第三方名称 */
  potential3rdPartyName?: string | null;
  /** 潜在第三方外文名称 */
  potential3rdPartyForeignName?: string | null;
  /** 潜在第三方股东实控人 */
  potential3rdPartyShareholderActualCtrlName?: string | null;
  /** 潜在第三方股东实控人外文名称 */
  potential3rdPartyShareholderActualCtrlForeignName?: string | null;
  /** 利冲案件类型 */
  category?: string | null;
  /** 利冲案件类型文本 */
  categoryText?: string | null;
  /** 是否紧急 */
  isUrgent?: string | null;
  /** 是否紧急文本 */
  isUrgentText?: string | null;
  /** 利冲转立案Id */
  caseId?: string | null;
  /** 利冲转立案编号 */
  caseSerialId?: string | null;
  /** 关联案件名称 */
  caseName?: string | null;
  /**
   * 申请时间
   * @format date-time
   */
  submittedOn?: string | null;
  /** 申请合伙人 */
  applicant?: string | null;
  /** 申请合伙人 */
  applicantName?: string | null;
  /**
   * 提醒方式
   * @format int32
   */
  reminderMethod?: 0 | 1;
  /** 提醒方式 */
  reminderMethodText?: string | null;
  /** 豁免信息 */
  csrExemptions?: CSRExemptionOutput[] | null;
  /**
   * 利冲审查进度
   * @format double
   */
  rateOfProgress?: number;
  /** 关联案件Id */
  relationCaseId?: string | null;
  /** 关联案件名称 */
  relationCaseName?: string | null;
  /** 关联案件编号 */
  relationCaseSerialId?: string | null;
  /** 分所 */
  officeName?: string | null;
  /** 审查结果集Id */
  csrProcessId?: string | null;
  /** 业务状态   列表不处理，只处理导出 */
  flowState?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用分页信息类 */
export interface CsrRequestConfirmOutputPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: CsrRequestConfirmOutput[] | null;
}

/** 通用返回信息类 */
export interface CsrRequestConfirmOutputPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: CsrRequestConfirmOutputPageModel;
}

/** 批量无利冲 */
export interface CsrRequestConfirmWLCBatchInput {
  ids?: string[] | null;
  /** 主办合伙人 */
  inChargeLawyer?: string[] | null;
  /** 是否冲突 */
  isConflict?: boolean;
}

export interface CsrRequestDataDto {
  /** ID（编辑时需传ID） */
  id?: string | null;
  /** 流水编号 */
  serialId?: string | null;
  /** 利冲案件名称 */
  name?: string | null;
  /** 利冲案件类型 */
  category?: string | null;
  /** 关联案件编号 */
  relationCaseId?: string | null;
  /** 关联案件编号 */
  relationCaseName?: string | null;
  /** 客户行业 */
  clientIndustryId?: string | null;
  /** 执业领域 */
  practiceArea?: string | null;
  /** 是否涉密 */
  isClassified?: string | null;
  /** 是否紧急 */
  isUrgent?: string | null;
  /**
   * 收费方式
   *
   * （固定，小时，风险，免费代理）
   */
  chargingMethods?: string[] | null;
  /** 收费方式文本 */
  chargingMethodText?: string | null;
  /**
   * 合同金额（费用总额）
   * @format double
   */
  contractAmount?: number;
  /** 委托事项 */
  entrustedMatters?: string | null;
  /** 说明描述 */
  narrative?: string | null;
  /** 秘书助理（预留）;选择人则限定选择人和值班秘书可见，不选就全部助理和秘书可见 */
  assistantSecretary?: string | null;
  /** 利冲关联案件编号 */
  caseId?: string | null;
  /** 利冲关联案件名称 */
  caseName?: string | null;
  /** 利冲关联案件类型 */
  caseCategory?: string | null;
  /** 利冲关联案件状态 */
  caseStatus?: string | null;
  /** 豁免文件 */
  exemptionAttachments?: string | null;
  /** 附件 */
  attachments?: string | null;
  /** 是否标的 */
  isTarget?: string | null;
  /**
   * 标的金额
   * @format double
   */
  targetAmount?: number;
  /** 利冲适用规则 */
  csrRule?: string | null;
  /** 利冲时效是否过期 */
  isExpired?: string | null;
  /**
   * 利冲审查进度
   * @format double
   */
  rateOfProgress?: number;
  /**
   * 利冲时效剩余天数
   * @format double
   */
  remainingDays?: number;
  /** 租户ID */
  tenantId?: string | null;
  /** 办公室 */
  organizationUnitId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 备注 */
  remark?: string | null;
  /** 案件关联方信息 */
  caseRelatedPartys?: CaseRelatedPartyDto[] | null;
  /** 案件参与律师 */
  caseLawyers?: CaseLawyerDto[] | null;
  /** 利冲附件信息列表 */
  attachmentList?: AttachmentDownloadDto[] | null;
  /** 利冲豁免附件信息列表 */
  exemptionAttachmentList?: AttachmentDownloadDto[] | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface CsrRequestDataDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  response?: CsrRequestDataDto;
}

/** 手动发送邮件入参 */
export interface CsrRequestEmailRemindInput {
  /** 需确认的合伙人 */
  inChargeLawyer?: string | null;
  /** 邮件列表 */
  emails?: string[] | null;
  id?: string | null;
}

/** 利冲邮箱规则设置 */
export interface CsrRequestEmailSettingInput {
  /** 推送日期设置 */
  weekList?: (0 | 1 | 2 | 3 | 4 | 5 | 6)[] | null;
  /** 提醒时间设置 */
  reminderTime?: string[] | null;
  /** 是否节假日提醒 */
  isHolidays?: boolean;
  /** 抄送角色 */
  ccRoles?: string[] | null;
  /** 常用发送邮箱 */
  commonEmails?: string[] | null;
}

/** 上传解决方案审批 */
export interface CsrRequestExaminerConfirmApprovalInput {
  /** 解决方案 */
  conflictNotes?: string | null;
  /** 业务Id */
  id?: string | null;
  /** 审批意见 */
  approvalOpinion?: string | null;
  /** 附件 */
  attachments?: string | null;
  /** 传参 */
  paramsString?: string | null;
}

/** 利益冲突列表 */
export interface CsrRequestInfoOutput {
  /** 利冲号 */
  serialId?: string | null;
  /** 利冲名称 */
  name?: string | null;
  /** 利冲案件类型 */
  category?: string | null;
  /** 利冲案件类型文本 */
  categoryText?: string | null;
  /** 关联案件编号 */
  relationCaseId?: string | null;
  /** 客户行业 */
  clientIndustryId?: string | null;
  /** 客户行业文本 */
  clientIndustryText?: string | null;
  /** 执业领域 */
  practiceArea?: string | null;
  /** 执业领域文本 */
  practiceAreaText?: string | null;
  /** 是否涉密 */
  isClassified?: string | null;
  /** 是否涉密文本 */
  isClassifiedText?: string | null;
  /** 是否紧急 */
  isUrgent?: string | null;
  /** 是否紧急文本 */
  isUrgentText?: string | null;
  /** 收费方式 */
  chargingMethods?: string | null;
  /** 收费方式文本 */
  chargingMethodsText?: string | null;
  /**
   * 合同金额
   * @format double
   */
  contractAmount?: number | null;
  /** 委托事项 */
  entrustedMatters?: string | null;
  /** 客户名称 */
  clientName?: string | null;
  /** 客户外文名称 */
  clientForeignName?: string | null;
  /** 客户股东实控人名称 */
  clientShareholderActualCtrlName?: string | null;
  /** 客户股东实控人外文名称 */
  clientShareholderActualCtrlForeignName?: string | null;
  /** 客户参与实体名称 */
  clientParticipatingEntityName?: string | null;
  /** 客户参与实体外文名称 */
  clientParticipatingEntityForeignName?: string | null;
  /** 对方当事人 */
  oppositeName?: string | null;
  /** 对方当事人外文名称 */
  oppositeForeignName?: string | null;
  /** 对方股东实控人名称 */
  oppositeShareholderActualCtrlName?: string | null;
  /** 对方股东实控人外文名称 */
  oppositeShareholderActualCtrlForeignName?: string | null;
  /** 对方参与实体名称 */
  oppositeParticipatingEntityName?: string | null;
  /** 对方参与实体外文名称 */
  oppositeParticipatingEntityForeignName?: string | null;
  /** 潜在第三方名称 */
  potential3rdPartyName?: string | null;
  /** 潜在第三方外文名称 */
  potential3rdPartyForeignName?: string | null;
  /** 潜在第三方股东实控人 */
  potential3rdPartyShareholderActualCtrlName?: string | null;
  /** 潜在第三方股东实控人外文名称 */
  potential3rdPartyShareholderActualCtrlForeignName?: string | null;
  /** 申请合伙人 */
  createBy?: string | null;
  /** 申请合伙人名称 */
  createByName?: string | null;
  /** 说明描述 */
  narrative?: string | null;
  /** 利冲转立案案件Id */
  caseId?: string | null;
  /** 利冲转立案案件名称 */
  caseName?: string | null;
  /** 利冲关联案件类型 */
  caseCategory?: string | null;
  /** 利冲关联案件类型文本 */
  caseCategoryText?: string | null;
  /** 利冲关联案件状态 */
  caseStatus?: string | null;
  /** 利冲适用规则 */
  csrRule?: string | null;
  /** 利冲适应规则文本 */
  csrRuleText?: string | null;
  /** 利冲时效是否过期 */
  isExpired?: string | null;
  /** 利冲时效是否过期文本 */
  isExpiredText?: string | null;
  /**
   * 利冲审查进度
   * @format double
   */
  rateOfProgress?: number;
  /**
   * 利冲时效剩余天数
   * @format float
   */
  remainingDays?: number;
  /** 利冲转立案案件编号 */
  caseSerialId?: string | null;
  /** 办公室 */
  officeName?: string | null;
  caseRelatedPartys?: CaseRelatedPartyDto[] | null;
  caseLawyers?: CaseLawyerDto[] | null;
  /** 利冲附件编号 */
  attachments?: string | null;
  /** 利冲豁免附件编号 */
  exemptionAttachments?: string | null;
  /** 利冲附件信息列表 */
  attachmentList?: AttachmentDownloadDto[] | null;
  /** 利冲豁免附件信息列表 */
  exemptionAttachmentList?: AttachmentDownloadDto[] | null;
  /** 利冲检索结果集 */
  csrProcessResults?: CsrSearchProcessResultDto[] | null;
  /** 利冲审查表 */
  csrProcessInfo?: CsrProcessDto;
  /** 利冲豁免确认信息 */
  csrExemptions?: CSRExemptionOutput[] | null;
  /** 关联案件名称 */
  relationCaseName?: string | null;
  /** 关联案件编号 */
  relationCaseSerialId?: string | null;
  /** 申请人 */
  applicant?: string | null;
  /** 申请人 */
  applicantName?: string | null;
  /** 申请人邮箱 */
  applicantEmailAddress?: string | null;
  /** 审查Id */
  csrProcessId?: string | null;
  /** 是否启用法律意义利冲检索 */
  isEnabledLegalSenseSearch?: boolean | null;
  /**
   * 提交时间
   * @format date-time
   */
  submittedOn?: string | null;
  /** 是否克隆数据 */
  isClone?: boolean;
  /** 克隆主键id */
  cloneId?: string | null;
  sourceId?: string | null;
  fromId?: string | null;
  /** 租户编号 */
  tenantId?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface CsrRequestInfoOutputMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 利益冲突列表 */
  response?: CsrRequestInfoOutput;
}

export interface CsrRequestNoConfirmOutput {
  /** 需确认合伙人 */
  confirmLawyerId?: string | null;
  /** 需确认合伙人 */
  confirmLawyerName?: string | null;
  /** 需确认合伙人 */
  confirmLawyerPicture?: string | null;
  /** 利冲号 */
  serialId?: string | null;
  /** 利冲名称 */
  name?: string | null;
  /** 利冲案件类型 */
  category?: string | null;
  /** 利冲案件类型文本 */
  categoryText?: string | null;
  /** 关联案件编号 */
  relationCaseId?: string | null;
  /** 客户行业 */
  clientIndustryId?: string | null;
  /** 客户行业文本 */
  clientIndustryText?: string | null;
  /** 执业领域 */
  practiceArea?: string | null;
  /** 执业领域文本 */
  practiceAreaText?: string | null;
  /** 是否涉密 */
  isClassified?: string | null;
  /** 是否涉密文本 */
  isClassifiedText?: string | null;
  /** 是否紧急 */
  isUrgent?: string | null;
  /** 是否紧急文本 */
  isUrgentText?: string | null;
  /** 收费方式 */
  chargingMethods?: string | null;
  /** 收费方式文本 */
  chargingMethodsText?: string | null;
  /**
   * 合同金额
   * @format double
   */
  contractAmount?: number | null;
  /** 委托事项 */
  entrustedMatters?: string | null;
  /** 客户名称 */
  clientName?: string | null;
  /** 客户外文名称 */
  clientForeignName?: string | null;
  /** 客户股东实控人名称 */
  clientShareholderActualCtrlName?: string | null;
  /** 客户股东实控人外文名称 */
  clientShareholderActualCtrlForeignName?: string | null;
  /** 客户参与实体名称 */
  clientParticipatingEntityName?: string | null;
  /** 客户参与实体外文名称 */
  clientParticipatingEntityForeignName?: string | null;
  /** 对方当事人 */
  oppositeName?: string | null;
  /** 对方当事人外文名称 */
  oppositeForeignName?: string | null;
  /** 对方股东实控人名称 */
  oppositeShareholderActualCtrlName?: string | null;
  /** 对方股东实控人外文名称 */
  oppositeShareholderActualCtrlForeignName?: string | null;
  /** 对方参与实体名称 */
  oppositeParticipatingEntityName?: string | null;
  /** 对方参与实体外文名称 */
  oppositeParticipatingEntityForeignName?: string | null;
  /** 潜在第三方名称 */
  potential3rdPartyName?: string | null;
  /** 潜在第三方外文名称 */
  potential3rdPartyForeignName?: string | null;
  /** 潜在第三方股东实控人 */
  potential3rdPartyShareholderActualCtrlName?: string | null;
  /** 潜在第三方股东实控人外文名称 */
  potential3rdPartyShareholderActualCtrlForeignName?: string | null;
  /** 申请合伙人 */
  createBy?: string | null;
  /** 申请合伙人名称 */
  createByName?: string | null;
  /** 说明描述 */
  narrative?: string | null;
  /** 利冲转立案案件Id */
  caseId?: string | null;
  /** 利冲转立案案件名称 */
  caseName?: string | null;
  /** 利冲关联案件类型 */
  caseCategory?: string | null;
  /** 利冲关联案件类型文本 */
  caseCategoryText?: string | null;
  /** 利冲关联案件状态 */
  caseStatus?: string | null;
  /** 利冲适用规则 */
  csrRule?: string | null;
  /** 利冲适应规则文本 */
  csrRuleText?: string | null;
  /** 利冲时效是否过期 */
  isExpired?: string | null;
  /** 利冲时效是否过期文本 */
  isExpiredText?: string | null;
  /**
   * 利冲审查进度
   * @format double
   */
  rateOfProgress?: number;
  /**
   * 利冲时效剩余天数
   * @format float
   */
  remainingDays?: number;
  /** 利冲转立案案件编号 */
  caseSerialId?: string | null;
  /** 办公室 */
  officeName?: string | null;
  caseRelatedPartys?: CaseRelatedPartyDto[] | null;
  caseLawyers?: CaseLawyerDto[] | null;
  /** 利冲附件编号 */
  attachments?: string | null;
  /** 利冲豁免附件编号 */
  exemptionAttachments?: string | null;
  /** 利冲附件信息列表 */
  attachmentList?: AttachmentDownloadDto[] | null;
  /** 利冲豁免附件信息列表 */
  exemptionAttachmentList?: AttachmentDownloadDto[] | null;
  /** 利冲检索结果集 */
  csrProcessResults?: CsrSearchProcessResultDto[] | null;
  /** 利冲审查表 */
  csrProcessInfo?: CsrProcessDto;
  /** 利冲豁免确认信息 */
  csrExemptions?: CSRExemptionOutput[] | null;
  /** 关联案件名称 */
  relationCaseName?: string | null;
  /** 关联案件编号 */
  relationCaseSerialId?: string | null;
  /** 申请人 */
  applicant?: string | null;
  /** 申请人 */
  applicantName?: string | null;
  /** 申请人邮箱 */
  applicantEmailAddress?: string | null;
  /** 审查Id */
  csrProcessId?: string | null;
  /** 是否启用法律意义利冲检索 */
  isEnabledLegalSenseSearch?: boolean | null;
  /**
   * 提交时间
   * @format date-time
   */
  submittedOn?: string | null;
  /** 是否克隆数据 */
  isClone?: boolean;
  /** 克隆主键id */
  cloneId?: string | null;
  sourceId?: string | null;
  fromId?: string | null;
  /** 租户编号 */
  tenantId?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface CsrRequestNoConfirmOutputListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: CsrRequestNoConfirmOutput[] | null;
}

export interface CsrRequestRemindInput {
  /** 提醒人 */
  confirmLawyerId?: string | null;
  /** 审查结果集Id */
  csrProcessIdList?: string[] | null;
  /** 利冲Id */
  csrRequestIdList?: string[] | null;
}

/** 利冲规则设置 */
export interface CsrRequestSettingInput {
  /** 利冲邮箱规则设置 */
  csrRequestEmailSetting?: CsrRequestEmailSettingInput;
  /** 利冲语音电话规则设置 */
  csrRequestVoiceCallSetting?: CsrRequestVoiceCallSettingInput;
  /** 关联案件限制 */
  relationCaseLimitation?: RelationCaseLimitationInput;
}

/** 通用返回信息类 */
export interface CsrRequestSettingInputMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 利冲规则设置 */
  response?: CsrRequestSettingInput;
}

/** 利冲语音电话规则设置 */
export interface CsrRequestVoiceCallSettingInput {
  /** 提醒时间设置 */
  reminderTime?: string[] | null;
  /** 是否节假日提醒 */
  isHolidays?: boolean;
}

export interface CsrSearchPreProcessResultDto {
  /** 利冲预检编号 */
  preCsrId?: string | null;
  /** 利冲关键词 */
  csrKeyword?: string | null;
  /** 与我冲突案件的角色 */
  csrParty?: string | null;
  /** 我被冲突案件的角色 */
  caseParty?: string | null;
  /** 我的案件冲突方名称 */
  casePartyName?: string | null;
  /** 匹配客户名称 */
  clientName?: string | null;
  /** 匹配客户外文名称 */
  clientForeignName?: string | null;
  /** 匹配客户实控人名称 */
  clientShareholderActualCtrlName?: string | null;
  /** 匹配客户实控人外文名称 */
  clientShareholderActualCtrlForeignName?: string | null;
  /** 客户参与实体名称 */
  clientParticipatingEntityName?: string | null;
  /** 客户参与实体外文名称 */
  clientParticipatingEntityForeignName?: string | null;
  /** 匹配对方名称 */
  oppositeName?: string | null;
  /** 匹配对方外文名称 */
  oppositeForeignName?: string | null;
  /** 匹配对方实控人名称 */
  oppositeShareholderActualCtrlName?: string | null;
  /** 匹配对方实控人外文名称 */
  oppositeShareholderActualCtrlForeignName?: string | null;
  /** 潜在第三方名称 */
  potential3rdPartyName?: string | null;
  /** 潜在第三方外文名称 */
  potential3rdPartyForeignName?: string | null;
  /** 利冲关联业务类型 */
  csrRelationType?: string | null;
  /** 利冲关联业务类型文本 */
  csrRelationTypeText?: string | null;
  /** 关联业务编号 */
  relationId?: string | null;
  /** 利冲流水号 */
  csrSerialId?: string | null;
  /** 案件流水号 */
  caseSerialId?: string | null;
  /** 案件/利冲号 */
  serialId?: string | null;
  /** 冲突产生时关联业务状态 */
  relationStatus?: string | null;
  /** 匹配利冲规则 */
  matchingRule?: string | null;
  /** 关联业务类型 */
  relationCategory?: string | null;
  /** 关联业务类型文本 */
  relationCategoryText?: string | null;
  /** 关联业务名称 */
  relationName?: string | null;
  /**
   * 关联业务申请时间
   * @format date-time
   */
  relationRequestTime?: string | null;
  /** 关联业务主办合伙人 */
  relationInChargeLawyerId?: string | null;
  /** 关联业务主办合伙人名称 */
  relationInChargeLawyerName?: string | null;
  /** 关联业务申请合伙人 */
  relationRequestUserId?: string | null;
  /** 关联业务申请合伙人名称 */
  relationRequestUserName?: string | null;
  /** 关系列表 */
  relationList?: CaseRelatedResultDto[] | null;
  /** 关联业务案源人 */
  relationCaseUser?: string | null;
  /** 关联业务案源人名称 */
  relationCaseUserName?: string | null;
  /** 账单管理人 */
  relationBillingLawyerNameId?: string | null;
  /** 账单管理人名称 */
  relationBillingLawyerName?: string | null;
  /**
   * 结案时间
   * @format date-time
   */
  relationEndTime?: string | null;
  /** 关联关系json */
  caseRelatedResultJson?: string | null;
  /**
   * 提交时间
   * @format date-time
   */
  submittedOn?: string | null;
  /** 委托事项 */
  entrustedMatters?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用分页信息类 */
export interface CsrSearchPreProcessResultDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: CsrSearchPreProcessResultDto[] | null;
}

/** 通用返回信息类 */
export interface CsrSearchPreProcessResultDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: CsrSearchPreProcessResultDtoPageModel;
}

export interface CsrSearchProcessResultDto {
  /** 利冲申请编号 */
  csrId?: string | null;
  /**
   * 索引
   * @format int32
   */
  index?: number;
  /** 利冲审查编号 */
  csrProcessId?: string | null;
  /** 是否确认 */
  isChecked?: string | null;
  /** 是否需要豁免 */
  isNeedExemption?: string | null;
  /** 利冲关键词 */
  csrKeyword?: string | null;
  /** 与我冲突案件的角色 */
  csrParty?: string | null;
  /** 我被冲突案件的角色 */
  caseParty?: string | null;
  /** 我的案件冲突方名称 */
  casePartyName?: string | null;
  /** 冲突等级 */
  csrLevel?: string | null;
  /** 利冲关联业务类型 */
  csrRelationType?: string | null;
  /** 利冲关联业务类型文本 */
  csrRelationTypeText?: string | null;
  /** 关联业务编号 */
  relationId?: string | null;
  /** 利冲申请编号 */
  csrSerialId?: string | null;
  /** 案件编号 */
  caseSerialId?: string | null;
  /** 冲突产生时关联业务状态 */
  relationStatus?: string | null;
  /** 利冲豁免结果 */
  exemptionResult?: string | null;
  /** 匹配利冲规则 */
  matchingRule?: string | null;
  /** 委托事项 */
  entrustedMatters?: string | null;
  /** 匹配客户名称 */
  clientName?: string | null;
  /** 匹配客户外文名称 */
  clientForeignName?: string | null;
  /** 匹配客户实控人名称 */
  clientShareholderActualCtrlName?: string | null;
  /** 匹配客户实控人外文名称 */
  clientShareholderActualCtrlForeignName?: string | null;
  /** 客户参与实体名称 */
  clientParticipatingEntityName?: string | null;
  /** 客户参与实体外文名称 */
  clientParticipatingEntityForeignName?: string | null;
  /** 匹配对方名称 */
  oppositeName?: string | null;
  /** 匹配对方外文名称 */
  oppositeForeignName?: string | null;
  /** 匹配对方实控人名称 */
  oppositeShareholderActualCtrlName?: string | null;
  /** 匹配对方实控人外文名称 */
  oppositeShareholderActualCtrlForeignName?: string | null;
  /** 潜在第三方名称 */
  potential3rdPartyName?: string | null;
  /** 潜在第三方外文名称 */
  potential3rdPartyForeignName?: string | null;
  /** 案件类型 */
  relationCategory?: string | null;
  /** 案件类型类型文本 */
  relationCategoryText?: string | null;
  /** 案件名称 */
  relationName?: string | null;
  /**
   * 申请时间
   * @format date-time
   */
  relationRequestTime?: string | null;
  /** 主办合伙人 */
  relationInChargeLawyerId?: string | null;
  /** 主办合伙人名称 */
  relationInChargeLawyerName?: string | null;
  /** 申请合伙人 */
  relationRequestUserId?: string | null;
  /** 申请合伙人名称 */
  relationRequestUserName?: string | null;
  /** 关系列表 */
  relationList?: CaseRelatedResultDto[] | null;
  /** 案源人 */
  relationCaseUser?: string | null;
  /** 案源人名称 */
  relationCaseUserName?: string | null;
  /**
   * 结案时间
   * @format date-time
   */
  relationEndTime?: string | null;
  /** 关联关系json */
  caseRelatedResultJson?: string | null;
  /** @format date-time */
  submittedOn?: string | null;
  /** 确认律师Id */
  confirmLawyerId?: string | null;
  /** 确认律师名称 */
  confirmLawyerName?: string | null;
  /** 账单管理人 */
  relationBillingLawyerNameId?: string | null;
  /** 账单管理人名称 */
  relationBillingLawyerName?: string | null;
  /** 客户Id */
  clientId?: string | null;
  /** 案件/利冲号 */
  serialId?: string | null;
  /** 对方关联方名称 */
  oppositeRelatedPartiesName?: string | null;
  /** 对方关联方外文名称 */
  oppositeRelatedPartiesForeignName?: string | null;
  /** 潜在第三方实控人名称 */
  potential3rdPartyShareholderActualCtrlName?: string | null;
  /** 潜在第三方实控人外文名称 */
  potential3rdPartyShareholderActualCtrlForeignName?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用分页信息类 */
export interface CsrSearchProcessResultDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: CsrSearchProcessResultDto[] | null;
}

/** 通用返回信息类 */
export interface CsrSearchProcessResultDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: CsrSearchProcessResultDtoPageModel;
}

export interface DataSelectBranchOfficeDto {
  /** Gets or sets the primary key identifier for the record. */
  id?: string | null;
  /** 账户昵称 */
  name?: string | null;
  /** 企业名称 */
  regName?: string | null;
  /** 信用代码 */
  creditCode?: string | null;
  /** 执业许可证号 */
  licenseNumber?: string | null;
  /** 税号 */
  taxVatNo?: string | null;
  /** 发票代码 */
  invoiceCode?: string | null;
  /** 企业注册地址 */
  address?: string | null;
  /** 联系电话 */
  landline?: string | null;
  /** 银行名称 */
  bankName?: string | null;
  /** 银行账号 */
  bankAccount?: string | null;
  /** 银行代码 */
  bankCode?: string | null;
  /** 开户行 */
  openingBranchName?: string | null;
  /** 开户支行代码 */
  openingBranchCode?: string | null;
  /** 开户行地址 */
  openingBranchAddress?: string | null;
  /** 开户支行电话 */
  openingBranchLandline?: string | null;
  /** 是否默认收款账号 */
  isDefaultCollectionAccount?: string | null;
  /** 是否默认收款账号文本 */
  isDefaultCollectionAccountText?: string | null;
  /** 是否默认付款账号 */
  isDefaultPaymentAccount?: string | null;
  /** 是否默认付款账号文本 */
  isDefaultPaymentAccountText?: string | null;
  /** 货币单位 */
  currency?: string | null;
  /** 货币单位文本 */
  currencyText?: string | null;
  /** 银行国际结算代码 */
  swiftCode?: string | null;
  /** 支付结算代码 */
  cnapsCode?: string | null;
  /** 银联支付号码 */
  unionPayNo?: string | null;
  /** 同城交换号码 */
  localExchangeNumber?: string | null;
  /** 语言 */
  language?: string | null;
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** @format date-time */
  createTime?: string;
}

/** 通用分页信息类 */
export interface DataSelectBranchOfficeDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: DataSelectBranchOfficeDto[] | null;
}

/** 通用返回信息类 */
export interface DataSelectBranchOfficeDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: DataSelectBranchOfficeDtoPageModel;
}

export interface DataSelectCaseDto {
  id?: string | null;
  serialId?: string | null;
  name?: string | null;
  category?: string | null;
  /** 案件类型 */
  categoryText?: string | null;
  /** 书写语言 */
  writtenLanguage?: string | null;
  /** 书写语言 */
  writtenLanguageText?: string | null;
  /** 利冲申请编号 */
  csrId?: string | null;
  reasonText?: string | null;
  clientId?: string | null;
  clientName?: string | null;
  /** 客户外文名称 */
  clientForeignName?: string | null;
  /** 客户编号 */
  clientSerialId?: string | null;
  inChargeLawyer?: string | null;
  coWorkingLawyer?: string | null;
  caseSourceLawyer?: string | null;
  billingLawyer?: string | null;
  applicant?: string | null;
  inChargeLawyerName?: string | null;
  coWorkingLawyerName?: string | null;
  caseSourceLawyerName?: string | null;
  billingLawyerName?: string | null;
  applicantName?: string | null;
  status?: string | null;
  /** @format date-time */
  submittedOn?: string;
  /** @format date-time */
  closedTime?: string;
  /** @format date-time */
  createTime?: string;
  officeId?: string | null;
  officeName?: string | null;
}

/** 通用分页信息类 */
export interface DataSelectCaseDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: DataSelectCaseDto[] | null;
}

/** 通用返回信息类 */
export interface DataSelectCaseDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: DataSelectCaseDtoPageModel;
}

export interface DataSelectCaseLawyerInfoDto {
  /** 利冲/案件编号 */
  caseId?: string | null;
  /** 用户编号 */
  userId?: string | null;
  /** 用户名称 */
  displayName?: string | null;
  /** 用户头像 */
  pictureUrl?: string | null;
  /** 用户邮箱 */
  emailAddress?: string | null;
  /** 律师参案角色 */
  lawyerRole?: string | null;
  /** 律师参案角色文本 */
  lawyerRoleText?: string | null;
  /**
   * 律师费率
   * @format double
   */
  chargeRatio?: number;
  /**
   * 服务开始时间
   * @format date-time
   */
  startDate?: string;
  /**
   * 服务结束时间
   * @format date-time
   */
  endDate?: string;
  /** 是否非当前服务律师 */
  isInActive?: boolean;
  /**
   * 排序
   * @format int32
   */
  sort?: number;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
}

/** 通用分页信息类 */
export interface DataSelectCaseLawyerInfoDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: DataSelectCaseLawyerInfoDto[] | null;
}

/** 通用返回信息类 */
export interface DataSelectCaseLawyerInfoDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: DataSelectCaseLawyerInfoDtoPageModel;
}

export interface DataSelectClientDto {
  id?: string | null;
  serialId?: string | null;
  name?: string | null;
  foreignName?: string | null;
  creditType?: string | null;
  creditTypeText?: string | null;
  creditCode?: string | null;
  /** @format date-time */
  createTime?: string;
  /** 是否查询所务客户 */
  isOfficeAffairs?: boolean | null;
}

/** 通用分页信息类 */
export interface DataSelectClientDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: DataSelectClientDto[] | null;
}

/** 通用返回信息类 */
export interface DataSelectClientDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: DataSelectClientDtoPageModel;
}

export interface DataSelectClientInvoiceHeaderDto {
  /** Gets or sets the primary key identifier for the record. */
  id?: string | null;
  /** Gets or sets the name of the bank. */
  bank?: string | null;
  /** Gets or sets the bank account number. */
  bankAccount?: string | null;
  /** Gets or sets the invoice title information. */
  invoiceTitle?: string | null;
  /** Gets or sets the credit code, usually a registration number for an organization. */
  creditCode?: string | null;
  /** Gets or sets the address of the bank branch. */
  address?: string | null;
  /** Gets or sets the telephone number of the bank branch. */
  tel?: string | null;
  /** Gets or sets the client identifier. */
  clientId?: string | null;
  /** Gets or sets the name of the client. */
  clientName?: string | null;
  /** @format date-time */
  createTime?: string;
}

/** 通用分页信息类 */
export interface DataSelectClientInvoiceHeaderDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: DataSelectClientInvoiceHeaderDto[] | null;
}

/** 通用返回信息类 */
export interface DataSelectClientInvoiceHeaderDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: DataSelectClientInvoiceHeaderDtoPageModel;
}

export interface DataSelectClientPaymentDto {
  /** 编号 */
  id?: string | null;
  /** 名称 */
  name?: string | null;
  /** 外文名称 */
  foreignName?: string | null;
  /** 证件类型 */
  creditType?: string | null;
  /** 证件类型文本 */
  creditTypeText?: string | null;
  /** 证件号码 */
  creditCode?: string | null;
  /** 开户行 */
  openingBank?: string | null;
  /** 账号 */
  account?: string | null;
  /** 税号 */
  taxNumber?: string | null;
  /** 地址 */
  address?: string | null;
  /** 联系电话 */
  landline?: string | null;
  /** 客户编号 */
  clientId?: string | null;
  /** 客户流水号 */
  clientSerialId?: string | null;
  /** 客户名称 */
  clientName?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
}

/** 通用分页信息类 */
export interface DataSelectClientPaymentDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: DataSelectClientPaymentDto[] | null;
}

/** 通用返回信息类 */
export interface DataSelectClientPaymentDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: DataSelectClientPaymentDtoPageModel;
}

export interface DataSelectFlowStatusDto {
  /** Gets or sets the value of the status. */
  name?: string | null;
  /** Gets or sets the display name of the status. */
  displayName?: string | null;
  /** Gets or sets a description of the status. */
  description?: string | null;
  /** Gets or sets a value indicating whether this status is related to an audit process. */
  isAudit?: boolean;
  /**
   * Gets or sets the sorting order of the status.
   * @format int32
   */
  sort?: number;
  /**
   * Gets or sets the category of the status.
   * @format int32
   */
  category?: number;
  /** 分类名称 */
  categoryName?: string | null;
}

/** 通用分页信息类 */
export interface DataSelectFlowStatusDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: DataSelectFlowStatusDto[] | null;
}

/** 通用返回信息类 */
export interface DataSelectFlowStatusDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: DataSelectFlowStatusDtoPageModel;
}

export interface DataSelectOrganizationUnitDto {
  /** Gets or sets the unique identifier for the entity. */
  id?: string | null;
  /** Gets or sets the code associated with the entity. */
  code?: string | null;
  /** Gets or sets the display name of the entity. */
  displayName?: string | null;
  /** Gets or sets an alternative name for the entity. */
  alterName?: string | null;
  /** Gets or sets the region code where the entity is located. */
  regionCode?: string | null;
  /** Gets or sets the identifier of the parent entity. */
  parentId?: string | null;
  /** Gets or sets the name of the parent entity. */
  parentName?: string | null;
  /**
   * Gets or sets the sort order of the entity.
   * @format int32
   */
  sort?: number;
  /**
   * Gets or sets a flag indicating the type of threshold.
   * @format int32
   */
  thresholdFlag?: 0 | 1 | 2 | 3;
  /** Gets or sets the name corresponding to the threshold flag. */
  thresholdFlagName?: string | null;
  /** Gets or sets the GPS location of the entity. */
  location?: string | null;
  /** Gets or sets the work address of the entity. */
  workAddress?: string | null;
  /** Gets or sets a value indicating whether the entity is active. */
  isActive?: boolean;
  /** Gets or sets a value indicating whether the entity is virtual. */
  isVirtual?: boolean;
  /** Gets or sets any remarks or additional notes about the entity. */
  remark?: string | null;
  /** @format date-time */
  createTime?: string;
}

/** 通用分页信息类 */
export interface DataSelectOrganizationUnitDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: DataSelectOrganizationUnitDto[] | null;
}

/** 通用返回信息类 */
export interface DataSelectOrganizationUnitDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: DataSelectOrganizationUnitDtoPageModel;
}

export interface DataSelectRoleDto {
  /** Gets or sets the name of the role. */
  name?: string | null;
  /** Gets or sets an alternative name for the role. */
  alterName?: string | null;
  /** Gets or sets any descriptive notes about the role. */
  description?: string | null;
  /** Gets or sets a value indicating whether the role is exclusive. */
  isMonopoly?: boolean;
  /**
   * Gets or sets the type of the role.
   * @format int32
   */
  roleType?: 0 | 1 | 2 | 3;
  /** Gets or sets the name of the role type. */
  roleTypeName?: string | null;
  /** Gets or sets the identifier of the role group. */
  roleGroupId?: string | null;
  /** Gets or sets a value indicating whether the role is active. */
  isActive?: boolean;
  /** Gets or sets a value indicating whether the role is the default one. */
  isDefault?: boolean;
  /** Gets or sets a value indicating whether the role is virtual. */
  isVirtual?: boolean;
  /** Gets or sets the unique identifier for the role. */
  id?: string | null;
  /** @format date-time */
  createTime?: string;
}

/** 通用分页信息类 */
export interface DataSelectRoleDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: DataSelectRoleDto[] | null;
}

/** 通用返回信息类 */
export interface DataSelectRoleDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: DataSelectRoleDtoPageModel;
}

export interface DataSelectUserInfoDto {
  /** Gets or sets the login name of the user. */
  userName?: string | null;
  /** Gets or sets the display name of the user. */
  displayName?: string | null;
  /** Gets or sets the job number of the user. */
  jobNumber?: string | null;
  /** Gets or sets an alternative name for the user. */
  alterName?: string | null;
  /** Gets or sets the phone number of the user. */
  phoneNumber?: string | null;
  /** Gets or sets the URL of the user's profile picture. */
  pictureUrl?: string | null;
  /** Gets or sets the email address of the user. */
  emailAddress?: string | null;
  /** Gets or sets the full name of the user. */
  name?: string | null;
  /** Gets or sets a value indicating whether the email address is confirmed. */
  isEmailConfirmed?: boolean;
  /** Gets or sets a value indicating whether the phone number is confirmed. */
  isPhoneNumberConfirmed?: boolean;
  /** Gets or sets a value indicating whether two-factor authentication is enabled. */
  isTwoFactorEnabled?: boolean;
  /** Gets or sets the name of the organization unit. */
  organizationUnitName?: string | null;
  /** Gets or sets the name of the office. */
  officeName?: string | null;
  /** Gets or sets the identifier of the office. */
  officeId?: string | null;
  /** Gets or sets the list of role names associated with the user. */
  roleNames?: string[] | null;
  /** Gets or sets the list of role identifiers associated with the user. */
  roleIds?: string[] | null;
  /** Gets or sets the list of position names associated with the user. */
  positionNames?: string[] | null;
  /** Gets or sets the list of position identifiers associated with the user. */
  positionIds?: string[] | null;
  /** Gets or sets the list of user group names associated with the user. */
  userGroupNames?: string[] | null;
  /** Gets or sets the list of user group identifiers associated with the user. */
  userGroupIds?: string[] | null;
  /**
   * Gets or sets the status of the user.
   * @format int32
   */
  userStatus?: 0 | 1 | 2;
  /** Gets or sets the text representation of the user status. */
  userStatusText?: string | null;
  /** Gets or sets a value indicating whether the user is active. */
  isActive?: boolean;
  /** Gets or sets the identifier for the timekeeping record. */
  timekeeperId?: string | null;
  /**
   * Gets or sets the date and time when the user joined the organization.
   * @format date-time
   */
  entryTime?: string | null;
  /** Gets or sets the identifier of the organization unit. */
  organizationUnitId?: string | null;
  /**
   * Gets or sets the type of the user.
   * @format int32
   */
  userType?: 0 | 1 | 2;
  /** Gets or sets the text representation of the user type. */
  userTypeText?: string | null;
  /** Gets or sets the unique identifier for the user. */
  id?: string | null;
  /** @format date-time */
  createTime?: string;
  /** 费率列表 */
  employeeRates?: EmployeeRatesDto[] | null;
}

/** 通用分页信息类 */
export interface DataSelectUserInfoDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: DataSelectUserInfoDto[] | null;
}

/** 通用返回信息类 */
export interface DataSelectUserInfoDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: DataSelectUserInfoDtoPageModel;
}

/** 日期和时区偏好设置 */
export interface DateAndTimePreferences {
  /** 是否使用系统时区 */
  isUseSystemTimeZone?: boolean;
  /** 时区 */
  timeZoneId?: string | null;
  /** 时区名称 */
  timeZoneName?: string | null;
  /**
   * 时间显示格式
   * @format int32
   */
  timeClockFormat?: 12 | 24;
  /** 工作日开始于 */
  theWorkdayBeginsAt?: string | null;
  /**
   * 记录时间格式
   * @format int32
   */
  timeRecordingFormat?: 0 | 1 | 2;
}

export interface DayHoursDto {
  /** 工时编号 */
  ids?: string | null;
  /**
   * 工时日期
   * @format date-time
   */
  workDate?: string;
  /** @format int32 */
  dayOfWeek?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  /**
   * 数量
   * @format int32
   */
  count?: number;
  /**
   * 日工作记录总小时数
   * @format double
   */
  hours?: number;
  /** 是否只读 */
  isReadOnly?: boolean;
}

export interface DingtalkDepartmentDto {
  id?: string | null;
  /** 应用编号 */
  appId?: string | null;
  /** 部门ID */
  deptId?: string | null;
  /** 部门名称 */
  name?: string | null;
  /** 父部门Id */
  parentId?: string | null;
  /** 是否同步创建一个关联此部门的企业群 */
  createDeptGroup?: boolean | null;
  /** 部门群已经创建后，有新人加入部门是否会自动加入该群 */
  autoAddUser?: boolean | null;
  /** 部门标识字段 */
  sourceIdentifier?: string | null;
  /** 是否默认同意加入该部门的申请 */
  autoApproveApply?: boolean | null;
  /** 部门是否来自关联组织 */
  fromUnionOrg?: boolean | null;
  /** 教育部门标签 */
  tags?: string | null;
  /** 在父部门中的次序值 */
  order?: string | null;
  /** 部门群ID */
  deptGroupChatId?: string | null;
  /** 部门群是否包含子部门 */
  groupContainSubDept?: boolean | null;
  /** 部门ID  DepartmentId */
  organizationUnitId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 备注 */
  remark?: string | null;
  /** 状态 	            中立字段，某些表可使用某些表不使用 */
  isEnabled?: boolean;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface DingtalkDepartmentDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: DingtalkDepartmentDto[] | null;
}

/** 通用返回信息类 */
export interface DingtalkDepartmentDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  response?: DingtalkDepartmentDto;
}

/** 通用分页信息类 */
export interface DingtalkDepartmentDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: DingtalkDepartmentDto[] | null;
}

/** 通用返回信息类 */
export interface DingtalkDepartmentDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: DingtalkDepartmentDtoPageModel;
}

export interface DingtalkUserDto {
  id?: string | null;
  /** 应用编号 */
  appId?: string | null;
  /** BitzOrcas UserId */
  relationUserId?: string | null;
  /** 用户名 */
  name?: string | null;
  /** 用户英文名称 */
  enName?: string | null;
  /** 昵称 */
  nickName?: string | null;
  /** 区域代码  +86 */
  stateCode?: string | null;
  /** 邮箱地址 */
  email?: string | null;
  /** 机构邮箱地址 */
  orgEmail?: string | null;
  /** 手机号 */
  mobile?: string | null;
  /** 是否显示手机号 */
  mobileVisible?: boolean | null;
  /** 分机号 */
  telephone?: string | null;
  /** 钉钉用户的open_id */
  openId?: string | null;
  /** 钉钉用户的union_id */
  unionId?: string | null;
  /** 钉钉用户的user_id */
  userId?: string | null;
  /**
   * 性别
   * @format int32
   */
  gender?: number | null;
  /** 用户头像信息 */
  avatar?: string | null;
  /** 员工工号 */
  jobNumber?: string | null;
  /** 员工的直属主管 */
  managerUserId?: string | null;
  /** 所属部门的Id列表 */
  departmentIds?: string | null;
  /** 员工在对应的部门中的排序 json：dept_id,order */
  deptOrderList?: string | null;
  /** 员工所在部门信息及是否是领导 json:dept_id,leader */
  leaderInDept?: string | null;
  /** 工作地点 */
  workPlace?: string | null;
  /** 入职时间 */
  hiredDate?: string | null;
  /** 职务 */
  title?: string | null;
  /** 是否为企业账号 */
  exclusiveAccount?: boolean | null;
  /** 扩展属性，最大长度2000个字符。 */
  extension?: string | null;
  /** 是否激活了钉钉 */
  active?: boolean | null;
  /** 是否完成了实名认证 */
  realAuthed?: boolean | null;
  /** 是否为企业高管 */
  senior?: boolean | null;
  /** 是否为企业的管理员 */
  admin?: boolean | null;
  /** 是否为企业的老板 */
  boss?: boolean | null;
  /** 部门ID  DepartmentId */
  organizationUnitId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 备注 */
  remark?: string | null;
  /** 状态 	            中立字段，某些表可使用某些表不使用 */
  isEnabled?: boolean;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface DingtalkUserDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: DingtalkUserDto[] | null;
}

/** 通用返回信息类 */
export interface DingtalkUserDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  response?: DingtalkUserDto;
}

/** 通用分页信息类 */
export interface DingtalkUserDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: DingtalkUserDto[] | null;
}

/** 通用返回信息类 */
export interface DingtalkUserDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: DingtalkUserDtoPageModel;
}

export interface DingtalkUserRelationDto {
  id?: string | null;
  /** 律智荟 UserId */
  relationUserId?: string | null;
  /** 邮箱地址 */
  email?: string | null;
  /** 手机号 */
  mobile?: string | null;
  /** 钉钉用户的open_id */
  openId?: string | null;
  /** 钉钉用户的union_id */
  unionId?: string | null;
  /** 钉钉用户的user_id */
  userId?: string | null;
  /** 钉钉appid */
  appId?: string | null;
  /** 钉钉用户编号获取状态（N：未同步到，A：同步到其中任一Id） */
  syncStatus?: string | null;
  /** 部门ID  DepartmentId */
  organizationUnitId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 备注 */
  remark?: string | null;
  /** 状态 	            中立字段，某些表可使用某些表不使用 */
  isEnabled?: boolean;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface DingtalkUserRelationDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: DingtalkUserRelationDto[] | null;
}

/** 通用返回信息类 */
export interface DingtalkUserRelationDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  response?: DingtalkUserRelationDto;
}

/** 通用分页信息类 */
export interface DingtalkUserRelationDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: DingtalkUserRelationDto[] | null;
}

/** 通用返回信息类 */
export interface DingtalkUserRelationDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: DingtalkUserRelationDtoPageModel;
}

export interface DisplayDesensitizationDefaultRuleDto {
  id?: string | null;
  /**
   * 脱敏类型
   * @format int32
   */
  displayDesensitizationType?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
  /** 脱敏类型 */
  displayDesensitizationTypeText?: string | null;
  /** 显示名称 */
  displayName?: string | null;
  /**
   * 开始保留位
   * @format int32
   */
  startIndex?: number;
  /**
   * 结束保留位
   * @format int32
   */
  endIndex?: number;
  /** 正则表达式 */
  regExp?: string | null;
  /** 备注 */
  remark?: string | null;
}

/** 通用返回信息类 */
export interface DisplayDesensitizationDefaultRuleDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: DisplayDesensitizationDefaultRuleDto[] | null;
}

export interface DisplayDesensitizationModule {
  /** 模块名称 */
  controllerName?: string | null;
  /** 分组名称 */
  groupName?: string | null;
  /** 模块描述 */
  controllerDescription?: string | null;
  /** 模块下脱敏配置列表 */
  displayDesensitizationSettingList?: DisplayDesensitizationSettingDto[] | null;
}

/** 通用返回信息类 */
export interface DisplayDesensitizationModuleListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: DisplayDesensitizationModule[] | null;
}

export interface DisplayDesensitizationOutput {
  /** 创建ID */
  createId?: string | null;
  /** 创建者 */
  createBy?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 显示脱敏规则 */
  displayDesensitizationRules?: DisplayDesensitizationRuleDto[] | null;
  /** 分组名称 */
  groupName?: string | null;
  /** 模块名称 */
  controllerName?: string | null;
  /** 方法名称 */
  actionName?: string | null;
  /** Dto名称 */
  dtoName?: string | null;
  /** 组织机构Id */
  orgId?: string | null;
  /**
   * 配置层级
   * @format int32
   */
  settingLevel?: 0 | 1 | 2 | 3;
  /** 配置层级 */
  settingLevelText?: string | null;
  /** 整体掩盖字符 */
  mainCharacter?: string | null;
  /** 备注 */
  remark?: string | null;
}

/** 通用返回信息类 */
export interface DisplayDesensitizationOutputMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  response?: DisplayDesensitizationOutput;
}

/** 通用分页信息类 */
export interface DisplayDesensitizationOutputPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: DisplayDesensitizationOutput[] | null;
}

/** 通用返回信息类 */
export interface DisplayDesensitizationOutputPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: DisplayDesensitizationOutputPageModel;
}

export interface DisplayDesensitizationRuleDto {
  /** 字段名称 */
  field?: string | null;
  /** 字段类型 */
  fieldType?: string | null;
  /** 字段描述 */
  fieldDescription?: string | null;
  /**
   * 验证规则
   * @format int32
   */
  ruleType?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
  /** 验证规则 */
  ruleTypeText?: string | null;
  /**
   * 开始保留位
   * @format int32
   */
  startIndex?: number;
  /**
   * 结束保留位
   * @format int32
   */
  endIndex?: number;
  /** 正则表达式 */
  regExp?: string | null;
  /** 脱敏替换字符 */
  character?: string | null;
  /** 是否脱敏 */
  isDesensitization?: boolean;
  /** 是否自动脱敏 */
  isAutoDesensitization?: boolean;
}

export interface DisplayDesensitizationSettingDto {
  /** Dto名称 */
  dtoName?: string | null;
  /** 请求地址 */
  url?: string | null;
  /** 描述 */
  actionDescription?: string | null;
  /** 方法名称 */
  actionName?: string | null;
  /** 控制器名称 */
  controllerName?: string | null;
  /** 模块描述 */
  controllerDescription?: string | null;
  /** 分组名称 */
  groupName?: string | null;
  /**
   * 配置层级
   * @format int32
   */
  settingLevel?: 0 | 1 | 2 | 3;
  /** 配置层级 */
  settingLevelText?: string | null;
  /** 整体掩盖字符 */
  mainCharacter?: string | null;
  /** 显示脱敏 */
  displayDesensitizationRules?: DisplayDesensitizationRuleDto[] | null;
}

/** 新兴行业分类 */
export interface EmergingIndustyListItem {
  /** 一级分类Code */
  primaryCode?: string | null;
  /** 一级分类描述 */
  primaryDes?: string | null;
  /** 二级分类 */
  secondaryList?: SecondaryListItem[] | null;
}

/** 工商登记主要人员（返回前100条） */
export interface EmployeeListItem {
  /** 主键 */
  keyNo?: string | null;
  /** 名称 */
  name?: string | null;
  /** 职务 */
  job?: string | null;
}

export interface EmployeeRate {
  /** 币种 */
  currency?: string | null;
  /**
   * 费率
   * @format double
   */
  rate?: number;
  /** 是否默认 */
  isDefault?: boolean;
}

export interface EmployeeRatesDto {
  /** 用户Id */
  userId?: string | null;
  /** 币种 */
  currency?: string | null;
  /** 币种文本 */
  currencyText?: string | null;
  /**
   * 费率
   * @format double
   */
  rate?: number;
  /** 是否默认 */
  isDefault?: boolean;
  /**
   * 生效日期
   * @format date-time
   */
  effectiveDate?: string | null;
  /** 计时人员编号 */
  timekeeperNumber?: string | null;
  /** 邮箱 */
  email?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface EmployeeRatesDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: EmployeeRatesDto[] | null;
}

export interface EmployeeRatesInput {
  /** 用户Id */
  userId?: string | null;
  /** 用户费率 */
  employeeRateList?: EmployeeRate[] | null;
  /**
   * 生效日期
   * @format date-time
   */
  effectiveDate?: string | null;
}

/** 通用返回信息类 */
export interface EmployeeRatesInputMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  response?: EmployeeRatesInput;
}

export interface EnterpriseBaseInfoDto {
  /** 公司名称 */
  enterpriseName?: string | null;
  /** 执行许可号 */
  enterpriseCode?: string | null;
  /** 法人姓名 */
  corporation?: string | null;
  /** 统一社会信用代码 */
  creditCode?: string | null;
  /** 邮箱地址 */
  email?: string | null;
  /** 注册地址 */
  enterpriseAddress?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 企业详细信息 */
export interface EnterpriseInfoDto {
  /** Id */
  id?: string | null;
  /** 主键 */
  keyNo?: string | null;
  /** 企业名称 */
  name?: string | null;
  /** 统一社会信用代码 */
  creditCode?: string | null;
  /** 法定代表人 */
  operName?: string | null;
  /** 登记状态 */
  status?: string | null;
  /** 成立日期，精确到天，如“2022-01-01” */
  startDate?: string | null;
  /** 注册资本 */
  registCapi?: string | null;
  /** 实缴资本 */
  realCapi?: string | null;
  /** 组织机构代码 */
  orgNo?: string | null;
  /** 注册号或企业编号 */
  no?: string | null;
  /** 纳税人识别号 */
  taxNo?: string | null;
  /** 企业类型 */
  econKind?: string | null;
  /** 营业期限始，精确到天，如“2022-01-01” */
  termStart?: string | null;
  /** 营业期限至，精确到天，如“2022-01-01” */
  termEnd?: string | null;
  /** 纳税人资质 */
  taxpayerType?: string | null;
  /** 人员规模 */
  personScope?: string | null;
  /** 参保人数 */
  insuredCount?: string | null;
  /** 核准日期，精确到天，如“2022-01-01” */
  checkDate?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 所属地区代码 */
  areaCode?: string | null;
  /** 行政区域 */
  area?: Area;
  /** 登记机关 */
  belongOrg?: string | null;
  /** 进出口企业代码 */
  imExCode?: string | null;
  /** 国标行业 */
  industry?: IndustryInfo;
  /** 英文名 */
  englishName?: string | null;
  /** 注册地址 */
  address?: string | null;
  /** 通信地址 */
  annualAddress?: string | null;
  /** 经营范围 */
  scope?: string | null;
  /** 企业性质，0-大陆企业，1-社会组织 ，3-中国香港公司，4-事业单位，5-中国台湾公司，7-医院 */
  entType?: string | null;
  /** 企业Logo地址 */
  imageUrl?: string | null;
  /** 注销吊销信息 */
  revokeInfo?: RevokeInfo;
  /** 曾用名 */
  originalName?: string | null;
  /** 上市信息 */
  stockInfo?: StockInfo;
  /** 联系信息 */
  contactInfo?: ContactInfo;
  /** 经纬度 */
  longLat?: LongLat;
  /** 开票信息 */
  bankInfo?: BankInfo;
  /** 是否是小微企业，1-是，0-不是 */
  isSmall?: string | null;
  /** 企业规模（L：大型、M：中型、S：小型、XS：微型） */
  scale?: string | null;
  /** 企查查行业 */
  qccIndustry?: QccIndustry;
  /** 英文名来源 1：官方标识 ，0：非官方标识，-1：未标识 */
  isOfficialEnglish?: string | null;
  /** 工商登记股东信息（返回前100条） */
  partnerList?: PartnerListItem[] | null;
  /** 最新公示股东信息（返回前100条） */
  pubPartnerList?: PubPartnerListItem[] | null;
  /** 工商登记主要人员（返回前100条） */
  employeeList?: EmployeeListItem[] | null;
  /** 最新公示主要人员（返回前100条） */
  pubEmployeeList?: PubEmployeeListItem[] | null;
  /** 分支机构（返回前100条） */
  branchList?: BranchListItem[] | null;
  /** 变更信息（返回前100条） */
  changeList?: ChangeListItem[] | null;
  /** 企业标签\企业画像 */
  tagList?: TagListItem[] | null;
  /** 总公司 */
  parent?: Parent;
  /** 受益所有人 */
  beneficiaryList?: Beneficiary[] | null;
  /** 实际控制人 */
  actualControllerList?: ActualControllerListItem[] | null;
  /** 新兴行业分类 */
  emergingIndustyList?: EmergingIndustyListItem[] | null;
  /** 所属集团 */
  groupInfo?: string | null;
  /** 对外投资（返回前100条） */
  investmentList?: InvestmentListItem[] | null;
  /** 产品列表（返回前100条） */
  productList?: ProductListItem[] | null;
  /** 行政许可（返回前100条） */
  adminLicenseList?: AdminLicenseListItem[] | null;
  /** 备案网站（返回前100条） */
  approveSiteList?: ApproveSiteListItem[] | null;
  /** 抽查检查（返回前100条） */
  spotCheckList?: SpotCheckListItem[] | null;
  /** 纳税信用等级（返回前100条） */
  taxCreditList?: TaxCreditListItem[] | null;
  /** 曾用名 */
  originalNameList?: OriginalName[] | null;
}

/** 通用返回信息类 */
export interface EnterpriseInfoDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 企业详细信息 */
  response?: EnterpriseInfoDto;
}

/** 通用分页信息类 */
export interface EnterpriseInfoDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: EnterpriseInfoDto[] | null;
}

/** 通用返回信息类 */
export interface EnterpriseInfoDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: EnterpriseInfoDtoPageModel;
}

/** 企业银行抬头信息 */
export interface EntityBankInfoDto {
  /** 企业名称 */
  name?: string | null;
  /** 统一社会信用代码（纳税人识别号） */
  creditCode?: string | null;
  /** 企业类型 */
  econKind?: string | null;
  /** 企业状态 */
  status?: string | null;
  /** 地址 */
  address?: string | null;
  /** 联系电话 */
  tel?: string | null;
  /** 开户行 */
  bank?: string | null;
  /** 开户行账号 */
  bankAccount?: string | null;
}

/** 通用返回信息类 */
export interface EntityBankInfoDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: EntityBankInfoDto[] | null;
}

/** 实体联系人表 */
export interface EntityContactDto {
  id?: string | null;
  /** 联系人名称 */
  name?: string | null;
  /** 别名 */
  altName?: string | null;
  /** 外文名称 */
  foreignName?: string | null;
  /** 速搜码，简码 */
  shortCode?: string | null;
  /** 身份类别（客户联系人/法院法官/对方联系人/检察官/监狱长等） */
  identityType?: string | null;
  /** 记录来源 */
  loadSource?: string | null;
  /** 性别 */
  gender?: string | null;
  /** 民族 */
  nation?: string | null;
  /**
   * 生日
   * @format date-time
   */
  birthday?: string;
  /** 职位 */
  duty?: string | null;
  /** 头像 */
  avatar?: string | null;
  /** 图像/图片 */
  picture?: string | null;
  /** 邮编 */
  zipCode?: string | null;
  /** 通讯地址 */
  address?: string | null;
  /** 所属地区 */
  region?: string | null;
  /** 画像 */
  portraitNarrative?: string | null;
  /** 邮箱地址 */
  emailAddress?: string | null;
  /** 个人邮箱地址 */
  perEmailAddress?: string | null;
  /** 联系固话 */
  landline?: string | null;
  /** 移动电话 */
  phoneNumber?: string | null;
  /** 个人移动电话 */
  perPhoneNumber?: string | null;
  /** 传真 */
  fax?: string | null;
  /** 网站主页 */
  webPortal?: string | null;
  /** 证件类型 */
  creditType?: string | null;
  /** 证件号码 */
  creditCode?: string | null;
  /** 来源 */
  originSource?: string | null;
  /** 即时通讯类型（微信/Line/Whatapp等） */
  imType?: string | null;
  /** 即时通讯号码 */
  imNumber?: string | null;
  /** 即时通讯描述 */
  imNarrative?: string | null;
  /** 即时通讯类型2 */
  imType2?: string | null;
  /** 即时通讯号码2 */
  imNumber2?: string | null;
  /** 即时通讯描述2 */
  imNarrative2?: string | null;
  /** 社交媒体类型 */
  socialMediaType?: string | null;
  /** 社交媒体编号 */
  socialMediaId?: string | null;
  /** 社交媒体描述 */
  socialMediaNarrative?: string | null;
  /** 描述说明 */
  narrative?: string | null;
  /** 喜好/爱好 */
  beFondOf?: string | null;
  /** 租户ID */
  tenantId?: string | null;
  /** 部门ID  DepartmentId */
  organizationUnitId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 备注 */
  remark?: string | null;
  /** 状态 	                中立字段，某些表可使用某些表不使用 */
  isEnabled?: boolean;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface EntityContactDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: EntityContactDto[] | null;
}

export interface EntityContactOutput {
  /** 客户编号 */
  clientId?: string | null;
  /** 客户流水号 */
  clientSerialId?: string | null;
  /** 客户名称 */
  clientName?: string | null;
  /** 案件编号 */
  caseId?: string | null;
  /** 案件流水号 */
  caseSerialId?: string | null;
  /** 案件名称 */
  caseName?: string | null;
  /** 显示名称 */
  displayName?: string | null;
  id?: string | null;
  /** 联系人名称 */
  name?: string | null;
  /** 别名 */
  altName?: string | null;
  /** 外文名称 */
  foreignName?: string | null;
  /** 速搜码，简码 */
  shortCode?: string | null;
  /** 身份类别（客户联系人/法院法官/对方联系人/检察官/监狱长等） */
  identityType?: string | null;
  /** 记录来源 */
  loadSource?: string | null;
  /** 性别 */
  gender?: string | null;
  /** 民族 */
  nation?: string | null;
  /**
   * 生日
   * @format date-time
   */
  birthday?: string;
  /** 职位 */
  duty?: string | null;
  /** 头像 */
  avatar?: string | null;
  /** 图像/图片 */
  picture?: string | null;
  /** 邮编 */
  zipCode?: string | null;
  /** 通讯地址 */
  address?: string | null;
  /** 所属地区 */
  region?: string | null;
  /** 画像 */
  portraitNarrative?: string | null;
  /** 邮箱地址 */
  emailAddress?: string | null;
  /** 个人邮箱地址 */
  perEmailAddress?: string | null;
  /** 联系固话 */
  landline?: string | null;
  /** 移动电话 */
  phoneNumber?: string | null;
  /** 个人移动电话 */
  perPhoneNumber?: string | null;
  /** 传真 */
  fax?: string | null;
  /** 网站主页 */
  webPortal?: string | null;
  /** 证件类型 */
  creditType?: string | null;
  /** 证件号码 */
  creditCode?: string | null;
  /** 来源 */
  originSource?: string | null;
  /** 即时通讯类型（微信/Line/Whatapp等） */
  imType?: string | null;
  /** 即时通讯号码 */
  imNumber?: string | null;
  /** 即时通讯描述 */
  imNarrative?: string | null;
  /** 即时通讯类型2 */
  imType2?: string | null;
  /** 即时通讯号码2 */
  imNumber2?: string | null;
  /** 即时通讯描述2 */
  imNarrative2?: string | null;
  /** 社交媒体类型 */
  socialMediaType?: string | null;
  /** 社交媒体编号 */
  socialMediaId?: string | null;
  /** 社交媒体描述 */
  socialMediaNarrative?: string | null;
  /** 描述说明 */
  narrative?: string | null;
  /** 喜好/爱好 */
  beFondOf?: string | null;
  /** 租户ID */
  tenantId?: string | null;
  /** 部门ID  DepartmentId */
  organizationUnitId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 备注 */
  remark?: string | null;
  /** 状态 	                中立字段，某些表可使用某些表不使用 */
  isEnabled?: boolean;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface EntityContactOutputMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  response?: EntityContactOutput;
}

/** 通用分页信息类 */
export interface EntityContactOutputPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: EntityContactOutput[] | null;
}

/** 通用返回信息类 */
export interface EntityContactOutputPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: EntityContactOutputPageModel;
}

/** 实体联系人与客户案件关系表 */
export interface EntityContactRelationshipDto {
  id?: string | null;
  /** 联系人编号 */
  contactId?: string | null;
  /** 客户编号 */
  clientId?: string | null;
  /** 案件编号 */
  caseId?: string | null;
  /** 说明描述 */
  narrative?: string | null;
  /** 租户ID */
  tenantId?: string | null;
  /** 部门ID  DepartmentId */
  organizationUnitId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 备注 */
  remark?: string | null;
  /** 状态 	                中立字段，某些表可使用某些表不使用 */
  isEnabled?: boolean;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface EntityContactRelationshipDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: EntityContactRelationshipDto[] | null;
}

/** 通用返回信息类 */
export interface EntityContactRelationshipDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 实体联系人与客户案件关系表 */
  response?: EntityContactRelationshipDto;
}

/** 通用分页信息类 */
export interface EntityContactRelationshipDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: EntityContactRelationshipDto[] | null;
}

/** 通用返回信息类 */
export interface EntityContactRelationshipDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: EntityContactRelationshipDtoPageModel;
}

/** 实体曾用名记录表 */
export interface EntityFormerNameDto {
  id?: string | null;
  /** 实体编号 */
  entityId?: string | null;
  /** 曾用名 */
  name?: string | null;
  /** 外文曾用名称 */
  foreignName?: string | null;
  /**
   * 名称使用开始时间
   * @format date-time
   */
  beginDate?: string;
  /**
   * 名称使用结束时间
   * @format date-time
   */
  endDate?: string;
  /** 记录来源 */
  loadSource?: string | null;
  /** 说明描述 */
  narrative?: string | null;
  /** 实体类别（客户/联系人/股东/实控人/母公司等） */
  entityType?: string | null;
  /** 租户ID */
  tenantId?: string | null;
  /** 部门ID  DepartmentId */
  organizationUnitId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 备注 */
  remark?: string | null;
  /** 状态 	                中立字段，某些表可使用某些表不使用 */
  isEnabled?: boolean;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface EntityFormerNameDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: EntityFormerNameDto[] | null;
}

/** 通用返回信息类 */
export interface EntityFormerNameDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 实体曾用名记录表 */
  response?: EntityFormerNameDto;
}

/** 通用分页信息类 */
export interface EntityFormerNameDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: EntityFormerNameDto[] | null;
}

/** 通用返回信息类 */
export interface EntityFormerNameDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: EntityFormerNameDtoPageModel;
}

/** 实体主体信息表 */
export interface EntityInfoDto {
  /** 外键ID */
  foreignId?: string | null;
  /** 实体或自然人名称 */
  name?: string | null;
  /** 外文名称 */
  foreignName?: string | null;
  /** 曾用户 */
  formerName?: string | null;
  /** 别名 */
  altName?: string | null;
  /** 速搜码 */
  shortCode?: string | null;
  /** 是否自然人 */
  isNaturalPerson?: boolean | null;
  /** 是否涉外实体或个人 */
  isForeign?: boolean | null;
  category?: string | null;
  /** 客户类型 */
  categoryText?: string | null;
  /** 实体类别（客户/客户实控人/股东等） */
  entityType?: string | null;
  /** 实体子类 */
  econKind?: string | null;
  /** 注册号 */
  registerationNumber?: string | null;
  /** 注册名称 */
  registerationName?: string | null;
  /** 注册地址 */
  registerationAddress?: string | null;
  /** 所属行业编号 */
  clientIndustryId?: string | null;
  /** 证件类型 */
  creditType?: string | null;
  /** 证件类型 */
  creditTypeText?: string | null;
  /** 证件号码（身份证/信用代码/护照） */
  creditCode?: string | null;
  /** 地址 */
  address?: string | null;
  /** 性别（自然人独占） */
  gender?: string | null;
  /** 民族（自然人独占） */
  nation?: string | null;
  /** 邮箱地址 */
  emailAddress?: string | null;
  /** 固定电话 */
  landline?: string | null;
  /** 移动电话 */
  phoneNumber?: string | null;
  /** 传真 */
  fax?: string | null;
  /** 邮政编码 */
  zipCode?: string | null;
  /** 网站地址 */
  webPortal?: string | null;
  /**
   * 出生日期（自然人独占）
   * @format date-time
   */
  birthday?: string | null;
  /** 法定代表人 */
  legalPerson?: string | null;
  /** 法定代表人ID */
  legalPersonId?: string | null;
  /** 经营场所 */
  businessAddress?: string | null;
  /** 税号 */
  taxNumber?: string | null;
  /** 组织机构编号 */
  orgNumber?: string | null;
  /** 经营范围 */
  businessScope?: string | null;
  /** 注册资本 */
  registeredCapital?: string | null;
  /** 实缴资本 */
  paidInCapital?: string | null;
  /**
   * 成立日期
   * @format date-time
   */
  establishmentDate?: string | null;
  /** 营业期限 */
  businessTerm?: string | null;
  /**
   * 营业执照发证日期
   * @format date-time
   */
  licenseIssueDate?: string | null;
  /** 发证机关（登记机关） */
  issuingAuthority?: string | null;
  /** 经营状态（登记状态） */
  operationalStatus?: string | null;
  /** 纳税人资质 */
  credential?: string | null;
  /** 纳税人资质ID */
  credentialId?: string | null;
  /**
   * 核准日期
   * @format date-time
   */
  issueDate?: string | null;
  /** 所属国家 */
  country?: string | null;
  /** 所属省或州郡 */
  province?: string | null;
  /** 所属地区 */
  region?: string | null;
  /** 进出口企业代码 */
  importExportCode?: string | null;
  /** 人员规模 */
  staffSize?: string | null;
  /** 参保人数 */
  numberOfInsured?: string | null;
  /** 客户来源 */
  customerSource?: string | null;
  /** 源实体编号 */
  originEntityId?: string | null;
  /** 源实体名称 */
  originEntityName?: string | null;
  /**
   * 业务来源
   * （立案/投标/入库等）
   */
  businessSource?: string | null;
  /** 是否上市企业 */
  isListedEnterprise?: string | null;
  /** 上市地区 */
  listedRegion?: string | null;
  /** 上市机构 */
  listedOrg?: string | null;
  /** 上市类别 */
  listedType?: string | null;
  /** 上市股票代码 */
  listedStockNumber?: string | null;
  /** 是否名录企业 */
  isDirectoryEnterprise?: string | null;
  /** 是否500强企业 */
  isTop500?: string | null;
  /** 是否小微企业 */
  isMicroEnterprise?: string | null;
  /** 标签 */
  tags?: string | null;
  /** 是否披露 */
  isDisclose?: string | null;
  /** 客户级别 */
  clientLevel?: string | null;
  /** 记录来源 */
  loadSource?: string | null;
  /** 记录来源编号 */
  loadSourceId?: string | null;
  /** logo链接 */
  logoUrl?: string | null;
  /** Logo图片 */
  logoPicture?: string | null;
  /** 头像 */
  avatar?: string | null;
  /** 客户画像描述 */
  portraitNarrative?: string | null;
  /** 是否吊销 */
  isRevoke?: boolean | null;
  /**
   * 吊销日期
   * @format date-time
   */
  revokeDate?: string | null;
  /** 吊销原因 */
  revokeRemark?: string | null;
  /** 母公司名称 */
  parentCompanyName?: string | null;
  /** 母公司外文名称 */
  parentCompanyForeignName?: string | null;
  /** 母公司证件类型 */
  parentCompanyCreditType?: string | null;
  /** 母公司证件号码 */
  parentCompanyCreditCode?: string | null;
  /** 联系人 */
  contactName?: string | null;
  /** 客户性质 */
  customerNature?: string | null;
  /** 所属集团 */
  belongGroupId?: string | null;
  /** 流水编号 */
  serialId?: string | null;
  /** 原系统主键编号 */
  originalId?: string | null;
  /** 流程数据大状态 */
  status?: string | null;
  /**
   * 提交时间
   * @format date-time
   */
  submittedOn?: string | null;
  /** 工作流状态 */
  flowState?: string | null;
  /** 申请人 */
  applicant?: string | null;
  /**
   * 工作流结束时间
   * @format date-time
   */
  flowEndDateTime?: string | null;
  /** 是否超期 */
  isOverdue?: boolean | null;
  /** 备注 */
  remark?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 发票抬头表 */
export interface EntityInvoiceTitleDto {
  /** 外键id */
  foreignId?: string | null;
  /** 类型 */
  entityType?: string | null;
  /** 开户行 */
  bank?: string | null;
  /** 账号 */
  bankAccount?: string | null;
  /** 发票抬头 */
  invoiceTitle?: string | null;
  /** 纳税人识别号 */
  creditCode?: string | null;
  /** 地址 */
  address?: string | null;
  /** 电话 */
  tel?: string | null;
  /** 流水编号 */
  serialId?: string | null;
  /** 原系统主键编号 */
  originalId?: string | null;
  /** 流程数据大状态 */
  status?: string | null;
  /**
   * 提交时间
   * @format date-time
   */
  submittedOn?: string | null;
  /** 工作流状态 */
  flowState?: string | null;
  /** 申请人 */
  applicant?: string | null;
  /**
   * 工作流结束时间
   * @format date-time
   */
  flowEndDateTime?: string | null;
  /** 是否超期 */
  isOverdue?: boolean | null;
  /** 备注 */
  remark?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface EntityInvoiceTitleDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 发票抬头表 */
  response?: EntityInvoiceTitleDto;
}

/** 通用分页信息类 */
export interface EntityInvoiceTitleDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: EntityInvoiceTitleDto[] | null;
}

/** 通用返回信息类 */
export interface EntityInvoiceTitleDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: EntityInvoiceTitleDtoPageModel;
}

export interface EntityNameSearchDto {
  /** 主键编号 */
  id?: string | null;
  /** 客户流水号 */
  serialId?: string | null;
  /** 企业名称 */
  name?: string | null;
  /** 匹配原因 */
  hitReason?: string | null;
  /**
   * 数据来源
   * @format int32
   */
  dataType?: 0 | 1 | 2 | 3 | 4;
  /** 数据来源文本 */
  dataTypeText?: string | null;
}

/** 通用返回信息类 */
export interface EntityNameSearchDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: EntityNameSearchDto[] | null;
}

export interface ExcelFileDto {
  /** 文件Id */
  fileId?: string | null;
  /** 文件名称 */
  fileName?: string | null;
  /**
   * 文件大小
   * @format int32
   */
  fileSize?: number;
  /** 文件路径 */
  filePath?: string | null;
  /** 下载地址 */
  downloadUrl?: string | null;
}

/** 通用返回信息类 */
export interface ExcelFileDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  response?: ExcelFileDto;
}

/** 字段来源 */
export interface FieldSourceDto {
  /** 所属表单 */
  tableName?: string | null;
  /** 编码字段 */
  field?: string | null;
  /** 关联字段 */
  associatedField?: string | null;
}

/** 字段值 */
export interface FieldValueDto {
  /** 所属表单 */
  tableName?: string | null;
  /** 编码字段 */
  field?: string | null;
  /** 字段值 */
  value?: string | null;
}

export interface FileAggregateRoute {
  routeKeys?: string[] | null;
  routeKeysConfig?: AggregateRouteConfig[] | null;
  upstreamPathTemplate?: string | null;
  upstreamHost?: string | null;
  routeIsCaseSensitive?: boolean;
  aggregator?: string | null;
  upstreamHttpMethod?: string[] | null;
  /** @format int32 */
  priority?: number;
}

export interface FileAuthenticationOptions {
  authenticationProviderKey?: string | null;
  allowedScopes?: string[] | null;
}

export interface FileCacheOptions {
  /** @format int32 */
  ttlSeconds?: number;
  region?: string | null;
}

export interface FileConfiguration {
  routes?: FileRoute[] | null;
  dynamicRoutes?: FileDynamicRoute[] | null;
  aggregates?: FileAggregateRoute[] | null;
  globalConfiguration?: FileGlobalConfiguration;
}

export interface FileDynamicRoute {
  serviceName?: string | null;
  rateLimitRule?: FileRateLimitRule;
  downstreamHttpVersion?: string | null;
}

export interface FileGlobalConfiguration {
  requestIdKey?: string | null;
  serviceDiscoveryProvider?: FileServiceDiscoveryProvider;
  rateLimitOptions?: FileRateLimitOptions;
  qoSOptions?: FileQoSOptions;
  baseUrl?: string | null;
  loadBalancerOptions?: FileLoadBalancerOptions;
  downstreamScheme?: string | null;
  httpHandlerOptions?: FileHttpHandlerOptions;
  downstreamHttpVersion?: string | null;
}

export interface FileHostAndPort {
  host?: string | null;
  /** @format int32 */
  port?: number;
}

export interface FileHttpHandlerOptions {
  allowAutoRedirect?: boolean;
  useCookieContainer?: boolean;
  useTracing?: boolean;
  useProxy?: boolean;
  /** @format int32 */
  maxConnectionsPerServer?: number;
}

export interface FileLoadBalancerOptions {
  type?: string | null;
  key?: string | null;
  /** @format int32 */
  expiry?: number;
}

export interface FileQoSOptions {
  /** @format int32 */
  exceptionsAllowedBeforeBreaking?: number;
  /** @format int32 */
  durationOfBreak?: number;
  /** @format int32 */
  timeoutValue?: number;
}

export interface FileRateLimitOptions {
  clientIdHeader?: string | null;
  quotaExceededMessage?: string | null;
  rateLimitCounterPrefix?: string | null;
  disableRateLimitHeaders?: boolean;
  /** @format int32 */
  httpStatusCode?: number;
}

export interface FileRateLimitRule {
  clientWhitelist?: string[] | null;
  enableRateLimiting?: boolean;
  period?: string | null;
  /** @format double */
  periodTimespan?: number;
  /** @format int64 */
  limit?: number;
}

export interface FileRoute {
  downstreamPathTemplate?: string | null;
  upstreamPathTemplate?: string | null;
  upstreamHttpMethod?: string[] | null;
  downstreamHttpMethod?: string | null;
  addHeadersToRequest?: Record<string, string | null>;
  upstreamHeaderTransform?: Record<string, string | null>;
  downstreamHeaderTransform?: Record<string, string | null>;
  addClaimsToRequest?: Record<string, string | null>;
  routeClaimsRequirement?: Record<string, string | null>;
  addQueriesToRequest?: Record<string, string | null>;
  changeDownstreamPathTemplate?: Record<string, string | null>;
  requestIdKey?: string | null;
  fileCacheOptions?: FileCacheOptions;
  routeIsCaseSensitive?: boolean;
  serviceName?: string | null;
  serviceNamespace?: string | null;
  downstreamScheme?: string | null;
  qoSOptions?: FileQoSOptions;
  loadBalancerOptions?: FileLoadBalancerOptions;
  rateLimitOptions?: FileRateLimitRule;
  authenticationOptions?: FileAuthenticationOptions;
  httpHandlerOptions?: FileHttpHandlerOptions;
  downstreamHostAndPorts?: FileHostAndPort[] | null;
  upstreamHost?: string | null;
  key?: string | null;
  delegatingHandlers?: string[] | null;
  /** @format int32 */
  priority?: number;
  /** @format int32 */
  timeout?: number;
  dangerousAcceptAnyServerCertificateValidator?: boolean;
  securityOptions?: FileSecurityOptions;
  downstreamHttpVersion?: string | null;
}

export interface FileSecurityOptions {
  ipAllowedList?: string[] | null;
  ipBlockedList?: string[] | null;
}

export interface FileServiceDiscoveryProvider {
  scheme?: string | null;
  host?: string | null;
  /** @format int32 */
  port?: number;
  type?: string | null;
  token?: string | null;
  configurationKey?: string | null;
  /** @format int32 */
  pollingInterval?: number;
  namespace?: string | null;
}

export interface FilterConfig {
  /** 显示更多筛选 */
  isShowMoreFilter?: boolean;
}

/** 分配信息表 */
export interface FinancialAllocationDto {
  /** 收款编号 */
  receiptId?: string | null;
  /** 案件Id */
  caseId?: string | null;
  /** 分配方式 */
  allocationType?: string | null;
  /**
   * 分配日期
   * @format date-time
   */
  allocationDate?: string | null;
  /** 是否红冲分配 */
  isRedPunch?: string | null;
  /** 是否红冲分配 */
  isRedPunchText?: string | null;
  /** 分配模式 */
  allocationMode?: string | null;
  /** 分配性质 */
  allocationNature?: string | null;
  /**
   * 分配金额
   * @format double
   */
  amount?: number;
  /**
   * 分配比例
   * @format double
   */
  allocationRate?: number;
  /**
   * 案源费比例
   * @format double
   */
  caseSourceRate?: number;
  /**
   * 案源费分配金额
   * @format double
   */
  caseSourceAmount?: number;
  /**
   * 尾差
   * @format double
   */
  roundingDifference?: number;
  /** 分配策略 */
  allocationStrattegy?: string | null;
  /** 分配人 */
  employeeId?: string | null;
  /** 是否保密 */
  ifConfidential?: string | null;
  /** 是否保密 */
  ifConfidentialText?: string | null;
  /**
   * 律师费金额
   * @format double
   */
  lawyerFee?: number;
  /**
   * 官费金额
   * @format double
   */
  officialFees?: number;
  /**
   * 支出费用
   * @format double
   */
  expenseFee?: number;
  /**
   * 会计账期
   * @format date-time
   */
  accountingPeriod?: string | null;
  currency?: string | null;
  /** 流水编号 */
  serialId?: string | null;
  /** 原系统主键编号 */
  originalId?: string | null;
  /** 流程数据大状态 */
  status?: string | null;
  /**
   * 提交时间
   * @format date-time
   */
  submittedOn?: string | null;
  /** 工作流状态 */
  flowState?: string | null;
  /** 申请人 */
  applicant?: string | null;
  /**
   * 工作流结束时间
   * @format date-time
   */
  flowEndDateTime?: string | null;
  /** 是否超期 */
  isOverdue?: boolean | null;
  /** 备注 */
  remark?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface FinancialAllocationDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: FinancialAllocationDto[] | null;
}

/** 通用返回信息类 */
export interface FinancialAllocationDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 分配信息表 */
  response?: FinancialAllocationDto;
}

/** 分配明细信息表 */
export interface FinancialAllocationItemDto {
  /** 分配编号 */
  allocationId?: string | null;
  /** 收款编号 */
  receiptId?: string | null;
  /** 案件Id */
  caseId?: string | null;
  /** 被分配人 */
  employeeId?: string | null;
  /** 被分配人 */
  employeeIdText?: string | null;
  /** 被分配人 */
  employeeIdPictureUrl?: string | null;
  /**
   * 分配日期
   * @format date-time
   */
  allocationDate?: string | null;
  /**
   * 分配金额
   * @format double
   */
  amount?: number;
  /**
   * 分配比例
   * @format double
   */
  allocationRate?: number;
  /**
   * 尾差
   * @format double
   */
  roundingDifference?: number;
  /**
   * 薪酬金额
   * @format double
   */
  remunerationAmount?: number;
  /**
   * 薪酬比例
   * @format double
   */
  remunerationRate?: number;
  /**
   * 案源费比例
   * @format double
   */
  caseSourceRate?: number;
  /**
   * 案源费分配金额
   * @format double
   */
  caseSourceAmount?: number;
  /** 是否是案源费 */
  isSourceFee?: string | null;
  /** 是否是案源费 */
  isSourceFeeText?: string | null;
  /** 参案角色 */
  lawyerRole?: string | null;
  /**
   * 分配基数
   * @format double
   */
  allocationBase?: number;
  /** 币种 */
  currency?: string | null;
  /** 币种文本 */
  currencyText?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface FinancialAllocationItemDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: FinancialAllocationItemDto[] | null;
}

/** 通用返回信息类 */
export interface FinancialAllocationItemDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 分配明细信息表 */
  response?: FinancialAllocationItemDto;
}

/** 历史分配明细信息表 */
export interface FinancialAllocationItemHistoryDto {
  /** 分配编号 */
  allocationId?: string | null;
  /** 收款编号 */
  receiptId?: string | null;
  /** 被分配人 */
  employeeId?: string | null;
  /** 被分配人 */
  employeeIdText?: string | null;
  /**
   * 分配日期
   * @format date-time
   */
  allocationDate?: string | null;
  /**
   * 分配金额
   * @format double
   */
  amount?: number;
  /**
   * 分配比例
   * @format double
   */
  allocationRate?: number;
  /**
   * 尾差
   * @format double
   */
  roundingDifference?: number;
  /**
   * 薪酬金额
   * @format double
   */
  remunerationAmount?: number;
  /**
   * 薪酬比例
   * @format double
   */
  remunerationRate?: number;
  /**
   * 案源费比例
   * @format double
   */
  caseSourceRate?: number;
  /**
   * 案源费分配金额
   * @format double
   */
  caseSourceAmount?: number;
  /** 是否是案源费 */
  isSourceFee?: string | null;
  /** 是否是案源费 */
  isSourceFeeText?: string | null;
  /** 参案角色 */
  lawyerRole?: string | null;
  /**
   * 分配基数
   * @format double
   */
  allocationBase?: number;
  /** 备注 */
  remark?: string | null;
  /** 版本号 分配为纬度 */
  historyVersion?: string | null;
  /** 版本号 案件+分配为纬度 */
  caseHistoryVersion?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface FinancialAllocationItemHistoryDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: FinancialAllocationItemHistoryDto[] | null;
}

/** 通用返回信息类 */
export interface FinancialAllocationItemHistoryDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 历史分配明细信息表 */
  response?: FinancialAllocationItemHistoryDto;
}

/** 通用分页信息类 */
export interface FinancialAllocationItemHistoryDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: FinancialAllocationItemHistoryDto[] | null;
}

/** 通用返回信息类 */
export interface FinancialAllocationItemHistoryDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: FinancialAllocationItemHistoryDtoPageModel;
}

/** 分配比例案件设置表 */
export interface FinancialAllocationRateCaseSettingDto {
  /** 案件Id */
  caseId?: string | null;
  /** 相关分配策略Id */
  rateSettingId?: string | null;
  /** 相关分配策略Id */
  rateSettingName?: string | null;
  /** 备注 */
  remark?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface FinancialAllocationRateCaseSettingDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 分配比例案件设置表 */
  response?: FinancialAllocationRateCaseSettingDto;
}

export interface FinancialAllocationRateCaseSettingItemDto {
  /** 分配比例设置编号 */
  allocationRateSettingId?: string | null;
  /** 案件编号 */
  caseId?: string | null;
  /** 人员编号 */
  employeeId?: string | null;
  /** 人员名称 */
  employeeIdText?: string | null;
  /** 人员头像 */
  employeeIdPicture?: string | null;
  /** 参案角色 */
  lawyerRole?: string | null;
  /** 参案角色 */
  lawyerRoleText?: string | null;
  /**
   * 分配比例
   * @format double
   */
  allocationRate?: number;
  /** 是否案源费 */
  isCaseSourceFee?: string | null;
  /** 是否案源费 */
  isCaseSourceFeeText?: string | null;
  /** 备注 */
  remark?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface FinancialAllocationRateCaseSettingItemDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  response?: FinancialAllocationRateCaseSettingItemDto;
}

/** 分配比例设置表 */
export interface FinancialAllocationRateSettingDto {
  /** 策略名称 */
  strattegyName?: string | null;
  /** 策略编号 */
  strattegyId?: string | null;
  /** 案件Id */
  caseId?: string | null;
  /** 是否默认 */
  isDefault?: boolean;
  /** 备注 */
  remark?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface FinancialAllocationRateSettingDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 分配比例设置表 */
  response?: FinancialAllocationRateSettingDto;
}

/** 分配比例设置明细表 */
export interface FinancialAllocationRateSettingItemDto {
  /** 分配比例设置编号 */
  allocationRateSettingId?: string | null;
  /** 案件编号 */
  caseId?: string | null;
  /** 人员编号 */
  employeeId?: string | null;
  /** 人员名称 */
  employeeIdText?: string | null;
  /** 人员头像 */
  employeeIdPicture?: string | null;
  /** 参案角色 */
  lawyerRole?: string | null;
  /** 参案角色 */
  lawyerRoleText?: string | null;
  /**
   * 分配比例
   * @format double
   */
  allocationRate?: number;
  /** 是否案源费 */
  isCaseSourceFee?: string | null;
  /** 是否案源费 */
  isCaseSourceFeeText?: string | null;
  /** 是否启用 */
  isEnable?: boolean;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface FinancialAllocationRateSettingItemDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 分配比例设置明细表 */
  response?: FinancialAllocationRateSettingItemDto;
}

/** 分配比例设置明细历史表 */
export interface FinancialAllocationRateSettingItemHistoryDto {
  /** 分配比例设置编号 */
  allocationRateSettingId?: string | null;
  /** 案件编号 */
  caseId?: string | null;
  /** 人员编号 */
  employeeId?: string | null;
  /** 参案角色 */
  lawyerRole?: string | null;
  /**
   * 分配比例
   * @format double
   */
  allocationRate?: number;
  /** 是否案源费 */
  isCaseSourceFee?: string | null;
  /** 是否案源费 */
  isCaseSourceFeeText?: string | null;
  /** 分配比例历史版本 */
  historyVersion?: string | null;
  /** 备注 */
  remark?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface FinancialAllocationRateSettingItemHistoryDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 分配比例设置明细历史表 */
  response?: FinancialAllocationRateSettingItemHistoryDto;
}

/** 分配设置 */
export interface FinancialAllocationSettingsDto {
  sourceAllocation?: FinancialAllocationSettingsItem[] | null;
  incomeDistribution?: FinancialAllocationSettingsItem[] | null;
}

/** 通用返回信息类 */
export interface FinancialAllocationSettingsDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 分配设置 */
  response?: FinancialAllocationSettingsDto;
}

/** 分配设置明细 */
export interface FinancialAllocationSettingsItem {
  /** 参案律师角色 */
  lawyerRole?: string | null;
  /** 参案律师角色 */
  lawyerRoleText?: string | null;
  /**
   * 分配比例
   * @format double
   */
  distributionRatio?: number;
  /** 是否启用 */
  isEnabled?: boolean;
}

/** 账单调整明细表 */
export interface FinancialBillingAdjustmentDto {
  /** 账单编号 */
  billingId?: string | null;
  /** 调整项目 */
  category?: string | null;
  /** 调整项目 */
  categoryText?: string | null;
  /** 应用范围 */
  scope?: string | null;
  /** 应用范围 */
  scopeText?: string | null;
  /** 调整类型 */
  adjustmentType?: string | null;
  /** 调整类型 */
  adjustmentTypeText?: string | null;
  /**
   * 基数
   * @format double
   */
  base?: number;
  /**
   * 调整比例
   * @format double
   */
  adjustmentRate?: number;
  /**
   * 调整额
   * @format double
   */
  adjustment?: number;
  /**
   * 调整后金额
   * @format double
   */
  amount?: number;
  /**
   * 小计金额
   * @format double
   */
  subtotal?: number;
  /** 描述 */
  narrative?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface FinancialBillingAdjustmentDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: FinancialBillingAdjustmentDto[] | null;
}

/** 通用返回信息类 */
export interface FinancialBillingAdjustmentDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 账单调整明细表 */
  response?: FinancialBillingAdjustmentDto;
}

/** 通用分页信息类 */
export interface FinancialBillingAdjustmentDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: FinancialBillingAdjustmentDto[] | null;
}

/** 通用返回信息类 */
export interface FinancialBillingAdjustmentDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: FinancialBillingAdjustmentDtoPageModel;
}

/** 账单生成文件表 */
export interface FinancialBillingAttachmentDto {
  /** 账单编号 */
  billingId?: string | null;
  /** 账单文件名称 */
  name?: string | null;
  /** 账单文件类型 */
  fileExtension?: string | null;
  templateId?: string | null;
  templateName?: string | null;
  attachmentId?: string | null;
  pdfAttachmentId?: string | null;
  isFinal?: boolean;
  displayAdjustmentDiscountPercentage?: boolean;
  includingTimesheetDetailedInfo?: boolean;
  displayTimesheetDetailedHorizontally?: boolean;
  displayOriginalFee?: boolean;
  includingExpenseDetailedInfo?: boolean;
  displayCoverLetter?: boolean;
  displayBankAccountInfo?: boolean;
  /** 备注 */
  remark?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface FinancialBillingAttachmentDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 账单生成文件表 */
  response?: FinancialBillingAttachmentDto;
}

/** 账单评论信息 */
export interface FinancialBillingCommentsDto {
  /** 账单编号 */
  billingId?: string | null;
  /** 账单子项编号 */
  itemId?: string | null;
  /** 账单子项类型 */
  itemScope?: string | null;
  /** 账单子项类型文本 */
  itemScopeText?: string | null;
  /** 评论人编号 */
  userId?: string | null;
  /** 评论人名称 */
  employeeName?: string | null;
  /** 评论人可见范围 */
  visibleScope?: string | null;
  /** 评论内容 */
  commentText?: string | null;
  /** 是否解决 */
  isResolved?: string | null;
  /** 是否解决文本 */
  isResolvedText?: string | null;
  /** 解决人编号 */
  resolvedUserId?: string | null;
  /** 解决人名称 */
  resolvedUserName?: string | null;
  /**
   * 解决时间
   * @format date-time
   */
  resolvedTime?: string | null;
  /** 父级评论编号 */
  parentCommitId?: string | null;
  /** 高亮文本 */
  highlightedText?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface FinancialBillingCommentsDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 账单评论信息 */
  response?: FinancialBillingCommentsDto;
}

/** 账单关联费用支出明细表 */
export interface FinancialBillingDisbursementDto {
  /** 账单编号 */
  billingId?: string | null;
  /** 费用名称 */
  name?: string | null;
  /**
   * 费用日期
   * @format date-time
   */
  expenseDate?: string | null;
  /** 费用代码 */
  expenseCode?: string | null;
  /** 费用类别 */
  category?: string | null;
  /** 费用类别文本 */
  categoryText?: string | null;
  /** 费用子类别 */
  subCategory?: string | null;
  /** 币种 */
  currency?: string | null;
  /** 币种 */
  currencyText?: string | null;
  /**
   * 币种汇率
   * @format double
   */
  exchangeRate?: number;
  /** 报销类型 */
  expenseType?: string | null;
  /** 报销类型文本 */
  expenseTypeText?: string | null;
  /** 是否代收代付 */
  isPobo?: string | null;
  /** 是否代收代付文本 */
  isPoboText?: string | null;
  /**
   * 费用金额
   * @format double
   */
  amount?: number;
  /**
   * 计数数量
   * @format double
   */
  count?: number;
  /** 调整类型 */
  adjustmentType?: string | null;
  /** 调整类型 */
  adjustmentTypeText?: string | null;
  /**
   * 调整比例
   * @format double
   */
  adjustmentRate?: number;
  /**
   * 调整金额
   * @format double
   */
  adjustment?: number;
  /**
   * 税率
   * @format double
   */
  taxRate?: number;
  /**
   * 税额
   * @format double
   */
  taxAmount?: number;
  /**
   * 外币服务费金额
   * @format double
   */
  foreignAmount?: number;
  /**
   * 外币小计金额
   * @format double
   */
  foreignSubtotal?: number;
  /**
   * 小计金额
   * @format double
   */
  subtotal?: number;
  /** 是否计费 */
  isBillable?: string | null;
  /** 是否计费 */
  isBillableText?: string | null;
  /** 报销单编号 */
  expenserportId?: string | null;
  /** 是否已开票 */
  isInvoiced?: string | null;
  /** 是否已开票 */
  isInvoicedText?: string | null;
  /** 商户名称 */
  merchant?: string | null;
  /** 案件编号 */
  caseId?: string | null;
  /** 费用报销人 */
  employeeId?: string | null;
  /** 费用报销人名称 */
  employeeName?: string | null;
  /** 费用报销人显示名称 */
  employeeDisplayName?: string | null;
  /** 发票/收据类型 */
  receiptType?: string | null;
  /** 发票/收据类型文本 */
  receiptTypeText?: string | null;
  /** 是否内部账单 */
  isInternalBilling?: string | null;
  /** 是否内部账单文本 */
  isInternalBillingText?: string | null;
  /** 内部账单编号 */
  internalBillingId?: string | null;
  /** 内部账单流水编号 */
  internalBillingSerialId?: string | null;
  /** 描述 */
  narrative?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface FinancialBillingDisbursementDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: FinancialBillingDisbursementDto[] | null;
}

/** 通用返回信息类 */
export interface FinancialBillingDisbursementDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 账单关联费用支出明细表 */
  response?: FinancialBillingDisbursementDto;
}

/** 账单信息表 */
export interface FinancialBillingDto {
  /** 案件编号 */
  caseId?: string | null;
  /** 案件流水号 */
  caseSerialId?: string | null;
  /** 案件名称 */
  caseName?: string | null;
  /** 账单案件别名 */
  caseMatterName?: string | null;
  /** 客户编号 */
  clientId?: string | null;
  /** 客户流水号 */
  clientSerialId?: string | null;
  /** 客户名称 */
  clientName?: string | null;
  /**
   * 账单日期
   * @format date-time
   */
  billingDate?: string | null;
  /**
   * 到账日期
   * @format date-time
   */
  arrivedDate?: string | null;
  /**
   * 账单开始日期
   * @format date-time
   */
  startDate?: string | null;
  /**
   * 账单结束日期
   * @format date-time
   */
  endDate?: string | null;
  /** 付款方编号 */
  paymentId?: string | null;
  /** 付款方名称 */
  paymentName?: string | null;
  /** 付款条件 */
  paymentTerms?: string | null;
  /** 付款条件 */
  paymentTermsText?: string | null;
  /** 付款货币类型 */
  paymentCurrency?: string | null;
  /** 付款货币类型文本 */
  paymentCurrencyText?: string | null;
  /**
   * 应收账款
   * @format double
   */
  accountsReceivable?: number;
  /** 账单管理合伙人 */
  billingPartnerId?: string | null;
  /** 账单管理合伙人名称 */
  billingPartnerName?: string | null;
  /** 账单申请律师 */
  billingLawyerId?: string | null;
  /** 账单申请律师名称 */
  billingLawyerName?: string | null;
  /**
   * 应收账款负责人
   *
   * Accounts Receivable Responsible 催收负责人
   */
  arResponsibleLawyerId?: string | null;
  /**
   * 应收账款负责人名称
   *
   * Accounts Receivable Responsible 催收负责人
   */
  arResponsibleLawyerName?: string | null;
  /** 币种 */
  currency?: string | null;
  /** 币种文本 */
  currencyText?: string | null;
  /**
   * 汇率
   * @format double
   */
  exchangeRate?: number;
  /** 支付状态 */
  paymentStatus?: string | null;
  /** 账单联系人 */
  contactPerson?: string | null;
  /** 邮箱地址 */
  emailAddress?: string | null;
  /** 账单所属办公室 */
  billingOffice?: string | null;
  /** 账单所属办公室名称 */
  billingOfficeName?: string | null;
  /** 账单关联银行账号 */
  billingBankId?: string | null;
  /** 账单类型;联合账单，内部账单，etc… */
  category?: string | null;
  /** 账单类型;联合账单，内部账单，etc… */
  categoryText?: string | null;
  /** 是否联合账单 */
  isBatchBilling?: string | null;
  /** 是否联合账单 */
  isBatchBillingText?: string | null;
  /** 联合账单关联案号 */
  batchCaseIds?: string | null;
  /** 关联内部账单编号 */
  internalBillingIds?: string | null;
  /**
   * 总固定服务费
   * @format double
   */
  totalFixedServiceFee?: number;
  /**
   * 总固定服务费实际金额（含税）
   * @format double
   */
  fixedSettlementAmount?: number;
  /**
   * 固定服务税费
   * @format double
   */
  fixedServiceTax?: number;
  /**
   * 账单总金额
   * @format double
   */
  totalAmount?: number;
  /**
   * 总工时服务费
   * @format double
   */
  totalTimesheetServiceFee?: number;
  /**
   * 总工时服务费实际金额（含税）
   * @format double
   */
  timesheetSettlementAmount?: number;
  /**
   * 工时税费
   * @format double
   */
  timesheetTax?: number;
  /**
   * 总支出费用金额
   * @format double
   */
  totalDisbursement?: number;
  /**
   * 总支出费用实际金额（含税）
   * @format double
   */
  disbursementSettlementAmount?: number;
  /**
   * 支出税费
   * @format double
   */
  disbursementTax?: number;
  /**
   * 调整金额
   * @format double
   */
  totalAdjustment?: number;
  /**
   * 账单税后调整比例
   * @format double
   */
  totalAdjustmentRate?: number;
  /**
   * 工时税后调整总额
   * @format double
   */
  timesheetAdjustment?: number;
  /**
   * 工时税后调整比例
   * @format double
   */
  timesheetAdjustmentRate?: number;
  /**
   * 工时上限金额
   * @format double
   */
  timesheetUpperLimitAmount?: number;
  /**
   * 工时上限税额
   * @format double
   */
  timesheetUpperLimitTax?: number;
  /**
   * 固定服务费税后调整总额
   * @format double
   */
  fixedServiceAdjustment?: number;
  /**
   * 固定服务费税后调整比例
   * @format double
   */
  fixedServiceAdjustmentRate?: number;
  /**
   * 固定费用上限金额
   * @format double
   */
  fixedServiceUpperLimitAmount?: number;
  /**
   * 固定费用上限税额
   * @format double
   */
  fixedServiceUpperLimitTax?: number;
  /**
   * 支出税后调整总额
   * @format double
   */
  disbursementAdjustment?: number;
  /**
   * 支出税后调整比例
   * @format double
   */
  disbursementAdjustmentRate?: number;
  /**
   * 支出费用上限金额
   * @format double
   */
  disbursementUpperLimitAmount?: number;
  /**
   * 支出费用上税金额
   * @format double
   */
  disbursementUpperLimitTax?: number;
  /**
   * 上限金额
   * @format double
   */
  upperLimitAmount?: number;
  /**
   * 上限金额税费
   * @format double
   */
  upperLimitAmountTax?: number;
  /**
   * POBO子项总计金额
   * @format double
   */
  poboSubtotalAmount?: number;
  /**
   * 税率
   * @format double
   */
  taxRate?: number;
  /**
   * 账单总税额
   * @format double
   */
  totalTaxAmount?: number;
  /**
   * 不含税金额合计
   *
   * = 净服务费 + 调整金额
   * @format double
   */
  totalExclusiveAmount?: number;
  /**
   * 价税合计
   *
   * = 不含税金额合计 + 税费
   * @format double
   */
  totalAmountIncludingTax?: number;
  /**
   * 外币结算金额
   * @format double
   */
  settlementAmount?: number;
  /** 是否跨境账单 */
  isCrossBorderBilling?: string | null;
  /** 是否跨境账单 */
  isCrossBorderBillingText?: string | null;
  /** 是否内部账单 */
  isInternalBilling?: string | null;
  /** 是否内部账单文本 */
  isInternalBillingText?: string | null;
  /** 是否坏账冲抵账单 */
  isBadDebts?: string | null;
  /** 是否坏账冲抵账单 */
  isBadDebtsText?: string | null;
  /** 账单生成附件 */
  billingAttachmentId?: string | null;
  /** 账单模板 */
  billingTemplateId?: string | null;
  /** 内部描述 */
  internalDescription?: string | null;
  /** 账单描述 */
  narrative?: string | null;
  /** 是否子账单 */
  isSubBilling?: string | null;
  /** 是否子账单文本 */
  isSubBillingText?: string | null;
  /** 父级账单编号 */
  parentBillingId?: string | null;
  /**
   * 账单工时区间回收率
   * @format double
   */
  timesheetRecoveryRate?: number;
  /** 是否低回收率 */
  isLowRecoveryRate?: string | null;
  /** 是否低回收率文本 */
  isLowRecoveryRateText?: string | null;
  /**
   * 账单工时区间低回收率说明
   * @maxLength 500
   */
  lowRecoveryRateRemark?: string | null;
  /** 是否e-billing账单 */
  isEBilling?: string | null;
  /** 是否e-billing账单文本 */
  isEBillingText?: string | null;
  /** 流水编号 */
  serialId?: string | null;
  /** 原系统主键编号 */
  originalId?: string | null;
  /** 流程数据大状态 */
  status?: string | null;
  /**
   * 提交时间
   * @format date-time
   */
  submittedOn?: string | null;
  /** 工作流状态 */
  flowState?: string | null;
  /** 申请人 */
  applicant?: string | null;
  /**
   * 工作流结束时间
   * @format date-time
   */
  flowEndDateTime?: string | null;
  /** 是否超期 */
  isOverdue?: boolean | null;
  /** 备注 */
  remark?: string | null;
  /** 办公室 */
  officeId?: string | null;
  /** 办公室名称 */
  officeName?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface FinancialBillingDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: FinancialBillingDto[] | null;
}

/** 通用返回信息类 */
export interface FinancialBillingDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 账单信息表 */
  response?: FinancialBillingDto;
}

/** 账单汇率表 */
export interface FinancialBillingExchangeDto {
  /** 账单编号 */
  billingId?: string | null;
  /** 账单币种 */
  baseCurrency?: string | null;
  /** 账单币种 */
  baseCurrencyText?: string | null;
  /** 目标币种 */
  targetCurrency?: string | null;
  /** 目标币种 */
  targetCurrencyText?: string | null;
  /**
   * 汇率
   * @format double
   */
  exchangeRate?: number | null;
  /** 备注 */
  remark?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface FinancialBillingExchangeDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 账单汇率表 */
  response?: FinancialBillingExchangeDto;
}

/** 账单关联固定服务费明细表 */
export interface FinancialBillingFixedServiceFeeDto {
  /** 账单编号 */
  billingId?: string | null;
  /**
   * 服务日期
   * @format date-time
   */
  serviceDate?: string | null;
  /** 任务 */
  task?: string | null;
  /** 任务文本 */
  taskText?: string | null;
  /** 活动代码 */
  activityCode?: string | null;
  /** 活动代码文本 */
  activityCodeText?: string | null;
  /** 服务类型 */
  category?: string | null;
  /** 服务类型文本 */
  categoryText?: string | null;
  /**
   * 服务费金额
   * @format double
   */
  amount?: number;
  /** 调整类型 */
  adjustmentType?: string | null;
  /** 调整类型 */
  adjustmentTypeText?: string | null;
  /**
   * 调整比例
   * @format double
   */
  adjustmentRate?: number;
  /**
   * 调整额
   * @format double
   */
  adjustment?: number;
  /**
   * 税率
   * @format double
   */
  taxRate?: number;
  /**
   * 税额
   * @format double
   */
  taxAmount?: number;
  /** 币种 */
  currency?: string | null;
  /** 币种 */
  currencyText?: string | null;
  /**
   * 币种汇率
   * @format double
   */
  exchangeRate?: number;
  /**
   * 外币服务费金额
   * @format double
   */
  foreignAmount?: number;
  /**
   * 外币小计金额
   * @format double
   */
  foreignSubtotal?: number;
  /**
   * 小计金额
   * @format double
   */
  subtotal?: number;
  /** 是否计费 */
  isBillable?: string | null;
  /** 是否计费 */
  isBillableText?: string | null;
  /** 描述 */
  narrative?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface FinancialBillingFixedServiceFeeDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 账单关联固定服务费明细表 */
  response?: FinancialBillingFixedServiceFeeDto;
}

/** 账单外币支付信息 */
export interface FinancialBillingForeignPaymentDto {
  /** 账单编号 */
  billingId?: string | null;
  /** 账单币种 */
  billingCurrency?: string | null;
  /** 账单币种 */
  billingCurrencyText?: string | null;
  /**
   * 账单金额
   * @format double
   */
  billingAmount?: number | null;
  /** 支付币种 */
  targetCurrency?: string | null;
  /** 支付币种 */
  targetCurrencyText?: string | null;
  /**
   * 汇率
   * @format double
   */
  exchangeRate?: number | null;
  /**
   * 结算金额
   * @format double
   */
  targetAmount?: number | null;
  /** 备注 */
  remark?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface FinancialBillingForeignPaymentDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 账单外币支付信息 */
  response?: FinancialBillingForeignPaymentDto;
}

/** 账单偏好设置 */
export interface FinancialBillingPreferenceSettingDto {
  /**
   * 显示调整/折扣百分比
   *
   * 在账单汇总页面上，选择此项以设置固定/小时专业服务费用及账单上的杂项费用的调整/折扣百分比
   */
  displayAdjustmentDiscountPercentage?: boolean;
  /**
   * 包含工时表的详细信息
   *
   * 开启可显示工时明细
   */
  includingTimesheetDetailedInfo?: boolean;
  /**
   * 横向显示工时明细信息
   *
   * false 时，显示为纵向表格
   */
  displayTimesheetDetailedHorizontally?: boolean;
  /**
   * 显示原始费用
   *
   * 开启可显示封顶之前的原始小时专业服务费用
   */
  displayOriginalFee?: boolean;
  /**
   * 显示银行信息
   *
   * 开启可显示银行账户信息
   */
  displayBankAccountInfo?: boolean;
  /**
   * 包含费用的详细信息
   *
   * 开启可显示费用明细
   */
  includingExpenseDetailedInfo?: boolean;
  /**
   * 显示账单封面
   *
   * 生成账单时包含封面信息
   */
  displayCoverLetter?: boolean;
}

/** 通用返回信息类 */
export interface FinancialBillingPreferenceSettingDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 账单偏好设置 */
  response?: FinancialBillingPreferenceSettingDto;
}

/** 账单模板表 */
export interface FinancialBillingTemplateDto {
  /** 模板名称 */
  name?: string | null;
  /** 文件路径 */
  filePath?: string | null;
  /** 文件类型 */
  fileExtension?: string | null;
  /**
   * 文件大小
   * @format float
   */
  fileSize?: number;
  /** 适用语言 */
  language?: string | null;
  /** 模板适用范围 */
  scope?: string | null;
  /** 适用案件类型 */
  caseCategory?: string | null;
  /** 适用案件类型 */
  caseCategoryText?: string | null;
  /** 模板类型 */
  category?: string | null;
  /** 是否包含封面页 */
  isIncludedCoverLetter?: string | null;
  /** 是否包含封面页文本 */
  isIncludedCoverLetterText?: string | null;
  /** 是否是上传模板 */
  isUpload?: string | null;
  /** 是否是上传模板 */
  isUploadText?: string | null;
  /** 备注 */
  remark?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface FinancialBillingTemplateDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: FinancialBillingTemplateDto[] | null;
}

/** 通用返回信息类 */
export interface FinancialBillingTemplateDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 账单模板表 */
  response?: FinancialBillingTemplateDto;
}

/** 通用分页信息类 */
export interface FinancialBillingTemplateDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: FinancialBillingTemplateDto[] | null;
}

/** 通用返回信息类 */
export interface FinancialBillingTemplateDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: FinancialBillingTemplateDtoPageModel;
}

/** 账单工时费明细表 */
export interface FinancialBillingTimesheetServiceFeeDto {
  /** 账单编号 */
  billingId?: string | null;
  /**
   * 工时日期
   * @format date-time
   */
  workDate?: string | null;
  /** 工时填报人 */
  employeeId?: string | null;
  /** 工时所属人名称 */
  employeeName?: string | null;
  /** 费用报销人显示名称 */
  employeeDisplayName?: string | null;
  /**
   * 小时费率
   * @format double
   */
  hourlyRate?: number;
  /** 任务 */
  task?: string | null;
  /** 任务文本 */
  taskText?: string | null;
  /** 活动代码 */
  activityCode?: string | null;
  /** 活动代码文本 */
  activityCodeText?: string | null;
  /** 币种 */
  currency?: string | null;
  /** 币种 */
  currencyText?: string | null;
  /**
   * 币种汇率
   * @format double
   */
  exchangeRate?: number;
  /**
   * 自报小时
   * @format double
   */
  hoursWorked?: number;
  /**
   * 计费小时
   * @format double
   */
  billableHours?: number;
  /** 工时类型 */
  category?: string | null;
  /** 调整类型 */
  adjustmentType?: string | null;
  /** 调整类型 */
  adjustmentTypeText?: string | null;
  /**
   * 调整比例
   * @format double
   */
  adjustmentRate?: number;
  /**
   * 调整额
   * @format double
   */
  adjustment?: number;
  /**
   * 税率
   * @format double
   */
  taxRate?: number;
  /**
   * 税额
   * @format double
   */
  taxAmount?: number;
  /**
   * 金额
   * @format double
   */
  amount?: number;
  /**
   * 外币服务费金额
   * @format double
   */
  foreignAmount?: number;
  /**
   * 外币小计金额
   * @format double
   */
  foreignSubtotal?: number;
  /**
   * 小计金额
   * @format double
   */
  subtotal?: number;
  /** 是否计费 */
  isBillable?: string | null;
  /** 是否计费 */
  isBillableText?: string | null;
  /** 是否销账 */
  isWriteOff?: string | null;
  /** 是否销账 */
  isWriteOffText?: string | null;
  /** 销账类型 */
  writeOffType?: string | null;
  /** 销账类型 */
  writeOffTypeText?: string | null;
  /** 销账理由 */
  writeOffRemark?: string | null;
  /** 工时编号 */
  timesheetId?: string | null;
  /** 工时描述 */
  narrative?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface FinancialBillingTimesheetServiceFeeDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 账单工时费明细表 */
  response?: FinancialBillingTimesheetServiceFeeDto;
}

/** 账单限额设置表 */
export interface FinancialBillingUpperLimitDto {
  /** 账单编号 */
  billingId?: string | null;
  /** 应用范围 */
  scope?: string | null;
  /** 应用范围 */
  scopeText?: string | null;
  /** 是否包含税费 */
  isIncludeTaxFee?: string | null;
  /** 是否包含税费文本 */
  isIncludeTaxFeeText?: string | null;
  /** 是否设为固定收费 */
  isSetAsFixedFee?: string | null;
  /** 是否设为固定收费文本 */
  isSetAsFixedFeeText?: string | null;
  /**
   * 上限金额
   * @format double
   */
  amount?: number;
  /**
   * 税率
   * @format double
   */
  taxRate?: number;
  /**
   * 税额
   * @format double
   */
  taxAmount?: number;
  /**
   * 小计金额
   * @format double
   */
  subtotal?: number;
  /** 备注 */
  remark?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface FinancialBillingUpperLimitDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: FinancialBillingUpperLimitDto[] | null;
}

/** 通用返回信息类 */
export interface FinancialBillingUpperLimitDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 账单限额设置表 */
  response?: FinancialBillingUpperLimitDto;
}

/** 通用分页信息类 */
export interface FinancialBillingUpperLimitDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: FinancialBillingUpperLimitDto[] | null;
}

/** 通用返回信息类 */
export interface FinancialBillingUpperLimitDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: FinancialBillingUpperLimitDtoPageModel;
}

/** 财务办公室主管会计表 */
export interface FinancialChiefAccountantDto {
  /** 会计主管ID */
  userId?: string | null;
  /** 会计主管头像 */
  userAvatar?: string | null;
  /** 会计主管人中文名 */
  userCnName?: string | null;
  /** 会计主管人姓名 */
  userName?: string | null;
  /** 会计主管人邮箱 */
  userEmailAddress?: string | null;
  /** 是否会计主管 */
  isChief?: string | null;
  /** 是否会计主管文本 */
  isChiefText?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface FinancialChiefAccountantDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 财务办公室主管会计表 */
  response?: FinancialChiefAccountantDto;
}

export interface FinancialCurrencySettingsInput {
  /** 默认本位币 */
  defaultCurrency?: string | null;
  /** 默认外币 */
  defaultForeignCurrency?: string | null;
  currencyList?: OfficesCurrency[] | null;
}

/** 通用返回信息类 */
export interface FinancialCurrencySettingsInputMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  response?: FinancialCurrencySettingsInput;
}

/** 费用申请表 */
export interface FinancialExpenseApplyDto {
  /**
   * 申请时间
   * @format date-time
   */
  applyDate?: string | null;
  /**
   * 消费时间
   * @format date-time
   */
  feeDate?: string | null;
  /** 账单编号 */
  billingId?: string | null;
  /** 费用名称 */
  name?: string | null;
  /** 费用代码 */
  expenseCode?: string | null;
  /** 费用类别 */
  category?: string | null;
  /** 费用类别 */
  categoryText?: string | null;
  /** 费用子类别 */
  subCategory?: string | null;
  /** 币种 */
  currency?: string | null;
  /** 币种 */
  currencyText?: string | null;
  /**
   * 币种汇率
   * @format double
   */
  exchangeRate?: number | null;
  /** 报销类型 */
  expenseType?: string | null;
  /** 报销类型 */
  expenseTypeText?: string | null;
  /** 是否代收代付 */
  isPobo?: string | null;
  /** 是否代收代付 */
  isPoboText?: string | null;
  /**
   * 费用金额
   * @format double
   */
  amount?: number | null;
  /**
   * 计数数量
   * @format double
   */
  count?: number | null;
  /**
   * 支付时间
   * @format date-time
   */
  payDate?: string | null;
  /**
   * 税率
   * @format double
   */
  taxRate?: number | null;
  /**
   * 税额
   * @format double
   */
  taxAmount?: number | null;
  /**
   * 小计金额
   * @format double
   */
  subtotal?: number | null;
  /** 是否计费 */
  isBillable?: string | null;
  /** 是否计费 */
  isBillableText?: string | null;
  /** 报销单编号 */
  expenserportId?: string | null;
  /** 是否已开票 */
  isInvoiced?: string | null;
  /** 是否已开票 */
  isInvoicedText?: string | null;
  /** 商户名称 */
  merchant?: string | null;
  /** 案件编号 */
  caseId?: string | null;
  /** 费用报销人 */
  employeeId?: string | null;
  /** 费用描述 */
  narrative?: string | null;
  /** 发票/收据类型 */
  receiptType?: string | null;
  /** 流水编号 */
  serialId?: string | null;
  /** 原系统主键编号 */
  originalId?: string | null;
  /** 流程数据大状态 */
  status?: string | null;
  /**
   * 提交时间
   * @format date-time
   */
  submittedOn?: string | null;
  /** 工作流状态 */
  flowState?: string | null;
  /** 申请人 */
  applicant?: string | null;
  /**
   * 工作流结束时间
   * @format date-time
   */
  flowEndDateTime?: string | null;
  /** 是否超期 */
  isOverdue?: boolean | null;
  /** 备注 */
  remark?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface FinancialExpenseApplyDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 费用申请表 */
  response?: FinancialExpenseApplyDto;
}

/** 费用明细表 */
export interface FinancialExpenseItemDto {
  /** 费用申请编号 */
  applyId?: string | null;
  /** 费用报销人 */
  employeeId?: string | null;
  /** 费用报销人名称 */
  employeeName?: string | null;
  /**
   * 费用日期
   * @format date-time
   */
  expenseDate?: string | null;
  /** 费用类型 */
  category?: string | null;
  /** 费用类型 */
  categoryText?: string | null;
  /** 费用类型子类 */
  subCategory?: string | null;
  /** 账单编号 */
  billingId?: string | null;
  /** 是否入账 */
  isBillable?: string | null;
  /** 是否入账 */
  isBillableText?: string | null;
  /**
   * 金额
   * @format double
   */
  amount?: number | null;
  /** 币种 */
  currency?: string | null;
  /** 币种 */
  currencyText?: string | null;
  /** 描述 */
  narrative?: string | null;
  /** 类型 */
  expenseType?: string | null;
  /** 类型 */
  expenseTypeText?: string | null;
  /** 商户名称 */
  merchant?: string | null;
  /** 案件编号 */
  caseId?: string | null;
  /** 客户编号 */
  clientId?: string | null;
  /** 收费代码 */
  chargeCode?: string | null;
  /** 费用详细描述 */
  expenseNarrative?: string | null;
  /** 报销单ID */
  expenseReportId?: string | null;
  /** 是否已开票 */
  isInvoiced?: string | null;
  /** 是否已开票 */
  isInvoicedText?: string | null;
  /** 是否为POBO */
  isPobo?: string | null;
  /** 是否为POBO */
  isPoboText?: string | null;
  /**
   * 支付时间
   * @format date-time
   */
  payDate?: string | null;
  /**
   * 税率
   * @format double
   */
  taxRate?: number | null;
  /**
   * 税额
   * @format double
   */
  taxAmount?: number | null;
  /**
   * 小计金额
   * @format double
   */
  subtotal?: number | null;
  /** 发票编号 */
  invoiceId?: string | null;
  /** 发票类型 */
  invoiceType?: string | null;
  /** 发票类型 */
  invoiceTypeText?: string | null;
  /** 结算币种 */
  settlementCurrency?: string | null;
  /** 结算币种 */
  settlementCurrencyText?: string | null;
  /**
   * 结算汇率
   * @format double
   */
  settlementRate?: number | null;
  /**
   * 结算金额
   * @format double
   */
  settlementAmount?: number | null;
  /** 是否销账 */
  isWriteOff?: string | null;
  /** 是否销账 */
  isWriteOffText?: string | null;
  /** 销账类型 */
  writeOffType?: string | null;
  /** 销账类型 */
  writeOffTypeText?: string | null;
  /** 销账理由 */
  writeOffRemark?: string | null;
  /**
   * 数量
   * @format double
   */
  count?: number | null;
  /** 参与人员 */
  attendees?: string | null;
  /** 备注 */
  remark?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface FinancialExpenseItemDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 费用明细表 */
  response?: FinancialExpenseItemDto;
}

/** 发票分配信息表 */
export interface FinancialInvoiceAllocationDto {
  /** 发票编号 */
  invoiceId?: string | null;
  /** 账单编号 */
  billingId?: string | null;
  /** 发票申请编号 */
  invoiceApplyId?: string | null;
  /** 案件编号 */
  caseId?: string | null;
  /**
   * 分配比例
   * @format double
   */
  allocationRate?: number;
  /**
   * 分配金额
   * @format double
   */
  allocationAmount?: number;
  /**
   * 尾差
   * @format double
   */
  roundingDifference?: number;
  /** 分配策略 */
  allocationStrattegy?: string | null;
  /** 分配人 */
  employeeId?: string | null;
  /** 是否保密 */
  ifConfidential?: string | null;
  /** 是否保密 */
  ifConfidentialText?: string | null;
  /** 备注 */
  remark?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface FinancialInvoiceAllocationDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: FinancialInvoiceAllocationDto[] | null;
}

/** 通用返回信息类 */
export interface FinancialInvoiceAllocationDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 发票分配信息表 */
  response?: FinancialInvoiceAllocationDto;
}

/** 通用分页信息类 */
export interface FinancialInvoiceAllocationDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: FinancialInvoiceAllocationDto[] | null;
}

/** 通用返回信息类 */
export interface FinancialInvoiceAllocationDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: FinancialInvoiceAllocationDtoPageModel;
}

/** 付款方表 */
export interface FinancialPayerInfoDto {
  /** 付款方名称 */
  name?: string | null;
  /** 外文名称 */
  foreignName?: string | null;
  /** 曾用名 */
  formerName?: string | null;
  /** 别名 */
  altName?: string | null;
  /** 证件类型 */
  creditType?: string | null;
  /** 证件类型 */
  creditTypeText?: string | null;
  /** 证件号码 */
  creditCode?: string | null;
  /** 税号 */
  taxNumber?: string | null;
  /** 邮箱地址 */
  emailAddress?: string | null;
  /** 联系电话 */
  landline?: string | null;
  /** 所属行业 */
  industry?: string | null;
  /** 所属行业 */
  industryText?: string | null;
  /** 区域城市 */
  area?: string | null;
  /** 注册名称 */
  regName?: string | null;
  /** 注册号 */
  regNo?: string | null;
  /** 银行名称 */
  bankName?: string | null;
  /** 银行账号 */
  bankAccount?: string | null;
  /** 开户行 */
  openingBranchName?: string | null;
  /** 开户行地址 */
  openingBranchAddress?: string | null;
  /** 银行联系电话 */
  bankLandline?: string | null;
  /** 所属客户编号 */
  clientId?: string | null;
  /** 客户性质 */
  customerNature?: string | null;
  /** 客户性质 */
  customerNatureText?: string | null;
  /** 备注 */
  remark?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface FinancialPayerInfoDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: FinancialPayerInfoDto[] | null;
}

/** 通用返回信息类 */
export interface FinancialPayerInfoDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 付款方表 */
  response?: FinancialPayerInfoDto;
}

/** 通用分页信息类 */
export interface FinancialPayerInfoDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: FinancialPayerInfoDto[] | null;
}

/** 通用返回信息类 */
export interface FinancialPayerInfoDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: FinancialPayerInfoDtoPageModel;
}

/** 收款信息表 */
export interface FinancialReceiptDto {
  /** 案件编号 */
  caseId?: string | null;
  /** 客户编号 */
  clientId?: string | null;
  /** 公共收款编号 */
  publicReceiptId?: string | null;
  /** 收款方式 */
  payMode?: string | null;
  /** 收款方式 */
  payModeText?: string | null;
  /** 付款方名称 */
  payerName?: string | null;
  /** 是否境外汇款 */
  isOverseasPay?: string | null;
  /** 是否境外汇款 */
  isOverseasPayText?: string | null;
  /** 汇款国家 */
  remittanceCountry?: string | null;
  /** 汇款国家 */
  remittanceCountryText?: string | null;
  /** 收款币种 */
  currency?: string | null;
  /** 收款币种 */
  currencyText?: string | null;
  /** 支票编号 */
  checkNo?: string | null;
  /**
   * 收款总额
   * @format double
   */
  payAmount?: number;
  /**
   * 收款日期
   * @format date-time
   */
  receiptDate?: string | null;
  /** 结算币种 */
  settlementCurrency?: string | null;
  /** 结算币种 */
  settlementCurrencyText?: string | null;
  /**
   * 结算汇率
   * @format double
   */
  settlementRate?: number;
  /**
   * 结算金额
   * @format double
   */
  settlementAmount?: number;
  /** 银行流水号 */
  bankSerialId?: string | null;
  /** 认领人 */
  claimEmployeeId?: string | null;
  /** 附件 */
  attachmentId?: string | null;
  /** 付款说明 */
  paymentExplain?: string | null;
  /** 认领收款编号 */
  receiptId?: string | null;
  /** 账单编号 */
  billingId?: string | null;
  /** 发票编号 */
  invoiceId?: string | null;
  /** 是否分配 */
  isAllocated?: string | null;
  /** 是否分配 */
  isAllocatedText?: string | null;
  /**
   * 官费金额
   * @format double
   */
  officialFees?: number;
  /**
   * 律师费金额
   * @format double
   */
  lawyerFee?: number;
  /**
   * 支出费用
   * @format double
   */
  expenseFee?: number;
  /** 流水编号 */
  serialId?: string | null;
  /** 原系统主键编号 */
  originalId?: string | null;
  /** 流程数据大状态 */
  status?: string | null;
  /**
   * 提交时间
   * @format date-time
   */
  submittedOn?: string | null;
  /** 工作流状态 */
  flowState?: string | null;
  /** 申请人 */
  applicant?: string | null;
  /**
   * 工作流结束时间
   * @format date-time
   */
  flowEndDateTime?: string | null;
  /** 是否超期 */
  isOverdue?: boolean | null;
  /**
   * 尾差金额
   * @format double
   */
  roundingDifference?: number;
  /** 到账原因 */
  receiptReason?: string | null;
  /**
   * 认领金额
   * @format double
   */
  claimAmount?: number;
  /**
   * 代收代付费
   * @format double
   */
  poboSubtotalAmount?: number;
  /**
   * 分配金额
   * @format double
   */
  allocatedAmount?: number;
  /** 是否全额到账 */
  isFullyOffset?: string | null;
  /** 是否全额到账 */
  isFullyOffsetText?: string | null;
  /** 是否同步账单汇率 */
  isSyncBillingExchangeRate?: string | null;
  /** 是否同步账单汇率 */
  isSyncBillingExchangeRateText?: string | null;
  /**
   * 税率
   * @format double
   */
  taxRate?: number;
  /**
   * 税费
   * @format double
   */
  taxAmount?: number;
  /** 描述说明 */
  narrative?: string | null;
  /** 账单结算币种 */
  billingCurrency?: string | null;
  /** 账单结算币种 */
  billingCurrencyText?: string | null;
  /**
   * 账单结算汇率
   * @format double
   */
  billingRate?: number;
  /**
   * 账单结算金额
   * @format double
   */
  billingAmount?: number;
  /** 案件分配信息 */
  caseDistributions?: CaseDistributionDto[] | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface FinancialReceiptDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 收款信息表 */
  response?: FinancialReceiptDto;
}

/** 财年偏好设置 */
export interface FiscalYearPreferenceSettingDto {
  /**
   * 财年起始月份
   * @format int32
   * @min 1
   * @max 12
   */
  fiscalYearStartMonth?: number;
}

/** 通用返回信息类 */
export interface FiscalYearPreferenceSettingDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 财年偏好设置 */
  response?: FiscalYearPreferenceSettingDto;
}

export interface FlowActionDto {
  /** Id */
  id?: string | null;
  /** 名称 */
  name?: string | null;
  /** 显示名称 */
  displayName?: string | null;
  /** 状态值Id */
  flowStateId?: string | null;
  /** 参数 */
  params?: string | null;
  /**
   * 排序
   * @format int32
   */
  sort?: number;
  /** 页面 */
  userType?: string | null;
}

export interface FlowActivityDto {
  /** 工作流实例id */
  flowInstanceId?: string | null;
  /** 审批人用户id */
  userId?: string | null;
  /** 审批人用户名 */
  userName?: string | null;
  /**
   * 审批用户类型
   * @format int32
   */
  userType?: 0 | 1 | 2 | 3;
  /** 节点id */
  nodeId?: string | null;
  /** 节点名称 */
  nodeName?: string | null;
  /** 上一步骤节点 */
  prevNodeId?: string | null;
  prevNodeName?: string | null;
  /** 流程编号 */
  flowDefineId?: string | null;
  /** 接收人;多个接收人 */
  senderName?: string | null;
  /** 部门编号 */
  departId?: string | null;
  /** 部门名称 */
  departName?: string | null;
  /**
   * 完成时间
   * @format date-time
   */
  completetime?: string | null;
  /**
   * 阅读时间
   * @format date-time
   */
  readTime?: string | null;
  /** 审批意见 */
  approvalOpinion?: string | null;
  /**
   * 挂起时间
   * @format date-time
   */
  hungupTime?: string | null;
  /** 是否读取 */
  isRead?: boolean;
  /** 是否通过 */
  isPass?: boolean;
  /** 额外参数 */
  paylodParams?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface FlowActivityDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: FlowActivityDto[] | null;
}

/** 流程节点定义 */
export interface FlowActivityNode {
  /** 节点id */
  nodeId?: string | null;
  /** 节点名称 */
  nodeName?: string | null;
  /** 流程编号 */
  flowDefineId?: string | null;
  /** 流程节点数据 */
  nodeData?: string | null;
  /** 当前工作流状态 */
  flowStateId?: string | null;
  /** 工作流退回节点状态 */
  flowReturnStateId?: string | null;
  /** 工作流关闭节点状态 */
  flowCloseStateId?: string | null;
  /** 流程步骤 */
  nodeStep?: string | null;
  /** 节点类型 */
  nodeType?: string | null;
  /** 是否是客户执行节点 */
  isGuestNode?: boolean;
  /** 节点时限 */
  isNodeTimelimit?: boolean;
  /** 节点时限规则 */
  nodeTimelimitRule?: string | null;
  /**
   * 节点时限规则
   * @format int32
   */
  nodeTimelimitType?: 0 | 1 | 2 | null;
  /**
   * 紧急级别
   * @format int32
   */
  urgentLevel?: number;
  /** 是否会签节点 */
  isCounterSigned?: boolean;
  /**
   * 会签通过率
   * @format int32
   */
  counterSignedPassRate?: number;
  /** 提交文本 */
  submitText?: string | null;
  /** 暂存文本 */
  temporarilyText?: string | null;
  /** 回退文本 */
  returnText?: string | null;
  /** 加签文本 */
  increaseText?: string | null;
  /** 转交文本 */
  transferText?: string | null;
  /** 是否逐人审批 只有在会签节点时有效 */
  examinationApproval?: boolean;
  /** 关闭流程 */
  closeFlowText?: string | null;
  /**
   * 会签选择类型
   * @format int32
   */
  counterSignedType?: 2 | 3 | null;
  /** 是否允许审批驳回 */
  isRollback?: boolean;
  /**
   * 退回规则;默认退回一步，前置节点都可退回，指定节点可退回
   * @format int32
   */
  rollbackRule?: 1 | 2 | 3 | -1;
  /** 驳回节点id */
  rollbackToNodeId?: string | null;
  /** 经办人按流经节点设置 复制上个节点参数设置;经办人按流经节点设置 复制上个节点参数设置 */
  actorBypPrevNodeId?: string | null;
  /** 是否开启跳转到节点;是否开启跳转到节点 */
  isSendToNode?: boolean;
  /** 跳转到指定节点;跳转到指定节点 */
  sendToNode?: string | null;
  /** 是否允许撤回;是否允许撤回 */
  isWithdraw?: boolean;
  /** 是否允许转交;是否允许转交 */
  isTransmitted?: boolean;
  /** 是否允许抄送;是否允许抄送 */
  isCC?: boolean;
  /** 抄送人id字符串 */
  ccUserIds?: string | null;
  /** 抄送人名称字符串 */
  ccUserNames?: string | null;
  /** 通用参数 */
  paylodParams?: string | null;
  /** 审核默认意见 */
  approvalOpinion?: string | null;
  /** 消息队列事件名称 */
  capEventName?: string | null;
  /** 审批意见开关 */
  isApprovalOpinion?: boolean;
  /** 是否提醒发送用户 */
  isRemindUser?: boolean;
  /** 提醒用户id */
  remindUserIds?: string | null;
  /** 提醒角色id */
  remindByPositionIds?: string | null;
  /** 提醒类型 */
  remindType?: string | null;
  /** 是否紧急 */
  isUrgent?: boolean;
  /** 是否结束流程 */
  isEndFlow?: boolean;
  /** 是否暂存 */
  isStaging?: boolean;
  /** 是否发送 */
  isSend?: boolean;
  /** 是否加签 */
  isCountersignUser?: boolean;
  /** 是否节点控制 */
  isNodeControl?: boolean;
  /** 允许退回直达审批 */
  isRollbackToApproval?: boolean;
  /** 是否允许编辑 */
  isAllowEdit?: boolean;
  /** 是否允许申请人审核 */
  isApplicantAudit?: boolean;
  /** 退回是否删除待办 */
  isReturnDeleteTodo?: boolean;
  /** 撤回是否删除待办 */
  isWithdrawDeleteToDo?: boolean;
  /** 雪花Id */
  id?: string | null;
  /** 租户ID */
  tenantId?: string | null;
  /**
   * 系统租户表
   *
   * 根据TenantType 分为两种方案:
   *
   * 1.按租户字段区分
   *
   * 2.按租户分库
   *
   *
   *
   * 注意:
   *
   * 使用租户Id方案,无需配置分库的连接
   */
  tenant?: SysTenant;
  /** 部门ID  DepartmentId */
  organizationUnitId?: string | null;
  /** 组织机构 */
  organizationUnit?: SysOrganizationUnits;
  /** 分所Id */
  officeId?: string | null;
  /** 组织机构 */
  office?: SysOrganizationUnits;
  /** 备注 */
  remark?: string | null;
  /**
   * 是否草稿
   * <param name="如果是操作传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
  /**
   * 中立字段，某些表可使用某些表不使用
   *
   * 逻辑上的删除，非物理删除
   *
   * 例如：单据删除并非直接删除
   */
  isDeleted?: boolean;
  /**
   * 中立字段
   *
   * 是否内置数据
   */
  isInternal?: boolean;
  /** 创建ID */
  createId?: string | null;
  /** 创建者 */
  createBy?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 修改ID */
  modifyId?: string | null;
  /** 更新者 */
  modifyBy?: string | null;
  /**
   * 修改日期
   * @format date-time
   */
  modifyTime?: string | null;
  /**
   * 数据版本
   * @format int64
   */
  version?: number;
  /** 删除ID */
  deleteId?: string | null;
  /** 删除者 */
  deleteBy?: string | null;
  /**
   * 删除日期
   * @format date-time
   */
  deleteTime?: string | null;
}

export interface FlowActivityNodeBaseDto {
  id?: string | null;
  /** 节点id */
  nodeId?: string | null;
  /** 节点名称 */
  nodeName?: string | null;
  /** 流程编号 */
  flowDefineId?: string | null;
  /** 流程名称 */
  flowName?: string | null;
  /** 流程节点数据 */
  nodeData?: string | null;
  /** 流程步骤 */
  nodeStep?: string | null;
  /** 节点类型 */
  nodeType?: string | null;
  /** 是否是客户执行节点 */
  isGuestNode?: boolean;
  /** 当前工作流状态 */
  flowStateId?: string | null;
  /** 工作流退回节点状态 */
  flowReturnStateId?: string | null;
  /** 工作流关闭节点状态 */
  flowCloseStateId?: string | null;
  /** 回调事件 */
  capEventName?: string | null;
  /** 节点时限 */
  isNodeTimelimit?: boolean;
  /** 节点时限规则 */
  nodeTimelimitRule?: string | null;
  /** 提交文本 */
  submitText?: string | null;
  /** 暂存文本 */
  temporarilyText?: string | null;
  /** 回退文本 */
  returnText?: string | null;
  /** 加签文本 */
  increaseText?: string | null;
  /** 转交文本 */
  transferText?: string | null;
  /** 关闭流程 */
  closeFlowText?: string | null;
  /**
   * 节点失效规则类型
   * @format int32
   */
  nodeTimelimitRuleType?: 0 | 1 | 2 | null;
  /**
   * 紧急级别
   * @format int32
   */
  urgentLevel?: number;
  /** 是否会签节点 */
  isCounterSigned?: boolean;
  /**
   * 会签通过率
   * @format int32
   */
  counterSignedPassRate?: number;
  /**
   * 会签选择类型
   * @format int32
   */
  counterSignedType?: 2 | 3 | null;
  /** 是否允许审批驳回 */
  isRollback?: boolean;
  /**
   * 退回规则;默认退回一步，前置节点都可退回，指定节点可退回
   * @format int32
   */
  rollbackRule?: 1 | 2 | 3 | -1 | null;
  /** 驳回节点id */
  rollbackToNodeId?: string | null;
  /** 经办人按流经节点设置 复制上个节点参数设置;经办人按流经节点设置 复制上个节点参数设置 */
  actorBypPrevNodeId?: string | null;
  /** 是否开启跳转到节点;是否开启跳转到节点 */
  isSendToNode?: boolean | null;
  /** 跳转到指定节点;跳转到指定节点 */
  sendToNode?: string | null;
  /** 是否允许撤回;是否允许撤回 */
  isWithdraw?: boolean | null;
  /** 是否允许转交;是否允许转交 */
  isTransmitted?: boolean;
  /** 是否允许抄送;是否允许抄送 */
  isCC?: boolean | null;
  /** 通用参数 */
  paylodParams?: string | null;
  /** 审核默认意见 */
  approvalOpinion?: string | null;
  /** 审批意见开关 */
  isApprovalOpinion?: boolean;
  /** 是否提醒发送用户 */
  isRemindUser?: boolean | null;
  /** 提醒类型 */
  remindType?: string | null;
  /** 是否紧急 */
  isUrgent?: boolean;
  /** 是否结束流程 */
  isEndFlow?: boolean;
  /** 是否暂存 */
  isStaging?: boolean;
  /** 是否发送 */
  isSend?: boolean;
  /** 是否加签 */
  isCountersignUser?: boolean;
  /** 是否节点控制 */
  isNodeControl?: boolean;
  /** 允许退回直达审批 */
  isRollbackToApproval?: boolean;
  /** 是否允许申请人审批 */
  isApplicantAudit?: boolean;
  /** 是否逐人审批 只有在会签节点时有效 */
  examinationApproval?: boolean;
  /** 是否允许可编辑 */
  isAllowEdit?: boolean | null;
}

/** 通用返回信息类 */
export interface FlowActivityNodeBaseDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  response?: FlowActivityNodeBaseDto;
}

/** 节点基本信息 */
export interface FlowActivityNodeBriefDto {
  id?: string | null;
  nodeId?: string | null;
  nodeName?: string | null;
  nodeType?: string | null;
}

/** 通用返回信息类 */
export interface FlowActivityNodeBriefDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: FlowActivityNodeBriefDto[] | null;
}

/** 节点信息 */
export interface FlowActivityNodeDto {
  activityNode?: FlowActivityNodeBaseDto;
  conditions?: FlowCondition[] | null;
}

/** 通用返回信息类 */
export interface FlowActivityNodeDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 节点信息 */
  response?: FlowActivityNodeDto;
}

/** 审批记录 */
export interface FlowActivityRecordDto {
  /** 流程id */
  flowDefineId?: string | null;
  /** 实例id */
  flowInstanceId?: string | null;
  /**
   * 状态类型;发起、退回、挂起 、催办 等过程多种状态
   * @format int32
   */
  actionType?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15;
  /** 状态类型文本 */
  actionTypeText?: string | null;
  /** 从节点id */
  nodeIdFrom?: string | null;
  /** 从节点名称 */
  nodeNameFrom?: string | null;
  /** 到节点id */
  nodeIdTo?: string | null;
  /** 到节点名称 */
  nodeNameTo?: string | null;
  /** 从人员id */
  userIdFrom?: string | null;
  /** 从人员名称 */
  usernameFrom?: string | null;
  /** 到人员id */
  userIdTo?: string | null;
  /** 到人员名称 */
  usernameTo?: string | null;
  /** 节点数据记录 */
  nodeDate?: string | null;
  /** 执行人 */
  executor?: string | null;
  /** 审核意见 */
  approvalOpinion?: string | null;
  avatar?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface FlowActivityRecordDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: FlowActivityRecordDto[] | null;
}

export interface FlowAdditionalInformationDto {
  /** 审核消息 */
  message?: string[] | null;
  /** 页面定义 */
  viewData?: NameValue[] | null;
}

/** 审批情况 */
export interface FlowApprovalStatusRecordDto {
  id?: string | null;
  nodeId?: string | null;
  nodeName?: string | null;
  statusText?: string | null;
  status?: boolean;
  /** @format date-time */
  approvalDate?: string | null;
  flowInstanceId?: string | null;
  userId?: string | null;
  userName?: string | null;
  approvalOpinion?: string | null;
  avatar?: string | null;
}

/** 通用返回信息类 */
export interface FlowApprovalStatusRecordDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: FlowApprovalStatusRecordDto[] | null;
}

export interface FlowApprovalTimelineDto {
  stepName?: string | null;
  approvalRecords?: FlowApprovalStatusRecordDto[] | null;
}

/** 通用返回信息类 */
export interface FlowApprovalTimelineDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: FlowApprovalTimelineDto[] | null;
}

export interface FlowBaseInput {
  /** 工作流名称 */
  flowName?: string | null;
  /** 业务Id */
  businessId?: string | null;
}

export interface FlowCancelInput {
  /** 业务Id */
  businessId?: string | null;
  /** 取消原因 */
  approvalOpinion?: string | null;
}

/** 流程图 */
export interface FlowChartDto {
  flowDefine?: FlowDefineDto;
  flowChartData?: any[] | null;
}

/** 通用返回信息类 */
export interface FlowChartDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 流程图 */
  response?: FlowChartDto;
}

export interface FlowCloseInput {
  /** 审批意见 */
  approvalOpinion?: string | null;
  /** 工作流名称 */
  flowName?: string | null;
  /** 业务Id */
  businessId?: string | null;
}

/** 工作流控制 */
export interface FlowCondition {
  /** 流程编号 */
  flowDefineId?: string | null;
  /** 连接线控制id */
  flowControlId?: string | null;
  /** 节点id */
  fromNodeId?: string | null;
  /**
   * 条件类型;0节点完成条件、1流程完成条件、2方向条件
   * @format int32
   */
  condType?: 0 | 1 | 2;
  /**
   * 条件数据源;0表单数据、1、sql计算
   * @format int32
   */
  dataFrom?: 0 | 1 | 3 | 4;
  /** 条件数据源文本 */
  dataFromText?: string | null;
  /** 到达节点Id;对方向条件有效 */
  toNodeId?: string | null;
  /** 属性ID;属性主键 如果存在 */
  attributeId?: string | null;
  /** 属性字段;业务表名 */
  modelValue?: string | null;
  /** 属性字段;如表单属性字段 Title、DeptName等 */
  fieldName?: string | null;
  /** 属性名称;属性中文名称 */
  fieldDescription?: string | null;
  /** 运算符号;如> >!= 包含等等 */
  conditionalTypeAlias?: string | null;
  /**
   * 运算符号枚举
   * @format int32
   */
  conditionalType?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | null;
  /** 要运算的值;具体值 如Emp = zhangsan ，123 */
  fieldValue?: string | null;
  /** 通用参数 */
  payloadParams?: string | null;
  /** 雪花Id */
  id?: string | null;
  /** 租户ID */
  tenantId?: string | null;
  /**
   * 系统租户表
   *
   * 根据TenantType 分为两种方案:
   *
   * 1.按租户字段区分
   *
   * 2.按租户分库
   *
   *
   *
   * 注意:
   *
   * 使用租户Id方案,无需配置分库的连接
   */
  tenant?: SysTenant;
  /** 部门ID  DepartmentId */
  organizationUnitId?: string | null;
  /** 组织机构 */
  organizationUnit?: SysOrganizationUnits;
  /** 分所Id */
  officeId?: string | null;
  /** 组织机构 */
  office?: SysOrganizationUnits;
  /** 备注 */
  remark?: string | null;
  /**
   * 是否草稿
   * <param name="如果是操作传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
  /**
   * 中立字段，某些表可使用某些表不使用
   *
   * 逻辑上的删除，非物理删除
   *
   * 例如：单据删除并非直接删除
   */
  isDeleted?: boolean;
  /**
   * 中立字段
   *
   * 是否内置数据
   */
  isInternal?: boolean;
  /** 创建ID */
  createId?: string | null;
  /** 创建者 */
  createBy?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 修改ID */
  modifyId?: string | null;
  /** 更新者 */
  modifyBy?: string | null;
  /**
   * 修改日期
   * @format date-time
   */
  modifyTime?: string | null;
  /**
   * 数据版本
   * @format int64
   */
  version?: number;
  /** 删除ID */
  deleteId?: string | null;
  /** 删除者 */
  deleteBy?: string | null;
  /**
   * 删除日期
   * @format date-time
   */
  deleteTime?: string | null;
}

/** 通用返回信息类 */
export interface FlowConditionListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: FlowCondition[] | null;
}

export interface FlowConditionUpsertDto {
  id?: string | null;
  modelValue?: string | null;
  fieldName?: string | null;
  /** 属性名称;属性中文名称 */
  fieldDescription?: string | null;
  /**
   * 运算符号;如> >!= 包含等等
   * @format int32
   */
  conditionalType?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16;
  conditionalTypeAlias?: string | null;
  /** 要运算的值 */
  fieldValue?: string | null;
  /** 额外参数 */
  payloadParams?: string | null;
  /**
   * 条件类型;0方向条件、1流程完成条件、2节点完成条件
   * @format int32
   */
  condType?: 0 | 1 | 2;
  /**
   * 条件数据源;0表单数据 2、sql计算
   * @format int32
   */
  dataFrom?: 0 | 1 | 3 | 4;
}

export interface FlowCountersignInput {
  /** 加签用户列表 */
  approvers?: Approver[] | null;
  userIds?: string[] | null;
  /** 工作流名称 */
  flowName?: string | null;
  /** 业务Id */
  businessId?: string | null;
}

/** 流程定义 */
export interface FlowDefine {
  /** 名称 */
  name?: string | null;
  /** 业务分类 */
  buinessType?: string | null;
  /**
   * 版本号;流程版本
   * @format int32
   */
  flowVersion?: number;
  /** 模型名称;模型名称 */
  modelName?: string | null;
  /** 模型值 */
  modelValue?: string | null;
  /** 节点 */
  nodes?: string | null;
  /** 边缘线 */
  edges?: string | null;
  /** 来源流程id */
  inheritId?: string | null;
  /** 通用参数 */
  paylodParams?: string | null;
  /** 是否允许在运行时编辑 */
  isAllowEdit?: boolean;
  /** 允许流程发起人催办 */
  isAllowRemind?: boolean;
  /** 提醒类型字符串分割 */
  remindType?: string | null;
  /** 是否开启自动提交 */
  isAutoCommit?: boolean;
  /**
   * 自动提交选项
   * @format int32
   */
  autoCommitType?: 2 | 3 | 4;
  /** 是否允许撤回 */
  isAllowWithdraw?: boolean;
  /**
   * 撤回类型
   * @format int32
   */
  withdrawType?: 2 | 3 | 4;
  /** 作用域 */
  isScope?: boolean;
  additionalInformation?: FlowAdditionalInformationDto;
  flowActivityNodes?: FlowActivityNode[] | null;
  /** 雪花Id */
  id?: string | null;
  /** 租户ID */
  tenantId?: string | null;
  /**
   * 系统租户表
   *
   * 根据TenantType 分为两种方案:
   *
   * 1.按租户字段区分
   *
   * 2.按租户分库
   *
   *
   *
   * 注意:
   *
   * 使用租户Id方案,无需配置分库的连接
   */
  tenant?: SysTenant;
  /** 部门ID  DepartmentId */
  organizationUnitId?: string | null;
  /** 组织机构 */
  organizationUnit?: SysOrganizationUnits;
  /** 分所Id */
  officeId?: string | null;
  /** 组织机构 */
  office?: SysOrganizationUnits;
  /** 备注 */
  remark?: string | null;
  /**
   * 是否草稿
   * <param name="如果是操作传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
  /**
   * 中立字段，某些表可使用某些表不使用
   *
   * 逻辑上的删除，非物理删除
   *
   * 例如：单据删除并非直接删除
   */
  isDeleted?: boolean;
  /**
   * 中立字段
   *
   * 是否内置数据
   */
  isInternal?: boolean;
  /** 创建ID */
  createId?: string | null;
  /** 创建者 */
  createBy?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 修改ID */
  modifyId?: string | null;
  /** 更新者 */
  modifyBy?: string | null;
  /**
   * 修改日期
   * @format date-time
   */
  modifyTime?: string | null;
  /**
   * 数据版本
   * @format int64
   */
  version?: number;
  /** 删除ID */
  deleteId?: string | null;
  /** 删除者 */
  deleteBy?: string | null;
  /**
   * 删除日期
   * @format date-time
   */
  deleteTime?: string | null;
}

export interface FlowDefineDto {
  /** 名称;名称 */
  name?: string | null;
  /** 业务分类;业务类型分类 */
  buinessType?: string | null;
  /**
   * 版本号;流程版本
   * @format int32
   */
  version?: number;
  /** 模型名称;模型名称 */
  modelName?: string | null;
  /** 模型值 */
  modelValue?: string | null;
  /** 是否允许在运行时编辑 */
  isAllowEdit?: boolean | null;
  /** 允许流程发起人催办 */
  isAllowRemind?: boolean | null;
  /** 提醒类型字符串分割 */
  remindType?: number[] | null;
  /** 是否开启自动提交 */
  isAutoCommit?: boolean | null;
  /**
   * 自动提交选项
   * @format int32
   */
  autoCommitType?: 2 | 3 | 4 | null;
  /** 是否允许撤回 */
  isAllowWithdraw?: boolean | null;
  /**
   * 撤回类型
   * @format int32
   */
  withdrawType?: 2 | 3 | 4 | null;
  /** 作用域 */
  isScope?: boolean | null;
  /** 备注 */
  remark?: string | null;
  id?: string | null;
  /** 租户ID */
  tenantId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /**
   * 状态
   *
   * 中立字段，某些表可使用某些表不使用
   */
  isEnabled?: boolean;
  additionalInformation?: FlowAdditionalInformationDto;
  isDeleted?: boolean;
}

/** 通用分页信息类 */
export interface FlowDefinePageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: FlowDefine[] | null;
}

/** 通用返回信息类 */
export interface FlowDefinePageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: FlowDefinePageModel;
}

/** 关系线 */
export interface FlowEdgeDataDto {
  id?: string | null;
  nodeId?: string | null;
  fromNodeId?: string | null;
  toNodeId?: string | null;
  nodeName?: string | null;
  /** @format int32 */
  type?: 0 | 1 | 2;
  /** @format int32 */
  condFlowingType?: 0 | 1 | null;
  /** @format int32 */
  condControlType?: 0 | 1 | null;
}

export interface FlowEdgesCondtionDto {
  /** 关系线 */
  edge?: FlowEdgeDataDto;
  conditions?: FlowConditionUpsertDto[] | null;
}

/** 通用返回信息类 */
export interface FlowEdgesCondtionDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  response?: FlowEdgesCondtionDto;
}

export interface FlowEventInput {
  /** 参数 */
  params?: string | null;
  /** 审批意见 */
  approvalOpinion?: string | null;
  /** 是否通过 */
  isPass?: boolean;
  /** 工作流名称 */
  flowName?: string | null;
  /** 业务Id */
  businessId?: string | null;
}

export interface FlowInitInput {
  /** 参数 */
  params?: string | null;
  /** 工作流名称 */
  flowName?: string | null;
  /** 业务Id */
  businessId?: string | null;
}

/** 根据业务id和流程id发送 */
export interface FlowNodeSendByBusinessInput {
  /** /实例id */
  flowDefineId?: string | null;
  /** 业务主键id */
  businessId?: string | null;
  /** 从那个节点开始 */
  fromNode?: string | null;
  /** 审批意见 */
  approvalOpinion?: string | null;
  /** 到达节点主键id或者步骤名称 */
  toNode?: string | null;
  payload?: string | null;
}

/** 根据流程实例id发送 */
export interface FlowNodeSendInput {
  /** /实例id */
  flowInstanceId?: string | null;
  /** 是否是回滚流程 */
  isReFlow?: boolean;
  /** 参数 */
  params?: string | null;
  /** 是否是联合审批 */
  isJoinPass?: boolean;
  /** 联合审批用户Id */
  joinUserIds?: string[] | null;
  /** 审批意见 */
  approvalOpinion?: string | null;
  /** 到达节点主键id或者步骤名称 */
  toNode?: string | null;
  payload?: string | null;
}

export interface FlowReFlowInput {
  flowStateId?: string | null;
  approvalOpinion?: string | null;
  /** 工作流名称 */
  flowName?: string | null;
  /** 业务Id */
  businessId?: string | null;
}

export interface FlowScopeType {
  name?: string | null;
  value?: string | null;
  properties?: Property[] | null;
}

/** 通用返回信息类 */
export interface FlowScopeTypeListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: FlowScopeType[] | null;
}

/** 通用返回信息类 */
export interface FlowScopeTypeMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  response?: FlowScopeType;
}

export interface FlowStateDto {
  /** 工作流Id */
  definitionId?: string | null;
  /** 名称 */
  name?: string | null;
  /** 节点Id */
  nodeId?: string | null;
  /** 显示名称 */
  displayName?: string | null;
  /** 备注 */
  description?: string | null;
  /** 是否是审核节点 */
  isAudit?: boolean;
  /**
   * 排序
   * @format int32
   */
  sort?: number;
  /**
   * 状态类型
   * @format int32
   */
  category?: 0 | 1 | 2 | 3 | 4 | null;
  /** 工作流状态按钮 */
  flowActionDtos?: FlowActionDto[] | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface FlowStateDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: FlowStateDto[] | null;
}

export interface FlowTodoDto {
  /** 流程编号 */
  flowDefineId?: string | null;
  /** 流程名称 */
  flowName?: string | null;
  /** 标题 */
  title?: string | null;
  /** 发起人 */
  startName?: string | null;
  /**
   * 发起时间
   * @format date-time
   */
  sendTime?: string | null;
  flowState?: string | null;
  /** 工作流实例id */
  flowInstanceId?: string | null;
  /** 处理人 */
  userId?: string | null;
  /** 处理人 */
  userName?: string | null;
  /** 节点id */
  nodeId?: string | null;
  /** 节点名称 */
  nodeName?: string | null;
  buinessId?: string | null;
  modelValue?: string | null;
}

/** 通用分页信息类 */
export interface FlowTodoDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: FlowTodoDto[] | null;
}

/** 通用返回信息类 */
export interface FlowTodoDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: FlowTodoDtoPageModel;
}

export interface FlowTransferInput {
  /** 移交人Id */
  userId?: string | null;
  /** 工作流名称 */
  flowName?: string | null;
  /** 业务Id */
  businessId?: string | null;
}

export interface FlowUserApproverDto {
  /** 用户Id */
  userId?: string | null;
  /** 用户名称 */
  displayName?: string | null;
  /** 用户头像 */
  pictureUrl?: string | null;
  /** 是否当前能审核 逐人审批 */
  isAudit?: boolean;
  /** 是否已审核 */
  isPass?: boolean;
  /**
   * 审批时间
   * @format date-time
   */
  completetime?: string | null;
  /**
   * 审核顺序  逐人审批
   * @format int32
   */
  sort?: number;
}

/** 通用返回信息类 */
export interface FlowUserApproverDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: FlowUserApproverDto[] | null;
}

export interface FlowWithdrawInput {
  /** 审批意见 */
  approvalOpinion?: string | null;
  /** @format int32 */
  withdrawTimelimit?: number;
  /** 工作流名称 */
  flowName?: string | null;
  /** 业务Id */
  businessId?: string | null;
}

/** 外汇汇率表 */
export interface ForeignExchangeRatesDto {
  /** 本位币 */
  base?: string | null;
  /** 本位币 */
  baseText?: string | null;
  /** 目标币种 */
  target?: string | null;
  /** 目标币种 */
  targetText?: string | null;
  /**
   * 汇率
   * @format double
   */
  rate?: number;
  /**
   * 汇率小数位精度
   * @format int32
   */
  scale?: number;
  /**
   * 反算汇率
   * @format double
   */
  inverseRate?: number;
  /**
   * 尾差阈值
   * @format double
   */
  tailDifferenceThreshold?: number;
  /** 汇率列表 */
  rates?: string | null;
  /**
   * 更新时间
   * @format date-time
   */
  updateTime?: string | null;
  /** 请求地址 */
  url?: string | null;
  /** 是否默认 */
  isDefault?: string | null;
  isDefaultText?: string | null;
  /**
   * 生效日期
   * @format date-time
   */
  effectiveDate?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface ForeignExchangeRatesDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: ForeignExchangeRatesDto[] | null;
}

/** 通用返回信息类 */
export interface ForeignExchangeRatesDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 外汇汇率表 */
  response?: ForeignExchangeRatesDto;
}

/** 通用分页信息类 */
export interface ForeignExchangeRatesDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: ForeignExchangeRatesDto[] | null;
}

/** 通用返回信息类 */
export interface ForeignExchangeRatesDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: ForeignExchangeRatesDtoPageModel;
}

export interface FormConfig {
  /** 文本框标签对齐方式 */
  textTagAlignmentWay?: string | null;
  /** 必填﹡号显示位置 */
  requiredDisplay?: string | null;
  /** 组件尺寸 */
  componentSize?: string | null;
  /** 组件宽度 */
  componentWidth?: string | null;
  /** 仅显示必填项 */
  isRequiredShow?: boolean;
}

export interface FormFieldAttributesDto {
  /** 显示名 */
  displayName?: string | null;
  /** 是否显示 */
  isDisplay?: boolean;
  /**
   * 字段表单类型
   * @format int32
   */
  formInputType?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20;
  /** 默认数据集 */
  defaultValue?: Record<string, any>;
  /** 前缀附件Id */
  startIconId?: string | null;
  /** 前缀文本 */
  startText?: string | null;
  /** 后缀附件Id */
  endIconId?: string | null;
  /** 后缀文本 */
  endText?: string | null;
  /**
   * 字间距
   * @format double
   */
  spacing?: number;
  /**
   * 行间距
   * @format double
   */
  lineSpacing?: number;
  /**
   * 对齐方式
   * @format int32
   */
  alignment?: 0 | 1 | 2 | 3;
  /**
   * 标签宽度
   * @format int32
   */
  labelWidth?: 0 | 1 | 2;
  /**
   * 字段大小
   * @format int32
   */
  fieldSize?: 0 | 1 | 2;
  /** 提示语 */
  reminder?: string | null;
  /** 提示文字 */
  placeholder?: string | null;
  /** 帮助说明 */
  helpExplanation?: string | null;
  /**
   * 字段宽度
   * @format int32
   */
  fieldWidth?: 0 | 1 | 2 | 3 | 4 | 5;
  /** 是否草稿必填 */
  isDraftRequired?: boolean;
  /** 千分位分隔符 */
  isThousandthSeparator?: boolean;
  /** 百分位 */
  isPercentile?: boolean;
  /** 正常颜色 */
  normalError?: string | null;
  /** 错误颜色 */
  errorColor?: string | null;
  /** 保留小数 */
  isReservedDecimal?: boolean;
  /** 负值标红 */
  isNegativeRed?: boolean;
  /** 数值调节按钮 */
  isNumericalAdjustment?: boolean;
  /** 科学计数法 */
  isScientificNotation?: boolean;
  /**
   * 长度
   * @format int32
   */
  length?: number;
  /**
   * 小数位置
   * @format int32
   */
  decimalLocation?: number;
  /**
   * 零值显示
   * @format int32
   */
  valueNullType?: 0 | 1 | 2;
  /** 是否必填 */
  isRequired?: boolean;
  /** 是否只读 */
  isRead?: boolean;
  /** 是否清除 */
  isClean?: boolean;
  /** 显示分钟 */
  isShowMinute?: boolean;
  /** 显示分钟 */
  isSecond?: boolean;
  /** Json配置集 */
  json?: Record<string, any>;
  /** 是否是模板列 */
  isImportTemplateField?: boolean;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

export interface FormValidateDto {
  /** 字段 */
  field?: string | null;
  /** 值类型 */
  fieldType?: string | null;
  /** 验证规则 */
  formValidateRuleList?: FormValidateRuleDto[] | null;
  formFieldAttributes?: FormFieldAttributesDto;
}

/** 通用返回信息类 */
export interface FormValidateDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  response?: FormValidateDto;
}

export interface FormValidateRuleDto {
  /**
   * 验证规则
   * @format int32
   */
  ruleType?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20;
  /**
   * 弹窗规则
   * @format int32
   */
  alertRule?: 0 | 1 | 2;
  /** 错误信息 */
  errorMessage?: string | null;
  /** 值 */
  value?: string | null;
  /** 是否是Dto字段 */
  isDtoField?: boolean;
  /**
   * 最小长度
   * @format int32
   */
  minLength?: number;
  /**
   * 最大长度
   * @format int32
   */
  maxLength?: number;
  /**
   * 开始值
   * @format int32
   */
  start?: number;
  /**
   * 结束值
   * @format int32
   */
  end?: number;
  /**
   * 验证货币相关
   * @format int32
   */
  number?: number;
  /**
   * 验证货币相关
   * @format int32
   */
  point?: number;
  /** 验证货币相关 */
  ignoreTrailingZeros?: boolean;
  /** 多语言Key */
  languages?: Record<string, string | null>;
}

export interface FormValidateSettingDto {
  /** Dto名称 */
  dtoName?: string | null;
  /** 请求方法 */
  methodName?: string | null;
  /** 请求地址 */
  url?: string | null;
  /** 功能 FromValidate */
  feature?: string | null;
  /** 模块  Sys.UserInfo SysUserInfo */
  module?: string | null;
  /** 描述 */
  description?: string | null;
  /** 字段验证 */
  formValidateList?: FormValidateDto[] | null;
}

/** 通用返回信息类 */
export interface FormValidateSettingDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: FormValidateSettingDto[] | null;
}

/** 通用返回信息类 */
export interface FormValidateSettingDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  response?: FormValidateSettingDto;
}

/** 工时记录表 */
export interface GetAllTimesheetInput {
  /** 关键字 */
  filter?: string | null;
  /** 客户号/客户名称 */
  clientId?: string[] | null;
  /** 案号/案件名称 */
  caseId?: string[] | null;
  /** 日期时间范围均使用 */
  workDate?: RangeDateTimeInput;
  /** 计时人员 */
  employeeId?: string[] | null;
  /** 特殊工时类型 */
  caseCategory?: string[] | null;
  /** 工时状态 */
  status?: string[] | null;
  /** 是否计费 */
  isBillable?: string[] | null;
  /** 是否销账 */
  isWriteOff?: string[] | null;
  /** 销账类型 */
  writeOffType?: string[] | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 导出功能输入的参数 */
export interface GetAllTimesheetInputExportInput {
  selectColumns?: SetColumn[] | null;
  /** 工时记录表 */
  search?: GetAllTimesheetInput;
  fileName?: string | null;
  extended?: string | null;
  /** 选择Id */
  checkedIds?: string[] | null;
  /**
   * 导出类型
   * @format int32
   */
  exportType?: 0 | 1;
}

export interface GetAllocationHistoryInput {
  /** 分配编号 */
  allocationId?: string | null;
  /** 案件Id */
  caseId?: string | null;
  /** 排序 */
  orderBy?: string | null;
}

export interface GetAllocationHistoryOutput {
  /** 版本号 */
  historyVersion?: string | null;
  /**
   * 分配日期
   * @format date-time
   */
  allocationDate?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 历史明细 */
  historyItems?: GetFinancialAllocationItemHistoryOutput[] | null;
}

/** 通用返回信息类 */
export interface GetAllocationHistoryOutputListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: GetAllocationHistoryOutput[] | null;
}

export interface GetBaseDataSelectInput {
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 关键字 */
  filter?: string | null;
  /** 是否查询所务客户 */
  isInternal?: boolean | null;
  /** 日期时间范围均使用 */
  lastUpdate?: RangeDateTimeInput;
  /** id集合 */
  ids?: string[] | null;
  /** 只返回基础Model */
  onlyReturnBaseEntity?: boolean;
}

export interface GetBatchChangeDateInput {
  /** 待调整日期固定服务费编号集合 */
  fixedServiceFeeIds?: string[] | null;
  /** 待调整日期工时编号集合 */
  timesheetIds?: string[] | null;
  /** 待调整日期费用编号集合 */
  disbursementIds?: string[] | null;
  /**
   * 日期
   * @format date-time
   */
  workDate?: string;
}

export interface GetBatchChangeEmployeeDisplayNameInput {
  /** 待调整日期工时编号集合 */
  timesheetIds?: string[] | null;
  /** 待调整日期费用编号集合 */
  disbursementIds?: string[] | null;
  /** 人员编号 */
  employeeId?: string | null;
  /** 人员显示名称 */
  employeeDisplayName?: string | null;
}

export interface GetBatchChangeTaxRateInput {
  /** 待调整税率固定服务费编号集合 */
  fixedServiceFeeIds?: string[] | null;
  /** 待调整税率工时编号集合 */
  timesheetIds?: string[] | null;
  /** 待调整税率费用编号集合 */
  disbursementIds?: string[] | null;
  /**
   * 税率
   * @format double
   */
  taxRate?: number;
}

export interface GetBatchWriteOffInput {
  /** 待销账工时编号集合 */
  timesheetIds?: string[] | null;
  /** 待销账费用编号集合 */
  disbursementIds?: string[] | null;
  /** 是否销账 */
  isWriteOff?: string | null;
  /** 销账类型 */
  writeOffType?: string | null;
  /** 销账理由 */
  writeOffRemark?: string | null;
}

/** 发票信息内容表 */
export interface GetBillingCaseInfoOutput {
  /** 账单Id */
  billingId?: string | null;
  /** 账单编号 */
  billingSerialId?: string | null;
  /** 案件Id */
  caseId?: string | null;
  /** 案件编号 */
  caseSerialId?: string | null;
  /** 案件名称 */
  caseName?: string | null;
  /**
   * 税率
   * @format double
   */
  taxRate?: number;
  /**
   * 税额
   * @format double
   */
  taxAmount?: number;
  /**
   * 不含税金额
   * @format double
   */
  preTaxAmount?: number;
  /**
   * 开票金额
   * @format double
   */
  invoiceAmount?: number;
  /**
   * 案件金额
   * @format double
   */
  caseAmount?: number;
  /**
   * 已开票金额
   * @format double
   */
  invoicedAmount?: number;
  /**
   * 可开票金额
   * @format double
   */
  toBeInvoicedAmount?: number;
}

/** 通用返回信息类 */
export interface GetBillingCaseInfoOutputMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 发票信息内容表 */
  response?: GetBillingCaseInfoOutput;
}

/** 获取 */
export interface GetBillingCaseListInput {
  billingIds?: string[] | null;
}

/** 租户分所银行账号信息 */
export interface GetBranchInfoOutput {
  /** 企业名称 */
  regName?: string | null;
  /** 信用代码 */
  creditCode?: string | null;
  /** 执业许可证号 */
  licenseNumber?: string | null;
  /** 税号 */
  taxVatNo?: string | null;
  /** 发票代码 */
  invoiceCode?: string | null;
  /** 企业注册地址 */
  address?: string | null;
  /** 联系电话 */
  landline?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface GetBranchInfoOutputMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 租户分所银行账号信息 */
  response?: GetBranchInfoOutput;
}

/** 利冲审查结果表 */
export interface GetCSRProcessResultPerferenceInput {
  /** 利冲申请编号 */
  csrId?: string | null;
  /** 利冲审查编号 */
  csrProcessId?: string | null;
  /** 是否需要豁免 */
  isNeedExemption?: string | null;
  /** 是否选中 */
  isChecked?: string | null;
  /** 利冲关键词 */
  csrKeyword?: string | null;
  /** 与我冲突案件的角色 */
  csrParty?: string | null;
  /** 我被冲突案件的角色 */
  caseParty?: string | null;
  /** 我的案件冲突方名称 */
  casePartyName?: string | null;
  /** 冲突等级 */
  csrLevel?: string | null;
  /** 利冲关联业务类型 */
  csrRelationType?: string | null;
  /** 关联业务编号（默认案件） */
  relationId?: string | null;
  /** 冲突产生时关联业务状态 */
  relationStatus?: string | null;
  /** 利冲豁免结果 */
  exemptionResult?: string | null;
  /** 匹配利冲规则 */
  matchingRule?: string | null;
  /** 关联方信息 */
  caseRelatedResultJson?: string | null;
  /** 匹配客户名称 */
  clientName?: string | null;
  /** 匹配客户外文名称 */
  clientForeignName?: string | null;
  /** 匹配客户实控人名称 */
  clientShareholderActualCtrlName?: string | null;
  /** 匹配客户实控人外文名称 */
  clientShareholderActualCtrlForeignName?: string | null;
  /** 客户参与实体名称 */
  clientParticipatingEntityName?: string | null;
  /** 客户参与实体外文名称 */
  clientParticipatingEntityForeignName?: string | null;
  /** 匹配对方名称 */
  oppositeName?: string | null;
  /** 匹配对方外文名称 */
  oppositeForeignName?: string | null;
  /** 匹配对方实控人名称 */
  oppositeShareholderActualCtrlName?: string | null;
  /** 匹配对方实控人外文名称 */
  oppositeShareholderActualCtrlForeignName?: string | null;
  /** 对方关联方名称 */
  oppositeRelatedPartiesName?: string | null;
  /** 对方关联方外文名称 */
  oppositeRelatedPartiesForeignName?: string | null;
  /** 潜在第三方名称 */
  potential3rdPartyName?: string | null;
  /** 潜在第三方外文名称 */
  potential3rdPartyForeignName?: string | null;
  /** 潜在第三方实控人名称 */
  potential3rdPartyShareholderActualCtrlName?: string | null;
  /** 潜在第三方实控人外文名称 */
  potential3rdPartyShareholderActualCtrlForeignName?: string | null;
  /** 关联业务类型 */
  relationCategory?: string | null;
  /** 关联业务名称 */
  relationName?: string | null;
  /** 日期时间范围均使用 */
  relationRequestTime?: RangeDateTimeInput;
  /** 关联业务主管律师 */
  relationInChargeLawyerId?: string | null;
  /** 关联业务申请合伙人 */
  relationRequestUserId?: string | null;
  /** 案源人 */
  relationCaseUser?: string | null;
  /** 关联实体 */
  relationEntity?: string | null;
  /** 日期时间范围均使用 */
  relationEndTime?: RangeDateTimeInput;
  /** 确认律师Id */
  confirmLawyerId?: string | null;
  /** 账单管理人 */
  relationBillingLawyerNameId?: string | null;
  /** 关联客户Id */
  clientId?: string | null;
  /** 标签 */
  tagName?: string | null;
  /** 流水编号 */
  serialId?: string | null;
  /** 原系统主键编号 */
  originalId?: string | null;
  /** 流程数据大状态 */
  status?: string | null;
  /** 日期时间范围均使用 */
  submittedOn?: RangeDateTimeInput;
  /** 工作流状态 */
  flowState?: string | null;
  /** 申请人 */
  applicant?: string | null;
  /** 日期时间范围均使用 */
  flowEndDateTime?: RangeDateTimeInput;
  /** 是否超期 */
  isOverdue?: boolean | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 导出功能输入的参数 */
export interface GetCSRProcessResultPerferenceInputExportInput {
  selectColumns?: SetColumn[] | null;
  /** 利冲审查结果表 */
  search?: GetCSRProcessResultPerferenceInput;
  fileName?: string | null;
  extended?: string | null;
  /** 选择Id */
  checkedIds?: string[] | null;
  /**
   * 导出类型
   * @format int32
   */
  exportType?: 0 | 1;
}

/** 利冲偏好结果查询条件 */
export interface GetCSRProcessResultSearchPerferenceInput {
  preCsrId?: string | null;
  /** 关键字 */
  filter?: string | null;
  /** 案件类型 */
  relationCategory?: string[] | null;
  /** 案件状态 */
  relationStatus?: string[] | null;
  /** 日期时间范围均使用 */
  relationRequestTime?: RangeDateTimeInput;
  /** 日期时间范围均使用 */
  relationEndTime?: RangeDateTimeInput;
  /** 主办合伙人 */
  relationInChargeLawyerId?: string[] | null;
  /** 案源人 */
  relationCaseUser?: string[] | null;
  /** 申请合伙人 */
  relationRequestUserId?: string[] | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 导出功能输入的参数 */
export interface GetCSRProcessResultSearchPerferenceInputExportInput {
  selectColumns?: SetColumn[] | null;
  /** 利冲偏好结果查询条件 */
  search?: GetCSRProcessResultSearchPerferenceInput;
  fileName?: string | null;
  extended?: string | null;
  /** 选择Id */
  checkedIds?: string[] | null;
  /**
   * 导出类型
   * @format int32
   */
  exportType?: 0 | 1;
}

/** 检索条件 */
export interface GetCSRSearchProcessPerferenceInput {
  /** 标签名 */
  tagName?: string | null;
  /** 预检ID */
  preCsrId?: string | null;
  /**
   * 搜索模式
   * @format int32
   */
  searchMode?: 0 | 1 | 2 | 3;
  /** 是否高级搜索 */
  isAdvanceSearch?: boolean;
  /** 是否启用法律意义上检索 */
  isEnabledLegalSenseSearch?: boolean | null;
  /** 是否分词检索 */
  isSplitWordSearch?: boolean;
  /**
   * 检索关键字
   * 非高级检索时使用
   */
  keywords?: string[] | null;
  /** 客户方（高级检索） */
  ourPartyName?: string[] | null;
  /** 对方（高级检索） */
  oppositeName?: string[] | null;
  /** 第三方（高级检索） */
  potential3rdPartyName?: string[] | null;
  /** 应用于 */
  applyCategory?: string | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

export interface GetCandidatesExpenseToBillingInput {
  expenseIds?: string[] | null;
  billingId?: string | null;
}

export interface GetCandidatesToBillingInput {
  timesheetIds?: string[] | null;
  billingId?: string | null;
}

export interface GetCaseCheckInput {
  /** 关键字搜索 */
  filter?: string | null;
  /** 客户编号 */
  clientId?: string[] | null;
  /** 日期时间范围均使用 */
  acceptDate?: RangeDateTimeInput;
  /**
   * 案件搜索类型
   * @format int32
   */
  searchType?: 0 | 1 | 2 | 3;
  /** 是否返回客户信息 */
  isClient?: boolean;
  /** 是否包含结案案件 */
  isIncluedColsed?: boolean;
  /** 是否限定为工时案件 */
  isTimesheetCase?: boolean;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

export interface GetCaseDataSelectInput {
  /** 客户编号 */
  clientId?: string[] | null;
  /** 日期时间范围均使用 */
  acceptDate?: RangeDateTimeInput;
  /** 是否返回客户信息 */
  isClient?: boolean;
  /** 是否包含结案案件 */
  isIncluedColsed?: boolean;
  /** 是否限定为工时案件 */
  isTimesheetCase?: boolean;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 关键字 */
  filter?: string | null;
  /** 是否查询所务客户 */
  isInternal?: boolean | null;
  /** 日期时间范围均使用 */
  lastUpdate?: RangeDateTimeInput;
  /** id集合 */
  ids?: string[] | null;
  /** 只返回基础Model */
  onlyReturnBaseEntity?: boolean;
}

/** 账单支出费用明细表 */
export interface GetCaseExpenseInput {
  /** 账单编号 */
  billingId?: string | null;
  /** 案件编号 */
  caseId?: string | null;
  /** 日期时间范围均使用 */
  expenseDate?: RangeDateTimeInput;
  /** 费用填报人 */
  employeeId?: string[] | null;
  /** 币种 */
  currency?: string[] | null;
  /**
   * 税率
   * @format double
   */
  taxRate?: number | null;
  /** 是否计费 */
  isBillable?: string[] | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

export interface GetCaseLawyerSelectInput {
  /** 案件编号 */
  caseId?: string[] | null;
  /** 律师参案角色 */
  lawyerRole?: string[] | null;
  /** 是否仅账单管理合伙人 */
  isOnlyBillingLawyer?: boolean;
  /** 是否限定为主管合伙人 */
  isOnlyInChargeLawyer?: boolean;
  /** 是否限定为案源合伙人 */
  isOnlyClientMgmtLawyer?: boolean;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 关键字 */
  filter?: string | null;
  /** 是否查询所务客户 */
  isInternal?: boolean | null;
  /** 日期时间范围均使用 */
  lastUpdate?: RangeDateTimeInput;
  /** id集合 */
  ids?: string[] | null;
  /** 只返回基础Model */
  onlyReturnBaseEntity?: boolean;
}

/** 立案列表检索 */
export interface GetCaseMatterFilingInput {
  /** 关键字 */
  filter?: string | null;
  /** 案件编号 */
  serialId?: string | null;
  /** 案件名称 */
  name?: string | null;
  /** 日期时间范围均使用 */
  acceptDate?: RangeDateTimeInput;
  /** 办公室 */
  officeId?: string[] | null;
  /** 客户ID */
  clientId?: string | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 导出功能输入的参数 */
export interface GetCaseMatterFilingInputExportInput {
  selectColumns?: SetColumn[] | null;
  /** 立案列表检索 */
  search?: GetCaseMatterFilingInput;
  fileName?: string | null;
  extended?: string | null;
  /** 选择Id */
  checkedIds?: string[] | null;
  /**
   * 导出类型
   * @format int32
   */
  exportType?: 0 | 1;
}

export interface GetCaseMatterFilingOutput {
  /** 利冲申请编号 */
  csrId?: string | null;
  /** 利冲申请编号 */
  csrSerialId?: string | null;
  /** 案件编号 */
  serialId?: string | null;
  /** 案件名称 */
  name?: string | null;
  /** 客户名称 */
  clientName?: string | null;
  /** 客户名称英文 */
  clientForeignName?: string | null;
  /** 案件类型 */
  category?: string | null;
  /** 案件类型 */
  categoryText?: string | null;
  /** 主办合伙人 */
  inChargeLawyer?: string | null;
  /** 主办合伙人 */
  inChargeLawyerName?: string | null;
  /** 案源人 */
  caseSourceLawyer?: string | null;
  /** 案源人 */
  caseSourceLawyerName?: string | null;
  /** 账单管理人 */
  billingLawyer?: string | null;
  /** 账单管理人 */
  billingLawyerName?: string | null;
  /** 申请合伙人 */
  applicant?: string | null;
  /** 申请合伙人 */
  applicantName?: string | null;
  /**
   * 收案日期
   * @format date-time
   */
  acceptDate?: string;
  /** 办公室 */
  officeName?: string | null;
  /** 关联案件Id */
  relationCaseId?: string | null;
  /** 关联案件名称 */
  relationCaseName?: string | null;
  /** 关联案件编号 */
  relationCaseSerialId?: string | null;
  /**
   * 申请时间
   * @format date-time
   */
  submittedOn?: string | null;
  /** 操作列 */
  flowOperation?: Record<string, any>;
  /**
   * 结案时间
   * @format date-time
   */
  closedTime?: string | null;
  /** 业务状态   列表不处理，只处理导出Excel */
  flowState?: string | null;
  /** 是否克隆 */
  isClone?: boolean;
  /** 原案件编号 */
  fromCaseId?: string | null;
  /** 原利冲编号 */
  fromCsrId?: string | null;
  /** 是否跨所合作案件 */
  isInterbankCooperation?: string | null;
  /** 是否跨所合作案件 */
  isInterbankCooperationText?: string | null;
  /** 是否所务案件 */
  isInternal?: boolean;
  /** 是否已出账单 */
  issuedBilling?: string | null;
  /** 是否已出账单 */
  issuedBillingText?: string | null;
  /** 是否紧急 */
  isUrgent?: string | null;
  /** 是否紧急文本 */
  isUrgentText?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用分页信息类 */
export interface GetCaseMatterFilingOutputPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: GetCaseMatterFilingOutput[] | null;
}

/** 通用返回信息类 */
export interface GetCaseMatterFilingOutputPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: GetCaseMatterFilingOutputPageModel;
}

/** 案件信息表 */
export interface GetCaseMatterInput {
  /** 利冲申请编号 */
  csrId?: string | null;
  /** 案件类型 */
  category?: string[] | null;
  /** 案由分类 */
  reason?: string[] | null;
  /** 补充案由 */
  reasonSupplement?: string | null;
  /** 关联案件编号 */
  relationCaseId?: string | null;
  /** 案件名称 */
  name?: string | null;
  /** 案件名称注释备注 */
  nameNote?: string | null;
  /** 客户行业 */
  clientIndustryId?: string[] | null;
  /** 执业领域 */
  practiceArea?: string[] | null;
  /** 是否涉密 */
  isClassified?: string | null;
  /** 是否紧急 */
  isUrgent?: string | null;
  /** 收费方式（固定，小时，风险，免费代理） */
  chargingMethods?: string | null;
  /** 收费方式文本 */
  chargingMethodText?: string | null;
  /**
   * 合同金额（费用总额）
   * @format double
   */
  contractAmount?: number | null;
  /** 委托事项 */
  entrustedMatters?: string | null;
  /** 书写语言 */
  writtenLanguage?: string | null;
  /**
   * 收案日期
   * @format date-time
   */
  acceptDate?: string | null;
  /** 案件来源 */
  caseSource?: string | null;
  /** 委托阶段 */
  stages?: string | null;
  /** 当前阶段 */
  currentStage?: string | null;
  /** 受理机构 */
  acceptAgency?: string | null;
  /** 受理机构案号 */
  acceptCaseNo?: string | null;
  /** 受理机构级别（法院级别） */
  acceptAgencyLevel?: string | null;
  /** 特殊案件类型 */
  specialType?: string | null;
  /** 是否跨所合作案件 */
  isInterbankCooperation?: string | null;
  /**
   * 合作分所
   * @format int64
   */
  cooperationOffice?: number | null;
  /** 合作律师 */
  cooperativeLawyerIds?: string | null;
  /** 案情简介 */
  narrative?: string | null;
  /** 代理权限 */
  agencyAuthority?: string | null;
  /** 诉讼地位（代理何方） */
  litigationStatus?: string | null;
  /** 是否标的 */
  isTarget?: string | null;
  /**
   * 标的额
   * @format double
   */
  targetAmount?: number | null;
  /** 货币单位 */
  currency?: string | null;
  /**
   * 顾问合同起始时间
   * @format date-time
   */
  startDate?: string | null;
  /**
   * 顾问合同结束时间（空则长期）
   * @format date-time
   */
  endDate?: string | null;
  /**
   * 律师费用
   * @format double
   */
  lawyerFee?: number | null;
  /**
   * 办案费
   * @format double
   */
  caseHandlingFee?: number | null;
  /**
   * 代收代付费
   * @format double
   */
  pocoFee?: number | null;
  /** 案件项下办案费支出方 */
  feePaidParty?: string | null;
  /**
   * 办案费限额
   * @format double
   */
  caseHandlingFeeLimit?: number | null;
  /** 约定汇率模式 */
  agreedExchangeRateMode?: string | null;
  /**
   * 汇率
   * @format double
   */
  exchangeRate?: number | null;
  /**
   * 免费代理小时数
   * @format double
   */
  freeHourLimit?: number | null;
  /** 主管律师（主办律师） */
  inChargeLawyer?: string | null;
  /** 协办律师（合作律师） */
  coWorkingLawyer?: string | null;
  /** 案源律师 */
  caseSourceLawyer?: string | null;
  /** 账单律师 */
  billingLawyer?: string | null;
  /** 秘书助理（预留）;选择人则限定选择人和值班秘书可见，不选就全部助理和秘书可见 */
  assistantSecretary?: string | null;
  /** 申请合伙人 */
  applicant?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 账单工时费明细表 */
export interface GetCaseTimesheetInput {
  /** 账单编号 */
  billingId?: string | null;
  /** 案件编号 */
  caseId?: string | null;
  /** 日期时间范围均使用 */
  workDate?: RangeDateTimeInput;
  /** 工时填报人 */
  employeeId?: string[] | null;
  /** 任务 */
  task?: string | null;
  /** 活动代码 */
  activityCode?: string | null;
  /** 币种 */
  currency?: string[] | null;
  /**
   * 计费小时
   * @format double
   */
  billableHours?: number | null;
  /** 工时类型 */
  category?: string | null;
  /**
   * 税率
   * @format double
   */
  taxRate?: number | null;
  /**
   * 金额
   * @format double
   */
  billableAmount?: number | null;
  /** 是否计费 */
  isBillable?: string[] | null;
  /** 工时描述 */
  narrative?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

export interface GetChangeBillingClientCaseInput {
  /** 账单编号 */
  billingId?: string | null;
  /** 客户编号 */
  clientId?: string | null;
  /** 案件编号 */
  caseId?: string | null;
  /**
   * 是否同步删除该账单创建的计时工时
   * 如果不做删除，则将创建的计时工时变更到新案件
   */
  isDeleteCreatedTimesheet?: boolean;
}

export interface GetChangeBilllableStateInput {
  /** 变更业务主键 */
  ids?: string[] | null;
  /** 是否计费 */
  isBillable?: string | null;
}

export interface GetChangeTaxRateInput {
  /** 账单服务费编号 */
  ids?: string[] | null;
  /**
   * 税率
   * @format double
   */
  taxRate?: number;
}

export interface GetChangeTimekeeperRateInput {
  /** 账单服务费编号集合 */
  ids?: string[] | null;
  /** 人员编号 */
  employeeId?: string | null;
  /** 币种 */
  currency?: string | null;
  /**
   * 汇率
   * @format double
   */
  exchangeRate?: number;
  /**
   * 小时费率
   * @format double
   */
  hourlyRate?: number;
}

/** 客户信息表 */
export interface GetClientForEditOutput {
  /** Id */
  id?: string | null;
  /** 客户名称 */
  name?: string | null;
  /** 外文名称 */
  foreignName?: string | null;
  /** 曾用名 */
  formerName?: string | null;
  /** 别名 */
  altName?: string | null;
  /** 客户类型 */
  clientType?: string | null;
  /** 客户行业 */
  clientIndustryId?: string | null;
  /** 证件类型 */
  creditType?: string | null;
  /** 证件号码 */
  creditCode?: string | null;
  /** 来源类型（立案/入库/投标） */
  originType?: string | null;
  /** 来源编号 */
  originId?: string | null;
  /** 通讯地址 */
  address?: string | null;
  /** 税号 */
  taxNumber?: string | null;
  /** 邮箱地址 */
  emailAddress?: string | null;
  /** 手机号码 */
  phoneNumber?: string | null;
  /** 固定电话 */
  landline?: string | null;
  /** 描述说明 */
  narrative?: string | null;
  /** 客户级别（星标客户/重要客户等） */
  clientLevel?: string | null;
  /** 母公司名称 */
  parentCompanyName?: string | null;
  /** 母公司外文名称 */
  parentCompanyForeignName?: string | null;
  /** 母公司证件类型 */
  parentCompanyCreditType?: string | null;
  /** 母公司证件号码 */
  parentCompanyCreditCode?: string | null;
  /** 客户性质 */
  customerNature?: string | null;
  /** 所属集团 */
  belongGroupId?: string | null;
  /** 所属集团名称 */
  belongGroupName?: string | null;
  /** 流水编号 */
  serialId?: string | null;
  /** 原系统主键编号 */
  originalId?: string | null;
  /** 流程数据大状态 */
  status?: string | null;
  /**
   * 提交时间
   * @format date-time
   */
  submittedOn?: string | null;
  /** 工作流状态 */
  flowState?: string | null;
  /** 申请人 */
  applicant?: string | null;
  /**
   * 工作流结束时间
   * @format date-time
   */
  flowEndDateTime?: string | null;
  /** 是否超期 */
  isOverdue?: boolean | null;
  /** 备注 */
  remark?: string | null;
  /** 股东/实控人都传入到实体表 */
  entityInfos?: CreateEntityInfoInput[] | null;
  /** 发票抬头 */
  entityInvoiceTitleInputs?: CreateEntityInvoiceTitleInput[] | null;
  /** 是否所务客户 */
  isInternal?: boolean;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 表格数据，支持分页 */
export interface GetClientForEditOutputForEditModel {
  /** 客户信息表 */
  data?: GetClientForEditOutput;
  /** 实体映射 */
  mapping?: Record<string, any>;
}

/** 通用返回信息类 */
export interface GetClientForEditOutputForEditModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 表格数据，支持分页 */
  response?: GetClientForEditOutputForEditModel;
}

/** 客户信息表 */
export interface GetClientInput {
  /** 关键字 */
  filter?: string | null;
  /** 客户名称 */
  name?: string | null;
  /** 客户类型 */
  clientType?: string[] | null;
  /** 证件类型 */
  creditType?: string[] | null;
  /** 证件号码 */
  creditCode?: string | null;
  /** 客户性质 */
  customerNature?: string[] | null;
  /** 客户行业 */
  clientIndustryId?: string[] | null;
  /** 案件编号 */
  caseId?: string[] | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 导出功能输入的参数 */
export interface GetClientInputExportInput {
  selectColumns?: SetColumn[] | null;
  /** 客户信息表 */
  search?: GetClientInput;
  fileName?: string | null;
  extended?: string | null;
  /** 选择Id */
  checkedIds?: string[] | null;
  /**
   * 导出类型
   * @format int32
   */
  exportType?: 0 | 1;
}

export interface GetClientPaymentDataSelectInput {
  /** 客户编号 */
  clientId?: string[] | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 关键字 */
  filter?: string | null;
  /** 是否查询所务客户 */
  isInternal?: boolean | null;
  /** 日期时间范围均使用 */
  lastUpdate?: RangeDateTimeInput;
  /** id集合 */
  ids?: string[] | null;
  /** 只返回基础Model */
  onlyReturnBaseEntity?: boolean;
}

/** 跨境账单入参 */
export interface GetCrossBorderBillingInput {
  /** 关键字 */
  filter?: string | null;
  /** 账单编号 */
  serialId?: string | null;
  /** 案件编号 */
  caseId?: string[] | null;
  /** 客户编号 */
  clientId?: string[] | null;
  /** 日期时间范围均使用 */
  billingDate?: RangeDateTimeInput;
  /** 日期时间范围均使用 */
  arrivedDate?: RangeDateTimeInput;
  /** 日期时间范围均使用 */
  startDate?: RangeDateTimeInput;
  /** 日期时间范围均使用 */
  endDate?: RangeDateTimeInput;
  /** 付款方名称 */
  paymentName?: string | null;
  /** 账单合伙人 */
  billingPartnerId?: string[] | null;
  /** 账单申请律师 */
  billingLawyerId?: string[] | null;
  /** 是否已关联外部账单 （true:过滤已关联账单的内部账单） */
  parentBillingId?: string | null;
  /** 账单状态 */
  status?: string[] | null;
  /** 日期时间范围均使用 */
  submittedOn?: RangeDateTimeInput;
  /** 申请人 */
  applicant?: string[] | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 利冲预检结果表 */
export interface GetCsrPreProcessResultInput {
  preCsrId?: string | null;
  /** 关键字 */
  filter?: string | null;
  /** 案件类型 */
  relationCategory?: string[] | null;
  /** 案件状态 */
  flowState?: NameValue[] | null;
  /** 日期时间范围均使用 */
  relationRequestTime?: RangeDateTimeInput;
  /** 日期时间范围均使用 */
  relationEndTime?: RangeDateTimeInput;
  /** 主办合伙人 */
  relationInChargeLawyerId?: string[] | null;
  /** 案源人 */
  relationCaseUser?: string[] | null;
  /** 申请合伙人 */
  relationRequestUserId?: string[] | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 导出功能输入的参数 */
export interface GetCsrPreProcessResultInputExportInput {
  selectColumns?: SetColumn[] | null;
  /** 利冲预检结果表 */
  search?: GetCsrPreProcessResultInput;
  fileName?: string | null;
  extended?: string | null;
  /** 选择Id */
  checkedIds?: string[] | null;
  /**
   * 导出类型
   * @format int32
   */
  exportType?: 0 | 1;
}

export interface GetCsrProtectEntityInfoInput {
  /** 关键字 */
  filter?: string | null;
  /** 预检关键字 */
  keywords?: string[] | null;
  /** 客户名称 */
  name?: string | null;
  /** 客户编号 */
  serialId?: string | null;
  /** 客户行业 */
  clientIndustryList?: string[] | null;
  /** 客户类型 */
  clientTypeList?: string[] | null;
  /** 客户类型 */
  creditType?: string[] | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 利冲审查搜索项 */
export interface GetCsrRequestCheckInput {
  /** 关键字 */
  filter?: string | null;
  /** 客户名称 */
  clientName?: string | null;
  /** 对方 */
  oppositeName?: string | null;
  /** 第三方 */
  potential3rdPartyName?: string | null;
  /** 利冲号 */
  serialId?: string | null;
  /** 案件类型 */
  category?: string[] | null;
  /** 申请合伙人 */
  applicant?: string[] | null;
  /** 是否查看分给我 */
  isAssignUser?: boolean;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 导出功能输入的参数 */
export interface GetCsrRequestCheckInputExportInput {
  selectColumns?: SetColumn[] | null;
  /** 利冲审查搜索项 */
  search?: GetCsrRequestCheckInput;
  fileName?: string | null;
  extended?: string | null;
  /** 选择Id */
  checkedIds?: string[] | null;
  /**
   * 导出类型
   * @format int32
   */
  exportType?: 0 | 1;
}

export interface GetCsrRequestConfirmInput {
  /** 关键字 */
  filter?: string | null;
  /** 客户名称 */
  clientName?: string | null;
  /** 对方 */
  oppositeName?: string | null;
  /** 第三方 */
  potential3rdPartyName?: string | null;
  /** 利冲号 */
  serialId?: string | null;
  /** 案件类型 */
  category?: string[] | null;
  /** 案件名称 */
  caseName?: string | null;
  /** 申请合伙人 */
  applicant?: string[] | null;
  /** 审批合伙人 */
  partnerId?: string[] | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 导出功能输入的参数 */
export interface GetCsrRequestConfirmInputExportInput {
  selectColumns?: SetColumn[] | null;
  search?: GetCsrRequestConfirmInput;
  fileName?: string | null;
  extended?: string | null;
  /** 选择Id */
  checkedIds?: string[] | null;
  /**
   * 导出类型
   * @format int32
   */
  exportType?: 0 | 1;
}

/** 利益冲突信息表 */
export interface GetCsrRequestInput {
  /** 关键字 */
  filter?: string | null;
  /** 利冲案件名称 */
  name?: string | null;
  /** 状态 */
  status?: string[] | null;
  /** 客户方 */
  clientPartyName?: string | null;
  /** 对方 */
  oppositePartyName?: string | null;
  /** 第三方 */
  threePartyName?: string | null;
  /** 利冲号 */
  serialId?: string | null;
  /** 利冲案件类型 */
  category?: string[] | null;
  /** 客户行业 */
  clientIndustryId?: string[] | null;
  /** 执业领域 */
  practiceArea?: string[] | null;
  /** 是否涉密 */
  isClassified?: string[] | null;
  /** 是否紧急 */
  isUrgent?: string[] | null;
  /** 收费方式 */
  chargingMethods?: string[] | null;
  /** 利冲时效是否过期 */
  isExpired?: string[] | null;
  /** 日期时间范围均使用 */
  createTime?: RangeDateTimeInput;
  /** 办公室 */
  officeId?: string[] | null;
  /** 主办合伙人 */
  inChargeLawyerId?: string[] | null;
  /** 案源人 */
  caseSourceLawyerId?: string[] | null;
  /** 账单合伙人 */
  billingLawyerId?: string[] | null;
  /** 申请合伙人 */
  applicantId?: string[] | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 导出功能输入的参数 */
export interface GetCsrRequestInputExportInput {
  selectColumns?: SetColumn[] | null;
  /** 利益冲突信息表 */
  search?: GetCsrRequestInput;
  fileName?: string | null;
  extended?: string | null;
  /** 选择Id */
  checkedIds?: string[] | null;
  /**
   * 导出类型
   * @format int32
   */
  exportType?: 0 | 1;
}

export interface GetCsrRequestNoConfirmInput {
  /** 利冲Id */
  csrRequestIds?: string[] | null;
  /** 结果集Id */
  csrProcessIds?: string[] | null;
  /** 需确认合伙人 */
  confirmLawyerId?: string | null;
}

/** 利益冲突列表 */
export interface GetCsrRequestOutput {
  /** 利冲号 */
  serialId?: string | null;
  /** 利冲名称 */
  name?: string | null;
  /** 利冲案件类型 */
  category?: string | null;
  /** 利冲案件类型文本 */
  categoryText?: string | null;
  /** 关联案件Id */
  relationCaseId?: string | null;
  /** 关联案件名称 */
  relationCaseName?: string | null;
  /** 关联案件编号 */
  relationCaseSerialId?: string | null;
  /** 客户行业 */
  clientIndustryId?: string | null;
  /** 客户行业文本 */
  clientIndustryText?: string | null;
  /** 执业领域 */
  practiceArea?: string | null;
  /** 执业领域文本 */
  practiceAreaText?: string | null;
  /** 是否涉密 */
  isClassified?: string | null;
  /** 是否涉密文本 */
  isClassifiedText?: string | null;
  /** 是否紧急 */
  isUrgent?: string | null;
  /** 是否紧急文本 */
  isUrgentText?: string | null;
  /** 收费方式 */
  chargingMethods?: string | null;
  /** 收费方式文本 */
  chargingMethodsText?: string | null;
  /**
   * 合同金额
   * @format double
   */
  contractAmount?: number | null;
  /** 委托事项 */
  entrustedMatters?: string | null;
  /** 客户名称 */
  clientName?: string | null;
  /** 客户外文名称 */
  clientForeignName?: string | null;
  /** 客户股东实控人名称 */
  clientShareholderActualCtrlName?: string | null;
  /** 客户股东实控人外文名称 */
  clientShareholderActualCtrlForeignName?: string | null;
  /** 客户参与实体名称 */
  clientParticipatingEntityName?: string | null;
  /** 客户参与实体外文名称 */
  clientParticipatingEntityForeignName?: string | null;
  /** 对方当事人 */
  oppositeName?: string | null;
  /** 对方当事人外文名称 */
  oppositeForeignName?: string | null;
  /** 对方股东实控人名称 */
  oppositeShareholderActualCtrlName?: string | null;
  /** 对方股东实控人外文名称 */
  oppositeShareholderActualCtrlForeignName?: string | null;
  /** 对方参与实体名称 */
  oppositeParticipatingEntityName?: string | null;
  /** 对方参与实体外文名称 */
  oppositeParticipatingEntityForeignName?: string | null;
  /** 潜在第三方名称 */
  potential3rdPartyName?: string | null;
  /** 潜在第三方外文名称 */
  potential3rdPartyForeignName?: string | null;
  /** 潜在第三方股东实控人 */
  potential3rdPartyShareholderActualCtrlName?: string | null;
  /** 潜在第三方股东实控人外文名称 */
  potential3rdPartyShareholderActualCtrlForeignName?: string | null;
  /** 申请合伙人 */
  createBy?: string | null;
  /** 申请合伙人名称 */
  createByName?: string | null;
  /** 说明描述 */
  narrative?: string | null;
  /** 利冲转立案Id */
  caseId?: string | null;
  /** 利冲转立案编号 */
  caseSerialId?: string | null;
  /** 关联案件名称 */
  caseName?: string | null;
  /** 利冲关联案件类型 */
  caseCategory?: string | null;
  /** 利冲关联案件类型文本 */
  caseCategoryText?: string | null;
  /** 利冲关联案件状态 */
  caseStatus?: string | null;
  /** 利冲适用规则 */
  csrRule?: string | null;
  /** 利冲适应规则文本 */
  csrRuleText?: string | null;
  /** 利冲时效是否过期 */
  isExpired?: string | null;
  /** 利冲时效是否过期文本 */
  isExpiredText?: string | null;
  /**
   * 利冲审查进度
   * @format double
   */
  rateOfProgress?: number;
  /**
   * 利冲时效剩余天数
   * @format float
   */
  remainingDays?: number;
  /** 利冲时效剩余天数 */
  remainingDaysText?: string | null;
  /** 主办合伙人 */
  inChargeLawyer?: string | null;
  /** 协办合伙人 */
  coWorkingLawyer?: string | null;
  /** 案源人 */
  caseSourceLawyer?: string | null;
  /** 账单管理人 */
  billingLawyer?: string | null;
  /** 主办合伙人名称 */
  inChargeLawyerName?: string | null;
  /** 协办合伙人名称 */
  coWorkingLawyerName?: string | null;
  /** 案源人名称 */
  caseSourceLawyerName?: string | null;
  /** 账单管理人名称 */
  billingLawyerName?: string | null;
  /** 申请合伙人名称 */
  applicantName?: string | null;
  /** 申请合伙人名称 */
  applicant?: string | null;
  /** 豁免信息 */
  csrExemptions?: CSRExemptionOutput[] | null;
  /**
   * 申请时间
   * @format date-time
   */
  submittedOn?: string | null;
  /** 业务状态   列表不处理，只处理导出 */
  flowState?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用分页信息类 */
export interface GetCsrRequestOutputPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: GetCsrRequestOutput[] | null;
}

/** 通用返回信息类 */
export interface GetCsrRequestOutputPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: GetCsrRequestOutputPageModel;
}

/** 预检-检索条件 */
export interface GetCsrSearchPreProcessInput {
  /** 预检ID */
  preCsrId?: string | null;
  /**
   * 搜索模式
   * @format int32
   */
  searchMode?: 0 | 1 | 2 | 3;
  /** 是否高级搜索 */
  isAdvanceSearch?: boolean;
  /** 是否启用法律意义上检索 */
  isEnabledLegalSenseSearch?: boolean | null;
  /** 是否分词检索 */
  isSplitWordSearch?: boolean;
  /**
   * 检索关键字
   * 非高级检索时使用
   */
  keywords?: string[] | null;
  /** 客户方（高级检索） */
  clientName?: string[] | null;
  /** 对方（高级检索） */
  oppositeName?: string[] | null;
  /** 第三方（高级检索） */
  potential3rdPartyName?: string[] | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

export interface GetCsrSearchProcessInput {
  /** 是否开启中文分词检索 */
  isSplitWordSearch?: boolean;
  /** 是否开启外文分词检索 */
  isForeignSplitWordSearch?: boolean;
  /** 利冲编号 */
  csrId?: string | null;
  /** 利冲关系列表 */
  casePartyList?: SearchProcessCasePartyResultDto[] | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

export interface GetDetailReportByTimekeeperAndWorkDateOutput {
  /** @format date-time */
  workDate?: string;
  employeeId?: string | null;
  employeeName?: string | null;
  employeeSerialId?: string | null;
  clientSerialId?: string | null;
  clientName?: string | null;
  caseSerialId?: string | null;
  caseName?: string | null;
  activityCode?: string | null;
  isBillable?: string | null;
  status?: string | null;
  /** @format double */
  hoursWorked?: number;
  narrative?: string | null;
}

/** 人员费率列表请求入参 */
export interface GetEmployeeRateInput {
  /** 关键字 */
  filter?: string | null;
  /** 人员名称 */
  name?: string | null;
  /** 显示名称 */
  displayName?: string | null;
  /** 办公室ID */
  officeId?: string | null;
  /**
   * 用户类型
   * @format int32
   */
  userType?: 0 | 1 | 2 | null;
  /**
   * 人员状态
   * @format int32
   */
  userStatus?: 0 | 1 | 2 | null;
  /** 部门抬头 */
  department?: string | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 导出功能输入的参数 */
export interface GetEmployeeRateInputExportInput {
  selectColumns?: SetColumn[] | null;
  /** 人员费率列表请求入参 */
  search?: GetEmployeeRateInput;
  fileName?: string | null;
  extended?: string | null;
  /** 选择Id */
  checkedIds?: string[] | null;
  /**
   * 导出类型
   * @format int32
   */
  exportType?: 0 | 1;
}

export interface GetEmployeeRateOutput {
  /** 人员主键ID */
  id?: string | null;
  /** 头像 */
  avatar?: string | null;
  /** 人员编号 */
  serialId?: string | null;
  /** 人员名称 */
  name?: string | null;
  /** 工时计费人员ID */
  timekeeperId?: string | null;
  /** 显示名称 */
  displayName?: string | null;
  /** 邮箱地址 */
  emailAddress?: string | null;
  /** 手机号码 */
  phoneNumber?: string | null;
  /** 账单显示名称 */
  initial?: string | null;
  /**
   * 人民币费率
   * @format double
   */
  cnyRate?: number;
  /**
   * 美元费率
   * @format double
   */
  usdRate?: number;
  /** 人员费率信息 */
  employeeRateList?: EmployeeRatesDto[] | null;
  /** 办公室ID */
  officeId?: string | null;
  /** 办公室 */
  officeName?: string | null;
  /**
   * 用户类型
   * @format int32
   */
  userType?: 0 | 1 | 2;
  /** 用户类型文本 */
  userTypeText?: string | null;
  /**
   * 人员状态
   * @format int32
   */
  userStatus?: 0 | 1 | 2;
  /** 人员状态文本 */
  userStatusText?: string | null;
  /** 部门抬头 */
  department?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
}

/** 通用分页信息类 */
export interface GetEmployeeRateOutputPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: GetEmployeeRateOutput[] | null;
}

/** 通用返回信息类 */
export interface GetEmployeeRateOutputPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: GetEmployeeRateOutputPageModel;
}

export interface GetEnterpriseInfoInput {
  /** 企业名称 */
  name?: string | null;
  /** 统一社会信用代码 */
  creditCode?: string | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

export interface GetEntityBaseInfoOutput {
  /** 主键编号 */
  id?: string | null;
  /** 名称 */
  name?: string | null;
  /** 外文名称 */
  foreignName?: string | null;
  /** 曾用名 */
  formerName?: string | null;
  /** 别名 */
  altName?: string | null;
  /** 证件类型 */
  creditType?: string | null;
  /** 证件号码 */
  creditCode?: string | null;
  /** 地址 */
  address?: string | null;
  /** 税号 */
  taxNumber?: string | null;
  /** 邮箱地址 */
  emailAddress?: string | null;
  /** 手机号 */
  phoneNumber?: string | null;
  /** 固定电话 */
  landline?: string | null;
  /** 客户性质 */
  customerNature?: string | null;
  /** 备注 */
  remark?: string | null;
}

/** 通用返回信息类 */
export interface GetEntityBaseInfoOutputMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  response?: GetEntityBaseInfoOutput;
}

/** 实体联系人表 */
export interface GetEntityContactInput {
  /** 关键字 */
  filter?: string | null;
  /** 客户编号 */
  clientId?: string | null;
  /** 联系人名称 */
  name?: string | null;
  /** 别名 */
  altName?: string | null;
  /** 外文名称 */
  foreignName?: string | null;
  /** 速搜码，简码 */
  shortCode?: string | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 实体主体信息表 */
export interface GetEntityInfoForEditOutput {
  /** 外键ID */
  foreignId?: string | null;
  /** 实体或自然人名称 */
  name?: string | null;
  /** 外文名称 */
  foreignName?: string | null;
  /** 曾用户 */
  formerName?: string | null;
  /** 别名 */
  altName?: string | null;
  /** 速搜码 */
  shortCode?: string | null;
  /** 是否自然人 */
  isNaturalPerson?: boolean | null;
  /** 是否涉外实体或个人 */
  isForeign?: boolean | null;
  /** 客户类型（自然人/政府机构/实体） */
  category?: string | null;
  /** 实体类别（客户/客户实控人/股东等） */
  entityType?: string | null;
  /** 实体子类 */
  econKind?: string | null;
  /** 注册号 */
  registerationNumber?: string | null;
  /** 注册名称 */
  registerationName?: string | null;
  /** 注册地址 */
  registerationAddress?: string | null;
  /** 所属行业编号 */
  clientIndustryId?: string | null;
  /** 证件类型 */
  creditType?: string | null;
  /** 证件号码（身份证/信用代码/护照） */
  creditCode?: string | null;
  /** 地址 */
  address?: string | null;
  /** 性别（自然人独占） */
  gender?: string | null;
  /** 民族（自然人独占） */
  nation?: string | null;
  /** 邮箱地址 */
  emailAddress?: string | null;
  /** 固定电话 */
  landline?: string | null;
  /** 移动电话 */
  phoneNumber?: string | null;
  /** 传真 */
  fax?: string | null;
  /** 邮政编码 */
  zipCode?: string | null;
  /** 网站地址 */
  webPortal?: string | null;
  /**
   * 出生日期（自然人独占）
   * @format date-time
   */
  birthday?: string | null;
  /** 法定代表人 */
  legalPerson?: string | null;
  /** 法定代表人ID */
  legalPersonId?: string | null;
  /** 经营场所 */
  businessAddress?: string | null;
  /** 税号 */
  taxNumber?: string | null;
  /** 组织机构编号 */
  orgNumber?: string | null;
  /** 经营范围 */
  businessScope?: string | null;
  /** 注册资本 */
  registeredCapital?: string | null;
  /** 实缴资本 */
  paidInCapital?: string | null;
  /**
   * 成立日期
   * @format date-time
   */
  establishmentDate?: string | null;
  /** 营业期限 */
  businessTerm?: string | null;
  /**
   * 营业执照发证日期
   * @format date-time
   */
  licenseIssueDate?: string | null;
  /** 发证机关（登记机关） */
  issuingAuthority?: string | null;
  /** 经营状态（登记状态） */
  operationalStatus?: string | null;
  /** 纳税人资质 */
  credential?: string | null;
  /** 纳税人资质ID */
  credentialId?: string | null;
  /**
   * 核准日期
   * @format date-time
   */
  issueDate?: string | null;
  /** 所属国家 */
  country?: string | null;
  /** 所属省或州郡 */
  province?: string | null;
  /** 所属地区 */
  region?: string | null;
  /** 进出口企业代码 */
  importExportCode?: string | null;
  /** 人员规模 */
  staffSize?: string | null;
  /** 参保人数 */
  numberOfInsured?: string | null;
  /** 客户来源 */
  customerSource?: string | null;
  /** 源实体编号 */
  originEntityId?: string | null;
  /** 源实体名称 */
  originEntityName?: string | null;
  /** 业务来源（立案/投标/入库等） */
  businessSource?: string | null;
  /** 是否上市企业 */
  isListedEnterprise?: string | null;
  /** 上市地区 */
  listedRegion?: string | null;
  /** 上市机构 */
  listedOrg?: string | null;
  /** 上市类别 */
  listedType?: string | null;
  /** 上市股票代码 */
  listedStockNumber?: string | null;
  /** 是否名录企业 */
  isDirectoryEnterprise?: string | null;
  /** 是否500强企业 */
  isTop500?: string | null;
  /** 是否小微企业 */
  isMicroEnterprise?: string | null;
  /** 标签 */
  tags?: string | null;
  /** 是否披露 */
  isDisclose?: string | null;
  /** 客户级别 */
  clientLevel?: string | null;
  /** 记录来源 */
  loadSource?: string | null;
  /** 记录来源编号 */
  loadSourceId?: string | null;
  /** logo链接 */
  logoUrl?: string | null;
  /** Logo图片 */
  logoPicture?: string | null;
  /** 头像 */
  avatar?: string | null;
  /** 客户画像描述 */
  portraitNarrative?: string | null;
  /** 是否吊销 */
  isRevoke?: boolean | null;
  /**
   * 吊销日期
   * @format date-time
   */
  revokeDate?: string | null;
  /** 吊销原因 */
  revokeRemark?: string | null;
  /** 母公司名称 */
  parentCompanyName?: string | null;
  /** 母公司外文名称 */
  parentCompanyForeignName?: string | null;
  /** 母公司证件类型 */
  parentCompanyCreditType?: string | null;
  /** 母公司证件号码 */
  parentCompanyCreditCode?: string | null;
  /** 联系人 */
  contactName?: string | null;
  /** 客户性质 */
  customerNature?: string | null;
  /** 所属集团 */
  belongGroupId?: string | null;
  /** 流水编号 */
  serialId?: string | null;
  /** 原系统主键编号 */
  originalId?: string | null;
  /** 流程数据大状态 */
  status?: string | null;
  /**
   * 提交时间
   * @format date-time
   */
  submittedOn?: string | null;
  /** 工作流状态 */
  flowState?: string | null;
  /** 申请人 */
  applicant?: string | null;
  /**
   * 工作流结束时间
   * @format date-time
   */
  flowEndDateTime?: string | null;
  /** 是否超期 */
  isOverdue?: boolean | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 表格数据，支持分页 */
export interface GetEntityInfoForEditOutputForEditModel {
  /** 实体主体信息表 */
  data?: GetEntityInfoForEditOutput;
  /** 实体映射 */
  mapping?: Record<string, any>;
}

/** 通用返回信息类 */
export interface GetEntityInfoForEditOutputForEditModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 表格数据，支持分页 */
  response?: GetEntityInfoForEditOutputForEditModel;
}

/** 实体主体信息表 */
export interface GetEntityInfoInput {
  /** 名字/证件编码  where (a.id =1 or b.id) and (c.id=2 or d.id=2) */
  filter?: string | null;
  /** 实体名称 */
  name?: string | null;
  category?: string[] | null;
  /** 证件号码（身份证/信用代码/护照） */
  creditCode?: string | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 实体主体信息表 */
export interface GetEntityInfoOutput {
  /** 外键ID */
  foreignId?: string | null;
  /** 实体或自然人名称 */
  name?: string | null;
  /** 外文名称 */
  foreignName?: string | null;
  /** 曾用户 */
  formerName?: string | null;
  /** 别名 */
  altName?: string | null;
  /** 速搜码 */
  shortCode?: string | null;
  /** 是否自然人 */
  isNaturalPerson?: boolean | null;
  /** 是否涉外实体或个人 */
  isForeign?: boolean | null;
  category?: string | null;
  /** 客户类型 */
  categoryText?: string | null;
  /** 实体类别（客户/客户实控人/股东等） */
  entityType?: string | null;
  /** 实体子类 */
  econKind?: string | null;
  /** 注册号 */
  registerationNumber?: string | null;
  /** 注册名称 */
  registerationName?: string | null;
  /** 注册地址 */
  registerationAddress?: string | null;
  /** 所属行业编号 */
  clientIndustryId?: string | null;
  /** 证件类型 */
  creditType?: string | null;
  /** 证件类型 */
  creditTypeText?: string | null;
  /** 证件号码（身份证/信用代码/护照） */
  creditCode?: string | null;
  /** 地址 */
  address?: string | null;
  /** 性别（自然人独占） */
  gender?: string | null;
  /** 民族（自然人独占） */
  nation?: string | null;
  /** 邮箱地址 */
  emailAddress?: string | null;
  /** 固定电话 */
  landline?: string | null;
  /** 移动电话 */
  phoneNumber?: string | null;
  /** 传真 */
  fax?: string | null;
  /** 邮政编码 */
  zipCode?: string | null;
  /** 网站地址 */
  webPortal?: string | null;
  /**
   * 出生日期（自然人独占）
   * @format date-time
   */
  birthday?: string | null;
  /** 法定代表人 */
  legalPerson?: string | null;
  /** 法定代表人ID */
  legalPersonId?: string | null;
  /** 经营场所 */
  businessAddress?: string | null;
  /** 税号 */
  taxNumber?: string | null;
  /** 组织机构编号 */
  orgNumber?: string | null;
  /** 经营范围 */
  businessScope?: string | null;
  /** 注册资本 */
  registeredCapital?: string | null;
  /** 实缴资本 */
  paidInCapital?: string | null;
  /**
   * 成立日期
   * @format date-time
   */
  establishmentDate?: string | null;
  /** 营业期限 */
  businessTerm?: string | null;
  /**
   * 营业执照发证日期
   * @format date-time
   */
  licenseIssueDate?: string | null;
  /** 发证机关（登记机关） */
  issuingAuthority?: string | null;
  /** 经营状态（登记状态） */
  operationalStatus?: string | null;
  /** 纳税人资质 */
  credential?: string | null;
  /** 纳税人资质ID */
  credentialId?: string | null;
  /**
   * 核准日期
   * @format date-time
   */
  issueDate?: string | null;
  /** 所属国家 */
  country?: string | null;
  /** 所属省或州郡 */
  province?: string | null;
  /** 所属地区 */
  region?: string | null;
  /** 进出口企业代码 */
  importExportCode?: string | null;
  /** 人员规模 */
  staffSize?: string | null;
  /** 参保人数 */
  numberOfInsured?: string | null;
  /** 客户来源 */
  customerSource?: string | null;
  /** 源实体编号 */
  originEntityId?: string | null;
  /** 源实体名称 */
  originEntityName?: string | null;
  /**
   * 业务来源
   * （立案/投标/入库等）
   */
  businessSource?: string | null;
  /** 是否上市企业 */
  isListedEnterprise?: string | null;
  /** 上市地区 */
  listedRegion?: string | null;
  /** 上市机构 */
  listedOrg?: string | null;
  /** 上市类别 */
  listedType?: string | null;
  /** 上市股票代码 */
  listedStockNumber?: string | null;
  /** 是否名录企业 */
  isDirectoryEnterprise?: string | null;
  /** 是否500强企业 */
  isTop500?: string | null;
  /** 是否小微企业 */
  isMicroEnterprise?: string | null;
  /** 标签 */
  tags?: string | null;
  /** 是否披露 */
  isDisclose?: string | null;
  /** 客户级别 */
  clientLevel?: string | null;
  /** 记录来源 */
  loadSource?: string | null;
  /** 记录来源编号 */
  loadSourceId?: string | null;
  /** logo链接 */
  logoUrl?: string | null;
  /** Logo图片 */
  logoPicture?: string | null;
  /** 头像 */
  avatar?: string | null;
  /** 客户画像描述 */
  portraitNarrative?: string | null;
  /** 是否吊销 */
  isRevoke?: boolean | null;
  /**
   * 吊销日期
   * @format date-time
   */
  revokeDate?: string | null;
  /** 吊销原因 */
  revokeRemark?: string | null;
  /** 母公司名称 */
  parentCompanyName?: string | null;
  /** 母公司外文名称 */
  parentCompanyForeignName?: string | null;
  /** 母公司证件类型 */
  parentCompanyCreditType?: string | null;
  /** 母公司证件号码 */
  parentCompanyCreditCode?: string | null;
  /** 联系人 */
  contactName?: string | null;
  /** 客户性质 */
  customerNature?: string | null;
  /** 所属集团 */
  belongGroupId?: string | null;
  /** 流水编号 */
  serialId?: string | null;
  /** 原系统主键编号 */
  originalId?: string | null;
  /** 流程数据大状态 */
  status?: string | null;
  /**
   * 提交时间
   * @format date-time
   */
  submittedOn?: string | null;
  /** 工作流状态 */
  flowState?: string | null;
  /** 申请人 */
  applicant?: string | null;
  /**
   * 工作流结束时间
   * @format date-time
   */
  flowEndDateTime?: string | null;
  /** 是否超期 */
  isOverdue?: boolean | null;
  /** 备注 */
  remark?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用分页信息类 */
export interface GetEntityInfoOutputPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: GetEntityInfoOutput[] | null;
}

/** 通用返回信息类 */
export interface GetEntityInfoOutputPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: GetEntityInfoOutputPageModel;
}

/** 根据名称获取实体信息入参 */
export interface GetEntityInfoWithNameInput {
  /** 主键编号 */
  id?: string | null;
  /** 企业名称 */
  name?: string | null;
  /** 数据来源 */
  dataType?: string | null;
}

/** 发票抬头表 */
export interface GetEntityInvoiceTitleForEditOutput {
  /** 外键id */
  foreignId?: string | null;
  /** 类型 */
  entityType?: string | null;
  /** 开户行 */
  bank?: string | null;
  /** 账号 */
  bankAccount?: string | null;
  /** 发票抬头 */
  invoiceTitle?: string | null;
  /** 纳税人识别号 */
  creditCode?: string | null;
  /** 地址 */
  address?: string | null;
  /** 电话 */
  tel?: string | null;
  /** 流水编号 */
  serialId?: string | null;
  /** 原系统主键编号 */
  originalId?: string | null;
  /** 流程数据大状态 */
  status?: string | null;
  /**
   * 提交时间
   * @format date-time
   */
  submittedOn?: string | null;
  /** 工作流状态 */
  flowState?: string | null;
  /** 申请人 */
  applicant?: string | null;
  /**
   * 工作流结束时间
   * @format date-time
   */
  flowEndDateTime?: string | null;
  /** 是否超期 */
  isOverdue?: boolean | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 表格数据，支持分页 */
export interface GetEntityInvoiceTitleForEditOutputForEditModel {
  /** 发票抬头表 */
  data?: GetEntityInvoiceTitleForEditOutput;
  /** 实体映射 */
  mapping?: Record<string, any>;
}

/** 通用返回信息类 */
export interface GetEntityInvoiceTitleForEditOutputForEditModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 表格数据，支持分页 */
  response?: GetEntityInvoiceTitleForEditOutputForEditModel;
}

/** 发票抬头表 */
export interface GetEntityInvoiceTitleInput {
  /** 外键id (客户Id) */
  foreignId?: string | null;
  /** 发票抬头 */
  invoiceTitle?: string | null;
  /** 纳税人识别号 */
  creditCode?: string | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 发票抬头表 */
export interface GetEntityInvoiceTitleOutput {
  /** 外键id */
  foreignId?: string | null;
  /** 类型 */
  entityType?: string | null;
  /** 开户行 */
  bank?: string | null;
  /** 账号 */
  bankAccount?: string | null;
  /** 发票抬头 */
  invoiceTitle?: string | null;
  /** 纳税人识别号 */
  creditCode?: string | null;
  /** 地址 */
  address?: string | null;
  /** 电话 */
  tel?: string | null;
  /** 流水编号 */
  serialId?: string | null;
  /** 原系统主键编号 */
  originalId?: string | null;
  /** 流程数据大状态 */
  status?: string | null;
  /**
   * 提交时间
   * @format date-time
   */
  submittedOn?: string | null;
  /** 工作流状态 */
  flowState?: string | null;
  /** 申请人 */
  applicant?: string | null;
  /**
   * 工作流结束时间
   * @format date-time
   */
  flowEndDateTime?: string | null;
  /** 是否超期 */
  isOverdue?: boolean | null;
  /** 备注 */
  remark?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface GetEntityInvoiceTitleOutputListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: GetEntityInvoiceTitleOutput[] | null;
}

/** 通用分页信息类 */
export interface GetEntityInvoiceTitleOutputPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: GetEntityInvoiceTitleOutput[] | null;
}

/** 通用返回信息类 */
export interface GetEntityInvoiceTitleOutputPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: GetEntityInvoiceTitleOutputPageModel;
}

export interface GetEntityPartnerAndActualControlPersonOutput {
  /** 隶属实体名称 */
  entityName?: string | null;
  /** 实体或自然人名称 */
  name?: string | null;
  /** 外文名称 */
  foreignName?: string | null;
  /** 曾用户 */
  formerName?: string | null;
  /** 别名 */
  altName?: string | null;
  /** 统一社会信用代码 */
  creditCode?: string | null;
  /** 地址 */
  address?: string | null;
  /** 持股比例 */
  stockPercent?: string | null;
  /** 认缴出资额 */
  shouldCapi?: string | null;
  /** 参股日期 */
  stakeDate?: string | null;
  /** 投资人类型 */
  stockType?: string | null;
  /** 实体类型(股东/实控人) */
  entityType?: string | null;
  /** 备注 */
  remark?: string | null;
  /** 证件类型 */
  creditType?: string | null;
  /** 客户类型 */
  category?: string | null;
}

/** 通用返回信息类 */
export interface GetEntityPartnerAndActualControlPersonOutputListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: GetEntityPartnerAndActualControlPersonOutput[] | null;
}

export interface GetFavoritedTimesheetInput {
  /** 关键字 */
  filter?: string | null;
  /** 日期时间范围均使用 */
  workDate?: RangeDateTimeInput;
  /** 计时人员 */
  employeeId?: string[] | null;
  /** 计时状态 */
  timerState?: string[] | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 分配信息表 */
export interface GetFinancialAllocationForEditOutput {
  /** 收款编号 */
  receiptId?: string | null;
  /** 分配方式 */
  allocationType?: string | null;
  /**
   * 分配日期
   * @format date-time
   */
  allocationDate?: string | null;
  /** 是否红冲分配 */
  isRedPunch?: string | null;
  /** 是否红冲分配 */
  isRedPunchText?: string | null;
  /** 分配模式 */
  allocationMode?: string | null;
  /** 分配性质 */
  allocationNature?: string | null;
  /**
   * 分配金额
   * @format double
   */
  amount?: number;
  /**
   * 分配比例
   * @format double
   */
  allocationRate?: number;
  /**
   * 案源费比例
   * @format double
   */
  caseSourceRate?: number;
  /**
   * 案源费分配金额
   * @format double
   */
  caseSourceAmount?: number;
  /**
   * 尾差
   * @format double
   */
  roundingDifference?: number;
  /** 分配策略 */
  allocationStrattegy?: string | null;
  /** 分配人 */
  employeeId?: string | null;
  /** 分配人 */
  employeeIdText?: string | null;
  /** 是否保密 */
  ifConfidential?: string | null;
  /** 是否保密 */
  ifConfidentialText?: string | null;
  /**
   * 律师费金额
   * @format double
   */
  lawyerFee?: number;
  /**
   * 官费金额
   * @format double
   */
  officialFees?: number;
  /**
   * 支出费用
   * @format double
   */
  expenseFee?: number;
  /**
   * 会计账期
   * @format date-time
   */
  accountingPeriod?: string | null;
  currency?: string | null;
  /** 流水编号 */
  serialId?: string | null;
  /** 原系统主键编号 */
  originalId?: string | null;
  /** 流程数据大状态 */
  status?: string | null;
  /**
   * 提交时间
   * @format date-time
   */
  submittedOn?: string | null;
  /** 工作流状态 */
  flowState?: string | null;
  /** 申请人 */
  applicant?: string | null;
  /** 申请人 */
  applicantText?: string | null;
  /**
   * 工作流结束时间
   * @format date-time
   */
  flowEndDateTime?: string | null;
  /** 是否超期 */
  isOverdue?: boolean | null;
  /** 备注 */
  remark?: string | null;
  /** 分配明细 */
  items?: CreateFinancialAllocationItemInput[] | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 表格数据，支持分页 */
export interface GetFinancialAllocationForEditOutputForEditModel {
  /** 分配信息表 */
  data?: GetFinancialAllocationForEditOutput;
  /** 实体映射 */
  mapping?: Record<string, any>;
}

/** 通用返回信息类 */
export interface GetFinancialAllocationForEditOutputForEditModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 表格数据，支持分页 */
  response?: GetFinancialAllocationForEditOutputForEditModel;
}

/** 分配信息表 */
export interface GetFinancialAllocationInput {
  /** 关键字 */
  filter?: string | null;
  /** 账单编号 */
  billingSerialId?: string | null;
  /** 付款方名称 */
  paymentName?: string | null;
  /** 案件编号 */
  caseSerialId?: string | null;
  /** 案件名称 */
  caseName?: string | null;
  /** 日期时间范围均使用 */
  receiptDate?: RangeDateTimeInput;
  /** 日期时间范围均使用 */
  allocationDate?: RangeDateTimeInput;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 导出功能输入的参数 */
export interface GetFinancialAllocationInputExportInput {
  selectColumns?: SetColumn[] | null;
  /** 分配信息表 */
  search?: GetFinancialAllocationInput;
  fileName?: string | null;
  extended?: string | null;
  /** 选择Id */
  checkedIds?: string[] | null;
  /**
   * 导出类型
   * @format int32
   */
  exportType?: 0 | 1;
}

/** 历史分配明细信息表 */
export interface GetFinancialAllocationItemHistoryInput {
  /** 分配编号 */
  allocationId?: string | null;
  /** 收款编号 */
  receiptId?: string | null;
  /** 被分配人 */
  employeeId?: string | null;
  /** 日期时间范围均使用 */
  allocationDate?: RangeDateTimeInput;
  /**
   * 分配金额
   * @format double
   */
  amount?: number | null;
  /**
   * 分配比例
   * @format double
   */
  allocationRate?: number | null;
  /**
   * 尾差
   * @format double
   */
  roundingDifference?: number | null;
  /**
   * 薪酬金额
   * @format double
   */
  remunerationAmount?: number | null;
  /**
   * 薪酬比例
   * @format double
   */
  remunerationRate?: number | null;
  /**
   * 案源费比例
   * @format double
   */
  caseSourceRate?: number | null;
  /**
   * 案源费分配金额
   * @format double
   */
  caseSourceAmount?: number | null;
  /** 是否是案源费 */
  isSourceFee?: string | null;
  /** 参案角色 */
  lawyerRole?: string | null;
  /**
   * 分配基数
   * @format double
   */
  allocationBase?: number | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 导出功能输入的参数 */
export interface GetFinancialAllocationItemHistoryInputExportInput {
  selectColumns?: SetColumn[] | null;
  /** 历史分配明细信息表 */
  search?: GetFinancialAllocationItemHistoryInput;
  fileName?: string | null;
  extended?: string | null;
  /** 选择Id */
  checkedIds?: string[] | null;
  /**
   * 导出类型
   * @format int32
   */
  exportType?: 0 | 1;
}

/** 历史分配明细信息表 */
export interface GetFinancialAllocationItemHistoryOutput {
  /** 分配编号 */
  allocationId?: string | null;
  /** 收款编号 */
  receiptId?: string | null;
  /** 被分配人 */
  employeeId?: string | null;
  /** 被分配人 */
  employeeIdText?: string | null;
  /**
   * 分配日期
   * @format date-time
   */
  allocationDate?: string | null;
  /**
   * 分配金额
   * @format double
   */
  amount?: number;
  /**
   * 分配比例
   * @format double
   */
  allocationRate?: number;
  /**
   * 尾差
   * @format double
   */
  roundingDifference?: number;
  /**
   * 薪酬金额
   * @format double
   */
  remunerationAmount?: number;
  /**
   * 薪酬比例
   * @format double
   */
  remunerationRate?: number;
  /**
   * 案源费比例
   * @format double
   */
  caseSourceRate?: number;
  /**
   * 案源费分配金额
   * @format double
   */
  caseSourceAmount?: number;
  /** 是否是案源费 */
  isSourceFee?: string | null;
  /** 是否是案源费 */
  isSourceFeeText?: string | null;
  /** 参案角色 */
  lawyerRole?: string | null;
  /**
   * 分配基数
   * @format double
   */
  allocationBase?: number;
  /** 备注 */
  remark?: string | null;
  /** 版本号 分配为纬度 */
  historyVersion?: string | null;
  /** 版本号 案件+分配为纬度 */
  caseHistoryVersion?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 分配明细信息表 */
export interface GetFinancialAllocationItemInput {
  /** 关键字 */
  filter?: string | null;
  /** 账单编号 */
  billingSerialId?: string | null;
  /** 付款方名称 */
  paymentName?: string | null;
  /** 案件编号 */
  caseSerialId?: string | null;
  /** 案件名称 */
  caseName?: string | null;
  /** 日期时间范围均使用 */
  receiptDate?: RangeDateTimeInput;
  /** 日期时间范围均使用 */
  allocationDate?: RangeDateTimeInput;
  /** 数值范围选择器 */
  amount?: RangeDecimalInput;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 导出功能输入的参数 */
export interface GetFinancialAllocationItemInputExportInput {
  selectColumns?: SetColumn[] | null;
  /** 分配明细信息表 */
  search?: GetFinancialAllocationItemInput;
  fileName?: string | null;
  extended?: string | null;
  /** 选择Id */
  checkedIds?: string[] | null;
  /**
   * 导出类型
   * @format int32
   */
  exportType?: 0 | 1;
}

/** 分配明细信息表 */
export interface GetFinancialAllocationItemOutput {
  /** 账单编号 */
  billingSerialId?: string | null;
  /** 付款方 */
  payerName?: string | null;
  /** 案件编号 */
  caseSerialId?: string | null;
  /** 案件名称 */
  caseName?: string | null;
  /**
   * 收款日期
   * @format date-time
   */
  receiptDate?: string | null;
  /** 主办合伙人 */
  inChargeLawyer?: string | null;
  /** 主办合伙人 */
  inChargeLawyerText?: string | null;
  /** 案源合伙人 */
  caseSourceLawyer?: string | null;
  /** 案源合伙人 */
  caseSourceLawyerText?: string | null;
  /** 律师分配明细 */
  lawyerAllocationItems?: LawyerAllocationItem[] | null;
  /** 分配编号 */
  allocationId?: string | null;
  /** 收款编号 */
  receiptId?: string | null;
  /** 案件Id */
  caseId?: string | null;
  /** 被分配人 */
  employeeId?: string | null;
  /** 被分配人 */
  employeeIdText?: string | null;
  /** 被分配人 */
  employeeIdPictureUrl?: string | null;
  /**
   * 分配日期
   * @format date-time
   */
  allocationDate?: string | null;
  /**
   * 分配金额
   * @format double
   */
  amount?: number;
  /**
   * 分配比例
   * @format double
   */
  allocationRate?: number;
  /**
   * 尾差
   * @format double
   */
  roundingDifference?: number;
  /**
   * 薪酬金额
   * @format double
   */
  remunerationAmount?: number;
  /**
   * 薪酬比例
   * @format double
   */
  remunerationRate?: number;
  /**
   * 案源费比例
   * @format double
   */
  caseSourceRate?: number;
  /**
   * 案源费分配金额
   * @format double
   */
  caseSourceAmount?: number;
  /** 是否是案源费 */
  isSourceFee?: string | null;
  /** 是否是案源费 */
  isSourceFeeText?: string | null;
  /** 参案角色 */
  lawyerRole?: string | null;
  /**
   * 分配基数
   * @format double
   */
  allocationBase?: number;
  /** 币种 */
  currency?: string | null;
  /** 币种文本 */
  currencyText?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用分页信息类 */
export interface GetFinancialAllocationItemOutputPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: GetFinancialAllocationItemOutput[] | null;
}

/** 通用返回信息类 */
export interface GetFinancialAllocationItemOutputPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: GetFinancialAllocationItemOutputPageModel;
}

/** 分配信息表 */
export interface GetFinancialAllocationOutput {
  /** 账单编号 */
  billingSerialId?: string | null;
  /** 付款方 */
  payerName?: string | null;
  /** 案件编号 */
  caseSerialId?: string | null;
  /** 案件名称 */
  caseName?: string | null;
  /**
   * 收款日期
   * @format date-time
   */
  receiptDate?: string | null;
  /** 主办合伙人 */
  inChargeLawyer?: string | null;
  /** 主办合伙人 */
  inChargeLawyerText?: string | null;
  /** 案源合伙人 */
  caseSourceLawyer?: string | null;
  /** 案源合伙人 */
  caseSourceLawyerText?: string | null;
  /**
   * 收款金额
   * @format double
   */
  receiptAmount?: number;
  /**
   * 已分配金额
   * @format double
   */
  allocatedAmount?: number;
  /** 分配明细信息 */
  allocationItemList?: GetFinancialAllocationItemOutput[] | null;
  /** 收款编号 */
  receiptId?: string | null;
  /** 案件Id */
  caseId?: string | null;
  /** 分配方式 */
  allocationType?: string | null;
  /**
   * 分配日期
   * @format date-time
   */
  allocationDate?: string | null;
  /** 是否红冲分配 */
  isRedPunch?: string | null;
  /** 是否红冲分配 */
  isRedPunchText?: string | null;
  /** 分配模式 */
  allocationMode?: string | null;
  /** 分配性质 */
  allocationNature?: string | null;
  /**
   * 分配金额
   * @format double
   */
  amount?: number;
  /**
   * 分配比例
   * @format double
   */
  allocationRate?: number;
  /**
   * 案源费比例
   * @format double
   */
  caseSourceRate?: number;
  /**
   * 案源费分配金额
   * @format double
   */
  caseSourceAmount?: number;
  /**
   * 尾差
   * @format double
   */
  roundingDifference?: number;
  /** 分配策略 */
  allocationStrattegy?: string | null;
  /** 分配人 */
  employeeId?: string | null;
  /** 是否保密 */
  ifConfidential?: string | null;
  /** 是否保密 */
  ifConfidentialText?: string | null;
  /**
   * 律师费金额
   * @format double
   */
  lawyerFee?: number;
  /**
   * 官费金额
   * @format double
   */
  officialFees?: number;
  /**
   * 支出费用
   * @format double
   */
  expenseFee?: number;
  /**
   * 会计账期
   * @format date-time
   */
  accountingPeriod?: string | null;
  currency?: string | null;
  /** 流水编号 */
  serialId?: string | null;
  /** 原系统主键编号 */
  originalId?: string | null;
  /** 流程数据大状态 */
  status?: string | null;
  /**
   * 提交时间
   * @format date-time
   */
  submittedOn?: string | null;
  /** 工作流状态 */
  flowState?: string | null;
  /** 申请人 */
  applicant?: string | null;
  /**
   * 工作流结束时间
   * @format date-time
   */
  flowEndDateTime?: string | null;
  /** 是否超期 */
  isOverdue?: boolean | null;
  /** 备注 */
  remark?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用分页信息类 */
export interface GetFinancialAllocationOutputPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: GetFinancialAllocationOutput[] | null;
}

/** 通用返回信息类 */
export interface GetFinancialAllocationOutputPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: GetFinancialAllocationOutputPageModel;
}

/** 分配比例案件设置表 */
export interface GetFinancialAllocationRateCaseSettingForEditOutput {
  /** 案件Id */
  caseId?: string | null;
  /** 相关分配策略Id */
  rateSettingId?: string | null;
  /** 相关分配策略Id */
  rateSettingName?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 表格数据，支持分页 */
export interface GetFinancialAllocationRateCaseSettingForEditOutputForEditModel {
  /** 分配比例案件设置表 */
  data?: GetFinancialAllocationRateCaseSettingForEditOutput;
  /** 实体映射 */
  mapping?: Record<string, any>;
}

/** 通用返回信息类 */
export interface GetFinancialAllocationRateCaseSettingForEditOutputForEditModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 表格数据，支持分页 */
  response?: GetFinancialAllocationRateCaseSettingForEditOutputForEditModel;
}

/** 分配比例案件设置表 */
export interface GetFinancialAllocationRateCaseSettingInput {
  /** 案件Id */
  caseId?: string | null;
  /** 相关分配策略Id */
  rateSettingId?: string | null;
  /** 相关分配策略Id */
  rateSettingName?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

export interface GetFinancialAllocationRateCaseSettingItemForEditOutput {
  /** 分配比例设置编号 */
  allocationRateSettingId?: string | null;
  /** 案件编号 */
  caseId?: string | null;
  /** 人员编号 */
  employeeId?: string | null;
  /** 参案角色 */
  lawyerRole?: string | null;
  /**
   * 分配比例
   * @format double
   */
  allocationRate?: number;
  /** 是否案源费 */
  isCaseSourceFee?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 表格数据，支持分页 */
export interface GetFinancialAllocationRateCaseSettingItemForEditOutputForEditModel {
  data?: GetFinancialAllocationRateCaseSettingItemForEditOutput;
  /** 实体映射 */
  mapping?: Record<string, any>;
}

/** 通用返回信息类 */
export interface GetFinancialAllocationRateCaseSettingItemForEditOutputForEditModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 表格数据，支持分页 */
  response?: GetFinancialAllocationRateCaseSettingItemForEditOutputForEditModel;
}

export interface GetFinancialAllocationRateCaseSettingItemInput {
  /** 分配比例设置编号 */
  allocationRateSettingId?: string | null;
  /** 案件编号 */
  caseId?: string | null;
  /** 人员编号 */
  employeeId?: string | null;
  /** 参案角色 */
  lawyerRole?: string | null;
  /**
   * 分配比例
   * @format double
   */
  allocationRate?: number | null;
  /** 是否案源费 */
  isCaseSourceFee?: string[] | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

export interface GetFinancialAllocationRateCaseSettingItemOutput {
  /** 分配比例设置编号 */
  allocationRateSettingId?: string | null;
  /** 案件编号 */
  caseId?: string | null;
  /** 人员编号 */
  employeeId?: string | null;
  /** 人员名称 */
  employeeIdText?: string | null;
  /** 人员头像 */
  employeeIdPicture?: string | null;
  /** 参案角色 */
  lawyerRole?: string | null;
  /** 参案角色 */
  lawyerRoleText?: string | null;
  /**
   * 分配比例
   * @format double
   */
  allocationRate?: number;
  /** 是否案源费 */
  isCaseSourceFee?: string | null;
  /** 是否案源费 */
  isCaseSourceFeeText?: string | null;
  /** 备注 */
  remark?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface GetFinancialAllocationRateCaseSettingItemOutputListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: GetFinancialAllocationRateCaseSettingItemOutput[] | null;
}

/** 通用分页信息类 */
export interface GetFinancialAllocationRateCaseSettingItemOutputPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: GetFinancialAllocationRateCaseSettingItemOutput[] | null;
}

/** 通用返回信息类 */
export interface GetFinancialAllocationRateCaseSettingItemOutputPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: GetFinancialAllocationRateCaseSettingItemOutputPageModel;
}

/** 分配比例案件设置表 */
export interface GetFinancialAllocationRateCaseSettingOutput {
  /** 案件编号 */
  caseSerialId?: string | null;
  /** 案件名称 */
  caseName?: string | null;
  /** 客户名称 */
  caseClientName?: string | null;
  /** 案件类型 */
  caseCategory?: string | null;
  /** 案件类型 */
  caseCategoryText?: string | null;
  /**
   * 收案日期
   * @format date-time
   */
  acceptDate?: string | null;
  /** 分配明细 */
  items?: GetFinancialAllocationRateCaseSettingItemOutput[] | null;
  /** 案件Id */
  caseId?: string | null;
  /** 相关分配策略Id */
  rateSettingId?: string | null;
  /** 相关分配策略Id */
  rateSettingName?: string | null;
  /** 备注 */
  remark?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface GetFinancialAllocationRateCaseSettingOutputListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: GetFinancialAllocationRateCaseSettingOutput[] | null;
}

/** 通用分页信息类 */
export interface GetFinancialAllocationRateCaseSettingOutputPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: GetFinancialAllocationRateCaseSettingOutput[] | null;
}

/** 通用返回信息类 */
export interface GetFinancialAllocationRateCaseSettingOutputPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: GetFinancialAllocationRateCaseSettingOutputPageModel;
}

/** 分配比例设置表 */
export interface GetFinancialAllocationRateSettingForEditOutput {
  /** 策略名称 */
  strattegyName?: string | null;
  /** 策略编号 */
  strattegyId?: string | null;
  /** 是否默认 */
  isDefault?: boolean;
  /** 备注 */
  remark?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 表格数据，支持分页 */
export interface GetFinancialAllocationRateSettingForEditOutputForEditModel {
  /** 分配比例设置表 */
  data?: GetFinancialAllocationRateSettingForEditOutput;
  /** 实体映射 */
  mapping?: Record<string, any>;
}

/** 通用返回信息类 */
export interface GetFinancialAllocationRateSettingForEditOutputForEditModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 表格数据，支持分页 */
  response?: GetFinancialAllocationRateSettingForEditOutputForEditModel;
}

/** 分配比例设置表 */
export interface GetFinancialAllocationRateSettingInput {
  /** 策略名称 */
  strattegyName?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 导出功能输入的参数 */
export interface GetFinancialAllocationRateSettingInputExportInput {
  selectColumns?: SetColumn[] | null;
  /** 分配比例设置表 */
  search?: GetFinancialAllocationRateSettingInput;
  fileName?: string | null;
  extended?: string | null;
  /** 选择Id */
  checkedIds?: string[] | null;
  /**
   * 导出类型
   * @format int32
   */
  exportType?: 0 | 1;
}

/** 分配比例设置明细表 */
export interface GetFinancialAllocationRateSettingItemForEditOutput {
  /** 分配比例设置编号 */
  allocationRateSettingId?: string | null;
  /** 案件编号 */
  caseId?: string | null;
  /** 人员编号 */
  employeeId?: string | null;
  /** 参案角色 */
  lawyerRole?: string | null;
  /**
   * 分配比例
   * @format double
   */
  allocationRate?: number;
  /** 是否案源费 */
  isCaseSourceFee?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 表格数据，支持分页 */
export interface GetFinancialAllocationRateSettingItemForEditOutputForEditModel {
  /** 分配比例设置明细表 */
  data?: GetFinancialAllocationRateSettingItemForEditOutput;
  /** 实体映射 */
  mapping?: Record<string, any>;
}

/** 通用返回信息类 */
export interface GetFinancialAllocationRateSettingItemForEditOutputForEditModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 表格数据，支持分页 */
  response?: GetFinancialAllocationRateSettingItemForEditOutputForEditModel;
}

/** 分配比例设置明细历史表 */
export interface GetFinancialAllocationRateSettingItemHistoryForEditOutput {
  /** 分配比例设置编号 */
  allocationRateSettingId?: string | null;
  /** 案件编号 */
  caseId?: string | null;
  /** 人员编号 */
  employeeId?: string | null;
  /** 参案角色 */
  lawyerRole?: string | null;
  /**
   * 分配比例
   * @format double
   */
  allocationRate?: number;
  /** 是否案源费 */
  isCaseSourceFee?: string | null;
  /** 分配比例历史版本 */
  historyVersion?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 表格数据，支持分页 */
export interface GetFinancialAllocationRateSettingItemHistoryForEditOutputForEditModel {
  /** 分配比例设置明细历史表 */
  data?: GetFinancialAllocationRateSettingItemHistoryForEditOutput;
  /** 实体映射 */
  mapping?: Record<string, any>;
}

/** 通用返回信息类 */
export interface GetFinancialAllocationRateSettingItemHistoryForEditOutputForEditModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 表格数据，支持分页 */
  response?: GetFinancialAllocationRateSettingItemHistoryForEditOutputForEditModel;
}

/** 分配比例设置明细历史表 */
export interface GetFinancialAllocationRateSettingItemHistoryInput {
  /** 分配比例设置编号 */
  allocationRateSettingId?: string | null;
  /** 案件编号 */
  caseId?: string | null;
  /** 人员编号 */
  employeeId?: string | null;
  /** 参案角色 */
  lawyerRole?: string | null;
  /**
   * 分配比例
   * @format double
   */
  allocationRate?: number | null;
  /** 是否案源费 */
  isCaseSourceFee?: string[] | null;
  /** 分配比例历史版本 */
  historyVersion?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 导出功能输入的参数 */
export interface GetFinancialAllocationRateSettingItemHistoryInputExportInput {
  selectColumns?: SetColumn[] | null;
  /** 分配比例设置明细历史表 */
  search?: GetFinancialAllocationRateSettingItemHistoryInput;
  fileName?: string | null;
  extended?: string | null;
  /** 选择Id */
  checkedIds?: string[] | null;
  /**
   * 导出类型
   * @format int32
   */
  exportType?: 0 | 1;
}

/** 分配比例设置明细历史表 */
export interface GetFinancialAllocationRateSettingItemHistoryOutput {
  /** 分配比例设置编号 */
  allocationRateSettingId?: string | null;
  /** 案件编号 */
  caseId?: string | null;
  /** 人员编号 */
  employeeId?: string | null;
  /** 参案角色 */
  lawyerRole?: string | null;
  /**
   * 分配比例
   * @format double
   */
  allocationRate?: number;
  /** 是否案源费 */
  isCaseSourceFee?: string | null;
  /** 是否案源费 */
  isCaseSourceFeeText?: string | null;
  /** 分配比例历史版本 */
  historyVersion?: string | null;
  /** 备注 */
  remark?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface GetFinancialAllocationRateSettingItemHistoryOutputListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: GetFinancialAllocationRateSettingItemHistoryOutput[] | null;
}

/** 通用分页信息类 */
export interface GetFinancialAllocationRateSettingItemHistoryOutputPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: GetFinancialAllocationRateSettingItemHistoryOutput[] | null;
}

/** 通用返回信息类 */
export interface GetFinancialAllocationRateSettingItemHistoryOutputPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: GetFinancialAllocationRateSettingItemHistoryOutputPageModel;
}

/** 分配比例设置明细表 */
export interface GetFinancialAllocationRateSettingItemInput {
  /** 分配比例设置编号 */
  allocationRateSettingId?: string | null;
  /** 案件编号 */
  caseId?: string | null;
  /** 人员编号 */
  employeeId?: string | null;
  /** 参案角色 */
  lawyerRole?: string | null;
  /**
   * 分配比例
   * @format double
   */
  allocationRate?: number | null;
  /** 是否案源费 */
  isCaseSourceFee?: string[] | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 导出功能输入的参数 */
export interface GetFinancialAllocationRateSettingItemInputExportInput {
  selectColumns?: SetColumn[] | null;
  /** 分配比例设置明细表 */
  search?: GetFinancialAllocationRateSettingItemInput;
  fileName?: string | null;
  extended?: string | null;
  /** 选择Id */
  checkedIds?: string[] | null;
  /**
   * 导出类型
   * @format int32
   */
  exportType?: 0 | 1;
}

/** 分配比例设置明细表 */
export interface GetFinancialAllocationRateSettingItemOutput {
  /** 分配比例设置编号 */
  allocationRateSettingId?: string | null;
  /** 案件编号 */
  caseId?: string | null;
  /** 人员编号 */
  employeeId?: string | null;
  /** 人员名称 */
  employeeIdText?: string | null;
  /** 人员头像 */
  employeeIdPicture?: string | null;
  /** 参案角色 */
  lawyerRole?: string | null;
  /** 参案角色 */
  lawyerRoleText?: string | null;
  /**
   * 分配比例
   * @format double
   */
  allocationRate?: number;
  /** 是否案源费 */
  isCaseSourceFee?: string | null;
  /** 是否案源费 */
  isCaseSourceFeeText?: string | null;
  /** 是否启用 */
  isEnable?: boolean;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface GetFinancialAllocationRateSettingItemOutputListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: GetFinancialAllocationRateSettingItemOutput[] | null;
}

/** 通用分页信息类 */
export interface GetFinancialAllocationRateSettingItemOutputPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: GetFinancialAllocationRateSettingItemOutput[] | null;
}

/** 通用返回信息类 */
export interface GetFinancialAllocationRateSettingItemOutputPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: GetFinancialAllocationRateSettingItemOutputPageModel;
}

/** 分配比例设置表 */
export interface GetFinancialAllocationRateSettingOutput {
  /** 分配比例明细 */
  items?: GetFinancialAllocationRateSettingItemOutput[] | null;
  /** 策略名称 */
  strattegyName?: string | null;
  /** 策略编号 */
  strattegyId?: string | null;
  /** 案件Id */
  caseId?: string | null;
  /** 是否默认 */
  isDefault?: boolean;
  /** 备注 */
  remark?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface GetFinancialAllocationRateSettingOutputListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: GetFinancialAllocationRateSettingOutput[] | null;
}

/** 通用分页信息类 */
export interface GetFinancialAllocationRateSettingOutputPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: GetFinancialAllocationRateSettingOutput[] | null;
}

/** 通用返回信息类 */
export interface GetFinancialAllocationRateSettingOutputPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: GetFinancialAllocationRateSettingOutputPageModel;
}

/** 账单调整明细表 */
export interface GetFinancialBillingAdjustmentInput {
  /** 账单编号 */
  billingId?: string | null;
  /** 调整项目 */
  category?: string | null;
  /** 应用范围 */
  scope?: string | null;
  /** 调整类型 */
  adjustmentType?: string | null;
  /**
   * 基数
   * @format double
   */
  base?: number | null;
  /**
   * 调整比例
   * @format double
   */
  adjustmentRate?: number | null;
  /**
   * 调整额
   * @format double
   */
  adjustment?: number | null;
  /**
   * 调整后金额
   * @format double
   */
  amount?: number | null;
  /**
   * 小计金额
   * @format double
   */
  subtotal?: number | null;
  /** 描述 */
  narrative?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 导出功能输入的参数 */
export interface GetFinancialBillingAdjustmentInputExportInput {
  selectColumns?: SetColumn[] | null;
  /** 账单调整明细表 */
  search?: GetFinancialBillingAdjustmentInput;
  fileName?: string | null;
  extended?: string | null;
  /** 选择Id */
  checkedIds?: string[] | null;
  /**
   * 导出类型
   * @format int32
   */
  exportType?: 0 | 1;
}

/** 账单生成文件表 */
export interface GetFinancialBillingAttachmentForEditOutput {
  /** 账单编号 */
  billingId?: string | null;
  /** 账单文件名称 */
  name?: string | null;
  /** 账单文件类型 */
  fileExtension?: string | null;
  templateId?: string | null;
  templateName?: string | null;
  attachmentId?: string | null;
  pdfAttachmentId?: string | null;
  isFinal?: boolean;
  displayAdjustmentDiscountPercentage?: boolean;
  includingTimesheetDetailedInfo?: boolean;
  displayTimesheetDetailedHorizontally?: boolean;
  displayOriginalFee?: boolean;
  includingExpenseDetailedInfo?: boolean;
  displayCoverLetter?: boolean;
  displayBankAccountInfo?: boolean;
  /** 备注 */
  remark?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 表格数据，支持分页 */
export interface GetFinancialBillingAttachmentForEditOutputForEditModel {
  /** 账单生成文件表 */
  data?: GetFinancialBillingAttachmentForEditOutput;
  /** 实体映射 */
  mapping?: Record<string, any>;
}

/** 通用返回信息类 */
export interface GetFinancialBillingAttachmentForEditOutputForEditModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 表格数据，支持分页 */
  response?: GetFinancialBillingAttachmentForEditOutputForEditModel;
}

/** 账单生成文件表 */
export interface GetFinancialBillingAttachmentInput {
  /** 账单编号 */
  billingId?: string | null;
  /** 账单文件名称 */
  name?: string | null;
  /** 账单文件类型 */
  fileExtension?: string | null;
  templateId?: string | null;
  templateName?: string | null;
  attachmentId?: string | null;
  pdfAttachmentId?: string | null;
  isFinal?: boolean | null;
  displayAdjustmentDiscountPercentage?: boolean | null;
  includingTimesheetDetailedInfo?: boolean | null;
  displayTimesheetDetailedHorizontally?: boolean | null;
  displayOriginalFee?: boolean | null;
  includingExpenseDetailedInfo?: boolean | null;
  displayCoverLetter?: boolean | null;
  displayBankAccountInfo?: boolean | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 导出功能输入的参数 */
export interface GetFinancialBillingAttachmentInputExportInput {
  selectColumns?: SetColumn[] | null;
  /** 账单生成文件表 */
  search?: GetFinancialBillingAttachmentInput;
  fileName?: string | null;
  extended?: string | null;
  /** 选择Id */
  checkedIds?: string[] | null;
  /**
   * 导出类型
   * @format int32
   */
  exportType?: 0 | 1;
}

/** 账单生成文件表 */
export interface GetFinancialBillingAttachmentOutput {
  /** 账单编号 */
  billingId?: string | null;
  /** 账单文件名称 */
  name?: string | null;
  /** 账单文件类型 */
  fileExtension?: string | null;
  templateId?: string | null;
  templateName?: string | null;
  attachmentId?: string | null;
  pdfAttachmentId?: string | null;
  isFinal?: boolean;
  displayAdjustmentDiscountPercentage?: boolean;
  includingTimesheetDetailedInfo?: boolean;
  displayTimesheetDetailedHorizontally?: boolean;
  displayOriginalFee?: boolean;
  includingExpenseDetailedInfo?: boolean;
  displayCoverLetter?: boolean;
  displayBankAccountInfo?: boolean;
  /** 备注 */
  remark?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface GetFinancialBillingAttachmentOutputListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: GetFinancialBillingAttachmentOutput[] | null;
}

/** 通用分页信息类 */
export interface GetFinancialBillingAttachmentOutputPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: GetFinancialBillingAttachmentOutput[] | null;
}

/** 通用返回信息类 */
export interface GetFinancialBillingAttachmentOutputPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: GetFinancialBillingAttachmentOutputPageModel;
}

/** 账单待审核信息入参 */
export interface GetFinancialBillingAuditInput {
  /** 关键字 */
  filter?: string | null;
  /** 账单编号 */
  serialId?: string | null;
  /** 案件编号 */
  caseId?: string[] | null;
  /** 客户编号 */
  clientId?: string[] | null;
  /** 日期时间范围均使用 */
  billingDate?: RangeDateTimeInput;
  /** 日期时间范围均使用 */
  arrivedDate?: RangeDateTimeInput;
  /** 日期时间范围均使用 */
  startDate?: RangeDateTimeInput;
  /** 日期时间范围均使用 */
  endDate?: RangeDateTimeInput;
  /** 付款方名称 */
  paymentName?: string | null;
  /** 付款周期 */
  paymentTerms?: string[] | null;
  /** 付款货币类型 */
  paymentCurrency?: string[] | null;
  /** 账单合伙人 */
  billingPartnerId?: string[] | null;
  /** 账单申请律师 */
  billingLawyerId?: string[] | null;
  /** 币种 */
  currency?: string[] | null;
  /** 支付状态 */
  paymentStatus?: string | null;
  /** 账单联系人 */
  contactPerson?: string | null;
  /** 邮箱地址 */
  emailAddress?: string | null;
  /** 账单所属办公室 */
  billingOffice?: string[] | null;
  /** 账单关联银行账号 */
  billingBankId?: string[] | null;
  /** 账单类型 */
  category?: string[] | null;
  /** 是否联合账单 */
  isBatchBilling?: string[] | null;
  /** 是否跨境账单 */
  isCrossBorderBilling?: string[] | null;
  /** 是否内部账单 */
  isInternalBilling?: string[] | null;
  /** 内部描述 */
  internalDescription?: string | null;
  /** 账单描述 */
  narrative?: string | null;
  /** 账单状态 */
  status?: string[] | null;
  /** 日期时间范围均使用 */
  submittedOn?: RangeDateTimeInput;
  /** 申请人 */
  applicant?: string[] | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 导出功能输入的参数 */
export interface GetFinancialBillingAuditInputExportInput {
  selectColumns?: SetColumn[] | null;
  /** 账单待审核信息入参 */
  search?: GetFinancialBillingAuditInput;
  fileName?: string | null;
  extended?: string | null;
  /** 选择Id */
  checkedIds?: string[] | null;
  /**
   * 导出类型
   * @format int32
   */
  exportType?: 0 | 1;
}

/** 账单评论信息 */
export interface GetFinancialBillingCommentsForEditOutput {
  /** 账单编号 */
  billingId?: string | null;
  /** 账单明细编号 */
  itemId?: string | null;
  /** 账单明细类型 */
  itemScope?: string | null;
  /** 账单明细创建人 */
  userId?: string | null;
  /** 可见范围 */
  visibleScope?: string | null;
  /** 账单明细备注 */
  commentText?: string | null;
  /** 是否已解决 */
  isResolved?: string | null;
  /** 解决人 */
  resolvedUserId?: string | null;
  /**
   * 解决时间
   * @format date-time
   */
  resolvedTime?: string | null;
  /** 父级评论编号 */
  parentCommitId?: string | null;
  /** 高亮文本 */
  highlightedText?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 表格数据，支持分页 */
export interface GetFinancialBillingCommentsForEditOutputForEditModel {
  /** 账单评论信息 */
  data?: GetFinancialBillingCommentsForEditOutput;
  /** 实体映射 */
  mapping?: Record<string, any>;
}

/** 通用返回信息类 */
export interface GetFinancialBillingCommentsForEditOutputForEditModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 表格数据，支持分页 */
  response?: GetFinancialBillingCommentsForEditOutputForEditModel;
}

/** 账单评论信息 */
export interface GetFinancialBillingCommentsInput {
  /** 账单编号 */
  billingId?: string | null;
  /** 账单明细编号 */
  itemId?: string[] | null;
  /** 明细调整类型 */
  itemScope?: string[] | null;
  /** 评论人编号 */
  userId?: string[] | null;
  /** 评论可见范围 */
  visibleScope?: string | null;
  /** 评论内容 */
  commentText?: string | null;
  /** 是否已解决 */
  isResolved?: string[] | null;
  /** 解决人编号 */
  resolvedUserId?: string[] | null;
  /** 日期时间范围均使用 */
  resolvedTime?: RangeDateTimeInput;
  /** 父评论编号 */
  parentCommitId?: string | null;
  /** 高亮文本 */
  highlightedText?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 导出功能输入的参数 */
export interface GetFinancialBillingCommentsInputExportInput {
  selectColumns?: SetColumn[] | null;
  /** 账单评论信息 */
  search?: GetFinancialBillingCommentsInput;
  fileName?: string | null;
  extended?: string | null;
  /** 选择Id */
  checkedIds?: string[] | null;
  /**
   * 导出类型
   * @format int32
   */
  exportType?: 0 | 1;
}

/** 账单评论信息 */
export interface GetFinancialBillingCommentsOutput {
  /** 账单编号 */
  billingId?: string | null;
  /** 账单子项编号 */
  itemId?: string | null;
  /** 账单子项类型 */
  itemScope?: string | null;
  /** 账单子项类型文本 */
  itemScopeText?: string | null;
  /** 评论人编号 */
  userId?: string | null;
  /** 评论人名称 */
  employeeName?: string | null;
  /** 评论人可见范围 */
  visibleScope?: string | null;
  /** 评论内容 */
  commentText?: string | null;
  /** 是否解决 */
  isResolved?: string | null;
  /** 是否解决文本 */
  isResolvedText?: string | null;
  /** 解决人编号 */
  resolvedUserId?: string | null;
  /** 解决人名称 */
  resolvedUserName?: string | null;
  /**
   * 解决时间
   * @format date-time
   */
  resolvedTime?: string | null;
  /** 父级评论编号 */
  parentCommitId?: string | null;
  /** 高亮文本 */
  highlightedText?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface GetFinancialBillingCommentsOutputListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: GetFinancialBillingCommentsOutput[] | null;
}

/** 通用分页信息类 */
export interface GetFinancialBillingCommentsOutputPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: GetFinancialBillingCommentsOutput[] | null;
}

/** 通用返回信息类 */
export interface GetFinancialBillingCommentsOutputPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: GetFinancialBillingCommentsOutputPageModel;
}

/** 账单关联费用支出明细表 */
export interface GetFinancialBillingDisbursementInput {
  /** 账单编号 */
  billingId?: string | null;
  /** 费用名称 */
  name?: string | null;
  /** 日期时间范围均使用 */
  expenseDate?: RangeDateTimeInput;
  /** 费用代码 */
  expenseCode?: string | null;
  /** 费用类别 */
  category?: string[] | null;
  /** 费用子类别 */
  subCategory?: string | null;
  /** 币种 */
  currency?: string[] | null;
  /**
   * 币种汇率
   * @format double
   */
  exchangeRate?: number | null;
  /** 报销类型 */
  expenseType?: string[] | null;
  /** 是否代收代付 */
  isPobo?: string[] | null;
  /**
   * 费用金额
   * @format double
   */
  amount?: number | null;
  /**
   * 计数数量
   * @format double
   */
  count?: number | null;
  /** 调整类型 */
  adjustmentType?: string[] | null;
  /**
   * 调整比例
   * @format double
   */
  adjustmentRate?: number | null;
  /**
   * 调整金额
   * @format double
   */
  adjustment?: number | null;
  /**
   * 税率
   * @format double
   */
  taxRate?: number | null;
  /**
   * 税额
   * @format double
   */
  taxAmount?: number | null;
  /**
   * 外币服务费金额
   * @format double
   */
  foreignAmount?: number;
  /**
   * 外币小计金额
   * @format double
   */
  foreignSubtotal?: number;
  /**
   * 小计金额
   * @format double
   */
  subtotal?: number | null;
  /** 是否计费 */
  isBillable?: string[] | null;
  /** 报销单编号 */
  expenserportId?: string | null;
  /** 是否已开票 */
  isInvoiced?: string[] | null;
  /** 商户名称 */
  merchant?: string | null;
  /** 案件编号 */
  caseId?: string | null;
  /** 费用报销人 */
  employeeId?: string | null;
  /** 发票/收据类型 */
  receiptType?: string | null;
  /** 费用报销人显示名称 */
  employeeDisplayName?: string | null;
  /** 是否内部账单 */
  isInternalBilling?: string[] | null;
  /** 内部账单编号 */
  internalBillingId?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 导出功能输入的参数 */
export interface GetFinancialBillingDisbursementInputExportInput {
  selectColumns?: SetColumn[] | null;
  /** 账单关联费用支出明细表 */
  search?: GetFinancialBillingDisbursementInput;
  fileName?: string | null;
  extended?: string | null;
  /** 选择Id */
  checkedIds?: string[] | null;
  /**
   * 导出类型
   * @format int32
   */
  exportType?: 0 | 1;
}

/** 账单关联费用支出明细表 */
export interface GetFinancialBillingDisbursementOutput {
  /** 账单编号 */
  billingId?: string | null;
  /** 费用名称 */
  name?: string | null;
  /**
   * 费用日期
   * @format date-time
   */
  expenseDate?: string | null;
  /** 费用代码 */
  expenseCode?: string | null;
  /** 费用类别 */
  category?: string | null;
  /** 费用类别文本 */
  categoryText?: string | null;
  /** 费用子类别 */
  subCategory?: string | null;
  /** 币种 */
  currency?: string | null;
  /** 币种 */
  currencyText?: string | null;
  /**
   * 币种汇率
   * @format double
   */
  exchangeRate?: number;
  /** 报销类型 */
  expenseType?: string | null;
  /** 报销类型文本 */
  expenseTypeText?: string | null;
  /** 是否代收代付 */
  isPobo?: string | null;
  /** 是否代收代付文本 */
  isPoboText?: string | null;
  /**
   * 费用金额
   * @format double
   */
  amount?: number;
  /**
   * 计数数量
   * @format double
   */
  count?: number;
  /** 调整类型 */
  adjustmentType?: string | null;
  /** 调整类型 */
  adjustmentTypeText?: string | null;
  /**
   * 调整比例
   * @format double
   */
  adjustmentRate?: number;
  /**
   * 调整金额
   * @format double
   */
  adjustment?: number;
  /**
   * 税率
   * @format double
   */
  taxRate?: number;
  /**
   * 税额
   * @format double
   */
  taxAmount?: number;
  /**
   * 外币服务费金额
   * @format double
   */
  foreignAmount?: number;
  /**
   * 外币小计金额
   * @format double
   */
  foreignSubtotal?: number;
  /**
   * 小计金额
   * @format double
   */
  subtotal?: number;
  /** 是否计费 */
  isBillable?: string | null;
  /** 是否计费 */
  isBillableText?: string | null;
  /** 报销单编号 */
  expenserportId?: string | null;
  /** 是否已开票 */
  isInvoiced?: string | null;
  /** 是否已开票 */
  isInvoicedText?: string | null;
  /** 商户名称 */
  merchant?: string | null;
  /** 案件编号 */
  caseId?: string | null;
  /** 费用报销人 */
  employeeId?: string | null;
  /** 费用报销人名称 */
  employeeName?: string | null;
  /** 费用报销人显示名称 */
  employeeDisplayName?: string | null;
  /** 发票/收据类型 */
  receiptType?: string | null;
  /** 发票/收据类型文本 */
  receiptTypeText?: string | null;
  /** 是否内部账单 */
  isInternalBilling?: string | null;
  /** 是否内部账单文本 */
  isInternalBillingText?: string | null;
  /** 内部账单编号 */
  internalBillingId?: string | null;
  /** 内部账单流水编号 */
  internalBillingSerialId?: string | null;
  /** 描述 */
  narrative?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用分页信息类 */
export interface GetFinancialBillingDisbursementOutputPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: GetFinancialBillingDisbursementOutput[] | null;
}

/** 通用返回信息类 */
export interface GetFinancialBillingDisbursementOutputPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: GetFinancialBillingDisbursementOutputPageModel;
}

export interface GetFinancialBillingDisbursementSubtotalOutput {
  /** 账单编号 */
  billingId?: string | null;
  /** 费用所属人 */
  employeeId?: string | null;
  /** 费用所属人名称 */
  employeeName?: string | null;
  /** 费用报销人显示名称 */
  employeeDisplayName?: string | null;
  /** 类型 */
  category?: string | null;
  /** 币种 */
  currency?: string | null;
  /** 币种文本 */
  currencyText?: string | null;
  /**
   * 币种汇率
   * @format double
   */
  exchangeRate?: number;
  /**
   * 数量
   * @format double
   */
  count?: number;
  /**
   * 调整额
   * @format double
   */
  adjustment?: number;
  /**
   * 调整后金额
   * @format double
   */
  adjustedAmount?: number;
  /**
   * 税率
   * @format double
   */
  taxRate?: number;
  /**
   * 税额
   * @format double
   */
  taxAmount?: number;
  /**
   * 金额
   * @format double
   */
  amount?: number;
  /**
   * 外币服务费金额
   * @format double
   */
  foreignAmount?: number;
  /**
   * 外币小计金额
   * @format double
   */
  foreignSubtotal?: number;
  /**
   * 小计金额
   * @format double
   */
  subtotal?: number;
}

/** 通用返回信息类 */
export interface GetFinancialBillingDisbursementSubtotalOutputListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: GetFinancialBillingDisbursementSubtotalOutput[] | null;
}

/** 账单汇率表 */
export interface GetFinancialBillingExchangeForEditOutput {
  /** 账单编号 */
  billingId?: string | null;
  /** 账单币种 */
  baseCurrency?: string | null;
  /** 目标币种 */
  targetCurrency?: string | null;
  /**
   * 汇率
   * @format double
   */
  exchangeRate?: number | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 表格数据，支持分页 */
export interface GetFinancialBillingExchangeForEditOutputForEditModel {
  /** 账单汇率表 */
  data?: GetFinancialBillingExchangeForEditOutput;
  /** 实体映射 */
  mapping?: Record<string, any>;
}

/** 通用返回信息类 */
export interface GetFinancialBillingExchangeForEditOutputForEditModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 表格数据，支持分页 */
  response?: GetFinancialBillingExchangeForEditOutputForEditModel;
}

/** 账单汇率表 */
export interface GetFinancialBillingExchangeInput {
  /** 账单编号 */
  billingId?: string | null;
  /** 账单币种 */
  baseCurrency?: string[] | null;
  /** 目标币种 */
  targetCurrency?: string[] | null;
  /**
   * 汇率
   * @format double
   */
  exchangeRate?: number | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 导出功能输入的参数 */
export interface GetFinancialBillingExchangeInputExportInput {
  selectColumns?: SetColumn[] | null;
  /** 账单汇率表 */
  search?: GetFinancialBillingExchangeInput;
  fileName?: string | null;
  extended?: string | null;
  /** 选择Id */
  checkedIds?: string[] | null;
  /**
   * 导出类型
   * @format int32
   */
  exportType?: 0 | 1;
}

/** 账单汇率表 */
export interface GetFinancialBillingExchangeOutput {
  /** 账单编号 */
  billingId?: string | null;
  /** 账单币种 */
  baseCurrency?: string | null;
  /** 账单币种 */
  baseCurrencyText?: string | null;
  /** 目标币种 */
  targetCurrency?: string | null;
  /** 目标币种 */
  targetCurrencyText?: string | null;
  /**
   * 汇率
   * @format double
   */
  exchangeRate?: number | null;
  /** 备注 */
  remark?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface GetFinancialBillingExchangeOutputListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: GetFinancialBillingExchangeOutput[] | null;
}

/** 通用分页信息类 */
export interface GetFinancialBillingExchangeOutputPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: GetFinancialBillingExchangeOutput[] | null;
}

/** 通用返回信息类 */
export interface GetFinancialBillingExchangeOutputPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: GetFinancialBillingExchangeOutputPageModel;
}

/** 账单关联固定服务费明细表 */
export interface GetFinancialBillingFixedServiceFeeInput {
  /** 账单编号 */
  billingId?: string | null;
  /** 日期时间范围均使用 */
  serviceDate?: RangeDateTimeInput;
  /** 任务 */
  task?: string | null;
  /** 活动代码 */
  activityCode?: string | null;
  /** 服务类型 */
  category?: string | null;
  /**
   * 服务费金额
   * @format double
   */
  amount?: number | null;
  /** 调整类型 */
  adjustmentType?: string | null;
  /**
   * 调整比例
   * @format double
   */
  adjustmentRate?: number | null;
  /**
   * 调整额
   * @format double
   */
  adjustment?: number | null;
  /**
   * 税率
   * @format double
   */
  taxRate?: number | null;
  /**
   * 税额
   * @format double
   */
  taxAmount?: number | null;
  /** 币种 */
  currency?: string | null;
  /**
   * 币种汇率
   * @format double
   */
  exchangeRate?: number | null;
  /**
   * 外币服务费金额
   * @format double
   */
  foreignAmount?: number;
  /**
   * 外币小计金额
   * @format double
   */
  foreignSubtotal?: number;
  /**
   * 小计金额
   * @format double
   */
  subtotal?: number | null;
  /** 是否计费 */
  isBillable?: string | null;
  /** 描述 */
  narrative?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 导出功能输入的参数 */
export interface GetFinancialBillingFixedServiceFeeInputExportInput {
  selectColumns?: SetColumn[] | null;
  /** 账单关联固定服务费明细表 */
  search?: GetFinancialBillingFixedServiceFeeInput;
  fileName?: string | null;
  extended?: string | null;
  /** 选择Id */
  checkedIds?: string[] | null;
  /**
   * 导出类型
   * @format int32
   */
  exportType?: 0 | 1;
}

/** 账单关联固定服务费明细表 */
export interface GetFinancialBillingFixedServiceFeeOutput {
  /** 账单编号 */
  billingId?: string | null;
  /**
   * 服务日期
   * @format date-time
   */
  serviceDate?: string | null;
  /** 任务 */
  task?: string | null;
  /** 任务文本 */
  taskText?: string | null;
  /** 活动代码 */
  activityCode?: string | null;
  /** 活动代码文本 */
  activityCodeText?: string | null;
  /** 服务类型 */
  category?: string | null;
  /** 服务类型文本 */
  categoryText?: string | null;
  /**
   * 服务费金额
   * @format double
   */
  amount?: number;
  /** 调整类型 */
  adjustmentType?: string | null;
  /** 调整类型 */
  adjustmentTypeText?: string | null;
  /**
   * 调整比例
   * @format double
   */
  adjustmentRate?: number;
  /**
   * 调整额
   * @format double
   */
  adjustment?: number;
  /**
   * 税率
   * @format double
   */
  taxRate?: number;
  /**
   * 税额
   * @format double
   */
  taxAmount?: number;
  /** 币种 */
  currency?: string | null;
  /** 币种 */
  currencyText?: string | null;
  /**
   * 币种汇率
   * @format double
   */
  exchangeRate?: number;
  /**
   * 外币服务费金额
   * @format double
   */
  foreignAmount?: number;
  /**
   * 外币小计金额
   * @format double
   */
  foreignSubtotal?: number;
  /**
   * 小计金额
   * @format double
   */
  subtotal?: number;
  /** 是否计费 */
  isBillable?: string | null;
  /** 是否计费 */
  isBillableText?: string | null;
  /** 描述 */
  narrative?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface GetFinancialBillingFixedServiceFeeOutputListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: GetFinancialBillingFixedServiceFeeOutput[] | null;
}

/** 通用分页信息类 */
export interface GetFinancialBillingFixedServiceFeeOutputPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: GetFinancialBillingFixedServiceFeeOutput[] | null;
}

/** 通用返回信息类 */
export interface GetFinancialBillingFixedServiceFeeOutputPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: GetFinancialBillingFixedServiceFeeOutputPageModel;
}

/** 账单信息编辑接口 */
export interface GetFinancialBillingForEditOutput {
  /**
   * 主案件编号
   *
   * 主要场景为联合账单子账单使用
   */
  mainCaseId?: string | null;
  /** 案件编号 */
  caseId?: string | null;
  /** 客户编号 */
  clientId?: string | null;
  /** 账单案件别名 */
  caseMatterName?: string | null;
  /**
   * 账单日期
   * @format date-time
   */
  billingDate?: string | null;
  /**
   * 到账日期
   * @format date-time
   */
  arrivedDate?: string | null;
  /**
   * 账单开始日期
   * @format date-time
   */
  startDate?: string | null;
  /**
   * 账单结束日期
   * @format date-time
   */
  endDate?: string | null;
  /** 付款方编号 */
  paymentId?: string | null;
  /** 付款方名称 */
  paymentName?: string | null;
  /** 付款周期 */
  paymentTerms?: string | null;
  /** 付款货币类型 */
  paymentCurrency?: string | null;
  /**
   * 应收账款
   * @format double
   */
  accountsReceivable?: number;
  /** 账单合伙人 */
  billingPartnerId?: string | null;
  /** 账单申请律师 */
  billingLawyerId?: string | null;
  /** AR负责人 */
  arResponsibleLawyerId?: string | null;
  /** 币种 */
  currency?: string | null;
  /**
   * 汇率
   * @format double
   */
  exchangeRate?: number;
  /** 支付状态 */
  paymentStatus?: string | null;
  /** 账单联系人 */
  contactPerson?: string | null;
  /** 邮箱地址 */
  emailAddress?: string | null;
  /** 账单所属办公室 */
  billingOffice?: string | null;
  /** 账单关联银行账号 */
  billingBankId?: string | null;
  /** 账单类型 */
  category?: string | null;
  /** 是否联合账单 */
  isBatchBilling?: string | null;
  /** 联合账单关联案号 */
  batchCaseIds?: string | null;
  /** 关联内部账单编号 */
  internalBillingIds?: string | null;
  /**
   * 总固定服务费
   * @format double
   */
  totalFixedServiceFee?: number;
  /**
   * 固定服务税费
   * @format double
   */
  fixedServiceTax?: number;
  /**
   * 账单总金额
   * @format double
   */
  totalAmount?: number;
  /**
   * 总工时服务费
   * @format double
   */
  totalTimesheetServiceFee?: number;
  /**
   * 工时税费
   * @format double
   */
  timesheetTax?: number;
  /**
   * 总支出费用金额
   * @format double
   */
  totalDisbursement?: number;
  /**
   * 支出税费
   * @format double
   */
  disbursementTax?: number;
  /**
   * 调整金额
   * @format double
   */
  totalAdjustment?: number;
  /**
   * 工时调整总额
   * @format double
   */
  timesheetAdjustment?: number;
  /**
   * 固定服务调整总额
   * @format double
   */
  fixedServiceAdjustment?: number;
  /**
   * 支出调整总额
   * @format double
   */
  disbursementAdjustment?: number;
  /**
   * 上限金额
   * @format double
   */
  upperLimitAmount?: number;
  /**
   * 上限金额税费
   * @format double
   */
  upperLimitAmountTax?: number;
  /**
   * POBO子项总计金额
   * @format double
   */
  poboSubtotalAmount?: number;
  /**
   * 外币结算金额
   * @format double
   */
  settlementAmount?: number;
  /** 是否跨境账单 */
  isCrossBorderBilling?: string | null;
  /** 是否内部账单 */
  isInternalBilling?: string | null;
  /** 是否坏账冲抵账单 */
  isBadDebts?: string | null;
  /** 账单生成附件 */
  billingAttachmentId?: string | null;
  /** 账单模板 */
  billingTemplateId?: string | null;
  /** 账单描述 */
  narrative?: string | null;
  /** 内部描述 */
  internalDescription?: string | null;
  /** 是否子账单 */
  isSubBilling?: string | null;
  /** 父级账单编号 */
  parentBillingId?: string | null;
  /**
   * 账单工时区间回收率
   * @format double
   */
  timesheetRecoveryRate?: number;
  /** 是否低回收率 */
  isLowRecoveryRate?: string | null;
  /** 是否低回收率文本 */
  isLowRecoveryRateText?: string | null;
  /**
   * 账单工时区间低回收率说明
   * @maxLength 500
   */
  lowRecoveryRateRemark?: string | null;
  /** 是否e-billing账单 */
  isEBilling?: string | null;
  /** 是否e-billing账单文本 */
  isEBillingText?: string | null;
  /** 流水编号 */
  serialId?: string | null;
  /** 原系统主键编号 */
  originalId?: string | null;
  /** 流程数据大状态 */
  status?: string | null;
  /**
   * 提交时间
   * @format date-time
   */
  submittedOn?: string | null;
  /** 工作流状态 */
  flowState?: string | null;
  /** 申请人 */
  applicant?: string | null;
  /**
   * 工作流结束时间
   * @format date-time
   */
  flowEndDateTime?: string | null;
  /** 是否超期 */
  isOverdue?: boolean | null;
  /** 备注 */
  remark?: string | null;
  /** 办公室 */
  officeId?: string | null;
  /** 子账单信息列表 */
  subBillingList?: GetSubFinancialBillingOutput[] | null;
  /** 内部账单信息列表 */
  internalBillingList?: GetSubFinancialBillingOutput[] | null;
  /** 父账单流水号 */
  parentBillingSerialId?: string | null;
  /** 关联子账单列表 */
  associatedSubBillingList?: IdAndSerialIdDto[] | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 表格数据，支持分页 */
export interface GetFinancialBillingForEditOutputForEditModel {
  /** 账单信息编辑接口 */
  data?: GetFinancialBillingForEditOutput;
  /** 实体映射 */
  mapping?: Record<string, any>;
}

/** 通用返回信息类 */
export interface GetFinancialBillingForEditOutputForEditModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 表格数据，支持分页 */
  response?: GetFinancialBillingForEditOutputForEditModel;
}

/** 账单外币支付信息 */
export interface GetFinancialBillingForeignPaymentForEditOutput {
  /** 账单编号 */
  billingId?: string | null;
  /** 账单币种 */
  billingCurrency?: string | null;
  /**
   * 账单金额
   * @format double
   */
  billingAmount?: number | null;
  /** 支付币种 */
  targetCurrency?: string | null;
  /**
   * 汇率
   * @format double
   */
  exchangeRate?: number | null;
  /**
   * 结算金额
   * @format double
   */
  targetAmount?: number | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 表格数据，支持分页 */
export interface GetFinancialBillingForeignPaymentForEditOutputForEditModel {
  /** 账单外币支付信息 */
  data?: GetFinancialBillingForeignPaymentForEditOutput;
  /** 实体映射 */
  mapping?: Record<string, any>;
}

/** 通用返回信息类 */
export interface GetFinancialBillingForeignPaymentForEditOutputForEditModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 表格数据，支持分页 */
  response?: GetFinancialBillingForeignPaymentForEditOutputForEditModel;
}

/** 账单外币支付信息 */
export interface GetFinancialBillingForeignPaymentInput {
  /** 账单编号 */
  billingId?: string | null;
  /** 账单币种 */
  billingCurrency?: string[] | null;
  /**
   * 账单金额
   * @format double
   */
  billingAmount?: number | null;
  /** 支付币种 */
  targetCurrency?: string[] | null;
  /**
   * 汇率
   * @format double
   */
  exchangeRate?: number | null;
  /**
   * 结算金额
   * @format double
   */
  targetAmount?: number | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 导出功能输入的参数 */
export interface GetFinancialBillingForeignPaymentInputExportInput {
  selectColumns?: SetColumn[] | null;
  /** 账单外币支付信息 */
  search?: GetFinancialBillingForeignPaymentInput;
  fileName?: string | null;
  extended?: string | null;
  /** 选择Id */
  checkedIds?: string[] | null;
  /**
   * 导出类型
   * @format int32
   */
  exportType?: 0 | 1;
}

/** 账单外币支付信息 */
export interface GetFinancialBillingForeignPaymentOutput {
  /** 账单编号 */
  billingId?: string | null;
  /** 账单币种 */
  billingCurrency?: string | null;
  /** 账单币种 */
  billingCurrencyText?: string | null;
  /**
   * 账单金额
   * @format double
   */
  billingAmount?: number | null;
  /** 支付币种 */
  targetCurrency?: string | null;
  /** 支付币种 */
  targetCurrencyText?: string | null;
  /**
   * 汇率
   * @format double
   */
  exchangeRate?: number | null;
  /**
   * 结算金额
   * @format double
   */
  targetAmount?: number | null;
  /** 备注 */
  remark?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface GetFinancialBillingForeignPaymentOutputListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: GetFinancialBillingForeignPaymentOutput[] | null;
}

/** 通用分页信息类 */
export interface GetFinancialBillingForeignPaymentOutputPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: GetFinancialBillingForeignPaymentOutput[] | null;
}

/** 通用返回信息类 */
export interface GetFinancialBillingForeignPaymentOutputPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: GetFinancialBillingForeignPaymentOutputPageModel;
}

/** 账单信息表 */
export interface GetFinancialBillingInput {
  /** 关键字 */
  filter?: string | null;
  /** 账单编号 */
  serialId?: string | null;
  /** 案件编号 */
  caseId?: string[] | null;
  /** 客户编号 */
  clientId?: string[] | null;
  /** 日期时间范围均使用 */
  billingDate?: RangeDateTimeInput;
  /** 日期时间范围均使用 */
  arrivedDate?: RangeDateTimeInput;
  /** 日期时间范围均使用 */
  workDate?: RangeDateTimeInput;
  /** 账单类型 */
  category?: string[] | null;
  /** 付款方名称 */
  paymentName?: string | null;
  /** 付款周期 */
  paymentTerms?: string[] | null;
  /** 付款货币类型 */
  paymentCurrency?: string[] | null;
  /** 账单合伙人 */
  billingPartnerId?: string[] | null;
  /** 支付状态 */
  paymentStatus?: string[] | null;
  /** 账单联系人 */
  contactPerson?: string | null;
  /** 隶属办公室 */
  officeId?: string[] | null;
  /** 账单关联银行账号 */
  billingBankId?: string[] | null;
  /** 内部描述 */
  internalDescription?: string | null;
  /** 账单描述 */
  narrative?: string | null;
  /** 账单状态 */
  status?: string[] | null;
  /** 日期时间范围均使用 */
  submittedOn?: RangeDateTimeInput;
  /** 申请人 */
  applicant?: string[] | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 导出功能输入的参数 */
export interface GetFinancialBillingInputExportInput {
  selectColumns?: SetColumn[] | null;
  /** 账单信息表 */
  search?: GetFinancialBillingInput;
  fileName?: string | null;
  extended?: string | null;
  /** 选择Id */
  checkedIds?: string[] | null;
  /**
   * 导出类型
   * @format int32
   */
  exportType?: 0 | 1;
}

/** 账单信息表 */
export interface GetFinancialBillingOutput {
  /** 客户外文名称 */
  clientForeignName?: string | null;
  /** 父账单流水号 */
  parentBillingSerialId?: string | null;
  /** 关联子账单列表 */
  associatedSubBillingList?: IdAndSerialIdDto[] | null;
  /** 案件编号 */
  caseId?: string | null;
  /** 案件流水号 */
  caseSerialId?: string | null;
  /** 案件名称 */
  caseName?: string | null;
  /** 账单案件别名 */
  caseMatterName?: string | null;
  /** 客户编号 */
  clientId?: string | null;
  /** 客户流水号 */
  clientSerialId?: string | null;
  /** 客户名称 */
  clientName?: string | null;
  /**
   * 账单日期
   * @format date-time
   */
  billingDate?: string | null;
  /**
   * 到账日期
   * @format date-time
   */
  arrivedDate?: string | null;
  /**
   * 账单开始日期
   * @format date-time
   */
  startDate?: string | null;
  /**
   * 账单结束日期
   * @format date-time
   */
  endDate?: string | null;
  /** 付款方编号 */
  paymentId?: string | null;
  /** 付款方名称 */
  paymentName?: string | null;
  /** 付款条件 */
  paymentTerms?: string | null;
  /** 付款条件 */
  paymentTermsText?: string | null;
  /** 付款货币类型 */
  paymentCurrency?: string | null;
  /** 付款货币类型文本 */
  paymentCurrencyText?: string | null;
  /**
   * 应收账款
   * @format double
   */
  accountsReceivable?: number;
  /** 账单管理合伙人 */
  billingPartnerId?: string | null;
  /** 账单管理合伙人名称 */
  billingPartnerName?: string | null;
  /** 账单申请律师 */
  billingLawyerId?: string | null;
  /** 账单申请律师名称 */
  billingLawyerName?: string | null;
  /**
   * 应收账款负责人
   *
   * Accounts Receivable Responsible 催收负责人
   */
  arResponsibleLawyerId?: string | null;
  /**
   * 应收账款负责人名称
   *
   * Accounts Receivable Responsible 催收负责人
   */
  arResponsibleLawyerName?: string | null;
  /** 币种 */
  currency?: string | null;
  /** 币种文本 */
  currencyText?: string | null;
  /**
   * 汇率
   * @format double
   */
  exchangeRate?: number;
  /** 支付状态 */
  paymentStatus?: string | null;
  /** 账单联系人 */
  contactPerson?: string | null;
  /** 邮箱地址 */
  emailAddress?: string | null;
  /** 账单所属办公室 */
  billingOffice?: string | null;
  /** 账单所属办公室名称 */
  billingOfficeName?: string | null;
  /** 账单关联银行账号 */
  billingBankId?: string | null;
  /** 账单类型;联合账单，内部账单，etc… */
  category?: string | null;
  /** 账单类型;联合账单，内部账单，etc… */
  categoryText?: string | null;
  /** 是否联合账单 */
  isBatchBilling?: string | null;
  /** 是否联合账单 */
  isBatchBillingText?: string | null;
  /** 联合账单关联案号 */
  batchCaseIds?: string | null;
  /** 关联内部账单编号 */
  internalBillingIds?: string | null;
  /**
   * 总固定服务费
   * @format double
   */
  totalFixedServiceFee?: number;
  /**
   * 总固定服务费实际金额（含税）
   * @format double
   */
  fixedSettlementAmount?: number;
  /**
   * 固定服务税费
   * @format double
   */
  fixedServiceTax?: number;
  /**
   * 账单总金额
   * @format double
   */
  totalAmount?: number;
  /**
   * 总工时服务费
   * @format double
   */
  totalTimesheetServiceFee?: number;
  /**
   * 总工时服务费实际金额（含税）
   * @format double
   */
  timesheetSettlementAmount?: number;
  /**
   * 工时税费
   * @format double
   */
  timesheetTax?: number;
  /**
   * 总支出费用金额
   * @format double
   */
  totalDisbursement?: number;
  /**
   * 总支出费用实际金额（含税）
   * @format double
   */
  disbursementSettlementAmount?: number;
  /**
   * 支出税费
   * @format double
   */
  disbursementTax?: number;
  /**
   * 调整金额
   * @format double
   */
  totalAdjustment?: number;
  /**
   * 账单税后调整比例
   * @format double
   */
  totalAdjustmentRate?: number;
  /**
   * 工时税后调整总额
   * @format double
   */
  timesheetAdjustment?: number;
  /**
   * 工时税后调整比例
   * @format double
   */
  timesheetAdjustmentRate?: number;
  /**
   * 工时上限金额
   * @format double
   */
  timesheetUpperLimitAmount?: number;
  /**
   * 工时上限税额
   * @format double
   */
  timesheetUpperLimitTax?: number;
  /**
   * 固定服务费税后调整总额
   * @format double
   */
  fixedServiceAdjustment?: number;
  /**
   * 固定服务费税后调整比例
   * @format double
   */
  fixedServiceAdjustmentRate?: number;
  /**
   * 固定费用上限金额
   * @format double
   */
  fixedServiceUpperLimitAmount?: number;
  /**
   * 固定费用上限税额
   * @format double
   */
  fixedServiceUpperLimitTax?: number;
  /**
   * 支出税后调整总额
   * @format double
   */
  disbursementAdjustment?: number;
  /**
   * 支出税后调整比例
   * @format double
   */
  disbursementAdjustmentRate?: number;
  /**
   * 支出费用上限金额
   * @format double
   */
  disbursementUpperLimitAmount?: number;
  /**
   * 支出费用上税金额
   * @format double
   */
  disbursementUpperLimitTax?: number;
  /**
   * 上限金额
   * @format double
   */
  upperLimitAmount?: number;
  /**
   * 上限金额税费
   * @format double
   */
  upperLimitAmountTax?: number;
  /**
   * POBO子项总计金额
   * @format double
   */
  poboSubtotalAmount?: number;
  /**
   * 税率
   * @format double
   */
  taxRate?: number;
  /**
   * 账单总税额
   * @format double
   */
  totalTaxAmount?: number;
  /**
   * 不含税金额合计
   *
   * = 净服务费 + 调整金额
   * @format double
   */
  totalExclusiveAmount?: number;
  /**
   * 价税合计
   *
   * = 不含税金额合计 + 税费
   * @format double
   */
  totalAmountIncludingTax?: number;
  /**
   * 外币结算金额
   * @format double
   */
  settlementAmount?: number;
  /** 是否跨境账单 */
  isCrossBorderBilling?: string | null;
  /** 是否跨境账单 */
  isCrossBorderBillingText?: string | null;
  /** 是否内部账单 */
  isInternalBilling?: string | null;
  /** 是否内部账单文本 */
  isInternalBillingText?: string | null;
  /** 是否坏账冲抵账单 */
  isBadDebts?: string | null;
  /** 是否坏账冲抵账单 */
  isBadDebtsText?: string | null;
  /** 账单生成附件 */
  billingAttachmentId?: string | null;
  /** 账单模板 */
  billingTemplateId?: string | null;
  /** 内部描述 */
  internalDescription?: string | null;
  /** 账单描述 */
  narrative?: string | null;
  /** 是否子账单 */
  isSubBilling?: string | null;
  /** 是否子账单文本 */
  isSubBillingText?: string | null;
  /** 父级账单编号 */
  parentBillingId?: string | null;
  /**
   * 账单工时区间回收率
   * @format double
   */
  timesheetRecoveryRate?: number;
  /** 是否低回收率 */
  isLowRecoveryRate?: string | null;
  /** 是否低回收率文本 */
  isLowRecoveryRateText?: string | null;
  /**
   * 账单工时区间低回收率说明
   * @maxLength 500
   */
  lowRecoveryRateRemark?: string | null;
  /** 是否e-billing账单 */
  isEBilling?: string | null;
  /** 是否e-billing账单文本 */
  isEBillingText?: string | null;
  /** 流水编号 */
  serialId?: string | null;
  /** 原系统主键编号 */
  originalId?: string | null;
  /** 流程数据大状态 */
  status?: string | null;
  /**
   * 提交时间
   * @format date-time
   */
  submittedOn?: string | null;
  /** 工作流状态 */
  flowState?: string | null;
  /** 申请人 */
  applicant?: string | null;
  /**
   * 工作流结束时间
   * @format date-time
   */
  flowEndDateTime?: string | null;
  /** 是否超期 */
  isOverdue?: boolean | null;
  /** 备注 */
  remark?: string | null;
  /** 办公室 */
  officeId?: string | null;
  /** 办公室名称 */
  officeName?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface GetFinancialBillingOutputListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: GetFinancialBillingOutput[] | null;
}

/** 通用分页信息类 */
export interface GetFinancialBillingOutputPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: GetFinancialBillingOutput[] | null;
}

/** 通用返回信息类 */
export interface GetFinancialBillingOutputPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: GetFinancialBillingOutputPageModel;
}

/** 账单预览信息输出 */
export interface GetFinancialBillingPreviewOutput {
  /** 模版编号 */
  templateId?: string | null;
  /** 模板名称 */
  templateName?: string | null;
  /** 文件路径 */
  templateFilePath?: string | null;
  /** 文件类型 */
  templateFileExtension?: string | null;
  /** 是否终版 */
  isFinal?: boolean;
  /**
   * 显示调整/折扣百分比
   *
   * 在账单汇总页面上，选择此项以设置固定/小时专业服务费用及账单上的杂项费用的调整/折扣百分比
   */
  displayAdjustmentDiscountPercentage?: boolean;
  /**
   * 包含工时表的详细信息
   *
   * 开启可显示工时明细
   */
  includingTimesheetDetailedInfo?: boolean;
  /**
   * 横向显示工时明细信息
   *
   * false 时，显示为纵向表格
   */
  displayTimesheetDetailedHorizontally?: boolean;
  /**
   * 显示原始费用
   *
   * 开启可显示封顶之前的原始小时专业服务费用
   */
  displayOriginalFee?: boolean;
  /**
   * 显示银行信息
   *
   * 开启可显示银行账户信息
   */
  displayBankAccountInfo?: boolean;
  /**
   * 包含费用的详细信息
   *
   * 开启可显示费用明细
   */
  includingExpenseDetailedInfo?: boolean;
  /**
   * 显示账单封面
   *
   * 生成账单时包含封面信息
   */
  displayCoverLetter?: boolean;
  /** 附件信息 */
  attachments?: AttachmentDownloadDto[] | null;
}

/** 通用返回信息类 */
export interface GetFinancialBillingPreviewOutputListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: GetFinancialBillingPreviewOutput[] | null;
}

/** 通用返回信息类 */
export interface GetFinancialBillingPreviewOutputMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 账单预览信息输出 */
  response?: GetFinancialBillingPreviewOutput;
}

/** 应收账单入参 */
export interface GetFinancialBillingReceivableInput {
  /** 关键字 */
  filter?: string | null;
  /** 案件编号 */
  caseId?: string[] | null;
  /** 账单编号 */
  serialId?: string | null;
  /** 付款方名称 */
  paymentName?: string[] | null;
  /** 数值范围选择器 */
  totalAmount?: RangeDecimalInput;
  /** 日期时间范围均使用 */
  billingDate?: RangeDateTimeInput;
  /** 账单管理人 */
  billingPartnerId?: string[] | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 账单模板表 */
export interface GetFinancialBillingTemplateInput {
  /** 模板名称 */
  name?: string | null;
  /** 文件路径 */
  filePath?: string | null;
  /** 文件类型 */
  fileExtension?: string | null;
  /**
   * 文件大小
   * @format float
   */
  fileSize?: number | null;
  /** 适用语言 */
  language?: string | null;
  /** 模板适用范围 */
  scope?: string | null;
  /** 适用案件类型 */
  caseCategory?: string | null;
  /** 模板类型 */
  category?: string | null;
  /** 是否包含封面页 */
  isIncludedCoverLetter?: string | null;
  /** 是否是上传模板 */
  isUpload?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 导出功能输入的参数 */
export interface GetFinancialBillingTemplateInputExportInput {
  selectColumns?: SetColumn[] | null;
  /** 账单模板表 */
  search?: GetFinancialBillingTemplateInput;
  fileName?: string | null;
  extended?: string | null;
  /** 选择Id */
  checkedIds?: string[] | null;
  /**
   * 导出类型
   * @format int32
   */
  exportType?: 0 | 1;
}

export interface GetFinancialBillingTimesheetRecoveryRateItemDto {
  employeeId?: string | null;
  /** 计时人员 Timekeeper ID */
  employeeSerialId?: string | null;
  /** 计时人员头像 */
  employeeAvatar?: string | null;
  /** 计时人员 */
  employeeName?: string | null;
  /**
   * 账单工时服务费数量
   * @format int32
   */
  serviceFeeCount?: number;
  /**
   * 账单工时区间内的工时数量
   * @format int32
   */
  timesheetCount?: number;
  /**
   * 账单工时服务费的可计费小时数
   * @format double
   */
  serviceBillableHours?: number;
  /**
   * 账单工时区间内的可计费小时数
   * @format double
   */
  timesheetBillableHours?: number;
  /**
   * 账单工时服务费的可计费金额
   * @format double
   */
  serviceBillableAmount?: number;
  /**
   * 账单工时区间内的可计费金额
   * @format double
   */
  timesheetBillableAmount?: number;
  /** 费率币种 */
  currency?: string | null;
  /**
   * 小时费率
   * @format double
   */
  hourlyRate?: number;
  /**
   * 工时回收率
   * @format double
   */
  recoveryRate?: number;
}

export interface GetFinancialBillingTimesheetRecoveryRateOutput {
  /**
   * 账单工时服务费数量
   * @format int32
   */
  serviceFeeCount?: number;
  /**
   * 账单工时区间内的工时数量
   * @format int32
   */
  timesheetCount?: number;
  /**
   * 账单工时服务费的可计费小时数
   * @format double
   */
  serviceBillableHours?: number;
  /**
   * 账单工时区间内的可计费小时数
   * @format double
   */
  timesheetBillableHours?: number;
  /**
   * 账单工时服务费的可计费金额
   * @format double
   */
  serviceBillableAmount?: number;
  /**
   * 账单工时区间内的可计费金额
   * @format double
   */
  timesheetBillableAmount?: number;
  /**
   * 工时回收率
   * @format double
   */
  recoveryRate?: number;
  /** 是否低回收率 */
  isLowRecoveryRate?: string | null;
  /** 计时人员回收率小计列表 */
  timekeeperRecoveryRateList?: GetFinancialBillingTimesheetRecoveryRateItemDto[] | null;
}

/** 通用返回信息类 */
export interface GetFinancialBillingTimesheetRecoveryRateOutputMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  response?: GetFinancialBillingTimesheetRecoveryRateOutput;
}

/** 账单工时费明细表 */
export interface GetFinancialBillingTimesheetServiceFeeInput {
  /** 账单编号 */
  billingId?: string | null;
  /** 案件编号 */
  caseId?: string | null;
  /** 日期时间范围均使用 */
  workDate?: RangeDateTimeInput;
  /** 工时填报人 */
  employeeId?: string | null;
  /** 计时人员Id */
  timekeeperId?: string | null;
  /**
   * 小时费率
   * @format double
   */
  hourlyRate?: number | null;
  /** 任务 */
  task?: string | null;
  /** 活动代码 */
  activityCode?: string | null;
  /** 币种 */
  currency?: string | null;
  /**
   * 币种汇率
   * @format double
   */
  exchangeRate?: number | null;
  /**
   * 自报小时
   * @format double
   */
  hoursWorked?: number;
  /**
   * 计费小时
   * @format double
   */
  billableHours?: number | null;
  /** 工时类型 */
  category?: string | null;
  /** 调整类型 */
  adjustmentType?: string | null;
  /**
   * 调整比例
   * @format double
   */
  adjustmentRate?: number | null;
  /**
   * 调整额
   * @format double
   */
  adjustment?: number | null;
  /**
   * 税率
   * @format double
   */
  taxRate?: number | null;
  /**
   * 税额
   * @format double
   */
  taxAmount?: number | null;
  /**
   * 金额
   * @format double
   */
  amount?: number | null;
  /**
   * 外币服务费金额
   * @format double
   */
  foreignAmount?: number;
  /**
   * 外币小计金额
   * @format double
   */
  foreignSubtotal?: number;
  /**
   * 小计金额
   * @format double
   */
  subtotal?: number | null;
  /** 是否计费 */
  isBillable?: string | null;
  /** 是否销账 */
  isWriteOff?: string | null;
  /** 销账类型 */
  writeOffType?: string | null;
  /** 销账理由 */
  writeOffRemark?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 导出功能输入的参数 */
export interface GetFinancialBillingTimesheetServiceFeeInputExportInput {
  selectColumns?: SetColumn[] | null;
  /** 账单工时费明细表 */
  search?: GetFinancialBillingTimesheetServiceFeeInput;
  fileName?: string | null;
  extended?: string | null;
  /** 选择Id */
  checkedIds?: string[] | null;
  /**
   * 导出类型
   * @format int32
   */
  exportType?: 0 | 1;
}

/** 账单工时费明细表 */
export interface GetFinancialBillingTimesheetServiceFeeOutput {
  /** 工时所属人TimekeeperId */
  employeeSerialId?: string | null;
  /** 账单编号 */
  billingId?: string | null;
  /**
   * 工时日期
   * @format date-time
   */
  workDate?: string | null;
  /** 工时填报人 */
  employeeId?: string | null;
  /** 工时所属人名称 */
  employeeName?: string | null;
  /** 费用报销人显示名称 */
  employeeDisplayName?: string | null;
  /**
   * 小时费率
   * @format double
   */
  hourlyRate?: number;
  /** 任务 */
  task?: string | null;
  /** 任务文本 */
  taskText?: string | null;
  /** 活动代码 */
  activityCode?: string | null;
  /** 活动代码文本 */
  activityCodeText?: string | null;
  /** 币种 */
  currency?: string | null;
  /** 币种 */
  currencyText?: string | null;
  /**
   * 币种汇率
   * @format double
   */
  exchangeRate?: number;
  /**
   * 自报小时
   * @format double
   */
  hoursWorked?: number;
  /**
   * 计费小时
   * @format double
   */
  billableHours?: number;
  /** 工时类型 */
  category?: string | null;
  /** 调整类型 */
  adjustmentType?: string | null;
  /** 调整类型 */
  adjustmentTypeText?: string | null;
  /**
   * 调整比例
   * @format double
   */
  adjustmentRate?: number;
  /**
   * 调整额
   * @format double
   */
  adjustment?: number;
  /**
   * 税率
   * @format double
   */
  taxRate?: number;
  /**
   * 税额
   * @format double
   */
  taxAmount?: number;
  /**
   * 金额
   * @format double
   */
  amount?: number;
  /**
   * 外币服务费金额
   * @format double
   */
  foreignAmount?: number;
  /**
   * 外币小计金额
   * @format double
   */
  foreignSubtotal?: number;
  /**
   * 小计金额
   * @format double
   */
  subtotal?: number;
  /** 是否计费 */
  isBillable?: string | null;
  /** 是否计费 */
  isBillableText?: string | null;
  /** 是否销账 */
  isWriteOff?: string | null;
  /** 是否销账 */
  isWriteOffText?: string | null;
  /** 销账类型 */
  writeOffType?: string | null;
  /** 销账类型 */
  writeOffTypeText?: string | null;
  /** 销账理由 */
  writeOffRemark?: string | null;
  /** 工时编号 */
  timesheetId?: string | null;
  /** 工时描述 */
  narrative?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用分页信息类 */
export interface GetFinancialBillingTimesheetServiceFeeOutputPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: GetFinancialBillingTimesheetServiceFeeOutput[] | null;
}

/** 通用返回信息类 */
export interface GetFinancialBillingTimesheetServiceFeeOutputPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: GetFinancialBillingTimesheetServiceFeeOutputPageModel;
}

export interface GetFinancialBillingTimesheetSubtotalOutput {
  /** 账单编号 */
  billingId?: string | null;
  /** 工时所属人 */
  employeeId?: string | null;
  /** 工时所属人名称 */
  employeeName?: string | null;
  /** 工时所属人显示名称 */
  employeeDisplayName?: string | null;
  /** 工时所属人TimekeeperId */
  employeeSerialId?: string | null;
  /**
   * 小时费率
   * @format double
   */
  hourlyRate?: number;
  /** 币种 */
  currency?: string | null;
  /** 币种 */
  currencyText?: string | null;
  /**
   * 币种汇率
   * @format double
   */
  exchangeRate?: number;
  /**
   * 自报小时
   * @format double
   */
  hoursWorked?: number;
  /**
   * 计费小时
   * @format double
   */
  billableHours?: number;
  /**
   * 调整额
   * @format double
   */
  adjustment?: number;
  /**
   * 调整后金额
   * @format double
   */
  adjustedAmount?: number;
  /**
   * 税率
   * @format double
   */
  taxRate?: number;
  /**
   * 税额
   * @format double
   */
  taxAmount?: number;
  /**
   * 金额
   * @format double
   */
  amount?: number;
  /**
   * 外币服务费金额
   * @format double
   */
  foreignAmount?: number;
  /**
   * 外币小计金额
   * @format double
   */
  foreignSubtotal?: number;
  /**
   * 小计金额
   * @format double
   */
  subtotal?: number;
}

/** 通用返回信息类 */
export interface GetFinancialBillingTimesheetSubtotalOutputListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: GetFinancialBillingTimesheetSubtotalOutput[] | null;
}

/** 账单合计请求 */
export interface GetFinancialBillingTotalInput {
  /** 账单编号 */
  id?: string | null;
  /** 是否编辑时调用 */
  isEdit?: boolean;
}

/** 账单合计 */
export interface GetFinancialBillingTotalOutput {
  /** 账单ID */
  billingId?: string | null;
  /** 总计科目名称 */
  name?: string | null;
  /** 总计科目作用域 */
  scope?: string | null;
  /**
   * 净服务费合计
   * @format double
   */
  totalAmount?: number;
  /**
   * 税前调整额度合计
   * @format double
   */
  totalAdjustment?: number;
  /**
   * 税前调整比例
   * @format double
   */
  totalAdjustmentRatio?: number;
  /**
   * 不含税金额合计
   *
   * = 净服务费 + 调整金额
   * @format double
   */
  totalExclusiveAmount?: number;
  /**
   * 税率
   * @format double
   */
  taxRate?: number;
  /**
   * 税费合计
   * @format double
   */
  totalTaxAmount?: number;
  /**
   * 价税合计
   *
   * = 不含税金额 + 税费
   * @format double
   */
  totalAmountIncludingTax?: number;
  /**
   * 不计费金额合计
   * @format double
   */
  totalNonBillableAmount?: number;
  /**
   * 合计金额
   * @format double
   */
  totalSubtotal?: number;
  /**
   * 上限金额
   * @format double
   */
  upperLimitAmount?: number;
  /**
   * 是否固定为上限金额
   *
   * Y：含税，N：不含税
   */
  isSetAsFixedFee?: string | null;
  /**
   * 是否含税
   *
   * Y：含税，N：不含税
   */
  isIncludeTaxFee?: string | null;
  /** 税费信息列表 */
  taxFeeList?: GetTaxFeeItemDto[] | null;
  /**
   * 合计结算金额 = 最终计算后的合计金额
   * @format double
   */
  totalSettlementAmount?: number;
}

/** 通用返回信息类 */
export interface GetFinancialBillingTotalOutputListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: GetFinancialBillingTotalOutput[] | null;
}

/** 账单限额设置表 */
export interface GetFinancialBillingUpperLimitInput {
  /** 账单编号 */
  billingId?: string | null;
  /** 应用范围 */
  scope?: string | null;
  /** 是否包含税费 */
  isIncludeTaxFee?: string | null;
  /** 是否设为固定收费 */
  isSetAsFixedFee?: string | null;
  /**
   * 上限金额
   * @format double
   */
  amount?: number | null;
  /**
   * 小计金额
   * @format double
   */
  subtotal?: number | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 导出功能输入的参数 */
export interface GetFinancialBillingUpperLimitInputExportInput {
  selectColumns?: SetColumn[] | null;
  /** 账单限额设置表 */
  search?: GetFinancialBillingUpperLimitInput;
  fileName?: string | null;
  extended?: string | null;
  /** 选择Id */
  checkedIds?: string[] | null;
  /**
   * 导出类型
   * @format int32
   */
  exportType?: 0 | 1;
}

export interface GetFinancialBillingWriteOffDisbursementInput {
  /** 账单编号 */
  billingId?: string | null;
  /** 案件编号 */
  caseId?: string | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

export interface GetFinancialBillingWriteOffTimesheetInput {
  /** 账单编号 */
  billingId?: string | null;
  /** 案件编号 */
  caseId?: string | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 财务办公室主管会计表 */
export interface GetFinancialChiefAccountantForEditOutput {
  /** 会计主管ID */
  userId?: string | null;
  /** 是否主管 */
  isChief?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 表格数据，支持分页 */
export interface GetFinancialChiefAccountantForEditOutputForEditModel {
  /** 财务办公室主管会计表 */
  data?: GetFinancialChiefAccountantForEditOutput;
  /** 实体映射 */
  mapping?: Record<string, any>;
}

/** 通用返回信息类 */
export interface GetFinancialChiefAccountantForEditOutputForEditModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 表格数据，支持分页 */
  response?: GetFinancialChiefAccountantForEditOutputForEditModel;
}

/** 财务办公室主管会计表 */
export interface GetFinancialChiefAccountantInput {
  /** 会计主管 */
  userId?: string[] | null;
  /** 是否会计主管 */
  isChief?: string[] | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 导出功能输入的参数 */
export interface GetFinancialChiefAccountantInputExportInput {
  selectColumns?: SetColumn[] | null;
  /** 财务办公室主管会计表 */
  search?: GetFinancialChiefAccountantInput;
  fileName?: string | null;
  extended?: string | null;
  /** 选择Id */
  checkedIds?: string[] | null;
  /**
   * 导出类型
   * @format int32
   */
  exportType?: 0 | 1;
}

/** 财务办公室主管会计表 */
export interface GetFinancialChiefAccountantOutput {
  /** 会计主管ID */
  userId?: string | null;
  /** 会计主管头像 */
  userAvatar?: string | null;
  /** 会计主管人中文名 */
  userCnName?: string | null;
  /** 会计主管人姓名 */
  userName?: string | null;
  /** 会计主管人邮箱 */
  userEmailAddress?: string | null;
  /** 是否会计主管 */
  isChief?: string | null;
  /** 是否会计主管文本 */
  isChiefText?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface GetFinancialChiefAccountantOutputListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: GetFinancialChiefAccountantOutput[] | null;
}

/** 通用分页信息类 */
export interface GetFinancialChiefAccountantOutputPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: GetFinancialChiefAccountantOutput[] | null;
}

/** 通用返回信息类 */
export interface GetFinancialChiefAccountantOutputPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: GetFinancialChiefAccountantOutputPageModel;
}

/** 费用申请表 */
export interface GetFinancialExpenseApplyForEditOutput {
  /**
   * 申请时间
   * @format date-time
   */
  applyDate?: string | null;
  /**
   * 消费时间
   * @format date-time
   */
  feeDate?: string | null;
  /** 账单编号 */
  billingId?: string | null;
  /** 费用名称 */
  name?: string | null;
  /** 费用代码 */
  expenseCode?: string | null;
  /** 费用类别 */
  category?: string | null;
  /** 费用子类别 */
  subCategory?: string | null;
  /** 币种 */
  currency?: string | null;
  /**
   * 币种汇率
   * @format double
   */
  exchangeRate?: number | null;
  /** 报销类型 */
  expenseType?: string | null;
  /** 是否代收代付 */
  isPobo?: string | null;
  /**
   * 费用金额
   * @format double
   */
  amount?: number | null;
  /**
   * 计数数量
   * @format double
   */
  count?: number | null;
  /**
   * 支付时间
   * @format date-time
   */
  payDate?: string | null;
  /**
   * 税率
   * @format double
   */
  taxRate?: number | null;
  /**
   * 税额
   * @format double
   */
  taxAmount?: number | null;
  /**
   * 小计金额
   * @format double
   */
  subtotal?: number | null;
  /** 是否计费 */
  isBillable?: string | null;
  /** 报销单编号 */
  expenserportId?: string | null;
  /** 是否已开票 */
  isInvoiced?: string | null;
  /** 商户名称 */
  merchant?: string | null;
  /** 案件编号 */
  caseId?: string | null;
  /** 费用报销人 */
  employeeId?: string | null;
  /** 费用描述 */
  narrative?: string | null;
  /** 发票/收据类型 */
  receiptType?: string | null;
  /** 流水编号 */
  serialId?: string | null;
  /** 原系统主键编号 */
  originalId?: string | null;
  /** 流程数据大状态 */
  status?: string | null;
  /**
   * 提交时间
   * @format date-time
   */
  submittedOn?: string | null;
  /** 工作流状态 */
  flowState?: string | null;
  /** 申请人 */
  applicant?: string | null;
  /**
   * 工作流结束时间
   * @format date-time
   */
  flowEndDateTime?: string | null;
  /** 是否超期 */
  isOverdue?: boolean | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 表格数据，支持分页 */
export interface GetFinancialExpenseApplyForEditOutputForEditModel {
  /** 费用申请表 */
  data?: GetFinancialExpenseApplyForEditOutput;
  /** 实体映射 */
  mapping?: Record<string, any>;
}

/** 通用返回信息类 */
export interface GetFinancialExpenseApplyForEditOutputForEditModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 表格数据，支持分页 */
  response?: GetFinancialExpenseApplyForEditOutputForEditModel;
}

/** 费用申请表 */
export interface GetFinancialExpenseApplyInput {
  /** 日期时间范围均使用 */
  applyDate?: RangeDateTimeInput;
  /** 日期时间范围均使用 */
  feeDate?: RangeDateTimeInput;
  /** 账单编号 */
  billingId?: string | null;
  /** 费用名称 */
  name?: string | null;
  /** 费用代码 */
  expenseCode?: string | null;
  /** 费用类别 */
  category?: string | null;
  /** 费用子类别 */
  subCategory?: string | null;
  /** 币种 */
  currency?: string | null;
  /**
   * 币种汇率
   * @format double
   */
  exchangeRate?: number | null;
  /** 报销类型 */
  expenseType?: string | null;
  /** 是否代收代付 */
  isPobo?: string | null;
  /**
   * 费用金额
   * @format double
   */
  amount?: number | null;
  /**
   * 计数数量
   * @format double
   */
  count?: number | null;
  /** 日期时间范围均使用 */
  payDate?: RangeDateTimeInput;
  /**
   * 税率
   * @format double
   */
  taxRate?: number | null;
  /**
   * 税额
   * @format double
   */
  taxAmount?: number | null;
  /**
   * 小计金额
   * @format double
   */
  subtotal?: number | null;
  /** 是否计费 */
  isBillable?: string | null;
  /** 报销单编号 */
  expenserportId?: string | null;
  /** 是否已开票 */
  isInvoiced?: string | null;
  /** 商户名称 */
  merchant?: string | null;
  /** 案件编号 */
  caseId?: string | null;
  /** 费用报销人 */
  employeeId?: string | null;
  /** 费用描述 */
  narrative?: string | null;
  /** 发票/收据类型 */
  receiptType?: string | null;
  /** 流水编号 */
  serialId?: string | null;
  /** 原系统主键编号 */
  originalId?: string | null;
  /** 流程数据大状态 */
  status?: string | null;
  /** 日期时间范围均使用 */
  submittedOn?: RangeDateTimeInput;
  /** 工作流状态 */
  flowState?: string | null;
  /** 申请人 */
  applicant?: string | null;
  /** 日期时间范围均使用 */
  flowEndDateTime?: RangeDateTimeInput;
  /** 是否超期 */
  isOverdue?: boolean | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 导出功能输入的参数 */
export interface GetFinancialExpenseApplyInputExportInput {
  selectColumns?: SetColumn[] | null;
  /** 费用申请表 */
  search?: GetFinancialExpenseApplyInput;
  fileName?: string | null;
  extended?: string | null;
  /** 选择Id */
  checkedIds?: string[] | null;
  /**
   * 导出类型
   * @format int32
   */
  exportType?: 0 | 1;
}

/** 费用申请表 */
export interface GetFinancialExpenseApplyOutput {
  /**
   * 申请时间
   * @format date-time
   */
  applyDate?: string | null;
  /**
   * 消费时间
   * @format date-time
   */
  feeDate?: string | null;
  /** 账单编号 */
  billingId?: string | null;
  /** 费用名称 */
  name?: string | null;
  /** 费用代码 */
  expenseCode?: string | null;
  /** 费用类别 */
  category?: string | null;
  /** 费用类别 */
  categoryText?: string | null;
  /** 费用子类别 */
  subCategory?: string | null;
  /** 币种 */
  currency?: string | null;
  /** 币种 */
  currencyText?: string | null;
  /**
   * 币种汇率
   * @format double
   */
  exchangeRate?: number | null;
  /** 报销类型 */
  expenseType?: string | null;
  /** 报销类型 */
  expenseTypeText?: string | null;
  /** 是否代收代付 */
  isPobo?: string | null;
  /** 是否代收代付 */
  isPoboText?: string | null;
  /**
   * 费用金额
   * @format double
   */
  amount?: number | null;
  /**
   * 计数数量
   * @format double
   */
  count?: number | null;
  /**
   * 支付时间
   * @format date-time
   */
  payDate?: string | null;
  /**
   * 税率
   * @format double
   */
  taxRate?: number | null;
  /**
   * 税额
   * @format double
   */
  taxAmount?: number | null;
  /**
   * 小计金额
   * @format double
   */
  subtotal?: number | null;
  /** 是否计费 */
  isBillable?: string | null;
  /** 是否计费 */
  isBillableText?: string | null;
  /** 报销单编号 */
  expenserportId?: string | null;
  /** 是否已开票 */
  isInvoiced?: string | null;
  /** 是否已开票 */
  isInvoicedText?: string | null;
  /** 商户名称 */
  merchant?: string | null;
  /** 案件编号 */
  caseId?: string | null;
  /** 费用报销人 */
  employeeId?: string | null;
  /** 费用描述 */
  narrative?: string | null;
  /** 发票/收据类型 */
  receiptType?: string | null;
  /** 流水编号 */
  serialId?: string | null;
  /** 原系统主键编号 */
  originalId?: string | null;
  /** 流程数据大状态 */
  status?: string | null;
  /**
   * 提交时间
   * @format date-time
   */
  submittedOn?: string | null;
  /** 工作流状态 */
  flowState?: string | null;
  /** 申请人 */
  applicant?: string | null;
  /**
   * 工作流结束时间
   * @format date-time
   */
  flowEndDateTime?: string | null;
  /** 是否超期 */
  isOverdue?: boolean | null;
  /** 备注 */
  remark?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface GetFinancialExpenseApplyOutputListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: GetFinancialExpenseApplyOutput[] | null;
}

/** 通用分页信息类 */
export interface GetFinancialExpenseApplyOutputPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: GetFinancialExpenseApplyOutput[] | null;
}

/** 通用返回信息类 */
export interface GetFinancialExpenseApplyOutputPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: GetFinancialExpenseApplyOutputPageModel;
}

/** 费用明细表 */
export interface GetFinancialExpenseItemForEditOutput {
  /** 费用申请编号 */
  applyId?: string | null;
  /** 费用类型 */
  category?: string | null;
  /** 费用类型子类 */
  subCategory?: string | null;
  /** 账单编号 */
  billingId?: string | null;
  /** 是否入账 */
  isBillable?: string | null;
  /**
   * 金额
   * @format double
   */
  amount?: number | null;
  /** 币种 */
  currency?: string | null;
  /** 描述 */
  narrative?: string | null;
  /** 类型 */
  expenseType?: string | null;
  /** 商户名称 */
  merchant?: string | null;
  /** 案件编号 */
  caseId?: string | null;
  /** 客户编号 */
  clientId?: string | null;
  /** 收费代码 */
  chargeCode?: string | null;
  /** 费用详细描述 */
  expenseNarrative?: string | null;
  /** 报销单ID */
  expenseReportId?: string | null;
  /** 是否已开票 */
  isInvoiced?: string | null;
  /** 是否为POBO */
  isPobo?: string | null;
  /**
   * 支付时间
   * @format date-time
   */
  payDate?: string | null;
  /**
   * 税率
   * @format double
   */
  taxRate?: number | null;
  /**
   * 税额
   * @format double
   */
  taxAmount?: number | null;
  /**
   * 小计金额
   * @format double
   */
  subtotal?: number | null;
  /** 发票编号 */
  invoiceId?: string | null;
  /** 发票类型 */
  invoiceType?: string | null;
  /** 结算币种 */
  settlementCurrency?: string | null;
  /**
   * 结算汇率
   * @format double
   */
  settlementRate?: number | null;
  /**
   * 结算金额
   * @format double
   */
  settlementAmount?: number | null;
  /** 是否销账 */
  isWriteOff?: string | null;
  /** 销账类型 */
  writeOffType?: string | null;
  /** 销账理由 */
  writeOffRemark?: string | null;
  /**
   * 数量
   * @format double
   */
  count?: number | null;
  /** 参与人员 */
  attendees?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 表格数据，支持分页 */
export interface GetFinancialExpenseItemForEditOutputForEditModel {
  /** 费用明细表 */
  data?: GetFinancialExpenseItemForEditOutput;
  /** 实体映射 */
  mapping?: Record<string, any>;
}

/** 通用返回信息类 */
export interface GetFinancialExpenseItemForEditOutputForEditModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 表格数据，支持分页 */
  response?: GetFinancialExpenseItemForEditOutputForEditModel;
}

/** 费用明细表 */
export interface GetFinancialExpenseItemInput {
  /** 费用申请编号 */
  applyId?: string | null;
  /** 费用报销人 */
  employeeId?: string | null;
  /** 日期时间范围均使用 */
  expenseDate?: RangeDateTimeInput;
  /** 费用类型 */
  category?: string | null;
  /** 费用类型子类 */
  subCategory?: string | null;
  /** 账单编号 */
  billingId?: string | null;
  /** 是否入账 */
  isBillable?: string | null;
  /**
   * 金额
   * @format double
   */
  amount?: number | null;
  /** 币种 */
  currency?: string | null;
  /** 描述 */
  narrative?: string | null;
  /** 类型 */
  expenseType?: string | null;
  /** 商户名称 */
  merchant?: string | null;
  /** 案件编号 */
  caseId?: string | null;
  /** 客户编号 */
  clientId?: string | null;
  /** 收费代码 */
  chargeCode?: string | null;
  /** 费用详细描述 */
  expenseNarrative?: string | null;
  /** 报销单ID */
  expenseReportId?: string | null;
  /** 是否已开票 */
  isInvoiced?: string | null;
  /** 是否为POBO */
  isPobo?: string | null;
  /** 日期时间范围均使用 */
  payDate?: RangeDateTimeInput;
  /**
   * 税率
   * @format double
   */
  taxRate?: number | null;
  /**
   * 税额
   * @format double
   */
  taxAmount?: number | null;
  /**
   * 小计金额
   * @format double
   */
  subtotal?: number | null;
  /** 发票编号 */
  invoiceId?: string | null;
  /** 发票类型 */
  invoiceType?: string | null;
  /** 结算币种 */
  settlementCurrency?: string | null;
  /**
   * 结算汇率
   * @format double
   */
  settlementRate?: number | null;
  /**
   * 结算金额
   * @format double
   */
  settlementAmount?: number | null;
  /** 是否销账 */
  isWriteOff?: string | null;
  /** 销账类型 */
  writeOffType?: string | null;
  /** 销账理由 */
  writeOffRemark?: string | null;
  /**
   * 数量
   * @format double
   */
  count?: number | null;
  /** 参与人员 */
  attendees?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 导出功能输入的参数 */
export interface GetFinancialExpenseItemInputExportInput {
  selectColumns?: SetColumn[] | null;
  /** 费用明细表 */
  search?: GetFinancialExpenseItemInput;
  fileName?: string | null;
  extended?: string | null;
  /** 选择Id */
  checkedIds?: string[] | null;
  /**
   * 导出类型
   * @format int32
   */
  exportType?: 0 | 1;
}

/** 费用明细表 */
export interface GetFinancialExpenseItemOutput {
  /** 费用申请编号 */
  applyId?: string | null;
  /** 费用报销人 */
  employeeId?: string | null;
  /** 费用报销人名称 */
  employeeName?: string | null;
  /**
   * 费用日期
   * @format date-time
   */
  expenseDate?: string | null;
  /** 费用类型 */
  category?: string | null;
  /** 费用类型 */
  categoryText?: string | null;
  /** 费用类型子类 */
  subCategory?: string | null;
  /** 账单编号 */
  billingId?: string | null;
  /** 是否入账 */
  isBillable?: string | null;
  /** 是否入账 */
  isBillableText?: string | null;
  /**
   * 金额
   * @format double
   */
  amount?: number | null;
  /** 币种 */
  currency?: string | null;
  /** 币种 */
  currencyText?: string | null;
  /** 描述 */
  narrative?: string | null;
  /** 类型 */
  expenseType?: string | null;
  /** 类型 */
  expenseTypeText?: string | null;
  /** 商户名称 */
  merchant?: string | null;
  /** 案件编号 */
  caseId?: string | null;
  /** 客户编号 */
  clientId?: string | null;
  /** 收费代码 */
  chargeCode?: string | null;
  /** 费用详细描述 */
  expenseNarrative?: string | null;
  /** 报销单ID */
  expenseReportId?: string | null;
  /** 是否已开票 */
  isInvoiced?: string | null;
  /** 是否已开票 */
  isInvoicedText?: string | null;
  /** 是否为POBO */
  isPobo?: string | null;
  /** 是否为POBO */
  isPoboText?: string | null;
  /**
   * 支付时间
   * @format date-time
   */
  payDate?: string | null;
  /**
   * 税率
   * @format double
   */
  taxRate?: number | null;
  /**
   * 税额
   * @format double
   */
  taxAmount?: number | null;
  /**
   * 小计金额
   * @format double
   */
  subtotal?: number | null;
  /** 发票编号 */
  invoiceId?: string | null;
  /** 发票类型 */
  invoiceType?: string | null;
  /** 发票类型 */
  invoiceTypeText?: string | null;
  /** 结算币种 */
  settlementCurrency?: string | null;
  /** 结算币种 */
  settlementCurrencyText?: string | null;
  /**
   * 结算汇率
   * @format double
   */
  settlementRate?: number | null;
  /**
   * 结算金额
   * @format double
   */
  settlementAmount?: number | null;
  /** 是否销账 */
  isWriteOff?: string | null;
  /** 是否销账 */
  isWriteOffText?: string | null;
  /** 销账类型 */
  writeOffType?: string | null;
  /** 销账类型 */
  writeOffTypeText?: string | null;
  /** 销账理由 */
  writeOffRemark?: string | null;
  /**
   * 数量
   * @format double
   */
  count?: number | null;
  /** 参与人员 */
  attendees?: string | null;
  /** 备注 */
  remark?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface GetFinancialExpenseItemOutputListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: GetFinancialExpenseItemOutput[] | null;
}

/** 通用分页信息类 */
export interface GetFinancialExpenseItemOutputPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: GetFinancialExpenseItemOutput[] | null;
}

/** 通用返回信息类 */
export interface GetFinancialExpenseItemOutputPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: GetFinancialExpenseItemOutputPageModel;
}

/** 发票分配信息表 */
export interface GetFinancialInvoiceAllocationInput {
  /** 发票编号 */
  invoiceId?: string | null;
  /** 账单编号 */
  billingId?: string | null;
  /** 发票申请编号 */
  invoiceApplyId?: string | null;
  /** 案件编号 */
  caseId?: string | null;
  /**
   * 分配比例
   * @format double
   */
  allocationRate?: number | null;
  /**
   * 分配金额
   * @format double
   */
  allocationAmount?: number | null;
  /**
   * 尾差
   * @format double
   */
  roundingDifference?: number | null;
  /** 分配策略 */
  allocationStrattegy?: string | null;
  /** 分配人 */
  employeeId?: string | null;
  /** 是否保密 */
  ifConfidential?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 导出功能输入的参数 */
export interface GetFinancialInvoiceAllocationInputExportInput {
  selectColumns?: SetColumn[] | null;
  /** 发票分配信息表 */
  search?: GetFinancialInvoiceAllocationInput;
  fileName?: string | null;
  extended?: string | null;
  /** 选择Id */
  checkedIds?: string[] | null;
  /**
   * 导出类型
   * @format int32
   */
  exportType?: 0 | 1;
}

/** 发票申请表 */
export interface GetFinancialInvoiceApplyInput {
  /** 关键字 */
  filter?: string | null;
  /** 发票编号 */
  invoiceNo?: string | null;
  /** 发票类型 */
  invoiceType?: string[] | null;
  /** 发票抬头 */
  invoiceHeader?: string | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 导出功能输入的参数 */
export interface GetFinancialInvoiceApplyInputExportInput {
  selectColumns?: SetColumn[] | null;
  /** 发票申请表 */
  search?: GetFinancialInvoiceApplyInput;
  fileName?: string | null;
  extended?: string | null;
  /** 选择Id */
  checkedIds?: string[] | null;
  /**
   * 导出类型
   * @format int32
   */
  exportType?: 0 | 1;
}

export interface GetFinancialInvoiceApplyManageInput {
  /** 年选择类型 */
  yearType?: string | null;
  /**
   * 年份
   * @format int32
   */
  year?: number;
  /**
   * 月份
   * @format int32
   */
  month?: number;
  /** 不显示已作废/红冲发票 */
  isNotShowInvalidatedAndRedPunchInvoice?: string | null;
  /** 关键字 */
  filter?: string | null;
  /** 发票编号 */
  invoiceNo?: string | null;
  /** 发票抬头 */
  invoiceHeader?: string | null;
  /** 到账状态 */
  arrivedAmountState?: string | null;
  /** 案件编号 */
  caseId?: string[] | null;
  /** 账单编号 */
  billingNo?: string | null;
  /** 日期时间范围均使用 */
  applyDate?: RangeDateTimeInput;
  /** 数值范围选择器 */
  invoiceAmount?: RangeDecimalInput;
  /** 开票办公室 */
  invoiceOfficeId?: string[] | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 导出功能输入的参数 */
export interface GetFinancialInvoiceApplyManageInputExportInput {
  selectColumns?: SetColumn[] | null;
  search?: GetFinancialInvoiceApplyManageInput;
  fileName?: string | null;
  extended?: string | null;
  /** 选择Id */
  checkedIds?: string[] | null;
  /**
   * 导出类型
   * @format int32
   */
  exportType?: 0 | 1;
}

/** 发票申请表 */
export interface GetFinancialInvoiceApplyOutput {
  /** 案件编号 */
  caseId?: string | null;
  /** 合伙人 */
  employeeId?: string | null;
  /** 账单编号 */
  billingId?: string | null;
  /** 是否预开票 */
  isPreInvoice?: string | null;
  /** 是否预开票 */
  isPreInvoiceText?: string | null;
  /** 发票编号 */
  invoiceNo?: string | null;
  /** 合同编号 */
  contractId?: string | null;
  /** 是否发票抬头与委托方不一致 */
  isInvoiceHeaderNotMatchPrincipalText?: string | null;
  /** 发票抬头说明附件 */
  invoiceHeaderAttachmentId?: string | null;
  /** 手机号码 */
  phoneNumber?: string | null;
  /** 企业注册地址 */
  registeredAddress?: string | null;
  /** 币种 */
  currency?: string | null;
  /** 币种 */
  currencyText?: string | null;
  /**
   * 币种汇率
   * @format double
   */
  exchangeRate?: number;
  /**
   * 外币结算金额
   * @format double
   */
  settlementAmount?: number;
  /** 发票电子凭据 */
  invoicePicture?: string | null;
  /** 是否电子发票 */
  isElectronicInvoice?: string | null;
  /** 是否电子发票 */
  isElectronicInvoiceText?: string | null;
  /** 是否代收代付 */
  isPoco?: string | null;
  /** 是否代收代付 */
  isPocoText?: string | null;
  /** 代收代付名称 */
  pocoName?: string | null;
  /** 是否官费 */
  isOfficialFee?: string | null;
  /** 是否官费 */
  isOfficialFeeText?: string | null;
  /**
   * 税额
   * @format double
   */
  taxAmount?: number;
  /**
   * 不含税金额
   * @format double
   */
  preTaxAmount?: number;
  /** 是否已上传双签协议 */
  isDualSignedAgreement?: string | null;
  /** 是否已上传双签协议 */
  isDualSignedAgreementText?: string | null;
  /** 协议或补充协议附件 */
  agreementAttachmentId?: string | null;
  /** 发票编号 */
  serialId?: string | null;
  /** 原系统主键编号 */
  originalId?: string | null;
  /**
   * 提交时间
   * @format date-time
   */
  submittedOn?: string | null;
  /** 申请人 */
  applicant?: string | null;
  /**
   * 工作流结束时间
   * @format date-time
   */
  flowEndDateTime?: string | null;
  /** 是否超期 */
  isOverdue?: boolean | null;
  /**
   * 申请时间
   * @format date-time
   */
  applyDate?: string | null;
  /** 发票类型 */
  invoiceType?: string | null;
  /** 发票类型 */
  invoiceTypeText?: string | null;
  /** 客户Id */
  clientId?: string | null;
  /** 客户名称 */
  clientName?: string | null;
  /**
   * 税率
   * @format double
   */
  taxRate?: number;
  /**
   * 开票日期
   * @format date-time
   */
  invoiceDate?: string | null;
  /**
   * 开票金额
   * @format double
   */
  invoiceAmount?: number;
  /** 发票内容 */
  invoiceContent?: string | null;
  /** 发票内容 */
  invoiceContentText?: string | null;
  /** 发票列表 */
  invoiceList?: GetFinancialInvoiceOutput[] | null;
  /** 发票抬头Id */
  invoiceTitleId?: string | null;
  /** 发票抬头 */
  invoiceHeader?: string | null;
  /** 开票方类型 */
  invoiceIssuerType?: string | null;
  /** 开票方类型 */
  invoiceIssuerTypeText?: string | null;
  /** 纳税人识别号 */
  taxNumber?: string | null;
  /** 开户行 */
  bankName?: string | null;
  /** 银行账号 */
  bankAccount?: string | null;
  /** 联系电话 */
  landline?: string | null;
  /** 是否发票抬头与委托方不一致 */
  isInvoiceHeaderNotMatchPrincipal?: string | null;
  /** 邮箱地址 */
  emailAddress?: string | null;
  /** 地址 */
  address?: string | null;
  /** 发票抬头补充协议 */
  invoiceHeaderAttachmentList?: AttachmentDownloadDto[] | null;
  /** 协议或补充协议附件 */
  agreementAttachmentList?: AttachmentDownloadDto[] | null;
  /** 其他附件 */
  otherAttachmentList?: AttachmentDownloadDto[] | null;
  /** 红冲状态 */
  redPunchState?: string | null;
  /** 红冲状态 */
  redPunchStateText?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface GetFinancialInvoiceApplyOutputMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 发票申请表 */
  response?: GetFinancialInvoiceApplyOutput;
}

export interface GetFinancialInvoiceAttachmentOutput {
  /** 发票Id */
  id?: string | null;
  /** 发票附件 */
  attachmentId?: string | null;
  /** 发票编号 */
  invoiceNo?: string | null;
  /** 发票附件 */
  invoiceAttachmentList?: AttachmentDownloadDto[] | null;
}

/** 发票作废表 */
export interface GetFinancialInvoiceInvalidateForEditOutput {
  /** 案件编号 */
  caseId?: string | null;
  /** 合伙人 */
  employeeId?: string | null;
  /** 客户编号 */
  clientId?: string | null;
  /** 账单编号 */
  billingId?: string | null;
  /** 发票申请编号 */
  invoiceApplyId?: string | null;
  /** 是否预开票 */
  isPreInvoice?: string | null;
  /** 发票编号 */
  invoiceNo?: string | null;
  /** 合同编号 */
  contractId?: string | null;
  /** 发票类型 */
  invoiceType?: string | null;
  /** 发票抬头 */
  invoiceHeader?: string | null;
  /** 是否发票抬头与委托方不一致 */
  isInvoiceHeaderNotMatchPrincipal?: string | null;
  /** 发票抬头说明附件 */
  invoiceHeaderAttachmentId?: string | null;
  /** 开票方类型 */
  invoiceIssuerType?: string | null;
  /** 纳税人识别号 */
  taxNumber?: string | null;
  /** 开户行 */
  bankName?: string | null;
  /** 银行账号 */
  bankAccount?: string | null;
  /** 联系电话 */
  landline?: string | null;
  /** 邮箱地址 */
  emailAddress?: string | null;
  /** 手机号码 */
  phoneNumber?: string | null;
  /** 地址 */
  address?: string | null;
  /** 企业注册地址 */
  registeredAddress?: string | null;
  /**
   * 开票日期
   * @format date-time
   */
  invoiceDate?: string | null;
  /** 币种 */
  currency?: string | null;
  /**
   * 币种汇率
   * @format double
   */
  exchangeRate?: number;
  /** 发票内容 */
  invoiceContent?: string | null;
  /**
   * 外币结算金额
   * @format double
   */
  settlementAmount?: number;
  /** 发票电子凭据 */
  invoicePicture?: string | null;
  /** 是否电子发票 */
  isElectronicInvoice?: string | null;
  /** 是否代收代付 */
  isPoco?: string | null;
  /** 代收代付名称 */
  pocoName?: string | null;
  /** 是否官费 */
  isOfficialFee?: string | null;
  /**
   * 税率
   * @format double
   */
  taxRate?: number;
  /**
   * 税额
   * @format double
   */
  taxAmount?: number;
  /**
   * 不含税金额
   * @format double
   */
  preTaxAmount?: number;
  /**
   * 开票金额
   * @format double
   */
  invoiceAmount?: number;
  /** 是否红字发票 */
  isRedPunch?: string | null;
  /** 红字发票编号 */
  redPunchInvoiceId?: string | null;
  /** 收款编号 */
  receiptId?: string | null;
  /**
   * 收款金额
   * @format double
   */
  receiptAmount?: number;
  /** 描述 */
  narrative?: string | null;
  /** 发票附件 */
  attachmentId?: string | null;
  /** 到账状态 */
  arrivedAmountState?: string | null;
  /** 作废原因 */
  reason?: string | null;
  /** 作废退回原因 */
  rejectReason?: string | null;
  /** 流水编号 */
  serialId?: string | null;
  /** 原系统主键编号 */
  originalId?: string | null;
  /** 流程数据大状态 */
  status?: string | null;
  /**
   * 提交时间
   * @format date-time
   */
  submittedOn?: string | null;
  /** 工作流状态 */
  flowState?: string | null;
  /** 申请人 */
  applicant?: string | null;
  /**
   * 工作流结束时间
   * @format date-time
   */
  flowEndDateTime?: string | null;
  /** 是否超期 */
  isOverdue?: boolean | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 表格数据，支持分页 */
export interface GetFinancialInvoiceInvalidateForEditOutputForEditModel {
  /** 发票作废表 */
  data?: GetFinancialInvoiceInvalidateForEditOutput;
  /** 实体映射 */
  mapping?: Record<string, any>;
}

/** 通用返回信息类 */
export interface GetFinancialInvoiceInvalidateForEditOutputForEditModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 表格数据，支持分页 */
  response?: GetFinancialInvoiceInvalidateForEditOutputForEditModel;
}

/** 发票作废表 */
export interface GetFinancialInvoiceInvalidateInput {
  /** 案件编号 */
  caseId?: string | null;
  /** 客户号/客户名称 */
  clientId?: string[] | null;
  /** 发票编号 */
  invoiceNo?: string | null;
  /** 发票抬头 */
  invoiceHeader?: string | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 导出功能输入的参数 */
export interface GetFinancialInvoiceInvalidateInputExportInput {
  selectColumns?: SetColumn[] | null;
  /** 发票作废表 */
  search?: GetFinancialInvoiceInvalidateInput;
  fileName?: string | null;
  extended?: string | null;
  /** 选择Id */
  checkedIds?: string[] | null;
  /**
   * 导出类型
   * @format int32
   */
  exportType?: 0 | 1;
}

/** 发票作废表 */
export interface GetFinancialInvoiceInvalidateOutput {
  /** 发票内容集合 */
  invoiceContentList?: GetBillingCaseInfoOutput[] | null;
  /** 开票金额中文 */
  invoiceAmountChineseNumeral?: string | null;
  /** 发票附件 */
  invoiceAttachmentList?: AttachmentDownloadDto[] | null;
  /** 客户名称 */
  clientName?: string | null;
  /** 客户英文名称 */
  clientForeignName?: string | null;
  /**
   * 开票申请时间
   * @format date-time
   */
  applyDate?: string | null;
  /** 发票Id */
  invoiceId?: string | null;
  /** 案件编号 */
  caseId?: string | null;
  /** 合伙人 */
  employeeId?: string | null;
  /** 客户编号 */
  clientId?: string | null;
  /** 账单编号 */
  billingId?: string | null;
  /** 发票申请编号 */
  invoiceApplyId?: string | null;
  /** 是否预开票 */
  isPreInvoice?: string | null;
  /** 是否预开票 */
  isPreInvoiceText?: string | null;
  /** 发票编号 */
  invoiceNo?: string | null;
  /** 合同编号 */
  contractId?: string | null;
  /** 发票类型 */
  invoiceType?: string | null;
  /** 发票类型 */
  invoiceTypeText?: string | null;
  /** 发票抬头 */
  invoiceHeader?: string | null;
  /** 是否发票抬头与委托方不一致 */
  isInvoiceHeaderNotMatchPrincipal?: string | null;
  /** 是否发票抬头与委托方不一致 */
  isInvoiceHeaderNotMatchPrincipalText?: string | null;
  /** 发票抬头说明附件 */
  invoiceHeaderAttachmentId?: string | null;
  /** 开票方类型 */
  invoiceIssuerType?: string | null;
  /** 开票方类型 */
  invoiceIssuerTypeText?: string | null;
  /** 纳税人识别号 */
  taxNumber?: string | null;
  /** 开户行 */
  bankName?: string | null;
  /** 银行账号 */
  bankAccount?: string | null;
  /** 联系电话 */
  landline?: string | null;
  /** 邮箱地址 */
  emailAddress?: string | null;
  /** 手机号码 */
  phoneNumber?: string | null;
  /** 地址 */
  address?: string | null;
  /** 企业注册地址 */
  registeredAddress?: string | null;
  /**
   * 开票日期
   * @format date-time
   */
  invoiceDate?: string | null;
  /** 币种 */
  currency?: string | null;
  /** 币种 */
  currencyText?: string | null;
  /**
   * 币种汇率
   * @format double
   */
  exchangeRate?: number;
  /** 发票内容 */
  invoiceContent?: string | null;
  /** 发票内容 */
  invoiceContentText?: string | null;
  /**
   * 外币结算金额
   * @format double
   */
  settlementAmount?: number;
  /** 发票电子凭据 */
  invoicePicture?: string | null;
  /** 是否电子发票 */
  isElectronicInvoice?: string | null;
  /** 是否电子发票 */
  isElectronicInvoiceText?: string | null;
  /** 是否代收代付 */
  isPoco?: string | null;
  /** 是否代收代付 */
  isPocoText?: string | null;
  /** 代收代付名称 */
  pocoName?: string | null;
  /** 是否官费 */
  isOfficialFee?: string | null;
  /** 是否官费 */
  isOfficialFeeText?: string | null;
  /**
   * 税率
   * @format double
   */
  taxRate?: number;
  /**
   * 税额
   * @format double
   */
  taxAmount?: number;
  /**
   * 不含税金额
   * @format double
   */
  preTaxAmount?: number;
  /**
   * 开票金额
   * @format double
   */
  invoiceAmount?: number;
  /** 是否红字发票 */
  isRedPunch?: string | null;
  /** 是否红字发票 */
  isRedPunchText?: string | null;
  /** 红字发票编号 */
  redPunchInvoiceId?: string | null;
  /** 收款编号 */
  receiptId?: string | null;
  /**
   * 收款金额
   * @format double
   */
  receiptAmount?: number;
  /** 描述 */
  narrative?: string | null;
  /** 发票附件 */
  attachmentId?: string | null;
  /** 到账状态 */
  arrivedAmountState?: string | null;
  /** 作废原因 */
  reason?: string | null;
  /** 作废退回原因 */
  rejectReason?: string | null;
  /** 发票编号 */
  serialId?: string | null;
  /** 原系统主键编号 */
  originalId?: string | null;
  /** 流程数据大状态 */
  status?: string | null;
  /**
   * 提交时间
   * @format date-time
   */
  submittedOn?: string | null;
  /** 工作流状态 */
  flowState?: string | null;
  /** 申请人 */
  applicant?: string | null;
  /**
   * 工作流结束时间
   * @format date-time
   */
  flowEndDateTime?: string | null;
  /** 是否超期 */
  isOverdue?: boolean | null;
  /** 备注 */
  remark?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface GetFinancialInvoiceInvalidateOutputListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: GetFinancialInvoiceInvalidateOutput[] | null;
}

/** 通用返回信息类 */
export interface GetFinancialInvoiceInvalidateOutputMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 发票作废表 */
  response?: GetFinancialInvoiceInvalidateOutput;
}

/** 通用分页信息类 */
export interface GetFinancialInvoiceInvalidateOutputPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: GetFinancialInvoiceInvalidateOutput[] | null;
}

/** 通用返回信息类 */
export interface GetFinancialInvoiceInvalidateOutputPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: GetFinancialInvoiceInvalidateOutputPageModel;
}

/** 发票信息表 */
export interface GetFinancialInvoiceOutput {
  /** 发票内容集合 */
  invoiceContentList?: GetBillingCaseInfoOutput[] | null;
  /** 开票金额中文 */
  invoiceAmountChineseNumeral?: string | null;
  /** 发票附件 */
  invoiceAttachmentList?: AttachmentDownloadDto[] | null;
  /** 客户名称 */
  clientName?: string | null;
  /** 客户英文名称 */
  clientForeignName?: string | null;
  /**
   * 红字时间
   * @format date-time
   */
  applyRedPunchedTime?: string;
  /**
   * 蓝字时间
   * @format date-time
   */
  applyInvoicedTime?: string;
  /** 红字发票号码 */
  redPunchedInvoiceNo?: string | null;
  /** 蓝字发票号码 */
  invoicedNo?: string | null;
  /**
   * 红字金额
   * @format double
   */
  redPunchedAmount?: number;
  /**
   * 蓝字金额
   * @format double
   */
  invoicedAmount?: number;
  /** 红字原因 */
  redPunchedReason?: string | null;
  /** 账单编号 */
  billingNo?: string | null;
  /** 案件编号 */
  caseNo?: string | null;
  /** 案件名称 */
  caseName?: string | null;
  /** 精简发票类型 */
  simplifyInvoiceType?: string | null;
  /** 案件编号 */
  caseId?: string | null;
  /** 合伙人 */
  employeeId?: string | null;
  /** 客户编号 */
  clientId?: string | null;
  /** 账单Id */
  billingId?: string | null;
  /** 发票申请编号 */
  invoiceApplyId?: string | null;
  /** 是否预开票 */
  isPreInvoice?: string | null;
  /** 是否预开票 */
  isPreInvoiceText?: string | null;
  /** 发票编号 */
  invoiceNo?: string | null;
  /** 合同编号 */
  contractId?: string | null;
  /** 发票类型 */
  invoiceType?: string | null;
  /** 发票类型 */
  invoiceTypeText?: string | null;
  /** 发票抬头 */
  invoiceHeader?: string | null;
  /** 是否发票抬头与委托方不一致 */
  isInvoiceHeaderNotMatchPrincipal?: string | null;
  /** 是否发票抬头与委托方不一致 */
  isInvoiceHeaderNotMatchPrincipalText?: string | null;
  /** 发票抬头说明附件 */
  invoiceHeaderAttachmentId?: string | null;
  /** 开票方类型 */
  invoiceIssuerType?: string | null;
  /** 开票方类型 */
  invoiceIssuerTypeText?: string | null;
  /** 纳税人识别号 */
  taxNumber?: string | null;
  /** 开户行 */
  bankName?: string | null;
  /** 银行账号 */
  bankAccount?: string | null;
  /** 联系电话 */
  landline?: string | null;
  /** 邮箱地址 */
  emailAddress?: string | null;
  /** 手机号码 */
  phoneNumber?: string | null;
  /** 地址 */
  address?: string | null;
  /** 企业注册地址 */
  registeredAddress?: string | null;
  /**
   * 开票日期
   * @format date-time
   */
  invoiceDate?: string | null;
  /** 币种 */
  currency?: string | null;
  /** 币种 */
  currencyText?: string | null;
  /**
   * 币种汇率
   * @format double
   */
  exchangeRate?: number;
  /** 发票内容 */
  invoiceContent?: string | null;
  /** 发票类型 */
  invoiceContentText?: string | null;
  /**
   * 外币结算金额
   * @format double
   */
  settlementAmount?: number;
  /** 发票电子凭据 */
  invoicePicture?: string | null;
  /** 是否电子发票 */
  isElectronicInvoice?: string | null;
  /** 是否电子发票 */
  isElectronicInvoiceText?: string | null;
  /** 是否代收代付 */
  isPoco?: string | null;
  /** 是否代收代付 */
  isPocoText?: string | null;
  /** 代收代付名称 */
  pocoName?: string | null;
  /** 是否官费 */
  isOfficialFee?: string | null;
  /** 是否官费 */
  isOfficialFeeText?: string | null;
  /**
   * 税率
   * @format double
   */
  taxRate?: number;
  /**
   * 税额
   * @format double
   */
  taxAmount?: number;
  /**
   * 不含税金额
   * @format double
   */
  preTaxAmount?: number;
  /**
   * 开票金额
   * @format double
   */
  invoiceAmount?: number;
  /** 是否红字发票 */
  isRedPunch?: string | null;
  /** 是否红字发票 */
  isRedPunchText?: string | null;
  /** 红字发票编号 */
  redPunchInvoiceId?: string | null;
  /** 发票附件编号 */
  attachmentId?: string | null;
  /** 收款编号 */
  receiptId?: string | null;
  /**
   * 收款金额
   * @format double
   */
  receiptAmount?: number;
  /** 描述 */
  narrative?: string | null;
  /** 到账状态 */
  arrivedAmountState?: string | null;
  /** 作废原因 */
  invalidateReason?: string | null;
  /** 作废退回原因 */
  invalidateBackReason?: string | null;
  /**
   * 作废申请时间
   * @format date-time
   */
  invalidateApplyDate?: string | null;
  /** 发票编号 */
  serialId?: string | null;
  /** 原系统主键编号 */
  originalId?: string | null;
  /**
   * 提交时间
   * @format date-time
   */
  submittedOn?: string | null;
  /** 申请人 */
  applicant?: string | null;
  /** 申请合伙人 */
  applicantName?: string | null;
  /**
   * 工作流结束时间
   * @format date-time
   */
  flowEndDateTime?: string | null;
  /** 是否超期 */
  isOverdue?: boolean | null;
  /** 红冲状态 */
  redPunchState?: string | null;
  /** 红冲状态 */
  redPunchStateText?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface GetFinancialInvoiceOutputMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 发票信息表 */
  response?: GetFinancialInvoiceOutput;
}

/** 通用分页信息类 */
export interface GetFinancialInvoiceOutputPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: GetFinancialInvoiceOutput[] | null;
}

/** 通用返回信息类 */
export interface GetFinancialInvoiceOutputPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: GetFinancialInvoiceOutputPageModel;
}

export interface GetFinancialInvoiceRedPunchInput {
  /** 发票抬头 */
  invoiceHeader?: string[] | null;
  /** 发票类型 */
  invoiceType?: string[] | null;
  /** 开票办公室 */
  officeId?: string[] | null;
  /** 日期时间范围均使用 */
  applyRedPunchedTime?: RangeDateTimeInput;
  /** 数值范围选择器 */
  invoiceAmount?: RangeDecimalInput;
  /** 案件编号 */
  caseId?: string[] | null;
  /** 账单编号 */
  billingId?: string[] | null;
  /** 蓝字发票号码 */
  invoicedNo?: string | null;
  /** 红字发票号码 */
  redPunchInvoiceNo?: string | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 付款方表 */
export interface GetFinancialPayerInfoInput {
  /** 付款方名称 */
  name?: string | null;
  /** 外文名称 */
  foreignName?: string | null;
  /** 曾用名 */
  formerName?: string | null;
  /** 别名 */
  altName?: string | null;
  /** 证件类型 */
  creditType?: string | null;
  /** 证件号码 */
  creditCode?: string | null;
  /** 税号 */
  taxNumber?: string | null;
  /** 邮箱地址 */
  emailAddress?: string | null;
  /** 联系电话 */
  landline?: string | null;
  /** 所属行业 */
  industry?: string | null;
  /** 区域城市 */
  area?: string | null;
  /** 注册名称 */
  regName?: string | null;
  /** 注册号 */
  regNo?: string | null;
  /** 银行名称 */
  bankName?: string | null;
  /** 银行账号 */
  bankAccount?: string | null;
  /** 开户行 */
  openingBranchName?: string | null;
  /** 开户行地址 */
  openingBranchAddress?: string | null;
  /** 银行联系电话 */
  bankLandline?: string | null;
  /** 所属客户编号 */
  clientId?: string | null;
  /** 客户性质 */
  customerNature?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 导出功能输入的参数 */
export interface GetFinancialPayerInfoInputExportInput {
  selectColumns?: SetColumn[] | null;
  /** 付款方表 */
  search?: GetFinancialPayerInfoInput;
  fileName?: string | null;
  extended?: string | null;
  /** 选择Id */
  checkedIds?: string[] | null;
  /**
   * 导出类型
   * @format int32
   */
  exportType?: 0 | 1;
}

/** 公共收款表 */
export interface GetFinancialPublicReceiptForEditOutput {
  /** 案件编号 */
  caseId?: string | null;
  /** 客户编号 */
  clientId?: string | null;
  /** 收款方式 */
  payMode?: string | null;
  /** 付款方名称 */
  payerName?: string | null;
  /** 是否境外汇款 */
  isOverseasPay?: string | null;
  /** 汇款国家 */
  remittanceCountry?: string | null;
  /** 收款币种 */
  currency?: string | null;
  /** 支票编号 */
  checkNo?: string | null;
  /**
   * 收款总额
   * @format double
   */
  payAmount?: number;
  /**
   * 收款日期
   * @format date-time
   */
  receiptDate?: string | null;
  /** 结算币种 */
  settlementCurrency?: string | null;
  /**
   * 结算汇率
   * @format double
   */
  settlementRate?: number;
  /**
   * 结算金额
   * @format double
   */
  settlementAmount?: number;
  /**
   * 已认领收款金额
   * @format double
   */
  receiptAmount?: number;
  /** 是否全额认领 */
  isFullyClaimed?: string | null;
  /**
   * 认领尾差金额
   * @format double
   */
  roundingDifference?: number;
  /** 银行流水号 */
  bankSerialId?: string | null;
  /** 认领人 */
  claimEmployeeId?: string | null;
  /** 附件 */
  attachmentId?: string | null;
  /** 付款说明 */
  paymentExplain?: string | null;
  /** 认领收款编号 */
  receiptId?: string | null;
  /** 账单编号 */
  billingId?: string | null;
  /** 发票编号 */
  invoiceId?: string | null;
  /** 备注 */
  remark?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 收款账号Id */
  bankId?: string | null;
  /** 收款账号 */
  bankAccount?: string | null;
  /** 描述说明 */
  narrative?: string | null;
  /** 收款认领信息 */
  financialReceipts?: GetFinancialReceiptForEditOutput[] | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 表格数据，支持分页 */
export interface GetFinancialPublicReceiptForEditOutputForEditModel {
  /** 公共收款表 */
  data?: GetFinancialPublicReceiptForEditOutput;
  /** 实体映射 */
  mapping?: Record<string, any>;
}

/** 通用返回信息类 */
export interface GetFinancialPublicReceiptForEditOutputForEditModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 表格数据，支持分页 */
  response?: GetFinancialPublicReceiptForEditOutputForEditModel;
}

/** 公共收款表 */
export interface GetFinancialPublicReceiptInput {
  /** 案件编号 */
  caseId?: string | null;
  /** 客户编号 */
  clientId?: string | null;
  /** 收款方式 */
  payMode?: string | null;
  /** 付款方名称 */
  payerName?: string | null;
  /** 是否境外汇款 */
  isOverseasPay?: string | null;
  /** 汇款国家 */
  remittanceCountry?: string | null;
  /** 收款币种 */
  currency?: string | null;
  /** 支票编号 */
  checkNo?: string | null;
  /**
   * 收款总额
   * @format double
   */
  payAmount?: number | null;
  /** 日期时间范围均使用 */
  receiptDate?: RangeDateTimeInput;
  /** 结算币种 */
  settlementCurrency?: string | null;
  /**
   * 结算汇率
   * @format double
   */
  settlementRate?: number | null;
  /**
   * 结算金额
   * @format double
   */
  settlementAmount?: number | null;
  /**
   * 已认领收款金额
   * @format double
   */
  receiptAmount?: number | null;
  /** 是否全额认领 */
  isFullyClaimed?: string | null;
  /**
   * 认领尾差金额
   * @format double
   */
  roundingDifference?: number | null;
  /** 银行流水号 */
  bankSerialId?: string | null;
  /** 认领人 */
  claimEmployeeId?: string | null;
  /** 附件 */
  attachmentId?: string | null;
  /** 付款说明 */
  paymentExplain?: string | null;
  /** 认领收款编号 */
  receiptId?: string | null;
  /** 账单编号 */
  billingId?: string | null;
  /** 发票编号 */
  invoiceId?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 导出功能输入的参数 */
export interface GetFinancialPublicReceiptInputExportInput {
  selectColumns?: SetColumn[] | null;
  /** 公共收款表 */
  search?: GetFinancialPublicReceiptInput;
  fileName?: string | null;
  extended?: string | null;
  /** 选择Id */
  checkedIds?: string[] | null;
  /**
   * 导出类型
   * @format int32
   */
  exportType?: 0 | 1;
}

/** 公共收款表 */
export interface GetFinancialPublicReceiptOutput {
  /** 收款附件信息列表 */
  attachmentList?: AttachmentDownloadDto[] | null;
  /** 收款信息 */
  financialReceipts?: GetFinancialReceiptOutput[] | null;
  /** 案件编号 */
  caseId?: string | null;
  /** 客户编号 */
  clientId?: string | null;
  /** 收款方式 */
  payMode?: string | null;
  /** 收款方式 */
  payModeText?: string | null;
  /** 付款方名称 */
  payerName?: string | null;
  /** 付款方Id */
  paymentId?: string | null;
  /** 是否境外汇款 */
  isOverseasPay?: string | null;
  /** 是否境外汇款 */
  isOverseasPayText?: string | null;
  /** 汇款国家 */
  remittanceCountry?: string | null;
  /** 汇款国家 */
  remittanceCountryText?: string | null;
  /** 收款币种 */
  currency?: string | null;
  /** 收款币种 */
  currencyText?: string | null;
  /** 支票编号 */
  checkNo?: string | null;
  /**
   * 收款总额
   * @format double
   */
  payAmount?: number;
  /**
   * 收款日期
   * @format date-time
   */
  receiptDate?: string | null;
  /** 结算币种 */
  settlementCurrency?: string | null;
  /** 结算币种 */
  settlementCurrencyText?: string | null;
  /**
   * 结算汇率
   * @format double
   */
  settlementRate?: number;
  /**
   * 结算金额
   * @format double
   */
  settlementAmount?: number;
  /**
   * 已认领收款金额
   * @format double
   */
  receiptAmount?: number;
  /** 是否全额认领 */
  isFullyClaimed?: string | null;
  /** 是否全额认领 */
  isFullyClaimedText?: string | null;
  /**
   * 认领尾差金额
   * @format double
   */
  roundingDifference?: number;
  /** 银行流水号 */
  bankSerialId?: string | null;
  /** 认领人 */
  claimEmployeeId?: string | null;
  /** 认领人名称 */
  claimEmployeeName?: string | null;
  /** 附件 */
  attachmentId?: string | null;
  /** 付款说明 */
  paymentExplain?: string | null;
  /** 认领收款编号 */
  receiptId?: string | null;
  /** 账单编号 */
  billingId?: string | null;
  /** 发票编号 */
  invoiceId?: string | null;
  /** 收款账号Id */
  bankId?: string | null;
  /** 收款账号 */
  bankAccount?: string | null;
  bankName?: string | null;
  /** 描述说明 */
  narrative?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface GetFinancialPublicReceiptOutputListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: GetFinancialPublicReceiptOutput[] | null;
}

/** 通用返回信息类 */
export interface GetFinancialPublicReceiptOutputMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 公共收款表 */
  response?: GetFinancialPublicReceiptOutput;
}

/** 通用分页信息类 */
export interface GetFinancialPublicReceiptOutputPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: GetFinancialPublicReceiptOutput[] | null;
}

/** 通用返回信息类 */
export interface GetFinancialPublicReceiptOutputPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: GetFinancialPublicReceiptOutputPageModel;
}

/** 收款信息表 */
export interface GetFinancialReceiptForEditOutput {
  /** 案件Id */
  caseId?: string | null;
  /** 案件编号 */
  caseSerialId?: string | null;
  /** 案件名称 */
  caseName?: string | null;
  /** 客户编号 */
  clientId?: string | null;
  /** 公共收款编号 */
  publicReceiptId?: string | null;
  /** 收款方式 */
  payMode?: string | null;
  /** 付款方名称 */
  payerName?: string | null;
  /** 是否境外汇款 */
  isOverseasPay?: string | null;
  /** 汇款国家 */
  remittanceCountry?: string | null;
  /** 收款币种 */
  currency?: string | null;
  /** 支票编号 */
  checkNo?: string | null;
  /**
   * 收款总额
   * @format double
   */
  payAmount?: number;
  /**
   * 收款日期
   * @format date-time
   */
  receiptDate?: string | null;
  /** 结算币种 */
  settlementCurrency?: string | null;
  /**
   * 结算汇率
   * @format double
   */
  settlementRate?: number;
  /**
   * 结算金额
   * @format double
   */
  settlementAmount?: number;
  /** 银行流水号 */
  bankSerialId?: string | null;
  /** 认领人 */
  claimEmployeeId?: string | null;
  /** 附件 */
  attachmentId?: string | null;
  /** 付款说明 */
  paymentExplain?: string | null;
  /** 认领收款编号 */
  receiptId?: string | null;
  /** 账单Id */
  billingId?: string | null;
  /** 账单编号 */
  billingSerialId?: string | null;
  /** 发票编号 */
  invoiceId?: string | null;
  /** 是否分配 */
  isAllocated?: string | null;
  /**
   * 官费金额
   * @format double
   */
  officialFees?: number;
  /**
   * 律师费金额
   * @format double
   */
  lawyerFee?: number;
  /**
   * 支出费用
   * @format double
   */
  expenseFee?: number;
  /** 流水编号 */
  serialId?: string | null;
  /** 原系统主键编号 */
  originalId?: string | null;
  /** 流程数据大状态 */
  status?: string | null;
  /**
   * 提交时间
   * @format date-time
   */
  submittedOn?: string | null;
  /** 工作流状态 */
  flowState?: string | null;
  /** 申请人 */
  applicant?: string | null;
  /**
   * 工作流结束时间
   * @format date-time
   */
  flowEndDateTime?: string | null;
  /** 是否超期 */
  isOverdue?: boolean | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 尾差金额
   * @format double
   */
  roundingDifference?: number;
  /** 到账原因 */
  receiptReason?: string | null;
  /**
   * 认领金额
   * @format double
   */
  claimAmount?: number;
  /**
   * 代收代付费
   * @format double
   */
  poboSubtotalAmount?: number;
  /** 描述说明 */
  narrative?: string | null;
  /** 案件分配信息 */
  caseDistributions?: CaseDistributionDto[] | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 表格数据，支持分页 */
export interface GetFinancialReceiptForEditOutputForEditModel {
  /** 收款信息表 */
  data?: GetFinancialReceiptForEditOutput;
  /** 实体映射 */
  mapping?: Record<string, any>;
}

/** 通用返回信息类 */
export interface GetFinancialReceiptForEditOutputForEditModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 表格数据，支持分页 */
  response?: GetFinancialReceiptForEditOutputForEditModel;
}

/** 收款信息表 */
export interface GetFinancialReceiptInput {
  /** 关键字 */
  filter?: string | null;
  /** 账单编号 */
  billingSerialId?: string | null;
  /** 付款方名称 */
  payerName?: string | null;
  /** 数值范围选择器 */
  payAmount?: RangeDecimalInput;
  /** 办公室 */
  officeId?: string[] | null;
  /** 日期时间范围均使用 */
  receiptDate?: RangeDateTimeInput;
  /** 状态 */
  flowState?: string[] | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 导出功能输入的参数 */
export interface GetFinancialReceiptInputExportInput {
  selectColumns?: SetColumn[] | null;
  /** 收款信息表 */
  search?: GetFinancialReceiptInput;
  fileName?: string | null;
  extended?: string | null;
  /** 选择Id */
  checkedIds?: string[] | null;
  /**
   * 导出类型
   * @format int32
   */
  exportType?: 0 | 1;
}

/** 收款信息表 */
export interface GetFinancialReceiptOutput {
  /** 账单编号 */
  billingSerialId?: string | null;
  /** 案件编号 */
  caseSerialId?: string | null;
  /** 案件名称 */
  caseName?: string | null;
  /** 发票编号 */
  invoiceSerialId?: string | null;
  /** 案件编号 */
  caseId?: string | null;
  /** 客户编号 */
  clientId?: string | null;
  /** 公共收款编号 */
  publicReceiptId?: string | null;
  /** 收款方式 */
  payMode?: string | null;
  /** 收款方式 */
  payModeText?: string | null;
  /** 付款方名称 */
  payerName?: string | null;
  /** 是否境外汇款 */
  isOverseasPay?: string | null;
  /** 是否境外汇款 */
  isOverseasPayText?: string | null;
  /** 汇款国家 */
  remittanceCountry?: string | null;
  /** 汇款国家 */
  remittanceCountryText?: string | null;
  /** 收款币种 */
  currency?: string | null;
  /** 收款币种 */
  currencyText?: string | null;
  /** 支票编号 */
  checkNo?: string | null;
  /**
   * 收款总额
   * @format double
   */
  payAmount?: number;
  /**
   * 收款日期
   * @format date-time
   */
  receiptDate?: string | null;
  /** 结算币种 */
  settlementCurrency?: string | null;
  /** 结算币种 */
  settlementCurrencyText?: string | null;
  /**
   * 结算汇率
   * @format double
   */
  settlementRate?: number;
  /**
   * 结算金额
   * @format double
   */
  settlementAmount?: number;
  /** 银行流水号 */
  bankSerialId?: string | null;
  /** 认领人 */
  claimEmployeeId?: string | null;
  /** 附件 */
  attachmentId?: string | null;
  /** 付款说明 */
  paymentExplain?: string | null;
  /** 认领收款编号 */
  receiptId?: string | null;
  /** 账单编号 */
  billingId?: string | null;
  /** 发票编号 */
  invoiceId?: string | null;
  /** 是否分配 */
  isAllocated?: string | null;
  /** 是否分配 */
  isAllocatedText?: string | null;
  /**
   * 官费金额
   * @format double
   */
  officialFees?: number;
  /**
   * 律师费金额
   * @format double
   */
  lawyerFee?: number;
  /**
   * 支出费用
   * @format double
   */
  expenseFee?: number;
  /** 流水编号 */
  serialId?: string | null;
  /** 原系统主键编号 */
  originalId?: string | null;
  /** 流程数据大状态 */
  status?: string | null;
  /**
   * 提交时间
   * @format date-time
   */
  submittedOn?: string | null;
  /** 工作流状态 */
  flowState?: string | null;
  /** 申请人 */
  applicant?: string | null;
  /**
   * 工作流结束时间
   * @format date-time
   */
  flowEndDateTime?: string | null;
  /** 是否超期 */
  isOverdue?: boolean | null;
  /**
   * 尾差金额
   * @format double
   */
  roundingDifference?: number;
  /** 到账原因 */
  receiptReason?: string | null;
  /**
   * 认领金额
   * @format double
   */
  claimAmount?: number;
  /**
   * 代收代付费
   * @format double
   */
  poboSubtotalAmount?: number;
  /**
   * 分配金额
   * @format double
   */
  allocatedAmount?: number;
  /** 是否全额到账 */
  isFullyOffset?: string | null;
  /** 是否全额到账 */
  isFullyOffsetText?: string | null;
  /** 是否同步账单汇率 */
  isSyncBillingExchangeRate?: string | null;
  /** 是否同步账单汇率 */
  isSyncBillingExchangeRateText?: string | null;
  /**
   * 税率
   * @format double
   */
  taxRate?: number;
  /**
   * 税费
   * @format double
   */
  taxAmount?: number;
  /** 描述说明 */
  narrative?: string | null;
  /** 账单结算币种 */
  billingCurrency?: string | null;
  /** 账单结算币种 */
  billingCurrencyText?: string | null;
  /**
   * 账单结算汇率
   * @format double
   */
  billingRate?: number;
  /**
   * 账单结算金额
   * @format double
   */
  billingAmount?: number;
  /** 案件分配信息 */
  caseDistributions?: CaseDistributionDto[] | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface GetFinancialReceiptOutputListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: GetFinancialReceiptOutput[] | null;
}

/** 通用分页信息类 */
export interface GetFinancialReceiptOutputPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: GetFinancialReceiptOutput[] | null;
}

/** 通用返回信息类 */
export interface GetFinancialReceiptOutputPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: GetFinancialReceiptOutputPageModel;
}

/** 税率 */
export interface GetFinancialTaxRateInput {
  /**
   * 配置层级
   * @format int32
   */
  settingLevel?: 0 | 1 | 2 | 3 | null;
  /** 税类型 */
  taxType?: string | null;
  /** 国家/地区 */
  region?: string | null;
  /**
   * 税率
   * @format double
   */
  taxRate?: number;
  /** 是否账单 */
  isBilling?: boolean | null;
  /** 账单是否可编辑 */
  isBillingChange?: boolean | null;
  /** 备注 */
  remark?: string | null;
  /** 办公室 */
  officeId?: string[] | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 导出功能输入的参数 */
export interface GetFinancialTaxRateInputExportInput {
  selectColumns?: SetColumn[] | null;
  /** 税率 */
  search?: GetFinancialTaxRateInput;
  fileName?: string | null;
  extended?: string | null;
  /** 选择Id */
  checkedIds?: string[] | null;
  /**
   * 导出类型
   * @format int32
   */
  exportType?: 0 | 1;
}

/** 税率 */
export interface GetFinancialTaxRateOutput {
  /**
   * 配置层级
   * @format int32
   */
  settingLevel?: 0 | 1 | 2 | 3;
  /** 税类型 */
  taxType?: string | null;
  /** 国家/地区 */
  region?: string | null;
  /** 币种 */
  currency?: string | null;
  /** 币种 */
  currencyText?: string | null;
  /**
   * 税率
   * @format double
   */
  taxRate?: number;
  /** 是否账单 */
  isBilling?: boolean;
  /** 账单是否可编辑 */
  isBillingChange?: boolean;
  /** 分所 */
  officeId?: string | null;
  /** 是否启用 */
  isEnabled?: boolean;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface GetFinancialTaxRateOutputListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: GetFinancialTaxRateOutput[] | null;
}

/** 通用返回信息类 */
export interface GetFinancialTaxRateOutputMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 税率 */
  response?: GetFinancialTaxRateOutput;
}

/** 通用分页信息类 */
export interface GetFinancialTaxRateOutputPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: GetFinancialTaxRateOutput[] | null;
}

/** 通用返回信息类 */
export interface GetFinancialTaxRateOutputPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: GetFinancialTaxRateOutputPageModel;
}

export interface GetForeignExchangeRatesCurrencyInput {
  /** 原始币种 */
  base?: string | null;
  /** 目标币种 */
  target?: string | null;
}

/** 外汇汇率表 */
export interface GetForeignExchangeRatesInput {
  /** 本位币 */
  base?: string[] | null;
  /** 目标币种 */
  target?: string[] | null;
  /**
   * 汇率
   * @format double
   */
  rate?: number | null;
  /**
   * 汇率小数位精度
   * @format int32
   */
  scale?: number | null;
  /** 汇率列表 */
  rates?: string | null;
  /** 日期时间范围均使用 */
  updateTime?: RangeDateTimeInput;
  /** 请求地址 */
  url?: string | null;
  /** 是否默认 */
  isDefault?: boolean | null;
  /** 日期时间范围均使用 */
  effectiveDate?: RangeDateTimeInput;
  /** 备注 */
  remark?: string | null;
  /** 是否启用 */
  isEnabled?: boolean | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 导出功能输入的参数 */
export interface GetForeignExchangeRatesInputExportInput {
  selectColumns?: SetColumn[] | null;
  /** 外汇汇率表 */
  search?: GetForeignExchangeRatesInput;
  fileName?: string | null;
  extended?: string | null;
  /** 选择Id */
  checkedIds?: string[] | null;
  /**
   * 导出类型
   * @format int32
   */
  exportType?: 0 | 1;
}

export interface GetInvoiceApplyAttachmentListOutput {
  /** 发票列表 */
  invoiceAttachmentList?: GetFinancialInvoiceAttachmentOutput[] | null;
  /** 发票抬头补充协议 */
  invoiceHeaderAttachmentList?: AttachmentDownloadDto[] | null;
  /** 协议或补充协议附件 */
  agreementAttachmentList?: AttachmentDownloadDto[] | null;
  /** 其他附件 */
  otherAttachmentList?: AttachmentDownloadDto[] | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface GetInvoiceApplyAttachmentListOutputMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  response?: GetInvoiceApplyAttachmentListOutput;
}

export interface GetInvoiceApplyListOutput {
  /** 发票抬头 */
  invoiceHeader?: string | null;
  /** 客户编号 */
  clientId?: string | null;
  /** 客户名称 */
  clientName?: string | null;
  /** 客户英文名称 */
  clientForeignName?: string | null;
  /** 发票编号 */
  invoiceNo?: string | null;
  /** 是否代收代付 */
  isPoco?: string | null;
  /** 是否代收代付 */
  isPocoText?: string | null;
  /** 代收代付名称 */
  pocoName?: string | null;
  /** 发票类型 */
  invoiceType?: string | null;
  /** 发票类型 */
  invoiceTypeText?: string | null;
  /**
   * 开票金额
   * @format double
   */
  invoiceAmount?: number;
  /**
   * 到账金额
   * @format double
   */
  arrivedAmount?: number;
  /** 到账状态 */
  arrivedAmountState?: string | null;
  /** 到账状态 */
  arrivedAmountStateText?: string | null;
  /** 申请名称 */
  applicantName?: string | null;
  /** 申请人 */
  applicant?: string | null;
  /**
   * 申请时间
   * @format date-time
   */
  applyDate?: string | null;
  /** 红冲状态 */
  redPunchState?: string | null;
  /** 红冲状态 */
  redPunchStateText?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用分页信息类 */
export interface GetInvoiceApplyListOutputPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: GetInvoiceApplyListOutput[] | null;
}

/** 通用返回信息类 */
export interface GetInvoiceApplyListOutputPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: GetInvoiceApplyListOutputPageModel;
}

export interface GetInvoiceInfoOutput {
  /** 账单编号 */
  billingSerialId?: string | null;
  /** 案件编号 */
  caseSerialId?: string | null;
  /** 案件名称 */
  caseName?: string | null;
  /** 发票编号 */
  invoiceNo?: string | null;
  /**
   * 案件金额
   * @format double
   */
  caseAmount?: number;
  /**
   * 开票金额
   * @format double
   */
  invoiceAmount?: number;
  /**
   * 已开票金额
   * @format double
   */
  invoicedAmount?: number;
  /** 到账状态 */
  arrivedAmountState?: string | null;
  /** 到账状态 */
  arrivedAmountStateText?: string | null;
  /** 发票附件 */
  attachmentId?: string | null;
  /** 发票类型 */
  invoiceType?: string | null;
  /** 发票类型 */
  invoiceTypeText?: string | null;
  /** 红冲状态 */
  redPunchState?: string | null;
  /** 红冲状态 */
  redPunchStateText?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface GetInvoiceInfoOutputListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: GetInvoiceInfoOutput[] | null;
}

export interface GetKeywordInput {
  /** 查询关键字 */
  keyword?: string | null;
  /** 是否客户检索 */
  isCustomer?: boolean;
  /** 实体类型 */
  category?: string | null;
  /** 客户ID */
  clientId?: string | null;
  /** 数据类型 */
  dataType?: string | null;
}

export interface GetLanguageKeyInput {
  /** 过滤字段 */
  filter?: string | null;
  /** 多语言合集 */
  languages?: string[] | null;
  /** 模块 */
  module?: string | null;
  /** 平台 */
  source?: (0 | 1 | 2)[] | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

export interface GetMergeTimesheetInput {
  /** 账单编号 */
  billingId?: string | null;
  /** 账单服务费编号 */
  ids?: string[] | null;
  /** 工时填报人 */
  employeeId?: string | null;
  /**
   * 小时费率
   * @format double
   */
  hourlyRate?: number;
  /**
   * 工时日期
   * @format date-time
   */
  workDate?: string | null;
  /** 任务 */
  task?: string | null;
  /** 活动代码 */
  activityCode?: string | null;
  /** 币种 */
  currency?: string | null;
  /**
   * 币种汇率
   * @format double
   */
  exchangeRate?: number;
  /**
   * 计费小时
   * @format double
   */
  billableHours?: number;
  /** 工时类型 */
  category?: string | null;
  /** 调整类型 */
  adjustmentType?: string | null;
  /**
   * 调整比例
   * @format double
   */
  adjustmentRate?: number;
  /**
   * 调整额
   * @format double
   */
  adjustment?: number;
  /**
   * 税率
   * @format double
   */
  taxRate?: number;
  /**
   * 税额
   * @format double
   */
  taxAmount?: number;
  /**
   * 金额
   * @format double
   */
  amount?: number;
  /**
   * 外币服务费金额
   * @format double
   */
  foreignAmount?: number;
  /**
   * 外币小计金额
   * @format double
   */
  foreignSubtotal?: number;
  /**
   * 小计金额
   * @format double
   */
  subtotal?: number;
  /** 是否计费 */
  isBillable?: string | null;
  /** 是否销账 */
  isWriteOff?: string | null;
  /** 销账类型 */
  writeOffType?: string | null;
  /** 销账理由 */
  writeOffRemark?: string | null;
  /** 备注 */
  remark?: string | null;
}

export interface GetMyCsrRequestConfirmInput {
  /** 关键字 */
  filter?: string | null;
  /** 客户名称 */
  clientName?: string | null;
  /** 对方 */
  oppositeName?: string | null;
  /** 第三方 */
  potential3rdPartyName?: string | null;
  /** 利冲号 */
  serialId?: string | null;
  /** 案件类型 */
  category?: string[] | null;
  /** 案件名称 */
  caseName?: string | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 导出功能输入的参数 */
export interface GetMyCsrRequestConfirmInputExportInput {
  selectColumns?: SetColumn[] | null;
  search?: GetMyCsrRequestConfirmInput;
  fileName?: string | null;
  extended?: string | null;
  /** 选择Id */
  checkedIds?: string[] | null;
  /**
   * 导出类型
   * @format int32
   */
  exportType?: 0 | 1;
}

/** 我申请的利益冲突信息请求入参 */
export interface GetMyCsrRequestInput {
  /** 关键字 */
  filter?: string | null;
  /** 利冲案件名称 */
  name?: string | null;
  /** 状态 */
  status?: string[] | null;
  /** 客户方 */
  clientPartyName?: string | null;
  /** 对方 */
  oppositePartyName?: string | null;
  /** 第三方 */
  threePartyName?: string | null;
  /** 利冲号 */
  serialId?: string | null;
  /** 利冲案件类型 */
  category?: string[] | null;
  /** 客户行业 */
  clientIndustryId?: string[] | null;
  /** 执业领域 */
  practiceArea?: string[] | null;
  /** 是否涉密 */
  isClassified?: string[] | null;
  /** 是否紧急 */
  isUrgent?: string[] | null;
  /** 收费方式（固定，小时，风险，免费代理） */
  chargingMethods?: string[] | null;
  /** 利冲时效是否过期 */
  isExpired?: string[] | null;
  /** 日期时间范围均使用 */
  createTime?: RangeDateTimeInput;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 导出功能输入的参数 */
export interface GetMyCsrRequestInputExportInput {
  selectColumns?: SetColumn[] | null;
  /** 我申请的利益冲突信息请求入参 */
  search?: GetMyCsrRequestInput;
  fileName?: string | null;
  extended?: string | null;
  /** 选择Id */
  checkedIds?: string[] | null;
  /**
   * 导出类型
   * @format int32
   */
  exportType?: 0 | 1;
}

/** 我申请的利益冲突列表 */
export interface GetMyCsrRequestOutput {
  /** 利冲号 */
  serialId?: string | null;
  /** 利冲名称 */
  name?: string | null;
  /** 利冲案件类型 */
  category?: string | null;
  /** 利冲案件类型文本 */
  categoryText?: string | null;
  /** 关联案件Id */
  relationCaseId?: string | null;
  /** 关联案件名称 */
  relationCaseName?: string | null;
  /** 关联案件编号 */
  relationCaseSerialId?: string | null;
  /** 客户行业 */
  clientIndustryId?: string | null;
  /** 客户行业文本 */
  clientIndustryText?: string | null;
  /** 执业领域 */
  practiceArea?: string | null;
  /** 执业领域文本 */
  practiceAreaText?: string | null;
  /** 是否涉密 */
  isClassified?: string | null;
  /** 是否涉密文本 */
  isClassifiedText?: string | null;
  /** 是否紧急 */
  isUrgent?: string | null;
  /** 是否紧急文本 */
  isUrgentText?: string | null;
  /** 收费方式 */
  chargingMethods?: string | null;
  /** 收费方式文本 */
  chargingMethodsText?: string | null;
  /**
   * 合同金额
   * @format double
   */
  contractAmount?: number | null;
  /** 委托事项 */
  entrustedMatters?: string | null;
  /** 客户名称 */
  clientName?: string | null;
  /** 客户外文名称 */
  clientForeignName?: string | null;
  /** 客户股东实控人名称 */
  clientShareholderActualCtrlName?: string | null;
  /** 客户股东实控人外文名称 */
  clientShareholderActualCtrlForeignName?: string | null;
  /** 客户参与实体名称 */
  clientParticipatingEntityName?: string | null;
  /** 客户参与实体外文名称 */
  clientParticipatingEntityForeignName?: string | null;
  /** 对方当事人 */
  oppositeName?: string | null;
  /** 对方当事人外文名称 */
  oppositeForeignName?: string | null;
  /** 对方股东实控人名称 */
  oppositeShareholderActualCtrlName?: string | null;
  /** 对方股东实控人外文名称 */
  oppositeShareholderActualCtrlForeignName?: string | null;
  /** 对方参与实体名称 */
  oppositeParticipatingEntityName?: string | null;
  /** 对方参与实体外文名称 */
  oppositeParticipatingEntityForeignName?: string | null;
  /** 潜在第三方名称 */
  potential3rdPartyName?: string | null;
  /** 潜在第三方外文名称 */
  potential3rdPartyForeignName?: string | null;
  /** 潜在第三方股东实控人 */
  potential3rdPartyShareholderActualCtrlName?: string | null;
  /** 潜在第三方股东实控人外文名称 */
  potential3rdPartyShareholderActualCtrlForeignName?: string | null;
  /** 申请合伙人 */
  createBy?: string | null;
  /** 申请合伙人名称 */
  createByName?: string | null;
  /** 说明描述 */
  narrative?: string | null;
  /** 利冲转立案Id */
  caseId?: string | null;
  /** 利冲转立案编号 */
  caseSerialId?: string | null;
  /** 关联案件名称 */
  caseName?: string | null;
  /** 利冲关联案件类型 */
  caseCategory?: string | null;
  /** 利冲关联案件类型文本 */
  caseCategoryText?: string | null;
  /** 利冲关联案件状态 */
  caseStatus?: string | null;
  /** 利冲适用规则 */
  csrRule?: string | null;
  /** 利冲时效是否过期 */
  isExpired?: string | null;
  /** 利冲时效是否过期文本 */
  isExpiredText?: string | null;
  /**
   * 利冲审查进度
   * @format double
   */
  rateOfProgress?: number;
  /**
   * 利冲时效剩余天数
   * @format float
   */
  remainingDays?: number;
  /** 主办合伙人 */
  inChargeLawyer?: string | null;
  /** 协办合伙人 */
  coWorkingLawyer?: string | null;
  /** 案源人 */
  caseSourceLawyer?: string | null;
  /** 账单管理人 */
  billingLawyer?: string | null;
  /** 主办合伙人名称 */
  inChargeLawyerName?: string | null;
  /** 协办合伙人名称 */
  coWorkingLawyerName?: string | null;
  /** 案源人名称 */
  caseSourceLawyerName?: string | null;
  /** 账单管理人名称 */
  billingLawyerName?: string | null;
  /** 申请合伙人名称 */
  applicantName?: string | null;
  /** 申请合伙人名称 */
  applicant?: string | null;
  /** 豁免信息 */
  csrExemptions?: CSRExemptionOutput[] | null;
  /** 利冲剩余时效天数 */
  remainingDaysText?: string | null;
  /**
   * 申请时间
   * @format date-time
   */
  submittedOn?: string | null;
  /** 业务状态   列表不处理，只处理导出 */
  flowState?: string | null;
  /** 是否克隆 */
  isClone?: boolean;
  /** 克隆自利冲编号 */
  fromId?: string | null;
  /** 利冲过期重新提交来源id */
  sourceId?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用分页信息类 */
export interface GetMyCsrRequestOutputPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: GetMyCsrRequestOutput[] | null;
}

/** 通用返回信息类 */
export interface GetMyCsrRequestOutputPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: GetMyCsrRequestOutputPageModel;
}

export interface GetMyInvoiceApplyListInput {
  /** 关键字 */
  filter?: string | null;
  /** 发票编号 */
  invoiceNo?: string | null;
  /** 发票抬头 */
  invoiceHeader?: string | null;
  /** 到账状态 */
  arrivedAmountState?: string | null;
  /** 案件编号 */
  caseId?: string[] | null;
  /** 账单编号 */
  billingNo?: string | null;
  /** 日期时间范围均使用 */
  applyDate?: RangeDateTimeInput;
  /** 数值范围选择器 */
  invoiceAmount?: RangeDecimalInput;
  /** 开票办公室 */
  invoiceOfficeId?: string[] | null;
  /** 24小时内申请人未上传已开发票附件 */
  isOnlyShowNotFullyUploadedInvoiceAttachmentWithin24Hours?: string | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

export interface GetMyReceiptInput {
  /** 关键字 */
  filter?: string | null;
  /** 账单编号 */
  billingSerialId?: string | null;
  /** 付款方名称 */
  payerName?: string | null;
  /** 数值范围选择器 */
  payAmount?: RangeDecimalInput;
  /** 办公室 */
  officeId?: string[] | null;
  /** 日期时间范围均使用 */
  receiptDate?: RangeDateTimeInput;
  /** 日期时间范围均使用 */
  submittedOn?: RangeDateTimeInput;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 导出功能输入的参数 */
export interface GetMyReceiptInputExportInput {
  selectColumns?: SetColumn[] | null;
  search?: GetMyReceiptInput;
  fileName?: string | null;
  extended?: string | null;
  /** 选择Id */
  checkedIds?: string[] | null;
  /**
   * 导出类型
   * @format int32
   */
  exportType?: 0 | 1;
}

export interface GetMyReceiptOutput {
  /** 账单编号 */
  billingSerialId?: string | null;
  /** 公共收款编号 */
  publicReceiptId?: string | null;
  /** 付款方名称 */
  payerName?: string | null;
  /**
   * 收款总额
   * @format double
   */
  payAmount?: number;
  /** 收款币种 */
  currency?: string | null;
  /** 收款币种 */
  currencyText?: string | null;
  /**
   * 收款日期
   * @format date-time
   */
  receiptDate?: string | null;
  /** 结算币种 */
  settlementCurrency?: string | null;
  /** 结算币种 */
  settlementCurrencyText?: string | null;
  /**
   * 结算汇率
   * @format double
   */
  settlementRate?: number;
  /**
   * 结算金额
   * @format double
   */
  settlementAmount?: number;
  /**
   * 律师费金额
   * @format double
   */
  lawyerFee?: number;
  /**
   * 代收代付费
   * @format double
   */
  poboSubtotalAmount?: number;
  /**
   * 分配金额
   * @format double
   */
  allocatedAmount?: number;
  /**
   * 认领日期
   * @format date-time
   */
  submittedOn?: string | null;
  /** 描述说明 */
  narrative?: string | null;
  /** 发票号 */
  invoiceSerialId?: string | null;
  /** 分配状态 */
  allocationStatus?: string | null;
  /** 分配状态文本 */
  allocationStatusText?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用分页信息类 */
export interface GetMyReceiptOutputPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: GetMyReceiptOutput[] | null;
}

/** 通用返回信息类 */
export interface GetMyReceiptOutputPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: GetMyReceiptOutputPageModel;
}

export interface GetNewCaseSerialIdInput {
  /** 案件编号 */
  id?: string | null;
  /**
   * 最大流水号
   * @format int64
   */
  maxIdNumber?: number;
  /**
   * 可选流水号数量
   * @format int32
   */
  serialIdNumber?: number;
}

export interface GetNewSerialIdOutput {
  /** 所属表单 */
  tableName?: string | null;
  /** 编码字段 */
  field?: string | null;
  /**
   * 序列号
   * @format int64
   */
  sequenceNumber?: number;
  /** 完整流水号 */
  sequenceNumberStr?: string | null;
  /** 是否子号 */
  isSubNumber?: boolean;
}

/** 通用返回信息类 */
export interface GetNewSerialIdOutputListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: GetNewSerialIdOutput[] | null;
}

/** 未出账单案件入参DTO */
export interface GetNoBillingCaseInput {
  /** 关键字 */
  filter?: string | null;
  /** 案件编号 */
  serialId?: string | null;
  /** 案件名称 */
  name?: string | null;
  /** 客户名称 */
  clientName?: string | null;
  /** 案件类型 */
  categoryText?: string[] | null;
  /** 主办合伙人 */
  inChargeLawyerName?: string[] | null;
  /** 案源人 */
  caseSourceLawyerName?: string[] | null;
  /** 账单管理人 */
  billingLawyerName?: string[] | null;
  /** 日期时间范围均使用 */
  acceptDate?: RangeDateTimeInput;
  /** 办公室 */
  officeId?: string[] | null;
  /** 以未出账工时过滤 */
  isUseTimesheetFilter?: string[] | null;
  /** 以未出账费用过滤 */
  isUseExpenseFilter?: string[] | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 未出账单案件输出DTO */
export interface GetNoBillingCaseOutput {
  /** 案件主键 */
  id?: string | null;
  /** 案件名称 */
  name?: string | null;
  /** 案件编号 */
  serialId?: string | null;
  /** 客户主键 */
  clientId?: string | null;
  /** 客户名称 */
  clientName?: string | null;
  /** 客户名称英文 */
  clientForeignName?: string | null;
  /** 客户编号 */
  clientSerialId?: string | null;
  /** 案件类型 */
  category?: string | null;
  /** 案件类型文本 */
  categoryText?: string | null;
  /** 隶属办公室 */
  officeId?: string | null;
  /** 隶属办公室名称 */
  officeName?: string | null;
  /** 主办合伙人 */
  inChargeLawyer?: string | null;
  /** 主办合伙人 */
  inChargeLawyerName?: string | null;
  /** 案源人 */
  caseSourceLawyer?: string | null;
  /** 案源人 */
  caseSourceLawyerName?: string | null;
  /** 账单管理人 */
  billingLawyer?: string | null;
  /** 账单管理人 */
  billingLawyerName?: string | null;
  /** 申请合伙人 */
  applicant?: string | null;
  /** 申请合伙人 */
  applicantName?: string | null;
  /**
   * 收案日期
   * @format date-time
   */
  acceptDate?: string;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /**
   * 最近出账日期
   * @format date-time
   */
  lastBillingDate?: string | null;
  /**
   * 未出账工时数量
   * @format int32
   */
  unpaidTimesheetCount?: number;
  /**
   * 未出账费用数量
   * @format int32
   */
  unpaidExpenseCount?: number;
}

/** 通用分页信息类 */
export interface GetNoBillingCaseOutputPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: GetNoBillingCaseOutput[] | null;
}

/** 通用返回信息类 */
export interface GetNoBillingCaseOutputPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: GetNoBillingCaseOutputPageModel;
}

export interface GetOfficeDataSelectInput {
  officeId?: string[] | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 关键字 */
  filter?: string | null;
  /** 是否查询所务客户 */
  isInternal?: boolean | null;
  /** 日期时间范围均使用 */
  lastUpdate?: RangeDateTimeInput;
  /** id集合 */
  ids?: string[] | null;
  /** 只返回基础Model */
  onlyReturnBaseEntity?: boolean;
}

export interface GetOppositeEntityInfoInput {
  /** 名字/证件编码 */
  filter?: string | null;
  /** 实体名称 */
  name?: string | null;
  /** 实体类型 */
  category?: string[] | null;
  /** 证件号码（身份证/信用代码/护照） */
  creditCode?: string | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 导出功能输入的参数 */
export interface GetOppositeEntityInfoInputExportInput {
  selectColumns?: SetColumn[] | null;
  search?: GetOppositeEntityInfoInput;
  fileName?: string | null;
  extended?: string | null;
  /** 选择Id */
  checkedIds?: string[] | null;
  /**
   * 导出类型
   * @format int32
   */
  exportType?: 0 | 1;
}

export interface GetOrganizationUnitsInput {
  /** 机构键值 */
  code?: string | null;
  /** 显示名称 */
  displayName?: string | null;
  /** 父级Id */
  parentId?: string | null;
  /** 别名 */
  alterName?: string | null;
  /** 区别标识 */
  discriminator?: string | null;
  /** 是否启用 */
  isActive?: boolean | null;
  /** 是否虚拟机构 */
  isVirtual?: boolean | null;
  /** 是否总所 */
  isHeadOffice?: boolean | null;
  /**
   * 阈值标识
   * @format int32
   */
  thresholdFlag?: 0 | 1 | 2 | 3 | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

export interface GetParticipatingEntityInfoInput {
  /** 名字/证件编码 */
  filter?: string | null;
  /** 实体名称 */
  name?: string | null;
  /** 实体类型 */
  category?: string[] | null;
  /** 证件号码（身份证/信用代码/护照） */
  creditCode?: string | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 导出功能输入的参数 */
export interface GetParticipatingEntityInfoInputExportInput {
  selectColumns?: SetColumn[] | null;
  search?: GetParticipatingEntityInfoInput;
  fileName?: string | null;
  extended?: string | null;
  /** 选择Id */
  checkedIds?: string[] | null;
  /**
   * 导出类型
   * @format int32
   */
  exportType?: 0 | 1;
}

export interface GetPayerInfoInput {
  /** 关键字 */
  filter?: string | null;
  /** 客户名称 */
  name?: string | null;
  /** 客户编号 */
  clientId?: string | null;
  /** 客户流水号 */
  serialId?: string | null;
  /** 客户行业 */
  clientIndustryList?: string[] | null;
  /** 客户类型 */
  clientTypeList?: string[] | null;
  /** 客户类型 */
  creditType?: string[] | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

export interface GetProcessCasePartyResultInput {
  /** 利冲Id */
  csrId?: string | null;
  csrProcessId?: string | null;
  /** 关键字 */
  filter?: string | null;
  /** 案件类型 */
  relationCategory?: string[] | null;
  /** 案件状态 */
  flowState?: NameValue[] | null;
  /** 日期时间范围均使用 */
  relationRequestTime?: RangeDateTimeInput;
  /** 日期时间范围均使用 */
  relationEndTime?: RangeDateTimeInput;
  /** 主办合伙人 */
  relationInChargeLawyerId?: string[] | null;
  /** 案源人 */
  relationCaseUser?: string[] | null;
  /** 申请合伙人 */
  relationRequestUserId?: string[] | null;
  /** 是否选中 */
  isChecked?: string[] | null;
  /** 是否需要豁免 */
  isNeedExemption?: string[] | null;
  /** 需确认合伙人 */
  confirmLawyerId?: string[] | null;
  /** 客户Id */
  clientIdList?: string[] | null;
  /** 根据关系方搜索 */
  casePartyResultSearchInputList?: CasePartyResultSearchInput[] | null;
  /** 客户方 */
  clientPartyName?: string | null;
  /** 对方 */
  oppositePartyName?: string | null;
  /** 第三方 */
  threePartyName?: string | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 导出功能输入的参数 */
export interface GetProcessCasePartyResultInputExportInput {
  selectColumns?: SetColumn[] | null;
  search?: GetProcessCasePartyResultInput;
  fileName?: string | null;
  extended?: string | null;
  /** 选择Id */
  checkedIds?: string[] | null;
  /**
   * 导出类型
   * @format int32
   */
  exportType?: 0 | 1;
}

export interface GetProcessCasePartyResultNoConfirmListInput {
  /** 利冲Id */
  csrRequestIds?: string[] | null;
  /** 结果集Id */
  csrProcessIds?: string[] | null;
  /** 需确认合伙人 */
  confirmLawyerId?: string | null;
}

/** 标签分页 */
export interface GetProcessTagsPerferenceInput {
  tagName?: string | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

export interface GetReceiptAllocationInput {
  /** 关键字 */
  filter?: string | null;
  /** 账单编号 */
  billingSerialId?: string | null;
  /** 付款方名称 */
  payerName?: string | null;
  /** 案件编号 */
  caseSerialId?: string | null;
  /** 案件名称 */
  caseName?: string | null;
  /** 日期时间范围均使用 */
  receiptDate?: RangeDateTimeInput;
  /** 办公室 */
  officeId?: string[] | null;
  /** 数值范围选择器 */
  settlementAmount?: RangeDecimalInput;
  /** 数值范围选择器 */
  allocatedAmount?: RangeDecimalInput;
  /** 分配状态 */
  allocationStatus?: string[] | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 导出功能输入的参数 */
export interface GetReceiptAllocationInputExportInput {
  selectColumns?: SetColumn[] | null;
  search?: GetReceiptAllocationInput;
  fileName?: string | null;
  extended?: string | null;
  /** 选择Id */
  checkedIds?: string[] | null;
  /**
   * 导出类型
   * @format int32
   */
  exportType?: 0 | 1;
}

export interface GetReceiptAllocationOutput {
  /** 账单编号 */
  billingSerialId?: string | null;
  /** 付款方名称 */
  payerName?: string | null;
  /** 案件名称 */
  caseName?: string | null;
  /** 案件编号 */
  caseSerialId?: string | null;
  /** 客户名称 */
  clientName?: string | null;
  /**
   * 收款日期
   * @format date-time
   */
  receiptDate?: string | null;
  /**
   * 结算金额
   * @format double
   */
  settlementAmount?: number;
  /** 结算币种 */
  settlementCurrency?: string | null;
  /** 结算币种 */
  settlementCurrencyText?: string | null;
  /**
   * 分配金额
   * @format double
   */
  allocatedAmount?: number;
  /** 分配状态 */
  allocationStatus?: string | null;
  /** 分配状态文本 */
  allocationStatusText?: string | null;
  /** 案件分配信息 */
  caseDistributions?: CaseDistributionDto[] | null;
  /** 发票 */
  invoices?: GetFinancialInvoiceOutput[] | null;
  /**
   * 律师费金额
   * @format double
   */
  lawyerFee?: number;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface GetReceiptAllocationOutputMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  response?: GetReceiptAllocationOutput;
}

/** 通用分页信息类 */
export interface GetReceiptAllocationOutputPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: GetReceiptAllocationOutput[] | null;
}

/** 通用返回信息类 */
export interface GetReceiptAllocationOutputPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: GetReceiptAllocationOutputPageModel;
}

export interface GetReceiptClaimedInput {
  /** 关键字 */
  filter?: string | null;
  /** 付款方名称 */
  payerName?: string | null;
  /** 办公室 */
  officeId?: string[] | null;
  /** 收款方式 */
  payMode?: string[] | null;
  /** 日期时间范围均使用 */
  receiptDate?: RangeDateTimeInput;
  /** 登记人 */
  applicant?: string[] | null;
  /** 日期时间范围均使用 */
  submittedOn?: RangeDateTimeInput;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 导出功能输入的参数 */
export interface GetReceiptClaimedInputExportInput {
  selectColumns?: SetColumn[] | null;
  search?: GetReceiptClaimedInput;
  fileName?: string | null;
  extended?: string | null;
  /** 选择Id */
  checkedIds?: string[] | null;
  /**
   * 导出类型
   * @format int32
   */
  exportType?: 0 | 1;
}

export interface GetReceiptClaimedOutput {
  /**
   * 认领总额
   * @format double
   */
  receiptAmount?: number;
  /** 付款方名称 */
  payerName?: string | null;
  /** 收款方式 */
  payMode?: string | null;
  /** 收款方式 */
  payModeText?: string | null;
  /**
   * 收款总额
   * @format double
   */
  payAmount?: number;
  /**
   * 收款日期
   * @format date-time
   */
  receiptDate?: string | null;
  /** 结算币种 */
  settlementCurrency?: string | null;
  /** 结算币种 */
  settlementCurrencyText?: string | null;
  /**
   * 结算汇率
   * @format double
   */
  settlementRate?: number;
  /**
   * 结算金额
   * @format double
   */
  settlementAmount?: number;
  /** 备注 */
  remark?: string | null;
  /** 是否定向认领 */
  isDirectionalClaimed?: string | null;
  /** 是否定向认领 */
  isDirectionalClaimedText?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用分页信息类 */
export interface GetReceiptClaimedOutputPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: GetReceiptClaimedOutput[] | null;
}

/** 通用返回信息类 */
export interface GetReceiptClaimedOutputPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: GetReceiptClaimedOutputPageModel;
}

export interface GetReceiptImportInput {
  /** 导入记录ID */
  importId?: string | null;
  /** 付款方名称 */
  payerName?: string | null;
  /** 数值范围选择器 */
  payAmount?: RangeDecimalInput;
  /** 日期时间范围均使用 */
  receiptDate?: RangeDateTimeInput;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

export interface GetReferToForeignExchangeRateInput {
  /** 本位币 */
  base?: string | null;
  /** 目标币种 */
  target?: string | null;
  /**
   * 转换金额
   * @format double
   */
  amount?: number | null;
}

export interface GetReferToForeignExchangeRateOutput {
  /**
   * 金额
   * @format double
   */
  targetAmount?: number;
  /** 本位币种 */
  base?: string | null;
  /** 目标币种 */
  target?: string | null;
  /** 汇率日期 */
  date?: string | null;
  /**
   * 目标汇率
   * @format double
   */
  targetRate?: number;
  /**
   * 汇率小数位精度
   * @format int32
   */
  scale?: number;
  /** 汇率列表 */
  rates?: Record<string, number>;
}

/** 通用返回信息类 */
export interface GetReferToForeignExchangeRateOutputMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  response?: GetReferToForeignExchangeRateOutput;
}

export interface GetReportByMatterOutput {
  employeeId?: string | null;
  clientSerialId?: string | null;
  clientName?: string | null;
  caseSerialId?: string | null;
  caseName?: string | null;
  clientCaseSerialIdAndName?: string | null;
  /** @format double */
  totalNonBillable?: number;
  /** @format double */
  totalBillable?: number;
  /** @format double */
  totalHours?: number;
}

export interface GetReportBySingleMatterDetailOutput {
  timeRecordDescription?: string | null;
  clientSerialId?: string | null;
  clientName?: string | null;
  caseSerialId?: string | null;
  caseName?: string | null;
  clientSerialIdAndName?: string | null;
  caseSerialIdAndName?: string | null;
  timesheetItem?: GetTimesheetDetailOutput[] | null;
  timekeeperSubtotal?: GetSubTotalByTimekeeperOutput[] | null;
  /** @format double */
  totalHours?: number;
  /** @format double */
  totalUsdFee?: number;
}

/** 通用返回信息类 */
export interface GetReportBySingleMatterDetailOutputMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  response?: GetReportBySingleMatterDetailOutput;
}

export interface GetReportGroupByTimekeeperOutput {
  employeeId?: string | null;
  employeeSerialId?: string | null;
  employeeName?: string | null;
  /** @format double */
  totalNonBillable?: number;
  /** @format double */
  totalBillable?: number;
  /** @format double */
  totalHours?: number;
}

export interface GetReportMultipleMatterGroupByTimekeeperOutput {
  employeeId?: string | null;
  employeeSerialId?: string | null;
  employeeName?: string | null;
  /** @format double */
  totalNonBillable?: number;
  /** @format double */
  totalBillable?: number;
  /** @format double */
  totalHours?: number;
  timekeeperMatterItems?: GetReportByMatterOutput[] | null;
  /** @format int32 */
  count?: number;
}

export interface GetRequestAuditLogInput {
  /** 关键词 */
  filter?: string | null;
  /** 日期时间范围均使用 */
  createTime?: RangeDateTimeInput;
  /** 请求人 */
  createId?: string | null;
  /** 浏览器信息 */
  browserInfo?: string | null;
  /** 客户端Id */
  clientIdAddress?: string | null;
  /** 客户端名称 */
  clientName?: string | null;
  /** 请求方法 */
  httpMethod?: string | null;
  /** 是否异常 */
  isException?: boolean | null;
  /**
   * 执行耗时 毫秒
   * @format double
   */
  executionDuration?: number | null;
  /** 是否是模拟登录 */
  isSimulation?: boolean | null;
  /** 请求地址 */
  requestUrl?: string | null;
  /** 错误信息 */
  exception?: string | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 导出功能输入的参数 */
export interface GetRequestAuditLogInputExportInput {
  selectColumns?: SetColumn[] | null;
  search?: GetRequestAuditLogInput;
  fileName?: string | null;
  extended?: string | null;
  /** 选择Id */
  checkedIds?: string[] | null;
  /**
   * 导出类型
   * @format int32
   */
  exportType?: 0 | 1;
}

export interface GetRoleInput {
  /** 关键词 */
  key?: string | null;
  /** 用户组Id */
  roleGroupId?: string | null;
  /**
   * 角色类型
   * @format int32
   */
  roleTypeEnum?: 0 | 1 | 2 | 3 | null;
  /** 权限类型 */
  roleTypes?: (0 | 1 | 2 | 3)[] | null;
  /** 是否启用 */
  isActive?: boolean | null;
  /** 是否默认角色 */
  isDefault?: boolean | null;
  /** 虚拟角色 */
  isVirtual?: boolean | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

export interface GetSequenceNumberRulesHistoryInput {
  /** 隶属表单 */
  tableName?: string | null;
  /** 编码字段 */
  field?: string | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

export interface GetSequenceNumberRulesInput {
  /** 关键字 */
  filter?: string | null;
  /** 隶属表单 */
  entityName?: string | null;
  /** 编码字段 */
  fieldName?: string | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

export interface GetSerialIdPageInput {
  /** 客户Id */
  clientId?: string | null;
  /** 账单编号 */
  serialId?: string | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

export interface GetSerialIdPageOutput {
  /** Id */
  id?: string | null;
  /** 账单编号 */
  serialId?: string | null;
}

/** 通用分页信息类 */
export interface GetSerialIdPageOutputPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: GetSerialIdPageOutput[] | null;
}

/** 通用返回信息类 */
export interface GetSerialIdPageOutputPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: GetSerialIdPageOutputPageModel;
}

export interface GetSettingInput {
  /** 配置名 */
  name?: string | null;
  /**
   * 配置级别
   * @format int32
   */
  settingScopes?: 1 | 2 | 4 | null;
  /** 租户Id */
  tenantId?: string | null;
  /** 用户Id */
  userId?: string | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 工时记录表 */
export interface GetSpecialTimesheetInput {
  /** 关键字 */
  filter?: string | null;
  /** 客户号/客户名称 */
  clientId?: string[] | null;
  /** 案号/案件名称 */
  caseId?: string[] | null;
  /** 日期时间范围均使用 */
  workDate?: RangeDateTimeInput;
  /** 计时人员 */
  employeeId?: string[] | null;
  /** 工时状态 */
  status?: string[] | null;
  /** 是否计费 */
  isBillable?: string[] | null;
  /** 特殊案件类型 */
  caseCategory?: string[] | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 导出功能输入的参数 */
export interface GetSpecialTimesheetInputExportInput {
  selectColumns?: SetColumn[] | null;
  /** 工时记录表 */
  search?: GetSpecialTimesheetInput;
  fileName?: string | null;
  extended?: string | null;
  /** 选择Id */
  checkedIds?: string[] | null;
  /**
   * 导出类型
   * @format int32
   */
  exportType?: 0 | 1;
}

export interface GetSplitTimesheetInput {
  /** 账单编号 */
  billingId?: string | null;
  /** 账单服务费编号 */
  id?: string | null;
  /** 拆分后的工时清单 */
  timesheetItems?: MergeOrSplitTimesheetFeeItemDto[] | null;
}

export interface GetStatisticalSummaryDetail {
  /** @format double */
  invoiceAmount?: number;
  /** @format double */
  arrivedAmount?: number;
  month?: string | null;
  day?: string | null;
  invoiceDate?: string | null;
}

/** 发票收款-统计概要 */
export interface GetStatisticalSummaryOutput {
  /**
   * 发票总金额
   * @format double
   */
  totalInvoiceAmount?: number;
  /** echart柱状图 */
  statisticalSummaryDetails?: GetStatisticalSummaryDetail[] | null;
  /**
   * 已到账金额
   * @format double
   */
  totalArrivedAmount?: number;
  /**
   * 已到账占比
   * @format double
   */
  totalArrivedAmountRate?: number;
  /**
   * 未到账金额
   * @format double
   */
  totalUnArrivedAmount?: number;
  /**
   * 未到账占比
   * @format double
   */
  totalUnArrivedAmountRate?: number;
  /**
   * 已开发票数量
   * @format double
   */
  invoiceCount?: number;
  /**
   * 发票总金额（不含作废/红字）
   * @format double
   */
  totalAmountExcludeInvalidatedAndRedPunch?: number;
  /** @format double */
  totalRedPunchAmount?: number;
  /** @format double */
  totalInvalidatedAmount?: number;
  /** @format double */
  invalidatedAndRedPunchAmount?: number;
  /** @format double */
  totalAmountExcludeInvalidated?: number;
}

/** 通用返回信息类 */
export interface GetStatisticalSummaryOutputMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 发票收款-统计概要 */
  response?: GetStatisticalSummaryOutput;
}

export interface GetStatisticalSummaryYearsOutput {
  /** @format int32 */
  value?: number;
  label?: string | null;
}

/** 通用返回信息类 */
export interface GetStatisticalSummaryYearsOutputListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: GetStatisticalSummaryYearsOutput[] | null;
}

/** 子账单信息 */
export interface GetSubFinancialBillingOutput {
  /** 主案件编号 */
  mainCaseId?: string | null;
  /** 案件编号 */
  caseId?: string | null;
  /** 案件流水号 */
  caseSerialId?: string | null;
  /** 案件名称 */
  caseName?: string | null;
  /** 账单案件别名 */
  caseMatterName?: string | null;
  /** 客户编号 */
  clientId?: string | null;
  /** 客户流水号 */
  clientSerialId?: string | null;
  /** 客户名称 */
  clientName?: string | null;
  /** 流水编号 */
  serialId?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

export interface GetSubTotalByTimekeeperOutput {
  employeeId?: string | null;
  employeeName?: string | null;
  /** @format double */
  usdRate?: number | null;
  /** @format double */
  totalHours?: number;
  /** @format double */
  totalUsdFee?: number | null;
}

export interface GetSubmissionCategoryForWorkingHoursOutput {
  /** 人员编号 */
  employeeId?: string | null;
  /** 计时人员计时编号 */
  employeeSerialId?: string | null;
  /** 计时人员名称 */
  employeeName?: string | null;
  /** 计时人员邮箱 */
  employeeEmailAddress?: string | null;
  /** 案件编号 */
  caseSerialId?: string | null;
  /**
   * 工时日期
   * @format date-time
   */
  workDate?: string;
  /**
   * 自报小时数
   * @format double
   */
  hoursWorked?: number;
  /** 是否计费 */
  isBillable?: string | null;
  /** 是否特殊案件工时 */
  isSpecialCaseType?: string | null;
  /** 工时案件类型 */
  caseCategory?: string | null;
  /** 工时状态 */
  status?: string | null;
  /** 工时描述 */
  narrative?: string | null;
  /** 提交类型 */
  submissionCategory?: string | null;
  /**
   * 工时转换日期
   *
   * DATETR
   * @format date-time
   */
  transferredDate?: string | null;
  /**
   * 工时转移操作人
   *
   * USERTR ID
   */
  transferredUserId?: string | null;
  /**
   * 工时转移操作人名称
   *
   * USERTR
   */
  transferredUserName?: string | null;
  /**
   * 关闭提交日期
   *
   * DATECL
   * @format date-time
   */
  closedEntryDate?: string | null;
  /**
   * 关闭录入提交人
   *
   * USERCL ID
   */
  closedEntryUserId?: string | null;
  /**
   * 关闭录入提交人名称
   *
   * USERCL
   */
  closedEntryUserIdName?: string | null;
  /**
   * 工时创建日期
   *
   * DATECR
   * @format date-time
   */
  createTime?: string | null;
  /**
   * 工时创建人
   *
   * USERCR ID
   */
  createId?: string | null;
  /**
   * 工时创建人名称
   *
   * USERCR
   */
  createName?: string | null;
  /**
   * 最后一次编辑提交日期
   *
   * DATEED
   * @format date-time
   */
  lastEditedEntryDate?: string | null;
  /**
   * 最后一次编辑提交人
   *
   * USERED ID
   */
  lastEditedEntryUserId?: string | null;
  /**
   * 最后一次编辑提交人名称
   *
   * USERED
   */
  lastEditedEntryUserName?: string | null;
}

/** 通用返回信息类 */
export interface GetSubmissionCategoryForWorkingHoursOutputListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: GetSubmissionCategoryForWorkingHoursOutput[] | null;
}

export interface GetSubmitBillingInput {
  /** 账单编号 */
  id?: string | null;
  /** 案件编号 */
  caseId?: string | null;
  /** 客户编号 */
  clientId?: string | null;
  /**
   * 账单日期
   * @format date-time
   */
  billingDate?: string | null;
  /**
   * 到账日期
   * @format date-time
   */
  arrivedDate?: string | null;
  /**
   * 账单开始日期
   * @format date-time
   */
  startDate?: string | null;
  /**
   * 账单结束日期
   * @format date-time
   */
  endDate?: string | null;
  /** 付款方编号 */
  paymentId?: string | null;
  /** 付款方名称 */
  paymentName?: string | null;
  /** 付款周期 */
  paymentTerms?: string | null;
  /** 付款货币类型 */
  paymentCurrency?: string | null;
  /**
   * 应收账款
   * @format double
   */
  accountsReceivable?: number;
  /** 账单合伙人 */
  billingPartnerId?: string | null;
  /** 账单申请律师 */
  billingLawyerId?: string | null;
  /** AR负责人 */
  arResponsibleLawyerId?: string | null;
  /** 币种 */
  currency?: string | null;
  /**
   * 汇率
   * @format double
   */
  exchangeRate?: number;
  /** 支付状态 */
  paymentStatus?: string | null;
  /** 账单联系人 */
  contactPerson?: string | null;
  /** 邮箱地址 */
  emailAddress?: string | null;
  /** 账单所属办公室 */
  billingOffice?: string | null;
  /** 账单关联银行账号 */
  billingBankId?: string | null;
  /** 账单类型 */
  category?: string | null;
  /** 是否联合账单 */
  isBatchBilling?: string | null;
  /** 联合账单关联案号 */
  batchCaseIds?: string | null;
  /**
   * 总固定服务费
   * @format double
   */
  totalFixedServiceFee?: number;
  /**
   * 固定服务税费
   * @format double
   */
  fixedServiceTax?: number;
  /**
   * 账单总金额
   * @format double
   */
  totalAmount?: number;
  /**
   * 总工时服务费
   * @format double
   */
  totalTimesheetServiceFee?: number;
  /**
   * 工时税费
   * @format double
   */
  timesheetTax?: number;
  /**
   * 总支出费用金额
   * @format double
   */
  totalDisbursement?: number;
  /**
   * 支出税费
   * @format double
   */
  disbursementTax?: number;
  /**
   * 调整金额
   * @format double
   */
  totalAdjustment?: number;
  /**
   * 工时调整总额
   * @format double
   */
  timesheetAdjustment?: number;
  /**
   * 固定服务调整总额
   * @format double
   */
  fixedServiceAdjustment?: number;
  /**
   * 支出调整总额
   * @format double
   */
  disbursementAdjustment?: number;
  /**
   * 上限金额
   * @format double
   */
  upperLimitAmount?: number;
  /**
   * 上限金额税费
   * @format double
   */
  upperLimitAmountTax?: number;
  /**
   * POBO子项总计金额
   * @format double
   */
  poboSubtotalAmount?: number;
  /**
   * 外币结算金额
   * @format double
   */
  settlementAmount?: number;
  /** 是否跨境账单 */
  isCrossBorderBilling?: string | null;
  /** 是否内部账单 */
  isInternalBilling?: string | null;
  /** 是否坏账冲抵账单 */
  isBadDebts?: string | null;
  /** 账单生成附件 */
  billingAttachmentId?: string | null;
  /** 账单模板 */
  billingTemplateId?: string | null;
  /** 账单描述 */
  narrative?: string | null;
  /** 内部描述 */
  internalDescription?: string | null;
  /** 是否子账单 */
  isSubBilling?: string | null;
  /** 流水编号 */
  serialId?: string | null;
  /** 原系统主键编号 */
  originalId?: string | null;
  /** 流程数据大状态 */
  status?: string | null;
  /**
   * 提交时间
   * @format date-time
   */
  submittedOn?: string | null;
  /** 工作流状态 */
  flowState?: string | null;
  /** 申请人 */
  applicant?: string | null;
  /**
   * 工作流结束时间
   * @format date-time
   */
  flowEndDateTime?: string | null;
  /** 是否超期 */
  isOverdue?: boolean | null;
  /** 备注 */
  remark?: string | null;
  /** 办公室 */
  officeId?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

export interface GetSupplierInput {
  /** 名称 */
  name?: string | null;
  /** 联系人 */
  contact?: string | null;
  /** 联系电话 */
  telephone?: string | null;
  /** 联系地址 */
  address?: string | null;
  /** 邮箱 */
  email?: string | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

export interface GetSupplierPluginInput {
  /** 插件名称 */
  pluginName?: string | null;
  /** 插件类型 */
  pluginType?: string | null;
  /** 供应商 */
  supplierId?: string | null;
  /** 状态 */
  status?: boolean | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 国家地区表 */
export interface GetSysCountryForEditOutput {
  /** 中文名称 */
  cnName?: string | null;
  /** 名称 */
  name?: string | null;
  alpha2?: string | null;
  /** 数据字典Code */
  alpha3?: string | null;
  /** 地区编号 */
  countryCode?: string | null;
  iso_3166_2?: string | null;
  region?: string | null;
  subRegion?: string | null;
  intermediateRegion?: string | null;
  regionCode?: string | null;
  subRegionCode?: string | null;
  intermediateRegionCode?: string | null;
  /** 国旗 */
  nationalFlag?: string | null;
  /** Emoji */
  emoji?: string | null;
  /** 币种 */
  currency?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 表格数据，支持分页 */
export interface GetSysCountryForEditOutputForEditModel {
  /** 国家地区表 */
  data?: GetSysCountryForEditOutput;
  /** 实体映射 */
  mapping?: Record<string, any>;
}

/** 通用返回信息类 */
export interface GetSysCountryForEditOutputForEditModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 表格数据，支持分页 */
  response?: GetSysCountryForEditOutputForEditModel;
}

/** 国家地区表 */
export interface GetSysCountryInput {
  /** 中文名称 */
  cnName?: string | null;
  /** 名称 */
  name?: string | null;
  alpha2?: string | null;
  /** 数据字典Code */
  alpha3?: string | null;
  /** 地区编号 */
  countryCode?: string | null;
  iso_3166_2?: string | null;
  region?: string | null;
  subRegion?: string | null;
  intermediateRegion?: string | null;
  regionCode?: string | null;
  subRegionCode?: string | null;
  intermediateRegionCode?: string | null;
  /** 国旗 */
  nationalFlag?: string | null;
  /** Emoji */
  emoji?: string | null;
  /** 币种 */
  currency?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 导出功能输入的参数 */
export interface GetSysCountryInputExportInput {
  selectColumns?: SetColumn[] | null;
  /** 国家地区表 */
  search?: GetSysCountryInput;
  fileName?: string | null;
  extended?: string | null;
  /** 选择Id */
  checkedIds?: string[] | null;
  /**
   * 导出类型
   * @format int32
   */
  exportType?: 0 | 1;
}

/** 国家地区表 */
export interface GetSysCountryOutput {
  /** 中文名称 */
  cnName?: string | null;
  /** 名称 */
  name?: string | null;
  alpha2?: string | null;
  /** 数据字典Code */
  alpha3?: string | null;
  /** 地区编号 */
  countryCode?: string | null;
  iso_3166_2?: string | null;
  region?: string | null;
  subRegion?: string | null;
  intermediateRegion?: string | null;
  regionCode?: string | null;
  subRegionCode?: string | null;
  intermediateRegionCode?: string | null;
  /** 国旗 */
  nationalFlag?: string | null;
  /** Emoji */
  emoji?: string | null;
  /** 币种 */
  currency?: string | null;
  /** 备注 */
  remark?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface GetSysCountryOutputListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: GetSysCountryOutput[] | null;
}

/** 通用分页信息类 */
export interface GetSysCountryOutputPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: GetSysCountryOutput[] | null;
}

/** 通用返回信息类 */
export interface GetSysCountryOutputPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: GetSysCountryOutputPageModel;
}

/** 文档中心 */
export interface GetSysDocumentCenterForEditOutput {
  /** 名称 */
  name?: string | null;
  /** 全名 */
  fullName?: string | null;
  /** 父级Id */
  parentId?: string | null;
  /** 递归路径Id */
  fullPathId?: string | null;
  /** 递归路径Name */
  fullPathName?: string | null;
  /** 级别 */
  level?: string | null;
  /** 业务Id */
  businessId?: string | null;
  /** 业务名称 */
  businessName?: string | null;
  /** 拓展名 */
  extension?: string | null;
  /** 附件Id */
  attachmentId?: string | null;
  /** 哈希值 */
  hashValue?: string | null;
  /**
   * 大小
   * @format int64
   */
  size?: number;
  /** 是否读取 */
  isWrite?: string | null;
  /** 是否写入 */
  isDownload?: string | null;
  /** 是否删除 */
  isDelete?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 表格数据，支持分页 */
export interface GetSysDocumentCenterForEditOutputForEditModel {
  /** 文档中心 */
  data?: GetSysDocumentCenterForEditOutput;
  /** 实体映射 */
  mapping?: Record<string, any>;
}

/** 通用返回信息类 */
export interface GetSysDocumentCenterForEditOutputForEditModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 表格数据，支持分页 */
  response?: GetSysDocumentCenterForEditOutputForEditModel;
}

/** 文档中心 */
export interface GetSysDocumentCenterInput {
  /** 名称 */
  name?: string | null;
  /** 全名 */
  fullName?: string | null;
  /** 父级Id */
  parentId?: string | null;
  /** 递归路径Id */
  fullPathId?: string | null;
  /** 递归路径Name */
  fullPathName?: string | null;
  /** 级别 */
  level?: string | null;
  /** 业务Id */
  businessId?: string | null;
  /** 业务名称 */
  businessName?: string | null;
  /** 拓展名 */
  extension?: string | null;
  /** 附件Id */
  attachmentId?: string | null;
  /** 哈希值 */
  hashValue?: string | null;
  /** 是否读取 */
  isWrite?: string[] | null;
  /** 是否写入 */
  isDownload?: string[] | null;
  /** 是否删除 */
  isDelete?: string[] | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 文档中心 */
export interface GetSysDocumentCenterOutput {
  /** 名称 */
  name?: string | null;
  /** 全名 */
  fullName?: string | null;
  /** 父级Id */
  parentId?: string | null;
  /** 递归路径Id */
  fullPathId?: string | null;
  /** 递归路径Name */
  fullPathName?: string | null;
  /** 级别 */
  level?: string | null;
  /** 是否文件夹 */
  isFolder?: string | null;
  /** 是否文件夹 */
  isFolderText?: string | null;
  /** 业务Id */
  businessId?: string | null;
  /** 业务名称 */
  businessName?: string | null;
  /** 拓展名 */
  extension?: string | null;
  /** 附件Id */
  attachmentId?: string | null;
  /** 哈希值 */
  hashValue?: string | null;
  /**
   * 大小
   * @format int64
   */
  size?: number;
  /** 是否读取 */
  isRead?: string | null;
  /** 是否读取 */
  isReadText?: string | null;
  /** 是否写入 */
  isWrite?: string | null;
  /** 是否写入 */
  isWriteText?: string | null;
  /** 是否下载 */
  isDownload?: string | null;
  /** 是否下载 */
  isDownloadText?: string | null;
  /** 是否删除 */
  isDelete?: string | null;
  /** 是否删除 */
  isDeleteText?: string | null;
  /**
   * 排序
   * @format int32
   */
  sort?: number;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface GetSysDocumentCenterOutputListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: GetSysDocumentCenterOutput[] | null;
}

/** 通用分页信息类 */
export interface GetSysDocumentCenterOutputPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: GetSysDocumentCenterOutput[] | null;
}

/** 通用返回信息类 */
export interface GetSysDocumentCenterOutputPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: GetSysDocumentCenterOutputPageModel;
}

export interface GetSysEmailTemplatesInput {
  /** 模版名称 名字唯一 */
  templateName?: string | null;
  /**
   * 配置层级
   * @format int32
   */
  settingLevel?: 0 | 1 | 2 | 3;
  /** 模版正文 */
  templateContent?: string | null;
  /** 模版描述 */
  templateSubject?: string | null;
  /**
   * 授权失效时间 分钟
   * @format int32
   */
  authFailureTime?: number;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** Excel导出记录 */
export interface GetSysExcelExportCenterInput {
  /** 关键字 */
  filter?: string | null;
  /** 导出数据类型 */
  module?: string[] | null;
  /**
   * 开始时间
   * @format date-time
   */
  startTime?: string;
  /**
   * 结束时间
   * @format date-time
   */
  endTime?: string;
  /** 导出人 */
  createBy?: string | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 导出功能输入的参数 */
export interface GetSysExcelExportCenterInputExportInput {
  selectColumns?: SetColumn[] | null;
  /** Excel导出记录 */
  search?: GetSysExcelExportCenterInput;
  fileName?: string | null;
  extended?: string | null;
  /** 选择Id */
  checkedIds?: string[] | null;
  /**
   * 导出类型
   * @format int32
   */
  exportType?: 0 | 1;
}

/** 角色分组 */
export interface GetSysRoleGroupsInput {
  /** 分组名称 */
  name?: string | null;
  /** 角色名称 */
  roleName?: string | null;
  /**
   * 排序
   * @format int32
   */
  sort?: number | null;
  /**
   * 权限类型
   * @format int32
   */
  roleType?: 0 | 1 | 2 | 3 | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

export interface GetTaxFeeItemDto {
  /** @format double */
  taxRate?: number;
  /** @format double */
  taxAmount?: number;
}

/** 租户分所银行账号信息 */
export interface GetTenantBankInfoForEditOutput {
  /** 账户昵称 */
  name?: string | null;
  /** 企业名称 */
  regName?: string | null;
  /** 信用代码 */
  creditCode?: string | null;
  /** 执业许可证号 */
  licenseNumber?: string | null;
  /** 税号 */
  taxVatNo?: string | null;
  /** 发票代码 */
  invoiceCode?: string | null;
  /** 企业注册地址 */
  address?: string | null;
  /** 联系电话 */
  landline?: string | null;
  /** 传真 */
  fax?: string | null;
  /** 邮政编码 */
  postCode?: string | null;
  /** 银行名称 */
  bankName?: string | null;
  /** 银行账号 */
  bankAccount?: string | null;
  /** 银行代码 */
  bankCode?: string | null;
  /** 开户支行 */
  openingBranchName?: string | null;
  /** 开户支行代码 */
  openingBranchCode?: string | null;
  /** 开户支行地址 */
  openingBranchAddress?: string | null;
  /** 开户支行电话 */
  openingBranchLandline?: string | null;
  /** 是否默认收款账号 */
  isDefaultCollectionAccount?: string | null;
  /** 是否默认付款账号 */
  isDefaultPaymentAccount?: string | null;
  /** 货币单位 */
  currency?: string | null;
  /** 银行国际结算代码 */
  swiftCode?: string | null;
  /** 支付结算代码 */
  cnapsCode?: string | null;
  /** 银联支付号码 */
  unionPayNo?: string | null;
  /** 同城交换号码 */
  localExchangeNumber?: string | null;
  /** 语言 */
  language?: string | null;
  /** 分所办公室ID */
  officeId?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 表格数据，支持分页 */
export interface GetTenantBankInfoForEditOutputForEditModel {
  /** 租户分所银行账号信息 */
  data?: GetTenantBankInfoForEditOutput;
  /** 实体映射 */
  mapping?: Record<string, any>;
}

/** 通用返回信息类 */
export interface GetTenantBankInfoForEditOutputForEditModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 表格数据，支持分页 */
  response?: GetTenantBankInfoForEditOutputForEditModel;
}

/** 租户分所银行账号信息 */
export interface GetTenantBankInfoInput {
  /** 关键字 */
  filter?: string | null;
  /** 账户昵称 */
  name?: string | null;
  /** 企业名称 */
  regName?: string | null;
  /** 信用代码 */
  creditCode?: string | null;
  /** 执业许可证号 */
  licenseNumber?: string | null;
  /** 税号 */
  taxVatNo?: string | null;
  /** 发票代码 */
  invoiceCode?: string | null;
  /** 企业注册地址 */
  address?: string | null;
  /** 联系电话 */
  landline?: string | null;
  /** 传真 */
  fax?: string | null;
  /** 邮政编码 */
  postCode?: string | null;
  /** 银行名称 */
  bankName?: string | null;
  /** 银行账号 */
  bankAccount?: string | null;
  /** 银行代码 */
  bankCode?: string | null;
  /** 开户支行 */
  openingBranchName?: string | null;
  /** 开户支行代码 */
  openingBranchCode?: string | null;
  /** 开户支行地址 */
  openingBranchAddress?: string | null;
  /** 开户支行电话 */
  openingBranchLandline?: string | null;
  /** 是否默认收款账号 */
  isDefaultCollectionAccount?: string[] | null;
  /** 是否默认付款账号 */
  isDefaultPaymentAccount?: string[] | null;
  /** 货币单位 */
  currency?: string[] | null;
  /** 银行国际结算代码 */
  swiftCode?: string | null;
  /** 支付结算代码 */
  cnapsCode?: string | null;
  /** 银联支付号码 */
  unionPayNo?: string | null;
  /** 同城交换号码 */
  localExchangeNumber?: string | null;
  /** 语言 */
  language?: string | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 导出功能输入的参数 */
export interface GetTenantBankInfoInputExportInput {
  selectColumns?: SetColumn[] | null;
  /** 租户分所银行账号信息 */
  search?: GetTenantBankInfoInput;
  fileName?: string | null;
  extended?: string | null;
  /** 选择Id */
  checkedIds?: string[] | null;
  /**
   * 导出类型
   * @format int32
   */
  exportType?: 0 | 1;
}

/** 租户分所银行账号信息 */
export interface GetTenantBankInfoOutput {
  /** 是否启用 */
  isEnabled?: boolean;
  /** 账户昵称 */
  name?: string | null;
  /** 企业名称 */
  regName?: string | null;
  /** 信用代码 */
  creditCode?: string | null;
  /** 执业许可证号 */
  licenseNumber?: string | null;
  /** 税号 */
  taxVatNo?: string | null;
  /** 发票代码 */
  invoiceCode?: string | null;
  /** 企业注册地址 */
  address?: string | null;
  /** 联系电话 */
  landline?: string | null;
  /** 传真 */
  fax?: string | null;
  /** 银行名称 */
  bankName?: string | null;
  /** 银行账号 */
  bankAccount?: string | null;
  /** 银行代码 */
  bankCode?: string | null;
  /** 开户行 */
  openingBranchName?: string | null;
  /** 开户支行代码 */
  openingBranchCode?: string | null;
  /** 开户行地址 */
  openingBranchAddress?: string | null;
  /** 开户支行电话 */
  openingBranchLandline?: string | null;
  /** 是否默认收款账号 */
  isDefaultCollectionAccount?: string | null;
  /** 是否默认收款账号文本 */
  isDefaultCollectionAccountText?: string | null;
  /** 是否默认付款账号 */
  isDefaultPaymentAccount?: string | null;
  /** 是否默认付款账号文本 */
  isDefaultPaymentAccountText?: string | null;
  /** 货币单位 */
  currency?: string | null;
  /** 货币单位文本 */
  currencyText?: string | null;
  /** 银行国际结算代码 */
  swiftCode?: string | null;
  /** 支付结算代码 */
  cnapsCode?: string | null;
  /** 银联支付号码 */
  unionPayNo?: string | null;
  /** 同城交换号码 */
  localExchangeNumber?: string | null;
  /** 语言 */
  language?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface GetTenantBankInfoOutputListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: GetTenantBankInfoOutput[] | null;
}

/** 通用返回信息类 */
export interface GetTenantBankInfoOutputMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 租户分所银行账号信息 */
  response?: GetTenantBankInfoOutput;
}

/** 通用分页信息类 */
export interface GetTenantBankInfoOutputPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: GetTenantBankInfoOutput[] | null;
}

/** 通用返回信息类 */
export interface GetTenantBankInfoOutputPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: GetTenantBankInfoOutputPageModel;
}

export interface GetTimeByTimekeeperAndMatterOutput {
  timeRecordDescription?: string | null;
  timekeeperItem?: GetReportMultipleMatterGroupByTimekeeperOutput[] | null;
}

/** 通用返回信息类 */
export interface GetTimeByTimekeeperAndMatterOutputMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  response?: GetTimeByTimekeeperAndMatterOutput;
}

export interface GetTimeByTimekeeperOutput {
  timeRecordDescription?: string | null;
  timekeeperItem?: GetReportGroupByTimekeeperOutput[] | null;
}

/** 通用返回信息类 */
export interface GetTimeByTimekeeperOutputMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  response?: GetTimeByTimekeeperOutput;
}

/** 获取计时器列表 */
export interface GetTimerInput {
  /** 关键字 */
  filter?: string | null;
  /** 日期时间范围均使用 */
  workDate?: RangeDateTimeInput;
  /** 暂代昵称 */
  nickName?: string | null;
  /** 案件编号 */
  caseId?: string[] | null;
  /** 书写语言 */
  writtenLanguage?: string[] | null;
  /** 是否星标 */
  isFavorited?: boolean | null;
  /** 日期时间范围均使用 */
  submittedOn?: RangeDateTimeInput;
  /** 计时人员 */
  employeeId?: string[] | null;
  /** 计时状态 */
  timerState?: string[] | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

export interface GetTimerOutput {
  /**
   * 计时时间
   * @format time
   */
  watchTime?: string;
  /**
   * 计时器开始时间
   * @format time
   */
  timerStart?: string | null;
  /**
   * 计时器结束时间
   * @format time
   */
  timerEnd?: string | null;
  /** 是否计时器计时 */
  isTimerTrackedText?: string | null;
  /** 货币类型 */
  currencyText?: string | null;
  /** 是否可计费 */
  isBillableText?: string | null;
  /** 客户流水号 */
  clientSerialId?: string | null;
  /** 客户名称 */
  clientName?: string | null;
  /** 案件流水号 */
  caseSerialId?: string | null;
  /** 案件名称 */
  caseName?: string | null;
  /** 书写语言 */
  writtenLanguageText?: string | null;
  employeeSerialId?: string | null;
  /** 计时人员 */
  employeeName?: string | null;
  /** 计时人员邮箱 */
  employeeEmailAddress?: string | null;
  /** 是否销账文本 */
  isWriteOffText?: string | null;
  /** 销账类型文本 */
  writeOffTypeText?: string | null;
  /** 是否特殊案件工时文本 */
  isSpecialCaseTypeText?: string | null;
  /** 案件类型文本 */
  caseCategoryText?: string | null;
  /** 工时提交人名称 */
  submittedByName?: string | null;
  /** 关联案件类型 */
  relatedCaseCategory?: string | null;
  /** 账单编号 */
  billingSerialId?: string | null;
  /** 账单到账状态 */
  billingPaymentStatus?: string | null;
  /**
   * 工作日期
   * @format date-time
   */
  workDate?: string;
  /**
   * 开始时间
   * @format time
   */
  startTime?: string | null;
  /**
   * 结束时间
   * @format time
   */
  endTime?: string | null;
  /**
   * 工作小时数
   * @format double
   */
  hoursWorked?: number;
  /**
   * 可计费小时数
   * @format double
   */
  billableHours?: number;
  /**
   * 业务计费小时数
   * @format double
   */
  businessHours?: number;
  /** 是否计时器计时 */
  isTimerTracked?: string | null;
  /**
   * 小时费率
   * @format double
   */
  billableRate?: number;
  /** 活动代码 */
  activityCode?: string | null;
  /** 货币类型 */
  currency?: string | null;
  /**
   * 税率
   * @format double
   */
  taxRate?: number;
  /** 是否可计费 */
  isBillable?: string | null;
  /**
   * 内部费率
   * @format double
   */
  internalRate?: number;
  /**
   * 成本小时数
   * @format double
   */
  costHours?: number;
  /** 暂代昵称 */
  nickName?: string | null;
  /** 任务 */
  task?: string | null;
  /**
   * 可计费金额
   * @format double
   */
  billableAmount?: number;
  /** 工时类别 */
  category?: string | null;
  /** 客户编号 */
  clientId?: string | null;
  /** 案件编号 */
  caseId?: string | null;
  /** 书写语言 */
  writtenLanguage?: string | null;
  /** 账单主键编号 */
  billingId?: string | null;
  /**
   * 小计金额
   * @format double
   */
  subtotal?: number;
  /** 计时人员ID */
  employeeId?: string | null;
  /** 计时员编号 */
  timekeeperId?: string | null;
  /** 工时内容描述 */
  narrative?: string | null;
  /** 是否收藏 */
  isFavorited?: boolean | null;
  /**
   * 提交时间
   * @format date-time
   */
  submittedOn?: string | null;
  /**
   * 审核通过时间
   * @format date-time
   */
  approvedDate?: string | null;
  /**
   * 累计计时时间
   * @format double
   */
  accumulatedTime?: number;
  /** 计时状态 */
  timerState?: string | null;
  /** 是否跨天拆分 */
  isSplitAcrossDays?: boolean | null;
  /** 跨天拆分前的工时编号 */
  originalAcrossRecordId?: string | null;
  /** 流水编号 */
  serialId?: string | null;
  /** 原系统主键编号 */
  originalId?: string | null;
  /** 备注 */
  remark?: string | null;
  /** 申请人 */
  applicant?: string | null;
  /** 是否草稿 */
  isEnabled?: boolean;
  /** 是否销账 */
  isWriteOff?: string | null;
  /** 销账类型 */
  writeOffType?: string | null;
  /** 销账理由 */
  writeOffRemark?: string | null;
  /** 是否特殊案件工时 */
  isSpecialCaseType?: string | null;
  /** 工时提交人 */
  submittedBy?: string | null;
  /** 是否超时提交 */
  isOverdue?: boolean;
  /** 案件类型 */
  caseCategory?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface GetTimerOutputListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: GetTimerOutput[] | null;
}

/** 通用返回信息类 */
export interface GetTimerOutputMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  response?: GetTimerOutput;
}

/** 计时器跟踪记录输出 */
export interface GetTimerTrackingRecordOutput {
  /** 工时编号 */
  timesheetId?: string | null;
  /**
   * 计时时间
   * @format time
   */
  watchTime?: string;
  /**
   * 计时器开始时间
   * @format time
   */
  timerStart?: string | null;
  /**
   * 计时器结束时间
   * @format time
   */
  timerEnd?: string | null;
  /** 计时状态 */
  timerState?: string | null;
  /**
   * 累计计时时间
   * @format double
   */
  accumulatedTime?: number;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface GetTimerTrackingRecordOutputListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: GetTimerTrackingRecordOutput[] | null;
}

export interface GetTimesheetDetailOutput {
  employeeId?: string | null;
  employeeName?: string | null;
  /** @format date-time */
  workDate?: string;
  /** @format double */
  hoursWorked?: number;
  /** @format double */
  billableHours?: number;
  /** @format double */
  nonBillableHours?: number;
  activityCode?: string | null;
  isBillable?: string | null;
  status?: string | null;
  narrative?: string | null;
}

/** 工时记录输出表 */
export interface GetTimesheetForCalendarOutput {
  /**
   * 开始日期
   * @format date-time
   */
  startDate?: string | null;
  /**
   * 结束日期
   * @format date-time
   */
  endDate?: string | null;
  /** 是否计时器计时 */
  isTimerTrackedText?: string | null;
  /** 货币类型 */
  currencyText?: string | null;
  /** 是否可计费 */
  isBillableText?: string | null;
  /** 客户流水号 */
  clientSerialId?: string | null;
  /** 客户名称 */
  clientName?: string | null;
  /** 案件流水号 */
  caseSerialId?: string | null;
  /** 案件名称 */
  caseName?: string | null;
  /** 书写语言 */
  writtenLanguageText?: string | null;
  employeeName?: string | null;
  /**
   * 工作日期
   * @format date-time
   */
  workDate?: string;
  /**
   * 开始时间
   * @format time
   */
  startTime?: string | null;
  /**
   * 结束时间
   * @format time
   */
  endTime?: string | null;
  /**
   * 工作小时数
   * @format double
   */
  hoursWorked?: number;
  /**
   * 可计费小时数
   * @format double
   */
  billableHours?: number;
  /**
   * 业务计费小时数
   * @format double
   */
  businessHours?: number;
  /** 是否计时器计时 */
  isTimerTracked?: string | null;
  /**
   * 小时费率
   * @format double
   */
  billableRate?: number;
  /** 活动代码 */
  activityCode?: string | null;
  /** 货币类型 */
  currency?: string | null;
  /**
   * 税率
   * @format double
   */
  taxRate?: number;
  /** 是否可计费 */
  isBillable?: string | null;
  /**
   * 内部费率
   * @format double
   */
  internalRate?: number;
  /**
   * 成本小时数
   * @format double
   */
  costHours?: number;
  /** 暂代昵称 */
  nickName?: string | null;
  /** 任务 */
  task?: string | null;
  /**
   * 可计费金额
   * @format double
   */
  billableAmount?: number;
  /** 工时类别 */
  category?: string | null;
  /** 客户编号 */
  clientId?: string | null;
  /** 案件编号 */
  caseId?: string | null;
  /** 书写语言 */
  writtenLanguage?: string | null;
  /** 账单主键编号 */
  billingId?: string | null;
  /**
   * 小计金额
   * @format double
   */
  subtotal?: number;
  /** 计时人员ID */
  employeeId?: string | null;
  /** 计时员编号 */
  timekeeperId?: string | null;
  /** 工时内容描述 */
  narrative?: string | null;
  /** 是否收藏 */
  isFavorited?: boolean | null;
  /**
   * 提交时间
   * @format date-time
   */
  submittedOn?: string | null;
  /**
   * 审核通过时间
   * @format date-time
   */
  approvedDate?: string | null;
  /**
   * 累计计时时间
   * @format double
   */
  accumulatedTime?: number;
  /** 计时状态 */
  timerState?: string | null;
  /** 是否跨天拆分 */
  isSplitAcrossDays?: boolean | null;
  /** 跨天拆分前的工时编号 */
  originalAcrossRecordId?: string | null;
  /** 流水编号 */
  serialId?: string | null;
  /** 原系统主键编号 */
  originalId?: string | null;
  /** 备注 */
  remark?: string | null;
  /** 申请人 */
  applicant?: string | null;
  /** 是否草稿 */
  isEnabled?: boolean;
  /** 是否销账 */
  isWriteOff?: string | null;
  /** 销账类型 */
  writeOffType?: string | null;
  /** 销账理由 */
  writeOffRemark?: string | null;
  /** 是否特殊案件工时 */
  isSpecialCaseType?: string | null;
  /** 工时提交人 */
  submittedBy?: string | null;
  /** 是否超时提交 */
  isOverdue?: boolean;
  /** 案件类型 */
  caseCategory?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface GetTimesheetForCalendarOutputListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: GetTimesheetForCalendarOutput[] | null;
}

/** 创建或修改工时记录入参 */
export interface GetTimesheetForEditOutput {
  /** 货币类型 */
  currencyText?: string | null;
  /** 是否可计费 */
  isBillableText?: string | null;
  /** 客户流水号 */
  clientSerialId?: string | null;
  /** 客户名称 */
  clientName?: string | null;
  /** 案件流水号 */
  caseSerialId?: string | null;
  /** 案件名称 */
  caseName?: string | null;
  /** 书写语言 */
  writtenLanguageText?: string | null;
  /** 计时人员 */
  employeeName?: string | null;
  /** 是否销账文本 */
  isWriteOffText?: string | null;
  /** 销账类型文本 */
  writeOffTypeText?: string | null;
  /**
   * 计时时间
   * @format time
   */
  watchTime?: string;
  /**
   * 计时器开始时间
   * @format time
   */
  timerStart?: string | null;
  /**
   * 计时器结束时间
   * @format time
   */
  timerEnd?: string | null;
  /** 是否LateEntry */
  isOverdue?: boolean;
  /** 是否特殊工时 */
  isSpecialCaseType?: string | null;
  /** 工时隶属案件类型 */
  caseCategory?: string | null;
  /** 工时编号 */
  id?: string | null;
  /**
   * 工作日期
   * @format date-time
   */
  workDate?: string;
  /**
   * 开始时间
   * @format time
   */
  startTime?: string | null;
  /**
   * 结束时间
   * @format time
   */
  endTime?: string | null;
  /**
   * 工作小时数
   * @format double
   */
  hoursWorked?: number;
  /**
   * 内部费率
   * @format double
   */
  internalRate?: number;
  /** 客户编号 */
  clientId?: string | null;
  /** 案件编号 */
  caseId?: string | null;
  /** 书写语言 */
  writtenLanguage?: string | null;
  /** 是否收藏 */
  isFavorited?: boolean | null;
  /**
   * 累计计时时间
   * @format double
   */
  accumulatedTime?: number;
  /** 是否跨天拆分 */
  isSplitAcrossDays?: boolean | null;
  /** 计时人员 */
  employeeId?: string | null;
  /** 计时状态 */
  timerState?: string | null;
  /** 工时描述 */
  narrative?: string | null;
  /** 工时类别 */
  category?: string | null;
  /** 暂代昵称 */
  nickName?: string | null;
  /** 关联任务 */
  task?: string | null;
  /** 活动代码 */
  activityCode?: string | null;
  /** 货币类型 */
  currency?: string | null;
  /** 是否计时器计时 */
  isTimerTracked?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 通用返回信息类 */
export interface GetTimesheetForEditOutputMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 创建或修改工时记录入参 */
  response?: GetTimesheetForEditOutput;
}

/** 工时记录输出表 */
export interface GetTimesheetInfoOutput {
  /** 是否计时器计时 */
  isTimerTrackedText?: string | null;
  /** 货币类型 */
  currencyText?: string | null;
  /** 是否可计费 */
  isBillableText?: string | null;
  /** 客户流水号 */
  clientSerialId?: string | null;
  /** 客户名称 */
  clientName?: string | null;
  /** 案件流水号 */
  caseSerialId?: string | null;
  /** 案件名称 */
  caseName?: string | null;
  /** 书写语言 */
  writtenLanguageText?: string | null;
  /** 计时人员 */
  employeeName?: string | null;
  /** 是否销账文本 */
  isWriteOffText?: string | null;
  /** 销账类型文本 */
  writeOffTypeText?: string | null;
  /**
   * 计时时间
   * @format time
   */
  watchTime?: string;
  /**
   * 计时器开始时间
   * @format time
   */
  timerStart?: string | null;
  /**
   * 计时器结束时间
   * @format time
   */
  timerEnd?: string | null;
  /** 账单编号 */
  billingSerialId?: string | null;
  /** 账单到账状态 */
  billingPaymentStatus?: string | null;
  /**
   * 工作日期
   * @format date-time
   */
  workDate?: string;
  /**
   * 开始时间
   * @format time
   */
  startTime?: string | null;
  /**
   * 结束时间
   * @format time
   */
  endTime?: string | null;
  /**
   * 工作小时数
   * @format double
   */
  hoursWorked?: number;
  /**
   * 可计费小时数
   * @format double
   */
  billableHours?: number;
  /**
   * 业务计费小时数
   * @format double
   */
  businessHours?: number;
  /** 是否计时器计时 */
  isTimerTracked?: string | null;
  /**
   * 小时费率
   * @format double
   */
  billableRate?: number;
  /** 活动代码 */
  activityCode?: string | null;
  /** 货币类型 */
  currency?: string | null;
  /**
   * 税率
   * @format double
   */
  taxRate?: number;
  /** 是否可计费 */
  isBillable?: string | null;
  /**
   * 内部费率
   * @format double
   */
  internalRate?: number;
  /**
   * 成本小时数
   * @format double
   */
  costHours?: number;
  /** 暂代昵称 */
  nickName?: string | null;
  /** 任务 */
  task?: string | null;
  /**
   * 可计费金额
   * @format double
   */
  billableAmount?: number;
  /** 工时类别 */
  category?: string | null;
  /** 客户编号 */
  clientId?: string | null;
  /** 案件编号 */
  caseId?: string | null;
  /** 书写语言 */
  writtenLanguage?: string | null;
  /** 账单主键编号 */
  billingId?: string | null;
  /**
   * 小计金额
   * @format double
   */
  subtotal?: number;
  /** 计时人员ID */
  employeeId?: string | null;
  /** 计时员编号 */
  timekeeperId?: string | null;
  /** 工时内容描述 */
  narrative?: string | null;
  /** 是否收藏 */
  isFavorited?: boolean | null;
  /**
   * 提交时间
   * @format date-time
   */
  submittedOn?: string | null;
  /**
   * 审核通过时间
   * @format date-time
   */
  approvedDate?: string | null;
  /**
   * 累计计时时间
   * @format double
   */
  accumulatedTime?: number;
  /** 计时状态 */
  timerState?: string | null;
  /** 是否跨天拆分 */
  isSplitAcrossDays?: boolean | null;
  /** 跨天拆分前的工时编号 */
  originalAcrossRecordId?: string | null;
  /** 流水编号 */
  serialId?: string | null;
  /** 原系统主键编号 */
  originalId?: string | null;
  /** 备注 */
  remark?: string | null;
  /** 申请人 */
  applicant?: string | null;
  /** 是否草稿 */
  isEnabled?: boolean;
  /** 是否销账 */
  isWriteOff?: string | null;
  /** 销账类型 */
  writeOffType?: string | null;
  /** 销账理由 */
  writeOffRemark?: string | null;
  /** 是否特殊案件工时 */
  isSpecialCaseType?: string | null;
  /** 工时提交人 */
  submittedBy?: string | null;
  /** 是否超时提交 */
  isOverdue?: boolean;
  /** 案件类型 */
  caseCategory?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface GetTimesheetInfoOutputMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 工时记录输出表 */
  response?: GetTimesheetInfoOutput;
}

/** 工时记录表 */
export interface GetTimesheetInput {
  /** 关键字 */
  filter?: string | null;
  /** 客户号/客户名称 */
  clientId?: string[] | null;
  /** 案号/案件名称 */
  caseId?: string[] | null;
  /** 日期时间范围均使用 */
  workDate?: RangeDateTimeInput;
  /** 工时状态 */
  status?: string[] | null;
  /** 是否计费 */
  isBillable?: string[] | null;
  /** 特殊工时类型 */
  caseCategory?: string[] | null;
  /** 是否销账 */
  isWriteOff?: string[] | null;
  /** 销账类型 */
  writeOffType?: string[] | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 导出功能输入的参数 */
export interface GetTimesheetInputExportInput {
  selectColumns?: SetColumn[] | null;
  /** 工时记录表 */
  search?: GetTimesheetInput;
  fileName?: string | null;
  extended?: string | null;
  /** 选择Id */
  checkedIds?: string[] | null;
  /**
   * 导出类型
   * @format int32
   */
  exportType?: 0 | 1;
}

/** 工时暂代名称表 */
export interface GetTimesheetNickNameForEditOutput {
  /** 暂代名称 */
  nickName?: string | null;
  /** 人员编号 */
  employeeId?: string | null;
  /** 客户编号 */
  clientId?: string | null;
  /** 案件编号 */
  caseId?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 表格数据，支持分页 */
export interface GetTimesheetNickNameForEditOutputForEditModel {
  /** 工时暂代名称表 */
  data?: GetTimesheetNickNameForEditOutput;
  /** 实体映射 */
  mapping?: Record<string, any>;
}

/** 通用返回信息类 */
export interface GetTimesheetNickNameForEditOutputForEditModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 表格数据，支持分页 */
  response?: GetTimesheetNickNameForEditOutputForEditModel;
}

/** 工时暂代名称表 */
export interface GetTimesheetNickNameInput {
  /** 暂代名称 */
  nickName?: string | null;
  /** 人员编号 */
  employeeId?: string | null;
  /** 客户编号 */
  clientId?: string | null;
  /** 案件编号 */
  caseId?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 导出功能输入的参数 */
export interface GetTimesheetNickNameInputExportInput {
  selectColumns?: SetColumn[] | null;
  /** 工时暂代名称表 */
  search?: GetTimesheetNickNameInput;
  fileName?: string | null;
  extended?: string | null;
  /** 选择Id */
  checkedIds?: string[] | null;
  /**
   * 导出类型
   * @format int32
   */
  exportType?: 0 | 1;
}

/** 工时暂代名称表 */
export interface GetTimesheetNickNameOutput {
  /** 暂代名称 */
  nickName?: string | null;
  /** 人员编号 */
  employeeId?: string | null;
  /** 客户编号 */
  clientId?: string | null;
  /** 案件编号 */
  caseId?: string | null;
  /** 备注 */
  remark?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface GetTimesheetNickNameOutputListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: GetTimesheetNickNameOutput[] | null;
}

/** 通用分页信息类 */
export interface GetTimesheetNickNameOutputPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: GetTimesheetNickNameOutput[] | null;
}

/** 通用返回信息类 */
export interface GetTimesheetNickNameOutputPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: GetTimesheetNickNameOutputPageModel;
}

/** 工时记录输出表 */
export interface GetTimesheetOutput {
  /** 是否计时器计时 */
  isTimerTrackedText?: string | null;
  /** 货币类型 */
  currencyText?: string | null;
  /** 是否可计费 */
  isBillableText?: string | null;
  /** 客户流水号 */
  clientSerialId?: string | null;
  /** 客户名称 */
  clientName?: string | null;
  /** 案件流水号 */
  caseSerialId?: string | null;
  /** 案件名称 */
  caseName?: string | null;
  /** 书写语言 */
  writtenLanguageText?: string | null;
  employeeSerialId?: string | null;
  /** 计时人员 */
  employeeName?: string | null;
  /** 计时人员邮箱 */
  employeeEmailAddress?: string | null;
  /** 是否销账文本 */
  isWriteOffText?: string | null;
  /** 销账类型文本 */
  writeOffTypeText?: string | null;
  /** 是否特殊案件工时文本 */
  isSpecialCaseTypeText?: string | null;
  /** 案件类型文本 */
  caseCategoryText?: string | null;
  /** 工时提交人名称 */
  submittedByName?: string | null;
  /** 关联案件类型 */
  relatedCaseCategory?: string | null;
  /** 账单编号 */
  billingSerialId?: string | null;
  /** 账单到账状态 */
  billingPaymentStatus?: string | null;
  /**
   * 工作日期
   * @format date-time
   */
  workDate?: string;
  /**
   * 开始时间
   * @format time
   */
  startTime?: string | null;
  /**
   * 结束时间
   * @format time
   */
  endTime?: string | null;
  /**
   * 工作小时数
   * @format double
   */
  hoursWorked?: number;
  /**
   * 可计费小时数
   * @format double
   */
  billableHours?: number;
  /**
   * 业务计费小时数
   * @format double
   */
  businessHours?: number;
  /** 是否计时器计时 */
  isTimerTracked?: string | null;
  /**
   * 小时费率
   * @format double
   */
  billableRate?: number;
  /** 活动代码 */
  activityCode?: string | null;
  /** 货币类型 */
  currency?: string | null;
  /**
   * 税率
   * @format double
   */
  taxRate?: number;
  /** 是否可计费 */
  isBillable?: string | null;
  /**
   * 内部费率
   * @format double
   */
  internalRate?: number;
  /**
   * 成本小时数
   * @format double
   */
  costHours?: number;
  /** 暂代昵称 */
  nickName?: string | null;
  /** 任务 */
  task?: string | null;
  /**
   * 可计费金额
   * @format double
   */
  billableAmount?: number;
  /** 工时类别 */
  category?: string | null;
  /** 客户编号 */
  clientId?: string | null;
  /** 案件编号 */
  caseId?: string | null;
  /** 书写语言 */
  writtenLanguage?: string | null;
  /** 账单主键编号 */
  billingId?: string | null;
  /**
   * 小计金额
   * @format double
   */
  subtotal?: number;
  /** 计时人员ID */
  employeeId?: string | null;
  /** 计时员编号 */
  timekeeperId?: string | null;
  /** 工时内容描述 */
  narrative?: string | null;
  /** 是否收藏 */
  isFavorited?: boolean | null;
  /**
   * 提交时间
   * @format date-time
   */
  submittedOn?: string | null;
  /**
   * 审核通过时间
   * @format date-time
   */
  approvedDate?: string | null;
  /**
   * 累计计时时间
   * @format double
   */
  accumulatedTime?: number;
  /** 计时状态 */
  timerState?: string | null;
  /** 是否跨天拆分 */
  isSplitAcrossDays?: boolean | null;
  /** 跨天拆分前的工时编号 */
  originalAcrossRecordId?: string | null;
  /** 流水编号 */
  serialId?: string | null;
  /** 原系统主键编号 */
  originalId?: string | null;
  /** 备注 */
  remark?: string | null;
  /** 申请人 */
  applicant?: string | null;
  /** 是否草稿 */
  isEnabled?: boolean;
  /** 是否销账 */
  isWriteOff?: string | null;
  /** 销账类型 */
  writeOffType?: string | null;
  /** 销账理由 */
  writeOffRemark?: string | null;
  /** 是否特殊案件工时 */
  isSpecialCaseType?: string | null;
  /** 工时提交人 */
  submittedBy?: string | null;
  /** 是否超时提交 */
  isOverdue?: boolean;
  /** 案件类型 */
  caseCategory?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用分页信息类 */
export interface GetTimesheetOutputPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: GetTimesheetOutput[] | null;
}

/** 通用返回信息类 */
export interface GetTimesheetOutputPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: GetTimesheetOutputPageModel;
}

export interface GetTimesheetReportInput {
  /** 客户号/客户名称 */
  clientId?: string[] | null;
  /** 案号/案件名称 */
  caseId?: string[] | null;
  /** 案件状态 */
  caseFlowStatus?: string[] | null;
  /** 日期时间范围均使用 */
  workDate?: RangeDateTimeInput;
  /** 计时人员 */
  employeeId?: string[] | null;
  /** 人员角色组 */
  roleId?: string[] | null;
  /** 工时状态 */
  status?: string[] | null;
  /** 是否计费 */
  isBillable?: string[] | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

export interface GetTimesheetSubtotalInput {
  /** 日期时间范围均使用 */
  workDate?: RangeDateTimeInput;
  /** 计时人员 */
  employeeId?: string[] | null;
  /** @format double */
  minimumDailyRecordedHours?: number | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

export interface GetTimesheetSubtotalOutput {
  /**
   * 工作日期
   * @format date-time
   */
  workDate?: string;
  /**
   * 数量
   * @format int32
   */
  count?: number;
  /**
   * 记录小时数
   * @format double
   */
  recordedHours?: number;
  /**
   * 目标要求小时数
   * @format double
   */
  targetHours?: number;
  /**
   * 缺失小时数
   * @format double
   */
  missingHours?: number;
  /**
   * 账单小时数
   * @format double
   */
  billableHours?: number;
  /**
   * 非账单小时数
   * @format double
   */
  nonBillableHours?: number;
  /** 状态小计 */
  statusTotal?: StautsTotalDto[] | null;
  /** 草稿待提交的工时编号 */
  inCompleteIds?: string[] | null;
}

/** 通用返回信息类 */
export interface GetTimesheetSubtotalOutputIEnumerableMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: GetTimesheetSubtotalOutput[] | null;
}

export interface GetTotalHoursAndListOutput {
  /** @format double */
  totalHours?: number;
  timesheetItem?: GetDetailReportByTimekeeperAndWorkDateOutput[] | null;
}

/** 通用返回信息类 */
export interface GetTotalHoursAndListOutputMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  response?: GetTotalHoursAndListOutput;
}

export interface GetUserInfoInput {
  /** 用户名/名字/邮箱地址 */
  filter?: string | null;
  /** 姓名 */
  name?: string | null;
  /** 工号 */
  jobNumber?: string | null;
  /** 手机号 */
  phoneNumber?: string | null;
  /**
   * 用户类型
   * @format int32
   */
  userType?: 0 | 1 | 2 | null;
  /**
   * 人员状态
   * @format int32
   */
  userStatus?: 0 | 1 | 2 | null;
  /** 邮箱 */
  emailAddress?: string | null;
  /** 部门 */
  organizationUnitId?: string[] | null;
  /** 角色 */
  roleIds?: string[] | null;
  /** 角色 */
  roleId?: string | null;
  /** 角色 */
  roleName?: string | null;
  /** 状态 */
  isActive?: boolean | null;
  /** 日期时间范围均使用 */
  createTime?: RangeDateTimeInput;
  /** 是否合伙人 */
  isParent?: boolean | null;
  /** 是否权益合伙人 */
  isEquityPartner?: boolean | null;
  /** 业务计时人员标识 */
  timekeeperId?: string | null;
  /** 日期时间范围均使用 */
  lastLoginTime?: RangeDateTimeInput;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

export interface GetUserLoginAttemptsInput {
  /** 客户端Ip所在地区 */
  clientIpRegion?: string | null;
  /** 用户名 */
  userName?: string | null;
  /** 手机号 */
  phoneNumber?: string | null;
  /** 客户端名称 */
  clientName?: string | null;
  /**
   * 开始时间
   * @format date-time
   */
  startTime?: string | null;
  /**
   * 结束时间
   * @format date-time
   */
  endTime?: string;
  /** 客户端设备信息 */
  clientDeviceInfo?: string | null;
  /** 是否在异地登录 */
  isLoginFromAnotherLocation?: boolean | null;
  /** 是否常用设备登录 */
  isLoginFromInCommonUseClient?: boolean | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 用户手册内容 */
export interface GetUserManualContentForEditOutput {
  /** 模块ID */
  userManualModuleId?: string | null;
  /** 内容 */
  content?: string | null;
  /** 简介 */
  brief?: string | null;
  /** 关键词 */
  keyWords?: string | null;
  /**
   * 访问量
   * @format int32
   */
  pageview?: number;
  /** 备注 */
  remark?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 表格数据，支持分页 */
export interface GetUserManualContentForEditOutputForEditModel {
  /** 用户手册内容 */
  data?: GetUserManualContentForEditOutput;
  /** 实体映射 */
  mapping?: Record<string, any>;
}

/** 通用返回信息类 */
export interface GetUserManualContentForEditOutputForEditModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 表格数据，支持分页 */
  response?: GetUserManualContentForEditOutputForEditModel;
}

/** 用户手册内容 */
export interface GetUserManualContentInput {
  /** 模块ID */
  userManualModuleId?: string | null;
  /** 内容 */
  content?: string | null;
  /** 简介 */
  brief?: string | null;
  /** 关键词 */
  keyWords?: string | null;
  /**
   * 访问量
   * @format int32
   */
  pageview?: number | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 用户手册内容 */
export interface GetUserManualContentOutput {
  /** 模块ID */
  userManualModuleId?: string | null;
  /** 模块名称 */
  userManualModuleName?: string | null;
  /** 内容 */
  content?: string | null;
  /** 简介 */
  brief?: string | null;
  /** 关键词 */
  keyWords?: string | null;
  /**
   * 访问量
   * @format int32
   */
  pageview?: number;
  /** 备注 */
  remark?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface GetUserManualContentOutputListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: GetUserManualContentOutput[] | null;
}

/** 通用分页信息类 */
export interface GetUserManualContentOutputPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: GetUserManualContentOutput[] | null;
}

/** 通用返回信息类 */
export interface GetUserManualContentOutputPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: GetUserManualContentOutputPageModel;
}

/** 用户手册问题 */
export interface GetUserManualFAQForEditOutput {
  /** id */
  userManualContentId?: string | null;
  /** 模块ID */
  userManualModuleId?: string | null;
  /** 主题 */
  question?: string | null;
  /** 答案 */
  answer?: string | null;
  /**
   * 排序
   * @format int32
   */
  sort?: number;
  /** 备注 */
  remark?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 表格数据，支持分页 */
export interface GetUserManualFAQForEditOutputForEditModel {
  /** 用户手册问题 */
  data?: GetUserManualFAQForEditOutput;
  /** 实体映射 */
  mapping?: Record<string, any>;
}

/** 通用返回信息类 */
export interface GetUserManualFAQForEditOutputForEditModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 表格数据，支持分页 */
  response?: GetUserManualFAQForEditOutputForEditModel;
}

/** 用户手册问题 */
export interface GetUserManualFAQInput {
  /** id */
  userManualContentId?: string | null;
  /** 模块ID */
  userManualModuleId?: string | null;
  /** 主题 */
  question?: string | null;
  /** 答案 */
  answer?: string | null;
  /**
   * 排序
   * @format int32
   */
  sort?: number | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 用户手册问题 */
export interface GetUserManualFAQOutput {
  /** id */
  userManualContentId?: string | null;
  /** 模块ID */
  userManualModuleId?: string | null;
  /** 模块名称 */
  userManualModuleName?: string | null;
  /** 主题 */
  question?: string | null;
  /** 答案 */
  answer?: string | null;
  /**
   * 排序
   * @format int32
   */
  sort?: number;
  /** 备注 */
  remark?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface GetUserManualFAQOutputListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: GetUserManualFAQOutput[] | null;
}

/** 通用分页信息类 */
export interface GetUserManualFAQOutputPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: GetUserManualFAQOutput[] | null;
}

/** 通用返回信息类 */
export interface GetUserManualFAQOutputPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: GetUserManualFAQOutputPageModel;
}

/** 用户手册模块 */
export interface GetUserManualModuleForEditOutput {
  /** 名称 */
  name?: string | null;
  /** 父级ID */
  parentId?: string | null;
  /** 主题 */
  topic?: string | null;
  /** 视频 */
  video?: string | null;
  /** 视频时长 */
  videoDuration?: string | null;
  /** 封面 */
  picture?: string | null;
  /** 业务描述 */
  description?: string | null;
  /** 汇总描述 */
  summary?: string | null;
  /** 菜单链接 */
  website?: string | null;
  /**
   * 排序
   * @format int32
   */
  sort?: number;
  /** 是否是菜单 */
  isMenu?: string | null;
  /** 语言 */
  language?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 表格数据，支持分页 */
export interface GetUserManualModuleForEditOutputForEditModel {
  /** 用户手册模块 */
  data?: GetUserManualModuleForEditOutput;
  /** 实体映射 */
  mapping?: Record<string, any>;
}

/** 通用返回信息类 */
export interface GetUserManualModuleForEditOutputForEditModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 表格数据，支持分页 */
  response?: GetUserManualModuleForEditOutputForEditModel;
}

/** 用户手册模块 */
export interface GetUserManualModuleInput {
  /** 名称 */
  name?: string | null;
  /** 父级ID */
  parentId?: string | null;
  /** 主题 */
  topic?: string | null;
  /** 视频 */
  video?: string | null;
  /** 视频时长 */
  videoDuration?: string | null;
  /** 封面 */
  picture?: string | null;
  /** 业务描述 */
  description?: string | null;
  /** 汇总描述 */
  summary?: string | null;
  /** 菜单链接 */
  website?: string | null;
  /**
   * 排序
   * @format int32
   */
  sort?: number | null;
  /** 是否是菜单 */
  isMenu?: string | null;
  /** 语言 */
  language?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 用户手册模块 */
export interface GetUserManualModuleOutput {
  /** 名称 */
  name?: string | null;
  /** 父级ID */
  parentId?: string | null;
  /** 主题 */
  topic?: string | null;
  /** 视频 */
  video?: string | null;
  /** 视频时长 */
  videoDuration?: string | null;
  /** 封面 */
  picture?: string | null;
  /** 业务描述 */
  description?: string | null;
  /** 汇总描述 */
  summary?: string | null;
  /** 菜单链接 */
  website?: string | null;
  /**
   * 排序
   * @format int32
   */
  sort?: number;
  /** 是否是菜单 */
  isMenu?: string | null;
  /** 语言 */
  language?: string | null;
  /** 备注 */
  remark?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface GetUserManualModuleOutputListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: GetUserManualModuleOutput[] | null;
}

/** 通用分页信息类 */
export interface GetUserManualModuleOutputPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: GetUserManualModuleOutput[] | null;
}

/** 通用返回信息类 */
export interface GetUserManualModuleOutputPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: GetUserManualModuleOutputPageModel;
}

export interface GetUserSelectInput {
  /**
   * 用户类型
   * @format int32
   */
  userType?: 0 | 1 | 2 | null;
  /**
   * 人员状态
   * @format int32
   */
  userStatus?: 0 | 1 | 2 | null;
  /** 角色 */
  roleIds?: string[] | null;
  /**
   * 案件编号
   *
   * 填入后案件参案律师排在前面
   */
  caseId?: string[] | null;
  /** 状态 */
  isActive?: boolean | null;
  /** 是否合伙人 */
  isParent?: boolean | null;
  /** 是否权益合伙人 */
  isEquityPartner?: boolean | null;
  /** 业务计时人员标识 */
  timekeeperId?: string | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 关键字 */
  filter?: string | null;
  /** 是否查询所务客户 */
  isInternal?: boolean | null;
  /** 日期时间范围均使用 */
  lastUpdate?: RangeDateTimeInput;
  /** id集合 */
  ids?: string[] | null;
  /** 只返回基础Model */
  onlyReturnBaseEntity?: boolean;
}

export interface GetWeekTimesheetInput {
  /** 关键字 */
  filter?: string | null;
  /** 日期时间范围均使用 */
  workDate?: RangeDateTimeInput;
  /** 计时人员 */
  employeeId?: string[] | null;
  /** 工时状态 */
  status?: string[] | null;
  /** 是否已计费 */
  isBillable?: string[] | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 周工时分组记录输出表 */
export interface GetWeekTimesheetOutput {
  /** 案件编号 */
  caseId?: string | null;
  /** 客户编号 */
  clientId?: string | null;
  /** 暂代昵称 */
  nickName?: string | null;
  /** 客户流水号 */
  clientSerialId?: string | null;
  /** 客户名称 */
  clientName?: string | null;
  /** 案件流水号 */
  caseSerialId?: string | null;
  /** 案件名称 */
  caseName?: string | null;
  /**
   * 工时数量
   * @format int32
   */
  count?: number;
  /**
   * 记录小时数
   * @format double
   */
  recordedHours?: number;
  /**
   * 账单小时数
   * @format double
   */
  billableHours?: number;
  /** 工时内容描述 */
  narrative?: string | null;
  /** 周工时记录 */
  timesheetList?: GetTimesheetOutput[] | null;
  /** 周工作记录列表 */
  dayHourList?: DayHoursDto[] | null;
}

/** 通用返回信息类 */
export interface GetWeekTimesheetOutputIEnumerableMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: GetWeekTimesheetOutput[] | null;
}

export interface GetWorkingHoursSummaryOutput {
  /** 人员编号 */
  employeeId?: string | null;
  /** 计时人员计时编号 */
  employeeSerialId?: string | null;
  /** 计时人员名称 */
  employeeName?: string | null;
  /** 计时人员邮箱 */
  employeeEmailAddress?: string | null;
  /**
   * 不计费（及时提交）
   * Non-billable (Timely Submission)
   * @format double
   */
  nonBillableTimelySubmission?: number;
  /**
   * 不计费（合理延迟提交）
   * Non-billable (Reasonably Late Submission)
   * @format double
   */
  nonBillableReasonablyLateSubmission?: number;
  /**
   * 不计费（无理延迟提交）
   * Non-billable (Unreasonably Late Submission)
   * @format double
   */
  nonBillableUnreasonablyLateSubmission?: number;
  /**
   * NCM（特别提交）
   * NCM (Special Submission)
   * @format double
   */
  ncmSpecialSubmission?: number;
  /**
   * 非计费总小时数
   * Total Non-billable Hours
   * @format double
   */
  totalNonBillableHours?: number;
  /**
   * 可计费（及时提交）
   * Billable (Timely Submission)
   * @format double
   */
  billableTimelySubmission?: number;
  /**
   * 可计费（合理延迟提交）
   * Billable (Reasonably Late Submission)
   * @format double
   */
  billableReasonablyLateSubmission?: number;
  /**
   * 可计费（无理延迟提交）
   * Billable (Unreasonably Late Submission)
   * @format double
   */
  billableUnreasonablyLateSubmission?: number;
  /**
   * 计费总小时数
   * Total Billable Hours
   * @format double
   */
  totalBillableHours?: number;
  /**
   * Total Hours(excluding HKM)
   * 总小时数（不包括HKM）
   * @format double
   */
  totalHoursExcludingHkm?: number;
  /**
   * HKM (HK Admin MGT)
   * HKM（香港行政管理类案件工时）
   * @format double
   */
  totalHoursHkm?: number;
}

/** 通用返回信息类 */
export interface GetWorkingHoursSummaryOutputListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: GetWorkingHoursSummaryOutput[] | null;
}

export interface GetWriteOffExpenseInput {
  /** 费用编号 */
  ids?: string[] | null;
  /** 是否销账 */
  isWriteOff?: string | null;
  /** 销账类型 */
  writeOffType?: string | null;
  /** 销账理由 */
  writeOffRemark?: string | null;
}

export interface GetWriteOffTimesheetInput {
  /** 工时编号 */
  ids?: string[] | null;
  /** 是否销账 */
  isWriteOff?: string | null;
  /** 销账类型 */
  writeOffType?: string | null;
  /** 销账理由 */
  writeOffRemark?: string | null;
}

export interface GroupInfoDto {
  /** 客户名称 */
  name?: string | null;
  /** 外文名称 */
  foreignName?: string | null;
  /** 曾用名 */
  formerName?: string | null;
  /** 别名 */
  altName?: string | null;
  /** 客户类型 */
  clientType?: string | null;
  /** 证件类型 */
  creditType?: string | null;
  /** 证件号码 */
  creditCode?: string | null;
  /** 来源类型（立案/入库/投标） */
  originType?: string | null;
  /** 来源编号 */
  originId?: string | null;
  /** 通讯地址 */
  address?: string | null;
  /** 税号 */
  taxNumber?: string | null;
  /** 邮箱地址 */
  emailAddress?: string | null;
  /** 手机号码 */
  phoneNumber?: string | null;
  /** 固定电话 */
  landline?: string | null;
  /** 描述说明 */
  narrative?: string | null;
  /** 客户级别（星标客户/重要客户等） */
  clientLevel?: string | null;
  /** 客户性质 */
  customerNature?: string | null;
  /** 股东/实控人存入EntityInfo */
  entityInfosInput?: CreateEntityInfoInput[] | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface GroupInfoDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: GroupInfoDto[] | null;
}

/** 通用分页信息类 */
export interface GroupInfoDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: GroupInfoDto[] | null;
}

/** 通用返回信息类 */
export interface GroupInfoDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: GroupInfoDtoPageModel;
}

export interface HostUserInfoDto {
  /** 时区 */
  timeZone?: string | null;
  /** 登录账号 */
  userName?: string | null;
  /** 显示名称 */
  displayName?: string | null;
  /** 编号 */
  serialId?: string | null;
  /** 工号 */
  jobNumber?: string | null;
  /** 别名 */
  alterName?: string | null;
  /** 手机号 */
  phoneNumber?: string | null;
  /** 头像地址 */
  pictureUrl?: string | null;
  /** 邮箱 */
  emailAddress?: string | null;
  /** 姓名 */
  name?: string | null;
  /** 邮箱是否已验证 */
  isEmailConfirmed?: boolean;
  /** 账号锁定是否开启 */
  isLockoutEnabled?: boolean;
  /** 是否手机号已验证 */
  isPhoneNumberConfirmed?: boolean;
  /** 是否启用多因子验证 */
  isTwoFactorEnabled?: boolean;
  /** 部门 */
  organizationUnitName?: string | null;
  /** 分所 */
  officeName?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 权限组 ， */
  roleNames?: string[] | null;
  roleIds?: string[] | null;
  /**
   * 用户组
   * 岗位
   */
  positionNames?: string[] | null;
  /** 岗位 */
  positionIds?: string[] | null;
  /** 用户组 */
  userGroupNames?: string[] | null;
  userGroupIds?: string[] | null;
  /**
   * 用户状态
   * @format int32
   */
  userStatus?: 0 | 1 | 2;
  /** 用户状态 */
  userStatusText?: string | null;
  /** 是否启用 */
  isActive?: boolean;
  /** 业务计时人员Id */
  timekeeperId?: string | null;
  /** 是否合伙人 */
  isParent?: boolean | null;
  /** 是否权益合伙人 */
  isEquityPartner?: boolean | null;
  /**
   * 入职时间
   * @format date-time
   */
  entryTime?: string | null;
  /**
   * 最后登录时间
   * @format date-time
   */
  lastLoginTime?: string | null;
  /** 组织机构Id */
  organizationUnitId?: string | null;
  /**
   * 用户类型
   * @format int32
   */
  userType?: 0 | 1 | 2;
  userTypeText?: string | null;
  /** 费率 */
  employeeRates?: EmployeeRatesDto[] | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface HostUserInfoDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  response?: HostUserInfoDto;
}

export interface IdAndSerialIdDto {
  /** 主键ID */
  id?: string | null;
  /** 流水号 */
  serialId?: string | null;
}

export interface ImportCaseRelPartyDto {
  /** 关联方类型 */
  category?: string | null;
  /** 关联方名称 */
  name?: string | null;
  /** 外文名称 */
  foreignName?: string | null;
  /** 证件类型 */
  creditType?: string | null;
  /** 证件号码 */
  creditCode?: string | null;
}

/** 通用返回信息类 */
export interface ImportCaseRelPartyDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: ImportCaseRelPartyDto[] | null;
}

/** 国标行业 */
export interface IndustryInfo {
  /** 行业门类code */
  industryCode?: string | null;
  /** 行业门类描述 */
  industry?: string | null;
  /** 行业大类code */
  subIndustryCode?: string | null;
  /** 行业大类描述 */
  subIndustry?: string | null;
  /** 行业中类code */
  middleCategoryCode?: string | null;
  /** 行业中类描述 */
  middleCategory?: string | null;
  /** 行业小类code */
  smallCategoryCode?: string | null;
  /** 行业小类描述 */
  smallCategory?: string | null;
}

/** 通用返回信息类 */
export interface Int32MessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /**
   * 返回数据集合
   * @format int32
   */
  response?: number;
}

export interface InvalidateInvoiceApplyDto {
  /** 发票申请Id */
  id?: string | null;
  /** 原因 */
  reason?: string | null;
  /** 发票附件 */
  invoiceAttachmentList?: GetFinancialInvoiceAttachmentOutput[] | null;
}

export interface InvalidateInvoiceDto {
  /** 发票D */
  id?: string | null;
  /** 原因 */
  reason?: string | null;
  /** 发票附件 */
  attachmentId?: string | null;
  /** 发票编号 */
  invoiceNo?: string | null;
  /** 发票附件 */
  invoiceAttachmentList?: AttachmentDownloadDto[] | null;
}

/** 对外投资（返回前100条） */
export interface InvestmentListItem {
  /** 被投资企业主键 */
  keyNo?: string | null;
  /** 被投资企业名称 */
  name?: string | null;
  /** 成立日期，精确到天，如“2022-01-01” */
  startDate?: string | null;
  /** 登记状态 */
  status?: string | null;
  /** 持股比例 */
  fundedRatio?: string | null;
  /** 认缴出资额/持股数 */
  shouldCapi?: string | null;
  /** 国标行业 */
  industry?: IndustryInfo;
  /** 行政区域 */
  area?: Area;
}

export interface ItemDto {
  /** Key值 */
  key?: string | null;
  /** 冗余字段 */
  keyField?: string | null;
  /** 预留 */
  value?: string | null;
  /** 描述 */
  description?: string | null;
  /** 值分隔符 */
  codeFieldSeparator?: string | null;
  /** 显示文本分隔符 */
  textFieldSeparator?: string | null;
}

/** 通用返回信息类 */
export interface ItemDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: ItemDto[] | null;
}

export interface LanguageDto {
  /** 图标 */
  icon?: string | null;
  /** 语言名称 */
  name?: string | null;
  /** 语言代码 */
  code?: string | null;
  /** 显示名称 */
  displayName?: string | null;
  /** 是否默认语言 */
  isDefault?: boolean;
  /** 是否启用该语言 */
  isEnabled?: boolean;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface LanguageDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: LanguageDto[] | null;
}

export interface LanguageKeyDto {
  /** Key值 */
  key?: string | null;
  /** 模块 */
  module?: string | null;
  /**
   * 语言平台
   * @format int32
   */
  source?: 0 | 1 | 2;
  sourceText?: string | null;
  /** 语言合集 */
  languageTexts?: LanguageTextDto[] | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用分页信息类 */
export interface LanguageKeyDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: LanguageKeyDto[] | null;
}

/** 通用返回信息类 */
export interface LanguageKeyDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: LanguageKeyDtoPageModel;
}

export interface LanguageTextDto {
  /** 语言代码 */
  code?: string | null;
  /** 显示名称 */
  displayName?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

export interface LawyerAllocationItem {
  /**
   * 分配金额
   * @format double
   */
  amount?: number;
  /**
   * 分配比例
   * @format double
   */
  allocationRate?: number;
  /**
   * 尾差
   * @format double
   */
  roundingDifference?: number;
  /**
   * 薪酬金额
   * @format double
   */
  remunerationAmount?: number;
  /**
   * 薪酬比例
   * @format double
   */
  remunerationRate?: number;
  /**
   * 案源费比例
   * @format double
   */
  caseSourceRate?: number;
  /**
   * 案源费分配金额
   * @format double
   */
  caseSourceAmount?: number;
  /** 是否是案源费 */
  isSourceFee?: string | null;
  /** 是否是案源费 */
  isSourceFeeText?: string | null;
  /** 参案角色 */
  lawyerRole?: string | null;
  /**
   * 分配基数
   * @format double
   */
  allocationBase?: number;
  /** 币种 */
  currency?: string | null;
  /** 币种文本 */
  currencyText?: string | null;
}

export interface LdapConfig {
  isEnabled?: boolean;
  ldapIp?: string | null;
  /** @format int32 */
  port?: number;
  isLdap?: boolean;
  userDn?: string | null;
  baseDn?: string | null;
  password?: string | null;
  roleTable?: string | null;
  organizationTable?: string | null;
  isCronSyncLdapToCurrentSystem?: boolean;
  isLdapAuth?: boolean;
  userObjectClass?: string | null;
  userLdapLoginIdentify?: string | null;
  organizationUnitObjectClass?: string | null;
  roleObjectClass?: string | null;
  roleFilterTable?: string | null;
  ldapFieldMatchRules?: LdapFieldMatchRule[] | null;
  userFilterRule?: string | null;
  notSyncUserEmails?: string[] | null;
}

/** 通用返回信息类 */
export interface LdapConfigMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  response?: LdapConfig;
}

export interface LdapFieldMatchRule {
  fieldName?: string | null;
  fieldDescription?: string | null;
  /** @format int32 */
  ldapOuType?: 0 | 1 | 2;
  ldapFieldName?: string | null;
  fieldTips?: string | null;
  isIdentify?: boolean;
}

export interface LoginValidateConfig {
  /** 会话超时策略 */
  isSessionTimeout?: boolean;
  /**
   * 用户在（N）分钟内没有操作，退出当前账号到锁屏界面
   * @format int32
   */
  sessionTimeoutTime?: number | null;
  /** 敏感会话超时策略 */
  sensitiveSessionTimeout?: boolean;
  /**
   * 用户在（N）分钟内没有操作，退出当前账号
   * @format int32
   */
  sensitiveSessionTimeoutTime?: number | null;
  /** 账号锁定策略 */
  isAccountLock?: boolean;
  /**
   * 账号锁定时间
   * @format int32
   */
  accountLockTime?: number | null;
  /**
   * 触发验证码
   * @format int32
   */
  validateCodeNumber?: number | null;
  /**
   * 触发锁定
   * @format int32
   */
  lockNumber?: number | null;
  /**
   * 账号锁定时长重置
   * @format int32
   */
  accountLockResetTime?: number | null;
  /** 账号停用策略 */
  isAccountStop?: boolean;
  /** 最近登录提示 */
  isLastLoginHint?: boolean;
  /** 登录验证提示 */
  isLoginValidateHint?: boolean;
  /** 登录验证提示文本内容 */
  loginValidateHint?: string | null;
}

/** 经纬度 */
export interface LongLat {
  /** 经度 */
  longitude?: string | null;
  /** 纬度 */
  latitude?: string | null;
}

export interface MergeOrSplitTimesheetFeeItemDto {
  /**
   * 工时日期
   * @format date-time
   */
  workDate?: string | null;
  /** 工时填报人 */
  employeeId?: string | null;
  /**
   * 小时费率
   * @format double
   */
  hourlyRate?: number;
  /** 任务 */
  task?: string | null;
  /** 活动代码 */
  activityCode?: string | null;
  /** 币种 */
  currency?: string | null;
  /**
   * 币种汇率
   * @format double
   */
  exchangeRate?: number;
  /**
   * 计费小时
   * @format double
   */
  billableHours?: number;
  /** 工时类型 */
  category?: string | null;
  /** 调整类型 */
  adjustmentType?: string | null;
  /**
   * 调整比例
   * @format double
   */
  adjustmentRate?: number;
  /**
   * 调整额
   * @format double
   */
  adjustment?: number;
  /**
   * 税率
   * @format double
   */
  taxRate?: number;
  /**
   * 税额
   * @format double
   */
  taxAmount?: number;
  /**
   * 金额
   * @format double
   */
  amount?: number;
  /**
   * 外币服务费金额
   * @format double
   */
  foreignAmount?: number;
  /**
   * 外币小计金额
   * @format double
   */
  foreignSubtotal?: number;
  /**
   * 小计金额
   * @format double
   */
  subtotal?: number;
}

export interface MergeOrSplitTimesheetItemDto {
  /**
   * 工时日期
   * @format date-time
   */
  workDate?: string | null;
  /**
   * 开始时间
   * @format time
   */
  startTime?: string | null;
  /**
   * 结束时间
   * @format time
   */
  endTime?: string | null;
  /**
   * 工作小时数
   * @format double
   */
  hoursWorked?: number;
  /** 工时填报人 */
  employeeId?: string | null;
  /** 客户编号 */
  clientId?: string | null;
  /** 案件编号 */
  caseId?: string | null;
  /** 暂代昵称 */
  nickName?: string | null;
  /** 工时描述 */
  narrative?: string | null;
}

/** 合并组织机构 */
export interface MergeOrganizationInput {
  /** 原组织机构 */
  organizationUnitId?: string | null;
  /** 合并组织机构 */
  mergeOrganizationUnitId?: string | null;
}

export interface MergeTimesheetInput {
  /** 合并工时编号集合 */
  ids?: string[] | null;
  timesheetInfo?: MergeOrSplitTimesheetItemDto;
}

export interface MessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功 */
  success?: boolean;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回信息 */
  msg?: string | null;
  /** 返回数据集合 */
  response?: any;
}

/** 杂项或其他偏好设置 */
export interface MiscellaneousPreferences {
  /** 缺省默认币种 */
  defaultCurrency?: string | null;
  /** 是否启用前端自动保存 */
  isEnabledAutoSave?: boolean;
  /**
   * 自动保存间隔(分钟)
   * @format double
   */
  autoSaveInterval?: number;
}

/** 更多邮箱 */
export interface MoreEmailListItem {
  /** 邮箱 */
  email?: string | null;
  /** 来源，如“互联网” */
  source?: string | null;
}

/** 更多电话 */
export interface MoreTelListItem {
  /** 电话 */
  tel?: string | null;
  /** 来源，如“互联网” */
  source?: string | null;
}

export interface MyCsrRequestConfirmOutput {
  /** 利冲号 */
  serialId?: string | null;
  /** 利冲适用规则 */
  csrRule?: string | null;
  /** 利冲适用规则 */
  csrRuleText?: string | null;
  /** 利冲名称 */
  name?: string | null;
  /** 客户名称 */
  clientName?: string | null;
  /** 客户外文名称 */
  clientForeignName?: string | null;
  /** 客户股东实控人名称 */
  clientShareholderActualCtrlName?: string | null;
  /** 客户股东实控人外文名称 */
  clientShareholderActualCtrlForeignName?: string | null;
  /** 参与实体客户 */
  clientParticipatingEntityForeignName?: string | null;
  /** 参与实体 */
  clientParticipatingEntityName?: string | null;
  /** 对方当事人 */
  oppositeName?: string | null;
  /** 对方当事人外文名称 */
  oppositeForeignName?: string | null;
  /** 对方股东实控人名称 */
  oppositeShareholderActualCtrlName?: string | null;
  /** 对方股东实控人外文名称 */
  oppositeShareholderActualCtrlForeignName?: string | null;
  /** 对方参与实体名称 */
  oppositeParticipatingEntityName?: string | null;
  /** 对方参与实体外文名称 */
  oppositeParticipatingEntityForeignName?: string | null;
  /** 潜在第三方名称 */
  potential3rdPartyName?: string | null;
  /** 潜在第三方外文名称 */
  potential3rdPartyForeignName?: string | null;
  /** 潜在第三方股东实控人 */
  potential3rdPartyShareholderActualCtrlName?: string | null;
  /** 潜在第三方股东实控人外文名称 */
  potential3rdPartyShareholderActualCtrlForeignName?: string | null;
  /** 利冲案件类型 */
  category?: string | null;
  /** 利冲案件类型文本 */
  categoryText?: string | null;
  /** 是否紧急 */
  isUrgent?: string | null;
  /** 是否紧急文本 */
  isUrgentText?: string | null;
  /** 利冲转立案Id */
  caseId?: string | null;
  /** 利冲转立案编号 */
  caseSerialId?: string | null;
  /** 关联案件名称 */
  caseName?: string | null;
  /**
   * 申请时间
   * @format date-time
   */
  submittedOn?: string | null;
  /** 申请合伙人 */
  applicant?: string | null;
  /** 申请合伙人 */
  applicantName?: string | null;
  /** 利冲关键词 */
  csrKeywordList?: string[] | null;
  /** 利冲关键词 */
  csrKeywords?: string | null;
  /** 与我冲突的角色 */
  casePartyNameList?: string[] | null;
  /** 与我冲突的角色 */
  casePartyNames?: string | null;
  /**
   * 与我冲突的案件数量
   * @format int32
   */
  caseCount?: number;
  /**
   * 确认时间
   * @format date-time
   */
  confirmTime?: string | null;
  /**
   * 利冲审查进度
   * @format double
   */
  rateOfProgress?: number;
  /** 关联案件Id */
  relationCaseId?: string | null;
  /** 关联案件名称 */
  relationCaseName?: string | null;
  /** 关联案件编号 */
  relationCaseSerialId?: string | null;
  /** 确认状态 */
  confirmStatus?: string | null;
  /** 检索json */
  caseRelatedPartyListJson?: string | null;
  /** 业务状态   列表不处理，只处理导出 */
  flowState?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用分页信息类 */
export interface MyCsrRequestConfirmOutputPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: MyCsrRequestConfirmOutput[] | null;
}

/** 通用返回信息类 */
export interface MyCsrRequestConfirmOutputPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: MyCsrRequestConfirmOutputPageModel;
}

export interface NameValue {
  name?: string | null;
  value?: string | null;
}

export interface NavigationBar {
  id?: string | null;
  pid?: string | null;
  /** @format int32 */
  order?: number;
  name?: string | null;
  isHide?: boolean;
  isButton?: boolean;
  path?: string | null;
  func?: string | null;
  iconCls?: string | null;
  meta?: NavigationBarMeta;
  children?: NavigationBar[] | null;
}

/** 通用返回信息类 */
export interface NavigationBarMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  response?: NavigationBar;
}

export interface NavigationBarMeta {
  title?: string | null;
  requireAuth?: boolean;
  noTabPage?: boolean;
  keepAlive?: boolean;
}

export interface NavigationBarMetaPro {
  title?: string | null;
  icon?: string | null;
  show?: boolean;
}

export interface NavigationBarPro {
  id?: string | null;
  parentId?: string | null;
  /** @format int32 */
  order?: number;
  name?: string | null;
  isHide?: boolean;
  isButton?: boolean;
  path?: string | null;
  component?: string | null;
  func?: string | null;
  iconCls?: string | null;
  meta?: NavigationBarMetaPro;
}

/** 通用返回信息类 */
export interface NavigationBarProListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: NavigationBarPro[] | null;
}

/** 通用返回信息类 */
export interface ObjectMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: any;
}

export interface OfficesCurrency {
  /** 分所Id */
  officeId?: string | null;
  /** 分所本位币 */
  currency?: string | null;
  /** 分所外币 */
  foreignCurrency?: string | null;
}

export interface OrderByModel {
  fieldName?: any;
  /** @format int32 */
  orderByType?: 0 | 1;
}

export interface OrganizationLeaderDto {
  /** 用户名 */
  userName?: string | null;
  /** 用户Id */
  userId?: string | null;
  /** 是否是主负责人 */
  isHost?: boolean;
}

export interface OrganizationUnitsDto {
  id?: string | null;
  /** 机构键值 */
  code?: string | null;
  /** 显示名称 */
  displayName?: string | null;
  /** 别名 */
  alterName?: string | null;
  /** 地区代码 */
  regionCode?: string | null;
  /** 父级Id */
  parentId?: string | null;
  /** 父级名称 */
  parentName?: string | null;
  /**
   * 默认排序
   * @format int32
   */
  sort?: number;
  /**
   * 阈值标识
   * @format int32
   */
  thresholdFlag?: 0 | 1 | 2 | 3;
  /** 机构类型 */
  thresholdFlagName?: string | null;
  /** 地理位置 */
  location?: string | null;
  /** 工作地点 */
  workAddress?: string | null;
  /** 是否启用 */
  isActive?: boolean;
  /** 是否虚拟机构 */
  isVirtual?: boolean;
  /** 机构描述 */
  remark?: string | null;
  enterpriseInfo?: EnterpriseBaseInfoDto;
  /** 负责人 */
  organizationLeaders?: OrganizationLeaderDto[] | null;
}

/** 通用返回信息类 */
export interface OrganizationUnitsDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  response?: OrganizationUnitsDto;
}

/** 通用分页信息类 */
export interface OrganizationUnitsDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: OrganizationUnitsDto[] | null;
}

/** 通用返回信息类 */
export interface OrganizationUnitsDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: OrganizationUnitsDtoPageModel;
}

/** 负责人 */
export interface OrganizationUnitsTreeDto {
  /** 子节点 */
  children?: OrganizationUnitsTreeDto[] | null;
  /** 是否拥有权限 */
  isCheck?: boolean;
  id?: string | null;
  /** 机构键值 */
  code?: string | null;
  /** 显示名称 */
  displayName?: string | null;
  /** 别名 */
  alterName?: string | null;
  /** 地区代码 */
  regionCode?: string | null;
  /** 父级Id */
  parentId?: string | null;
  /** 父级名称 */
  parentName?: string | null;
  /**
   * 默认排序
   * @format int32
   */
  sort?: number;
  /**
   * 阈值标识
   * @format int32
   */
  thresholdFlag?: 0 | 1 | 2 | 3;
  /** 机构类型 */
  thresholdFlagName?: string | null;
  /** 地理位置 */
  location?: string | null;
  /** 工作地点 */
  workAddress?: string | null;
  /** 是否启用 */
  isActive?: boolean;
  /** 是否虚拟机构 */
  isVirtual?: boolean;
  /** 机构描述 */
  remark?: string | null;
  enterpriseInfo?: EnterpriseBaseInfoDto;
  /** 负责人 */
  organizationLeaders?: OrganizationLeaderDto[] | null;
}

/** 通用返回信息类 */
export interface OrganizationUnitsTreeDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 负责人 */
  response?: OrganizationUnitsTreeDto;
}

/** 曾用名 */
export interface OriginalName {
  /** 曾用名 */
  name?: string | null;
  /** 变更日期 */
  changeDate?: string | null;
}

/** 总公司 */
export interface Parent {
  /** 主键 */
  keyNo?: string | null;
  /** 名称 */
  name?: string | null;
  /** 法定代表人 */
  operName?: string | null;
  /** 成立日期，精确到天，如“2022-01-01” */
  startDate?: string | null;
  /** 登记状态 */
  status?: string | null;
  /** 注册资本 */
  registCapi?: string | null;
}

/** 工商登记股东信息（返回前100条） */
export interface PartnerListItem {
  /** 主键 */
  keyNo?: string | null;
  /** 投资人名称 */
  stockName?: string | null;
  /** 投资人类型 */
  stockType?: string | null;
  /** 持股比例 */
  stockPercent?: string | null;
  /** 认缴出资额/持股数 */
  shouldCapi?: string | null;
  /** 认缴出资日期，精确到天，如“2022-01-01” */
  shoudDate?: string | null;
  /** 首次持股日期，精确到天，如“2022-01-01” */
  stakeDate?: string | null;
  /** 统一社会信用代码 */
  creditCode?: string | null;
  /** 国籍（或地区）/注册地 */
  area?: string | null;
}

export interface PasswordConfig {
  /** 密码设置策略 */
  isSetting?: boolean;
  /**
   * 最小密码长度
   * @format int32
   */
  minPasswordLength?: number | null;
  /** 在密码中使用 */
  passwordType?: string[] | null;
  /** 允许字符连续出现 */
  isContinuousCharacterNumber?: boolean | null;
  /**
   * 连续字符
   * @format int32
   */
  continuousCharacterNumber?: number | null;
  /** 不允许与历史密码相同 */
  isHistoryPasswordsSame?: boolean | null;
  /**
   * 历史密码不重复范围
   * @format int32
   */
  historyPasswordNumber?: number | null;
  /** 密码有效期 */
  isPasswordExpiration?: boolean | null;
  /**
   * 密码有效期天数
   * @format int32
   */
  passwordExpirationTime?: number | null;
}

export interface PayerInfoOutput {
  /** 客户类型 */
  clientTypeText?: string | null;
  /** 客户行业 */
  clientIndustryText?: string | null;
  /** 证件类型 */
  creditTypeText?: string | null;
  /** 客户性质 */
  customerNatureText?: string | null;
  /** 客户名称 */
  clientName?: string | null;
  /** 客户名称英文 */
  clientForeignName?: string | null;
  /** 客户流水号 */
  clientSerialId?: string | null;
  /** 发票抬头信息 */
  entityInvoiceTitles?: GetEntityInvoiceTitleOutput[] | null;
  /** 客户名称 */
  name?: string | null;
  /** 外文名称 */
  foreignName?: string | null;
  /** 曾用名 */
  formerName?: string | null;
  /** 别名 */
  altName?: string | null;
  /** 账户 */
  account?: string | null;
  /** 开户行 */
  openingBank?: string | null;
  /** 客户类型 */
  clientType?: string | null;
  /** 客户行业 */
  clientIndustryId?: string | null;
  /** 证件类型 */
  creditType?: string | null;
  /** 证件号码 */
  creditCode?: string | null;
  /** 通讯地址 */
  address?: string | null;
  /** 税号 */
  taxNumber?: string | null;
  /** 邮箱地址 */
  emailAddress?: string | null;
  /** 手机号码 */
  phoneNumber?: string | null;
  /** 固定电话 */
  landline?: string | null;
  /** 描述说明 */
  narrative?: string | null;
  /** 母公司名称 */
  parentCompanyName?: string | null;
  /** 母公司外文名称 */
  parentCompanyForeignName?: string | null;
  /** 母公司证件类型 */
  parentCompanyCreditType?: string | null;
  /** 母公司证件号码 */
  parentCompanyCreditCode?: string | null;
  /** 客户性质 */
  customerNature?: string | null;
  /** 所属客户 */
  clientId?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface PayerInfoOutputMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  response?: PayerInfoOutput;
}

/** 通用分页信息类 */
export interface PayerInfoOutputPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: PayerInfoOutput[] | null;
}

/** 通用返回信息类 */
export interface PayerInfoOutputPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: PayerInfoOutputPageModel;
}

export interface PermissionTree {
  code?: string | null;
  value?: string | null;
  pid?: string | null;
  label?: string | null;
  /** @format int32 */
  order?: number;
  isBtn?: boolean;
  isHide?: boolean | null;
  disabled?: boolean;
  isCheck?: boolean;
  children?: PermissionTree[] | null;
  btnList?: PermissionTree[] | null;
}

/** 通用返回信息类 */
export interface PermissionTreeMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  response?: PermissionTree;
}

export interface PluginItem {
  value?: string | null;
  title?: string | null;
}

/** 通用返回信息类 */
export interface PluginItemListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: PluginItem[] | null;
}

/** 产品列表（返回前100条） */
export interface ProductListItem {
  /** 产品名称 */
  name?: string | null;
  /** 成立日期，精确到天，如“2022-01-01” */
  startDate?: string | null;
  /** 融资信息 */
  roundDesc?: string | null;
  /** 所属地 */
  location?: string | null;
  /** 产品介绍 */
  description?: string | null;
}

export interface Property {
  name?: string | null;
  value?: string | null;
}

/** 最新公示主要人员（返回前100条） */
export interface PubEmployeeListItem {
  /** 名称 */
  name?: string | null;
  /** 职务 */
  job?: string | null;
}

/** 最新公示股东信息（返回前100条） */
export interface PubPartnerListItem {
  /** 股东名称 */
  stockName?: string | null;
  /** 持股比例 */
  stockPercent?: string | null;
  /** 持股类型，1-股数；0-金额 */
  holdType?: string | null;
  /** HoldType=1：持股数(股)；HoldType=0：股本(万元) */
  amount?: string | null;
  /** 统一社会信用代码 */
  creditCode?: string | null;
  /** 国籍（或地区）/注册地 */
  area?: string | null;
}

/** 企查查行业 */
export interface QccIndustry {
  /** 一级分类名称 */
  aName?: string | null;
  /** 二级分类名称 */
  bName?: string | null;
  /** 三级分类名称 */
  cName?: string | null;
  /** 四级分类名称 */
  dName?: string | null;
}

/** 日期时间范围均使用 */
export interface RangeDateTimeInput {
  /**
   * 开始日期时间
   * @format date-time
   */
  startDate?: string | null;
  /**
   * 结束日期时间
   * @format date-time
   */
  endDate?: string | null;
}

/** 数值范围选择器 */
export interface RangeDecimalInput {
  /**
   * 最小金额
   * @format double
   */
  minValue?: number | null;
  /**
   * 最大金额
   * @format double
   */
  maxValue?: number | null;
}

export interface ReceiptImportOutput {
  /** 导入Id */
  importId?: string | null;
  /** 通用分页信息类 */
  pageData?: CreateFinancialPublicReceiptInputPageModel;
}

/** 通用返回信息类 */
export interface ReceiptImportOutputMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  response?: ReceiptImportOutput;
}

/** 关联案件限制 */
export interface RelationCaseLimitationInput {
  /** 是否激活 */
  isActive?: boolean;
  /** 限制案件类型 */
  caseCategoryList?: string[] | null;
}

export interface RequestAuditLogDto {
  /** 请求参数 */
  requestBodyData?: string | null;
  /** 错误信息 堆栈信息 */
  exceptionMessage?: string | null;
  /** Url参数 */
  parameters?: string | null;
  /** 浏览器信息 */
  browserInfo?: string | null;
  /** 客户端IP */
  clientIdAddress?: string | null;
  /** 客户端名称 */
  clientName?: string | null;
  /** 请求方法 */
  httpMethod?: string | null;
  /**
   * 执行耗时 毫秒
   * @format double
   */
  executionDuration?: number;
  /** 是否异常 */
  isException?: boolean;
  /** 模拟登录 */
  isSimulation?: boolean;
  /** 请求地址 */
  requestUrl?: string | null;
  /** 错误信息 */
  exception?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface RequestAuditLogDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  response?: RequestAuditLogDto;
}

export interface RequestAuditLogListDto {
  /** 浏览器信息 */
  browserInfo?: string | null;
  /** 客户端IP */
  clientIdAddress?: string | null;
  /** 客户端名称 */
  clientName?: string | null;
  /** 请求方法 */
  httpMethod?: string | null;
  /**
   * 执行耗时 毫秒
   * @format double
   */
  executionDuration?: number;
  /** 是否异常 */
  isException?: boolean;
  /** 模拟登录 */
  isSimulation?: boolean;
  /** 请求地址 */
  requestUrl?: string | null;
  /** 错误信息 */
  exception?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用分页信息类 */
export interface RequestAuditLogListDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: RequestAuditLogListDto[] | null;
}

/** 通用返回信息类 */
export interface RequestAuditLogListDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: RequestAuditLogListDtoPageModel;
}

/** 注销吊销信息 */
export interface RevokeInfo {
  /** 注销日期 */
  cancelDate?: string | null;
  /** 注销原因 */
  cancelReason?: string | null;
  /** 吊销日期 */
  revokeDate?: string | null;
  /** 吊销原因 */
  revokeReason?: string | null;
}

export interface RoleDto {
  /** 角色名 */
  name?: string | null;
  /** 显示名称 */
  alterName?: string | null;
  /** 描述 */
  description?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 是否独占 */
  isMonopoly?: boolean | null;
  /**
   * 权限类型
   * @format int32
   */
  roleType?: 0 | 1 | 2 | 3;
  roleTypeName?: string | null;
  /** 用户组Id */
  roleGroupId?: string | null;
  /** 是否启用 */
  isActive?: boolean;
  /** 是否默认角色 */
  isDefault?: boolean;
  /** 是否虚拟角色 */
  isVirtual?: boolean;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface RoleDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: RoleDto[] | null;
}

/** 通用返回信息类 */
export interface RoleDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  response?: RoleDto;
}

/** 通用分页信息类 */
export interface RoleDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: RoleDto[] | null;
}

/** 通用返回信息类 */
export interface RoleDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: RoleDtoPageModel;
}

export interface RunningTimerInput {
  /** 计时工时编号 */
  ids?: string[] | null;
  /**
   * 计时器状态
   * @format int32
   */
  timerState?: 0 | 1 | 2 | 3 | 4 | 5;
}

export interface SaveImportOutput {
  /** 信息 */
  message?: string | null;
  /** 错误数据 */
  errorList?: CreateFinancialPublicReceiptInput[] | null;
}

/** 通用返回信息类 */
export interface SaveImportOutputMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  response?: SaveImportOutput;
}

/** 定时任务偏好设置 */
export interface ScheduledTaskPreferences {
  /** 是否开启自动提交 */
  isEnabledAutoSubmitForMonthly?: boolean;
  /**
   * 自动提交时间
   * @format int32
   */
  autoSubmitTime?: number;
  /** 是否开启自动状态变更 */
  isEnabledAutoChangeState?: boolean;
  /**
   * 自动变更状态时间
   * @format int32
   */
  autoChangeStateTime?: number;
}

/** 利冲管理检索条件 */
export interface SearchProcessCasePartyProcessInput {
  /** 利冲编号 */
  csrId?: string | null;
  /** 利冲审查检索编号 */
  csrProcessId?: string | null;
  /**
   * 搜索模式
   * @format int32
   */
  searchMode?: 0 | 1 | 2 | 3;
  /** 是否启用法律意义上检索 */
  isEnabledLegalSenseSearch?: boolean | null;
  /** 是否中文分词检索 */
  isSplitWordSearch?: boolean;
  /** 是否外文分词检索 */
  isForeignSplitWordSearch?: boolean;
  /** 是否启用利冲偏好检索 */
  isPreferences?: boolean | null;
  /** 利冲偏好Id合集 */
  preferencesIds?: string[] | null;
  /** 利冲关系列表 */
  casePartyList?: SearchProcessCasePartyResultDto[] | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

/** 利冲信息-检索条件列表-条件 */
export interface SearchProcessCasePartyResultDto {
  /** 利冲方编号 */
  id?: string | null;
  /** 是否中文分词检索 */
  isSplitWordSearch?: boolean | null;
  /** 是否开启外文分词检索 */
  isForeignSplitWordSearch?: boolean | null;
  /** 类别 */
  category?: string | null;
  /** 类别文本 */
  categoryText?: string | null;
  /** 中文名称 */
  name?: string | null;
  /** 英文名称 */
  foreignName?: string | null;
  /** 证件类型 */
  creditType?: string | null;
  /** 证件类型文本 */
  creditTypeText?: string | null;
  /** 证件号码 */
  creditCode?: string | null;
  /** 检索关键字（中文名称） */
  keywords?: string[] | null;
  /** 检索关键字（英文名称） */
  foreignKeywords?: string[] | null;
  clientId?: string | null;
  groupId?: string | null;
  groupAltName?: string | null;
  groupName?: string | null;
  /** 排序 */
  sort?: string | null;
}

/** 通用返回信息类 */
export interface SearchProcessCasePartyResultDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: SearchProcessCasePartyResultDto[] | null;
}

/** 二级分类 */
export interface SecondaryListItem {
  /** 二级分类Code */
  secondaryCode?: string | null;
  /** 二级分类描述 */
  secondaryDes?: string | null;
  /** 三级分类 */
  tertiaryList?: TertiaryListItem[] | null;
}

export interface SensitiveConfig {
  /** 多因子验证 */
  isMultiFactor?: boolean;
  /** 虚拟MFA */
  isMFA?: boolean;
  /** 短信 */
  issms?: boolean;
  /** 邮箱 */
  isEmail?: boolean;
  /** 操作 */
  isOperator?: boolean;
}

export interface SequenceNumberRulesDto {
  /** 编码名称 */
  name?: string | null;
  /** 所属表单 */
  tableName?: string | null;
  /** 编码字段 */
  field?: string | null;
  /** 段间分隔符 */
  separator?: string | null;
  /**
   * 应用规则
   * @format int32
   */
  applyRulesType?: 0 | 1;
  /** 编码依据变化时重新编码 */
  isChange?: boolean;
  /** 允许断号补偿 */
  isCompensate?: boolean;
  /**
   * 补位衬垫位置
   * @format int32
   */
  paddingSideType?: 0 | 1;
  /**
   * 起始值时间
   * @format date-time
   */
  startTime?: string;
  /** 数据集合 */
  dataset?: string | null;
  /** 是否启用 */
  isActive?: boolean;
  /** 流水号预览 */
  sequenceNumberPreview?: string | null;
  /** 流水号单据编码配置 */
  sequenceNumberSettings?: SequenceNumberSettingsDto[] | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface SequenceNumberRulesDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  response?: SequenceNumberRulesDto;
}

/** 通用分页信息类 */
export interface SequenceNumberRulesDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: SequenceNumberRulesDto[] | null;
}

/** 通用返回信息类 */
export interface SequenceNumberRulesDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: SequenceNumberRulesDtoPageModel;
}

export interface SequenceNumberRulesOutput {
  sequenceNumberRules?: SequenceNumberRulesDto;
  /** 实体名称 */
  entityName?: string | null;
  /** 实体描述 */
  entityDescription?: string | null;
  /** 字段名称 */
  fieldName?: string | null;
  /** 字段描述 */
  description?: string | null;
  /** 数据源键 */
  dataSourceKey?: string | null;
  /**
   * 数据类型
   * @format int32
   */
  fieldDataType?: 0 | 1 | 2 | 3 | 4;
}

/** 通用分页信息类 */
export interface SequenceNumberRulesOutputPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: SequenceNumberRulesOutput[] | null;
}

/** 通用返回信息类 */
export interface SequenceNumberRulesOutputPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: SequenceNumberRulesOutputPageModel;
}

export interface SequenceNumberSettingsDto {
  /**
   * 分段类型
   * @format int32
   */
  sectionType?: 0 | 1 | 2 | 3 | 4;
  /** 常量内容 */
  value?: string | null;
  /**
   * 日期格式
   * @format int32
   */
  dateFormat?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;
  /**
   * 补位衬垫位置
   * @format int32
   */
  paddingSide?: 0 | 1;
  /**
   * 文本包裹符
   * @format int32
   */
  textWrapperStyle?: 0 | 40 | 60 | 123 | 12298 | 12300 | 12302 | 12304 | 65288;
  /**
   * 是否财年日期
   *
   * 仅分段类型为日期时有效
   */
  isFiscalYear?: boolean;
  /**
   * 流水号长度
   * @format int32
   */
  length?: number;
  /**
   * 起始值
   * @format int32
   */
  startValue?: number;
  /** 单位 */
  unit?: string | null;
  /** 是否自动补位 */
  isAutomaticFilling?: boolean;
  /** 是否开启子号设置 */
  isSubNumber?: boolean;
  /**
   * 子号长度
   * @format int32
   */
  subNumberLength?: number;
  /**
   * 子号补位衬垫位置
   * @format int32
   */
  subNumberPaddingSide?: 0 | 1;
  /**
   * 子号起始值
   * @format int32
   */
  subNumberStartValue?: number;
  /** 子号单位 */
  subNumberUnit?: string | null;
  /** 字段来源 */
  fieldSource?: string | null;
  /** 字段来源 */
  fieldSourceList?: FieldSourceDto[] | null;
  /** 代值设置 */
  substitutionValue?: string | null;
  /** 代值设置 */
  substitutionList?: SubstitutionDto[] | null;
  /** 是否流水号依据 */
  isBasedOn?: boolean;
  /** 是否业务字段依据 */
  isFieldBasedOn?: boolean;
  /** 是否起始值依据 */
  isStartBasedOn?: boolean;
  /** 是否启用 */
  isEnabled?: boolean;
}

/** 设置选中 */
export interface SetCSRProcessPerferenceIdsInput {
  /** serialId */
  id?: string | null;
  csrId?: string | null;
  isChecked?: boolean;
  tagName?: string | null;
}

/** 导出页面设置的列 */
export interface SetColumn {
  field?: string | null;
  title?: string | null;
  /** @format int32 */
  rowspan?: number;
  /** @format int32 */
  colspan?: number;
  hidden?: boolean;
  isChild?: boolean;
  format?: string | null;
}

export interface SetSequenceNumberRulesStatus {
  /** Id */
  id?: string | null;
  /** 是否激活 */
  isActive?: boolean;
}

export interface SettingDto {
  /** 配置名 */
  name?: string | null;
  /** 值 */
  value?: string | null;
  /**
   * 配置级别
   * @format int32
   */
  settingScopes?: 1 | 2 | 4;
  tenantTypeEnum?: string | null;
  /** 租户Id */
  tenantId?: string | null;
  /** 租户名 */
  tenantName?: string | null;
  /** 用户Id */
  userId?: string | null;
  /** 用户名 */
  userName?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用分页信息类 */
export interface SettingDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: SettingDto[] | null;
}

/** 通用返回信息类 */
export interface SettingDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: SettingDtoPageModel;
}

export interface SettingLanguageDto {
  /** 语言 */
  languageNames?: ComboboxItemDto[] | null;
  /** 图标 */
  flags?: ComboboxItemDto[] | null;
}

/** 通用返回信息类 */
export interface SettingLanguageDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  response?: SettingLanguageDto;
}

export interface SplitTimesheetInput {
  /** 工时编号 */
  id?: string | null;
  /** 拆分工时明细 */
  timesheetItems?: MergeOrSplitTimesheetItemDto[] | null;
}

/** 抽查检查（返回前100条） */
export interface SpotCheckListItem {
  /** 检查实施机关 */
  executiveOrg?: string | null;
  /** 类型 */
  type?: string | null;
  /** 日期，精确到天，如“2022-01-01” */
  date?: string | null;
  /** 结果 */
  consequence?: string | null;
}

export interface StautsTotalDto {
  /**
   * 记录工时数量
   * @format int32
   */
  count?: number;
  /**
   * 记录小时数
   * @format double
   */
  recordedHours?: number;
  /**
   * 账单小时数
   * @format double
   */
  billableHours?: number;
  /**
   * 非账单小时数
   * @format double
   */
  nonBillableHours?: number;
  name?: string | null;
  value?: string | null;
}

/** 通用返回信息类 */
export interface StautsTotalDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: StautsTotalDto[] | null;
}

/** 上市信息 */
export interface StockInfo {
  /** 股票代码（如A股和港股同时存在，优先显示A股代码） */
  stockNumber?: string | null;
  /** 上市类型（A股、港股、美股、新三板、新四板） */
  stockType?: string | null;
}

export interface StringEntityDto {
  id?: string | null;
}

/** 通用返回信息类 */
export interface StringFlowStateDtoListDictionaryMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: Record<string, FlowStateDto[] | null>;
}

/** 通用返回信息类 */
export interface StringListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: string[] | null;
}

/** 通用返回信息类 */
export interface StringMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: string | null;
}

/** 代值 */
export interface SubstitutionDto {
  /** 字段值 */
  fieldValues?: FieldValueDto[] | null;
  /** 代值 */
  substitutionValue?: string | null;
  /**
   * 起始值
   * @format int32
   */
  startValue?: number;
}

export interface SupplierDto {
  /** 供应商类型 */
  supplierTypes?: (0 | 1 | 2 | 3)[] | null;
  /** 名称 */
  name?: string | null;
  /** 联系人 */
  contact?: string | null;
  /** 联系电话 */
  telephone?: string | null;
  /** 联系地址 */
  address?: string | null;
  /** 邮箱 */
  email?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用分页信息类 */
export interface SupplierDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: SupplierDto[] | null;
}

/** 通用返回信息类 */
export interface SupplierDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: SupplierDtoPageModel;
}

export interface SupplierPluginDto {
  /** 插件名称 */
  pluginName?: string | null;
  /** 插件类型 */
  pluginType?: string | null;
  /** 供应商id */
  supplierId?: string | null;
  /** 供应商名称 */
  supplierName?: string | null;
  /** 供应商插件类别名称 */
  pluginTypeNames?: string | null;
  ico?: string | null;
  /** 状态 */
  status?: boolean;
  /** 配置字段 */
  fieldsJson?: string | null;
  remark?: string | null;
  /** @format date-time */
  modifyTime?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 流程状态 */
  flowState?: string | null;
}

/** 通用分页信息类 */
export interface SupplierPluginDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: SupplierPluginDto[] | null;
}

/** 通用返回信息类 */
export interface SupplierPluginDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: SupplierPluginDtoPageModel;
}

/** App版本 */
export interface SysAppEditions {
  /**
   * 平台
   * @format int32
   */
  appPlatform?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  /** App版本 */
  appVersion?: string | null;
  /** 是否上线 */
  isOnline?: boolean;
  /**
   * 更新时间
   * @format date-time
   */
  updateVersionTime?: string;
  /** 更新日志 */
  context?: string | null;
  /** 是否独占 */
  isExclusive?: boolean;
  attachments?: SysAttachments;
  /** 附件地址 */
  attachmentsId?: string | null;
  /** 雪花Id */
  id?: string | null;
  /** 租户ID */
  tenantId?: string | null;
  /**
   * 系统租户表
   *
   * 根据TenantType 分为两种方案:
   *
   * 1.按租户字段区分
   *
   * 2.按租户分库
   *
   *
   *
   * 注意:
   *
   * 使用租户Id方案,无需配置分库的连接
   */
  tenant?: SysTenant;
  /** 部门ID  DepartmentId */
  organizationUnitId?: string | null;
  /** 组织机构 */
  organizationUnit?: SysOrganizationUnits;
  /** 分所Id */
  officeId?: string | null;
  /** 组织机构 */
  office?: SysOrganizationUnits;
  /** 备注 */
  remark?: string | null;
  /**
   * 是否草稿
   * <param name="如果是操作传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
  /**
   * 中立字段，某些表可使用某些表不使用
   *
   * 逻辑上的删除，非物理删除
   *
   * 例如：单据删除并非直接删除
   */
  isDeleted?: boolean;
  /**
   * 中立字段
   *
   * 是否内置数据
   */
  isInternal?: boolean;
  /** 创建ID */
  createId?: string | null;
  /** 创建者 */
  createBy?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 修改ID */
  modifyId?: string | null;
  /** 更新者 */
  modifyBy?: string | null;
  /**
   * 修改日期
   * @format date-time
   */
  modifyTime?: string | null;
  /**
   * 数据版本
   * @format int64
   */
  version?: number;
  /** 删除ID */
  deleteId?: string | null;
  /** 删除者 */
  deleteBy?: string | null;
  /**
   * 删除日期
   * @format date-time
   */
  deleteTime?: string | null;
}

export interface SysAttachments {
  /** FileName */
  name?: string | null;
  /**
   * 文件大小
   * @format int32
   */
  size?: number;
  /** 文件路径 */
  path?: string | null;
  /** 文件扩展名 */
  extension?: string | null;
  /** 文件哈希值 */
  hash?: string | null;
  /**
   * 文件状态
   * @format int32
   */
  status?: 0 | 1;
  /**
   * 文件排序
   * @format int32
   */
  sort?: number;
  /** 雪花Id */
  id?: string | null;
  /** 租户ID */
  tenantId?: string | null;
  /**
   * 系统租户表
   *
   * 根据TenantType 分为两种方案:
   *
   * 1.按租户字段区分
   *
   * 2.按租户分库
   *
   *
   *
   * 注意:
   *
   * 使用租户Id方案,无需配置分库的连接
   */
  tenant?: SysTenant;
  /** 部门ID  DepartmentId */
  organizationUnitId?: string | null;
  /** 组织机构 */
  organizationUnit?: SysOrganizationUnits;
  /** 分所Id */
  officeId?: string | null;
  /** 组织机构 */
  office?: SysOrganizationUnits;
  /** 备注 */
  remark?: string | null;
  /**
   * 是否草稿
   * <param name="如果是操作传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
  /**
   * 中立字段，某些表可使用某些表不使用
   *
   * 逻辑上的删除，非物理删除
   *
   * 例如：单据删除并非直接删除
   */
  isDeleted?: boolean;
  /**
   * 中立字段
   *
   * 是否内置数据
   */
  isInternal?: boolean;
  /** 创建ID */
  createId?: string | null;
  /** 创建者 */
  createBy?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 修改ID */
  modifyId?: string | null;
  /** 更新者 */
  modifyBy?: string | null;
  /**
   * 修改日期
   * @format date-time
   */
  modifyTime?: string | null;
  /**
   * 数据版本
   * @format int64
   */
  version?: number;
  /** 删除ID */
  deleteId?: string | null;
  /** 删除者 */
  deleteBy?: string | null;
  /**
   * 删除日期
   * @format date-time
   */
  deleteTime?: string | null;
}

/** 国家地区表 */
export interface SysCountryDto {
  /** 中文名称 */
  cnName?: string | null;
  /** 名称 */
  name?: string | null;
  alpha2?: string | null;
  /** 数据字典Code */
  alpha3?: string | null;
  /** 地区编号 */
  countryCode?: string | null;
  iso_3166_2?: string | null;
  region?: string | null;
  subRegion?: string | null;
  intermediateRegion?: string | null;
  regionCode?: string | null;
  subRegionCode?: string | null;
  intermediateRegionCode?: string | null;
  /** 国旗 */
  nationalFlag?: string | null;
  /** Emoji */
  emoji?: string | null;
  /** 币种 */
  currency?: string | null;
  /** 备注 */
  remark?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface SysCountryDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 国家地区表 */
  response?: SysCountryDto;
}

/** 文档中心 */
export interface SysDocumentCenterDto {
  /** 名称 */
  name?: string | null;
  /** 全名 */
  fullName?: string | null;
  /** 父级Id */
  parentId?: string | null;
  /** 递归路径Id */
  fullPathId?: string | null;
  /** 递归路径Name */
  fullPathName?: string | null;
  /** 级别 */
  level?: string | null;
  /** 是否文件夹 */
  isFolder?: string | null;
  /** 是否文件夹 */
  isFolderText?: string | null;
  /** 业务Id */
  businessId?: string | null;
  /** 业务名称 */
  businessName?: string | null;
  /** 拓展名 */
  extension?: string | null;
  /** 附件Id */
  attachmentId?: string | null;
  /** 哈希值 */
  hashValue?: string | null;
  /**
   * 大小
   * @format int64
   */
  size?: number;
  /** 是否读取 */
  isRead?: string | null;
  /** 是否读取 */
  isReadText?: string | null;
  /** 是否写入 */
  isWrite?: string | null;
  /** 是否写入 */
  isWriteText?: string | null;
  /** 是否下载 */
  isDownload?: string | null;
  /** 是否下载 */
  isDownloadText?: string | null;
  /** 是否删除 */
  isDelete?: string | null;
  /** 是否删除 */
  isDeleteText?: string | null;
  /**
   * 排序
   * @format int32
   */
  sort?: number;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface SysDocumentCenterDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 文档中心 */
  response?: SysDocumentCenterDto;
}

/** 如果TenantId = 0 那么是租户版本配置 如果租户Id > 0 则是租户配置 */
export interface SysEditionsModulePermission {
  /** 版本Id */
  editionsId?: string | null;
  /** App版本 */
  appEditions?: SysAppEditions;
  /** 菜单ID */
  moduleId?: string | null;
  /** 接口API地址信息表 */
  modules?: SysModules;
  /** api ID */
  permissionId?: string | null;
  tenantId?: string | null;
  /** 路由菜单表 */
  permission?: SysPermission;
  /** 雪花Id */
  id?: string | null;
  /**
   * 系统租户表
   *
   * 根据TenantType 分为两种方案:
   *
   * 1.按租户字段区分
   *
   * 2.按租户分库
   *
   *
   *
   * 注意:
   *
   * 使用租户Id方案,无需配置分库的连接
   */
  tenant?: SysTenant;
  /** 部门ID  DepartmentId */
  organizationUnitId?: string | null;
  /** 组织机构 */
  organizationUnit?: SysOrganizationUnits;
  /** 分所Id */
  officeId?: string | null;
  /** 组织机构 */
  office?: SysOrganizationUnits;
  /** 备注 */
  remark?: string | null;
  /**
   * 是否草稿
   * <param name="如果是操作传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
  /**
   * 中立字段，某些表可使用某些表不使用
   *
   * 逻辑上的删除，非物理删除
   *
   * 例如：单据删除并非直接删除
   */
  isDeleted?: boolean;
  /**
   * 中立字段
   *
   * 是否内置数据
   */
  isInternal?: boolean;
  /** 创建ID */
  createId?: string | null;
  /** 创建者 */
  createBy?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 修改ID */
  modifyId?: string | null;
  /** 更新者 */
  modifyBy?: string | null;
  /**
   * 修改日期
   * @format date-time
   */
  modifyTime?: string | null;
  /**
   * 数据版本
   * @format int64
   */
  version?: number;
  /** 删除ID */
  deleteId?: string | null;
  /** 删除者 */
  deleteBy?: string | null;
  /**
   * 删除日期
   * @format date-time
   */
  deleteTime?: string | null;
}

export interface SysEmailTemplatesDto {
  /** 模版名称 名字唯一 */
  templateName?: string | null;
  /**
   * 配置层级
   * @format int32
   */
  settingLevel?: 0 | 1 | 2 | 3;
  settingLevelName?: string | null;
  /** 模版正文 */
  templateContent?: string | null;
  /** 模版描述 */
  templateSubject?: string | null;
  /**
   * 授权失效时间 分钟
   * @format int32
   */
  authFailureTime?: number;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface SysEmailTemplatesDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: SysEmailTemplatesDto[] | null;
}

/** 通用返回信息类 */
export interface SysEmailTemplatesDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  response?: SysEmailTemplatesDto;
}

/** 通用分页信息类 */
export interface SysEmailTemplatesDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: SysEmailTemplatesDto[] | null;
}

/** 通用返回信息类 */
export interface SysEmailTemplatesDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: SysEmailTemplatesDtoPageModel;
}

/** 雇员表彰和奖励 */
export interface SysEmployeeAwardsDto {
  /**
   * 入职日期
   * @format date-time
   */
  entryDate?: string | null;
  /** 类别 */
  type?: string | null;
  /**
   * 最后工作日
   * @format date-time
   */
  lastWorkDate?: string | null;
  /** 描述 */
  discription?: string | null;
  /** 奖项 */
  awardName?: string | null;
  /** 工号 */
  jobNumber?: string | null;
  /**
   * 奖励级别
   * @format int32
   */
  level?: number | null;
  levelText?: string | null;
  /**
   * 获奖日期
   * @format date-time
   */
  date?: string | null;
  /** 雇员Id */
  userId?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface SysEmployeeAwardsDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: SysEmployeeAwardsDto[] | null;
}

/** 通用返回信息类 */
export interface SysEmployeeAwardsDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 雇员表彰和奖励 */
  response?: SysEmployeeAwardsDto;
}

/** 通用分页信息类 */
export interface SysEmployeeAwardsDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: SysEmployeeAwardsDto[] | null;
}

/** 通用返回信息类 */
export interface SysEmployeeAwardsDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: SysEmployeeAwardsDtoPageModel;
}

/** 雇员证书执照 */
export interface SysEmployeeCertificatesDto {
  /** 证书名称 */
  name?: string | null;
  /** 证书描述 */
  discription?: string | null;
  /**
   * 获得时间
   * @format date-time
   */
  gettime?: string | null;
  /** 学习证书Id */
  learningCertificateId?: string | null;
  /**
   * 有效期至
   * @format date-time
   */
  validuntil?: string | null;
  /**
   * 入职日期
   * @format date-time
   */
  entryDate?: string | null;
  /** 证书编号 */
  certificateNo?: string | null;
  /** 发证机构 */
  issauthority?: string | null;
  /** 工号 */
  jobNumber?: string | null;
  /** 审批状态 */
  approveStatus?: string | null;
  approveStatusText?: string | null;
  /**
   * 最后工作日
   * @format date-time
   */
  lastWorkDate?: string | null;
  /**
   * 生效日期
   * @format date-time
   */
  effectiveDate?: string | null;
  /** 证书照类型 */
  certificateType?: string | null;
  /** 证书照类型 */
  certificateTypeText?: string | null;
  /** 雇员Id */
  userId?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface SysEmployeeCertificatesDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: SysEmployeeCertificatesDto[] | null;
}

/** 通用返回信息类 */
export interface SysEmployeeCertificatesDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 雇员证书执照 */
  response?: SysEmployeeCertificatesDto;
}

/** 通用分页信息类 */
export interface SysEmployeeCertificatesDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: SysEmployeeCertificatesDto[] | null;
}

/** 通用返回信息类 */
export interface SysEmployeeCertificatesDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: SysEmployeeCertificatesDtoPageModel;
}

/** 雇员教育经历 */
export interface SysEmployeeEducationsDto {
  /** 学历 */
  educationLevel?: string | null;
  /** 学历 */
  educationLevelText?: string | null;
  /** 工号 */
  jobNumber?: string | null;
  /** 培养方式 */
  trainingMode?: string | null;
  /** 培养方式 */
  trainingModeText?: string | null;
  /** 工作情况 */
  performance?: string | null;
  /** 备注 */
  comments?: string | null;
  /** 学制 */
  schoolSystme?: string | null;
  /** 专业类别 */
  specializedfield?: string | null;
  /** 专业类别 */
  specializedfieldText?: string | null;
  /** 专业授予机构 */
  grantingInstitution?: string | null;
  /** 专业 */
  major?: string | null;
  /** 毕业学校 */
  schoolCode?: string | null;
  /** 毕业学校 */
  schoolCodeText?: string | null;
  /** 班级排名 */
  classRanking?: string | null;
  /** 学校名繁体 */
  school_zh_TW?: string | null;
  /** 学位授予国家 */
  degreeCountry?: string | null;
  /** 专业描述 */
  mainCourse?: string | null;
  /** 专业描述 */
  majorDescription?: string | null;
  /** 审批状态 */
  approveStatus?: string | null;
  /** 审批状态 */
  approveStatusText?: string | null;
  /** 学位 */
  degree?: string | null;
  /** 学位 */
  degreeText?: string | null;
  /** 所在城市 */
  location?: string | null;
  /** 所在城市 */
  locationText?: string | null;
  /** 毕业学校名称 */
  school?: string | null;
  /** 见证人 */
  prover?: string | null;
  /** 是否主专业 */
  isFirstMajor?: boolean | null;
  /** 是否最高学历 */
  isHighestEducation?: boolean | null;
  /** 是否最高学位 */
  isHighestDegree?: boolean | null;
  /** 是否第一学历 */
  isFirstEducationLevel?: boolean | null;
  /**
   * 学习形式
   * @format int32
   */
  studyMode?: number | null;
  /** 学习形式 */
  studyModeText?: string | null;
  /**
   * 毕业类型
   * @format int32
   */
  graduationType?: number | null;
  /** 毕业类型 */
  graduationTypeText?: string | null;
  /**
   * 入职日期
   * @format date-time
   */
  entryDate?: string | null;
  /**
   * 结束日期
   * @format date-time
   */
  enddatE?: string | null;
  /**
   * 最后工作日
   * @format date-time
   */
  lastWorkDate?: string | null;
  /** 毕业学校名称_英文 */
  school_en_US?: string | null;
  /** 雇员Id */
  userId?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface SysEmployeeEducationsDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: SysEmployeeEducationsDto[] | null;
}

/** 通用返回信息类 */
export interface SysEmployeeEducationsDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 雇员教育经历 */
  response?: SysEmployeeEducationsDto;
}

/** 通用分页信息类 */
export interface SysEmployeeEducationsDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: SysEmployeeEducationsDto[] | null;
}

/** 通用返回信息类 */
export interface SysEmployeeEducationsDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: SysEmployeeEducationsDtoPageModel;
}

/** 材料管理 */
export interface SysEmployeeEntryMaterialRecDto {
  /** 材料名称 */
  materialName?: string | null;
  /** 所属单元 */
  onwerMou?: string | null;
  onwerMouText?: string | null;
  /**
   * 催办时间
   * @format date-time
   */
  promotTime?: string | null;
  /** 是否法定 */
  isLegal?: boolean | null;
  /** 是否收到 */
  hasReceived?: boolean | null;
  /** 退回备注 */
  giveBackRemark?: string | null;
  /** 材料名称(文本) */
  name?: string | null;
  /**
   * 提交日期
   * @format date-time
   */
  submitDate?: string | null;
  /** 组织排序号 */
  organizationOrderNum?: string | null;
  /** 工号 */
  jobNumber?: string | null;
  /** 材料说明 */
  remark?: string | null;
  /** 材料状态 */
  materialState?: string | null;
  materialStateText?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createdTime?: string | null;
  /** 准备阶段 */
  preparationPhase?: string | null;
  preparationPhaseText?: string | null;
  /**
   * 接收日期
   * @format date-time
   */
  receiveDate?: string | null;
  /**
   * 退回日期
   * @format date-time
   */
  giveBackDate?: string | null;
  /** 未提交备注 */
  notSubmittedRemarks?: string | null;
  /** 附件 */
  materialAttachment?: string | null;
  /**
   * 入职日期
   * @format date-time
   */
  entryDate?: string | null;
  /** 处理备注 */
  handleRemark?: string | null;
  /** 是否提交 */
  hasSubmitted?: boolean | null;
  /**
   * 预计提交日期
   * @format date-time
   */
  prepareSubmitDate?: string | null;
  /** 是否必备 */
  isEssential?: boolean | null;
  /**
   * 所属单元层级
   * @format int32
   */
  onwerMou_TreeLevel?: number | null;
  /**
   * 催办人
   * @format int32
   */
  promoter?: number | null;
  promoterText?: string | null;
  /** 所属单元路径 */
  onwerMou_TreePath?: string | null;
  /** 材料图片缩略图 */
  materialImageThumbnail?: string | null;
  /**
   * 最后工作日
   * @format date-time
   */
  lastWorkDate?: string | null;
  /** 雇员Id */
  userId?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface SysEmployeeEntryMaterialRecDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: SysEmployeeEntryMaterialRecDto[] | null;
}

/** 通用返回信息类 */
export interface SysEmployeeEntryMaterialRecDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 材料管理 */
  response?: SysEmployeeEntryMaterialRecDto;
}

/** 通用分页信息类 */
export interface SysEmployeeEntryMaterialRecDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: SysEmployeeEntryMaterialRecDto[] | null;
}

/** 通用返回信息类 */
export interface SysEmployeeEntryMaterialRecDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: SysEmployeeEntryMaterialRecDtoPageModel;
}

/** 雇员家庭信息 */
export interface SysEmployeeFamilyDto {
  /**
   * 性别
   * @format int32
   */
  gender?: number | null;
  genderText?: string | null;
  /** 组织排序号 */
  organizationOrderNum?: string | null;
  /** 与本人关系 */
  relationType?: string | null;
  relationTypeText?: string | null;
  /** 职级排序号 */
  jobLevelOrderNum?: string | null;
  /**
   * 业务修改人
   * @format int32
   */
  businessModifiedBy?: number | null;
  /**
   * 出生日期
   * @format date-time
   */
  birthday?: string | null;
  /**
   * 最后工作日
   * @format date-time
   */
  lastWorkDate?: string | null;
  /** 审批状态 */
  approveStatus?: string | null;
  approveStatusText?: string | null;
  /** 邮件 */
  email?: string | null;
  /** 工号 */
  jobNumber?: string | null;
  /** 职务 */
  jobPost?: string | null;
  /**
   * 入职日期
   * @format date-time
   */
  entryDate?: string | null;
  /** 政治面貌 */
  politicalStatus?: string | null;
  politicalStatusText?: string | null;
  /** 手机号码 */
  mobile?: string | null;
  /** 与本人关系名称 */
  relation?: string | null;
  /** 联系电话 */
  telephone?: string | null;
  /** 成员姓名 */
  name?: string | null;
  /** 工作单位 */
  company?: string | null;
  /** 雇员Id */
  userId?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface SysEmployeeFamilyDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: SysEmployeeFamilyDto[] | null;
}

/** 通用返回信息类 */
export interface SysEmployeeFamilyDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 雇员家庭信息 */
  response?: SysEmployeeFamilyDto;
}

/** 通用分页信息类 */
export interface SysEmployeeFamilyDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: SysEmployeeFamilyDto[] | null;
}

/** 通用返回信息类 */
export interface SysEmployeeFamilyDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: SysEmployeeFamilyDtoPageModel;
}

/** 雇员信息表 */
export interface SysEmployeeInfos {
  /** 显示名称 */
  displayName?: string | null;
  /**
   * 入职时间
   * @format date-time
   */
  entryTime?: string | null;
  /** 别名 */
  alterName?: string | null;
  /** 手机号 */
  phoneNumber?: string | null;
  /** 描述 */
  description?: string | null;
  address?: string | null;
  /** 邮箱 */
  emailAddress?: string | null;
  /** @format int32 */
  sex?: number;
  /**
   * 生日
   * @format date-time
   */
  birthday?: string | null;
  /** @format int32 */
  age?: number;
  /** 教育背景 */
  educationBackground?: string | null;
  /** 工号 */
  jobNumber?: string | null;
  /** Id卡类型 */
  idCardType?: string | null;
  /** 唯一标识号码 */
  idCardNumber?: string | null;
  /**
   * 毕业时间
   * @format date-time
   */
  graduateDate?: string | null;
  /** 婚姻状态 */
  maritalStatus?: string | null;
  /** 用户类型 */
  userType?: string | null;
  /** @format int32 */
  origin?: 0 | 1 | 2 | null;
  /** 外部标识 */
  originalId?: string | null;
  /** 户籍所在地 */
  householdRegister?: string | null;
  /** 民族 */
  nation?: string | null;
  /** 政治面貌 */
  politicalOutlook?: string | null;
  /** 籍贯 */
  nativePlace?: string | null;
  /** 兴趣爱好 */
  hobbies?: string | null;
  /** 特长 */
  speciality?: string | null;
  /**
   * 参加工作时间
   * @format date-time
   */
  beganWorkDate?: string | null;
  /** 毕业学校 */
  graduateFrom?: string | null;
  /** 专业 */
  major?: string | null;
  /** 工作地址 */
  officeLocation?: string | null;
  /** 工作电话 */
  workPhone?: string | null;
  /** 传真 */
  fax?: string | null;
  /** 座机 */
  landline?: string | null;
  /** 头像地址 */
  pictureUrl?: string | null;
  /** 关于我 */
  aboutMe?: string | null;
  /** 最高学历 */
  highestEducation?: string | null;
  /** 邮政编码 */
  postalCode?: string | null;
  /**
   * 对外费率
   * @format double
   */
  externalRate?: number;
  /**
   * 内部费率
   * @format double
   */
  internalRate?: number;
  /** 币种 */
  currency?: string | null;
  /** 擅长语言 */
  goodLanguage?: string | null;
  /** 擅长专业 */
  goodProfessional?: string | null;
  /** 原执业律师事务所 */
  formerLawyer?: string | null;
  /** 执业证号 */
  formerLawyerNumber?: string | null;
  /** 紧急联系人 */
  emergencyContact?: string | null;
  /** 与其关系 */
  relationship?: string | null;
  /** 紧急联系人电话 */
  emergencyContactPhone?: string | null;
  /** 紧急联系人地址 */
  emergencyContactAddress?: string | null;
  /** 律师关系存放地 */
  localRelationshipStorage?: string | null;
  /**
   * 律师关系存放日期
   * @format date-time
   */
  localRelationshipTime?: string | null;
  /** 人事档案存放地 */
  personnelArchivesStorageAgency?: string | null;
  /** 存档号 */
  archiveNumber?: string | null;
  /**
   * 存档日期
   * @format date-time
   */
  archiveTime?: string | null;
  /**
   * 是否离职 ，从SysUserInfo冗余字段
   * @format int32
   */
  userStatus?: 0 | 1 | 2;
  /** 计时人员 */
  timekeeperId?: string | null;
  /** 编号 */
  serialId?: string | null;
  /** 费率 */
  employeeRates?: SysEmployeeRates[] | null;
  /** 雪花Id */
  id?: string | null;
  /** 租户ID */
  tenantId?: string | null;
  /**
   * 系统租户表
   *
   * 根据TenantType 分为两种方案:
   *
   * 1.按租户字段区分
   *
   * 2.按租户分库
   *
   *
   *
   * 注意:
   *
   * 使用租户Id方案,无需配置分库的连接
   */
  tenant?: SysTenant;
  /** 部门ID  DepartmentId */
  organizationUnitId?: string | null;
  /** 组织机构 */
  organizationUnit?: SysOrganizationUnits;
  /** 分所Id */
  officeId?: string | null;
  /** 组织机构 */
  office?: SysOrganizationUnits;
  /** 备注 */
  remark?: string | null;
  /**
   * 是否草稿
   * <param name="如果是操作传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
  /**
   * 中立字段，某些表可使用某些表不使用
   *
   * 逻辑上的删除，非物理删除
   *
   * 例如：单据删除并非直接删除
   */
  isDeleted?: boolean;
  /**
   * 中立字段
   *
   * 是否内置数据
   */
  isInternal?: boolean;
  /** 创建ID */
  createId?: string | null;
  /** 创建者 */
  createBy?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 修改ID */
  modifyId?: string | null;
  /** 更新者 */
  modifyBy?: string | null;
  /**
   * 修改日期
   * @format date-time
   */
  modifyTime?: string | null;
  /**
   * 数据版本
   * @format int64
   */
  version?: number;
  /** 删除ID */
  deleteId?: string | null;
  /** 删除者 */
  deleteBy?: string | null;
  /**
   * 删除日期
   * @format date-time
   */
  deleteTime?: string | null;
}

/** 雇员信息表 */
export interface SysEmployeeInfosDto {
  /** 显示名称 */
  displayName?: string | null;
  /**
   * 入职时间
   * @format date-time
   */
  entryTime?: string | null;
  /** 别名 */
  alterName?: string | null;
  /** 手机号 */
  phoneNumber?: string | null;
  /** 描述 */
  description?: string | null;
  address?: string | null;
  /** 邮箱 */
  emailAddress?: string | null;
  /** @format int32 */
  sex?: number | null;
  sexText?: string | null;
  /**
   * 生日
   * @format date-time
   */
  birthday?: string | null;
  /** @format int32 */
  age?: number | null;
  /** 教育背景 */
  educationBackground?: string | null;
  /** 工号 */
  jobNumber?: string | null;
  /** Id卡类型 */
  idCardType?: string | null;
  idCardText?: string | null;
  /** 唯一标识号码 */
  idCardNumber?: string | null;
  /**
   * 毕业时间
   * @format date-time
   */
  graduateDate?: string | null;
  /** 婚姻状态 */
  maritalStatus?: string | null;
  maritalStatusText?: string | null;
  /**
   * 用户类型
   * @format int32
   */
  userType?: 0 | 1 | 2;
  /** 用户类型 */
  userTypeText?: string | null;
  /** @format int32 */
  origin?: number | null;
  originText?: string | null;
  /** 外部标识 */
  originalId?: string | null;
  /** 户籍所在地 */
  householdRegister?: string | null;
  /** 民族 */
  nation?: string | null;
  nationText?: string | null;
  /** 政治面貌 */
  politicalOutlook?: string | null;
  politicalOutlookText?: string | null;
  /** 籍贯 */
  nativePlace?: string | null;
  nativePlaceText?: string | null;
  /** 兴趣爱好 */
  hobbies?: string | null;
  /** 特长 */
  speciality?: string | null;
  /**
   * 参加工作时间
   * @format date-time
   */
  beganWorkDate?: string | null;
  /** 毕业学校 */
  graduateFrom?: string | null;
  /** 专业 */
  major?: string | null;
  /** 工作地址 */
  officeLocation?: string | null;
  /** 工作电话 */
  workPhone?: string | null;
  /** 传真 */
  fax?: string | null;
  /** 座机 */
  landline?: string | null;
  /** 头像地址 */
  pictureUrl?: string | null;
  /** 关于我 */
  aboutMe?: string | null;
  /** 最高学历 */
  highestEducation?: string | null;
  /** 邮政编码 */
  postalCode?: string | null;
  /**
   * 对外费率
   * @format double
   */
  externalRate?: number | null;
  /**
   * 内部费率
   * @format double
   */
  internalRate?: number | null;
  /** 币种 */
  currency?: string | null;
  /** 擅长语言 */
  goodLanguage?: string | null;
  /** 擅长专业 */
  goodProfessional?: string | null;
  /** 原执业律师事务所 */
  formerLawyer?: string | null;
  /** 执业证号 */
  formerLawyerNumber?: string | null;
  /** 紧急联系人 */
  emergencyContact?: string | null;
  /** 与其关系 */
  relationship?: string | null;
  /** 紧急联系人电话 */
  emergencyContactPhone?: string | null;
  /** 紧急联系人地址 */
  emergencyContactAddress?: string | null;
  /** 律师关系存放地 */
  localRelationshipStorage?: string | null;
  /**
   * 律师关系存放日期
   * @format date-time
   */
  localRelationshipTime?: string | null;
  /** 人事档案存放地 */
  personnelArchivesStorageAgency?: string | null;
  /** 存档号 */
  archiveNumber?: string | null;
  /**
   * 存档日期
   * @format date-time
   */
  archiveTime?: string | null;
  /**
   * 入职周年
   * @format int32
   */
  admissionYear?: number;
  /**
   * 用户状态
   * @format int32
   */
  userStatus?: 0 | 1 | 2;
  /** 用户状态文本 */
  userStatusText?: string | null;
  /** 用户组 */
  userGroupNames?: string[] | null;
  userGroupIds?: string[] | null;
  /** 是否是当月寿星 */
  isLifeStar?: boolean;
  /** 雇员Id */
  userId?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface SysEmployeeInfosDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: SysEmployeeInfosDto[] | null;
}

/** 通用返回信息类 */
export interface SysEmployeeInfosDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 雇员信息表 */
  response?: SysEmployeeInfosDto;
}

/** 通用分页信息类 */
export interface SysEmployeeInfosDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: SysEmployeeInfosDto[] | null;
}

/** 通用返回信息类 */
export interface SysEmployeeInfosDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: SysEmployeeInfosDtoPageModel;
}

/** 雇员工作经历 */
export interface SysEmployeeJobHistoryDto {
  /** 审批状态 */
  approveStatus?: string | null;
  /** 审批状态 */
  approveStatusText?: string | null;
  /** 部门_繁体 */
  department_zh_TW?: string | null;
  /** 单位名称 */
  company?: string | null;
  /** 工作业绩 */
  achievement?: string | null;
  /**
   * 月薪（税前）
   * @format double
   */
  salary?: number | null;
  /**
   * 离职日期
   * @format date-time
   */
  dimissionDate?: string | null;
  /** 职务_英文 */
  firstJob_en_US?: string | null;
  /** 工作类型 */
  jobType?: string | null;
  /** 企业规模 */
  companySize?: string | null;
  /** 工号 */
  jobNumber?: string | null;
  /** 与本人关系 */
  proverRelation?: string | null;
  /** 部门_英文 */
  department_en_US?: string | null;
  /** 证明人联系方式 */
  proverContactInfo?: string | null;
  /**
   * 下属人数
   * @format int32
   */
  underlingNum?: number | null;
  /** 部门 */
  department?: string | null;
  /** 证明人 */
  proverName?: string | null;
  /** 行业类别 */
  hangye?: string | null;
  hangyeText?: string | null;
  /** 单位介绍 */
  introduction?: string | null;
  /** 职务_繁体 */
  firstJob_zh_TW?: string | null;
  /** 工作性质 */
  workKind?: string | null;
  workKindText?: string | null;
  /** 单位类型 */
  companyType?: string | null;
  companyTypeText?: string | null;
  /**
   * 开始日期
   * @format date-time
   */
  startDate?: string | null;
  /** 职务 */
  firstJob?: string | null;
  /** 单位名称_繁体 */
  company_zh_TW?: string | null;
  /** 职级名称 */
  jobLevel?: string | null;
  jobLevelText?: string | null;
  /** 职级名称_繁体 */
  jobLevel_zh_TW?: string | null;
  /** 是否本单位工作经历 */
  whetherTheWorkExperienceOfTheUnit?: boolean | null;
  /** 职级名称_英文 */
  jobLevel_en_US?: string | null;
  /** 职位_繁体 */
  lastDog_zh_TW?: string | null;
  /** 离职原因 */
  leaveReason?: string | null;
  /**
   * 入职日期
   * @format date-time
   */
  beginDate?: string | null;
  /**
   * 入职日期
   * @format date-time
   */
  entryDate?: string | null;
  /**
   * 结束日期
   * @format date-time
   */
  stopDate?: string | null;
  /** 任职类型 */
  serviceType?: string | null;
  serviceTypeText?: string | null;
  /** 职位 */
  lastJog?: string | null;
  /** 部门全称 */
  poiOldAdminNameTreePath?: string | null;
  /** 职位英文 */
  lastJog_en_US?: string | null;
  /** 工作地址 */
  address?: string | null;
  /**
   * 最后工作日
   * @format date-time
   */
  lastWorkDate?: string | null;
  /** 证明人职务 */
  proverJobPost?: string | null;
  /** 工作职责 */
  responsibility?: string | null;
  /** 雇员Id */
  userId?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface SysEmployeeJobHistoryDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: SysEmployeeJobHistoryDto[] | null;
}

/** 通用返回信息类 */
export interface SysEmployeeJobHistoryDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 雇员工作经历 */
  response?: SysEmployeeJobHistoryDto;
}

/** 通用分页信息类 */
export interface SysEmployeeJobHistoryDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: SysEmployeeJobHistoryDto[] | null;
}

/** 通用返回信息类 */
export interface SysEmployeeJobHistoryDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: SysEmployeeJobHistoryDtoPageModel;
}

export interface SysEmployeeLanguageAbilitiesDto {
  /** 语言 */
  language?: string | null;
  /** 技能描述 */
  languageskill?: string | null;
  /** 审批状态 */
  approveStatus?: string | null;
  approveStatusText?: string | null;
  /** 掌握程度 */
  level?: string | null;
  levelText?: string | null;
  /** 书写能力 */
  writingAbility?: string | null;
  writingAbilityText?: string | null;
  /** 工号 */
  jobNumber?: string | null;
  /**
   * 最后工作日
   * @format date-time
   */
  lastWorkDate?: string | null;
  /** 是否母语 */
  isNative?: boolean | null;
  /**
   * 入职日期
   * @format date-time
   */
  entryDate?: string | null;
  /** 阅读能力 */
  readingAbility?: string | null;
  readingAbilityText?: string | null;
  /** 口语能力 */
  speakingAbility?: string | null;
  speakingAbilityText?: string | null;
  /** 雇员Id */
  userId?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface SysEmployeeLanguageAbilitiesDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: SysEmployeeLanguageAbilitiesDto[] | null;
}

/** 通用返回信息类 */
export interface SysEmployeeLanguageAbilitiesDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  response?: SysEmployeeLanguageAbilitiesDto;
}

/** 通用分页信息类 */
export interface SysEmployeeLanguageAbilitiesDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: SysEmployeeLanguageAbilitiesDto[] | null;
}

/** 通用返回信息类 */
export interface SysEmployeeLanguageAbilitiesDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: SysEmployeeLanguageAbilitiesDtoPageModel;
}

/** 雇员专业技术职务 */
export interface SysEmployeeProfessionalTechnicalPostInfoDto {
  /** 工号 */
  jobNumber?: string | null;
  /**
   * 组织_层级
   * @format int32
   */
  stdOrganization_TreeLevel?: number | null;
  /** 资格评审单位 */
  qualificationEvaluationUnit?: string | null;
  /** 备注 */
  remarks?: string | null;
  /** 共享人 */
  permissionBy?: string | null;
  /** 是否最高级别 */
  isTopLevel?: boolean | null;
  /**
   * 组织
   * @format int32
   */
  stdOrganization?: number | null;
  /**
   * 聘任起始时间
   * @format date-time
   */
  appointStartDate?: string | null;
  isTopLevelText?: string | null;
  /**
   * 评定日期
   * @format date-time
   */
  assessmentDate?: string | null;
  /**
   * 所属单位层级
   * @format int32
   */
  onwerMou_TreeLevel?: number | null;
  /** 熟悉专业专长 */
  professionalSpeciality?: string | null;
  /** 审批状态 */
  approveStatus?: string | null;
  approveStatusText?: string | null;
  /** 所属单位 */
  onwerMou?: string | null;
  onwerMouText?: string | null;
  /**
   * 最后工作日
   * @format date-time
   */
  lastWorkDate?: string | null;
  /**
   * 入职日期
   * @format date-time
   */
  entryDate?: string | null;
  /** 共享部门 */
  permissionOrganization?: string | null;
  permissionOrganizationText?: string | null;
  /** 获取资历途径 */
  accessWayID?: string | null;
  accessWayText?: string | null;
  /** 共享部门，包含下级 */
  permissionOrganization_SelfAndChildren?: string | null;
  /** 职任专业技术级别 */
  appointTechnicalLevelID?: string | null;
  appointTechnicalLevelText?: string | null;
  /** 职任单位 */
  appointCompany?: string | null;
  /** 共享部门，仅自己 */
  permissionOrganization_SelfOnly?: string | null;
  /** 专业技术职务任职资格名称 */
  technicalPostQualificationsID?: string | null;
  /** 所属单元路径 */
  onwerMou_TreePath?: string | null;
  /** 组织路径 */
  stdOrganization_TreePath?: string | null;
  /** 职任专业技术职务名称 */
  appointTechnicalPostQualificationsID?: string | null;
  /** 专业技术级别 */
  technicalLevelID?: string | null;
  technicalLevelText?: string | null;
  /**
   * 职任结束时间
   * @format date-time
   */
  appointEndDate?: string | null;
  /** 雇员Id */
  userId?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface SysEmployeeProfessionalTechnicalPostInfoDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: SysEmployeeProfessionalTechnicalPostInfoDto[] | null;
}

/** 通用返回信息类 */
export interface SysEmployeeProfessionalTechnicalPostInfoDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 雇员专业技术职务 */
  response?: SysEmployeeProfessionalTechnicalPostInfoDto;
}

/** 通用分页信息类 */
export interface SysEmployeeProfessionalTechnicalPostInfoDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: SysEmployeeProfessionalTechnicalPostInfoDto[] | null;
}

/** 通用返回信息类 */
export interface SysEmployeeProfessionalTechnicalPostInfoDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: SysEmployeeProfessionalTechnicalPostInfoDtoPageModel;
}

/** 雇员项目经历 */
export interface SysEmployeeProjectExperiencesDto {
  /** 职位 */
  jobPosition?: string | null;
  /**
   * 最后工作日
   * @format date-time
   */
  lastWorkDate?: string | null;
  /**
   * 项目人数
   * @format int32
   */
  peopleNumber?: number | null;
  /** 硬件环境 */
  hardware?: string | null;
  /** 职位拼音排序 */
  jobPosition_Pinyin?: string | null;
  /** 开发工具 */
  devTool?: string | null;
  /** 软件环境 */
  software?: string | null;
  /**
   * 开始日期
   * @format date-time
   */
  startDate?: string | null;
  /** 项目名称 */
  name?: string | null;
  /** 描述 */
  description?: string | null;
  /** 项目成果 */
  achievement?: string | null;
  /**
   * 结束日期
   * @format date-time
   */
  endDate?: string | null;
  /** 项目职责 */
  responsibility?: string | null;
  /**
   * 入职日期
   * @format date-time
   */
  entryDate?: string | null;
  /** 审批状态 */
  approveStatus?: string | null;
  approveStatusText?: string | null;
  /** 工号 */
  jobNumber?: string | null;
  /** 雇员Id */
  userId?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface SysEmployeeProjectExperiencesDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: SysEmployeeProjectExperiencesDto[] | null;
}

/** 通用返回信息类 */
export interface SysEmployeeProjectExperiencesDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 雇员项目经历 */
  response?: SysEmployeeProjectExperiencesDto;
}

/** 通用分页信息类 */
export interface SysEmployeeProjectExperiencesDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: SysEmployeeProjectExperiencesDto[] | null;
}

/** 通用返回信息类 */
export interface SysEmployeeProjectExperiencesDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: SysEmployeeProjectExperiencesDtoPageModel;
}

/** 雇员费率表 */
export interface SysEmployeeRates {
  /** 用户Id */
  userId?: string | null;
  /** 币种 */
  currency?: string | null;
  /**
   * 费率
   * @format double
   */
  rate?: number;
  /** 是否默认 */
  isDefault?: boolean;
  /**
   * 生效日期
   * @format date-time
   */
  effectiveDate?: string | null;
  /** 计时人员编号 */
  timekeeperNumber?: string | null;
  /** 雪花Id */
  id?: string | null;
  /** 租户ID */
  tenantId?: string | null;
  /**
   * 系统租户表
   *
   * 根据TenantType 分为两种方案:
   *
   * 1.按租户字段区分
   *
   * 2.按租户分库
   *
   *
   *
   * 注意:
   *
   * 使用租户Id方案,无需配置分库的连接
   */
  tenant?: SysTenant;
  /** 部门ID  DepartmentId */
  organizationUnitId?: string | null;
  /** 组织机构 */
  organizationUnit?: SysOrganizationUnits;
  /** 分所Id */
  officeId?: string | null;
  /** 组织机构 */
  office?: SysOrganizationUnits;
  /** 备注 */
  remark?: string | null;
  /**
   * 是否草稿
   * <param name="如果是操作传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
  /**
   * 中立字段，某些表可使用某些表不使用
   *
   * 逻辑上的删除，非物理删除
   *
   * 例如：单据删除并非直接删除
   */
  isDeleted?: boolean;
  /**
   * 中立字段
   *
   * 是否内置数据
   */
  isInternal?: boolean;
  /** 创建ID */
  createId?: string | null;
  /** 创建者 */
  createBy?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 修改ID */
  modifyId?: string | null;
  /** 更新者 */
  modifyBy?: string | null;
  /**
   * 修改日期
   * @format date-time
   */
  modifyTime?: string | null;
  /**
   * 数据版本
   * @format int64
   */
  version?: number;
  /** 删除ID */
  deleteId?: string | null;
  /** 删除者 */
  deleteBy?: string | null;
  /**
   * 删除日期
   * @format date-time
   */
  deleteTime?: string | null;
}

/** 雇员专业技能 */
export interface SysEmployeeSkillDto {
  /** 审批状态 */
  approveStatus?: string | null;
  approveStatusText?: string | null;
  /** 掌握程度 */
  level?: string | null;
  levelText?: string | null;
  /**
   * 使用时间（月）
   * @format int32
   */
  useLength?: number | null;
  /**
   * 入职日期
   * @format date-time
   */
  entryDate?: string | null;
  /**
   * 最后工作日
   * @format date-time
   */
  lastWorkDate?: string | null;
  /** 种类 */
  category?: string | null;
  /** 工号 */
  jobNumber?: string | null;
  /** 名称 */
  name?: string | null;
  /** 雇员Id */
  userId?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface SysEmployeeSkillDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: SysEmployeeSkillDto[] | null;
}

/** 通用返回信息类 */
export interface SysEmployeeSkillDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 雇员专业技能 */
  response?: SysEmployeeSkillDto;
}

/** 通用分页信息类 */
export interface SysEmployeeSkillDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: SysEmployeeSkillDto[] | null;
}

/** 通用返回信息类 */
export interface SysEmployeeSkillDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: SysEmployeeSkillDtoPageModel;
}

/** 培训经历 */
export interface SysEmployeeTrainingsDto {
  /** 培训描述 */
  discription?: string | null;
  /** 是否获得勋章 */
  isHaveMedal?: boolean | null;
  /**
   * 开始日期
   * @format date-time
   */
  startDate?: string | null;
  /** 适用场景 */
  applyScene?: string | null;
  applySceneText?: string | null;
  /** 培训机构 */
  trainingAgency?: string | null;
  /** 培训成绩 */
  result?: string | null;
  /** 培训表现 */
  evaluation?: string | null;
  /**
   * 获得学分
   * @format double
   */
  getCredit?: number | null;
  /**
   * 结束日期
   * @format date-time
   */
  stopDate?: string | null;
  /** 名称 */
  course?: string | null;
  /**
   * 最后工作日
   * @format date-time
   */
  lastWorkDate?: string | null;
  /**
   * 入职日期
   * @format date-time
   */
  entryDate?: string | null;
  /** 培训类别 */
  trainingCategory?: string | null;
  trainingCategoryText?: string | null;
  /** 培训类别名称 */
  trainType?: string | null;
  /** 课时总计 */
  timeToTile?: string | null;
  /** 及格状态 */
  passStatus?: string | null;
  passStatusText?: string | null;
  /** 工号 */
  jobNumber?: string | null;
  /** 审批状态 */
  approveStatus?: string | null;
  approveStatusText?: string | null;
  /**
   * 辅导导师
   * @format int32
   */
  mentorUserId?: number | null;
  /** 获得证书 */
  certificate?: string | null;
  /** 完成状态 */
  completionStatus?: string | null;
  completionStatusText?: string | null;
  /** 培训活动编号 */
  trainingActivityNum?: string | null;
  /** 雇员Id */
  userId?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface SysEmployeeTrainingsDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: SysEmployeeTrainingsDto[] | null;
}

/** 通用返回信息类 */
export interface SysEmployeeTrainingsDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 培训经历 */
  response?: SysEmployeeTrainingsDto;
}

/** 通用分页信息类 */
export interface SysEmployeeTrainingsDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: SysEmployeeTrainingsDto[] | null;
}

/** 通用返回信息类 */
export interface SysEmployeeTrainingsDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: SysEmployeeTrainingsDtoPageModel;
}

/** 雇员执业（职业）资格信息 */
export interface SysEmployeeVocationalQualificationInfoDto {
  /** 资格名称 */
  qualificationNameID?: string | null;
  /** 资格级别 */
  qualificationLevelID?: string | null;
  qualificationLevelText?: string | null;
  /** 组织路径 */
  stdOrganization_TreePath?: string | null;
  /** 获取资格途径 */
  accessWayID?: string | null;
  accessWayText?: string | null;
  /** 证书附件 */
  accessory?: string | null;
  /**
   * 组织层级
   * @format int32
   */
  stdOrganization_TreeLevel?: number | null;
  /** 共享部门_包含下级 */
  permissionOrganization_SelfAndChildren?: string | null;
  /** 工号 */
  jobNumber?: string | null;
  /** 资格类型 */
  qualificationTypeID?: string | null;
  qualificationTypeText?: string | null;
  /** 共享部门 */
  permissionOrganization?: string | null;
  permissionOrganizationText?: string | null;
  /**
   * 取得日期
   * @format date-time
   */
  dateOfAcquisition?: string | null;
  /** 共享人 */
  permissionBy?: string | null;
  /** 证书编号 */
  certificateNumber?: string | null;
  /**
   * 所属单元_层级
   * @format int32
   */
  onverMou_TreeLevel?: number | null;
  /**
   * 有效期至
   * @format date-time
   */
  validUntil?: string | null;
  /**
   * 入职日期
   * @format date-time
   */
  entryDate?: string | null;
  /** 期限类型 */
  termTypeId?: string | null;
  termTypeText?: string | null;
  /** 是否最高等级 */
  supremeGrade?: boolean | null;
  /** 申请类型 */
  applicationTypeId?: string | null;
  applicationTypeText?: string | null;
  /**
   * 最后工作日
   * @format date-time
   */
  lastWorkDate?: string | null;
  /** 所属单位 */
  ownerMou?: string | null;
  /** 所属单位路径 */
  ownerMou_TreePath?: string | null;
  /** 专业 */
  professional?: string | null;
  /** 组织 */
  stdOrganization?: string | null;
  stdOrganizationText?: string | null;
  /** 证据来源 */
  sourceLicensiesID?: string | null;
  sourceLicensiesText?: string | null;
  /** 共享部门_仅自己 */
  permissionOrganization_SelfOnly?: string | null;
  /** 审批状态 */
  approveStatus?: string | null;
  approveStatusText?: string | null;
  /** 发布机构 */
  issueUnit?: string | null;
  /** 证书编号 */
  certificateNumberUpper?: string | null;
  /** 雇员Id */
  userId?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface SysEmployeeVocationalQualificationInfoDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: SysEmployeeVocationalQualificationInfoDto[] | null;
}

/** 通用返回信息类 */
export interface SysEmployeeVocationalQualificationInfoDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 雇员执业（职业）资格信息 */
  response?: SysEmployeeVocationalQualificationInfoDto;
}

/** 通用分页信息类 */
export interface SysEmployeeVocationalQualificationInfoDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: SysEmployeeVocationalQualificationInfoDto[] | null;
}

/** 通用返回信息类 */
export interface SysEmployeeVocationalQualificationInfoDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: SysEmployeeVocationalQualificationInfoDtoPageModel;
}

/** 企业信息表 */
export interface SysEnterpriseInfo {
  /** 公司名称 */
  enterpriseName?: string | null;
  /** 执行许可号 */
  enterpriseCode?: string | null;
  /** 法人姓名 */
  corporation?: string | null;
  /** 统一社会信用代码 */
  creditCode?: string | null;
  /** 邮箱地址 */
  email?: string | null;
  /** 注册地址 */
  enterpriseAddress?: string | null;
  /** 雪花Id */
  id?: string | null;
  /** 租户ID */
  tenantId?: string | null;
  /**
   * 系统租户表
   *
   * 根据TenantType 分为两种方案:
   *
   * 1.按租户字段区分
   *
   * 2.按租户分库
   *
   *
   *
   * 注意:
   *
   * 使用租户Id方案,无需配置分库的连接
   */
  tenant?: SysTenant;
  /** 部门ID  DepartmentId */
  organizationUnitId?: string | null;
  /** 组织机构 */
  organizationUnit?: SysOrganizationUnits;
  /** 分所Id */
  officeId?: string | null;
  /** 组织机构 */
  office?: SysOrganizationUnits;
  /** 备注 */
  remark?: string | null;
  /**
   * 是否草稿
   * <param name="如果是操作传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
  /**
   * 中立字段，某些表可使用某些表不使用
   *
   * 逻辑上的删除，非物理删除
   *
   * 例如：单据删除并非直接删除
   */
  isDeleted?: boolean;
  /**
   * 中立字段
   *
   * 是否内置数据
   */
  isInternal?: boolean;
  /** 创建ID */
  createId?: string | null;
  /** 创建者 */
  createBy?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 修改ID */
  modifyId?: string | null;
  /** 更新者 */
  modifyBy?: string | null;
  /**
   * 修改日期
   * @format date-time
   */
  modifyTime?: string | null;
  /**
   * 数据版本
   * @format int64
   */
  version?: number;
  /** 删除ID */
  deleteId?: string | null;
  /** 删除者 */
  deleteBy?: string | null;
  /**
   * 删除日期
   * @format date-time
   */
  deleteTime?: string | null;
}

/** Excel导出记录 */
export interface SysExcelExportCenterDto {
  /** 导出数据类型 */
  module?: string | null;
  /** 导出文件地址 */
  path?: string | null;
  /**
   * 文件大小
   * @format int32
   */
  size?: number;
  /** 导出条件 */
  tableSearchParameter?: string | null;
  /**
   * 下载次数
   * @format int32
   */
  downloadCount?: number;
  /** 文件下载FileId */
  fileId?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用分页信息类 */
export interface SysExcelExportCenterDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: SysExcelExportCenterDto[] | null;
}

/** 通用返回信息类 */
export interface SysExcelExportCenterDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: SysExcelExportCenterDtoPageModel;
}

/** 第三方通知记录表   //备注使用Remark */
export interface SysExternalNotificationHistoriesDto {
  /** 正文 */
  notificationContent?: string | null;
  /**
   * 通知类型
   * @format int32
   */
  notificationType?: 0 | 1 | 2 | 3 | 4;
  notificationTypeText?: string | null;
  /** 关联Id */
  associatedId?: string | null;
  /** 参数 */
  parameterJson?: string | null;
  /** 返回参数 */
  responseJson?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface SysExternalNotificationHistoriesDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: SysExternalNotificationHistoriesDto[] | null;
}

/** 通用返回信息类 */
export interface SysExternalNotificationHistoriesDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 第三方通知记录表   //备注使用Remark */
  response?: SysExternalNotificationHistoriesDto;
}

/** 通用分页信息类 */
export interface SysExternalNotificationHistoriesDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: SysExternalNotificationHistoriesDto[] | null;
}

/** 通用返回信息类 */
export interface SysExternalNotificationHistoriesDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: SysExternalNotificationHistoriesDtoPageModel;
}

/** 字典分组 */
export interface SysGeneralCodeGroupsDto {
  /** 名称 */
  name?: string | null;
  /** 代码 */
  code?: string | null;
  /** 父级代码 */
  parentCode?: string | null;
  /**
   * 模块
   * @format int32
   */
  funcGroup?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;
  funcGroupName?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface SysGeneralCodeGroupsDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: SysGeneralCodeGroupsDto[] | null;
}

/** 通用返回信息类 */
export interface SysGeneralCodeGroupsDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 字典分组 */
  response?: SysGeneralCodeGroupsDto;
}

/** 通用分页信息类 */
export interface SysGeneralCodeGroupsDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: SysGeneralCodeGroupsDto[] | null;
}

/** 通用返回信息类 */
export interface SysGeneralCodeGroupsDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: SysGeneralCodeGroupsDtoPageModel;
}

/** 接口API地址信息表 */
export interface SysModules {
  /** 获取或设置是否禁用，逻辑上的删除，非物理删除 */
  isDeleted?: boolean | null;
  /** 名称 */
  name?: string | null;
  /** 菜单链接地址 */
  linkUrl?: string | null;
  /** 区域名称 */
  area?: string | null;
  /** 控制器名称 */
  controller?: string | null;
  /** Action名称 */
  action?: string | null;
  /** 图标 */
  icon?: string | null;
  /** 菜单编号 */
  code?: string | null;
  /**
   * 排序
   * @format int32
   */
  orderSort?: number;
  /** /描述 */
  description?: string | null;
  /** 是否是右侧菜单 */
  isMenu?: boolean;
  /** 是否激活 */
  enabled?: boolean;
  /** 创建ID */
  createId?: string | null;
  /** 创建者 */
  createBy?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 修改ID */
  modifyId?: string | null;
  /** 修改者 */
  modifyBy?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string | null;
  /**
   * 平台
   * @format int32
   */
  scope?: 0 | 1;
  /** 父ID */
  parentId?: string | null;
  /**
   * ID
   * 泛型主键Tkey
   */
  id?: string | null;
}

export interface SysOrganizationUnitLeaders {
  /** 机构键值 */
  code?: string | null;
  /** 是否主负责人 */
  isHost?: boolean;
  /**
   * 默认排序
   * @format int32
   */
  sort?: number;
  /** 用户标识 */
  userId?: string | null;
  /** 用户信息表 */
  userInfo?: SysUserInfos;
  /** 是否启用 */
  isActive?: boolean;
  /** 雪花Id */
  id?: string | null;
  /** 租户ID */
  tenantId?: string | null;
  /**
   * 系统租户表
   *
   * 根据TenantType 分为两种方案:
   *
   * 1.按租户字段区分
   *
   * 2.按租户分库
   *
   *
   *
   * 注意:
   *
   * 使用租户Id方案,无需配置分库的连接
   */
  tenant?: SysTenant;
  /** 部门ID  DepartmentId */
  organizationUnitId?: string | null;
  /** 组织机构 */
  organizationUnit?: SysOrganizationUnits;
  /** 分所Id */
  officeId?: string | null;
  /** 组织机构 */
  office?: SysOrganizationUnits;
  /** 备注 */
  remark?: string | null;
  /**
   * 是否草稿
   * <param name="如果是操作传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
  /**
   * 中立字段，某些表可使用某些表不使用
   *
   * 逻辑上的删除，非物理删除
   *
   * 例如：单据删除并非直接删除
   */
  isDeleted?: boolean;
  /**
   * 中立字段
   *
   * 是否内置数据
   */
  isInternal?: boolean;
  /** 创建ID */
  createId?: string | null;
  /** 创建者 */
  createBy?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 修改ID */
  modifyId?: string | null;
  /** 更新者 */
  modifyBy?: string | null;
  /**
   * 修改日期
   * @format date-time
   */
  modifyTime?: string | null;
  /**
   * 数据版本
   * @format int64
   */
  version?: number;
  /** 删除ID */
  deleteId?: string | null;
  /** 删除者 */
  deleteBy?: string | null;
  /**
   * 删除日期
   * @format date-time
   */
  deleteTime?: string | null;
}

/** 组织机构 */
export interface SysOrganizationUnits {
  /** 机构键值 */
  code?: string | null;
  /** 显示名称 */
  displayName?: string | null;
  /** 别名 */
  alterName?: string | null;
  /** 地区代码 */
  regionCode?: string | null;
  /** 父级Id */
  parentId?: string | null;
  /** 组织机构 */
  parentOrganizationUnits?: SysOrganizationUnits;
  /** 层级键值 */
  nodeCode?: string | null;
  /** 层级显示名称 */
  nodeDisplayName?: string | null;
  /** 区别标识 */
  discriminator?: string | null;
  /**
   * 默认排序
   * @format int32
   */
  sort?: number;
  /** 是否启用 */
  isActive?: boolean;
  /** 是否虚拟机构 */
  isVirtual?: boolean;
  /** 是否总所 */
  isHeadOffice?: boolean;
  /**
   * 阈值标识
   * @format int32
   */
  thresholdFlag?: 0 | 1 | 2 | 3;
  /** 地理位置 */
  location?: string | null;
  /** 工作地点 */
  workAddress?: string | null;
  /**
   * 北森Id
   * @format int32
   */
  pakSenId?: number;
  /** 企业信息表 */
  enterpriseInfo?: SysEnterpriseInfo;
  organizationUnitLeaders?: SysOrganizationUnitLeaders[] | null;
  /** 雪花Id */
  id?: string | null;
  /** 租户ID */
  tenantId?: string | null;
  /**
   * 系统租户表
   *
   * 根据TenantType 分为两种方案:
   *
   * 1.按租户字段区分
   *
   * 2.按租户分库
   *
   *
   *
   * 注意:
   *
   * 使用租户Id方案,无需配置分库的连接
   */
  tenant?: SysTenant;
  /** 部门ID  DepartmentId */
  organizationUnitId?: string | null;
  /** 组织机构 */
  organizationUnit?: SysOrganizationUnits;
  /** 分所Id */
  officeId?: string | null;
  /** 组织机构 */
  office?: SysOrganizationUnits;
  /** 备注 */
  remark?: string | null;
  /**
   * 是否草稿
   * <param name="如果是操作传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
  /**
   * 中立字段，某些表可使用某些表不使用
   *
   * 逻辑上的删除，非物理删除
   *
   * 例如：单据删除并非直接删除
   */
  isDeleted?: boolean;
  /**
   * 中立字段
   *
   * 是否内置数据
   */
  isInternal?: boolean;
  /** 创建ID */
  createId?: string | null;
  /** 创建者 */
  createBy?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 修改ID */
  modifyId?: string | null;
  /** 更新者 */
  modifyBy?: string | null;
  /**
   * 修改日期
   * @format date-time
   */
  modifyTime?: string | null;
  /**
   * 数据版本
   * @format int64
   */
  version?: number;
  /** 删除ID */
  deleteId?: string | null;
  /** 删除者 */
  deleteBy?: string | null;
  /**
   * 删除日期
   * @format date-time
   */
  deleteTime?: string | null;
}

/** 路由菜单表 */
export interface SysPermission {
  /** 菜单执行Action名 */
  code?: string | null;
  /** 菜单显示名（如用户页、编辑(按钮)、删除(按钮)） */
  name?: string | null;
  /** 是否是按钮 */
  isButton?: boolean;
  /** 是否是隐藏菜单 */
  isHide?: boolean | null;
  /** 是否keepAlive */
  iskeepAlive?: boolean | null;
  /** 按钮事件 */
  func?: string | null;
  /**
   * 排序
   * @format int32
   */
  orderSort?: number;
  /** 菜单图标 */
  icon?: string | null;
  /** 菜单描述 */
  description?: string | null;
  /** 激活状态 */
  enabled?: boolean;
  /** 创建ID */
  createId?: string | null;
  /** 创建者 */
  createBy?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string | null;
  /** 修改ID */
  modifyId?: string | null;
  /** 修改者 */
  modifyBy?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string | null;
  /** 获取或设置是否禁用，逻辑上的删除，非物理删除 */
  isDeleted?: boolean | null;
  pnameArr?: string[] | null;
  pCodeArr?: string[] | null;
  mName?: string | null;
  hasChildren?: boolean;
  children?: SysPermission[] | null;
  /** 接口API地址信息表 */
  sysModule?: SysModules;
  /**
   * 平台
   * @format int32
   */
  scope?: 0 | 1;
  /** 显示名称多语言Key */
  displayName?: string | null;
  /** 上一级菜单（0表示上一级无菜单） */
  pid?: string | null;
  /** 接口api */
  mid?: string | null;
  pidArr?: string[] | null;
  /**
   * ID
   * 泛型主键Tkey
   */
  id?: string | null;
}

/** 通用返回信息类 */
export interface SysPermissionListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: SysPermission[] | null;
}

/** 通用分页信息类 */
export interface SysPermissionPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: SysPermission[] | null;
}

/** 通用返回信息类 */
export interface SysPermissionPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: SysPermissionPageModel;
}

/** 角色分组 */
export interface SysRoleGroupsDto {
  /** 分组名称 */
  name?: string | null;
  /**
   * 排序
   * @format int32
   */
  sort?: number | null;
  /**
   * 权限类型
   * @format int32
   */
  roleType?: 0 | 1 | 2 | 3;
  roleTypeText?: string | null;
  /** 子级 */
  sysRolesList?: RoleDto[] | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface SysRoleGroupsDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: SysRoleGroupsDto[] | null;
}

/** 通用返回信息类 */
export interface SysRoleGroupsDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 角色分组 */
  response?: SysRoleGroupsDto;
}

/** 通用分页信息类 */
export interface SysRoleGroupsDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: SysRoleGroupsDto[] | null;
}

/** 通用返回信息类 */
export interface SysRoleGroupsDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: SysRoleGroupsDtoPageModel;
}

/** 角色表 */
export interface SysRoles {
  /** 角色名 */
  name?: string | null;
  /** 显示名称 */
  alterName?: string | null;
  /** 描述 */
  description?: string | null;
  /**
   * 排序
   * @format int32
   */
  sort?: number;
  /**
   * 权限类型
   * @format int32
   */
  roleType?: 0 | 1 | 2 | 3;
  /** 是否启用 */
  isActive?: boolean;
  /** 是否默认角色 */
  isDefault?: boolean;
  /** 用户组Id */
  roleGroupId?: string | null;
  /** 是否虚拟角色 */
  isVirtual?: boolean;
  /**
   * 数据来源
   * @format int32
   */
  origin?: 0 | 1 | 2;
  /** 是否独占 */
  isMonopoly?: boolean | null;
  /** 雪花Id */
  id?: string | null;
  /** 租户ID */
  tenantId?: string | null;
  /**
   * 系统租户表
   *
   * 根据TenantType 分为两种方案:
   *
   * 1.按租户字段区分
   *
   * 2.按租户分库
   *
   *
   *
   * 注意:
   *
   * 使用租户Id方案,无需配置分库的连接
   */
  tenant?: SysTenant;
  /** 部门ID  DepartmentId */
  organizationUnitId?: string | null;
  /** 组织机构 */
  organizationUnit?: SysOrganizationUnits;
  /** 分所Id */
  officeId?: string | null;
  /** 组织机构 */
  office?: SysOrganizationUnits;
  /** 备注 */
  remark?: string | null;
  /**
   * 是否草稿
   * <param name="如果是操作传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
  /**
   * 中立字段，某些表可使用某些表不使用
   *
   * 逻辑上的删除，非物理删除
   *
   * 例如：单据删除并非直接删除
   */
  isDeleted?: boolean;
  /**
   * 中立字段
   *
   * 是否内置数据
   */
  isInternal?: boolean;
  /** 创建ID */
  createId?: string | null;
  /** 创建者 */
  createBy?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 修改ID */
  modifyId?: string | null;
  /** 更新者 */
  modifyBy?: string | null;
  /**
   * 修改日期
   * @format date-time
   */
  modifyTime?: string | null;
  /**
   * 数据版本
   * @format int64
   */
  version?: number;
  /** 删除ID */
  deleteId?: string | null;
  /** 删除者 */
  deleteBy?: string | null;
  /**
   * 删除日期
   * @format date-time
   */
  deleteTime?: string | null;
}

/**
 * 系统租户表
 *
 * 根据TenantType 分为两种方案:
 *
 * 1.按租户字段区分
 *
 * 2.按租户分库
 *
 *
 *
 * 注意:
 *
 * 使用租户Id方案,无需配置分库的连接
 */
export interface SysTenant {
  /** 租户标识 */
  tenancyName?: string | null;
  /** 名称 */
  name?: string | null;
  /**
   * 租户类型
   * @format int32
   */
  tenantType?: 0 | 1 | 2 | 3;
  /** 信用代码 */
  creditCode?: string | null;
  /** 税号 */
  taxNumber?: string | null;
  /** logo流编号 */
  logoId?: string | null;
  /** logo文件类型 eg:image/png */
  logoFileType?: string | null;
  /**
   * 数据库/租户标识 不可重复
   *
   * 使用Id方案,可无需配置
   */
  configId?: string | null;
  /**
   * 主机
   *
   * 使用Id方案,可无需配置
   */
  host?: string | null;
  /**
   * 数据库类型
   *
   * 使用Id方案,可无需配置
   * @format int32
   */
  dbType?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20 | 21 | 22 | 23 | 900 | null;
  /**
   * 数据库连接
   *
   * 使用Id方案,可无需配置
   */
  connection?: string | null;
  /** 状态 */
  status?: boolean;
  /** 备注 */
  remark?: string | null;
  /** 租户版本 */
  tenantEditions?: SysTenantEditions;
  /** 配置项 */
  editionsModulePermissions?: SysEditionsModulePermission[] | null;
  /** 关联版本Id */
  tenantEditionsId?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /**
   * 到期时间
   * @format date-time
   */
  endSubscriptionTime?: string | null;
  json?: TenantSettingDto;
  isEnabled?: boolean;
  /**
   * ID
   * 泛型主键Tkey
   */
  id?: string | null;
}

/** 租户版本 */
export interface SysTenantEditions {
  /** 产品名称 */
  name?: string | null;
  /** 是否租户独占 */
  isTenantMonopoly?: boolean;
  /**
   * 订阅月价格
   * @format double
   */
  subscribeMonthlyPrice?: number | null;
  /**
   * 订阅年价格
   * @format double
   */
  subscribeYearlyPrice?: number | null;
  /** 版本名称 */
  versionName?: string | null;
  /**
   * 版本规则
   * @format int32
   */
  editionSaleType?: 0 | 1;
  /** 是否试用 */
  isTry?: boolean;
  /** 租户版本 */
  tenantEditions?: SysTenantEditions;
  /** 关联版本Id */
  tenantEditionsId?: string | null;
  /**
   * 试用数量
   * @format int32
   */
  tryNumber?: number | null;
  /**
   * 试用类型
   * @format int32
   */
  tenantTryType?: 0 | 1 | 2 | 3 | null;
  /**
   * 到期提醒天数
   * @format int32
   */
  reminderDay?: number | null;
  /** 是否延期 */
  isPostpone?: boolean | null;
  /**
   * 延期天数
   * @format int32
   */
  postponeDay?: number | null;
  /**
   * 到期后处理
   * @format int32
   */
  editionsExpiredType?: 0 | 1;
  editionsModulePermissions?: SysEditionsModulePermission[] | null;
  json?: TenantSettingDto;
  /** 雪花Id */
  id?: string | null;
  /** 租户ID */
  tenantId?: string | null;
  /**
   * 系统租户表
   *
   * 根据TenantType 分为两种方案:
   *
   * 1.按租户字段区分
   *
   * 2.按租户分库
   *
   *
   *
   * 注意:
   *
   * 使用租户Id方案,无需配置分库的连接
   */
  tenant?: SysTenant;
  /** 部门ID  DepartmentId */
  organizationUnitId?: string | null;
  /** 组织机构 */
  organizationUnit?: SysOrganizationUnits;
  /** 分所Id */
  officeId?: string | null;
  /** 组织机构 */
  office?: SysOrganizationUnits;
  /** 备注 */
  remark?: string | null;
  /**
   * 是否草稿
   * <param name="如果是操作传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
  /**
   * 中立字段，某些表可使用某些表不使用
   *
   * 逻辑上的删除，非物理删除
   *
   * 例如：单据删除并非直接删除
   */
  isDeleted?: boolean;
  /**
   * 中立字段
   *
   * 是否内置数据
   */
  isInternal?: boolean;
  /** 创建ID */
  createId?: string | null;
  /** 创建者 */
  createBy?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 修改ID */
  modifyId?: string | null;
  /** 更新者 */
  modifyBy?: string | null;
  /**
   * 修改日期
   * @format date-time
   */
  modifyTime?: string | null;
  /**
   * 数据版本
   * @format int64
   */
  version?: number;
  /** 删除ID */
  deleteId?: string | null;
  /** 删除者 */
  deleteBy?: string | null;
  /**
   * 删除日期
   * @format date-time
   */
  deleteTime?: string | null;
}

/** 用户信息表 */
export interface SysUserInfos {
  /** 登录账号 */
  userName?: string | null;
  /** 登录密码 */
  password?: string | null;
  /**
   * 关键业务修改时间
   * @format date-time
   */
  criticalModifyTime?: string;
  /**
   * 最后异常时间
   * @format date-time
   */
  lastErrorTime?: string;
  /**
   * 错误次数
   * @format int32
   */
  errorCount?: number;
  /** 手机号 */
  phoneNumber?: string | null;
  /** 外部标识 */
  originalId?: string | null;
  /** 邮箱 */
  emailAddress?: string | null;
  /** 姓名 */
  name?: string | null;
  /**
   * 用户类型
   * @format int32
   */
  userType?: 0 | 1 | 2;
  /** 业务计时人员Id */
  timekeeperId?: string | null;
  /** 域账号Id */
  activeDirectoryId?: string | null;
  /**
   * 登录失败次数
   * @format int32
   */
  accessFailedCount?: number;
  /** 邮箱验证代码 */
  emailConfirmationCode?: string | null;
  /** 邮箱是否已验证 */
  isEmailConfirmed?: boolean;
  /** 账号锁定是否开启 */
  isLockoutEnabled?: boolean;
  /** 是否手机号已验证 */
  isPhoneNumberConfirmed?: boolean;
  /** 是否启用多因子验证 */
  isTwoFactorEnabled?: boolean;
  /**
   * 账号锁定结束时间
   * @format date-time
   */
  lockoutEndDateUtc?: string | null;
  /** 规范化电子邮件地址 */
  normalizedEmailAddress?: string | null;
  /** 规范化用户名 */
  normalizedUserName?: string | null;
  /** 规范化手机号码 */
  normalizedPhoneNumber?: string | null;
  /** 密码重置代码 */
  passwordResetCode?: string | null;
  /** 下次登录是否需要修改密码 */
  isShouldChangePasswordOnNextLogin?: boolean;
  /**
   * 密码重置周期	天数
   * @format int32
   */
  passwordResetCycle?: number;
  /**
   * MFA多因子登录源
   * @format int32
   */
  authenticationSource?: 0 | 1 | 2;
  /** MFA多因子登录key */
  authenticatorKey?: string | null;
  /** 默认角色 */
  defaultRoleId?: string | null;
  /** 是否在本系统创建 */
  isOwned?: boolean;
  /** 首字母简码 */
  initial?: string | null;
  /**
   * 用户状态
   * @format int32
   */
  userStatus?: 0 | 1 | 2;
  /** 雇员信息表 */
  sysEmployeeInfos?: SysEmployeeInfos;
  /** 费率 */
  employeeRates?: SysEmployeeRates[] | null;
  /**
   * 默认排序
   * @format int32
   */
  sort?: number;
  /** 是否默认角色 */
  isDefault?: boolean;
  /** 是否启用 */
  isActive?: boolean;
  /** 必智运维账号 */
  isBitzOwned?: boolean;
  userRoles?: SysUserRoles[] | null;
  roleNames?: string[] | null;
  /** 流水编号 */
  serialId?: string | null;
  /** 雪花Id */
  id?: string | null;
  /** 租户ID */
  tenantId?: string | null;
  /**
   * 系统租户表
   *
   * 根据TenantType 分为两种方案:
   *
   * 1.按租户字段区分
   *
   * 2.按租户分库
   *
   *
   *
   * 注意:
   *
   * 使用租户Id方案,无需配置分库的连接
   */
  tenant?: SysTenant;
  /** 部门ID  DepartmentId */
  organizationUnitId?: string | null;
  /** 组织机构 */
  organizationUnit?: SysOrganizationUnits;
  /** 分所Id */
  officeId?: string | null;
  /** 组织机构 */
  office?: SysOrganizationUnits;
  /** 备注 */
  remark?: string | null;
  /**
   * 是否草稿
   * <param name="如果是操作传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
  /**
   * 中立字段，某些表可使用某些表不使用
   *
   * 逻辑上的删除，非物理删除
   *
   * 例如：单据删除并非直接删除
   */
  isDeleted?: boolean;
  /**
   * 中立字段
   *
   * 是否内置数据
   */
  isInternal?: boolean;
  /** 创建ID */
  createId?: string | null;
  /** 创建者 */
  createBy?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 修改ID */
  modifyId?: string | null;
  /** 更新者 */
  modifyBy?: string | null;
  /**
   * 修改日期
   * @format date-time
   */
  modifyTime?: string | null;
  /**
   * 数据版本
   * @format int64
   */
  version?: number;
  /** 删除ID */
  deleteId?: string | null;
  /** 删除者 */
  deleteBy?: string | null;
  /**
   * 删除日期
   * @format date-time
   */
  deleteTime?: string | null;
}

/** 用户跟角色关联表 */
export interface SysUserRoles {
  /** 角色表 */
  role?: SysRoles;
  isActive?: boolean;
  /** 用户信息表 */
  userInfo?: SysUserInfos;
  userId?: string | null;
  /** 权限Id */
  roleId?: string | null;
  /** 雪花Id */
  id?: string | null;
  /** 租户ID */
  tenantId?: string | null;
  /**
   * 系统租户表
   *
   * 根据TenantType 分为两种方案:
   *
   * 1.按租户字段区分
   *
   * 2.按租户分库
   *
   *
   *
   * 注意:
   *
   * 使用租户Id方案,无需配置分库的连接
   */
  tenant?: SysTenant;
  /** 部门ID  DepartmentId */
  organizationUnitId?: string | null;
  /** 组织机构 */
  organizationUnit?: SysOrganizationUnits;
  /** 分所Id */
  officeId?: string | null;
  /** 组织机构 */
  office?: SysOrganizationUnits;
  /** 备注 */
  remark?: string | null;
  /**
   * 是否草稿
   * <param name="如果是操作传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
  /**
   * 中立字段，某些表可使用某些表不使用
   *
   * 逻辑上的删除，非物理删除
   *
   * 例如：单据删除并非直接删除
   */
  isDeleted?: boolean;
  /**
   * 中立字段
   *
   * 是否内置数据
   */
  isInternal?: boolean;
  /** 创建ID */
  createId?: string | null;
  /** 创建者 */
  createBy?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 修改ID */
  modifyId?: string | null;
  /** 更新者 */
  modifyBy?: string | null;
  /**
   * 修改日期
   * @format date-time
   */
  modifyTime?: string | null;
  /**
   * 数据版本
   * @format int64
   */
  version?: number;
  /** 删除ID */
  deleteId?: string | null;
  /** 删除者 */
  deleteBy?: string | null;
  /**
   * 删除日期
   * @format date-time
   */
  deleteTime?: string | null;
}

/** 系统锁定配置 */
export interface SystemLockDto {
  /** 是否开启 */
  isEnabled?: boolean;
  /**
   * 锁定分钟 长时间未操作锁定
   * @format int32
   */
  lockMinute?: number;
  /** 适用平台 */
  platforms?: string[] | null;
}

/** 通用返回信息类 */
export interface SystemLockDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 系统锁定配置 */
  response?: SystemLockDto;
}

export interface SystemSecuritySettingDto {
  sensitiveConfig?: SensitiveConfig;
  loginValidateConfig?: LoginValidateConfig;
  passwordConfig?: PasswordConfig;
  /** 访问控制 */
  accessConfig?: AccessConfig;
}

/** 通用返回信息类 */
export interface SystemSecuritySettingDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  response?: SystemSecuritySettingDto;
}

export interface SystemThemeSettingDto {
  themeConfig?: ThemeConfig;
  tableConfig?: TableConfig;
  formConfig?: FormConfig;
  filterConfig?: FilterConfig;
}

/** 通用返回信息类 */
export interface SystemThemeSettingDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  response?: SystemThemeSettingDto;
}

export interface TableConfig {
  /**
   * 默认展示数据条数
   * @format int32
   */
  dataSize?: number;
  /** 操作栏显示位置 */
  operatePosition?: string | null;
  /** 开启全局提醒 */
  isTrueRemind?: boolean;
  /** 默认排序方式 */
  defaultSortWay?: string | null;
  /** 操作跳转方式 */
  operatorHrefWay?: string | null;
}

/** 企业标签\企业画像 */
export interface TagListItem {
  /** 类型 */
  type?: string | null;
  /** 类型说明 */
  name?: string | null;
}

export interface TargetLanguageListDto {
  name?: string | null;
  value?: string | null;
}

/** 通用返回信息类 */
export interface TargetLanguageListDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: TargetLanguageListDto[] | null;
}

/** 纳税信用等级（返回前100条） */
export interface TaxCreditListItem {
  /** 纳税人识别号 */
  taxNo?: string | null;
  /** 评价年度 */
  year?: string | null;
  /** 纳税信用等级 */
  level?: string | null;
  /** 评价单位 */
  org?: string | null;
}

export interface TenantSettingDto {
  account?: AccountDto;
  /** 多语言配置 */
  languageCodes?: string[] | null;
  /** 默认语言 */
  currentLanguageCode?: string | null;
  /** 启用企业信息 */
  isEnabled?: boolean | null;
}

/** 三级分类 */
export interface TertiaryListItem {
  /** 三级分类Code */
  tertiaryCode?: string | null;
  /** 三级分类描述 */
  tertiaryDes?: string | null;
}

export interface ThemeConfig {
  /** 主题样式 */
  theme?: string | null;
  /** 主题颜色 */
  colorPrimary?: string | null;
  /** 多开标签页 */
  isMorePages?: boolean;
  /** 侧边栏背景色 */
  sideBgColor?: string | null;
  /** 菜单栏背景色 */
  menuBgColor?: string | null;
  /** 导航栏样式 */
  navigationBarStyle?: string | null;
}

/** 计时器偏好设置 */
export interface TimerPreferences {
  /** 是否允许多开计时器 */
  isAllowedConcurrentTimers?: boolean;
  /** 是否启用超时提醒 */
  isEnabledTimeoutAlert?: boolean;
  /** 超时提醒时间 */
  timeoutAlertTime?: string | null;
  /**
   * 最小计时单位(分钟)
   * @format int32
   */
  minimumTimingUnit?: number;
}

/** 工时暂代名称表 */
export interface TimesheetNickNameDto {
  /** 暂代名称 */
  nickName?: string | null;
  /** 人员编号 */
  employeeId?: string | null;
  /** 客户编号 */
  clientId?: string | null;
  /** 案件编号 */
  caseId?: string | null;
  /** 备注 */
  remark?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface TimesheetNickNameDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 工时暂代名称表 */
  response?: TimesheetNickNameDto;
}

/** 工时偏好设置 */
export interface TimesheetPreferencesSettingDto {
  /** 日期和时区偏好设置 */
  dateAndTimePreferences?: DateAndTimePreferences;
  /** 工时记录偏好设置 */
  timesheetRecordPreferences?: TimesheetRecordPreferences;
  /** 计时器偏好设置 */
  timerPreferences?: TimerPreferences;
  /** 定时任务偏好设置 */
  scheduledTaskPreferences?: ScheduledTaskPreferences;
  /** 杂项或其他偏好设置 */
  miscellaneousPreferences?: MiscellaneousPreferences;
}

/** 通用返回信息类 */
export interface TimesheetPreferencesSettingDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 工时偏好设置 */
  response?: TimesheetPreferencesSettingDto;
}

/** 工时记录偏好设置 */
export interface TimesheetRecordPreferences {
  /** 是否开启过去工时输入截止日期 */
  isEnabledPastTimesheetEntryDeadline?: boolean;
  /**
   * 过去工时输入截止日期
   * 单位默认为天
   * @format int32
   */
  pastTimesheetEntryDeadline?: number;
  /** 是否开启未来工时输入限制 */
  isEnabledFutureTimesheetEntryLimit?: boolean;
  /**
   * 未来工时输入限制
   * 单位默认为天
   * @format int32
   */
  futureTimesheetEntryLimit?: number;
  /** 是否开启每日最小工时记录小时 */
  isEnabledDailyMinimumRecordedHours?: boolean;
  /**
   * 每日最小工时记录小时
   * @format double
   */
  minimumDailyRecordedHours?: number;
}

/** 转移工时入参 */
export interface TransferTimesheetInput {
  /** 目标案件编号 */
  caseId?: string | null;
  /** 目标客户编号 */
  clientId?: string | null;
  /** 待转移工时ID */
  tobeTransferIds?: string[] | null;
  /** 是否转移所有同NickName的工时 */
  isTransferAllSameNickName?: boolean | null;
}

export interface TranslateLanguagesInput {
  source?: string | null;
  targets?: string[] | null;
}

export interface UpdInvoiceApplyAttachmentInput {
  /** 发票申请id */
  id?: string | null;
  /** 发票抬头说明附件 */
  invoiceHeaderAttachmentId?: string | null;
  /** 协议或补充协议附件 */
  agreementAttachmentId?: string | null;
  /** 其它附件 */
  otherAttachmentId?: string | null;
}

export interface UpdInvoiceAttachmentIdInput {
  /** 发票Id */
  id?: string | null;
  /** 发票附件 */
  attachmentId?: string | null;
}

export interface UpdInvoiceNoInput {
  /** 发票Id */
  id?: string | null;
  /** 发票编号 */
  invoiceNo?: string | null;
}

/** 更新案件流水号 */
export interface UpdateCaseSerialIdInput {
  id?: string | null;
  serialId?: string | null;
}

/** 修改工时记录草稿入参 */
export interface UpdateDraftTimesheetInput {
  /** 工时编号 */
  id?: string | null;
  /**
   * 工作日期
   * @format date-time
   */
  workDate?: string;
  /**
   * 开始时间
   * @format time
   */
  startTime?: string | null;
  /**
   * 结束时间
   * @format time
   */
  endTime?: string | null;
  /**
   * 工作小时数
   * @format double
   */
  hoursWorked?: number;
  /**
   * 内部费率
   * @format double
   */
  internalRate?: number;
  /** 客户编号 */
  clientId?: string | null;
  /** 案件编号 */
  caseId?: string | null;
  /** 书写语言 */
  writtenLanguage?: string | null;
  /** 是否收藏 */
  isFavorited?: boolean | null;
  /**
   * 累计计时时间
   * @format double
   */
  accumulatedTime?: number;
  /** 是否跨天拆分 */
  isSplitAcrossDays?: boolean | null;
  /** 计时人员 */
  employeeId?: string | null;
  /** 计时状态 */
  timerState?: string | null;
  /** 工时描述 */
  narrative?: string | null;
  /** 工时类别 */
  category?: string | null;
  /** 暂代昵称 */
  nickName?: string | null;
  /** 关联任务 */
  task?: string | null;
  /** 活动代码 */
  activityCode?: string | null;
  /** 货币类型 */
  currency?: string | null;
  /** 是否计时器计时 */
  isTimerTracked?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

export interface UpdateFinancialPublicReceiptOfficeInput {
  /** 机构Id */
  officeId?: string | null;
  /** 原因 */
  remark?: string | null;
  id?: string | null;
}

export interface UpdateFlowActivityNodeInput {
  /** 节点名称 */
  nodeName?: string | null;
  /** 流程节点数据 */
  nodeData?: string | null;
  /** 流程步骤 */
  nodeStep?: string | null;
  /** 节点类型 */
  nodeType?: string | null;
  /** 是否是客户执行节点 */
  isGuestNode?: boolean | null;
  /** 节点时限 */
  isNodeTimelimit?: boolean | null;
  /** 节点时限规则 */
  nodeTimelimitRule?: string | null;
  /**
   * 节点时限规则
   * @format int32
   */
  nodeTimelimitType?: 0 | 1 | 2 | null;
  /**
   * 紧急级别
   * @format int32
   */
  urgentLevel?: number | null;
  /** 是否会签节点 */
  isCounterSigned?: boolean | null;
  /**
   * 会签通过率
   * @format int32
   */
  counterSignedPassRate?: number | null;
  /**
   * 会签选择类型
   * @format int32
   */
  counterSignedType?: 2 | 3 | null;
  /** 是否允许审批驳回 */
  isRollback?: boolean | null;
  /**
   * 退回规则;默认退回一步，前置节点都可退回，指定节点可退回
   * @format int32
   */
  rollbackRule?: 1 | 2 | 3 | -1 | null;
  /** 驳回节点id */
  rollbackToNodeId?: string | null;
  /** 经办人按流经节点设置 复制上个节点参数设置;经办人按流经节点设置 复制上个节点参数设置 */
  actorBypPrevNodeId?: string | null;
  /** 是否开启跳转到节点;是否开启跳转到节点 */
  isSendToNode?: boolean | null;
  /** 跳转到指定节点;跳转到指定节点 */
  sendToNode?: string | null;
  /** 是否允许撤回;是否允许撤回 */
  isWithdraw?: boolean | null;
  /** 是否允许转交;是否允许转交 */
  isTransmitted?: boolean | null;
  /** 是否允许抄送;是否允许抄送 */
  isCC?: boolean | null;
  /** 通用参数 */
  paylodParams?: string | null;
  /** 审核默认意见 */
  approvalOpinion?: string | null;
  /** 审批意见开关 */
  isApprovalOpinion?: boolean | null;
  /** 是否提醒发送用户 */
  isRemindUser?: boolean | null;
  /** 提醒类型 */
  remindType?: string | null;
  /** 是否紧急 */
  isUrgent?: boolean | null;
  /** 是否结束流程 */
  isEndFlow?: boolean | null;
  /** 提交文本 */
  submitText?: string | null;
  /** 暂存文本 */
  temporarilyText?: string | null;
  /** 回退文本 */
  returnText?: string | null;
  /** 加签文本 */
  increaseText?: string | null;
  /** 转交文本 */
  transferText?: string | null;
  /** 关闭流程 */
  closeFlowText?: string | null;
  /** 是否暂存 */
  isStaging?: boolean | null;
  /** 是否发送 */
  isSend?: boolean | null;
  /** 是否加签 */
  isCountersignUser?: boolean | null;
  /** 当前工作流状态 */
  flowStateId?: string | null;
  /** 工作流退回节点状态 */
  flowReturnStateId?: string | null;
  /** 工作流关闭节点状态 */
  flowCloseStateId?: string | null;
  /** 回调事件 */
  capEventName?: string | null;
  /** 是否节点控制 */
  isNodeControl?: boolean | null;
  /** 允许退回直达审批 */
  isRollbackToApproval?: boolean | null;
  /** 是否允许可编辑 */
  isAllowEdit?: boolean | null;
  /** 是否允许申请人审批 */
  isApplicantAudit?: boolean | null;
  /** 是否逐人审批 只有在会签节点时有效 */
  examinationApproval?: boolean | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

export interface UpdateNarrativeInput {
  /** 工时编号 */
  id?: string | null;
  /** 工时描述 */
  narrative?: string | null;
}

export interface UpdateRemarkInput {
  /** Id */
  id?: string | null;
  /** 备注 */
  remark?: string | null;
}

/** 修改工时记录入参 */
export interface UpdateTimesheetInput {
  /** 工时编号 */
  id?: string | null;
  /**
   * 工作日期
   * @format date-time
   */
  workDate?: string;
  /**
   * 开始时间
   * @format time
   */
  startTime?: string | null;
  /**
   * 结束时间
   * @format time
   */
  endTime?: string | null;
  /**
   * 工作小时数
   * @format double
   */
  hoursWorked?: number;
  /**
   * 内部费率
   * @format double
   */
  internalRate?: number;
  /** 客户编号 */
  clientId?: string | null;
  /** 案件编号 */
  caseId?: string | null;
  /** 书写语言 */
  writtenLanguage?: string | null;
  /** 是否收藏 */
  isFavorited?: boolean | null;
  /**
   * 累计计时时间
   * @format double
   */
  accumulatedTime?: number;
  /** 是否跨天拆分 */
  isSplitAcrossDays?: boolean | null;
  /** 计时人员 */
  employeeId?: string | null;
  /** 计时状态 */
  timerState?: string | null;
  /** 工时描述 */
  narrative?: string | null;
  /** 工时类别 */
  category?: string | null;
  /** 暂代昵称 */
  nickName?: string | null;
  /** 关联任务 */
  task?: string | null;
  /** 活动代码 */
  activityCode?: string | null;
  /** 货币类型 */
  currency?: string | null;
  /** 是否计时器计时 */
  isTimerTracked?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

export interface UpdateUserEmailInput {
  /** 手机号 */
  phone?: string | null;
  /** 验证码 */
  verifyCode?: string | null;
  /** 邮箱 */
  email?: string | null;
  /** 邮箱验证码 */
  emailVerifyCode?: string | null;
}

export interface UpdateUserPassWordInput {
  /** 当前密码 */
  currentPassWord?: string | null;
  /** 新密码 */
  newPassWord?: string | null;
  /** 确认新密码 */
  confirmNewPassWord?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

export interface UpdateUserPhoneInput {
  /** 旧手机号 */
  oldPhone?: string | null;
  /** 旧手机号验证码 */
  verifyCode?: string | null;
  /** 新手机号 */
  newPhone?: string | null;
  /** 新手机号验证码 */
  newVerifyCode?: string | null;
}

export interface UpdateUserPhoneToPasswordInput {
  /** 手机号 */
  phone?: string | null;
  /** 验证码 */
  code?: string | null;
  /** 密码 */
  password?: string | null;
  /** 确认密码 */
  confirmPassword?: string | null;
}

export interface UserInfoDto {
  /** 登录账号 */
  userName?: string | null;
  /** 显示名称 */
  displayName?: string | null;
  /** 编号 */
  serialId?: string | null;
  /** 工号 */
  jobNumber?: string | null;
  /** 别名 */
  alterName?: string | null;
  /** 手机号 */
  phoneNumber?: string | null;
  /** 头像地址 */
  pictureUrl?: string | null;
  /** 邮箱 */
  emailAddress?: string | null;
  /** 姓名 */
  name?: string | null;
  /** 邮箱是否已验证 */
  isEmailConfirmed?: boolean;
  /** 账号锁定是否开启 */
  isLockoutEnabled?: boolean;
  /** 是否手机号已验证 */
  isPhoneNumberConfirmed?: boolean;
  /** 是否启用多因子验证 */
  isTwoFactorEnabled?: boolean;
  /** 部门 */
  organizationUnitName?: string | null;
  /** 分所 */
  officeName?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 权限组 ， */
  roleNames?: string[] | null;
  roleIds?: string[] | null;
  /**
   * 用户组
   * 岗位
   */
  positionNames?: string[] | null;
  /** 岗位 */
  positionIds?: string[] | null;
  /** 用户组 */
  userGroupNames?: string[] | null;
  userGroupIds?: string[] | null;
  /**
   * 用户状态
   * @format int32
   */
  userStatus?: 0 | 1 | 2;
  /** 用户状态 */
  userStatusText?: string | null;
  /** 是否启用 */
  isActive?: boolean;
  /** 业务计时人员Id */
  timekeeperId?: string | null;
  /** 是否合伙人 */
  isParent?: boolean | null;
  /** 是否权益合伙人 */
  isEquityPartner?: boolean | null;
  /**
   * 入职时间
   * @format date-time
   */
  entryTime?: string | null;
  /**
   * 最后登录时间
   * @format date-time
   */
  lastLoginTime?: string | null;
  /** 组织机构Id */
  organizationUnitId?: string | null;
  /**
   * 用户类型
   * @format int32
   */
  userType?: 0 | 1 | 2;
  userTypeText?: string | null;
  /** 费率 */
  employeeRates?: EmployeeRatesDto[] | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface UserInfoDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: UserInfoDto[] | null;
}

/** 通用返回信息类 */
export interface UserInfoDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  response?: UserInfoDto;
}

/** 通用分页信息类 */
export interface UserInfoDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: UserInfoDto[] | null;
}

/** 通用返回信息类 */
export interface UserInfoDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: UserInfoDtoPageModel;
}

export interface UserLoginAttemptsDto {
  /** 用户名 */
  userName?: string | null;
  /** 手机号 */
  phoneNumber?: string | null;
  /**
   * 操作类型
   * @format int32
   */
  loginOperation?: 0 | 1 | 2 | 3 | 4;
  /** 浏览器代理信息 */
  browserAgent?: string | null;
  /** 客户端Ip地址 */
  clientIpAddress?: string | null;
  /** 客户端Ip所在地区 */
  clientIpRegion?: string | null;
  /** 客户端Ip所在地区坐标 */
  clientIpLocation?: string | null;
  /** 客户端名称 */
  clientName?: string | null;
  /** 客户端设备信息 */
  clientDeviceInfo?: string | null;
  /** 是否在异地登录 */
  isLoginFromAnotherLocation?: boolean;
  /** 是否常用设备登录 */
  isLoginFromInCommonUseClient?: boolean;
  /** 是否App登录 */
  isAppLogin?: boolean;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用分页信息类 */
export interface UserLoginAttemptsDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: UserLoginAttemptsDto[] | null;
}

/** 通用返回信息类 */
export interface UserLoginAttemptsDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: UserLoginAttemptsDtoPageModel;
}

/** 用户手册内容 */
export interface UserManualContentDto {
  /** 模块ID */
  userManualModuleId?: string | null;
  /** 模块名称 */
  userManualModuleName?: string | null;
  /** 内容 */
  content?: string | null;
  /** 简介 */
  brief?: string | null;
  /** 关键词 */
  keyWords?: string | null;
  /**
   * 访问量
   * @format int32
   */
  pageview?: number;
  /** 备注 */
  remark?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface UserManualContentDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 用户手册内容 */
  response?: UserManualContentDto;
}

/** 用户手册问题 */
export interface UserManualFAQDto {
  /** id */
  userManualContentId?: string | null;
  /** 模块ID */
  userManualModuleId?: string | null;
  /** 模块名称 */
  userManualModuleName?: string | null;
  /** 主题 */
  question?: string | null;
  /** 答案 */
  answer?: string | null;
  /**
   * 排序
   * @format int32
   */
  sort?: number;
  /** 备注 */
  remark?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface UserManualFAQDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 用户手册问题 */
  response?: UserManualFAQDto;
}

/** 用户手册模块 */
export interface UserManualModuleDto {
  /** 名称 */
  name?: string | null;
  /** 父级ID */
  parentId?: string | null;
  /** 主题 */
  topic?: string | null;
  /** 视频 */
  video?: string | null;
  /** 视频时长 */
  videoDuration?: string | null;
  /** 封面 */
  picture?: string | null;
  /** 业务描述 */
  description?: string | null;
  /** 汇总描述 */
  summary?: string | null;
  /** 菜单链接 */
  website?: string | null;
  /**
   * 排序
   * @format int32
   */
  sort?: number;
  /** 是否是菜单 */
  isMenu?: string | null;
  /** 语言 */
  language?: string | null;
  /** 备注 */
  remark?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface UserManualModuleDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 用户手册模块 */
  response?: UserManualModuleDto;
}

export interface UserManualModuleTree {
  /** 当前权限Id */
  value?: string | null;
  /** 上级Id */
  parentId?: string | null;
  /** 显示 */
  label?: string | null;
  /**
   * 排序
   * @format int32
   */
  order?: number;
  /** 子菜单 */
  children?: UserManualModuleTree[] | null;
}

/** 通用返回信息类 */
export interface UserManualModuleTreeMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  response?: UserManualModuleTree;
}

export interface UserOrganizationUnitDto {
  /** @format int32 */
  thresholdFlag?: 0 | 1 | 2 | 3 | 4 | 5;
  thresholdFlagText?: string | null;
  userId?: string | null;
  roleId?: string | null;
  isActive?: boolean;
  roleName?: string | null;
  userName?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 分所Id */
  officeId?: string | null;
  /** 分所名称 */
  officeName?: string | null;
  /** 业务状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
  /**
   * 修改时间
   * @format date-time
   */
  modifyTime?: string;
}

/** 通用返回信息类 */
export interface UserOrganizationUnitDtoIListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: UserOrganizationUnitDto[] | null;
}

export interface UserWorkTransferInput {
  /** 交接人用户Id */
  userId?: string[] | null;
  /** 接收人用户Id */
  transferUserId?: string | null;
}

import type { AxiosInstance, AxiosRequestConfig, HeadersDefaults, ResponseType } from 'axios';
import axios from 'axios';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (securityData: SecurityDataType | null) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain'
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || '' });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {})
      }
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    if (input instanceof FormData) {
      return input;
    }
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({ secure, path, type, query, format, body, ...params }: FullRequestParams): Promise<T> => {
    const secureParams = ((typeof secure === 'boolean' ? secure : this.secure) && this.securityWorker && (await this.securityWorker(this.securityData))) || {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body);
    }

    return this.instance
      .request({
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type ? { 'Content-Type': type } : {})
        },
        params: query,
        responseType: responseFormat,
        data: body,
        url: path
      })
      .then((response) => response.data);
  };
}

/**
 * @title Web接口
 * @version 1.0.0
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * No description
     *
     * @tags CaseAcceptAgency
     * @name WebBusinessCaseCaseacceptagencyGetpagePageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/business/case/caseacceptagency/getpage/page
     * @secure
     */
    webBusinessCaseCaseacceptagencyGetpagePageCreate: (
      query?: {
        /** 案件或利冲编号 */
        caseId?: string;
        /** 机构名称 */
        name?: string;
        /** 机构类别（法院/检察院/监狱等） */
        agencyType?: string;
        /**
         * 开始时间
         * @format date-time
         */
        startTime?: string;
        /**
         * 结束时间
         * @format date-time
         */
        endTime?: string;
        /** 事由分类 */
        category?: string;
        /** 案件阶段 */
        stage?: string;
        /** 联系人 */
        linker?: string;
        /** 联系电话 */
        phoneNumber?: string;
        /** 关联法院编号 */
        courtId?: string;
        /** 部门ID  DepartmentId */
        organizationUnitId?: string;
        /** 分所Id */
        officeId?: string;
        /** 备注 */
        remark?: string;
        /** 状态 */
        isEnabled?: boolean;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<CaseAcceptAgencyDtoPageModelMessageModel, void>({
        path: `/api/web/business/case/caseacceptagency/getpage/page`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CaseAcceptAgency
     * @name WebBusinessCaseCaseacceptagencyListListList
     * @summary 列表获取所有 (Auth policies: Web)
     * @request GET:/api/web/business/case/caseacceptagency/list/list
     * @secure
     */
    webBusinessCaseCaseacceptagencyListListList: (
      query?: {
        /** 案件或利冲编号 */
        caseId?: string;
        /** 机构名称 */
        name?: string;
        /** 机构类别（法院/检察院/监狱等） */
        agencyType?: string;
        /**
         * 开始时间
         * @format date-time
         */
        startTime?: string;
        /**
         * 结束时间
         * @format date-time
         */
        endTime?: string;
        /** 事由分类 */
        category?: string;
        /** 案件阶段 */
        stage?: string;
        /** 联系人 */
        linker?: string;
        /** 联系电话 */
        phoneNumber?: string;
        /** 关联法院编号 */
        courtId?: string;
        /** 部门ID  DepartmentId */
        organizationUnitId?: string;
        /** 分所Id */
        officeId?: string;
        /** 备注 */
        remark?: string;
        /** 状态 */
        isEnabled?: boolean;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<CaseAcceptAgencyDtoListMessageModel, void>({
        path: `/api/web/business/case/caseacceptagency/list/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CaseAcceptAgency
     * @name WebBusinessCaseCaseacceptagencyGetDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/business/case/caseacceptagency/get/{id}
     * @secure
     */
    webBusinessCaseCaseacceptagencyGetDetail: (id: string, params: RequestParams = {}) =>
      this.request<CaseAcceptAgencyDtoMessageModel, void>({
        path: `/api/web/business/case/caseacceptagency/get/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CaseAcceptAgency
     * @name WebBusinessCaseCaseacceptagencyPostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/business/case/caseacceptagency/post
     * @secure
     */
    webBusinessCaseCaseacceptagencyPostCreate: (data: CreateCaseAcceptAgencyInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/case/caseacceptagency/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CaseAcceptAgency
     * @name WebBusinessCaseCaseacceptagencyPutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/business/case/caseacceptagency/put/{id}
     * @secure
     */
    webBusinessCaseCaseacceptagencyPutUpdate: (id: string, data: CreateCaseAcceptAgencyInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/case/caseacceptagency/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CaseAcceptAgency
     * @name WebBusinessCaseCaseacceptagencyDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/business/case/caseacceptagency/delete/{id}
     * @secure
     */
    webBusinessCaseCaseacceptagencyDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/case/caseacceptagency/delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CaseClosed
     * @name WebBusinessCaseclosedCaseclosedGetpagePageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/business/caseclosed/caseclosed/getpage/page
     * @secure
     */
    webBusinessCaseclosedCaseclosedGetpagePageCreate: (
      query?: {
        id?: string;
        /** 案件编号 */
        caseId?: string;
        /** 裁判类型（判决/裁定/调解/其他） */
        refereeType?: string;
        /** 受理机构（法院名称） */
        court?: string;
        /**
         * 裁判文书日期
         * @format date-time
         */
        judgmentDate?: string;
        /** 裁判文书号 */
        judgmentNo?: string;
        /**
         * 送达日期
         * @format date-time
         */
        deliveryDate?: string;
        /** 是否机密案卷 */
        confidentialVolume?: boolean;
        /** 结案时收费额是否与委托协议一致 */
        isConsistentCharge?: boolean;
        /**
         * 起诉日期
         * @format date-time
         */
        sueDate?: string;
        /**
         * 阅卷日期
         * @format date-time
         */
        examDate?: string;
        /**
         * 初次会见/谈话日期
         * @format date-time
         */
        meetDate?: string;
        /**
         * 首次开庭日期
         * @format date-time
         */
        courtDate?: string;
        /** 简要案情和工作概况 */
        result?: string;
        /** 处理结果 */
        pocessResult?: string;
        /** 律师办案体会 */
        experience?: string;
        /** 代理事项 */
        discussion?: string;
        /** 结案必要文件夹 */
        folders?: string;
        /** 归档人编号 */
        archiveUserId?: string;
        /**
         * 归档日期
         * @format date-time
         */
        archiveDate?: string;
        /** 归档编号 */
        archiveId?: string;
        /** 归档状态 */
        archiveStatus?: string;
        /** 服务结果（中止/终止/存续） */
        serviceResult?: string;
        /** 电子档案编号 */
        electronicFileNo?: string;
        /** 是否扫描件 */
        isScanFile?: boolean;
        /** 是否逾期结案 */
        isOverdueFiling?: boolean;
        /** 逾期结案原因 */
        overdueFilingReason?: string;
        /**
         * 逾期天数
         * @format double
         */
        overdueDays?: number;
        /** 归档地 */
        archivePlace?: string;
        /** 部门ID  DepartmentId */
        organizationUnitId?: string;
        /** 分所Id */
        officeId?: string;
        /** 备注 */
        remark?: string;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<CaseClosedDtoPageModelMessageModel, void>({
        path: `/api/web/business/caseclosed/caseclosed/getpage/page`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CaseClosed
     * @name WebBusinessCaseclosedCaseclosedListListList
     * @summary 列表获取所有 (Auth policies: Web)
     * @request GET:/api/web/business/caseclosed/caseclosed/list/list
     * @secure
     */
    webBusinessCaseclosedCaseclosedListListList: (
      query?: {
        id?: string;
        /** 案件编号 */
        caseId?: string;
        /** 裁判类型（判决/裁定/调解/其他） */
        refereeType?: string;
        /** 受理机构（法院名称） */
        court?: string;
        /**
         * 裁判文书日期
         * @format date-time
         */
        judgmentDate?: string;
        /** 裁判文书号 */
        judgmentNo?: string;
        /**
         * 送达日期
         * @format date-time
         */
        deliveryDate?: string;
        /** 是否机密案卷 */
        confidentialVolume?: boolean;
        /** 结案时收费额是否与委托协议一致 */
        isConsistentCharge?: boolean;
        /**
         * 起诉日期
         * @format date-time
         */
        sueDate?: string;
        /**
         * 阅卷日期
         * @format date-time
         */
        examDate?: string;
        /**
         * 初次会见/谈话日期
         * @format date-time
         */
        meetDate?: string;
        /**
         * 首次开庭日期
         * @format date-time
         */
        courtDate?: string;
        /** 简要案情和工作概况 */
        result?: string;
        /** 处理结果 */
        pocessResult?: string;
        /** 律师办案体会 */
        experience?: string;
        /** 代理事项 */
        discussion?: string;
        /** 结案必要文件夹 */
        folders?: string;
        /** 归档人编号 */
        archiveUserId?: string;
        /**
         * 归档日期
         * @format date-time
         */
        archiveDate?: string;
        /** 归档编号 */
        archiveId?: string;
        /** 归档状态 */
        archiveStatus?: string;
        /** 服务结果（中止/终止/存续） */
        serviceResult?: string;
        /** 电子档案编号 */
        electronicFileNo?: string;
        /** 是否扫描件 */
        isScanFile?: boolean;
        /** 是否逾期结案 */
        isOverdueFiling?: boolean;
        /** 逾期结案原因 */
        overdueFilingReason?: string;
        /**
         * 逾期天数
         * @format double
         */
        overdueDays?: number;
        /** 归档地 */
        archivePlace?: string;
        /** 部门ID  DepartmentId */
        organizationUnitId?: string;
        /** 分所Id */
        officeId?: string;
        /** 备注 */
        remark?: string;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<CaseClosedDtoListMessageModel, void>({
        path: `/api/web/business/caseclosed/caseclosed/list/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CaseClosed
     * @name WebBusinessCaseclosedCaseclosedGetDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/business/caseclosed/caseclosed/get/{id}
     * @secure
     */
    webBusinessCaseclosedCaseclosedGetDetail: (id: string, params: RequestParams = {}) =>
      this.request<CaseClosedDtoMessageModel, void>({
        path: `/api/web/business/caseclosed/caseclosed/get/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CaseClosed
     * @name WebBusinessCaseclosedCaseclosedPostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/business/caseclosed/caseclosed/post
     * @secure
     */
    webBusinessCaseclosedCaseclosedPostCreate: (data: CreateCaseClosedInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/caseclosed/caseclosed/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CaseClosed
     * @name WebBusinessCaseclosedCaseclosedPutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/business/caseclosed/caseclosed/put/{id}
     * @secure
     */
    webBusinessCaseclosedCaseclosedPutUpdate: (id: string, data: CreateCaseClosedInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/caseclosed/caseclosed/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CaseClosed
     * @name WebBusinessCaseclosedCaseclosedDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/business/caseclosed/caseclosed/delete/{id}
     * @secure
     */
    webBusinessCaseclosedCaseclosedDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/caseclosed/caseclosed/delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CaseCollection
     * @name WebBusinessCaseCasecollectionGetpagePageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/business/case/casecollection/getpage/page
     * @secure
     */
    webBusinessCaseCasecollectionGetpagePageCreate: (
      query?: {
        id?: string;
        /** 案件编号 */
        caseId?: string;
        /** 收费方式 */
        chargingMethod?: string;
        /**
         * 收费金额
         * @format double
         */
        amount?: number;
        /**
         * 基础风险金额
         * @format double
         */
        riskBasicAmount?: number;
        /** 说明描述/风险达成条件 */
        narrative?: string;
        /** 是否可收款 */
        isAcceptable?: boolean;
        /** 是否使用账单 */
        isUseBilling?: boolean;
        /**
         * 收费日期
         * @format date-time
         */
        chargeDate?: string;
        /** 是否已到账 */
        isArrived?: string;
        /**
         * 到账日期
         * @format date-time
         */
        arrivedDate?: string;
        /**
         * 到账金额
         * @format double
         */
        arrivedAmount?: number;
        /** 币种 */
        currency?: string;
        /**
         * 汇率
         * @format double
         */
        exchangeRate?: number;
        /** 是否账单 */
        isBilling?: string;
        /** 关联账单编号 */
        billingId?: string;
        /**
         * 付款比例
         * @format double
         */
        chargeRatio?: number;
        /**
         * 开始时间
         * @format date-time
         */
        startDate?: string;
        /**
         * 结束时间
         * @format date-time
         */
        endDate?: string;
        /** 是否提醒 */
        isRemind?: string;
        /**
         * 提醒时间
         * @format date-time
         */
        remindTime?: string;
        /**
         * 风险收费比例
         * @format double
         */
        riskRatio?: number;
        /** 关联案件编号 */
        relationCaseId?: string;
        /**
         * 外币收费金额
         * @format double
         */
        foreignCurrencyAmount?: number;
        /** 部门ID  DepartmentId */
        organizationUnitId?: string;
        /** 分所Id */
        officeId?: string;
        /** 备注 */
        remark?: string;
        /** 状态 	            中立字段，某些表可使用某些表不使用 */
        isEnabled?: boolean;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<CaseCollectionDtoPageModelMessageModel, void>({
        path: `/api/web/business/case/casecollection/getpage/page`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CaseCollection
     * @name WebBusinessCaseCasecollectionListListList
     * @summary 列表获取所有 (Auth policies: Web)
     * @request GET:/api/web/business/case/casecollection/list/list
     * @secure
     */
    webBusinessCaseCasecollectionListListList: (
      query?: {
        id?: string;
        /** 案件编号 */
        caseId?: string;
        /** 收费方式 */
        chargingMethod?: string;
        /**
         * 收费金额
         * @format double
         */
        amount?: number;
        /**
         * 基础风险金额
         * @format double
         */
        riskBasicAmount?: number;
        /** 说明描述/风险达成条件 */
        narrative?: string;
        /** 是否可收款 */
        isAcceptable?: boolean;
        /** 是否使用账单 */
        isUseBilling?: boolean;
        /**
         * 收费日期
         * @format date-time
         */
        chargeDate?: string;
        /** 是否已到账 */
        isArrived?: string;
        /**
         * 到账日期
         * @format date-time
         */
        arrivedDate?: string;
        /**
         * 到账金额
         * @format double
         */
        arrivedAmount?: number;
        /** 币种 */
        currency?: string;
        /**
         * 汇率
         * @format double
         */
        exchangeRate?: number;
        /** 是否账单 */
        isBilling?: string;
        /** 关联账单编号 */
        billingId?: string;
        /**
         * 付款比例
         * @format double
         */
        chargeRatio?: number;
        /**
         * 开始时间
         * @format date-time
         */
        startDate?: string;
        /**
         * 结束时间
         * @format date-time
         */
        endDate?: string;
        /** 是否提醒 */
        isRemind?: string;
        /**
         * 提醒时间
         * @format date-time
         */
        remindTime?: string;
        /**
         * 风险收费比例
         * @format double
         */
        riskRatio?: number;
        /** 关联案件编号 */
        relationCaseId?: string;
        /**
         * 外币收费金额
         * @format double
         */
        foreignCurrencyAmount?: number;
        /** 部门ID  DepartmentId */
        organizationUnitId?: string;
        /** 分所Id */
        officeId?: string;
        /** 备注 */
        remark?: string;
        /** 状态 	            中立字段，某些表可使用某些表不使用 */
        isEnabled?: boolean;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<CaseCollectionDtoListMessageModel, void>({
        path: `/api/web/business/case/casecollection/list/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CaseCollection
     * @name WebBusinessCaseCasecollectionGetDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/business/case/casecollection/get/{id}
     * @secure
     */
    webBusinessCaseCasecollectionGetDetail: (id: string, params: RequestParams = {}) =>
      this.request<CaseCollectionDtoMessageModel, void>({
        path: `/api/web/business/case/casecollection/get/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CaseCollection
     * @name WebBusinessCaseCasecollectionPostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/business/case/casecollection/post
     * @secure
     */
    webBusinessCaseCasecollectionPostCreate: (data: CreateCaseCollectionInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/case/casecollection/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CaseCollection
     * @name WebBusinessCaseCasecollectionPutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/business/case/casecollection/put/{id}
     * @secure
     */
    webBusinessCaseCasecollectionPutUpdate: (id: string, data: CreateCaseCollectionInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/case/casecollection/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CaseCollection
     * @name WebBusinessCaseCasecollectionDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/business/case/casecollection/delete/{id}
     * @secure
     */
    webBusinessCaseCasecollectionDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/case/casecollection/delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CaseDistribution
     * @name WebBusinessCaseCasedistributionGetpagePageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/business/case/casedistribution/getpage/page
     * @secure
     */
    webBusinessCaseCasedistributionGetpagePageCreate: (
      query?: {
        id?: string;
        /** 案件编号 */
        caseId?: string;
        /** 用户编号 */
        userId?: string;
        /** 律师角色 */
        lawyerRole?: string;
        /**
         * 分配金额
         * @format double
         */
        allocationAmount?: number;
        /**
         * 分配比例
         * @format double
         */
        allocationRate?: number;
        /**
         * 尾差金额
         * @format double
         */
        tailAmount?: number;
        /** 是否案源费 */
        isSourceFee?: boolean;
        /** 是否默认策略 */
        isDefault?: boolean;
        /** 部门ID  DepartmentId */
        organizationUnitId?: string;
        /** 分所Id */
        officeId?: string;
        /** 备注 */
        remark?: string;
        /** 状态 	            中立字段，某些表可使用某些表不使用 */
        isEnabled?: boolean;
        /** 中立字段，某些表可使用某些表不使用   	            逻辑上的删除，非物理删除  	            例如：单据删除并非直接删除 */
        isDeleted?: boolean;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<CaseDistributionDtoPageModelMessageModel, void>({
        path: `/api/web/business/case/casedistribution/getpage/page`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CaseDistribution
     * @name WebBusinessCaseCasedistributionListListList
     * @summary 列表获取所有 (Auth policies: Web)
     * @request GET:/api/web/business/case/casedistribution/list/list
     * @secure
     */
    webBusinessCaseCasedistributionListListList: (
      query?: {
        id?: string;
        /** 案件编号 */
        caseId?: string;
        /** 用户编号 */
        userId?: string;
        /** 律师角色 */
        lawyerRole?: string;
        /**
         * 分配金额
         * @format double
         */
        allocationAmount?: number;
        /**
         * 分配比例
         * @format double
         */
        allocationRate?: number;
        /**
         * 尾差金额
         * @format double
         */
        tailAmount?: number;
        /** 是否案源费 */
        isSourceFee?: boolean;
        /** 是否默认策略 */
        isDefault?: boolean;
        /** 部门ID  DepartmentId */
        organizationUnitId?: string;
        /** 分所Id */
        officeId?: string;
        /** 备注 */
        remark?: string;
        /** 状态 	            中立字段，某些表可使用某些表不使用 */
        isEnabled?: boolean;
        /** 中立字段，某些表可使用某些表不使用   	            逻辑上的删除，非物理删除  	            例如：单据删除并非直接删除 */
        isDeleted?: boolean;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<CaseDistributionDtoListMessageModel, void>({
        path: `/api/web/business/case/casedistribution/list/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CaseDistribution
     * @name WebBusinessCaseCasedistributionGetDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/business/case/casedistribution/get/{id}
     * @secure
     */
    webBusinessCaseCasedistributionGetDetail: (id: string, params: RequestParams = {}) =>
      this.request<CaseDistributionDtoMessageModel, void>({
        path: `/api/web/business/case/casedistribution/get/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CaseDistribution
     * @name WebBusinessCaseCasedistributionPostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/business/case/casedistribution/post
     * @secure
     */
    webBusinessCaseCasedistributionPostCreate: (data: CreateCaseDistributionInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/case/casedistribution/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CaseDistribution
     * @name WebBusinessCaseCasedistributionPutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/business/case/casedistribution/put/{id}
     * @secure
     */
    webBusinessCaseCasedistributionPutUpdate: (id: string, data: CreateCaseDistributionInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/case/casedistribution/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CaseDistribution
     * @name WebBusinessCaseCasedistributionDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/business/case/casedistribution/delete/{id}
     * @secure
     */
    webBusinessCaseCasedistributionDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/case/casedistribution/delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CaseLawyer
     * @name WebBusinessCaseCaselawyerGetpagePageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/business/case/caselawyer/getpage/page
     * @secure
     */
    webBusinessCaseCaselawyerGetpagePageCreate: (
      query?: {
        /** 案件编号 */
        caseId?: string;
        /** 用户编号 */
        userId?: string;
        /** 律师参案角色 */
        lawyerRole?: string;
        /**
         * 律师费率 外币
         * @format double
         */
        uSDChargeRatio?: number;
        /**
         * 分配比例
         * @format double
         */
        assignRatio?: number;
        /** 是否启用（参与分配） */
        isEnableAssign?: boolean;
        /**
         * 律师费率
         * @format double
         */
        chargeRatio?: number;
        /**
         * 服务开始时间
         * @format date-time
         */
        startDate?: string;
        /**
         * 服务结束时间
         * @format date-time
         */
        endDate?: string;
        /** 是否非当前服务律师 */
        isInActive?: boolean;
        /** 备注 */
        remark?: string;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<CaseLawyerDtoPageModelMessageModel, void>({
        path: `/api/web/business/case/caselawyer/getpage/page`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CaseLawyer
     * @name WebBusinessCaseCaselawyerListListList
     * @summary 列表获取所有 (Auth policies: Web)
     * @request GET:/api/web/business/case/caselawyer/list/list
     * @secure
     */
    webBusinessCaseCaselawyerListListList: (
      query?: {
        /** 案件编号 */
        caseId?: string;
        /** 用户编号 */
        userId?: string;
        /** 律师参案角色 */
        lawyerRole?: string;
        /**
         * 律师费率 外币
         * @format double
         */
        uSDChargeRatio?: number;
        /**
         * 分配比例
         * @format double
         */
        assignRatio?: number;
        /** 是否启用（参与分配） */
        isEnableAssign?: boolean;
        /**
         * 律师费率
         * @format double
         */
        chargeRatio?: number;
        /**
         * 服务开始时间
         * @format date-time
         */
        startDate?: string;
        /**
         * 服务结束时间
         * @format date-time
         */
        endDate?: string;
        /** 是否非当前服务律师 */
        isInActive?: boolean;
        /** 备注 */
        remark?: string;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<CaseLawyerDtoListMessageModel, void>({
        path: `/api/web/business/case/caselawyer/list/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CaseLawyer
     * @name WebBusinessCaseCaselawyerGetDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/business/case/caselawyer/get/{id}
     * @secure
     */
    webBusinessCaseCaselawyerGetDetail: (id: string, params: RequestParams = {}) =>
      this.request<CaseLawyerDtoMessageModel, void>({
        path: `/api/web/business/case/caselawyer/get/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CaseLawyer
     * @name WebBusinessCaseCaselawyerPostCreate
     * @summary 添加参案律师 (Auth policies: Web)
     * @request POST:/api/web/business/case/caselawyer/post
     * @secure
     */
    webBusinessCaseCaselawyerPostCreate: (data: CreateCaseLawyerInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/business/case/caselawyer/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CaseLawyer
     * @name WebBusinessCaseCaselawyerCreatecaselawyerlistCreate
     * @summary 批量添加参案律师 (Auth policies: Web)
     * @request POST:/api/web/business/case/caselawyer/createcaselawyerlist
     * @secure
     */
    webBusinessCaseCaselawyerCreatecaselawyerlistCreate: (data: CreateCaseLawyerInput[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/case/caselawyer/createcaselawyerlist`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CaseLawyer
     * @name WebBusinessCaseCaselawyerCancelcaselawyerlistUpdate
     * @summary 批量取消参案律师 (Auth policies: Web)
     * @request PUT:/api/web/business/case/caselawyer/cancelcaselawyerlist
     * @secure
     */
    webBusinessCaseCaselawyerCancelcaselawyerlistUpdate: (data: CancelCaseLawyerListInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/case/caselawyer/cancelcaselawyerlist`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CaseLawyer
     * @name WebBusinessCaseCaselawyerCancelCancelUpdate
     * @summary 取消参案律师 (Auth policies: Web)
     * @request PUT:/api/web/business/case/caselawyer/cancel/{id}/cancel
     * @secure
     */
    webBusinessCaseCaselawyerCancelCancelUpdate: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/case/caselawyer/cancel/${id}/cancel`,
        method: 'PUT',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CaseLawyer
     * @name WebBusinessCaseCaselawyerPutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/business/case/caselawyer/put/{id}
     * @secure
     */
    webBusinessCaseCaselawyerPutUpdate: (id: string, data: CreateCaseLawyerInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/case/caselawyer/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CaseLawyer
     * @name WebBusinessCaseCaselawyerDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/business/case/caselawyer/delete/{id}
     * @secure
     */
    webBusinessCaseCaselawyerDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/case/caselawyer/delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CaseMatter
     * @name WebBusinessCaseCasematterGetpagePageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/business/case/casematter/getpage/page
     * @secure
     */
    webBusinessCaseCasematterGetpagePageCreate: (data: GetCaseMatterInput, params: RequestParams = {}) =>
      this.request<CaseMatterDtoPageModelMessageModel, void>({
        path: `/api/web/business/case/casematter/getpage/page`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CaseMatter
     * @name WebBusinessCaseCasematterGetcasematterauditpageCreate
     * @summary 立案申请审核列表 (Auth policies: Web)
     * @request POST:/api/web/business/case/casematter/getcasematterauditpage
     * @secure
     */
    webBusinessCaseCasematterGetcasematterauditpageCreate: (data: GetCaseMatterFilingInput, params: RequestParams = {}) =>
      this.request<GetCaseMatterFilingOutputPageModelMessageModel, void>({
        path: `/api/web/business/case/casematter/getcasematterauditpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CaseMatter
     * @name WebBusinessCaseCasematterGetcasematterauditexportCreate
     * @summary 立案审核导出 (Auth policies: Web)
     * @request POST:/api/web/business/case/casematter/getcasematterauditexport
     * @secure
     */
    webBusinessCaseCasematterGetcasematterauditexportCreate: (data: GetCaseMatterFilingInputExportInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/business/case/casematter/getcasematterauditexport`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CaseMatter
     * @name WebBusinessCaseCasematterGetfilingpageCreate
     * @summary 立案申请列表 (Auth policies: Web)
     * @request POST:/api/web/business/case/casematter/getfilingpage
     * @secure
     */
    webBusinessCaseCasematterGetfilingpageCreate: (data: GetCaseMatterFilingInput, params: RequestParams = {}) =>
      this.request<GetCaseMatterFilingOutputPageModelMessageModel, void>({
        path: `/api/web/business/case/casematter/getfilingpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CaseMatter
     * @name WebBusinessCaseCasematterGetcasematterfilingexportCreate
     * @summary 立案列表导出 (Auth policies: Web)
     * @request POST:/api/web/business/case/casematter/getcasematterfilingexport
     * @secure
     */
    webBusinessCaseCasematterGetcasematterfilingexportCreate: (data: GetCaseMatterFilingInputExportInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/business/case/casematter/getcasematterfilingexport`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CaseMatter
     * @name WebBusinessCaseCasematterGetcasemanagepageCreate
     * @summary 立案管理列表 (Auth policies: Web)
     * @request POST:/api/web/business/case/casematter/getcasemanagepage
     * @secure
     */
    webBusinessCaseCasematterGetcasemanagepageCreate: (data: GetCaseMatterFilingInput, params: RequestParams = {}) =>
      this.request<GetCaseMatterFilingOutputPageModelMessageModel, void>({
        path: `/api/web/business/case/casematter/getcasemanagepage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CaseMatter
     * @name WebBusinessCaseCasematterGetcasemanageexportCreate
     * @summary 立案管理列表导出 (Auth policies: Web)
     * @request POST:/api/web/business/case/casematter/getcasemanageexport
     * @secure
     */
    webBusinessCaseCasematterGetcasemanageexportCreate: (data: GetCaseMatterFilingInputExportInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/business/case/casematter/getcasemanageexport`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CaseMatter
     * @name WebBusinessCaseCasematterGetmycasematterfilingpageCreate
     * @summary 我的立案申请 (Auth policies: Web)
     * @request POST:/api/web/business/case/casematter/getmycasematterfilingpage
     * @secure
     */
    webBusinessCaseCasematterGetmycasematterfilingpageCreate: (data: GetCaseMatterFilingInput, params: RequestParams = {}) =>
      this.request<GetCaseMatterFilingOutputPageModelMessageModel, void>({
        path: `/api/web/business/case/casematter/getmycasematterfilingpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CaseMatter
     * @name WebBusinessCaseCasematterListListList
     * @summary 列表获取所有 (Auth policies: Web)
     * @request GET:/api/web/business/case/casematter/list/list
     * @secure
     */
    webBusinessCaseCasematterListListList: (data: GetCaseMatterInput, params: RequestParams = {}) =>
      this.request<CaseMatterDtoListMessageModel, void>({
        path: `/api/web/business/case/casematter/list/list`,
        method: 'GET',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CaseMatter
     * @name WebBusinessCaseCasematterGetDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/business/case/casematter/get/{id}
     * @secure
     */
    webBusinessCaseCasematterGetDetail: (id: string, params: RequestParams = {}) =>
      this.request<CaseMatterDtoMessageModel, void>({
        path: `/api/web/business/case/casematter/get/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CaseMatter
     * @name WebBusinessCaseCasematterGetcasematterforeditCreate
     * @summary 获取立案申请编辑对象 (Auth policies: Web)
     * @request POST:/api/web/business/case/casematter/getcasematterforedit
     * @secure
     */
    webBusinessCaseCasematterGetcasematterforeditCreate: (data: StringEntityDto, params: RequestParams = {}) =>
      this.request<CreateCaseMatterInputMessageModel, void>({
        path: `/api/web/business/case/casematter/getcasematterforedit`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CaseMatter
     * @name WebBusinessCaseCasematterGetcasematterinfoInfoDetail
     * @summary 获取立案申请详情 (Auth policies: Web)
     * @request GET:/api/web/business/case/casematter/getcasematterinfo/{id}/info
     * @secure
     */
    webBusinessCaseCasematterGetcasematterinfoInfoDetail: (id: string, params: RequestParams = {}) =>
      this.request<CaseMatterOutputMessageModel, void>({
        path: `/api/web/business/case/casematter/getcasematterinfo/${id}/info`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CaseMatter
     * @name WebBusinessCaseCasematterCreatecasematterCreate
     * @summary 新增立案申请 (Auth policies: Web)
     * @request POST:/api/web/business/case/casematter/createcasematter
     * @secure
     */
    webBusinessCaseCasematterCreatecasematterCreate: (data: CreateCaseMatterInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/case/casematter/createcasematter`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CaseMatter
     * @name WebBusinessCaseCasematterUpdatecasematterUpdate
     * @summary 修改立案申请 (Auth policies: Web)
     * @request PUT:/api/web/business/case/casematter/updatecasematter
     * @secure
     */
    webBusinessCaseCasematterUpdatecasematterUpdate: (data: CreateCaseMatterInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/case/casematter/updatecasematter`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CaseMatter
     * @name WebBusinessCaseCasematterCreatedraftofficeaffairscasematterCreate
     * @summary 所务立案暂存草稿 (Auth policies: Web)
     * @request POST:/api/web/business/case/casematter/createdraftofficeaffairscasematter
     * @secure
     */
    webBusinessCaseCasematterCreatedraftofficeaffairscasematterCreate: (data: CreateDraftCaseMatterInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/case/casematter/createdraftofficeaffairscasematter`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CaseMatter
     * @name WebBusinessCaseCasematterCreateofficeaffairscasematterCreate
     * @summary 新增所务立案申请 (Auth policies: Web)
     * @request POST:/api/web/business/case/casematter/createofficeaffairscasematter
     * @secure
     */
    webBusinessCaseCasematterCreateofficeaffairscasematterCreate: (data: CreateCaseMatterInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/case/casematter/createofficeaffairscasematter`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CaseMatter
     * @name WebBusinessCaseCasematterUpdateofficeaffairscasematterUpdate
     * @summary 修改所务立案申请 (Auth policies: Web)
     * @request PUT:/api/web/business/case/casematter/updateofficeaffairscasematter
     * @secure
     */
    webBusinessCaseCasematterUpdateofficeaffairscasematterUpdate: (data: CreateCaseMatterInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/case/casematter/updateofficeaffairscasematter`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CaseMatter
     * @name WebBusinessCaseCasematterUpdatecasemanageUpdate
     * @summary 立案管理修改 (Auth policies: Web)
     * @request PUT:/api/web/business/case/casematter/updatecasemanage
     * @secure
     */
    webBusinessCaseCasematterUpdatecasemanageUpdate: (data: CreateCaseManageInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/case/casematter/updatecasemanage`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CaseMatter
     * @name WebBusinessCaseCasematterDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/business/case/casematter/delete/{id}
     * @secure
     */
    webBusinessCaseCasematterDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/case/casematter/delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CaseMatter
     * @name WebBusinessCaseCasematterCasematterapplyCreate
     * @summary 立案发起审核 (Auth policies: Web)
     * @request POST:/api/web/business/case/casematter/casematterapply
     * @secure
     */
    webBusinessCaseCasematterCasematterapplyCreate: (data: ApprovalInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/business/case/casematter/casematterapply`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CaseMatter
     * @name WebBusinessCaseCasematterCasematterapprovedwithserialidCreate
     * @summary 立案申请审核通过并指定流水号 (Auth policies: Web)
     * @request POST:/api/web/business/case/casematter/casematterapprovedwithserialid
     * @secure
     */
    webBusinessCaseCasematterCasematterapprovedwithserialidCreate: (data: CaseApprovalInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/business/case/casematter/casematterapprovedwithserialid`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CaseMatter
     * @name WebBusinessCaseCasematterCasematterapprovedCreate
     * @summary 立案申请审核通过 (Auth policies: Web)
     * @request POST:/api/web/business/case/casematter/casematterapproved
     * @secure
     */
    webBusinessCaseCasematterCasematterapprovedCreate: (data: ApprovalInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/business/case/casematter/casematterapproved`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CaseMatter
     * @name WebBusinessCaseCasematterCasematterrejectedCreate
     * @summary 立案申请审核退回 (Auth policies: Web)
     * @request POST:/api/web/business/case/casematter/casematterrejected
     * @secure
     */
    webBusinessCaseCasematterCasematterrejectedCreate: (data: ApprovalInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/business/case/casematter/casematterrejected`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CaseMatter
     * @name WebBusinessCaseCasematterCasematterwithdrawCreate
     * @summary 立案申请撤回 (Auth policies: Web)
     * @request POST:/api/web/business/case/casematter/casematterwithdraw
     * @secure
     */
    webBusinessCaseCasematterCasematterwithdrawCreate: (data: ApprovalInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/business/case/casematter/casematterwithdraw`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CaseMatter
     * @name WebBusinessCaseCasematterGetcasecheckpageCreate
     * @summary 案件选择列表 (Auth policies: Web)
     * @request POST:/api/web/business/case/casematter/getcasecheckpage
     * @secure
     */
    webBusinessCaseCasematterGetcasecheckpageCreate: (data: GetCaseCheckInput, params: RequestParams = {}) =>
      this.request<CaseCheckOutputPageModelMessageModel, void>({
        path: `/api/web/business/case/casematter/getcasecheckpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CaseMatter
     * @name WebBusinessCaseCasematterCreatedraftcasematterCreate
     * @summary 立案暂存草稿 (Auth policies: Web)
     * @request POST:/api/web/business/case/casematter/createdraftcasematter
     * @secure
     */
    webBusinessCaseCasematterCreatedraftcasematterCreate: (data: CreateDraftCaseMatterInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/case/casematter/createdraftcasematter`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CaseMatter
     * @name WebBusinessCaseCasematterGetnewcaseserialidCreate
     * @summary 获取案件生成流水号 (Auth policies: Web)
     * @request POST:/api/web/business/case/casematter/getnewcaseserialid
     * @secure
     */
    webBusinessCaseCasematterGetnewcaseserialidCreate: (data: GetNewCaseSerialIdInput, params: RequestParams = {}) =>
      this.request<GetNewSerialIdOutputListMessageModel, void>({
        path: `/api/web/business/case/casematter/getnewcaseserialid`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CaseMatter
     * @name WebBusinessCaseCasematterUpdatecaseserialidCreate
     * @summary 根据id更新案件流水编号 (Auth policies: Web)
     * @request POST:/api/web/business/case/casematter/updatecaseserialid
     * @secure
     */
    webBusinessCaseCasematterUpdatecaseserialidCreate: (data: UpdateCaseSerialIdInput, params: RequestParams = {}) =>
      this.request<BooleanMessageModel, void>({
        path: `/api/web/business/case/casematter/updatecaseserialid`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CaseRelatedParty
     * @name WebBusinessCaseCaserelatedpartyPostCreate
     * @summary 保存案件关联方信息 (Auth policies: Web)
     * @request POST:/api/web/business/case/caserelatedparty/post
     * @secure
     */
    webBusinessCaseCaserelatedpartyPostCreate: (data: CreateCaseRelatedPartyInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/business/case/caserelatedparty/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CaseRelatedParty
     * @name WebBusinessCaseCaserelatedpartyGetcaserelatedpartydetailList
     * @summary 获取案件关联方详情 (Auth policies: Web)
     * @request GET:/api/web/business/case/caserelatedparty/getcaserelatedpartydetail
     * @deprecated
     * @secure
     */
    webBusinessCaseCaserelatedpartyGetcaserelatedpartydetailList: (
      query?: {
        id?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<CaseRelatedPartyDtoMessageModel, void>({
        path: `/api/web/business/case/caserelatedparty/getcaserelatedpartydetail`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CaseRelatedParty
     * @name WebBusinessCaseCaserelatedpartyDownloadimporttemplateList
     * @summary 下载模板 (Auth policies: Web)
     * @request GET:/api/web/business/case/caserelatedparty/downloadimporttemplate
     * @secure
     */
    webBusinessCaseCaserelatedpartyDownloadimporttemplateList: (
      query?: {
        fileName?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<ExcelFileDtoMessageModel, void>({
        path: `/api/web/business/case/caserelatedparty/downloadimporttemplate`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CaseRelatedParty
     * @name WebBusinessCaseCaserelatedpartyImportcaserelpartywithtemplateCreate
     * @summary 导入数据 (Auth policies: Web)
     * @request POST:/api/web/business/case/caserelatedparty/importcaserelpartywithtemplate
     * @secure
     */
    webBusinessCaseCaserelatedpartyImportcaserelpartywithtemplateCreate: (
      data: {
        /** @format binary */
        file?: File;
      },
      params: RequestParams = {}
    ) =>
      this.request<ImportCaseRelPartyDtoListMessageModel, void>({
        path: `/api/web/business/case/caserelatedparty/importcaserelpartywithtemplate`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CaseRelatedParty
     * @name WebBusinessCaseCaserelatedpartyCaserelpartyexcelcpimvalidatorCreate
     * @summary 导入数据能否匹配验证（客户方） (Auth policies: Web)
     * @request POST:/api/web/business/case/caserelatedparty/caserelpartyexcelcpimvalidator
     * @secure
     */
    webBusinessCaseCaserelatedpartyCaserelpartyexcelcpimvalidatorCreate: (
      data: {
        /** @format binary */
        file?: File;
      },
      query?: {
        category?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<CaseRelPartyValidatorDtoListMessageModel, void>({
        path: `/api/web/business/case/caserelatedparty/caserelpartyexcelcpimvalidator`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CaseRelatedParty
     * @name WebBusinessCaseCaserelatedpartyCaserelpartyexcelopimvalidatorCreate
     * @summary 导入数据能否匹配验证（ 对方） (Auth policies: Web)
     * @request POST:/api/web/business/case/caserelatedparty/caserelpartyexcelopimvalidator
     * @secure
     */
    webBusinessCaseCaserelatedpartyCaserelpartyexcelopimvalidatorCreate: (
      data: {
        /** @format binary */
        file?: File;
      },
      query?: {
        category?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<CaseRelPartyValidatorDtoListMessageModel, void>({
        path: `/api/web/business/case/caserelatedparty/caserelpartyexcelopimvalidator`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CaseRelatedParty
     * @name WebBusinessCaseCaserelatedpartyCaserelpartyexceleporopeortpimvalidatorCreate
     * @summary 导入数据能否匹配验证（客户实体/对方关联方/第三方） (Auth policies: Web)
     * @request POST:/api/web/business/case/caserelatedparty/caserelpartyexceleporopeortpimvalidator
     * @secure
     */
    webBusinessCaseCaserelatedpartyCaserelpartyexceleporopeortpimvalidatorCreate: (
      data: {
        /** @format binary */
        file?: File;
      },
      query?: {
        category?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<CaseRelPartyValidatorDtoListMessageModel, void>({
        path: `/api/web/business/case/caserelatedparty/caserelpartyexceleporopeortpimvalidator`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CaseRelatedParty
     * @name WebBusinessCaseCaserelatedpartyCaserelpartyexceltemplateimportCreate
     * @summary Excel导入数据并返回匹配成功的数据 (Auth policies: Web)
     * @request POST:/api/web/business/case/caserelatedparty/caserelpartyexceltemplateimport
     * @secure
     */
    webBusinessCaseCaserelatedpartyCaserelpartyexceltemplateimportCreate: (
      data: {
        /** @format binary */
        file?: File;
      },
      query?: {
        category?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<CaseRelatedPartyDtoListMessageModel, void>({
        path: `/api/web/business/case/caserelatedparty/caserelpartyexceltemplateimport`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CaseRelatedParty
     * @name WebBusinessCaseCaserelatedpartyGettemplatecaserelpartytemplateList
     * @summary 下载模板 (Auth policies: Web)
     * @request GET:/api/web/business/case/caserelatedparty/gettemplatecaserelpartytemplate
     * @secure
     */
    webBusinessCaseCaserelatedpartyGettemplatecaserelpartytemplateList: (params: RequestParams = {}) =>
      this.request<ExcelFileDtoMessageModel, void>({
        path: `/api/web/business/case/caserelatedparty/gettemplatecaserelpartytemplate`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CaseRelatedParty
     * @name WebBusinessCaseCaserelatedpartyGettemplatecaserelpartydataCreate
     * @summary 获取Excel客户数据 (Auth policies: Web)
     * @request POST:/api/web/business/case/caserelatedparty/gettemplatecaserelpartydata
     * @secure
     */
    webBusinessCaseCaserelatedpartyGettemplatecaserelpartydataCreate: (
      data: {
        /** @format binary */
        file?: File;
      },
      params: RequestParams = {}
    ) =>
      this.request<CaseRelationExcelDtoListMessageModel, void>({
        path: `/api/web/business/case/caserelatedparty/gettemplatecaserelpartydata`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Client
     * @name WebCustomerClientSaveclientinfoCreate
     * @summary 创建客户 (Auth policies: Web)
     * @request POST:/api/web/customer/client/saveclientinfo
     * @secure
     */
    webCustomerClientSaveclientinfoCreate: (data: CreateClientInput, params: RequestParams = {}) =>
      this.request<ClientDtoMessageModel, void>({
        path: `/api/web/customer/client/saveclientinfo`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Client
     * @name WebCustomerClientGetclientpageCreate
     * @summary 获取客户分页 (Auth policies: Web)
     * @request POST:/api/web/customer/client/getclientpage
     * @secure
     */
    webCustomerClientGetclientpageCreate: (data: GetClientInput, params: RequestParams = {}) =>
      this.request<ClientOutputPageModelMessageModel, void>({
        path: `/api/web/customer/client/getclientpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Client
     * @name WebCustomerClientGetDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/customer/client/get/{id}
     * @secure
     */
    webCustomerClientGetDetail: (id: string, params: RequestParams = {}) =>
      this.request<ClientOutputMessageModel, void>({
        path: `/api/web/customer/client/get/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Client
     * @name WebCustomerClientGetforeditCreate
     * @summary 获取修改实体 (Auth policies: Web)
     * @request POST:/api/web/customer/client/getforedit/{id}
     * @secure
     */
    webCustomerClientGetforeditCreate: (id: string, params: RequestParams = {}) =>
      this.request<GetClientForEditOutputForEditModelMessageModel, void>({
        path: `/api/web/customer/client/getforedit/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Client
     * @name WebCustomerClientPostCreate
     * @summary 添加/更新 (Auth policies: Web)
     * @request POST:/api/web/customer/client/post
     * @secure
     */
    webCustomerClientPostCreate: (data: CreateClientInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/customer/client/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Client
     * @name WebCustomerClientDeleteDelete
     * @summary 删除客户 (Auth policies: Web)
     * @request DELETE:/api/web/customer/client/delete
     * @secure
     */
    webCustomerClientDeleteDelete: (
      query?: {
        id?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<BooleanMessageModel, void>({
        path: `/api/web/customer/client/delete`,
        method: 'DELETE',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Client
     * @name WebCustomerClientBatchdeleteCreate
     * @summary 批量删除 (Auth policies: Web)
     * @request POST:/api/web/customer/client/batchdelete
     * @secure
     */
    webCustomerClientBatchdeleteCreate: (data: StringEntityDto[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/customer/client/batchdelete`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Client
     * @name WebCustomerClientCustomexceltemplateinitExcelTemplateList
     * @summary 下载导入模板 (Auth policies: Web)
     * @request GET:/api/web/customer/client/customexceltemplateinit/excel/template
     * @secure
     */
    webCustomerClientCustomexceltemplateinitExcelTemplateList: (
      query?: {
        fileName?: string;
        requestUrl?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<ExcelFileDtoMessageModel, void>({
        path: `/api/web/customer/client/customexceltemplateinit/excel/template`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Client
     * @name WebCustomerClientCustomexceltemplateimportExcelImportCreate
     * @summary Excel导入数据 (Auth policies: Web)
     * @request POST:/api/web/customer/client/customexceltemplateimport/excel/import
     * @secure
     */
    webCustomerClientCustomexceltemplateimportExcelImportCreate: (
      data: {
        /** @format binary */
        file?: File;
      },
      params: RequestParams = {}
    ) =>
      this.request<ClientDtoListMessageModel, void>({
        path: `/api/web/customer/client/customexceltemplateimport/excel/import`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Client
     * @name WebCustomerClientCustomexcelexportCreate
     * @summary 导出数据 (Auth policies: Web)
     * @request POST:/api/web/customer/client/customexcelexport
     * @secure
     */
    webCustomerClientCustomexcelexportCreate: (data: GetClientInputExportInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/customer/client/customexcelexport`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags ClientBelongTimekeeper
     * @name WebCustomerClientbelongtimekeeperGetpagePageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/customer/clientbelongtimekeeper/getpage/page
     * @secure
     */
    webCustomerClientbelongtimekeeperGetpagePageCreate: (
      query?: {
        id?: string;
        /** 客户编号 */
        clientId?: string;
        /** 计时人员编号 */
        timekeeperId?: string;
        /** 是否其助理或秘书可见 */
        isAssistantVisible?: string;
        /** 是否团队律师可见 */
        isTeamLawyerVisible?: string;
        /**
         * 所属时效开始时间
         * @format date-time
         */
        startDate?: string;
        /**
         * 所属时效结束时间（空则长期有效）
         * @format date-time
         */
        endDate?: string;
        /** 是否是初始所属人 */
        isFirstOwner?: string;
        /** 是否过期失效 */
        isExpred?: string;
        /** 租户ID */
        tenantId?: string;
        /** 部门ID  DepartmentId */
        organizationUnitId?: string;
        /** 分所Id */
        officeId?: string;
        /** 备注 */
        remark?: string;
        /** 状态 	                中立字段，某些表可使用某些表不使用 */
        isEnabled?: boolean;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<ClientBelongTimekeeperDtoPageModelMessageModel, void>({
        path: `/api/web/customer/clientbelongtimekeeper/getpage/page`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags ClientBelongTimekeeper
     * @name WebCustomerClientbelongtimekeeperListListList
     * @summary 列表获取所有 (Auth policies: Web)
     * @request GET:/api/web/customer/clientbelongtimekeeper/list/list
     * @secure
     */
    webCustomerClientbelongtimekeeperListListList: (
      query?: {
        id?: string;
        /** 客户编号 */
        clientId?: string;
        /** 计时人员编号 */
        timekeeperId?: string;
        /** 是否其助理或秘书可见 */
        isAssistantVisible?: string;
        /** 是否团队律师可见 */
        isTeamLawyerVisible?: string;
        /**
         * 所属时效开始时间
         * @format date-time
         */
        startDate?: string;
        /**
         * 所属时效结束时间（空则长期有效）
         * @format date-time
         */
        endDate?: string;
        /** 是否是初始所属人 */
        isFirstOwner?: string;
        /** 是否过期失效 */
        isExpred?: string;
        /** 租户ID */
        tenantId?: string;
        /** 部门ID  DepartmentId */
        organizationUnitId?: string;
        /** 分所Id */
        officeId?: string;
        /** 备注 */
        remark?: string;
        /** 状态 	                中立字段，某些表可使用某些表不使用 */
        isEnabled?: boolean;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<ClientBelongTimekeeperDtoListMessageModel, void>({
        path: `/api/web/customer/clientbelongtimekeeper/list/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags ClientBelongTimekeeper
     * @name WebCustomerClientbelongtimekeeperGetDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/customer/clientbelongtimekeeper/get/{id}
     * @secure
     */
    webCustomerClientbelongtimekeeperGetDetail: (id: string, params: RequestParams = {}) =>
      this.request<ClientBelongTimekeeperDtoMessageModel, void>({
        path: `/api/web/customer/clientbelongtimekeeper/get/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags ClientBelongTimekeeper
     * @name WebCustomerClientbelongtimekeeperPostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/customer/clientbelongtimekeeper/post
     * @secure
     */
    webCustomerClientbelongtimekeeperPostCreate: (data: CreateClientBelongTimekeeperInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/customer/clientbelongtimekeeper/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags ClientBelongTimekeeper
     * @name WebCustomerClientbelongtimekeeperPutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/customer/clientbelongtimekeeper/put/{id}
     * @secure
     */
    webCustomerClientbelongtimekeeperPutUpdate: (id: string, data: CreateClientBelongTimekeeperInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/customer/clientbelongtimekeeper/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags ClientBelongTimekeeper
     * @name WebCustomerClientbelongtimekeeperDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/customer/clientbelongtimekeeper/delete/{id}
     * @secure
     */
    webCustomerClientbelongtimekeeperDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/customer/clientbelongtimekeeper/delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrAvoidPreference
     * @name WebBusinessCsrCsravoidpreferenceGetpagePageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/business/csr/csravoidpreference/getpage/page
     * @secure
     */
    webBusinessCsrCsravoidpreferenceGetpagePageCreate: (
      query?: {
        id?: string;
        /** 规避偏好关键字 */
        avoidKeyword?: string;
        /** 规避利冲方类型 */
        patryType?: string;
        /** 关键字规避方法（模糊/精确） */
        avoidMethod?: string;
        /**
         * 规避开始时间
         * @format date-time
         */
        startDate?: string;
        /**
         * 规避结束时间（空则长期生效）
         * @format date-time
         */
        endTime?: string;
        /** 附件信息 */
        attachments?: string;
        /** 说明描述 */
        narrative?: string;
        /** 租户ID */
        tenantId?: string;
        /** 部门ID  DepartmentId */
        organizationUnitId?: string;
        /** 分所Id */
        officeId?: string;
        /** 备注 */
        remark?: string;
        /** 状态 	                中立字段，某些表可使用某些表不使用 */
        isEnabled?: boolean;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<CsrAvoidPreferenceDtoPageModelMessageModel, void>({
        path: `/api/web/business/csr/csravoidpreference/getpage/page`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrAvoidPreference
     * @name WebBusinessCsrCsravoidpreferenceListListList
     * @summary 列表获取所有 (Auth policies: Web)
     * @request GET:/api/web/business/csr/csravoidpreference/list/list
     * @secure
     */
    webBusinessCsrCsravoidpreferenceListListList: (
      query?: {
        id?: string;
        /** 规避偏好关键字 */
        avoidKeyword?: string;
        /** 规避利冲方类型 */
        patryType?: string;
        /** 关键字规避方法（模糊/精确） */
        avoidMethod?: string;
        /**
         * 规避开始时间
         * @format date-time
         */
        startDate?: string;
        /**
         * 规避结束时间（空则长期生效）
         * @format date-time
         */
        endTime?: string;
        /** 附件信息 */
        attachments?: string;
        /** 说明描述 */
        narrative?: string;
        /** 租户ID */
        tenantId?: string;
        /** 部门ID  DepartmentId */
        organizationUnitId?: string;
        /** 分所Id */
        officeId?: string;
        /** 备注 */
        remark?: string;
        /** 状态 	                中立字段，某些表可使用某些表不使用 */
        isEnabled?: boolean;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<CsrAvoidPreferenceDtoListMessageModel, void>({
        path: `/api/web/business/csr/csravoidpreference/list/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrAvoidPreference
     * @name WebBusinessCsrCsravoidpreferenceGetDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/business/csr/csravoidpreference/get/{id}
     * @secure
     */
    webBusinessCsrCsravoidpreferenceGetDetail: (id: string, params: RequestParams = {}) =>
      this.request<CsrAvoidPreferenceDtoMessageModel, void>({
        path: `/api/web/business/csr/csravoidpreference/get/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrAvoidPreference
     * @name WebBusinessCsrCsravoidpreferencePostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/business/csr/csravoidpreference/post
     * @secure
     */
    webBusinessCsrCsravoidpreferencePostCreate: (data: CreateCsrAvoidPreferenceInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/csr/csravoidpreference/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrAvoidPreference
     * @name WebBusinessCsrCsravoidpreferencePutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/business/csr/csravoidpreference/put/{id}
     * @secure
     */
    webBusinessCsrCsravoidpreferencePutUpdate: (id: string, data: CreateCsrAvoidPreferenceInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/csr/csravoidpreference/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrAvoidPreference
     * @name WebBusinessCsrCsravoidpreferenceDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/business/csr/csravoidpreference/delete/{id}
     * @secure
     */
    webBusinessCsrCsravoidpreferenceDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/csr/csravoidpreference/delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrAvoidPreferenceResult
     * @name WebBusinessCsrCsravoidpreferenceresultGetpagePageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/business/csr/csravoidpreferenceresult/getpage/page
     * @secure
     */
    webBusinessCsrCsravoidpreferenceresultGetpagePageCreate: (
      query?: {
        id?: string;
        /** 利冲规避编号 */
        csrAvoidId?: string;
        /** 利冲规避关键词 */
        avoidKeyword?: string;
        /** 关键词隶属规避方角色 */
        partyType?: string;
        /** 规避冲突案件的角色 */
        caseParty?: string;
        /** 规避案件冲突方名称 */
        casePartyName?: string;
        /** 规避利冲关联业务类型（利冲/案件/入库/投标等） */
        csrRelationType?: string;
        /** 规避业务编号（默认案件） */
        relationId?: string;
        /** 冲突产生时关联业务状态 */
        relationStatus?: string;
        /** 匹配客户名称 */
        clientName?: string;
        /** 匹配客户外文名称 */
        clientForeignName?: string;
        /** 匹配客户实控人名称 */
        clientShareholderActualCtrlName?: string;
        /** 匹配客户实控人外文名称 */
        clientShareholderActualCtrlForeignName?: string;
        /** 匹配对方名称 */
        oppositeName?: string;
        /** 匹配对方外文名称 */
        oppositeForeignName?: string;
        /** 匹配对方实控人名称 */
        oppositeShareholderActualCtrlName?: string;
        /** 匹配对方实控人外文名称 */
        oppositeShareholderActualCtrlForeignName?: string;
        /** 潜在第三方名称 */
        potential3rdPartyName?: string;
        /** 潜在第三方外文名称 */
        potential3rdPartyForeignName?: string;
        /** 关联业务类型 */
        relationCategory?: string;
        /** 关联业务名称 */
        relationName?: string;
        /**
         * 关联业务申请时间
         * @format date-time
         */
        relationRequestTime?: string;
        /** 关联业务主管律师 */
        relationInChargeLawyerId?: string;
        /** 关联业务申请合伙人 */
        relationRequestUserId?: string;
        /** 关联业务协办律师 */
        relationCoWorkingLawyerId?: string;
        /** 关联业务账单律师 */
        relationBillingLawyerId?: string;
        /** 规避匹配方式（包含/忽略） */
        matchingMethod?: string;
        /** 租户ID */
        tenantId?: string;
        /** 部门ID  DepartmentId */
        organizationUnitId?: string;
        /** 分所Id */
        officeId?: string;
        /** 备注 */
        remark?: string;
        /** 状态 	                中立字段，某些表可使用某些表不使用 */
        isEnabled?: boolean;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<CsrAvoidPreferenceResultDtoPageModelMessageModel, void>({
        path: `/api/web/business/csr/csravoidpreferenceresult/getpage/page`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrAvoidPreferenceResult
     * @name WebBusinessCsrCsravoidpreferenceresultListListList
     * @summary 列表获取所有 (Auth policies: Web)
     * @request GET:/api/web/business/csr/csravoidpreferenceresult/list/list
     * @secure
     */
    webBusinessCsrCsravoidpreferenceresultListListList: (
      query?: {
        id?: string;
        /** 利冲规避编号 */
        csrAvoidId?: string;
        /** 利冲规避关键词 */
        avoidKeyword?: string;
        /** 关键词隶属规避方角色 */
        partyType?: string;
        /** 规避冲突案件的角色 */
        caseParty?: string;
        /** 规避案件冲突方名称 */
        casePartyName?: string;
        /** 规避利冲关联业务类型（利冲/案件/入库/投标等） */
        csrRelationType?: string;
        /** 规避业务编号（默认案件） */
        relationId?: string;
        /** 冲突产生时关联业务状态 */
        relationStatus?: string;
        /** 匹配客户名称 */
        clientName?: string;
        /** 匹配客户外文名称 */
        clientForeignName?: string;
        /** 匹配客户实控人名称 */
        clientShareholderActualCtrlName?: string;
        /** 匹配客户实控人外文名称 */
        clientShareholderActualCtrlForeignName?: string;
        /** 匹配对方名称 */
        oppositeName?: string;
        /** 匹配对方外文名称 */
        oppositeForeignName?: string;
        /** 匹配对方实控人名称 */
        oppositeShareholderActualCtrlName?: string;
        /** 匹配对方实控人外文名称 */
        oppositeShareholderActualCtrlForeignName?: string;
        /** 潜在第三方名称 */
        potential3rdPartyName?: string;
        /** 潜在第三方外文名称 */
        potential3rdPartyForeignName?: string;
        /** 关联业务类型 */
        relationCategory?: string;
        /** 关联业务名称 */
        relationName?: string;
        /**
         * 关联业务申请时间
         * @format date-time
         */
        relationRequestTime?: string;
        /** 关联业务主管律师 */
        relationInChargeLawyerId?: string;
        /** 关联业务申请合伙人 */
        relationRequestUserId?: string;
        /** 关联业务协办律师 */
        relationCoWorkingLawyerId?: string;
        /** 关联业务账单律师 */
        relationBillingLawyerId?: string;
        /** 规避匹配方式（包含/忽略） */
        matchingMethod?: string;
        /** 租户ID */
        tenantId?: string;
        /** 部门ID  DepartmentId */
        organizationUnitId?: string;
        /** 分所Id */
        officeId?: string;
        /** 备注 */
        remark?: string;
        /** 状态 	                中立字段，某些表可使用某些表不使用 */
        isEnabled?: boolean;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<CsrAvoidPreferenceResultDtoListMessageModel, void>({
        path: `/api/web/business/csr/csravoidpreferenceresult/list/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrAvoidPreferenceResult
     * @name WebBusinessCsrCsravoidpreferenceresultGetDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/business/csr/csravoidpreferenceresult/get/{id}
     * @secure
     */
    webBusinessCsrCsravoidpreferenceresultGetDetail: (id: string, params: RequestParams = {}) =>
      this.request<CsrAvoidPreferenceResultDtoMessageModel, void>({
        path: `/api/web/business/csr/csravoidpreferenceresult/get/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrAvoidPreferenceResult
     * @name WebBusinessCsrCsravoidpreferenceresultPostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/business/csr/csravoidpreferenceresult/post
     * @secure
     */
    webBusinessCsrCsravoidpreferenceresultPostCreate: (data: CreateCsrAvoidPreferenceResultInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/csr/csravoidpreferenceresult/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrAvoidPreferenceResult
     * @name WebBusinessCsrCsravoidpreferenceresultPutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/business/csr/csravoidpreferenceresult/put/{id}
     * @secure
     */
    webBusinessCsrCsravoidpreferenceresultPutUpdate: (id: string, data: CreateCsrAvoidPreferenceResultInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/csr/csravoidpreferenceresult/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrAvoidPreferenceResult
     * @name WebBusinessCsrCsravoidpreferenceresultDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/business/csr/csravoidpreferenceresult/delete/{id}
     * @secure
     */
    webBusinessCsrCsravoidpreferenceresultDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/csr/csravoidpreferenceresult/delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrExemption
     * @name WebBusinessCsrCsrexemptionGetpagePageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/business/csr/csrexemption/getpage/page
     * @secure
     */
    webBusinessCsrCsrexemptionGetpagePageCreate: (
      query?: {
        id?: string;
        /** 利冲编号 */
        csrId?: string;
        /** 利冲关键词 */
        csrKeyword?: string;
        /** 与我冲突案件的角色（冲突方） */
        csrParty?: string;
        /** 我的案件冲突角色 */
        caseParty?: string;
        /** 我的案件冲突方名称 */
        casePartyName?: string;
        /** 主管律师 */
        inChargeLawyer?: string;
        /** 利冲关联业务类型（利冲/案件/入库/投标等） */
        csrRelationType?: string;
        /** 关联业务编号（默认案件） */
        relationId?: string;
        /** 冲突产生时关联业务状态 */
        relationStatus?: string;
        /** 与我冲突业务编号合计 */
        csrRelationIds?: string;
        /** 利冲确认发送人编号 */
        csrSendUserId?: string;
        /**
         * 利冲确认发送时间
         * @format date-time
         */
        csrSendTime?: string;
        /**
         * 利冲豁免确认时间
         * @format date-time
         */
        confirmTime?: string;
        /** 豁免文件 */
        attachments?: string;
        /** 说明描述 */
        narrative?: string;
        /** 租户ID */
        tenantId?: string;
        /** 部门ID  DepartmentId */
        organizationUnitId?: string;
        /** 分所Id */
        officeId?: string;
        /** 备注 */
        remark?: string;
        /** 状态 	                中立字段，某些表可使用某些表不使用 */
        isEnabled?: boolean;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<CsrExemptionDtoPageModelMessageModel, void>({
        path: `/api/web/business/csr/csrexemption/getpage/page`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrExemption
     * @name WebBusinessCsrCsrexemptionListListList
     * @summary 列表获取所有 (Auth policies: Web)
     * @request GET:/api/web/business/csr/csrexemption/list/list
     * @secure
     */
    webBusinessCsrCsrexemptionListListList: (
      query?: {
        id?: string;
        /** 利冲编号 */
        csrId?: string;
        /** 利冲关键词 */
        csrKeyword?: string;
        /** 与我冲突案件的角色（冲突方） */
        csrParty?: string;
        /** 我的案件冲突角色 */
        caseParty?: string;
        /** 我的案件冲突方名称 */
        casePartyName?: string;
        /** 主管律师 */
        inChargeLawyer?: string;
        /** 利冲关联业务类型（利冲/案件/入库/投标等） */
        csrRelationType?: string;
        /** 关联业务编号（默认案件） */
        relationId?: string;
        /** 冲突产生时关联业务状态 */
        relationStatus?: string;
        /** 与我冲突业务编号合计 */
        csrRelationIds?: string;
        /** 利冲确认发送人编号 */
        csrSendUserId?: string;
        /**
         * 利冲确认发送时间
         * @format date-time
         */
        csrSendTime?: string;
        /**
         * 利冲豁免确认时间
         * @format date-time
         */
        confirmTime?: string;
        /** 豁免文件 */
        attachments?: string;
        /** 说明描述 */
        narrative?: string;
        /** 租户ID */
        tenantId?: string;
        /** 部门ID  DepartmentId */
        organizationUnitId?: string;
        /** 分所Id */
        officeId?: string;
        /** 备注 */
        remark?: string;
        /** 状态 	                中立字段，某些表可使用某些表不使用 */
        isEnabled?: boolean;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<CsrExemptionDtoListMessageModel, void>({
        path: `/api/web/business/csr/csrexemption/list/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrExemption
     * @name WebBusinessCsrCsrexemptionGetDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/business/csr/csrexemption/get/{id}
     * @secure
     */
    webBusinessCsrCsrexemptionGetDetail: (id: string, params: RequestParams = {}) =>
      this.request<CsrExemptionDtoMessageModel, void>({
        path: `/api/web/business/csr/csrexemption/get/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrExemption
     * @name WebBusinessCsrCsrexemptionPostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/business/csr/csrexemption/post
     * @secure
     */
    webBusinessCsrCsrexemptionPostCreate: (data: CreateCsrExemptionInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/csr/csrexemption/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrExemption
     * @name WebBusinessCsrCsrexemptionPutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/business/csr/csrexemption/put/{id}
     * @secure
     */
    webBusinessCsrCsrexemptionPutUpdate: (id: string, data: CreateCsrExemptionInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/csr/csrexemption/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrExemption
     * @name WebBusinessCsrCsrexemptionDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/business/csr/csrexemption/delete/{id}
     * @secure
     */
    webBusinessCsrCsrexemptionDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/csr/csrexemption/delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrPreProcess
     * @name WebBusinessCsrCsrpreprocessListcsrprocessCreate
     * @summary 利冲预检-检索结果 (Auth policies: Web)
     * @request POST:/api/web/business/csr/csrpreprocess/listcsrprocess
     * @secure
     */
    webBusinessCsrCsrpreprocessListcsrprocessCreate: (data: GetCsrSearchPreProcessInput, params: RequestParams = {}) =>
      this.request<CsrSearchPreProcessResultDtoPageModelMessageModel, void>({
        path: `/api/web/business/csr/csrpreprocess/listcsrprocess`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrPreProcess
     * @name WebBusinessCsrCsrpreprocessGetsegmentList
     * @summary 利冲预检-获取分词 (Auth policies: Web)
     * @request GET:/api/web/business/csr/csrpreprocess/getsegment
     * @secure
     */
    webBusinessCsrCsrpreprocessGetsegmentList: (
      query?: {
        word?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<StringListMessageModel, void>({
        path: `/api/web/business/csr/csrpreprocess/getsegment`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrPreProcessResult
     * @name WebBusinessCsrCsrpreprocessresultGetpagePageCreate
     * @summary 检索结果列表 (Auth policies: Web)
     * @request POST:/api/web/business/csr/csrpreprocessresult/getpage/page
     * @secure
     */
    webBusinessCsrCsrpreprocessresultGetpagePageCreate: (data: GetCsrPreProcessResultInput, params: RequestParams = {}) =>
      this.request<CsrSearchPreProcessResultDtoPageModelMessageModel, void>({
        path: `/api/web/business/csr/csrpreprocessresult/getpage/page`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrPreProcessResult
     * @name WebBusinessCsrCsrpreprocessresultGetpreprocessresultexportCreate
     * @summary 导出利冲预检索结果列表 (Auth policies: Web)
     * @request POST:/api/web/business/csr/csrpreprocessresult/getpreprocessresultexport
     * @secure
     */
    webBusinessCsrCsrpreprocessresultGetpreprocessresultexportCreate: (data: GetCsrPreProcessResultInputExportInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/business/csr/csrpreprocessresult/getpreprocessresultexport`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrProcess
     * @name WebBusinessCsrCsrprocessListListList
     * @summary 列表获取所有 (Auth policies: Web)
     * @request GET:/api/web/business/csr/csrprocess/list/list
     * @secure
     */
    webBusinessCsrCsrprocessListListList: (
      query?: {
        /** @format int64 */
        csrId?: number;
        /** 检索模式（精确，模糊） */
        searchMode?: string;
        /** 我方名称 */
        ourPatryName?: string;
        /** 我方外文名称 */
        ourPatryForeignName?: string;
        /** 对方名称 */
        oppositeName?: string;
        /** 对方外文名称 */
        oppositeForeignName?: string;
        /** 潜在三方名称 */
        potential3rdPartyName?: string;
        /** 潜在三方外文名称 */
        potential3rdPartyForeignName?: string;
        /** 是否分词检索 */
        isSplitWordSearch?: string;
        /** 是否外文分词检索 */
        isForeignSplitWordSearch?: string;
        /**
         * 匹配结果数量
         * @format double
         */
        matchingCount?: number;
        /** 需要豁免案件编号 */
        needExemptionCaseId?: string;
        /**
         * 利冲审查人编号
         * @format int64
         */
        processUserId?: number;
        /**
         * 利冲审查时间
         * @format date-time
         */
        processTime?: string;
        /** 说明描述 */
        narrative?: string;
        /** 备注 */
        remark?: string;
        /**
         * 创建ID
         * @format int64
         */
        createId?: number;
        /** 创建者 */
        createBy?: string;
        /**
         * 创建时间
         * @format date-time
         */
        createTime?: string;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<CsrProcessDtoListMessageModel, void>({
        path: `/api/web/business/csr/csrprocess/list/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrProcess
     * @name WebBusinessCsrCsrprocessGetDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/business/csr/csrprocess/get/{id}
     * @secure
     */
    webBusinessCsrCsrprocessGetDetail: (id: string, params: RequestParams = {}) =>
      this.request<CsrProcessDtoMessageModel, void>({
        path: `/api/web/business/csr/csrprocess/get/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrProcess
     * @name WebBusinessCsrCsrprocessGetsearchprocesscasepartyresultlistList
     * @summary 利冲信息-检索条件列表 (Auth policies: Web)
     * @request GET:/api/web/business/csr/csrprocess/getsearchprocesscasepartyresultlist
     * @deprecated
     * @secure
     */
    webBusinessCsrCsrprocessGetsearchprocesscasepartyresultlistList: (
      query?: {
        id?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<SearchProcessCasePartyResultDtoListMessageModel, void>({
        path: `/api/web/business/csr/csrprocess/getsearchprocesscasepartyresultlist`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrProcess
     * @name WebBusinessCsrCsrprocessGetsearchprocesscasepartyconditionlistCreate
     * @summary 利冲信息-获取检索条件列表 (Auth policies: Web)
     * @request POST:/api/web/business/csr/csrprocess/getsearchprocesscasepartyconditionlist
     * @secure
     */
    webBusinessCsrCsrprocessGetsearchprocesscasepartyconditionlistCreate: (data: GetCsrSearchProcessInput, params: RequestParams = {}) =>
      this.request<SearchProcessCasePartyResultDtoListMessageModel, void>({
        path: `/api/web/business/csr/csrprocess/getsearchprocesscasepartyconditionlist`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrProcess
     * @name WebBusinessCsrCsrprocessSearchprocesscasepartyresultlistCreate
     * @summary 利冲信息-检索 (Auth policies: Web)
     * @request POST:/api/web/business/csr/csrprocess/searchprocesscasepartyresultlist
     * @secure
     */
    webBusinessCsrCsrprocessSearchprocesscasepartyresultlistCreate: (data: SearchProcessCasePartyProcessInput, params: RequestParams = {}) =>
      this.request<CsrSearchProcessResultDtoPageModelMessageModel, void>({
        path: `/api/web/business/csr/csrprocess/searchprocesscasepartyresultlist`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrProcess
     * @name WebBusinessCsrCsrprocessGetcsrprocesslistCreate
     * @summary 获取利冲的所有审查结果列表 (Auth policies: Web)
     * @request POST:/api/web/business/csr/csrprocess/getcsrprocesslist
     * @secure
     */
    webBusinessCsrCsrprocessGetcsrprocesslistCreate: (data: StringEntityDto, params: RequestParams = {}) =>
      this.request<CsrProcessDtoListMessageModel, void>({
        path: `/api/web/business/csr/csrprocess/getcsrprocesslist`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrProcess
     * @name WebBusinessCsrCsrprocessGetcsrprocessinfoCreate
     * @summary 获取审查详情 (Auth policies: Web)
     * @request POST:/api/web/business/csr/csrprocess/getcsrprocessinfo
     * @secure
     */
    webBusinessCsrCsrprocessGetcsrprocessinfoCreate: (data: StringEntityDto, params: RequestParams = {}) =>
      this.request<CsrProcessDtoMessageModel, void>({
        path: `/api/web/business/csr/csrprocess/getcsrprocessinfo`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrProcess
     * @name WebBusinessCsrCsrprocessCsrrequestpartnerconfirmationapplicationCreate
     * @summary 利冲信息-合伙人确认申请 (Auth policies: Web)
     * @request POST:/api/web/business/csr/csrprocess/csrrequestpartnerconfirmationapplication
     * @secure
     */
    webBusinessCsrCsrprocessCsrrequestpartnerconfirmationapplicationCreate: (data: CerRequestPartnerConfirmationApplicationInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/business/csr/csrprocess/csrrequestpartnerconfirmationapplication`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrProcessAssign
     * @name WebBusinessCsrCsrprocessassignGetallassignlistListallList
     * @summary 获取所有审查分配信息 (Auth policies: Web)
     * @request GET:/api/web/business/csr/csrprocessassign/getallassignlist/listall
     * @secure
     */
    webBusinessCsrCsrprocessassignGetallassignlistListallList: (params: RequestParams = {}) =>
      this.request<CsrProcessAssignDtoListMessageModel, void>({
        path: `/api/web/business/csr/csrprocessassign/getallassignlist/listall`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrProcessAssign
     * @name WebBusinessCsrCsrprocessassignPostSaveCreate
     * @summary 保存审查分配 (Auth policies: Web)
     * @request POST:/api/web/business/csr/csrprocessassign/post/save
     * @secure
     */
    webBusinessCsrCsrprocessassignPostSaveCreate: (data: CsrProcessAssignDto[], params: RequestParams = {}) =>
      this.request<BooleanMessageModel, void>({
        path: `/api/web/business/csr/csrprocessassign/post/save`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrProcessAssign
     * @name WebBusinessCsrCsrprocessassignGetassignusersList
     * @summary 人员选择 (Auth policies: Web)
     * @request GET:/api/web/business/csr/csrprocessassign/getassignusers
     * @secure
     */
    webBusinessCsrCsrprocessassignGetassignusersList: (params: RequestParams = {}) =>
      this.request<CsrProcessAssignUserDtoListMessageModel, void>({
        path: `/api/web/business/csr/csrprocessassign/getassignusers`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CSRProcessPerference
     * @name WebCsrprocessperferenceCsrprocessperferenceGetpageCreate
     * @summary 标签分页 (Auth policies: Web)
     * @request POST:/api/web/csrprocessperference/csrprocessperference/getpage
     * @secure
     */
    webCsrprocessperferenceCsrprocessperferenceGetpageCreate: (data: GetProcessTagsPerferenceInput, params: RequestParams = {}) =>
      this.request<CSRProcessPerferenceDtoPageModelMessageModel, void>({
        path: `/api/web/csrprocessperference/csrprocessperference/getpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CSRProcessPerference
     * @name WebCsrprocessperferenceCsrprocessperferenceGetCreate
     * @summary 获取详情 (Auth policies: Web)
     * @request POST:/api/web/csrprocessperference/csrprocessperference/get/{id}
     * @secure
     */
    webCsrprocessperferenceCsrprocessperferenceGetCreate: (id: string, params: RequestParams = {}) =>
      this.request<CSRProcessPerferenceDtoMessageModel, void>({
        path: `/api/web/csrprocessperference/csrprocessperference/get/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CSRProcessPerference
     * @name WebCsrprocessperferenceCsrprocessperferencePostCreate
     * @summary 添加标签 (Auth policies: Web)
     * @request POST:/api/web/csrprocessperference/csrprocessperference/post
     * @secure
     */
    webCsrprocessperferenceCsrprocessperferencePostCreate: (data: CreateCSRProcessTagsPerferenceInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/csrprocessperference/csrprocessperference/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CSRProcessPerference
     * @name WebCsrprocessperferenceCsrprocessperferenceSetcsrprocessresultcheckedstatusCreate
     * @summary 设置选中状态 (Auth policies: Web)
     * @request POST:/api/web/csrprocessperference/csrprocessperference/setcsrprocessresultcheckedstatus
     * @secure
     */
    webCsrprocessperferenceCsrprocessperferenceSetcsrprocessresultcheckedstatusCreate: (data: SetCSRProcessPerferenceIdsInput, params: RequestParams = {}) =>
      this.request<BooleanMessageModel, void>({
        path: `/api/web/csrprocessperference/csrprocessperference/setcsrprocessresultcheckedstatus`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CSRProcessPerference
     * @name WebCsrprocessperferenceCsrprocessperferencePutUpdate
     * @summary 修改标签 (Auth policies: Web)
     * @request PUT:/api/web/csrprocessperference/csrprocessperference/put/{id}
     * @secure
     */
    webCsrprocessperferenceCsrprocessperferencePutUpdate: (id: string, data: CreateCSRProcessPerferenceInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/csrprocessperference/csrprocessperference/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CSRProcessPerference
     * @name WebCsrprocessperferenceCsrprocessperferenceListcsrprocessCreate
     * @summary 检索结果 (Auth policies: Web)
     * @request POST:/api/web/csrprocessperference/csrprocessperference/listcsrprocess
     * @secure
     */
    webCsrprocessperferenceCsrprocessperferenceListcsrprocessCreate: (data: GetCSRSearchProcessPerferenceInput, params: RequestParams = {}) =>
      this.request<CSRProcessResultPerferenceDtoPageModelMessageModel, void>({
        path: `/api/web/csrprocessperference/csrprocessperference/listcsrprocess`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CSRProcessPerference
     * @name WebCsrprocessperferenceCsrprocessperferenceGetsearchresultpagePageCreate
     * @summary 检索结果列表 (Auth policies: Web)
     * @request POST:/api/web/csrprocessperference/csrprocessperference/getsearchresultpage/page
     * @secure
     */
    webCsrprocessperferenceCsrprocessperferenceGetsearchresultpagePageCreate: (data: GetCSRProcessResultSearchPerferenceInput, params: RequestParams = {}) =>
      this.request<CSRProcessResultPerferenceDtoPageModelMessageModel, void>({
        path: `/api/web/csrprocessperference/csrprocessperference/getsearchresultpage/page`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CSRProcessPerference
     * @name WebCsrprocessperferenceCsrprocessperferenceGetmatchkeywordslistCreate
     * @summary 匹配利冲偏好 (Auth policies: Web)
     * @request POST:/api/web/csrprocessperference/csrprocessperference/getmatchkeywordslist
     * @secure
     */
    webCsrprocessperferenceCsrprocessperferenceGetmatchkeywordslistCreate: (data: SearchProcessCasePartyResultDto[], params: RequestParams = {}) =>
      this.request<CSRProcessPerferenceDtoListMessageModel, void>({
        path: `/api/web/csrprocessperference/csrprocessperference/getmatchkeywordslist`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CSRProcessPerference
     * @name WebCsrprocessperferenceCsrprocessperferenceGetsearchresultpageexportCreate
     * @summary 检索结果列表导出 (Auth policies: Web)
     * @request POST:/api/web/csrprocessperference/csrprocessperference/getsearchresultpageexport
     * @secure
     */
    webCsrprocessperferenceCsrprocessperferenceGetsearchresultpageexportCreate: (
      data: GetCSRProcessResultSearchPerferenceInputExportInput,
      params: RequestParams = {}
    ) =>
      this.request<MessageModel, void>({
        path: `/api/web/csrprocessperference/csrprocessperference/getsearchresultpageexport`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CSRProcessPerference
     * @name WebCsrprocessperferenceCsrprocessperferenceDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/csrprocessperference/csrprocessperference/delete/{id}
     * @secure
     */
    webCsrprocessperferenceCsrprocessperferenceDeleteDelete: (id: string, data: StringEntityDto, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/csrprocessperference/csrprocessperference/delete/${id}`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrProcessResult
     * @name WebBusinessCsrCsrprocessresultListListList
     * @summary 列表获取所有 (Auth policies: Web)
     * @request GET:/api/web/business/csr/csrprocessresult/list/list
     * @secure
     */
    webBusinessCsrCsrprocessresultListListList: (
      query?: {
        /** Id */
        id?: string;
        /** 利冲申请编号 */
        csrId?: string;
        /** 利冲审查编号 */
        csrProcessId?: string;
        /** 是否需要豁免 */
        isNeedExemption?: string;
        /** 利冲关键词 */
        csrKeyword?: string;
        /** 与我冲突案件的角色 */
        csrParty?: string;
        /** 我被冲突案件的角色 */
        caseParty?: string;
        /** 我的案件冲突方名称 */
        casePartyName?: string;
        /** 利冲关联业务类型 */
        csrRelationType?: string;
        /** 关联业务编号 */
        relationId?: string;
        /** 冲突产生时关联业务状态 */
        relationStatus?: string;
        /** 利冲豁免结果 */
        exemptionResult?: string;
        /** 利冲规则 */
        matchingRule?: string;
        /** 客户名称 */
        clientName?: string;
        /** 客户外文名称 */
        clientForeignName?: string;
        /** 客户实控人名称 */
        clientShareholderActualCtrlName?: string;
        /** 客户实控人外文名称 */
        clientShareholderActualCtrlForeignName?: string;
        /** 对方名称 */
        oppositeName?: string;
        /** 对方外文名称 */
        oppositeForeignName?: string;
        /** 对方实控人名称 */
        oppositeShareholderActualCtrlName?: string;
        /** 对方实控人外文名称 */
        oppositeShareholderActualCtrlForeignName?: string;
        /** 潜在第三方名称 */
        potential3rdPartyName?: string;
        /** 潜在第三方外文名称 */
        potential3rdPartyForeignName?: string;
        /** 关联业务类型 */
        relationCategory?: string;
        /** 关联业务名称 */
        relationName?: string;
        /**
         * 关联业务申请时间
         * @format date-time
         */
        relationRequestTime?: string;
        /** 关联业务主管律师 */
        relationInChargeLawyerId?: string;
        /** 关联业务申请合伙人 */
        relationRequestUserId?: string;
        /** 部门编号 */
        organizationUnitId?: string;
        /** 分所Id */
        officeId?: string;
        /** 备注 */
        remark?: string;
        /** 创建ID */
        createBy?: string;
        /**
         * 创建时间
         * @format date-time
         */
        createTime?: string;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<CsrProcessResultDtoListMessageModel, void>({
        path: `/api/web/business/csr/csrprocessresult/list/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrProcessResult
     * @name WebBusinessCsrCsrprocessresultGetDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/business/csr/csrprocessresult/get/{id}
     * @secure
     */
    webBusinessCsrCsrprocessresultGetDetail: (id: string, params: RequestParams = {}) =>
      this.request<CsrProcessResultDtoMessageModel, void>({
        path: `/api/web/business/csr/csrprocessresult/get/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrProcessResult
     * @name WebBusinessCsrCsrprocessresultPostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/business/csr/csrprocessresult/post
     * @secure
     */
    webBusinessCsrCsrprocessresultPostCreate: (data: CreateCsrProcessResultInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/csr/csrprocessresult/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrProcessResult
     * @name WebBusinessCsrCsrprocessresultPutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/business/csr/csrprocessresult/put/{id}
     * @secure
     */
    webBusinessCsrCsrprocessresultPutUpdate: (id: string, data: CreateCsrProcessResultInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/csr/csrprocessresult/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrProcessResult
     * @name WebBusinessCsrCsrprocessresultDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/business/csr/csrprocessresult/delete/{id}
     * @secure
     */
    webBusinessCsrCsrprocessresultDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/csr/csrprocessresult/delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrProcessResult
     * @name WebBusinessCsrCsrprocessresultGetprocesscasepartyresultnoconfirmlistCreate
     * @summary 获取未确认的数据列表 (Auth policies: Web)
     * @request POST:/api/web/business/csr/csrprocessresult/getprocesscasepartyresultnoconfirmlist
     * @secure
     */
    webBusinessCsrCsrprocessresultGetprocesscasepartyresultnoconfirmlistCreate: (
      data: GetProcessCasePartyResultNoConfirmListInput,
      params: RequestParams = {}
    ) =>
      this.request<CsrProcessResultNoConfirmOutputListMessageModel, void>({
        path: `/api/web/business/csr/csrprocessresult/getprocesscasepartyresultnoconfirmlist`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrProcessResult
     * @name WebBusinessCsrCsrprocessresultGetprocesscasepartyresultconfirminfolistList
     * @summary 利冲结果 (Auth policies: Web)
     * @request GET:/api/web/business/csr/csrprocessresult/getprocesscasepartyresultconfirminfolist
     * @secure
     */
    webBusinessCsrCsrprocessresultGetprocesscasepartyresultconfirminfolistList: (
      query?: {
        csrProcessId?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<CsrProcessResultConfirmInfoOutputListMessageModel, void>({
        path: `/api/web/business/csr/csrprocessresult/getprocesscasepartyresultconfirminfolist`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrProcessResult
     * @name WebBusinessCsrCsrprocessresultGetprocesscasepartyresultpageCreate
     * @summary 检索结果列表 (Auth policies: Web)
     * @request POST:/api/web/business/csr/csrprocessresult/getprocesscasepartyresultpage
     * @secure
     */
    webBusinessCsrCsrprocessresultGetprocesscasepartyresultpageCreate: (data: GetProcessCasePartyResultInput, params: RequestParams = {}) =>
      this.request<CsrSearchProcessResultDtoPageModelMessageModel, void>({
        path: `/api/web/business/csr/csrprocessresult/getprocesscasepartyresultpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrProcessResult
     * @name WebBusinessCsrCsrprocessresultGetprocesscasepartyresultexportCreate
     * @summary 导出检索结果列表 (Auth policies: Web)
     * @request POST:/api/web/business/csr/csrprocessresult/getprocesscasepartyresultexport
     * @secure
     */
    webBusinessCsrCsrprocessresultGetprocesscasepartyresultexportCreate: (data: GetProcessCasePartyResultInputExportInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/business/csr/csrprocessresult/getprocesscasepartyresultexport`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CSRProcessResultPerference
     * @name WebCsrprocessresultperferenceCsrprocessresultperferenceGetpageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/csrprocessresultperference/csrprocessresultperference/getpage
     * @secure
     */
    webCsrprocessresultperferenceCsrprocessresultperferenceGetpageCreate: (data: GetCSRProcessResultPerferenceInput, params: RequestParams = {}) =>
      this.request<CSRProcessResultPerferenceDtoPageModelMessageModel, void>({
        path: `/api/web/csrprocessresultperference/csrprocessresultperference/getpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CSRProcessResultPerference
     * @name WebCsrprocessresultperferenceCsrprocessresultperferenceListCreate
     * @summary 列表获取所有 (Auth policies: Web)
     * @request POST:/api/web/csrprocessresultperference/csrprocessresultperference/list
     * @secure
     */
    webCsrprocessresultperferenceCsrprocessresultperferenceListCreate: (
      query?: {
        /** 利冲申请编号 */
        csrId?: string;
        /** 利冲审查编号 */
        csrProcessId?: string;
        /** 是否需要豁免 */
        isNeedExemption?: string;
        /** 是否选中 */
        isChecked?: string;
        /** 利冲关键词 */
        csrKeyword?: string;
        /** 与我冲突案件的角色 */
        csrParty?: string;
        /** 我被冲突案件的角色 */
        caseParty?: string;
        /** 我的案件冲突方名称 */
        casePartyName?: string;
        /** 冲突等级 */
        csrLevel?: string;
        /** 利冲关联业务类型 */
        csrRelationType?: string;
        /** 关联业务编号（默认案件） */
        relationId?: string;
        /** 冲突产生时关联业务状态 */
        relationStatus?: string;
        /** 利冲豁免结果 */
        exemptionResult?: string;
        /** 匹配利冲规则 */
        matchingRule?: string;
        /** 关联方信息 */
        caseRelatedResultJson?: string;
        /** 匹配客户名称 */
        clientName?: string;
        /** 匹配客户外文名称 */
        clientForeignName?: string;
        /** 匹配客户实控人名称 */
        clientShareholderActualCtrlName?: string;
        /** 匹配客户实控人外文名称 */
        clientShareholderActualCtrlForeignName?: string;
        /** 客户参与实体名称 */
        clientParticipatingEntityName?: string;
        /** 客户参与实体外文名称 */
        clientParticipatingEntityForeignName?: string;
        /** 匹配对方名称 */
        oppositeName?: string;
        /** 匹配对方外文名称 */
        oppositeForeignName?: string;
        /** 匹配对方实控人名称 */
        oppositeShareholderActualCtrlName?: string;
        /** 匹配对方实控人外文名称 */
        oppositeShareholderActualCtrlForeignName?: string;
        /** 对方关联方名称 */
        oppositeRelatedPartiesName?: string;
        /** 对方关联方外文名称 */
        oppositeRelatedPartiesForeignName?: string;
        /** 潜在第三方名称 */
        potential3rdPartyName?: string;
        /** 潜在第三方外文名称 */
        potential3rdPartyForeignName?: string;
        /** 潜在第三方实控人名称 */
        potential3rdPartyShareholderActualCtrlName?: string;
        /** 潜在第三方实控人外文名称 */
        potential3rdPartyShareholderActualCtrlForeignName?: string;
        /** 关联业务类型 */
        relationCategory?: string;
        /** 关联业务名称 */
        relationName?: string;
        /**
         * 开始日期时间
         * @format date-time
         */
        relationRequestTimeStartDate?: string;
        /**
         * 结束日期时间
         * @format date-time
         */
        relationRequestTimeEndDate?: string;
        /** 关联业务主管律师 */
        relationInChargeLawyerId?: string;
        /** 关联业务申请合伙人 */
        relationRequestUserId?: string;
        /** 案源人 */
        relationCaseUser?: string;
        /** 关联实体 */
        relationEntity?: string;
        /**
         * 开始日期时间
         * @format date-time
         */
        relationEndTimeStartDate?: string;
        /**
         * 结束日期时间
         * @format date-time
         */
        relationEndTimeEndDate?: string;
        /** 确认律师Id */
        confirmLawyerId?: string;
        /** 账单管理人 */
        relationBillingLawyerNameId?: string;
        /** 关联客户Id */
        clientId?: string;
        /** 标签 */
        tagName?: string;
        /** 流水编号 */
        serialId?: string;
        /** 原系统主键编号 */
        originalId?: string;
        /** 流程数据大状态 */
        status?: string;
        /**
         * 开始日期时间
         * @format date-time
         */
        submittedOnStartDate?: string;
        /**
         * 结束日期时间
         * @format date-time
         */
        submittedOnEndDate?: string;
        /** 工作流状态 */
        flowState?: string;
        /** 申请人 */
        applicant?: string;
        /**
         * 开始日期时间
         * @format date-time
         */
        flowEndDateTimeStartDate?: string;
        /**
         * 结束日期时间
         * @format date-time
         */
        flowEndDateTimeEndDate?: string;
        /** 是否超期 */
        isOverdue?: boolean;
        /** 备注 */
        remark?: string;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<CSRProcessResultPerferenceDtoListMessageModel, void>({
        path: `/api/web/csrprocessresultperference/csrprocessresultperference/list`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CSRProcessResultPerference
     * @name WebCsrprocessresultperferenceCsrprocessresultperferenceGetcsrprocessresultperferenceexportCreate
     * @summary 列表导出 (Auth policies: Web)
     * @request POST:/api/web/csrprocessresultperference/csrprocessresultperference/getcsrprocessresultperferenceexport
     * @secure
     */
    webCsrprocessresultperferenceCsrprocessresultperferenceGetcsrprocessresultperferenceexportCreate: (
      data: GetCSRProcessResultPerferenceInputExportInput,
      params: RequestParams = {}
    ) =>
      this.request<ExcelFileDtoMessageModel, void>({
        path: `/api/web/csrprocessresultperference/csrprocessresultperference/getcsrprocessresultperferenceexport`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CSRProcessResultPerference
     * @name WebCsrprocessresultperferenceCsrprocessresultperferenceGetCreate
     * @summary 获取详情 (Auth policies: Web)
     * @request POST:/api/web/csrprocessresultperference/csrprocessresultperference/get/{id}
     * @secure
     */
    webCsrprocessresultperferenceCsrprocessresultperferenceGetCreate: (id: string, params: RequestParams = {}) =>
      this.request<CSRProcessResultPerferenceDtoMessageModel, void>({
        path: `/api/web/csrprocessresultperference/csrprocessresultperference/get/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CSRProcessResultPerference
     * @name WebCsrprocessresultperferenceCsrprocessresultperferencePostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/csrprocessresultperference/csrprocessresultperference/post
     * @secure
     */
    webCsrprocessresultperferenceCsrprocessresultperferencePostCreate: (data: CreateCSRProcessResultPerferenceInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/csrprocessresultperference/csrprocessresultperference/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CSRProcessResultPerference
     * @name WebCsrprocessresultperferenceCsrprocessresultperferencePutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/csrprocessresultperference/csrprocessresultperference/put/{id}
     * @secure
     */
    webCsrprocessresultperferenceCsrprocessresultperferencePutUpdate: (id: string, data: CreateCSRProcessResultPerferenceInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/csrprocessresultperference/csrprocessresultperference/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrProtectEntityInfo
     * @name WebCustomerCsrprotectentityinfoGetcsrprotectentityinfopageCreate
     * @summary 获取利冲保护名单分页 (Auth policies: Web)
     * @request POST:/api/web/customer/csrprotectentityinfo/getcsrprotectentityinfopage
     * @secure
     */
    webCustomerCsrprotectentityinfoGetcsrprotectentityinfopageCreate: (data: GetCsrProtectEntityInfoInput, params: RequestParams = {}) =>
      this.request<CsrProtectEntityInfoOutputPageModelMessageModel, void>({
        path: `/api/web/customer/csrprotectentityinfo/getcsrprotectentityinfopage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrProtectEntityInfo
     * @name WebCustomerCsrprotectentityinfoGetcsrprotectentityinfoCreate
     * @summary 获取利冲保护名单详情 (Auth policies: Web)
     * @request POST:/api/web/customer/csrprotectentityinfo/getcsrprotectentityinfo
     * @secure
     */
    webCustomerCsrprotectentityinfoGetcsrprotectentityinfoCreate: (data: StringEntityDto, params: RequestParams = {}) =>
      this.request<CsrProtectEntityInfoOutputMessageModel, void>({
        path: `/api/web/customer/csrprotectentityinfo/getcsrprotectentityinfo`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrProtectEntityInfo
     * @name WebCustomerCsrprotectentityinfoGetcsrprotectentityinfoforeditCreate
     * @summary 获取利冲保护名单编辑对象 (Auth policies: Web)
     * @request POST:/api/web/customer/csrprotectentityinfo/getcsrprotectentityinfoforedit
     * @secure
     */
    webCustomerCsrprotectentityinfoGetcsrprotectentityinfoforeditCreate: (data: StringEntityDto, params: RequestParams = {}) =>
      this.request<CreateCsrProtectEntityInfoInputMessageModel, void>({
        path: `/api/web/customer/csrprotectentityinfo/getcsrprotectentityinfoforedit`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrProtectEntityInfo
     * @name WebCustomerCsrprotectentityinfoCreateorupdatecsrprotectentityinfoCreate
     * @summary 新增或修改利冲保护名单 (Auth policies: Web)
     * @request POST:/api/web/customer/csrprotectentityinfo/createorupdatecsrprotectentityinfo
     * @secure
     */
    webCustomerCsrprotectentityinfoCreateorupdatecsrprotectentityinfoCreate: (data: CreateCsrProtectEntityInfoInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/customer/csrprotectentityinfo/createorupdatecsrprotectentityinfo`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrProtectEntityInfo
     * @name WebCustomerCsrprotectentityinfoDeletecsrprotectentityinfoCreate
     * @summary 删除利冲保护名单 (Auth policies: Web)
     * @request POST:/api/web/customer/csrprotectentityinfo/deletecsrprotectentityinfo
     * @secure
     */
    webCustomerCsrprotectentityinfoDeletecsrprotectentityinfoCreate: (data: StringEntityDto, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/customer/csrprotectentityinfo/deletecsrprotectentityinfo`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrRequest
     * @name WebBusinessCsrCsrrequestCreateexpirecsrrequestinfoCreate
     * @summary 利冲过期重新发起 (Auth policies: Web)
     * @request POST:/api/web/business/csr/csrrequest/createexpirecsrrequestinfo
     * @secure
     */
    webBusinessCsrCsrrequestCreateexpirecsrrequestinfoCreate: (data: StringEntityDto, params: RequestParams = {}) =>
      this.request<BooleanMessageModel, void>({
        path: `/api/web/business/csr/csrrequest/createexpirecsrrequestinfo`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrRequest
     * @name WebBusinessCsrCsrrequestCopycsrrequestinfoCreate
     * @summary 利冲已取消重新发起为草稿 (Auth policies: Web)
     * @request POST:/api/web/business/csr/csrrequest/copycsrrequestinfo
     * @secure
     */
    webBusinessCsrCsrrequestCopycsrrequestinfoCreate: (data: StringEntityDto, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/business/csr/csrrequest/copycsrrequestinfo`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrRequest
     * @name WebBusinessCsrCsrrequestCsrrequestconfirmapprovedwlcCreate
     * @summary 批量确认无利冲 (Auth policies: Web)
     * @request POST:/api/web/business/csr/csrrequest/csrrequestconfirmapprovedwlc
     * @secure
     */
    webBusinessCsrCsrrequestCsrrequestconfirmapprovedwlcCreate: (data: CsrRequestConfirmWLCBatchInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/business/csr/csrrequest/csrrequestconfirmapprovedwlc`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrRequest
     * @name WebBusinessCsrCsrrequestGetcsrexemptionconfirmuserlistCreate
     * @summary 获取合伙人列表 (Auth policies: Web)
     * @request POST:/api/web/business/csr/csrrequest/getcsrexemptionconfirmuserlist
     * @secure
     */
    webBusinessCsrCsrrequestGetcsrexemptionconfirmuserlistCreate: (data: string[], params: RequestParams = {}) =>
      this.request<CSRExemptionInchargeLawyerOutputListMessageModel, void>({
        path: `/api/web/business/csr/csrrequest/getcsrexemptionconfirmuserlist`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrRequest
     * @name WebBusinessCsrCsrrequestCsrrequestconfirmapprovedbatchCreate
     * @summary 利冲豁免确认批量通过 (Auth policies: Web)
     * @request POST:/api/web/business/csr/csrrequest/csrrequestconfirmapprovedbatch
     * @secure
     */
    webBusinessCsrCsrrequestCsrrequestconfirmapprovedbatchCreate: (data: CsrRequestConfirmInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/business/csr/csrrequest/csrrequestconfirmapprovedbatch`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrRequest
     * @name WebBusinessCsrCsrrequestGetcsrrequestforeditCreate
     * @summary 根据ID获取利冲相关信息 (Auth policies: Web)
     * @request POST:/api/web/business/csr/csrrequest/getcsrrequestforedit
     * @secure
     */
    webBusinessCsrCsrrequestGetcsrrequestforeditCreate: (data: StringEntityDto, params: RequestParams = {}) =>
      this.request<CsrRequestDataDtoMessageModel, void>({
        path: `/api/web/business/csr/csrrequest/getcsrrequestforedit`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrRequest
     * @name WebBusinessCsrCsrrequestCreatecsrrequestCreate
     * @summary 利冲案件申请 (Auth policies: Web)
     * @request POST:/api/web/business/csr/csrrequest/createcsrrequest
     * @secure
     */
    webBusinessCsrCsrrequestCreatecsrrequestCreate: (data: CreateCsrRequestInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/csr/csrrequest/createcsrrequest`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrRequest
     * @name WebBusinessCsrCsrrequestCancelcsrrequestCreate
     * @summary 取消利冲 (Auth policies: Web)
     * @request POST:/api/web/business/csr/csrrequest/cancelcsrrequest
     * @secure
     */
    webBusinessCsrCsrrequestCancelcsrrequestCreate: (data: FlowCancelInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/csr/csrrequest/cancelcsrrequest`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrRequest
     * @name WebBusinessCsrCsrrequestGetmycsrrequestpageListCreate
     * @summary 获取我申请的利冲列表 (Auth policies: Web)
     * @request POST:/api/web/business/csr/csrrequest/getmycsrrequestpage/list
     * @secure
     */
    webBusinessCsrCsrrequestGetmycsrrequestpageListCreate: (data: GetMyCsrRequestInput, params: RequestParams = {}) =>
      this.request<GetMyCsrRequestOutputPageModelMessageModel, void>({
        path: `/api/web/business/csr/csrrequest/getmycsrrequestpage/list`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrRequest
     * @name WebBusinessCsrCsrrequestCsrrequestconfirmCreate
     * @summary 利冲解决方案确认 (Auth policies: Web)
     * @request POST:/api/web/business/csr/csrrequest/csrrequestconfirm
     * @secure
     */
    webBusinessCsrCsrrequestCsrrequestconfirmCreate: (data: ApprovalInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/business/csr/csrrequest/csrrequestconfirm`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrRequest
     * @name WebBusinessCsrCsrrequestGetcsrrequestattachmentsCreate
     * @summary 获取利冲豁免文件 (Auth policies: Web)
     * @request POST:/api/web/business/csr/csrrequest/getcsrrequestattachments
     * @secure
     */
    webBusinessCsrCsrrequestGetcsrrequestattachmentsCreate: (data: StringEntityDto, params: RequestParams = {}) =>
      this.request<AttachmentDownloadDtoListMessageModel, void>({
        path: `/api/web/business/csr/csrrequest/getcsrrequestattachments`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrRequest
     * @name WebBusinessCsrCsrrequestCsrrequestapprovedCreate
     * @summary 利冲审查审核通过 (Auth policies: Web)
     * @request POST:/api/web/business/csr/csrrequest/csrrequestapproved
     * @secure
     */
    webBusinessCsrCsrrequestCsrrequestapprovedCreate: (data: ApprovalInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/business/csr/csrrequest/csrrequestapproved`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrRequest
     * @name WebBusinessCsrCsrrequestCsrrequestrejectedCreate
     * @summary 利冲审查审核退回 (Auth policies: Web)
     * @request POST:/api/web/business/csr/csrrequest/csrrequestrejected
     * @secure
     */
    webBusinessCsrCsrrequestCsrrequestrejectedCreate: (data: ApprovalInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/business/csr/csrrequest/csrrequestrejected`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrRequest
     * @name WebBusinessCsrCsrrequestGetflowstatelistCreate
     * @summary 获取工作流状态 (Auth policies: Web)
     * @request POST:/api/web/business/csr/csrrequest/getflowstatelist
     * @secure
     */
    webBusinessCsrCsrrequestGetflowstatelistCreate: (params: RequestParams = {}) =>
      this.request<StringFlowStateDtoListDictionaryMessageModel, void>({
        path: `/api/web/business/csr/csrrequest/getflowstatelist`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrRequest
     * @name WebBusinessCsrCsrrequestGetcsrrequestpageListCreate
     * @summary 获取所有利冲 (Auth policies: Web)
     * @request POST:/api/web/business/csr/csrrequest/getcsrrequestpage/list
     * @secure
     */
    webBusinessCsrCsrrequestGetcsrrequestpageListCreate: (data: GetCsrRequestInput, params: RequestParams = {}) =>
      this.request<GetCsrRequestOutputPageModelMessageModel, void>({
        path: `/api/web/business/csr/csrrequest/getcsrrequestpage/list`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrRequest
     * @name WebBusinessCsrCsrrequestGetcsrrequestpagelistPagelistCreate
     * @summary 利冲列表-所有利冲 (Auth policies: Web)
     * @request POST:/api/web/business/csr/csrrequest/getcsrrequestpagelist/pagelist
     * @secure
     */
    webBusinessCsrCsrrequestGetcsrrequestpagelistPagelistCreate: (data: GetCsrRequestInput, params: RequestParams = {}) =>
      this.request<GetCsrRequestOutputPageModelMessageModel, void>({
        path: `/api/web/business/csr/csrrequest/getcsrrequestpagelist/pagelist`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrRequest
     * @name WebBusinessCsrCsrrequestGetcsrwaitforfilingpageCreate
     * @summary 获取待转为案件申请的利冲申请列表 (Auth policies: Web)
     * @request POST:/api/web/business/csr/csrrequest/getcsrwaitforfilingpage
     * @secure
     */
    webBusinessCsrCsrrequestGetcsrwaitforfilingpageCreate: (data: GetCsrRequestInput, params: RequestParams = {}) =>
      this.request<GetCsrRequestOutputPageModelMessageModel, void>({
        path: `/api/web/business/csr/csrrequest/getcsrwaitforfilingpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrRequest
     * @name WebBusinessCsrCsrrequestGetcsrrequestinfoCreate
     * @summary 获取利冲申请详细信息 (Auth policies: Web)
     * @request POST:/api/web/business/csr/csrrequest/getcsrrequestinfo
     * @secure
     */
    webBusinessCsrCsrrequestGetcsrrequestinfoCreate: (data: StringEntityDto, params: RequestParams = {}) =>
      this.request<CsrRequestInfoOutputMessageModel, void>({
        path: `/api/web/business/csr/csrrequest/getcsrrequestinfo`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrRequest
     * @name WebBusinessCsrCsrrequestGetcsrrequestcloneinfoCreate
     * @summary 获取利冲申请克隆信息 (Auth policies: Web)
     * @request POST:/api/web/business/csr/csrrequest/getcsrrequestcloneinfo
     * @deprecated
     * @secure
     */
    webBusinessCsrCsrrequestGetcsrrequestcloneinfoCreate: (data: StringEntityDto, params: RequestParams = {}) =>
      this.request<CsrRequestInfoOutputMessageModel, void>({
        path: `/api/web/business/csr/csrrequest/getcsrrequestcloneinfo`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrRequest
     * @name WebBusinessCsrCsrrequestGetcasematterforcloneCreate
     * @summary 创建并获取克隆案件信息 (Auth policies: Web)
     * @request POST:/api/web/business/csr/csrrequest/getcasematterforclone
     * @secure
     */
    webBusinessCsrCsrrequestGetcasematterforcloneCreate: (data: CreateCloneCaseInput, params: RequestParams = {}) =>
      this.request<CreateCaseMatterInputMessageModel, void>({
        path: `/api/web/business/csr/csrrequest/getcasematterforclone`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrRequest
     * @name WebBusinessCsrCsrrequestDeleteDelete
     * @summary 删除利冲申请 (Auth policies: Web)
     * @request DELETE:/api/web/business/csr/csrrequest/delete/{id}
     * @secure
     */
    webBusinessCsrCsrrequestDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/csr/csrrequest/delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrRequest
     * @name WebBusinessCsrCsrrequestGetcsrrequestcheckpageCreate
     * @summary 利冲审查 (Auth policies: Web)
     * @request POST:/api/web/business/csr/csrrequest/getcsrrequestcheckpage
     * @secure
     */
    webBusinessCsrCsrrequestGetcsrrequestcheckpageCreate: (data: GetCsrRequestCheckInput, params: RequestParams = {}) =>
      this.request<CsrRequestCheckOutputPageModelMessageModel, void>({
        path: `/api/web/business/csr/csrrequest/getcsrrequestcheckpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrRequest
     * @name WebBusinessCsrCsrrequestGetcsrrequestcheckexportCreate
     * @summary 利冲审查导出 (Auth policies: Web)
     * @request POST:/api/web/business/csr/csrrequest/getcsrrequestcheckexport
     * @secure
     */
    webBusinessCsrCsrrequestGetcsrrequestcheckexportCreate: (data: GetCsrRequestCheckInputExportInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/business/csr/csrrequest/getcsrrequestcheckexport`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrRequest
     * @name WebBusinessCsrCsrrequestGetmypageexportCreate
     * @summary 我的利冲申请列表导出 (Auth policies: Web)
     * @request POST:/api/web/business/csr/csrrequest/getmypageexport
     * @secure
     */
    webBusinessCsrCsrrequestGetmypageexportCreate: (data: GetMyCsrRequestInputExportInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/business/csr/csrrequest/getmypageexport`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrRequest
     * @name WebBusinessCsrCsrrequestGetcsrrequestexportCreate
     * @summary 所有利冲列表导出 (Auth policies: Web)
     * @request POST:/api/web/business/csr/csrrequest/getcsrrequestexport
     * @secure
     */
    webBusinessCsrCsrrequestGetcsrrequestexportCreate: (data: GetCsrRequestInputExportInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/business/csr/csrrequest/getcsrrequestexport`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrRequest
     * @name WebBusinessCsrCsrrequestGetcsrrequestcheckcountList
     * @summary 利冲审查数量 (Auth policies: Web)
     * @request GET:/api/web/business/csr/csrrequest/getcsrrequestcheckcount
     * @secure
     */
    webBusinessCsrCsrrequestGetcsrrequestcheckcountList: (params: RequestParams = {}) =>
      this.request<Int32MessageModel, void>({
        path: `/api/web/business/csr/csrrequest/getcsrrequestcheckcount`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrRequest
     * @name WebBusinessCsrCsrrequestGetmyinprocesscsrrequestcheckcountList
     * @summary 获取我在途的利冲申请数量 （在途数量大于2或者24小时内有在途申请都会返回大于等于2的数值） (Auth policies: Web)
     * @request GET:/api/web/business/csr/csrrequest/getmyinprocesscsrrequestcheckcount
     * @secure
     */
    webBusinessCsrCsrrequestGetmyinprocesscsrrequestcheckcountList: (params: RequestParams = {}) =>
      this.request<Int32MessageModel, void>({
        path: `/api/web/business/csr/csrrequest/getmyinprocesscsrrequestcheckcount`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrRequest
     * @name WebBusinessCsrCsrrequestGetcsrrequestconfirmcountList
     * @summary 利冲确认管理数量 (Auth policies: Web)
     * @request GET:/api/web/business/csr/csrrequest/getcsrrequestconfirmcount
     * @secure
     */
    webBusinessCsrCsrrequestGetcsrrequestconfirmcountList: (params: RequestParams = {}) =>
      this.request<Int32MessageModel, void>({
        path: `/api/web/business/csr/csrrequest/getcsrrequestconfirmcount`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrRequest
     * @name WebBusinessCsrCsrrequestGetcsrrequestconfirmpageCreate
     * @summary 利冲确认管理 (Auth policies: Web)
     * @request POST:/api/web/business/csr/csrrequest/getcsrrequestconfirmpage
     * @secure
     */
    webBusinessCsrCsrrequestGetcsrrequestconfirmpageCreate: (data: GetCsrRequestConfirmInput, params: RequestParams = {}) =>
      this.request<CsrRequestConfirmOutputPageModelMessageModel, void>({
        path: `/api/web/business/csr/csrrequest/getcsrrequestconfirmpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrRequest
     * @name WebBusinessCsrCsrrequestGetcsrrequestconfirmsolutionpageCreate
     * @summary 利冲解决方案确认管理 (Auth policies: Web)
     * @request POST:/api/web/business/csr/csrrequest/getcsrrequestconfirmsolutionpage
     * @secure
     */
    webBusinessCsrCsrrequestGetcsrrequestconfirmsolutionpageCreate: (data: GetCsrRequestConfirmInput, params: RequestParams = {}) =>
      this.request<CsrRequestConfirmOutputPageModelMessageModel, void>({
        path: `/api/web/business/csr/csrrequest/getcsrrequestconfirmsolutionpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrRequest
     * @name WebBusinessCsrCsrrequestGetcsrrequestconfirmsolutionexportCreate
     * @summary 利冲解决方案确认导出 (Auth policies: Web)
     * @request POST:/api/web/business/csr/csrrequest/getcsrrequestconfirmsolutionexport
     * @secure
     */
    webBusinessCsrCsrrequestGetcsrrequestconfirmsolutionexportCreate: (data: GetCsrRequestConfirmInputExportInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/business/csr/csrrequest/getcsrrequestconfirmsolutionexport`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrRequest
     * @name WebBusinessCsrCsrrequestGetcsrrequestconfirmexportCreate
     * @summary 利冲确认管理导出 (Auth policies: Web)
     * @request POST:/api/web/business/csr/csrrequest/getcsrrequestconfirmexport
     * @secure
     */
    webBusinessCsrCsrrequestGetcsrrequestconfirmexportCreate: (data: GetCsrRequestConfirmInputExportInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/business/csr/csrrequest/getcsrrequestconfirmexport`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrRequest
     * @name WebBusinessCsrCsrrequestGetmycsrrequestconfirmpageCreate
     * @summary 待我确认的利冲列表 (Auth policies: Web)
     * @request POST:/api/web/business/csr/csrrequest/getmycsrrequestconfirmpage
     * @secure
     */
    webBusinessCsrCsrrequestGetmycsrrequestconfirmpageCreate: (data: GetMyCsrRequestConfirmInput, params: RequestParams = {}) =>
      this.request<MyCsrRequestConfirmOutputPageModelMessageModel, void>({
        path: `/api/web/business/csr/csrrequest/getmycsrrequestconfirmpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrRequest
     * @name WebBusinessCsrCsrrequestGetmycsrrequestconfirmexportCreate
     * @summary 待我确认的利冲列表导出 (Auth policies: Web)
     * @request POST:/api/web/business/csr/csrrequest/getmycsrrequestconfirmexport
     * @secure
     */
    webBusinessCsrCsrrequestGetmycsrrequestconfirmexportCreate: (data: GetMyCsrRequestConfirmInputExportInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/business/csr/csrrequest/getmycsrrequestconfirmexport`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrRequest
     * @name WebBusinessCsrCsrrequestGetmycsrrequestconfirmhistorypageCreate
     * @summary 利冲确认历史列表 (Auth policies: Web)
     * @request POST:/api/web/business/csr/csrrequest/getmycsrrequestconfirmhistorypage
     * @secure
     */
    webBusinessCsrCsrrequestGetmycsrrequestconfirmhistorypageCreate: (data: GetMyCsrRequestConfirmInput, params: RequestParams = {}) =>
      this.request<MyCsrRequestConfirmOutputPageModelMessageModel, void>({
        path: `/api/web/business/csr/csrrequest/getmycsrrequestconfirmhistorypage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrRequest
     * @name WebBusinessCsrCsrrequestGetmycsrrequestconfirmhistoryexportCreate
     * @summary 利冲确认历史列表导出 (Auth policies: Web)
     * @request POST:/api/web/business/csr/csrrequest/getmycsrrequestconfirmhistoryexport
     * @secure
     */
    webBusinessCsrCsrrequestGetmycsrrequestconfirmhistoryexportCreate: (data: GetMyCsrRequestConfirmInputExportInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/business/csr/csrrequest/getmycsrrequestconfirmhistoryexport`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrRequest
     * @name WebBusinessCsrCsrrequestCsrrequestapplyCreate
     * @summary 利冲申请重新发起审核 (Auth policies: Web)
     * @request POST:/api/web/business/csr/csrrequest/csrrequestapply
     * @secure
     */
    webBusinessCsrCsrrequestCsrrequestapplyCreate: (data: ApprovalInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/business/csr/csrrequest/csrrequestapply`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrRequest
     * @name WebBusinessCsrCsrrequestCsrrequestexpireapplyCreate
     * @summary 利冲过期重新提交 (Auth policies: Web)
     * @request POST:/api/web/business/csr/csrrequest/csrrequestexpireapply
     * @secure
     */
    webBusinessCsrCsrrequestCsrrequestexpireapplyCreate: (data: ApprovalInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/business/csr/csrrequest/csrrequestexpireapply`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrRequest
     * @name WebBusinessCsrCsrrequestCsrrequestexaminerconfirmapprovedCreate
     * @summary 利冲审查专员确认通过 (Auth policies: Web)
     * @request POST:/api/web/business/csr/csrrequest/csrrequestexaminerconfirmapproved
     * @secure
     */
    webBusinessCsrCsrrequestCsrrequestexaminerconfirmapprovedCreate: (data: CsrRequestExaminerConfirmApprovalInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/business/csr/csrrequest/csrrequestexaminerconfirmapproved`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrRequest
     * @name WebBusinessCsrCsrrequestCsrrequestexaminerconfirmwithdrawCreate
     * @summary 利冲豁免确认撤回 (Auth policies: Web)
     * @request POST:/api/web/business/csr/csrrequest/csrrequestexaminerconfirmwithdraw
     * @secure
     */
    webBusinessCsrCsrrequestCsrrequestexaminerconfirmwithdrawCreate: (data: ApprovalInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/business/csr/csrrequest/csrrequestexaminerconfirmwithdraw`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrRequest
     * @name WebBusinessCsrCsrrequestCsrrequestexaminerconfirmrejectedCreate
     * @summary 利冲审查专员确认退回 (Auth policies: Web)
     * @request POST:/api/web/business/csr/csrrequest/csrrequestexaminerconfirmrejected
     * @secure
     */
    webBusinessCsrCsrrequestCsrrequestexaminerconfirmrejectedCreate: (data: ApprovalInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/business/csr/csrrequest/csrrequestexaminerconfirmrejected`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrRequest
     * @name WebBusinessCsrCsrrequestCsrrequestwithdrawCreate
     * @summary 利冲申请撤回 (Auth policies: Web)
     * @request POST:/api/web/business/csr/csrrequest/csrrequestwithdraw
     * @secure
     */
    webBusinessCsrCsrrequestCsrrequestwithdrawCreate: (data: ApprovalInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/business/csr/csrrequest/csrrequestwithdraw`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrRequest
     * @name WebBusinessCsrCsrrequestCsrrequestconfirmapprovedCreate
     * @summary 利冲豁免确认通过 (Auth policies: Web)
     * @request POST:/api/web/business/csr/csrrequest/csrrequestconfirmapproved
     * @secure
     */
    webBusinessCsrCsrrequestCsrrequestconfirmapprovedCreate: (data: CsrRequestConfirmInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/business/csr/csrrequest/csrrequestconfirmapproved`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrRequest
     * @name WebBusinessCsrCsrrequestCsrrequestunconfirmapprovedCreate
     * @summary 利冲豁免确认通过 (Auth policies: Web)
     * @request POST:/api/web/business/csr/csrrequest/csrrequestunconfirmapproved
     * @secure
     */
    webBusinessCsrCsrrequestCsrrequestunconfirmapprovedCreate: (data: CsrRequestConfirmInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/business/csr/csrrequest/csrrequestunconfirmapproved`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrRequest
     * @name WebBusinessCsrCsrrequestGetcsrrequestconfirminfoCreate
     * @summary 获取利冲确认详细信息 (Auth policies: Web)
     * @request POST:/api/web/business/csr/csrrequest/getcsrrequestconfirminfo
     * @secure
     */
    webBusinessCsrCsrrequestGetcsrrequestconfirminfoCreate: (data: StringEntityDto, params: RequestParams = {}) =>
      this.request<CsrRequestInfoOutputMessageModel, void>({
        path: `/api/web/business/csr/csrrequest/getcsrrequestconfirminfo`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrRequest
     * @name WebBusinessCsrCsrrequestGetmycsrrequestconfirminfoCreate
     * @summary 获取待我确认的利冲详细信息 (Auth policies: Web)
     * @request POST:/api/web/business/csr/csrrequest/getmycsrrequestconfirminfo
     * @secure
     */
    webBusinessCsrCsrrequestGetmycsrrequestconfirminfoCreate: (data: StringEntityDto, params: RequestParams = {}) =>
      this.request<CsrRequestInfoOutputMessageModel, void>({
        path: `/api/web/business/csr/csrrequest/getmycsrrequestconfirminfo`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrRequest
     * @name WebBusinessCsrCsrrequestCsrrequestsettingforeditList
     * @summary 获取利冲规则编辑对象 (Auth policies: Web)
     * @request GET:/api/web/business/csr/csrrequest/csrrequestsettingforedit
     * @secure
     */
    webBusinessCsrCsrrequestCsrrequestsettingforeditList: (params: RequestParams = {}) =>
      this.request<CsrRequestSettingInputMessageModel, void>({
        path: `/api/web/business/csr/csrrequest/csrrequestsettingforedit`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrRequest
     * @name WebBusinessCsrCsrrequestCsrrequestsettingCreate
     * @summary 修改利冲规则设置 (Auth policies: Web)
     * @request POST:/api/web/business/csr/csrrequest/csrrequestsetting
     * @secure
     */
    webBusinessCsrCsrrequestCsrrequestsettingCreate: (data: CsrRequestSettingInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/csr/csrrequest/csrrequestsetting`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrRequest
     * @name WebBusinessCsrCsrrequestGetcommonemailsList
     * @summary  (Auth policies: Web)
     * @request GET:/api/web/business/csr/csrrequest/getcommonemails
     * @secure
     */
    webBusinessCsrCsrrequestGetcommonemailsList: (params: RequestParams = {}) =>
      this.request<StringListMessageModel, void>({
        path: `/api/web/business/csr/csrrequest/getcommonemails`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrRequest
     * @name WebBusinessCsrCsrrequestSendemailreminderCreate
     * @summary 手动发送确认提醒邮件 (Auth policies: Web)
     * @request POST:/api/web/business/csr/csrrequest/sendemailreminder
     * @secure
     */
    webBusinessCsrCsrrequestSendemailreminderCreate: (data: CsrRequestEmailRemindInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/business/csr/csrrequest/sendemailreminder`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrRequest
     * @name WebBusinessCsrCsrrequestSendemailreminderallList
     * @summary 手动发送全部确认提醒邮件 (Auth policies: Web)
     * @request GET:/api/web/business/csr/csrrequest/sendemailreminderall
     * @secure
     */
    webBusinessCsrCsrrequestSendemailreminderallList: (params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/business/csr/csrrequest/sendemailreminderall`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrRequest
     * @name WebBusinessCsrCsrrequestSendcsrrequestemailList
     * @summary 手动发送利冲列表邮件 (Auth policies: Web)
     * @request GET:/api/web/business/csr/csrrequest/sendcsrrequestemail
     * @secure
     */
    webBusinessCsrCsrrequestSendcsrrequestemailList: (params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/business/csr/csrrequest/sendcsrrequestemail`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrRequest
     * @name WebBusinessCsrCsrrequestCreatedraftcsrrequestCreate
     * @summary 利益冲突信息暂存草稿 (Auth policies: Web)
     * @request POST:/api/web/business/csr/csrrequest/createdraftcsrrequest
     * @secure
     */
    webBusinessCsrCsrrequestCreatedraftcsrrequestCreate: (data: CreateDraftCsrRequestInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/csr/csrrequest/createdraftcsrrequest`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrRequest
     * @name WebBusinessCsrCsrrequestImportreplenishCreate
     * @summary 导入补充利冲信息 (Auth policies: Web)
     * @request POST:/api/web/business/csr/csrrequest/importreplenish
     * @secure
     */
    webBusinessCsrCsrrequestImportreplenishCreate: (
      data: {
        /**
         * 文件
         * @format binary
         */
        File?: File;
        /** 路径 */
        Path?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<MessageModel, void>({
        path: `/api/web/business/csr/csrrequest/importreplenish`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrRequest
     * @name WebBusinessCsrCsrrequestUpdateremarkCreate
     * @summary 修改利冲备注 (Auth policies: Web)
     * @request POST:/api/web/business/csr/csrrequest/updateremark
     * @secure
     */
    webBusinessCsrCsrrequestUpdateremarkCreate: (data: UpdateRemarkInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/business/csr/csrrequest/updateremark`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrRequest
     * @name WebBusinessCsrCsrrequestSenduserbatchcsrremindemailCreate
     * @summary 手动发送批量合并提醒邮件 (Auth policies: Web)
     * @request POST:/api/web/business/csr/csrrequest/senduserbatchcsrremindemail
     * @secure
     */
    webBusinessCsrCsrrequestSenduserbatchcsrremindemailCreate: (data: CsrRequestBatchEmailRemindInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/business/csr/csrrequest/senduserbatchcsrremindemail`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrRequest
     * @name WebBusinessCsrCsrrequestSendusercsrremindemailCreate
     * @summary 手动发送合并案件提醒邮件 (Auth policies: Web)
     * @request POST:/api/web/business/csr/csrrequest/sendusercsrremindemail
     * @secure
     */
    webBusinessCsrCsrrequestSendusercsrremindemailCreate: (data: CsrRequestBatchEmailRemindInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/business/csr/csrrequest/sendusercsrremindemail`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrRequest
     * @name WebBusinessCsrCsrrequestSenduserbatchremindemailCreate
     * @summary 手动发送批量提醒邮件 (Auth policies: Web)
     * @request POST:/api/web/business/csr/csrrequest/senduserbatchremindemail
     * @secure
     */
    webBusinessCsrCsrrequestSenduserbatchremindemailCreate: (data: CsrRequestBatchEmailRemindInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/business/csr/csrrequest/senduserbatchremindemail`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrRequest
     * @name WebBusinessCsrCsrrequestGetcsrrequestnoconfirmlistCreate
     * @summary 获取未确认利冲数据 (Auth policies: Web)
     * @request POST:/api/web/business/csr/csrrequest/getcsrrequestnoconfirmlist
     * @secure
     */
    webBusinessCsrCsrrequestGetcsrrequestnoconfirmlistCreate: (data: GetCsrRequestNoConfirmInput, params: RequestParams = {}) =>
      this.request<CsrRequestNoConfirmOutputListMessageModel, void>({
        path: `/api/web/business/csr/csrrequest/getcsrrequestnoconfirmlist`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CsrRequest
     * @name WebBusinessCsrCsrrequestSend24ReminderCreate
     * @summary 发送24小时提醒邮件 (Auth policies: Web)
     * @request POST:/api/web/business/csr/csrrequest/send24reminder
     * @secure
     */
    webBusinessCsrCsrrequestSend24ReminderCreate: (data: StringEntityDto, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/business/csr/csrrequest/send24reminder`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CustomExcel
     * @name WebSysCustomexcelCreatetemplateruleCreate
     * @summary 新增显示创建客户模板规则 (Auth policies: Web)
     * @request POST:/api/web/sys/customexcel/createtemplaterule
     * @secure
     */
    webSysCustomexcelCreatetemplateruleCreate: (data: CreateEntityInfoInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/customexcel/createtemplaterule`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CustomExcel
     * @name WebSysCustomexcelCustomexceltemplateinitExcelTemplateList
     * @summary Excel导出 (Auth policies: Web)
     * @request GET:/api/web/sys/customexcel/customexceltemplateinit/excel/template
     * @secure
     */
    webSysCustomexcelCustomexceltemplateinitExcelTemplateList: (
      query?: {
        fileName?: string;
        requestUrl?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<ExcelFileDtoMessageModel, void>({
        path: `/api/web/sys/customexcel/customexceltemplateinit/excel/template`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags DingtalkDepartment
     * @name WebTeamworkDingtalkdepartmentGetpagePageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/teamwork/dingtalkdepartment/getpage/page
     * @secure
     */
    webTeamworkDingtalkdepartmentGetpagePageCreate: (
      query?: {
        /** 应用编号 */
        appId?: string;
        /** 部门ID */
        deptId?: string;
        /** 部门名称 */
        name?: string;
        /** 父部门Id */
        parentId?: string;
        /** 是否同步创建一个关联此部门的企业群 */
        createDeptGroup?: boolean;
        /** 部门群已经创建后，有新人加入部门是否会自动加入该群 */
        autoAddUser?: boolean;
        /** 部门标识字段 */
        sourceIdentifier?: string;
        /** 是否默认同意加入该部门的申请 */
        autoApproveApply?: boolean;
        /** 部门是否来自关联组织 */
        fromUnionOrg?: boolean;
        /** 教育部门标签 */
        tags?: string;
        /** 在父部门中的次序值 */
        order?: string;
        /** 部门群ID */
        deptGroupChatId?: string;
        /** 部门群是否包含子部门 */
        groupContainSubDept?: boolean;
        /** 租户ID */
        tenantId?: string;
        /** 部门ID  DepartmentId */
        organizationUnitId?: string;
        /** 分所Id */
        officeId?: string;
        /** 备注 */
        remark?: string;
        /** 状态 	            中立字段，某些表可使用某些表不使用 */
        isEnabled?: boolean;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<DingtalkDepartmentDtoPageModelMessageModel, void>({
        path: `/api/web/teamwork/dingtalkdepartment/getpage/page`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags DingtalkDepartment
     * @name WebTeamworkDingtalkdepartmentListListList
     * @summary 列表获取所有 (Auth policies: Web)
     * @request GET:/api/web/teamwork/dingtalkdepartment/list/list
     * @secure
     */
    webTeamworkDingtalkdepartmentListListList: (
      query?: {
        /** 应用编号 */
        appId?: string;
        /** 部门ID */
        deptId?: string;
        /** 部门名称 */
        name?: string;
        /** 父部门Id */
        parentId?: string;
        /** 是否同步创建一个关联此部门的企业群 */
        createDeptGroup?: boolean;
        /** 部门群已经创建后，有新人加入部门是否会自动加入该群 */
        autoAddUser?: boolean;
        /** 部门标识字段 */
        sourceIdentifier?: string;
        /** 是否默认同意加入该部门的申请 */
        autoApproveApply?: boolean;
        /** 部门是否来自关联组织 */
        fromUnionOrg?: boolean;
        /** 教育部门标签 */
        tags?: string;
        /** 在父部门中的次序值 */
        order?: string;
        /** 部门群ID */
        deptGroupChatId?: string;
        /** 部门群是否包含子部门 */
        groupContainSubDept?: boolean;
        /** 租户ID */
        tenantId?: string;
        /** 部门ID  DepartmentId */
        organizationUnitId?: string;
        /** 分所Id */
        officeId?: string;
        /** 备注 */
        remark?: string;
        /** 状态 	            中立字段，某些表可使用某些表不使用 */
        isEnabled?: boolean;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<DingtalkDepartmentDtoListMessageModel, void>({
        path: `/api/web/teamwork/dingtalkdepartment/list/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags DingtalkDepartment
     * @name WebTeamworkDingtalkdepartmentGetDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/teamwork/dingtalkdepartment/get/{id}
     * @secure
     */
    webTeamworkDingtalkdepartmentGetDetail: (id: string, params: RequestParams = {}) =>
      this.request<DingtalkDepartmentDtoMessageModel, void>({
        path: `/api/web/teamwork/dingtalkdepartment/get/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags DingtalkDepartment
     * @name WebTeamworkDingtalkdepartmentPostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/teamwork/dingtalkdepartment/post
     * @secure
     */
    webTeamworkDingtalkdepartmentPostCreate: (data: CreateDingtalkDepartmentInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/teamwork/dingtalkdepartment/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags DingtalkDepartment
     * @name WebTeamworkDingtalkdepartmentPutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/teamwork/dingtalkdepartment/put/{id}
     * @secure
     */
    webTeamworkDingtalkdepartmentPutUpdate: (id: string, data: CreateDingtalkDepartmentInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/teamwork/dingtalkdepartment/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags DingtalkDepartment
     * @name WebTeamworkDingtalkdepartmentDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/teamwork/dingtalkdepartment/delete/{id}
     * @secure
     */
    webTeamworkDingtalkdepartmentDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/teamwork/dingtalkdepartment/delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags DingtalkUser
     * @name WebTeamworkDingtalkuserGetpagePageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/teamwork/dingtalkuser/getpage/page
     * @secure
     */
    webTeamworkDingtalkuserGetpagePageCreate: (
      query?: {
        id?: string;
        /** 应用编号 */
        appId?: string;
        /** BitzOrcas UserId */
        relationUserId?: string;
        /** 用户名 */
        name?: string;
        /** 用户英文名称 */
        enName?: string;
        /** 昵称 */
        nickName?: string;
        /** 区域代码  +86 */
        stateCode?: string;
        /** 邮箱地址 */
        email?: string;
        /** 机构邮箱地址 */
        orgEmail?: string;
        /** 手机号 */
        mobile?: string;
        /** 是否显示手机号 */
        mobileVisible?: boolean;
        /** 分机号 */
        telephone?: string;
        /** 钉钉用户的open_id */
        openId?: string;
        /** 钉钉用户的union_id */
        unionId?: string;
        /** 钉钉用户的user_id */
        userId?: string;
        /**
         * 性别
         * @format int32
         */
        gender?: number;
        /** 用户头像信息 */
        avatar?: string;
        /** 员工工号 */
        jobNumber?: string;
        /** 员工的直属主管 */
        managerUserId?: string;
        /** 所属部门的Id列表 */
        departmentIds?: string;
        /** 员工在对应的部门中的排序 json：dept_id,order */
        deptOrderList?: string;
        /** 员工所在部门信息及是否是领导 json:dept_id,leader */
        leaderInDept?: string;
        /** 工作地点 */
        workPlace?: string;
        /** 入职时间 */
        hiredDate?: string;
        /** 职务 */
        title?: string;
        /** 是否为企业账号 */
        exclusiveAccount?: boolean;
        /** 扩展属性，最大长度2000个字符。 */
        extension?: string;
        /** 是否激活了钉钉 */
        active?: boolean;
        /** 是否完成了实名认证 */
        realAuthed?: boolean;
        /** 是否为企业高管 */
        senior?: boolean;
        /** 是否为企业的管理员 */
        admin?: boolean;
        /** 是否为企业的老板 */
        boss?: boolean;
        /** 租户ID */
        tenantId?: string;
        /** 部门ID  DepartmentId */
        organizationUnitId?: string;
        /** 分所Id */
        officeId?: string;
        /** 备注 */
        remark?: string;
        /** 状态 	            中立字段，某些表可使用某些表不使用 */
        isEnabled?: boolean;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<DingtalkUserDtoPageModelMessageModel, void>({
        path: `/api/web/teamwork/dingtalkuser/getpage/page`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags DingtalkUser
     * @name WebTeamworkDingtalkuserListListList
     * @summary 列表获取所有 (Auth policies: Web)
     * @request GET:/api/web/teamwork/dingtalkuser/list/list
     * @secure
     */
    webTeamworkDingtalkuserListListList: (
      query?: {
        id?: string;
        /** 应用编号 */
        appId?: string;
        /** BitzOrcas UserId */
        relationUserId?: string;
        /** 用户名 */
        name?: string;
        /** 用户英文名称 */
        enName?: string;
        /** 昵称 */
        nickName?: string;
        /** 区域代码  +86 */
        stateCode?: string;
        /** 邮箱地址 */
        email?: string;
        /** 机构邮箱地址 */
        orgEmail?: string;
        /** 手机号 */
        mobile?: string;
        /** 是否显示手机号 */
        mobileVisible?: boolean;
        /** 分机号 */
        telephone?: string;
        /** 钉钉用户的open_id */
        openId?: string;
        /** 钉钉用户的union_id */
        unionId?: string;
        /** 钉钉用户的user_id */
        userId?: string;
        /**
         * 性别
         * @format int32
         */
        gender?: number;
        /** 用户头像信息 */
        avatar?: string;
        /** 员工工号 */
        jobNumber?: string;
        /** 员工的直属主管 */
        managerUserId?: string;
        /** 所属部门的Id列表 */
        departmentIds?: string;
        /** 员工在对应的部门中的排序 json：dept_id,order */
        deptOrderList?: string;
        /** 员工所在部门信息及是否是领导 json:dept_id,leader */
        leaderInDept?: string;
        /** 工作地点 */
        workPlace?: string;
        /** 入职时间 */
        hiredDate?: string;
        /** 职务 */
        title?: string;
        /** 是否为企业账号 */
        exclusiveAccount?: boolean;
        /** 扩展属性，最大长度2000个字符。 */
        extension?: string;
        /** 是否激活了钉钉 */
        active?: boolean;
        /** 是否完成了实名认证 */
        realAuthed?: boolean;
        /** 是否为企业高管 */
        senior?: boolean;
        /** 是否为企业的管理员 */
        admin?: boolean;
        /** 是否为企业的老板 */
        boss?: boolean;
        /** 租户ID */
        tenantId?: string;
        /** 部门ID  DepartmentId */
        organizationUnitId?: string;
        /** 分所Id */
        officeId?: string;
        /** 备注 */
        remark?: string;
        /** 状态 	            中立字段，某些表可使用某些表不使用 */
        isEnabled?: boolean;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<DingtalkUserDtoListMessageModel, void>({
        path: `/api/web/teamwork/dingtalkuser/list/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags DingtalkUser
     * @name WebTeamworkDingtalkuserGetDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/teamwork/dingtalkuser/get/{id}
     * @secure
     */
    webTeamworkDingtalkuserGetDetail: (id: string, params: RequestParams = {}) =>
      this.request<DingtalkUserDtoMessageModel, void>({
        path: `/api/web/teamwork/dingtalkuser/get/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags DingtalkUser
     * @name WebTeamworkDingtalkuserPostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/teamwork/dingtalkuser/post
     * @secure
     */
    webTeamworkDingtalkuserPostCreate: (data: CreateDingtalkUserInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/teamwork/dingtalkuser/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags DingtalkUser
     * @name WebTeamworkDingtalkuserPutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/teamwork/dingtalkuser/put/{id}
     * @secure
     */
    webTeamworkDingtalkuserPutUpdate: (id: string, data: CreateDingtalkUserInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/teamwork/dingtalkuser/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags DingtalkUser
     * @name WebTeamworkDingtalkuserDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/teamwork/dingtalkuser/delete/{id}
     * @secure
     */
    webTeamworkDingtalkuserDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/teamwork/dingtalkuser/delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags DingtalkUser
     * @name WebTeamworkDingtalkuserDingtalkloginList
     * @summary 钉钉登录
     * @request GET:/api/web/teamwork/dingtalkuser/dingtalklogin
     */
    webTeamworkDingtalkuserDingtalkloginList: (
      query?: {
        /** 应用ID */
        appId?: string;
        /** 租户标识 */
        tenancyName?: string;
        /** Code */
        code?: string;
        /** 跳转链接 */
        redirectUrl?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/web/teamwork/dingtalkuser/dingtalklogin`,
        method: 'GET',
        query: query,
        ...params
      }),

    /**
     * No description
     *
     * @tags DingtalkUserRelation
     * @name WebTeamworkDingtalkuserrelationGetpagePageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/teamwork/dingtalkuserrelation/getpage/page
     * @secure
     */
    webTeamworkDingtalkuserrelationGetpagePageCreate: (
      query?: {
        id?: string;
        /** 律智荟 UserId */
        relationUserId?: string;
        /** 邮箱地址 */
        email?: string;
        /** 手机号 */
        mobile?: string;
        /** 钉钉用户的open_id */
        openId?: string;
        /** 钉钉用户的union_id */
        unionId?: string;
        /** 钉钉用户的user_id */
        userId?: string;
        /** 钉钉appid */
        appId?: string;
        /** 钉钉用户编号获取状态（N：未同步到，A：同步到其中任一Id） */
        syncStatus?: string;
        /** 租户ID */
        tenantId?: string;
        /** 部门ID  DepartmentId */
        organizationUnitId?: string;
        /** 分所Id */
        officeId?: string;
        /** 备注 */
        remark?: string;
        /** 状态 	            中立字段，某些表可使用某些表不使用 */
        isEnabled?: boolean;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<DingtalkUserRelationDtoPageModelMessageModel, void>({
        path: `/api/web/teamwork/dingtalkuserrelation/getpage/page`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags DingtalkUserRelation
     * @name WebTeamworkDingtalkuserrelationListListList
     * @summary 列表获取所有 (Auth policies: Web)
     * @request GET:/api/web/teamwork/dingtalkuserrelation/list/list
     * @secure
     */
    webTeamworkDingtalkuserrelationListListList: (
      query?: {
        id?: string;
        /** 律智荟 UserId */
        relationUserId?: string;
        /** 邮箱地址 */
        email?: string;
        /** 手机号 */
        mobile?: string;
        /** 钉钉用户的open_id */
        openId?: string;
        /** 钉钉用户的union_id */
        unionId?: string;
        /** 钉钉用户的user_id */
        userId?: string;
        /** 钉钉appid */
        appId?: string;
        /** 钉钉用户编号获取状态（N：未同步到，A：同步到其中任一Id） */
        syncStatus?: string;
        /** 租户ID */
        tenantId?: string;
        /** 部门ID  DepartmentId */
        organizationUnitId?: string;
        /** 分所Id */
        officeId?: string;
        /** 备注 */
        remark?: string;
        /** 状态 	            中立字段，某些表可使用某些表不使用 */
        isEnabled?: boolean;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<DingtalkUserRelationDtoListMessageModel, void>({
        path: `/api/web/teamwork/dingtalkuserrelation/list/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags DingtalkUserRelation
     * @name WebTeamworkDingtalkuserrelationGetDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/teamwork/dingtalkuserrelation/get/{id}
     * @secure
     */
    webTeamworkDingtalkuserrelationGetDetail: (id: string, params: RequestParams = {}) =>
      this.request<DingtalkUserRelationDtoMessageModel, void>({
        path: `/api/web/teamwork/dingtalkuserrelation/get/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags DingtalkUserRelation
     * @name WebTeamworkDingtalkuserrelationPostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/teamwork/dingtalkuserrelation/post
     * @secure
     */
    webTeamworkDingtalkuserrelationPostCreate: (data: CreateDingtalkUserRelationInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/teamwork/dingtalkuserrelation/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags DingtalkUserRelation
     * @name WebTeamworkDingtalkuserrelationPutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/teamwork/dingtalkuserrelation/put/{id}
     * @secure
     */
    webTeamworkDingtalkuserrelationPutUpdate: (id: string, data: CreateDingtalkUserRelationInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/teamwork/dingtalkuserrelation/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags DingtalkUserRelation
     * @name WebTeamworkDingtalkuserrelationDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/teamwork/dingtalkuserrelation/delete/{id}
     * @secure
     */
    webTeamworkDingtalkuserrelationDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/teamwork/dingtalkuserrelation/delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags DisplayDesensitization
     * @name WebSysDisplaydesensitizationListList
     * @summary 获取所有Dto规则 (Auth policies: Web)
     * @request GET:/api/web/sys/displaydesensitization/list
     * @secure
     */
    webSysDisplaydesensitizationListList: (params: RequestParams = {}) =>
      this.request<DisplayDesensitizationModuleListMessageModel, void>({
        path: `/api/web/sys/displaydesensitization/list`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags DisplayDesensitization
     * @name WebSysDisplaydesensitizationGetdisplaydesensitizationpagelistList
     * @summary 获取显示规则分页 (Auth policies: Web)
     * @request GET:/api/web/sys/displaydesensitization/getdisplaydesensitizationpagelist
     * @secure
     */
    webSysDisplaydesensitizationGetdisplaydesensitizationpagelistList: (
      query?: {
        /** 分组名称 */
        groupName?: string;
        /** 模块名称 */
        controllerName?: string;
        /** 方法名称 */
        actionName?: string;
        /** Dto名称 */
        dtoName?: string;
        /**
         * 配置层级
         * @format int32
         */
        settingLevel?: 0 | 1 | 2 | 3;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<DisplayDesensitizationOutputPageModelMessageModel, void>({
        path: `/api/web/sys/displaydesensitization/getdisplaydesensitizationpagelist`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags DisplayDesensitization
     * @name WebSysDisplaydesensitizationGetdisplaydesensitizationDetail
     * @summary 根据Id获取显示脱敏信息 (Auth policies: Web)
     * @request GET:/api/web/sys/displaydesensitization/getdisplaydesensitization/{id}
     * @secure
     */
    webSysDisplaydesensitizationGetdisplaydesensitizationDetail: (id: string, params: RequestParams = {}) =>
      this.request<DisplayDesensitizationOutputMessageModel, void>({
        path: `/api/web/sys/displaydesensitization/getdisplaydesensitization/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags DisplayDesensitization
     * @name WebSysDisplaydesensitizationGetdisplaydesensitizationforeditDetail
     * @summary 根据Id获取显示脱敏编辑信息 (Auth policies: Web)
     * @request GET:/api/web/sys/displaydesensitization/getdisplaydesensitizationforedit/{id}
     * @secure
     */
    webSysDisplaydesensitizationGetdisplaydesensitizationforeditDetail: (id: string, params: RequestParams = {}) =>
      this.request<CreateDisplayDesensitizationInputMessageModel, void>({
        path: `/api/web/sys/displaydesensitization/getdisplaydesensitizationforedit/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags DisplayDesensitization
     * @name WebSysDisplaydesensitizationCreateCreate
     * @summary 新增显示脱敏规则 (Auth policies: Web)
     * @request POST:/api/web/sys/displaydesensitization/create
     * @secure
     */
    webSysDisplaydesensitizationCreateCreate: (data: CreateDisplayDesensitizationInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/displaydesensitization/create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags DisplayDesensitization
     * @name WebSysDisplaydesensitizationUpdateUpdate
     * @summary 修改显示脱敏规则 (Auth policies: Web)
     * @request PUT:/api/web/sys/displaydesensitization/update
     * @secure
     */
    webSysDisplaydesensitizationUpdateUpdate: (
      data: CreateDisplayDesensitizationInput,
      query?: {
        id?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/displaydesensitization/update`,
        method: 'PUT',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags DisplayDesensitization
     * @name WebSysDisplaydesensitizationDeleteDelete
     * @summary 删除显示脱敏规则 (Auth policies: Web)
     * @request DELETE:/api/web/sys/displaydesensitization/delete/{id}
     * @secure
     */
    webSysDisplaydesensitizationDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/displaydesensitization/delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags DisplayDesensitization
     * @name WebSysDisplaydesensitizationGetdefaultrulelistList
     * @summary 获取默认规则设置 (Auth policies: Web)
     * @request GET:/api/web/sys/displaydesensitization/getdefaultrulelist
     * @secure
     */
    webSysDisplaydesensitizationGetdefaultrulelistList: (params: RequestParams = {}) =>
      this.request<DisplayDesensitizationDefaultRuleDtoListMessageModel, void>({
        path: `/api/web/sys/displaydesensitization/getdefaultrulelist`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags EmployeeRates
     * @name WebSysEmployeeEmployeeratesGetpagePageCreate
     * @summary 合伙人费率分页 (Auth policies: Web)
     * @request POST:/api/web/sys/employee/employeerates/getpage/page
     * @secure
     */
    webSysEmployeeEmployeeratesGetpagePageCreate: (data: GetEmployeeRateInput, params: RequestParams = {}) =>
      this.request<GetEmployeeRateOutputPageModelMessageModel, void>({
        path: `/api/web/sys/employee/employeerates/getpage/page`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags EmployeeRates
     * @name WebSysEmployeeEmployeeratesGetpageexportCreate
     * @summary 合伙人费率列表导出 (Auth policies: Web)
     * @request POST:/api/web/sys/employee/employeerates/getpageexport
     * @secure
     */
    webSysEmployeeEmployeeratesGetpageexportCreate: (data: GetEmployeeRateInputExportInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/sys/employee/employeerates/getpageexport`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags EmployeeRates
     * @name WebSysEmployeeEmployeeratesGetlistCreate
     * @summary 获取用户费率 (Auth policies: Web)
     * @request POST:/api/web/sys/employee/employeerates/getlist
     * @secure
     */
    webSysEmployeeEmployeeratesGetlistCreate: (data: StringEntityDto, params: RequestParams = {}) =>
      this.request<EmployeeRatesDtoListMessageModel, void>({
        path: `/api/web/sys/employee/employeerates/getlist`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags EmployeeRates
     * @name WebSysEmployeeEmployeeratesGetforeditCreate
     * @summary 获取用户费率编辑对象 (Auth policies: Web)
     * @request POST:/api/web/sys/employee/employeerates/getforedit/{id}
     * @secure
     */
    webSysEmployeeEmployeeratesGetforeditCreate: (id: string, params: RequestParams = {}) =>
      this.request<EmployeeRatesInputMessageModel, void>({
        path: `/api/web/sys/employee/employeerates/getforedit/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags EmployeeRates
     * @name WebSysEmployeeEmployeeratesPostCreate
     * @summary 保存用户费率 (Auth policies: Web)
     * @request POST:/api/web/sys/employee/employeerates/post
     * @secure
     */
    webSysEmployeeEmployeeratesPostCreate: (data: EmployeeRatesInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/sys/employee/employeerates/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags EmployeeRates
     * @name WebSysEmployeeEmployeeratesSyncemployeeratesList
     * @summary 手动同步CSV用户费率 (Auth policies: Web)
     * @request GET:/api/web/sys/employee/employeerates/syncemployeerates
     * @secure
     */
    webSysEmployeeEmployeeratesSyncemployeeratesList: (params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/employeerates/syncemployeerates`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags EnterpriseInfo
     * @name WebCustomerEnterpriseinfoGetpageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/customer/enterpriseinfo/getpage
     * @secure
     */
    webCustomerEnterpriseinfoGetpageCreate: (data: GetEnterpriseInfoInput, params: RequestParams = {}) =>
      this.request<EnterpriseInfoDtoPageModelMessageModel, void>({
        path: `/api/web/customer/enterpriseinfo/getpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags EnterpriseInfo
     * @name WebCustomerEnterpriseinfoGetCreate
     * @summary 获取详情 (Auth policies: Web)
     * @request POST:/api/web/customer/enterpriseinfo/get
     * @secure
     */
    webCustomerEnterpriseinfoGetCreate: (data: CreditCodeInput, params: RequestParams = {}) =>
      this.request<EnterpriseInfoDtoMessageModel, void>({
        path: `/api/web/customer/enterpriseinfo/get`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags EnterpriseInfo
     * @name WebCustomerEnterpriseinfoPostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/customer/enterpriseinfo/post
     * @secure
     */
    webCustomerEnterpriseinfoPostCreate: (data: CreateEnterpriseInfoInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/customer/enterpriseinfo/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags EnterpriseInfo
     * @name WebCustomerEnterpriseinfoPutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/customer/enterpriseinfo/put/{id}
     * @secure
     */
    webCustomerEnterpriseinfoPutUpdate: (id: string, data: CreateEnterpriseInfoInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/customer/enterpriseinfo/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags EnterpriseInfo
     * @name WebCustomerEnterpriseinfoDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/customer/enterpriseinfo/delete
     * @secure
     */
    webCustomerEnterpriseinfoDeleteDelete: (data: StringEntityDto, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/customer/enterpriseinfo/delete`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags EnterpriseInfo
     * @name WebCustomerEnterpriseinfoBatchdeleteCreate
     * @summary 批量删除 (Auth policies: Web)
     * @request POST:/api/web/customer/enterpriseinfo/batchdelete
     * @secure
     */
    webCustomerEnterpriseinfoBatchdeleteCreate: (data: StringEntityDto[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/customer/enterpriseinfo/batchdelete`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags EntityContact
     * @name WebCustomerEntitycontactGetpageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/customer/entitycontact/getpage
     * @secure
     */
    webCustomerEntitycontactGetpageCreate: (data: GetEntityContactInput, params: RequestParams = {}) =>
      this.request<EntityContactOutputPageModelMessageModel, void>({
        path: `/api/web/customer/entitycontact/getpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags EntityContact
     * @name WebCustomerEntitycontactListCreate
     * @summary 列表获取所有 (Auth policies: Web)
     * @request POST:/api/web/customer/entitycontact/list
     * @secure
     */
    webCustomerEntitycontactListCreate: (data: GetEntityContactInput, params: RequestParams = {}) =>
      this.request<EntityContactDtoListMessageModel, void>({
        path: `/api/web/customer/entitycontact/list`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags EntityContact
     * @name WebCustomerEntitycontactGetentitycontactinfoCreate
     * @summary 获取联系人详情 (Auth policies: Web)
     * @request POST:/api/web/customer/entitycontact/getentitycontactinfo
     * @secure
     */
    webCustomerEntitycontactGetentitycontactinfoCreate: (data: StringEntityDto, params: RequestParams = {}) =>
      this.request<EntityContactOutputMessageModel, void>({
        path: `/api/web/customer/entitycontact/getentitycontactinfo`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags EntityContact
     * @name WebCustomerEntitycontactGetentitycontactforeditCreate
     * @summary 获取联系人编辑对象 (Auth policies: Web)
     * @request POST:/api/web/customer/entitycontact/getentitycontactforedit
     * @secure
     */
    webCustomerEntitycontactGetentitycontactforeditCreate: (data: StringEntityDto, params: RequestParams = {}) =>
      this.request<CreateEntityContactInputMessageModel, void>({
        path: `/api/web/customer/entitycontact/getentitycontactforedit`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags EntityContact
     * @name WebCustomerEntitycontactPostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/customer/entitycontact/post
     * @secure
     */
    webCustomerEntitycontactPostCreate: (data: CreateEntityContactInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/customer/entitycontact/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags EntityContact
     * @name WebCustomerEntitycontactPutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/customer/entitycontact/put/{id}
     * @secure
     */
    webCustomerEntitycontactPutUpdate: (id: string, data: CreateEntityContactInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/customer/entitycontact/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags EntityContact
     * @name WebCustomerEntitycontactDeleteDelete
     * @summary 删除联系人 (Auth policies: Web)
     * @request DELETE:/api/web/customer/entitycontact/delete/{id}
     * @secure
     */
    webCustomerEntitycontactDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/customer/entitycontact/delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags EntityContactRelationship
     * @name WebCustomerEntitycontactrelationshipGetpagePageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/customer/entitycontactrelationship/getpage/page
     * @secure
     */
    webCustomerEntitycontactrelationshipGetpagePageCreate: (
      query?: {
        id?: string;
        /** 联系人编号 */
        contactId?: string;
        /** 客户编号 */
        clientId?: string;
        /** 案件编号 */
        caseId?: string;
        /** 说明描述 */
        narrative?: string;
        /** 租户ID */
        tenantId?: string;
        /** 部门ID  DepartmentId */
        organizationUnitId?: string;
        /** 分所Id */
        officeId?: string;
        /** 备注 */
        remark?: string;
        /** 状态 	                中立字段，某些表可使用某些表不使用 */
        isEnabled?: boolean;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<EntityContactRelationshipDtoPageModelMessageModel, void>({
        path: `/api/web/customer/entitycontactrelationship/getpage/page`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags EntityContactRelationship
     * @name WebCustomerEntitycontactrelationshipListListList
     * @summary 列表获取所有 (Auth policies: Web)
     * @request GET:/api/web/customer/entitycontactrelationship/list/list
     * @secure
     */
    webCustomerEntitycontactrelationshipListListList: (
      query?: {
        id?: string;
        /** 联系人编号 */
        contactId?: string;
        /** 客户编号 */
        clientId?: string;
        /** 案件编号 */
        caseId?: string;
        /** 说明描述 */
        narrative?: string;
        /** 租户ID */
        tenantId?: string;
        /** 部门ID  DepartmentId */
        organizationUnitId?: string;
        /** 分所Id */
        officeId?: string;
        /** 备注 */
        remark?: string;
        /** 状态 	                中立字段，某些表可使用某些表不使用 */
        isEnabled?: boolean;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<EntityContactRelationshipDtoListMessageModel, void>({
        path: `/api/web/customer/entitycontactrelationship/list/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags EntityContactRelationship
     * @name WebCustomerEntitycontactrelationshipGetDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/customer/entitycontactrelationship/get/{id}
     * @secure
     */
    webCustomerEntitycontactrelationshipGetDetail: (id: string, params: RequestParams = {}) =>
      this.request<EntityContactRelationshipDtoMessageModel, void>({
        path: `/api/web/customer/entitycontactrelationship/get/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags EntityContactRelationship
     * @name WebCustomerEntitycontactrelationshipPostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/customer/entitycontactrelationship/post
     * @secure
     */
    webCustomerEntitycontactrelationshipPostCreate: (data: CreateEntityContactRelationshipInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/customer/entitycontactrelationship/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags EntityContactRelationship
     * @name WebCustomerEntitycontactrelationshipPutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/customer/entitycontactrelationship/put/{id}
     * @secure
     */
    webCustomerEntitycontactrelationshipPutUpdate: (id: string, data: CreateEntityContactRelationshipInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/customer/entitycontactrelationship/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags EntityContactRelationship
     * @name WebCustomerEntitycontactrelationshipDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/customer/entitycontactrelationship/delete/{id}
     * @secure
     */
    webCustomerEntitycontactrelationshipDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/customer/entitycontactrelationship/delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags EntityFormerName
     * @name WebCustomerEntityformernameGetpagePageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/customer/entityformername/getpage/page
     * @secure
     */
    webCustomerEntityformernameGetpagePageCreate: (
      query?: {
        /** 实体编号 */
        entityId?: string;
        /** 曾用名 */
        name?: string;
        /** 外文曾用名称 */
        foreignName?: string;
        /**
         * 名称使用开始时间
         * @format date-time
         */
        beginDate?: string;
        /**
         * 名称使用结束时间
         * @format date-time
         */
        endDate?: string;
        /** 记录来源 */
        loadSource?: string;
        /** 说明描述 */
        narrative?: string;
        /** 实体类别（客户/联系人/股东/实控人/母公司等） */
        entityType?: string;
        /** 租户ID */
        tenantId?: string;
        /** 部门ID  DepartmentId */
        organizationUnitId?: string;
        /** 分所Id */
        officeId?: string;
        /** 备注 */
        remark?: string;
        /** 状态 	                中立字段，某些表可使用某些表不使用 */
        isEnabled?: boolean;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<EntityFormerNameDtoPageModelMessageModel, void>({
        path: `/api/web/customer/entityformername/getpage/page`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags EntityFormerName
     * @name WebCustomerEntityformernameListListList
     * @summary 列表获取所有 (Auth policies: Web)
     * @request GET:/api/web/customer/entityformername/list/list
     * @secure
     */
    webCustomerEntityformernameListListList: (
      query?: {
        /** 实体编号 */
        entityId?: string;
        /** 曾用名 */
        name?: string;
        /** 外文曾用名称 */
        foreignName?: string;
        /**
         * 名称使用开始时间
         * @format date-time
         */
        beginDate?: string;
        /**
         * 名称使用结束时间
         * @format date-time
         */
        endDate?: string;
        /** 记录来源 */
        loadSource?: string;
        /** 说明描述 */
        narrative?: string;
        /** 实体类别（客户/联系人/股东/实控人/母公司等） */
        entityType?: string;
        /** 租户ID */
        tenantId?: string;
        /** 部门ID  DepartmentId */
        organizationUnitId?: string;
        /** 分所Id */
        officeId?: string;
        /** 备注 */
        remark?: string;
        /** 状态 	                中立字段，某些表可使用某些表不使用 */
        isEnabled?: boolean;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<EntityFormerNameDtoListMessageModel, void>({
        path: `/api/web/customer/entityformername/list/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags EntityFormerName
     * @name WebCustomerEntityformernameGetDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/customer/entityformername/get/{id}
     * @secure
     */
    webCustomerEntityformernameGetDetail: (id: string, params: RequestParams = {}) =>
      this.request<EntityFormerNameDtoMessageModel, void>({
        path: `/api/web/customer/entityformername/get/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags EntityFormerName
     * @name WebCustomerEntityformernamePostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/customer/entityformername/post
     * @secure
     */
    webCustomerEntityformernamePostCreate: (data: CreateEntityFormerNameInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/customer/entityformername/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags EntityFormerName
     * @name WebCustomerEntityformernamePutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/customer/entityformername/put/{id}
     * @secure
     */
    webCustomerEntityformernamePutUpdate: (id: string, data: CreateEntityFormerNameInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/customer/entityformername/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags EntityFormerName
     * @name WebCustomerEntityformernameDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/customer/entityformername/delete/{id}
     * @secure
     */
    webCustomerEntityformernameDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/customer/entityformername/delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags EntityInfo
     * @name WebCustomerEntityinfoGetentitynamesCreate
     * @summary 模糊搜索实体名称 (Auth policies: Web)
     * @request POST:/api/web/customer/entityinfo/getentitynames
     * @secure
     */
    webCustomerEntityinfoGetentitynamesCreate: (data: GetKeywordInput, params: RequestParams = {}) =>
      this.request<EntityNameSearchDtoListMessageModel, void>({
        path: `/api/web/customer/entityinfo/getentitynames`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags EntityInfo
     * @name WebCustomerEntityinfoGetentityinfowithnameCreate
     * @summary 根据名称获取实体信息 (Auth policies: Web)
     * @request POST:/api/web/customer/entityinfo/getentityinfowithname
     * @secure
     */
    webCustomerEntityinfoGetentityinfowithnameCreate: (data: GetEntityInfoWithNameInput, params: RequestParams = {}) =>
      this.request<GetEntityBaseInfoOutputMessageModel, void>({
        path: `/api/web/customer/entityinfo/getentityinfowithname`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags EntityInfo
     * @name WebCustomerEntityinfoGetentitybaseinfowithnameCreate
     * @summary 根据名称获取工商照面信息 (Auth policies: Web)
     * @request POST:/api/web/customer/entityinfo/getentitybaseinfowithname
     * @secure
     */
    webCustomerEntityinfoGetentitybaseinfowithnameCreate: (data: GetKeywordInput, params: RequestParams = {}) =>
      this.request<GetEntityBaseInfoOutputMessageModel, void>({
        path: `/api/web/customer/entityinfo/getentitybaseinfowithname`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags EntityInfo
     * @name WebCustomerEntityinfoGetentitypartnersandactualcontrolpersonswithidornameCreate
     * @summary 获取股东和实控人信息 (Auth policies: Web)
     * @request POST:/api/web/customer/entityinfo/getentitypartnersandactualcontrolpersonswithidorname
     * @secure
     */
    webCustomerEntityinfoGetentitypartnersandactualcontrolpersonswithidornameCreate: (data: GetKeywordInput, params: RequestParams = {}) =>
      this.request<GetEntityPartnerAndActualControlPersonOutputListMessageModel, void>({
        path: `/api/web/customer/entityinfo/getentitypartnersandactualcontrolpersonswithidorname`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags EntityInfo
     * @name WebCustomerEntityinfoGetentitybankinfowithkeywordCreate
     * @summary 获取开户行账号信息 (Auth policies: Web)
     * @request POST:/api/web/customer/entityinfo/getentitybankinfowithkeyword
     * @secure
     */
    webCustomerEntityinfoGetentitybankinfowithkeywordCreate: (data: GetKeywordInput, params: RequestParams = {}) =>
      this.request<EntityBankInfoDtoListMessageModel, void>({
        path: `/api/web/customer/entityinfo/getentitybankinfowithkeyword`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags EntityInfo
     * @name WebCustomerEntityinfoGetoppositeentityinfoexportCreate
     * @summary 对方列表导出 (Auth policies: Web)
     * @request POST:/api/web/customer/entityinfo/getoppositeentityinfoexport
     * @secure
     */
    webCustomerEntityinfoGetoppositeentityinfoexportCreate: (data: GetOppositeEntityInfoInputExportInput, params: RequestParams = {}) =>
      this.request<ExcelFileDtoMessageModel, void>({
        path: `/api/web/customer/entityinfo/getoppositeentityinfoexport`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags EntityInfo
     * @name WebCustomerEntityinfoGetparticipatingentityinfoexportCreate
     * @summary 参与实体列表导出 (Auth policies: Web)
     * @request POST:/api/web/customer/entityinfo/getparticipatingentityinfoexport
     * @secure
     */
    webCustomerEntityinfoGetparticipatingentityinfoexportCreate: (data: GetParticipatingEntityInfoInputExportInput, params: RequestParams = {}) =>
      this.request<ExcelFileDtoMessageModel, void>({
        path: `/api/web/customer/entityinfo/getparticipatingentityinfoexport`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags EntityInfo
     * @name WebCustomerEntityinfoGetpageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/customer/entityinfo/getpage
     * @secure
     */
    webCustomerEntityinfoGetpageCreate: (data: GetEntityInfoInput, params: RequestParams = {}) =>
      this.request<GetEntityInfoOutputPageModelMessageModel, void>({
        path: `/api/web/customer/entityinfo/getpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags EntityInfo
     * @name WebCustomerEntityinfoGetoppositeentityinfopageCreate
     * @summary 对方实体分页列表 (Auth policies: Web)
     * @request POST:/api/web/customer/entityinfo/getoppositeentityinfopage
     * @secure
     */
    webCustomerEntityinfoGetoppositeentityinfopageCreate: (data: GetOppositeEntityInfoInput, params: RequestParams = {}) =>
      this.request<GetEntityInfoOutputPageModelMessageModel, void>({
        path: `/api/web/customer/entityinfo/getoppositeentityinfopage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags EntityInfo
     * @name WebCustomerEntityinfoGetparticipatingentityinfopageCreate
     * @summary 参与实体分页列表 (Auth policies: Web)
     * @request POST:/api/web/customer/entityinfo/getparticipatingentityinfopage
     * @secure
     */
    webCustomerEntityinfoGetparticipatingentityinfopageCreate: (data: GetParticipatingEntityInfoInput, params: RequestParams = {}) =>
      this.request<GetEntityInfoOutputPageModelMessageModel, void>({
        path: `/api/web/customer/entityinfo/getparticipatingentityinfopage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags EntityInfo
     * @name WebCustomerEntityinfoGetforeditCreate
     * @summary 获取修改实体 (Auth policies: Web)
     * @request POST:/api/web/customer/entityinfo/getforedit/{id}
     * @secure
     */
    webCustomerEntityinfoGetforeditCreate: (id: string, params: RequestParams = {}) =>
      this.request<GetEntityInfoForEditOutputForEditModelMessageModel, void>({
        path: `/api/web/customer/entityinfo/getforedit/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags EntityInfo
     * @name WebCustomerEntityinfoPostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/customer/entityinfo/post
     * @secure
     */
    webCustomerEntityinfoPostCreate: (data: CreateEntityInfoInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/customer/entityinfo/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags EntityInfo
     * @name WebCustomerEntityinfoPutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/customer/entityinfo/put/{id}
     * @secure
     */
    webCustomerEntityinfoPutUpdate: (id: string, data: CreateEntityInfoInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/customer/entityinfo/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags EntityInfo
     * @name WebCustomerEntityinfoDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/customer/entityinfo/delete
     * @secure
     */
    webCustomerEntityinfoDeleteDelete: (data: StringEntityDto, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/customer/entityinfo/delete`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags EntityInfo
     * @name WebCustomerEntityinfoBatchdeleteCreate
     * @summary 批量删除 (Auth policies: Web)
     * @request POST:/api/web/customer/entityinfo/batchdelete
     * @secure
     */
    webCustomerEntityinfoBatchdeleteCreate: (data: StringEntityDto[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/customer/entityinfo/batchdelete`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags EntityInvoiceTitle
     * @name WebCustomerEntityinvoicetitleGetentityinvoicetitlepageCreate
     * @summary 模糊搜索实体名称-分页 (Auth policies: Web)
     * @request POST:/api/web/customer/entityinvoicetitle/getentityinvoicetitlepage
     * @secure
     */
    webCustomerEntityinvoicetitleGetentityinvoicetitlepageCreate: (data: GetEntityInvoiceTitleInput, params: RequestParams = {}) =>
      this.request<EntityInvoiceTitleDtoPageModelMessageModel, void>({
        path: `/api/web/customer/entityinvoicetitle/getentityinvoicetitlepage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags EntityInvoiceTitle
     * @name WebCustomerEntityinvoicetitleGetlatestentityinvoicetitleList
     * @summary 获取最新的发票抬头 (Auth policies: Web)
     * @request GET:/api/web/customer/entityinvoicetitle/getlatestentityinvoicetitle
     * @secure
     */
    webCustomerEntityinvoicetitleGetlatestentityinvoicetitleList: (
      query?: {
        clientId?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<EntityInvoiceTitleDtoMessageModel, void>({
        path: `/api/web/customer/entityinvoicetitle/getlatestentityinvoicetitle`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags EntityInvoiceTitle
     * @name WebCustomerEntityinvoicetitleListCreate
     * @summary 列表获取所有 (Auth policies: Web)
     * @request POST:/api/web/customer/entityinvoicetitle/list
     * @secure
     */
    webCustomerEntityinvoicetitleListCreate: (data: GetEntityInvoiceTitleInput, params: RequestParams = {}) =>
      this.request<GetEntityInvoiceTitleOutputListMessageModel, void>({
        path: `/api/web/customer/entityinvoicetitle/list`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags EntityInvoiceTitle
     * @name WebCustomerEntityinvoicetitleGetpageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/customer/entityinvoicetitle/getpage
     * @secure
     */
    webCustomerEntityinvoicetitleGetpageCreate: (data: GetEntityInvoiceTitleInput, params: RequestParams = {}) =>
      this.request<GetEntityInvoiceTitleOutputPageModelMessageModel, void>({
        path: `/api/web/customer/entityinvoicetitle/getpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags EntityInvoiceTitle
     * @name WebCustomerEntityinvoicetitleGetCreate
     * @summary 获取详情 (Auth policies: Web)
     * @request POST:/api/web/customer/entityinvoicetitle/get/{id}
     * @secure
     */
    webCustomerEntityinvoicetitleGetCreate: (id: string, params: RequestParams = {}) =>
      this.request<EntityInvoiceTitleDtoMessageModel, void>({
        path: `/api/web/customer/entityinvoicetitle/get/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags EntityInvoiceTitle
     * @name WebCustomerEntityinvoicetitleGetforeditDetail
     * @summary 获取修改实体 (Auth policies: Web)
     * @request GET:/api/web/customer/entityinvoicetitle/getforedit/{id}
     * @secure
     */
    webCustomerEntityinvoicetitleGetforeditDetail: (id: string, params: RequestParams = {}) =>
      this.request<GetEntityInvoiceTitleForEditOutputForEditModelMessageModel, void>({
        path: `/api/web/customer/entityinvoicetitle/getforedit/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags EntityInvoiceTitle
     * @name WebCustomerEntityinvoicetitlePostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/customer/entityinvoicetitle/post
     * @secure
     */
    webCustomerEntityinvoicetitlePostCreate: (data: CreateEntityInvoiceTitleInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/customer/entityinvoicetitle/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags EntityInvoiceTitle
     * @name WebCustomerEntityinvoicetitlePutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/customer/entityinvoicetitle/put/{id}
     * @secure
     */
    webCustomerEntityinvoicetitlePutUpdate: (id: string, data: CreateEntityInvoiceTitleInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/customer/entityinvoicetitle/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags EntityInvoiceTitle
     * @name WebCustomerEntityinvoicetitleDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/customer/entityinvoicetitle/delete/{id}
     * @secure
     */
    webCustomerEntityinvoicetitleDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/customer/entityinvoicetitle/delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags EntityInvoiceTitle
     * @name WebCustomerEntityinvoicetitleBatchdeleteCreate
     * @summary 批量删除 (Auth policies: Web)
     * @request POST:/api/web/customer/entityinvoicetitle/batchdelete
     * @secure
     */
    webCustomerEntityinvoicetitleBatchdeleteCreate: (data: StringEntityDto[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/customer/entityinvoicetitle/batchdelete`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocation
     * @name WebFinancialAllocationFinancialallocationGetpageCreate
     * @summary 已分配列表分页 (Auth policies: Web)
     * @request POST:/api/web/financial/allocation/financialallocation/getpage
     * @secure
     */
    webFinancialAllocationFinancialallocationGetpageCreate: (data: GetFinancialAllocationInput, params: RequestParams = {}) =>
      this.request<GetFinancialAllocationOutputPageModelMessageModel, void>({
        path: `/api/web/financial/allocation/financialallocation/getpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocation
     * @name WebFinancialAllocationFinancialallocationListCreate
     * @summary 列表获取所有 (Auth policies: Web)
     * @request POST:/api/web/financial/allocation/financialallocation/list
     * @secure
     */
    webFinancialAllocationFinancialallocationListCreate: (
      query?: {
        /** 关键字 */
        filter?: string;
        /** 账单编号 */
        billingSerialId?: string;
        /** 付款方名称 */
        paymentName?: string;
        /** 案件编号 */
        caseSerialId?: string;
        /** 案件名称 */
        caseName?: string;
        /**
         * 开始日期时间
         * @format date-time
         */
        receiptDateStartDate?: string;
        /**
         * 结束日期时间
         * @format date-time
         */
        receiptDateEndDate?: string;
        /**
         * 开始日期时间
         * @format date-time
         */
        allocationDateStartDate?: string;
        /**
         * 结束日期时间
         * @format date-time
         */
        allocationDateEndDate?: string;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<FinancialAllocationDtoListMessageModel, void>({
        path: `/api/web/financial/allocation/financialallocation/list`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocation
     * @name WebFinancialAllocationFinancialallocationGetfinancialallocationexportCreate
     * @summary 已分配列表导出 (Auth policies: Web)
     * @request POST:/api/web/financial/allocation/financialallocation/getfinancialallocationexport
     * @secure
     */
    webFinancialAllocationFinancialallocationGetfinancialallocationexportCreate: (data: GetFinancialAllocationInputExportInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/financial/allocation/financialallocation/getfinancialallocationexport`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocation
     * @name WebFinancialAllocationFinancialallocationGetselectCreate
     * @summary 选择框 (Auth policies: Web)
     * @request POST:/api/web/financial/allocation/financialallocation/getselect
     * @secure
     */
    webFinancialAllocationFinancialallocationGetselectCreate: (data: GetOrganizationUnitsInput, params: RequestParams = {}) =>
      this.request<ComboboxItemDtoListMessageModel, void>({
        path: `/api/web/financial/allocation/financialallocation/getselect`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocation
     * @name WebFinancialAllocationFinancialallocationGetCreate
     * @summary 获取详情 (Auth policies: Web)
     * @request POST:/api/web/financial/allocation/financialallocation/get/{id}
     * @secure
     */
    webFinancialAllocationFinancialallocationGetCreate: (id: string, params: RequestParams = {}) =>
      this.request<FinancialAllocationDtoMessageModel, void>({
        path: `/api/web/financial/allocation/financialallocation/get/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocation
     * @name WebFinancialAllocationFinancialallocationGetforeditCreate
     * @summary 获取修改实体 (Auth policies: Web)
     * @request POST:/api/web/financial/allocation/financialallocation/getforedit/{id}
     * @secure
     */
    webFinancialAllocationFinancialallocationGetforeditCreate: (id: string, params: RequestParams = {}) =>
      this.request<GetFinancialAllocationForEditOutputForEditModelMessageModel, void>({
        path: `/api/web/financial/allocation/financialallocation/getforedit/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocation
     * @name WebFinancialAllocationFinancialallocationPostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/financial/allocation/financialallocation/post
     * @secure
     */
    webFinancialAllocationFinancialallocationPostCreate: (data: CreateFinancialAllocationInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/allocation/financialallocation/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocation
     * @name WebFinancialAllocationFinancialallocationPutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/financial/allocation/financialallocation/put/{id}
     * @secure
     */
    webFinancialAllocationFinancialallocationPutUpdate: (id: string, data: CreateFinancialAllocationInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/allocation/financialallocation/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocation
     * @name WebFinancialAllocationFinancialallocationDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/financial/allocation/financialallocation/delete/{id}
     * @secure
     */
    webFinancialAllocationFinancialallocationDeleteDelete: (id: string, data: StringEntityDto, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/allocation/financialallocation/delete/${id}`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocation
     * @name WebFinancialAllocationFinancialallocationBatchdeleteCreate
     * @summary 批量删除 (Auth policies: Web)
     * @request POST:/api/web/financial/allocation/financialallocation/batchdelete
     * @secure
     */
    webFinancialAllocationFinancialallocationBatchdeleteCreate: (data: StringEntityDto[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/allocation/financialallocation/batchdelete`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocation
     * @name WebFinancialAllocationFinancialallocationAllocationnullifyCreate
     * @summary 分配作废 (Auth policies: Web)
     * @request POST:/api/web/financial/allocation/financialallocation/allocationnullify
     * @secure
     */
    webFinancialAllocationFinancialallocationAllocationnullifyCreate: (data: ApprovalInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/financial/allocation/financialallocation/allocationnullify`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocation
     * @name WebFinancialAllocationFinancialallocationAllocationcloneCreate
     * @summary 分配克隆 (Auth policies: Web)
     * @request POST:/api/web/financial/allocation/financialallocation/allocationclone
     * @secure
     */
    webFinancialAllocationFinancialallocationAllocationcloneCreate: (data: StringEntityDto, params: RequestParams = {}) =>
      this.request<GetFinancialAllocationForEditOutputForEditModelMessageModel, void>({
        path: `/api/web/financial/allocation/financialallocation/allocationclone`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocationItem
     * @name WebFinancialAllocationFinancialallocationitemGetpageCreate
     * @summary 我的分配分页 (Auth policies: Web)
     * @request POST:/api/web/financial/allocation/financialallocationitem/getpage
     * @secure
     */
    webFinancialAllocationFinancialallocationitemGetpageCreate: (data: GetFinancialAllocationItemInput, params: RequestParams = {}) =>
      this.request<GetFinancialAllocationItemOutputPageModelMessageModel, void>({
        path: `/api/web/financial/allocation/financialallocationitem/getpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocationItem
     * @name WebFinancialAllocationFinancialallocationitemListCreate
     * @summary 列表获取所有 (Auth policies: Web)
     * @request POST:/api/web/financial/allocation/financialallocationitem/list
     * @secure
     */
    webFinancialAllocationFinancialallocationitemListCreate: (
      query?: {
        /** 关键字 */
        filter?: string;
        /** 账单编号 */
        billingSerialId?: string;
        /** 付款方名称 */
        paymentName?: string;
        /** 案件编号 */
        caseSerialId?: string;
        /** 案件名称 */
        caseName?: string;
        /**
         * 开始日期时间
         * @format date-time
         */
        receiptDateStartDate?: string;
        /**
         * 结束日期时间
         * @format date-time
         */
        receiptDateEndDate?: string;
        /**
         * 开始日期时间
         * @format date-time
         */
        allocationDateStartDate?: string;
        /**
         * 结束日期时间
         * @format date-time
         */
        allocationDateEndDate?: string;
        /**
         * 最小金额
         * @format double
         */
        amountMinValue?: number;
        /**
         * 最大金额
         * @format double
         */
        amountMaxValue?: number;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<FinancialAllocationItemDtoListMessageModel, void>({
        path: `/api/web/financial/allocation/financialallocationitem/list`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocationItem
     * @name WebFinancialAllocationFinancialallocationitemGetfinancialallocationitemexportCreate
     * @summary 我的分配导出 (Auth policies: Web)
     * @request POST:/api/web/financial/allocation/financialallocationitem/getfinancialallocationitemexport
     * @secure
     */
    webFinancialAllocationFinancialallocationitemGetfinancialallocationitemexportCreate: (
      data: GetFinancialAllocationItemInputExportInput,
      params: RequestParams = {}
    ) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/financial/allocation/financialallocationitem/getfinancialallocationitemexport`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocationItem
     * @name WebFinancialAllocationFinancialallocationitemGetselectCreate
     * @summary 选择框 (Auth policies: Web)
     * @request POST:/api/web/financial/allocation/financialallocationitem/getselect
     * @secure
     */
    webFinancialAllocationFinancialallocationitemGetselectCreate: (data: GetOrganizationUnitsInput, params: RequestParams = {}) =>
      this.request<ComboboxItemDtoListMessageModel, void>({
        path: `/api/web/financial/allocation/financialallocationitem/getselect`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocationItem
     * @name WebFinancialAllocationFinancialallocationitemGetCreate
     * @summary 获取详情 (Auth policies: Web)
     * @request POST:/api/web/financial/allocation/financialallocationitem/get/{id}
     * @secure
     */
    webFinancialAllocationFinancialallocationitemGetCreate: (id: string, params: RequestParams = {}) =>
      this.request<FinancialAllocationItemDtoMessageModel, void>({
        path: `/api/web/financial/allocation/financialallocationitem/get/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocationItem
     * @name WebFinancialAllocationFinancialallocationitemGetforeditCreate
     * @summary 获取修改实体 (Auth policies: Web)
     * @request POST:/api/web/financial/allocation/financialallocationitem/getforedit/{id}
     * @secure
     */
    webFinancialAllocationFinancialallocationitemGetforeditCreate: (id: string, params: RequestParams = {}) =>
      this.request<CreateFinancialAllocationItemInputMessageModel, void>({
        path: `/api/web/financial/allocation/financialallocationitem/getforedit/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocationItem
     * @name WebFinancialAllocationFinancialallocationitemPostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/financial/allocation/financialallocationitem/post
     * @secure
     */
    webFinancialAllocationFinancialallocationitemPostCreate: (data: CreateFinancialAllocationItemInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/allocation/financialallocationitem/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocationItem
     * @name WebFinancialAllocationFinancialallocationitemPutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/financial/allocation/financialallocationitem/put/{id}
     * @secure
     */
    webFinancialAllocationFinancialallocationitemPutUpdate: (id: string, data: CreateFinancialAllocationItemInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/allocation/financialallocationitem/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocationItem
     * @name WebFinancialAllocationFinancialallocationitemDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/financial/allocation/financialallocationitem/delete/{id}
     * @secure
     */
    webFinancialAllocationFinancialallocationitemDeleteDelete: (id: string, data: StringEntityDto, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/allocation/financialallocationitem/delete/${id}`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocationItem
     * @name WebFinancialAllocationFinancialallocationitemBatchdeleteCreate
     * @summary 批量删除 (Auth policies: Web)
     * @request POST:/api/web/financial/allocation/financialallocationitem/batchdelete
     * @secure
     */
    webFinancialAllocationFinancialallocationitemBatchdeleteCreate: (data: StringEntityDto[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/allocation/financialallocationitem/batchdelete`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocationItem
     * @name WebFinancialAllocationFinancialallocationitemAllocationapproveCreate
     * @summary 分配确认通过 (Auth policies: Web)
     * @request POST:/api/web/financial/allocation/financialallocationitem/allocationapprove
     * @secure
     */
    webFinancialAllocationFinancialallocationitemAllocationapproveCreate: (data: ApprovalInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/financial/allocation/financialallocationitem/allocationapprove`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocationItem
     * @name WebFinancialAllocationFinancialallocationitemAllocationreturnCreate
     * @summary 分配确认退回 (Auth policies: Web)
     * @request POST:/api/web/financial/allocation/financialallocationitem/allocationreturn
     * @secure
     */
    webFinancialAllocationFinancialallocationitemAllocationreturnCreate: (data: ApprovalInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/financial/allocation/financialallocationitem/allocationreturn`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocationItemHistory
     * @name WebFinancialAllocationFinancialallocationitemhistoryGetpageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/financial/allocation/financialallocationitemhistory/getpage
     * @secure
     */
    webFinancialAllocationFinancialallocationitemhistoryGetpageCreate: (data: GetFinancialAllocationItemHistoryInput, params: RequestParams = {}) =>
      this.request<FinancialAllocationItemHistoryDtoPageModelMessageModel, void>({
        path: `/api/web/financial/allocation/financialallocationitemhistory/getpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocationItemHistory
     * @name WebFinancialAllocationFinancialallocationitemhistoryListCreate
     * @summary 列表获取所有 (Auth policies: Web)
     * @request POST:/api/web/financial/allocation/financialallocationitemhistory/list
     * @secure
     */
    webFinancialAllocationFinancialallocationitemhistoryListCreate: (
      query?: {
        /** 分配编号 */
        allocationId?: string;
        /** 收款编号 */
        receiptId?: string;
        /** 被分配人 */
        employeeId?: string;
        /**
         * 开始日期时间
         * @format date-time
         */
        allocationDateStartDate?: string;
        /**
         * 结束日期时间
         * @format date-time
         */
        allocationDateEndDate?: string;
        /**
         * 分配金额
         * @format double
         */
        amount?: number;
        /**
         * 分配比例
         * @format double
         */
        allocationRate?: number;
        /**
         * 尾差
         * @format double
         */
        roundingDifference?: number;
        /**
         * 薪酬金额
         * @format double
         */
        remunerationAmount?: number;
        /**
         * 薪酬比例
         * @format double
         */
        remunerationRate?: number;
        /**
         * 案源费比例
         * @format double
         */
        caseSourceRate?: number;
        /**
         * 案源费分配金额
         * @format double
         */
        caseSourceAmount?: number;
        /** 是否是案源费 */
        isSourceFee?: string;
        /** 参案角色 */
        lawyerRole?: string;
        /**
         * 分配基数
         * @format double
         */
        allocationBase?: number;
        /** 备注 */
        remark?: string;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<FinancialAllocationItemHistoryDtoListMessageModel, void>({
        path: `/api/web/financial/allocation/financialallocationitemhistory/list`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocationItemHistory
     * @name WebFinancialAllocationFinancialallocationitemhistoryGetfinancialallocationitemhistoryexportCreate
     * @summary 列表导出 (Auth policies: Web)
     * @request POST:/api/web/financial/allocation/financialallocationitemhistory/getfinancialallocationitemhistoryexport
     * @secure
     */
    webFinancialAllocationFinancialallocationitemhistoryGetfinancialallocationitemhistoryexportCreate: (
      data: GetFinancialAllocationItemHistoryInputExportInput,
      params: RequestParams = {}
    ) =>
      this.request<ExcelFileDtoMessageModel, void>({
        path: `/api/web/financial/allocation/financialallocationitemhistory/getfinancialallocationitemhistoryexport`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocationItemHistory
     * @name WebFinancialAllocationFinancialallocationitemhistoryGetselectCreate
     * @summary 选择框 (Auth policies: Web)
     * @request POST:/api/web/financial/allocation/financialallocationitemhistory/getselect
     * @secure
     */
    webFinancialAllocationFinancialallocationitemhistoryGetselectCreate: (data: GetOrganizationUnitsInput, params: RequestParams = {}) =>
      this.request<ComboboxItemDtoListMessageModel, void>({
        path: `/api/web/financial/allocation/financialallocationitemhistory/getselect`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocationItemHistory
     * @name WebFinancialAllocationFinancialallocationitemhistoryGetCreate
     * @summary 获取详情 (Auth policies: Web)
     * @request POST:/api/web/financial/allocation/financialallocationitemhistory/get/{id}
     * @secure
     */
    webFinancialAllocationFinancialallocationitemhistoryGetCreate: (id: string, params: RequestParams = {}) =>
      this.request<FinancialAllocationItemHistoryDtoMessageModel, void>({
        path: `/api/web/financial/allocation/financialallocationitemhistory/get/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocationItemHistory
     * @name WebFinancialAllocationFinancialallocationitemhistoryGetforeditCreate
     * @summary 获取修改实体 (Auth policies: Web)
     * @request POST:/api/web/financial/allocation/financialallocationitemhistory/getforedit/{id}
     * @secure
     */
    webFinancialAllocationFinancialallocationitemhistoryGetforeditCreate: (id: string, params: RequestParams = {}) =>
      this.request<CreateFinancialAllocationItemHistoryInputMessageModel, void>({
        path: `/api/web/financial/allocation/financialallocationitemhistory/getforedit/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocationItemHistory
     * @name WebFinancialAllocationFinancialallocationitemhistoryPostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/financial/allocation/financialallocationitemhistory/post
     * @secure
     */
    webFinancialAllocationFinancialallocationitemhistoryPostCreate: (data: CreateFinancialAllocationItemHistoryInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/allocation/financialallocationitemhistory/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocationItemHistory
     * @name WebFinancialAllocationFinancialallocationitemhistoryPutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/financial/allocation/financialallocationitemhistory/put/{id}
     * @secure
     */
    webFinancialAllocationFinancialallocationitemhistoryPutUpdate: (id: string, data: CreateFinancialAllocationItemHistoryInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/allocation/financialallocationitemhistory/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocationItemHistory
     * @name WebFinancialAllocationFinancialallocationitemhistoryDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/financial/allocation/financialallocationitemhistory/delete/{id}
     * @secure
     */
    webFinancialAllocationFinancialallocationitemhistoryDeleteDelete: (id: string, data: StringEntityDto, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/allocation/financialallocationitemhistory/delete/${id}`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocationItemHistory
     * @name WebFinancialAllocationFinancialallocationitemhistoryBatchdeleteCreate
     * @summary 批量删除 (Auth policies: Web)
     * @request POST:/api/web/financial/allocation/financialallocationitemhistory/batchdelete
     * @secure
     */
    webFinancialAllocationFinancialallocationitemhistoryBatchdeleteCreate: (data: StringEntityDto[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/allocation/financialallocationitemhistory/batchdelete`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocationItemHistory
     * @name WebFinancialAllocationFinancialallocationitemhistoryGetallocationhistoryCreate
     * @summary 获取分配历史记录 (Auth policies: Web)
     * @request POST:/api/web/financial/allocation/financialallocationitemhistory/getallocationhistory
     * @secure
     */
    webFinancialAllocationFinancialallocationitemhistoryGetallocationhistoryCreate: (data: GetAllocationHistoryInput, params: RequestParams = {}) =>
      this.request<GetAllocationHistoryOutputListMessageModel, void>({
        path: `/api/web/financial/allocation/financialallocationitemhistory/getallocationhistory`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocationRateCaseSetting
     * @name WebFinancialAllocationFinancialallocationratecasesettingGetpageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/financial/allocation/financialallocationratecasesetting/getpage
     * @secure
     */
    webFinancialAllocationFinancialallocationratecasesettingGetpageCreate: (data: GetFinancialAllocationRateCaseSettingInput, params: RequestParams = {}) =>
      this.request<GetFinancialAllocationRateCaseSettingOutputPageModelMessageModel, void>({
        path: `/api/web/financial/allocation/financialallocationratecasesetting/getpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocationRateCaseSetting
     * @name WebFinancialAllocationFinancialallocationratecasesettingListCreate
     * @summary 列表获取所有 (Auth policies: Web)
     * @request POST:/api/web/financial/allocation/financialallocationratecasesetting/list
     * @secure
     */
    webFinancialAllocationFinancialallocationratecasesettingListCreate: (data: GetFinancialAllocationRateCaseSettingInput, params: RequestParams = {}) =>
      this.request<GetFinancialAllocationRateCaseSettingOutputListMessageModel, void>({
        path: `/api/web/financial/allocation/financialallocationratecasesetting/list`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocationRateCaseSetting
     * @name WebFinancialAllocationFinancialallocationratecasesettingGetCreate
     * @summary 获取详情 (Auth policies: Web)
     * @request POST:/api/web/financial/allocation/financialallocationratecasesetting/get/{id}
     * @secure
     */
    webFinancialAllocationFinancialallocationratecasesettingGetCreate: (id: string, params: RequestParams = {}) =>
      this.request<FinancialAllocationRateCaseSettingDtoMessageModel, void>({
        path: `/api/web/financial/allocation/financialallocationratecasesetting/get/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocationRateCaseSetting
     * @name WebFinancialAllocationFinancialallocationratecasesettingGetforeditCreate
     * @summary 获取修改实体 (Auth policies: Web)
     * @request POST:/api/web/financial/allocation/financialallocationratecasesetting/getforedit/{id}
     * @secure
     */
    webFinancialAllocationFinancialallocationratecasesettingGetforeditCreate: (id: string, params: RequestParams = {}) =>
      this.request<GetFinancialAllocationRateCaseSettingForEditOutputForEditModelMessageModel, void>({
        path: `/api/web/financial/allocation/financialallocationratecasesetting/getforedit/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocationRateCaseSetting
     * @name WebFinancialAllocationFinancialallocationratecasesettingPostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/financial/allocation/financialallocationratecasesetting/post
     * @secure
     */
    webFinancialAllocationFinancialallocationratecasesettingPostCreate: (data: CreateFinancialAllocationRateCaseSettingInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/allocation/financialallocationratecasesetting/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocationRateCaseSetting
     * @name WebFinancialAllocationFinancialallocationratecasesettingPutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/financial/allocation/financialallocationratecasesetting/put/{id}
     * @secure
     */
    webFinancialAllocationFinancialallocationratecasesettingPutUpdate: (
      id: string,
      data: CreateFinancialAllocationRateCaseSettingInput,
      params: RequestParams = {}
    ) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/allocation/financialallocationratecasesetting/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocationRateCaseSetting
     * @name WebFinancialAllocationFinancialallocationratecasesettingDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/financial/allocation/financialallocationratecasesetting/delete
     * @secure
     */
    webFinancialAllocationFinancialallocationratecasesettingDeleteDelete: (data: StringEntityDto, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/allocation/financialallocationratecasesetting/delete`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocationRateCaseSetting
     * @name WebFinancialAllocationFinancialallocationratecasesettingBatchdeleteCreate
     * @summary 批量删除 (Auth policies: Web)
     * @request POST:/api/web/financial/allocation/financialallocationratecasesetting/batchdelete
     * @secure
     */
    webFinancialAllocationFinancialallocationratecasesettingBatchdeleteCreate: (data: StringEntityDto[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/allocation/financialallocationratecasesetting/batchdelete`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocationRateCaseSetting
     * @name WebFinancialAllocationFinancialallocationratecasesettingCreateorupdatefinancialallocationrateCreate
     * @summary 新增或修改案件分配策略 (Auth policies: Web)
     * @request POST:/api/web/financial/allocation/financialallocationratecasesetting/createorupdatefinancialallocationrate
     * @secure
     */
    webFinancialAllocationFinancialallocationratecasesettingCreateorupdatefinancialallocationrateCreate: (
      data: CreateFinancialAllocationRateCaseSettingInput,
      params: RequestParams = {}
    ) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/allocation/financialallocationratecasesetting/createorupdatefinancialallocationrate`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocationRateCaseSettingItem
     * @name WebFinancialAllocationFinancialallocationratecasesettingitemGetpageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/financial/allocation/financialallocationratecasesettingitem/getpage
     * @secure
     */
    webFinancialAllocationFinancialallocationratecasesettingitemGetpageCreate: (
      data: GetFinancialAllocationRateCaseSettingItemInput,
      params: RequestParams = {}
    ) =>
      this.request<GetFinancialAllocationRateCaseSettingItemOutputPageModelMessageModel, void>({
        path: `/api/web/financial/allocation/financialallocationratecasesettingitem/getpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocationRateCaseSettingItem
     * @name WebFinancialAllocationFinancialallocationratecasesettingitemListCreate
     * @summary 列表获取所有 (Auth policies: Web)
     * @request POST:/api/web/financial/allocation/financialallocationratecasesettingitem/list
     * @secure
     */
    webFinancialAllocationFinancialallocationratecasesettingitemListCreate: (
      data: GetFinancialAllocationRateCaseSettingItemInput,
      params: RequestParams = {}
    ) =>
      this.request<GetFinancialAllocationRateCaseSettingItemOutputListMessageModel, void>({
        path: `/api/web/financial/allocation/financialallocationratecasesettingitem/list`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocationRateCaseSettingItem
     * @name WebFinancialAllocationFinancialallocationratecasesettingitemGetCreate
     * @summary 获取详情 (Auth policies: Web)
     * @request POST:/api/web/financial/allocation/financialallocationratecasesettingitem/get/{id}
     * @secure
     */
    webFinancialAllocationFinancialallocationratecasesettingitemGetCreate: (id: string, params: RequestParams = {}) =>
      this.request<FinancialAllocationRateCaseSettingItemDtoMessageModel, void>({
        path: `/api/web/financial/allocation/financialallocationratecasesettingitem/get/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocationRateCaseSettingItem
     * @name WebFinancialAllocationFinancialallocationratecasesettingitemGetforeditCreate
     * @summary 获取修改实体 (Auth policies: Web)
     * @request POST:/api/web/financial/allocation/financialallocationratecasesettingitem/getforedit/{id}
     * @secure
     */
    webFinancialAllocationFinancialallocationratecasesettingitemGetforeditCreate: (id: string, params: RequestParams = {}) =>
      this.request<GetFinancialAllocationRateCaseSettingItemForEditOutputForEditModelMessageModel, void>({
        path: `/api/web/financial/allocation/financialallocationratecasesettingitem/getforedit/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocationRateCaseSettingItem
     * @name WebFinancialAllocationFinancialallocationratecasesettingitemPostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/financial/allocation/financialallocationratecasesettingitem/post
     * @secure
     */
    webFinancialAllocationFinancialallocationratecasesettingitemPostCreate: (
      data: CreateFinancialAllocationRateCaseSettingItemInput,
      params: RequestParams = {}
    ) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/allocation/financialallocationratecasesettingitem/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocationRateCaseSettingItem
     * @name WebFinancialAllocationFinancialallocationratecasesettingitemPutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/financial/allocation/financialallocationratecasesettingitem/put/{id}
     * @secure
     */
    webFinancialAllocationFinancialallocationratecasesettingitemPutUpdate: (
      id: string,
      data: CreateFinancialAllocationRateCaseSettingItemInput,
      params: RequestParams = {}
    ) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/allocation/financialallocationratecasesettingitem/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocationRateCaseSettingItem
     * @name WebFinancialAllocationFinancialallocationratecasesettingitemDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/financial/allocation/financialallocationratecasesettingitem/delete
     * @secure
     */
    webFinancialAllocationFinancialallocationratecasesettingitemDeleteDelete: (data: StringEntityDto, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/allocation/financialallocationratecasesettingitem/delete`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocationRateCaseSettingItem
     * @name WebFinancialAllocationFinancialallocationratecasesettingitemBatchdeleteCreate
     * @summary 批量删除 (Auth policies: Web)
     * @request POST:/api/web/financial/allocation/financialallocationratecasesettingitem/batchdelete
     * @secure
     */
    webFinancialAllocationFinancialallocationratecasesettingitemBatchdeleteCreate: (data: StringEntityDto[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/allocation/financialallocationratecasesettingitem/batchdelete`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocationRateSetting
     * @name WebFinancialAllocationFinancialallocationratesettingGetpageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/financial/allocation/financialallocationratesetting/getpage
     * @secure
     */
    webFinancialAllocationFinancialallocationratesettingGetpageCreate: (data: GetFinancialAllocationRateSettingInput, params: RequestParams = {}) =>
      this.request<GetFinancialAllocationRateSettingOutputPageModelMessageModel, void>({
        path: `/api/web/financial/allocation/financialallocationratesetting/getpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocationRateSetting
     * @name WebFinancialAllocationFinancialallocationratesettingListCreate
     * @summary 列表获取所有 (Auth policies: Web)
     * @request POST:/api/web/financial/allocation/financialallocationratesetting/list
     * @secure
     */
    webFinancialAllocationFinancialallocationratesettingListCreate: (data: GetFinancialAllocationRateSettingInput, params: RequestParams = {}) =>
      this.request<GetFinancialAllocationRateSettingOutputListMessageModel, void>({
        path: `/api/web/financial/allocation/financialallocationratesetting/list`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocationRateSetting
     * @name WebFinancialAllocationFinancialallocationratesettingGetfinancialallocationratesettingexportCreate
     * @summary 列表导出 (Auth policies: Web)
     * @request POST:/api/web/financial/allocation/financialallocationratesetting/getfinancialallocationratesettingexport
     * @secure
     */
    webFinancialAllocationFinancialallocationratesettingGetfinancialallocationratesettingexportCreate: (
      data: GetFinancialAllocationRateSettingInputExportInput,
      params: RequestParams = {}
    ) =>
      this.request<ExcelFileDtoMessageModel, void>({
        path: `/api/web/financial/allocation/financialallocationratesetting/getfinancialallocationratesettingexport`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocationRateSetting
     * @name WebFinancialAllocationFinancialallocationratesettingGetCreate
     * @summary 获取详情 (Auth policies: Web)
     * @request POST:/api/web/financial/allocation/financialallocationratesetting/get/{id}
     * @secure
     */
    webFinancialAllocationFinancialallocationratesettingGetCreate: (id: string, params: RequestParams = {}) =>
      this.request<FinancialAllocationRateSettingDtoMessageModel, void>({
        path: `/api/web/financial/allocation/financialallocationratesetting/get/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocationRateSetting
     * @name WebFinancialAllocationFinancialallocationratesettingGetforeditCreate
     * @summary 获取修改实体 (Auth policies: Web)
     * @request POST:/api/web/financial/allocation/financialallocationratesetting/getforedit/{id}
     * @secure
     */
    webFinancialAllocationFinancialallocationratesettingGetforeditCreate: (id: string, params: RequestParams = {}) =>
      this.request<GetFinancialAllocationRateSettingForEditOutputForEditModelMessageModel, void>({
        path: `/api/web/financial/allocation/financialallocationratesetting/getforedit/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocationRateSetting
     * @name WebFinancialAllocationFinancialallocationratesettingPostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/financial/allocation/financialallocationratesetting/post
     * @secure
     */
    webFinancialAllocationFinancialallocationratesettingPostCreate: (data: CreateFinancialAllocationRateSettingInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/allocation/financialallocationratesetting/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocationRateSetting
     * @name WebFinancialAllocationFinancialallocationratesettingPutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/financial/allocation/financialallocationratesetting/put/{id}
     * @secure
     */
    webFinancialAllocationFinancialallocationratesettingPutUpdate: (id: string, data: CreateFinancialAllocationRateSettingInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/allocation/financialallocationratesetting/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocationRateSetting
     * @name WebFinancialAllocationFinancialallocationratesettingDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/financial/allocation/financialallocationratesetting/delete
     * @secure
     */
    webFinancialAllocationFinancialallocationratesettingDeleteDelete: (data: StringEntityDto, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/allocation/financialallocationratesetting/delete`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocationRateSetting
     * @name WebFinancialAllocationFinancialallocationratesettingBatchdeleteCreate
     * @summary 批量删除 (Auth policies: Web)
     * @request POST:/api/web/financial/allocation/financialallocationratesetting/batchdelete
     * @secure
     */
    webFinancialAllocationFinancialallocationratesettingBatchdeleteCreate: (data: StringEntityDto[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/allocation/financialallocationratesetting/batchdelete`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocationRateSetting
     * @name WebFinancialAllocationFinancialallocationratesettingCreateorupdatefinancialallocationrateCreate
     * @summary 添加或修改分配策略 (Auth policies: Web)
     * @request POST:/api/web/financial/allocation/financialallocationratesetting/createorupdatefinancialallocationrate
     * @secure
     */
    webFinancialAllocationFinancialallocationratesettingCreateorupdatefinancialallocationrateCreate: (
      data: CreateFinancialAllocationRateSettingInput,
      params: RequestParams = {}
    ) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/allocation/financialallocationratesetting/createorupdatefinancialallocationrate`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocationRateSetting
     * @name WebFinancialAllocationFinancialallocationratesettingCreateorupdatepartnerallocationrateCreate
     * @summary 添加或修改合伙人分配策略 (Auth policies: Web)
     * @request POST:/api/web/financial/allocation/financialallocationratesetting/createorupdatepartnerallocationrate
     * @secure
     */
    webFinancialAllocationFinancialallocationratesettingCreateorupdatepartnerallocationrateCreate: (
      data: CreateFinancialAllocationRateSettingInput,
      params: RequestParams = {}
    ) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/allocation/financialallocationratesetting/createorupdatepartnerallocationrate`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocationRateSetting
     * @name WebFinancialAllocationFinancialallocationratesettingGetallocationratesettingpageCreate
     * @summary 获取分配策略分页 (Auth policies: Web)
     * @request POST:/api/web/financial/allocation/financialallocationratesetting/getallocationratesettingpage
     * @secure
     */
    webFinancialAllocationFinancialallocationratesettingGetallocationratesettingpageCreate: (
      data: GetFinancialAllocationRateSettingInput,
      params: RequestParams = {}
    ) =>
      this.request<GetFinancialAllocationRateSettingOutputPageModelMessageModel, void>({
        path: `/api/web/financial/allocation/financialallocationratesetting/getallocationratesettingpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocationRateSetting
     * @name WebFinancialAllocationFinancialallocationratesettingGetpartnerallocationratesettingpageCreate
     * @summary 获取合伙人分配策略分页 (Auth policies: Web)
     * @request POST:/api/web/financial/allocation/financialallocationratesetting/getpartnerallocationratesettingpage
     * @secure
     */
    webFinancialAllocationFinancialallocationratesettingGetpartnerallocationratesettingpageCreate: (
      data: GetFinancialAllocationRateSettingInput,
      params: RequestParams = {}
    ) =>
      this.request<GetFinancialAllocationRateSettingOutputPageModelMessageModel, void>({
        path: `/api/web/financial/allocation/financialallocationratesetting/getpartnerallocationratesettingpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocationRateSetting
     * @name WebFinancialAllocationFinancialallocationratesettingSetfinancialallocationratedefaultCreate
     * @summary 设置默认策略 (Auth policies: Web)
     * @request POST:/api/web/financial/allocation/financialallocationratesetting/setfinancialallocationratedefault
     * @secure
     */
    webFinancialAllocationFinancialallocationratesettingSetfinancialallocationratedefaultCreate: (data: StringEntityDto, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/financial/allocation/financialallocationratesetting/setfinancialallocationratedefault`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocationRateSettingItem
     * @name WebFinancialAllocationFinancialallocationratesettingitemGetpageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/financial/allocation/financialallocationratesettingitem/getpage
     * @secure
     */
    webFinancialAllocationFinancialallocationratesettingitemGetpageCreate: (data: GetFinancialAllocationRateSettingItemInput, params: RequestParams = {}) =>
      this.request<GetFinancialAllocationRateSettingItemOutputPageModelMessageModel, void>({
        path: `/api/web/financial/allocation/financialallocationratesettingitem/getpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocationRateSettingItem
     * @name WebFinancialAllocationFinancialallocationratesettingitemListCreate
     * @summary 列表获取所有 (Auth policies: Web)
     * @request POST:/api/web/financial/allocation/financialallocationratesettingitem/list
     * @secure
     */
    webFinancialAllocationFinancialallocationratesettingitemListCreate: (data: GetFinancialAllocationRateSettingItemInput, params: RequestParams = {}) =>
      this.request<GetFinancialAllocationRateSettingItemOutputListMessageModel, void>({
        path: `/api/web/financial/allocation/financialallocationratesettingitem/list`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocationRateSettingItem
     * @name WebFinancialAllocationFinancialallocationratesettingitemGetfinancialallocationratesettingitemexportCreate
     * @summary 列表导出 (Auth policies: Web)
     * @request POST:/api/web/financial/allocation/financialallocationratesettingitem/getfinancialallocationratesettingitemexport
     * @secure
     */
    webFinancialAllocationFinancialallocationratesettingitemGetfinancialallocationratesettingitemexportCreate: (
      data: GetFinancialAllocationRateSettingItemInputExportInput,
      params: RequestParams = {}
    ) =>
      this.request<ExcelFileDtoMessageModel, void>({
        path: `/api/web/financial/allocation/financialallocationratesettingitem/getfinancialallocationratesettingitemexport`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocationRateSettingItem
     * @name WebFinancialAllocationFinancialallocationratesettingitemGetCreate
     * @summary 获取详情 (Auth policies: Web)
     * @request POST:/api/web/financial/allocation/financialallocationratesettingitem/get/{id}
     * @secure
     */
    webFinancialAllocationFinancialallocationratesettingitemGetCreate: (id: string, params: RequestParams = {}) =>
      this.request<FinancialAllocationRateSettingItemDtoMessageModel, void>({
        path: `/api/web/financial/allocation/financialallocationratesettingitem/get/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocationRateSettingItem
     * @name WebFinancialAllocationFinancialallocationratesettingitemGetforeditCreate
     * @summary 获取修改实体 (Auth policies: Web)
     * @request POST:/api/web/financial/allocation/financialallocationratesettingitem/getforedit/{id}
     * @secure
     */
    webFinancialAllocationFinancialallocationratesettingitemGetforeditCreate: (id: string, params: RequestParams = {}) =>
      this.request<GetFinancialAllocationRateSettingItemForEditOutputForEditModelMessageModel, void>({
        path: `/api/web/financial/allocation/financialallocationratesettingitem/getforedit/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocationRateSettingItem
     * @name WebFinancialAllocationFinancialallocationratesettingitemPostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/financial/allocation/financialallocationratesettingitem/post
     * @secure
     */
    webFinancialAllocationFinancialallocationratesettingitemPostCreate: (data: CreateFinancialAllocationRateSettingItemInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/allocation/financialallocationratesettingitem/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocationRateSettingItem
     * @name WebFinancialAllocationFinancialallocationratesettingitemPutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/financial/allocation/financialallocationratesettingitem/put/{id}
     * @secure
     */
    webFinancialAllocationFinancialallocationratesettingitemPutUpdate: (
      id: string,
      data: CreateFinancialAllocationRateSettingItemInput,
      params: RequestParams = {}
    ) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/allocation/financialallocationratesettingitem/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocationRateSettingItem
     * @name WebFinancialAllocationFinancialallocationratesettingitemDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/financial/allocation/financialallocationratesettingitem/delete
     * @secure
     */
    webFinancialAllocationFinancialallocationratesettingitemDeleteDelete: (data: StringEntityDto, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/allocation/financialallocationratesettingitem/delete`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocationRateSettingItem
     * @name WebFinancialAllocationFinancialallocationratesettingitemBatchdeleteCreate
     * @summary 批量删除 (Auth policies: Web)
     * @request POST:/api/web/financial/allocation/financialallocationratesettingitem/batchdelete
     * @secure
     */
    webFinancialAllocationFinancialallocationratesettingitemBatchdeleteCreate: (data: StringEntityDto[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/allocation/financialallocationratesettingitem/batchdelete`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocationRateSettingItemHistory
     * @name WebFinancialAllocationFinancialallocationratesettingitemhistoryGetpageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/financial/allocation/financialallocationratesettingitemhistory/getpage
     * @secure
     */
    webFinancialAllocationFinancialallocationratesettingitemhistoryGetpageCreate: (
      data: GetFinancialAllocationRateSettingItemHistoryInput,
      params: RequestParams = {}
    ) =>
      this.request<GetFinancialAllocationRateSettingItemHistoryOutputPageModelMessageModel, void>({
        path: `/api/web/financial/allocation/financialallocationratesettingitemhistory/getpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocationRateSettingItemHistory
     * @name WebFinancialAllocationFinancialallocationratesettingitemhistoryListCreate
     * @summary 列表获取所有 (Auth policies: Web)
     * @request POST:/api/web/financial/allocation/financialallocationratesettingitemhistory/list
     * @secure
     */
    webFinancialAllocationFinancialallocationratesettingitemhistoryListCreate: (
      data: GetFinancialAllocationRateSettingItemHistoryInput,
      params: RequestParams = {}
    ) =>
      this.request<GetFinancialAllocationRateSettingItemHistoryOutputListMessageModel, void>({
        path: `/api/web/financial/allocation/financialallocationratesettingitemhistory/list`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocationRateSettingItemHistory
     * @name WebFinancialAllocationFinancialallocationratesettingitemhistoryGetfinancialallocationratesettingitemhistoryexportCreate
     * @summary 列表导出 (Auth policies: Web)
     * @request POST:/api/web/financial/allocation/financialallocationratesettingitemhistory/getfinancialallocationratesettingitemhistoryexport
     * @secure
     */
    webFinancialAllocationFinancialallocationratesettingitemhistoryGetfinancialallocationratesettingitemhistoryexportCreate: (
      data: GetFinancialAllocationRateSettingItemHistoryInputExportInput,
      params: RequestParams = {}
    ) =>
      this.request<ExcelFileDtoMessageModel, void>({
        path: `/api/web/financial/allocation/financialallocationratesettingitemhistory/getfinancialallocationratesettingitemhistoryexport`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocationRateSettingItemHistory
     * @name WebFinancialAllocationFinancialallocationratesettingitemhistoryGetCreate
     * @summary 获取详情 (Auth policies: Web)
     * @request POST:/api/web/financial/allocation/financialallocationratesettingitemhistory/get/{id}
     * @secure
     */
    webFinancialAllocationFinancialallocationratesettingitemhistoryGetCreate: (id: string, params: RequestParams = {}) =>
      this.request<FinancialAllocationRateSettingItemHistoryDtoMessageModel, void>({
        path: `/api/web/financial/allocation/financialallocationratesettingitemhistory/get/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocationRateSettingItemHistory
     * @name WebFinancialAllocationFinancialallocationratesettingitemhistoryGetforeditCreate
     * @summary 获取修改实体 (Auth policies: Web)
     * @request POST:/api/web/financial/allocation/financialallocationratesettingitemhistory/getforedit/{id}
     * @secure
     */
    webFinancialAllocationFinancialallocationratesettingitemhistoryGetforeditCreate: (id: string, params: RequestParams = {}) =>
      this.request<GetFinancialAllocationRateSettingItemHistoryForEditOutputForEditModelMessageModel, void>({
        path: `/api/web/financial/allocation/financialallocationratesettingitemhistory/getforedit/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocationRateSettingItemHistory
     * @name WebFinancialAllocationFinancialallocationratesettingitemhistoryPostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/financial/allocation/financialallocationratesettingitemhistory/post
     * @secure
     */
    webFinancialAllocationFinancialallocationratesettingitemhistoryPostCreate: (
      data: CreateFinancialAllocationRateSettingItemHistoryInput,
      params: RequestParams = {}
    ) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/allocation/financialallocationratesettingitemhistory/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocationRateSettingItemHistory
     * @name WebFinancialAllocationFinancialallocationratesettingitemhistoryPutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/financial/allocation/financialallocationratesettingitemhistory/put/{id}
     * @secure
     */
    webFinancialAllocationFinancialallocationratesettingitemhistoryPutUpdate: (
      id: string,
      data: CreateFinancialAllocationRateSettingItemHistoryInput,
      params: RequestParams = {}
    ) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/allocation/financialallocationratesettingitemhistory/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocationRateSettingItemHistory
     * @name WebFinancialAllocationFinancialallocationratesettingitemhistoryDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/financial/allocation/financialallocationratesettingitemhistory/delete
     * @secure
     */
    webFinancialAllocationFinancialallocationratesettingitemhistoryDeleteDelete: (data: StringEntityDto, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/allocation/financialallocationratesettingitemhistory/delete`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialAllocationRateSettingItemHistory
     * @name WebFinancialAllocationFinancialallocationratesettingitemhistoryBatchdeleteCreate
     * @summary 批量删除 (Auth policies: Web)
     * @request POST:/api/web/financial/allocation/financialallocationratesettingitemhistory/batchdelete
     * @secure
     */
    webFinancialAllocationFinancialallocationratesettingitemhistoryBatchdeleteCreate: (data: StringEntityDto[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/allocation/financialallocationratesettingitemhistory/batchdelete`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBilling
     * @name WebFinancialBillingFinancialbillingGetpageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbilling/getpage
     * @secure
     */
    webFinancialBillingFinancialbillingGetpageCreate: (data: GetFinancialBillingInput, params: RequestParams = {}) =>
      this.request<GetFinancialBillingOutputPageModelMessageModel, void>({
        path: `/api/web/financial/billing/financialbilling/getpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBilling
     * @name WebFinancialBillingFinancialbillingGetmypageCreate
     * @summary 我的账单分页 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbilling/getmypage
     * @secure
     */
    webFinancialBillingFinancialbillingGetmypageCreate: (data: GetFinancialBillingInput, params: RequestParams = {}) =>
      this.request<GetFinancialBillingOutputPageModelMessageModel, void>({
        path: `/api/web/financial/billing/financialbilling/getmypage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBilling
     * @name WebFinancialBillingFinancialbillingGetcrossborderpageCreate
     * @summary 获取跨境账单分页列表 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbilling/getcrossborderpage
     * @secure
     */
    webFinancialBillingFinancialbillingGetcrossborderpageCreate: (data: GetCrossBorderBillingInput, params: RequestParams = {}) =>
      this.request<GetFinancialBillingOutputPageModelMessageModel, void>({
        path: `/api/web/financial/billing/financialbilling/getcrossborderpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBilling
     * @name WebFinancialBillingFinancialbillingGetreceivablepageCreate
     * @summary 应收账单分页 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbilling/getreceivablepage
     * @secure
     */
    webFinancialBillingFinancialbillingGetreceivablepageCreate: (data: GetFinancialBillingReceivableInput, params: RequestParams = {}) =>
      this.request<GetFinancialBillingOutputPageModelMessageModel, void>({
        path: `/api/web/financial/billing/financialbilling/getreceivablepage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBilling
     * @name WebFinancialBillingFinancialbillingGetnobillingcasepageCreate
     * @summary 未出账案件分页列表接口 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbilling/getnobillingcasepage
     * @secure
     */
    webFinancialBillingFinancialbillingGetnobillingcasepageCreate: (data: GetNoBillingCaseInput, params: RequestParams = {}) =>
      this.request<GetNoBillingCaseOutputPageModelMessageModel, void>({
        path: `/api/web/financial/billing/financialbilling/getnobillingcasepage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBilling
     * @name WebFinancialBillingFinancialbillingGetfinancialbillingauditpageCreate
     * @summary 账单申请审核分页 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbilling/getfinancialbillingauditpage
     * @secure
     */
    webFinancialBillingFinancialbillingGetfinancialbillingauditpageCreate: (data: GetFinancialBillingAuditInput, params: RequestParams = {}) =>
      this.request<GetFinancialBillingOutputPageModelMessageModel, void>({
        path: `/api/web/financial/billing/financialbilling/getfinancialbillingauditpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBilling
     * @name WebFinancialBillingFinancialbillingListCreate
     * @summary 列表获取所有 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbilling/list
     * @secure
     */
    webFinancialBillingFinancialbillingListCreate: (data: GetFinancialBillingInput, params: RequestParams = {}) =>
      this.request<FinancialBillingDtoListMessageModel, void>({
        path: `/api/web/financial/billing/financialbilling/list`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBilling
     * @name WebFinancialBillingFinancialbillingGetjointandsubbillingCreate
     * @summary 联合账单及其子账单列表接口 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbilling/getjointandsubbilling
     * @secure
     */
    webFinancialBillingFinancialbillingGetjointandsubbillingCreate: (data: StringEntityDto, params: RequestParams = {}) =>
      this.request<GetFinancialBillingOutputListMessageModel, void>({
        path: `/api/web/financial/billing/financialbilling/getjointandsubbilling`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBilling
     * @name WebFinancialBillingFinancialbillingGetmyfinancialbillingexportCreate
     * @summary 列表导出 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbilling/getmyfinancialbillingexport
     * @secure
     */
    webFinancialBillingFinancialbillingGetmyfinancialbillingexportCreate: (data: GetFinancialBillingInputExportInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/financial/billing/financialbilling/getmyfinancialbillingexport`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBilling
     * @name WebFinancialBillingFinancialbillingGetfinancialbillingexportCreate
     * @summary 列表导出 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbilling/getfinancialbillingexport
     * @secure
     */
    webFinancialBillingFinancialbillingGetfinancialbillingexportCreate: (data: GetFinancialBillingInputExportInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/financial/billing/financialbilling/getfinancialbillingexport`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBilling
     * @name WebFinancialBillingFinancialbillingGetnobillingcaseexportCreate
     * @summary 未出工时案件列表导出 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbilling/getnobillingcaseexport
     * @secure
     */
    webFinancialBillingFinancialbillingGetnobillingcaseexportCreate: (data: GetFinancialBillingInputExportInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/financial/billing/financialbilling/getnobillingcaseexport`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBilling
     * @name WebFinancialBillingFinancialbillingGetfinancialbillingauditexportCreate
     * @summary 账单申请审核列表导出 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbilling/getfinancialbillingauditexport
     * @secure
     */
    webFinancialBillingFinancialbillingGetfinancialbillingauditexportCreate: (data: GetFinancialBillingAuditInputExportInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/financial/billing/financialbilling/getfinancialbillingauditexport`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBilling
     * @name WebFinancialBillingFinancialbillingGetselectCreate
     * @summary 选择框 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbilling/getselect
     * @secure
     */
    webFinancialBillingFinancialbillingGetselectCreate: (data: GetOrganizationUnitsInput, params: RequestParams = {}) =>
      this.request<ComboboxItemDtoListMessageModel, void>({
        path: `/api/web/financial/billing/financialbilling/getselect`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBilling
     * @name WebFinancialBillingFinancialbillingBatchwriteoffbillingitemsCreate
     * @summary 账单子项批量销账 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbilling/batchwriteoffbillingitems
     * @secure
     */
    webFinancialBillingFinancialbillingBatchwriteoffbillingitemsCreate: (data: GetBatchWriteOffInput, params: RequestParams = {}) =>
      this.request<BooleanMessageModel, void>({
        path: `/api/web/financial/billing/financialbilling/batchwriteoffbillingitems`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBilling
     * @name WebFinancialBillingFinancialbillingBatchchangebillingitemstaxrateCreate
     * @summary 账单子项批量设置税率 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbilling/batchchangebillingitemstaxrate
     * @secure
     */
    webFinancialBillingFinancialbillingBatchchangebillingitemstaxrateCreate: (data: GetBatchChangeTaxRateInput, params: RequestParams = {}) =>
      this.request<BooleanMessageModel, void>({
        path: `/api/web/financial/billing/financialbilling/batchchangebillingitemstaxrate`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBilling
     * @name WebFinancialBillingFinancialbillingBatchchangebillingitemsdateCreate
     * @summary 账单子项批量设置日期 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbilling/batchchangebillingitemsdate
     * @secure
     */
    webFinancialBillingFinancialbillingBatchchangebillingitemsdateCreate: (data: GetBatchChangeDateInput, params: RequestParams = {}) =>
      this.request<BooleanMessageModel, void>({
        path: `/api/web/financial/billing/financialbilling/batchchangebillingitemsdate`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBilling
     * @name WebFinancialBillingFinancialbillingBatchchangebillingitemsemployeedisplaynameCreate
     * @summary 账单子项批量设置人员显示名称 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbilling/batchchangebillingitemsemployeedisplayname
     * @secure
     */
    webFinancialBillingFinancialbillingBatchchangebillingitemsemployeedisplaynameCreate: (
      data: GetBatchChangeEmployeeDisplayNameInput,
      params: RequestParams = {}
    ) =>
      this.request<BooleanMessageModel, void>({
        path: `/api/web/financial/billing/financialbilling/batchchangebillingitemsemployeedisplayname`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBilling
     * @name WebFinancialBillingFinancialbillingGetbillingtotallistCreate
     * @summary 账单详情合计接口 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbilling/getbillingtotallist
     * @secure
     */
    webFinancialBillingFinancialbillingGetbillingtotallistCreate: (data: GetFinancialBillingTotalInput, params: RequestParams = {}) =>
      this.request<GetFinancialBillingTotalOutputListMessageModel, void>({
        path: `/api/web/financial/billing/financialbilling/getbillingtotallist`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBilling
     * @name WebFinancialBillingFinancialbillingGetCreate
     * @summary 获取详情 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbilling/get/{id}
     * @secure
     */
    webFinancialBillingFinancialbillingGetCreate: (id: string, params: RequestParams = {}) =>
      this.request<FinancialBillingDtoMessageModel, void>({
        path: `/api/web/financial/billing/financialbilling/get/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBilling
     * @name WebFinancialBillingFinancialbillingGetforeditCreate
     * @summary 获取修改实体 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbilling/getforedit/{id}
     * @secure
     */
    webFinancialBillingFinancialbillingGetforeditCreate: (id: string, params: RequestParams = {}) =>
      this.request<GetFinancialBillingForEditOutputForEditModelMessageModel, void>({
        path: `/api/web/financial/billing/financialbilling/getforedit/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBilling
     * @name WebFinancialBillingFinancialbillingGetpreviewinfoCreate
     * @summary 获取账单预览信息 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbilling/getpreviewinfo/{id}
     * @secure
     */
    webFinancialBillingFinancialbillingGetpreviewinfoCreate: (id: string, params: RequestParams = {}) =>
      this.request<GetFinancialBillingPreviewOutputListMessageModel, void>({
        path: `/api/web/financial/billing/financialbilling/getpreviewinfo/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBilling
     * @name WebFinancialBillingFinancialbillingGetflowstatelistCreate
     * @summary 获取工作流状态 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbilling/getflowstatelist
     * @secure
     */
    webFinancialBillingFinancialbillingGetflowstatelistCreate: (params: RequestParams = {}) =>
      this.request<StringFlowStateDtoListDictionaryMessageModel, void>({
        path: `/api/web/financial/billing/financialbilling/getflowstatelist`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBilling
     * @name WebFinancialBillingFinancialbillingChangebillingclientandcaseCreate
     * @summary 变更账单关联客户案件信息 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbilling/changebillingclientandcase
     * @secure
     */
    webFinancialBillingFinancialbillingChangebillingclientandcaseCreate: (data: GetChangeBillingClientCaseInput, params: RequestParams = {}) =>
      this.request<BooleanMessageModel, void>({
        path: `/api/web/financial/billing/financialbilling/changebillingclientandcase`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBilling
     * @name WebFinancialBillingFinancialbillingSubmitbillingCreate
     * @summary 提交账单 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbilling/submitbilling
     * @secure
     */
    webFinancialBillingFinancialbillingSubmitbillingCreate: (data: GetSubmitBillingInput, params: RequestParams = {}) =>
      this.request<BooleanMessageModel, void>({
        path: `/api/web/financial/billing/financialbilling/submitbilling`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBilling
     * @name WebFinancialBillingFinancialbillingPostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbilling/post
     * @secure
     */
    webFinancialBillingFinancialbillingPostCreate: (data: CreateFinancialBillingInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/financial/billing/financialbilling/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBilling
     * @name WebFinancialBillingFinancialbillingPutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/financial/billing/financialbilling/put/{id}
     * @secure
     */
    webFinancialBillingFinancialbillingPutUpdate: (id: string, data: CreateFinancialBillingInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/billing/financialbilling/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBilling
     * @name WebFinancialBillingFinancialbillingPostdraftCreate
     * @summary 添加草稿 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbilling/postdraft
     * @secure
     */
    webFinancialBillingFinancialbillingPostdraftCreate: (data: CreateDraftFinancialBillingInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/financial/billing/financialbilling/postdraft`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBilling
     * @name WebFinancialBillingFinancialbillingPutdraftUpdate
     * @summary 修改草稿 (Auth policies: Web)
     * @request PUT:/api/web/financial/billing/financialbilling/putdraft/{id}
     * @secure
     */
    webFinancialBillingFinancialbillingPutdraftUpdate: (id: string, data: CreateDraftFinancialBillingInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/billing/financialbilling/putdraft/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBilling
     * @name WebFinancialBillingFinancialbillingDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/financial/billing/financialbilling/delete/{id}
     * @secure
     */
    webFinancialBillingFinancialbillingDeleteDelete: (id: string, data: StringEntityDto, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/billing/financialbilling/delete/${id}`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBilling
     * @name WebFinancialBillingFinancialbillingBatchdeleteCreate
     * @summary 批量删除 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbilling/batchdelete
     * @secure
     */
    webFinancialBillingFinancialbillingBatchdeleteCreate: (data: StringEntityDto[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/billing/financialbilling/batchdelete`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBilling
     * @name WebFinancialBillingFinancialbillingGetserialidpageCreate
     * @summary 获取账单编号列表 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbilling/getserialidpage
     * @secure
     */
    webFinancialBillingFinancialbillingGetserialidpageCreate: (data: GetSerialIdPageInput, params: RequestParams = {}) =>
      this.request<GetSerialIdPageOutputPageModelMessageModel, void>({
        path: `/api/web/financial/billing/financialbilling/getserialidpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBilling
     * @name WebFinancialBillingFinancialbillingGetbillingcaseinfoDetail
     * @summary 获取账单案件信息集合 (Auth policies: Web)
     * @request GET:/api/web/financial/billing/financialbilling/getbillingcaseinfo/{id}
     * @secure
     */
    webFinancialBillingFinancialbillingGetbillingcaseinfoDetail: (id: string, params: RequestParams = {}) =>
      this.request<GetBillingCaseInfoOutputMessageModel, void>({
        path: `/api/web/financial/billing/financialbilling/getbillingcaseinfo/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBilling
     * @name WebFinancialBillingFinancialbillingBatchupdatebillingadjustmentCreate
     * @summary 批量更新账单子项调整金额 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbilling/batchupdatebillingadjustment
     * @secure
     */
    webFinancialBillingFinancialbillingBatchupdatebillingadjustmentCreate: (data: BatchUpdateAdjustmentInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/billing/financialbilling/batchupdatebillingadjustment`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBilling
     * @name WebFinancialBillingFinancialbillingTestfinancialbillingexportList
     * @summary  (Auth policies: Web)
     * @request GET:/api/web/financial/billing/financialbilling/testfinancialbillingexport
     * @secure
     */
    webFinancialBillingFinancialbillingTestfinancialbillingexportList: (
      query?: {
        billingId?: string;
        /** @default false */
        isForeign?: boolean;
        /** @default false */
        isPdf?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<AttachmentDownloadDtoMessageModel, void>({
        path: `/api/web/financial/billing/financialbilling/testfinancialbillingexport`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingAdjustment
     * @name WebFinancialBillingFinancialbillingadjustmentGetpageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingadjustment/getpage
     * @secure
     */
    webFinancialBillingFinancialbillingadjustmentGetpageCreate: (data: GetFinancialBillingAdjustmentInput, params: RequestParams = {}) =>
      this.request<FinancialBillingAdjustmentDtoPageModelMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingadjustment/getpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingAdjustment
     * @name WebFinancialBillingFinancialbillingadjustmentListCreate
     * @summary 列表获取所有 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingadjustment/list
     * @secure
     */
    webFinancialBillingFinancialbillingadjustmentListCreate: (
      query?: {
        /** 账单编号 */
        billingId?: string;
        /** 调整项目 */
        category?: string;
        /** 应用范围 */
        scope?: string;
        /** 调整类型 */
        adjustmentType?: string;
        /**
         * 基数
         * @format double
         */
        base?: number;
        /**
         * 调整比例
         * @format double
         */
        adjustmentRate?: number;
        /**
         * 调整额
         * @format double
         */
        adjustment?: number;
        /**
         * 调整后金额
         * @format double
         */
        amount?: number;
        /**
         * 小计金额
         * @format double
         */
        subtotal?: number;
        /** 描述 */
        narrative?: string;
        /** 备注 */
        remark?: string;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<FinancialBillingAdjustmentDtoListMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingadjustment/list`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingAdjustment
     * @name WebFinancialBillingFinancialbillingadjustmentGetfinancialbillingadjustmentexportCreate
     * @summary 列表导出 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingadjustment/getfinancialbillingadjustmentexport
     * @secure
     */
    webFinancialBillingFinancialbillingadjustmentGetfinancialbillingadjustmentexportCreate: (
      data: GetFinancialBillingAdjustmentInputExportInput,
      params: RequestParams = {}
    ) =>
      this.request<ExcelFileDtoMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingadjustment/getfinancialbillingadjustmentexport`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingAdjustment
     * @name WebFinancialBillingFinancialbillingadjustmentGetselectCreate
     * @summary 选择框 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingadjustment/getselect
     * @secure
     */
    webFinancialBillingFinancialbillingadjustmentGetselectCreate: (data: GetOrganizationUnitsInput, params: RequestParams = {}) =>
      this.request<ComboboxItemDtoListMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingadjustment/getselect`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingAdjustment
     * @name WebFinancialBillingFinancialbillingadjustmentGetCreate
     * @summary 获取详情 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingadjustment/get/{id}
     * @secure
     */
    webFinancialBillingFinancialbillingadjustmentGetCreate: (id: string, params: RequestParams = {}) =>
      this.request<FinancialBillingAdjustmentDtoMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingadjustment/get/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingAdjustment
     * @name WebFinancialBillingFinancialbillingadjustmentGetforeditCreate
     * @summary 获取修改实体 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingadjustment/getforedit/{id}
     * @secure
     */
    webFinancialBillingFinancialbillingadjustmentGetforeditCreate: (id: string, params: RequestParams = {}) =>
      this.request<CreateFinancialBillingAdjustmentInputForEditModelMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingadjustment/getforedit/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingAdjustment
     * @name WebFinancialBillingFinancialbillingadjustmentPostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingadjustment/post
     * @secure
     */
    webFinancialBillingFinancialbillingadjustmentPostCreate: (data: CreateFinancialBillingAdjustmentInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/billing/financialbillingadjustment/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingAdjustment
     * @name WebFinancialBillingFinancialbillingadjustmentPutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/financial/billing/financialbillingadjustment/put/{id}
     * @secure
     */
    webFinancialBillingFinancialbillingadjustmentPutUpdate: (id: string, data: CreateFinancialBillingAdjustmentInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/billing/financialbillingadjustment/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingAdjustment
     * @name WebFinancialBillingFinancialbillingadjustmentDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/financial/billing/financialbillingadjustment/delete/{id}
     * @secure
     */
    webFinancialBillingFinancialbillingadjustmentDeleteDelete: (id: string, data: StringEntityDto, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/billing/financialbillingadjustment/delete/${id}`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingAdjustment
     * @name WebFinancialBillingFinancialbillingadjustmentBatchdeleteCreate
     * @summary 批量删除 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingadjustment/batchdelete
     * @secure
     */
    webFinancialBillingFinancialbillingadjustmentBatchdeleteCreate: (data: StringEntityDto[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/billing/financialbillingadjustment/batchdelete`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingAttachment
     * @name WebFinancialBillingFinancialbillingattachmentGetpageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingattachment/getpage
     * @secure
     */
    webFinancialBillingFinancialbillingattachmentGetpageCreate: (data: GetFinancialBillingAttachmentInput, params: RequestParams = {}) =>
      this.request<GetFinancialBillingAttachmentOutputPageModelMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingattachment/getpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingAttachment
     * @name WebFinancialBillingFinancialbillingattachmentListCreate
     * @summary 列表获取所有 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingattachment/list
     * @secure
     */
    webFinancialBillingFinancialbillingattachmentListCreate: (data: GetFinancialBillingAttachmentInput, params: RequestParams = {}) =>
      this.request<GetFinancialBillingAttachmentOutputListMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingattachment/list`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingAttachment
     * @name WebFinancialBillingFinancialbillingattachmentGetfinancialbillingattachmentexportCreate
     * @summary 列表导出 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingattachment/getfinancialbillingattachmentexport
     * @secure
     */
    webFinancialBillingFinancialbillingattachmentGetfinancialbillingattachmentexportCreate: (
      data: GetFinancialBillingAttachmentInputExportInput,
      params: RequestParams = {}
    ) =>
      this.request<ExcelFileDtoMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingattachment/getfinancialbillingattachmentexport`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingAttachment
     * @name WebFinancialBillingFinancialbillingattachmentGetselectCreate
     * @summary 选择框 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingattachment/getselect
     * @secure
     */
    webFinancialBillingFinancialbillingattachmentGetselectCreate: (data: GetOrganizationUnitsInput, params: RequestParams = {}) =>
      this.request<ComboboxItemDtoListMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingattachment/getselect`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingAttachment
     * @name WebFinancialBillingFinancialbillingattachmentGetCreate
     * @summary 获取详情 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingattachment/get/{id}
     * @secure
     */
    webFinancialBillingFinancialbillingattachmentGetCreate: (id: string, params: RequestParams = {}) =>
      this.request<FinancialBillingAttachmentDtoMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingattachment/get/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingAttachment
     * @name WebFinancialBillingFinancialbillingattachmentGetforeditCreate
     * @summary 获取修改实体 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingattachment/getforedit/{id}
     * @secure
     */
    webFinancialBillingFinancialbillingattachmentGetforeditCreate: (id: string, params: RequestParams = {}) =>
      this.request<GetFinancialBillingAttachmentForEditOutputForEditModelMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingattachment/getforedit/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingAttachment
     * @name WebFinancialBillingFinancialbillingattachmentPostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingattachment/post
     * @secure
     */
    webFinancialBillingFinancialbillingattachmentPostCreate: (data: CreateFinancialBillingAttachmentInput, params: RequestParams = {}) =>
      this.request<GetFinancialBillingPreviewOutputMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingattachment/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingAttachment
     * @name WebFinancialBillingFinancialbillingattachmentPutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/financial/billing/financialbillingattachment/put/{id}
     * @secure
     */
    webFinancialBillingFinancialbillingattachmentPutUpdate: (id: string, data: CreateFinancialBillingAttachmentInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/billing/financialbillingattachment/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingAttachment
     * @name WebFinancialBillingFinancialbillingattachmentDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/financial/billing/financialbillingattachment/delete
     * @secure
     */
    webFinancialBillingFinancialbillingattachmentDeleteDelete: (data: StringEntityDto, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/billing/financialbillingattachment/delete`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingAttachment
     * @name WebFinancialBillingFinancialbillingattachmentBatchdeleteCreate
     * @summary 批量删除 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingattachment/batchdelete
     * @secure
     */
    webFinancialBillingFinancialbillingattachmentBatchdeleteCreate: (data: StringEntityDto[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/billing/financialbillingattachment/batchdelete`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingComments
     * @name WebFinancialBillingFinancialbillingcommentsGetpageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingcomments/getpage
     * @secure
     */
    webFinancialBillingFinancialbillingcommentsGetpageCreate: (data: GetFinancialBillingCommentsInput, params: RequestParams = {}) =>
      this.request<GetFinancialBillingCommentsOutputPageModelMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingcomments/getpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingComments
     * @name WebFinancialBillingFinancialbillingcommentsListCreate
     * @summary 列表获取所有 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingcomments/list
     * @secure
     */
    webFinancialBillingFinancialbillingcommentsListCreate: (data: GetFinancialBillingCommentsInput, params: RequestParams = {}) =>
      this.request<GetFinancialBillingCommentsOutputListMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingcomments/list`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingComments
     * @name WebFinancialBillingFinancialbillingcommentsGetfinancialbillingcommentsexportCreate
     * @summary 列表导出 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingcomments/getfinancialbillingcommentsexport
     * @secure
     */
    webFinancialBillingFinancialbillingcommentsGetfinancialbillingcommentsexportCreate: (
      data: GetFinancialBillingCommentsInputExportInput,
      params: RequestParams = {}
    ) =>
      this.request<ExcelFileDtoMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingcomments/getfinancialbillingcommentsexport`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingComments
     * @name WebFinancialBillingFinancialbillingcommentsGetCreate
     * @summary 获取详情 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingcomments/get/{id}
     * @secure
     */
    webFinancialBillingFinancialbillingcommentsGetCreate: (id: string, params: RequestParams = {}) =>
      this.request<FinancialBillingCommentsDtoMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingcomments/get/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingComments
     * @name WebFinancialBillingFinancialbillingcommentsGetforeditCreate
     * @summary 获取修改实体 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingcomments/getforedit/{id}
     * @secure
     */
    webFinancialBillingFinancialbillingcommentsGetforeditCreate: (id: string, params: RequestParams = {}) =>
      this.request<GetFinancialBillingCommentsForEditOutputForEditModelMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingcomments/getforedit/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingComments
     * @name WebFinancialBillingFinancialbillingcommentsPostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingcomments/post
     * @secure
     */
    webFinancialBillingFinancialbillingcommentsPostCreate: (data: CreateFinancialBillingCommentsInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/billing/financialbillingcomments/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingComments
     * @name WebFinancialBillingFinancialbillingcommentsPutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/financial/billing/financialbillingcomments/put/{id}
     * @secure
     */
    webFinancialBillingFinancialbillingcommentsPutUpdate: (id: string, data: CreateFinancialBillingCommentsInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/billing/financialbillingcomments/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingComments
     * @name WebFinancialBillingFinancialbillingcommentsDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/financial/billing/financialbillingcomments/delete
     * @secure
     */
    webFinancialBillingFinancialbillingcommentsDeleteDelete: (data: StringEntityDto, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/billing/financialbillingcomments/delete`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingComments
     * @name WebFinancialBillingFinancialbillingcommentsBatchdeleteCreate
     * @summary 批量删除 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingcomments/batchdelete
     * @secure
     */
    webFinancialBillingFinancialbillingcommentsBatchdeleteCreate: (data: StringEntityDto[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/billing/financialbillingcomments/batchdelete`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingDisbursement
     * @name WebFinancialBillingFinancialbillingdisbursementGetpageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingdisbursement/getpage
     * @secure
     */
    webFinancialBillingFinancialbillingdisbursementGetpageCreate: (data: GetFinancialBillingDisbursementInput, params: RequestParams = {}) =>
      this.request<GetFinancialBillingDisbursementOutputPageModelMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingdisbursement/getpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingDisbursement
     * @name WebFinancialBillingFinancialbillingdisbursementGetsubtotalpageCreate
     * @summary 费用小计列表（以所属人分组统计） (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingdisbursement/getsubtotalpage
     * @secure
     */
    webFinancialBillingFinancialbillingdisbursementGetsubtotalpageCreate: (data: StringEntityDto, params: RequestParams = {}) =>
      this.request<GetFinancialBillingDisbursementSubtotalOutputListMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingdisbursement/getsubtotalpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingDisbursement
     * @name WebFinancialBillingFinancialbillingdisbursementListCreate
     * @summary 列表获取所有 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingdisbursement/list
     * @secure
     */
    webFinancialBillingFinancialbillingdisbursementListCreate: (
      query?: {
        /** 账单编号 */
        billingId?: string;
        /** 费用名称 */
        name?: string;
        /**
         * 开始日期时间
         * @format date-time
         */
        expenseDateStartDate?: string;
        /**
         * 结束日期时间
         * @format date-time
         */
        expenseDateEndDate?: string;
        /** 费用代码 */
        expenseCode?: string;
        /** 费用类别 */
        category?: string[];
        /** 费用子类别 */
        subCategory?: string;
        /** 币种 */
        currency?: string[];
        /**
         * 币种汇率
         * @format double
         */
        exchangeRate?: number;
        /** 报销类型 */
        expenseType?: string[];
        /** 是否代收代付 */
        isPobo?: string[];
        /**
         * 费用金额
         * @format double
         */
        amount?: number;
        /**
         * 计数数量
         * @format double
         */
        count?: number;
        /** 调整类型 */
        adjustmentType?: string[];
        /**
         * 调整比例
         * @format double
         */
        adjustmentRate?: number;
        /**
         * 调整金额
         * @format double
         */
        adjustment?: number;
        /**
         * 税率
         * @format double
         */
        taxRate?: number;
        /**
         * 税额
         * @format double
         */
        taxAmount?: number;
        /**
         * 外币服务费金额
         * @format double
         */
        foreignAmount?: number;
        /**
         * 外币小计金额
         * @format double
         */
        foreignSubtotal?: number;
        /**
         * 小计金额
         * @format double
         */
        subtotal?: number;
        /** 是否计费 */
        isBillable?: string[];
        /** 报销单编号 */
        expenserportId?: string;
        /** 是否已开票 */
        isInvoiced?: string[];
        /** 商户名称 */
        merchant?: string;
        /** 案件编号 */
        caseId?: string;
        /** 费用报销人 */
        employeeId?: string;
        /** 发票/收据类型 */
        receiptType?: string;
        /** 费用报销人显示名称 */
        employeeDisplayName?: string;
        /** 是否内部账单 */
        isInternalBilling?: string[];
        /** 内部账单编号 */
        internalBillingId?: string;
        /** 备注 */
        remark?: string;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<FinancialBillingDisbursementDtoListMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingdisbursement/list`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingDisbursement
     * @name WebFinancialBillingFinancialbillingdisbursementGetfinancialbillingdisbursementexportCreate
     * @summary 列表导出 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingdisbursement/getfinancialbillingdisbursementexport
     * @secure
     */
    webFinancialBillingFinancialbillingdisbursementGetfinancialbillingdisbursementexportCreate: (
      data: GetFinancialBillingDisbursementInputExportInput,
      params: RequestParams = {}
    ) =>
      this.request<ExcelFileDtoMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingdisbursement/getfinancialbillingdisbursementexport`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingDisbursement
     * @name WebFinancialBillingFinancialbillingdisbursementGetselectCreate
     * @summary 选择框 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingdisbursement/getselect
     * @secure
     */
    webFinancialBillingFinancialbillingdisbursementGetselectCreate: (data: GetOrganizationUnitsInput, params: RequestParams = {}) =>
      this.request<ComboboxItemDtoListMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingdisbursement/getselect`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingDisbursement
     * @name WebFinancialBillingFinancialbillingdisbursementWriteoffexpensesCreate
     * @summary 账单费用销账 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingdisbursement/writeoffexpenses
     * @secure
     */
    webFinancialBillingFinancialbillingdisbursementWriteoffexpensesCreate: (data: GetWriteOffExpenseInput, params: RequestParams = {}) =>
      this.request<BooleanMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingdisbursement/writeoffexpenses`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingDisbursement
     * @name WebFinancialBillingFinancialbillingdisbursementChangebillablestateCreate
     * @summary 变更支出费用计费状态 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingdisbursement/changebillablestate
     * @secure
     */
    webFinancialBillingFinancialbillingdisbursementChangebillablestateCreate: (data: GetChangeBilllableStateInput, params: RequestParams = {}) =>
      this.request<BooleanMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingdisbursement/changebillablestate`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingDisbursement
     * @name WebFinancialBillingFinancialbillingdisbursementChangetaxratesCreate
     * @summary 设置账单支出费用税率 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingdisbursement/changetaxrates
     * @secure
     */
    webFinancialBillingFinancialbillingdisbursementChangetaxratesCreate: (data: GetChangeTaxRateInput, params: RequestParams = {}) =>
      this.request<BooleanMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingdisbursement/changetaxrates`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingDisbursement
     * @name WebFinancialBillingFinancialbillingdisbursementAddcandidatesexpensetobillingCreate
     * @summary 添加候选项支出费用到账单 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingdisbursement/addcandidatesexpensetobilling
     * @secure
     */
    webFinancialBillingFinancialbillingdisbursementAddcandidatesexpensetobillingCreate: (
      data: GetCandidatesExpenseToBillingInput,
      params: RequestParams = {}
    ) =>
      this.request<BooleanMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingdisbursement/addcandidatesexpensetobilling`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingDisbursement
     * @name WebFinancialBillingFinancialbillingdisbursementWriteoffcandidatesexpensesCreate
     * @summary 候选项支出费用销账 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingdisbursement/writeoffcandidatesexpenses
     * @secure
     */
    webFinancialBillingFinancialbillingdisbursementWriteoffcandidatesexpensesCreate: (data: GetWriteOffExpenseInput, params: RequestParams = {}) =>
      this.request<BooleanMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingdisbursement/writeoffcandidatesexpenses`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingDisbursement
     * @name WebFinancialBillingFinancialbillingdisbursementGetcandidatespageCreate
     * @summary 候选支出费用列表 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingdisbursement/getcandidatespage
     * @secure
     */
    webFinancialBillingFinancialbillingdisbursementGetcandidatespageCreate: (data: GetCaseExpenseInput, params: RequestParams = {}) =>
      this.request<GetFinancialBillingDisbursementOutputPageModelMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingdisbursement/getcandidatespage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingDisbursement
     * @name WebFinancialBillingFinancialbillingdisbursementGetcandidatesinternalbillingpageCreate
     * @summary 内部账单候选列表 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingdisbursement/getcandidatesinternalbillingpage
     * @secure
     */
    webFinancialBillingFinancialbillingdisbursementGetcandidatesinternalbillingpageCreate: (data: GetCaseExpenseInput, params: RequestParams = {}) =>
      this.request<GetFinancialBillingDisbursementOutputPageModelMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingdisbursement/getcandidatesinternalbillingpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingDisbursement
     * @name WebFinancialBillingFinancialbillingdisbursementGetwriteoffpageCreate
     * @summary 已销账费用列表 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingdisbursement/getwriteoffpage
     * @secure
     */
    webFinancialBillingFinancialbillingdisbursementGetwriteoffpageCreate: (data: GetFinancialBillingWriteOffDisbursementInput, params: RequestParams = {}) =>
      this.request<GetFinancialBillingDisbursementOutputPageModelMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingdisbursement/getwriteoffpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingDisbursement
     * @name WebFinancialBillingFinancialbillingdisbursementGetCreate
     * @summary 获取详情 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingdisbursement/get/{id}
     * @secure
     */
    webFinancialBillingFinancialbillingdisbursementGetCreate: (id: string, params: RequestParams = {}) =>
      this.request<FinancialBillingDisbursementDtoMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingdisbursement/get/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingDisbursement
     * @name WebFinancialBillingFinancialbillingdisbursementGetforeditCreate
     * @summary 获取修改实体 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingdisbursement/getforedit/{id}
     * @secure
     */
    webFinancialBillingFinancialbillingdisbursementGetforeditCreate: (id: string, params: RequestParams = {}) =>
      this.request<CreateFinancialBillingDisbursementInputForEditModelMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingdisbursement/getforedit/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingDisbursement
     * @name WebFinancialBillingFinancialbillingdisbursementPostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingdisbursement/post
     * @secure
     */
    webFinancialBillingFinancialbillingdisbursementPostCreate: (data: CreateFinancialBillingDisbursementInput, params: RequestParams = {}) =>
      this.request<FinancialBillingDisbursementDtoMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingdisbursement/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingDisbursement
     * @name WebFinancialBillingFinancialbillingdisbursementPutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/financial/billing/financialbillingdisbursement/put/{id}
     * @secure
     */
    webFinancialBillingFinancialbillingdisbursementPutUpdate: (id: string, data: CreateFinancialBillingDisbursementInput, params: RequestParams = {}) =>
      this.request<FinancialBillingDisbursementDtoMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingdisbursement/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingDisbursement
     * @name WebFinancialBillingFinancialbillingdisbursementDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/financial/billing/financialbillingdisbursement/delete/{id}
     * @secure
     */
    webFinancialBillingFinancialbillingdisbursementDeleteDelete: (id: string, data: StringEntityDto, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/billing/financialbillingdisbursement/delete/${id}`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingDisbursement
     * @name WebFinancialBillingFinancialbillingdisbursementBatchdeleteCreate
     * @summary 批量删除 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingdisbursement/batchdelete
     * @secure
     */
    webFinancialBillingFinancialbillingdisbursementBatchdeleteCreate: (data: StringEntityDto[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/billing/financialbillingdisbursement/batchdelete`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingExchange
     * @name WebFinancialBillingFinancialbillingexchangeGetpageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingexchange/getpage
     * @secure
     */
    webFinancialBillingFinancialbillingexchangeGetpageCreate: (data: GetFinancialBillingExchangeInput, params: RequestParams = {}) =>
      this.request<GetFinancialBillingExchangeOutputPageModelMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingexchange/getpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingExchange
     * @name WebFinancialBillingFinancialbillingexchangeListCreate
     * @summary 列表获取所有 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingexchange/list
     * @secure
     */
    webFinancialBillingFinancialbillingexchangeListCreate: (data: GetFinancialBillingExchangeInput, params: RequestParams = {}) =>
      this.request<GetFinancialBillingExchangeOutputListMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingexchange/list`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingExchange
     * @name WebFinancialBillingFinancialbillingexchangeGetfinancialbillingexchangeexportCreate
     * @summary 列表导出 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingexchange/getfinancialbillingexchangeexport
     * @secure
     */
    webFinancialBillingFinancialbillingexchangeGetfinancialbillingexchangeexportCreate: (
      data: GetFinancialBillingExchangeInputExportInput,
      params: RequestParams = {}
    ) =>
      this.request<ExcelFileDtoMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingexchange/getfinancialbillingexchangeexport`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingExchange
     * @name WebFinancialBillingFinancialbillingexchangeGetCreate
     * @summary 获取详情 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingexchange/get/{id}
     * @secure
     */
    webFinancialBillingFinancialbillingexchangeGetCreate: (id: string, params: RequestParams = {}) =>
      this.request<FinancialBillingExchangeDtoMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingexchange/get/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingExchange
     * @name WebFinancialBillingFinancialbillingexchangeGetforeditCreate
     * @summary 获取修改实体 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingexchange/getforedit/{id}
     * @secure
     */
    webFinancialBillingFinancialbillingexchangeGetforeditCreate: (id: string, params: RequestParams = {}) =>
      this.request<GetFinancialBillingExchangeForEditOutputForEditModelMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingexchange/getforedit/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingExchange
     * @name WebFinancialBillingFinancialbillingexchangePostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingexchange/post
     * @secure
     */
    webFinancialBillingFinancialbillingexchangePostCreate: (data: CreateFinancialBillingExchangeInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/billing/financialbillingexchange/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingExchange
     * @name WebFinancialBillingFinancialbillingexchangePutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/financial/billing/financialbillingexchange/put/{id}
     * @secure
     */
    webFinancialBillingFinancialbillingexchangePutUpdate: (id: string, data: CreateFinancialBillingExchangeInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/billing/financialbillingexchange/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingExchange
     * @name WebFinancialBillingFinancialbillingexchangeDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/financial/billing/financialbillingexchange/delete/{id}
     * @secure
     */
    webFinancialBillingFinancialbillingexchangeDeleteDelete: (id: string, data: StringEntityDto, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/billing/financialbillingexchange/delete/${id}`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingExchange
     * @name WebFinancialBillingFinancialbillingexchangeBatchdeleteCreate
     * @summary 批量删除 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingexchange/batchdelete
     * @secure
     */
    webFinancialBillingFinancialbillingexchangeBatchdeleteCreate: (data: StringEntityDto[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/billing/financialbillingexchange/batchdelete`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingFixedServiceFee
     * @name WebFinancialBillingFinancialbillingfixedservicefeeGetpageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingfixedservicefee/getpage
     * @secure
     */
    webFinancialBillingFinancialbillingfixedservicefeeGetpageCreate: (data: GetFinancialBillingFixedServiceFeeInput, params: RequestParams = {}) =>
      this.request<GetFinancialBillingFixedServiceFeeOutputPageModelMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingfixedservicefee/getpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingFixedServiceFee
     * @name WebFinancialBillingFinancialbillingfixedservicefeeListCreate
     * @summary 列表获取所有 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingfixedservicefee/list
     * @secure
     */
    webFinancialBillingFinancialbillingfixedservicefeeListCreate: (
      query?: {
        /** 账单编号 */
        billingId?: string;
        /**
         * 开始日期时间
         * @format date-time
         */
        serviceDateStartDate?: string;
        /**
         * 结束日期时间
         * @format date-time
         */
        serviceDateEndDate?: string;
        /** 任务 */
        task?: string;
        /** 活动代码 */
        activityCode?: string;
        /** 服务类型 */
        category?: string;
        /**
         * 服务费金额
         * @format double
         */
        amount?: number;
        /** 调整类型 */
        adjustmentType?: string;
        /**
         * 调整比例
         * @format double
         */
        adjustmentRate?: number;
        /**
         * 调整额
         * @format double
         */
        adjustment?: number;
        /**
         * 税率
         * @format double
         */
        taxRate?: number;
        /**
         * 税额
         * @format double
         */
        taxAmount?: number;
        /** 币种 */
        currency?: string;
        /**
         * 币种汇率
         * @format double
         */
        exchangeRate?: number;
        /**
         * 外币服务费金额
         * @format double
         */
        foreignAmount?: number;
        /**
         * 外币小计金额
         * @format double
         */
        foreignSubtotal?: number;
        /**
         * 小计金额
         * @format double
         */
        subtotal?: number;
        /** 是否计费 */
        isBillable?: string;
        /** 描述 */
        narrative?: string;
        /** 备注 */
        remark?: string;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<GetFinancialBillingFixedServiceFeeOutputListMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingfixedservicefee/list`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingFixedServiceFee
     * @name WebFinancialBillingFinancialbillingfixedservicefeeGetfinancialbillingfixedservicefeeexportCreate
     * @summary 固定专业服务费列表导出 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingfixedservicefee/getfinancialbillingfixedservicefeeexport
     * @secure
     */
    webFinancialBillingFinancialbillingfixedservicefeeGetfinancialbillingfixedservicefeeexportCreate: (
      data: GetFinancialBillingFixedServiceFeeInputExportInput,
      params: RequestParams = {}
    ) =>
      this.request<ExcelFileDtoMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingfixedservicefee/getfinancialbillingfixedservicefeeexport`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingFixedServiceFee
     * @name WebFinancialBillingFinancialbillingfixedservicefeeGetCreate
     * @summary 获取详情 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingfixedservicefee/get/{id}
     * @secure
     */
    webFinancialBillingFinancialbillingfixedservicefeeGetCreate: (id: string, params: RequestParams = {}) =>
      this.request<FinancialBillingFixedServiceFeeDtoMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingfixedservicefee/get/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingFixedServiceFee
     * @name WebFinancialBillingFinancialbillingfixedservicefeeGetforeditCreate
     * @summary 获取修改实体 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingfixedservicefee/getforedit/{id}
     * @secure
     */
    webFinancialBillingFinancialbillingfixedservicefeeGetforeditCreate: (id: string, params: RequestParams = {}) =>
      this.request<CreateFinancialBillingFixedServiceFeeInputForEditModelMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingfixedservicefee/getforedit/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingFixedServiceFee
     * @name WebFinancialBillingFinancialbillingfixedservicefeeChangetaxratesCreate
     * @summary 变更账单固定服务费税率 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingfixedservicefee/changetaxrates
     * @secure
     */
    webFinancialBillingFinancialbillingfixedservicefeeChangetaxratesCreate: (data: GetChangeTaxRateInput, params: RequestParams = {}) =>
      this.request<BooleanMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingfixedservicefee/changetaxrates`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingFixedServiceFee
     * @name WebFinancialBillingFinancialbillingfixedservicefeeChangebillablestateCreate
     * @summary 变更固定专业服务费计费状态 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingfixedservicefee/changebillablestate
     * @secure
     */
    webFinancialBillingFinancialbillingfixedservicefeeChangebillablestateCreate: (data: GetChangeBilllableStateInput, params: RequestParams = {}) =>
      this.request<BooleanMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingfixedservicefee/changebillablestate`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingFixedServiceFee
     * @name WebFinancialBillingFinancialbillingfixedservicefeePostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingfixedservicefee/post
     * @secure
     */
    webFinancialBillingFinancialbillingfixedservicefeePostCreate: (data: CreateFinancialBillingFixedServiceFeeInput, params: RequestParams = {}) =>
      this.request<FinancialBillingFixedServiceFeeDtoMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingfixedservicefee/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingFixedServiceFee
     * @name WebFinancialBillingFinancialbillingfixedservicefeePutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/financial/billing/financialbillingfixedservicefee/put/{id}
     * @secure
     */
    webFinancialBillingFinancialbillingfixedservicefeePutUpdate: (id: string, data: CreateFinancialBillingFixedServiceFeeInput, params: RequestParams = {}) =>
      this.request<FinancialBillingFixedServiceFeeDtoMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingfixedservicefee/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingFixedServiceFee
     * @name WebFinancialBillingFinancialbillingfixedservicefeeDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/financial/billing/financialbillingfixedservicefee/delete/{id}
     * @secure
     */
    webFinancialBillingFinancialbillingfixedservicefeeDeleteDelete: (id: string, data: StringEntityDto, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/billing/financialbillingfixedservicefee/delete/${id}`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingFixedServiceFee
     * @name WebFinancialBillingFinancialbillingfixedservicefeeBatchdeleteCreate
     * @summary 批量删除 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingfixedservicefee/batchdelete
     * @secure
     */
    webFinancialBillingFinancialbillingfixedservicefeeBatchdeleteCreate: (data: StringEntityDto[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/billing/financialbillingfixedservicefee/batchdelete`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingForeignPayment
     * @name WebFinancialBillingFinancialbillingforeignpaymentGetpageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingforeignpayment/getpage
     * @secure
     */
    webFinancialBillingFinancialbillingforeignpaymentGetpageCreate: (data: GetFinancialBillingForeignPaymentInput, params: RequestParams = {}) =>
      this.request<GetFinancialBillingForeignPaymentOutputPageModelMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingforeignpayment/getpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingForeignPayment
     * @name WebFinancialBillingFinancialbillingforeignpaymentListCreate
     * @summary 列表获取所有 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingforeignpayment/list
     * @secure
     */
    webFinancialBillingFinancialbillingforeignpaymentListCreate: (data: GetFinancialBillingForeignPaymentInput, params: RequestParams = {}) =>
      this.request<GetFinancialBillingForeignPaymentOutputListMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingforeignpayment/list`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingForeignPayment
     * @name WebFinancialBillingFinancialbillingforeignpaymentGetfinancialbillingforeignpaymentexportCreate
     * @summary 列表导出 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingforeignpayment/getfinancialbillingforeignpaymentexport
     * @secure
     */
    webFinancialBillingFinancialbillingforeignpaymentGetfinancialbillingforeignpaymentexportCreate: (
      data: GetFinancialBillingForeignPaymentInputExportInput,
      params: RequestParams = {}
    ) =>
      this.request<ExcelFileDtoMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingforeignpayment/getfinancialbillingforeignpaymentexport`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingForeignPayment
     * @name WebFinancialBillingFinancialbillingforeignpaymentGetCreate
     * @summary 获取详情 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingforeignpayment/get/{id}
     * @secure
     */
    webFinancialBillingFinancialbillingforeignpaymentGetCreate: (id: string, params: RequestParams = {}) =>
      this.request<FinancialBillingForeignPaymentDtoMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingforeignpayment/get/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingForeignPayment
     * @name WebFinancialBillingFinancialbillingforeignpaymentGetforeditCreate
     * @summary 获取修改实体 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingforeignpayment/getforedit/{id}
     * @secure
     */
    webFinancialBillingFinancialbillingforeignpaymentGetforeditCreate: (id: string, params: RequestParams = {}) =>
      this.request<GetFinancialBillingForeignPaymentForEditOutputForEditModelMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingforeignpayment/getforedit/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingForeignPayment
     * @name WebFinancialBillingFinancialbillingforeignpaymentPostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingforeignpayment/post
     * @secure
     */
    webFinancialBillingFinancialbillingforeignpaymentPostCreate: (data: CreateFinancialBillingForeignPaymentInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/billing/financialbillingforeignpayment/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingForeignPayment
     * @name WebFinancialBillingFinancialbillingforeignpaymentPutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/financial/billing/financialbillingforeignpayment/put/{id}
     * @secure
     */
    webFinancialBillingFinancialbillingforeignpaymentPutUpdate: (id: string, data: CreateFinancialBillingForeignPaymentInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/billing/financialbillingforeignpayment/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingForeignPayment
     * @name WebFinancialBillingFinancialbillingforeignpaymentDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/financial/billing/financialbillingforeignpayment/delete/{id}
     * @secure
     */
    webFinancialBillingFinancialbillingforeignpaymentDeleteDelete: (id: string, data: StringEntityDto, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/billing/financialbillingforeignpayment/delete/${id}`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingForeignPayment
     * @name WebFinancialBillingFinancialbillingforeignpaymentBatchdeleteCreate
     * @summary 批量删除 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingforeignpayment/batchdelete
     * @secure
     */
    webFinancialBillingFinancialbillingforeignpaymentBatchdeleteCreate: (data: StringEntityDto[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/billing/financialbillingforeignpayment/batchdelete`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingTemplate
     * @name WebFinancialBillingFinancialbillingtemplateGetpageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingtemplate/getpage
     * @secure
     */
    webFinancialBillingFinancialbillingtemplateGetpageCreate: (data: GetFinancialBillingTemplateInput, params: RequestParams = {}) =>
      this.request<FinancialBillingTemplateDtoPageModelMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingtemplate/getpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingTemplate
     * @name WebFinancialBillingFinancialbillingtemplateListCreate
     * @summary 列表获取所有 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingtemplate/list
     * @secure
     */
    webFinancialBillingFinancialbillingtemplateListCreate: (
      query?: {
        /** 模板名称 */
        name?: string;
        /** 文件路径 */
        filePath?: string;
        /** 文件类型 */
        fileExtension?: string;
        /**
         * 文件大小
         * @format float
         */
        fileSize?: number;
        /** 适用语言 */
        language?: string;
        /** 模板适用范围 */
        scope?: string;
        /** 适用案件类型 */
        caseCategory?: string;
        /** 模板类型 */
        category?: string;
        /** 是否包含封面页 */
        isIncludedCoverLetter?: string;
        /** 是否是上传模板 */
        isUpload?: string;
        /** 备注 */
        remark?: string;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<FinancialBillingTemplateDtoListMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingtemplate/list`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingTemplate
     * @name WebFinancialBillingFinancialbillingtemplateGetfinancialbillingtemplateexportCreate
     * @summary 列表导出 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingtemplate/getfinancialbillingtemplateexport
     * @secure
     */
    webFinancialBillingFinancialbillingtemplateGetfinancialbillingtemplateexportCreate: (
      data: GetFinancialBillingTemplateInputExportInput,
      params: RequestParams = {}
    ) =>
      this.request<ExcelFileDtoMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingtemplate/getfinancialbillingtemplateexport`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingTemplate
     * @name WebFinancialBillingFinancialbillingtemplateGetselectCreate
     * @summary 选择框 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingtemplate/getselect
     * @secure
     */
    webFinancialBillingFinancialbillingtemplateGetselectCreate: (data: GetOrganizationUnitsInput, params: RequestParams = {}) =>
      this.request<ComboboxItemDtoListMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingtemplate/getselect`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingTemplate
     * @name WebFinancialBillingFinancialbillingtemplateGetCreate
     * @summary 获取详情 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingtemplate/get/{id}
     * @secure
     */
    webFinancialBillingFinancialbillingtemplateGetCreate: (id: string, params: RequestParams = {}) =>
      this.request<FinancialBillingTemplateDtoMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingtemplate/get/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingTemplate
     * @name WebFinancialBillingFinancialbillingtemplateGetforeditCreate
     * @summary 获取修改实体 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingtemplate/getforedit/{id}
     * @secure
     */
    webFinancialBillingFinancialbillingtemplateGetforeditCreate: (id: string, params: RequestParams = {}) =>
      this.request<CreateFinancialBillingTemplateInputMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingtemplate/getforedit/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingTemplate
     * @name WebFinancialBillingFinancialbillingtemplatePostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingtemplate/post
     * @secure
     */
    webFinancialBillingFinancialbillingtemplatePostCreate: (data: CreateFinancialBillingTemplateInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/billing/financialbillingtemplate/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingTemplate
     * @name WebFinancialBillingFinancialbillingtemplatePutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/financial/billing/financialbillingtemplate/put/{id}
     * @secure
     */
    webFinancialBillingFinancialbillingtemplatePutUpdate: (id: string, data: CreateFinancialBillingTemplateInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/billing/financialbillingtemplate/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingTemplate
     * @name WebFinancialBillingFinancialbillingtemplateDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/financial/billing/financialbillingtemplate/delete/{id}
     * @secure
     */
    webFinancialBillingFinancialbillingtemplateDeleteDelete: (id: string, data: StringEntityDto, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/billing/financialbillingtemplate/delete/${id}`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingTemplate
     * @name WebFinancialBillingFinancialbillingtemplateBatchdeleteCreate
     * @summary 批量删除 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingtemplate/batchdelete
     * @secure
     */
    webFinancialBillingFinancialbillingtemplateBatchdeleteCreate: (data: StringEntityDto[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/billing/financialbillingtemplate/batchdelete`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingTimesheetServiceFee
     * @name WebFinancialBillingFinancialbillingtimesheetservicefeeGetpageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingtimesheetservicefee/getpage
     * @secure
     */
    webFinancialBillingFinancialbillingtimesheetservicefeeGetpageCreate: (data: GetFinancialBillingTimesheetServiceFeeInput, params: RequestParams = {}) =>
      this.request<GetFinancialBillingTimesheetServiceFeeOutputPageModelMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingtimesheetservicefee/getpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingTimesheetServiceFee
     * @name WebFinancialBillingFinancialbillingtimesheetservicefeeChangebillablestateCreate
     * @summary 变更工时计费状态 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingtimesheetservicefee/changebillablestate
     * @secure
     */
    webFinancialBillingFinancialbillingtimesheetservicefeeChangebillablestateCreate: (data: GetChangeBilllableStateInput, params: RequestParams = {}) =>
      this.request<BooleanMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingtimesheetservicefee/changebillablestate`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingTimesheetServiceFee
     * @name WebFinancialBillingFinancialbillingtimesheetservicefeeChangetaxratesCreate
     * @summary 设置账单工时服务费税率 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingtimesheetservicefee/changetaxrates
     * @secure
     */
    webFinancialBillingFinancialbillingtimesheetservicefeeChangetaxratesCreate: (data: GetChangeTaxRateInput, params: RequestParams = {}) =>
      this.request<BooleanMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingtimesheetservicefee/changetaxrates`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingTimesheetServiceFee
     * @name WebFinancialBillingFinancialbillingtimesheetservicefeeChangetimekeeperratesCreate
     * @summary 设置账单服务费人员费率 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingtimesheetservicefee/changetimekeeperrates
     * @secure
     */
    webFinancialBillingFinancialbillingtimesheetservicefeeChangetimekeeperratesCreate: (data: GetChangeTimekeeperRateInput[], params: RequestParams = {}) =>
      this.request<BooleanMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingtimesheetservicefee/changetimekeeperrates`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingTimesheetServiceFee
     * @name WebFinancialBillingFinancialbillingtimesheetservicefeeSplittimesheetsCreate
     * @summary 拆分工时服务费 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingtimesheetservicefee/splittimesheets
     * @secure
     */
    webFinancialBillingFinancialbillingtimesheetservicefeeSplittimesheetsCreate: (data: GetSplitTimesheetInput, params: RequestParams = {}) =>
      this.request<BooleanMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingtimesheetservicefee/splittimesheets`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingTimesheetServiceFee
     * @name WebFinancialBillingFinancialbillingtimesheetservicefeeMergetimesheetsCreate
     * @summary 合并工时服务费 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingtimesheetservicefee/mergetimesheets
     * @secure
     */
    webFinancialBillingFinancialbillingtimesheetservicefeeMergetimesheetsCreate: (data: GetMergeTimesheetInput, params: RequestParams = {}) =>
      this.request<BooleanMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingtimesheetservicefee/mergetimesheets`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingTimesheetServiceFee
     * @name WebFinancialBillingFinancialbillingtimesheetservicefeeWriteofftimesheetsCreate
     * @summary 账单工时销账 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingtimesheetservicefee/writeofftimesheets
     * @secure
     */
    webFinancialBillingFinancialbillingtimesheetservicefeeWriteofftimesheetsCreate: (data: GetWriteOffTimesheetInput, params: RequestParams = {}) =>
      this.request<BooleanMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingtimesheetservicefee/writeofftimesheets`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingTimesheetServiceFee
     * @name WebFinancialBillingFinancialbillingtimesheetservicefeeAddcandidatestobillingCreate
     * @summary 添加候选项工时到账单 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingtimesheetservicefee/addcandidatestobilling
     * @secure
     */
    webFinancialBillingFinancialbillingtimesheetservicefeeAddcandidatestobillingCreate: (data: GetCandidatesToBillingInput, params: RequestParams = {}) =>
      this.request<BooleanMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingtimesheetservicefee/addcandidatestobilling`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingTimesheetServiceFee
     * @name WebFinancialBillingFinancialbillingtimesheetservicefeeWriteoffcandidatestimesheetsCreate
     * @summary 候选项工时销账 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingtimesheetservicefee/writeoffcandidatestimesheets
     * @secure
     */
    webFinancialBillingFinancialbillingtimesheetservicefeeWriteoffcandidatestimesheetsCreate: (data: GetWriteOffTimesheetInput, params: RequestParams = {}) =>
      this.request<BooleanMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingtimesheetservicefee/writeoffcandidatestimesheets`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingTimesheetServiceFee
     * @name WebFinancialBillingFinancialbillingtimesheetservicefeeGetcandidatespageCreate
     * @summary 候选工时列表 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingtimesheetservicefee/getcandidatespage
     * @secure
     */
    webFinancialBillingFinancialbillingtimesheetservicefeeGetcandidatespageCreate: (data: GetCaseTimesheetInput, params: RequestParams = {}) =>
      this.request<GetFinancialBillingTimesheetServiceFeeOutputPageModelMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingtimesheetservicefee/getcandidatespage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingTimesheetServiceFee
     * @name WebFinancialBillingFinancialbillingtimesheetservicefeeGetsubtotalpageCreate
     * @summary 账单工时小计列表（以所属人分组统计） (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingtimesheetservicefee/getsubtotalpage
     * @secure
     */
    webFinancialBillingFinancialbillingtimesheetservicefeeGetsubtotalpageCreate: (data: StringEntityDto, params: RequestParams = {}) =>
      this.request<GetFinancialBillingTimesheetSubtotalOutputListMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingtimesheetservicefee/getsubtotalpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingTimesheetServiceFee
     * @name WebFinancialBillingFinancialbillingtimesheetservicefeeGetwriteoffpageCreate
     * @summary 已销账工时列表 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingtimesheetservicefee/getwriteoffpage
     * @secure
     */
    webFinancialBillingFinancialbillingtimesheetservicefeeGetwriteoffpageCreate: (
      data: GetFinancialBillingWriteOffTimesheetInput,
      params: RequestParams = {}
    ) =>
      this.request<GetFinancialBillingTimesheetServiceFeeOutputPageModelMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingtimesheetservicefee/getwriteoffpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingTimesheetServiceFee
     * @name WebFinancialBillingFinancialbillingtimesheetservicefeeGettimesheetrecoveryrateCreate
     * @summary 账单工时回收率统计 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingtimesheetservicefee/gettimesheetrecoveryrate
     * @secure
     */
    webFinancialBillingFinancialbillingtimesheetservicefeeGettimesheetrecoveryrateCreate: (data: StringEntityDto, params: RequestParams = {}) =>
      this.request<GetFinancialBillingTimesheetRecoveryRateOutputMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingtimesheetservicefee/gettimesheetrecoveryrate`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingTimesheetServiceFee
     * @name WebFinancialBillingFinancialbillingtimesheetservicefeeGetfinancialbillingtimesheetservicefeeexportCreate
     * @summary 账单工时服务费列表导出 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingtimesheetservicefee/getfinancialbillingtimesheetservicefeeexport
     * @secure
     */
    webFinancialBillingFinancialbillingtimesheetservicefeeGetfinancialbillingtimesheetservicefeeexportCreate: (
      data: GetFinancialBillingTimesheetServiceFeeInputExportInput,
      params: RequestParams = {}
    ) =>
      this.request<ExcelFileDtoMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingtimesheetservicefee/getfinancialbillingtimesheetservicefeeexport`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingTimesheetServiceFee
     * @name WebFinancialBillingFinancialbillingtimesheetservicefeeGetCreate
     * @summary 获取详情 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingtimesheetservicefee/get/{id}
     * @secure
     */
    webFinancialBillingFinancialbillingtimesheetservicefeeGetCreate: (id: string, params: RequestParams = {}) =>
      this.request<FinancialBillingTimesheetServiceFeeDtoMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingtimesheetservicefee/get/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingTimesheetServiceFee
     * @name WebFinancialBillingFinancialbillingtimesheetservicefeeGetforeditCreate
     * @summary 获取修改实体 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingtimesheetservicefee/getforedit/{id}
     * @secure
     */
    webFinancialBillingFinancialbillingtimesheetservicefeeGetforeditCreate: (id: string, params: RequestParams = {}) =>
      this.request<CreateFinancialBillingTimesheetServiceFeeInputForEditModelMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingtimesheetservicefee/getforedit/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingTimesheetServiceFee
     * @name WebFinancialBillingFinancialbillingtimesheetservicefeePostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingtimesheetservicefee/post
     * @secure
     */
    webFinancialBillingFinancialbillingtimesheetservicefeePostCreate: (data: CreateFinancialBillingTimesheetServiceFeeInput, params: RequestParams = {}) =>
      this.request<FinancialBillingTimesheetServiceFeeDtoMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingtimesheetservicefee/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingTimesheetServiceFee
     * @name WebFinancialBillingFinancialbillingtimesheetservicefeePutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/financial/billing/financialbillingtimesheetservicefee/put/{id}
     * @secure
     */
    webFinancialBillingFinancialbillingtimesheetservicefeePutUpdate: (
      id: string,
      data: CreateFinancialBillingTimesheetServiceFeeInput,
      params: RequestParams = {}
    ) =>
      this.request<FinancialBillingTimesheetServiceFeeDtoMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingtimesheetservicefee/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingTimesheetServiceFee
     * @name WebFinancialBillingFinancialbillingtimesheetservicefeeDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/financial/billing/financialbillingtimesheetservicefee/delete/{id}
     * @secure
     */
    webFinancialBillingFinancialbillingtimesheetservicefeeDeleteDelete: (id: string, data: StringEntityDto, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/billing/financialbillingtimesheetservicefee/delete/${id}`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingTimesheetServiceFee
     * @name WebFinancialBillingFinancialbillingtimesheetservicefeeBatchdeleteCreate
     * @summary 批量删除 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingtimesheetservicefee/batchdelete
     * @secure
     */
    webFinancialBillingFinancialbillingtimesheetservicefeeBatchdeleteCreate: (data: StringEntityDto[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/billing/financialbillingtimesheetservicefee/batchdelete`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingUpperLimit
     * @name WebFinancialBillingFinancialbillingupperlimitGetpageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingupperlimit/getpage
     * @secure
     */
    webFinancialBillingFinancialbillingupperlimitGetpageCreate: (data: GetFinancialBillingUpperLimitInput, params: RequestParams = {}) =>
      this.request<FinancialBillingUpperLimitDtoPageModelMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingupperlimit/getpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingUpperLimit
     * @name WebFinancialBillingFinancialbillingupperlimitListCreate
     * @summary 列表获取所有 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingupperlimit/list
     * @secure
     */
    webFinancialBillingFinancialbillingupperlimitListCreate: (
      query?: {
        /** 账单编号 */
        billingId?: string;
        /** 应用范围 */
        scope?: string;
        /** 是否包含税费 */
        isIncludeTaxFee?: string;
        /** 是否设为固定收费 */
        isSetAsFixedFee?: string;
        /**
         * 上限金额
         * @format double
         */
        amount?: number;
        /**
         * 小计金额
         * @format double
         */
        subtotal?: number;
        /** 备注 */
        remark?: string;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<FinancialBillingUpperLimitDtoListMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingupperlimit/list`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingUpperLimit
     * @name WebFinancialBillingFinancialbillingupperlimitGetfinancialbillingupperlimitexportCreate
     * @summary 列表导出 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingupperlimit/getfinancialbillingupperlimitexport
     * @secure
     */
    webFinancialBillingFinancialbillingupperlimitGetfinancialbillingupperlimitexportCreate: (
      data: GetFinancialBillingUpperLimitInputExportInput,
      params: RequestParams = {}
    ) =>
      this.request<ExcelFileDtoMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingupperlimit/getfinancialbillingupperlimitexport`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingUpperLimit
     * @name WebFinancialBillingFinancialbillingupperlimitGetselectCreate
     * @summary 选择框 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingupperlimit/getselect
     * @secure
     */
    webFinancialBillingFinancialbillingupperlimitGetselectCreate: (data: GetOrganizationUnitsInput, params: RequestParams = {}) =>
      this.request<ComboboxItemDtoListMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingupperlimit/getselect`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingUpperLimit
     * @name WebFinancialBillingFinancialbillingupperlimitGetCreate
     * @summary 获取详情 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingupperlimit/get/{id}
     * @secure
     */
    webFinancialBillingFinancialbillingupperlimitGetCreate: (id: string, params: RequestParams = {}) =>
      this.request<FinancialBillingUpperLimitDtoMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingupperlimit/get/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingUpperLimit
     * @name WebFinancialBillingFinancialbillingupperlimitGetforeditCreate
     * @summary 获取修改实体 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingupperlimit/getforedit/{id}
     * @secure
     */
    webFinancialBillingFinancialbillingupperlimitGetforeditCreate: (id: string, params: RequestParams = {}) =>
      this.request<CreateFinancialBillingUpperLimitInputForEditModelMessageModel, void>({
        path: `/api/web/financial/billing/financialbillingupperlimit/getforedit/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingUpperLimit
     * @name WebFinancialBillingFinancialbillingupperlimitPostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingupperlimit/post
     * @secure
     */
    webFinancialBillingFinancialbillingupperlimitPostCreate: (data: CreateFinancialBillingUpperLimitInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/billing/financialbillingupperlimit/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingUpperLimit
     * @name WebFinancialBillingFinancialbillingupperlimitPutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/financial/billing/financialbillingupperlimit/put/{id}
     * @secure
     */
    webFinancialBillingFinancialbillingupperlimitPutUpdate: (id: string, data: CreateFinancialBillingUpperLimitInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/billing/financialbillingupperlimit/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingUpperLimit
     * @name WebFinancialBillingFinancialbillingupperlimitDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/financial/billing/financialbillingupperlimit/delete/{id}
     * @secure
     */
    webFinancialBillingFinancialbillingupperlimitDeleteDelete: (id: string, data: StringEntityDto, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/billing/financialbillingupperlimit/delete/${id}`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialBillingUpperLimit
     * @name WebFinancialBillingFinancialbillingupperlimitBatchdeleteCreate
     * @summary 批量删除 (Auth policies: Web)
     * @request POST:/api/web/financial/billing/financialbillingupperlimit/batchdelete
     * @secure
     */
    webFinancialBillingFinancialbillingupperlimitBatchdeleteCreate: (data: StringEntityDto[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/billing/financialbillingupperlimit/batchdelete`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialChiefAccountant
     * @name WebFinancialBaseFinancialchiefaccountantGetpageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/financial/base/financialchiefaccountant/getpage
     * @secure
     */
    webFinancialBaseFinancialchiefaccountantGetpageCreate: (data: GetFinancialChiefAccountantInput, params: RequestParams = {}) =>
      this.request<GetFinancialChiefAccountantOutputPageModelMessageModel, void>({
        path: `/api/web/financial/base/financialchiefaccountant/getpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialChiefAccountant
     * @name WebFinancialBaseFinancialchiefaccountantListCreate
     * @summary 列表获取所有 (Auth policies: Web)
     * @request POST:/api/web/financial/base/financialchiefaccountant/list
     * @secure
     */
    webFinancialBaseFinancialchiefaccountantListCreate: (data: GetFinancialChiefAccountantInput, params: RequestParams = {}) =>
      this.request<GetFinancialChiefAccountantOutputListMessageModel, void>({
        path: `/api/web/financial/base/financialchiefaccountant/list`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialChiefAccountant
     * @name WebFinancialBaseFinancialchiefaccountantGetfinancialchiefaccountantexportCreate
     * @summary 列表导出 (Auth policies: Web)
     * @request POST:/api/web/financial/base/financialchiefaccountant/getfinancialchiefaccountantexport
     * @secure
     */
    webFinancialBaseFinancialchiefaccountantGetfinancialchiefaccountantexportCreate: (
      data: GetFinancialChiefAccountantInputExportInput,
      params: RequestParams = {}
    ) =>
      this.request<ExcelFileDtoMessageModel, void>({
        path: `/api/web/financial/base/financialchiefaccountant/getfinancialchiefaccountantexport`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialChiefAccountant
     * @name WebFinancialBaseFinancialchiefaccountantGetCreate
     * @summary 获取详情 (Auth policies: Web)
     * @request POST:/api/web/financial/base/financialchiefaccountant/get/{id}
     * @secure
     */
    webFinancialBaseFinancialchiefaccountantGetCreate: (id: string, params: RequestParams = {}) =>
      this.request<FinancialChiefAccountantDtoMessageModel, void>({
        path: `/api/web/financial/base/financialchiefaccountant/get/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialChiefAccountant
     * @name WebFinancialBaseFinancialchiefaccountantGetforeditCreate
     * @summary 获取修改实体 (Auth policies: Web)
     * @request POST:/api/web/financial/base/financialchiefaccountant/getforedit/{id}
     * @secure
     */
    webFinancialBaseFinancialchiefaccountantGetforeditCreate: (id: string, params: RequestParams = {}) =>
      this.request<GetFinancialChiefAccountantForEditOutputForEditModelMessageModel, void>({
        path: `/api/web/financial/base/financialchiefaccountant/getforedit/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialChiefAccountant
     * @name WebFinancialBaseFinancialchiefaccountantPostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/financial/base/financialchiefaccountant/post
     * @secure
     */
    webFinancialBaseFinancialchiefaccountantPostCreate: (data: CreateFinancialChiefAccountantInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/base/financialchiefaccountant/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialChiefAccountant
     * @name WebFinancialBaseFinancialchiefaccountantPutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/financial/base/financialchiefaccountant/put/{id}
     * @secure
     */
    webFinancialBaseFinancialchiefaccountantPutUpdate: (id: string, data: CreateFinancialChiefAccountantInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/base/financialchiefaccountant/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialChiefAccountant
     * @name WebFinancialBaseFinancialchiefaccountantDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/financial/base/financialchiefaccountant/delete
     * @secure
     */
    webFinancialBaseFinancialchiefaccountantDeleteDelete: (data: StringEntityDto, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/base/financialchiefaccountant/delete`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialChiefAccountant
     * @name WebFinancialBaseFinancialchiefaccountantBatchdeleteCreate
     * @summary 批量删除 (Auth policies: Web)
     * @request POST:/api/web/financial/base/financialchiefaccountant/batchdelete
     * @secure
     */
    webFinancialBaseFinancialchiefaccountantBatchdeleteCreate: (data: StringEntityDto[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/base/financialchiefaccountant/batchdelete`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialExpenseApply
     * @name WebFinancialExpenseFinancialexpenseapplyGetpageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/financial/expense/financialexpenseapply/getpage
     * @secure
     */
    webFinancialExpenseFinancialexpenseapplyGetpageCreate: (data: GetFinancialExpenseApplyInput, params: RequestParams = {}) =>
      this.request<GetFinancialExpenseApplyOutputPageModelMessageModel, void>({
        path: `/api/web/financial/expense/financialexpenseapply/getpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialExpenseApply
     * @name WebFinancialExpenseFinancialexpenseapplyListCreate
     * @summary 列表获取所有 (Auth policies: Web)
     * @request POST:/api/web/financial/expense/financialexpenseapply/list
     * @secure
     */
    webFinancialExpenseFinancialexpenseapplyListCreate: (
      query?: {
        /**
         * 开始日期时间
         * @format date-time
         */
        applyDateStartDate?: string;
        /**
         * 结束日期时间
         * @format date-time
         */
        applyDateEndDate?: string;
        /**
         * 开始日期时间
         * @format date-time
         */
        feeDateStartDate?: string;
        /**
         * 结束日期时间
         * @format date-time
         */
        feeDateEndDate?: string;
        /** 账单编号 */
        billingId?: string;
        /** 费用名称 */
        name?: string;
        /** 费用代码 */
        expenseCode?: string;
        /** 费用类别 */
        category?: string;
        /** 费用子类别 */
        subCategory?: string;
        /** 币种 */
        currency?: string;
        /**
         * 币种汇率
         * @format double
         */
        exchangeRate?: number;
        /** 报销类型 */
        expenseType?: string;
        /** 是否代收代付 */
        isPobo?: string;
        /**
         * 费用金额
         * @format double
         */
        amount?: number;
        /**
         * 计数数量
         * @format double
         */
        count?: number;
        /**
         * 开始日期时间
         * @format date-time
         */
        payDateStartDate?: string;
        /**
         * 结束日期时间
         * @format date-time
         */
        payDateEndDate?: string;
        /**
         * 税率
         * @format double
         */
        taxRate?: number;
        /**
         * 税额
         * @format double
         */
        taxAmount?: number;
        /**
         * 小计金额
         * @format double
         */
        subtotal?: number;
        /** 是否计费 */
        isBillable?: string;
        /** 报销单编号 */
        expenserportId?: string;
        /** 是否已开票 */
        isInvoiced?: string;
        /** 商户名称 */
        merchant?: string;
        /** 案件编号 */
        caseId?: string;
        /** 费用报销人 */
        employeeId?: string;
        /** 费用描述 */
        narrative?: string;
        /** 发票/收据类型 */
        receiptType?: string;
        /** 流水编号 */
        serialId?: string;
        /** 原系统主键编号 */
        originalId?: string;
        /** 流程数据大状态 */
        status?: string;
        /**
         * 开始日期时间
         * @format date-time
         */
        submittedOnStartDate?: string;
        /**
         * 结束日期时间
         * @format date-time
         */
        submittedOnEndDate?: string;
        /** 工作流状态 */
        flowState?: string;
        /** 申请人 */
        applicant?: string;
        /**
         * 开始日期时间
         * @format date-time
         */
        flowEndDateTimeStartDate?: string;
        /**
         * 结束日期时间
         * @format date-time
         */
        flowEndDateTimeEndDate?: string;
        /** 是否超期 */
        isOverdue?: boolean;
        /** 备注 */
        remark?: string;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<GetFinancialExpenseApplyOutputListMessageModel, void>({
        path: `/api/web/financial/expense/financialexpenseapply/list`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialExpenseApply
     * @name WebFinancialExpenseFinancialexpenseapplyGetfinancialexpenseapplyexportCreate
     * @summary 列表导出 (Auth policies: Web)
     * @request POST:/api/web/financial/expense/financialexpenseapply/getfinancialexpenseapplyexport
     * @secure
     */
    webFinancialExpenseFinancialexpenseapplyGetfinancialexpenseapplyexportCreate: (
      data: GetFinancialExpenseApplyInputExportInput,
      params: RequestParams = {}
    ) =>
      this.request<ExcelFileDtoMessageModel, void>({
        path: `/api/web/financial/expense/financialexpenseapply/getfinancialexpenseapplyexport`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialExpenseApply
     * @name WebFinancialExpenseFinancialexpenseapplyGetselectCreate
     * @summary 选择框 (Auth policies: Web)
     * @request POST:/api/web/financial/expense/financialexpenseapply/getselect
     * @secure
     */
    webFinancialExpenseFinancialexpenseapplyGetselectCreate: (data: GetOrganizationUnitsInput, params: RequestParams = {}) =>
      this.request<ComboboxItemDtoListMessageModel, void>({
        path: `/api/web/financial/expense/financialexpenseapply/getselect`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialExpenseApply
     * @name WebFinancialExpenseFinancialexpenseapplyGetCreate
     * @summary 获取详情 (Auth policies: Web)
     * @request POST:/api/web/financial/expense/financialexpenseapply/get/{id}
     * @secure
     */
    webFinancialExpenseFinancialexpenseapplyGetCreate: (id: string, params: RequestParams = {}) =>
      this.request<FinancialExpenseApplyDtoMessageModel, void>({
        path: `/api/web/financial/expense/financialexpenseapply/get/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialExpenseApply
     * @name WebFinancialExpenseFinancialexpenseapplyGetforeditCreate
     * @summary 获取修改实体 (Auth policies: Web)
     * @request POST:/api/web/financial/expense/financialexpenseapply/getforedit/{id}
     * @secure
     */
    webFinancialExpenseFinancialexpenseapplyGetforeditCreate: (id: string, params: RequestParams = {}) =>
      this.request<GetFinancialExpenseApplyForEditOutputForEditModelMessageModel, void>({
        path: `/api/web/financial/expense/financialexpenseapply/getforedit/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialExpenseApply
     * @name WebFinancialExpenseFinancialexpenseapplyPostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/financial/expense/financialexpenseapply/post
     * @secure
     */
    webFinancialExpenseFinancialexpenseapplyPostCreate: (data: CreateFinancialExpenseApplyInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/expense/financialexpenseapply/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialExpenseApply
     * @name WebFinancialExpenseFinancialexpenseapplyPutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/financial/expense/financialexpenseapply/put/{id}
     * @secure
     */
    webFinancialExpenseFinancialexpenseapplyPutUpdate: (id: string, data: CreateFinancialExpenseApplyInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/expense/financialexpenseapply/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialExpenseApply
     * @name WebFinancialExpenseFinancialexpenseapplyDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/financial/expense/financialexpenseapply/delete/{id}
     * @secure
     */
    webFinancialExpenseFinancialexpenseapplyDeleteDelete: (id: string, data: StringEntityDto, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/expense/financialexpenseapply/delete/${id}`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialExpenseApply
     * @name WebFinancialExpenseFinancialexpenseapplyBatchdeleteCreate
     * @summary 批量删除 (Auth policies: Web)
     * @request POST:/api/web/financial/expense/financialexpenseapply/batchdelete
     * @secure
     */
    webFinancialExpenseFinancialexpenseapplyBatchdeleteCreate: (data: StringEntityDto[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/expense/financialexpenseapply/batchdelete`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialExpenseItem
     * @name WebFinancialExpenseFinancialexpenseitemGetpageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/financial/expense/financialexpenseitem/getpage
     * @secure
     */
    webFinancialExpenseFinancialexpenseitemGetpageCreate: (data: GetFinancialExpenseItemInput, params: RequestParams = {}) =>
      this.request<GetFinancialExpenseItemOutputPageModelMessageModel, void>({
        path: `/api/web/financial/expense/financialexpenseitem/getpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialExpenseItem
     * @name WebFinancialExpenseFinancialexpenseitemListCreate
     * @summary 列表获取所有 (Auth policies: Web)
     * @request POST:/api/web/financial/expense/financialexpenseitem/list
     * @secure
     */
    webFinancialExpenseFinancialexpenseitemListCreate: (
      query?: {
        /** 费用申请编号 */
        applyId?: string;
        /** 费用报销人 */
        employeeId?: string;
        /**
         * 开始日期时间
         * @format date-time
         */
        expenseDateStartDate?: string;
        /**
         * 结束日期时间
         * @format date-time
         */
        expenseDateEndDate?: string;
        /** 费用类型 */
        category?: string;
        /** 费用类型子类 */
        subCategory?: string;
        /** 账单编号 */
        billingId?: string;
        /** 是否入账 */
        isBillable?: string;
        /**
         * 金额
         * @format double
         */
        amount?: number;
        /** 币种 */
        currency?: string;
        /** 描述 */
        narrative?: string;
        /** 类型 */
        expenseType?: string;
        /** 商户名称 */
        merchant?: string;
        /** 案件编号 */
        caseId?: string;
        /** 客户编号 */
        clientId?: string;
        /** 收费代码 */
        chargeCode?: string;
        /** 费用详细描述 */
        expenseNarrative?: string;
        /** 报销单ID */
        expenseReportId?: string;
        /** 是否已开票 */
        isInvoiced?: string;
        /** 是否为POBO */
        isPobo?: string;
        /**
         * 开始日期时间
         * @format date-time
         */
        payDateStartDate?: string;
        /**
         * 结束日期时间
         * @format date-time
         */
        payDateEndDate?: string;
        /**
         * 税率
         * @format double
         */
        taxRate?: number;
        /**
         * 税额
         * @format double
         */
        taxAmount?: number;
        /**
         * 小计金额
         * @format double
         */
        subtotal?: number;
        /** 发票编号 */
        invoiceId?: string;
        /** 发票类型 */
        invoiceType?: string;
        /** 结算币种 */
        settlementCurrency?: string;
        /**
         * 结算汇率
         * @format double
         */
        settlementRate?: number;
        /**
         * 结算金额
         * @format double
         */
        settlementAmount?: number;
        /** 是否销账 */
        isWriteOff?: string;
        /** 销账类型 */
        writeOffType?: string;
        /** 销账理由 */
        writeOffRemark?: string;
        /**
         * 数量
         * @format double
         */
        count?: number;
        /** 参与人员 */
        attendees?: string;
        /** 备注 */
        remark?: string;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<GetFinancialExpenseItemOutputListMessageModel, void>({
        path: `/api/web/financial/expense/financialexpenseitem/list`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialExpenseItem
     * @name WebFinancialExpenseFinancialexpenseitemGetfinancialexpenseitemexportCreate
     * @summary 列表导出 (Auth policies: Web)
     * @request POST:/api/web/financial/expense/financialexpenseitem/getfinancialexpenseitemexport
     * @secure
     */
    webFinancialExpenseFinancialexpenseitemGetfinancialexpenseitemexportCreate: (data: GetFinancialExpenseItemInputExportInput, params: RequestParams = {}) =>
      this.request<ExcelFileDtoMessageModel, void>({
        path: `/api/web/financial/expense/financialexpenseitem/getfinancialexpenseitemexport`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialExpenseItem
     * @name WebFinancialExpenseFinancialexpenseitemGetCreate
     * @summary 获取详情 (Auth policies: Web)
     * @request POST:/api/web/financial/expense/financialexpenseitem/get/{id}
     * @secure
     */
    webFinancialExpenseFinancialexpenseitemGetCreate: (id: string, params: RequestParams = {}) =>
      this.request<FinancialExpenseItemDtoMessageModel, void>({
        path: `/api/web/financial/expense/financialexpenseitem/get/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialExpenseItem
     * @name WebFinancialExpenseFinancialexpenseitemGetforeditCreate
     * @summary 获取修改实体 (Auth policies: Web)
     * @request POST:/api/web/financial/expense/financialexpenseitem/getforedit/{id}
     * @secure
     */
    webFinancialExpenseFinancialexpenseitemGetforeditCreate: (id: string, params: RequestParams = {}) =>
      this.request<GetFinancialExpenseItemForEditOutputForEditModelMessageModel, void>({
        path: `/api/web/financial/expense/financialexpenseitem/getforedit/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialExpenseItem
     * @name WebFinancialExpenseFinancialexpenseitemPostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/financial/expense/financialexpenseitem/post
     * @secure
     */
    webFinancialExpenseFinancialexpenseitemPostCreate: (data: CreateFinancialExpenseItemInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/expense/financialexpenseitem/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialExpenseItem
     * @name WebFinancialExpenseFinancialexpenseitemPutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/financial/expense/financialexpenseitem/put/{id}
     * @secure
     */
    webFinancialExpenseFinancialexpenseitemPutUpdate: (id: string, data: CreateFinancialExpenseItemInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/expense/financialexpenseitem/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialExpenseItem
     * @name WebFinancialExpenseFinancialexpenseitemDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/financial/expense/financialexpenseitem/delete/{id}
     * @secure
     */
    webFinancialExpenseFinancialexpenseitemDeleteDelete: (id: string, data: StringEntityDto, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/expense/financialexpenseitem/delete/${id}`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialExpenseItem
     * @name WebFinancialExpenseFinancialexpenseitemBatchdeleteCreate
     * @summary 批量删除 (Auth policies: Web)
     * @request POST:/api/web/financial/expense/financialexpenseitem/batchdelete
     * @secure
     */
    webFinancialExpenseFinancialexpenseitemBatchdeleteCreate: (data: StringEntityDto[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/expense/financialexpenseitem/batchdelete`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialInvoice
     * @name WebFinancialInvoiceFinancialinvoiceGetDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/financial/invoice/financialinvoice/get/{id}
     * @secure
     */
    webFinancialInvoiceFinancialinvoiceGetDetail: (id: string, params: RequestParams = {}) =>
      this.request<GetFinancialInvoiceOutputMessageModel, void>({
        path: `/api/web/financial/invoice/financialinvoice/get/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialInvoice
     * @name WebFinancialInvoiceFinancialinvoiceGetinvoiceattachmentlistDetail
     * @summary 获取发票附件 (Auth policies: Web)
     * @request GET:/api/web/financial/invoice/financialinvoice/getinvoiceattachmentlist/{id}
     * @secure
     */
    webFinancialInvoiceFinancialinvoiceGetinvoiceattachmentlistDetail: (id: string, params: RequestParams = {}) =>
      this.request<AttachmentDownloadDtoListMessageModel, void>({
        path: `/api/web/financial/invoice/financialinvoice/getinvoiceattachmentlist/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialInvoice
     * @name WebFinancialInvoiceFinancialinvoiceUpdinvoicenoCreate
     * @summary 修改发票编号 (Auth policies: Web)
     * @request POST:/api/web/financial/invoice/financialinvoice/updinvoiceno
     * @secure
     */
    webFinancialInvoiceFinancialinvoiceUpdinvoicenoCreate: (data: UpdInvoiceNoInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/invoice/financialinvoice/updinvoiceno`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialInvoice
     * @name WebFinancialInvoiceFinancialinvoiceUpdinvoiceattachmentidCreate
     * @summary 上传发票 (Auth policies: Web)
     * @request POST:/api/web/financial/invoice/financialinvoice/updinvoiceattachmentid
     * @secure
     */
    webFinancialInvoiceFinancialinvoiceUpdinvoiceattachmentidCreate: (data: UpdInvoiceAttachmentIdInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/invoice/financialinvoice/updinvoiceattachmentid`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialInvoice
     * @name WebFinancialInvoiceFinancialinvoiceGetunuploadedinvoiceattachmentcountList
     * @summary 获取24小时内申请人未上传已开发票附件 (Auth policies: Web)
     * @request GET:/api/web/financial/invoice/financialinvoice/getunuploadedinvoiceattachmentcount
     * @secure
     */
    webFinancialInvoiceFinancialinvoiceGetunuploadedinvoiceattachmentcountList: (params: RequestParams = {}) =>
      this.request<Int32MessageModel, void>({
        path: `/api/web/financial/invoice/financialinvoice/getunuploadedinvoiceattachmentcount`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialInvoice
     * @name WebFinancialInvoiceFinancialinvoiceInvalidateapplyCreate
     * @summary 申请作废 (Auth policies: Web)
     * @request POST:/api/web/financial/invoice/financialinvoice/invalidateapply
     * @secure
     */
    webFinancialInvoiceFinancialinvoiceInvalidateapplyCreate: (data: InvalidateInvoiceDto, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/invoice/financialinvoice/invalidateapply`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialInvoice
     * @name WebFinancialInvoiceFinancialinvoiceGetredpunchedpageCreate
     * @summary 红冲发票分页 (Auth policies: Web)
     * @request POST:/api/web/financial/invoice/financialinvoice/getredpunchedpage
     * @secure
     */
    webFinancialInvoiceFinancialinvoiceGetredpunchedpageCreate: (data: GetFinancialInvoiceRedPunchInput, params: RequestParams = {}) =>
      this.request<GetFinancialInvoiceOutputPageModelMessageModel, void>({
        path: `/api/web/financial/invoice/financialinvoice/getredpunchedpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialInvoice
     * @name WebFinancialInvoiceFinancialinvoiceGetredpunchedexportCreate
     * @summary 已红冲列表导出 (Auth policies: Web)
     * @request POST:/api/web/financial/invoice/financialinvoice/getredpunchedexport
     * @secure
     */
    webFinancialInvoiceFinancialinvoiceGetredpunchedexportCreate: (data: GetFinancialInvoiceInvalidateInputExportInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/financial/invoice/financialinvoice/getredpunchedexport`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialInvoice
     * @name WebFinancialInvoiceFinancialinvoiceU8InvoicedDetail
     * @summary U8开票 (Auth policies: Web)
     * @request GET:/api/web/financial/invoice/financialinvoice/u8invoiced/{id}
     * @secure
     */
    webFinancialInvoiceFinancialinvoiceU8InvoicedDetail: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/invoice/financialinvoice/u8invoiced/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialInvoiceAllocation
     * @name WebFinancialInvoiceFinancialinvoiceallocationGetpageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/financial/invoice/financialinvoiceallocation/getpage
     * @secure
     */
    webFinancialInvoiceFinancialinvoiceallocationGetpageCreate: (data: GetFinancialInvoiceAllocationInput, params: RequestParams = {}) =>
      this.request<FinancialInvoiceAllocationDtoPageModelMessageModel, void>({
        path: `/api/web/financial/invoice/financialinvoiceallocation/getpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialInvoiceAllocation
     * @name WebFinancialInvoiceFinancialinvoiceallocationListCreate
     * @summary 列表获取所有 (Auth policies: Web)
     * @request POST:/api/web/financial/invoice/financialinvoiceallocation/list
     * @secure
     */
    webFinancialInvoiceFinancialinvoiceallocationListCreate: (
      query?: {
        /** 发票编号 */
        invoiceId?: string;
        /** 账单编号 */
        billingId?: string;
        /** 发票申请编号 */
        invoiceApplyId?: string;
        /** 案件编号 */
        caseId?: string;
        /**
         * 分配比例
         * @format double
         */
        allocationRate?: number;
        /**
         * 分配金额
         * @format double
         */
        allocationAmount?: number;
        /**
         * 尾差
         * @format double
         */
        roundingDifference?: number;
        /** 分配策略 */
        allocationStrattegy?: string;
        /** 分配人 */
        employeeId?: string;
        /** 是否保密 */
        ifConfidential?: string;
        /** 备注 */
        remark?: string;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<FinancialInvoiceAllocationDtoListMessageModel, void>({
        path: `/api/web/financial/invoice/financialinvoiceallocation/list`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialInvoiceAllocation
     * @name WebFinancialInvoiceFinancialinvoiceallocationGetfinancialinvoiceallocationexportCreate
     * @summary 列表导出 (Auth policies: Web)
     * @request POST:/api/web/financial/invoice/financialinvoiceallocation/getfinancialinvoiceallocationexport
     * @secure
     */
    webFinancialInvoiceFinancialinvoiceallocationGetfinancialinvoiceallocationexportCreate: (
      data: GetFinancialInvoiceAllocationInputExportInput,
      params: RequestParams = {}
    ) =>
      this.request<ExcelFileDtoMessageModel, void>({
        path: `/api/web/financial/invoice/financialinvoiceallocation/getfinancialinvoiceallocationexport`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialInvoiceAllocation
     * @name WebFinancialInvoiceFinancialinvoiceallocationGetselectCreate
     * @summary 选择框 (Auth policies: Web)
     * @request POST:/api/web/financial/invoice/financialinvoiceallocation/getselect
     * @secure
     */
    webFinancialInvoiceFinancialinvoiceallocationGetselectCreate: (data: GetOrganizationUnitsInput, params: RequestParams = {}) =>
      this.request<ComboboxItemDtoListMessageModel, void>({
        path: `/api/web/financial/invoice/financialinvoiceallocation/getselect`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialInvoiceAllocation
     * @name WebFinancialInvoiceFinancialinvoiceallocationGetCreate
     * @summary 获取详情 (Auth policies: Web)
     * @request POST:/api/web/financial/invoice/financialinvoiceallocation/get/{id}
     * @secure
     */
    webFinancialInvoiceFinancialinvoiceallocationGetCreate: (id: string, params: RequestParams = {}) =>
      this.request<FinancialInvoiceAllocationDtoMessageModel, void>({
        path: `/api/web/financial/invoice/financialinvoiceallocation/get/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialInvoiceAllocation
     * @name WebFinancialInvoiceFinancialinvoiceallocationGetforeditCreate
     * @summary 获取修改实体 (Auth policies: Web)
     * @request POST:/api/web/financial/invoice/financialinvoiceallocation/getforedit/{id}
     * @secure
     */
    webFinancialInvoiceFinancialinvoiceallocationGetforeditCreate: (id: string, params: RequestParams = {}) =>
      this.request<CreateFinancialInvoiceAllocationInputMessageModel, void>({
        path: `/api/web/financial/invoice/financialinvoiceallocation/getforedit/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialInvoiceAllocation
     * @name WebFinancialInvoiceFinancialinvoiceallocationPostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/financial/invoice/financialinvoiceallocation/post
     * @secure
     */
    webFinancialInvoiceFinancialinvoiceallocationPostCreate: (data: CreateFinancialInvoiceAllocationInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/invoice/financialinvoiceallocation/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialInvoiceAllocation
     * @name WebFinancialInvoiceFinancialinvoiceallocationPutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/financial/invoice/financialinvoiceallocation/put/{id}
     * @secure
     */
    webFinancialInvoiceFinancialinvoiceallocationPutUpdate: (id: string, data: CreateFinancialInvoiceAllocationInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/invoice/financialinvoiceallocation/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialInvoiceAllocation
     * @name WebFinancialInvoiceFinancialinvoiceallocationDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/financial/invoice/financialinvoiceallocation/delete/{id}
     * @secure
     */
    webFinancialInvoiceFinancialinvoiceallocationDeleteDelete: (id: string, data: StringEntityDto, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/invoice/financialinvoiceallocation/delete/${id}`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialInvoiceAllocation
     * @name WebFinancialInvoiceFinancialinvoiceallocationBatchdeleteCreate
     * @summary 批量删除 (Auth policies: Web)
     * @request POST:/api/web/financial/invoice/financialinvoiceallocation/batchdelete
     * @secure
     */
    webFinancialInvoiceFinancialinvoiceallocationBatchdeleteCreate: (data: StringEntityDto[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/invoice/financialinvoiceallocation/batchdelete`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialInvoiceApply
     * @name WebFinancialInvoiceFinancialinvoiceapplyPostdraftCreate
     * @summary 新增保存发票申请草稿(发票申请-暂存) (Auth policies: Web)
     * @request POST:/api/web/financial/invoice/financialinvoiceapply/postdraft
     * @secure
     */
    webFinancialInvoiceFinancialinvoiceapplyPostdraftCreate: (data: CreateDraftFinancialInvoiceApplyInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/invoice/financialinvoiceapply/postdraft`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialInvoiceApply
     * @name WebFinancialInvoiceFinancialinvoiceapplyPostCreate
     * @summary 提交发票申请(发票申请-提交) (Auth policies: Web)
     * @request POST:/api/web/financial/invoice/financialinvoiceapply/post
     * @secure
     */
    webFinancialInvoiceFinancialinvoiceapplyPostCreate: (data: CreateFinancialInvoiceApplyInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/invoice/financialinvoiceapply/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialInvoiceApply
     * @name WebFinancialInvoiceFinancialinvoiceapplyGetinvoiceapplyinfoDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/financial/invoice/financialinvoiceapply/getinvoiceapplyinfo/{id}
     * @secure
     */
    webFinancialInvoiceFinancialinvoiceapplyGetinvoiceapplyinfoDetail: (id: string, params: RequestParams = {}) =>
      this.request<GetFinancialInvoiceApplyOutputMessageModel, void>({
        path: `/api/web/financial/invoice/financialinvoiceapply/getinvoiceapplyinfo/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialInvoiceApply
     * @name WebFinancialInvoiceFinancialinvoiceapplyGetinvoicelistDetail
     * @summary 获取发票列表 (Auth policies: Web)
     * @request GET:/api/web/financial/invoice/financialinvoiceapply/getinvoicelist/{id}
     * @secure
     */
    webFinancialInvoiceFinancialinvoiceapplyGetinvoicelistDetail: (id: string, params: RequestParams = {}) =>
      this.request<GetInvoiceInfoOutputListMessageModel, void>({
        path: `/api/web/financial/invoice/financialinvoiceapply/getinvoicelist/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialInvoiceApply
     * @name WebFinancialInvoiceFinancialinvoiceapplyGetinvoiceapplyattachmentlistDetail
     * @summary 获取发票附件列表 (Auth policies: Web)
     * @request GET:/api/web/financial/invoice/financialinvoiceapply/getinvoiceapplyattachmentlist/{id}
     * @secure
     */
    webFinancialInvoiceFinancialinvoiceapplyGetinvoiceapplyattachmentlistDetail: (id: string, params: RequestParams = {}) =>
      this.request<GetInvoiceApplyAttachmentListOutputMessageModel, void>({
        path: `/api/web/financial/invoice/financialinvoiceapply/getinvoiceapplyattachmentlist/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialInvoiceApply
     * @name WebFinancialInvoiceFinancialinvoiceapplyGetmyinvoiceapplypageCreate
     * @summary 我的发票-分页 (Auth policies: Web)
     * @request POST:/api/web/financial/invoice/financialinvoiceapply/getmyinvoiceapplypage
     * @secure
     */
    webFinancialInvoiceFinancialinvoiceapplyGetmyinvoiceapplypageCreate: (data: GetMyInvoiceApplyListInput, params: RequestParams = {}) =>
      this.request<GetInvoiceApplyListOutputPageModelMessageModel, void>({
        path: `/api/web/financial/invoice/financialinvoiceapply/getmyinvoiceapplypage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialInvoiceApply
     * @name WebFinancialInvoiceFinancialinvoiceapplyGetinvoiceapplymanagepageCreate
     * @summary 发票管理-分页 (Auth policies: Web)
     * @request POST:/api/web/financial/invoice/financialinvoiceapply/getinvoiceapplymanagepage
     * @secure
     */
    webFinancialInvoiceFinancialinvoiceapplyGetinvoiceapplymanagepageCreate: (data: GetFinancialInvoiceApplyManageInput, params: RequestParams = {}) =>
      this.request<GetInvoiceApplyListOutputPageModelMessageModel, void>({
        path: `/api/web/financial/invoice/financialinvoiceapply/getinvoiceapplymanagepage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialInvoiceApply
     * @name WebFinancialInvoiceFinancialinvoiceapplyGetmyfinancialinvoiceapplyexportCreate
     * @summary 我的发票-导出 (Auth policies: Web)
     * @request POST:/api/web/financial/invoice/financialinvoiceapply/getmyfinancialinvoiceapplyexport
     * @secure
     */
    webFinancialInvoiceFinancialinvoiceapplyGetmyfinancialinvoiceapplyexportCreate: (
      data: GetFinancialInvoiceApplyInputExportInput,
      params: RequestParams = {}
    ) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/financial/invoice/financialinvoiceapply/getmyfinancialinvoiceapplyexport`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialInvoiceApply
     * @name WebFinancialInvoiceFinancialinvoiceapplyGetinvoiceapplymanageexportCreate
     * @summary 发票管理-导出 (Auth policies: Web)
     * @request POST:/api/web/financial/invoice/financialinvoiceapply/getinvoiceapplymanageexport
     * @secure
     */
    webFinancialInvoiceFinancialinvoiceapplyGetinvoiceapplymanageexportCreate: (
      data: GetFinancialInvoiceApplyManageInputExportInput,
      params: RequestParams = {}
    ) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/financial/invoice/financialinvoiceapply/getinvoiceapplymanageexport`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialInvoiceApply
     * @name WebFinancialInvoiceFinancialinvoiceapplyDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/financial/invoice/financialinvoiceapply/delete/{id}
     * @secure
     */
    webFinancialInvoiceFinancialinvoiceapplyDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/invoice/financialinvoiceapply/delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialInvoiceApply
     * @name WebFinancialInvoiceFinancialinvoiceapplyBatchdeleteCreate
     * @summary 批量删除 (Auth policies: Web)
     * @request POST:/api/web/financial/invoice/financialinvoiceapply/batchdelete
     * @secure
     */
    webFinancialInvoiceFinancialinvoiceapplyBatchdeleteCreate: (data: StringEntityDto[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/invoice/financialinvoiceapply/batchdelete`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialInvoiceApply
     * @name WebFinancialInvoiceFinancialinvoiceapplySubmitinvoiceapplyUpdate
     * @summary 发票列表-提交 (Auth policies: Web)
     * @request PUT:/api/web/financial/invoice/financialinvoiceapply/submitinvoiceapply/{id}
     * @secure
     */
    webFinancialInvoiceFinancialinvoiceapplySubmitinvoiceapplyUpdate: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/invoice/financialinvoiceapply/submitinvoiceapply/${id}`,
        method: 'PUT',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialInvoiceApply
     * @name WebFinancialInvoiceFinancialinvoiceapplyWithdrawinvoiceapplyUpdate
     * @summary 发票列表-撤销 (Auth policies: Web)
     * @request PUT:/api/web/financial/invoice/financialinvoiceapply/withdrawinvoiceapply/{id}
     * @secure
     */
    webFinancialInvoiceFinancialinvoiceapplyWithdrawinvoiceapplyUpdate: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/invoice/financialinvoiceapply/withdrawinvoiceapply/${id}`,
        method: 'PUT',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialInvoiceApply
     * @name WebFinancialInvoiceFinancialinvoiceapplyInvoicedapplyUpdate
     * @summary 发票列表-开票 (Auth policies: Web)
     * @request PUT:/api/web/financial/invoice/financialinvoiceapply/invoicedapply/{id}
     * @secure
     */
    webFinancialInvoiceFinancialinvoiceapplyInvoicedapplyUpdate: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/invoice/financialinvoiceapply/invoicedapply/${id}`,
        method: 'PUT',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialInvoiceApply
     * @name WebFinancialInvoiceFinancialinvoiceapplyInvalidateinvoiceapplyCreate
     * @summary 发票列表-作废 (Auth policies: Web)
     * @request POST:/api/web/financial/invoice/financialinvoiceapply/invalidateinvoiceapply
     * @secure
     */
    webFinancialInvoiceFinancialinvoiceapplyInvalidateinvoiceapplyCreate: (data: InvalidateInvoiceApplyDto, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/invoice/financialinvoiceapply/invalidateinvoiceapply`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialInvoiceApply
     * @name WebFinancialInvoiceFinancialinvoiceapplyUpdinvoiceapplyattachmentCreate
     * @summary 修改附件 (Auth policies: Web)
     * @request POST:/api/web/financial/invoice/financialinvoiceapply/updinvoiceapplyattachment
     * @secure
     */
    webFinancialInvoiceFinancialinvoiceapplyUpdinvoiceapplyattachmentCreate: (data: UpdInvoiceApplyAttachmentInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/invoice/financialinvoiceapply/updinvoiceapplyattachment`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialInvoiceApply
     * @name WebFinancialInvoiceFinancialinvoiceapplyGetstatisticalsummaryyearsList
     * @summary 发票收款-年 (Auth policies: Web)
     * @request GET:/api/web/financial/invoice/financialinvoiceapply/getstatisticalsummaryyears
     * @secure
     */
    webFinancialInvoiceFinancialinvoiceapplyGetstatisticalsummaryyearsList: (params: RequestParams = {}) =>
      this.request<GetStatisticalSummaryYearsOutputListMessageModel, void>({
        path: `/api/web/financial/invoice/financialinvoiceapply/getstatisticalsummaryyears`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialInvoiceApply
     * @name WebFinancialInvoiceFinancialinvoiceapplyGetstatisticalsummaryCreate
     * @summary 发票收款-统计概要 (Auth policies: Web)
     * @request POST:/api/web/financial/invoice/financialinvoiceapply/getstatisticalsummary
     * @secure
     */
    webFinancialInvoiceFinancialinvoiceapplyGetstatisticalsummaryCreate: (data: GetFinancialInvoiceApplyManageInput, params: RequestParams = {}) =>
      this.request<GetStatisticalSummaryOutputMessageModel, void>({
        path: `/api/web/financial/invoice/financialinvoiceapply/getstatisticalsummary`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialInvoiceInvalidate
     * @name WebFinancialInvoiceFinancialinvoiceinvalidateGetmypageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/financial/invoice/financialinvoiceinvalidate/getmypage
     * @secure
     */
    webFinancialInvoiceFinancialinvoiceinvalidateGetmypageCreate: (data: GetFinancialInvoiceInvalidateInput, params: RequestParams = {}) =>
      this.request<GetFinancialInvoiceInvalidateOutputPageModelMessageModel, void>({
        path: `/api/web/financial/invoice/financialinvoiceinvalidate/getmypage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialInvoiceInvalidate
     * @name WebFinancialInvoiceFinancialinvoiceinvalidateGetfinancialinvoiceinvalidateauditpageCreate
     * @summary 申请审核分页 (Auth policies: Web)
     * @request POST:/api/web/financial/invoice/financialinvoiceinvalidate/getfinancialinvoiceinvalidateauditpage
     * @secure
     */
    webFinancialInvoiceFinancialinvoiceinvalidateGetfinancialinvoiceinvalidateauditpageCreate: (
      data: GetFinancialInvoiceInvalidateInput,
      params: RequestParams = {}
    ) =>
      this.request<GetFinancialInvoiceInvalidateOutputPageModelMessageModel, void>({
        path: `/api/web/financial/invoice/financialinvoiceinvalidate/getfinancialinvoiceinvalidateauditpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialInvoiceInvalidate
     * @name WebFinancialInvoiceFinancialinvoiceinvalidateListCreate
     * @summary 列表获取所有 (Auth policies: Web)
     * @request POST:/api/web/financial/invoice/financialinvoiceinvalidate/list
     * @secure
     */
    webFinancialInvoiceFinancialinvoiceinvalidateListCreate: (data: GetFinancialInvoiceInvalidateInput, params: RequestParams = {}) =>
      this.request<GetFinancialInvoiceInvalidateOutputListMessageModel, void>({
        path: `/api/web/financial/invoice/financialinvoiceinvalidate/list`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialInvoiceInvalidate
     * @name WebFinancialInvoiceFinancialinvoiceinvalidateGetmyapplyexportCreate
     * @summary 列表导出 (Auth policies: Web)
     * @request POST:/api/web/financial/invoice/financialinvoiceinvalidate/getmyapplyexport
     * @secure
     */
    webFinancialInvoiceFinancialinvoiceinvalidateGetmyapplyexportCreate: (data: GetFinancialInvoiceInvalidateInputExportInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/financial/invoice/financialinvoiceinvalidate/getmyapplyexport`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialInvoiceInvalidate
     * @name WebFinancialInvoiceFinancialinvoiceinvalidateGetfinancialinvoiceinvalidateauditexportCreate
     * @summary 列表导出 (Auth policies: Web)
     * @request POST:/api/web/financial/invoice/financialinvoiceinvalidate/getfinancialinvoiceinvalidateauditexport
     * @secure
     */
    webFinancialInvoiceFinancialinvoiceinvalidateGetfinancialinvoiceinvalidateauditexportCreate: (
      data: GetFinancialInvoiceInvalidateInputExportInput,
      params: RequestParams = {}
    ) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/financial/invoice/financialinvoiceinvalidate/getfinancialinvoiceinvalidateauditexport`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialInvoiceInvalidate
     * @name WebFinancialInvoiceFinancialinvoiceinvalidateGetDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/financial/invoice/financialinvoiceinvalidate/get/{id}
     * @secure
     */
    webFinancialInvoiceFinancialinvoiceinvalidateGetDetail: (id: string, params: RequestParams = {}) =>
      this.request<GetFinancialInvoiceInvalidateOutputMessageModel, void>({
        path: `/api/web/financial/invoice/financialinvoiceinvalidate/get/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialInvoiceInvalidate
     * @name WebFinancialInvoiceFinancialinvoiceinvalidateGetforeditCreate
     * @summary 获取修改实体 (Auth policies: Web)
     * @request POST:/api/web/financial/invoice/financialinvoiceinvalidate/getforedit/{id}
     * @secure
     */
    webFinancialInvoiceFinancialinvoiceinvalidateGetforeditCreate: (id: string, params: RequestParams = {}) =>
      this.request<GetFinancialInvoiceInvalidateForEditOutputForEditModelMessageModel, void>({
        path: `/api/web/financial/invoice/financialinvoiceinvalidate/getforedit/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialInvoiceInvalidate
     * @name WebFinancialInvoiceFinancialinvoiceinvalidatePostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/financial/invoice/financialinvoiceinvalidate/post
     * @secure
     */
    webFinancialInvoiceFinancialinvoiceinvalidatePostCreate: (data: CreateFinancialInvoiceInvalidateInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/invoice/financialinvoiceinvalidate/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialInvoiceInvalidate
     * @name WebFinancialInvoiceFinancialinvoiceinvalidatePutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/financial/invoice/financialinvoiceinvalidate/put/{id}
     * @secure
     */
    webFinancialInvoiceFinancialinvoiceinvalidatePutUpdate: (id: string, data: CreateFinancialInvoiceInvalidateInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/invoice/financialinvoiceinvalidate/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialInvoiceInvalidate
     * @name WebFinancialInvoiceFinancialinvoiceinvalidateDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/financial/invoice/financialinvoiceinvalidate/delete
     * @secure
     */
    webFinancialInvoiceFinancialinvoiceinvalidateDeleteDelete: (data: StringEntityDto, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/invoice/financialinvoiceinvalidate/delete`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialInvoiceInvalidate
     * @name WebFinancialInvoiceFinancialinvoiceinvalidateBatchdeleteCreate
     * @summary 批量删除 (Auth policies: Web)
     * @request POST:/api/web/financial/invoice/financialinvoiceinvalidate/batchdelete
     * @secure
     */
    webFinancialInvoiceFinancialinvoiceinvalidateBatchdeleteCreate: (data: StringEntityDto[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/invoice/financialinvoiceinvalidate/batchdelete`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialPayerInfo
     * @name WebFinancialBaseFinancialpayerinfoGetpageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/financial/base/financialpayerinfo/getpage
     * @secure
     */
    webFinancialBaseFinancialpayerinfoGetpageCreate: (data: GetFinancialPayerInfoInput, params: RequestParams = {}) =>
      this.request<FinancialPayerInfoDtoPageModelMessageModel, void>({
        path: `/api/web/financial/base/financialpayerinfo/getpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialPayerInfo
     * @name WebFinancialBaseFinancialpayerinfoListCreate
     * @summary 列表获取所有 (Auth policies: Web)
     * @request POST:/api/web/financial/base/financialpayerinfo/list
     * @secure
     */
    webFinancialBaseFinancialpayerinfoListCreate: (
      query?: {
        /** 付款方名称 */
        name?: string;
        /** 外文名称 */
        foreignName?: string;
        /** 曾用名 */
        formerName?: string;
        /** 别名 */
        altName?: string;
        /** 证件类型 */
        creditType?: string;
        /** 证件号码 */
        creditCode?: string;
        /** 税号 */
        taxNumber?: string;
        /** 邮箱地址 */
        emailAddress?: string;
        /** 联系电话 */
        landline?: string;
        /** 所属行业 */
        industry?: string;
        /** 区域城市 */
        area?: string;
        /** 注册名称 */
        regName?: string;
        /** 注册号 */
        regNo?: string;
        /** 银行名称 */
        bankName?: string;
        /** 银行账号 */
        bankAccount?: string;
        /** 开户行 */
        openingBranchName?: string;
        /** 开户行地址 */
        openingBranchAddress?: string;
        /** 银行联系电话 */
        bankLandline?: string;
        /** 所属客户编号 */
        clientId?: string;
        /** 客户性质 */
        customerNature?: string;
        /** 备注 */
        remark?: string;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<FinancialPayerInfoDtoListMessageModel, void>({
        path: `/api/web/financial/base/financialpayerinfo/list`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialPayerInfo
     * @name WebFinancialBaseFinancialpayerinfoGetfinancialpayerinfoexportCreate
     * @summary 列表导出 (Auth policies: Web)
     * @request POST:/api/web/financial/base/financialpayerinfo/getfinancialpayerinfoexport
     * @secure
     */
    webFinancialBaseFinancialpayerinfoGetfinancialpayerinfoexportCreate: (data: GetFinancialPayerInfoInputExportInput, params: RequestParams = {}) =>
      this.request<ExcelFileDtoMessageModel, void>({
        path: `/api/web/financial/base/financialpayerinfo/getfinancialpayerinfoexport`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialPayerInfo
     * @name WebFinancialBaseFinancialpayerinfoGetselectCreate
     * @summary 选择框 (Auth policies: Web)
     * @request POST:/api/web/financial/base/financialpayerinfo/getselect
     * @secure
     */
    webFinancialBaseFinancialpayerinfoGetselectCreate: (data: GetOrganizationUnitsInput, params: RequestParams = {}) =>
      this.request<ComboboxItemDtoListMessageModel, void>({
        path: `/api/web/financial/base/financialpayerinfo/getselect`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialPayerInfo
     * @name WebFinancialBaseFinancialpayerinfoGetCreate
     * @summary 获取详情 (Auth policies: Web)
     * @request POST:/api/web/financial/base/financialpayerinfo/get/{id}
     * @secure
     */
    webFinancialBaseFinancialpayerinfoGetCreate: (id: string, params: RequestParams = {}) =>
      this.request<FinancialPayerInfoDtoMessageModel, void>({
        path: `/api/web/financial/base/financialpayerinfo/get/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialPayerInfo
     * @name WebFinancialBaseFinancialpayerinfoGetforeditCreate
     * @summary 获取修改实体 (Auth policies: Web)
     * @request POST:/api/web/financial/base/financialpayerinfo/getforedit/{id}
     * @secure
     */
    webFinancialBaseFinancialpayerinfoGetforeditCreate: (id: string, params: RequestParams = {}) =>
      this.request<CreateFinancialPayerInfoInputMessageModel, void>({
        path: `/api/web/financial/base/financialpayerinfo/getforedit/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialPayerInfo
     * @name WebFinancialBaseFinancialpayerinfoPostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/financial/base/financialpayerinfo/post
     * @secure
     */
    webFinancialBaseFinancialpayerinfoPostCreate: (data: CreateFinancialPayerInfoInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/base/financialpayerinfo/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialPayerInfo
     * @name WebFinancialBaseFinancialpayerinfoPutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/financial/base/financialpayerinfo/put/{id}
     * @secure
     */
    webFinancialBaseFinancialpayerinfoPutUpdate: (id: string, data: CreateFinancialPayerInfoInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/base/financialpayerinfo/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialPayerInfo
     * @name WebFinancialBaseFinancialpayerinfoDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/financial/base/financialpayerinfo/delete/{id}
     * @secure
     */
    webFinancialBaseFinancialpayerinfoDeleteDelete: (id: string, data: StringEntityDto, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/base/financialpayerinfo/delete/${id}`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialPayerInfo
     * @name WebFinancialBaseFinancialpayerinfoBatchdeleteCreate
     * @summary 批量删除 (Auth policies: Web)
     * @request POST:/api/web/financial/base/financialpayerinfo/batchdelete
     * @secure
     */
    webFinancialBaseFinancialpayerinfoBatchdeleteCreate: (data: StringEntityDto[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/base/financialpayerinfo/batchdelete`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialPublicReceipt
     * @name WebFinancialReceiptFinancialpublicreceiptGetpageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/financial/receipt/financialpublicreceipt/getpage
     * @secure
     */
    webFinancialReceiptFinancialpublicreceiptGetpageCreate: (data: GetFinancialPublicReceiptInput, params: RequestParams = {}) =>
      this.request<GetFinancialPublicReceiptOutputPageModelMessageModel, void>({
        path: `/api/web/financial/receipt/financialpublicreceipt/getpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialPublicReceipt
     * @name WebFinancialReceiptFinancialpublicreceiptListCreate
     * @summary 列表获取所有 (Auth policies: Web)
     * @request POST:/api/web/financial/receipt/financialpublicreceipt/list
     * @secure
     */
    webFinancialReceiptFinancialpublicreceiptListCreate: (
      query?: {
        /** 案件编号 */
        caseId?: string;
        /** 客户编号 */
        clientId?: string;
        /** 收款方式 */
        payMode?: string;
        /** 付款方名称 */
        payerName?: string;
        /** 是否境外汇款 */
        isOverseasPay?: string;
        /** 汇款国家 */
        remittanceCountry?: string;
        /** 收款币种 */
        currency?: string;
        /** 支票编号 */
        checkNo?: string;
        /**
         * 收款总额
         * @format double
         */
        payAmount?: number;
        /**
         * 开始日期时间
         * @format date-time
         */
        receiptDateStartDate?: string;
        /**
         * 结束日期时间
         * @format date-time
         */
        receiptDateEndDate?: string;
        /** 结算币种 */
        settlementCurrency?: string;
        /**
         * 结算汇率
         * @format double
         */
        settlementRate?: number;
        /**
         * 结算金额
         * @format double
         */
        settlementAmount?: number;
        /**
         * 已认领收款金额
         * @format double
         */
        receiptAmount?: number;
        /** 是否全额认领 */
        isFullyClaimed?: string;
        /**
         * 认领尾差金额
         * @format double
         */
        roundingDifference?: number;
        /** 银行流水号 */
        bankSerialId?: string;
        /** 认领人 */
        claimEmployeeId?: string;
        /** 附件 */
        attachmentId?: string;
        /** 付款说明 */
        paymentExplain?: string;
        /** 认领收款编号 */
        receiptId?: string;
        /** 账单编号 */
        billingId?: string;
        /** 发票编号 */
        invoiceId?: string;
        /** 备注 */
        remark?: string;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<GetFinancialPublicReceiptOutputListMessageModel, void>({
        path: `/api/web/financial/receipt/financialpublicreceipt/list`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialPublicReceipt
     * @name WebFinancialReceiptFinancialpublicreceiptGetfinancialpublicreceiptexportCreate
     * @summary 列表导出 (Auth policies: Web)
     * @request POST:/api/web/financial/receipt/financialpublicreceipt/getfinancialpublicreceiptexport
     * @secure
     */
    webFinancialReceiptFinancialpublicreceiptGetfinancialpublicreceiptexportCreate: (
      data: GetFinancialPublicReceiptInputExportInput,
      params: RequestParams = {}
    ) =>
      this.request<ExcelFileDtoMessageModel, void>({
        path: `/api/web/financial/receipt/financialpublicreceipt/getfinancialpublicreceiptexport`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialPublicReceipt
     * @name WebFinancialReceiptFinancialpublicreceiptGetreceiptclaimedpageCreate
     * @summary 收款认领列表 (Auth policies: Web)
     * @request POST:/api/web/financial/receipt/financialpublicreceipt/getreceiptclaimedpage
     * @secure
     */
    webFinancialReceiptFinancialpublicreceiptGetreceiptclaimedpageCreate: (data: GetReceiptClaimedInput, params: RequestParams = {}) =>
      this.request<GetReceiptClaimedOutputPageModelMessageModel, void>({
        path: `/api/web/financial/receipt/financialpublicreceipt/getreceiptclaimedpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialPublicReceipt
     * @name WebFinancialReceiptFinancialpublicreceiptGetreceiptclaimedpageexportCreate
     * @summary 收款认领列表导出 (Auth policies: Web)
     * @request POST:/api/web/financial/receipt/financialpublicreceipt/getreceiptclaimedpageexport
     * @secure
     */
    webFinancialReceiptFinancialpublicreceiptGetreceiptclaimedpageexportCreate: (data: GetReceiptClaimedInputExportInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/financial/receipt/financialpublicreceipt/getreceiptclaimedpageexport`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialPublicReceipt
     * @name WebFinancialReceiptFinancialpublicreceiptGetinternaltransferpageCreate
     * @summary 内部划转列表 (Auth policies: Web)
     * @request POST:/api/web/financial/receipt/financialpublicreceipt/getinternaltransferpage
     * @secure
     */
    webFinancialReceiptFinancialpublicreceiptGetinternaltransferpageCreate: (data: GetReceiptClaimedInput, params: RequestParams = {}) =>
      this.request<GetReceiptClaimedOutputPageModelMessageModel, void>({
        path: `/api/web/financial/receipt/financialpublicreceipt/getinternaltransferpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialPublicReceipt
     * @name WebFinancialReceiptFinancialpublicreceiptGetinternaltransferpageexportCreate
     * @summary 内部划转列表导出 (Auth policies: Web)
     * @request POST:/api/web/financial/receipt/financialpublicreceipt/getinternaltransferpageexport
     * @secure
     */
    webFinancialReceiptFinancialpublicreceiptGetinternaltransferpageexportCreate: (data: GetReceiptClaimedInputExportInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/financial/receipt/financialpublicreceipt/getinternaltransferpageexport`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialPublicReceipt
     * @name WebFinancialReceiptFinancialpublicreceiptGetCreate
     * @summary 获取详情 (Auth policies: Web)
     * @request POST:/api/web/financial/receipt/financialpublicreceipt/get
     * @secure
     */
    webFinancialReceiptFinancialpublicreceiptGetCreate: (data: StringEntityDto, params: RequestParams = {}) =>
      this.request<GetFinancialPublicReceiptOutputMessageModel, void>({
        path: `/api/web/financial/receipt/financialpublicreceipt/get`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialPublicReceipt
     * @name WebFinancialReceiptFinancialpublicreceiptGetforeditCreate
     * @summary 获取修改实体 (Auth policies: Web)
     * @request POST:/api/web/financial/receipt/financialpublicreceipt/getforedit/{id}
     * @secure
     */
    webFinancialReceiptFinancialpublicreceiptGetforeditCreate: (id: string, params: RequestParams = {}) =>
      this.request<GetFinancialPublicReceiptForEditOutputForEditModelMessageModel, void>({
        path: `/api/web/financial/receipt/financialpublicreceipt/getforedit/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialPublicReceipt
     * @name WebFinancialReceiptFinancialpublicreceiptPostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/financial/receipt/financialpublicreceipt/post
     * @secure
     */
    webFinancialReceiptFinancialpublicreceiptPostCreate: (data: CreateFinancialPublicReceiptInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/receipt/financialpublicreceipt/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialPublicReceipt
     * @name WebFinancialReceiptFinancialpublicreceiptPutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/financial/receipt/financialpublicreceipt/put/{id}
     * @secure
     */
    webFinancialReceiptFinancialpublicreceiptPutUpdate: (id: string, data: CreateFinancialPublicReceiptInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/receipt/financialpublicreceipt/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialPublicReceipt
     * @name WebFinancialReceiptFinancialpublicreceiptCreateorupdatepublicreceiptCreate
     * @summary 新增或修改收款 (Auth policies: Web)
     * @request POST:/api/web/financial/receipt/financialpublicreceipt/createorupdatepublicreceipt
     * @secure
     */
    webFinancialReceiptFinancialpublicreceiptCreateorupdatepublicreceiptCreate: (data: CreateFinancialPublicReceiptInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/financial/receipt/financialpublicreceipt/createorupdatepublicreceipt`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialPublicReceipt
     * @name WebFinancialReceiptFinancialpublicreceiptDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/financial/receipt/financialpublicreceipt/delete/{id}
     * @secure
     */
    webFinancialReceiptFinancialpublicreceiptDeleteDelete: (id: string, data: StringEntityDto, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/receipt/financialpublicreceipt/delete/${id}`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialPublicReceipt
     * @name WebFinancialReceiptFinancialpublicreceiptBatchdeleteCreate
     * @summary 批量删除 (Auth policies: Web)
     * @request POST:/api/web/financial/receipt/financialpublicreceipt/batchdelete
     * @secure
     */
    webFinancialReceiptFinancialpublicreceiptBatchdeleteCreate: (data: StringEntityDto[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/receipt/financialpublicreceipt/batchdelete`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialPublicReceipt
     * @name WebFinancialReceiptFinancialpublicreceiptImportcitiCreate
     * @summary 收款导入_花旗银行 (Auth policies: Web)
     * @request POST:/api/web/financial/receipt/financialpublicreceipt/importciti
     * @secure
     */
    webFinancialReceiptFinancialpublicreceiptImportcitiCreate: (
      data: {
        /**
         * 文件
         * @format binary
         */
        File?: File;
        /** 路径 */
        Path?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<ReceiptImportOutputMessageModel, void>({
        path: `/api/web/financial/receipt/financialpublicreceipt/importciti`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialPublicReceipt
     * @name WebFinancialReceiptFinancialpublicreceiptImportbocCreate
     * @summary 收款导入_中国银行 (Auth policies: Web)
     * @request POST:/api/web/financial/receipt/financialpublicreceipt/importboc
     * @secure
     */
    webFinancialReceiptFinancialpublicreceiptImportbocCreate: (
      data: {
        /**
         * 文件
         * @format binary
         */
        File?: File;
        /** 路径 */
        Path?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<ReceiptImportOutputMessageModel, void>({
        path: `/api/web/financial/receipt/financialpublicreceipt/importboc`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialPublicReceipt
     * @name WebFinancialReceiptFinancialpublicreceiptGetimportreceiptpageCreate
     * @summary 获取导入数据分页 (Auth policies: Web)
     * @request POST:/api/web/financial/receipt/financialpublicreceipt/getimportreceiptpage
     * @secure
     */
    webFinancialReceiptFinancialpublicreceiptGetimportreceiptpageCreate: (data: GetReceiptImportInput, params: RequestParams = {}) =>
      this.request<CreateFinancialPublicReceiptInputPageModelMessageModel, void>({
        path: `/api/web/financial/receipt/financialpublicreceipt/getimportreceiptpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialPublicReceipt
     * @name WebFinancialReceiptFinancialpublicreceiptSavefinancialpublicreceiptlistCreate
     * @summary 批量保存导入数据 (Auth policies: Web)
     * @request POST:/api/web/financial/receipt/financialpublicreceipt/savefinancialpublicreceiptlist
     * @secure
     */
    webFinancialReceiptFinancialpublicreceiptSavefinancialpublicreceiptlistCreate: (data: CreateFinancialPublicReceiptInput[], params: RequestParams = {}) =>
      this.request<SaveImportOutputMessageModel, void>({
        path: `/api/web/financial/receipt/financialpublicreceipt/savefinancialpublicreceiptlist`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialPublicReceipt
     * @name WebFinancialReceiptFinancialpublicreceiptUpdatefinancialpublicreceiptofficeCreate
     * @summary 内部划转 (Auth policies: Web)
     * @request POST:/api/web/financial/receipt/financialpublicreceipt/updatefinancialpublicreceiptoffice
     * @secure
     */
    webFinancialReceiptFinancialpublicreceiptUpdatefinancialpublicreceiptofficeCreate: (
      data: UpdateFinancialPublicReceiptOfficeInput,
      params: RequestParams = {}
    ) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/financial/receipt/financialpublicreceipt/updatefinancialpublicreceiptoffice`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialPublicReceipt
     * @name WebFinancialReceiptFinancialpublicreceiptGetbillingcaselistCreate
     * @summary 根据账单Ids获取案件信息 (Auth policies: Web)
     * @request POST:/api/web/financial/receipt/financialpublicreceipt/getbillingcaselist
     * @secure
     */
    webFinancialReceiptFinancialpublicreceiptGetbillingcaselistCreate: (data: GetBillingCaseListInput, params: RequestParams = {}) =>
      this.request<BillingCaseInfoListMessageModel, void>({
        path: `/api/web/financial/receipt/financialpublicreceipt/getbillingcaselist`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialReceipt
     * @name WebFinancialReceiptFinancialreceiptGetpageCreate
     * @summary 认领确认分页 (Auth policies: Web)
     * @request POST:/api/web/financial/receipt/financialreceipt/getpage
     * @secure
     */
    webFinancialReceiptFinancialreceiptGetpageCreate: (data: GetFinancialReceiptInput, params: RequestParams = {}) =>
      this.request<GetFinancialReceiptOutputPageModelMessageModel, void>({
        path: `/api/web/financial/receipt/financialreceipt/getpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialReceipt
     * @name WebFinancialReceiptFinancialreceiptListCreate
     * @summary 列表获取所有 (Auth policies: Web)
     * @request POST:/api/web/financial/receipt/financialreceipt/list
     * @secure
     */
    webFinancialReceiptFinancialreceiptListCreate: (
      query?: {
        /** 关键字 */
        filter?: string;
        /** 账单编号 */
        billingSerialId?: string;
        /** 付款方名称 */
        payerName?: string;
        /**
         * 最小金额
         * @format double
         */
        payAmountMinValue?: number;
        /**
         * 最大金额
         * @format double
         */
        payAmountMaxValue?: number;
        /** 办公室 */
        officeId?: string[];
        /**
         * 开始日期时间
         * @format date-time
         */
        receiptDateStartDate?: string;
        /**
         * 结束日期时间
         * @format date-time
         */
        receiptDateEndDate?: string;
        /** 状态 */
        flowState?: string[];
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<GetFinancialReceiptOutputListMessageModel, void>({
        path: `/api/web/financial/receipt/financialreceipt/list`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialReceipt
     * @name WebFinancialReceiptFinancialreceiptGetfinancialreceiptexportCreate
     * @summary 认领确认导出 (Auth policies: Web)
     * @request POST:/api/web/financial/receipt/financialreceipt/getfinancialreceiptexport
     * @secure
     */
    webFinancialReceiptFinancialreceiptGetfinancialreceiptexportCreate: (data: GetFinancialReceiptInputExportInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/financial/receipt/financialreceipt/getfinancialreceiptexport`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialReceipt
     * @name WebFinancialReceiptFinancialreceiptGetmyreceiptpageCreate
     * @summary 我的收款 (Auth policies: Web)
     * @request POST:/api/web/financial/receipt/financialreceipt/getmyreceiptpage
     * @secure
     */
    webFinancialReceiptFinancialreceiptGetmyreceiptpageCreate: (data: GetMyReceiptInput, params: RequestParams = {}) =>
      this.request<GetMyReceiptOutputPageModelMessageModel, void>({
        path: `/api/web/financial/receipt/financialreceipt/getmyreceiptpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialReceipt
     * @name WebFinancialReceiptFinancialreceiptGetmyreceiptpageexportCreate
     * @summary 我的收款导出 (Auth policies: Web)
     * @request POST:/api/web/financial/receipt/financialreceipt/getmyreceiptpageexport
     * @secure
     */
    webFinancialReceiptFinancialreceiptGetmyreceiptpageexportCreate: (data: GetMyReceiptInputExportInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/financial/receipt/financialreceipt/getmyreceiptpageexport`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialReceipt
     * @name WebFinancialReceiptFinancialreceiptGetCreate
     * @summary 获取详情 (Auth policies: Web)
     * @request POST:/api/web/financial/receipt/financialreceipt/get/{id}
     * @secure
     */
    webFinancialReceiptFinancialreceiptGetCreate: (id: string, params: RequestParams = {}) =>
      this.request<FinancialReceiptDtoMessageModel, void>({
        path: `/api/web/financial/receipt/financialreceipt/get/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialReceipt
     * @name WebFinancialReceiptFinancialreceiptGetforeditCreate
     * @summary 获取修改实体 (Auth policies: Web)
     * @request POST:/api/web/financial/receipt/financialreceipt/getforedit/{id}
     * @secure
     */
    webFinancialReceiptFinancialreceiptGetforeditCreate: (id: string, params: RequestParams = {}) =>
      this.request<GetFinancialReceiptForEditOutputForEditModelMessageModel, void>({
        path: `/api/web/financial/receipt/financialreceipt/getforedit/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialReceipt
     * @name WebFinancialReceiptFinancialreceiptPostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/financial/receipt/financialreceipt/post
     * @secure
     */
    webFinancialReceiptFinancialreceiptPostCreate: (data: CreateFinancialReceiptInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/receipt/financialreceipt/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialReceipt
     * @name WebFinancialReceiptFinancialreceiptCreatefinancialreceiptlistCreate
     * @summary 批量添加 (Auth policies: Web)
     * @request POST:/api/web/financial/receipt/financialreceipt/createfinancialreceiptlist
     * @secure
     */
    webFinancialReceiptFinancialreceiptCreatefinancialreceiptlistCreate: (data: CreateFinancialReceiptInput[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/receipt/financialreceipt/createfinancialreceiptlist`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialReceipt
     * @name WebFinancialReceiptFinancialreceiptPutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/financial/receipt/financialreceipt/put/{id}
     * @secure
     */
    webFinancialReceiptFinancialreceiptPutUpdate: (id: string, data: CreateFinancialReceiptInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/receipt/financialreceipt/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialReceipt
     * @name WebFinancialReceiptFinancialreceiptDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/financial/receipt/financialreceipt/delete/{id}
     * @secure
     */
    webFinancialReceiptFinancialreceiptDeleteDelete: (id: string, data: StringEntityDto, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/receipt/financialreceipt/delete/${id}`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialReceipt
     * @name WebFinancialReceiptFinancialreceiptBatchdeleteCreate
     * @summary 批量删除 (Auth policies: Web)
     * @request POST:/api/web/financial/receipt/financialreceipt/batchdelete
     * @secure
     */
    webFinancialReceiptFinancialreceiptBatchdeleteCreate: (data: StringEntityDto[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/receipt/financialreceipt/batchdelete`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialReceipt
     * @name WebFinancialReceiptFinancialreceiptClaimedthroughCreate
     * @summary 秘书确认通过 (Auth policies: Web)
     * @request POST:/api/web/financial/receipt/financialreceipt/claimedthrough
     * @secure
     */
    webFinancialReceiptFinancialreceiptClaimedthroughCreate: (data: ApprovalInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/financial/receipt/financialreceipt/claimedthrough`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialReceipt
     * @name WebFinancialReceiptFinancialreceiptClaimedreturnCreate
     * @summary 秘书确认退回 (Auth policies: Web)
     * @request POST:/api/web/financial/receipt/financialreceipt/claimedreturn
     * @secure
     */
    webFinancialReceiptFinancialreceiptClaimedreturnCreate: (data: ApprovalInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/financial/receipt/financialreceipt/claimedreturn`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialReceipt
     * @name WebFinancialReceiptFinancialreceiptReviewpassedCreate
     * @summary 财务复核确认 (Auth policies: Web)
     * @request POST:/api/web/financial/receipt/financialreceipt/reviewpassed
     * @secure
     */
    webFinancialReceiptFinancialreceiptReviewpassedCreate: (data: ApprovalInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/financial/receipt/financialreceipt/reviewpassed`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialReceipt
     * @name WebFinancialReceiptFinancialreceiptGetreceiptallocationpageCreate
     * @summary 待分配列表 (Auth policies: Web)
     * @request POST:/api/web/financial/receipt/financialreceipt/getreceiptallocationpage
     * @secure
     */
    webFinancialReceiptFinancialreceiptGetreceiptallocationpageCreate: (data: GetReceiptAllocationInput, params: RequestParams = {}) =>
      this.request<GetReceiptAllocationOutputPageModelMessageModel, void>({
        path: `/api/web/financial/receipt/financialreceipt/getreceiptallocationpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialReceipt
     * @name WebFinancialReceiptFinancialreceiptGetreceiptallocationpageexportCreate
     * @summary 待分配列表导出 (Auth policies: Web)
     * @request POST:/api/web/financial/receipt/financialreceipt/getreceiptallocationpageexport
     * @secure
     */
    webFinancialReceiptFinancialreceiptGetreceiptallocationpageexportCreate: (data: GetReceiptAllocationInputExportInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/financial/receipt/financialreceipt/getreceiptallocationpageexport`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialReceipt
     * @name WebFinancialReceiptFinancialreceiptGetreceiptallocationinfoCreate
     * @summary 获取待分配详情 (Auth policies: Web)
     * @request POST:/api/web/financial/receipt/financialreceipt/getreceiptallocationinfo
     * @secure
     */
    webFinancialReceiptFinancialreceiptGetreceiptallocationinfoCreate: (data: StringEntityDto, params: RequestParams = {}) =>
      this.request<GetReceiptAllocationOutputMessageModel, void>({
        path: `/api/web/financial/receipt/financialreceipt/getreceiptallocationinfo`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialReceipt
     * @name WebFinancialReceiptFinancialreceiptBillingfinaldraftCreate
     * @summary 账单终稿后触发 (Auth policies: Web)
     * @request POST:/api/web/financial/receipt/financialreceipt/billingfinaldraft
     * @secure
     */
    webFinancialReceiptFinancialreceiptBillingfinaldraftCreate: (data: StringEntityDto, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/receipt/financialreceipt/billingfinaldraft`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialSettings
     * @name WebFinancialBaseFinancialsettingsFinancialcurrencysettingsforeditList
     * @summary 获取财务本位币设置编辑对象 (Auth policies: Web)
     * @request GET:/api/web/financial/base/financialsettings/financialcurrencysettingsforedit
     * @secure
     */
    webFinancialBaseFinancialsettingsFinancialcurrencysettingsforeditList: (params: RequestParams = {}) =>
      this.request<FinancialCurrencySettingsInputMessageModel, void>({
        path: `/api/web/financial/base/financialsettings/financialcurrencysettingsforedit`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialSettings
     * @name WebFinancialBaseFinancialsettingsFinancialsettingCreate
     * @summary 修改财务本位币设置 (Auth policies: Web)
     * @request POST:/api/web/financial/base/financialsettings/financialsetting
     * @secure
     */
    webFinancialBaseFinancialsettingsFinancialsettingCreate: (data: FinancialCurrencySettingsInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/base/financialsettings/financialsetting`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialSettings
     * @name WebFinancialBaseFinancialsettingsGetfiscalyearpreferencesCreate
     * @summary 获取财年偏好设置 (Auth policies: Web)
     * @request POST:/api/web/financial/base/financialsettings/getfiscalyearpreferences
     * @secure
     */
    webFinancialBaseFinancialsettingsGetfiscalyearpreferencesCreate: (params: RequestParams = {}) =>
      this.request<FiscalYearPreferenceSettingDtoMessageModel, void>({
        path: `/api/web/financial/base/financialsettings/getfiscalyearpreferences`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialSettings
     * @name WebFinancialBaseFinancialsettingsGetbillingpreferencesCreate
     * @summary 获取财务账单偏好设置 (Auth policies: Web)
     * @request POST:/api/web/financial/base/financialsettings/getbillingpreferences
     * @secure
     */
    webFinancialBaseFinancialsettingsGetbillingpreferencesCreate: (params: RequestParams = {}) =>
      this.request<FinancialBillingPreferenceSettingDtoMessageModel, void>({
        path: `/api/web/financial/base/financialsettings/getbillingpreferences`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialSettings
     * @name WebFinancialBaseFinancialsettingsPostfiscalyearpreferenceCreate
     * @summary 设置或更新财年偏好设置 (Auth policies: Web)
     * @request POST:/api/web/financial/base/financialsettings/postfiscalyearpreference
     * @secure
     */
    webFinancialBaseFinancialsettingsPostfiscalyearpreferenceCreate: (data: FiscalYearPreferenceSettingDto, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/base/financialsettings/postfiscalyearpreference`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialSettings
     * @name WebFinancialBaseFinancialsettingsPostbillingpreferenceCreate
     * @summary 设置或更新账单偏好设置 (Auth policies: Web)
     * @request POST:/api/web/financial/base/financialsettings/postbillingpreference
     * @secure
     */
    webFinancialBaseFinancialsettingsPostbillingpreferenceCreate: (data: FinancialBillingPreferenceSettingDto, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/base/financialsettings/postbillingpreference`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialSettings
     * @name WebFinancialBaseFinancialsettingsGetallocationsettingsCreate
     * @summary 获取财务分配基础设置 (Auth policies: Web)
     * @request POST:/api/web/financial/base/financialsettings/getallocationsettings
     * @secure
     */
    webFinancialBaseFinancialsettingsGetallocationsettingsCreate: (params: RequestParams = {}) =>
      this.request<FinancialAllocationSettingsDtoMessageModel, void>({
        path: `/api/web/financial/base/financialsettings/getallocationsettings`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialSettings
     * @name WebFinancialBaseFinancialsettingsPostallocationsettingsCreate
     * @summary 设置或更新财务分配基础设置 (Auth policies: Web)
     * @request POST:/api/web/financial/base/financialsettings/postallocationsettings
     * @secure
     */
    webFinancialBaseFinancialsettingsPostallocationsettingsCreate: (data: FinancialAllocationSettingsDto, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/base/financialsettings/postallocationsettings`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialTaxRate
     * @name WebFinancialBaseFinancialtaxrateGetpageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/financial/base/financialtaxrate/getpage
     * @secure
     */
    webFinancialBaseFinancialtaxrateGetpageCreate: (data: GetFinancialTaxRateInput, params: RequestParams = {}) =>
      this.request<GetFinancialTaxRateOutputPageModelMessageModel, void>({
        path: `/api/web/financial/base/financialtaxrate/getpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialTaxRate
     * @name WebFinancialBaseFinancialtaxrateListCreate
     * @summary 列表获取所有 (Auth policies: Web)
     * @request POST:/api/web/financial/base/financialtaxrate/list
     * @secure
     */
    webFinancialBaseFinancialtaxrateListCreate: (data: GetFinancialTaxRateInput, params: RequestParams = {}) =>
      this.request<GetFinancialTaxRateOutputListMessageModel, void>({
        path: `/api/web/financial/base/financialtaxrate/list`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialTaxRate
     * @name WebFinancialBaseFinancialtaxrateGetfinancialtaxrateexportCreate
     * @summary 列表导出 (Auth policies: Web)
     * @request POST:/api/web/financial/base/financialtaxrate/getfinancialtaxrateexport
     * @secure
     */
    webFinancialBaseFinancialtaxrateGetfinancialtaxrateexportCreate: (data: GetFinancialTaxRateInputExportInput, params: RequestParams = {}) =>
      this.request<ExcelFileDtoMessageModel, void>({
        path: `/api/web/financial/base/financialtaxrate/getfinancialtaxrateexport`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialTaxRate
     * @name WebFinancialBaseFinancialtaxrateGetCreate
     * @summary 获取详情 (Auth policies: Web)
     * @request POST:/api/web/financial/base/financialtaxrate/get/{id}
     * @secure
     */
    webFinancialBaseFinancialtaxrateGetCreate: (id: string, params: RequestParams = {}) =>
      this.request<GetFinancialTaxRateOutputMessageModel, void>({
        path: `/api/web/financial/base/financialtaxrate/get/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialTaxRate
     * @name WebFinancialBaseFinancialtaxratePostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/financial/base/financialtaxrate/post
     * @secure
     */
    webFinancialBaseFinancialtaxratePostCreate: (data: CreateFinancialTaxRateInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/base/financialtaxrate/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialTaxRate
     * @name WebFinancialBaseFinancialtaxratePutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/financial/base/financialtaxrate/put/{id}
     * @secure
     */
    webFinancialBaseFinancialtaxratePutUpdate: (id: string, data: CreateFinancialTaxRateInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/base/financialtaxrate/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialTaxRate
     * @name WebFinancialBaseFinancialtaxrateDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/financial/base/financialtaxrate/delete/{id}
     * @secure
     */
    webFinancialBaseFinancialtaxrateDeleteDelete: (id: string, data: StringEntityDto, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/base/financialtaxrate/delete/${id}`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialTaxRate
     * @name WebFinancialBaseFinancialtaxrateBatchdeleteCreate
     * @summary 批量删除 (Auth policies: Web)
     * @request POST:/api/web/financial/base/financialtaxrate/batchdelete
     * @secure
     */
    webFinancialBaseFinancialtaxrateBatchdeleteCreate: (data: StringEntityDto[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/base/financialtaxrate/batchdelete`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FinancialTaxRate
     * @name WebFinancialBaseFinancialtaxrateCreatefinancialtaxratelistCreate
     * @summary 批量添加税率 (Auth policies: Web)
     * @request POST:/api/web/financial/base/financialtaxrate/createfinancialtaxratelist
     * @secure
     */
    webFinancialBaseFinancialtaxrateCreatefinancialtaxratelistCreate: (data: CreateFinancialTaxRateInput[], params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/financial/base/financialtaxrate/createfinancialtaxratelist`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Flow
     * @name WebSysFlowFlowinitCreate
     * @summary 发起工作流并执行第一步 (Auth policies: Web)
     * @request POST:/api/web/sys/flow/flowinit
     * @secure
     */
    webSysFlowFlowinitCreate: (data: FlowInitInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/flow/flowinit`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Flow
     * @name WebSysFlowPublisheventCreate
     * @summary 发布事件 (Auth policies: Web)
     * @request POST:/api/web/sys/flow/publishevent
     * @secure
     */
    webSysFlowPublisheventCreate: (data: FlowEventInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/flow/publishevent`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Flow
     * @name WebSysFlowGetflowstatelistCreate
     * @summary 获取工作流状态 (Auth policies: Web)
     * @request POST:/api/web/sys/flow/getflowstatelist
     * @secure
     */
    webSysFlowGetflowstatelistCreate: (data: FlowBaseInput, params: RequestParams = {}) =>
      this.request<StringFlowStateDtoListDictionaryMessageModel, void>({
        path: `/api/web/sys/flow/getflowstatelist`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Flow
     * @name WebSysFlowGetflowauditstateCreate
     * @summary 获取工作流待审核状态 (Auth policies: Web)
     * @request POST:/api/web/sys/flow/getflowauditstate
     * @secure
     */
    webSysFlowGetflowauditstateCreate: (data: FlowBaseInput, params: RequestParams = {}) =>
      this.request<FlowStateDtoListMessageModel, void>({
        path: `/api/web/sys/flow/getflowauditstate`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Flow
     * @name WebSysFlowReflowCreate
     * @summary 已完成工作流回滚 (Auth policies: Web)
     * @request POST:/api/web/sys/flow/reflow
     * @secure
     */
    webSysFlowReflowCreate: (data: FlowReFlowInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/sys/flow/reflow`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Flow
     * @name WebSysFlowGetflowuserapproverList
     * @summary 获取当前节点审批人 (Auth policies: Web)
     * @request GET:/api/web/sys/flow/getflowuserapprover
     * @secure
     */
    webSysFlowGetflowuserapproverList: (
      query?: {
        businessId?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<FlowUserApproverDtoListMessageModel, void>({
        path: `/api/web/sys/flow/getflowuserapprover`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Flow
     * @name WebSysFlowTransferCreate
     * @summary 移交 (Auth policies: Web)
     * @request POST:/api/web/sys/flow/transfer
     * @secure
     */
    webSysFlowTransferCreate: (data: FlowTransferInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/sys/flow/transfer`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Flow
     * @name WebSysFlowCloseflowCreate
     * @summary 关闭流程 (Auth policies: Web)
     * @request POST:/api/web/sys/flow/closeflow
     * @secure
     */
    webSysFlowCloseflowCreate: (data: FlowCloseInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/flow/closeflow`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Flow
     * @name WebSysFlowReviewwithdrawCreate
     * @summary 审核人撤回 (Auth policies: Web)
     * @request POST:/api/web/sys/flow/reviewwithdraw
     * @secure
     */
    webSysFlowReviewwithdrawCreate: (data: FlowWithdrawInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/flow/reviewwithdraw`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Flow
     * @name WebSysFlowApplicantwithdrawCreate
     * @summary 申请人撤回 (Auth policies: Web)
     * @request POST:/api/web/sys/flow/applicantwithdraw
     * @secure
     */
    webSysFlowApplicantwithdrawCreate: (data: FlowWithdrawInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/flow/applicantwithdraw`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Flow
     * @name WebSysFlowCountersignCreate
     * @summary 当前审核节点加签 (Auth policies: Web)
     * @request POST:/api/web/sys/flow/countersign
     * @secure
     */
    webSysFlowCountersignCreate: (data: FlowCountersignInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/flow/countersign`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FlowApprover
     * @name WebSysFlowapproverAddinstancenodeusersCreate
     * @summary 新增加签用户 (Auth policies: Web)
     * @request POST:/api/web/sys/flowapprover/addinstancenodeusers
     * @secure
     */
    webSysFlowapproverAddinstancenodeusersCreate: (data: CreateFlowInstanceNodeUsersInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/sys/flowapprover/addinstancenodeusers`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FlowCondition
     * @name WebSysFlowconditionCreate
     * @summary 创建流程条件 (Auth policies: Web)
     * @request POST:/api/web/sys/flowcondition
     * @secure
     */
    webSysFlowconditionCreate: (data: CreateFlowConditionInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/sys/flowcondition`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FlowCondition
     * @name WebSysFlowconditionUpsertCreate
     * @summary 更新、新增流程条件 (Auth policies: Web)
     * @request POST:/api/web/sys/flowcondition/{id}/upsert
     * @secure
     */
    webSysFlowconditionUpsertCreate: (id: string, data: CreateUpsertFlowConditionInput, params: RequestParams = {}) =>
      this.request<BooleanMessageModel, void>({
        path: `/api/web/sys/flowcondition/${id}/upsert`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FlowCondition
     * @name WebSysFlowconditionUpdate
     * @summary 修改流程条件 (Auth policies: Web)
     * @request PUT:/api/web/sys/flowcondition/{id}
     * @secure
     */
    webSysFlowconditionUpdate: (id: string, data: CreateFlowConditionInput, params: RequestParams = {}) =>
      this.request<BooleanMessageModel, void>({
        path: `/api/web/sys/flowcondition/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FlowCondition
     * @name WebSysFlowconditionDelete
     * @summary 删除流程条件 (Auth policies: Web)
     * @request DELETE:/api/web/sys/flowcondition/{id}
     * @secure
     */
    webSysFlowconditionDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/flowcondition/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FlowCondition
     * @name WebSysFlowconditionDetail
     * @summary 流程条件 (Auth policies: Web)
     * @request GET:/api/web/sys/flowcondition/{flowdefineid}
     * @secure
     */
    webSysFlowconditionDetail: (flowDefineId: string, flowdefineid: string, params: RequestParams = {}) =>
      this.request<FlowConditionListMessageModel, void>({
        path: `/api/web/sys/flowcondition/${flowdefineid}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FlowCondition
     * @name WebSysFlowconditionDetail2
     * @summary 根据id获取连接线信息和条件信息 (Auth policies: Web)
     * @request GET:/api/web/sys/flowcondition/{flowdefineid}/{id}
     * @originalName webSysFlowconditionDetail
     * @duplicate
     * @secure
     */
    webSysFlowconditionDetail2: (flowDefineId: string, id: string, flowdefineid: string, params: RequestParams = {}) =>
      this.request<FlowEdgesCondtionDtoMessageModel, void>({
        path: `/api/web/sys/flowcondition/${flowdefineid}/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FlowCondition
     * @name WebSysFlowconditionConditiontypesList
     * @summary 获取条件类型 (Auth policies: Web)
     * @request GET:/api/web/sys/flowcondition/conditiontypes
     * @secure
     */
    webSysFlowconditionConditiontypesList: (params: RequestParams = {}) =>
      this.request<ObjectMessageModel, void>({
        path: `/api/web/sys/flowcondition/conditiontypes`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FlowControl
     * @name WebSysFlowcontrolUpdate
     * @summary 更新流程控制节点 (Auth policies: Web)
     * @request PUT:/api/web/sys/flowcontrol/{id}
     * @secure
     */
    webSysFlowcontrolUpdate: (id: string, data: CreateFlowControlInput, params: RequestParams = {}) =>
      this.request<BooleanMessageModel, void>({
        path: `/api/web/sys/flowcontrol/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FlowControl
     * @name WebSysFlowcontrolCreate
     * @summary 新增流程控制节点 (Auth policies: Web)
     * @request POST:/api/web/sys/flowcontrol
     * @secure
     */
    webSysFlowcontrolCreate: (data: CreateFlowControlInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/sys/flowcontrol`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FlowControl
     * @name WebSysFlowcontrolDelete
     * @summary 删除流程控制节点 (Auth policies: Web)
     * @request DELETE:/api/web/sys/flowcontrol/{nodeid}
     * @secure
     */
    webSysFlowcontrolDelete: (nodeId: string, nodeid: string, params: RequestParams = {}) =>
      this.request<Int32MessageModel, void>({
        path: `/api/web/sys/flowcontrol/${nodeid}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FlowDefine
     * @name WebSysFlowdefineCreate
     * @summary 工作流定义 (Auth policies: Web)
     * @request POST:/api/web/sys/flowdefine
     * @secure
     */
    webSysFlowdefineCreate: (data: CreateFlowDefineInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/sys/flowdefine`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FlowDefine
     * @name WebSysFlowdefineList
     * @summary 获取流程定义分页列表 (Auth policies: Web)
     * @request GET:/api/web/sys/flowdefine
     * @secure
     */
    webSysFlowdefineList: (
      query?: {
        /** 名称;名称 */
        name?: string;
        /** 业务分类;业务类型分类 */
        buinessType?: string;
        /**
         * 版本号;流程版本
         * @format int32
         */
        version?: number;
        /** 模型名称;模型名称 */
        modelName?: string;
        /** 模型值 */
        modelValue?: string;
        /** 是否允许在运行时编辑 */
        isAllowEdit?: boolean;
        /** 允许流程发起人催办 */
        isAllowRemind?: boolean;
        /** 提醒类型字符串分割 */
        remindType?: string;
        /** 是否开启自动提交 */
        isAutoCommit?: boolean;
        /**
         * 自动提交选项
         * @format int32
         */
        autoCommitType?: 2 | 3 | 4;
        /** 是否允许撤回 */
        isAllowWithdraw?: boolean;
        /**
         * 撤回类型
         * @format int32
         */
        withdrawType?: 2 | 3 | 4;
        /** 作用域 */
        isScope?: boolean;
        /** 备注 */
        remark?: string;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<FlowDefinePageModelMessageModel, void>({
        path: `/api/web/sys/flowdefine`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FlowDefine
     * @name WebSysFlowdefineUpdate
     * @summary 修改工作流定义 (Auth policies: Web)
     * @request PUT:/api/web/sys/flowdefine/{id}
     * @secure
     */
    webSysFlowdefineUpdate: (id: string, data: CreateFlowDefineInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/sys/flowdefine/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FlowDefine
     * @name WebSysFlowdefineDetail
     * @summary 获取指定工作流/流程图 (Auth policies: Web)
     * @request GET:/api/web/sys/flowdefine/{id}
     * @secure
     */
    webSysFlowdefineDetail: (
      id: string,
      query?: {
        /**
         * 流程图显示类型
         * @format int32
         * @default 0
         */
        flowType?: 0 | 1;
      },
      params: RequestParams = {}
    ) =>
      this.request<FlowChartDtoMessageModel, void>({
        path: `/api/web/sys/flowdefine/${id}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FlowDefine
     * @name WebSysFlowdefineDelete
     * @summary 删除流程 (Auth policies: Web)
     * @request DELETE:/api/web/sys/flowdefine/{id}
     * @secure
     */
    webSysFlowdefineDelete: (id: string, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/sys/flowdefine/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FlowDefine
     * @name WebSysFlowdefineBuinesstypesList
     * @summary 获取业务类型记录 (Auth policies: Web)
     * @request GET:/api/web/sys/flowdefine/buinesstypes
     * @secure
     */
    webSysFlowdefineBuinesstypesList: (params: RequestParams = {}) =>
      this.request<FlowScopeTypeListMessageModel, void>({
        path: `/api/web/sys/flowdefine/buinesstypes`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FlowDefine
     * @name WebSysFlowdefineBuinesstypeList
     * @summary 获取业务类型 (Auth policies: Web)
     * @request GET:/api/web/sys/flowdefine/buinesstype
     * @secure
     */
    webSysFlowdefineBuinesstypeList: (
      query?: {
        key?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<FlowScopeTypeMessageModel, void>({
        path: `/api/web/sys/flowdefine/buinesstype`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FlowService
     * @name WebSysFlowserviceFlowinitCreate
     * @summary 流程初始化发起 (Auth policies: Web)
     * @request POST:/api/web/sys/flowservice/flowinit
     * @secure
     */
    webSysFlowserviceFlowinitCreate: (
      query?: {
        /** 流程id */
        id?: string;
        /** 工作流名称 */
        flowName?: string;
        /** 数据业务主键id */
        buinessId?: string;
        /** 从指定步骤开始发起 */
        nodeId?: string;
        /** 从步骤节点开始/编号或名称 */
        fromNode?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/sys/flowservice/flowinit`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FlowService
     * @name WebSysFlowserviceFlowinstanceidList
     * @summary 获取流程实例id (Auth policies: Web)
     * @request GET:/api/web/sys/flowservice/flowinstanceid
     * @secure
     */
    webSysFlowserviceFlowinstanceidList: (
      query?: {
        /** 业务id */
        buinessId?: string;
        /** 数据库表名 */
        modelVale?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/sys/flowservice/flowinstanceid`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FlowService
     * @name WebSysFlowserviceFlowcreateversionCreate
     * @summary 创建流程版本 (Auth policies: Web)
     * @request POST:/api/web/sys/flowservice/flowcreateversion
     * @secure
     */
    webSysFlowserviceFlowcreateversionCreate: (
      query?: {
        /** 流程id */
        id?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/sys/flowservice/flowcreateversion`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FlowService
     * @name WebSysFlowserviceNodesendCreate
     * @summary 根据流程实例发送 (Auth policies: Web)
     * @request POST:/api/web/sys/flowservice/nodesend
     * @secure
     */
    webSysFlowserviceNodesendCreate: (data: FlowNodeSendInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/sys/flowservice/nodesend`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FlowService
     * @name WebSysFlowserviceNodesendbyCreate
     * @summary 根据业务id自动创建实例并发送 (Auth policies: Web)
     * @request POST:/api/web/sys/flowservice/nodesendby
     * @secure
     */
    webSysFlowserviceNodesendbyCreate: (data: FlowNodeSendByBusinessInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/sys/flowservice/nodesendby`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FlowService
     * @name WebSysFlowserviceNodereturnCreate
     * @summary 流程驳回 (Auth policies: Web)
     * @request POST:/api/web/sys/flowservice/nodereturn
     * @secure
     */
    webSysFlowserviceNodereturnCreate: (
      query?: {
        /** /实例id */
        flowInstanceId?: string;
        /** 目标节点主键id */
        nodeId?: string;
        /** 审批意见/原因 */
        approvalOpinion?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/sys/flowservice/nodereturn`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FlowService
     * @name WebSysFlowserviceNodewithdrawCreate
     * @summary 流程撤回 (Auth policies: Web)
     * @request POST:/api/web/sys/flowservice/nodewithdraw
     * @secure
     */
    webSysFlowserviceNodewithdrawCreate: (
      query?: {
        /** /实例id */
        flowInstanceId?: string;
        /** 审批意见/原因 */
        approvalOpinion?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/sys/flowservice/nodewithdraw`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FlowService
     * @name WebSysFlowserviceNodeupdateUpdate
     * @summary 流程节点更新 (Auth policies: Web)
     * @request PUT:/api/web/sys/flowservice/nodeupdate/{id}
     * @secure
     */
    webSysFlowserviceNodeupdateUpdate: (id: string, data: UpdateFlowActivityNodeInput, params: RequestParams = {}) =>
      this.request<BooleanMessageModel, void>({
        path: `/api/web/sys/flowservice/nodeupdate/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FlowService
     * @name WebSysFlowserviceNodeaddCreate
     * @summary 新增节点 (Auth policies: Web)
     * @request POST:/api/web/sys/flowservice/nodeadd
     * @secure
     */
    webSysFlowserviceNodeaddCreate: (data: CreateFlowActivityNodeInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/sys/flowservice/nodeadd`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FlowService
     * @name WebSysFlowserviceNodedeleteDelete
     * @summary 删除节点 (Auth policies: Web)
     * @request DELETE:/api/web/sys/flowservice/nodedelete/{id}
     * @secure
     */
    webSysFlowserviceNodedeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/sys/flowservice/nodedelete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FlowService
     * @name WebSysFlowserviceNodedeinfoDetail
     * @summary 获取指定节点信息 (Auth policies: Web)
     * @request GET:/api/web/sys/flowservice/nodedeinfo/{id}
     * @secure
     */
    webSysFlowserviceNodedeinfoDetail: (id: string, params: RequestParams = {}) =>
      this.request<FlowActivityNodeDtoMessageModel, void>({
        path: `/api/web/sys/flowservice/nodedeinfo/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FlowService
     * @name WebSysFlowserviceNodedepreviousDetail
     * @summary 获取上一步骤节点 (Auth policies: Web)
     * @request GET:/api/web/sys/flowservice/nodedeprevious/{flowdefineid}/{nodeid}
     * @secure
     */
    webSysFlowserviceNodedepreviousDetail: (nodeId: string, flowDefineId: string, flowdefineid: string, nodeid: string, params: RequestParams = {}) =>
      this.request<FlowActivityNodeBaseDtoMessageModel, void>({
        path: `/api/web/sys/flowservice/nodedeprevious/${flowdefineid}/${nodeid}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FlowService
     * @name WebSysFlowserviceNodebrieflistDetail
     * @summary 获取指定流程节点列表 (Auth policies: Web)
     * @request GET:/api/web/sys/flowservice/nodebrieflist/{flowdefineid}
     * @secure
     */
    webSysFlowserviceNodebrieflistDetail: (flowDefineId: string, flowdefineid: string, params: RequestParams = {}) =>
      this.request<FlowActivityNodeBriefDtoListMessageModel, void>({
        path: `/api/web/sys/flowservice/nodebrieflist/${flowdefineid}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FlowService
     * @name WebSysFlowserviceFlowtodoDetail
     * @summary 获取用户待办 (Auth policies: Web)
     * @request GET:/api/web/sys/flowservice/flowtodo/{userid}
     * @secure
     */
    webSysFlowserviceFlowtodoDetail: (
      userId: string,
      userid: string,
      query?: {
        /** 业务表名 */
        modelValue?: string;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<FlowTodoDtoPageModelMessageModel, void>({
        path: `/api/web/sys/flowservice/flowtodo/${userid}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FlowService
     * @name WebSysFlowserviceFlowrecordDetail
     * @summary 获取业务审批记录 (Auth policies: Web)
     * @request GET:/api/web/sys/flowservice/flowrecord/{buinessid}/{flowbuiness}
     * @secure
     */
    webSysFlowserviceFlowrecordDetail: (buinessId: string, flowBuiness: string, buinessid: string, flowbuiness: string, params: RequestParams = {}) =>
      this.request<FlowActivityRecordDtoListMessageModel, void>({
        path: `/api/web/sys/flowservice/flowrecord/${buinessid}/${flowbuiness}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FlowService
     * @name WebSysFlowserviceFlowinstanceDetail
     * @summary 根据业务编号和业务类型获取当前步骤审批列表 (Auth policies: Web)
     * @request GET:/api/web/sys/flowservice/flowinstance/{buinessid}/{flowbuiness}
     * @secure
     */
    webSysFlowserviceFlowinstanceDetail: (buinessId: string, flowBuiness: string, buinessid: string, flowbuiness: string, params: RequestParams = {}) =>
      this.request<FlowActivityDtoListMessageModel, void>({
        path: `/api/web/sys/flowservice/flowinstance/${buinessid}/${flowbuiness}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FlowService
     * @name WebSysFlowserviceFlowapprovalDetail
     * @summary 根据业务编号和业务类型获取审批情况 (Auth policies: Web)
     * @request GET:/api/web/sys/flowservice/flowapproval/{buinessid}/{flowbuiness}
     * @secure
     */
    webSysFlowserviceFlowapprovalDetail: (buinessId: string, flowBuiness: string, buinessid: string, flowbuiness: string, params: RequestParams = {}) =>
      this.request<FlowApprovalStatusRecordDtoListMessageModel, void>({
        path: `/api/web/sys/flowservice/flowapproval/${buinessid}/${flowbuiness}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FlowService
     * @name WebSysFlowserviceFlowapprovalTimelineDetail
     * @summary 根据业务编号和业务类型获取审批进度情况 (Auth policies: Web)
     * @request GET:/api/web/sys/flowservice/flowapproval/timeline/{businessid}/{flowbusiness}
     * @secure
     */
    webSysFlowserviceFlowapprovalTimelineDetail: (
      businessId: string,
      flowBusiness: string,
      businessid: string,
      flowbusiness: string,
      params: RequestParams = {}
    ) =>
      this.request<FlowApprovalTimelineDtoListMessageModel, void>({
        path: `/api/web/sys/flowservice/flowapproval/timeline/${businessid}/${flowbusiness}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FlowService
     * @name WebSysFlowserviceFlowapprovalStatusDetail
     * @summary 根据业务类型获取审批状态级联 (Auth policies: Web)
     * @request GET:/api/web/sys/flowservice/flowapproval/status/{flowbusiness}
     * @secure
     */
    webSysFlowserviceFlowapprovalStatusDetail: (flowBusiness: string, flowbusiness: string, params: RequestParams = {}) =>
      this.request<ComboboxGroupDtoListMessageModel, void>({
        path: `/api/web/sys/flowservice/flowapproval/status/${flowbusiness}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FlowService
     * @name WebSysFlowserviceFlowapprovalCountersignuserCreate
     * @summary 新增加签用户 (Auth policies: Web)
     * @request POST:/api/web/sys/flowservice/flowapproval/countersignuser
     * @secure
     */
    webSysFlowserviceFlowapprovalCountersignuserCreate: (data: CreateFlowInstanceNodeUsersInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/sys/flowservice/flowapproval/countersignuser`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags ForeignExchangeRates
     * @name WebFinancialBaseForeignexchangeratesGetpageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/financial/base/foreignexchangerates/getpage
     * @secure
     */
    webFinancialBaseForeignexchangeratesGetpageCreate: (data: GetForeignExchangeRatesInput, params: RequestParams = {}) =>
      this.request<ForeignExchangeRatesDtoPageModelMessageModel, void>({
        path: `/api/web/financial/base/foreignexchangerates/getpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags ForeignExchangeRates
     * @name WebFinancialBaseForeignexchangeratesListCreate
     * @summary 列表获取所有 (Auth policies: Web)
     * @request POST:/api/web/financial/base/foreignexchangerates/list
     * @secure
     */
    webFinancialBaseForeignexchangeratesListCreate: (data: GetForeignExchangeRatesInput, params: RequestParams = {}) =>
      this.request<ForeignExchangeRatesDtoListMessageModel, void>({
        path: `/api/web/financial/base/foreignexchangerates/list`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags ForeignExchangeRates
     * @name WebFinancialBaseForeignexchangeratesGetforeignexchangeratesexportCreate
     * @summary 列表导出 (Auth policies: Web)
     * @request POST:/api/web/financial/base/foreignexchangerates/getforeignexchangeratesexport
     * @secure
     */
    webFinancialBaseForeignexchangeratesGetforeignexchangeratesexportCreate: (data: GetForeignExchangeRatesInputExportInput, params: RequestParams = {}) =>
      this.request<ExcelFileDtoMessageModel, void>({
        path: `/api/web/financial/base/foreignexchangerates/getforeignexchangeratesexport`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags ForeignExchangeRates
     * @name WebFinancialBaseForeignexchangeratesGetselectCreate
     * @summary 选择框 (Auth policies: Web)
     * @request POST:/api/web/financial/base/foreignexchangerates/getselect
     * @secure
     */
    webFinancialBaseForeignexchangeratesGetselectCreate: (data: GetOrganizationUnitsInput, params: RequestParams = {}) =>
      this.request<ComboboxItemDtoListMessageModel, void>({
        path: `/api/web/financial/base/foreignexchangerates/getselect`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags ForeignExchangeRates
     * @name WebFinancialBaseForeignexchangeratesGetCreate
     * @summary 获取详情 (Auth policies: Web)
     * @request POST:/api/web/financial/base/foreignexchangerates/get/{id}
     * @secure
     */
    webFinancialBaseForeignexchangeratesGetCreate: (id: string, params: RequestParams = {}) =>
      this.request<ForeignExchangeRatesDtoMessageModel, void>({
        path: `/api/web/financial/base/foreignexchangerates/get/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags ForeignExchangeRates
     * @name WebFinancialBaseForeignexchangeratesGetforeditCreate
     * @summary 获取修改实体 (Auth policies: Web)
     * @request POST:/api/web/financial/base/foreignexchangerates/getforedit/{id}
     * @secure
     */
    webFinancialBaseForeignexchangeratesGetforeditCreate: (id: string, params: RequestParams = {}) =>
      this.request<CreateForeignExchangeRatesInputMessageModel, void>({
        path: `/api/web/financial/base/foreignexchangerates/getforedit/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags ForeignExchangeRates
     * @name WebFinancialBaseForeignexchangeratesPostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/financial/base/foreignexchangerates/post
     * @secure
     */
    webFinancialBaseForeignexchangeratesPostCreate: (data: CreateForeignExchangeRatesInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/base/foreignexchangerates/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags ForeignExchangeRates
     * @name WebFinancialBaseForeignexchangeratesPutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/financial/base/foreignexchangerates/put/{id}
     * @secure
     */
    webFinancialBaseForeignexchangeratesPutUpdate: (id: string, data: CreateForeignExchangeRatesInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/base/foreignexchangerates/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags ForeignExchangeRates
     * @name WebFinancialBaseForeignexchangeratesDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/financial/base/foreignexchangerates/delete/{id}
     * @secure
     */
    webFinancialBaseForeignexchangeratesDeleteDelete: (id: string, data: StringEntityDto, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/base/foreignexchangerates/delete/${id}`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags ForeignExchangeRates
     * @name WebFinancialBaseForeignexchangeratesBatchdeleteCreate
     * @summary 批量删除 (Auth policies: Web)
     * @request POST:/api/web/financial/base/foreignexchangerates/batchdelete
     * @secure
     */
    webFinancialBaseForeignexchangeratesBatchdeleteCreate: (data: StringEntityDto[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/base/foreignexchangerates/batchdelete`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags ForeignExchangeRates
     * @name WebFinancialBaseForeignexchangeratesGetrefertoforeignexchangerateCreate
     * @summary 获取外部参考汇率 (Auth policies: Web)
     * @request POST:/api/web/financial/base/foreignexchangerates/getrefertoforeignexchangerate
     * @secure
     */
    webFinancialBaseForeignexchangeratesGetrefertoforeignexchangerateCreate: (data: GetReferToForeignExchangeRateInput, params: RequestParams = {}) =>
      this.request<GetReferToForeignExchangeRateOutputMessageModel, void>({
        path: `/api/web/financial/base/foreignexchangerates/getrefertoforeignexchangerate`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags ForeignExchangeRates
     * @name WebFinancialBaseForeignexchangeratesGetforeignexchangeratesforeditbycurrencyCreate
     * @summary 根据汇率获取编辑对象 (Auth policies: Web)
     * @request POST:/api/web/financial/base/foreignexchangerates/getforeignexchangeratesforeditbycurrency
     * @secure
     */
    webFinancialBaseForeignexchangeratesGetforeignexchangeratesforeditbycurrencyCreate: (
      data: GetForeignExchangeRatesCurrencyInput,
      params: RequestParams = {}
    ) =>
      this.request<CreateForeignExchangeRatesInputMessageModel, void>({
        path: `/api/web/financial/base/foreignexchangerates/getforeignexchangeratesforeditbycurrency`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags ForeignExchangeRates
     * @name WebFinancialBaseForeignexchangeratesCreateforeignexchangeratesCreate
     * @summary 保存汇率覆盖旧的 (Auth policies: Web)
     * @request POST:/api/web/financial/base/foreignexchangerates/createforeignexchangerates
     * @secure
     */
    webFinancialBaseForeignexchangeratesCreateforeignexchangeratesCreate: (data: CreateForeignExchangeRatesInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/financial/base/foreignexchangerates/createforeignexchangerates`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FormValidate
     * @name WebSysFormvalidateList
     * @summary 获取所有Dto规则， 待处理 (Auth policies: Web)
     * @request GET:/api/web/sys/formvalidate
     * @secure
     */
    webSysFormvalidateList: (params: RequestParams = {}) =>
      this.request<FormValidateSettingDtoListMessageModel, void>({
        path: `/api/web/sys/formvalidate`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FormValidate
     * @name WebSysFormvalidateCreate
     * @summary 添加验证规则 , 并更新缓存 (Auth policies: Web)
     * @request POST:/api/web/sys/formvalidate
     * @secure
     */
    webSysFormvalidateCreate: (data: CreateFormValidateBatchInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/formvalidate`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FormValidate
     * @name WebSysFormvalidateDetail
     * @summary 获取单个Dto规则 (Auth policies: Web)
     * @request GET:/api/web/sys/formvalidate/{id}
     * @secure
     */
    webSysFormvalidateDetail: (id: string, params: RequestParams = {}) =>
      this.request<FormValidateDtoMessageModel, void>({
        path: `/api/web/sys/formvalidate/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags FormValidate
     * @name WebSysFormvalidateDtoList
     * @summary 通过Url获取Dto验证规则 (Auth policies: Web)
     * @request GET:/api/web/sys/formvalidate/dto
     * @secure
     */
    webSysFormvalidateDtoList: (
      query?: {
        url?: string;
        requestMethod?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<FormValidateSettingDtoMessageModel, void>({
        path: `/api/web/sys/formvalidate/dto`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags GroupInfo
     * @name WebCustomerGroupinfoGetpageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/customer/groupinfo/getpage
     * @secure
     */
    webCustomerGroupinfoGetpageCreate: (
      query?: {
        /** 名字/证件编码  where (a.id =1 or b.id) and (c.id=2 or d.id=2) */
        filter?: string;
        /** 客户名称 */
        name?: string;
        /** 客户类型 */
        clientType?: string;
        /** 证件类型 */
        creditType?: string;
        /** 证件号码 */
        creditCode?: string;
        /** 来源类型 */
        originType?: string;
        /** 来源编号 */
        originId?: string;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<GroupInfoDtoPageModelMessageModel, void>({
        path: `/api/web/customer/groupinfo/getpage`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags GroupInfo
     * @name WebCustomerGroupinfoGetgrouplistCreate
     * @summary 集团模糊匹配 (Auth policies: Web)
     * @request POST:/api/web/customer/groupinfo/getgrouplist
     * @secure
     */
    webCustomerGroupinfoGetgrouplistCreate: (
      query?: {
        /** 名字/证件编码  where (a.id =1 or b.id) and (c.id=2 or d.id=2) */
        filter?: string;
        /** 客户名称 */
        name?: string;
        /** 客户类型 */
        clientType?: string;
        /** 证件类型 */
        creditType?: string;
        /** 证件号码 */
        creditCode?: string;
        /** 来源类型 */
        originType?: string;
        /** 来源编号 */
        originId?: string;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<GroupInfoDtoListMessageModel, void>({
        path: `/api/web/customer/groupinfo/getgrouplist`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags GroupInfo
     * @name WebCustomerGroupinfoCreategroupinfoCreate
     * @summary 添加集团信息 (Auth policies: Web)
     * @request POST:/api/web/customer/groupinfo/creategroupinfo
     * @secure
     */
    webCustomerGroupinfoCreategroupinfoCreate: (data: CreateGroupInfoInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/customer/groupinfo/creategroupinfo`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags GroupInfo
     * @name WebCustomerGroupinfoUpdategroupinfoCreate
     * @summary 修改集团信息 (Auth policies: Web)
     * @request POST:/api/web/customer/groupinfo/updategroupinfo
     * @secure
     */
    webCustomerGroupinfoUpdategroupinfoCreate: (
      data: CreateGroupInfoInput,
      query?: {
        id?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<BooleanMessageModel, void>({
        path: `/api/web/customer/groupinfo/updategroupinfo`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags GroupInfo
     * @name WebCustomerGroupinfoDeleteDelete
     * @summary 删除集团信息 (Auth policies: Web)
     * @request DELETE:/api/web/customer/groupinfo/delete/{id}
     * @secure
     */
    webCustomerGroupinfoDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<BooleanMessageModel, void>({
        path: `/api/web/customer/groupinfo/delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags GroupInfo
     * @name WebCustomerGroupinfoUpdategroupidbyclientidCreate
     * @summary 关联集团与客户信息 (Auth policies: Web)
     * @request POST:/api/web/customer/groupinfo/updategroupidbyclientid
     * @secure
     */
    webCustomerGroupinfoUpdategroupidbyclientidCreate: (data: CreateGroupClientRelshipInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/customer/groupinfo/updategroupidbyclientid`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Language
     * @name WebSysLanguageList
     * @summary 获取语言列表 (Auth policies: Web)
     * @request GET:/api/web/sys/language
     * @secure
     */
    webSysLanguageList: (params: RequestParams = {}) =>
      this.request<LanguageDtoListMessageModel, void>({
        path: `/api/web/sys/language`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Language
     * @name WebSysLanguageDelete
     * @summary 批量删除 (Auth policies: Web)
     * @request DELETE:/api/web/sys/language
     * @secure
     */
    webSysLanguageDelete: (params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/language`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Language
     * @name WebSysLanguageCreate
     * @summary 添加语言 (Auth policies: Web)
     * @request POST:/api/web/sys/language
     * @secure
     */
    webSysLanguageCreate: (data: CreateLanguageInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/sys/language`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Language
     * @name WebSysLanguageCreate2
     * @summary  (Auth policies: Web)
     * @request POST:/api/web/sys/language/{id}
     * @originalName webSysLanguageCreate
     * @duplicate
     * @secure
     */
    webSysLanguageCreate2: (id: string, params: RequestParams = {}) =>
      this.request<CreateLanguageInputMessageModel, void>({
        path: `/api/web/sys/language/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Language
     * @name WebSysLanguageUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/sys/language/{id}
     * @secure
     */
    webSysLanguageUpdate: (id: string, data: CreateLanguageInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/language/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Language
     * @name WebSysLanguageDelete2
     * @summary 删除语言 (Auth policies: Web)
     * @request DELETE:/api/web/sys/language/{id}
     * @originalName webSysLanguageDelete
     * @duplicate
     * @secure
     */
    webSysLanguageDelete2: (id: string, params: RequestParams = {}) =>
      this.request<BooleanMessageModel, void>({
        path: `/api/web/sys/language/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Language
     * @name WebSysLanguageSettingsList
     * @summary 获得语言配置 (Auth policies: Web)
     * @request GET:/api/web/sys/language/settings
     * @secure
     */
    webSysLanguageSettingsList: (params: RequestParams = {}) =>
      this.request<SettingLanguageDtoMessageModel, void>({
        path: `/api/web/sys/language/settings`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Language
     * @name WebSysLanguageDefaultUpdate
     * @summary 设置默认语言 (Auth policies: Web)
     * @request PUT:/api/web/sys/language/{id}/default
     * @secure
     */
    webSysLanguageDefaultUpdate: (id: string, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/sys/language/${id}/default`,
        method: 'PUT',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Language
     * @name WebSysLanguageEnabledUpdate
     * @summary 启用 (Auth policies: Web)
     * @request PUT:/api/web/sys/language/{id}/enabled
     * @secure
     */
    webSysLanguageEnabledUpdate: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/language/${id}/enabled`,
        method: 'PUT',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Language
     * @name WebSysLanguageDisabledUpdate
     * @summary 禁用 (Auth policies: Web)
     * @request PUT:/api/web/sys/language/{id}/disabled
     * @secure
     */
    webSysLanguageDisabledUpdate: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/language/${id}/disabled`,
        method: 'PUT',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags LanguageText
     * @name WebSysLanguagetextPageCreate
     * @summary 获取语言列表 (Auth policies: Web)
     * @request POST:/api/web/sys/languagetext/page
     * @secure
     */
    webSysLanguagetextPageCreate: (data: GetLanguageKeyInput, params: RequestParams = {}) =>
      this.request<LanguageKeyDtoPageModelMessageModel, void>({
        path: `/api/web/sys/languagetext/page`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags LanguageText
     * @name WebSysLanguagetextExportList
     * @summary 导出语言xml 文件 (Auth policies: Web)
     * @request GET:/api/web/sys/languagetext/export
     * @secure
     */
    webSysLanguagetextExportList: (
      query?: {
        /** 过滤字段 */
        filter?: string;
        /** 多语言合集 */
        languages?: string[];
        /** 模块 */
        module?: string;
        /** 平台 */
        source?: (0 | 1 | 2)[];
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/sys/languagetext/export`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags LanguageText
     * @name WebSysLanguagetextTranslateCreate
     * @summary 翻译 (Auth policies: Web)
     * @request POST:/api/web/sys/languagetext/translate
     * @secure
     */
    webSysLanguagetextTranslateCreate: (data: TranslateLanguagesInput, params: RequestParams = {}) =>
      this.request<TargetLanguageListDtoListMessageModel, void>({
        path: `/api/web/sys/languagetext/translate`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags LanguageText
     * @name WebSysLanguagetextCreate
     * @summary 创建 (Auth policies: Web)
     * @request POST:/api/web/sys/languagetext
     * @secure
     */
    webSysLanguagetextCreate: (data: CreateLanguageKeyInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/languagetext`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags LanguageText
     * @name WebSysLanguagetextUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/sys/languagetext/{id}
     * @secure
     */
    webSysLanguagetextUpdate: (id: string, data: CreateLanguageKeyInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/languagetext/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags LanguageText
     * @name WebSysLanguagetextDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/sys/languagetext/{id}
     * @secure
     */
    webSysLanguagetextDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/languagetext/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Log
     * @name WebSysLogLokiList
     * @summary 获取日志中心地址 (Auth policies: Web)
     * @request GET:/api/web/sys/log/loki
     * @secure
     */
    webSysLogLokiList: (params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/sys/log/loki`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Log
     * @name WebSysLogRequestCreate
     * @summary 获取请求日志 (Auth policies: Web)
     * @request POST:/api/web/sys/log/request
     * @secure
     */
    webSysLogRequestCreate: (data: GetRequestAuditLogInput, params: RequestParams = {}) =>
      this.request<RequestAuditLogListDtoPageModelMessageModel, void>({
        path: `/api/web/sys/log/request`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Log
     * @name WebSysLogRequestExportCreate
     * @summary 导出 (Auth policies: Web)
     * @request POST:/api/web/sys/log/request/export
     * @secure
     */
    webSysLogRequestExportCreate: (data: GetRequestAuditLogInputExportInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/log/request/export`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Log
     * @name WebSysLogRequestDetail
     * @summary 获取请求日志详情 (Auth policies: Web)
     * @request GET:/api/web/sys/log/request/{id}
     * @secure
     */
    webSysLogRequestDetail: (id: string, params: RequestParams = {}) =>
      this.request<RequestAuditLogDtoMessageModel, void>({
        path: `/api/web/sys/log/request/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Log
     * @name WebSysLogLoginCreate
     * @summary 登录日志 (Auth policies: Web)
     * @request POST:/api/web/sys/log/login
     * @secure
     */
    webSysLogLoginCreate: (data: GetUserLoginAttemptsInput, params: RequestParams = {}) =>
      this.request<UserLoginAttemptsDtoPageModelMessageModel, void>({
        path: `/api/web/sys/log/login`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags OrganizationUnit
     * @name WebSysOrganizationunitPageCreate
     * @summary 获取部门列表 (Auth policies: Web)
     * @request POST:/api/web/sys/organizationunit/page
     * @secure
     */
    webSysOrganizationunitPageCreate: (data: GetOrganizationUnitsInput, params: RequestParams = {}) =>
      this.request<OrganizationUnitsDtoPageModelMessageModel, void>({
        path: `/api/web/sys/organizationunit/page`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags OrganizationUnit
     * @name WebSysOrganizationunitActiveUpdate
     * @summary 是否启用 (Auth policies: Web)
     * @request PUT:/api/web/sys/organizationunit/active/{id}
     * @secure
     */
    webSysOrganizationunitActiveUpdate: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/organizationunit/active/${id}`,
        method: 'PUT',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags OrganizationUnit
     * @name WebSysOrganizationunitSelectCreate
     * @summary 选择框 (Auth policies: Web)
     * @request POST:/api/web/sys/organizationunit/select
     * @secure
     */
    webSysOrganizationunitSelectCreate: (data: GetOrganizationUnitsInput, params: RequestParams = {}) =>
      this.request<ComboboxItemDtoListMessageModel, void>({
        path: `/api/web/sys/organizationunit/select`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags OrganizationUnit
     * @name WebSysOrganizationunitDetail
     * @summary 获取部门详情 (Auth policies: Web)
     * @request GET:/api/web/sys/organizationunit/{id}
     * @secure
     */
    webSysOrganizationunitDetail: (id: string, params: RequestParams = {}) =>
      this.request<OrganizationUnitsDtoMessageModel, void>({
        path: `/api/web/sys/organizationunit/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags OrganizationUnit
     * @name WebSysOrganizationunitUpdate
     * @summary 修改组织机构 (Auth policies: Web)
     * @request PUT:/api/web/sys/organizationunit/{id}
     * @secure
     */
    webSysOrganizationunitUpdate: (id: string, data: CreateOrganizationUnitsInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/sys/organizationunit/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags OrganizationUnit
     * @name WebSysOrganizationunitDelete
     * @summary 删除数据 (Auth policies: Web)
     * @request DELETE:/api/web/sys/organizationunit/{id}
     * @secure
     */
    webSysOrganizationunitDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/organizationunit/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags OrganizationUnit
     * @name WebSysOrganizationunitTreeList
     * @summary 获取组织机构结构树 (Auth policies: Web)
     * @request GET:/api/web/sys/organizationunit/tree
     * @secure
     */
    webSysOrganizationunitTreeList: (
      query?: {
        /** @default "0" */
        pid?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<OrganizationUnitsTreeDtoMessageModel, void>({
        path: `/api/web/sys/organizationunit/tree`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags OrganizationUnit
     * @name WebSysOrganizationunitUserCreate
     * @summary 移动部门 (Auth policies: Web)
     * @request POST:/api/web/sys/organizationunit/{orgid}/user
     * @secure
     */
    webSysOrganizationunitUserCreate: (orgId: string, orgid: string, data: string[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/organizationunit/${orgid}/user`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags OrganizationUnit
     * @name WebSysOrganizationunitBatchUpdate
     * @summary 批量更新状态 (Auth policies: Web)
     * @request PUT:/api/web/sys/organizationunit/batch/{status}
     * @secure
     */
    webSysOrganizationunitBatchUpdate: (status: string, data: string[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/organizationunit/batch/${status}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags OrganizationUnit
     * @name WebSysOrganizationunitMergeUpdate
     * @summary 合并组织机构 (Auth policies: Web)
     * @request PUT:/api/web/sys/organizationunit/merge
     * @secure
     */
    webSysOrganizationunitMergeUpdate: (data: MergeOrganizationInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/organizationunit/merge`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags OrganizationUnit
     * @name WebSysOrganizationunitCreate
     * @summary 创建部门机构 (Auth policies: Web)
     * @request POST:/api/web/sys/organizationunit
     * @secure
     */
    webSysOrganizationunitCreate: (data: CreateOrganizationUnitsInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/sys/organizationunit`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PayerInfo
     * @name WebCustomerPayerinfoGetpayerinfopageCreate
     * @summary 获取付款方分页 (Auth policies: Web)
     * @request POST:/api/web/customer/payerinfo/getpayerinfopage
     * @secure
     */
    webCustomerPayerinfoGetpayerinfopageCreate: (data: GetPayerInfoInput, params: RequestParams = {}) =>
      this.request<PayerInfoOutputPageModelMessageModel, void>({
        path: `/api/web/customer/payerinfo/getpayerinfopage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PayerInfo
     * @name WebCustomerPayerinfoGetpayerinfoselectCreate
     * @summary 获取付款方下拉 (Auth policies: Web)
     * @request POST:/api/web/customer/payerinfo/getpayerinfoselect
     * @secure
     */
    webCustomerPayerinfoGetpayerinfoselectCreate: (data: GetPayerInfoInput, params: RequestParams = {}) =>
      this.request<ComboboxItemDtoListMessageModel, void>({
        path: `/api/web/customer/payerinfo/getpayerinfoselect`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PayerInfo
     * @name WebCustomerPayerinfoGetpayerinfoCreate
     * @summary 获取付款方详情 (Auth policies: Web)
     * @request POST:/api/web/customer/payerinfo/getpayerinfo
     * @secure
     */
    webCustomerPayerinfoGetpayerinfoCreate: (data: StringEntityDto, params: RequestParams = {}) =>
      this.request<PayerInfoOutputMessageModel, void>({
        path: `/api/web/customer/payerinfo/getpayerinfo`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PayerInfo
     * @name WebCustomerPayerinfoGetpayerinfoforeditCreate
     * @summary 获取付款方编辑对象 (Auth policies: Web)
     * @request POST:/api/web/customer/payerinfo/getpayerinfoforedit
     * @secure
     */
    webCustomerPayerinfoGetpayerinfoforeditCreate: (data: StringEntityDto, params: RequestParams = {}) =>
      this.request<CreatePayerInfoInputForEditModelMessageModel, void>({
        path: `/api/web/customer/payerinfo/getpayerinfoforedit`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PayerInfo
     * @name WebCustomerPayerinfoCreateorupdatepayerinfoCreate
     * @summary 新增或修改付款方 (Auth policies: Web)
     * @request POST:/api/web/customer/payerinfo/createorupdatepayerinfo
     * @secure
     */
    webCustomerPayerinfoCreateorupdatepayerinfoCreate: (data: CreatePayerInfoInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/customer/payerinfo/createorupdatepayerinfo`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PayerInfo
     * @name WebCustomerPayerinfoDeletepayerinfoCreate
     * @summary 删除付款方 (Auth policies: Web)
     * @request POST:/api/web/customer/payerinfo/deletepayerinfo
     * @secure
     */
    webCustomerPayerinfoDeletepayerinfoCreate: (data: StringEntityDto, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/customer/payerinfo/deletepayerinfo`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PayerInfo
     * @name WebCustomerPayerinfoGetu8PayerDetail
     * @summary GetU8Payer (Auth policies: Web)
     * @request GET:/api/web/customer/payerinfo/getu8payer/{id}
     * @secure
     */
    webCustomerPayerinfoGetu8PayerDetail: (id: string, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/customer/payerinfo/getu8payer/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Permission
     * @name WebSysPermissionGetList
     * @summary 获取菜单 (Auth policies: Web)
     * @request GET:/api/web/sys/permission/get
     * @secure
     */
    webSysPermissionGetList: (
      query?: {
        /**
         * @format int32
         * @default 1
         */
        page?: number;
        /** @default "" */
        key?: string;
        /**
         * @format int32
         * @default 50
         */
        pageSize?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<SysPermissionPageModelMessageModel, void>({
        path: `/api/web/sys/permission/get`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Permission
     * @name WebSysPermissionGettreetableList
     * @summary 查询树形 Table
     * @request GET:/api/web/sys/permission/gettreetable
     */
    webSysPermissionGettreetableList: (
      query?: {
        /**
         * 父节点
         * @default "0"
         */
        f?: string;
        /**
         * 关键字
         * @default ""
         */
        key?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<SysPermissionListMessageModel, any>({
        path: `/api/web/sys/permission/gettreetable`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Permission
     * @name WebSysPermissionPostCreate
     * @summary 添加一个菜单 (Auth policies: Web)
     * @request POST:/api/web/sys/permission/post
     * @secure
     */
    webSysPermissionPostCreate: (data: SysPermission, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/sys/permission/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Permission
     * @name WebSysPermissionAssignCreate
     * @summary 保存菜单权限分配 (Auth policies: Web)
     * @request POST:/api/web/sys/permission/assign
     * @secure
     */
    webSysPermissionAssignCreate: (data: AssignView, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/sys/permission/assign`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Permission
     * @name WebSysPermissionGetpermissiontreeList
     * @summary 获取菜单树 (Auth policies: Web)
     * @request GET:/api/web/sys/permission/getpermissiontree
     * @secure
     */
    webSysPermissionGetpermissiontreeList: (
      query?: {
        /** @default "0" */
        pid?: string;
        /** @default false */
        needbtn?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<PermissionTreeMessageModel, void>({
        path: `/api/web/sys/permission/getpermissiontree`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Permission
     * @name WebSysPermissionGetnavigationbarList
     * @summary 获取路由树 (Auth policies: Web)
     * @request GET:/api/web/sys/permission/getnavigationbar
     * @secure
     */
    webSysPermissionGetnavigationbarList: (
      query?: {
        uid?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<NavigationBarMessageModel, void>({
        path: `/api/web/sys/permission/getnavigationbar`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Permission
     * @name WebSysPermissionGetnavigationbarproList
     * @summary 获取路由树 (Auth policies: Web)
     * @request GET:/api/web/sys/permission/getnavigationbarpro
     * @secure
     */
    webSysPermissionGetnavigationbarproList: (
      query?: {
        uid?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<NavigationBarProListMessageModel, void>({
        path: `/api/web/sys/permission/getnavigationbarpro`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Permission
     * @name WebSysPermissionGetpermissionidbyroleidList
     * @summary 通过角色获取菜单
     * @request GET:/api/web/sys/permission/getpermissionidbyroleid
     */
    webSysPermissionGetpermissionidbyroleidList: (
      query?: {
        /** @default "0" */
        rid?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<AssignShowMessageModel, any>({
        path: `/api/web/sys/permission/getpermissionidbyroleid`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Permission
     * @name WebSysPermissionPutUpdate
     * @summary 更新菜单 (Auth policies: Web)
     * @request PUT:/api/web/sys/permission/put
     * @secure
     */
    webSysPermissionPutUpdate: (data: SysPermission, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/sys/permission/put`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Permission
     * @name WebSysPermissionDeleteDelete
     * @summary 删除菜单 (Auth policies: Web)
     * @request DELETE:/api/web/sys/permission/delete
     * @secure
     */
    webSysPermissionDeleteDelete: (
      query?: {
        /** @format int32 */
        id?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/sys/permission/delete`,
        method: 'DELETE',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Permission
     * @name WebSysPermissionBatchpostCreate
     * @summary 导入多条菜单信息 (Auth policies: Web)
     * @request POST:/api/web/sys/permission/batchpost
     * @secure
     */
    webSysPermissionBatchpostCreate: (data: SysPermission[], params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/sys/permission/batchpost`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Permission
     * @name WebSysPermissionMigratepermissionList
     * @summary 系统接口菜单同步接口 (Auth policies: Web)
     * @request GET:/api/web/sys/permission/migratepermission
     * @secure
     */
    webSysPermissionMigratepermissionList: (
      query?: {
        /** @default "" */
        action?: string;
        /**
         * 接口module的控制器名称
         * @default ""
         */
        controllerName?: string;
        /**
         * 菜单permission的父id
         * @default "0"
         */
        pid?: string;
        /**
         * 是否执行迁移到数据
         * @default false
         */
        isAction?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<SysPermissionListMessageModel, void>({
        path: `/api/web/sys/permission/migratepermission`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
 * No description
 *
 * @tags Role
 * @name WebSysRolePageCreate
 * @summary z
            获取角色 (Auth policies: Web)
 * @request POST:/api/web/sys/role/page
 * @secure
 */
    webSysRolePageCreate: (data: GetRoleInput, params: RequestParams = {}) =>
      this.request<RoleDtoPageModelMessageModel, void>({
        path: `/api/web/sys/role/page`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Role
     * @name WebSysRoleAllList
     * @summary 获取列表数据 (Auth policies: Web)
     * @request GET:/api/web/sys/role/all
     * @secure
     */
    webSysRoleAllList: (
      query?: {
        /** 关键词 */
        key?: string;
        /** 用户组Id */
        roleGroupId?: string;
        /**
         * 角色类型
         * @format int32
         */
        roleTypeEnum?: 0 | 1 | 2 | 3;
        /** 权限类型 */
        roleTypes?: (0 | 1 | 2 | 3)[];
        /** 是否启用 */
        isActive?: boolean;
        /** 是否默认角色 */
        isDefault?: boolean;
        /** 虚拟角色 */
        isVirtual?: boolean;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<RoleDtoListMessageModel, void>({
        path: `/api/web/sys/role/all`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Role
     * @name WebSysRoleDetail
     * @summary  (Auth policies: Web)
     * @request GET:/api/web/sys/role/{id}
     * @secure
     */
    webSysRoleDetail: (id: string, params: RequestParams = {}) =>
      this.request<RoleDtoMessageModel, void>({
        path: `/api/web/sys/role/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Role
     * @name WebSysRoleUpdate
     * @summary 更新角色 (Auth policies: Web)
     * @request PUT:/api/web/sys/role/{id}
     * @secure
     */
    webSysRoleUpdate: (id: string, data: CreateRoleInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/sys/role/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Role
     * @name WebSysRoleDelete
     * @summary 删除角色 (Auth policies: Web)
     * @request DELETE:/api/web/sys/role/{id}
     * @secure
     */
    webSysRoleDelete: (id: string, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/sys/role/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Role
     * @name WebSysRoleOrganizationunitDetail
     * @summary 获取角色数据权限 (Auth policies: Web)
     * @request GET:/api/web/sys/role/{id}/organizationunit
     * @secure
     */
    webSysRoleOrganizationunitDetail: (id: string, params: RequestParams = {}) =>
      this.request<OrganizationUnitsTreeDtoMessageModel, void>({
        path: `/api/web/sys/role/${id}/organizationunit`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Role
     * @name WebSysRoleOrganizationunitCreate
     * @summary 设置角色数据权限 (Auth policies: Web)
     * @request POST:/api/web/sys/role/{id}/organizationunit
     * @secure
     */
    webSysRoleOrganizationunitCreate: (id: string, data: string[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/role/${id}/organizationunit`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Role
     * @name WebSysRolePermissionDetail
     * @summary 获取角色权限 (Auth policies: Web)
     * @request GET:/api/web/sys/role/{id}/permission
     * @secure
     */
    webSysRolePermissionDetail: (id: string, params: RequestParams = {}) =>
      this.request<PermissionTreeMessageModel, void>({
        path: `/api/web/sys/role/${id}/permission`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Role
     * @name WebSysRolePermissionCreate
     * @summary 添加角色权限 (Auth policies: Web)
     * @request POST:/api/web/sys/role/{id}/permission
     * @secure
     */
    webSysRolePermissionCreate: (id: string, data: string[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/role/${id}/permission`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Role
     * @name WebSysRoleCreate
     * @summary 添加角色 (Auth policies: Web)
     * @request POST:/api/web/sys/role
     * @secure
     */
    webSysRoleCreate: (data: CreateRoleInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/sys/role`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Role
     * @name WebSysRoleUnbindUserCreate
     * @summary 删除绑定用户 (Auth policies: Web)
     * @request POST:/api/web/sys/role/unbind/{id}/user
     * @secure
     */
    webSysRoleUnbindUserCreate: (id: string, data: string[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/role/unbind/${id}/user`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Role
     * @name WebSysRoleActiveUpdate
     * @summary 是否启用 (Auth policies: Web)
     * @request PUT:/api/web/sys/role/active/{id}
     * @secure
     */
    webSysRoleActiveUpdate: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/role/active/${id}`,
        method: 'PUT',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Role
     * @name WebSysRoleBindGroupCreate
     * @summary 添加用户到用户组 (Auth policies: Web)
     * @request POST:/api/web/sys/role/bind/{id}/group
     * @secure
     */
    webSysRoleBindGroupCreate: (id: string, data: string[], params: RequestParams = {}) =>
      this.request<BooleanMessageModel, void>({
        path: `/api/web/sys/role/bind/${id}/group`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Role
     * @name WebSysRoleBindUserCreate
     * @summary 为一个角色添加多个用户 (Auth policies: Web)
     * @request POST:/api/web/sys/role/bind/{id}/user
     * @secure
     */
    webSysRoleBindUserCreate: (id: string, data: string[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/role/bind/${id}/user`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SequenceNumberRules
     * @name WebSysSequencenumberrulesPageCreate
     * @summary 获取序列号规则分页 (Auth)
     * @request POST:/api/web/sys/sequencenumberrules/page
     * @secure
     */
    webSysSequencenumberrulesPageCreate: (data: GetSequenceNumberRulesInput, params: RequestParams = {}) =>
      this.request<SequenceNumberRulesOutputPageModelMessageModel, void>({
        path: `/api/web/sys/sequencenumberrules/page`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SequenceNumberRules
     * @name WebSysSequencenumberrulesHistoryPageCreate
     * @summary 获取历史序列号规则分页 (Auth)
     * @request POST:/api/web/sys/sequencenumberrules/history/page
     * @secure
     */
    webSysSequencenumberrulesHistoryPageCreate: (data: GetSequenceNumberRulesHistoryInput, params: RequestParams = {}) =>
      this.request<SequenceNumberRulesDtoPageModelMessageModel, void>({
        path: `/api/web/sys/sequencenumberrules/history/page`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SequenceNumberRules
     * @name WebSysSequencenumberrulesGetEditList
     * @summary 获取序列号规则编辑对象 (Auth)
     * @request GET:/api/web/sys/sequencenumberrules/get/edit
     * @secure
     */
    webSysSequencenumberrulesGetEditList: (
      query?: {
        id?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<CreateSequenceNumberRulesInputMessageModel, void>({
        path: `/api/web/sys/sequencenumberrules/get/edit`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SequenceNumberRules
     * @name WebSysSequencenumberrulesGetList
     * @summary 获取序列号规则详情 (Auth)
     * @request GET:/api/web/sys/sequencenumberrules/get
     * @secure
     */
    webSysSequencenumberrulesGetList: (
      query?: {
        id?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<SequenceNumberRulesDtoMessageModel, void>({
        path: `/api/web/sys/sequencenumberrules/get`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SequenceNumberRules
     * @name WebSysSequencenumberrulesPostCreate
     * @summary 新增序列号规则 (Auth)
     * @request POST:/api/web/sys/sequencenumberrules/post
     * @secure
     */
    webSysSequencenumberrulesPostCreate: (data: CreateSequenceNumberRulesInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/sequencenumberrules/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SequenceNumberRules
     * @name WebSysSequencenumberrulesPutUpdate
     * @summary 修改序列号规则 (Auth)
     * @request PUT:/api/web/sys/sequencenumberrules/put
     * @secure
     */
    webSysSequencenumberrulesPutUpdate: (
      data: CreateSequenceNumberRulesInput,
      query?: {
        id?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/sequencenumberrules/put`,
        method: 'PUT',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SequenceNumberRules
     * @name WebSysSequencenumberrulesUpdate
     * @summary 设置编码规则状态 (Auth)
     * @request PUT:/api/web/sys/sequencenumberrules
     * @secure
     */
    webSysSequencenumberrulesUpdate: (data: SetSequenceNumberRulesStatus, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/sequencenumberrules`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SequenceNumberRules
     * @name WebSysSequencenumberrulesDeleteDelete
     * @summary 删除序列号规则 (Auth)
     * @request DELETE:/api/web/sys/sequencenumberrules/delete
     * @secure
     */
    webSysSequencenumberrulesDeleteDelete: (
      query?: {
        id?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<BooleanMessageModel, void>({
        path: `/api/web/sys/sequencenumberrules/delete`,
        method: 'DELETE',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Setting
     * @name WebSysSettingPageCreate
     * @summary 获取所有配置 (Auth policies: Web)
     * @request POST:/api/web/sys/setting/page
     * @secure
     */
    webSysSettingPageCreate: (data: GetSettingInput, params: RequestParams = {}) =>
      this.request<SettingDtoPageModelMessageModel, void>({
        path: `/api/web/sys/setting/page`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Setting
     * @name WebSysSettingKeyList
     * @summary 获取配置key (Auth policies: Web)
     * @request GET:/api/web/sys/setting/key
     * @secure
     */
    webSysSettingKeyList: (params: RequestParams = {}) =>
      this.request<ItemDtoListMessageModel, void>({
        path: `/api/web/sys/setting/key`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Setting
     * @name WebSysSettingCreate
     * @summary 创建配置 (Auth policies: Web)
     * @request POST:/api/web/sys/setting
     * @secure
     */
    webSysSettingCreate: (data: CreateSettingInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/setting`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Setting
     * @name WebSysSettingUpdate
     * @summary 修改配置 (Auth policies: Web)
     * @request PUT:/api/web/sys/setting/{id}
     * @secure
     */
    webSysSettingUpdate: (id: string, data: CreateSettingInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/setting/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Setting
     * @name WebSysSettingDelete
     * @summary 删除配置 (Auth policies: Web)
     * @request DELETE:/api/web/sys/setting/{id}
     * @secure
     */
    webSysSettingDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/setting/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Supplier
     * @name WebSysSupplierPageCreate
     * @summary 获取 (Auth policies: Web)
     * @request POST:/api/web/sys/supplier/page
     * @secure
     */
    webSysSupplierPageCreate: (data: GetSupplierInput, params: RequestParams = {}) =>
      this.request<SupplierDtoPageModelMessageModel, void>({
        path: `/api/web/sys/supplier/page`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Supplier
     * @name WebSysSupplierCreate
     * @summary 创建 (Auth policies: Web)
     * @request POST:/api/web/sys/supplier
     * @secure
     */
    webSysSupplierCreate: (data: CreateSupplierInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/sys/supplier`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Supplier
     * @name WebSysSupplierUpdate
     * @summary 更新 (Auth policies: Web)
     * @request PUT:/api/web/sys/supplier/{id}
     * @secure
     */
    webSysSupplierUpdate: (id: string, data: CreateSupplierInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/supplier/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Supplier
     * @name WebSysSupplierDelete
     * @summary 删除供应商 (Auth policies: Web)
     * @request DELETE:/api/web/sys/supplier/{id}
     * @secure
     */
    webSysSupplierDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/supplier/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SupplierPlugin
     * @name WebSysSupplierpluginPageCreate
     * @summary 获取 (Auth policies: Web)
     * @request POST:/api/web/sys/supplierplugin/page
     * @secure
     */
    webSysSupplierpluginPageCreate: (data: GetSupplierPluginInput, params: RequestParams = {}) =>
      this.request<SupplierPluginDtoPageModelMessageModel, void>({
        path: `/api/web/sys/supplierplugin/page`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SupplierPlugin
     * @name WebSysSupplierpluginPlugintypesList
     * @summary 获取插件类型 (Auth policies: Web)
     * @request GET:/api/web/sys/supplierplugin/plugintypes
     * @secure
     */
    webSysSupplierpluginPlugintypesList: (params: RequestParams = {}) =>
      this.request<PluginItemListMessageModel, void>({
        path: `/api/web/sys/supplierplugin/plugintypes`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SupplierPlugin
     * @name WebSysSupplierpluginPluginconfigList
     * @summary 获取插件类型字段 (Auth policies: Web)
     * @request GET:/api/web/sys/supplierplugin/pluginconfig
     * @secure
     */
    webSysSupplierpluginPluginconfigList: (
      query?: {
        pluginName?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<string[], void>({
        path: `/api/web/sys/supplierplugin/pluginconfig`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SupplierPlugin
     * @name WebSysSupplierpluginCreate
     * @summary 创建 (Auth policies: Web)
     * @request POST:/api/web/sys/supplierplugin
     * @secure
     */
    webSysSupplierpluginCreate: (data: CreateSupplierPluginInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/supplierplugin`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SupplierPlugin
     * @name WebSysSupplierpluginUpdate
     * @summary 更新 (Auth policies: Web)
     * @request PUT:/api/web/sys/supplierplugin/{id}
     * @secure
     */
    webSysSupplierpluginUpdate: (id: string, data: CreateSupplierPluginInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/supplierplugin/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SupplierPlugin
     * @name WebSysSupplierpluginDelete
     * @summary 删除供应商插件 (Auth policies: Web)
     * @request DELETE:/api/web/sys/supplierplugin/{id}
     * @secure
     */
    webSysSupplierpluginDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/supplierplugin/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysCountry
     * @name WebSysCountrySyscountryGetpageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/sys/country/syscountry/getpage
     * @secure
     */
    webSysCountrySyscountryGetpageCreate: (data: GetSysCountryInput, params: RequestParams = {}) =>
      this.request<GetSysCountryOutputPageModelMessageModel, void>({
        path: `/api/web/sys/country/syscountry/getpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysCountry
     * @name WebSysCountrySyscountryListCreate
     * @summary 列表获取所有 (Auth policies: Web)
     * @request POST:/api/web/sys/country/syscountry/list
     * @secure
     */
    webSysCountrySyscountryListCreate: (data: GetSysCountryInput, params: RequestParams = {}) =>
      this.request<GetSysCountryOutputListMessageModel, void>({
        path: `/api/web/sys/country/syscountry/list`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysCountry
     * @name WebSysCountrySyscountryGetsyscountryexportCreate
     * @summary 列表导出 (Auth policies: Web)
     * @request POST:/api/web/sys/country/syscountry/getsyscountryexport
     * @secure
     */
    webSysCountrySyscountryGetsyscountryexportCreate: (data: GetSysCountryInputExportInput, params: RequestParams = {}) =>
      this.request<ExcelFileDtoMessageModel, void>({
        path: `/api/web/sys/country/syscountry/getsyscountryexport`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysCountry
     * @name WebSysCountrySyscountryGetselectCreate
     * @summary 选择框 (Auth policies: Web)
     * @request POST:/api/web/sys/country/syscountry/getselect
     * @secure
     */
    webSysCountrySyscountryGetselectCreate: (data: GetOrganizationUnitsInput, params: RequestParams = {}) =>
      this.request<ComboboxItemDtoListMessageModel, void>({
        path: `/api/web/sys/country/syscountry/getselect`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysCountry
     * @name WebSysCountrySyscountryGetCreate
     * @summary 获取详情 (Auth policies: Web)
     * @request POST:/api/web/sys/country/syscountry/get/{id}
     * @secure
     */
    webSysCountrySyscountryGetCreate: (id: string, params: RequestParams = {}) =>
      this.request<SysCountryDtoMessageModel, void>({
        path: `/api/web/sys/country/syscountry/get/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysCountry
     * @name WebSysCountrySyscountryGetforeditCreate
     * @summary 获取修改实体 (Auth policies: Web)
     * @request POST:/api/web/sys/country/syscountry/getforedit/{id}
     * @secure
     */
    webSysCountrySyscountryGetforeditCreate: (id: string, params: RequestParams = {}) =>
      this.request<GetSysCountryForEditOutputForEditModelMessageModel, void>({
        path: `/api/web/sys/country/syscountry/getforedit/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysCountry
     * @name WebSysCountrySyscountryPostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/sys/country/syscountry/post
     * @secure
     */
    webSysCountrySyscountryPostCreate: (data: CreateSysCountryInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/country/syscountry/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysCountry
     * @name WebSysCountrySyscountryPutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/sys/country/syscountry/put/{id}
     * @secure
     */
    webSysCountrySyscountryPutUpdate: (id: string, data: CreateSysCountryInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/country/syscountry/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysCountry
     * @name WebSysCountrySyscountryDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/sys/country/syscountry/delete
     * @secure
     */
    webSysCountrySyscountryDeleteDelete: (data: StringEntityDto, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/country/syscountry/delete`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysCountry
     * @name WebSysCountrySyscountryBatchdeleteCreate
     * @summary 批量删除 (Auth policies: Web)
     * @request POST:/api/web/sys/country/syscountry/batchdelete
     * @secure
     */
    webSysCountrySyscountryBatchdeleteCreate: (data: StringEntityDto[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/country/syscountry/batchdelete`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysCountry
     * @name WebSysCountrySyscountryImportCreate
     * @summary 国家地区导入 (Auth policies: Web)
     * @request POST:/api/web/sys/country/syscountry/import
     * @secure
     */
    webSysCountrySyscountryImportCreate: (
      data: {
        /**
         * 文件
         * @format binary
         */
        File?: File;
        /** 路径 */
        Path?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/sys/country/syscountry/import`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysDataSelect
     * @name WebSysSysdataselectGetclientCreate
     * @summary 客户选择器 (Auth policies: Web)
     * @request POST:/api/web/sys/sysdataselect/getclient
     * @secure
     */
    webSysSysdataselectGetclientCreate: (data: GetBaseDataSelectInput, params: RequestParams = {}) =>
      this.request<DataSelectClientDtoPageModelMessageModel, void>({
        path: `/api/web/sys/sysdataselect/getclient`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysDataSelect
     * @name WebSysSysdataselectGetclientpaymentCreate
     * @summary 客户付款人选择器 (Auth policies: Web)
     * @request POST:/api/web/sys/sysdataselect/getclientpayment
     * @secure
     */
    webSysSysdataselectGetclientpaymentCreate: (data: GetClientPaymentDataSelectInput, params: RequestParams = {}) =>
      this.request<DataSelectClientPaymentDtoPageModelMessageModel, void>({
        path: `/api/web/sys/sysdataselect/getclientpayment`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysDataSelect
     * @name WebSysSysdataselectGetclientinvoiceheaderCreate
     * @summary 客户发票抬头选择器 (Auth policies: Web)
     * @request POST:/api/web/sys/sysdataselect/getclientinvoiceheader
     * @secure
     */
    webSysSysdataselectGetclientinvoiceheaderCreate: (data: GetClientPaymentDataSelectInput, params: RequestParams = {}) =>
      this.request<DataSelectClientInvoiceHeaderDtoPageModelMessageModel, void>({
        path: `/api/web/sys/sysdataselect/getclientinvoiceheader`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysDataSelect
     * @name WebSysSysdataselectGetcaseCreate
     * @summary 案件选择器 (Auth policies: Web)
     * @request POST:/api/web/sys/sysdataselect/getcase
     * @secure
     */
    webSysSysdataselectGetcaseCreate: (data: GetCaseDataSelectInput, params: RequestParams = {}) =>
      this.request<DataSelectCaseDtoPageModelMessageModel, void>({
        path: `/api/web/sys/sysdataselect/getcase`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysDataSelect
     * @name WebSysSysdataselectGetcaselawyerCreate
     * @summary 案件参案律师选择器 (Auth policies: Web)
     * @request POST:/api/web/sys/sysdataselect/getcaselawyer
     * @secure
     */
    webSysSysdataselectGetcaselawyerCreate: (data: GetCaseLawyerSelectInput, params: RequestParams = {}) =>
      this.request<DataSelectCaseLawyerInfoDtoPageModelMessageModel, void>({
        path: `/api/web/sys/sysdataselect/getcaselawyer`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysDataSelect
     * @name WebSysSysdataselectGetflowstatusCreate
     * @summary 工作流状态选择器   暂时未实现，等待工作流分支合并 (Auth policies: Web)
     * @request POST:/api/web/sys/sysdataselect/getflowstatus
     * @secure
     */
    webSysSysdataselectGetflowstatusCreate: (
      query?: {
        flowName?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<DataSelectFlowStatusDtoPageModelMessageModel, void>({
        path: `/api/web/sys/sysdataselect/getflowstatus`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysDataSelect
     * @name WebSysSysdataselectGetbranchofficeCreate
     * @summary 办公室银行账号选择器 (Auth policies: Web)
     * @request POST:/api/web/sys/sysdataselect/getbranchoffice
     * @secure
     */
    webSysSysdataselectGetbranchofficeCreate: (data: GetOfficeDataSelectInput, params: RequestParams = {}) =>
      this.request<DataSelectBranchOfficeDtoPageModelMessageModel, void>({
        path: `/api/web/sys/sysdataselect/getbranchoffice`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysDataSelect
     * @name WebSysSysdataselectGetorganizationunitCreate
     * @summary 组织机构选择器 (Auth policies: Web)
     * @request POST:/api/web/sys/sysdataselect/getorganizationunit
     * @secure
     */
    webSysSysdataselectGetorganizationunitCreate: (data: GetBaseDataSelectInput, params: RequestParams = {}) =>
      this.request<DataSelectOrganizationUnitDtoPageModelMessageModel, void>({
        path: `/api/web/sys/sysdataselect/getorganizationunit`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysDataSelect
     * @name WebSysSysdataselectGetuserinfoCreate
     * @summary 人员选择器 (Auth policies: Web)
     * @request POST:/api/web/sys/sysdataselect/getuserinfo
     * @secure
     */
    webSysSysdataselectGetuserinfoCreate: (data: GetUserSelectInput, params: RequestParams = {}) =>
      this.request<DataSelectUserInfoDtoPageModelMessageModel, void>({
        path: `/api/web/sys/sysdataselect/getuserinfo`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysDataSelect
     * @name WebSysSysdataselectGetroleCreate
     * @summary 角色选择器 (Auth policies: Web)
     * @request POST:/api/web/sys/sysdataselect/getrole
     * @secure
     */
    webSysSysdataselectGetroleCreate: (data: GetBaseDataSelectInput, params: RequestParams = {}) =>
      this.request<DataSelectRoleDtoPageModelMessageModel, void>({
        path: `/api/web/sys/sysdataselect/getrole`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysDataSelect
     * @name WebSysSysdataselectGetpositionCreate
     * @summary 岗位选择器 (Auth policies: Web)
     * @request POST:/api/web/sys/sysdataselect/getposition
     * @secure
     */
    webSysSysdataselectGetpositionCreate: (data: GetBaseDataSelectInput, params: RequestParams = {}) =>
      this.request<DataSelectRoleDtoPageModelMessageModel, void>({
        path: `/api/web/sys/sysdataselect/getposition`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysDataSelect
     * @name WebSysSysdataselectGetusergroupCreate
     * @summary 用户组选择器 (Auth policies: Web)
     * @request POST:/api/web/sys/sysdataselect/getusergroup
     * @secure
     */
    webSysSysdataselectGetusergroupCreate: (data: GetBaseDataSelectInput, params: RequestParams = {}) =>
      this.request<DataSelectRoleDtoPageModelMessageModel, void>({
        path: `/api/web/sys/sysdataselect/getusergroup`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysDocumentCenter
     * @name WebSysDocumentcenterSysdocumentcenterGetpageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/sys/documentcenter/sysdocumentcenter/getpage
     * @secure
     */
    webSysDocumentcenterSysdocumentcenterGetpageCreate: (data: GetSysDocumentCenterInput, params: RequestParams = {}) =>
      this.request<GetSysDocumentCenterOutputPageModelMessageModel, void>({
        path: `/api/web/sys/documentcenter/sysdocumentcenter/getpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysDocumentCenter
     * @name WebSysDocumentcenterSysdocumentcenterListCreate
     * @summary 列表获取所有 (Auth policies: Web)
     * @request POST:/api/web/sys/documentcenter/sysdocumentcenter/list
     * @secure
     */
    webSysDocumentcenterSysdocumentcenterListCreate: (data: GetSysDocumentCenterInput, params: RequestParams = {}) =>
      this.request<GetSysDocumentCenterOutputListMessageModel, void>({
        path: `/api/web/sys/documentcenter/sysdocumentcenter/list`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysDocumentCenter
     * @name WebSysDocumentcenterSysdocumentcenterGetselectCreate
     * @summary 选择框 (Auth policies: Web)
     * @request POST:/api/web/sys/documentcenter/sysdocumentcenter/getselect
     * @secure
     */
    webSysDocumentcenterSysdocumentcenterGetselectCreate: (data: GetOrganizationUnitsInput, params: RequestParams = {}) =>
      this.request<ComboboxItemDtoListMessageModel, void>({
        path: `/api/web/sys/documentcenter/sysdocumentcenter/getselect`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysDocumentCenter
     * @name WebSysDocumentcenterSysdocumentcenterGetCreate
     * @summary 获取详情 (Auth policies: Web)
     * @request POST:/api/web/sys/documentcenter/sysdocumentcenter/get/{id}
     * @secure
     */
    webSysDocumentcenterSysdocumentcenterGetCreate: (id: string, params: RequestParams = {}) =>
      this.request<SysDocumentCenterDtoMessageModel, void>({
        path: `/api/web/sys/documentcenter/sysdocumentcenter/get/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysDocumentCenter
     * @name WebSysDocumentcenterSysdocumentcenterGetforeditCreate
     * @summary 获取修改实体 (Auth policies: Web)
     * @request POST:/api/web/sys/documentcenter/sysdocumentcenter/getforedit/{id}
     * @secure
     */
    webSysDocumentcenterSysdocumentcenterGetforeditCreate: (id: string, params: RequestParams = {}) =>
      this.request<GetSysDocumentCenterForEditOutputForEditModelMessageModel, void>({
        path: `/api/web/sys/documentcenter/sysdocumentcenter/getforedit/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysDocumentCenter
     * @name WebSysDocumentcenterSysdocumentcenterPostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/sys/documentcenter/sysdocumentcenter/post
     * @secure
     */
    webSysDocumentcenterSysdocumentcenterPostCreate: (data: CreateSysDocumentCenterInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/documentcenter/sysdocumentcenter/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysDocumentCenter
     * @name WebSysDocumentcenterSysdocumentcenterPutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/sys/documentcenter/sysdocumentcenter/put/{id}
     * @secure
     */
    webSysDocumentcenterSysdocumentcenterPutUpdate: (id: string, data: CreateSysDocumentCenterInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/documentcenter/sysdocumentcenter/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysDocumentCenter
     * @name WebSysDocumentcenterSysdocumentcenterDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/sys/documentcenter/sysdocumentcenter/delete
     * @secure
     */
    webSysDocumentcenterSysdocumentcenterDeleteDelete: (data: StringEntityDto, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/documentcenter/sysdocumentcenter/delete`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysDocumentCenter
     * @name WebSysDocumentcenterSysdocumentcenterBatchdeleteCreate
     * @summary 批量删除 (Auth policies: Web)
     * @request POST:/api/web/sys/documentcenter/sysdocumentcenter/batchdelete
     * @secure
     */
    webSysDocumentcenterSysdocumentcenterBatchdeleteCreate: (data: StringEntityDto[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/documentcenter/sysdocumentcenter/batchdelete`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmailTemplates
     * @name WebSysSysemailtemplatesPageCreate
     * @summary 获取邮件模版分页列表 (Auth policies: Web)
     * @request POST:/api/web/sys/sysemailtemplates/page
     * @secure
     */
    webSysSysemailtemplatesPageCreate: (data: GetSysEmailTemplatesInput, params: RequestParams = {}) =>
      this.request<SysEmailTemplatesDtoPageModelMessageModel, void>({
        path: `/api/web/sys/sysemailtemplates/page`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmailTemplates
     * @name WebSysSysemailtemplatesListCreate
     * @summary 获取所有邮件模版列表 (Auth policies: Web)
     * @request POST:/api/web/sys/sysemailtemplates/list
     * @secure
     */
    webSysSysemailtemplatesListCreate: (data: GetSysEmailTemplatesInput, params: RequestParams = {}) =>
      this.request<SysEmailTemplatesDtoListMessageModel, void>({
        path: `/api/web/sys/sysemailtemplates/list`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmailTemplates
     * @name WebSysSysemailtemplatesDetail
     * @summary 获取邮件模版详情 (Auth policies: Web)
     * @request GET:/api/web/sys/sysemailtemplates/{id}
     * @secure
     */
    webSysSysemailtemplatesDetail: (id: string, params: RequestParams = {}) =>
      this.request<SysEmailTemplatesDtoMessageModel, void>({
        path: `/api/web/sys/sysemailtemplates/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmailTemplates
     * @name WebSysSysemailtemplatesUpdate
     * @summary 修改邮件模版 (Auth policies: Web)
     * @request PUT:/api/web/sys/sysemailtemplates/{id}
     * @secure
     */
    webSysSysemailtemplatesUpdate: (id: string, data: CreateSysEmailTemplatesInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/sysemailtemplates/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmailTemplates
     * @name WebSysSysemailtemplatesDelete
     * @summary 删除邮件模版 (Auth policies: Web)
     * @request DELETE:/api/web/sys/sysemailtemplates/{id}
     * @secure
     */
    webSysSysemailtemplatesDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/sysemailtemplates/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmailTemplates
     * @name WebSysSysemailtemplatesCreate
     * @summary 添加邮件模版 (Auth policies: Web)
     * @request POST:/api/web/sys/sysemailtemplates
     * @secure
     */
    webSysSysemailtemplatesCreate: (data: CreateSysEmailTemplatesInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/sysemailtemplates`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeAwards
     * @name WebSysEmployeeSysemployeeawardsPageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/sys/employee/sysemployeeawards/page
     * @secure
     */
    webSysEmployeeSysemployeeawardsPageCreate: (
      query?: {
        /**
         * 入职日期
         * @format date-time
         */
        entryDate?: string;
        /** 类别 */
        type?: string;
        /**
         * 最后工作日
         * @format date-time
         */
        lastWorkDate?: string;
        /** 描述 */
        discription?: string;
        /** 奖项 */
        awardName?: string;
        /** 工号 */
        jobNumber?: string;
        /**
         * 奖励级别
         * @format int32
         */
        level?: number;
        /**
         * 获奖日期
         * @format date-time
         */
        date?: string;
        /** 雇员Id */
        userId?: string;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<SysEmployeeAwardsDtoPageModelMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeawards/page`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeAwards
     * @name WebSysEmployeeSysemployeeawardsListList
     * @summary 列表获取所有 (Auth policies: Web)
     * @request GET:/api/web/sys/employee/sysemployeeawards/list
     * @secure
     */
    webSysEmployeeSysemployeeawardsListList: (
      query?: {
        /**
         * 入职日期
         * @format date-time
         */
        entryDate?: string;
        /** 类别 */
        type?: string;
        /**
         * 最后工作日
         * @format date-time
         */
        lastWorkDate?: string;
        /** 描述 */
        discription?: string;
        /** 奖项 */
        awardName?: string;
        /** 工号 */
        jobNumber?: string;
        /**
         * 奖励级别
         * @format int32
         */
        level?: number;
        /**
         * 获奖日期
         * @format date-time
         */
        date?: string;
        /** 雇员Id */
        userId?: string;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<SysEmployeeAwardsDtoListMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeawards/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeAwards
     * @name WebSysEmployeeSysemployeeawardsDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/sys/employee/sysemployeeawards/{id}
     * @secure
     */
    webSysEmployeeSysemployeeawardsDetail: (id: string, params: RequestParams = {}) =>
      this.request<SysEmployeeAwardsDtoMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeawards/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeAwards
     * @name WebSysEmployeeSysemployeeawardsUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/sys/employee/sysemployeeawards/{id}
     * @secure
     */
    webSysEmployeeSysemployeeawardsUpdate: (id: string, data: CreateSysEmployeeAwardsInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeawards/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeAwards
     * @name WebSysEmployeeSysemployeeawardsDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/sys/employee/sysemployeeawards/{id}
     * @secure
     */
    webSysEmployeeSysemployeeawardsDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeawards/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeAwards
     * @name WebSysEmployeeSysemployeeawardsCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/sys/employee/sysemployeeawards
     * @secure
     */
    webSysEmployeeSysemployeeawardsCreate: (data: CreateSysEmployeeAwardsInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeawards`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeCertificates
     * @name WebSysEmployeeSysemployeecertificatesPageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/sys/employee/sysemployeecertificates/page
     * @secure
     */
    webSysEmployeeSysemployeecertificatesPageCreate: (
      query?: {
        /** 证书名称 */
        name?: string;
        /** 证书描述 */
        discription?: string;
        /**
         * 获得时间
         * @format date-time
         */
        gettime?: string;
        /** 学习证书Id */
        learningCertificateId?: string;
        /**
         * 有效期至
         * @format date-time
         */
        validuntil?: string;
        /**
         * 入职日期
         * @format date-time
         */
        entryDate?: string;
        /** 证书编号 */
        certificateNo?: string;
        /** 发证机构 */
        issauthority?: string;
        /** 工号 */
        jobNumber?: string;
        /** 审批状态 */
        approveStatus?: string;
        /**
         * 最后工作日
         * @format date-time
         */
        lastWorkDate?: string;
        /**
         * 生效日期
         * @format date-time
         */
        effectiveDate?: string;
        /** 证书照类型 */
        certificateType?: string;
        /** 雇员Id */
        userId?: string;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<SysEmployeeCertificatesDtoPageModelMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeecertificates/page`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeCertificates
     * @name WebSysEmployeeSysemployeecertificatesListList
     * @summary 列表获取所有 (Auth policies: Web)
     * @request GET:/api/web/sys/employee/sysemployeecertificates/list
     * @secure
     */
    webSysEmployeeSysemployeecertificatesListList: (
      query?: {
        /** 证书名称 */
        name?: string;
        /** 证书描述 */
        discription?: string;
        /**
         * 获得时间
         * @format date-time
         */
        gettime?: string;
        /** 学习证书Id */
        learningCertificateId?: string;
        /**
         * 有效期至
         * @format date-time
         */
        validuntil?: string;
        /**
         * 入职日期
         * @format date-time
         */
        entryDate?: string;
        /** 证书编号 */
        certificateNo?: string;
        /** 发证机构 */
        issauthority?: string;
        /** 工号 */
        jobNumber?: string;
        /** 审批状态 */
        approveStatus?: string;
        /**
         * 最后工作日
         * @format date-time
         */
        lastWorkDate?: string;
        /**
         * 生效日期
         * @format date-time
         */
        effectiveDate?: string;
        /** 证书照类型 */
        certificateType?: string;
        /** 雇员Id */
        userId?: string;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<SysEmployeeCertificatesDtoListMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeecertificates/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeCertificates
     * @name WebSysEmployeeSysemployeecertificatesDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/sys/employee/sysemployeecertificates/{id}
     * @secure
     */
    webSysEmployeeSysemployeecertificatesDetail: (id: string, params: RequestParams = {}) =>
      this.request<SysEmployeeCertificatesDtoMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeecertificates/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeCertificates
     * @name WebSysEmployeeSysemployeecertificatesUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/sys/employee/sysemployeecertificates/{id}
     * @secure
     */
    webSysEmployeeSysemployeecertificatesUpdate: (id: string, data: CreateSysEmployeeCertificatesInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeecertificates/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeCertificates
     * @name WebSysEmployeeSysemployeecertificatesDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/sys/employee/sysemployeecertificates/{id}
     * @secure
     */
    webSysEmployeeSysemployeecertificatesDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeecertificates/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeCertificates
     * @name WebSysEmployeeSysemployeecertificatesCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/sys/employee/sysemployeecertificates
     * @secure
     */
    webSysEmployeeSysemployeecertificatesCreate: (data: CreateSysEmployeeCertificatesInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeecertificates`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeEducations
     * @name WebSysEmployeeSysemployeeeducationsPageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/sys/employee/sysemployeeeducations/page
     * @secure
     */
    webSysEmployeeSysemployeeeducationsPageCreate: (
      query?: {
        /** 学历 */
        educationLevel?: string;
        /** 工号 */
        jobNumber?: string;
        /** 培养方式 */
        trainingMode?: string;
        /** 工作情况 */
        performance?: string;
        /** 备注 */
        comments?: string;
        /** 学制 */
        schoolSystme?: string;
        /** 专业类别 */
        specializedfield?: string;
        /** 专业授予机构 */
        grantingInstitution?: string;
        /** 专业 */
        major?: string;
        /** 毕业学校 */
        schoolCode?: string;
        /** 班级排名 */
        classRanking?: string;
        /** 学校名繁体 */
        school_zh_TW?: string;
        /** 学位授予国家 */
        degreeCountry?: string;
        /** 专业描述 */
        mainCourse?: string;
        /** 专业描述 */
        majorDescription?: string;
        /** 审批状态 */
        approveStatus?: string;
        /** 学位 */
        degree?: string;
        /** 所在城市 */
        location?: string;
        /** 毕业学校名称 */
        school?: string;
        /** 见证人 */
        prover?: string;
        /** 是否主专业 */
        isFirstMajor?: boolean;
        /** 是否最高学历 */
        isHighestEducation?: boolean;
        /** 是否最高学位 */
        isHighestDegree?: boolean;
        /** 是否第一学历 */
        isFirstEducationLevel?: boolean;
        /**
         * 学习形式
         * @format int32
         */
        studyMode?: number;
        /**
         * 毕业类型
         * @format int32
         */
        graduationType?: number;
        /**
         * 入职日期
         * @format date-time
         */
        entryDate?: string;
        /**
         * 结束日期
         * @format date-time
         */
        enddatE?: string;
        /**
         * 最后工作日
         * @format date-time
         */
        lastWorkDate?: string;
        /** 毕业学校名称_英文 */
        school_en_US?: string;
        /** 雇员Id */
        userId?: string;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<SysEmployeeEducationsDtoPageModelMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeeducations/page`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeEducations
     * @name WebSysEmployeeSysemployeeeducationsListList
     * @summary 列表获取所有 (Auth policies: Web)
     * @request GET:/api/web/sys/employee/sysemployeeeducations/list
     * @secure
     */
    webSysEmployeeSysemployeeeducationsListList: (
      query?: {
        /** 学历 */
        educationLevel?: string;
        /** 工号 */
        jobNumber?: string;
        /** 培养方式 */
        trainingMode?: string;
        /** 工作情况 */
        performance?: string;
        /** 备注 */
        comments?: string;
        /** 学制 */
        schoolSystme?: string;
        /** 专业类别 */
        specializedfield?: string;
        /** 专业授予机构 */
        grantingInstitution?: string;
        /** 专业 */
        major?: string;
        /** 毕业学校 */
        schoolCode?: string;
        /** 班级排名 */
        classRanking?: string;
        /** 学校名繁体 */
        school_zh_TW?: string;
        /** 学位授予国家 */
        degreeCountry?: string;
        /** 专业描述 */
        mainCourse?: string;
        /** 专业描述 */
        majorDescription?: string;
        /** 审批状态 */
        approveStatus?: string;
        /** 学位 */
        degree?: string;
        /** 所在城市 */
        location?: string;
        /** 毕业学校名称 */
        school?: string;
        /** 见证人 */
        prover?: string;
        /** 是否主专业 */
        isFirstMajor?: boolean;
        /** 是否最高学历 */
        isHighestEducation?: boolean;
        /** 是否最高学位 */
        isHighestDegree?: boolean;
        /** 是否第一学历 */
        isFirstEducationLevel?: boolean;
        /**
         * 学习形式
         * @format int32
         */
        studyMode?: number;
        /**
         * 毕业类型
         * @format int32
         */
        graduationType?: number;
        /**
         * 入职日期
         * @format date-time
         */
        entryDate?: string;
        /**
         * 结束日期
         * @format date-time
         */
        enddatE?: string;
        /**
         * 最后工作日
         * @format date-time
         */
        lastWorkDate?: string;
        /** 毕业学校名称_英文 */
        school_en_US?: string;
        /** 雇员Id */
        userId?: string;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<SysEmployeeEducationsDtoListMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeeducations/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeEducations
     * @name WebSysEmployeeSysemployeeeducationsDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/sys/employee/sysemployeeeducations/{id}
     * @secure
     */
    webSysEmployeeSysemployeeeducationsDetail: (id: string, params: RequestParams = {}) =>
      this.request<SysEmployeeEducationsDtoMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeeducations/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeEducations
     * @name WebSysEmployeeSysemployeeeducationsUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/sys/employee/sysemployeeeducations/{id}
     * @secure
     */
    webSysEmployeeSysemployeeeducationsUpdate: (id: string, data: CreateSysEmployeeEducationsInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeeducations/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeEducations
     * @name WebSysEmployeeSysemployeeeducationsDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/sys/employee/sysemployeeeducations/{id}
     * @secure
     */
    webSysEmployeeSysemployeeeducationsDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeeducations/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeEducations
     * @name WebSysEmployeeSysemployeeeducationsCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/sys/employee/sysemployeeeducations
     * @secure
     */
    webSysEmployeeSysemployeeeducationsCreate: (data: CreateSysEmployeeEducationsInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeeducations`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeEntryMaterialRec
     * @name WebSysEmployeeSysemployeeentrymaterialrecPageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/sys/employee/sysemployeeentrymaterialrec/page
     * @secure
     */
    webSysEmployeeSysemployeeentrymaterialrecPageCreate: (
      query?: {
        /** 材料名称 */
        materialName?: string;
        /** 所属单元 */
        onwerMou?: string;
        /**
         * 催办时间
         * @format date-time
         */
        promotTime?: string;
        /** 是否法定 */
        isLegal?: boolean;
        /** 是否收到 */
        hasReceived?: boolean;
        /** 退回备注 */
        giveBackRemark?: string;
        /** 材料名称(文本) */
        name?: string;
        /**
         * 提交日期
         * @format date-time
         */
        submitDate?: string;
        /** 组织排序号 */
        organizationOrderNum?: string;
        /** 工号 */
        jobNumber?: string;
        /** 材料说明 */
        remark?: string;
        /** 材料状态 */
        materialState?: string;
        /**
         * 创建时间
         * @format date-time
         */
        createdTime?: string;
        /** 准备阶段 */
        preparationPhase?: string;
        /**
         * 接收日期
         * @format date-time
         */
        receiveDate?: string;
        /**
         * 退回日期
         * @format date-time
         */
        giveBackDate?: string;
        /** 未提交备注 */
        notSubmittedRemarks?: string;
        /** 附件 */
        materialAttachment?: string;
        /**
         * 入职日期
         * @format date-time
         */
        entryDate?: string;
        /** 处理备注 */
        handleRemark?: string;
        /** 是否提交 */
        hasSubmitted?: boolean;
        /**
         * 预计提交日期
         * @format date-time
         */
        prepareSubmitDate?: string;
        /** 是否必备 */
        isEssential?: boolean;
        /**
         * 所属单元层级
         * @format int32
         */
        onwerMou_TreeLevel?: number;
        /**
         * 催办人
         * @format int32
         */
        promoter?: number;
        /** 所属单元路径 */
        onwerMou_TreePath?: string;
        /** 材料图片缩略图 */
        materialImageThumbnail?: string;
        /**
         * 最后工作日
         * @format date-time
         */
        lastWorkDate?: string;
        /** 雇员Id */
        userId?: string;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<SysEmployeeEntryMaterialRecDtoPageModelMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeentrymaterialrec/page`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeEntryMaterialRec
     * @name WebSysEmployeeSysemployeeentrymaterialrecListList
     * @summary 列表获取所有 (Auth policies: Web)
     * @request GET:/api/web/sys/employee/sysemployeeentrymaterialrec/list
     * @secure
     */
    webSysEmployeeSysemployeeentrymaterialrecListList: (
      query?: {
        /** 材料名称 */
        materialName?: string;
        /** 所属单元 */
        onwerMou?: string;
        /**
         * 催办时间
         * @format date-time
         */
        promotTime?: string;
        /** 是否法定 */
        isLegal?: boolean;
        /** 是否收到 */
        hasReceived?: boolean;
        /** 退回备注 */
        giveBackRemark?: string;
        /** 材料名称(文本) */
        name?: string;
        /**
         * 提交日期
         * @format date-time
         */
        submitDate?: string;
        /** 组织排序号 */
        organizationOrderNum?: string;
        /** 工号 */
        jobNumber?: string;
        /** 材料说明 */
        remark?: string;
        /** 材料状态 */
        materialState?: string;
        /**
         * 创建时间
         * @format date-time
         */
        createdTime?: string;
        /** 准备阶段 */
        preparationPhase?: string;
        /**
         * 接收日期
         * @format date-time
         */
        receiveDate?: string;
        /**
         * 退回日期
         * @format date-time
         */
        giveBackDate?: string;
        /** 未提交备注 */
        notSubmittedRemarks?: string;
        /** 附件 */
        materialAttachment?: string;
        /**
         * 入职日期
         * @format date-time
         */
        entryDate?: string;
        /** 处理备注 */
        handleRemark?: string;
        /** 是否提交 */
        hasSubmitted?: boolean;
        /**
         * 预计提交日期
         * @format date-time
         */
        prepareSubmitDate?: string;
        /** 是否必备 */
        isEssential?: boolean;
        /**
         * 所属单元层级
         * @format int32
         */
        onwerMou_TreeLevel?: number;
        /**
         * 催办人
         * @format int32
         */
        promoter?: number;
        /** 所属单元路径 */
        onwerMou_TreePath?: string;
        /** 材料图片缩略图 */
        materialImageThumbnail?: string;
        /**
         * 最后工作日
         * @format date-time
         */
        lastWorkDate?: string;
        /** 雇员Id */
        userId?: string;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<SysEmployeeEntryMaterialRecDtoListMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeentrymaterialrec/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeEntryMaterialRec
     * @name WebSysEmployeeSysemployeeentrymaterialrecDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/sys/employee/sysemployeeentrymaterialrec/{id}
     * @secure
     */
    webSysEmployeeSysemployeeentrymaterialrecDetail: (id: string, params: RequestParams = {}) =>
      this.request<SysEmployeeEntryMaterialRecDtoMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeentrymaterialrec/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeEntryMaterialRec
     * @name WebSysEmployeeSysemployeeentrymaterialrecUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/sys/employee/sysemployeeentrymaterialrec/{id}
     * @secure
     */
    webSysEmployeeSysemployeeentrymaterialrecUpdate: (id: string, data: CreateSysEmployeeEntryMaterialRecInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeentrymaterialrec/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeEntryMaterialRec
     * @name WebSysEmployeeSysemployeeentrymaterialrecDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/sys/employee/sysemployeeentrymaterialrec/{id}
     * @secure
     */
    webSysEmployeeSysemployeeentrymaterialrecDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeentrymaterialrec/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeEntryMaterialRec
     * @name WebSysEmployeeSysemployeeentrymaterialrecCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/sys/employee/sysemployeeentrymaterialrec
     * @secure
     */
    webSysEmployeeSysemployeeentrymaterialrecCreate: (data: CreateSysEmployeeEntryMaterialRecInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeentrymaterialrec`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeFamily
     * @name WebSysEmployeeSysemployeefamilyPageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/sys/employee/sysemployeefamily/page
     * @secure
     */
    webSysEmployeeSysemployeefamilyPageCreate: (
      query?: {
        /**
         * 性别
         * @format int32
         */
        gender?: number;
        /** 组织排序号 */
        organizationOrderNum?: string;
        /** 与本人关系 */
        relationType?: string;
        /** 职级排序号 */
        jobLevelOrderNum?: string;
        /**
         * 业务修改人
         * @format int32
         */
        businessModifiedBy?: number;
        /**
         * 出生日期
         * @format date-time
         */
        birthday?: string;
        /**
         * 最后工作日
         * @format date-time
         */
        lastWorkDate?: string;
        /** 审批状态 */
        approveStatus?: string;
        /** 邮件 */
        email?: string;
        /** 工号 */
        jobNumber?: string;
        /** 职务 */
        jobPost?: string;
        /**
         * 入职日期
         * @format date-time
         */
        entryDate?: string;
        /** 政治面貌 */
        politicalStatus?: string;
        /** 手机号码 */
        mobile?: string;
        /** 与本人关系名称 */
        relation?: string;
        /** 联系电话 */
        telephone?: string;
        /** 成员姓名 */
        name?: string;
        /** 工作单位 */
        company?: string;
        /** 雇员Id */
        userId?: string;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<SysEmployeeFamilyDtoPageModelMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeefamily/page`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeFamily
     * @name WebSysEmployeeSysemployeefamilyListList
     * @summary 列表获取所有 (Auth policies: Web)
     * @request GET:/api/web/sys/employee/sysemployeefamily/list
     * @secure
     */
    webSysEmployeeSysemployeefamilyListList: (
      query?: {
        /**
         * 性别
         * @format int32
         */
        gender?: number;
        /** 组织排序号 */
        organizationOrderNum?: string;
        /** 与本人关系 */
        relationType?: string;
        /** 职级排序号 */
        jobLevelOrderNum?: string;
        /**
         * 业务修改人
         * @format int32
         */
        businessModifiedBy?: number;
        /**
         * 出生日期
         * @format date-time
         */
        birthday?: string;
        /**
         * 最后工作日
         * @format date-time
         */
        lastWorkDate?: string;
        /** 审批状态 */
        approveStatus?: string;
        /** 邮件 */
        email?: string;
        /** 工号 */
        jobNumber?: string;
        /** 职务 */
        jobPost?: string;
        /**
         * 入职日期
         * @format date-time
         */
        entryDate?: string;
        /** 政治面貌 */
        politicalStatus?: string;
        /** 手机号码 */
        mobile?: string;
        /** 与本人关系名称 */
        relation?: string;
        /** 联系电话 */
        telephone?: string;
        /** 成员姓名 */
        name?: string;
        /** 工作单位 */
        company?: string;
        /** 雇员Id */
        userId?: string;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<SysEmployeeFamilyDtoListMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeefamily/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeFamily
     * @name WebSysEmployeeSysemployeefamilyDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/sys/employee/sysemployeefamily/{id}
     * @secure
     */
    webSysEmployeeSysemployeefamilyDetail: (id: string, params: RequestParams = {}) =>
      this.request<SysEmployeeFamilyDtoMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeefamily/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeFamily
     * @name WebSysEmployeeSysemployeefamilyUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/sys/employee/sysemployeefamily/{id}
     * @secure
     */
    webSysEmployeeSysemployeefamilyUpdate: (id: string, data: CreateSysEmployeeFamilyInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeefamily/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeFamily
     * @name WebSysEmployeeSysemployeefamilyDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/sys/employee/sysemployeefamily/{id}
     * @secure
     */
    webSysEmployeeSysemployeefamilyDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeefamily/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeFamily
     * @name WebSysEmployeeSysemployeefamilyCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/sys/employee/sysemployeefamily
     * @secure
     */
    webSysEmployeeSysemployeefamilyCreate: (data: CreateSysEmployeeFamilyInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeefamily`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeInfos
     * @name WebSysEmployeeSysemployeeinfosPageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/sys/employee/sysemployeeinfos/page
     * @secure
     */
    webSysEmployeeSysemployeeinfosPageCreate: (
      query?: {
        /** 显示名称 */
        displayName?: string;
        /**
         * 入职时间
         * @format date-time
         */
        entryTime?: string;
        /** 别名 */
        alterName?: string;
        /** 手机号 */
        phoneNumber?: string;
        /** 描述 */
        description?: string;
        address?: string;
        /** 邮箱 */
        emailAddress?: string;
        /** @format int32 */
        sex?: number;
        /**
         * 生日
         * @format date-time
         */
        birthday?: string;
        /** @format int32 */
        age?: number;
        /** 教育背景 */
        educationBackground?: string;
        /** 工号 */
        jobNumber?: string;
        /** Id卡类型 */
        idCardType?: string;
        /** 唯一标识号码 */
        idCardNumber?: string;
        /**
         * 毕业时间
         * @format date-time
         */
        graduateDate?: string;
        /** 婚姻状态 */
        maritalStatus?: string;
        /** 用户类型 */
        userType?: string;
        /** @format int32 */
        origin?: number;
        /** 外部标识 */
        originalId?: string;
        /** 户籍所在地 */
        householdRegister?: string;
        /** 民族 */
        nation?: string;
        /** 政治面貌 */
        politicalOutlook?: string;
        /** 籍贯 */
        nativePlace?: string;
        /** 兴趣爱好 */
        hobbies?: string;
        /** 特长 */
        speciality?: string;
        /**
         * 参加工作时间
         * @format date-time
         */
        beganWorkDate?: string;
        /** 毕业学校 */
        graduateFrom?: string;
        /** 专业 */
        major?: string;
        /** 工作地址 */
        officeLocation?: string;
        /** 工作电话 */
        workPhone?: string;
        /** 传真 */
        fax?: string;
        /** 座机 */
        landline?: string;
        /** 头像地址 */
        pictureUrl?: string;
        /** 关于我 */
        aboutMe?: string;
        /** 最高学历 */
        highestEducation?: string;
        /** 邮政编码 */
        postalCode?: string;
        /**
         * 对外费率
         * @format double
         */
        externalRate?: number;
        /**
         * 内部费率
         * @format double
         */
        internalRate?: number;
        /** 币种 */
        currency?: string;
        /** 擅长语言 */
        goodLanguage?: string;
        /** 擅长专业 */
        goodProfessional?: string;
        /** 原执业律师事务所 */
        formerLawyer?: string;
        /** 执业证号 */
        formerLawyerNumber?: string;
        /** 紧急联系人 */
        emergencyContact?: string;
        /** 与其关系 */
        relationship?: string;
        /** 紧急联系人电话 */
        emergencyContactPhone?: string;
        /** 紧急联系人地址 */
        emergencyContactAddress?: string;
        /** 律师关系存放地 */
        localRelationshipStorage?: string;
        /**
         * 律师关系存放日期
         * @format date-time
         */
        localRelationshipTime?: string;
        /** 人事档案存放地 */
        personnelArchivesStorageAgency?: string;
        /** 存档号 */
        archiveNumber?: string;
        /**
         * 存档日期
         * @format date-time
         */
        archiveTime?: string;
        /** 雇员Id */
        userId?: string;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<SysEmployeeInfosDtoPageModelMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeinfos/page`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeInfos
     * @name WebSysEmployeeSysemployeeinfosListList
     * @summary 列表获取所有 (Auth policies: Web)
     * @request GET:/api/web/sys/employee/sysemployeeinfos/list
     * @secure
     */
    webSysEmployeeSysemployeeinfosListList: (
      query?: {
        /** 显示名称 */
        displayName?: string;
        /**
         * 入职时间
         * @format date-time
         */
        entryTime?: string;
        /** 别名 */
        alterName?: string;
        /** 手机号 */
        phoneNumber?: string;
        /** 描述 */
        description?: string;
        address?: string;
        /** 邮箱 */
        emailAddress?: string;
        /** @format int32 */
        sex?: number;
        /**
         * 生日
         * @format date-time
         */
        birthday?: string;
        /** @format int32 */
        age?: number;
        /** 教育背景 */
        educationBackground?: string;
        /** 工号 */
        jobNumber?: string;
        /** Id卡类型 */
        idCardType?: string;
        /** 唯一标识号码 */
        idCardNumber?: string;
        /**
         * 毕业时间
         * @format date-time
         */
        graduateDate?: string;
        /** 婚姻状态 */
        maritalStatus?: string;
        /** 用户类型 */
        userType?: string;
        /** @format int32 */
        origin?: number;
        /** 外部标识 */
        originalId?: string;
        /** 户籍所在地 */
        householdRegister?: string;
        /** 民族 */
        nation?: string;
        /** 政治面貌 */
        politicalOutlook?: string;
        /** 籍贯 */
        nativePlace?: string;
        /** 兴趣爱好 */
        hobbies?: string;
        /** 特长 */
        speciality?: string;
        /**
         * 参加工作时间
         * @format date-time
         */
        beganWorkDate?: string;
        /** 毕业学校 */
        graduateFrom?: string;
        /** 专业 */
        major?: string;
        /** 工作地址 */
        officeLocation?: string;
        /** 工作电话 */
        workPhone?: string;
        /** 传真 */
        fax?: string;
        /** 座机 */
        landline?: string;
        /** 头像地址 */
        pictureUrl?: string;
        /** 关于我 */
        aboutMe?: string;
        /** 最高学历 */
        highestEducation?: string;
        /** 邮政编码 */
        postalCode?: string;
        /**
         * 对外费率
         * @format double
         */
        externalRate?: number;
        /**
         * 内部费率
         * @format double
         */
        internalRate?: number;
        /** 币种 */
        currency?: string;
        /** 擅长语言 */
        goodLanguage?: string;
        /** 擅长专业 */
        goodProfessional?: string;
        /** 原执业律师事务所 */
        formerLawyer?: string;
        /** 执业证号 */
        formerLawyerNumber?: string;
        /** 紧急联系人 */
        emergencyContact?: string;
        /** 与其关系 */
        relationship?: string;
        /** 紧急联系人电话 */
        emergencyContactPhone?: string;
        /** 紧急联系人地址 */
        emergencyContactAddress?: string;
        /** 律师关系存放地 */
        localRelationshipStorage?: string;
        /**
         * 律师关系存放日期
         * @format date-time
         */
        localRelationshipTime?: string;
        /** 人事档案存放地 */
        personnelArchivesStorageAgency?: string;
        /** 存档号 */
        archiveNumber?: string;
        /**
         * 存档日期
         * @format date-time
         */
        archiveTime?: string;
        /** 雇员Id */
        userId?: string;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<SysEmployeeInfosDtoListMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeinfos/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeInfos
     * @name WebSysEmployeeSysemployeeinfosDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/sys/employee/sysemployeeinfos/{id}
     * @secure
     */
    webSysEmployeeSysemployeeinfosDetail: (id: string, params: RequestParams = {}) =>
      this.request<SysEmployeeInfosDtoMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeinfos/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeInfos
     * @name WebSysEmployeeSysemployeeinfosUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/sys/employee/sysemployeeinfos/{id}
     * @secure
     */
    webSysEmployeeSysemployeeinfosUpdate: (id: string, data: CreateSysEmployeeInfosInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeinfos/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeInfos
     * @name WebSysEmployeeSysemployeeinfosDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/sys/employee/sysemployeeinfos/{id}
     * @secure
     */
    webSysEmployeeSysemployeeinfosDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeinfos/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeInfos
     * @name WebSysEmployeeSysemployeeinfosCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/sys/employee/sysemployeeinfos
     * @secure
     */
    webSysEmployeeSysemployeeinfosCreate: (data: CreateSysEmployeeInfosInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeinfos`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeJobHistory
     * @name WebSysEmployeeSysemployeejobhistoryPageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/sys/employee/sysemployeejobhistory/page
     * @secure
     */
    webSysEmployeeSysemployeejobhistoryPageCreate: (
      query?: {
        /** 审批状态 */
        approveStatus?: string;
        /** 部门_繁体 */
        department_zh_TW?: string;
        /** 单位名称 */
        company?: string;
        /** 工作业绩 */
        achievement?: string;
        /**
         * 月薪（税前）
         * @format double
         */
        salary?: number;
        /**
         * 离职日期
         * @format date-time
         */
        dimissionDate?: string;
        /** 职务_英文 */
        firstJob_en_US?: string;
        /** 工作类型 */
        jobType?: string;
        /** 企业规模 */
        companySize?: string;
        /** 工号 */
        jobNumber?: string;
        /** 与本人关系 */
        proverRelation?: string;
        /** 部门_英文 */
        department_en_US?: string;
        /** 证明人联系方式 */
        proverContactInfo?: string;
        /**
         * 下属人数
         * @format int32
         */
        underlingNum?: number;
        /** 部门 */
        department?: string;
        /** 证明人 */
        proverName?: string;
        /** 行业类别 */
        hangye?: string;
        /** 单位介绍 */
        introduction?: string;
        /** 职务_繁体 */
        firstJob_zh_TW?: string;
        /** 工作性质 */
        workKind?: string;
        /** 单位类型 */
        companyType?: string;
        /**
         * 开始日期
         * @format date-time
         */
        startDate?: string;
        /** 职务 */
        firstJob?: string;
        /** 单位名称_繁体 */
        company_zh_TW?: string;
        /** 职级名称 */
        jobLevel?: string;
        /** 职级名称_繁体 */
        jobLevel_zh_TW?: string;
        /** 是否本单位工作经历 */
        whetherTheWorkExperienceOfTheUnit?: boolean;
        /** 职级名称_英文 */
        jobLevel_en_US?: string;
        /** 职位_繁体 */
        lastDog_zh_TW?: string;
        /** 离职原因 */
        leaveReason?: string;
        /**
         * 入职日期
         * @format date-time
         */
        beginDate?: string;
        /**
         * 入职日期
         * @format date-time
         */
        entryDate?: string;
        /**
         * 结束日期
         * @format date-time
         */
        stopDate?: string;
        /** 任职类型 */
        serviceType?: string;
        /** 职位 */
        lastJog?: string;
        /** 部门全称 */
        pOIOldAdminNameTreePath?: string;
        /** 职位英文 */
        lastJog_en_US?: string;
        /** 工作地址 */
        address?: string;
        /**
         * 最后工作日
         * @format date-time
         */
        lastWorkDate?: string;
        /** 证明人职务 */
        proverJobPost?: string;
        /** 工作职责 */
        responsibility?: string;
        /** 雇员Id */
        userId?: string;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<SysEmployeeJobHistoryDtoPageModelMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeejobhistory/page`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeJobHistory
     * @name WebSysEmployeeSysemployeejobhistoryListList
     * @summary 列表获取所有 (Auth policies: Web)
     * @request GET:/api/web/sys/employee/sysemployeejobhistory/list
     * @secure
     */
    webSysEmployeeSysemployeejobhistoryListList: (
      query?: {
        /** 审批状态 */
        approveStatus?: string;
        /** 部门_繁体 */
        department_zh_TW?: string;
        /** 单位名称 */
        company?: string;
        /** 工作业绩 */
        achievement?: string;
        /**
         * 月薪（税前）
         * @format double
         */
        salary?: number;
        /**
         * 离职日期
         * @format date-time
         */
        dimissionDate?: string;
        /** 职务_英文 */
        firstJob_en_US?: string;
        /** 工作类型 */
        jobType?: string;
        /** 企业规模 */
        companySize?: string;
        /** 工号 */
        jobNumber?: string;
        /** 与本人关系 */
        proverRelation?: string;
        /** 部门_英文 */
        department_en_US?: string;
        /** 证明人联系方式 */
        proverContactInfo?: string;
        /**
         * 下属人数
         * @format int32
         */
        underlingNum?: number;
        /** 部门 */
        department?: string;
        /** 证明人 */
        proverName?: string;
        /** 行业类别 */
        hangye?: string;
        /** 单位介绍 */
        introduction?: string;
        /** 职务_繁体 */
        firstJob_zh_TW?: string;
        /** 工作性质 */
        workKind?: string;
        /** 单位类型 */
        companyType?: string;
        /**
         * 开始日期
         * @format date-time
         */
        startDate?: string;
        /** 职务 */
        firstJob?: string;
        /** 单位名称_繁体 */
        company_zh_TW?: string;
        /** 职级名称 */
        jobLevel?: string;
        /** 职级名称_繁体 */
        jobLevel_zh_TW?: string;
        /** 是否本单位工作经历 */
        whetherTheWorkExperienceOfTheUnit?: boolean;
        /** 职级名称_英文 */
        jobLevel_en_US?: string;
        /** 职位_繁体 */
        lastDog_zh_TW?: string;
        /** 离职原因 */
        leaveReason?: string;
        /**
         * 入职日期
         * @format date-time
         */
        beginDate?: string;
        /**
         * 入职日期
         * @format date-time
         */
        entryDate?: string;
        /**
         * 结束日期
         * @format date-time
         */
        stopDate?: string;
        /** 任职类型 */
        serviceType?: string;
        /** 职位 */
        lastJog?: string;
        /** 部门全称 */
        pOIOldAdminNameTreePath?: string;
        /** 职位英文 */
        lastJog_en_US?: string;
        /** 工作地址 */
        address?: string;
        /**
         * 最后工作日
         * @format date-time
         */
        lastWorkDate?: string;
        /** 证明人职务 */
        proverJobPost?: string;
        /** 工作职责 */
        responsibility?: string;
        /** 雇员Id */
        userId?: string;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<SysEmployeeJobHistoryDtoListMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeejobhistory/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeJobHistory
     * @name WebSysEmployeeSysemployeejobhistoryDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/sys/employee/sysemployeejobhistory/{id}
     * @secure
     */
    webSysEmployeeSysemployeejobhistoryDetail: (id: string, params: RequestParams = {}) =>
      this.request<SysEmployeeJobHistoryDtoMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeejobhistory/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeJobHistory
     * @name WebSysEmployeeSysemployeejobhistoryUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/sys/employee/sysemployeejobhistory/{id}
     * @secure
     */
    webSysEmployeeSysemployeejobhistoryUpdate: (id: string, data: CreateSysEmployeeJobHistoryInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeejobhistory/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeJobHistory
     * @name WebSysEmployeeSysemployeejobhistoryDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/sys/employee/sysemployeejobhistory/{id}
     * @secure
     */
    webSysEmployeeSysemployeejobhistoryDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeejobhistory/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeJobHistory
     * @name WebSysEmployeeSysemployeejobhistoryCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/sys/employee/sysemployeejobhistory
     * @secure
     */
    webSysEmployeeSysemployeejobhistoryCreate: (data: CreateSysEmployeeJobHistoryInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeejobhistory`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeLanguageAbilities
     * @name WebSysEmployeeSysemployeelanguageabilitiesPageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/sys/employee/sysemployeelanguageabilities/page
     * @secure
     */
    webSysEmployeeSysemployeelanguageabilitiesPageCreate: (
      query?: {
        /** 语言 */
        language?: string;
        /** 技能描述 */
        languageskill?: string;
        /** 审批状态 */
        approveStatus?: string;
        /** 掌握程度 */
        level?: string;
        /** 书写能力 */
        writingAbility?: string;
        /** 工号 */
        jobNumber?: string;
        /**
         * 最后工作日
         * @format date-time
         */
        lastWorkDate?: string;
        /** 是否母语 */
        isNative?: boolean;
        /**
         * 入职日期
         * @format date-time
         */
        entryDate?: string;
        /** 阅读能力 */
        readingAbility?: string;
        /** 口语能力 */
        speakingAbility?: string;
        /** 雇员Id */
        userId?: string;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<SysEmployeeLanguageAbilitiesDtoPageModelMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeelanguageabilities/page`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeLanguageAbilities
     * @name WebSysEmployeeSysemployeelanguageabilitiesListList
     * @summary 列表获取所有 (Auth policies: Web)
     * @request GET:/api/web/sys/employee/sysemployeelanguageabilities/list
     * @secure
     */
    webSysEmployeeSysemployeelanguageabilitiesListList: (
      query?: {
        /** 语言 */
        language?: string;
        /** 技能描述 */
        languageskill?: string;
        /** 审批状态 */
        approveStatus?: string;
        /** 掌握程度 */
        level?: string;
        /** 书写能力 */
        writingAbility?: string;
        /** 工号 */
        jobNumber?: string;
        /**
         * 最后工作日
         * @format date-time
         */
        lastWorkDate?: string;
        /** 是否母语 */
        isNative?: boolean;
        /**
         * 入职日期
         * @format date-time
         */
        entryDate?: string;
        /** 阅读能力 */
        readingAbility?: string;
        /** 口语能力 */
        speakingAbility?: string;
        /** 雇员Id */
        userId?: string;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<SysEmployeeLanguageAbilitiesDtoListMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeelanguageabilities/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeLanguageAbilities
     * @name WebSysEmployeeSysemployeelanguageabilitiesDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/sys/employee/sysemployeelanguageabilities/{id}
     * @secure
     */
    webSysEmployeeSysemployeelanguageabilitiesDetail: (id: string, params: RequestParams = {}) =>
      this.request<SysEmployeeLanguageAbilitiesDtoMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeelanguageabilities/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeLanguageAbilities
     * @name WebSysEmployeeSysemployeelanguageabilitiesUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/sys/employee/sysemployeelanguageabilities/{id}
     * @secure
     */
    webSysEmployeeSysemployeelanguageabilitiesUpdate: (id: string, data: CreateSysEmployeeLanguageAbilitiesInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeelanguageabilities/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeLanguageAbilities
     * @name WebSysEmployeeSysemployeelanguageabilitiesDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/sys/employee/sysemployeelanguageabilities/{id}
     * @secure
     */
    webSysEmployeeSysemployeelanguageabilitiesDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeelanguageabilities/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeLanguageAbilities
     * @name WebSysEmployeeSysemployeelanguageabilitiesCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/sys/employee/sysemployeelanguageabilities
     * @secure
     */
    webSysEmployeeSysemployeelanguageabilitiesCreate: (data: CreateSysEmployeeLanguageAbilitiesInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeelanguageabilities`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeProfessionalTechnicalPostInfo
     * @name WebSysEmployeeSysemployeeprofessionaltechnicalpostinfoPageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/sys/employee/sysemployeeprofessionaltechnicalpostinfo/page
     * @secure
     */
    webSysEmployeeSysemployeeprofessionaltechnicalpostinfoPageCreate: (
      query?: {
        /** 工号 */
        jobNumber?: string;
        /**
         * 组织_层级
         * @format int32
         */
        stdOrganization_TreeLevel?: number;
        /** 资格评审单位 */
        qualificationEvaluationUnit?: string;
        /** 备注 */
        remarks?: string;
        /** 共享人 */
        permissionBy?: string;
        /** 是否最高级别 */
        isTopLevel?: boolean;
        /**
         * 组织
         * @format int32
         */
        stdOrganization?: number;
        /**
         * 聘任起始时间
         * @format date-time
         */
        appointStartDate?: string;
        /**
         * 评定日期
         * @format date-time
         */
        assessmentDate?: string;
        /**
         * 所属单位层级
         * @format int32
         */
        onwerMou_TreeLevel?: number;
        /** 熟悉专业专长 */
        professionalSpeciality?: string;
        /** 审批状态 */
        approveStatus?: string;
        /** 所属单位 */
        onwerMou?: string;
        /**
         * 最后工作日
         * @format date-time
         */
        lastWorkDate?: string;
        /**
         * 入职日期
         * @format date-time
         */
        entryDate?: string;
        /** 共享部门 */
        permissionOrganization?: string;
        /** 获取资历途径 */
        accessWayID?: string;
        /** 共享部门，包含下级 */
        permissionOrganization_SelfAndChildren?: string;
        /** 职任专业技术级别 */
        appointTechnicalLevelID?: string;
        /** 职任单位 */
        appointCompany?: string;
        /** 共享部门，仅自己 */
        permissionOrganization_SelfOnly?: string;
        /** 专业技术职务任职资格名称 */
        technicalPostQualificationsID?: string;
        /** 所属单元路径 */
        onwerMou_TreePath?: string;
        /** 组织路径 */
        stdOrganization_TreePath?: string;
        /** 职任专业技术职务名称 */
        appointTechnicalPostQualificationsID?: string;
        /** 专业技术级别 */
        technicalLevelID?: string;
        /**
         * 职任结束时间
         * @format date-time
         */
        appointEndDate?: string;
        /** 雇员Id */
        userId?: string;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<SysEmployeeProfessionalTechnicalPostInfoDtoPageModelMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeprofessionaltechnicalpostinfo/page`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeProfessionalTechnicalPostInfo
     * @name WebSysEmployeeSysemployeeprofessionaltechnicalpostinfoListList
     * @summary 列表获取所有 (Auth policies: Web)
     * @request GET:/api/web/sys/employee/sysemployeeprofessionaltechnicalpostinfo/list
     * @secure
     */
    webSysEmployeeSysemployeeprofessionaltechnicalpostinfoListList: (
      query?: {
        /** 工号 */
        jobNumber?: string;
        /**
         * 组织_层级
         * @format int32
         */
        stdOrganization_TreeLevel?: number;
        /** 资格评审单位 */
        qualificationEvaluationUnit?: string;
        /** 备注 */
        remarks?: string;
        /** 共享人 */
        permissionBy?: string;
        /** 是否最高级别 */
        isTopLevel?: boolean;
        /**
         * 组织
         * @format int32
         */
        stdOrganization?: number;
        /**
         * 聘任起始时间
         * @format date-time
         */
        appointStartDate?: string;
        /**
         * 评定日期
         * @format date-time
         */
        assessmentDate?: string;
        /**
         * 所属单位层级
         * @format int32
         */
        onwerMou_TreeLevel?: number;
        /** 熟悉专业专长 */
        professionalSpeciality?: string;
        /** 审批状态 */
        approveStatus?: string;
        /** 所属单位 */
        onwerMou?: string;
        /**
         * 最后工作日
         * @format date-time
         */
        lastWorkDate?: string;
        /**
         * 入职日期
         * @format date-time
         */
        entryDate?: string;
        /** 共享部门 */
        permissionOrganization?: string;
        /** 获取资历途径 */
        accessWayID?: string;
        /** 共享部门，包含下级 */
        permissionOrganization_SelfAndChildren?: string;
        /** 职任专业技术级别 */
        appointTechnicalLevelID?: string;
        /** 职任单位 */
        appointCompany?: string;
        /** 共享部门，仅自己 */
        permissionOrganization_SelfOnly?: string;
        /** 专业技术职务任职资格名称 */
        technicalPostQualificationsID?: string;
        /** 所属单元路径 */
        onwerMou_TreePath?: string;
        /** 组织路径 */
        stdOrganization_TreePath?: string;
        /** 职任专业技术职务名称 */
        appointTechnicalPostQualificationsID?: string;
        /** 专业技术级别 */
        technicalLevelID?: string;
        /**
         * 职任结束时间
         * @format date-time
         */
        appointEndDate?: string;
        /** 雇员Id */
        userId?: string;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<SysEmployeeProfessionalTechnicalPostInfoDtoListMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeprofessionaltechnicalpostinfo/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeProfessionalTechnicalPostInfo
     * @name WebSysEmployeeSysemployeeprofessionaltechnicalpostinfoDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/sys/employee/sysemployeeprofessionaltechnicalpostinfo/{id}
     * @secure
     */
    webSysEmployeeSysemployeeprofessionaltechnicalpostinfoDetail: (id: string, params: RequestParams = {}) =>
      this.request<SysEmployeeProfessionalTechnicalPostInfoDtoMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeprofessionaltechnicalpostinfo/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeProfessionalTechnicalPostInfo
     * @name WebSysEmployeeSysemployeeprofessionaltechnicalpostinfoUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/sys/employee/sysemployeeprofessionaltechnicalpostinfo/{id}
     * @secure
     */
    webSysEmployeeSysemployeeprofessionaltechnicalpostinfoUpdate: (
      id: string,
      data: CreateSysEmployeeProfessionalTechnicalPostInfoInput,
      params: RequestParams = {}
    ) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeprofessionaltechnicalpostinfo/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeProfessionalTechnicalPostInfo
     * @name WebSysEmployeeSysemployeeprofessionaltechnicalpostinfoDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/sys/employee/sysemployeeprofessionaltechnicalpostinfo/{id}
     * @secure
     */
    webSysEmployeeSysemployeeprofessionaltechnicalpostinfoDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeprofessionaltechnicalpostinfo/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeProfessionalTechnicalPostInfo
     * @name WebSysEmployeeSysemployeeprofessionaltechnicalpostinfoCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/sys/employee/sysemployeeprofessionaltechnicalpostinfo
     * @secure
     */
    webSysEmployeeSysemployeeprofessionaltechnicalpostinfoCreate: (data: CreateSysEmployeeProfessionalTechnicalPostInfoInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeprofessionaltechnicalpostinfo`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeProjectExperiences
     * @name WebSysEmployeeSysemployeeprojectexperiencesPageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/sys/employee/sysemployeeprojectexperiences/page
     * @secure
     */
    webSysEmployeeSysemployeeprojectexperiencesPageCreate: (
      query?: {
        /** 职位 */
        jobPosition?: string;
        /**
         * 最后工作日
         * @format date-time
         */
        lastWorkDate?: string;
        /**
         * 项目人数
         * @format int32
         */
        peopleNumber?: number;
        /** 硬件环境 */
        hardware?: string;
        /** 职位拼音排序 */
        jobPosition_Pinyin?: string;
        /** 开发工具 */
        devTool?: string;
        /** 软件环境 */
        software?: string;
        /**
         * 开始日期
         * @format date-time
         */
        startDate?: string;
        /** 项目名称 */
        name?: string;
        /** 描述 */
        description?: string;
        /** 项目成果 */
        achievement?: string;
        /**
         * 结束日期
         * @format date-time
         */
        endDate?: string;
        /** 项目职责 */
        responsibility?: string;
        /**
         * 入职日期
         * @format date-time
         */
        entryDate?: string;
        /** 审批状态 */
        approveStatus?: string;
        /** 工号 */
        jobNumber?: string;
        /** 雇员Id */
        userId?: string;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<SysEmployeeProjectExperiencesDtoPageModelMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeprojectexperiences/page`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeProjectExperiences
     * @name WebSysEmployeeSysemployeeprojectexperiencesListList
     * @summary 列表获取所有 (Auth policies: Web)
     * @request GET:/api/web/sys/employee/sysemployeeprojectexperiences/list
     * @secure
     */
    webSysEmployeeSysemployeeprojectexperiencesListList: (
      query?: {
        /** 职位 */
        jobPosition?: string;
        /**
         * 最后工作日
         * @format date-time
         */
        lastWorkDate?: string;
        /**
         * 项目人数
         * @format int32
         */
        peopleNumber?: number;
        /** 硬件环境 */
        hardware?: string;
        /** 职位拼音排序 */
        jobPosition_Pinyin?: string;
        /** 开发工具 */
        devTool?: string;
        /** 软件环境 */
        software?: string;
        /**
         * 开始日期
         * @format date-time
         */
        startDate?: string;
        /** 项目名称 */
        name?: string;
        /** 描述 */
        description?: string;
        /** 项目成果 */
        achievement?: string;
        /**
         * 结束日期
         * @format date-time
         */
        endDate?: string;
        /** 项目职责 */
        responsibility?: string;
        /**
         * 入职日期
         * @format date-time
         */
        entryDate?: string;
        /** 审批状态 */
        approveStatus?: string;
        /** 工号 */
        jobNumber?: string;
        /** 雇员Id */
        userId?: string;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<SysEmployeeProjectExperiencesDtoListMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeprojectexperiences/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeProjectExperiences
     * @name WebSysEmployeeSysemployeeprojectexperiencesDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/sys/employee/sysemployeeprojectexperiences/{id}
     * @secure
     */
    webSysEmployeeSysemployeeprojectexperiencesDetail: (id: string, params: RequestParams = {}) =>
      this.request<SysEmployeeProjectExperiencesDtoMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeprojectexperiences/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeProjectExperiences
     * @name WebSysEmployeeSysemployeeprojectexperiencesUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/sys/employee/sysemployeeprojectexperiences/{id}
     * @secure
     */
    webSysEmployeeSysemployeeprojectexperiencesUpdate: (id: string, data: CreateSysEmployeeProjectExperiencesInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeprojectexperiences/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeProjectExperiences
     * @name WebSysEmployeeSysemployeeprojectexperiencesDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/sys/employee/sysemployeeprojectexperiences/{id}
     * @secure
     */
    webSysEmployeeSysemployeeprojectexperiencesDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeprojectexperiences/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeProjectExperiences
     * @name WebSysEmployeeSysemployeeprojectexperiencesCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/sys/employee/sysemployeeprojectexperiences
     * @secure
     */
    webSysEmployeeSysemployeeprojectexperiencesCreate: (data: CreateSysEmployeeProjectExperiencesInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeprojectexperiences`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeSkill
     * @name WebSysEmployeeSysemployeeskillPageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/sys/employee/sysemployeeskill/page
     * @secure
     */
    webSysEmployeeSysemployeeskillPageCreate: (
      query?: {
        /** 审批状态 */
        approveStatus?: string;
        /** 掌握程度 */
        level?: string;
        /**
         * 使用时间（月）
         * @format int32
         */
        useLength?: number;
        /**
         * 入职日期
         * @format date-time
         */
        entryDate?: string;
        /**
         * 最后工作日
         * @format date-time
         */
        lastWorkDate?: string;
        /** 种类 */
        category?: string;
        /** 工号 */
        jobNumber?: string;
        /** 名称 */
        name?: string;
        /** 雇员Id */
        userId?: string;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<SysEmployeeSkillDtoPageModelMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeskill/page`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeSkill
     * @name WebSysEmployeeSysemployeeskillListList
     * @summary 列表获取所有 (Auth policies: Web)
     * @request GET:/api/web/sys/employee/sysemployeeskill/list
     * @secure
     */
    webSysEmployeeSysemployeeskillListList: (
      query?: {
        /** 审批状态 */
        approveStatus?: string;
        /** 掌握程度 */
        level?: string;
        /**
         * 使用时间（月）
         * @format int32
         */
        useLength?: number;
        /**
         * 入职日期
         * @format date-time
         */
        entryDate?: string;
        /**
         * 最后工作日
         * @format date-time
         */
        lastWorkDate?: string;
        /** 种类 */
        category?: string;
        /** 工号 */
        jobNumber?: string;
        /** 名称 */
        name?: string;
        /** 雇员Id */
        userId?: string;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<SysEmployeeSkillDtoListMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeskill/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeSkill
     * @name WebSysEmployeeSysemployeeskillDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/sys/employee/sysemployeeskill/{id}
     * @secure
     */
    webSysEmployeeSysemployeeskillDetail: (id: string, params: RequestParams = {}) =>
      this.request<SysEmployeeSkillDtoMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeskill/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeSkill
     * @name WebSysEmployeeSysemployeeskillUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/sys/employee/sysemployeeskill/{id}
     * @secure
     */
    webSysEmployeeSysemployeeskillUpdate: (id: string, data: CreateSysEmployeeSkillInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeskill/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeSkill
     * @name WebSysEmployeeSysemployeeskillDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/sys/employee/sysemployeeskill/{id}
     * @secure
     */
    webSysEmployeeSysemployeeskillDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeskill/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeSkill
     * @name WebSysEmployeeSysemployeeskillCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/sys/employee/sysemployeeskill
     * @secure
     */
    webSysEmployeeSysemployeeskillCreate: (data: CreateSysEmployeeSkillInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeeskill`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeTrainings
     * @name WebSysEmployeeSysemployeetrainingsPageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/sys/employee/sysemployeetrainings/page
     * @secure
     */
    webSysEmployeeSysemployeetrainingsPageCreate: (
      query?: {
        /** 培训描述 */
        discription?: string;
        /** 是否获得勋章 */
        isHaveMedal?: boolean;
        /**
         * 开始日期
         * @format date-time
         */
        startDate?: string;
        /** 适用场景 */
        applyScene?: string;
        /** 培训机构 */
        trainingAgency?: string;
        /** 培训成绩 */
        result?: string;
        /** 培训表现 */
        evaluation?: string;
        /**
         * 获得学分
         * @format double
         */
        getCredit?: number;
        /**
         * 结束日期
         * @format date-time
         */
        stopDate?: string;
        /** 名称 */
        course?: string;
        /**
         * 最后工作日
         * @format date-time
         */
        lastWorkDate?: string;
        /**
         * 入职日期
         * @format date-time
         */
        entryDate?: string;
        /** 培训类别 */
        trainingCategory?: string;
        /** 培训类别名称 */
        trainType?: string;
        /** 课时总计 */
        timeToTile?: string;
        /** 及格状态 */
        passStatus?: string;
        /** 工号 */
        jobNumber?: string;
        /** 审批状态 */
        approveStatus?: string;
        /**
         * 辅导导师
         * @format int32
         */
        mentorUserId?: number;
        /** 获得证书 */
        certificate?: string;
        /** 完成状态 */
        completionStatus?: string;
        /** 培训活动编号 */
        trainingActivityNum?: string;
        /** 雇员Id */
        userId?: string;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<SysEmployeeTrainingsDtoPageModelMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeetrainings/page`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeTrainings
     * @name WebSysEmployeeSysemployeetrainingsListList
     * @summary 列表获取所有 (Auth policies: Web)
     * @request GET:/api/web/sys/employee/sysemployeetrainings/list
     * @secure
     */
    webSysEmployeeSysemployeetrainingsListList: (
      query?: {
        /** 培训描述 */
        discription?: string;
        /** 是否获得勋章 */
        isHaveMedal?: boolean;
        /**
         * 开始日期
         * @format date-time
         */
        startDate?: string;
        /** 适用场景 */
        applyScene?: string;
        /** 培训机构 */
        trainingAgency?: string;
        /** 培训成绩 */
        result?: string;
        /** 培训表现 */
        evaluation?: string;
        /**
         * 获得学分
         * @format double
         */
        getCredit?: number;
        /**
         * 结束日期
         * @format date-time
         */
        stopDate?: string;
        /** 名称 */
        course?: string;
        /**
         * 最后工作日
         * @format date-time
         */
        lastWorkDate?: string;
        /**
         * 入职日期
         * @format date-time
         */
        entryDate?: string;
        /** 培训类别 */
        trainingCategory?: string;
        /** 培训类别名称 */
        trainType?: string;
        /** 课时总计 */
        timeToTile?: string;
        /** 及格状态 */
        passStatus?: string;
        /** 工号 */
        jobNumber?: string;
        /** 审批状态 */
        approveStatus?: string;
        /**
         * 辅导导师
         * @format int32
         */
        mentorUserId?: number;
        /** 获得证书 */
        certificate?: string;
        /** 完成状态 */
        completionStatus?: string;
        /** 培训活动编号 */
        trainingActivityNum?: string;
        /** 雇员Id */
        userId?: string;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<SysEmployeeTrainingsDtoListMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeetrainings/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeTrainings
     * @name WebSysEmployeeSysemployeetrainingsDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/sys/employee/sysemployeetrainings/{id}
     * @secure
     */
    webSysEmployeeSysemployeetrainingsDetail: (id: string, params: RequestParams = {}) =>
      this.request<SysEmployeeTrainingsDtoMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeetrainings/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeTrainings
     * @name WebSysEmployeeSysemployeetrainingsUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/sys/employee/sysemployeetrainings/{id}
     * @secure
     */
    webSysEmployeeSysemployeetrainingsUpdate: (id: string, data: CreateSysEmployeeTrainingsInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeetrainings/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeTrainings
     * @name WebSysEmployeeSysemployeetrainingsDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/sys/employee/sysemployeetrainings/{id}
     * @secure
     */
    webSysEmployeeSysemployeetrainingsDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeetrainings/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeTrainings
     * @name WebSysEmployeeSysemployeetrainingsCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/sys/employee/sysemployeetrainings
     * @secure
     */
    webSysEmployeeSysemployeetrainingsCreate: (data: CreateSysEmployeeTrainingsInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeetrainings`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeVocationalQualificationInfo
     * @name WebSysEmployeeSysemployeevocationalqualificationinfoPageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/sys/employee/sysemployeevocationalqualificationinfo/page
     * @secure
     */
    webSysEmployeeSysemployeevocationalqualificationinfoPageCreate: (
      query?: {
        /** 资格名称 */
        qualificationNameID?: string;
        /** 资格级别 */
        qualificationLevelID?: string;
        /** 组织路径 */
        stdOrganization_TreePath?: string;
        /** 获取资格途径 */
        accessWayID?: string;
        /** 证书附件 */
        accessory?: string;
        /**
         * 组织层级
         * @format int32
         */
        stdOrganization_TreeLevel?: number;
        /** 共享部门_包含下级 */
        permissionOrganization_SelfAndChildren?: string;
        /** 工号 */
        jobNumber?: string;
        /** 资格类型 */
        qualificationTypeID?: string;
        /** 共享部门 */
        permissionOrganization?: string;
        /**
         * 取得日期
         * @format date-time
         */
        dateOfAcquisition?: string;
        /** 共享人 */
        permissionBy?: string;
        /** 证书编号 */
        certificateNumber?: string;
        /**
         * 所属单元_层级
         * @format int32
         */
        onverMou_TreeLevel?: number;
        /**
         * 有效期至
         * @format date-time
         */
        validUntil?: string;
        /**
         * 入职日期
         * @format date-time
         */
        entryDate?: string;
        /** 期限类型 */
        termTypeId?: string;
        /** 是否最高等级 */
        supremeGrade?: boolean;
        /** 申请类型 */
        applicationTypeId?: string;
        /**
         * 最后工作日
         * @format date-time
         */
        lastWorkDate?: string;
        /** 所属单位 */
        ownerMou?: string;
        /** 所属单位路径 */
        ownerMou_TreePath?: string;
        /** 专业 */
        professional?: string;
        /** 组织 */
        stdOrganization?: string;
        /** 证据来源 */
        sourceLicensiesID?: string;
        /** 共享部门_仅自己 */
        permissionOrganization_SelfOnly?: string;
        /** 审批状态 */
        approveStatus?: string;
        /** 发布机构 */
        issueUnit?: string;
        /** 证书编号 */
        certificateNumberUpper?: string;
        /** 雇员Id */
        userId?: string;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<SysEmployeeVocationalQualificationInfoDtoPageModelMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeevocationalqualificationinfo/page`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeVocationalQualificationInfo
     * @name WebSysEmployeeSysemployeevocationalqualificationinfoListList
     * @summary 列表获取所有 (Auth policies: Web)
     * @request GET:/api/web/sys/employee/sysemployeevocationalqualificationinfo/list
     * @secure
     */
    webSysEmployeeSysemployeevocationalqualificationinfoListList: (
      query?: {
        /** 资格名称 */
        qualificationNameID?: string;
        /** 资格级别 */
        qualificationLevelID?: string;
        /** 组织路径 */
        stdOrganization_TreePath?: string;
        /** 获取资格途径 */
        accessWayID?: string;
        /** 证书附件 */
        accessory?: string;
        /**
         * 组织层级
         * @format int32
         */
        stdOrganization_TreeLevel?: number;
        /** 共享部门_包含下级 */
        permissionOrganization_SelfAndChildren?: string;
        /** 工号 */
        jobNumber?: string;
        /** 资格类型 */
        qualificationTypeID?: string;
        /** 共享部门 */
        permissionOrganization?: string;
        /**
         * 取得日期
         * @format date-time
         */
        dateOfAcquisition?: string;
        /** 共享人 */
        permissionBy?: string;
        /** 证书编号 */
        certificateNumber?: string;
        /**
         * 所属单元_层级
         * @format int32
         */
        onverMou_TreeLevel?: number;
        /**
         * 有效期至
         * @format date-time
         */
        validUntil?: string;
        /**
         * 入职日期
         * @format date-time
         */
        entryDate?: string;
        /** 期限类型 */
        termTypeId?: string;
        /** 是否最高等级 */
        supremeGrade?: boolean;
        /** 申请类型 */
        applicationTypeId?: string;
        /**
         * 最后工作日
         * @format date-time
         */
        lastWorkDate?: string;
        /** 所属单位 */
        ownerMou?: string;
        /** 所属单位路径 */
        ownerMou_TreePath?: string;
        /** 专业 */
        professional?: string;
        /** 组织 */
        stdOrganization?: string;
        /** 证据来源 */
        sourceLicensiesID?: string;
        /** 共享部门_仅自己 */
        permissionOrganization_SelfOnly?: string;
        /** 审批状态 */
        approveStatus?: string;
        /** 发布机构 */
        issueUnit?: string;
        /** 证书编号 */
        certificateNumberUpper?: string;
        /** 雇员Id */
        userId?: string;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<SysEmployeeVocationalQualificationInfoDtoListMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeevocationalqualificationinfo/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeVocationalQualificationInfo
     * @name WebSysEmployeeSysemployeevocationalqualificationinfoDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/sys/employee/sysemployeevocationalqualificationinfo/{id}
     * @secure
     */
    webSysEmployeeSysemployeevocationalqualificationinfoDetail: (id: string, params: RequestParams = {}) =>
      this.request<SysEmployeeVocationalQualificationInfoDtoMessageModel, void>({
        path: `/api/web/sys/employee/sysemployeevocationalqualificationinfo/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeVocationalQualificationInfo
     * @name WebSysEmployeeSysemployeevocationalqualificationinfoUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/sys/employee/sysemployeevocationalqualificationinfo/{id}
     * @secure
     */
    webSysEmployeeSysemployeevocationalqualificationinfoUpdate: (
      id: string,
      data: CreateSysEmployeeVocationalQualificationInfoInput,
      params: RequestParams = {}
    ) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeevocationalqualificationinfo/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeVocationalQualificationInfo
     * @name WebSysEmployeeSysemployeevocationalqualificationinfoDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/sys/employee/sysemployeevocationalqualificationinfo/{id}
     * @secure
     */
    webSysEmployeeSysemployeevocationalqualificationinfoDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeevocationalqualificationinfo/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysEmployeeVocationalQualificationInfo
     * @name WebSysEmployeeSysemployeevocationalqualificationinfoCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/sys/employee/sysemployeevocationalqualificationinfo
     * @secure
     */
    webSysEmployeeSysemployeevocationalqualificationinfoCreate: (data: CreateSysEmployeeVocationalQualificationInfoInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/employee/sysemployeevocationalqualificationinfo`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysExcelExportCenter
     * @name WebSysSysexcelexportcenterPageCreate
     * @summary 导出中心列表 (Auth policies: Web)
     * @request POST:/api/web/sys/sysexcelexportcenter/page
     * @secure
     */
    webSysSysexcelexportcenterPageCreate: (data: GetSysExcelExportCenterInput, params: RequestParams = {}) =>
      this.request<SysExcelExportCenterDtoPageModelMessageModel, void>({
        path: `/api/web/sys/sysexcelexportcenter/page`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysExcelExportCenter
     * @name WebSysSysexcelexportcenterCreate
     * @summary 导出中心列表导出 (Auth policies: Web)
     * @request POST:/api/web/sys/sysexcelexportcenter
     * @secure
     */
    webSysSysexcelexportcenterCreate: (data: GetSysExcelExportCenterInputExportInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/sys/sysexcelexportcenter`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysExcelExportCenter
     * @name WebSysSysexcelexportcenterUpdate
     * @summary 累加下载次数 (Auth policies: Web)
     * @request PUT:/api/web/sys/sysexcelexportcenter/{id}
     * @secure
     */
    webSysSysexcelexportcenterUpdate: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/sysexcelexportcenter/${id}`,
        method: 'PUT',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysExternalNotificationHistories
     * @name WebSysSysexternalnotificationhistoriesPageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/sys/sysexternalnotificationhistories/page
     * @secure
     */
    webSysSysexternalnotificationhistoriesPageCreate: (
      query?: {
        /** 正文 */
        notificationContent?: string;
        /**
         * 通知类型
         * @format int32
         */
        notificationType?: 0 | 1 | 2 | 3 | 4;
        /** 关联Id */
        associatedId?: string;
        /** 参数 */
        parameterJson?: string;
        /** 返回参数 */
        responseJson?: string;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<SysExternalNotificationHistoriesDtoPageModelMessageModel, void>({
        path: `/api/web/sys/sysexternalnotificationhistories/page`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysExternalNotificationHistories
     * @name WebSysSysexternalnotificationhistoriesListList
     * @summary 列表获取所有 (Auth policies: Web)
     * @request GET:/api/web/sys/sysexternalnotificationhistories/list
     * @secure
     */
    webSysSysexternalnotificationhistoriesListList: (
      query?: {
        /** 正文 */
        notificationContent?: string;
        /**
         * 通知类型
         * @format int32
         */
        notificationType?: 0 | 1 | 2 | 3 | 4;
        /** 关联Id */
        associatedId?: string;
        /** 参数 */
        parameterJson?: string;
        /** 返回参数 */
        responseJson?: string;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<SysExternalNotificationHistoriesDtoListMessageModel, void>({
        path: `/api/web/sys/sysexternalnotificationhistories/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysExternalNotificationHistories
     * @name WebSysSysexternalnotificationhistoriesDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/sys/sysexternalnotificationhistories/{id}
     * @secure
     */
    webSysSysexternalnotificationhistoriesDetail: (id: string, params: RequestParams = {}) =>
      this.request<SysExternalNotificationHistoriesDtoMessageModel, void>({
        path: `/api/web/sys/sysexternalnotificationhistories/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysExternalNotificationHistories
     * @name WebSysSysexternalnotificationhistoriesUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/sys/sysexternalnotificationhistories/{id}
     * @secure
     */
    webSysSysexternalnotificationhistoriesUpdate: (id: string, data: CreateSysExternalNotificationHistoriesInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/sysexternalnotificationhistories/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysExternalNotificationHistories
     * @name WebSysSysexternalnotificationhistoriesDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/sys/sysexternalnotificationhistories/{id}
     * @secure
     */
    webSysSysexternalnotificationhistoriesDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/sysexternalnotificationhistories/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysExternalNotificationHistories
     * @name WebSysSysexternalnotificationhistoriesCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/sys/sysexternalnotificationhistories
     * @secure
     */
    webSysSysexternalnotificationhistoriesCreate: (data: CreateSysExternalNotificationHistoriesInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/sysexternalnotificationhistories`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysGeneralCodeGroups
     * @name WebSysSysgeneralcodegroupsPageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/sys/sysgeneralcodegroups/page
     * @secure
     */
    webSysSysgeneralcodegroupsPageCreate: (
      query?: {
        /** 名称 */
        name?: string;
        /** 代码 */
        code?: string;
        /** 父级代码 */
        parentCode?: string;
        /**
         * 模块
         * @format int32
         */
        funcGroup?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<SysGeneralCodeGroupsDtoPageModelMessageModel, void>({
        path: `/api/web/sys/sysgeneralcodegroups/page`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysGeneralCodeGroups
     * @name WebSysSysgeneralcodegroupsListList
     * @summary 列表获取所有 (Auth policies: Web)
     * @request GET:/api/web/sys/sysgeneralcodegroups/list
     * @secure
     */
    webSysSysgeneralcodegroupsListList: (
      query?: {
        /** 名称 */
        name?: string;
        /** 代码 */
        code?: string;
        /** 父级代码 */
        parentCode?: string;
        /**
         * 模块
         * @format int32
         */
        funcGroup?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<SysGeneralCodeGroupsDtoListMessageModel, void>({
        path: `/api/web/sys/sysgeneralcodegroups/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysGeneralCodeGroups
     * @name WebSysSysgeneralcodegroupsDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/sys/sysgeneralcodegroups/{id}
     * @secure
     */
    webSysSysgeneralcodegroupsDetail: (id: string, params: RequestParams = {}) =>
      this.request<SysGeneralCodeGroupsDtoMessageModel, void>({
        path: `/api/web/sys/sysgeneralcodegroups/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysGeneralCodeGroups
     * @name WebSysSysgeneralcodegroupsUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/sys/sysgeneralcodegroups/{id}
     * @secure
     */
    webSysSysgeneralcodegroupsUpdate: (id: string, data: CreateSysGeneralCodeGroupsInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/sysgeneralcodegroups/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysGeneralCodeGroups
     * @name WebSysSysgeneralcodegroupsDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/sys/sysgeneralcodegroups/{id}
     * @secure
     */
    webSysSysgeneralcodegroupsDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/sysgeneralcodegroups/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysGeneralCodeGroups
     * @name WebSysSysgeneralcodegroupsCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/sys/sysgeneralcodegroups
     * @secure
     */
    webSysSysgeneralcodegroupsCreate: (data: CreateSysGeneralCodeGroupsInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/sysgeneralcodegroups`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysRoleGroups
     * @name WebSysSysrolegroupsPageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/sys/sysrolegroups/page
     * @secure
     */
    webSysSysrolegroupsPageCreate: (data: GetSysRoleGroupsInput, params: RequestParams = {}) =>
      this.request<SysRoleGroupsDtoPageModelMessageModel, void>({
        path: `/api/web/sys/sysrolegroups/page`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysRoleGroups
     * @name WebSysSysrolegroupsListList
     * @summary 列表获取所有 (Auth policies: Web)
     * @request GET:/api/web/sys/sysrolegroups/list
     * @secure
     */
    webSysSysrolegroupsListList: (
      query?: {
        /** 分组名称 */
        name?: string;
        /** 角色名称 */
        roleName?: string;
        /**
         * 排序
         * @format int32
         */
        sort?: number;
        /**
         * 权限类型
         * @format int32
         */
        roleType?: 0 | 1 | 2 | 3;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<SysRoleGroupsDtoListMessageModel, void>({
        path: `/api/web/sys/sysrolegroups/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysRoleGroups
     * @name WebSysSysrolegroupsDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/sys/sysrolegroups/{id}
     * @secure
     */
    webSysSysrolegroupsDetail: (id: string, params: RequestParams = {}) =>
      this.request<SysRoleGroupsDtoMessageModel, void>({
        path: `/api/web/sys/sysrolegroups/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysRoleGroups
     * @name WebSysSysrolegroupsUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/sys/sysrolegroups/{id}
     * @secure
     */
    webSysSysrolegroupsUpdate: (id: string, data: CreateSysRoleGroupsInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/sysrolegroups/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysRoleGroups
     * @name WebSysSysrolegroupsDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/sys/sysrolegroups/{id}
     * @secure
     */
    webSysSysrolegroupsDelete: (id: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/sysrolegroups/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SysRoleGroups
     * @name WebSysSysrolegroupsCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/sys/sysrolegroups
     * @secure
     */
    webSysSysrolegroupsCreate: (data: CreateSysRoleGroupsInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/sysrolegroups`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SystemConfig
     * @name WebSysSystemconfigGeneralList
     * @summary 获取常规配置 (Auth policies: Web)
     * @request GET:/api/web/sys/systemconfig/general
     * @secure
     */
    webSysSystemconfigGeneralList: (params: RequestParams = {}) =>
      this.request<SystemThemeSettingDtoMessageModel, void>({
        path: `/api/web/sys/systemconfig/general`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SystemConfig
     * @name WebSysSystemconfigGeneralCreate
     * @summary 修改常规配置 (Auth policies: Web)
     * @request POST:/api/web/sys/systemconfig/general
     * @secure
     */
    webSysSystemconfigGeneralCreate: (data: SystemThemeSettingDto, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/systemconfig/general`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SystemConfig
     * @name WebSysSystemconfigTestmailList
     * @summary 获取测试邮箱配置 (Auth policies: Web)
     * @request GET:/api/web/sys/systemconfig/testmail
     * @secure
     */
    webSysSystemconfigTestmailList: (params: RequestParams = {}) =>
      this.request<StringListMessageModel, void>({
        path: `/api/web/sys/systemconfig/testmail`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SystemConfig
     * @name WebSysSystemconfigTestmailCreate
     * @summary 更新测试邮箱配置 (Auth policies: Web)
     * @request POST:/api/web/sys/systemconfig/testmail
     * @secure
     */
    webSysSystemconfigTestmailCreate: (data: string[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/systemconfig/testmail`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SystemConfig
     * @name WebSysSystemconfigSystemLockList
     * @summary 获取锁定配置 (Auth policies: Web)
     * @request GET:/api/web/sys/systemconfig/system/lock
     * @secure
     */
    webSysSystemconfigSystemLockList: (params: RequestParams = {}) =>
      this.request<SystemLockDtoMessageModel, void>({
        path: `/api/web/sys/systemconfig/system/lock`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SystemConfig
     * @name WebSysSystemconfigSystemLockCreate
     * @summary 更新锁定配置 (Auth policies: Web)
     * @request POST:/api/web/sys/systemconfig/system/lock
     * @secure
     */
    webSysSystemconfigSystemLockCreate: (data: SystemLockDto, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/systemconfig/system/lock`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SystemConfig
     * @name WebSysSystemconfigGeneralRemindUpdate
     * @summary 修改全局弹窗 (Auth policies: Web)
     * @request PUT:/api/web/sys/systemconfig/general/remind
     * @secure
     */
    webSysSystemconfigGeneralRemindUpdate: (params: RequestParams = {}) =>
      this.request<SystemThemeSettingDtoMessageModel, void>({
        path: `/api/web/sys/systemconfig/general/remind`,
        method: 'PUT',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SystemConfig
     * @name WebSysSystemconfigSecurityList
     * @summary 获取安全配置 (Auth policies: Web)
     * @request GET:/api/web/sys/systemconfig/security
     * @secure
     */
    webSysSystemconfigSecurityList: (params: RequestParams = {}) =>
      this.request<SystemSecuritySettingDtoMessageModel, void>({
        path: `/api/web/sys/systemconfig/security`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SystemConfig
     * @name WebSysSystemconfigSecurityCreate
     * @summary 修改安全配置 (Auth policies: Web)
     * @request POST:/api/web/sys/systemconfig/security
     * @secure
     */
    webSysSystemconfigSecurityCreate: (data: SystemSecuritySettingDto, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/systemconfig/security`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SystemConfig
     * @name WebSysSystemconfigLdapList
     * @summary 获取Ldap配置 (Auth policies: Web)
     * @request GET:/api/web/sys/systemconfig/ldap
     * @secure
     */
    webSysSystemconfigLdapList: (
      query?: {
        tenantId?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<LdapConfigMessageModel, void>({
        path: `/api/web/sys/systemconfig/ldap`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SystemConfig
     * @name WebSysSystemconfigLdapCreate
     * @summary 修改LDAP配置 (Auth policies: Web)
     * @request POST:/api/web/sys/systemconfig/ldap
     * @secure
     */
    webSysSystemconfigLdapCreate: (
      data: LdapConfig,
      query?: {
        tenantId?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/systemconfig/ldap`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags TenantBankInfo
     * @name WebFinancialBaseTenantbankinfoGetpageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/financial/base/tenantbankinfo/getpage
     * @secure
     */
    webFinancialBaseTenantbankinfoGetpageCreate: (data: GetTenantBankInfoInput, params: RequestParams = {}) =>
      this.request<GetTenantBankInfoOutputPageModelMessageModel, void>({
        path: `/api/web/financial/base/tenantbankinfo/getpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags TenantBankInfo
     * @name WebFinancialBaseTenantbankinfoListCreate
     * @summary 列表获取所有 (Auth policies: Web)
     * @request POST:/api/web/financial/base/tenantbankinfo/list
     * @secure
     */
    webFinancialBaseTenantbankinfoListCreate: (data: GetTenantBankInfoInput, params: RequestParams = {}) =>
      this.request<GetTenantBankInfoOutputListMessageModel, void>({
        path: `/api/web/financial/base/tenantbankinfo/list`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags TenantBankInfo
     * @name WebFinancialBaseTenantbankinfoGettenantbankinfoexportCreate
     * @summary 列表导出 (Auth policies: Web)
     * @request POST:/api/web/financial/base/tenantbankinfo/gettenantbankinfoexport
     * @secure
     */
    webFinancialBaseTenantbankinfoGettenantbankinfoexportCreate: (data: GetTenantBankInfoInputExportInput, params: RequestParams = {}) =>
      this.request<ExcelFileDtoMessageModel, void>({
        path: `/api/web/financial/base/tenantbankinfo/gettenantbankinfoexport`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags TenantBankInfo
     * @name WebFinancialBaseTenantbankinfoGetselectCreate
     * @summary 选择框 (Auth policies: Web)
     * @request POST:/api/web/financial/base/tenantbankinfo/getselect
     * @secure
     */
    webFinancialBaseTenantbankinfoGetselectCreate: (data: GetOrganizationUnitsInput, params: RequestParams = {}) =>
      this.request<ComboboxItemDtoListMessageModel, void>({
        path: `/api/web/financial/base/tenantbankinfo/getselect`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags TenantBankInfo
     * @name WebFinancialBaseTenantbankinfoGetCreate
     * @summary 获取详情 (Auth policies: Web)
     * @request POST:/api/web/financial/base/tenantbankinfo/get/{id}
     * @secure
     */
    webFinancialBaseTenantbankinfoGetCreate: (id: string, params: RequestParams = {}) =>
      this.request<GetTenantBankInfoOutputMessageModel, void>({
        path: `/api/web/financial/base/tenantbankinfo/get/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags TenantBankInfo
     * @name WebFinancialBaseTenantbankinfoGetforeditCreate
     * @summary 获取修改实体 (Auth policies: Web)
     * @request POST:/api/web/financial/base/tenantbankinfo/getforedit/{id}
     * @secure
     */
    webFinancialBaseTenantbankinfoGetforeditCreate: (id: string, params: RequestParams = {}) =>
      this.request<GetTenantBankInfoForEditOutputForEditModelMessageModel, void>({
        path: `/api/web/financial/base/tenantbankinfo/getforedit/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags TenantBankInfo
     * @name WebFinancialBaseTenantbankinfoToggleenablestatusCreate
     * @summary 切换银行账号的启用状态 (Auth policies: Web)
     * @request POST:/api/web/financial/base/tenantbankinfo/toggleenablestatus
     * @secure
     */
    webFinancialBaseTenantbankinfoToggleenablestatusCreate: (data: StringEntityDto, params: RequestParams = {}) =>
      this.request<BooleanMessageModel, void>({
        path: `/api/web/financial/base/tenantbankinfo/toggleenablestatus`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags TenantBankInfo
     * @name WebFinancialBaseTenantbankinfoGetbranchinfowithofficeidCreate
     * @summary 根据办公室编号获取工商基本信息 (Auth policies: Web)
     * @request POST:/api/web/financial/base/tenantbankinfo/getbranchinfowithofficeid
     * @secure
     */
    webFinancialBaseTenantbankinfoGetbranchinfowithofficeidCreate: (data: StringEntityDto, params: RequestParams = {}) =>
      this.request<GetBranchInfoOutputMessageModel, void>({
        path: `/api/web/financial/base/tenantbankinfo/getbranchinfowithofficeid`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags TenantBankInfo
     * @name WebFinancialBaseTenantbankinfoChangebindofficeinfoCreate
     * @summary 变更绑定办公室信息 (Auth policies: Web)
     * @request POST:/api/web/financial/base/tenantbankinfo/changebindofficeinfo
     * @secure
     */
    webFinancialBaseTenantbankinfoChangebindofficeinfoCreate: (data: ChangeTenantBankInfoOfficeInput, params: RequestParams = {}) =>
      this.request<BooleanMessageModel, void>({
        path: `/api/web/financial/base/tenantbankinfo/changebindofficeinfo`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags TenantBankInfo
     * @name WebFinancialBaseTenantbankinfoChangedefaultaccountCreate
     * @summary 变更默认银行账号信息 (Auth policies: Web)
     * @request POST:/api/web/financial/base/tenantbankinfo/changedefaultaccount
     * @secure
     */
    webFinancialBaseTenantbankinfoChangedefaultaccountCreate: (data: ChangeDefaultTenantBankInfoInput, params: RequestParams = {}) =>
      this.request<BooleanMessageModel, void>({
        path: `/api/web/financial/base/tenantbankinfo/changedefaultaccount`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags TenantBankInfo
     * @name WebFinancialBaseTenantbankinfoPostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/financial/base/tenantbankinfo/post
     * @secure
     */
    webFinancialBaseTenantbankinfoPostCreate: (data: CreateTenantBankInfoInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/base/tenantbankinfo/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags TenantBankInfo
     * @name WebFinancialBaseTenantbankinfoPutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/financial/base/tenantbankinfo/put/{id}
     * @secure
     */
    webFinancialBaseTenantbankinfoPutUpdate: (id: string, data: CreateTenantBankInfoInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/base/tenantbankinfo/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags TenantBankInfo
     * @name WebFinancialBaseTenantbankinfoDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/financial/base/tenantbankinfo/delete
     * @secure
     */
    webFinancialBaseTenantbankinfoDeleteDelete: (data: StringEntityDto, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/base/tenantbankinfo/delete`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags TenantBankInfo
     * @name WebFinancialBaseTenantbankinfoBatchdeleteCreate
     * @summary 批量删除 (Auth policies: Web)
     * @request POST:/api/web/financial/base/tenantbankinfo/batchdelete
     * @secure
     */
    webFinancialBaseTenantbankinfoBatchdeleteCreate: (data: StringEntityDto[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/financial/base/tenantbankinfo/batchdelete`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Timesheet
     * @name WebTimesheetsTimesheetTimesheetGetmycalendarCreate
     * @summary 获取日历视图我的工时 (Auth policies: Web)
     * @request POST:/api/web/timesheets/timesheet/timesheet/getmycalendar
     * @secure
     */
    webTimesheetsTimesheetTimesheetGetmycalendarCreate: (data: GetTimesheetInput, params: RequestParams = {}) =>
      this.request<GetTimesheetForCalendarOutputListMessageModel, void>({
        path: `/api/web/timesheets/timesheet/timesheet/getmycalendar`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Timesheet
     * @name WebTimesheetsTimesheetTimesheetGetcalendarCreate
     * @summary 获取日历视图工时 (Auth policies: Web)
     * @request POST:/api/web/timesheets/timesheet/timesheet/getcalendar
     * @secure
     */
    webTimesheetsTimesheetTimesheetGetcalendarCreate: (data: GetTimesheetInput, params: RequestParams = {}) =>
      this.request<GetTimesheetForCalendarOutputListMessageModel, void>({
        path: `/api/web/timesheets/timesheet/timesheet/getcalendar`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Timesheet
     * @name WebTimesheetsTimesheetTimesheetGetmypageCreate
     * @summary 我的工时分页 (Auth policies: Web)
     * @request POST:/api/web/timesheets/timesheet/timesheet/getmypage
     * @secure
     */
    webTimesheetsTimesheetTimesheetGetmypageCreate: (data: GetTimesheetInput, params: RequestParams = {}) =>
      this.request<GetTimesheetOutputPageModelMessageModel, void>({
        path: `/api/web/timesheets/timesheet/timesheet/getmypage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Timesheet
     * @name WebTimesheetsTimesheetTimesheetGetspecialpageCreate
     * @summary 特殊工时分页列表 (Auth policies: Web)
     * @request POST:/api/web/timesheets/timesheet/timesheet/getspecialpage
     * @secure
     */
    webTimesheetsTimesheetTimesheetGetspecialpageCreate: (data: GetSpecialTimesheetInput, params: RequestParams = {}) =>
      this.request<GetTimesheetOutputPageModelMessageModel, void>({
        path: `/api/web/timesheets/timesheet/timesheet/getspecialpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Timesheet
     * @name WebTimesheetsTimesheetTimesheetGetmyfavoritedpageCreate
     * @summary 我星标的工时分页 (Auth policies: Web)
     * @request POST:/api/web/timesheets/timesheet/timesheet/getmyfavoritedpage
     * @secure
     */
    webTimesheetsTimesheetTimesheetGetmyfavoritedpageCreate: (data: GetFavoritedTimesheetInput, params: RequestParams = {}) =>
      this.request<GetTimesheetOutputPageModelMessageModel, void>({
        path: `/api/web/timesheets/timesheet/timesheet/getmyfavoritedpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Timesheet
     * @name WebTimesheetsTimesheetTimesheetGetpageCreate
     * @summary 工时分页 (Auth policies: Web)
     * @request POST:/api/web/timesheets/timesheet/timesheet/getpage
     * @secure
     */
    webTimesheetsTimesheetTimesheetGetpageCreate: (data: GetAllTimesheetInput, params: RequestParams = {}) =>
      this.request<GetTimesheetOutputPageModelMessageModel, void>({
        path: `/api/web/timesheets/timesheet/timesheet/getpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Timesheet
     * @name WebTimesheetsTimesheetTimesheetGettimesheettotalCreate
     * @summary 工时状态统计 (Auth policies: Web)
     * @request POST:/api/web/timesheets/timesheet/timesheet/gettimesheettotal
     * @secure
     */
    webTimesheetsTimesheetTimesheetGettimesheettotalCreate: (data: GetAllTimesheetInput, params: RequestParams = {}) =>
      this.request<StautsTotalDtoListMessageModel, void>({
        path: `/api/web/timesheets/timesheet/timesheet/gettimesheettotal`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Timesheet
     * @name WebTimesheetsTimesheetTimesheetGetmytimesheetexportCreate
     * @summary 我的工时列表导出 (Auth policies: Web)
     * @request POST:/api/web/timesheets/timesheet/timesheet/getmytimesheetexport
     * @secure
     */
    webTimesheetsTimesheetTimesheetGetmytimesheetexportCreate: (data: GetTimesheetInputExportInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/timesheets/timesheet/timesheet/getmytimesheetexport`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Timesheet
     * @name WebTimesheetsTimesheetTimesheetGettimesheetexportCreate
     * @summary 工时列表导出 (Auth policies: Web)
     * @request POST:/api/web/timesheets/timesheet/timesheet/gettimesheetexport
     * @secure
     */
    webTimesheetsTimesheetTimesheetGettimesheetexportCreate: (data: GetAllTimesheetInputExportInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/timesheets/timesheet/timesheet/gettimesheetexport`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Timesheet
     * @name WebTimesheetsTimesheetTimesheetGetspecialtimesheetexportCreate
     * @summary 特殊工时列表导出 (Auth policies: Web)
     * @request POST:/api/web/timesheets/timesheet/timesheet/getspecialtimesheetexport
     * @secure
     */
    webTimesheetsTimesheetTimesheetGetspecialtimesheetexportCreate: (data: GetSpecialTimesheetInputExportInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/timesheets/timesheet/timesheet/getspecialtimesheetexport`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Timesheet
     * @name WebTimesheetsTimesheetTimesheetGetCreate
     * @summary 获取详情 (Auth policies: Web)
     * @request POST:/api/web/timesheets/timesheet/timesheet/get/{id}
     * @secure
     */
    webTimesheetsTimesheetTimesheetGetCreate: (id: string, params: RequestParams = {}) =>
      this.request<GetTimesheetInfoOutputMessageModel, void>({
        path: `/api/web/timesheets/timesheet/timesheet/get/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Timesheet
     * @name WebTimesheetsTimesheetTimesheetCreatetimerCreate
     * @summary 新增计时器 (Auth policies: Web)
     * @request POST:/api/web/timesheets/timesheet/timesheet/createtimer
     * @secure
     */
    webTimesheetsTimesheetTimesheetCreatetimerCreate: (data: CreateTimerInput, params: RequestParams = {}) =>
      this.request<GetTimerOutputMessageModel, void>({
        path: `/api/web/timesheets/timesheet/timesheet/createtimer`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Timesheet
     * @name WebTimesheetsTimesheetTimesheetRunningtimerCreate
     * @summary 计时器状态变更 (Auth policies: Web)
     * @request POST:/api/web/timesheets/timesheet/timesheet/runningtimer
     * @secure
     */
    webTimesheetsTimesheetTimesheetRunningtimerCreate: (data: RunningTimerInput, params: RequestParams = {}) =>
      this.request<GetTimerOutputMessageModel, void>({
        path: `/api/web/timesheets/timesheet/timesheet/runningtimer`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Timesheet
     * @name WebTimesheetsTimesheetTimesheetGetmytimerpageCreate
     * @summary 我的运行中的计时器列表 (Auth policies: Web)
     * @request POST:/api/web/timesheets/timesheet/timesheet/getmytimerpage
     * @secure
     */
    webTimesheetsTimesheetTimesheetGetmytimerpageCreate: (data: GetTimerInput, params: RequestParams = {}) =>
      this.request<GetTimerOutputListMessageModel, void>({
        path: `/api/web/timesheets/timesheet/timesheet/getmytimerpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Timesheet
     * @name WebTimesheetsTimesheetTimesheetGettimertrackinglistCreate
     * @summary 计时器Tracking列表 (Auth policies: Web)
     * @request POST:/api/web/timesheets/timesheet/timesheet/gettimertrackinglist
     * @secure
     */
    webTimesheetsTimesheetTimesheetGettimertrackinglistCreate: (data: StringEntityDto, params: RequestParams = {}) =>
      this.request<GetTimerTrackingRecordOutputListMessageModel, void>({
        path: `/api/web/timesheets/timesheet/timesheet/gettimertrackinglist`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Timesheet
     * @name WebTimesheetsTimesheetTimesheetGettimerpageCreate
     * @summary 运行中的计时器列表 (Auth policies: Web)
     * @request POST:/api/web/timesheets/timesheet/timesheet/gettimerpage
     * @secure
     */
    webTimesheetsTimesheetTimesheetGettimerpageCreate: (data: GetTimerInput, params: RequestParams = {}) =>
      this.request<GetTimerOutputListMessageModel, void>({
        path: `/api/web/timesheets/timesheet/timesheet/gettimerpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Timesheet
     * @name WebTimesheetsTimesheetTimesheetCopytimesheetCreate
     * @summary 复制工时 (Auth policies: Web)
     * @request POST:/api/web/timesheets/timesheet/timesheet/copytimesheet
     * @secure
     */
    webTimesheetsTimesheetTimesheetCopytimesheetCreate: (data: StringEntityDto[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/timesheets/timesheet/timesheet/copytimesheet`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Timesheet
     * @name WebTimesheetsTimesheetTimesheetSplittimesheetCreate
     * @summary 拆分单个工时 (Auth policies: Web)
     * @request POST:/api/web/timesheets/timesheet/timesheet/splittimesheet
     * @secure
     */
    webTimesheetsTimesheetTimesheetSplittimesheetCreate: (data: SplitTimesheetInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/timesheets/timesheet/timesheet/splittimesheet`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Timesheet
     * @name WebTimesheetsTimesheetTimesheetBatchsplittimesheetCreate
     * @summary 批量拆分工时 (Auth policies: Web)
     * @request POST:/api/web/timesheets/timesheet/timesheet/batchsplittimesheet
     * @secure
     */
    webTimesheetsTimesheetTimesheetBatchsplittimesheetCreate: (data: SplitTimesheetInput[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/timesheets/timesheet/timesheet/batchsplittimesheet`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Timesheet
     * @name WebTimesheetsTimesheetTimesheetMergetimesheetCreate
     * @summary 合并工时 (Auth policies: Web)
     * @request POST:/api/web/timesheets/timesheet/timesheet/mergetimesheet
     * @secure
     */
    webTimesheetsTimesheetTimesheetMergetimesheetCreate: (data: MergeTimesheetInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/timesheets/timesheet/timesheet/mergetimesheet`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Timesheet
     * @name WebTimesheetsTimesheetTimesheetTransfertimesheetCreate
     * @summary 转移工时 (Auth policies: Web)
     * @request POST:/api/web/timesheets/timesheet/timesheet/transfertimesheet
     * @secure
     */
    webTimesheetsTimesheetTimesheetTransfertimesheetCreate: (data: TransferTimesheetInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/timesheets/timesheet/timesheet/transfertimesheet`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Timesheet
     * @name WebTimesheetsTimesheetTimesheetSubmittimesheetCreate
     * @summary 提交工时 (Auth policies: Web)
     * @request POST:/api/web/timesheets/timesheet/timesheet/submittimesheet
     * @secure
     */
    webTimesheetsTimesheetTimesheetSubmittimesheetCreate: (data: StringEntityDto[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/timesheets/timesheet/timesheet/submittimesheet`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Timesheet
     * @name WebTimesheetsTimesheetTimesheetSubmitspecialtimesheetCreate
     * @summary 提交特殊案件工时 (Auth policies: Web)
     * @request POST:/api/web/timesheets/timesheet/timesheet/submitspecialtimesheet
     * @secure
     */
    webTimesheetsTimesheetTimesheetSubmitspecialtimesheetCreate: (data: StringEntityDto[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/timesheets/timesheet/timesheet/submitspecialtimesheet`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Timesheet
     * @name WebTimesheetsTimesheetTimesheetSubmitworkdaterangereadytimesheetCreate
     * @summary 提交工时区间内的所有ready状态的工时 (Auth policies: Web)
     * @request POST:/api/web/timesheets/timesheet/timesheet/submitworkdaterangereadytimesheet
     * @secure
     */
    webTimesheetsTimesheetTimesheetSubmitworkdaterangereadytimesheetCreate: (data: GetTimesheetSubtotalInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/timesheets/timesheet/timesheet/submitworkdaterangereadytimesheet`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Timesheet
     * @name WebTimesheetsTimesheetTimesheetWithdrawtimesheetCreate
     * @summary 撤回工时 (Auth policies: Web)
     * @request POST:/api/web/timesheets/timesheet/timesheet/withdrawtimesheet
     * @secure
     */
    webTimesheetsTimesheetTimesheetWithdrawtimesheetCreate: (data: StringEntityDto[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/timesheets/timesheet/timesheet/withdrawtimesheet`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Timesheet
     * @name WebTimesheetsTimesheetTimesheetGetweekgrouptimesheetCreate
     * @summary 周视图分组工时 (Auth policies: Web)
     * @request POST:/api/web/timesheets/timesheet/timesheet/getweekgrouptimesheet
     * @secure
     */
    webTimesheetsTimesheetTimesheetGetweekgrouptimesheetCreate: (data: GetWeekTimesheetInput, params: RequestParams = {}) =>
      this.request<GetWeekTimesheetOutputIEnumerableMessageModel, void>({
        path: `/api/web/timesheets/timesheet/timesheet/getweekgrouptimesheet`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Timesheet
     * @name WebTimesheetsTimesheetTimesheetGettimesheetsubtotalCreate
     * @summary 获取工时小计 (Auth policies: Web)
     * @request POST:/api/web/timesheets/timesheet/timesheet/gettimesheetsubtotal
     * @secure
     */
    webTimesheetsTimesheetTimesheetGettimesheetsubtotalCreate: (data: GetTimesheetSubtotalInput, params: RequestParams = {}) =>
      this.request<GetTimesheetSubtotalOutputIEnumerableMessageModel, void>({
        path: `/api/web/timesheets/timesheet/timesheet/gettimesheetsubtotal`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Timesheet
     * @name WebTimesheetsTimesheetTimesheetFavoritedtimesheetCreate
     * @summary 星标工时 (Auth policies: Web)
     * @request POST:/api/web/timesheets/timesheet/timesheet/favoritedtimesheet
     * @secure
     */
    webTimesheetsTimesheetTimesheetFavoritedtimesheetCreate: (data: StringEntityDto, params: RequestParams = {}) =>
      this.request<BooleanMessageModel, void>({
        path: `/api/web/timesheets/timesheet/timesheet/favoritedtimesheet`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Timesheet
     * @name WebTimesheetsTimesheetTimesheetGetforeditCreate
     * @summary 获取修改实体 (Auth policies: Web)
     * @request POST:/api/web/timesheets/timesheet/timesheet/getforedit/{id}
     * @secure
     */
    webTimesheetsTimesheetTimesheetGetforeditCreate: (id: string, params: RequestParams = {}) =>
      this.request<GetTimesheetForEditOutputMessageModel, void>({
        path: `/api/web/timesheets/timesheet/timesheet/getforedit/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Timesheet
     * @name WebTimesheetsTimesheetTimesheetPostdraftCreate
     * @summary 新增保存工时草稿 (Auth policies: Web)
     * @request POST:/api/web/timesheets/timesheet/timesheet/postdraft
     * @secure
     */
    webTimesheetsTimesheetTimesheetPostdraftCreate: (data: CreateDraftTimesheetInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/timesheets/timesheet/timesheet/postdraft`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Timesheet
     * @name WebTimesheetsTimesheetTimesheetPostdraftBatchCreate
     * @summary 批量新增保存草稿 (Auth policies: Web)
     * @request POST:/api/web/timesheets/timesheet/timesheet/postdraft/batch
     * @secure
     */
    webTimesheetsTimesheetTimesheetPostdraftBatchCreate: (data: CreateDraftTimesheetInput[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/timesheets/timesheet/timesheet/postdraft/batch`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Timesheet
     * @name WebTimesheetsTimesheetTimesheetPutdraftUpdate
     * @summary 修改工时草稿 (Auth policies: Web)
     * @request PUT:/api/web/timesheets/timesheet/timesheet/putdraft/{id}
     * @secure
     */
    webTimesheetsTimesheetTimesheetPutdraftUpdate: (id: string, data: UpdateTimesheetInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/timesheets/timesheet/timesheet/putdraft/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Timesheet
     * @name WebTimesheetsTimesheetTimesheetPutdraftBatchUpdate
     * @summary 批量修改草稿 (Auth policies: Web)
     * @request PUT:/api/web/timesheets/timesheet/timesheet/putdraft/batch
     * @secure
     */
    webTimesheetsTimesheetTimesheetPutdraftBatchUpdate: (data: UpdateDraftTimesheetInput[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/timesheets/timesheet/timesheet/putdraft/batch`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Timesheet
     * @name WebTimesheetsTimesheetTimesheetPostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/timesheets/timesheet/timesheet/post
     * @secure
     */
    webTimesheetsTimesheetTimesheetPostCreate: (data: CreateTimesheetInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/timesheets/timesheet/timesheet/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Timesheet
     * @name WebTimesheetsTimesheetTimesheetPostBatchCreate
     * @summary 批量保存 (Auth policies: Web)
     * @request POST:/api/web/timesheets/timesheet/timesheet/post/batch
     * @secure
     */
    webTimesheetsTimesheetTimesheetPostBatchCreate: (data: CreateTimesheetInput[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/timesheets/timesheet/timesheet/post/batch`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Timesheet
     * @name WebTimesheetsTimesheetTimesheetUpdatenarrationCreate
     * @summary 修改工时描述 (Auth policies: Web)
     * @request POST:/api/web/timesheets/timesheet/timesheet/updatenarration
     * @secure
     */
    webTimesheetsTimesheetTimesheetUpdatenarrationCreate: (data: UpdateNarrativeInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/timesheets/timesheet/timesheet/updatenarration`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Timesheet
     * @name WebTimesheetsTimesheetTimesheetPutUpdate
     * @summary 修改工时 (Auth policies: Web)
     * @request PUT:/api/web/timesheets/timesheet/timesheet/put/{id}
     * @secure
     */
    webTimesheetsTimesheetTimesheetPutUpdate: (id: string, data: UpdateTimesheetInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/timesheets/timesheet/timesheet/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Timesheet
     * @name WebTimesheetsTimesheetTimesheetPutBatchUpdate
     * @summary 批量修改 (Auth policies: Web)
     * @request PUT:/api/web/timesheets/timesheet/timesheet/put/batch
     * @secure
     */
    webTimesheetsTimesheetTimesheetPutBatchUpdate: (data: UpdateTimesheetInput[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/timesheets/timesheet/timesheet/put/batch`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Timesheet
     * @name WebTimesheetsTimesheetTimesheetDeleteCreate
     * @summary 删除 (Auth policies: Web)
     * @request POST:/api/web/timesheets/timesheet/timesheet/delete
     * @secure
     */
    webTimesheetsTimesheetTimesheetDeleteCreate: (data: StringEntityDto, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/timesheets/timesheet/timesheet/delete`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Timesheet
     * @name WebTimesheetsTimesheetTimesheetBatchdeleteCreate
     * @summary 批量删除 (Auth policies: Web)
     * @request POST:/api/web/timesheets/timesheet/timesheet/batchdelete
     * @secure
     */
    webTimesheetsTimesheetTimesheetBatchdeleteCreate: (data: StringEntityDto[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/timesheets/timesheet/timesheet/batchdelete`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags TimesheetNickName
     * @name WebTimesheetsTimesheetnicknameGetpageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/timesheets/timesheetnickname/getpage
     * @secure
     */
    webTimesheetsTimesheetnicknameGetpageCreate: (data: GetTimesheetNickNameInput, params: RequestParams = {}) =>
      this.request<GetTimesheetNickNameOutputPageModelMessageModel, void>({
        path: `/api/web/timesheets/timesheetnickname/getpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags TimesheetNickName
     * @name WebTimesheetsTimesheetnicknameListCreate
     * @summary 列表获取所有 (Auth policies: Web)
     * @request POST:/api/web/timesheets/timesheetnickname/list
     * @secure
     */
    webTimesheetsTimesheetnicknameListCreate: (
      query?: {
        /** 暂代名称 */
        nickName?: string;
        /** 人员编号 */
        employeeId?: string;
        /** 客户编号 */
        clientId?: string;
        /** 案件编号 */
        caseId?: string;
        /** 备注 */
        remark?: string;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<GetTimesheetNickNameOutputListMessageModel, void>({
        path: `/api/web/timesheets/timesheetnickname/list`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags TimesheetNickName
     * @name WebTimesheetsTimesheetnicknameGettimesheetnicknameexportCreate
     * @summary 列表导出 (Auth policies: Web)
     * @request POST:/api/web/timesheets/timesheetnickname/gettimesheetnicknameexport
     * @secure
     */
    webTimesheetsTimesheetnicknameGettimesheetnicknameexportCreate: (data: GetTimesheetNickNameInputExportInput, params: RequestParams = {}) =>
      this.request<ExcelFileDtoMessageModel, void>({
        path: `/api/web/timesheets/timesheetnickname/gettimesheetnicknameexport`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags TimesheetNickName
     * @name WebTimesheetsTimesheetnicknameGetselectCreate
     * @summary 选择框 (Auth policies: Web)
     * @request POST:/api/web/timesheets/timesheetnickname/getselect
     * @secure
     */
    webTimesheetsTimesheetnicknameGetselectCreate: (data: GetOrganizationUnitsInput, params: RequestParams = {}) =>
      this.request<ComboboxItemDtoListMessageModel, void>({
        path: `/api/web/timesheets/timesheetnickname/getselect`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags TimesheetNickName
     * @name WebTimesheetsTimesheetnicknameGetCreate
     * @summary 获取详情 (Auth policies: Web)
     * @request POST:/api/web/timesheets/timesheetnickname/get/{id}
     * @secure
     */
    webTimesheetsTimesheetnicknameGetCreate: (id: string, params: RequestParams = {}) =>
      this.request<TimesheetNickNameDtoMessageModel, void>({
        path: `/api/web/timesheets/timesheetnickname/get/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags TimesheetNickName
     * @name WebTimesheetsTimesheetnicknameGetforeditCreate
     * @summary 获取修改实体 (Auth policies: Web)
     * @request POST:/api/web/timesheets/timesheetnickname/getforedit/{id}
     * @secure
     */
    webTimesheetsTimesheetnicknameGetforeditCreate: (id: string, params: RequestParams = {}) =>
      this.request<GetTimesheetNickNameForEditOutputForEditModelMessageModel, void>({
        path: `/api/web/timesheets/timesheetnickname/getforedit/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags TimesheetNickName
     * @name WebTimesheetsTimesheetnicknamePostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/timesheets/timesheetnickname/post
     * @secure
     */
    webTimesheetsTimesheetnicknamePostCreate: (data: CreateTimesheetNickNameInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/timesheets/timesheetnickname/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags TimesheetNickName
     * @name WebTimesheetsTimesheetnicknamePutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/timesheets/timesheetnickname/put/{id}
     * @secure
     */
    webTimesheetsTimesheetnicknamePutUpdate: (id: string, data: CreateTimesheetNickNameInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/timesheets/timesheetnickname/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags TimesheetNickName
     * @name WebTimesheetsTimesheetnicknameDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/timesheets/timesheetnickname/delete/{id}
     * @secure
     */
    webTimesheetsTimesheetnicknameDeleteDelete: (id: string, data: StringEntityDto, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/timesheets/timesheetnickname/delete/${id}`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags TimesheetNickName
     * @name WebTimesheetsTimesheetnicknameBatchdeleteCreate
     * @summary 批量删除 (Auth policies: Web)
     * @request POST:/api/web/timesheets/timesheetnickname/batchdelete
     * @secure
     */
    webTimesheetsTimesheetnicknameBatchdeleteCreate: (data: StringEntityDto[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/timesheets/timesheetnickname/batchdelete`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags TimesheetPreferences
     * @name WebTimesheetsTimesheetpreferencesTimesheetpreferencesGetpreferencesCreate
     * @summary 获取工时偏好设置 (Auth policies: Web)
     * @request POST:/api/web/timesheets/timesheetpreferences/timesheetpreferences/getpreferences
     * @secure
     */
    webTimesheetsTimesheetpreferencesTimesheetpreferencesGetpreferencesCreate: (params: RequestParams = {}) =>
      this.request<TimesheetPreferencesSettingDtoMessageModel, void>({
        path: `/api/web/timesheets/timesheetpreferences/timesheetpreferences/getpreferences`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags TimesheetPreferences
     * @name WebTimesheetsTimesheetpreferencesTimesheetpreferencesPostpreferencesCreate
     * @summary 修改工时偏好设置 (Auth policies: Web)
     * @request POST:/api/web/timesheets/timesheetpreferences/timesheetpreferences/postpreferences
     * @secure
     */
    webTimesheetsTimesheetpreferencesTimesheetpreferencesPostpreferencesCreate: (data: TimesheetPreferencesSettingDto, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/timesheets/timesheetpreferences/timesheetpreferences/postpreferences`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags TimesheetReport
     * @name WebTimesheetsTimesheetreportGetmattertimesheetsubtotalCreate
     * @summary 单个案件工时列表及统计信息 (Auth policies: Web)
     * @request POST:/api/web/timesheets/timesheetreport/getmattertimesheetsubtotal
     * @secure
     */
    webTimesheetsTimesheetreportGetmattertimesheetsubtotalCreate: (data: GetTimesheetReportInput, params: RequestParams = {}) =>
      this.request<GetReportBySingleMatterDetailOutputMessageModel, void>({
        path: `/api/web/timesheets/timesheetreport/getmattertimesheetsubtotal`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags TimesheetReport
     * @name WebTimesheetsTimesheetreportGettimekeeperbydateworkedCreate
     * @summary 统计计时人员某段时间区间内的工时记录小计 (Auth policies: Web)
     * @request POST:/api/web/timesheets/timesheetreport/gettimekeeperbydateworked
     * @secure
     */
    webTimesheetsTimesheetreportGettimekeeperbydateworkedCreate: (data: GetTimesheetReportInput, params: RequestParams = {}) =>
      this.request<GetTotalHoursAndListOutputMessageModel, void>({
        path: `/api/web/timesheets/timesheetreport/gettimekeeperbydateworked`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags TimesheetReport
     * @name WebTimesheetsTimesheetreportGettimebytimekeeperCreate
     * @summary 统计计时人员的工时小时数 (Auth policies: Web)
     * @request POST:/api/web/timesheets/timesheetreport/gettimebytimekeeper
     * @secure
     */
    webTimesheetsTimesheetreportGettimebytimekeeperCreate: (data: GetTimesheetReportInput, params: RequestParams = {}) =>
      this.request<GetTimeByTimekeeperOutputMessageModel, void>({
        path: `/api/web/timesheets/timesheetreport/gettimebytimekeeper`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags TimesheetReport
     * @name WebTimesheetsTimesheetreportGettimebytimekeeperandmatterCreate
     * @summary 统计某个案件下，某几位律师或某个组的工时小时数 (Auth policies: Web)
     * @request POST:/api/web/timesheets/timesheetreport/gettimebytimekeeperandmatter
     * @secure
     */
    webTimesheetsTimesheetreportGettimebytimekeeperandmatterCreate: (data: GetTimesheetReportInput, params: RequestParams = {}) =>
      this.request<GetTimeByTimekeeperAndMatterOutputMessageModel, void>({
        path: `/api/web/timesheets/timesheetreport/gettimebytimekeeperandmatter`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags TimesheetReport
     * @name WebTimesheetsTimesheetreportGetworkinghourssummarybytimekeeperCreate
     * @summary 统计特殊工时提交的工时记录小计 (Auth policies: Web)
     * @request POST:/api/web/timesheets/timesheetreport/getworkinghourssummarybytimekeeper
     * @secure
     */
    webTimesheetsTimesheetreportGetworkinghourssummarybytimekeeperCreate: (data: GetTimesheetReportInput, params: RequestParams = {}) =>
      this.request<GetWorkingHoursSummaryOutputListMessageModel, void>({
        path: `/api/web/timesheets/timesheetreport/getworkinghourssummarybytimekeeper`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags TimesheetReport
     * @name WebTimesheetsTimesheetreportGetsubmissioncategoryforworkinghoursCreate
     * @summary 统计工时提交类型记录明细 (Auth policies: Web)
     * @request POST:/api/web/timesheets/timesheetreport/getsubmissioncategoryforworkinghours
     * @secure
     */
    webTimesheetsTimesheetreportGetsubmissioncategoryforworkinghoursCreate: (data: GetTimesheetReportInput, params: RequestParams = {}) =>
      this.request<GetSubmissionCategoryForWorkingHoursOutputListMessageModel, void>({
        path: `/api/web/timesheets/timesheetreport/getsubmissioncategoryforworkinghours`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags TimesheetReport
     * @name WebTimesheetsTimesheetreportGetbillingguideoldformatexportCreate
     * @summary 单个案件工时列表及统计信息导出 (Auth policies: Web)
     * @request POST:/api/web/timesheets/timesheetreport/getbillingguideoldformatexport
     * @secure
     */
    webTimesheetsTimesheetreportGetbillingguideoldformatexportCreate: (data: GetTimesheetReportInput, params: RequestParams = {}) =>
      this.request<AttachmentDownloadDtoMessageModel, void>({
        path: `/api/web/timesheets/timesheetreport/getbillingguideoldformatexport`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags TimesheetReport
     * @name WebTimesheetsTimesheetreportGetbillingguideoldformatexportwithpdfCreate
     * @summary 单个案件工时列表及统计信息导出Xlsx和Pdf (Auth policies: Web)
     * @request POST:/api/web/timesheets/timesheetreport/getbillingguideoldformatexportwithpdf
     * @secure
     */
    webTimesheetsTimesheetreportGetbillingguideoldformatexportwithpdfCreate: (data: GetTimesheetReportInput, params: RequestParams = {}) =>
      this.request<AttachmentDownloadDtoListMessageModel, void>({
        path: `/api/web/timesheets/timesheetreport/getbillingguideoldformatexportwithpdf`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags TimesheetReport
     * @name WebTimesheetsTimesheetreportGetbillingguideexportCreate
     * @summary 单个案件工时列表BillingGuide导出 (Auth policies: Web)
     * @request POST:/api/web/timesheets/timesheetreport/getbillingguideexport
     * @secure
     */
    webTimesheetsTimesheetreportGetbillingguideexportCreate: (data: GetTimesheetReportInput, params: RequestParams = {}) =>
      this.request<AttachmentDownloadDtoMessageModel, void>({
        path: `/api/web/timesheets/timesheetreport/getbillingguideexport`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags TimesheetReport
     * @name WebTimesheetsTimesheetreportGetbillingguideexportwithpdfCreate
     * @summary 单个案件工时列表BillingGuide导出Xlsx和Pdf (Auth policies: Web)
     * @request POST:/api/web/timesheets/timesheetreport/getbillingguideexportwithpdf
     * @secure
     */
    webTimesheetsTimesheetreportGetbillingguideexportwithpdfCreate: (data: GetTimesheetReportInput, params: RequestParams = {}) =>
      this.request<AttachmentDownloadDtoListMessageModel, void>({
        path: `/api/web/timesheets/timesheetreport/getbillingguideexportwithpdf`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags TimesheetReport
     * @name WebTimesheetsTimesheetreportGettimekeeperbydateworkedexportCreate
     * @summary Get Timekeeper By Date Worked Export Excel (Auth policies: Web)
     * @request POST:/api/web/timesheets/timesheetreport/gettimekeeperbydateworkedexport
     * @secure
     */
    webTimesheetsTimesheetreportGettimekeeperbydateworkedexportCreate: (data: GetTimesheetReportInput, params: RequestParams = {}) =>
      this.request<AttachmentDownloadDtoMessageModel, void>({
        path: `/api/web/timesheets/timesheetreport/gettimekeeperbydateworkedexport`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags TimesheetReport
     * @name WebTimesheetsTimesheetreportGettimekeeperbydateworkedexportwithpdfCreate
     * @summary Get Timekeeper By Date Worked Export Excel and Pdf (Auth policies: Web)
     * @request POST:/api/web/timesheets/timesheetreport/gettimekeeperbydateworkedexportwithpdf
     * @secure
     */
    webTimesheetsTimesheetreportGettimekeeperbydateworkedexportwithpdfCreate: (data: GetTimesheetReportInput, params: RequestParams = {}) =>
      this.request<AttachmentDownloadDtoListMessageModel, void>({
        path: `/api/web/timesheets/timesheetreport/gettimekeeperbydateworkedexportwithpdf`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags TimesheetReport
     * @name WebTimesheetsTimesheetreportGettimebytimekeeperexportCreate
     * @summary Get Time By Timekeeper Export Excel (Auth policies: Web)
     * @request POST:/api/web/timesheets/timesheetreport/gettimebytimekeeperexport
     * @secure
     */
    webTimesheetsTimesheetreportGettimebytimekeeperexportCreate: (data: GetTimesheetReportInput, params: RequestParams = {}) =>
      this.request<AttachmentDownloadDtoMessageModel, void>({
        path: `/api/web/timesheets/timesheetreport/gettimebytimekeeperexport`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags TimesheetReport
     * @name WebTimesheetsTimesheetreportGettimebytimekeeperexportwithpdfCreate
     * @summary Get Time By Timekeeper Export Excel and Pdf (Auth policies: Web)
     * @request POST:/api/web/timesheets/timesheetreport/gettimebytimekeeperexportwithpdf
     * @secure
     */
    webTimesheetsTimesheetreportGettimebytimekeeperexportwithpdfCreate: (data: GetTimesheetReportInput, params: RequestParams = {}) =>
      this.request<AttachmentDownloadDtoListMessageModel, void>({
        path: `/api/web/timesheets/timesheetreport/gettimebytimekeeperexportwithpdf`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags TimesheetReport
     * @name WebTimesheetsTimesheetreportGettimebytimekeeperandmatterexportCreate
     * @summary Get Time By Timekeeper,Matter Export Excel (Auth policies: Web)
     * @request POST:/api/web/timesheets/timesheetreport/gettimebytimekeeperandmatterexport
     * @secure
     */
    webTimesheetsTimesheetreportGettimebytimekeeperandmatterexportCreate: (data: GetTimesheetReportInput, params: RequestParams = {}) =>
      this.request<AttachmentDownloadDtoMessageModel, void>({
        path: `/api/web/timesheets/timesheetreport/gettimebytimekeeperandmatterexport`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags TimesheetReport
     * @name WebTimesheetsTimesheetreportGettimebytimekeeperandmatterexportwithpdfCreate
     * @summary Get Time By Timekeeper,Matter Export Excel and Pdf (Auth policies: Web)
     * @request POST:/api/web/timesheets/timesheetreport/gettimebytimekeeperandmatterexportwithpdf
     * @secure
     */
    webTimesheetsTimesheetreportGettimebytimekeeperandmatterexportwithpdfCreate: (data: GetTimesheetReportInput, params: RequestParams = {}) =>
      this.request<AttachmentDownloadDtoListMessageModel, void>({
        path: `/api/web/timesheets/timesheetreport/gettimebytimekeeperandmatterexportwithpdf`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags TimesheetReport
     * @name WebTimesheetsTimesheetreportGettimebytimekeeperwithoutmatterexportCreate
     * @summary Get Time By Timekeeper Without Matter Export Excel (Auth policies: Web)
     * @request POST:/api/web/timesheets/timesheetreport/gettimebytimekeeperwithoutmatterexport
     * @secure
     */
    webTimesheetsTimesheetreportGettimebytimekeeperwithoutmatterexportCreate: (data: GetTimesheetReportInput, params: RequestParams = {}) =>
      this.request<AttachmentDownloadDtoMessageModel, void>({
        path: `/api/web/timesheets/timesheetreport/gettimebytimekeeperwithoutmatterexport`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags TimesheetReport
     * @name WebTimesheetsTimesheetreportGettimebytimekeeperwithoutmatterexportwithpdfCreate
     * @summary Get Time By Timekeeper Without Matter Export Excel and Pdf (Auth policies: Web)
     * @request POST:/api/web/timesheets/timesheetreport/gettimebytimekeeperwithoutmatterexportwithpdf
     * @secure
     */
    webTimesheetsTimesheetreportGettimebytimekeeperwithoutmatterexportwithpdfCreate: (data: GetTimesheetReportInput, params: RequestParams = {}) =>
      this.request<AttachmentDownloadDtoListMessageModel, void>({
        path: `/api/web/timesheets/timesheetreport/gettimebytimekeeperwithoutmatterexportwithpdf`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags TimesheetReport
     * @name WebTimesheetsTimesheetreportGettimebymatterwithalltimekeeperexportCreate
     * @summary Get Time By Matter With AllTimekeeper Export Excel (Auth policies: Web)
     * @request POST:/api/web/timesheets/timesheetreport/gettimebymatterwithalltimekeeperexport
     * @secure
     */
    webTimesheetsTimesheetreportGettimebymatterwithalltimekeeperexportCreate: (data: GetTimesheetReportInput, params: RequestParams = {}) =>
      this.request<AttachmentDownloadDtoMessageModel, void>({
        path: `/api/web/timesheets/timesheetreport/gettimebymatterwithalltimekeeperexport`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags TimesheetReport
     * @name WebTimesheetsTimesheetreportGettimebymatterwithalltimekeeperexportwithpdfCreate
     * @summary Get Time By Matter With AllTimekeeper Export Excel and Pdf (Auth policies: Web)
     * @request POST:/api/web/timesheets/timesheetreport/gettimebymatterwithalltimekeeperexportwithpdf
     * @secure
     */
    webTimesheetsTimesheetreportGettimebymatterwithalltimekeeperexportwithpdfCreate: (data: GetTimesheetReportInput, params: RequestParams = {}) =>
      this.request<AttachmentDownloadDtoListMessageModel, void>({
        path: `/api/web/timesheets/timesheetreport/gettimebymatterwithalltimekeeperexportwithpdf`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags TimesheetReport
     * @name WebTimesheetsTimesheetreportGettimebymatterwithmultipletimekeeperexportCreate
     * @summary Get Time By Matter With Multiple Timekeeper Export Excel (Auth policies: Web)
     * @request POST:/api/web/timesheets/timesheetreport/gettimebymatterwithmultipletimekeeperexport
     * @secure
     */
    webTimesheetsTimesheetreportGettimebymatterwithmultipletimekeeperexportCreate: (data: GetTimesheetReportInput, params: RequestParams = {}) =>
      this.request<AttachmentDownloadDtoMessageModel, void>({
        path: `/api/web/timesheets/timesheetreport/gettimebymatterwithmultipletimekeeperexport`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags TimesheetReport
     * @name WebTimesheetsTimesheetreportGettimebymatterwithmultipletimekeeperexportwithpdfCreate
     * @summary Get Time By Matter With Multiple Timekeeper Export Excel and Pdf (Auth policies: Web)
     * @request POST:/api/web/timesheets/timesheetreport/gettimebymatterwithmultipletimekeeperexportwithpdf
     * @secure
     */
    webTimesheetsTimesheetreportGettimebymatterwithmultipletimekeeperexportwithpdfCreate: (data: GetTimesheetReportInput, params: RequestParams = {}) =>
      this.request<AttachmentDownloadDtoListMessageModel, void>({
        path: `/api/web/timesheets/timesheetreport/gettimebymatterwithmultipletimekeeperexportwithpdf`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags TimesheetReport
     * @name WebTimesheetsTimesheetreportGetworkinghourssummarybytimekeeperexportCreate
     * @summary Get Working Hours Summary By Timekeeper Export Excel (Auth policies: Web)
     * @request POST:/api/web/timesheets/timesheetreport/getworkinghourssummarybytimekeeperexport
     * @secure
     */
    webTimesheetsTimesheetreportGetworkinghourssummarybytimekeeperexportCreate: (data: GetTimesheetReportInput, params: RequestParams = {}) =>
      this.request<AttachmentDownloadDtoMessageModel, void>({
        path: `/api/web/timesheets/timesheetreport/getworkinghourssummarybytimekeeperexport`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags TimesheetReport
     * @name WebTimesheetsTimesheetreportGetworkinghourssummarybytimekeeperexportwithpdfCreate
     * @summary Get Working Hours Summary By Timekeeper Export Excel and Pdf (Auth policies: Web)
     * @request POST:/api/web/timesheets/timesheetreport/getworkinghourssummarybytimekeeperexportwithpdf
     * @secure
     */
    webTimesheetsTimesheetreportGetworkinghourssummarybytimekeeperexportwithpdfCreate: (data: GetTimesheetReportInput, params: RequestParams = {}) =>
      this.request<AttachmentDownloadDtoListMessageModel, void>({
        path: `/api/web/timesheets/timesheetreport/getworkinghourssummarybytimekeeperexportwithpdf`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags TimesheetReport
     * @name WebTimesheetsTimesheetreportGetsubmissioncategoryforworkinghoursexportCreate
     * @summary Get Submission Category For Working Hours Export Excel (Auth policies: Web)
     * @request POST:/api/web/timesheets/timesheetreport/getsubmissioncategoryforworkinghoursexport
     * @secure
     */
    webTimesheetsTimesheetreportGetsubmissioncategoryforworkinghoursexportCreate: (data: GetTimesheetReportInput, params: RequestParams = {}) =>
      this.request<AttachmentDownloadDtoMessageModel, void>({
        path: `/api/web/timesheets/timesheetreport/getsubmissioncategoryforworkinghoursexport`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags UserInfo
     * @name WebSysUserinfoPageCreate
     * @summary 获取全部用户 (Auth policies: Web)
     * @request POST:/api/web/sys/userinfo/page
     * @secure
     */
    webSysUserinfoPageCreate: (data: GetUserInfoInput, params: RequestParams = {}) =>
      this.request<UserInfoDtoPageModelMessageModel, void>({
        path: `/api/web/sys/userinfo/page`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags UserInfo
     * @name WebSysUserinfoSelectCreate
     * @summary 人员选择数据 (Auth policies: Web)
     * @request POST:/api/web/sys/userinfo/select
     * @secure
     */
    webSysUserinfoSelectCreate: (data: GetUserInfoInput, params: RequestParams = {}) =>
      this.request<UserInfoDtoPageModelMessageModel, void>({
        path: `/api/web/sys/userinfo/select`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags UserInfo
     * @name WebSysUserinfoListCreate
     * @summary 列表 (Auth policies: Web)
     * @request POST:/api/web/sys/userinfo/list
     * @secure
     */
    webSysUserinfoListCreate: (data: string[], params: RequestParams = {}) =>
      this.request<UserInfoDtoListMessageModel, void>({
        path: `/api/web/sys/userinfo/list`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags UserInfo
     * @name WebSysUserinfoOrganizationunitDetail
     * @summary 获取用户数据权限 (Auth policies: Web)
     * @request GET:/api/web/sys/userinfo/{id}/organizationunit
     * @secure
     */
    webSysUserinfoOrganizationunitDetail: (id: string, params: RequestParams = {}) =>
      this.request<OrganizationUnitsTreeDtoMessageModel, void>({
        path: `/api/web/sys/userinfo/${id}/organizationunit`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags UserInfo
     * @name WebSysUserinfoOrganizationunitCreate
     * @summary 设置用户数据权限 (Auth policies: Web)
     * @request POST:/api/web/sys/userinfo/{id}/organizationunit
     * @secure
     */
    webSysUserinfoOrganizationunitCreate: (id: string, data: string[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/userinfo/${id}/organizationunit`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags UserInfo
     * @name WebSysUserinfoPermissionDetail
     * @summary 获取用户权限 (Auth policies: Web)
     * @request GET:/api/web/sys/userinfo/{id}/permission
     * @secure
     */
    webSysUserinfoPermissionDetail: (id: string, params: RequestParams = {}) =>
      this.request<PermissionTreeMessageModel, void>({
        path: `/api/web/sys/userinfo/${id}/permission`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags UserInfo
     * @name WebSysUserinfoPermissionCreate
     * @summary 添加用户权限 (Auth policies: Web)
     * @request POST:/api/web/sys/userinfo/{id}/permission
     * @secure
     */
    webSysUserinfoPermissionCreate: (id: string, data: string[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/userinfo/${id}/permission`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags UserInfo
     * @name WebSysUserinfoPermissionList
     * @summary 获取当前用户权限配置 (Auth policies: Web)
     * @request GET:/api/web/sys/userinfo/permission
     * @secure
     */
    webSysUserinfoPermissionList: (params: RequestParams = {}) =>
      this.request<StringListMessageModel, void>({
        path: `/api/web/sys/userinfo/permission`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags UserInfo
     * @name WebSysUserinfoDetail
     * @summary 获取用户 (Auth policies: Web)
     * @request GET:/api/web/sys/userinfo/{id}
     * @secure
     */
    webSysUserinfoDetail: (id: string, params: RequestParams = {}) =>
      this.request<UserInfoDtoMessageModel, void>({
        path: `/api/web/sys/userinfo/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags UserInfo
     * @name WebSysUserinfoUpdate
     * @summary 更新用户与角色 (Auth policies: Web)
     * @request PUT:/api/web/sys/userinfo/{id}
     * @secure
     */
    webSysUserinfoUpdate: (id: string, data: CreateUserInfoInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/sys/userinfo/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags UserInfo
     * @name WebSysUserinfoDelete
     * @summary 删除用户 (Auth policies: Web)
     * @request DELETE:/api/web/sys/userinfo/{id}
     * @secure
     */
    webSysUserinfoDelete: (id: string, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/sys/userinfo/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags UserInfo
     * @name WebSysUserinfoCreate
     * @summary 添加一个用户 (Auth policies: Web)
     * @request POST:/api/web/sys/userinfo
     * @secure
     */
    webSysUserinfoCreate: (data: CreateUserInfoInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/web/sys/userinfo`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags UserInfo
     * @name WebSysUserinfoDelete2
     * @summary 批量删除 (Auth policies: Web)
     * @request DELETE:/api/web/sys/userinfo
     * @originalName webSysUserinfoDelete
     * @duplicate
     * @secure
     */
    webSysUserinfoDelete2: (data: string[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/userinfo`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags UserInfo
     * @name WebSysUserinfoBindEmailUpdate
     * @summary 绑定邮箱 (Auth policies: Web)
     * @request PUT:/api/web/sys/userinfo/bind/email
     * @secure
     */
    webSysUserinfoBindEmailUpdate: (data: BindEmailInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/userinfo/bind/email`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags UserInfo
     * @name WebSysUserinfoEmailUpdate
     * @summary 修改邮箱 (Auth policies: Web)
     * @request PUT:/api/web/sys/userinfo/email
     * @secure
     */
    webSysUserinfoEmailUpdate: (data: UpdateUserEmailInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/userinfo/email`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags UserInfo
     * @name WebSysUserinfoBindPhoneUpdate
     * @summary  (Auth policies: Web)
     * @request PUT:/api/web/sys/userinfo/bind/phone
     * @secure
     */
    webSysUserinfoBindPhoneUpdate: (data: BindPhoneInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/userinfo/bind/phone`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags UserInfo
     * @name WebSysUserinfoPhoneUpdate
     * @summary 修改手机号   888888 万能验证码 (Auth policies: Web)
     * @request PUT:/api/web/sys/userinfo/phone
     * @secure
     */
    webSysUserinfoPhoneUpdate: (data: UpdateUserPhoneInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/userinfo/phone`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags UserInfo
     * @name WebSysUserinfoBindRoleCreate
     * @summary 绑定角色或职位 (Auth policies: Web)
     * @request POST:/api/web/sys/userinfo/bind/{id}/role
     * @secure
     */
    webSysUserinfoBindRoleCreate: (id: string, data: string[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/userinfo/bind/${id}/role`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
 * No description
 *
 * @tags UserInfo
 * @name WebSysUserinfoWorkTransferUpdate
 * @summary 工作交接
TODO 待完善，实际业务场景 (Auth policies: Web)
 * @request PUT:/api/web/sys/userinfo/work/transfer
 * @secure
 */
    webSysUserinfoWorkTransferUpdate: (data: UserWorkTransferInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/userinfo/work/transfer`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags UserInfo
     * @name WebSysUserinfoBatchUpdate
     * @summary 批量更新状态 (Auth policies: Web)
     * @request PUT:/api/web/sys/userinfo/batch/{status}
     * @secure
     */
    webSysUserinfoBatchUpdate: (status: string, data: string[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/userinfo/batch/${status}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags UserInfo
     * @name WebSysUserinfoCurrentList
     * @summary 获取当前登录用户信息 (Auth policies: Web)
     * @request GET:/api/web/sys/userinfo/current
     * @secure
     */
    webSysUserinfoCurrentList: (params: RequestParams = {}) =>
      this.request<HostUserInfoDtoMessageModel, void>({
        path: `/api/web/sys/userinfo/current`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags UserInfo
     * @name WebSysUserinfoLoginLogList
     * @summary 获取用户登录日志 (Auth policies: Web)
     * @request GET:/api/web/sys/userinfo/login/log
     * @secure
     */
    webSysUserinfoLoginLogList: (
      query?: {
        /** 客户端Ip所在地区 */
        clientIpRegion?: string;
        /** 用户名 */
        userName?: string;
        /** 手机号 */
        phoneNumber?: string;
        /** 客户端名称 */
        clientName?: string;
        /**
         * 开始时间
         * @format date-time
         */
        startTime?: string;
        /**
         * 结束时间
         * @format date-time
         */
        endTime?: string;
        /** 客户端设备信息 */
        clientDeviceInfo?: string;
        /** 是否在异地登录 */
        isLoginFromAnotherLocation?: boolean;
        /** 是否常用设备登录 */
        isLoginFromInCommonUseClient?: boolean;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<UserLoginAttemptsDtoPageModelMessageModel, void>({
        path: `/api/web/sys/userinfo/login/log`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags UserInfo
     * @name WebSysUserinfoPhonePasswordUpdate
     * @summary 通过手机号修改密码 (Auth policies: Web)
     * @request PUT:/api/web/sys/userinfo/phone/password
     * @secure
     */
    webSysUserinfoPhonePasswordUpdate: (data: UpdateUserPhoneToPasswordInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/userinfo/phone/password`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags UserInfo
     * @name WebSysUserinfoPasswordUpdate
     * @summary 修改密码 (Auth policies: Web)
     * @request PUT:/api/web/sys/userinfo/password
     * @secure
     */
    webSysUserinfoPasswordUpdate: (data: UpdateUserPassWordInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/userinfo/password`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags UserManualContent
     * @name WebUsermanualUsermanualcontentGetpageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/usermanual/usermanualcontent/getpage
     * @secure
     */
    webUsermanualUsermanualcontentGetpageCreate: (data: GetUserManualContentInput, params: RequestParams = {}) =>
      this.request<GetUserManualContentOutputPageModelMessageModel, void>({
        path: `/api/web/usermanual/usermanualcontent/getpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags UserManualContent
     * @name WebUsermanualUsermanualcontentListCreate
     * @summary 列表获取所有 (Auth policies: Web)
     * @request POST:/api/web/usermanual/usermanualcontent/list
     * @secure
     */
    webUsermanualUsermanualcontentListCreate: (data: GetUserManualContentInput, params: RequestParams = {}) =>
      this.request<GetUserManualContentOutputListMessageModel, void>({
        path: `/api/web/usermanual/usermanualcontent/list`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags UserManualContent
     * @name WebUsermanualUsermanualcontentGetbyusermanualmoduleidList
     * @summary 通过 UserManualModuleId 获取详情 (Auth policies: Web)
     * @request GET:/api/web/usermanual/usermanualcontent/getbyusermanualmoduleid
     * @secure
     */
    webUsermanualUsermanualcontentGetbyusermanualmoduleidList: (
      query?: {
        userManualModuleId?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<UserManualContentDtoMessageModel, void>({
        path: `/api/web/usermanual/usermanualcontent/getbyusermanualmoduleid`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags UserManualContent
     * @name WebUsermanualUsermanualcontentGetDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/usermanual/usermanualcontent/get/{id}
     * @secure
     */
    webUsermanualUsermanualcontentGetDetail: (id: string, params: RequestParams = {}) =>
      this.request<UserManualContentDtoMessageModel, void>({
        path: `/api/web/usermanual/usermanualcontent/get/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags UserManualContent
     * @name WebUsermanualUsermanualcontentGetforeditDetail
     * @summary 获取修改实体 (Auth policies: Web)
     * @request GET:/api/web/usermanual/usermanualcontent/getforedit/{id}
     * @secure
     */
    webUsermanualUsermanualcontentGetforeditDetail: (id: string, params: RequestParams = {}) =>
      this.request<GetUserManualContentForEditOutputForEditModelMessageModel, void>({
        path: `/api/web/usermanual/usermanualcontent/getforedit/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags UserManualContent
     * @name WebUsermanualUsermanualcontentPostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/usermanual/usermanualcontent/post
     * @secure
     */
    webUsermanualUsermanualcontentPostCreate: (data: CreateUserManualContentInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/usermanual/usermanualcontent/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags UserManualContent
     * @name WebUsermanualUsermanualcontentPutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/usermanual/usermanualcontent/put/{id}
     * @secure
     */
    webUsermanualUsermanualcontentPutUpdate: (id: string, data: CreateUserManualContentInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/usermanual/usermanualcontent/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags UserManualContent
     * @name WebUsermanualUsermanualcontentDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/usermanual/usermanualcontent/delete
     * @secure
     */
    webUsermanualUsermanualcontentDeleteDelete: (data: StringEntityDto, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/usermanual/usermanualcontent/delete`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags UserManualContent
     * @name WebUsermanualUsermanualcontentBatchdeleteCreate
     * @summary 批量删除 (Auth policies: Web)
     * @request POST:/api/web/usermanual/usermanualcontent/batchdelete
     * @secure
     */
    webUsermanualUsermanualcontentBatchdeleteCreate: (data: StringEntityDto[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/usermanual/usermanualcontent/batchdelete`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags UserManualFAQ
     * @name WebUsermanualUsermanualfaqGetpageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/usermanual/usermanualfaq/getpage
     * @secure
     */
    webUsermanualUsermanualfaqGetpageCreate: (data: GetUserManualFAQInput, params: RequestParams = {}) =>
      this.request<GetUserManualFAQOutputPageModelMessageModel, void>({
        path: `/api/web/usermanual/usermanualfaq/getpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags UserManualFAQ
     * @name WebUsermanualUsermanualfaqListCreate
     * @summary 列表获取所有 (Auth policies: Web)
     * @request POST:/api/web/usermanual/usermanualfaq/list
     * @secure
     */
    webUsermanualUsermanualfaqListCreate: (data: GetUserManualFAQInput, params: RequestParams = {}) =>
      this.request<GetUserManualFAQOutputListMessageModel, void>({
        path: `/api/web/usermanual/usermanualfaq/list`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags UserManualFAQ
     * @name WebUsermanualUsermanualfaqGetCreate
     * @summary 获取详情 (Auth policies: Web)
     * @request POST:/api/web/usermanual/usermanualfaq/get/{id}
     * @secure
     */
    webUsermanualUsermanualfaqGetCreate: (id: string, params: RequestParams = {}) =>
      this.request<UserManualFAQDtoMessageModel, void>({
        path: `/api/web/usermanual/usermanualfaq/get/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags UserManualFAQ
     * @name WebUsermanualUsermanualfaqGetforeditCreate
     * @summary 获取修改实体 (Auth policies: Web)
     * @request POST:/api/web/usermanual/usermanualfaq/getforedit/{id}
     * @secure
     */
    webUsermanualUsermanualfaqGetforeditCreate: (id: string, params: RequestParams = {}) =>
      this.request<GetUserManualFAQForEditOutputForEditModelMessageModel, void>({
        path: `/api/web/usermanual/usermanualfaq/getforedit/${id}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags UserManualFAQ
     * @name WebUsermanualUsermanualfaqPostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/usermanual/usermanualfaq/post
     * @secure
     */
    webUsermanualUsermanualfaqPostCreate: (data: CreateUserManualFAQInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/usermanual/usermanualfaq/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags UserManualFAQ
     * @name WebUsermanualUsermanualfaqPutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/usermanual/usermanualfaq/put/{id}
     * @secure
     */
    webUsermanualUsermanualfaqPutUpdate: (id: string, data: CreateUserManualFAQInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/usermanual/usermanualfaq/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags UserManualFAQ
     * @name WebUsermanualUsermanualfaqDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/usermanual/usermanualfaq/delete
     * @secure
     */
    webUsermanualUsermanualfaqDeleteDelete: (data: StringEntityDto, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/usermanual/usermanualfaq/delete`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags UserManualFAQ
     * @name WebUsermanualUsermanualfaqBatchdeleteCreate
     * @summary 批量删除 (Auth policies: Web)
     * @request POST:/api/web/usermanual/usermanualfaq/batchdelete
     * @secure
     */
    webUsermanualUsermanualfaqBatchdeleteCreate: (data: StringEntityDto[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/usermanual/usermanualfaq/batchdelete`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags UserManualModule
     * @name WebUsermanualUsermanualmoduleGetpageCreate
     * @summary 分页 (Auth policies: Web)
     * @request POST:/api/web/usermanual/usermanualmodule/getpage
     * @secure
     */
    webUsermanualUsermanualmoduleGetpageCreate: (data: GetUserManualModuleInput, params: RequestParams = {}) =>
      this.request<GetUserManualModuleOutputPageModelMessageModel, void>({
        path: `/api/web/usermanual/usermanualmodule/getpage`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags UserManualModule
     * @name WebUsermanualUsermanualmoduleListCreate
     * @summary 列表获取所有 (Auth policies: Web)
     * @request POST:/api/web/usermanual/usermanualmodule/list
     * @secure
     */
    webUsermanualUsermanualmoduleListCreate: (data: GetUserManualModuleInput, params: RequestParams = {}) =>
      this.request<GetUserManualModuleOutputListMessageModel, void>({
        path: `/api/web/usermanual/usermanualmodule/list`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags UserManualModule
     * @name WebUsermanualUsermanualmoduleGetusermanualmoduletreeList
     * @summary 获取菜单树 (Auth policies: Web)
     * @request GET:/api/web/usermanual/usermanualmodule/getusermanualmoduletree
     * @secure
     */
    webUsermanualUsermanualmoduleGetusermanualmoduletreeList: (
      query?: {
        /** @default "0" */
        pid?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<UserManualModuleTreeMessageModel, void>({
        path: `/api/web/usermanual/usermanualmodule/getusermanualmoduletree`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags UserManualModule
     * @name WebUsermanualUsermanualmoduleGetDetail
     * @summary 获取详情 (Auth policies: Web)
     * @request GET:/api/web/usermanual/usermanualmodule/get/{id}
     * @secure
     */
    webUsermanualUsermanualmoduleGetDetail: (id: string, params: RequestParams = {}) =>
      this.request<UserManualModuleDtoMessageModel, void>({
        path: `/api/web/usermanual/usermanualmodule/get/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags UserManualModule
     * @name WebUsermanualUsermanualmoduleGetforeditDetail
     * @summary 获取修改实体 (Auth policies: Web)
     * @request GET:/api/web/usermanual/usermanualmodule/getforedit/{id}
     * @secure
     */
    webUsermanualUsermanualmoduleGetforeditDetail: (id: string, params: RequestParams = {}) =>
      this.request<GetUserManualModuleForEditOutputForEditModelMessageModel, void>({
        path: `/api/web/usermanual/usermanualmodule/getforedit/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags UserManualModule
     * @name WebUsermanualUsermanualmodulePostCreate
     * @summary 添加 (Auth policies: Web)
     * @request POST:/api/web/usermanual/usermanualmodule/post
     * @secure
     */
    webUsermanualUsermanualmodulePostCreate: (data: CreateUserManualModuleInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/usermanual/usermanualmodule/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags UserManualModule
     * @name WebUsermanualUsermanualmodulePutUpdate
     * @summary 修改 (Auth policies: Web)
     * @request PUT:/api/web/usermanual/usermanualmodule/put/{id}
     * @secure
     */
    webUsermanualUsermanualmodulePutUpdate: (id: string, data: CreateUserManualModuleInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/usermanual/usermanualmodule/put/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags UserManualModule
     * @name WebUsermanualUsermanualmoduleDeleteDelete
     * @summary 删除 (Auth policies: Web)
     * @request DELETE:/api/web/usermanual/usermanualmodule/delete
     * @secure
     */
    webUsermanualUsermanualmoduleDeleteDelete: (data: StringEntityDto, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/usermanual/usermanualmodule/delete`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags UserManualModule
     * @name WebUsermanualUsermanualmoduleBatchdeleteCreate
     * @summary 批量删除 (Auth policies: Web)
     * @request POST:/api/web/usermanual/usermanualmodule/batchdelete
     * @secure
     */
    webUsermanualUsermanualmoduleBatchdeleteCreate: (data: StringEntityDto[], params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/usermanual/usermanualmodule/batchdelete`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags UserOrganizationUnit
     * @name WebSysUserorganizationunitCreate
     * @summary 添加角色或用户数据权限 (Auth policies: Web)
     * @request POST:/api/web/sys/userorganizationunit
     * @secure
     */
    webSysUserorganizationunitCreate: (data: CreateUserOrganizationUnitInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/web/sys/userorganizationunit`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags UserOrganizationUnit
     * @name WebSysUserorganizationunitList
     * @summary 获取用户配置的数据权限 (Auth policies: Web)
     * @request GET:/api/web/sys/userorganizationunit
     * @secure
     */
    webSysUserorganizationunitList: (
      query?: {
        userId?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<UserOrganizationUnitDtoIListMessageModel, void>({
        path: `/api/web/sys/userorganizationunit`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags UserOrganizationUnit
     * @name WebSysUserorganizationunitUserDetail
     * @summary 获取用户配置的数据权限 (Auth policies: Web)
     * @request GET:/api/web/sys/userorganizationunit/{id}/user
     * @secure
     */
    webSysUserorganizationunitUserDetail: (
      id: string,
      query?: {
        userId?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<UserOrganizationUnitDtoIListMessageModel, void>({
        path: `/api/web/sys/userorganizationunit/${id}/user`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags UserRole
     * @name WebSysUserroleUserDetail
     * @summary 获取用户所有角色 (Auth policies: Web)
     * @request GET:/api/web/sys/userrole/user/{id}
     * @secure
     */
    webSysUserroleUserDetail: (
      id: string,
      query?: {
        userId?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<RoleDtoListMessageModel, void>({
        path: `/api/web/sys/userrole/user/${id}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      })
  };
  configuration = {
    /**
     * No description
     *
     * @tags FileConfiguration
     * @name ConfigurationList
     * @summary  (Auth)
     * @request GET:/configuration
     * @secure
     */
    configurationList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/configuration`,
        method: 'GET',
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags FileConfiguration
     * @name ConfigurationCreate
     * @summary  (Auth)
     * @request POST:/configuration
     * @secure
     */
    configurationCreate: (data: FileConfiguration, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/configuration`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params
      })
  };
  outputcache = {
    /**
     * No description
     *
     * @tags OutputCache
     * @name OutputcacheDelete
     * @summary  (Auth)
     * @request DELETE:/outputcache/{region}
     * @secure
     */
    outputcacheDelete: (region: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/outputcache/${region}`,
        method: 'DELETE',
        secure: true,
        ...params
      })
  };
}
