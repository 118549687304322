import { isPowerFalsy } from './is';

// todo 支持数据
// compact(obj, fn)
export const compact = (obj, fn = isPowerFalsy) => {
  const entries = Object.entries(obj).filter(([key, value]) => {
    return !fn?.(value);
  });
  return Object.fromEntries(entries);
};
