import { mapping } from '@/utils';
import { refetch } from '@/utils/refetch';
import { fetchConcurrency } from './concurrency-promise';
/**
 * 分片读取数据
 * @param api
 * @param size
 * @param mapper
 */

// @todo mapper
export async function chunkFetch(api: string, options: Row = {}) {
  const defaultMappper = { value: 'id', label: 'displayName', display: 'displayName' };
  const onlyReturnBaseEntity = true;
  // debugTime 用于调试
  /**
   * 不给足concurrency并发进程数
   * 避免与前端抢浏览器进程数，造成卡顿
   */
  const { size = 2000, concurrency = 4, timeout = 20 * 1000, debugTime, mapper = defaultMappper, headers, body, ...otherFetchOptions } = options;
  try {
    const { response } = await refetch(api, { body: { page: 1, size: 1, onlyReturnBaseEntity, ...body }, method: 'post', headers, ...otherFetchOptions });
    const time = debugTime ?? Math.ceil(response.dataCount / size);
    const temp: any[] = [];
    for (let page = 1; page <= time; page++) {
      const args = [api, { body: { page, size, onlyReturnBaseEntity }, method: 'post', headers, ...otherFetchOptions }, timeout];
      temp.push(args);
    }

    const now = Date.now();
    const result = await fetchConcurrency(temp, concurrency);
    console.log(`chunkFetch 耗时: ${Date.now() - now}ms`);

    // const result = await Promise.allSettled(temp);
    // const result$filterRejected = result.filter((item => item.status === 'fulfilled')).map(({ value }) =>  value);
    // const data = result$filterRejected.map(({ response }) => response.data ?? response);
    // @ts-ignore
    const data = result.map(({ response }) => response?.data ?? response);
    const data$flat = data.flat();
    const data$mapping = mapping(data$flat, {
      value: 'id',
      label: 'name',
      display: 'displayName'
    });
    return data$mapping;
  } catch (e) {
    return [];
  }
}
