import { getCurrencyInfo } from './getCurrencyInfo';

export const transformCurrencyOptionLabel = (options) => {
  return options.map((e) => {
    const currencyInfo = getCurrencyInfo(e.value);
    const { flag_svg } = currencyInfo[0] || {};
    return {
      ...e,
      label: (
        <div>
          {flag_svg ? (
            <Avatar style={{ marginTop: '-4px' }} size={20} src={flag_svg} />
          ) : (
            <Avatar style={{ marginTop: '-4px' }} size={20}>
              {e.value}
            </Avatar>
          )}
          <span style={{ marginLeft: '4px' }}> {`${e.value}`}</span>
          {/* <span style={{ marginLeft: '4px' }}> {`${e.value}(${e.label})`}</span> */}
        </div>
      )
    };
  });
};
