import { groupBy } from 'lodash-es';

/**
 * 这是一个表格合并行的方法，传入要处理的数据，和以哪个值做合并
 * @param list
 * @param key
 * @returns
 */
export const setRowSpan = (list: any[], key: string) => {
  const mapping = groupBy(list, key);
  let arr: number[] = [];
  let newList: any = [];
  for (let key in mapping) {
    const item = mapping[key];
    const len = item.length;
    for (let i = 0; i < len; i++) {
      newList = [...newList, item[i]];
      if (i === 0) {
        arr.push(len);
      } else {
        arr.push(0);
      }
    }
  }
  return {
    onCell_rowSpan: (_, index) => {
      return { rowSpan: arr[index] };
    },
    newList
  };
};
