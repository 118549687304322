export const ConcurrencyManager = (axios, MAX_CONCURRENT = 10) => {
  const instance = {
    queue: [],
    running: [],
    shiftInitial: () => {
      // 用了异步任务,确保发送请求时都已入栈
      setTimeout(() => {
        if (instance.running.length < MAX_CONCURRENT) {
          instance.shift();
        }
      }, 0);
    },
    push: (reqHandler) => {
      instance.queue.push(reqHandler);
      instance.shiftInitial();
    },
    shift: () => {
      if (instance.queue.length) {
        const queued = instance.queue.shift();
        // 执行resolver, 才会发送请求
        queued.resolver(queued.request);

        // 入栈
        instance.running.push(queued);
      }
    },
    // 请求拦截
    requestHandler: (req) => {
      // 请求拦截中返回了一个pending状态的promise,
      return new Promise((resolve) => {
        // 收集当前请求参数,!!!注意, 只有resolve调用才会走到发送请求, 请参考 https://juejin.cn/post/7343534050148728872
        instance.push({ request: req, resolver: resolve });
      });
    },
    // 响应成功拦截
    responseHandler: (res) => {
      instance.running.shift();
      instance.shift();
      return res;
    },
    // 响应错误拦截
    responseErrorHandler: (res) => {
      return Promise.reject(instance.responseHandler(res));
    },
    interceptors: {
      request: null,
      response: null
    },
    // 删除拦截器
    detach: () => {
      axios.interceptors.request.eject(instance.interceptors.request);
      axios.interceptors.response.eject(instance.interceptors.response);
    }
  };
  // axios.interceptors.request.use 会返回一个id值,可以用来取消拦截器
  instance.interceptors.request = axios.interceptors.request.use(instance.requestHandler);
  instance.interceptors.response = axios.interceptors.response.use(instance.responseHandler, instance.responseErrorHandler);
  return instance;
};
