import { refetch, RefetchOptions } from "@/utils/refetch";
import { chunk } from "lodash-es";

/**
 * 请求限制并发数
 * // todo 类型
 */
export async function fetchConcurrency (refetcgArgs: [string, RefetchOptions, number][], concurrency = 6) {
    const chunks = chunk(refetcgArgs, concurrency);
    console.log('chunks', chunks)
    const results = []
    for await (const chunk of chunks) {
       const result = await Promise.allSettled(chunk.map((args) => refetch(...args)));
       for (const item of result) {
           if (item.status === "fulfilled") {
               // @ts-ignore
               results.push(item?.value);
           }
       }
    }
    return results;
}