/**
 * 鸭子原则
 * 希望是，那就是
 */

import { uniqueId } from 'lodash-es';
import { atom, atomFamily, DefaultValue, isRecoilValue, RecoilState, RecoilValue, selector, selectorFamily } from 'recoil';

export const makeAtom = (duck: any, key?: string) => {
  const state = isRecoilValue(duck) ? duck : atom({ key: key ?? uniqueId('makeAtom'), default: typeof duck === 'function' ? new DefaultValue() : duck });
  return state as unknown as RecoilState<any>;
};

export const makeAtomFamily = (duck: any, key?: string) => {
  return isRecoilValue(duck) ? duck : atomFamily({ key: key ?? uniqueId('makeAtomFamily'), default: typeof duck === 'function' ? new DefaultValue() : duck });
};
export const makeSelector = (duck: any, key?: string) => {
  return isRecoilValue(duck) ? duck : selector({ key: key ?? uniqueId('makeSelector'), get: () => duck });
};
export const makeSelectorFamily = (duck: any, key?: string) => {
  return isRecoilValue(duck) ? duck : selectorFamily({ key: key ?? uniqueId('makeSelectorFamily'), get: () => () => duck });
};
