import { create } from 'zustand'
import { persist, devtools } from 'zustand/middleware'

interface LanguageState {
  language?: string
  setLanguage?: (val: string) => void
}

const useLanguageStore = create<LanguageState>()(
  devtools(
    persist(
      (set) => ({
        language: 'zh-Hans',
        setLanguage: (language) => set(() => ({ language }), false, 'languageStorage/setLanguage'),
      }),
      {
        // 本地存储名称
        name: 'languageStorage',
      },
    ),
    {
      // redux调试工具名称
      name: 'languageStorage',
    },
  ),
)

export default useLanguageStore
